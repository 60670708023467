import React, {
  useContext,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';

import ContentSwitcherContext from './context';
import {
  ContentWrapper,
  StyledContentSwitcher,
  StyledSlides,
  StyledSlide,
} from './styles';

const ContentSwitcher = ({
  className,
}) => {
  const slidesRef = useRef(null);
  const {
    currentSection,
    goToNextSection,
    sectionsConfig,
  } = useContext(ContentSwitcherContext);

  useEffect(() => {
    slidesRef.current.slideTo(currentSection);
  }, [
    currentSection,
  ]);

  const renderContent = () => (
    sectionsConfig.map(({ Content, name }) => (
      <StyledSlide key={name}>
        <Content onClick={goToNextSection} />
      </StyledSlide>
    ))
  );

  return (
    <StyledContentSwitcher className={className}>
      <ContentWrapper>
        <StyledSlides
          ref={slidesRef}
          options={{
            allowTouchMove: false,
          }}
        >
          {renderContent()}
        </StyledSlides>
      </ContentWrapper>
    </StyledContentSwitcher>
  );
};

ContentSwitcher.propTypes = {
  className: PropTypes.string,
};

ContentSwitcher.defaultProps = {
  className: '',
};

export default ContentSwitcher;
