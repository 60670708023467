import styled from 'styled-components';
import VideoPreview from './components/VideoPreview';

const ViewportExplanatoryVideo = styled(VideoPreview)`
  position: absolute;
  transform: scale(1.5) translateY(-4%);
`;

const GenericViewportExplanatoryVideo = styled(VideoPreview)`
  position: absolute;
  height: unset;
  top: 50%;
  transform: scale(1) translateY(-50%);
`;

export {
  ViewportExplanatoryVideo,
  GenericViewportExplanatoryVideo,
};
