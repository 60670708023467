import styled, { css } from 'styled-components';
import { IonSpinner } from '@ionic/react';
import Player from 'react-player';

// Styles generated with http://embedresponsively.com/

const styleForRatio = ({ ratio }) => {
  const ratioValue = ratio > 0 ? ratio : 0.5625;
  const ratioPct = ratioValue * 100;
  return css`padding-top: ${ratioPct}%;`;
};

const Container = styled.div`
  position: relative;
  height: 100%;
  max-width: 100%;
  overflow: hidden;
  ${(props) => styleForRatio(props)}
`;

const StyledPlayer = styled(Player)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: ${(props) => (props.hidden ? 'none' : 'block')};

  iframe {
    height: 100%;
    width: 100%;
  }
`;

const StyledIonSpinner = styled(IonSpinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const SpinnerOverlay = styled(Player)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
`;

export {
  Container,
  StyledPlayer,
  StyledIonSpinner,
  SpinnerOverlay,
};
