import React from 'react';

const initialValues = {
  isReady: false,
  newContractDoc: null,
  isNewContractAvailable: false,
  userContractCollection: {},
  isReonboardingModalOpen: false,
  setIsReonboardingModalOpen: () => {},
};

const UserContractContext = React.createContext(initialValues);

export default UserContractContext;
export {
  initialValues,
};
