const Side = {
  ALTERNATE_SIDES: 'ALTERNATE_SIDES',
  LEFT_SIDE: 'LEFT_SIDE',
  RIGHT_SIDE: 'RIGHT_SIDE',
  LEFT_THEN_RIGHT_SIDE: 'LEFT_THEN_RIGHT_SIDE',
};

const SideLabel = {
  [Side.ALTERNATE_SIDES]: 'Alternate Sides',
  [Side.LEFT_SIDE]: 'Left Side',
  [Side.RIGHT_SIDE]: 'Right Side',
  [Side.LEFT_THEN_RIGHT_SIDE]: 'First left then right',
};

export {
  Side,
  SideLabel,
};
