import React, {
  useEffect,
  useContext,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import { withLayout, HeaderContext } from '../../../components/Layout';
import { HostedPage } from '../../HostedPage';
import config from '../../../config';

import {
  Container,
} from './styles';
import texts from './texts.json';

const PRIVACY_HOSTED_PAGE = 'privacy.html';

const PrivacyPolicy = () => {
  const { setTitle } = useContext(HeaderContext);
  const { appUrl } = config;

  useEffect(() => {
    setTitle(texts.privacyPolicy);
  }, [
    setTitle,
  ]);

  return (
    <Container>
      <HostedPage src={`${appUrl}/${PRIVACY_HOSTED_PAGE}`} />
    </Container>
  );
};

export default compose(
  withLayout,
  observer,
)(PrivacyPolicy);
