import { css } from 'styled-components';

import { mixins } from './breakpoints';

const fontFamily = {
  serif: 'Recoleta',
  sansSerif: 'Neue Montreal',
};

const headlineFontStyles = css`
  font-family: ${fontFamily.serif};
  font-style: normal;
`;

const sansSerifHeadlineFontStyles = css`
  font-family: ${fontFamily.sansSerif};
  font-style: normal;
`;

const headlineStyles = css`
  ${headlineFontStyles}
  color: ${({ theme }) => theme.colors.system.beta};
`;

// Headline styles

const headline1 = css`
  ${headlineStyles}
  font-size: 40px;
  line-height: 61px;
`;

const headline2 = css`
  ${headlineStyles}
  font-size: 27px;
  line-height: 130%;
  font-feature-settings: 'liga' off;
`;

const headline3 = css`
  ${headlineStyles}
  font-size: 27px;
  line-height: 136%;
  font-feature-settings: 'liga' off;
`;

const headline4 = css`
  ${headlineStyles}
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 3px;
  font-feature-settings: 'liga' off;
`;

// Sans-serif header styles
const headline1ss = css`
  ${headline1}
  ${sansSerifHeadlineFontStyles}
`;

const headline2ss = css`
  ${headline2}
  ${sansSerifHeadlineFontStyles}
  font-size: 28px;
`;

const headline3ss = css`
  ${headline3}
  ${sansSerifHeadlineFontStyles}
  font-size: 22px;
`;

const headline4ss = css`
  ${headline4}
  ${sansSerifHeadlineFontStyles}
`;

// Subtitle styles
const sutbtitleStyles = css`
  font-style: normal;
  font-family: 'Recoleta';
  font-size: 18px;
  line-height: 27px;
  color: ${({ theme }) => theme.colors.system.beta};
`;

const subtitle1 = css`
  ${sutbtitleStyles}
`;

const subtitle2 = css`
  ${sutbtitleStyles}
`;

// label styles

const labelStyles = css`
  font-style: normal;
  font-size: 16px;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.system.beta};
`;

// Overline styles

const overline = css`
  font-style: normal;
  font-size: 14px;
  line-height: 161.95%;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-feature-settings: 'liga' off;
  color: ${({ theme }) => theme.colors.system.beta};
  opacity: 0.6;
`;

// Body styles

const bodyStyles = css`
  font-style: normal;
  font-size: 16px;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.system.beta};
`;

const body1 = css`
  ${bodyStyles}
`;

const body2 = css`
  ${bodyStyles}
`;

// Caption styles

const captionStyles = css`
  font-style: normal;
  font-size: 16px;
  line-height: 19.2px;
  color: ${({ theme }) => theme.colors.base.primary};
`;

const caption1 = css`
  ${captionStyles}
`;

const caption2 = css`
  ${captionStyles}
`;

// Button styles
const button = css`
  font-style: normal;
  font-size: 21px;
  ${mixins.tabletMedia(css`
    font-size: 18px;
  `)}
  line-height: 119%;
  font-feature-settings: 'liga' off;
  user-select: none;
`;

const sansSerif = {
  headline1: headline1ss,
  headline2: headline2ss,
  headline3: headline3ss,
  headline4: headline4ss,
  headlineFontStyles: sansSerifHeadlineFontStyles,
};

export {
  headline1,
  headline2,
  headline3,
  headline4,
  subtitle1,
  subtitle2,
  overline,
  body1,
  body2,
  caption1,
  caption2,
  button,
  headlineStyles,
  headlineFontStyles,
  sutbtitleStyles,
  labelStyles,
  sansSerif,
  fontFamily,
};
