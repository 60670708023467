import React, {
  useEffect,
  useState,
  useMemo,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import useComponentMounted from '../../hooks/useComponentMounted';
import useComponentLoadingTime from '../../hooks/useComponentLoadingTime';
import UserNutritionProfile from '../../models/UserNutritionProfile';
import UserNutritionMacroGoals from '../../models/UserNutritionMacroGoals';
import CronometerToken from '../../models/CronometerToken';
import UserContext from '../UserContext';
import UserNutritionInfoContext, { initialValues } from './UserNutritionInfoContext';

const UserNutritionInfoContextProvider = ({
  children,
}) => {
  const [userNutritionProfileDoc, setUserNutritionProfileDoc] = useState(initialValues.userNutritionProfileDoc);
  const [
    userNutritionMacroGoalsDoc,
    setUserNutritionMacroGoalsDoc,
  ] = useState(initialValues.userNutritionMacroGoalsDoc);
  const [cronometerTokenDoc, setCronometerTokenDoc] = useState(initialValues.cronometerTokenDoc);
  const [isReady, setIsReady] = useState(initialValues.isReady);
  const { startLoading, finishLoading } = useComponentLoadingTime('userNutritionInfoContext');

  const {
    userId,
  } = useContext(UserContext);

  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    const init = async () => {
      startLoading();
      const nutritionProfileDoc = new UserNutritionProfile(userId);
      const macroGoalsDoc = new UserNutritionMacroGoals(userId);
      const tokenDoc = new CronometerToken(userId);

      await Promise.all([
        nutritionProfileDoc.init(),
        macroGoalsDoc.init(),
        tokenDoc.init(),
      ]);

      if (isComponentMountedRef.current) {
        setUserNutritionProfileDoc(nutritionProfileDoc);
        setUserNutritionMacroGoalsDoc(macroGoalsDoc);
        setCronometerTokenDoc(tokenDoc);
        setIsReady(true);
        finishLoading();
      }
    };
    init();
  }, [
    userId,
    isComponentMountedRef,
    startLoading,
    finishLoading,
  ]);

  const context = useMemo(() => ({
    userNutritionProfileDoc,
    userNutritionMacroGoalsDoc,
    cronometerTokenDoc,
    isReady,
  }), [
    userNutritionProfileDoc,
    userNutritionMacroGoalsDoc,
    cronometerTokenDoc,
    isReady,
  ]);

  return (
    <UserNutritionInfoContext.Provider value={context}>
      {children}
    </UserNutritionInfoContext.Provider>
  );
};

UserNutritionInfoContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(UserNutritionInfoContextProvider);
