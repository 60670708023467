import React from 'react';

import InAppBrowserContextProvider from './InAppBrowserContextProvider';

const withInAppBrowserContextProvider = (Component) => (props) => (
  <InAppBrowserContextProvider>
    <Component {...props} />
  </InAppBrowserContextProvider>
);

export {
  withInAppBrowserContextProvider,
};
