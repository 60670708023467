import React, { useMemo } from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { WorkoutFeedViewType } from '../../../../../models/WorkoutFeedView';

import {
  Container,
  ItemContainer,
  StyledTitle,
  WorkoutItem,
  WorkoutName,
  WorkoutStatusContainer,
  StyledCollapsiblePanel,
  CompletedIcon,
  IncompletIcon,
} from './styles';
import texts from './texts.json';

const WorkoutDayPanel = ({
  workoutList,
  dayName,
  dayString,
}) => {
  const subTitle = useMemo(() => {
    let text = '';
    const assignedWorkoutCount = workoutList[WorkoutFeedViewType.COACH_ASSIGNED_WORKOUT].length;
    const customWorkoutCount = workoutList[WorkoutFeedViewType.CUSTOM_WORKOUT].length;
    if (assignedWorkoutCount) {
      const completedCount = workoutList[WorkoutFeedViewType.COACH_ASSIGNED_WORKOUT]
        .filter((workout) => !!workout.isCompleted)
        .length;
      text = `${text}${completedCount}/${assignedWorkoutCount} workouts  `;
    }
    if (customWorkoutCount) {
      text = `${text}${customWorkoutCount} custom activities`;
    }
    if (!customWorkoutCount && !assignedWorkoutCount) {
      text = `${text}Rest Day`;
    }
    return text;
  }, [
    workoutList,
  ]);

  return (
    <Container>
      <StyledCollapsiblePanel
        title={dayName}
        titleDescription={dayString}
        disabled={!workoutList[WorkoutFeedViewType.COACH_ASSIGNED_WORKOUT].length
          && !workoutList[WorkoutFeedViewType.CUSTOM_WORKOUT].length}
        subTitle={subTitle}
      >
        {!!workoutList[WorkoutFeedViewType.COACH_ASSIGNED_WORKOUT].length && (
          <ItemContainer>
            <StyledTitle>{texts.workouts}</StyledTitle>
            {workoutList[WorkoutFeedViewType.COACH_ASSIGNED_WORKOUT].map((workout) => (
              <WorkoutItem key={workout.id}>
                <WorkoutStatusContainer>
                  {workout.isCompleted ? <CompletedIcon /> : <IncompletIcon />}
                </WorkoutStatusContainer>
                <WorkoutName>{workout.title}</WorkoutName>
              </WorkoutItem>
            ))}
          </ItemContainer>
        )}
        {!!workoutList[WorkoutFeedViewType.CUSTOM_WORKOUT].length && (
          <ItemContainer>
            <StyledTitle>{texts.customActivities}</StyledTitle>
            {workoutList[WorkoutFeedViewType.CUSTOM_WORKOUT].map((workout) => (
              <WorkoutItem key={workout.id}>
                <WorkoutStatusContainer>
                  {workout.isCompleted ? <CompletedIcon /> : <IncompletIcon />}
                </WorkoutStatusContainer>
                <WorkoutName>{workout.title}</WorkoutName>
              </WorkoutItem>
            ))}
          </ItemContainer>
        )}
      </StyledCollapsiblePanel>
    </Container>
  );
};

WorkoutDayPanel.propTypes = {
  workoutList: PropTypes.object.isRequired,
  dayName: PropTypes.string.isRequired,
  dayString: PropTypes.string.isRequired,
};

export default compose(
  observer,
)(WorkoutDayPanel);
