import React from 'react';
import {
  Route,
  Redirect,
  useRouteMatch,
} from 'react-router-dom';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import useSessionStore from '../hooks/useSessionStore';
import DebugSettings from '../pages/DebugSettings';

/**
 * Routes accessible only by admins.
 */
const DebugRoutes = () => {
  const { path } = useRouteMatch();
  const { isAdmin } = useSessionStore();

  // If the user trying to get access to this page is not an admin, then redirect to the root.
  if (!isAdmin) {
    return <Redirect to={{ pathname: '/' }} />;
  }

  return (
    <Route exact path={`${path}/settings`} component={DebugSettings} />
  );
};

export default compose(
  observer,
)(DebugRoutes);
