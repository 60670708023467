import {
  useCallback,
  useContext,
} from 'react';

import ChatContext from '../context';

/*
  This is the channel name that a user would see for its own channel when its
  own channel is tagged as admin channel.
*/
const ADMIN_CHANNEL_NAME = 'System2 Bot';

const useChannel = (channel) => {
  const { userId } = useContext(ChatContext);

  const getChannelName = useCallback(() => {
    if (!!channel?.data?.admin && userId === channel.id) {
      return ADMIN_CHANNEL_NAME;
    }

    return channel.data.name || channel.data.id;
  }, [
    channel,
    userId,
  ]);

  return {
    getChannelName,
  };
};

export default useChannel;
