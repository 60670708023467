import styled from 'styled-components';

import LoadingPage from '../../components/LoadingPage';
import WorkoutInfo from '../../components/WorkoutBaseData';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 15px;
  padding-bottom: 10px;
  height: 100%;
  overflow: hidden;
`;

const ContentSection = styled.div`
  flex: 1;
  width: 100%;
  overflow: auto;
`;

const FooterSection = styled.div`
  margin-top: 10px;
`;

const StyledLoadingPage = styled(LoadingPage)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: rgba(237, 238, 233, 0.6);
`;

const StyledWorkoutInfo = styled(WorkoutInfo)`
  margin-bottom: 30px;
`;

export {
  Container,
  ContentSection,
  FooterSection,
  StyledLoadingPage,
  StyledWorkoutInfo,
};
