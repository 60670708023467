// Video size configuration by default in Landscape mode
const videoSizeConfig = {
  video: {
    aspectRatio: 720 / 405,
    width: {
      ideal: 720,
    },
    height: {
      ideal: 405,
    },
  },
  debug: {
    width: 720,
  },
  selfie: {
    width: 456,
  },
};

export default videoSizeConfig;
