import { useMemo, useState } from 'react';

import config from '../../config';

const useDebugSettings = () => {
  // NOTE: for now let's keep using query params to easily support tracker playground
  const urlParams = new URLSearchParams(window.location.search);
  const videoName = urlParams.get('sv');

  // Debug settings data
  const [isDebugMode, setIsDebugMode] = useState(!!urlParams.has('debug'));
  const [testVideoServerURL, setTestVideoServerURL] = useState(config.debug.videosUrl);
  const [testVideoName, setTestVideoName] = useState(videoName);
  const [isTestVideoEnabled, setIsTestVideoEnabled] = useState(() => !!(testVideoName && testVideoServerURL));

  return useMemo(() => ({
    isDebugMode,
    isTestVideoEnabled,
    testVideoName,
    testVideoServerURL,
    setIsDebugMode,
    setIsTestVideoEnabled,
    setTestVideoName,
    setTestVideoServerURL,
  }), [
    isDebugMode,
    isTestVideoEnabled,
    testVideoName,
    testVideoServerURL,
    setIsDebugMode,
    setIsTestVideoEnabled,
    setTestVideoName,
    setTestVideoServerURL,
  ]);
};

export default useDebugSettings;
