import React from 'react';
import NavigationMenuLayout from './NavigationMenuLayout';

/**
 * High Order Component (HOC) that renders the given component with the NavigationMenuLayout.
 *
 * @param {ReactComponent} Component React Component to place as page content.
 */
const withNavigationMenuLayout = (Component) => (props) => (
  <NavigationMenuLayout>
    <Component {...props} />
  </NavigationMenuLayout>
);

export default withNavigationMenuLayout;
