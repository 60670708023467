import React, {
  useContext,
  useMemo,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import format from 'string-template';

import {
  caloriesToProtein,
  caloriesToCarbs,
  caloriesToFat,
} from '../../../utils/meals';
import CoachInfoContext from '../../../context/CoachInfoContext';
import UserContext from '../../../context/UserContext';
import UserNutritionInfoContext from '../../../context/UserNutritionInfoContext';
import MealPlanContext from '../../../context/MealPlanContext';
import useWeightGoalDisplayText from './hooks/useWeightGoalDisplayText';
import MealPlanTextSection from './MealPlanTextSection';
import PlanStructure from './PlanStructure';
import CustomizedNeeds from './CustomizedNeeds';
import MealPlanFaqs from './MealPlanFaqs';
import texts from './texts.json';

import {
  StyledMacroGoalsCard,
  StyledCollapsibleCard,
} from './styles';

const MealPlanOverview = () => {
  const {
    userNutritionProfileDoc,
    userNutritionMacroGoalsDoc,
  } = useContext(UserNutritionInfoContext);
  const {
    mealPlanAssignmentDoc: {
      macroAverages: {
        percentages,
      },
    },
    mealPlanAssignmentDoc,
  } = useContext(MealPlanContext);
  const { coachInfo } = useContext(CoachInfoContext);
  const { userDoc } = useContext(UserContext);

  const weeklyWeightGoalText = useWeightGoalDisplayText();

  const {
    totalDailyCalories,
    macroAverages: {
      percentages: userMacroPercentages = {},
      macros: userMacros,
    } = {},
  } = userNutritionMacroGoalsDoc;

  const {
    mealPlanConfig: {
      mealPlanIntroMessage = '',
      macrosInfoDisabled,
    } = {},
  } = coachInfo || {};

  // User macro percentages. Use overwritten if available, or use meal plan percentages if not.
  const macroPercentages = useMemo(() => ({
    protein: userMacroPercentages.protein || percentages.protein,
    carbs: userMacroPercentages.carbs || percentages.carbs,
    fat: userMacroPercentages.fat || percentages.fat,
  }), [
    userMacroPercentages,
    percentages,
  ]);

  // Macro nutrients (in grams). Use overwritten if available, or calculate from calories if not
  const macroNutrients = useMemo(() => (
    userMacros
      ? ({
        protein: userMacros.protein,
        carbs: userMacros.carbs,
        fat: userMacros.fat,
      }) : ({
        protein: caloriesToProtein(totalDailyCalories * ((macroPercentages.protein) / 100)),
        carbs: caloriesToCarbs(totalDailyCalories * ((macroPercentages.carbs) / 100)),
        fat: caloriesToFat(totalDailyCalories * ((macroPercentages.fat) / 100)),
      })
  ), [
    totalDailyCalories,
    userMacros,
    macroPercentages,
  ]);

  const mealPlanIntroContent = format(
    mealPlanAssignmentDoc?.customMealPlanIntroMessage || mealPlanIntroMessage || texts.introductionMessage,
    {
      clientName: userDoc.name,
    },
  );
  const guideContent = format(texts.guideIntroductionMessage, {
    goal: weeklyWeightGoalText,
  });

  return (
    <>
      {!macrosInfoDisabled && (
        <StyledMacroGoalsCard
          totalCalories={totalDailyCalories}
          protein={macroNutrients.protein}
          carbs={macroNutrients.carbs}
          fat={macroNutrients.fat}
          proteinPercentage={macroPercentages.protein}
          carbsPercentage={macroPercentages.carbs}
          fatPercentage={macroPercentages.fat}
        />
      )}

      <StyledCollapsibleCard
        title={texts.titleIntroduction}
        startOpen
      >
        <MealPlanTextSection content={mealPlanIntroContent} />
      </StyledCollapsibleCard>

      <StyledCollapsibleCard
        title={texts.titleGuide}
        startOpen
      >
        <MealPlanTextSection content={guideContent} />
      </StyledCollapsibleCard>

      {!macrosInfoDisabled && (
        <StyledCollapsibleCard title={texts.titleStructure}>
          <PlanStructure
            protein={macroNutrients.protein}
            carbs={macroNutrients.carbs}
            fat={macroNutrients.fat}
          />
        </StyledCollapsibleCard>
      )}

      {userNutritionProfileDoc.dietaryRestrictions.length > 0 && (
        <StyledCollapsibleCard title={texts.titleCustomizedNeeds}>
          <CustomizedNeeds />
        </StyledCollapsibleCard>
      )}

      <StyledCollapsibleCard title={texts.titleFaqs}>
        <MealPlanFaqs />
      </StyledCollapsibleCard>
    </>
  );
};

export default compose(
  observer,
)(MealPlanOverview);
