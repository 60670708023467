import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const TipsSection = ({ text }) => {
  const [tip, setTip] = useState(null);
  const [textLastTime, setTextLastTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    if (text) {
      setTip(text);
      setTextLastTime(Date.now());
    }
  }, [
    text,
  ]);

  /*
    Cleanup the tip text after 3 seconds.
  */
  useEffect(() => {
    let timer;
    if (textLastTime !== null) {
      const runMessageTimer = () => {
        const timeoutThreshold = 3000;
        const currentElapsedTime = Date.now() - textLastTime;
        if (currentElapsedTime >= timeoutThreshold) {
          setTextLastTime(null);
          setTip(null);
        } else {
          setElapsedTime(currentElapsedTime);
        }
      };
      timer = setInterval(runMessageTimer, 1000);
    } else {
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [
    textLastTime,
    elapsedTime,
  ]);

  return tip && (
    <div>{`Tips: ${tip}`}</div>
  );
};

TipsSection.propTypes = {
  text: PropTypes.string,
};

TipsSection.defaultProps = {
  text: '',
};

export default TipsSection;
