import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Logo = styled.img`
  max-width: none;
  height: 30px;
`;

const HeaderLogo = ({
  logo,
}) => (
  <Logo src={logo} alt="System2" />
);

HeaderLogo.propTypes = {
  logo: PropTypes.string.isRequired,
};

export default HeaderLogo;
