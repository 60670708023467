import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import format from 'string-template';

import useWorkoutNavigation from './useWorkoutNavigation';

/**
 * This React Hook allows navigation to the specified gameplay session path.
 */
const useGameplayNavigation = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { workoutAssignmentPath } = useWorkoutNavigation();

  const pathname = useMemo(() => `${workoutAssignmentPath}/{workoutAssignmentId}/play`, [workoutAssignmentPath]);

  const navigateToGameplaySession = useCallback(({
    workoutAssignmentId,
    gameplaySessionId = '',
  }, other = {}) => {
    let pathToGo = pathname;

    if (gameplaySessionId) {
      pathToGo += '/{gameplaySessionId}';
    }

    const formattedPathToGo = format(pathToGo, {
      workoutAssignmentId,
      gameplaySessionId,
    });

    const opts = {
      ...other,
      search,
      pathname: formattedPathToGo,
    };

    const { shouldReplaceURL = false } = other;

    if (shouldReplaceURL) {
      history.replace(opts);
    } else {
      history.push(opts);
    }
  }, [
    history,
    search,
    pathname,
  ]);

  return {
    navigateToGameplaySession,
    pathname,
  };
};

export default useGameplayNavigation;
