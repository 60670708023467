export const getQueryParams = () => window.location.search.substring(1).split('&').reduce((queryParams, queryParam) => {
  const pair = queryParam.split('=');
  return {
    ...queryParams,
    [decodeURIComponent(pair[0])]: decodeURIComponent(pair[1]),
  };
}, {});

export const getQueryVariable = (variable) => getQueryParams()[variable];

export const getUpdatedQueryString = (key, value) => {
  let { search } = window.location;

  if (search.length) {
    search = `${search}&${key}=${value}`;
  } else {
    search = `?${key}=${value}`;
  }

  return search;
};
