import React, {
  useMemo,
  useCallback,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

import { search } from '../../../utils/search';
import { ExerciseTagCategory } from '../../../models/ExerciseTag';
import ExerciseOverrideSelectorContext from '../context/ExerciseOverrideSelectorContext';
import { defaultTagsByCategory } from '../utils/exerciseTags';
import TagFilters from './TagFilters';

const TagFiltersContainer = ({
  className,
}) => {
  const {
    tagsByCategory,
    selectedTagsByCategory,
    updateSelectedTag,
    logExerciseOverrideEvent,
  } = useContext(ExerciseOverrideSelectorContext);

  const handleTagsChange = useCallback((categoryId, newTag) => {
    const newSelectedTag = newTag
      ? { id: newTag.value, tag: newTag.label }
      : null;

    if (newSelectedTag) {
      logExerciseOverrideEvent('exerciseOverrideTagFilterSelected', {
        ...newSelectedTag,
        categoryId,
      });
    } else {
      logExerciseOverrideEvent('exerciseOverrideTagFilterRemoved', {
        categoryId,
      });
    }

    updateSelectedTag(categoryId, newSelectedTag);
  }, [
    updateSelectedTag,
    logExerciseOverrideEvent,
  ]);

  const tags = useMemo(() => (
    Object.keys(tagsByCategory).reduce((accTags, categoryId) => {
      if (categoryId === ExerciseTagCategory.BODY_REGION_ADVANCED) {
        /*
          Check if some selection exists for the BODY_REGION_BASIC tag's category. If no selection exists
          then remove the tags available for selection for the BODY_REGION_ADVANCED category
        */
        const selectedBasicBodyRegionTags = selectedTagsByCategory[ExerciseTagCategory.BODY_REGION_BASIC];
        if (selectedBasicBodyRegionTags && selectedBasicBodyRegionTags.length === 0) {
          return {
            ...accTags,
            [categoryId]: {
              options: [],
              isDisabled: true,
            },
          };
        }
      }

      return {
        ...accTags,
        [categoryId]: {
          options: tagsByCategory[categoryId].map(({ id, tag }) => ({ label: tag, value: id })),
          isDisabled: false,
        },
      };
    }, defaultTagsByCategory())
  ), [
    selectedTagsByCategory,
    tagsByCategory,
  ]);

  const selectedTags = useMemo(() => (
    Object.keys(selectedTagsByCategory).reduce((accTags, categoryId) => {
      const newAccTags = {
        ...accTags,
      };

      const currentSelectedTags = selectedTagsByCategory[categoryId];
      newAccTags[categoryId] = currentSelectedTags.map(({ id, tag }) => ({ label: tag, value: id }));

      return newAccTags;
    }, defaultTagsByCategory())
  ), [
    selectedTagsByCategory,
  ]);

  const filterOption = useCallback((option, inputValue) => {
    // Extract the current label to check against the search query.
    const { label } = option;
    return search(label, inputValue);
  }, []);

  return (
    <TagFilters
      tags={tags}
      selectedTags={selectedTags}
      handleTagsChange={handleTagsChange}
      filterOption={filterOption}
      className={className}
    />
  );
};

TagFiltersContainer.propTypes = {
  className: PropTypes.string,
};

TagFiltersContainer.defaultProps = {
  className: '',
};

export default TagFiltersContainer;
