import React, { useState, useCallback, useContext } from 'react';

import PropTypes from 'prop-types';

import { Collection } from 'firestorter';
import UserContext from '../../context/UserContext';
import MealPlanContext from '../../context/MealPlanContext';
import CoachInfoContext from '../../context/CoachInfoContext';

import logEvent from '../../utils/logger';
import CollectionName from '../../utils/collections';
import useComponentMounted from '../../hooks/useComponentMounted';
import ReportRecipeSliderDrawer from './ReportRecipeSliderDrawer';
import texts from './texts.json';

import { ButtonContainer, ReportRecipeButton } from './styles';
import { INITIAL_STEP } from './constants';

const ReportRecipeSlider = ({ recipeId }) => {
  const [isSliderOpen, setIsSliderOpen] = useState(false);

  const [comments, setComments] = useState('');
  const [stepNumber, setStepNumber] = useState(INITIAL_STEP);

  const [reason, setReason] = useState('');

  const isComponentMountedRef = useComponentMounted();

  const { coachInfo: { id: coachId } = {} } = useContext(CoachInfoContext);
  const { userId: user } = useContext(UserContext);
  const {
    mealPlanAssignmentDoc: {
      id: mealPlanAssignmentId,
    } = {},
  } = useContext(MealPlanContext);

  const onClose = useCallback(() => {
    setIsSliderOpen(false);
  }, []);

  const onOpen = useCallback(() => {
    setStepNumber(INITIAL_STEP);
    setIsSliderOpen(true);
    logEvent('recipeReportOpen');
  }, []);

  const onSubmit = useCallback(async () => {
    const reportRecipeCollection = new Collection(CollectionName.RECIPE_REPORT);
    const reportData = {
      recipeId,
      userId: user,
      coachId,
      mealPlanAssignmentId,
      reportComment: comments,
      reportType: reason,
    };

    logEvent('recipeReport', reportData);

    await reportRecipeCollection.add({
      ...reportData,
      createdAt: new Date(),
    });

    if (isComponentMountedRef.current) {
      setComments('');
    }
  }, [
    coachId,
    comments,
    isComponentMountedRef,
    mealPlanAssignmentId,
    reason,
    recipeId,
    user,
  ]);

  return (
    <>
      <ButtonContainer>
        <ReportRecipeButton onClick={onOpen} variant="tertiary">
          {texts.reportRecipeButton}
        </ReportRecipeButton>
      </ButtonContainer>

      <ReportRecipeSliderDrawer
        isOpen={isSliderOpen}
        onClose={onClose}
        setComments={setComments}
        onSubmit={onSubmit}
        setStepNumber={setStepNumber}
        stepNumber={stepNumber}
        disabled={!comments}
        reason={reason}
        setReason={setReason}
      />
    </>
  );
};

ReportRecipeSlider.propTypes = {
  recipeId: PropTypes.string.isRequired,
};

export default ReportRecipeSlider;
