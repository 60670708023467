import React, {
  useContext,
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import { ReactComponent as PlayIcon } from '../../../../../../assets/icons/play-icon.svg';
import { ReactComponent as PauseIcon } from '../../../../../../assets/icons/pause-icon.svg';
import { ReactComponent as PencilIcon } from '../../../../../../assets/icons/pencil-icon.svg';

import UserActivityHistoryContext from '../../../../../../context/UserActivityHistoryContext';
import GameplayContext from '../../../../context/GameplayContext';
import ActivityContext from '../../../../context/ActivityContext';
import WorkoutExecutionInfoContext from '../../../../context/WorkoutExecutionInfoContext';
import ChatContext from '../../../../../../chat/context';

import ActionsMenu from './ActionsMenu';
import NotesBelt from './NotesBelt';

import {
  StyledActionsPanel,
  PlayPauseButton,
  PlayPauseButtonContainer,
  NotesWrapper,
  SlidingPanelsContainer,
  AddNotes,
} from './styles';

const ActionsPanel = ({
  showWorkoutDetails,
  onExit,
  logActivityHistoryClicked,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {
    onWorkoutPaused,
  } = useContext(GameplayContext);

  const { toggleChatModal } = useContext(ChatContext);

  const {
    isActivityWorkoutPaused,
    onPlayPauseActivity,
    activityExecutor: {
      logActivityEvent,
    },
  } = useContext(ActivityContext);

  const {
    displayInfo: {
      name: activityName,
    },
  } = useContext(WorkoutExecutionInfoContext);

  const onPlayPause = useCallback(() => {
    logActivityEvent('playPauseButtonClicked');

    onPlayPauseActivity();
  }, [
    onPlayPauseActivity,
    logActivityEvent,
  ]);

  const onMenuOpen = () => {
    setIsMenuOpen(true);
  };

  const onMenuClose = () => {
    setIsMenuOpen(false);
  };

  const onChatButtonClicked = useCallback(() => {
    logActivityEvent('chatButtonGameplayClicked');
    toggleChatModal();
    onWorkoutPaused();
  }, [
    onWorkoutPaused,
    logActivityEvent,
    toggleChatModal,
  ]);

  const { activityHistory } = useContext(UserActivityHistoryContext);

  const renderNotesBelt = useCallback(() => {
    const latestActivityHistoryValue = !!activityHistory[activityName] && activityHistory[activityName].latestValue;

    const isHistoryValueAvailable = !!latestActivityHistoryValue
      && (latestActivityHistoryValue.reps !== 0 || latestActivityHistoryValue.weight !== 0);

    if (!isHistoryValueAvailable) {
      return (
        <AddNotes onClick={logActivityHistoryClicked}>
          <PencilIcon />
        </AddNotes>
      );
    }

    return (
      <NotesBelt
        reps={activityHistory[activityName].latestValue.reps}
        weight={activityHistory[activityName].latestValue.weight}
        date={activityHistory[activityName].latestValue.date}
        measurementSystem={activityHistory[activityName].latestValue.measurementSystem}
        onClick={logActivityHistoryClicked}
      />
    );
  }, [
    activityHistory,
    activityName,
    logActivityHistoryClicked,
  ]);

  return (
    <StyledActionsPanel>
      <SlidingPanelsContainer>
        <NotesWrapper $isHidden={isMenuOpen}>
          {renderNotesBelt()}
        </NotesWrapper>

        <ActionsMenu
          isOpen={isMenuOpen}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          showWorkoutDetails={showWorkoutDetails}
          onExit={onExit}
          logActivityHistoryClicked={logActivityHistoryClicked}
          onChatButtonClicked={onChatButtonClicked}
        />
      </SlidingPanelsContainer>
      <PlayPauseButtonContainer>
        <PlayPauseButton
          onClick={onPlayPause}
          isPlaying={!isActivityWorkoutPaused}
        >
          {!isActivityWorkoutPaused ? <PauseIcon /> : <PlayIcon />}
        </PlayPauseButton>
      </PlayPauseButtonContainer>
    </StyledActionsPanel>
  );
};

ActionsPanel.propTypes = {
  showWorkoutDetails: PropTypes.func.isRequired,
  onExit: PropTypes.func.isRequired,
  logActivityHistoryClicked: PropTypes.func.isRequired,
};

export default compose(
  observer,
)(ActionsPanel);
