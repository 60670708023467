import React, {
  useCallback,
  useContext,
  useState,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { IonAlert } from '@ionic/react';

import { Section } from '../../../../../../components/WorkoutInfoSwitcher';
import FirebaseContext from '../../../../../../context/FirebaseContext';
import FeedbackPanel from '../FeedbackPanel';
import GameplayContext from '../../../../context/GameplayContext';
import GameplayMenuContext from '../../../../context/GameplayMenuContext';
import ActivityContext from '../../../../context/ActivityContext';
import ActionsPanel from './ActionsPanel';
import texts from './texts.json';

const FEEDBACK_ACTIVITY_INDEX = 1;

const WorkoutActionsContainer = () => {
  const {
    onWorkoutPaused,
    workoutExecutor,
    workoutExecutor: {
      currentActivityId,
      currentActivityCircuits,
      activitiesExecutor: {
        currentActivityIndex,
      },
    },
  } = useContext(GameplayContext);

  const { onGameplayMenuOpen } = useContext(GameplayMenuContext);

  const {
    activityExecutor: {
      quit: quitCurrentActivity,
      logActivityEvent,
    },
  } = useContext(ActivityContext);

  const { firebase: { remote } } = useContext(FirebaseContext);

  const [showExitAlert, setShowExitAlert] = useState(false);
  const [showFeedbackPanel, setShowFeedbackPanel] = useState(false);

  const onExit = useCallback(async () => {
    logActivityEvent('exitAlertQuitOptionClicked');
    setShowExitAlert(false);

    // We show the feedback panel if the user quits the workout after completing a couple of activities.
    if (currentActivityIndex > FEEDBACK_ACTIVITY_INDEX) {
      setShowFeedbackPanel(true);
    } else {
      quitCurrentActivity();
    }
  }, [
    quitCurrentActivity,
    logActivityEvent,
    currentActivityIndex,
  ]);

  /**
   * callback to execute when the user has sent feedback after quitting the workout.
   */
  const onFeedbackDone = useCallback(() => {
    quitCurrentActivity();
  }, [
    quitCurrentActivity,
  ]);

  const onForceFinishWorkout = useCallback(async () => {
    logActivityEvent('forceFinishWorkoutClicked');
    setShowExitAlert(false);

    workoutExecutor.finish(true, remote);
  }, [
    logActivityEvent,
    workoutExecutor,
    remote,
  ]);

  const showWorkoutDetails = useCallback(() => {
    logActivityEvent('showWorkoutDetails');
    onGameplayMenuOpen();
  }, [
    logActivityEvent,
    onGameplayMenuOpen,
  ]);

  const logActivityHistoryClicked = useCallback(() => {
    logActivityEvent('logActivityHistoryClicked');
    onWorkoutPaused();
    onGameplayMenuOpen({
      sectionToOpen: Section.ACTIVITY_LOG,
    });
  }, [
    onWorkoutPaused,
    logActivityEvent,
    onGameplayMenuOpen,
  ]);

  const onExitAttempt = useCallback(() => {
    onWorkoutPaused();
    setShowExitAlert(true);
    logActivityEvent('Workout exit attempt', {
      currentActivityId,
      currentActivityCircuits,
    });
  }, [
    logActivityEvent,
    onWorkoutPaused,
    currentActivityId,
    currentActivityCircuits,
  ]);

  const onExitAlertDismissed = useCallback(() => {
    logActivityEvent('exitAlertDismissed');
    setShowExitAlert(false);
  }, [logActivityEvent]);

  return (
    <>
      <IonAlert
        isOpen={showExitAlert}
        header={texts.workoutExitTitle}
        message={texts.exitWarning}
        buttons={[
          {
            text: texts.finishExitAlertAction,
            handler: onForceFinishWorkout,
          },
          {
            text: texts.acceptExitAlertAction,
            handler: onExit,
          },
          {
            text: texts.cancelExitAlertAction,
            role: 'cancel',
            handler: onExitAlertDismissed,
          },
        ]}
      />
      <FeedbackPanel
        isOpen={showFeedbackPanel}
        onDoneClick={onFeedbackDone}
        onCloseButtonClick={onFeedbackDone}
        hideCongratulationsMessage
      />
      <ActionsPanel
        showWorkoutDetails={showWorkoutDetails}
        onExit={onExitAttempt}
        logActivityHistoryClicked={logActivityHistoryClicked}
      />
    </>
  );
};

export default compose(
  observer,
)(WorkoutActionsContainer);
