import styled, { css, keyframes } from 'styled-components';

import { ReactComponent as NextIcon } from '../../../../../assets/icons/next-icon.svg';

import {
  IconContainer as BaseIconContainer,
} from '../styles';

const blinkAnimation = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`;

const iconContainerStyles = ({ shouldBlink }) => (
  shouldBlink
    ? css`animation: ${blinkAnimation} 0.5s linear infinite;`
    : ''
);

const IconContainer = styled(BaseIconContainer)`
  background: ${({ theme }) => theme.colors.system.success};
  box-shadow: 0 0 0 10px rgba(153, 237, 100, 0.3);
  ${(props) => iconContainerStyles(props)}
`;

const StyledNextIcon = styled(NextIcon)`
  margin-left: 4px;
  path {
    stroke: ${({ theme }) => theme.colors.system.beta};
  }
`;

export {
  IconContainer,
  StyledNextIcon,
};
