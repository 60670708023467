import React from 'react';
import { components } from 'react-select';
import PropTypes from 'prop-types';

import {
  CustomPlaceholder,
} from './styles';

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
  const {
    selectProps,
    hasValue,
    isDisabled,
  } = props;

  return (
    <ValueContainer {...props}>
      <CustomPlaceholder
        $hasValue={hasValue}
        $isDisabled={isDisabled}
      >
        {selectProps.placeholder}
      </CustomPlaceholder>
      {children.map((child) => (
        child && child.type !== Placeholder ? child : null
      ))}
    </ValueContainer>
  );
};

CustomValueContainer.propTypes = {
  children: PropTypes.node.isRequired,
  isFocused: PropTypes.bool,
  hasValue: PropTypes.bool,
  isDisabled: PropTypes.bool,
  selectProps: PropTypes.object,
};

CustomValueContainer.defaultProps = {
  selectProps: {},
  isFocused: false,
  hasValue: false,
  isDisabled: false,
};

export default CustomValueContainer;
