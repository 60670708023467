import styled from 'styled-components';

const TermsContainer = styled.div`
  height: 30vh;
  ::-webkit-scrollbar {
    width: 7px;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.system.background};
    box-shadow: ${({ theme }) => theme.colors.shades.secondary20} 0 0 1px;
  }
`;

export {
  TermsContainer,
};
