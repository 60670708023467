import React from 'react';
import { IonAlert } from '@ionic/react';
import PropTypes from 'prop-types';

import {
  editionSucceeded,
  editionFailed,
  actionButton,
} from './text.json';

const EditionResultAlert = ({
  isOpen,
  state,
  resetState,
}) => {
  const message = state.isSuccess
    ? editionSucceeded
    : editionFailed;

  return (
    <IonAlert
      isOpen={isOpen}
      message={message}
      backdropDismiss={false}
      buttons={
        [
          {
            text: actionButton,
            handler: resetState,
          },
        ]
      }
    />
  );
};

EditionResultAlert.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  state: PropTypes.shape(
    {
      isSuccess: PropTypes.bool,
    },
  ).isRequired,
  resetState: PropTypes.func.isRequired,
};

export default EditionResultAlert;
