import {
  decorate, observable, action, computed,
} from 'mobx';
import { Collection } from 'firestorter';
import format from 'string-template';

import GameplaySession, { GameplayStatuses } from '../../models/GameplaySession';
import { ActivityStatuses } from '../../models/BaseActivity';

import {
  pathPlaceholder,
  firestorePaths,
  storagePaths,
} from '../../utils/firebasePaths';

class GameplayStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.userId = null;
    this.coachId = null;
    this.workoutAssignmentId = null;
    this.isNewGameplaySession = true;
    this.gameplaySessionId = null;
    this.isStoreWorkoutFramesEnabled = false;
    this.isCameraEnabled = true;
    this.isVideoReviewRequested = false;
  }

  /**
   * Configures the store so that it can be used with a new
   * workout.
   * @param {string} userId User ID.
   * @param {string} coachId Coach ID.
   * @param {string} workoutAssignmentId Workout Assignment ID.
   * @param {Array} activities An array of activities (execution format).
   * @param {Object} workoutExecutor A reference to current workout executor.
   * @param {boolean=} isStoreWorkoutFramesEnabled A boolean indicating whether storing workout frames is enabled.
   * @param {boolean=} isCameraEnabled  A boolean flag to indicate if the camera was enabled by the user.
   * @param {boolean=} isVideoReviewRequested A boolean flag to indicate if the video review is required.
   */
  setupStore(
    userId,
    coachId,
    workoutAssignmentId,
    workoutExecutor,
    isStoreWorkoutFramesEnabled,
    isCameraEnabled,
    isVideoReviewRequested,
  ) {
    this.userId = userId;
    this.coachId = coachId;
    this.workoutAssignmentId = workoutAssignmentId;
    this.workoutExecutor = workoutExecutor;
    this.isNewGameplaySession = true;
    this.isStoreWorkoutFramesEnabled = !!isStoreWorkoutFramesEnabled;
    this.isCameraEnabled = !!isCameraEnabled;
    this.isVideoReviewRequested = !!isVideoReviewRequested;
  }

  /**
   * Starts a new workout by creating a new gameplay session document.
   * It also internally stores the gameplaySessionId for further usage.
   * @param {Array} activities The initial activities execution array
   * @param {string} gameplaySessionId The gameplay session id to be used for workout.
   */
  startNewWorkout = () => {
    const { initialGameplaySessionDoc } = this.workoutExecutor;

    this.isNewGameplaySession = !initialGameplaySessionDoc;

    if (initialGameplaySessionDoc) {
      this.gameplaySessionId = initialGameplaySessionDoc.id;

      /*
        Set the flag only if the client turns off the camera. This will be used to notify coaches about potential
        missing video parts. For example, the user can start the workout with the camera on, and then turn it off for
        the rest of the workout.
      */
      if (!this.isCameraEnabled) {
        initialGameplaySessionDoc.setCameraEnabled(this.isCameraEnabled);
      }
    } else {
      const gameplaySessionDoc = this.rootStore
        .firebase
        .firestore
        .collection(firestorePaths.GAMEPLAY_SESSION_COLLECTION)
        .doc();

      gameplaySessionDoc.set({
        user: this.userId,
        coach: this.coachId,
        workoutAssignment: this.workoutAssignmentId,
        startTime: Date.now(),
        activities: this.workoutExecutor.jsonActivities,
        status: GameplayStatuses.ASSIGNED,
        isCameraEnabled: this.isCameraEnabled,
      });

      this.gameplaySessionId = gameplaySessionDoc.id;
    }
  }

  /**
   * Returns a GameplaySession doc based on current gameplaySessionId
   * @returns {Object} The gameplay session doc
   */
  getGameplaySessionDoc = () => {
    const gameplaySessionDoc = new GameplaySession(format(firestorePaths.GAMEPLAY_SESSION_DOC, {
      [pathPlaceholder.GAMEPLAY_SESSION_ID]: this.gameplaySessionId,
    }));
    return gameplaySessionDoc;
  }

  /**
   * Adds a new activity session to the execution array of the gameplay session.
   */
  addNewActivitySession = async (activitySession) => {
    const currentActivity = this.workoutExecutor.currentExecutableActivity;
    currentActivity.sessions = currentActivity.sessions || [];
    currentActivity.sessions.push(activitySession.path);

    const getGameplaySessionDoc = this.getGameplaySessionDoc();

    getGameplaySessionDoc.ref.update({
      activities: this.workoutExecutor.jsonActivities,
    });
  }

  /**
   * Stars a new Activity Session
   */
  startNewActivity = async () => {
    this.firstIncompleteActivityWorkout = {
      startTime: Date.now(),
      activityStartTime: 0,
      latestWorkoutResult: null,
    };

    const activitiySessionCollectionPath = format(firestorePaths.ACTIVITY_SESSION_COLLECTION, {
      [pathPlaceholder.GAMEPLAY_SESSION_ID]: this.gameplaySessionId,
    });

    this.activitySession = this.rootStore.firebase.firestore.collection(activitiySessionCollectionPath).doc();

    this.activitySession.set({
      complete: false,
      startTime: this.firstIncompleteActivityWorkout.startTime,
    });

    if (this.isStoreWorkoutFramesEnabled) {
      // Only create the WorkoutFrames collection when it's enabled
      this.workoutFrames = new Collection(format(firestorePaths.WORKOUT_FRAMES_COLLECTION, {
        [pathPlaceholder.GAMEPLAY_SESSION_ID]: this.gameplaySessionId,
        [pathPlaceholder.ACTIVITY_SESSION_ID]: this.activitySession.id,
      }));
    }

    this.addNewActivitySession(this.activitySession);
  };

  /**
   * Saves the current activity workout to the firestore collection and returns the id
   * of the saved document.
   * @param {Object} workoutValue
   * @param {boolean} complete
   * @param {Object} options
   * @param {boolean} options.isQuitting
   * @param {boolean} options.shouldSaveSession
   * @return {string} activitySession ID.
   */
  async saveToFirebase(workoutValue, complete, options) {
    const { isQuitting, shouldSaveSession } = options;
    const { startTime, activityStartTime } = this.firstIncompleteActivityWorkout;

    if (shouldSaveSession) {
      const sessionData = {
        ...workoutValue,
        complete,
        startTime,
        activityStartTime,
        endTime: Date.now(),
      };

      if (this.activitySession) {
        this.activitySession.update(sessionData);
      }
    }

    if (!isQuitting) {
      /*
        Update the activity in the gameplay activities execution array
        only if we are not quitting. There's nothing useful to update there
        as it should be kept in an ASSIGNED state for further resume
        functionality.
      */
      const currentActivity = this.workoutExecutor.currentExecutableActivity;

      /*
        Mark as completed or not in the corresponding activity in the execution array.
      */
      if (complete) {
        currentActivity.status = ActivityStatuses.COMPLETED;
        currentActivity.completedBy = this.activitySession.path;
      } else {
        currentActivity.status = ActivityStatuses.SKIPPED;
      }

      const gameplaySessionDoc = this.getGameplaySessionDoc();

      gameplaySessionDoc.ref.update({
        activities: this.workoutExecutor.jsonActivities,
      });
    }

    return this.activitySession.id;
  }

  /**
   * Finishes a workout by updating its end timestamp and also marking as completed
   * if the related activities are completed.
   * @param {boolean} force indicated that the workout was forced to finish
   * @returns {Object} The current gameplay session doc
   */
  finishWorkout = async (force = false) => {
    const gameplaySessionDoc = this.getGameplaySessionDoc();
    /*
      We need to init so we make sure that we're getting the latest data associated to
      activities as they're used to calculate the final state of the workout.
    */
    this.workoutExecutor.logWorkoutEvent('workoutEndInit');
    await gameplaySessionDoc.init();
    this.workoutExecutor.logWorkoutEvent('workoutEndInitFinished');

    // Finish
    gameplaySessionDoc.finish(force);

    // Force a re-init to that we get the latest changes made during finish.
    this.workoutExecutor.logWorkoutEvent('workoutEndReInit');
    await gameplaySessionDoc.init();
    this.workoutExecutor.logWorkoutEvent('workoutEndReInitFinished');

    return gameplaySessionDoc;
  }

  /**
   * Quits a workout by requesting to process the video
   * @returns {Object} The current gameplay session doc
   */
  quitWorkout = async () => {
    const gameplaySessionDoc = this.getGameplaySessionDoc();
    this.workoutExecutor.logWorkoutEvent('workoutQuitInit');
    await gameplaySessionDoc.init();
    this.workoutExecutor.logWorkoutEvent('workoutQuitInitFinished');
    return gameplaySessionDoc;
  }

  setActivityStartTime() {
    this.firstIncompleteActivityWorkout = {
      ...this.firstIncompleteActivityWorkout,
      activityStartTime: 0,
      latestWorkoutResult: null,
    };
  }

  setFirstIncompleteActivityWorkoutStartTime(t) {
    this.firstIncompleteActivityWorkout.activityStartTime = t;
  }

  get firstIncompleteActivityWorkoutStartTime() {
    return this.firstIncompleteActivityWorkout.activityStartTime;
  }

  /**
   *
   * @param {Object} workoutResult A workout result.
   * @param {number} workoutResult.time The date in which the workout result was created.
   * @param {Object} workoutResult.image The image associated with the step/pose.
   * @param {number} workoutResult.fps FPS value.
   * @param {Object=} workoutResult.gameplayActionProcessResult The result of gameplay actions tracking.
   * @param {Object=} workoutResult.predictionModelResult Instance of FlowHuman or Hand.
   * @param {number=} workoutResult.workoutTime Workout value in time (ms).
   * @param {number=} workoutResult.workoutRepetitions Workout value in repetitions.
   */
  addToWorkoutHistory(workoutResult) {
    if (!this.isStoreWorkoutFramesEnabled) {
      return;
    }

    const {
      image,
      ...workoutHistory
    } = workoutResult;

    // Just keep the last workout history frame
    this.firstIncompleteActivityWorkout.latestWorkoutResult = workoutHistory;

    if (image) {
      // Do not hold references to the blob object
      workoutResult.image = undefined; // eslint-disable-line no-param-reassign
    }

    const { gameplayActionProcessResult, predictionModelResult } = workoutHistory;

    const workoutFrame = {
      ...workoutHistory,
      predictionModelResult: predictionModelResult ? { ...predictionModelResult } : null,
      gameplayActionProcessResult: gameplayActionProcessResult ? {
        ...gameplayActionProcessResult,
        stepResult: gameplayActionProcessResult.stepResult ? gameplayActionProcessResult.stepResult.toJSON() : null,
      } : null,
    };

    if (image) {
      const filename = format(storagePaths.WORKOUT_HISTORY_FRAME, {
        [pathPlaceholder.USER_ID]: this.userId,
        [pathPlaceholder.GAMEPLAY_SESSION_ID]: this.gameplaySessionId,
        [pathPlaceholder.ACTIVITY_SESSION_ID]: this.activitySession.id,
        [pathPlaceholder.WORKOUT_HISTORY_FRAME]: workoutResult.time,
      });

      const storageRef = this.rootStore.firebase.storage.ref().child(filename);
      const imageRef = storageRef.fullPath;

      // Add the imageRef to the workout frame
      workoutFrame.imageRef = imageRef;

      /*
        Put the Blob object, not need to create the blob URL.
        If somehow we end up requiring a blob URL, do not forget to call URL.revokeObjectURL(blobURL)
        once it has been used.
      */
      storageRef.put(image);
    }

    this.workoutFrames.add(workoutFrame);
  }

  /**
   * Get the latest workout result data. When workout frames collection is enabled, this returns the latest
   * workout history data, otherwise null.
   *
   * @returns {Object|null}
   */
  get latestWorkoutResult() {
    return this.firstIncompleteActivityWorkout ? this.firstIncompleteActivityWorkout.latestWorkoutResult : null;
  }

  updateVideoReviewRequestedFlag = (isVideoReviewRequested) => {
    const gameplaySessionDoc = this.getGameplaySessionDoc();
    gameplaySessionDoc.ref.update({
      isVideoReviewRequested,
    });
  }
}

decorate(GameplayStore, {
  addToWorkoutHistory: action,
  firstIncompleteActivityWorkout: observable,
  isNewGameplaySession: observable,
  gameplaySessionId: observable,
  firstIncompleteActivityWorkoutStartTime: computed,
  latestWorkoutResult: computed,
  updateVideoReviewRequestedFlag: action,
});

export default GameplayStore;
