import React, {
  useEffect,
  useContext,
  useState,
} from 'react';
import { compose } from 'recompose';

import {
  withLayout,
  HeaderContext,
} from '../../components/Layout';

import Log from './Log';
import History from './History';

import texts from './texts.json';
import {
  StyledContainer,
  StyledSegment,
  StyledSegmentButton,
} from './styles';

const MeasurementSegment = {
  LOG: 'LOG',
  HISTORY: 'HISTORY',
};

const Measurements = () => {
  const { setTitle } = useContext(HeaderContext);
  const [selectedSegment, setSelectedSegment] = useState(MeasurementSegment.LOG);

  useEffect(() => setTitle(texts.title), [setTitle]);

  const getSegments = () => Object.keys(MeasurementSegment)
    .map((segment) => (
      <StyledSegmentButton value={segment} key={segment}>
        {texts.measurementSegments[segment]}
      </StyledSegmentButton>
    ));

  return (
    <StyledContainer>
      <StyledSegment
        value={selectedSegment}
        onIonChange={(e) => setSelectedSegment(e.detail.value)}
        mode="ios"
      >
        {getSegments()}
      </StyledSegment>
      {selectedSegment === MeasurementSegment.LOG && <Log />}
      {selectedSegment === MeasurementSegment.HISTORY && <History />}
    </StyledContainer>
  );
};

export default compose(
  withLayout,
)(Measurements);
