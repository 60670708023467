import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import BaseActivity, { ActivityTypes } from '../../../../models/BaseActivity';

import ActivityContext from '../ActivityContext';
import GameplayContext from '../GameplayContext';

import WorkoutExecutionInfoContext from './WorkoutExecutionInfoContext';

const WorkoutExecutionInfoContextProvider = ({
  children,
}) => {
  const {
    workoutExecutor: {
      currentActivityId,
      currentActivityCircuits,
      currentCircuitRound,
      currentCircuitTotalRounds,
      currentExecutableActivity,
      nextActivityId,
      nextActivityCircuits,
      nextActivityCircuitRound,
      nextExecutableActivity,
      nextActivityCircuitTotalRounds,
      currentExercise,
      currentExerciseIndex,
      currentExerciseId,
      currentExerciseCircuits,
      currentExerciseCircuitRound,
      currentExerciseCircuitTotalRounds,
      totalExercises,
    },
  } = useContext(GameplayContext);

  const {
    activityExecutor: {
      remainingWorkoutValue,
      activity: currentExecutorActivity,
    },
  } = useContext(ActivityContext);

  const {
    type: currentActivityType,
    name: currentActivityName,
    goalField: currentActivityGoalType,
    goalValue: currentActivityGoalValue,
    videoUrl: currentActivityVideoUrl,
    videoPreviewUrl: currentActivityVideoPreviewUrl,
    side: currentActivitySide,
  } = currentExecutableActivity;

  const contextValue = useMemo(() => {
    if (currentActivityType === ActivityTypes.REST && nextExecutableActivity) {
      if (nextExecutableActivity.type === ActivityTypes.REST) {
        const {
          name: currentExerciseName,
          videoUrl: currentExerciseVideoUrl,
          videoPreviewUrl: currentExerciseVideoPreviewUrl,
          goalValue: currentExerciseGoalValue,
          goalField: currentExerciseGoalType,
          side: currentExerciseSide,
        } = currentExercise;

        return {
          displayInfo: {
            name: currentExerciseName,
            id: currentExerciseId,
            circuits: currentExerciseCircuits,
            circuitRound: currentExerciseCircuitRound,
            circuitTotalRounds: currentExerciseCircuitTotalRounds,
            videoUrl: currentExerciseVideoUrl,
            videoPreviewUrl: currentExerciseVideoPreviewUrl,
            goalType: currentExerciseGoalType,
            remainingWorkoutValue: currentExerciseGoalValue,
            side: currentExerciseSide,
            currentExerciseIndex,
            totalExercises,
          },
          activity: currentExercise,
          isCurrentActivityRunning: false,
        };
      }

      const {
        name: nextActivityName,
        videoUrl: nextActivityVideoUrl,
        videoPreviewUrl: nextActivityVideoPreviewUrl,
        goalValue: nextActivityGoalValue,
        goalField: nextActivityGoalType,
        side: nextActivitySide,
      } = nextExecutableActivity;
      return {
        displayInfo: {
          name: nextActivityName,
          id: nextActivityId,
          circuits: nextActivityCircuits,
          circuitRound: nextActivityCircuitRound,
          circuitTotalRounds: nextActivityCircuitTotalRounds,
          videoUrl: nextActivityVideoUrl,
          videoPreviewUrl: nextActivityVideoPreviewUrl,
          goalType: nextActivityGoalType,
          remainingWorkoutValue: nextActivityGoalValue,
          side: nextActivitySide,
          currentExerciseIndex,
          totalExercises,
        },
        activity: nextExecutableActivity,
        isCurrentActivityRunning: false,
      };
    }

    return {
      displayInfo: {
        name: currentActivityName,
        id: currentActivityId,
        circuits: currentActivityCircuits,
        circuitRound: currentCircuitRound,
        circuitTotalRounds: currentCircuitTotalRounds,
        videoUrl: currentActivityVideoUrl,
        videoPreviewUrl: currentActivityVideoPreviewUrl,
        goalType: currentActivityGoalType,
        side: currentActivitySide,
        remainingWorkoutValue: currentExecutorActivity !== currentExecutableActivity
          ? currentActivityGoalValue
          : BaseActivity.toDisplayableValue(currentActivityGoalType, remainingWorkoutValue),
        currentExerciseIndex,
        totalExercises,
      },
      activity: currentExecutableActivity,
      isCurrentActivityRunning: true,
    };
  }, [
    currentActivityType,
    currentActivityName,
    currentActivityId,
    currentActivityCircuits,
    currentCircuitTotalRounds,
    nextActivityCircuitTotalRounds,
    currentCircuitRound,
    currentActivityVideoUrl,
    currentActivityVideoPreviewUrl,
    currentActivityGoalType,
    nextExecutableActivity,
    nextActivityId,
    nextActivityCircuits,
    nextActivityCircuitRound,
    remainingWorkoutValue,
    currentExecutableActivity,
    currentActivityGoalValue,
    currentExecutorActivity,
    currentExerciseIndex,
    currentActivitySide,
    currentExercise,
    currentExerciseId,
    currentExerciseCircuits,
    currentExerciseCircuitRound,
    currentExerciseCircuitTotalRounds,
    totalExercises,
  ]);

  return (
    <WorkoutExecutionInfoContext.Provider value={contextValue}>
      {children}
    </WorkoutExecutionInfoContext.Provider>
  );
};

WorkoutExecutionInfoContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(WorkoutExecutionInfoContextProvider);
