const ChannelBuckets = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
  ALL: 'ALL',
};

const ChannelActions = {
  ARCHIVE: 'ARCHIVE',
  UNARCHIVE: 'UNARCHIVE',
  HIDE: 'HIDE',
  SHOW: 'SHOW',
};

export {
  ChannelBuckets,
  ChannelActions,
};
