import styled from 'styled-components';

import { headlineFontStyles, sansSerif } from '../../../../styles/text';
import { ReactComponent as AngleArrow } from '../../../../assets/angle-arrow.svg';

const StyledContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.system.alpha};
  margin-bottom: 16px;
  padding: 24px;
`;

const Title = styled.h1`
  ${headlineFontStyles}
  font-size: 28px;
  line-height: 38px;
  font-weight: 500;
  text-align: left;
  margin: 8px 0;
  width: 100%;
  font-style: normal;
  font-feature-settings: 'liga' off;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  margin-top: 8px;
  border-radius: 10px;
`;

const Text = styled.span`
  ${sansSerif.headlineFontStyles}
  font-size: 16px;
  line-height: 19px;
  flex: 1;
  color: ${({ theme }) => theme.colors.base.secondaryVariant};
`;

const NavigateIcon = styled(AngleArrow)`
  transform: rotate(-90deg);

  > path {
    fill: ${({ theme }) => theme.colors.base.secondaryVariant};
  }
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  justify-self: flex-end;
`;

export {
  StyledContainer,
  Title,
  IconContainer,
  Text,
  ItemContainer,
  NavigateIcon,
};
