import React from 'react';
import PropTypes from 'prop-types';

import { IconContainer } from './styles';
import { getIcon } from './utils';

const PaymentMethodIcon = ({
  type,
  className,
  onClick,
}) => {
  const Icon = getIcon(type);

  return (
    <IconContainer
      onClick={onClick}
      className={className}
    >
      <Icon
        width="100%"
        height="100%"
      />
    </IconContainer>
  );
};

PaymentMethodIcon.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

PaymentMethodIcon.defaultProps = {
  type: '',
  className: '',
  onClick: () => {},
};

export default PaymentMethodIcon;
