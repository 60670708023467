import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { TimeUnit } from '../../utils/time';

import {
  Container,
  StyledInput,
  StyledSelect,
} from './styles';

const DurationInput = ({
  value,
  unitValue,
  onInputChange,
  onUnitChange,
  label,
  name,
  isRequired,
  ...otherProps
}) => {
  const timeUnitOptions = useMemo(() => (
    Object.values(TimeUnit).map((item) => ({
      value: item,
      label: item,
    }))
  ), []);

  return (
    <Container>
      <StyledInput
        type="number"
        value={value}
        onChange={onInputChange}
        label={label}
        name={name}
        required={isRequired}
        {...otherProps}
      />
      <StyledSelect
        label=""
        name=""
        value={unitValue}
        options={timeUnitOptions}
        onChange={onUnitChange}
      />
    </Container>
  );
};

DurationInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  unitValue: PropTypes.object,
  onInputChange: PropTypes.func,
  onUnitChange: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  isRequired: PropTypes.bool,
};

DurationInput.defaultProps = {
  value: '',
  unitValue: { value: TimeUnit.SECONDS, label: TimeUnit.SECONDS },
  onInputChange: () => {},
  onUnitChange: () => {},
  label: '',
  name: '',
  isRequired: false,
};

export default DurationInput;
