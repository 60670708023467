import React, {
  useState,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import useUniqueActivitiesTape from '../../../hooks/useUniqueActivitiesTape';
import WorkoutInfoSwitcherContext from './WorkoutInfoSwitcherContext';

const WorkoutInfoSwitcherContextProvider = ({
  children,
}) => {
  const [currentActivity, setCurrentActivity] = useState({});
  const {
    activitiesTape,
    getPreviousActivity,
    getNextActivity,
  } = useUniqueActivitiesTape();

  /**
   * Load the previous activity in the activitiesTape.
   */
  const loadPreviousActivity = useCallback(() => {
    const prevActivity = getPreviousActivity(currentActivity.name);
    setCurrentActivity(prevActivity);
  }, [
    currentActivity,
    getPreviousActivity,
  ]);

  /**
   * Load the next activity in the activitiesTape.
   */
  const loadNextActivity = useCallback(() => {
    const nextActivity = getNextActivity(currentActivity.name);
    setCurrentActivity(nextActivity);
  }, [
    currentActivity,
    getNextActivity,
  ]);

  // This is the context value we expose with the Provider.
  const contextValue = useMemo(() => ({
    currentActivity,
    setCurrentActivity,

    // Unique activities tape
    activitiesTape,
    loadPreviousActivity,
    loadNextActivity,
  }), [
    currentActivity,
    setCurrentActivity,
    activitiesTape,
    loadPreviousActivity,
    loadNextActivity,
  ]);

  return (
    <WorkoutInfoSwitcherContext.Provider value={contextValue}>
      {children}
    </WorkoutInfoSwitcherContext.Provider>
  );
};

WorkoutInfoSwitcherContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WorkoutInfoSwitcherContextProvider;
