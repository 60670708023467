/* eslint max-len:off */

import React from 'react';
import PropTypes from 'prop-types';

import useAppTheme from '../../../hooks/useAppTheme';

const CrossIcon = ({
  color,
  className,
}) => {
  const { colors } = useAppTheme();
  const fillColor = color || colors.system.alpha;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m3.31846,3.60432c0.40487,-0.39154 1.06132,-0.39154 1.46619,0l7.21536,6.97772l7.2154,-6.97772c0.40489,-0.39154 1.06122,-0.39154 1.46611,0c0.40489,0.39154 0.40489,1.02637 0,1.41792l-7.21526,6.97778l7.21526,6.97782c0.40489,0.39156 0.40489,1.02628 0,1.41784c-0.40489,0.39156 -1.06122,0.39156 -1.46611,0l-7.2154,-6.97768l-7.21536,6.97768c-0.40487,0.39156 -1.06132,0.39156 -1.46619,0c-0.40487,-0.39156 -0.40487,-1.02628 0,-1.41784l7.2153,-6.97782l-7.2153,-6.97778c-0.40487,-0.39154 -0.40487,-1.02637 0,-1.41792z"
        fill={fillColor}
      />
    </svg>
  );
};

CrossIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

CrossIcon.defaultProps = {
  color: '',
  className: '',
};

export default CrossIcon;
