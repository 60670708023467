import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as PencilIcon } from '../../../../../../../assets/icons/pencil-icon.svg';

import {
  StyledActionButton,
  IconContainer,
  Label,
} from './styles';

const LogActivityHistoryButton = ({
  onClick,
  ...otherButtonProps
}) => (
  <StyledActionButton
    onClick={onClick}
    {...otherButtonProps}
  >
    <IconContainer><PencilIcon /></IconContainer>
    <Label>Log</Label>
  </StyledActionButton>
);

LogActivityHistoryButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default LogActivityHistoryButton;
