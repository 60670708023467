import React from 'react';
import styled, { css } from 'styled-components';
import { IonSpinner } from '@ionic/react';

import { body1, body2 } from '../../styles/text';
import Button from '../../components/Button';

const IntermediateText = styled.div`
  ${body2}
  font-size: 12px;
  font-feature-settings: 'liga' off;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.16em;
  padding: 34px 0 29px 0;
`;

const SuccessMessage = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.system.success2};
  text-align: center;
  margin: 14px;
`;

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.system.error};
  text-align: center;
`;

const helpTextBaseCss = css`
  ${body1}
  font-feature-settings: 'liga' off;
  text-align: center;
  letter-spacing: 0.16em;

  @media (min-width: 375px) {
    font-size: 14px;
  }
`;

const HelpText = styled.div`
  ${helpTextBaseCss}
`;

const LinkButton = (props) => <Button {...props} variant="link" />;

const ActionButton = styled(LinkButton)`
  ${helpTextBaseCss}
  width: unset;
  height: unset;
  padding: 0 5px;
  font-weight: normal;
  text-transform: none;
  text-decoration: underline;
`;

const HelpContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150px;
  flex-direction: column;
`;

const StyledSpinner = styled(IonSpinner)`
  width: 80px;
  height: 80px;
`;

const InfoMessage = styled.div`
  font-size: 18px;
  border: 5px solid ${({ theme }) => theme.colors.system.highlight};
  text-align: center;
  padding: 10px;
  margin: 10px;
  color: ${({ theme }) => theme.colors.system.highlight};
`;

const StyledButton = styled(Button)`
  max-width: 150px;
  margin-top: 25px;
`;

const LoginNotice = styled.div`
  ${body2}
  font-size: 14px;
  text-align: center;
  padding: 20px 10px;
`;

const ContactSupport = styled.div`
  ${body1};
  margin-top: 30px;
  font-size: 12px;
  font-feature-settings: 'liga' off;
  text-align: center;
  letter-spacing: 0.16em;
  color: ${({ theme }) => theme.colors.base.primary};
  padding: 0 10px;
  a {
    color: ${({ theme }) => theme.colors.base.primary};
    text-transform: none;
    text-decoration: underline;
  }
`;

export {
  IntermediateText,
  ErrorMessage,
  SuccessMessage,
  HelpText,
  ActionButton,
  HelpContainer,
  Container,
  StyledSpinner,
  InfoMessage,
  StyledButton,
  LoginNotice,
  ContactSupport,
};
