import React, { useContext } from 'react';
import LoadingPage from '../../components/LoadingPage';

import HabitLogContext from './HabitLogContext';
import HabitLogContextProvider from './HabitLogContextProvider';

/**
 * Inserts HabitLogContextProvider in the react tree
 * before adding the given component.
 *
 * @param {Object} Component The component that needs the context provider.
 * @returns {Object} The component to render wrapped with the provider.
 */
const withHabitLogContextProvider = (Component) => (props) => (
  <HabitLogContextProvider>
    <Component {...props} />
  </HabitLogContextProvider>
);

/**
 * Renders the given component when HabitLogContext is ready.
 *
 * @param {Object} Component The component to render.
 * @param {Object} Component The rendered component.
 * @returns {Object} The component to render or the LoadingPage component.
 */
const withHabitLogContextReady = (Component) => (props) => {
  const { isReady } = useContext(HabitLogContext);
  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withHabitLogContextProvider,
  withHabitLogContextReady,
};
