import styled from 'styled-components';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import { commonCardStyles } from '../components/styles';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  padding: 20px 0;
`;

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colors.fonts.primary};
  font-size: 16px;
  margin-top: 20px;
`;

const StyledCard = styled(Card)`
  ${commonCardStyles};
  padding: 20px;
`;

const StyledImage = styled.img`
  width: 100px;
  border: 1px solid ${({ theme }) => theme.colors.system.gamma1};
`;

export {
  Container,
  StyledButton,
  StyledCard,
  StyledImage,
};
