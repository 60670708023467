import format from 'string-template';

import {
  formatSecondsToMinForThreshold,
  TimeFormatType,
} from '../utils/time';
import { Category, Platform } from '../workoutEditor/utils/tags';

const ActivityTypes = {
  REST: 'REST',
  TIMED: 'TIMED',
  REPETITIONS: 'REPETITIONS',
  CIRCUIT: 'CIRCUIT',
};

const ActivityStatuses = {
  COMPLETED: 'COMPLETED',
  SKIPPED: 'SKIPPED',
  ASSIGNED: 'ASSIGNED',
};

const ActivityGoalType = {
  REPETITIONS: 'repetitions',
  DURATION: 'duration',
};

const GOAL_FORMAT_REPETITIONS = 'x{goalValue}';

const getGoalField = (jsonActivity) => {
  if (jsonActivity.goalField) {
    return jsonActivity.goalField;
  }
  return Object.values(ActivityGoalType).find((goalField) => (
    Object.prototype.hasOwnProperty.call(jsonActivity, goalField)
  ));
};

const formatGoal = (goalValue, activityGoalType) => {
  if (activityGoalType === ActivityGoalType.DURATION) {
    return formatSecondsToMinForThreshold(goalValue, TimeFormatType.SHORT_DISPLAY);
  }
  return format(GOAL_FORMAT_REPETITIONS, {
    goalValue,
  });
};

class BaseActivity {
  /**
   * Constructs a new activity out of a JSON activity definition.
   * @param {Object} jsonActivity The activity in JSON format.
   * @param {Boolean} isNew Flag to create new object without any property value,
   * Its will use to create new object for editable workout.
   * For new object we don't have any property to assing so bypass throwing error
   */
  constructor(jsonActivity, isNew = false) {
    const {
      exerciseId,
      type,
      name,
      note,
      description,
      videoUrl,
      videoPreviewUrl,
      videoPreviewThumbnail,
      goalValue,
      restTime,
      side,
      startCountdown,
      path,
      repetitions,
      duration,
      tags,
    } = jsonActivity;

    // Required fields
    this.exerciseId = exerciseId;
    this.type = type;
    /*
      Name is required for all activities except for CIRCUIT activities.
      For CIRCUIT activities we do still want to be able to save correctly to
      firebase and that's why we need to initialize with an empty string,
      otherwise firebase does not considers undefined as a valid value.
    */
    this.name = name || '';
    this.repetitions = repetitions || '';
    this.duration = duration || '';

    this.tags = tags || [];

    // If no name is provided for a rest activity just call it "Rest"
    if (!this.name && this.type === ActivityTypes.REST) {
      this.name = 'Rest';
    }

    if (!this.type) {
      throw new Error('Error loading activity. Missing required field: type');
    }

    if (this.type !== ActivityTypes.CIRCUIT && !this.name && !isNew) {
      throw new Error('Error loading activity. Missing required field: name');
    }

    // Optional fields
    if (note) {
      this.note = note;
    }

    this.description = description || '';

    if (restTime) {
      this.restTime = restTime;
    }

    if (startCountdown) {
      this.startCountdown = startCountdown;
    }

    this.path = path;

    if (this.type !== ActivityTypes.CIRCUIT && !isNew) {
      this.goalField = getGoalField(jsonActivity);
      if (!this.goalField) {
        throw new Error('Error loading activity. Missing required goal field.');
      }
      if (videoUrl) {
        this.videoUrl = videoUrl;
      }
      this.videoPreviewUrl = videoPreviewUrl || '';
      this.videoPreviewThumbnail = videoPreviewThumbnail || '';
      this.side = side || '';
      this.goalValue = goalValue || jsonActivity[this.goalField];
      this.goal = formatGoal(this.goalValue, this.goalField);
      this.side = side || '';
    }
  }

  get isAutoPauseable() {
    return this.type !== ActivityTypes.REST;
  }

  get hasDetailsInfo() {
    return !!(this.videoPreviewUrl || this.videoUrl || this.description);
  }

  // TODO: Update this once the activity replacement feature is released.
  get isS2Activity() {
    return this.tags.some(({ tag, category }) => category === Category.PLATFORM && tag === Platform.S2);
  }

  get isCAActivity() {
    return this.tags.some(({ tag, category }) => category === Category.PLATFORM && tag === Platform.CA);
  }

  /**
   * Transforms a workout value to a displayable value
   * @param {ActivityGoalType} activityGoalField The activity goal field
   * @param {number} workoutValue The workout value to transform
   * @returns {string} A displayable workout value
   */
  static toDisplayableValue(activityGoalField, workoutValue) {
    let displayableValue;
    switch (activityGoalField) {
      case ActivityGoalType.REPETITIONS: {
        displayableValue = workoutValue;
        break;
      }
      case ActivityGoalType.DURATION: {
        displayableValue = Math.round(workoutValue / 1000);
        break;
      }
      default: {
        displayableValue = 0;
        break;
      }
    }
    return displayableValue;
  }

  /**
   * Get a set (unique values) with the names of the activities passed as param.
   * @param {Array} activities The array that contains the Activities to process.
   * @param {boolean} convertToArray Convert the result into an array of unique strings. If this param is false,
   *                                 then an object will be returned instead.
   * @returns {Object|Array} The set of names in an object or array format (based on the convertToArray prop)
   */
  static getActivityNamesSet(activities, convertToArray = true) {
    let activityNames = {};

    activities.forEach((activity) => {
      // If there are more activities to process, execute the same function, but do not convert the result into an array
      if (activity.activities) {
        activityNames = {
          ...activityNames,
          ...BaseActivity.getActivityNamesSet(activity.activities, false),
        };
      }

      // Only TIMED and REPETITIONS activities should be considered.
      if (activity.type === ActivityTypes.TIMED || activity.type === ActivityTypes.REPETITIONS) {
        activityNames[activity.name] = true;
      }
    });

    return convertToArray ? Object.keys(activityNames) : activityNames;
  }
}

export default BaseActivity;
export {
  ActivityTypes,
  ActivityStatuses,
  ActivityGoalType,
};
