import React, { useContext, useCallback, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { ImageDropzone, FileUploadButton } from 'react-file-utils';
import { Capacitor } from '@capacitor/core';
import { v4 as uuidv4 } from 'uuid';
import {
  useChannelStateContext,
  useMessageInputContext,
  ChatAutoComplete,
  EmojiPicker,
  useChannelActionContext,
} from 'stream-chat-react';
import format from 'string-template';
import { useIonPopover } from '@ionic/react';
import { VideoEditor } from '@awesome-cordova-plugins/video-editor';
import {
  Camera,
  PictureSourceType,
  MediaType,
  DestinationType,
} from '@awesome-cordova-plugins/camera';

import ChatContext from '../../context';
import TimeCounter from '../../../components/TimeCounter';
import LoadingOverlay from '../../../components/LoadingOverlay';

import logEvent from '../../../utils/logger';
import { fileURLtoFile } from '../../../utils/dataProcessing';
import { isAndroid, isIOS } from '../../../utils/platform';
import useMediaRecorder from '../../hooks/useMediaRecorder';
import MessagingAttachmentPopover, { AttachmentActions } from '../MessagingAttachmentPopover';
import UploadsPreview from './UploadsPreview';
import {
  MessagingInputContainer,
  StyledSendIcon,
  StyledAttachmentIcon,
  TriggerSuggestion,
  StyledMicIcon,
  StyledStopIcon,
  RecordingView,
  RecordingLabel,
  InputWrapper,
  InputSection,
  SendButton,
  StyledButton,
} from './styles';
import texts from './texts.json';

const MAX_FILE_UPLOAD_SIZE = 100000000; // 100MB

const MessagingInput = () => {
  const {
    acceptedFiles,
    maxNumberOfFiles,
    multipleUploads,
    channel,
  } = useChannelStateContext();

  const { readOnlyMode, isMultiChannelView } = useContext(ChatContext);

  const messageInputProps = useMessageInputContext();

  const { addNotification } = useChannelActionContext();

  const {
    uploadNewFiles,
    numberOfUploads,
    handleSubmit,
    textareaRef,
    suggestionList,
  } = messageInputProps;
  const [isLoading, setIsLoading] = useState(false);

  const onAudioDataAvailable = useCallback((audioFile) => {
    const file = new File(
      [audioFile.data],
      `Voice-Recording-${new Date().toTimeString().split(' ')[0]}.${audioFile.data.type.split('/')[1]}`,
      {
        type: audioFile.data.type,
        lastModified: Date.now(),
      },
    );
    uploadNewFiles([file]);
  }, [uploadNewFiles]);

  const {
    isRecording,
    startRecording,
    stopRecording,
  } = useMediaRecorder(onAudioDataAvailable);

  const onSubmit = (event) => {
    if (isMultiChannelView) {
      const sentSuggestion = suggestionList
        ?.find((suggestion) => suggestion.message.trim() === textareaRef.current.value.trim());
      if (sentSuggestion) {
        logEvent('chatSuggestionSent', {
          suggestionId: sentSuggestion.id,
        });
      }
    }
    handleSubmit(event);
    textareaRef.current.focus();
  };

  /* creating an input change event to trigger the input suggestion component with the
  defined trigger character (#) */
  const triggerSuggestionList = () => {
    textareaRef.current.value = textareaRef.current.value.replace(texts.triggerCharacter, '');
    const nativeTextAreaValueSetter = Object
      .getOwnPropertyDescriptor(window.HTMLTextAreaElement.prototype, 'value')
      .set;
    nativeTextAreaValueSetter.call(textareaRef.current, textareaRef.current.value + texts.triggerCharacter);
    const event = new Event('input', { bubbles: true });
    textareaRef.current.dispatchEvent(event);
    textareaRef.current.focus();
    textareaRef.current.value = '';
    logEvent('chatSuggestionListTriggered');
  };

  const handleFileUpload = useCallback((fileList) => {
    // Stream supports any file type and a maximum size of 20MB
    const validFiles = Object.values(fileList).every((file) => file.size < MAX_FILE_UPLOAD_SIZE);
    if (validFiles) {
      uploadNewFiles(fileList);
    } else {
      const errorMessage = format(texts.notification.message, {
        sizeLimit: MAX_FILE_UPLOAD_SIZE / 1000000, // Bytes to MB
      });
      addNotification(errorMessage, 'error');
    }
  }, [uploadNewFiles, addNotification]);

  const selectFiles = useCallback(async (attachmentActionType) => {
    const mediaType = attachmentActionType === AttachmentActions.PHOTO ? MediaType.PICTURE : MediaType.VIDEO;
    const destinationType = isAndroid() ? DestinationType.FILE_URL : DestinationType.NATIVE_URI;

    const fileUriPath = await Camera.getPicture({
      quality: 50,
      sourceType: PictureSourceType.PHOTOLIBRARY,
      mediaType,
      saveToPhotoAlbum: true,
      destinationType,
    });

    const fileName = uuidv4();
    let fileUri;
    const isVideo = attachmentActionType === AttachmentActions.VIDEO;
    // transcode video only if platform is android and file is a video
    if (isAndroid() && isVideo) {
      setIsLoading(true);
      try {
        const localFilePath = Capacitor.convertFileSrc(fileUriPath);
        fileUri = await VideoEditor.transcodeVideo({
          fileUri: `${localFilePath}`,
          outputFileName: fileName,
          outputFileType: VideoEditor.OutputFileType.MPEG4,
          optimizeForNetworkUse: VideoEditor.OptimizeForNetworkUse.YES,
          width: 640,
          height: 640,
          videoBitrate: 500000,
        });
      } catch (error) {
        Sentry.captureException(error, {
          extra: {
            channel: channel?.data?.name || channel?.data?.id,
          },
        });
      }
      setIsLoading(false);
    }

    const filePath = fileUri || fileUriPath;
    const url = Capacitor.convertFileSrc(filePath);
    // fallback file type to use if type is not retrieved from blob
    const fileType = isVideo ? 'video/mp4' : 'image/jpeg';
    const fileToUpload = await fileURLtoFile(url, fileName, fileType);
    uploadNewFiles([fileToUpload]);
  }, [
    uploadNewFiles,
    channel,
  ]);

  const [openPopover, dismissPopover] = useIonPopover(MessagingAttachmentPopover, {
    handleAttachmentAction: (actionType) => {
      selectFiles(actionType);
      dismissPopover();
    },
  });

  /* If voice message recording starts render the timer along with recording label else
   show the normal text input with attachment and voice record buttons */
  const renderMessageInputComponent = useCallback(() => {
    if (!isRecording) {
      return (
        <InputWrapper>
          <ChatAutoComplete placeholder={texts.chatInputPlaceholder} />
          {isIOS() ? (
            <StyledButton
              id="attachment-action-trigger"
              onClick={() => openPopover({
                trigger: 'attachment-action-trigger',
              })}
            >
              <StyledAttachmentIcon />
            </StyledButton>
          ) : (
            <FileUploadButton handleFiles={handleFileUpload}>
              <StyledAttachmentIcon />
            </FileUploadButton>
          )}
          <StyledMicIcon onClick={startRecording} />
        </InputWrapper>
      );
    }

    return (
      <RecordingView>
        <RecordingLabel>
          {texts.recordingLabel}
          <TimeCounter run />
        </RecordingLabel>
        <StyledStopIcon onClick={stopRecording} />
      </RecordingView>
    );
  }, [isRecording, startRecording, stopRecording, handleFileUpload, openPopover]);

  if (readOnlyMode) {
    return null;
  }

  return (
    <MessagingInputContainer>
      {isMultiChannelView && (
        <TriggerSuggestion
          onClick={triggerSuggestionList}
        >
          {`Hey ${(channel?.data?.name || channel?.data?.id)?.split(' ')[0]}!`}
        </TriggerSuggestion>
      )}
      <InputSection>
        <ImageDropzone
          accept={acceptedFiles}
          handleFiles={handleFileUpload}
          multiple={multipleUploads}
          disabled={(maxNumberOfFiles !== undefined && numberOfUploads >= maxNumberOfFiles)}
        >
          <UploadsPreview {...messageInputProps} />
          {renderMessageInputComponent()}
        </ImageDropzone>
        <SendButton
          role="presentation"
          aria-roledescription="button"
          onClick={onSubmit}
        >
          <StyledSendIcon />
        </SendButton>
        <EmojiPicker {...messageInputProps} />
      </InputSection>
      <LoadingOverlay isLoading={isLoading} />
    </MessagingInputContainer>
  );
};

export default MessagingInput;
