import { Deploy } from 'cordova-plugin-ionic';
import {
  decorate,
  action,
  computed,
  observable,
  runInAction,
} from 'mobx';
import { App } from '@capacitor/app';
import * as Sentry from '@sentry/browser';

import { isNative } from '../../utils/platform';

const UNKNOWN_VERSION = 'unknown';

class AppVersion {
  constructor() {
    const { REACT_APP_VERSION } = process.env;

    this.appPackageVersion = REACT_APP_VERSION;
    this.appVersion = null;
    this.appBuild = null;
    this.liveUpdateBuildId = null;
    this.liveUpdateVersionId = null;
  }

  get versions() {
    return {
      appPackageVersion: this.appPackageVersion,
      appVersion: this.appVersion || UNKNOWN_VERSION,
      appBuild: this.appBuild || UNKNOWN_VERSION,
      liveUpdateBuildId: this.liveUpdateBuildId || UNKNOWN_VERSION,
    };
  }

  get displayVersions() {
    return [
      {
        key: 'av', // (a)pp (v)ersion
        value: this.appVersion,
      },
      {
        key: 'ab', // (a)pp (b)uild
        value: this.appBuild,
      },
      {
        key: 'apv', // (a)pp (p)ackage (v)ersion
        value: this.appPackageVersion,
      },
      {
        key: 'lui', // (l)ive (u)pdate (i)d
        value: this.liveUpdateBuildId,
      }]
      .filter(({ value }) => !!value)
      .map(({ key, value }) => `${key}:${value}`)
      .join('-');
  }

  /**
   * Loads and prepares the app versions.
   */
  load = async () => {
    if (isNative) {
      const deviceInfo = await App.getInfo();

      runInAction(() => {
        this.appVersion = deviceInfo.version;
        this.appBuild = deviceInfo.build;
      });

      try {
        const versionInfo = await Deploy.getCurrentVersion();

        runInAction(() => {
          if (versionInfo) {
            this.liveUpdateBuildId = versionInfo.buildId;
            this.liveUpdateVersionId = versionInfo.versionId;
          }
        });
      } catch (error) {
        Sentry.captureException(error, {
          extra: {
            description: 'Failed to get live update ID version',
          },
        });
      }
    }
  };
}

decorate(AppVersion, {
  appPackageVersion: observable,
  appVersion: observable,
  appBuild: observable,
  liveUpdateBuildId: observable,
  liveUpdateVersionId: observable,
  load: action,
  displayVersions: computed,
  versions: computed,
});

export default AppVersion;
