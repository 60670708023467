import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  TooltipContainer,
  Bubble,
} from './styles';

const Tooltip = ({
  text,
  children,
  activateOnHover,
  className,
}) => {
  const [active, setActive] = useState(false);

  const renderTooltip = () => {
    if (!active) {
      return null;
    }

    return (
      <Bubble className={className}>{text}</Bubble>
    );
  };

  return (
    <TooltipContainer
      onClick={() => setActive(!active)}
      onMouseEnter={() => activateOnHover && setActive(true)}
      onMouseLeave={() => activateOnHover && setActive(false)}
    >
      {children}
      {renderTooltip()}
    </TooltipContainer>
  );
};

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  activateOnHover: PropTypes.bool,
  className: PropTypes.string,
};

Tooltip.defaultProps = {
  activateOnHover: false,
  className: '',
};

export default Tooltip;
