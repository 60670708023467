import React from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';

import { ReactComponent as AddIcon } from '../../../assets/icons/icon_plus.svg';
import { ReactComponent as AddCircuitIcon } from '../../../assets/icons/icon_plus_square.svg';
import { ReactComponent as CopyIcon } from '../../../assets/icons/icon_copy.svg';
import { ReactComponent as InsertIcon } from '../../../assets/icons/icon_paste.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/icon_edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/icon_trash.svg';
import { ReactComponent as MoveUpIcon } from '../../../assets/icons/icon_arrow_up.svg';
import { ReactComponent as MoveDownIcon } from '../../../assets/icons/icon_arrow_down.svg';

import { ActivityActionTypes } from '../../models/ActivityAction';
import ActionButton from '../ActionButton';
import {
  ActionsBarContainer,
} from './styles';
import texts from './texts.json';

const ActivityActionBar = ({
  onActionButtonClick,
  hasAddCircuit,
  isCircuit,
}) => {
  const positionLabel = isCircuit ? texts.circuitPositionlabel : texts.activityPositionlabel;
  const labelSuffix = isCircuit ? texts.circuit : texts.activity;

  return (
    <ActionsBarContainer isCircuit={isCircuit}>
      <ActionButton
        name={texts.buttons.addActivity.name}
        title={format(texts.buttons.addActivity.title, {
          positionLabel,
          labelSuffix,
        })}
        onClick={(e) => {
          onActionButtonClick(e, ActivityActionTypes.ADD);
        }}
      >
        <AddIcon />
      </ActionButton>
      {hasAddCircuit && (
      <ActionButton
        name={texts.buttons.addCircuit.name}
        title={format(texts.buttons.addCircuit.title, { labelSuffix })}
        onClick={(e) => {
          onActionButtonClick(e, ActivityActionTypes.ADD_CIRCUIT);
        }}
      >
        <AddCircuitIcon />
      </ActionButton>
      )}
      <ActionButton
        name={texts.buttons.copy.name}
        title={format(texts.buttons.copy.title, { labelSuffix })}
        onClick={(e) => {
          onActionButtonClick(e, ActivityActionTypes.COPY);
        }}
      >
        <CopyIcon />
      </ActionButton>
      <ActionButton
        name={texts.buttons.paste.name}
        title={format(texts.buttons.paste.title, { labelSuffix })}
        onClick={(e) => {
          onActionButtonClick(e, ActivityActionTypes.PASTE);
        }}
      >
        <InsertIcon />
      </ActionButton>
      <ActionButton
        name={texts.buttons.edit.name}
        title={format(texts.buttons.edit.title, { labelSuffix })}
        onClick={(e) => {
          onActionButtonClick(e, ActivityActionTypes.EDIT);
        }}
      >
        <EditIcon />
      </ActionButton>
      <ActionButton
        name={texts.buttons.delete.name}
        title={format(texts.buttons.delete.title, { labelSuffix })}
        onClick={(e) => {
          onActionButtonClick(e, ActivityActionTypes.DELETE);
        }}
      >
        <DeleteIcon />
      </ActionButton>
      <ActionButton
        name={texts.buttons.moveUp.name}
        title={format(texts.buttons.moveUp.title, { labelSuffix })}
        onClick={(e) => {
          onActionButtonClick(e, ActivityActionTypes.MOVE_UP);
        }}
      >
        <MoveUpIcon />
      </ActionButton>
      <ActionButton
        name={texts.buttons.moveDown.name}
        title={format(texts.buttons.moveDown.title, { labelSuffix })}
        onClick={(e) => {
          onActionButtonClick(e, ActivityActionTypes.MOVE_DOWN);
        }}
      >
        <MoveDownIcon />
      </ActionButton>
    </ActionsBarContainer>
  );
};

ActivityActionBar.propTypes = {
  onActionButtonClick: PropTypes.func.isRequired,
  hasAddCircuit: PropTypes.bool.isRequired,
  isCircuit: PropTypes.bool.isRequired,
};

export default ActivityActionBar;
