import React, {
  forwardRef,
  useContext,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import ContentSwitcherContext from './context';

const ContentSwitcherAPI = forwardRef(({
  children,
}, ref) => {
  const {
    goToSection,
    goToPreviousSection,
    goToNextSection,
    goToSectionByName,
  } = useContext(ContentSwitcherContext);

  useEffect(() => {
    if (ref) {
      // eslint-disable-next-line
      ref.current = {
        goToSection,
        goToPreviousSection,
        goToNextSection,
        goToSectionByName,
      };
    }
  }, [
    ref,
    goToSection,
    goToPreviousSection,
    goToNextSection,
    goToSectionByName,
  ]);

  return (
    <>
      {children}
    </>
  );
});

ContentSwitcherAPI.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContentSwitcherAPI;
