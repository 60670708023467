import React, { useContext } from 'react';

import LoadingPage from '../../components/LoadingPage';
import AppCustomizationContext from './AppCustomizationContext';
import AppCustomizationContextProvider from './AppCustomizationContextProvider';

const withAppCustomizationContextProvider = (Component) => (props) => (
  <AppCustomizationContextProvider>
    <Component {...props} />
  </AppCustomizationContextProvider>
);

const withAppCustomizationContextReady = (Component) => (props) => {
  const { isReady } = useContext(AppCustomizationContext);
  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withAppCustomizationContextProvider,
  withAppCustomizationContextReady,
};
