const CHART_CONFIG = {
  type: 'bar',
  options: {
    responsive: true,
    scales: {
      x: {
        ticks: {
          display: true,
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
        },
        beginAtZero: false,
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        beginAtZero: false,
        ticks: {
          display: true,
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
    },
    plugins: {
      legend: false,
      title: {
        display: false,
      },
    },
  },
};

export default CHART_CONFIG;
