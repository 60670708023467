import styled from 'styled-components';

import LoadingPage from '../LoadingPage';

const LoadingContent = styled(LoadingPage)`
  width: 100%;
  height: 100%;
`;

export default LoadingContent;
