import ActivityDefinition from './ActivityDefinition';

class WorkoutDefinition {
  constructor(workoutData) {
    const {
      name,
      note,
      estimatedDuration,
      activities,
    } = workoutData;
    this.name = name;
    this.note = note;
    this.estimatedDuration = estimatedDuration;
    this._activities = activities || [];
  }

  get activities() {
    return this._activities.map((jsonActivity) => new ActivityDefinition(jsonActivity));
  }
}

export default WorkoutDefinition;
