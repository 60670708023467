import React from 'react';
import PropTypes from 'prop-types';

import {
  HighlightImageContainer,
  HighlightImage,
} from './styles';

const WorkoutPreviewImage = ({ previewImage }) => (
  <HighlightImageContainer>
    <HighlightImage src={previewImage} />
  </HighlightImageContainer>
);

WorkoutPreviewImage.propTypes = {
  previewImage: PropTypes.string.isRequired,
};

export default WorkoutPreviewImage;
