import moment from 'moment';
import momentDurationFormat from 'moment-duration-format';

momentDurationFormat(moment);

const DEFAULT_TIME_DURATION_FORMAT = 'mm’ss”';

const MIN_IN_SECONDS = 60;

const TimeFormatType = {
  TIMER: 'TIMER',
  SHORT_DISPLAY: 'SHORT_DISPLAY',
};

const TimeFormat = {
  SHORT_DISPLAY: 'h[h] m[m] s[s]',
  TIMER: 'h:m:ss',
  HOUR_MINUTE: 'HH:mm',
};

/**
 * This simple function adds zeros to the left, if needed. We prepend two zeros to the number that's passed as param
 * and then we take the last two digits.
 *
 * @example
 *   digits = 7;
 *   padded string = '007';
 *   result: '07';
 *
 * @param {number} digits The digits to add the zeros to.
 * @returns {string} The padded string
 */
const leftZeroPadding = (digits) => `00${digits}`.slice(-2);

/**
 * Parse a time value expressed in milliseconds and convert it to a time string in the following format: hh:mm:ss.
 * The hours portion will not be returned if the converted time is less or equal to 59:59.
 *
 * @param {number} timeInMillis The time in milliseconds
 * @returns {string} The converted time
 */
const parseTimeInMillis = (timeInMillis) => {
  const duration = moment.duration(timeInMillis);
  const seconds = duration.seconds();
  const minutes = duration.minutes();
  const hours = duration.hours();

  let timeString = `${leftZeroPadding(minutes)}:${leftZeroPadding(seconds)}`;

  if (hours) {
    timeString = `${leftZeroPadding(hours)}:${timeString}`;
  }

  return timeString;
};

const formatDuration = (duration, formatType = DEFAULT_TIME_DURATION_FORMAT) => (
  moment.duration(duration, 'seconds').format(formatType)
);

const formatSecondsToMinForThreshold = (
  secondsValue,
  formatType,
  threshold = MIN_IN_SECONDS,
) => {
  if (formatType === TimeFormatType.SHORT_DISPLAY) {
    const stopTrim = secondsValue >= threshold ? 'm' : 's';

    return moment.duration(secondsValue, 'seconds')
      .format(TimeFormat.SHORT_DISPLAY, {
        trim: 'both',
        stopTrim,
      });
  }

  // Timer format type will be used as default
  return secondsValue >= threshold
    ? formatDuration(secondsValue, TimeFormat.TIMER)
    : `:${secondsValue}`;
};

export {
  leftZeroPadding,
  parseTimeInMillis,
  formatDuration,
  TimeFormatType,
  formatSecondsToMinForThreshold,
  TimeFormat,
};
