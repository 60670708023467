import merge from 'lodash.merge';

import calculateCalibrationData from './utils/calibration';
import TrackerTypes from './trackerTypes';
import StepResult from './StepResult';

const defaultOptions = {
  smootheningInterval: 1000,
};

class StaticTracker {
  constructor(activity, options = {}) {
    this.activity = activity;
    this.poseCheckTimes = [];
    this.calibrationData = null;
    this.options = merge({ ...options }, defaultOptions);
  }

  static trackerType = () => TrackerTypes.STATIC;

  get step() {
    return this.activity.step;
  }

  get stepName() {
    return this.step.name;
  }

  /**
   * Check if user is in pose, performs some sanity checks to make sure it's not a false positive,
   * and adds some smoothing to the output.
   *
   * @param {Objet} predictionModelResult prediction model instance.
   * @param {Object=} processingArea
   * @returns {Object} StepResult instance.
   */
  analyzeStep = (predictionModelResult, processingArea) => {
    let inPoseProcessed = false;
    let qualityProcessed = -1;
    let poseDetectionResult;

    if (predictionModelResult) {
      // Check if the user is in pose in the current frame
      poseDetectionResult = this.step.validatorFn(predictionModelResult, processingArea);

      inPoseProcessed = poseDetectionResult.inPose;
      qualityProcessed = poseDetectionResult.quality;
      // NOTE: for now, pass a single predictionModelResult
      this.calibrationData = calculateCalibrationData(this.step.calibration, predictionModelResult);
    } else {
      this.calibrationData = null;
    }

    // Check how many times the user has been in pose in last SMOOTHENING_INTERVAL milisecs
    const newposeCheckTimes = [];
    const timeNow = Date.now();
    const { smootheningInterval } = this.options;
    let inPoseCounter = (inPoseProcessed ? 1 : 0);
    let highestQuality = qualityProcessed;
    this.poseCheckTimes.forEach(([t, ip, qt]) => {
      if ((timeNow - t) < smootheningInterval) {
        newposeCheckTimes.push([t, ip, qt]);
        inPoseCounter += (ip ? 1 : 0);
        highestQuality = (highestQuality > qt ? highestQuality : qt);
      }
    });
    newposeCheckTimes.push([timeNow, inPoseProcessed, qualityProcessed]);
    this.poseCheckTimes = newposeCheckTimes;

    // We optimize for to eleminate false negatives. If any frame is true
    // in SMOOTHENING_INTERVAL, return true
    inPoseProcessed = inPoseCounter > 0;
    // We output the highest quality score from SMOOTHENING_INTERVAL milisecs
    qualityProcessed = highestQuality || -1;

    return new StepResult(
      inPoseProcessed,
      qualityProcessed,
      poseDetectionResult,
    );
  }

  /**
   * Returns the best candidate prediction for the workout, out of a list of all predictions calculated.
   */
  bestPrediction = (predictionModels) => {
    predictionModels.sort((a, b) => {
      // First, compare by number of feedback items.
      try {
        const aRes = this.step.validatorFn(a);
        const bRes = this.step.validatorFn(b);
        // First compare by quality
        if (aRes.quality !== bRes.quality) {
          return bRes.quality - aRes.quality;
        }
        // Then compare by number of visible feedback items.
        if (aRes.displayReasons.length !== bRes.displayReasons.length) {
          return aRes.displayReasons.length - bRes.displayReasons.length;
        }
        // Then compare by number of total feedback items.
        if (aRes.reasons.length !== bRes.reasons.length) {
          return aRes.reasons.length - bRes.reasons.length;
        }
      } catch (e) {
        // console.log('Error detecting pose', this.step.name, ':', e);
      }

      // Otherwise, sort by reverse size of bounding box.
      return b.boundingBoxWidth * b.boundingBoxHeight
        - a.boundingBoxWidth * a.boundingBoxHeight;
    });
    return predictionModels.length > 0 ? predictionModels[0] : null;
  }
}

export default StaticTracker;
