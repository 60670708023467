import React from 'react';
import PropTypes from 'prop-types';

import {
  BadgeContainer,
  BadgeText,
} from './styles';

import texts from './texts.json';

const CardStatusBadge = ({
  isExpired,
}) => (
  <BadgeContainer $isExpired={isExpired}>
    <BadgeText>
      {isExpired ? texts.cardExpired : texts.cardActive}
    </BadgeText>
  </BadgeContainer>
);

CardStatusBadge.propTypes = {
  isExpired: PropTypes.bool.isRequired,
};

export default CardStatusBadge;
