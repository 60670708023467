import React from 'react';
import PropTypes from 'prop-types';

import {
  MenuOption,
} from './styles';

const CustomOption = (props) => {
  const {
    label,
    innerProps: { ref, ...restInnerProps },
  } = props;

  return (
    <MenuOption
      {...restInnerProps}
      ref={ref}
    >
      {label}
    </MenuOption>
  );
};

CustomOption.propTypes = {
  label: PropTypes.string.isRequired,
  innerProps: PropTypes.object,
};

CustomOption.defaultProps = {
  innerProps: {},
};

export default CustomOption;
