import React, {
  useEffect,
  useContext,
  useMemo,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { debounce } from 'throttle-debounce';

import UserContext from '../../../context/UserContext';
import FCMContext from '../../../context/FCMContext';
import WatchContext from '../../../context/WatchContext';
import useHealthData from '../../../hooks/useHealthData';
import { withLayout, HeaderContext } from '../../../components/Layout';
import { TimePicker } from '../components';
import {
  MenuItemContainer,
  StyledToggle,
} from '../components/styles';
import {
  Container,
  SectionContainer,
} from '../styles';
import { SETTING_CHANGE_DEBOUNCE_TIME } from '../utils';

import texts from './texts.json';

const WorkoutPreferences = () => {
  const { setTitle } = useContext(HeaderContext);
  const { userConfigDoc } = useContext(UserContext);
  const { canUseNotifications } = useContext(FCMContext);
  const { isWatchAvailable } = useContext(WatchContext);

  const { userCanUseHealthData } = useHealthData();

  useEffect(() => {
    setTitle(texts.workoutPreferences);
  }, [
    setTitle,
  ]);

  const onAudioConfigChange = useMemo(() => debounce(SETTING_CHANGE_DEBOUNCE_TIME, (newAudioConfigValue) => {
    userConfigDoc.updateAudioCuesConfig(newAudioConfigValue);
  }), [userConfigDoc]);

  const onVNBConfigChange = useMemo(() => debounce(SETTING_CHANGE_DEBOUNCE_TIME, (value) => {
    userConfigDoc.updateVNBActionConfig(value);
  }), [userConfigDoc]);

  const onAlwaysStartWithCameraOffChange = useMemo(() => debounce(SETTING_CHANGE_DEBOUNCE_TIME, (value) => {
    userConfigDoc.updateAlwaysStartWithCameraOffConfig(value);
  }), [userConfigDoc]);

  const onHealthDataConfigChange = useMemo(() => debounce(SETTING_CHANGE_DEBOUNCE_TIME, (value) => {
    userConfigDoc.updateHealthDataEnabledConfig(value);
  }), [userConfigDoc]);

  const onWorkoutNotificationTimeChange = useMemo(() => debounce(SETTING_CHANGE_DEBOUNCE_TIME, (value) => {
    userConfigDoc.updateWorkoutReminderNotificationTime(value);
  }), [userConfigDoc]);

  const onAutoStartRecWorkoutConfigChange = useMemo(() => debounce(SETTING_CHANGE_DEBOUNCE_TIME, (value) => {
    userConfigDoc.updateWatchAutoStartWorkoutRecordingConfig(value);
  }), [userConfigDoc]);

  return (
    <Container>
      <SectionContainer>
        <MenuItemContainer>
          <StyledToggle
            name={texts.settings.audioCues}
            onChange={onAudioConfigChange}
            isChecked={userConfigDoc.areAudioCuesEnabled}
          />
        </MenuItemContainer>
        <MenuItemContainer>
          <StyledToggle
            name={texts.settings.vnb}
            onChange={onVNBConfigChange}
            isChecked={userConfigDoc.isVNBActionEnabled}
          />
        </MenuItemContainer>
        <MenuItemContainer>
          <StyledToggle
            name={texts.settings.cameraOff}
            onChange={onAlwaysStartWithCameraOffChange}
            isChecked={userConfigDoc.isAlwaysStartWithCameraOffEnabled}
          />
        </MenuItemContainer>
        {userCanUseHealthData && (
          <MenuItemContainer>
            <StyledToggle
              name={texts.settings.appleHealth}
              onChange={onHealthDataConfigChange}
              isChecked={userConfigDoc.isHealthDataEnabled}
            />
          </MenuItemContainer>
        )}
        {isWatchAvailable && (
          <MenuItemContainer>
            <StyledToggle
              name={texts.settings.watchAutoStart}
              onChange={onAutoStartRecWorkoutConfigChange}
              isChecked={userConfigDoc.isWatchAutoStartWorkoutRecordingEnabled}
            />
          </MenuItemContainer>
        )}
      </SectionContainer>
      {canUseNotifications && (
        <SectionContainer>
          <MenuItemContainer>
            <TimePicker
              name={texts.settings.workoutReminder}
              onChange={onWorkoutNotificationTimeChange}
              initialValue={userConfigDoc.workoutReminderNotificationTime}
            />
          </MenuItemContainer>
        </SectionContainer>
      )}
    </Container>
  );
};

export default compose(
  withLayout,
  observer,
)(WorkoutPreferences);
