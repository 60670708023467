import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import moment from 'moment';
import { debounce } from 'throttle-debounce';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { Formik } from 'formik';

import SlideDrawer from '../../../../components/SlideDrawer';
import Label from '../../../../components/Label';
import { DateFormat } from '../../../../utils/date';
import HealthDataSyncContext from '../../../../context/HealthDataSyncContext';
import UserContext from '../../../../context/UserContext';
import HabitLogContext from '../../../../context/HabitLogContext';
import useComponentMounted from '../../../../hooks/useComponentMounted';
import HabitLog from '../../../../models/HabitLog';
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/icon-calendar.svg';
import useHealthData from '../../../../hooks/useHealthData';
import {
  healthStats,
  healthInitialValues as initialValues,
} from '../../utils';

import OptionInput from './OptionInput';
import {
  ItemContainer,
  Container,
  IconContainer,
  StatLabel,
  Value,
  QuestionText,
  RecommendationText,
  SliderContainer,
  InputWithUnits,
  InputContainer,
  UnitContainer,
  DateContainer,
  StepCount,
  ValueContainer,
  StyledButton,
} from './styles';
import texts from './texts.json';

const Health = () => {
  const [focusedHealthStat, setFocusedHealthStat] = useState();
  const [inputSliderOpen, setInputSliderOpen] = useState(false);
  const { userCanUseHealthData } = useHealthData();
  const { retrieveDailyStepCount } = useContext(HealthDataSyncContext);
  const { userId: user } = useContext(UserContext);
  const {
    currentHabitLogDoc: habitLogDoc,
    currentSelectedDate: date,
  } = useContext(HabitLogContext);
  const isComponentMountedRef = useComponentMounted();
  const [healthValues, setHealthValues] = useState(initialValues);

  const onHealthItemClickHandle = useCallback((stat) => {
    setFocusedHealthStat(stat);
    if (stat === healthStats.steps.name && !userCanUseHealthData) {
      return;
    }
    setInputSliderOpen(true);
  }, [
    userCanUseHealthData,
  ]);

  useEffect(() => {
    if (isComponentMountedRef.current) {
      if (habitLogDoc) {
        setHealthValues(habitLogDoc);
      } else {
        setHealthValues(initialValues);
      }
    }
  }, [
    habitLogDoc,
    isComponentMountedRef,
  ]);

  const onHealthItemChanged = useMemo(() => debounce(50, async (data) => {
    if (data) {
      if (!habitLogDoc) {
        await HabitLog.addDoc({
          user,
          date: moment.utc(date).startOf('day').toDate(),
          ...data,
        });
      } else {
        await habitLogDoc.update({ ...data });
      }
    }
  }), [
    habitLogDoc,
    date,
    user,
  ]);

  const handleStepCountSync = useCallback(async () => {
    const stepCountList = await retrieveDailyStepCount(date);
    if (stepCountList.length > 0) {
      const currentStepCount = stepCountList[0].value;
      onHealthItemChanged({ steps: Math.round(currentStepCount) });
      setInputSliderOpen(false);
    }
  }, [
    retrieveDailyStepCount,
    onHealthItemChanged,
    date,
  ]);

  const getSliderView = useCallback(() => {
    if (focusedHealthStat === healthStats.waterIntake.name || focusedHealthStat === healthStats.sleep.name) {
      return (
        <>
          <QuestionText>{healthStats[focusedHealthStat].question}</QuestionText>
          <RecommendationText>{healthStats[focusedHealthStat].recommendationText}</RecommendationText>
          <Formik>
            <InputContainer>
              <InputWithUnits
                value={healthValues[focusedHealthStat]}
                type="number"
                inputMode="decimal"
                min="0"
                name={healthStats[focusedHealthStat].name}
                placeholder="0"
                onChange={(e) => onHealthItemChanged({ [healthStats[focusedHealthStat].name]: e.target.value || '' })}
              />
              <UnitContainer>
                {healthStats[focusedHealthStat].units}
              </UnitContainer>
            </InputContainer>
          </Formik>
        </>
      );
    }
    if (focusedHealthStat === healthStats.steps.name) {
      return userCanUseHealthData && (
        <StepCount>
          {healthValues[focusedHealthStat] && (
            <>
              <QuestionText>{healthStats[focusedHealthStat] && healthStats[focusedHealthStat].question}</QuestionText>
              <Value>{healthValues[focusedHealthStat]}</Value>
            </>
          )}
          <StyledButton onClick={handleStepCountSync}>{texts.syncStepCount}</StyledButton>
        </StepCount>
      );
    }
    return (
      <>
        <QuestionText>{healthStats[focusedHealthStat] && healthStats[focusedHealthStat].question}</QuestionText>
        <OptionInput
          type={focusedHealthStat}
          key={focusedHealthStat}
          initialValue={healthValues[focusedHealthStat]}
          onOptionSelected={(value) => onHealthItemChanged({ [focusedHealthStat]: value })}
        />
      </>
    );
  }, [
    focusedHealthStat,
    healthValues,
    onHealthItemChanged,
    userCanUseHealthData,
    handleStepCountSync,
  ]);

  const getHealthStat = () => Object.keys(healthStats).map((stat) => {
    const { label, icon: StatIcon } = healthStats[stat];

    if (!userCanUseHealthData && healthStats.steps.name === stat && !healthValues[healthStats.steps.name]) {
      return null;
    }

    return (
      <ItemContainer onClick={() => onHealthItemClickHandle(stat)} key={stat}>
        <IconContainer>
          <StatIcon />
        </IconContainer>
        <StatLabel>{label}</StatLabel>
        {habitLogDoc && healthValues[stat] && (
          <ValueContainer>
            <Value>{healthValues[stat]}</Value>
            {healthStats[stat].units && (
              <Value>
                {healthStats[stat].units}
              </Value>
            )}
          </ValueContainer>
        )}
      </ItemContainer>
    );
  });

  return (
    <>
      <Container>
        {getHealthStat()}
      </Container>
      <SlideDrawer
        isOpen={inputSliderOpen}
        fitContent
        onClose={() => setInputSliderOpen(false)}
      >
        <DateContainer>
          <Label
            Icon={CalendarIcon}
            text={moment(date).format(DateFormat.LONG_MONTH_WITH_DAY_YEAR)}
          />
        </DateContainer>
        <SliderContainer>
          {getSliderView()}
        </SliderContainer>
      </SlideDrawer>
    </>
  );
};

export default compose(
  observer,
)(Health);
