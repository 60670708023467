import React, {
  useMemo,
  useEffect,
  useState,
  useContext,
} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { IonAlert } from '@ionic/react';
import { observer } from 'mobx-react';
import * as Sentry from '@sentry/browser';

import FirebaseContext from '../../../context/FirebaseContext';
import useSessionStore from '../../../hooks/useSessionStore';
import useCurrentLoggedInUser from '../../../hooks/useCurrentLoggedInUser';
import { getFirebaseVideoUrl } from '../../../utils/video';
import { isIOS } from '../../../utils/platform';
import {
  VideoPlaylistContext,
} from '../../../components/VideoPlaylist';

import WorkoutVideo from './WorkoutVideo';
import WorkoutVideoStatus from './workoutVideoStatus';
import texts from './texts.json';

const WorkoutVideoContainer = ({
  latestGameplaySession,
}) => {
  const [status, setStatus] = useState(WorkoutVideoStatus.PREPARING);
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(false);
  const [showSpinnerOverlay, setShowSpinnerOverlay] = useState(false);
  const [showDeleteVideoAlert, setShowDeleteVideoAlert] = useState(false);

  const sessionStore = useSessionStore();
  const { isCurrentLoggedInUserInPath } = useCurrentLoggedInUser();

  const { firebase } = useContext(FirebaseContext);
  const {
    videos,
    setVideos,
  } = useContext(VideoPlaylistContext);

  const hasVideo = !!(latestGameplaySession && latestGameplaySession.hasWorkoutVideo);
  useEffect(() => {
    let shouldUpdate = true;

    const fetchVideosUrls = async () => {
      const isIOSPlatform = isIOS();
      const sessionVideosPromises = latestGameplaySession.videos
        .map(async (video) => {
          const url = await getFirebaseVideoUrl(firebase, video.videoRef);
          /*
            NOTE: use Media Fragments to show a frame at t=0.05 as a thumbnail/preview on iOS.
            The time t=0.05 is determined by testing, since the suggested time t=0.001 from several
            forums doesn't show any frame on our videos.
          */
          return {
            url: isIOSPlatform ? `${url}#t=0.05` : url,
            archiveId: video.archiveId || url,
            canPlay: true,
          };
        });
      const sessionVideos = await Promise.all(sessionVideosPromises);
      if (shouldUpdate) {
        setVideos(sessionVideos);
      }
    };

    if (hasVideo) {
      fetchVideosUrls();
    } else {
      // Cleanup videoUrl in case it was modified in the GameplaySession document
      setVideos([]);
    }

    return () => {
      shouldUpdate = false;
    };
  }, [
    firebase,
    hasVideo,
    latestGameplaySession,
    setVideos,
  ]);

  const onOpenDeleteVideoAlert = () => {
    setShowDeleteVideoAlert(true);
  };

  const onCancelVideoDeletion = () => {
    setShowDeleteVideoAlert(false);
  };

  const onDeleteVideo = async () => {
    const gameplaySessionId = latestGameplaySession.id;
    setIsDeleteButtonDisabled(true);
    setShowSpinnerOverlay(true);
    setShowDeleteVideoAlert(false);

    try {
      await firebase.remote('deleteWorkoutVideo', {
        gameplaySessionId,
      });
      setVideos([]);
    } catch (error) {
      Sentry.captureException({
        message: `Error while deleting the workout video for the sessionId: ${gameplaySessionId}`,
        error,
      });

      // Enable the delete button again
      setIsDeleteButtonDisabled(false);
    }

    setShowSpinnerOverlay(false);
  };

  const videoDate = useMemo(() => (
    latestGameplaySession && latestGameplaySession.startTime !== 0
      ? moment(latestGameplaySession.startTime).format('ddd MMM D, h.mm A')
      : ''
  ), [latestGameplaySession]);

  const showCameraOffWarning = sessionStore.isCoachOrAdmin
    && !!latestGameplaySession
    && !latestGameplaySession.isCameraEnabled;

  const isVideoInProgressValue = !!(latestGameplaySession && latestGameplaySession.isVideoInProgress);

  useEffect(() => {
    let currentStatus;

    if (isVideoInProgressValue && videos.length === 0) {
      currentStatus = WorkoutVideoStatus.PROCESSING;
    } else if (hasVideo && videos.length === 0) {
      currentStatus = WorkoutVideoStatus.PREPARING;
    } else {
      currentStatus = WorkoutVideoStatus.READY;
    }

    setStatus(currentStatus);
  }, [
    isVideoInProgressValue,
    videos,
    hasVideo,
  ]);

  return (
    <>
      <WorkoutVideo
        videos={videos}
        date={videoDate}
        showDeleteButton={isCurrentLoggedInUserInPath}
        showCameraOffWarning={showCameraOffWarning}
        isDeleteButtonDisabled={isDeleteButtonDisabled}
        showSpinnerOverlay={showSpinnerOverlay}
        status={status}
        onOpenDeleteVideoAlert={onOpenDeleteVideoAlert}
      />
      <IonAlert
        isOpen={showDeleteVideoAlert}
        header={texts.deleteVideoAlert.title}
        message={texts.deleteVideoAlert.message}
        buttons={[
          {
            text: texts.deleteVideoAlert.cancelAction,
            role: 'cancel',
            handler: onCancelVideoDeletion,
          },
          {
            text: texts.deleteVideoAlert.deleteAction,
            handler: onDeleteVideo,
          },
        ]}
      />
    </>
  );
};

WorkoutVideoContainer.propTypes = {
  latestGameplaySession: PropTypes.object.isRequired,
};

export default compose(
  observer,
)(WorkoutVideoContainer);
