import React, {
  useContext,
} from 'react';

import AppCustomizationContext from '../../../context/AppCustomizationContext';
import HeaderLogo from './HeaderLogo';

const HeaderLogoContainer = () => {
  const {
    appIcon,
  } = useContext(AppCustomizationContext);

  return <HeaderLogo logo={appIcon} />;
};

export default HeaderLogoContainer;
