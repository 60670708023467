import React from 'react';

import AppCustomThemeContextProvider from './AppCustomThemeProvider';

const withAppCustomThemeProvider = (Component) => (props) => (
  <AppCustomThemeContextProvider>
    <Component {...props} />
  </AppCustomThemeContextProvider>
);

export default withAppCustomThemeProvider;
