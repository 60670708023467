import styled from 'styled-components';
import { ErrorMessage as FormikErrorMessage } from 'formik';

import { body1 } from '../../styles/text';

const Container = styled.div`
  position: relative;

  textarea {
    ${body1};
    background: ${({ theme }) => theme.colors.system.background};
    border-radius: 0;
    border: none;
    outline: none;
    box-shadow: unset;
    width: 100%;
    line-height: 148%;
    margin-bottom: 25px;

    &::placeholder {
      opacity: 0.4;
    }

    &:focus {
      ${body1};
      line-height: 148%;
      background: ${({ theme }) => theme.colors.system.background};
      border: none;
      outline: none;
      box-shadow: unset;
    }

    &:disabled {
      opacity: 0.3;
    }
  }
`;

const ErrorMessage = styled(FormikErrorMessage)`
  position: absolute;
  bottom: 25px;
  margin-top: 0;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.system.error};
`;

export {
  Container,
  ErrorMessage,
};
