import { useMemo, useContext } from 'react';

import UserContext from '../context/UserContext';
import AppContext from '../context/AppContext';
import useCurrentLoggedInUser from './useCurrentLoggedInUser';

const useHealthData = () => {
  const { isCurrentLoggedInUserInPath } = useCurrentLoggedInUser();
  const {
    userConfigDoc: {
      isHealthDataEnabled,
      isHealthDataExplainerShown,
    },
  } = useContext(UserContext);
  const {
    isHealthAvailable,
  } = useContext(AppContext);

  const userCanUseHealthData = isHealthAvailable && isCurrentLoggedInUserInPath;

  const isHealthEnabled = userCanUseHealthData
    && isHealthDataEnabled
    && isHealthDataExplainerShown; // This cover the case where new permissions might be required by the app.

  return useMemo(() => ({
    userCanUseHealthData,
    isHealthEnabled,
  }), [
    userCanUseHealthData,
    isHealthEnabled,
  ]);
};

export default useHealthData;
