import React from 'react';
import PropTypes from 'prop-types';

import ActivityCard, { SimpleActivityCard } from '../ActivityCard';
import {
  CircuitContainer,
  CircuitHeader,
  CircuitName,
  Rounds,
  CircuitNote,
} from './styles';

const Circuit = ({
  activity,
  circuitTag,
  ActivityExtraContent,
  ExtraContentWidth,
  renderExtraOnHighlightOnly,
  currentActivityId,
  displayReducedData,
}) => {
  const {
    name,
    rounds,
    note,
    activities: circuitActivities,
  } = activity;

  const hasMultipleSets = rounds > 1;
  const showHeader = !!name || hasMultipleSets;
  const enableCircuitMargin = circuitActivities.length > 1;

  return (
    <CircuitContainer $enableCircuitMargin={enableCircuitMargin}>
      {showHeader && (
        <CircuitHeader>
          <CircuitName>{name}</CircuitName>
          {hasMultipleSets && (
            <Rounds>{`${rounds}x Sets`}</Rounds>
          )}
        </CircuitHeader>
      )}
      {!displayReducedData && !!note && <CircuitNote>{note}</CircuitNote>}
      {circuitActivities.map(
        (cActivity, index) => {
          /*
            The tag is a combination of a letter and a number. eg: A1, A2, etc. If the circuit holds only one activity,
            then use the letter only
          */
          const tag = circuitActivities.length === 1 ? `${circuitTag}` : `${circuitTag}${index + 1}`;

          // Show the top connector for every activity except the first one.
          const showTopConnector = index !== 0;

          // Show the bottom connector for every activity except the last one.
          const showBottomConnector = index < circuitActivities.length - 1;

          const isHighlighted = currentActivityId === tag;
          const shouldRenderExtra = renderExtraOnHighlightOnly ? isHighlighted && hasMultipleSets : true;

          // Choose the type of activity card to render
          const ActivityToRender = displayReducedData ? SimpleActivityCard : ActivityCard;

          return (
            <ActivityToRender
              activity={cActivity}
              tag={tag}
              showTopConnector={showTopConnector}
              showBottomConnector={showBottomConnector}
              key={tag}
              ExtraContent={shouldRenderExtra ? ActivityExtraContent : null}
              ExtraContentWidth={ExtraContentWidth}
              isHighlighted={isHighlighted}
            />
          );
        },
      )}
    </CircuitContainer>
  );
};

Circuit.propTypes = {
  activity: PropTypes.object.isRequired,
  circuitTag: PropTypes.string.isRequired,
  ActivityExtraContent: PropTypes.elementType,
  currentActivityId: PropTypes.string,
  displayReducedData: PropTypes.bool,
  ExtraContentWidth: PropTypes.string,
  renderExtraOnHighlightOnly: PropTypes.bool,
};

Circuit.defaultProps = {
  ActivityExtraContent: null,
  currentActivityId: '',
  displayReducedData: false,
  ExtraContentWidth: '70px',
  renderExtraOnHighlightOnly: false,
};

export default Circuit;
