import { ActionTypes } from '../../../gameplayActions';

const defaultActivityConfig = {
  enableGoal: true,
};

const config = {
  activities: {
    [ActionTypes.HAND_ADVANCE_NEXT]: {
      enableGoal: false,
    },
  },
  supportedTypes: [
    ActionTypes.HAND_ADVANCE_NEXT,
  ],
};

export default config;
export {
  defaultActivityConfig,
};
