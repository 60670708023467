import BaseActivity, { ActivityTypes } from './BaseActivity';

class ActivityExecution extends BaseActivity {
  constructor(jsonActivity) {
    super(jsonActivity);

    /*
      In execution format, circuits are composed by an array of rounds.
    */
    if (this.type === ActivityTypes.CIRCUIT) {
      const {
        rounds,
      } = jsonActivity;
      this.rounds = rounds.map((jsonRound) => ({
        ...jsonRound,
        activities: jsonRound
          .activities
          .map((jsonSubActivity) => new ActivityExecution(jsonSubActivity)),
      }));
    } else {
      const {
        sessions,
        status,
        completedBy,
        coachFeedback,
      } = jsonActivity;
      this.sessions = sessions || [];
      this.status = status;
      if (completedBy) {
        this.completedBy = completedBy;
      }
      if (coachFeedback) {
        this.coachFeedback = coachFeedback;
      }
    }
  }
}

export default ActivityExecution;
