import React, { useContext, useEffect } from 'react';
import {
  Chat,
  ChatContext as NativeChatContext,
  ChatView as NewChatView,
} from '@fitmoola/system2-chat';

import UserContext from '../../../context/UserContext';
import LoadingPage from '../../../components/LoadingPage';
import { isNewChatEnabled } from '../../../utils/featureFlags';
import ChatContext from '../../context';
import { StyledIonModal } from '../../components/ChatView/styles';
import ChatView from '../../components/ChatView';
import ChatLoadingView from '../../components/ChatLoadingView';
import useSessionStore from '../../../hooks/useSessionStore';

import MessagingBroadcastView from '../../components/MessagingBroadcastView/MessagingBroadcastView';
import MessagingBroadcastButton from '../../components/MessagingBroadcastButton';
import { CloseButton, BroadcastButtonContainer } from './styles';

const ChatContainer = React.memo(() => {
  const {
    isChatReady,
    isChatModalOpen,
    toggleChatModal,
    isBroadcastOpen,
  } = useContext(ChatContext);
  const { isReady, setActiveUserId, currentChatPage } = useContext(NativeChatContext);
  const { isCoachOrAdmin, isCoachAssistant } = useSessionStore();

  const {
    userId: chatUserId,
  } = useContext(UserContext);

  useEffect(() => {
    // Open channel list directly if user is admin or coach assistant
    if (isChatModalOpen && !isCoachOrAdmin && !isCoachAssistant) {
      setActiveUserId(chatUserId);
    }
  }, [
    isChatModalOpen,
    chatUserId,
    setActiveUserId,
    isCoachOrAdmin,
    isCoachAssistant,
  ]);

  const renderChat = () => {
    if (isNewChatEnabled()) {
      return (
        <>
          { isBroadcastOpen
            ? <MessagingBroadcastView />
            : (
              <>
                {isReady ? <Chat chatView={NewChatView.SidebarView} isMobileView /> : <LoadingPage />}
                <CloseButton onClick={() => toggleChatModal()} />
                {(isCoachOrAdmin && currentChatPage === 'ChannelList') && (
                <BroadcastButtonContainer>
                  <MessagingBroadcastButton />
                </BroadcastButtonContainer>
                )}
              </>
            )}
        </>
      );
    }

    return (
      <>
        {
          isChatReady
            ? <ChatView />
            : <ChatLoadingView />
        }
      </>
    );
  };

  return (
    <StyledIonModal
      isOpen={isChatModalOpen}
      backdropDismiss={false}
      showBackdrop={false}
    >
      {renderChat()}
    </StyledIonModal>
  );
});

export default ChatContainer;
