import styled, { css } from 'styled-components';

const LoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ $fullHeight }) => $fullHeight && css`
    height: 100vh;
  `}
`;

export default LoadingWrapper;
