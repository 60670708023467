import React from 'react';
import PropTypes from 'prop-types';

import Select from '../../Select';
import texts from './texts.json';
import {
  Container,
  TagsContainer,
} from './styles';

const TagFilters = ({
  tags,
  selectedTags,
  handleTagsChange,
  filterOption,
  className,
}) => (
  <Container className={className}>
    <TagsContainer>
      {Object.keys(tags).map((category) => {
        const { options, isDisabled } = tags[category];
        const onChange = (newTags) => handleTagsChange(category, newTags);

        return (
          <Select
            key={category}
            name={category}
            placeholder={texts[category]}
            value={selectedTags[category][0]}
            isClearable
            isMulti={false}
            isDisabled={isDisabled}
            options={options}
            onChange={onChange}
            filterOption={filterOption}
          />
        );
      })}
    </TagsContainer>
  </Container>
);

TagFilters.propTypes = {
  tags: PropTypes.object.isRequired,
  selectedTags: PropTypes.object,
  handleTagsChange: PropTypes.func.isRequired,
  filterOption: PropTypes.func.isRequired,
  className: PropTypes.string,
};

TagFilters.defaultProps = {
  selectedTags: {},
  className: '',
};

export default TagFilters;
