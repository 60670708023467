import styled, { css } from 'styled-components';

import { sansSerif } from '../../styles/text';

const CustomPlaceholder = styled.div`
  ${sansSerif.headlineFontStyles}
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;

  ${({ $isDisabled }) => {
    const color = $isDisabled
      ? 'rgba(20, 34, 47, 0.4)'
      : 'rgba(58, 53, 71, 0.4)';

    return css`
      color: ${color};
    `;
  }}

  ${({ $hasValue }) => (
    !$hasValue && css`
      position: absolute;
      font-size: 16px;
      line-height: 19px;
    `
  )}
`;

const MenuOption = styled.div`
  ${sansSerif.headlineFontStyles}
  font-weight: normal;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.system.beta};
  padding: 8px 16px;

  &:hover {
    background: ${({ theme }) => theme.colors.system.secondary6};
  }

  &:active {
    color: ${({ theme }) => theme.colors.system.alpha};
    background: ${({ theme }) => theme.colors.system.secondary2};
  }
`;

export {
  CustomPlaceholder,
  MenuOption,
};
