import React from 'react';
import WorkoutEditorContextProviderFromUrl from './WorkoutEditorContextProviderFromUrl';

const withWorkoutEditorContextProviderFromUrl = (Component) => (props) => (
  <WorkoutEditorContextProviderFromUrl>
    <Component {...props} />
  </WorkoutEditorContextProviderFromUrl>
);

export default withWorkoutEditorContextProviderFromUrl;
