import styled, { css } from 'styled-components';
import { sansSerif, body1 } from '../../styles/text';

const { headline2 } = sansSerif;

const statStyles = ({ $theme }) => {
  if ($theme.statFontSize) {
    return css`
      font-size: ${$theme.statFontSize};
    `;
  }
  return '';
};

const iconStyles = ({ $theme }) => {
  const width = $theme.iconWidth || '22px';
  return css`
    width: ${width};
  `;
};

const descriptionStyles = ({ $theme }) => {
  const fontSize = $theme.descriptionFontSize || '14px';
  return css`
    font-size: ${fontSize};
  `;
};

const StatIcon = styled.img`
  margin-right: 10px;
`;

const StyledStat = styled.div`
  ${headline2}
  font-weight: bold;
`;

const Description = styled.div`
  ${body1}
  line-height: 21px;
`;

const StatContainer = styled.div`
  display: flex;
  padding: ${({ $theme }) => (
    $theme.padding || '25px 10px 25px 25px'
  )};
  flex-direction: column;
  flex: 1;
  position: relative;
  flex-basis: 50%;

  ${StyledStat} {
    ${(props) => statStyles(props)}
  }

  ${Description} {
    ${(props) => descriptionStyles(props)}
  }

  ${StatIcon} {
    ${(props) => iconStyles(props)}
  }

  /* Horizontal lines between stats */
  &:before {
    content: '';
    position: absolute;
    background-color: ${({ theme }) => theme.colors.system.gamma6};
    width: 100%;
    height: 1px;
    bottom: -2px;
    z-index: 1;
    left: 0;
  }

  /* Vertical lines between stats */
  &:after {
    content: '';
    position: absolute;
    background-color: ${({ theme }) => theme.colors.system.gamma6};
    width: 1px;
    height: 80%;
    right: -1px;
    z-index: 1;
    top: 10%;
  }
`;

const StatLine = styled.div`
  display: flex;
  flex-direction: row;
`;

export {
  StatContainer,
  StatLine,
  StatIcon,
  StyledStat,
  Description,
};
