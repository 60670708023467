import React, {
  useEffect,
  useContext,
  useCallback,
  useState,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import CameraContext from '../../../../context/CameraContext';
import UserContext from '../../../../context/UserContext';
import useAppSettings from '../../../../hooks/useAppSettings';
import useNavContext from '../../../../hooks/useNavContext';
import useAppTheme from '../../../../hooks/useAppTheme';
import logEvent from '../../../../utils/logger';
import { lock, unlock, OrientationTypes } from '../../../../utils/orientation';
import { isIOS as isIOSPlatform } from '../../../../utils/platform';
import GrantCameraAccess from './GrantCameraAccess';

const isIOS = isIOSPlatform();

const GrantCameraAccessContainer = () => {
  const { userConfigDoc } = useContext(UserContext);
  const {
    camera: {
      startCamera,
      stopCamera,
    },
    isCameraPermissionGranted,
    isCameraPermissionDenied,
    setIsCameraGranted,
    skipCameraPermission,
  } = useContext(CameraContext);
  const { goBack } = useNavContext();
  const { openAppSettings } = useAppSettings();
  // User's interactions
  const [isGranted, setIsGranted] = useState(false);
  // Android: first time it will be "denied"
  const [isDenied, setIsDenied] = useState(() => !isIOS);
  const [cameraPreviewElement, setCameraPreviewElement] = useState(null);
  const [shouldCheckPermission, setShouldCheckPermission] = useState(false);
  const [cameraStarted, setCameraStarted] = useState(false);

  const { colors } = useAppTheme();

  useEffect(() => {
    /*
      NOTE: This page runs always in portrait mode. Remove this when:
      - There's a layout definition for landscape mode that makes the camera visible
      - Listen for orientation changes to update the camera native UI, only in iOS for now
    */
    lock(OrientationTypes.PORTRAIT);

    logEvent('CameraAccessPageShown');

    return () => {
      unlock();

      stopCamera();
    };
  }, [
    stopCamera,
  ]);

  useEffect(() => {
    let shouldUpdate = true;
    let timerId;

    /**
     * Camera permission status is checked in a timer when the app requests it due to:
     * - There's no formal API to programmatically request a permission and handle the
     * user response
     * - iOS: No way to get a response of the user selection
     * - Android: an exception thrown (media API: Android and web)
     *
     * We keep a timer that will check the permission status and updated because of the above reasons and:
     * - Android: the app doesnt' reload when changing the App Settings and going back to the foreground
     * (different to iOS that when some App Settings is changed, the app reloads when coming back to foreground).
     *
     * This will be changed once Capacitor releases version 3 or we develop a custom plugin:
     * - Capacitor releases the version 3.x so we can check if Camera API exposes the requestPermission
     * API that other plugins are starting to expose. https://github.com/ionic-team/capacitor/discussions/3273
     * - If there's no suitable implementation for us, that follows the permissions guidelines of both
     * platforms, implement a very basic one for the permissions we need.
     */
    const checkPermission = () => {
      timerId = setInterval(async () => {
        const isPermissionGranted = await isCameraPermissionGranted();
        const isPermissionDenied = await isCameraPermissionDenied();

        if (shouldUpdate) {
          setIsGranted(isPermissionGranted);
          setIsDenied(isPermissionDenied);
        }
      }, 500);
    };
    if (shouldCheckPermission) {
      checkPermission();
    } else {
      clearInterval(timerId);
    }
    return () => {
      clearInterval(timerId);
      shouldUpdate = false;
    };
  }, [
    isCameraPermissionGranted,
    isCameraPermissionDenied,
    shouldCheckPermission,
  ]);

  const initCamera = useCallback(async () => {
    try {
      if (!cameraStarted) {
        setCameraStarted(true);
        await startCamera({
          htmlView: cameraPreviewElement,
          position: 'front',
          fitPreview: false,
          backgroundColor: colors.system.alpha,
        });
      }
    } catch (error) {
      // Users will need to grant camera access by using app settings.
      stopCamera();
      setIsDenied(true);
      setCameraStarted(false);
    }
  }, [
    startCamera,
    stopCamera,
    cameraStarted,
    cameraPreviewElement,
    colors,
  ]);

  useEffect(() => {
    if (isGranted) {
      logEvent('CameraAccessGranted');
      setShouldCheckPermission(false);
      initCamera();
    } else if (isDenied) {
      logEvent('CameraAccessDenied');
    }
  }, [
    isGranted,
    isDenied,
    initCamera,
  ]);

  const onCameraPreviewRefReady = useCallback((previewRef) => {
    setCameraPreviewElement(previewRef.current);
  }, []);

  const onGrantCamera = useCallback(async () => {
    const isPermissionDenied = await isCameraPermissionDenied();

    /*
      iOS: first time or permission not updated yet, it will be "prompt"
      Android: first time or permission not granted, it will be "denied"
     */
    if (isDenied || (isIOS && isPermissionDenied)) {
      // Open app settings
      openAppSettings();
    } else {
      initCamera();
    }

    setShouldCheckPermission(true);
  }, [
    isDenied,
    initCamera,
    isCameraPermissionDenied,
    openAppSettings,
    setShouldCheckPermission,
  ]);

  const onContinue = useCallback(async () => {
    userConfigDoc.grantCameraAccess();
    await stopCamera();
    setIsCameraGranted(isGranted);
  }, [
    userConfigDoc,
    isGranted,
    setIsCameraGranted,
    stopCamera,
  ]);

  /*
    NOTE: `goBack` must be called within a function already defined in order to get back navigation working properly
    on all scenarios. If `goBack` is passed directly via props, it won't work in all scenarios.
  */
  const onClose = useCallback(() => goBack(), [goBack]);

  return (
    <GrantCameraAccess
      isDenied={isDenied}
      isGranted={isGranted}
      onGrantCamera={onGrantCamera}
      onContinue={onContinue}
      onClose={onClose}
      onCameraPreviewRefReady={onCameraPreviewRefReady}
      onContinueWithoutCamera={skipCameraPermission}
    />
  );
};

export default compose(
  observer,
)(GrantCameraAccessContainer);
