import styled from 'styled-components';

import {
  headline2,
} from '../../../../styles/text';

const DynamicTitle = styled.div`
  ${headline2}
  opacity: ${({ isActive }) => (isActive ? 1 : 0.34)};
  color: ${({ theme }) => theme.colors.base.primary};
`;

export default DynamicTitle;
