import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import format from 'string-template';

import CoachInfoContext from '../../../../../../context/CoachInfoContext';
import WorkoutAssignmentFeedback from '../../../../../../models/WorkoutAssignmentFeedback';

import logEvent from '../../../../../../utils/logger';
import { CardDescription } from '../../../../../../components/Card';
import Slider from '../../../../../../components/Slider';
import CollapsiblePanel from '../../../../../../components/CollapsiblePanel';
import WorkoutContext from '../../../../../../context/WorkoutContext';
import GameplayContext from '../../../../context/GameplayContext';

import config, { getCardsOrderedByID } from './config';
import texts from './texts.json';
import {
  StyledFeedbackModal,
  StyledFeedbackPanel,
  HeaderCard,
  StyledCloseButton,
  StyledMedal,
  Title,
  Subtitle,
  StyledCard,
  StyledCardTitle,
  Comments,
  StyledToggle,
  StyledButton,
} from './styles';

/**
 * Get the initial configuration for the selected options. This is defined in the module scope since it is independent
 * of the component itself. It only depends on the config we import.
 *
 * @returns {Object}
 */
const getInitialOptionsSelected = () => {
  // Get all the cards
  const { cards } = config;
  return cards.reduce((opts, { id, initialOptionSelected }) => {
    const newOptions = {
      ...opts,
      [id]: initialOptionSelected, // We select the first element of each options array.
    };
    return newOptions;
  }, {});
};

// Get the cards information by id, so that we can get constant time access to it.
const cardsByIds = getCardsOrderedByID();

const FeedbackPanel = ({
  isOpen,
  onDoneClick,
  onCloseButtonClick,
  showCloseButton,
  hideCongratulationsMessage,
}) => {
  const { coachInfo } = useContext(CoachInfoContext);
  const { workoutAssignmentDoc, workoutDef } = useContext(WorkoutContext);
  const { params: { userId } } = useRouteMatch();

  // Store the selected options in a state variable to keep track of the changes.
  const [selectedOptions, setSelectedOptions] = useState(() => getInitialOptionsSelected());

  // The comments section is stored separately since it is not configured as a slider card.
  const [comments, setComments] = useState('');

  const {
    gameplayStore: {
      isVideoReviewRequested: initialIsVideoReviewRequested,
      updateVideoReviewRequestedFlag,
      isCameraEnabled,
    },
  } = useContext(GameplayContext);

  // By default, videos are not going to be reviewed by the coach, unless the client actively asks for it.
  const [isVideoReviewRequested, setIsVideoReviewRequested] = useState(initialIsVideoReviewRequested);

  /*
    the value for initialIsVideoReviewRequested can change because the gameplaystore is created, and then we could
    potentially load the session doc, and we need to update the value accordingly.
  */
  useEffect(() => {
    setIsVideoReviewRequested(initialIsVideoReviewRequested);
  }, [
    initialIsVideoReviewRequested,
  ]);

  const onOptionSelected = useCallback((id, optionSelected) => {
    const newOptions = {
      ...selectedOptions,
      [id]: optionSelected.index,
    };
    setSelectedOptions(newOptions);
  }, [selectedOptions]);

  /**
   * Render a slider card. Each card will contain a slider configured based on the options available.
   * @param {string} id The id of the card.
   * @param {string} title The title of the card.
   * @param {string[]} sliderOptions An array of strings that correspond to each option rendered in the slider.
   * @param {Object} options
   * @param {boolean} [options.showDynamicLabel] configure the slider to show dynamic labels instead of static ones.
   * @param {boolean} [options.noMargins] control whether the card has margins.
   *
   * @returns {ReactElement}
   */
  const renderCard = useCallback((id, { showDynamicLabel = false, noMargins = false } = {}) => {
    const { title, options, initialOptionSelected } = cardsByIds[id];
    return (
      <StyledCard key={id} noMargins={noMargins}>
        <StyledCardTitle>{title}</StyledCardTitle>
        <CardDescription>
          <Slider
            key={id}
            onOptionSelected={(optionSelected) => onOptionSelected(id, optionSelected)}
            options={options}
            showDynamicLabel={showDynamicLabel}
            initialOptionSelected={initialOptionSelected}
          />
        </CardDescription>
      </StyledCard>
    );
  }, [onOptionSelected]);

  /**
   * Render the main card. This card is not rendered inside the collapsible panel. It is shown at the top.
   */
  const renderMainCard = () => {
    const { cards } = config;

    // The first card in the config list is the main card.
    const { id } = cards[0];
    return renderCard(id, { showDynamicLabel: true, noMargins: true });
  };

  /**
   * Render extra cards. These cards are included inside a callapsible panel.
   */
  const renderExtraCards = useCallback(() => {
    const { cards } = config;
    const extraCards = [];

    cards.forEach((card, index) => {
      // The first index contains the main card info, so we discard it here.
      if (index !== 0) {
        const { id } = card;

        /* We need to remove the margins for the last item in the collapsible panel to avoid pushing the rest of the
          elements further down. */
        const noMargins = index === cards.length - 1;

        extraCards.push(renderCard(id, { noMargins }));
      }
    });

    return extraCards;
  }, [renderCard]);

  /**
   * Render the header content. If the workout is complete, then the medal is shown. Otherwise, only the workout name
   * is rendered as the title.
   */
  const renderHeaderContent = useCallback(() => {
    if (hideCongratulationsMessage) {
      return <Title addMargin>{workoutDef.name}</Title>;
    }

    return (
      <>
        <StyledMedal />
        <Title>{texts.congratulations}</Title>
        <Subtitle>{texts.congratulationsSubtitle}</Subtitle>
        <Subtitle>{workoutDef.name}</Subtitle>
      </>
    );
  }, [workoutDef.name, hideCongratulationsMessage]);

  const onCommentsChange = ({ target: { value } }) => {
    setComments(value);
  };

  const onDone = async () => {
    logEvent('FeedbackDoneButtonClicked');

    // Get the answers for the different feedback cards based on the selectedOptions state variable.
    const feedbackAnswers = Object.entries(selectedOptions).reduce((acc, [id, selectedOptionIndex]) => {
      const { title, options } = cardsByIds[id];

      // The feedback is sent using the questions themselves (title).
      return {
        ...acc,
        [title]: options[selectedOptionIndex],
      };
    }, {});

    const feedback = {
      ...feedbackAnswers,
      Comments: comments,
    };

    // Save the feedback in our db.
    WorkoutAssignmentFeedback.saveFeedback(userId, workoutAssignmentDoc.id, feedback);

    onDoneClick();
  };

  const onCloseClick = () => {
    logEvent('FeedbackPanelCloseButtonClicked');
    onCloseButtonClick();
  };

  const onVideoReviewToggleChange = (toggleValue) => {
    setIsVideoReviewRequested(toggleValue);
    updateVideoReviewRequestedFlag(toggleValue);
  };

  return (
    <StyledFeedbackModal isOpen={isOpen}>
      <StyledFeedbackPanel>
        <HeaderCard>
          {showCloseButton && <StyledCloseButton onClick={onCloseClick} />}
          {renderHeaderContent()}
        </HeaderCard>
        {isCameraEnabled && (
          <StyledToggle
            name={format(texts.videoReviewToggle, { coachName: coachInfo.coachAssistant?.name || coachInfo.name })}
            onChange={onVideoReviewToggleChange}
            isChecked={isVideoReviewRequested}
          />
        )}
        {renderMainCard()}
        <CollapsiblePanel headerAtBottom>
          {renderExtraCards()}
        </CollapsiblePanel>
        <StyledCard noMargins>
          <CardDescription>
            <Comments placeholder={texts.commentsPlaceholder} onChange={onCommentsChange} />
          </CardDescription>
        </StyledCard>
        <StyledButton onClick={onDone}>{texts.buttonText}</StyledButton>
      </StyledFeedbackPanel>
    </StyledFeedbackModal>
  );
};

FeedbackPanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onDoneClick: PropTypes.func,
  onCloseButtonClick: PropTypes.func,
  showCloseButton: PropTypes.bool,
  hideCongratulationsMessage: PropTypes.bool,
};

FeedbackPanel.defaultProps = {
  onDoneClick: () => {},
  onCloseButtonClick: () => {},
  showCloseButton: true,
  hideCongratulationsMessage: false,
};

export default FeedbackPanel;
