import React from 'react';
import PropTypes from 'prop-types';

import {
  HeaderContainer,
  WorkoutDate,
} from '../styles';

const DefaultDayCard = ({
  date,
}) => (
  <HeaderContainer>
    <WorkoutDate>{date}</WorkoutDate>
  </HeaderContainer>
);

DefaultDayCard.propTypes = {
  date: PropTypes.string.isRequired,
};

export default DefaultDayCard;
