import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as EquipmentIcon } from '../../assets/icons/icon_weight.svg';
import { getEquipmentList } from '../../utils/activities';
import Label from '../Label';

import { equipmentLabel } from './text.json';

const EquipmentList = ({
  activityList,
}) => {
  const equipmentTags = getEquipmentList(activityList);

  // Do not render anything if there are no items listed.
  if (equipmentTags.length === 0) {
    return null;
  }

  const textString = `${equipmentLabel}: ${equipmentTags.join(', ')}`;

  return (
    <Label
      text={textString}
      Icon={EquipmentIcon}
    />
  );
};

EquipmentList.propTypes = {
  activityList: PropTypes.array.isRequired,
};

export default EquipmentList;
