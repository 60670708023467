import React, {
  useCallback,
  useContext,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import AppContext from '../../context/AppContext';
import { isNative } from '../../utils/platform';
import CameraContext from '../../context/CameraContext';
import logEvent from '../../utils/logger';
import { SelfieVideoContext } from './SelfieVideoContext';
import SelfieVideo from './SelfieVideo';
import TestVideo from './TestVideo/TestVideo';
import {
  Container,
  Loader,
  CameraPreviewWrapper,
} from './styles';

const SelfieVideoContainer = ({
  children,
}) => {
  const {
    isReady,
    debugMode,
  } = useContext(SelfieVideoContext);

  const {
    isCameraEnabled,
  } = useContext(CameraContext);

  const {
    debugSettings: {
      isTestVideoEnabled,
      testVideoName,
      testVideoServerURL,
    },
  } = useContext(AppContext);

  useEffect(() => {
    logEvent('selfieVideoShown');
  }, []);

  const renderPlaceholder = useCallback(() => {
    if (!isReady && isCameraEnabled) {
      return <Loader />;
    }

    return null;
  }, [
    isReady,
    isCameraEnabled,
  ]);

  const renderSelfieComponent = useCallback(() => {
    const hasVideoTest = isTestVideoEnabled && !!testVideoServerURL && !!testVideoName;
    if (hasVideoTest) {
      return <TestVideo />;
    }

    if (isCameraEnabled) {
      return <SelfieVideo />;
    }
    return null;
  }, [
    isTestVideoEnabled,
    testVideoName,
    testVideoServerURL,
    isCameraEnabled,
  ]);

  const cameraPreviewFullHeight = isNative && !debugMode;
  const transparentBackground = (isNative && isReady) || false;

  return (
    <Container
      transparentBackground={transparentBackground}
    >
      {renderPlaceholder()}
      <CameraPreviewWrapper
        fullHeight={cameraPreviewFullHeight}
      >
        {renderSelfieComponent()}
        {children}
      </CameraPreviewWrapper>
    </Container>
  );
};

SelfieVideoContainer.propTypes = {
  children: PropTypes.node,
};

SelfieVideoContainer.defaultProps = {
  children: [],
};

export default SelfieVideoContainer;
