import { IonSegment, IonSegmentButton } from '@ionic/react';
import styled from 'styled-components';

import Button from '../../components/Button';

import { sansSerif } from '../../styles/text';

const Container = styled.div`
  height: 100%;
  position: sticky;
  overflow: hidden;
`;

const StyledContainer = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const AddButton = styled(Button)`
  font-size: 18px;
  line-height: 22px;
  background-color: ${({ theme }) => theme.colors.base.tertiary};
`;

const GraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.system.alpha};
  margin-bottom: 16px;
`;

const GraphPlaceholderContainer = styled.div`
  padding: 75px 50px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GraphPlaceholderMessage = styled.div`
  ${sansSerif.headlineFontStyles}
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: ${({ theme }) => theme.colors.base.primary};
  margin-bottom: 25px;
`;

const StyledSegment = styled(IonSegment)`
  height: 56px;
  --background: white;
`;

const StyledSegmentButton = styled(IonSegmentButton)`
  font-size: 16px;
  --color-checked: ${({ theme }) => theme.colors.system.beta};
  --color: ${({ theme }) => theme.colors.system.gamma2};
`;

export {
  Container,
  GraphContainer,
  AddButton,
  GraphPlaceholderContainer,
  GraphPlaceholderMessage,
  StyledSegment,
  StyledSegmentButton,
  StyledContainer,
};
