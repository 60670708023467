import moment from 'moment';
import format from 'string-template';
import * as Sentry from '@sentry/browser';
import { firestorePaths, pathPlaceholder, storagePaths } from '../../utils/firebasePaths';
import TrackedActivity from '../../models/TrackedActivity';
import WorkoutFeedView from '../../models/WorkoutFeedView';

const getStoragePath = (userId, trackedActivityId) => (
  format(storagePaths.TRACKED_ACTIVITY_ATTACHEMENT, {
    [pathPlaceholder.USER_ID]: userId,
    [pathPlaceholder.TRACKED_ACTIVITY_ID]: trackedActivityId,
  })
);

const createWorkoutFeedViewData = (trackedActivityDetail) => {
  const {
    customTitle,
    attachments,
    activityType,
  } = trackedActivityDetail;

  const previewImageLink = attachments && attachments.length !== 0 ? attachments[0] : null;
  const title = customTitle || TrackedActivity.getActivityName(activityType);

  return {
    title,
    previewImageLink,
    lastUpdatedDateTime: moment.utc().toDate(),
  };
};

const persistTrackedActivityDetail = async (trackedActivityDetail, trackedActivityId, firestore) => {
  try {
    const trackedActivityDoc = new TrackedActivity(format(firestorePaths.TRACKED_ACTIVITY_DOC, {
      [pathPlaceholder.TRACKED_ACTIVITY_ID]: trackedActivityId,
    }));
    const workoutFeedViewDoc = new WorkoutFeedView(trackedActivityId);

    await firestore.runTransaction(async (transaction) => {
      transaction.set(trackedActivityDoc.ref, trackedActivityDetail, { merge: true });
      transaction.set(workoutFeedViewDoc.ref, createWorkoutFeedViewData(trackedActivityDetail), { merge: true });
    });
  } catch (error) {
    Sentry.captureException(error, {
      extra: {
        message: 'Error occurred while persisting tracked activity details',
      },
    });
  }
};

export {
  persistTrackedActivityDetail,
  getStoragePath,
};
