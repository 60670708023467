import React from 'react';
import WorkoutSelectorContextProvider from './WorkoutSelectorContextProvider';

const withWorkoutSelectorContextProvider = (Component) => (props) => (
  <WorkoutSelectorContextProvider>
    <Component {...props} />
  </WorkoutSelectorContextProvider>
);

export default withWorkoutSelectorContextProvider;
