import styled from 'styled-components';
import { IonSegment, IonSegmentButton } from '@ionic/react';

const StyledUnitSegment = styled(IonSegment)`
  margin: 24px 0;
  --background: ${({ theme }) => theme.colors.system.gamma};
`;

const StyledUnitSegmentButton = styled(IonSegmentButton)`
  --indicator-color: ${({ theme }) => theme.colors.base.secondaryVariant};
  --color-checked: ${({ theme }) => theme.colors.system.alpha};
`;

export {
  StyledUnitSegment,
  StyledUnitSegmentButton,
};
