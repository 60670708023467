import styled, { css } from 'styled-components';

import TimeCounter from '../../../../../../components/TimeCounter';
import { safeAreaFromRight } from '../../../../../../styles/mixins';
import { sansSerif } from '../../../../../../styles/text';

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  ${sansSerif.headline3};
  font-weight: normal;
  text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
  color: ${({ theme }) => theme.colors.system.alpha};
  text-transform: capitalize;
  margin-top: 10px;
`;

const CircuitInfo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-right: 10px;
`;

const StyledTimeCounter = styled(TimeCounter)`
  /* We need to force this font because Neue Montreal does not include fixed width gliphs for the numbers.*/
  font-family: sans-serif;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.base.secondary};
`;

const highContrastRules = ({ $enableHighContrast, theme }) => {
  if ($enableHighContrast) {
    return css`
      ${InfoRow} {
        text-shadow: none;
        color: ${theme.colors.system.beta};
      }
    `;
  }

  return null;
};

const ProgressInfoPanelContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding: 0 max(${safeAreaFromRight()}, 10px) 0 10px;
  transition: opacity 0.5s;

  ${(props) => highContrastRules(props)}
`;

export {
  ProgressInfoPanelContainer,
  InfoRow,
  CircuitInfo,
  StyledTimeCounter,
};
