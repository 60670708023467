import BaseActivity, { ActivityTypes } from './BaseActivity';

class ActivityDefinition extends BaseActivity {
  /**
   * Constructs a new activity out of a JSON activity definition.
   * @param {Object} jsonActivity The activity in JSON format.
   * @param {Boolean} isNew Flag to create new object without any property value,
   * Its will use to create new object for editable workout.
   */
  constructor(jsonActivity, isNew = false) {
    super(jsonActivity, isNew);

    /*
      In activity definition format, circuit contains an array of related
      activities and rounds is just a number of how many times a circuit is
      supposed to be executed.
    */
    if (this.type === ActivityTypes.CIRCUIT) {
      const {
        activities,
        rounds,
      } = jsonActivity;
      this.activities = activities.map((jsonSubActivity) => new ActivityDefinition(jsonSubActivity));
      this.rounds = rounds;
    }
  }
}

export default ActivityDefinition;
