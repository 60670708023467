import styled from 'styled-components';
import { IonSlides, IonSlide } from '@ionic/react';

import Card from '../../../components/Card';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledCard = styled(Card)`
  flex-direction: column;
`;

const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  overflow: hidden;
  text-align: left;
`;

const StyledSlides = styled(IonSlides)`
  width: 100%;
  padding-bottom: 15px;

  .swiper-pagination-bullets {
    bottom: 0px;
  }
`;

const StyledSlide = styled(IonSlide)`
  width: 100%;
  height: 100%;
`;

const GraphContainer = styled.div`
  margin-top: 15px;
`;

export {
  StyledCard,
  Container,
  InfoContainer,
  StyledSlides,
  StyledSlide,
  GraphContainer,
};
