import React, {
  useState,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

import { Section } from '../../../../components/WorkoutInfoSwitcher';
import GameplayContext from '../GameplayContext';
import ActivityContext from '../ActivityContext';
import GameplayMenuContext from './GameplayMenuContext';

const GameplayMenuContextProvider = ({
  children,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState(Section.WORKOUT_ROUTINE);

  const {
    onWorkoutPaused,
  } = useContext(GameplayContext);

  const {
    activityExecutor: {
      logActivityEvent,
    },
  } = useContext(ActivityContext);

  const onGameplayMenuOpen = useCallback(({
    sectionToOpen,
  } = {}) => {
    logActivityEvent('gameplayMenuOpen');
    onWorkoutPaused();
    setSelectedSection(sectionToOpen || Section.WORKOUT_ROUTINE);
    setIsMenuOpen(true);
  }, [
    logActivityEvent,
    onWorkoutPaused,
  ]);

  const onGameplayMenuClose = useCallback(() => {
    logActivityEvent('gameplayMenuClose');
    setIsMenuOpen(false);
  }, [
    logActivityEvent,
  ]);

  const context = useMemo(() => ({
    isMenuOpen,
    selectedSection,
    onGameplayMenuOpen,
    onGameplayMenuClose,
  }), [
    isMenuOpen,
    selectedSection,
    onGameplayMenuOpen,
    onGameplayMenuClose,
  ]);

  return (
    <GameplayMenuContext.Provider value={context}>
      {children}
    </GameplayMenuContext.Provider>
  );
};

GameplayMenuContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GameplayMenuContextProvider;
