import styled from 'styled-components';
import LeftSideBigIcon from '../Icons/LeftSideBigIcon';
import { ReactComponent as LeftSideSmallIcon } from '../../assets/icons/left-arrow-icon.svg';

const RightSideBigIcon = styled(LeftSideBigIcon)`
  transform: rotate(180deg);
`;

const RightSideSmallIcon = styled(LeftSideSmallIcon)`
  transform: rotate(180deg);
`;

export {
  LeftSideBigIcon,
  RightSideBigIcon,
  LeftSideSmallIcon,
  RightSideSmallIcon,
};
