import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as HistoryIcon } from '../../../../assets/icons/history-icon.svg';
import InfoPanel from '../../../InfoPanel';
import { ContentSwitcherContext } from '../../../ContentSwitcher';
import { WorkoutDetailsWithWorkoutContext } from '../../../WorkoutDetails';
import Section from '../../section';
import texts from './texts.json';

const WorkoutRoutineSection = ({
  WorkoutRoutineComponent,
}) => {
  const {
    goToSectionByName,
  } = useContext(ContentSwitcherContext);

  const WorkoutRoutineToRender = WorkoutRoutineComponent || WorkoutDetailsWithWorkoutContext;

  return (
    <InfoPanel
      title={texts.title}
      RightIcon={HistoryIcon}
      onRightIconClick={() => goToSectionByName(Section.ACTIVITY_HISTORY)}
    >
      <WorkoutRoutineToRender />
    </InfoPanel>
  );
};

WorkoutRoutineSection.propTypes = {
  WorkoutRoutineComponent: PropTypes.elementType,
};

WorkoutRoutineSection.defaultProps = {
  WorkoutRoutineComponent: null,
};

export default WorkoutRoutineSection;
