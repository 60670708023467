import React, {
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

import ActivityDetailsContext from './ActivityDetailsContext';

const ActivityDetailsContextProvider = ({
  enableExerciseOverride,
  children,
}) => {
  const contextValue = useMemo(() => ({
    enableExerciseOverride,
  }), [
    enableExerciseOverride,
  ]);

  return (
    <ActivityDetailsContext.Provider value={contextValue}>
      {children}
    </ActivityDetailsContext.Provider>
  );
};

ActivityDetailsContextProvider.propTypes = {
  enableExerciseOverride: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

ActivityDetailsContextProvider.defaultProps = {
  enableExerciseOverride: true,
};

export default ActivityDetailsContextProvider;
