/**
 * Available Gameplay actions statuses:
 * - IDLE: State in which the executor has not detected any attempt of gameplay action yet.
 * - DETECTING: State in which the executor has detected the attempt of the gameplay action, and it will remain
 * on the same state for the given period of time depending on the action configuration.
 * - PROCESSING: The executor confirms the execution/presence of the action, and remains in the same state
 * until the time determined in the activity configuration.
 * - PROCESSED: The executor confirms the action is successfully processed. The action followed all the steps in
 * the given time. At this point is safe that callers proceed with the action.
 *
 * @type {Object}
 */
const gameplayActionStatuses = {
  IDLE: 'IDLE',
  DETECTING: 'DETECTING',
  PROCESSING: 'PROCESSING',
  PROCESSED: 'PROCESSED',
};

export default gameplayActionStatuses;
