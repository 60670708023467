/**
 * This function adds an alpha value (transparency) to a given hexadecimal color
 * @param {string} hexColor hexadecimal 6 digit color (#FFFFFF)
 * @param {number} alpha opacity value, between 0 (transparent) and 1 (opaque)
 * @returns rgba color
 */
const addAlpha = (hexColor, alpha) => {
  const radix = 16;
  const [, red, green, blue] = hexColor.match(/#([\da-f]{2})([\da-f]{2})([\da-f]{2})/i);
  return `rgba(${parseInt(red, radix)},${parseInt(green, radix)},${parseInt(blue, radix)},${alpha})`;
};

export {
  addAlpha,
};
