import React, {
  useContext,
  useEffect,
} from 'react';
import { compose } from 'recompose';
import format from 'string-template';

import SubscriptionSlideDrawerContext from '../../context/SubscriptionSlideDrawerContext';
import UserContext from '../../context/UserContext';
import CoachInfoContext from '../../context/CoachInfoContext';
import UserContractContext from '../../context/UserContractContext';
import withPortal from '../withPortal';
import SlideDrawer from '../SlideDrawer';

import ErrorCard from './ErrorCard';
import {
  SubscriptionBg,
  Title,
  Message,
  CoachAvatar,
  Container,
} from './styles';
import text from './text.json';

const SubscriptionSlideDrawer = () => {
  const {
    isSliderOpen,
    closeSlider,
  } = useContext(SubscriptionSlideDrawerContext);

  const {
    userDoc: {
      firstName: userName,
      isActive,
    },
  } = useContext(UserContext);
  const {
    isNewContractAvailable,
  } = useContext(UserContractContext);

  const { coachInfo } = useContext(CoachInfoContext);

  useEffect(() => {
    if (isActive || isNewContractAvailable) {
      closeSlider();
    }
  }, [
    isActive,
    isNewContractAvailable,
    closeSlider,
  ]);

  const renderContent = () => (
    <Container>
      <SubscriptionBg />
      <Title>{text.title}</Title>
      <CoachAvatar url={coachInfo.avatar} />
      <Message>{format(text.message, { userName })}</Message>
      <ErrorCard />
    </Container>
  );

  return (
    <SlideDrawer
      isOpen={isSliderOpen}
      onClose={closeSlider}
      title="Subscribe"
      fitContent
      useTransparentHandle
    >
      {renderContent()}
    </SlideDrawer>
  );
};

export default compose(
  withPortal('subscriptionSlideDrawer'),
)(SubscriptionSlideDrawer);
