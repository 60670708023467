import React, { useContext } from 'react';
import { ThemeProvider } from 'styled-components';

import AppContext from '../AppContext';

const withStyledThemeProvider = (Component) => (props) => {
  const {
    isPortraitMode,
    orientationType,
  } = useContext(AppContext);

  return (
    <ThemeProvider
      theme={{
        isPortrait: isPortraitMode,
        orientationType,
      }}
    >
      <Component {...props} />
    </ThemeProvider>
  );
};

export default withStyledThemeProvider;
