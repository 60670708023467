import React, {
  useState,
  useMemo,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { useRouteMatch } from 'react-router-dom';
import format from 'string-template';

import TrackedActivity from '../../models/TrackedActivity';
import { firestorePaths, pathPlaceholder } from '../../utils/firebasePaths';
import useComponentMounted from '../../hooks/useComponentMounted';

import TrackedActivityContext from './TrackedActivityContext';

const TrackedActivityContextProvider = ({
  children,
}) => {
  const { params: { trackedActivityId } } = useRouteMatch();
  const isComponentMountedRef = useComponentMounted();
  const [isReady, setIsReady] = useState(false);
  const [trackedActivity, setTrackedActivity] = useState(null);

  useEffect(() => {
    const init = async () => {
      const trackedActivityDoc = new TrackedActivity(format(firestorePaths.TRACKED_ACTIVITY_DOC, {
        [pathPlaceholder.TRACKED_ACTIVITY_ID]: trackedActivityId,
      }));

      await trackedActivityDoc.init();

      if (isComponentMountedRef.current) {
        setTrackedActivity(trackedActivityDoc);
        setIsReady(true);
      }
    };

    if (!isReady) {
      init();
    }
  }, [
    trackedActivityId,
    isComponentMountedRef,
    isReady,
  ]);

  const contextValue = useMemo(() => ({
    isReady,
    trackedActivity,
  }), [
    isReady,
    trackedActivity,
  ]);

  return (
    <TrackedActivityContext.Provider value={contextValue}>
      {children}
    </TrackedActivityContext.Provider>
  );
};

TrackedActivityContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(TrackedActivityContextProvider);
