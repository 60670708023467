import styled, { css } from 'styled-components';

import CloseButton from '../../../CloseButton';

import { labelStyles } from '../../../../styles/text';
import colors from '../../colors';

const titleStyle = css`
  ${labelStyles}
  font-size: 18px;
  line-height: 22px;
`;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.system.alpha};
  width: 100%;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 10px;
  background-color: ${({ theme }) => theme.colors.base.tertiary};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const Title = styled.div`
  ${titleStyle}
`;

const TitleHighlight = styled.span`
  ${titleStyle}
  font-weight: 600;
  color: ${colors.base};
`;

const Description = styled.div`
  ${labelStyles}
  font-size: 14px;
  line-height: 16px;
`;

const StyledCloseButton = styled(CloseButton)`
  margin-left: auto;
  display: grid;
  place-items: center;
`;

export {
  CardContainer,
  Description,
  Title,
  TitleHighlight,
  TextWrapper,
  Wrapper,
  StyledCloseButton,
};
