import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import smoothscroll from 'smoothscroll-polyfill';

import ExerciseOverrideSelectorContext from '../context/ExerciseOverrideSelectorContext';
import ExerciseList from './ExerciseList';

smoothscroll.polyfill();

const EXERCISES_COUNT = 25;

const ExerciseListContainer = ({
  showOriginalExercise,
  exercises,
  className,
}) => {
  const {
    logExerciseOverrideEvent,
  } = useContext(ExerciseOverrideSelectorContext);

  const [currentExercises, setCurrentExercises] = useState([]);
  const [expandedItem, setExpandedItem] = useState(null);

  const calculateNextPageIndexes = useCallback((currentValues) => {
    const startIndex = currentValues.length;
    let endIndex = startIndex + EXERCISES_COUNT;

    if (endIndex > exercises.length) {
      endIndex = exercises.length;
    }

    return {
      startIndex,
      endIndex,
    };
  }, [
    exercises,
  ]);

  useEffect(() => {
    const {
      startIndex,
      endIndex,
    } = calculateNextPageIndexes([]);

    setCurrentExercises(exercises.slice(startIndex, endIndex));
  }, [
    exercises,
    calculateNextPageIndexes,
  ]);

  const onLoadMoreData = useCallback(() => {
    logExerciseOverrideEvent('exerciseOverrideLoadMoreData');

    setCurrentExercises((prevCurrentExercises) => {
      if (prevCurrentExercises.length < exercises.length) {
        const {
          startIndex,
          endIndex,
        } = calculateNextPageIndexes(prevCurrentExercises);

        return [
          ...prevCurrentExercises,
          ...exercises.slice(startIndex, endIndex),
        ];
      }
      return prevCurrentExercises;
    });
  }, [
    exercises,
    calculateNextPageIndexes,
    logExerciseOverrideEvent,
  ]);

  const onItemExpanded = useCallback((newExercise) => {
    if (newExercise) {
      logExerciseOverrideEvent('exerciseOverrideItemViewExpanded', {
        expandedExerciseId: newExercise.id,
        expandedExerciseName: newExercise.name,
      });
    }

    setExpandedItem((prevExercise) => (
      (prevExercise && prevExercise.id === newExercise.id)
        ? null
        : newExercise
    ));
  }, [
    logExerciseOverrideEvent,
  ]);

  return (
    <ExerciseList
      exercises={currentExercises}
      expandedItem={expandedItem}
      showOriginalExercise={showOriginalExercise}
      onItemExpanded={onItemExpanded}
      onLoadMoreData={onLoadMoreData}
      className={className}
    />
  );
};

ExerciseListContainer.propTypes = {
  showOriginalExercise: PropTypes.bool.isRequired,
  exercises: PropTypes.array.isRequired,
  className: PropTypes.string,
};

ExerciseListContainer.defaultProps = {
  className: '',
};

export default ExerciseListContainer;
