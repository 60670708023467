import {
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { Deploy } from 'cordova-plugin-ionic';
import * as Sentry from '@sentry/browser';

import { isIOS } from '../../utils/platform';
import logEvent from '../../utils/logger';
import FirebaseContext from '../FirebaseContext';

const GAMEPLAY_PATHNAME_REGEX = /\/workoutAssignment\/([^/.]+)\/play/;

const useLiveUpdateHook = () => {
  const {
    firebase: {
      firestore,
    },
  } = useContext(FirebaseContext);

  /**
   * Add a live update pre-install version hook to determine if the installation process
   * should continue or not and perform a required step: release all firestore resources,
   * so IndexedDB db is not locked the next time the app initializes as part of the reload process.
   * The reload process is done using Ionic.WebView.setServerBasePath (same issue when using window.location.href).
   * More information: https://linear.app/system2/issue/S2-262/live-update-process-improvements
   */
  const preInstallVersionHook = useCallback(() => {
    if (typeof Deploy.addPreInstallVersionHook === 'function') {
      Deploy.addPreInstallVersionHook(async () => {
        logEvent('preLiveUpdateInstallHookCalled');

        if (GAMEPLAY_PATHNAME_REGEX.test(window.location.pathname)) {
          logEvent('liveUpdateInstallShouldNotContinue');
          // Do not allow reloading when the user is in the gameplay
          return false;
        }

        if (isIOS()) {
          logEvent('liveUpdateTerminatingFirestore');

          try {
            await firestore.terminate();
            logEvent('liveUpdateTerminatingFirestoreSucceeded');
          } catch (error) {
            logEvent('liveUpdateTerminatingFirestoreFailed');
            Sentry.captureException(error, {
              extra: {
                description: '"firestore.terminate()" failed to run on live update pre install hook',
              },
            });
          }
        }
        logEvent('liveUpdateInstallShouldContinue');
        return true;
      });
    }
  }, [
    firestore,
  ]);

  return useMemo(() => ({
    preInstallVersionHook,
  }), [
    preInstallVersionHook,
  ]);
};

export default useLiveUpdateHook;
