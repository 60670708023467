import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { autorun, observable } from 'mobx';
import { compose } from 'recompose';

import HabitLog from '../../models/HabitLog';
import useComponentMounted from '../../hooks/useComponentMounted';
import useComponentLoadingTime from '../../hooks/useComponentLoadingTime';

import LogContext from '../LogContext';
import UserContext from '../UserContext';

import HabitLogContext, { initialState } from './HabitLogContext';

const LOGS_FETCH_AMOUNT = 15;

const HabitLogContextProvider = ({
  children,
}) => {
  const { currentSelectedDate } = useContext(LogContext);
  const [currentHabitLogDoc, setCurrentHabitLogDoc] = useState(null);

  const {
    userId,
  } = useContext(UserContext);

  const [habitLogs, setHabitLogs] = useState(initialState.habitLogs);
  const [isReady, setIsReady] = useState(initialState.isReady);
  const [isLoadingMore, setIsLoadingMore] = useState(initialState.isLoadingMore);
  const [logCollections] = useState(() => observable({
    collections: [],
    addCollection(collection) {
      logCollections.collections = [...logCollections.collections, collection];
    },
  }));
  const [noMoreLogs, setNoMoreLogs] = useState(false);

  const isComponentMountedRef = useComponentMounted();
  const { startLoading, finishLoading } = useComponentLoadingTime('habitLogContext');

  const loadLogs = useCallback(async (options) => {
    setIsLoadingMore(true);

    const newLogs = await HabitLog.getLogs(userId, {
      limit: LOGS_FETCH_AMOUNT,
      ...options,
    }).fetch();
    if (!isComponentMountedRef.current) {
      return;
    }
    logCollections.addCollection(newLogs);
    if (newLogs.hasDocs) {
      setNoMoreLogs(newLogs.size < LOGS_FETCH_AMOUNT);
    } else {
      setNoMoreLogs(true);
    }
    setIsLoadingMore(false);
  }, [
    isComponentMountedRef,
    logCollections,
    userId,
  ]);

  const loadMoreLogs = useCallback(async () => {
    if (!habitLogs.length || noMoreLogs) {
      return;
    }

    const lastDoc = habitLogs[habitLogs.length - 1];
    await loadLogs({ startAfterDoc: lastDoc });
  }, [
    loadLogs,
    habitLogs,
    noMoreLogs,
  ]);

  useEffect(() => {
    let disposer;
    const setDoc = async () => {
      const habitLogCollection = await HabitLog.getLogsByDate(userId, currentSelectedDate);
      disposer = autorun(() => {
        if (habitLogCollection.hasDocs) {
          setCurrentHabitLogDoc(habitLogCollection.docs[0]);
        } else {
          setCurrentHabitLogDoc(null);
        }
      });
    };
    setDoc();
    return disposer;
  }, [
    currentSelectedDate,
    userId,
  ]);

  useEffect(() => {
    const getLogs = async () => {
      startLoading();
      await loadLogs();
      if (isComponentMountedRef.current) {
        setIsReady(true);
        finishLoading();
      }
    };
    if (!isReady) {
      getLogs();
    }
  }, [
    isReady,
    loadLogs,
    isComponentMountedRef,
    startLoading,
    finishLoading,
  ]);

  const value = useMemo(() => ({
    currentHabitLogDoc,
    currentSelectedDate,
    isReady,
    loadMoreLogs,
    isLoadingMore,
    habitLogs,
  }), [
    currentHabitLogDoc,
    currentSelectedDate,
    isReady,
    loadMoreLogs,
    isLoadingMore,
    habitLogs,
  ]);

  useEffect(() => {
    const habitLogsDisposer = autorun(() => {
      const logs = logCollections.collections
        .reduce((previous, current) => [...previous, ...current.docs], []);
      setHabitLogs(logs);
    });
    return habitLogsDisposer;
  }, [
    logCollections,
  ]);

  return (
    <HabitLogContext.Provider value={value}>
      {children}
    </HabitLogContext.Provider>
  );
};

HabitLogContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(HabitLogContextProvider);
