import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import MessageBannerContext, { initialState } from './MessageBannerContext';

const MessageBannerContextProvider = ({
  children,
}) => {
  const [isBannerVisible, setIsBannerVisible] = useState(initialState.isBannerVisible);
  const [messageText, setMessageText] = useState(initialState.messageText);
  const [onClick, setOnClick] = useState(initialState.onClick);
  const [bannerType, setBannerType] = useState(initialState.bannerType);

  const showBanner = useCallback(({
    message,
    onClickHandler = initialState.onClick,
    type = initialState.bannerType,
  }) => {
    setIsBannerVisible(true);
    setMessageText(message);
    setOnClick(() => onClickHandler);
    setBannerType(type);
  }, []);

  const hideBanner = useCallback(() => {
    setIsBannerVisible(false);
    setMessageText(initialState.messageText);
    setOnClick(initialState.onClick);
    setBannerType(initialState.bannerType);
  }, []);

  const value = useMemo(() => ({
    isBannerVisible,
    showBanner,
    hideBanner,
    messageText,
    onClick,
    bannerType,
  }), [
    isBannerVisible,
    showBanner,
    hideBanner,
    messageText,
    onClick,
    bannerType,
  ]);

  return (
    <MessageBannerContext.Provider value={value}>
      {children}
    </MessageBannerContext.Provider>
  );
};

MessageBannerContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MessageBannerContextProvider;
