import styled, { css } from 'styled-components';

const ActivitySideMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: none;
  text-align: center;
`;

const Message = styled.div`
  font-size: 55px;

  ${({ theme }) => theme.isPortrait && css`
    max-width: 300px;
  `}
`;

export {
  ActivitySideMessageContainer,
  Message,
};
