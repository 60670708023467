import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import BaseActivity from '../../../models/BaseActivity';
import ActivityActionBar from '../../ActivityActionBar';

import ActivityDetailsModal from '../../../../components/ActivityDetailsModal';
import { config as sideConfig } from '../../../../components/ActivitySideConfig';
import WorkoutDetailsContext from '../../../../components/WorkoutDetails/WorkoutDetailsContext';
import {
  ActivityContainer,
  ActivityTagContainer,
  ActivityTag,
  ActivityCard,
  ActivityName,
  Requirements,
  ActivityRequirement,
  ActivityNote,
  RestNote,
  ActivityInfo,
  BasicInfoWrapper,
  ThumbnailWrapper,
  Thumbnail,
} from './styles';

const Activity = ({
  activity,
  path,
  tag,
  showTopConnector,
  showBottomConnector,

  isEditorMode,
  onEditorModeActionPerform,
  isSingleActivity,
}) => {
  const {
    name,
    note,
    goal,
    restTime,
    hasDetailsInfo,
    side,
    videoPreviewThumbnail,
  } = activity;

  const { enableActivityDetails } = useContext(WorkoutDetailsContext);
  const [showActivityDetails, setShowActivityDetails] = useState(false);

  const onViewDetailsClick = useCallback(() => {
    if (enableActivityDetails && hasDetailsInfo) {
      setShowActivityDetails(true);
    }
  }, [
    hasDetailsInfo,
    enableActivityDetails,
    setShowActivityDetails,
  ]);

  /**
   * Handle action button click to perform activity action and pass required parameters
   * @param {Object} event click event object.
   * @param {Object} action Action object to handle action
   */
  const onActionButtonClick = useCallback((event, action) => {
    event.preventDefault();
    event.stopPropagation();
    onEditorModeActionPerform({ action, activity, path });
  }, [
    activity,
    path,
    onEditorModeActionPerform,
  ]);

  const renderActionBar = () => {
    if (isEditorMode) {
      return (
        <ActivityActionBar
          onActionButtonClick={onActionButtonClick}
          hasAddCircuit={isSingleActivity}
          isCircuit={false}
        />
      );
    }
    return null;
  };

  const onDetailsClose = () => setShowActivityDetails(false);

  const renderActivityRequirements = () => {
    // We only display the goal by default.
    let requirement = goal;

    // If there is any side information, then we add this to the requirement text.
    if (side) {
      const { text: sideText } = sideConfig[side];
      requirement = `${requirement} - ${sideText}`;
    }

    return <ActivityRequirement>{requirement}</ActivityRequirement>;
  };

  return (
    <>
      <ActivityContainer>
        <ActivityTagContainer>
          <ActivityTag
            showTopConnector={showTopConnector}
            showBottomConnector={showBottomConnector}
          >
            {tag}
          </ActivityTag>
        </ActivityTagContainer>
        <ActivityCard onClick={onViewDetailsClick} isEditorMode>
          <ActivityInfo>
            {!!videoPreviewThumbnail && (
              <ThumbnailWrapper>
                <Thumbnail src={videoPreviewThumbnail} />
              </ThumbnailWrapper>
            )}
            <BasicInfoWrapper>
              <ActivityName>{name}</ActivityName>
              <Requirements>
                {renderActivityRequirements()}
              </Requirements>
            </BasicInfoWrapper>
          </ActivityInfo>
          {note && <ActivityNote>{note}</ActivityNote>}
          {!!restTime && <RestNote>{`Rest: ${restTime}s`}</RestNote>}
          {renderActionBar()}
        </ActivityCard>
      </ActivityContainer>
      <ActivityDetailsModal
        isOpen={showActivityDetails}
        onClose={onDetailsClose}
        activity={activity}
      />
    </>
  );
};

Activity.propTypes = {
  activity: PropTypes.instanceOf(BaseActivity).isRequired,
  path: PropTypes.arrayOf(PropTypes.number).isRequired,
  tag: PropTypes.string.isRequired,
  showTopConnector: PropTypes.bool,
  showBottomConnector: PropTypes.bool,

  onEditorModeActionPerform: PropTypes.func.isRequired,
  isEditorMode: PropTypes.bool.isRequired,
  isSingleActivity: PropTypes.bool,
};

Activity.defaultProps = {
  showTopConnector: false,
  showBottomConnector: false,
  isSingleActivity: true,
};

export default Activity;
