import React from 'react';
import PropTypes from 'prop-types';

import { StyledIFrame } from './styles';

const HostedPage = ({
  src,
}) => (
  <StyledIFrame
    title="AppHostedPage"
    src={src}
    frameBorder="0"
    width="100%"
  />
);

HostedPage.propTypes = {
  src: PropTypes.string.isRequired,
};

export default HostedPage;
