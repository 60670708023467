import React, { useContext } from 'react';

import NotificationModal from '../../components/NotificationModal';

import NotificationModalContextProvider from './NotificationModalContextProvider';

import NotificationModalContext from './NotificationModalContext';

const WrappedNotificationModal = () => {
  const {
    isOpen,
    title,
    description,
    Icon,
    actions,
    content,
    hideNotificationModal,
  } = useContext(NotificationModalContext);

  return (
    <NotificationModal
      title={title}
      isOpen={isOpen}
      onClose={hideNotificationModal}
      description={description}
      Icon={Icon}
      actions={actions}
    >
      {content}
    </NotificationModal>
  );
};

/**
 * Inserts NotificationModalContextProvider in the React tree
 * before adding the given component.
 *
 * @param {Object} Component The component that needs the context provider.
 * @returns {Object} The component to render wrapped with the provider.
 */
const withNotificationModalContextProvider = (Component) => (props) => (
  <NotificationModalContextProvider>
    <Component {...props} />
    <WrappedNotificationModal />
  </NotificationModalContextProvider>
);

export default withNotificationModalContextProvider;
