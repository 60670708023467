import {
  decorate, action, computed, observable,
} from 'mobx';

import { createTrackerByActivityType } from '../trackingSystem';
import gameplayActionStatuses from './gameplayActionStatuses';
import GameplayActionExecutor from './GameplayActionExecutor';
import actionsConfig from './actionsConfig';

class GameplayActionsExecutor {
  constructor() {
    /**
     * Array of available executors objects that will handle the flow humans
     * processing and action processing.
     * The GameplayActionsExecutor will iterate over this array in order to
     * process actions.
     *
     * @type {Array}
     */
    this.executors = Object.keys(actionsConfig)
      .map((actionType) => {
        const actionConfig = actionsConfig[actionType];
        const tracker = createTrackerByActivityType(actionType);

        return new GameplayActionExecutor(actionType, actionConfig, tracker);
      });

    this.activeExecutor = null;

    this.processingArea = {
      cameraViewportClientRect: null,
    };
  }

  updateProcessingArea = (value) => {
    this.processingArea = {
      ...this.processingArea,
      ...value,
    };
  }

  /**
   * Process the gameplay actions.
   * Iterate over the executors processing the flow humans and detect if an action
   * exists. When one of the executors detects an action, it is saved as the "activeExecutor"
   * and the incoming actions processing requests will be handled by that executor unless
   * the action is no longer detected.
   *
   * @param {Array} predictionModels Array of prediction models instances (FlowHuman or Hand).
   * @param {Object=} processingArea Information about available processing area that trackers can use later.
   * @return {Object}
   */
  processAction = (predictionModels) => {
    if (this.actionProcessed) {
      /*
        Do not keep processing and looking for actions until the caller
        executes the "actionDone" API, indicating the the action processed
        has been executed/done/handled and it is safe to continue processing
        actions attempts.
      */
      return {
        id: null,
        stepResult: null,
        predictionModelResult: null,
      };
    }

    if (this.activeExecutor) {
      const result = this.activeExecutor.processAction(predictionModels, this.processingArea);

      if (this.activeExecutor.actionState !== gameplayActionStatuses.IDLE) {
        return result;
      }
    }

    let predictionModelResult = null;

    // Execute all action executors until one detects an action possibility
    for (let i = 0; i < this.executors.length; i++) {
      const executor = this.executors[i];
      const result = executor.processAction(predictionModels, this.processingArea);

      /*
        NOTE: for now, let's keep track of the predictionModelResult to later render it
        in the gameplay.
      */
      predictionModelResult = result.predictionModelResult;

      if (executor.actionState !== gameplayActionStatuses.IDLE) {
        this.activeExecutor = executor;
        return result;
      }
    }

    // Clean up current executor
    if (this.activeExecutor) {
      this.activeExecutor = null;
    }

    return {
      id: null,
      predictionModelResult,
      stepResult: null,
    };
  }

  /**
   * Indicates that the action processed has been handled by the caller and it is
   * moment to start processing again looking for new actions.
   */
  actionDone = () => {
    this.restart();
  }

  /**
   * Restarts the current active executor if any. Call this function when a deep clean up
   * has to be done in order to keep processing new upcoming actions.
   */
  restart = () => {
    if (this.activeExecutor) {
      this.activeExecutor.restart();
      this.activeExecutor = null;
    }
  }

  /**
   * This function must be called on "componentWillUnmount". It ensures to do a proper cleanup
   * to prevent updating the state when the component is unmounted.
   */
  reset = () => {
    if (this.activeExecutor) {
      this.activeExecutor.reset();
    }
  }

  get actionTracked() {
    return (this.activeExecutor && this.activeExecutor.isActionTracked)
      ? this.activeExecutor.id
      : null;
  }

  get actionProcessing() {
    return (this.activeExecutor && this.activeExecutor.isProcessingAction)
      ? this.activeExecutor.id
      : null;
  }

  get actionProcessed() {
    return (this.activeExecutor && this.activeExecutor.isActionProcessed)
      ? this.activeExecutor.id
      : null;
  }
}

decorate(GameplayActionsExecutor, {
  activeExecutor: observable,
  processingArea: observable,
  actionProcessing: computed,
  actionProcessed: computed,
  actionTracked: computed,
  processAction: action,
  actionDone: action,
  reset: action,
  updateProcessingArea: action,
});

export default GameplayActionsExecutor;
