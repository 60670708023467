import {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from 'react';
import moment from 'moment';
import { useRouteMatch } from 'react-router-dom';

import logEvent from '../utils/logger';
import CollectionName from '../utils/collections';

import useSessionStore from '../hooks/useSessionStore';
import FirebaseContext from '../context/FirebaseContext';
import { updateClearCache, lastTimeFirestoreCacheCleared } from '../context/FirebaseContext/persistence';

const CLEAR_LOCAL_CACHE_MAX_DAYS_THRESHOLD = 10;

const maxDocumentsQuery = {
  [CollectionName.WORKOUT_ASSIGNMENT]: 16,
  [CollectionName.GAMEPLAY_SESSION]: 6,
};

/**
 * Custom hook to manage the logic related to Firestore cache clear time.
 *
 * NOTE: This is an internal and private hook that must be used only once.
 */
const useFirestoreLocalCache = () => {
  const { params: { userId } } = useRouteMatch();
  const { isCoachOrAdmin, hasUserClaims } = useSessionStore();

  const [isReady, setIsReady] = useState(false);
  const [pendingWritesData, setPendingWritesData] = useState({
    [CollectionName.WORKOUT_ASSIGNMENT]: false,
    [CollectionName.GAMEPLAY_SESSION]: false,
  });
  const [hasPendingWrites, setHasPendingWrites] = useState(false);
  const [isCacheClearedDaysThresholdReached] = useState(() => {
    const lastTime = lastTimeFirestoreCacheCleared();

    if (lastTime) {
      const lastDate = moment(lastTime);
      const now = moment();
      const days = Math.round(now.diff(lastDate, 'days'));

      return days >= CLEAR_LOCAL_CACHE_MAX_DAYS_THRESHOLD;
    }

    // If no last timestamp specified, we assume the cache should be cleared
    return true;
  });

  const unsubscribeSnapshotsRef = useRef({});

  const {
    isReady: isFirebaseReady,
    firebase: {
      firestore,
    },
  } = useContext(FirebaseContext);

  /**
   * Determines if the persistence cache should be cleaned up, based on the following conditions:
   * - An specific number of days passed since the last time the cache was cleaned up
   * - There are no pending writes on the most critical collections
   */
  useEffect(() => {
    if (isReady) {
      const clearCache = !hasPendingWrites && isCacheClearedDaysThresholdReached;
      updateClearCache(clearCache);

      const eventName = clearCache
        ? 'firestoreShouldClearCacheNextAppOpen'
        : 'firestoreShouldNotClearCacheNextAppOpen';

      logEvent(eventName, {
        hasPendingWrites,
        isCacheClearedDaysThresholdReached,
      });
    }
  }, [
    isReady,
    hasPendingWrites,
    isCacheClearedDaysThresholdReached,
  ]);

  useEffect(() => {
    const hasNewPendingWrites = Object.values(pendingWritesData).every((value) => !!value);
    setHasPendingWrites(hasNewPendingWrites);
  }, [
    pendingWritesData,
  ]);

  /**
   * Creates a query and attach a snapshot change listener for those collections and documents that are critical
   * for supporting offline mode and detect pending writes.
   *
   * @param {string} collectionName
   * @param {Array} conditions
   * @param {Object} ordering
   */
  const setupSnapshotListener = useCallback((collectionName, conditions, ordering) => {
    if (unsubscribeSnapshotsRef.current && typeof unsubscribeSnapshotsRef.current[collectionName] === 'function') {
      unsubscribeSnapshotsRef.current[collectionName]();
    }

    const query = firestore
      .collection(collectionName)
      .where('user', '==', userId);

    conditions.forEach(({ field, condition, value }) => {
      query.where(field, condition, value);
    });

    const { field, orderType } = ordering;

    unsubscribeSnapshotsRef.current[collectionName] = query.orderBy(field, orderType)
      .limit(maxDocumentsQuery[collectionName])
      .onSnapshot({
        includeMetadataChanges: true,
      }, (snapshot) => {
        const { metadata: { hasPendingWrites: hasPendingWritesValue } } = snapshot;
        const newData = {
          ...pendingWritesData,
          [collectionName]: hasPendingWritesValue,
        };
        setPendingWritesData(newData);
      });
  }, [
    firestore,
    userId,
    pendingWritesData,
  ]);

  /**
   * Initialize the custom hook by attaching a snapshot listener over the collections that are
   * critical to ensure an offline experience. The hook should detect pending writes over those
   * collections to guarantee that the cache won't be cleared out when there are pending writes.
   */
  useEffect(() => {
    const setupSnapshotListeners = () => {
      const nextDay = moment();
      const offset = nextDay.utcOffset();
      nextDay.subtract(offset, 'minutes');
      nextDay.add(1, 'days');

      setupSnapshotListener(CollectionName.WORKOUT_ASSIGNMENT, [{
        field: 'startDate',
        condition: '<=',
        value: nextDay.toDate(),
      }], {
        field: 'startDate',
        orderType: 'desc',
      });

      setupSnapshotListener(CollectionName.GAMEPLAY_SESSION, [{
        field: 'startTime',
        condition: '<=',
        value: nextDay.valueOf(),
      }], {
        field: 'startTime',
        orderType: 'desc',
      });

      setIsReady(true);
    };

    if (!isReady && hasUserClaims) {
      if (isFirebaseReady && userId && !isCoachOrAdmin) {
        setupSnapshotListeners();
        logEvent('firestoreSnapshotsListenersSettedUp');
      } else if (isCoachOrAdmin) {
        // When offline mode is not enabled or the user is coach/admin, do not setup snapshot listeners
        setIsReady(true);
        logEvent('firestoreSnapshotsListenersNotSettedUp');
      }
    }
  }, [
    isFirebaseReady,
    isReady,
    firestore,
    pendingWritesData,
    userId,
    hasUserClaims,
    isCoachOrAdmin,
    setupSnapshotListener,
  ]);

  useEffect(() => (
    () => {
      Object.keys(unsubscribeSnapshotsRef.current).forEach((collectionName) => {
        if (typeof unsubscribeSnapshotsRef.current[collectionName] === 'function') {
          unsubscribeSnapshotsRef.current[collectionName]();
        }
      });
    }
  ), []);
};

export default useFirestoreLocalCache;
