import styled, { css } from 'styled-components';
import { IonRange } from '@ionic/react';

import { caption1, labelStyles } from '../../styles/text';

const SliderContainer = styled.div`
  ${caption1}
  width: 100%;
`;

/**
 * We need to calculate the padding of the range slider to make it align with the middle section of each option label.
 *
 * @example
 * Here, we show 5 Labels, and the limits of the range slider correspond to the middle section of the first and
 * last Labels. The handler is positioned on Label 2, and it is aligned to its center.
 *    _________________________________________________
 *   | Label 1 | Label 2 | Label 3 | Label 4 | Label 5 |
 *    ⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻⎻
 *        |         |         |         |          |
 *        ----------◯-------------------------------
 *
 *   |----|                                        |----|
 *    padding-left: 10%;               padding-right: 10%
 *
 * @param {number} numItems The number of items shown by the range slider.
 * @return {string}
 */
const getIonRangePadding = (numItems) => {
  const padding = (100 / numItems) / 2;
  return css`padding: 0 ${padding}%`;
};

const StyledIonRange = styled(IonRange)`
  ${(props) => getIonRangePadding(props.optionsLength)};

  --bar-background: ${({ theme }) => theme.colors.system.gamma1};
  --knob-background: ${({ theme }) => theme.colors.base.tertiary};
  --bar-background-active: ${({ theme }) => theme.colors.system.gamma1};
  --knob-box-shadow: none;
  --knob-size: 34px;
`;

const DynamicLabel = styled.div`
  ${labelStyles}
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.system.highlight};
  text-align: center;
`;

const StepLabelsContainer = styled.div`
  display: flex;
  padding: 0;
`;

const StepLabel = styled.div`
  transition: color 0.2s;
  cursor: pointer;
  flex: 1;
  text-align: center;

  ${({ selected, theme: { colors } }) => (selected
    ? css`color: ${colors.base.primary}`
    : css`color: ${colors.system.beta}`)};
  ${({ selected }) => (selected
    ? css`opacity: 1`
    : css`opacity: 0.15`)};
`;

export {
  SliderContainer,
  StyledIonRange,
  DynamicLabel,
  StepLabelsContainer,
  StepLabel,
};
