import React from 'react';

import ActivityDetailsContextProvider from './ActivityDetailsContextProvider';

const withActivityDetailsContextProvider = (Component) => (props) => (
  <ActivityDetailsContextProvider>
    <Component {...props} />
  </ActivityDetailsContextProvider>
);

export default withActivityDetailsContextProvider;
