import React, {
  useContext,
  useMemo,
  useCallback,
} from 'react';
import { ChannelList, useChatContext } from 'stream-chat-react';
import {
  IonContent,
  IonHeader,
  IonMenu,
  IonSegmentButton,
  IonLabel,
} from '@ionic/react';
import { channelConfiguration } from '../../util';
import MessagingChannelList from '../MessagingChannelList';
import MessagingChannelPreview from '../MessagingChannelPreview';
import MessagingChannelSearch from '../MessagingChannelSearch';
import Avatar from '../../../components/Avatar';
import { CHAT_MENU_ID } from '../../util/menu';
import ChatContext from '../../context';

import { ChannelBuckets } from '../../util/buckets';
import MessagingBroadcastButton from '../MessagingBroadcastButton';
import {
  AccountInfo,
  Profile,
  EmptyListPlaceholder,
  StyledSegment,
  ChannelListContainer,
  ChannelListHeader,
} from './styles';
import texts from './texts';

const MultipleChannelList = () => {
  const { userId } = useContext(ChatContext);

  const { client } = useChatContext();
  const {
    customActiveChannel,
    selectedBucket,
    onBucketSelected,
    chatMenuRef,
    isChatModalOpen,
  } = useContext(ChatContext);

  const handleBucketChange = (e) => onBucketSelected(e.detail.value);

  const {
    name,
    image,
  } = client?.user || {};

  const channelFilters = useMemo(() => ({
    filters: channelConfiguration.filters(userId, selectedBucket),
    options: channelConfiguration.options,
    sort: channelConfiguration.sort,
  }), [
    selectedBucket,
    userId,
  ]);

  const getSegmentButtons = useCallback(() => (
    Object.values(ChannelBuckets).map((bucket) => (
      <IonSegmentButton key={bucket} value={bucket}>
        <IonLabel>{texts.channelBuckets[bucket]}</IonLabel>
      </IonSegmentButton>
    ))
  ), []);

  return (
    <IonMenu
      contentId="app-chat-content"
      side="start"
      menuId={CHAT_MENU_ID}
      ref={chatMenuRef}
      disabled={!isChatModalOpen}
    >
      <IonHeader>
        <ChannelListContainer>
          <ChannelListHeader>
            <Profile>
              {!!image && <Avatar url={image} />}
              <AccountInfo>
                {name}
              </AccountInfo>
              <MessagingBroadcastButton />
            </Profile>
            <StyledSegment
              onIonChange={handleBucketChange}
              value={selectedBucket}
              mode="ios"
            >
              {getSegmentButtons()}
            </StyledSegment>
          </ChannelListHeader>
        </ChannelListContainer>
      </IonHeader>
      <IonContent>
        <MessagingChannelSearch
          searchQueryParams={{
            channelFilters,
            userFilters: { filters: { id: '' } },
          }}
          searchForChannels
        />
        <ChannelList
          customActiveChannel={customActiveChannel}
          {...channelFilters}
          List={(props) => <MessagingChannelList {...props} />}
          Preview={(props) => <MessagingChannelPreview {...props} />}
          EmptyStateIndicator={() => <EmptyListPlaceholder>{texts.noChannelsPlaceholder}</EmptyListPlaceholder>}
        />
      </IonContent>
    </IonMenu>
  );
};

export default MultipleChannelList;
