import PoseQuality from './poseQuality';

const PoseReasons = {
  GenericNotInPose_Fail: 'GenericNotInPose_Fail',
  WristNotInArea_Fail: 'WristNotInArea_Fail',
  HandIsNotOpened_Fail: 'HandIsNotOpened_Fail',
  HandNotInArea_Fail: 'HandNotInArea_Fail',
};

const PoseReasonData = {
  GenericNotInPose_Fail: {
    text: 'Not in pose',
    quality: PoseQuality.NotInPose,
  },
  WristNotInArea_Fail: {
    text: 'The wrist is not in the expected area',
    quality: PoseQuality.NotInPose,
  },
  HandIsNotOpened_Fail: {
    text: 'The hand is not opened',
    quality: PoseQuality.NotInPose,
  },
  HandNotInArea_Fail: {
    text: 'The hand is not in the expected area',
    quality: PoseQuality.NotInPose,
  },
};

export default PoseReasons;
export {
  PoseReasonData,
};
