import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import moment from 'moment';

import BaseActivity from '../../models/BaseActivity';
import UserActivityHistory from '../../models/UserActivityHistory';
import { firestorePaths } from '../../utils/firebasePaths';
import { DateFormat } from '../../utils/date';
import FirebaseContext from '../FirebaseContext';
import WorkoutContext, { withWorkoutContextReady } from '../WorkoutContext';
import UserContext from '../UserContext';
import UserActivityHistoryContext from './UserActivityHistoryContext';

const UserActivityHistoryContextProvider = ({
  children,
}) => {
  const { firebase } = useContext(FirebaseContext);
  const { activitiesWithOverrides } = useContext(WorkoutContext);
  const { userId } = useContext(UserContext);

  const [activityHistory, setActivityHistory] = useState({});

  useEffect(() => {
    let shouldUpdate = true;

    const init = async () => {
      // Get the unique names of the activities included in the workout.
      const activityNamesSet = BaseActivity.getActivityNamesSet(activitiesWithOverrides);

      // Get the docs for the history of all activities.
      const docPromises = activityNamesSet.map((name) => (
        UserActivityHistory.get(userId, name)
      ));
      const results = await Promise.all(docPromises);

      /*
        Create the map of activity/history. This map will use the name of the activity as the key, and it stores
        the associated doc.
      */
      const historyMap = results.reduce((historyObj, doc) => {
        /*
          Some activities will not have a history array associated, so we ignore those cases (result is null when no
          data is found in the DB)
        */
        if (!doc) {
          return historyObj;
        }

        const historyDoc = doc;
        const { activityName } = historyDoc;

        return {
          ...historyObj,
          [activityName]: historyDoc,
        };
      }, {});

      if (shouldUpdate) {
        setActivityHistory(historyMap);
      }
    };

    init();

    return () => {
      shouldUpdate = false;
    };
  }, [
    activitiesWithOverrides,
    userId,
  ]);

  /**
   * Add a history value for the specified ActivityName. This will create a new document in the DB if there
   * is none available.
   *
   * @param {string} activityName The name of the activity on which the note is taken.
   * @param {string} historyDate The date of the history value.
   * @param {number} historyData The information provided by the user.
   */
  const addHistoryValue = useCallback(async (
    activityName,
    historyDate,
    historyData = {
      weight: 0,
      reps: 0,
      sets: [],
      comment: '',
    },
  ) => {
    // Force the default date format before using this date.
    const date = moment(historyDate).format(DateFormat.DEFAULT_DATE_FORMAT);
    const newEntry = {
      ...historyData,
      date,
    };

    // Check if we already have an entry for this activity
    const existingDoc = activityHistory[activityName];

    if (!existingDoc) {
      // This is the data that will be stored in the document we create.
      const newDocValue = {
        user: userId,
        activityName,
        history: [newEntry],
        createdAt: Date.now(),
      };

      const userActivityHistoryCollection = firebase.firestore.collection(firestorePaths.USER_ACTIVITY_HISTORY);
      const newUserActivityHistoryDoc = userActivityHistoryCollection.doc();

      const userActivityHistoryDoc = new UserActivityHistory(newUserActivityHistoryDoc.path);
      userActivityHistoryDoc.set(newDocValue);
      await userActivityHistoryDoc.init();

      // Update the history map with the latest one added.
      const newActivityHistoryMap = {
        ...activityHistory,
        [activityName]: userActivityHistoryDoc,
      };
      setActivityHistory(newActivityHistoryMap);
    } else {
      const newData = [
        ...existingDoc.history,
      ];

      // Check if we have an entry for the specified date
      const existingHistoryIndex = existingDoc.history.findIndex((item) => item.date === date);
      if (existingHistoryIndex > -1) {
        newData[existingHistoryIndex] = newEntry;
      } else {
        newData.push(newEntry);

        // Order the data by date
        newData.sort((a, b) => (a.date > b.date ? -1 : 1));
      }

      existingDoc.ref.update({
        history: [...newData],
      });
    }
  }, [
    firebase,
    activityHistory,
    userId,
  ]);

  const contextValue = useMemo(() => ({
    activityHistory,
    addHistoryValue,
  }), [
    activityHistory,
    addHistoryValue,
  ]);

  return (
    <UserActivityHistoryContext.Provider value={contextValue}>
      {children}
    </UserActivityHistoryContext.Provider>
  );
};

UserActivityHistoryContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  withWorkoutContextReady,
  observer,
)(UserActivityHistoryContextProvider);
