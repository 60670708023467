import styled, { css } from 'styled-components';

import Card from '../../Card';
import Note from '../../Note';
import Thumbnail from '../../Thumbnail';

import {
  body1,
  body2,
  caption2,
} from '../../../styles/text';

const topConnector = css`
  &:before {
    content: '';
    display: block;
    border-right: 1px solid ${({ theme }) => theme.colors.system.beta};
    height: 16px;
    position: absolute;
    top: 0;
    left: 50%;
  }
`;

const bottomConnector = css`
  &:after {
    content: '';
    display: block;
    border-right: 1px solid ${({ theme }) => theme.colors.system.beta};
    height: calc(100% - 46px);
    position: absolute;
    bottom: 0;
    left: 50%;
  }
`;

const connectorStyles = ({
  showTopConnector,
  showBottomConnector,
}) => css`
  ${(showTopConnector ? topConnector : null)}
  ${(showBottomConnector ? bottomConnector : null)}
`;

const ActivityTagContainer = styled.div`
  width: 30px;
  margin-right: 8px;
  position: absolute;
  left: 8px;
  height: 100%;
`;

const ActivityTag = styled.div`
  color: ${({ theme }) => theme.colors.base.primary};
  background: ${({ theme }) => theme.colors.system.background};
  border: 1px solid ${({ theme }) => theme.colors.base.primary};
  border-radius: 50%;
  min-width: 30px;
  height: 30px;
  margin-top: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  ${caption2}

  ${(props) => connectorStyles(props)}
`;

const StyledActivityCard = styled(Card)`
  width: unset;
  flex: 1;
  margin-bottom: 8px;
  padding: 16px;
  padding-left: 46px;
  flex-direction: column;
  text-align: left;
`;

const StyledSimpleActivityCard = styled(StyledActivityCard)`
  padding-left: 10px;
`;

const InfoHeaderSection = styled.div`
  display: flex;
`;

const ActivityName = styled.div`
  ${body2}
  margin-bottom: 5px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.base.primary};
  flex: 1;
`;

const Labels = styled.div`
  display: flex;
`;

const ActivityLabel = styled.div`
  ${body2}
  color: ${({ theme }) => theme.colors.base.primary};
  padding: 4px;
  border-radius: 6px;
  width: fit-content;
  background: ${({ theme }) => theme.colors.base.tertiary};
`;

const ActivityNote = styled(Note)`
  opacity: 0.4;
  margin: 20px 0 0;
  font-size: 12px;
  line-height: 18px;
`;

const RestNote = styled.div`
  ${body1}
  font-size: 14px;
  line-height: 18px;
  opacity: 0.4;
  margin-top: 10px;
  padding-top: 11px;
  border-top: 1px solid ${({ theme }) => theme.colors.system.gamma2};
`;

const ExtraContentWrapper = styled.div`
  width: ${({ $width }) => $width};
  margin-left: 4px;
  margin-bottom: 8px;
  background: ${({ theme }) => theme.colors.system.alpha};
`;

const ActivityContainer = styled.div`
  position: relative;
  display: flex;

  ${({ $isHighlighted }) => $isHighlighted && css`
    ${ActivityTag} {
      background: ${({ theme }) => theme.colors.base.secondary};
      color: ${({ theme }) => theme.colors.fonts.primary};
    }
    ${StyledActivityCard} {
      background: ${({ theme }) => theme.colors.base.tertiary};
      color: ${({ theme }) => theme.colors.fonts.primary};
    }
    ${ActivityLabel} {
      background: rgba(255, 255, 255, 0.44);
    }
    ${ActivityName} {
      color: ${({ theme }) => theme.colors.fonts.primary};
    }
    ${ExtraContentWrapper} {
      background: ${({ theme }) => theme.colors.base.secondaryVariant};
      color: ${({ theme }) => theme.colors.fonts.primary};
    }
    ${ActivityNote} {
      div {
        color: ${({ theme }) => theme.colors.fonts.primary};
      }
      svg {
        path {
          fill: ${({ theme }) => theme.colors.fonts.primary};
        }
      }
    }
    ${RestNote} {
      color: ${({ theme }) => theme.colors.fonts.primary};
    }
  `}
`;

const ActivityInfo = styled.div`
  display: flex;
`;

const BasicInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-evenly;
`;

const StyledThumbnail = styled(Thumbnail)`
  margin-right: 8px;
`;

const OverrideIconWrapper = styled.div`
  background: ${({ theme }) => theme.colors.system.background};
  border-radius: 50%;
  height: 20px;
  width: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  ActivityContainer,
  ActivityTagContainer,
  ActivityTag,
  StyledActivityCard,
  StyledSimpleActivityCard,
  InfoHeaderSection,
  ActivityName,
  Labels,
  ActivityLabel,
  ActivityNote,
  RestNote,
  ExtraContentWrapper,
  ActivityInfo,
  BasicInfoWrapper,
  StyledThumbnail,
  OverrideIconWrapper,
};
