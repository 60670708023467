import React from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import { compose } from 'recompose';

import withCurrentUserAccess from '../components/withCurrentUserAccess';
import Gameplay from '../pages/Gameplay';

/**
 * Routes used for gameplay.
 */
const GameplayRoutes = () => {
  const {
    path,
  } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={Gameplay} />
      <Route exact path={`${path}/:gameplaySessionId`} component={Gameplay} />
    </Switch>
  );
};

export default compose(
  withCurrentUserAccess({ forceSameUser: true }),
)(GameplayRoutes);
