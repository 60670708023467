import styled from 'styled-components';

import { headlineStyles, sansSerif } from '../../styles/text';

const Title = styled.div`
  ${headlineStyles};
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  padding-bottom: 10px;
`;

const Description = styled.div`
  ${sansSerif.headlineFontStyles};
  font-size: 15px;
  line-height: 18px;
`;

export {
  Title,
  Description,
};
