import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledToggle,
  ToggleName,
  StyledToggleButton,
} from './styles';

const Toggle = ({
  name,
  isChecked,
  onChange,
  className,
}) => {
  const onChangeHandler = (event) => {
    const {
      detail: {
        checked,
      },
    } = event;

    onChange(checked);
  };

  return (
    <StyledToggle className={className}>
      <ToggleName>{name}</ToggleName>
      <StyledToggleButton
        checked={isChecked}
        onIonChange={onChangeHandler}
      />
    </StyledToggle>
  );
};

Toggle.propTypes = {
  name: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Toggle.defaultProps = {
  className: '',
};

export default Toggle;
