import styled from 'styled-components';

import { ReactComponent as Icon } from '../../../assets/icons/icon_meal.svg';

const MealIcon = styled(Icon)`
  path {
    fill: ${({ theme }) => theme.colors.base.secondaryVariant};
  }
`;

export default MealIcon;
