import styled from 'styled-components';
import { subtitle1 } from '../../../styles/text';

const Container = styled.div`
  ${subtitle1}
  display: flex;
  flex-direction: column;
  position: relative;
`;

const NavigationBody = styled.div`
  flex: 1;
`;

const NavigationFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 90px;
`;

const NavigationList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const NavigationItem = styled.li`
  padding: 17px 0;
  list-style-type: none;
  box-sizing: border-box;
  border-radius: 30px;
  border: 1px solid transparent;
`;

const IconContainer = styled.div`
  margin-right: 20px;
  width: 23px;
  height: 23px;
`;

const FooterElement = styled.div`
  &:before {
    content: '';
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.12);
    position: absolute;
    left: 0;
  }
`;

const AppVersion = styled.div`
  font-size: 13px;
  text-align: left;
  padding: 0 5px 0 26px;
`;

export {
  Container,
  NavigationList,
  NavigationBody,
  NavigationItem,
  NavigationFooter,
  FooterElement,
  IconContainer,
  AppVersion,
};
