import styled from 'styled-components';

import BaseCard from '../../components/Card';
import { sansSerif } from '../../styles/text';

const Card = styled(BaseCard)`
  display: flex;
  flex-direction: column;
  margin: 0 0 8px;
  background: ${({ theme }) => theme.colors.system.alpha};
  padding: 16px;
`;

const CardTitle = styled.div`
  ${sansSerif.headline4};
  color: ${({ theme }) => theme.colors.base.primary};
  text-transform: uppercase;
  margin-bottom: 16px;
`;

const CardNote = styled.div`
  ${sansSerif.headlineFontStyles};
  color: ${({ theme }) => theme.colors.base.primary};
  font-size: 12px;
  margin-bottom: 14px;
`;

export {
  Card,
  CardTitle,
  CardNote,
};
