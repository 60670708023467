import styled, { css } from 'styled-components';

import colors from '../../../../styles/baseColors';
import Note from '../../../../components/Note';

import {
  body1,
  body2,
} from '../../../../styles/text';

const CircuitContainer = styled.div`
  ${({ enableMargins }) => (enableMargins ? css`margin: 40px 0;` : '')}
`;

const CircuitHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 16px 15px;
  min-height: 28px;
`;

const CircuitName = styled.div`
  ${body1}
  color: ${colors.base.primary};
`;

const Rounds = styled.div`
  ${body2}
  min-width: 65px;
  text-align: right;
  color: ${colors.base.primary};
`;

const CircuitNote = styled(Note)`
  opacity: 0.4;
  margin: 15px;
`;

const RestWarning = styled.div`
  color: ${colors.system.error};
  padding-left: 10px;
`;

export {
  CircuitContainer,
  CircuitHeader,
  CircuitName,
  Rounds,
  CircuitNote,
  RestWarning,
};
