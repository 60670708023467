import React from 'react';
import PropTypes from 'prop-types';

import useUserNavigation from '../../hooks/useUserNavigation';
import { ReactComponent as IconComponent } from '../../assets/notification-icon.svg';
import ActionCard from '../ActionCard';
import texts from './texts.json';

const WorkoutReminderCard = ({
  className,
}) => {
  const { navigateToUserPath } = useUserNavigation();
  const navigateToAppSettings = () => navigateToUserPath('settings/workout');

  return (
    <ActionCard
      className={className}
      title={texts.title}
      description={texts.description}
      onClick={navigateToAppSettings}
      icon={IconComponent}
    />
  );
};

WorkoutReminderCard.propTypes = {
  className: PropTypes.string,
};

WorkoutReminderCard.defaultProps = {
  className: '',
};

export default WorkoutReminderCard;
