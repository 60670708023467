/**
 * Custom colors definition for Cronometer.
 *
 * @type {Object}
 */
const colors = {
  base: '#ff6733',
};

export default colors;
