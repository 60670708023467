import styled, { css } from 'styled-components';
import BasePageContent from '../../PageContent';
import {
  getSafeAreaFromHeader,
  safeAreaFromLeft,
  safeAreaFromRight,
} from '../../../styles/mixins';

const getStyles = ({ enablePadding = false }) => {
  const commonPadding = enablePadding ? '10px' : '0px';

  return css`
    padding-left: calc(${safeAreaFromLeft()} + ${commonPadding});
    padding-right: calc(${safeAreaFromRight()} + ${commonPadding});
  `;
};

const PageContent = styled(BasePageContent)`
  height: calc(100% - ${getSafeAreaFromHeader()});
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.system.background};
  color: ${({ theme }) => theme.colors.system.beta};
  margin-top: ${getSafeAreaFromHeader()};
  padding-top: 0;
  ${(props) => getStyles(props)}
`;

export default PageContent;
