import styled, { createGlobalStyle, css } from 'styled-components';

import {
  safeAreaFromLeft,
  safeAreaFromRight,
} from '../../styles/mixins';
import { OrientationTypes } from '../../utils/orientation';
import { SlideDrawerAlignment } from './SlideDrawerAlignment';

const StylesConfig = {
  LANDSCAPE_MAX_WIDTH: 375,
  TRANSITION_VALUE: 'transform .3s',
};

const leftAlignmentRules = (theme) => {
  // If the notch is on the left hand side, we add the safe area value from the left to avoid overlapping.
  const safeAreaValue = (
    theme.orientationType === OrientationTypes.LANDSCAPE_PRIMARY
    || theme.orientationType === OrientationTypes.LANDSCAPE
  ) ? safeAreaFromLeft() : 0;

  return css`
    left: 0;
    max-width: calc(${StylesConfig.LANDSCAPE_MAX_WIDTH}px + ${safeAreaValue});
    padding-left: ${safeAreaValue};
  `;
};

const centerAlignmentRules = () => css`
  left: 50%;
  margin-left: -${StylesConfig.LANDSCAPE_MAX_WIDTH / 2}px;
`;

const rightAlignmentRules = (theme) => {
  // If the notch is on the right hand side, we add the safe area value from the right to avoid overlapping.
  const safeAreaValue = (
    theme.orientationType === OrientationTypes.LANDSCAPE_SECONDARY
    || theme.orientationType === OrientationTypes.LANDSCAPE
  ) ? safeAreaFromRight() : 0;

  return css`
    right: 0;
    max-width: calc(${StylesConfig.LANDSCAPE_MAX_WIDTH}px + ${safeAreaValue});
    padding-right: ${safeAreaValue};
  `;
};

const rulesMap = {
  [SlideDrawerAlignment.LEFT]: leftAlignmentRules,
  [SlideDrawerAlignment.CENTER]: centerAlignmentRules,
  [SlideDrawerAlignment.RIGHT]: rightAlignmentRules,
};

const getLandscapeRules = ({
  theme,
  $alignment,
}) => {
  if (!theme.isPortrait) {
    return css`
      max-width: ${StylesConfig.LANDSCAPE_MAX_WIDTH}px;
      ${rulesMap[$alignment](theme)}
    `;
  }
  return null;
};

const Backdrop = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.system.beta};
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  transition: .3s;
`;

/*
  We need to use the attrs api because this component is changed many times per second. This way we avoid creating
  extra class names that will be used only for a split of a second. We use transient props here to avoid passing the
  prop to the DOM. This will be consumed only by this styled component.
*/
const SlideDrawerContainer = styled.div.attrs(({ $positionY }) => (
  $positionY > 0 && ({
    style: {
      transform: `translateY(${$positionY}px)`,
    },
  })
))`
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 99;
  background-color: ${({ theme }) => theme.colors.system.alpha};
  border-radius: 30px 30px 0 0;
  transition: ${StylesConfig.TRANSITION_VALUE};
  overflow: hidden;
  height: ${({ $height }) => $height || '90%'};
  max-height: 90%;
  display: flex;

  ${(props) => getLandscapeRules(props)}

  &::before {
    content: '';
    height: 2px;
    width: 36px;
    top: 8px;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.system.beta};
    left: 50%;
    transform: translateX(-50%);
  }
`;

const Drawer = styled.div`
  position: relative;
  margin-top: ${({ $useTransparentHandle }) => ($useTransparentHandle ? 0 : '20px')};
  max-height: calc(100% - 20px);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
  left: 0;
  top: 0;
  transition: .3s;
  overflow: hidden;

  ${({ isOpen }) => {
    if (isOpen) {
      return css`
        z-index: 98;

        ${Backdrop} {
          opacity: 0.7;
        }
        ${SlideDrawerContainer} {
          transform: translateY(0);
        }
      `;
    }

    /* Move the Container to the bottom when closed. */
    return css`
      ${SlideDrawerContainer} {
        transform: translateY(100%);
      }
    `;
  }}
`;

const OverflowHidden = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

export {
  Container,
  Backdrop,
  SlideDrawerContainer,
  Drawer,
  OverflowHidden,
};
