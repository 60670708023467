import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Side,
  SideLabel,
} from '../../../utils/sides';
import Select from '../../Select';
import texts from './texts.json';

const SideSelector = ({
  side,
  onChange,
}) => {
  const sidesOptions = useMemo(() => {
    const sidesOrder = [
      Side.ALTERNATE_SIDES,
      Side.LEFT_SIDE,
      Side.RIGHT_SIDE,
      Side.LEFT_THEN_RIGHT_SIDE,
    ];

    return sidesOrder.map((item) => ({
      value: item,
      label: SideLabel[item],
    }));
  }, []);

  return (
    <Select
      label={texts.side}
      value={side}
      isClearable="true"
      name={texts.side}
      options={sidesOptions}
      onChange={onChange}
    />
  );
};

SideSelector.propTypes = {
  side: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

SideSelector.defaultProps = {
  side: null,
};

export default SideSelector;
