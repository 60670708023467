import React, {
  useContext,
  useEffect,
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import ExerciseOverrideSelectorContext from '../context/ExerciseOverrideSelectorContext';
import {
  searchByName,
} from '../exercisesSearch';
import SearchExercises from './SearchExercises';

const SearchExercisesContainer = ({
  className,
}) => {
  const {
    allExercisesRef,
    setIsSearchEnabled,
    logExerciseOverrideEvent,
    searchText,
    setSearchText,
  } = useContext(ExerciseOverrideSelectorContext);

  const [exercises, setExercises] = useState([]);

  useEffect(() => {
    logExerciseOverrideEvent('exerciseOverrideSearchView');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // When searchText exists, filter the entire exercises list by name
    const filteredExercises = searchText
      ? searchByName(allExercisesRef.current, searchText)
      : allExercisesRef.current;

    setExercises(filteredExercises);
  }, [
    searchText,
    allExercisesRef,
  ]);

  const onTextChange = useCallback(({ detail }) => setSearchText(detail.value), [setSearchText]);

  const onCancelSearch = useCallback(() => setIsSearchEnabled(false), [setIsSearchEnabled]);

  return (
    <SearchExercises
      className={className}
      text={searchText}
      exercises={exercises}
      onTextChange={onTextChange}
      onCancel={onCancelSearch}
    />
  );
};

SearchExercisesContainer.propTypes = {
  className: PropTypes.string,
};

SearchExercisesContainer.defaultProps = {
  className: '',
};

export default SearchExercisesContainer;
