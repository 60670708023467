import React, { useContext } from 'react';

import LoadingPage from '../../components/LoadingPage';

import UserExerciseOverridesContextProvider from './UserExerciseOverridesContextProvider';
import UserExerciseOverridesContext from './UserExerciseOverridesContext';

/**
 * Inserts UserExerciseOverridesContextProvider in the react tree before adding the given component.
 *
 * @param {Object} Component The component that needs the context provider.
 * @returns {Object} The component to render wrapped with the provider.
 */
const withUserExerciseOverridesContextProvider = (Component) => (props) => (
  <UserExerciseOverridesContextProvider>
    <Component {...props} />
  </UserExerciseOverridesContextProvider>
);

/**
 * Renders the given component when the context is ready.
 *
 * @param {Object} Component The component to render.
 * @param {Object} Component The rendered component.
 * @returns {Object} The component to render or the LoadingPage component.
 */
const withUserExerciseOverridesContextReady = (Component) => (props) => {
  const { isReady } = useContext(UserExerciseOverridesContext);
  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withUserExerciseOverridesContextReady,
  withUserExerciseOverridesContextProvider,
};
