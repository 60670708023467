import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import UserActivityHistoryContext from '../../../../context/UserActivityHistoryContext';
import UserContext from '../../../../context/UserContext';
import { DateFormat } from '../../../../utils/date';
import {
  MeasurementSystem,
  convertUnits,
  WeightUnit,
} from '../../../../utils/measurement';
import { ContentSwitcherContext } from '../../../ContentSwitcher';
import WorkoutInfoSwitcherContext from '../../context/WorkoutInfoSwitcherContext';
import {
  StyledHistoryData,
  DateWrapper,
  ValuesWrapper,
  HistoryValue,
  Value,
  Label,
  StyledPencilIcon,
} from './styles';
import texts from './texts.json';

const ActivityHistoryData = ({
  activity,
}) => {
  const { activityHistory } = useContext(UserActivityHistoryContext);
  const { goToSectionByName } = useContext(ContentSwitcherContext);
  const { setCurrentActivity } = useContext(WorkoutInfoSwitcherContext);
  const {
    userConfigDoc: {
      measurementSystem,
    },
  } = useContext(UserContext);

  const onClick = () => {
    setCurrentActivity(activity);
    goToSectionByName('activityLog');
  };

  const renderContent = () => {
    // Extract a previously saved value
    const historyDoc = activityHistory[activity.name];

    // If there's no data saved, then we show the edit icon.
    if (!historyDoc || !historyDoc.latestValue) {
      return <StyledPencilIcon />;
    }

    const {
      weight,
      reps,
      date,
      measurementSystem: latestValueMeasurementSystem = MeasurementSystem.IMPERIAL,
    } = historyDoc.latestValue;

    return (
      <>
        <DateWrapper>{moment(date).format(DateFormat.SHORT_DATE_FORMAT)}</DateWrapper>
        <ValuesWrapper>
          {!!weight && (
            <HistoryValue>
              <Value>
                {convertUnits(weight, WeightUnit[latestValueMeasurementSystem], WeightUnit[measurementSystem])}
              </Value>
              <Label>{measurementSystem === MeasurementSystem.IMPERIAL ? texts.lbs : texts.kg}</Label>
            </HistoryValue>
          )}
          {!!reps && (
            <HistoryValue>
              <Value>{reps}</Value>
              <Label>{texts.reps}</Label>
            </HistoryValue>
          )}
        </ValuesWrapper>
      </>
    );
  };

  return (
    <StyledHistoryData onClick={onClick}>
      {renderContent()}
    </StyledHistoryData>
  );
};

ActivityHistoryData.propTypes = {
  activity: PropTypes.object.isRequired,
};

export default compose(
  observer,
)(ActivityHistoryData);
