import videoSizeConfig from '../config';
import BaseRecorder from './BaseRecorder';

class SelfieCameraStreamRecorder extends BaseRecorder {
  constructor(drawParts) {
    super(drawParts);
    this.selfieImage = new Image();
    this.aspectRatio = 1;
  }

  ready = (onReady) => {
    if (this.hasValidPreview) {
      onReady();
    }
  }

  isReadyToCapture = () => this.hasValidPreview;

  /**
   * Capture the image by using `CameraContext.captureImage` API. This will return a Blob
   * image with metadata to calculate aspect ratio.
   *
   * @param {Object} cameraContext
   * @returns {Promise}
   */
  captureImage = async (cameraContext) => {
    const options = {
      width: videoSizeConfig.selfie.width,
    };

    const { image: cameraData, width, height } = await cameraContext.camera.captureImage(options);

    const aspectRatio = width / height;
    const hasAspectRatioChanged = aspectRatio !== this.aspectRatio;

    if (hasAspectRatioChanged) {
      this.aspectRatio = aspectRatio;
    }

    return {
      cameraData,
      hasAspectRatioChanged,
      aspectRatio: this.aspectRatio,
    };
  }

  /**
   * In order to capture an image to process via posenet, we need an HTMLElement, in this case an Image
   * that loads using Blob URL the given image.
   *
   * @param {Object} cameraData A Blob image.
   * @param {number} aspectRatio The aspect ratio of cameraData.
   * @returns {Promise} A promise that is always resolved when the image is loaded.
   */
  prepareForImageProcessing = async (cameraData, aspectRatio) => (
    new Promise((resolve) => {
      const onSelfieImageLoad = async () => {
        this.drawSelfie(this.selfieImage, aspectRatio);

        this.selfieImage.onload = null;

        resolve();
      };

      URL.revokeObjectURL(this.selfieImage.src);

      this.selfieImage.onload = onSelfieImageLoad;

      this.selfieImage.src = URL.createObjectURL(cameraData);
    }));

  /**
   * Revoke any Blob URL it might exist already in order to prevent memory leaks.
   */
  cleanCapturedImage = () => {
    URL.revokeObjectURL(this.selfieImage.src);
    this.selfieImage.src = null;
  }

  clear = () => this.cleanCapturedImage();
}

export default SelfieCameraStreamRecorder;
