import React, {
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import useMediaRecorder from '../../../../hooks/useMediaRecorder';
import TimeCounter from '../../../../../components/TimeCounter';
import LoadingOverlay from '../../../../../components/LoadingOverlay';

import {
  Container,
  AudioRecorderWrapper,
  StyledAudioPlayer,
  AudioPlayerWrapper,
  StyledButton,
  StyledMicIcon as MicIcon,
  StyledStopIcon as StopIcon,
  StyledClearButton,
} from './styles';
import texts from './texts.json';

const AudioAttachmentsContainer = ({
  onAudioRecorded,
  onRemove,
  isUploading,
}) => {
  const [audioFileDataUrl, setAudioFileDataUrl] = useState('');

  const revokeDataUrl = useCallback(() => {
    if (audioFileDataUrl) {
      URL.revokeObjectURL(audioFileDataUrl);
      setAudioFileDataUrl('');
    }
  }, [
    audioFileDataUrl,
  ]);

  const removeAudioAttachment = useCallback(() => {
    onRemove();
    revokeDataUrl();
  }, [
    onRemove,
    revokeDataUrl,
  ]);

  const onAudioDataAvailable = useCallback((recordedAudioFile) => {
    // Revoke data url from previous recording (if exists)
    revokeDataUrl();
    const file = new File(
      [recordedAudioFile.data],
      `Voice-Recording-${new Date().toTimeString().split(' ')[0]}.${recordedAudioFile.data.type.split('/')[1]}`,
      {
        type: recordedAudioFile.data.type,
        lastModified: Date.now(),
      },
    );
    setAudioFileDataUrl(URL.createObjectURL(file));
    onAudioRecorded(file);
  }, [
    revokeDataUrl,
    onAudioRecorded,
  ]);

  const {
    isRecording,
    startRecording,
    stopRecording,
  } = useMediaRecorder(onAudioDataAvailable);

  const renderAudioInput = useCallback(() => {
    if (isRecording) {
      return (
        <AudioRecorderWrapper>
          <StyledButton onClick={stopRecording}>
            <StopIcon />
          </StyledButton>
          <TimeCounter run />
        </AudioRecorderWrapper>
      );
    }
    return (
      <StyledButton onClick={startRecording}>
        <MicIcon />
        {texts.recordAudio}
      </StyledButton>
    );
  }, [
    isRecording,
    stopRecording,
    startRecording,
  ]);

  const renderAudioPlayer = useCallback(() => {
    if (audioFileDataUrl) {
      return (
        <AudioPlayerWrapper>
          <StyledAudioPlayer
            controls
            src={audioFileDataUrl}
          />
          <StyledClearButton onClick={removeAudioAttachment} />
        </AudioPlayerWrapper>
      );
    }
    return null;
  }, [
    audioFileDataUrl,
    removeAudioAttachment,
  ]);

  return (
    <Container>
      {renderAudioInput()}
      {renderAudioPlayer()}
      <LoadingOverlay isLoading={isUploading && audioFileDataUrl !== ''} />
    </Container>
  );
};

AudioAttachmentsContainer.propTypes = {
  onAudioRecorded: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  isUploading: PropTypes.bool.isRequired,
};

export default AudioAttachmentsContainer;
