import styled, { css } from 'styled-components';
import Card from '../../components/Card';

const StyledCard = styled(Card)`
  flex-direction: column;
  max-width: 500px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;

  ${StyledCard} {
    margin-bottom: 10px;
  }
`;

const itemStyles = ({ column = false }) => {
  if (column) {
    return css`
      flex-direction: column;
      align-items: start;
    `;
  }
  return css`
    flex-direction: row;
    align-items: center;
  `;
};

const Item = styled.div`
  display: flex;
  justify-content: space-between;

  ${(props) => itemStyles(props)}
`;

export {
  Container,
  StyledCard,
  Item,
};
