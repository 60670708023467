import React, {
  useState,
  useCallback,
} from 'react';
import moment from 'moment';
import { IonLabel } from '@ionic/react';
import PropTypes from 'prop-types';

import { DateFormat } from '../../utils/date';
import CalendarSlideDrawer from './CalendarSlideDrawer';

import {
  DateContainer,
  IconContainer,
  StyledCalendarIcon,
} from './styles';

const CalendarSlider = ({
  currentSelectedDateTime,
  onChange,
  displayFormat,
  ...props
}) => {
  const [isSliderOpen, setIsSliderOpen] = useState(false);

  const onClose = useCallback(() => {
    setIsSliderOpen(false);
  }, []);

  const onOpen = useCallback(() => {
    setIsSliderOpen(true);
  }, []);

  const placeHolderText = props?.presentation === 'time' ? 'Select Time' : 'Select Date';

  return (
    <>
      <DateContainer onClick={onOpen}>
        {props?.presentation !== 'time' && (
          <IconContainer>
            <StyledCalendarIcon />
          </IconContainer>
        )}
        <IonLabel>
          {currentSelectedDateTime ? moment(currentSelectedDateTime).format(displayFormat) : placeHolderText}
        </IonLabel>
      </DateContainer>
      <CalendarSlideDrawer
        currentSelectedDateTime={currentSelectedDateTime}
        isOpen={isSliderOpen}
        onClose={onClose}
        onChange={onChange}
        displayFormat={displayFormat}
        {...props}
      />
    </>
  );
};

CalendarSlider.propTypes = {
  currentSelectedDateTime: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  displayFormat: PropTypes.string,
  presentation: PropTypes.string,
};

CalendarSlider.defaultProps = {
  displayFormat: DateFormat.LONG_MONTH_WITH_DAY_YEAR,
  presentation: 'date',
};

export default CalendarSlider;
