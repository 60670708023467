import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { App } from '@capacitor/app';
import { PowerMode } from 'capacitor-power-mode';
import { Network } from '@capacitor/network';
import { SplashScreen } from '@capacitor/splash-screen';
import { useHistory } from 'react-router-dom';

import { isIOS } from '../../utils/platform';
import {
  addOrientationChangeEventListener,
  removeOrientationChangeEventListener,
  isPortrait,
  getOrientationType,
} from '../../utils/orientation';
import { checkHealthAvailable } from '../../services/health';
import useComponentLoadingTime from '../../hooks/useComponentLoadingTime';
import useComponentMounted from '../../hooks/useComponentMounted';
import { setUserTraits } from '../../utils/logger';
import AppContext from './AppContext';
import useDebugSettings from './useDebugSettings';
import useLiveUpdateHook from './useLiveUpdateHook';
import AppVersion from './AppVersion';

// Timeout in ms for the isBackOnline state
const BACK_ONLINE_TIMEOUT_MS = 3000;

const AppContextProvider = ({
  children,
}) => {
  const [isReady, setIsReady] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [isLowPowerModeEnabled, setIsLowPowerModeEnabled] = useState(false);
  const [isPortraitMode, setIsPortraitMode] = useState(() => isPortrait());
  const [orientationType, setOrientationType] = useState(() => getOrientationType());
  const [networkStatus, setNetworkStatus] = useState({});
  const [isBackOnline, setIsBackOnline] = useState(false);
  // TODO: we need to add all these menus (names) to a global state and have an
  // active/enabled state. Or alternatively try to get the menuContoller provided by
  // ionic to work
  const sideMenuRef = useRef();
  const { startLoading, finishLoading } = useComponentLoadingTime('appContext');

  const history = useHistory();

  const [appVersion] = useState(() => {
    const version = new AppVersion();
    version.load();
    return version;
  });

  /**
   * Flag that indicates if Health data (Apple Health or Google Fit is available on this device and platform).
   * @type {boolean} True when it's available, otherwise false.
   */
  const [isHealthAvailable, setIsHealthAvailable] = useState(false);

  // Expose debug settings within this context provider
  const debugSettings = useDebugSettings();

  const { preInstallVersionHook } = useLiveUpdateHook();

  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    preInstallVersionHook();
  }, [
    preInstallVersionHook,
  ]);

  useEffect(() => {
    // Update user properties with latest available app versions
    setUserTraits({
      version: appVersion.displayVersions,
      ...appVersion.versions,
    });
  }, [
    appVersion.displayVersions,
    appVersion.versions,
  ]);

  // This effect sets isBackOnline back to false after BACK_ONLINE_TIMEOUT_MS
  useEffect(() => {
    if (isBackOnline) {
      setTimeout(() => {
        if (isComponentMountedRef.current) {
          setIsBackOnline(false);
        }
      }, BACK_ONLINE_TIMEOUT_MS);
    }
  }, [
    isBackOnline,
    isComponentMountedRef,
  ]);

  useEffect(() => {
    let shouldUpdate = true;
    let powerStateChangeListener;
    const isIOSPlatform = isIOS();

    const appStateChangeListener = App.addListener('appStateChange', ({ isActive: isAppActive }) => {
      setIsActive(isAppActive);
      // Also update isPortraitMode in case the orientation changed while the app wasn't active
      if (isAppActive) {
        setIsPortraitMode(isPortrait());
        setOrientationType(getOrientationType());
      }
    });

    if (isIOSPlatform) {
      powerStateChangeListener = PowerMode.addListener('powerStateChanged', ({
        isLowPowerModeEnabled: lowPowerModeActive,
      }) => {
        setIsLowPowerModeEnabled(lowPowerModeActive);
      });
    }

    const networkStatusListener = Network.addListener('networkStatusChange', (status) => {
      setNetworkStatus((prev) => {
        const backOnline = status.connected && prev && !prev.connected;
        setIsBackOnline(backOnline);
        return status;
      });
    });

    // App opened from an url (deep link)
    const appUrlListener = App.addListener('appUrlOpen', (event) => {
      // Example url: https://mobile-system2.web.app/tabs/tab2
      // slug = /tabs/tab2
      const slug = event.url.split('.app').pop();
      if (slug) {
        history.push(slug);
      }
      // If no match, do nothing - let regular routing
      // logic take over
    });

    const handleOrientationChange = () => {
      setIsPortraitMode(isPortrait());
      setOrientationType(getOrientationType());
    };

    addOrientationChangeEventListener(handleOrientationChange);

    /*
      Initializes the app context provider by calling any async API that is involved
      in the initialization process, then once it's done set the readiness status
      to "true".
     */
    const init = async () => {
      startLoading();
      let lowPowerModeActive = false;
      if (isIOSPlatform) {
        ({ isLowPowerModeEnabled: lowPowerModeActive } = await PowerMode.getPowerModeState());
      }

      const networkData = await Network.getStatus();

      const isHealthAvailableValue = await checkHealthAvailable();

      await SplashScreen.hide();

      if (shouldUpdate) {
        setIsHealthAvailable(isHealthAvailableValue);
        setIsReady(true);
        finishLoading();
        setIsLowPowerModeEnabled(lowPowerModeActive);
        setNetworkStatus(networkData);
      }
    };

    init();

    return () => {
      shouldUpdate = false;
      appStateChangeListener.remove();
      networkStatusListener.remove();
      appUrlListener.remove();
      removeOrientationChangeEventListener(handleOrientationChange);

      if (powerStateChangeListener) {
        powerStateChangeListener.remove();
      }
    };
  }, [
    history,
    startLoading,
    finishLoading,
  ]);

  const contextValue = useMemo(() => ({
    isActive,
    isReady,
    isLowPowerModeEnabled,
    isPortraitMode,
    orientationType,
    networkStatus,
    isBackOnline,
    isHealthAvailable,
    debugSettings,
    appVersion,
    sideMenuRef,
  }), [
    isActive,
    isReady,
    isLowPowerModeEnabled,
    isPortraitMode,
    orientationType,
    networkStatus,
    isBackOnline,
    isHealthAvailable,
    debugSettings,
    appVersion,
    sideMenuRef,
  ]);

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default observer(AppContextProvider);
