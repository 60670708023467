import styled from 'styled-components';

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.system.alpha};
  padding: 8px 0;
  width: 100%;
`;

const StyledInput = styled.input`
  padding: 4px 8px;
  background: ${({ theme }) => theme.colors.system.background};
  border: none;
  line-height: 40px;
  flex: 4;
  font-size: 15px;
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.system.gamma2};
    outline: none;
  }
  &:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  &:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;

const InputTitle = styled.div`
  text-align: left;
  padding: 0 10px 0 20px;
  border-radius: 6px 0 0 6px;
  background: ${({ theme }) => theme.colors.system.gamma1};
  line-height: 50px;
  font-size: 15px;
  flex: 2;
`;

const InputLabel = styled.div`
  text-align: center;
  padding: 5px 10px;
  background: ${({ theme }) => theme.colors.system.background};
  border-radius: 0 6px 6px 0;
  line-height: 40px;
  font-size: 15px;
  flex: 1;
`;

export {
  InputContainer,
  StyledInput,
  InputLabel,
  InputTitle,
};
