import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as OverrideIcon } from '../../../assets/icons/icon_override.svg';
import BaseActivity from '../../../models/BaseActivity';

import {
  ActivityContainer,
  StyledSimpleActivityCard,
  InfoHeaderSection,
  ActivityName,
  ExtraContentWrapper,
  ActivityInfo,
  BasicInfoWrapper,
  StyledThumbnail,
  OverrideIconWrapper,
} from './styles';

const SimpleActivityCard = ({
  activity,
  tag,
  ExtraContent,
  ExtraContentWidth,
  isHighlighted,
  onClick,
}) => {
  const {
    name,
    videoPreviewThumbnail,
    originalActivity,
  } = activity;

  return (
    <>
      <ActivityContainer $isHighlighted={isHighlighted}>
        <StyledSimpleActivityCard onClick={onClick}>
          <ActivityInfo>
            {!!videoPreviewThumbnail && (
              <StyledThumbnail url={videoPreviewThumbnail} />
            )}
            <BasicInfoWrapper>
              <InfoHeaderSection>
                <ActivityName>{name}</ActivityName>
                {!!originalActivity && (
                  <OverrideIconWrapper>
                    <OverrideIcon />
                  </OverrideIconWrapper>
                )}
              </InfoHeaderSection>
            </BasicInfoWrapper>
          </ActivityInfo>
        </StyledSimpleActivityCard>

        {!!ExtraContent && (
          <ExtraContentWrapper $width={ExtraContentWidth}>
            <ExtraContent
              activity={activity}
              tag={tag}
              isHighlighted={isHighlighted}
            />
          </ExtraContentWrapper>
        )}
      </ActivityContainer>
    </>
  );
};

SimpleActivityCard.propTypes = {
  activity: PropTypes.instanceOf(BaseActivity).isRequired,
  tag: PropTypes.string.isRequired,
  ExtraContent: PropTypes.elementType,
  isHighlighted: PropTypes.bool,
  onClick: PropTypes.func,
  ExtraContentWidth: PropTypes.string,
};

SimpleActivityCard.defaultProps = {
  ExtraContent: null,
  isHighlighted: false,
  onClick: () => {},
  ExtraContentWidth: '70px',
};

export default SimpleActivityCard;
