import React, { useContext } from 'react';
import { Chat } from 'stream-chat-react';
import 'stream-chat-react/dist/css/index.css';

import ChatContext from '../../context';
import '../../assets/css/Chat.css';
import ChatState from '../../context/states';

import MultipleChannelView from '../MultipleChannelView';
import SingleChannelView from '../SingleChannelView';
import ChatNotificationView from '../Notification';
import MessagingBroadcastView from '../MessagingBroadcastView/MessagingBroadcastView';

import texts from './texts.json';

const ChatView = () => {
  const {
    chatClient,
    chatState,
    isMultiChannelView,
    isBroadcastOpen,
  } = useContext(ChatContext);

  if (chatState === ChatState.CHAT_ERROR) {
    return <ChatNotificationView notificationMessage={texts.chatViewErrorMessage} />;
  }

  if (isBroadcastOpen) {
    return <MessagingBroadcastView />;
  }

  return (
    <Chat client={chatClient} theme="messaging light">
      {isMultiChannelView ? <MultipleChannelView /> : <SingleChannelView />}
    </Chat>
  );
};

export default ChatView;
