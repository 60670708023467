import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import WorkoutFeedView from '../../../../models/WorkoutFeedView';
import useWorkoutNavigation from '../../../../hooks/useWorkoutNavigation';
import ActiveWorkoutCard from './ActiveWorkoutCard';

const ActiveWorkoutCardContainer = ({
  workoutFeedView,
}) => {
  const { navigateToWorkout } = useWorkoutNavigation();

  const onGoToWorkoutDetails = useCallback(() => {
    navigateToWorkout(workoutFeedView.id, workoutFeedView.type);
  }, [
    navigateToWorkout,
    workoutFeedView.id,
    workoutFeedView.type,
  ]);

  return (
    <ActiveWorkoutCard
      title={workoutFeedView.title}
      onStartWorkout={onGoToWorkoutDetails}
    />
  );
};

ActiveWorkoutCardContainer.propTypes = {
  workoutFeedView: PropTypes.instanceOf(WorkoutFeedView).isRequired,
};

export default ActiveWorkoutCardContainer;
