import styled from 'styled-components';

import { ReactComponent as NextIcon } from '../../../../../assets/icons/next-icon.svg';

const StyledNextIcon = styled(NextIcon)`
  margin-left: 4px;
  path {
    stroke: ${({ theme }) => theme.colors.system.alpha};
  }
`;

export {
  StyledNextIcon,
};
