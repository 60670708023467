import React, {
  useContext,
} from 'react';
import {
  Route,
  Redirect,
  useRouteMatch,
} from 'react-router-dom';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { IonRouterOutlet } from '@ionic/react';

import MealPlanContext, {
  withMealPlanContextReady,
} from '../context/MealPlanContext';
import MealPlan from '../pages/MealPlan';
import {
  MealDetailsContainer,
  RecipeDetailsContainer,
} from '../pages/MealDetails';

/**
 * Routes used for meal plan.
 */
const MealPlanRoutes = () => {
  const { path } = useRouteMatch();
  const { hasMealPlan } = useContext(MealPlanContext);

  if (hasMealPlan) {
    return (
      <IonRouterOutlet ionPage>
        <Route exact path={`${path}/bucket/:bucket/meal/:meal/details`} component={MealDetailsContainer} />
        <Route exact path={`${path}/recipe/:recipeId`} component={RecipeDetailsContainer} />
        <Route exact path={path} component={MealPlan} />
      </IonRouterOutlet>
    );
  }

  return (
    <IonRouterOutlet>
      <Route exact path={`${path}/recipe/:recipeId`} component={RecipeDetailsContainer} />
      <Route exact path={path}>
        <Redirect to={{ pathname: '/' }} />
      </Route>
    </IonRouterOutlet>
  );
};

export default compose(
  observer,
  withMealPlanContextReady,
)(MealPlanRoutes);
