import React, {
  useCallback,
  useState,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

import LoggedInUserContext from '../../../context/LoggedInUserContext';
import EditFeedbackSection from './EditFeedbackSection';

const EditFeedbackSectionContainer = ({
  latestGameplaySession,
  activityFeedbacks,
  selectedActivityIndex,
  setIsReady,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [workoutFeedback, setWorkoutFeedback] = useState(latestGameplaySession.coachFeedback);
  const [activities, setActivities] = useState(JSON.parse(JSON.stringify(latestGameplaySession.activities)));
  const [unsaved, setUnsaved] = useState(false);

  const {
    userId: loggedInUserId,
  } = useContext(LoggedInUserContext);

  const onSubmitFeedbackClick = useCallback(async () => {
    setIsLoading(true);
    await latestGameplaySession.updateFields({
      coachFeedback: workoutFeedback,
      coachFeedbackId: loggedInUserId,
      activities,
    });
    setIsReady(false);
    setUnsaved(false);
    setIsLoading(false);
  }, [
    activities,
    latestGameplaySession,
    setIsReady,
    workoutFeedback,
    loggedInUserId,
  ]);

  return (
    <EditFeedbackSection
      onSubmitFeedbackClick={onSubmitFeedbackClick}
      selectedActivityIndex={selectedActivityIndex}
      latestGameplaySession={latestGameplaySession}
      activityFeedbacks={activityFeedbacks}
      setWorkoutFeedback={setWorkoutFeedback}
      isLoading={isLoading}
      activities={activities}
      setActivities={setActivities}
      unsaved={unsaved}
      setUnsaved={setUnsaved}
    />
  );
};

EditFeedbackSectionContainer.propTypes = {
  latestGameplaySession: PropTypes.object.isRequired,
  activityFeedbacks: PropTypes.array.isRequired,
  selectedActivityIndex: PropTypes.number.isRequired,
  setIsReady: PropTypes.func.isRequired,
};

export default EditFeedbackSectionContainer;
