import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { InlineWidget } from 'react-calendly';
import { IonAlert } from '@ionic/react';

import CoachContactContext, {
  withCoachContactContextReady,
} from '../../context/CoachContactContext';
import { Feature } from '../../context/AppCustomizationContext';
import UserContext from '../../context/UserContext';
import LoadingPage from '../../components/LoadingPage';
import { withCustomizedLayout } from '../../components/Layout';
import withFeatureEnabled from '../../components/withFeatureEnabled';
import useHomeNavigation from '../../hooks/useHomeNavigation';
import useComponentMounted from '../../hooks/useComponentMounted';

import { Container } from './styles';
import { schedulingTaskCompletedMessage } from './texts.json';

const CalendlyEvent = {
  EVENT_SCHEDULED: 'calendly.event_scheduled',
};

const ScheduleCoachCall = () => {
  const {
    coachDoc,
    canContactCoach,
  } = useContext(CoachContactContext);
  const {
    userDoc,
  } = useContext(UserContext);
  const [isCoachCallScheduled, setCoachCallScheduled] = useState(false);
  const [shouldRedirect, setRedirect] = useState(!canContactCoach);
  const { navigateBackHome } = useHomeNavigation();
  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    if (shouldRedirect) {
      navigateBackHome();
    }
  }, [
    navigateBackHome,
    shouldRedirect,
  ]);

  useEffect(() => {
    const listener = ({ data }) => {
      if (isComponentMountedRef.current && data.event === CalendlyEvent.EVENT_SCHEDULED) {
        setCoachCallScheduled(true);
      }
    };
    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, [
    isComponentMountedRef,
  ]);

  const onSuccessAlertDismissed = () => {
    setRedirect(true);
  };

  if (shouldRedirect) {
    return <LoadingPage />;
  }

  return (
    <Container>
      <InlineWidget
        styles={{
          width: '90%',
          height: '100%',
        }}
        url={coachDoc.calendlySchedulerURL}
        prefill={{
          name: userDoc.firstName,
          email: userDoc.email,
        }}
      />
      {isCoachCallScheduled && (
        <IonAlert
          isOpen
          message={schedulingTaskCompletedMessage}
          onDidDismiss={onSuccessAlertDismissed}
          buttons={[
            {
              text: 'OK',
            },
          ]}
        />
      )}
    </Container>
  );
};

export default compose(
  withFeatureEnabled(Feature.COACH_CALL_SCHEDULING),
  withCustomizedLayout({ enablePadding: false }),
  withCoachContactContextReady,
  observer,
)(ScheduleCoachCall);
