import * as Sentry from '@sentry/browser';

import { isIOS } from '../../utils/platform';
import logEvent from '../../utils/logger';

const HealthDataTypes = {
  APPLE_ACTIVITY_SUMMARY: 'apple_activity_summary',
  WORKOUTS: 'workouts', // This is only available in iOS. Need to change if we integrate Google Fit later on.
  HEART_RATE: 'heart_rate',
  ACTIVE_CALORIES: 'calories.active',
  BASAL_CALORIES: 'calories.basal',
  DISTANCE: 'distance',
  STEPS: 'steps',
};

const readAppleHealthDataTypes = Object.values(HealthDataTypes).sort();

const writeAppleHealthDataTypes = [
  HealthDataTypes.WORKOUTS,
  HealthDataTypes.HEART_RATE,
  HealthDataTypes.DISTANCE,
  HealthDataTypes.ACTIVE_CALORIES,
  HealthDataTypes.BASAL_CALORIES,
];

/**
 * The health data version keeps track of the already approved apple health data types (read and write).
 * @type {string}
 */
const HEALTH_DATA_VERSION = `${readAppleHealthDataTypes.join('+')};write:${writeAppleHealthDataTypes.join('+')}`;

const checkHealthAvailable = () => (
  new Promise((resolve) => {
    if (isIOS() && navigator.health) {
      navigator.health.isAvailable((isAvailable) => {
        resolve(isAvailable);
      }, (error) => {
        Sentry.captureException(new Error(error), {
          extra: {
            description: 'Error checking if health data is available on the device using "checkHealthAvailable" API',
          },
        });
        resolve(false);
      });
    } else {
      resolve(false);
    }
  })
);

/**
 * Requests all read and write permissions both App and watch app needs.
 *
 * @returns {Promise}
 */
const requestAppPermissions = () => (new Promise((resolve, reject) => {
  const dataTypes = [
    {
      read: readAppleHealthDataTypes,
      write: writeAppleHealthDataTypes,
    },
  ];

  navigator.health.requestAuthorization(dataTypes, () => {
    logEvent('healthDataAccessAuthorizationRequested');
    resolve();
  }, (error) => {
    logEvent('errorRequestingHealthDataAuthorization', { error });
    reject(error);
  });
}));

export {
  checkHealthAvailable,
  HealthDataTypes,
  HEALTH_DATA_VERSION,
  readAppleHealthDataTypes,
  writeAppleHealthDataTypes,
  requestAppPermissions,
};
