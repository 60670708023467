import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import format from 'string-template';
import ReactHtmlParser from 'react-html-parser';

import logEvent from '../../utils/logger';

import useAuthentication from '../../hooks/useAuthentication';
import useComponentMounted from '../../hooks/useComponentMounted';
import useSessionStore from '../../hooks/useSessionStore';

import PageContainer from './components/PageContainer';
import LoginForm from './components/LoginForm';
import ForgotPassword from './components/ForgotPassword';

import {
  ErrorMessage,
  Container,
  StyledSpinner,
  InfoMessage,
  StyledButton,
  LoginNotice,
  ContactSupport,
} from './styles';

import texts from './texts.json';

const Login = () => {
  const sessionStore = useSessionStore();
  const [loginError, setLoginError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const location = useLocation();
  const isComponentMountedRef = useComponentMounted();
  const {
    sendAuthenticationEmail,
    signInWithEmailAndPassword,
  } = useAuthentication(sessionStore);

  const [shouldSendAuthLink, setShouldSendAuthLink] = useState(false);
  const [authLinkSent, setAuthLinkSent] = useState(false);

  const triggerAuthLinkSending = useCallback(() => {
    setShouldSendAuthLink(true);
  }, [
    setShouldSendAuthLink,
  ]);

  useEffect(() => {
    if (shouldSendAuthLink && !authLinkSent) {
      const performAuthLinkSending = async () => {
        setIsLoading(true);
        await sendAuthenticationEmail(email);
        if (isComponentMountedRef.current) {
          setAuthLinkSent(true);
          setIsLoading(false);
        }
      };
      performAuthLinkSending();
    }
  }, [
    isComponentMountedRef,
    email,
    shouldSendAuthLink,
    sendAuthenticationEmail,
    authLinkSent,
  ]);

  const onLoginSubmit = async (fields) => {
    logEvent('signInClick', { from: 'signInWithEmail' });
    try {
      await signInWithEmailAndPassword(fields.email.trim(), fields.password);
      logEvent('signInSuccess', { from: 'signInWithEmail' });
    } catch (error) {
      logEvent('signInError', { error, from: 'signInWithEmail' });
      setLoginError(error);
    }
  };

  const onEmailChange = ({ target: { value } }) => {
    setEmail(value);
  };

  const { authUser } = sessionStore;
  if (authUser && !authUser.isAnonymous) {
    const {
      state: {
        redirectAfterLogin = '/',
      } = {},
      search,
    } = location;
    return (
      <Redirect to={{ pathname: redirectAfterLogin, search }} />
    );
  }

  const onAuthSentLinkAccepted = () => {
    setShouldSendAuthLink(false);
    setAuthLinkSent(false);
  };

  const renderPageContent = () => {
    if (isLoading) {
      return (
        <Container>
          <StyledSpinner name="crescent" />
        </Container>
      );
    }

    if (authLinkSent) {
      return (
        <Container>
          <InfoMessage>
            {format(texts.sentAuthLink, {
              email,
            })}
          </InfoMessage>
          <StyledButton onClick={onAuthSentLinkAccepted}>{texts.ok}</StyledButton>
        </Container>
      );
    }

    return (
      <>
        <LoginForm
          onSubmit={onLoginSubmit}
          onEmailChange={onEmailChange}
        />
        {!!loginError && <ErrorMessage>{loginError.message}</ErrorMessage>}
        <LoginNotice>{texts.loginNotice}</LoginNotice>
        <ForgotPassword
          email={email}
          sendAuthLink={triggerAuthLinkSending}
          sessionStore={sessionStore}
        />
        <ContactSupport>{ReactHtmlParser(texts.contactSupport)}</ContactSupport>
      </>
    );
  };

  return (
    <PageContainer formHeading={texts.login}>
      {renderPageContent()}
    </PageContainer>
  );
};

export default Login;
