import React, {
  useEffect,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

import RemoteConfigContext from './RemoteConfigContex';

const MIN_FETCH_INTERVAL = 1000 * 60 * 5;

const defaultConfig = {
  LOGGING_LEVEL: 'error',
  LOGGING_SAMPLE_RATE: 0.3,
  USERS_FOR_FIREBASE_LOGGING: '',
};

const remoteConfigKeys = {
  USERS_FOR_FIREBASE_REMOTE_LOGGING: 'usersForFirebaseRemoteLogging',
  REMOTE_LOGGING_LEVEL: 'remoteLoggingLevel',
  REMOTE_LOGGING_SAMPLE_RATE: 'remoteLoggingSampleRate',
};

/**
 * The RemoteConfigContextProvider is meant to enable firebase remote config
 * into the app by providing an explicit context from where those configuration values
 * can be consumed.
 *
 * @param {Object} firebaseRemoteConfig The firebase app remote config instance
 */
const RemoteConfigContextProvider = ({
  children,
  firebaseRemoteConfig,
}) => {
  const [isReady, setIsReady] = useState(false);
  const [usersForFirebaseRemoteLogging, setUsersForFirebaseRemoteLogging] = useState(() => []);
  const [remoteLoggingLevel, setRemoteLoggingLevel] = useState(defaultConfig.LOGGING_LEVEL);
  const [remoteLoggingSampleRate, setRemoteLoggingSampleRate] = useState(defaultConfig.LOGGING_SAMPLE_RATE);

  useEffect(() => {
    let shouldUpdate = true;

    const initializeRemoteConfig = async () => {
      const remoteConfig = firebaseRemoteConfig;

      remoteConfig.settings = {
        minimumFetchIntervalMillis: MIN_FETCH_INTERVAL,
      };

      const {
        LOGGING_LEVEL,
        LOGGING_SAMPLE_RATE,
        USERS_FOR_FIREBASE_LOGGING,
      } = defaultConfig;

      remoteConfig.defaultConfig = {
        remoteLoggingLevel: LOGGING_LEVEL,
        usersForFirebaseRemoteLogging: USERS_FOR_FIREBASE_LOGGING,
        remoteLoggingSampleRate: LOGGING_SAMPLE_RATE,
      };

      await remoteConfig.fetchAndActivate();

      const usersForFirebaseRemoteLoggingValue = remoteConfig
        .getString(remoteConfigKeys.USERS_FOR_FIREBASE_REMOTE_LOGGING)
        .split(',')
        .reduce((usersForLogging, userId) => {
          if (userId.length > 0) {
            return [
              ...usersForLogging,
              userId,
            ];
          }
          return usersForLogging;
        }, []);

      const remoteLoggingLevelValue = remoteConfig.getString(remoteConfigKeys.REMOTE_LOGGING_LEVEL);

      const remoteLoggingSampleRateValue = parseFloat(remoteConfig.getString(
        remoteConfigKeys.REMOTE_LOGGING_SAMPLE_RATE,
      ));

      if (shouldUpdate) {
        setUsersForFirebaseRemoteLogging(usersForFirebaseRemoteLoggingValue);
        setRemoteLoggingLevel(remoteLoggingLevelValue);
        setRemoteLoggingSampleRate(remoteLoggingSampleRateValue);
        setIsReady(true);
      }
    };

    if (!isReady) {
      initializeRemoteConfig();
    }

    return () => {
      shouldUpdate = false;
    };
  }, [
    firebaseRemoteConfig,
    isReady,
  ]);

  const contextValue = useMemo(() => ({
    isReady,
    usersForFirebaseRemoteLogging,
    remoteLoggingLevel,
    remoteLoggingSampleRate,
  }), [
    isReady,
    usersForFirebaseRemoteLogging,
    remoteLoggingLevel,
    remoteLoggingSampleRate,
  ]);

  return (
    <RemoteConfigContext.Provider value={contextValue}>
      {children}
    </RemoteConfigContext.Provider>
  );
};

RemoteConfigContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  firebaseRemoteConfig: PropTypes.object.isRequired,
};

export default RemoteConfigContextProvider;
