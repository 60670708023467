import styled from 'styled-components';
import { ReactComponent as AddImageIcon } from '../../../../../assets/icons/add-image.svg';
import { ReactComponent as UploadedFileDeleteIcon } from '../../../../../assets/icons/uploaded-file-delete-icon.svg';
import LoadingPage from '../../../../../components/LoadingPage';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 20px 0 0;
  padding: 15px;
  min-width: 38%;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.system.background};
  justify-content: space-between;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.system.background};
`;

const StyledLoadingPage = styled(LoadingPage)`
  margin: 30px 0;
`;

const ImageUploadButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  align-items: center;
  color: ${({ theme }) => theme.colors.system.gamma2};
`;

const StyledAddImageIcon = styled(AddImageIcon)`
  display: flex;
  height: 14px;
  width: 14px;
  margin: 2px 6px;
`;

const FileDeleteIcon = styled(UploadedFileDeleteIcon)`
  align-self: flex-end;
`;

const StyledImageContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledImage = styled.img`
  width: auto;
  display: ${({ $isLoading }) => ($isLoading ? 'none' : 'block')};
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  Container,
  IconContainer,
  ImageUploadButtonContainer,
  StyledAddImageIcon,
  FileDeleteIcon,
  StyledImageContainer,
  StyledImage,
  ImageWrapper,
  StyledLoadingPage,
};
