import React, {
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';

import UserContext from '../UserContext';
import useComponentMounted from '../../hooks/useComponentMounted';
import useComponentLoadingTime from '../../hooks/useComponentLoadingTime';
import UserContract from '../../models/UserContract';
import UserContractContext, { initialValues } from './UserContractContext';

const UserContractContextProvider = ({
  children,
}) => {
  const [newContractDoc, setNewContractDoc] = useState();
  const [userContractCollection, setUserContractCollection] = useState(initialValues.userContractCollection);
  const [isReady, setIsReady] = useState(initialValues.isReady);
  const [isReonboardingModalOpen, setIsReonboardingModalOpen] = useState(false);

  const {
    userId,
  } = useContext(UserContext);
  const isComponentMountedRef = useComponentMounted();
  const { startLoading, finishLoading } = useComponentLoadingTime('userContractContext');

  useEffect(() => {
    const run = async () => {
      startLoading();
      const pendingUserContractCollection = await UserContract.getPendingContractCollectionByUserId(userId);
      const contractCollection = await UserContract.getAllContractsByUserId(userId);

      autorun(() => {
        if (isComponentMountedRef.current) {
          if (pendingUserContractCollection.hasDocs) {
            setNewContractDoc(pendingUserContractCollection.docs[0]);
          } else {
            setNewContractDoc(null);
          }
          setUserContractCollection(contractCollection);
          setIsReady(true);
          finishLoading();
        }
      });
    };

    run();
  }, [
    isComponentMountedRef,
    userId,
    startLoading,
    finishLoading,
  ]);

  const context = useMemo(() => ({
    isReady,
    newContractDoc,
    isNewContractAvailable: !!newContractDoc,
    userContractCollection,
    isReonboardingModalOpen,
    setIsReonboardingModalOpen,
  }), [
    isReady,
    newContractDoc,
    userContractCollection,
    isReonboardingModalOpen,
    setIsReonboardingModalOpen,
  ]);

  return (
    <UserContractContext.Provider value={context}>
      {children}
    </UserContractContext.Provider>
  );
};

UserContractContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(UserContractContextProvider);
