import React, {
  useContext,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import AppContext from '../../../../context/AppContext';
import MessageBannerContext, {
  withMessageBannerContextProvider,
  BannerType,
  bannerTexts,
} from '../../../../context/MessageBannerContext';

import MessageBanner from '../../../../components/MessageBanner';
import SelfieVideo from '../../../../components/SelfieVideo';
import ActivityDetailsModal from '../../../../components/ActivityDetailsModal';

import GameplayActions from '../GameplayActions';
import {
  Container,
  InfoContainer,
  CameraViewport,
} from '../Layout';

import ActivityPanelContainer from './components/ActivityPanel';
import FinishedWorkout from './components/FinishedWorkout';
import ActionsPanel from './components/ActionsPanel';
import WorkoutMessages from './components/WorkoutMessages';
import ProgressInfoPanel from './components/ProgressInfoPanel';
import {
  ViewportExplanatoryVideo,
  GenericViewportExplanatoryVideo,
} from './styles';

import texts from './texts.json';

const WorkoutInProgress = ({
  isWorkoutFinished,
  displayActivityDetails,
  activityForDetailsPage,
  onDetailsClose,
  cameraAspectRatio,
  isCameraEnabled,
  videoUrl,
  cameraViewportRef,
  isS2Activity,
  isCAActivity,
}) => {
  const {
    networkStatus,
    isBackOnline,
    isLowPowerModeEnabled,
  } = useContext(AppContext);
  const {
    showBanner,
    hideBanner,
    isBannerVisible,
  } = useContext(MessageBannerContext);

  const renderExplanatoryVideo = () => {
    if (isCameraEnabled || !videoUrl) {
      return null;
    }

    /*
      We render the regular ExplanatoryVideo for S2 activities.
      For the CA activities, we render a generic one (with blurred borders).
    */
    const VideoPlayerToRender = isS2Activity ? ViewportExplanatoryVideo : GenericViewportExplanatoryVideo;

    return (
      <VideoPlayerToRender
        videoUrl={videoUrl}
        showShadowOverlay={isCAActivity}
      />
    );
  };

  useEffect(() => {
    if (!networkStatus.connected) {
      showBanner({
        message: bannerTexts.noInternet,
        type: BannerType.WARNING,
      });
    } else if (isBackOnline) {
      showBanner({
        message: bannerTexts.online,
        type: BannerType.SUCCESS,
      });
    } else if (isLowPowerModeEnabled) {
      showBanner({
        message: texts.lowPowerMode,
        type: BannerType.WARNING,
      });
    } else {
      hideBanner();
    }
  }, [
    networkStatus,
    isBackOnline,
    isLowPowerModeEnabled,
    showBanner,
    hideBanner,
  ]);

  return (
    <>
      <Container>
        {!!isBannerVisible && (
          <MessageBanner staticBanner />
        )}
        <InfoContainer>
          <ActivityPanelContainer />
        </InfoContainer>
        <CameraViewport
          ref={cameraViewportRef}
          $aspectRatio={cameraAspectRatio}
        >
          {renderExplanatoryVideo()}
          <SelfieVideo>
            <GameplayActions />
          </SelfieVideo>
          <WorkoutMessages />
          <ProgressInfoPanel />
          <ActionsPanel />
        </CameraViewport>
      </Container>
      {isWorkoutFinished && <FinishedWorkout />}
      <ActivityDetailsModal
        isOpen={displayActivityDetails}
        onClose={onDetailsClose}
        activity={activityForDetailsPage}
      />
    </>
  );
};

WorkoutInProgress.propTypes = {
  isWorkoutFinished: PropTypes.bool.isRequired,
  displayActivityDetails: PropTypes.bool.isRequired,
  onDetailsClose: PropTypes.func.isRequired,
  activityForDetailsPage: PropTypes.object.isRequired,
  cameraAspectRatio: PropTypes.number.isRequired,
  isCameraEnabled: PropTypes.bool.isRequired,
  videoUrl: PropTypes.string,
  cameraViewportRef: PropTypes.object.isRequired,
  isS2Activity: PropTypes.bool,
  isCAActivity: PropTypes.bool,
};

WorkoutInProgress.defaultProps = {
  videoUrl: '',
  isS2Activity: true,
  isCAActivity: false,
};

export default compose(
  withMessageBannerContextProvider,
  observer,
)(WorkoutInProgress);
