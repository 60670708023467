import React, {
  useContext,
} from 'react';
import format from 'string-template';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';

import {
  carbsToCalories,
  proteinToCalories,
  fatToCalories,
} from '../../../../utils/meals';
import UserNutritionInfoContext from '../../../../context/UserNutritionInfoContext';
import useWeightGoalDisplayText from '../hooks/useWeightGoalDisplayText';
import {
  Title,
  Paragraph,
} from '../commonStyles';
import {
  Wrapper,
  List,
  ListItem,
  HighlightSection,
  Grid,
  GridItemHeader,
  GridItem,
} from './styles';
import texts from './texts.json';

const PlanStructure = ({
  protein,
  carbs,
  fat,
}) => {
  const {
    userNutritionMacroGoalsDoc,
  } = useContext(UserNutritionInfoContext);

  const weeklyWeightGoalText = useWeightGoalDisplayText();

  const toDisplayNumberValue = (value) => Math.round(value);

  const descriptionOptions = {
    bmr: toDisplayNumberValue(userNutritionMacroGoalsDoc.bmr),
    tdee: toDisplayNumberValue(userNutritionMacroGoalsDoc.tdee),
    goal: weeklyWeightGoalText,
    totalDailyCalories: toDisplayNumberValue(userNutritionMacroGoalsDoc.totalDailyCalories),
  };

  return (
    <>
      <Wrapper>
        {texts.descriptionLines.map(({ id, text }) => (
          <Paragraph key={id}>
            {format(text, descriptionOptions)}
          </Paragraph>
        ))}
      </Wrapper>
      <Wrapper>
        <Title>{texts.macroDistributionTitle}</Title>
        <Grid>
          <GridItemHeader>{texts.carbTitle}</GridItemHeader>
          <GridItemHeader>{texts.proteinTitle}</GridItemHeader>
          <GridItemHeader>{texts.fatTitle}</GridItemHeader>
          <GridItem>{toDisplayNumberValue(carbs)}</GridItem>
          <GridItem>{toDisplayNumberValue(protein)}</GridItem>
          <GridItem>{toDisplayNumberValue(fat)}</GridItem>
        </Grid>
      </Wrapper>
      <Wrapper>
        <Title>{texts.nutrientsBreakdownTitle}</Title>
        <List>
          <ListItem>{texts.macroBreakdownCarbs}</ListItem>
          <ListItem>{texts.macroBreakdownProtein}</ListItem>
          <ListItem>{texts.macroBreakdownFat}</ListItem>
        </List>
      </Wrapper>
      <Wrapper>
        <Title>{texts.personalizedBreakdownTitle}</Title>
        <List>
          <ListItem>
            {ReactHtmlParser(format(texts.personalizedBreakdownCarbs, {
              carbs: toDisplayNumberValue(carbs),
              totalCarbsCalories: toDisplayNumberValue(carbsToCalories(carbs)),
            }))}
          </ListItem>
          <ListItem>
            {ReactHtmlParser(format(texts.personalizedBreakdownProtein, {
              protein: toDisplayNumberValue(protein),
              totalProteinCalories: toDisplayNumberValue(proteinToCalories(protein)),
            }))}
          </ListItem>
          <ListItem>
            {ReactHtmlParser(format(texts.personalizedBreakdownFat, {
              fat: toDisplayNumberValue(fat),
              totalFatCalories: toDisplayNumberValue(fatToCalories(fat)),
            }))}
          </ListItem>
        </List>
        <HighlightSection>
          {format(texts.totalDailyCalories, {
            totalDailyCalories: toDisplayNumberValue(userNutritionMacroGoalsDoc.totalDailyCalories),
          })}
        </HighlightSection>
      </Wrapper>
    </>
  );
};

PlanStructure.propTypes = {
  protein: PropTypes.number.isRequired,
  carbs: PropTypes.number.isRequired,
  fat: PropTypes.number.isRequired,
};

export default PlanStructure;
