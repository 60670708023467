import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { useLocation, useHistory } from 'react-router-dom';

import useSessionStore from '../../../hooks/useSessionStore';
import Button from '../../../components/Button';
import Workout from '../../models/Workout';

import {
  Form,
  HeaderTitle,
  StyledInput,
  StyledTextArea,
} from './styles';
import texts from './texts.json';

const CreateWorkout = () => {
  const [name, setName] = useState('');
  const [note, setNote] = useState('');

  const history = useHistory();
  const location = useLocation();
  const {
    authUser: {
      uid,
    },
  } = useSessionStore();

  const urlParams = new URLSearchParams(location.search);
  const coachId = urlParams.get('coachId') || uid;

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const onNoteChange = (event) => {
    setNote(event.target.value);
  };

  const createWorkoutButtonClick = async (event) => {
    event.preventDefault();
    // Create the new workout document
    const doc = await Workout.createWorkoutDoc(name, note, coachId);

    if (!urlParams.has('edit')) {
      urlParams.append('edit', true);
    }

    // Redirect to the workout editor
    history.push(`${location.pathname}/${doc.id}?${urlParams.toString()}`);
  };

  return (
    <>
      <HeaderTitle>{texts.title}</HeaderTitle>
      <Form>
        <StyledInput
          type="text"
          name={texts.name}
          onChange={onNameChange}
          value={name}
          placeholder={texts.name}
          required
        />
        <StyledTextArea
          type="textarea"
          name={texts.notes}
          onChange={onNoteChange}
          value={note}
          placeholder={texts.notes}
          required
        />
        <Button disabled={!name} onClick={createWorkoutButtonClick}>{texts.createWorkoutButton}</Button>
      </Form>
    </>
  );
};

export default compose(
  observer,
)(CreateWorkout);
