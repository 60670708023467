import * as Yup from 'yup';

import { UnitType } from '../../../utils/units';

import { validation } from './texts.json';

const validationSchema = Yup.object().shape({
  weight: Yup
    .number()
    .typeError(validation.weight.type)
    .positive(validation.weight.positive),
  thigh: Yup
    .number()
    .typeError(validation.length.type)
    .positive(validation.length.positive),
  stomach: Yup
    .number()
    .typeError(validation.length.type)
    .positive(validation.length.positive),
  chest: Yup
    .number()
    .typeError(validation.length.type)
    .positive(validation.length.positive),
  upperArm: Yup
    .number()
    .typeError(validation.length.type)
    .positive(validation.length.positive),
  waist: Yup
    .number()
    .typeError(validation.length.type)
    .positive(validation.length.positive),
  hip: Yup
    .number()
    .typeError(validation.length.type)
    .positive(validation.length.positive),
  comment: Yup.string(),
});

const defaultValues = {
  weight: '',
  unitType: UnitType.IMPERIAL,
  comment: '',
  thigh: '',
  stomach: '',
  chest: '',
  upperArm: '',
  waist: '',
  hip: '',
  attachments: [],
};

export {
  validationSchema,
  defaultValues,
};
