import React, {
  useEffect,
  useState,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import {
  Redirect,
  useRouteMatch,
} from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import { getQueryVariable } from '../../utils/queryParams';
import useComponentMounted from '../../hooks/useComponentMounted';
import Recipe from '../../models/Recipe';
import LoadingPage from '../../components/LoadingPage';
import useMealPlanNavigation from '../../hooks/useMealPlanNavigation';
import useSessionStore from '../../hooks/useSessionStore';
import { withCustomizedLayout } from '../../components/Layout';
import MealDetails from './MealDetails';

const RecipeDetailsContainer = () => {
  const [isReady, setIsReady] = useState(false);
  const [recipeDoc, setRecipeDoc] = useState(null);

  const {
    params: {
      recipeId,
      userId,
    },
  } = useRouteMatch();
  const { pathname: mealPlanPathname } = useMealPlanNavigation();
  const { isCoachOrAdmin } = useSessionStore();

  const isComponentMountedRef = useComponentMounted();

  // Use 1 serving by default
  const servings = Number(getQueryVariable('servings') || 1);

  useEffect(() => {
    const init = async () => {
      let recipe = null;
      try {
        const recipeDocResult = new Recipe(recipeId);
        await recipeDocResult.init();
        recipe = recipeDocResult;
      } catch (error) {
        Sentry.captureException(error, {
          extra: {
            recipeId,
            userId,
          },
        });
      }
      if (isComponentMountedRef.current) {
        setRecipeDoc(recipe);
        setIsReady(true);
      }
    };
    if (!isReady) {
      init();
    }
  }, [
    isReady,
    recipeId,
    userId,
    isComponentMountedRef,
  ]);

  if (!isReady) {
    return <LoadingPage />;
  }

  if ((recipeDoc && !recipeDoc.exists) || !recipeDoc) {
    Sentry.captureMessage(`Recipe ${recipeId} does not exist, or the user does not have access to it`, {
      extra: {
        recipeId,
        userId,
      },
    });
    return <Redirect to={mealPlanPathname} />;
  }

  const meal = {
    servings: recipeDoc.servings,
    recipe: recipeDoc,
  };

  return (
    <MealDetails
      meal={meal}
      recommendedServings={servings}
      showFooterNote={isCoachOrAdmin}
    />
  );
};

export default compose(
  withCustomizedLayout({ addChat: false }),
  observer,
)(RecipeDetailsContainer);
