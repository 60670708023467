import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';
import ReactHtmlParser from 'react-html-parser';

import CoachInfoContext from '../../../../context/CoachInfoContext';

import {
  StyledCard,
  ImageContainer,
  Name,
  NutrientsContainer,
  StyledMealImage,
  Nutrient,
} from './styles';
import texts from './texts.json';

const MealCard = ({
  name,
  imageSrc,
  protein,
  carbs,
  fat,
  percentProtein,
  percentCarbs,
  percentFat,
  calories,
  onClick,
}) => {
  const {
    coachInfo: {
      mealPlanConfig: {
        recipeNutritionInfoDisabled,
      } = {},
    } = {},
  } = useContext(CoachInfoContext);

  const proteinGrams = parseInt(protein, 10);
  const carbsGrams = parseInt(carbs, 10);
  const fatGrams = parseInt(fat, 10);

  return (
    <StyledCard onClick={onClick}>
      <ImageContainer>
        <StyledMealImage src={imageSrc} />
      </ImageContainer>
      <Name>{name}</Name>
      {!recipeNutritionInfoDisabled && (
        <NutrientsContainer>
          <Nutrient>{ReactHtmlParser(format(texts.protein, { proteinGrams, percentProtein }))}</Nutrient>
          <Nutrient>{ReactHtmlParser(format(texts.carbs, { carbsGrams, percentCarbs }))}</Nutrient>
          <Nutrient>{ReactHtmlParser(format(texts.fat, { fatGrams, percentFat }))}</Nutrient>
          <Nutrient>{ReactHtmlParser(format(texts.calories, parseInt(calories, 10)))}</Nutrient>
        </NutrientsContainer>
      )}
    </StyledCard>
  );
};

MealCard.propTypes = {
  name: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  protein: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  carbs: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  fat: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  calories: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  percentCarbs: PropTypes.number.isRequired,
  percentFat: PropTypes.number.isRequired,
  percentProtein: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};

MealCard.defaultProps = {
  onClick: () => {},
};

export default MealCard;
