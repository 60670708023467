import styled, { css } from 'styled-components';
import Button from '../../../Button';

import { labelStyles } from '../../../../styles/text';
import colors from '../../colors';

const titleStyle = css`
  ${labelStyles}
  font-size: 18px;
  line-height: 22px;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 10px;
  background-color: ${({ theme }) => theme.colors.base.tertiary};
  width: 100%;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const Title = styled.div`
  ${titleStyle}
  font-weight: 600;
  color: ${colors.base};
`;

const StyledButton = styled(Button)`
  background: transparent;
  font-size: 14px;
  padding: 0;
  width: fit-content;
  margin-left: auto;
`;

export {
  CardContainer,
  Title,
  TextWrapper,
  StyledButton,
};
