import React from 'react';
import { PropTypes } from 'prop-types';
import { compose } from 'recompose';

import withPortal from '../withPortal';
import SlideDrawer from '../SlideDrawer';
import {
  SliderCardContainer,
  Title,
  StyledInput,
  Subtitle,
  TextContainer,
  SubmitButton,
  StyledButton,
  StyledArrow,
  ProblemSelectTitle,
} from './styles';
import texts from './texts.json';

import {
  INITIAL_STEP,
  LAST_STEP,
  ReportTypes,
  SECOND_STEP,
} from './constants';

const StepTwoTextOptions = {
  [ReportTypes.ERROR]: 'recipeErrorTitle',
  [ReportTypes.USER_PREFERENCE]: 'personalPreferenceTitle',
};

const ProblemWithRecipeSliderDrawer = ({
  isOpen,
  onClose,
  setComments,
  onSubmit,
  setStepNumber,
  stepNumber,
  disabled,
  reason,
  setReason,
}) => {
  const onClick = () => {
    setStepNumber(Math.min(stepNumber + 1, LAST_STEP));
  };

  const reportTypeButton = (type) => (
    <StyledButton
      onClick={() => {
        onClick();
        setReason(type);
      }}
      trackingProps={{
        actionName: type,
      }}
    >
      <div>{texts.steps[stepNumber][type]}</div>
      <StyledArrow />
    </StyledButton>
  );

  return (
    <SlideDrawer isOpen={isOpen} dragDownThreshold={0.5} onClose={onClose} fitContent>
      <SliderCardContainer>
        {stepNumber === INITIAL_STEP && (
        <>
          <ProblemSelectTitle>{texts.steps[stepNumber].title}</ProblemSelectTitle>
          {reportTypeButton(ReportTypes.USER_PREFERENCE)}
          {reportTypeButton(ReportTypes.ERROR)}
        </>
        )}
        {stepNumber === SECOND_STEP && (
        <>
          <Title>{texts.steps[stepNumber][StepTwoTextOptions[reason]] }</Title>
          <StyledInput
            rows={5}
            defaultValue=""
            placeholder={texts.steps[stepNumber].placeHolder}
            onChange={(e) => setComments(e.target.value)}
          />
          <SubmitButton
            disabled={disabled}
            onClick={() => {
              onClick();
              onSubmit();
            }}
          >
            {texts.steps[stepNumber].submit}
          </SubmitButton>
        </>
        )}
        {stepNumber === LAST_STEP && (
        <>
          <TextContainer>
            <Title>{texts.steps[stepNumber].reportRecipeSuccesLine1}</Title>
            <Subtitle>{texts.steps[stepNumber].reportRecipeSuccesLine2}</Subtitle>
            <Subtitle>{texts.steps[stepNumber].reportRecipeSuccesLine3}</Subtitle>
          </TextContainer>
          <SubmitButton onClick={onClose}>{texts.steps[stepNumber].ok}</SubmitButton>
        </>
        )}
      </SliderCardContainer>
    </SlideDrawer>
  );
};

ProblemWithRecipeSliderDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setComments: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setStepNumber: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  reason: PropTypes.string,
  setReason: PropTypes.func.isRequired,
};

ProblemWithRecipeSliderDrawer.defaultProps = {
  reason: '',
};

export default compose(withPortal('ProblemWithRecipeSliderDrawer'))(ProblemWithRecipeSliderDrawer);
