import React, {
  useMemo,
} from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';

import useAppTheme from '../../hooks/useAppTheme';
import { fontFamily } from '../../styles/text';
import CustomValueContainer from './CustomValueContainer';
import CustomClearIndicator from './CustomClearIndicator';
import CustomOption from './CustomOption';

const Select = ({
  label,
  name,
  value,
  options,
  onChange,
  required,
  ...otherProps
}) => {
  const { colors } = useAppTheme();

  // https://react-select.com/styles#provided-styles-and-state
  const selectCustomStyles = useMemo(() => ({
    container: (base) => ({
      ...base,
      width: '100%',
    }),
    control: (base, { isDisabled }) => ({
      ...base,
      border: 0,
      boxShadow: 'none',
      minHeight: '42px',
      borderRadius: '6px',
      background: isDisabled ? colors.system.background : colors.system.secondary6,
    }),
    singleValue: (base) => ({
      ...base,
      fontFamily: fontFamily.sansSerif,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '19px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      margin: '5px 1px 0 0',
    }),
    valueContainer: (base) => ({
      ...base,
      padding: '4px 0 4px 16px',
      flexDirection: 'column',
      alignItems: 'baseline',
    }),
    menu: (base) => ({
      ...base,
      border: 'none',
      boxShadow: '0 4px 18px rgba(0, 0, 0, 0.3)',
      borderRadius: '6px',
      padding: '0',
    }),
  }), [
    colors,
  ]);

  return (
    <ReactSelect
      {...otherProps}
      components={{
        ValueContainer: CustomValueContainer,
        ClearIndicator: CustomClearIndicator,
        Option: CustomOption,
        DropdownIndicator: null,
      }}
      styles={selectCustomStyles}
      value={value}
      name={name}
      label={name}
      options={options}
      isSearchable={false}
      onChange={onChange}
    />
  );
};

Select.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

Select.defaultProps = {
  label: '',
  value: null,
  required: false,
};

export default Select;
