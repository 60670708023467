import React, {
  useContext,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import ActivitiesList from '../../../../../../components/ActivitiesList';
import WorkoutContext, { withWorkoutContextReady } from '../../../../../../context/WorkoutContext';
import WorkoutExecutionInfoContext from '../../../../context/WorkoutExecutionInfoContext';
import CircuitExtraContent from '../CircuitActivityExtraContent';

const WorkoutDetails = () => {
  const {
    activitiesWithOverrides,
  } = useContext(WorkoutContext);

  const {
    displayInfo: {
      id: activityId,
    },
  } = useContext(WorkoutExecutionInfoContext);

  return (
    <ActivitiesList
      activities={activitiesWithOverrides}
      currentActivityId={activityId}
      ActivityExtraContent={CircuitExtraContent}
      renderExtraOnHighlightOnly
    />
  );
};

export default compose(
  withWorkoutContextReady,
  observer,
)(WorkoutDetails);
