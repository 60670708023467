import React, {
  useMemo,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

import { isVimeoVideo } from '../../../utils/video';
import AppContext from '../../../context/AppContext';
import {
  Description,
  VideoContainer,
  StyledVideoPlayer,
} from './styles';

// NOTE: This implementation is temporary. It will be updated/improved after review.
const ExerciseDetail = ({
  exercise,
}) => {
  const {
    isLowPowerModeEnabled,
  } = useContext(AppContext);

  const {
    description,
    videoSrc,
  } = exercise;

  const isVimeoVideoLoaded = useMemo(() => isVimeoVideo(videoSrc), [videoSrc]);

  return (
    <>
      {!!videoSrc && (
        <VideoContainer>
          <StyledVideoPlayer
            url={videoSrc}
            playsinline
            autoPlay
            showCustomControls={isLowPowerModeEnabled}
            autoHideCustomControls
            volume={isVimeoVideoLoaded ? 0 : 1}
            muted={isVimeoVideoLoaded}
            loop={isVimeoVideoLoaded}
            $enlarge={isVimeoVideoLoaded}
          />
        </VideoContainer>
      )}
      {!!description && (
        <Description>
          {description}
        </Description>
      )}
    </>
  );
};

ExerciseDetail.propTypes = {
  exercise: PropTypes.object.isRequired,
};

export default ExerciseDetail;
