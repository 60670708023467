import { Capacitor } from '@capacitor/core';

const isNative = Capacitor.isNativePlatform();

const isIOS = () => Capacitor.getPlatform() === 'ios';

const isAndroid = () => Capacitor.getPlatform() === 'android';

export {
  isIOS,
  isAndroid,
  isNative,
};
