import React, {
  useState,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import {
  useElements,
  useStripe,
  CardNumberElement,
} from '@stripe/react-stripe-js';

import { compose } from 'recompose';
import { observer } from 'mobx-react';

import FirebaseContext from '../../context/FirebaseContext';
import StripeContext from '../../context/StripeContext';
import SubscriptionContext from '../../context/SubscriptionContext';
import useCurrentLoggedInUser from '../../hooks/useCurrentLoggedInUser';

import CardStatusBadge from './components/CardStatusBadge';

import {
  Container,
  InfoContainer,
  StyledPaymentMethodIcon,
  CardNumber,
  EditButton,
  UploadButton,
  EditContainer,
  StyledCardNumberElement,
  StyledCardCvcElement,
  StyledCardExpiryElement,
  ErrorText,
} from './styles';
import texts from './texts.json';

const BillingInformation = ({
  isEditable,
}) => {
  const elements = useElements();
  const stripe = useStripe();

  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { isCurrentLoggedInUserInPath } = useCurrentLoggedInUser();

  const {
    defaultPaymentMethod,
    onPaymentMethodChanged,
  } = useContext(SubscriptionContext);
  const {
    cardBrand,
    lastFourDigits,
    phone,
    name,
    email,
  } = defaultPaymentMethod;
  const { stripeAccountId } = useContext(StripeContext);
  const {
    firebase: {
      remote,
    },
  } = useContext(FirebaseContext);

  const updatePaymentMethod = useCallback(async () => {
    setIsLoading(true);
    const card = elements.getElement(CardNumberElement);
    const billingDetails = (phone && name && email) ? { billing_details: { phone, name, email } } : {};
    const paymentMethodData = {
      type: 'card',
      card,
      ...billingDetails,
    };
    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod(paymentMethodData);
      if (error) {
        setErrorMessage(error.message);
      } else {
        const apiResponse = await remote('attachPaymentMethod', {
          newPaymentMethodId: paymentMethod.id,
          stripeAccountId,
        });
        const stripeResponse = await apiResponse.json();
        const { success, error: responseError } = stripeResponse;

        if (success) {
          onPaymentMethodChanged(paymentMethod.id);
          setIsEditing(false);
        } else {
          setErrorMessage(responseError);
        }
      }
    } catch (error) {
      Sentry.captureException(error);
      setErrorMessage(texts.cardErrorMessage);
    }
    setIsLoading(false);
  }, [
    elements,
    stripe,
    phone,
    name,
    email,
    stripeAccountId,
    remote,
    onPaymentMethodChanged,
  ]);

  const handleEditButton = useCallback(() => {
    setIsEditing(true);
    setErrorMessage('');
  }, []);

  const isCardExpired = useMemo(() => (
    defaultPaymentMethod.isExpired()
  ), [
    defaultPaymentMethod,
  ]);

  const handleStripeFieldChange = useCallback((event) => {
    const stripeErrorMsg = event.error ? event.error.message : '';
    setErrorMessage(stripeErrorMsg);
  }, []);

  const renderContent = useCallback(() => {
    if (isEditing) {
      return (
        <EditContainer>
          <StyledCardNumberElement
            onChange={handleStripeFieldChange}
            options={{
              placeholder: texts.cardNumber,
              showIcon: true,
            }}
          />
          <StyledCardExpiryElement onChange={handleStripeFieldChange} />
          <StyledCardCvcElement onChange={handleStripeFieldChange} />
          <UploadButton
            disabled={isLoading || !isCurrentLoggedInUserInPath}
            onClick={updatePaymentMethod}
          >
            {texts.updateButton}
          </UploadButton>
        </EditContainer>
      );
    }
    const paymentMethodNumber = `• • • • ${lastFourDigits}`;
    return (
      <InfoContainer>
        <StyledPaymentMethodIcon type={cardBrand} />
        <CardNumber>{paymentMethodNumber}</CardNumber>
        <CardStatusBadge isExpired={isCardExpired} />
        {isEditable && (
          <EditButton
            variant="link"
            onClick={handleEditButton}
            disabled={!isCurrentLoggedInUserInPath}
          >
            {texts.editButton}
          </EditButton>
        )}
      </InfoContainer>
    );
  }, [
    isEditing,
    isLoading,
    handleStripeFieldChange,
    updatePaymentMethod,
    cardBrand,
    lastFourDigits,
    isCardExpired,
    handleEditButton,
    isCurrentLoggedInUserInPath,
    isEditable,
  ]);

  return (
    <Container>
      {renderContent()}
      {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
    </Container>
  );
};

BillingInformation.propTypes = {
  isEditable: PropTypes.bool,
};

BillingInformation.defaultProps = {
  isEditable: false,
};

export default compose(
  observer,
)(BillingInformation);
