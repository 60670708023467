import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  title: Yup.string().max(35, 'The title should be shorter than 35 characters.'),
  comment: Yup.string(),
});

const initialValues = {
  title: '',
  comment: '',
};

export {
  validationSchema,
  initialValues,
};
