import styled from 'styled-components';

import { ReactComponent as Icon } from '../../../assets/icons/icon_ingredients.svg';

const IngredientsIcon = styled(Icon)`
  path {
    fill: ${({ theme }) => theme.colors.base.secondaryVariant};
    stroke: ${({ theme }) => theme.colors.base.secondaryVariant};
  }
`;

export default IngredientsIcon;
