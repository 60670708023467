import styled from 'styled-components';
import { body1 } from '../../styles/text';

const CardDescription = styled.div`
  ${body1}
  margin: 0;
  padding: 0 0 20px 0;
`;

export default CardDescription;
