import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as AddIcon } from '../../../assets/icons/add-icon.svg';
import {
  Title,
} from '../commonStyles';
import texts from './texts.json';
import {
  StyledCard,
  StyledDescription,
  StyledButton,
  ActivitiesContainer,
  ActivityItem,
} from './styles';

const LogActivity = ({
  activities,
  onActivitySelected,
  onAddCustomWorkout,
  className,
}) => (
  <StyledCard className={className}>
    <Title>{texts.title}</Title>
    <StyledDescription>{texts.description}</StyledDescription>
    <ActivitiesContainer>
      {activities.map((activity) => {
        const onClick = () => onActivitySelected(activity);
        const { title } = activity;

        return (
          <ActivityItem
            key={title}
            onClick={onClick}
          >
            {title}
          </ActivityItem>
        );
      })}
    </ActivitiesContainer>
    <StyledButton
      onClick={onAddCustomWorkout}
    >
      <AddIcon />
      {texts.actionText}
    </StyledButton>
  </StyledCard>
);

LogActivity.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
  })).isRequired,
  onActivitySelected: PropTypes.func.isRequired,
  onAddCustomWorkout: PropTypes.func.isRequired,
  className: PropTypes.string,
};

LogActivity.defaultProps = {
  className: '',
};

export default LogActivity;
