import styled from 'styled-components';
import { LinkButton } from '../Button';
import BackArrowIcon from '../Icons/BackArrowIcon';

const StyledBackArrowIcon = styled(BackArrowIcon)``;

const BackButtonContainer = styled(LinkButton)`
  padding: 6px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;

  ${StyledBackArrowIcon} {
    width: 20px;
    height: 20px;
  }
`;

export {
  BackButtonContainer,
  StyledBackArrowIcon,
};
