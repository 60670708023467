import React, {
  useContext,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { compose } from 'recompose';

import logEvent from '../../utils/logger';
import useWorkoutAssignmentEdition from '../../hooks/useWorkoutAssignmentEdition';
import useGameplayNavigation from '../../hooks/useGameplayNavigation';
import useComponentMounted from '../../hooks/useComponentMounted';
import { withUserExerciseOverridesContextReady } from '../../context/UserExerciseOverridesContext';
import { withLayout, HeaderContext } from '../../components/Layout';
import WorkoutDetails, { WorkoutDetailsContextProvider } from '../../components/WorkoutDetails';
import Button from '../../components/Button';
import { withActivityDetailsContextProvider } from '../../components/ActivityDetailsModal';
import WorkoutSelectorContext, {
  withWorkoutSelectorContextProvider,
  withWorkoutSelectorContextReady,
} from './context/WorkoutSelectorContext';
import texts from './texts.json';
import {
  Container,
  ContentSection,
  FooterSection,
  StyledLoadingPage,
  StyledWorkoutInfo,
} from './styles';

const WorkoutDetailsSelector = () => {
  const {
    workoutDoc,
    activitiesWithOverrides,
  } = useContext(WorkoutSelectorContext);
  const { setTitle } = useContext(HeaderContext);

  const [showProgressIndicator, setShowProgressIndicator] = useState(false);

  const { createWorkoutAssignment } = useWorkoutAssignmentEdition();
  const { navigateToGameplaySession } = useGameplayNavigation();
  const isComponentMountedRef = useComponentMounted();

  useEffect(() => setTitle(texts.title), [setTitle]);

  const onAction = useCallback(async () => {
    logEvent('selfAssignWorkoutCalled', {
      workoutId: workoutDoc.id,
    });

    setShowProgressIndicator(true);

    let workoutAssigmentDoc;

    try {
      workoutAssigmentDoc = await createWorkoutAssignment(workoutDoc);
    } catch (error) {
      logEvent('failedToInitializeSelfAssignedWorkoutAssignment');

      if (isComponentMountedRef.current) {
        setShowProgressIndicator(false);
      }
      return;
    }

    const { id: newWorkoutAssignmentId } = workoutAssigmentDoc;

    logEvent('selfAssignedWorkoutAssignmentReady', {
      newWorkoutAssignmentId,
    });

    if (isComponentMountedRef.current) {
      setShowProgressIndicator(false);

      // Replace current page, remove it from the stack and navigate to the Gameplay
      navigateToGameplaySession({
        workoutAssignmentId: newWorkoutAssignmentId,
      }, {
        shouldReplaceURL: true,
      });
    }
  }, [
    workoutDoc,
    isComponentMountedRef,
    createWorkoutAssignment,
    navigateToGameplaySession,
  ]);

  const {
    workoutDefinition: {
      name,
      note,
      estimatedDuration,
    },
  } = workoutDoc;

  return (
    <Container>
      <ContentSection>
        <StyledWorkoutInfo
          name={name}
          note={note}
          estimatedDuration={estimatedDuration}
          activities={activitiesWithOverrides}
        />
        <WorkoutDetailsContextProvider>
          <WorkoutDetails activities={activitiesWithOverrides} />
        </WorkoutDetailsContextProvider>
      </ContentSection>
      <FooterSection>
        <Button onClick={onAction}>
          {texts.actionText}
        </Button>
      </FooterSection>
      {showProgressIndicator && (
        <StyledLoadingPage />
      )}
    </Container>
  );
};

export default compose(
  withLayout,
  withUserExerciseOverridesContextReady,
  withWorkoutSelectorContextProvider,
  withWorkoutSelectorContextReady,
  withActivityDetailsContextProvider,
)(WorkoutDetailsSelector);
