import React, {
  useCallback,
  useEffect,
  useContext,
} from 'react';
import moment from 'moment';

import useUserNavigation from '../../../../hooks/useUserNavigation';
import LogContext from '../../../../context/LogContext';
import HabitLog from '../../../Measurements/Log/HabitLog';
import { DateFormat } from '../../../../utils/date';

import texts from './texts.json';
import {
  StyledContainer,
  Title,
  ItemContainer,
  Text,
  IconContainer,
  NavigateIcon,
} from './styles';

const CheckInCard = () => {
  const { navigateToUserPath } = useUserNavigation();
  const { setCurrentSelectedDate } = useContext(LogContext);

  const onGoToMeasurementPage = useCallback(() => (
    navigateToUserPath('measurements')
  ), [
    navigateToUserPath,
  ]);

  useEffect(() => {
    const currentDate = moment().format(DateFormat.DEFAULT_DATE_FORMAT);
    setCurrentSelectedDate(currentDate);
  }, [
    setCurrentSelectedDate,
  ]);

  return (
    <StyledContainer>
      <Title>{texts.habitTitle}</Title>
      <HabitLog showAllHabits={false} />
      <ItemContainer onClick={onGoToMeasurementPage}>
        <Text>{texts.viewMore}</Text>
        <IconContainer>
          <NavigateIcon />
        </IconContainer>
      </ItemContainer>
    </StyledContainer>
  );
};

export default CheckInCard;
