import styled from 'styled-components';

import { ReactComponent as Icon } from '../../../assets/icons/left-right-side-icon-big.svg';

const AlternateSidesBigIcon = styled(Icon)`
  rect {
    fill: ${({ theme }) => theme.colors.base.secondaryVariant};
  }
`;

export default AlternateSidesBigIcon;
