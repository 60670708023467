import WorkoutDefinition from './WorkoutDefinition';
import BaseDocument from './BaseDocument';

const WorkoutVisibility = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
};

class Workout extends BaseDocument {
  async init(initOpts) {
    await super.init(initOpts);
    this.workoutDefinition = new WorkoutDefinition(this.data);
  }

  get name() {
    return this.data.name;
  }

  get estimatedDuration() {
    return this.data.estimatedDuration;
  }
}

export default Workout;
export {
  WorkoutVisibility,
};
