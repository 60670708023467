import React, { useContext } from 'react';

import LoadingPage from '../../components/LoadingPage';

import UserContextProvider from './UserContextProvider';
import UserContext from './UserContext';

/**
 * Inserts UserContextProvider in the react tree
 * before adding the given component.
 *
 * @param {Object} Component The component that needs the context provider.
 * @returns {Object} The component to render wrapped with the provider.
 */
const withUserContextProvider = (Component) => (props) => (
  <UserContextProvider>
    <Component {...props} />
  </UserContextProvider>
);

/**
 * Renders the given component when the user context is ready.
 *
 * @param {Object} Component The component to render.
 * @param {Object} Component The rendered component.
 * @returns {Object} The component to render or the LoadingPage component.
 */
const withUserContextReady = (Component) => (props) => {
  const { isReady } = useContext(UserContext);
  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withUserContextReady,
  withUserContextProvider,
};
