import React, { useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import { filterOption } from '../../../../utils/search';
import { Category } from '../../../utils/tags';
import ExerciseContext from '../../../context/ExerciseContext';
import {
  StyledToggle,
  StyledTagsSelect,
} from './styles';
import texts from './texts.json';

const TagsSelector = ({
  selectedTags,
  onChange,
}) => {
  const [showTagsField, setShowTagsField] = useState(false);

  const {
    exerciseTags,
  } = useContext(ExerciseContext);

  const tagsList = useMemo(() => (
    exerciseTags
      .filter((tag) => tag.category !== Category.PLATFORM)
      .map(({ tag, id }) => ({ label: tag, value: id }))
      .sort((a, b) => (a.label < b.label ? -1 : 1))
  ), [exerciseTags]);

  return (
    <>
      <StyledToggle
        name={texts.showTagsSelector}
        onChange={setShowTagsField}
        isChecked={showTagsField}
      />
      <CSSTransition
        in={showTagsField}
        timeout={200}
        classNames="tagsTransition"
      >
        <StyledTagsSelect
          label={texts.filterByTags}
          name={texts.filterByTags}
          value={selectedTags}
          isSearchable
          isMulti
          options={tagsList}
          onChange={onChange}
          filterOption={filterOption}
          components={{ DropdownIndicator: null }}
        />
      </CSSTransition>
    </>
  );
};

TagsSelector.propTypes = {
  selectedTags: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
};

TagsSelector.defaultProps = {
  selectedTags: [],
};

export default TagsSelector;
