import devConfig from './development.json';
import prodConfig from './production.json';
import localConfig from './local.json';

const configs = {
  development: devConfig,
  production: prodConfig,
  local: localConfig,
};

const config = configs[window.system2 ? window.system2.appEnv : 'local'];

export default config;
