import React from 'react';

const initialState = {
  isReady: false,
  currentCheckInDoc: null,
  currentSelectedDate: null,
  setCurrentSelectedDate: () => {},
  getCurrentWeekDate: () => {},
  checkInDay: 0,
  shouldUploadImage: false,
};

const CheckInContext = React.createContext(initialState);

export default CheckInContext;
export {
  initialState,
};
