import styled from 'styled-components';

import { ReactComponent as AttachmentIcon } from '../../assets/icons/attachment-icon.svg';
import Button from '../Button';
import CloseButton from '../CloseButton';

const FileUploadContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  align-items: center;
  column-gap: 10px;
  background: ${({ theme }) => theme.colors.system.alpha};
  padding: 6px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.system.gamma2};
`;

const FileList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 4px;
  overflow-x: auto;
`;

const FileItem = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: center;
  justify-content: center;
`;

const FileName = styled.div`
  max-width: 60%;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  padding: 2px 0px;
  text-overflow: ellipsis;
  cursor: pointer;
`;

const StyledButton = styled(Button)`
  display: flex;
  background-color: ${({ theme }) => theme.colors.base.tertiary};
  color: ${({ theme }) => theme.colors.fonts.primary};
  padding: 10px 29px 10px 14px ;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  height: 32px;
  flex-shrink: 0;
  width: fit-content;
  line-height: 30px;
  font-weight: 500;
  border-radius: 7px;
`;

const StyledCloseButton = styled(CloseButton)`
  display: flex;
  background: transparent;
  color: ${({ theme }) => theme.colors.system.beta};
  padding: 12px 10px 10px 2px;
  font-size: 12px;
  line-height: 16px;
  align-items: center;
  justify-content: center;
  height: 32px;
  flex-shrink: 0;
  width: fit-content;
  font-weight: 500;
  border-radius: 50%;
  svg {
    height: 12px;
    width: 12px;
    > path {
      stroke: ${({ theme }) => theme.colors.system.beta};
      fill: ${({ theme }) => theme.colors.system.beta};
  }
}
`;

const StyledAttchmentIcon = styled(AttachmentIcon)`
  display: flex;
  height: 14px;
  width: 14px;
  color: ${({ theme }) => theme.colors.fonts.primary};
  margin-right: 4px;
  > path {
    stroke: ${({ theme }) => theme.colors.fonts.primary};
  }
`;

export {
  FileUploadContainer,
  FileList,
  FileItem,
  FileName,
  StyledButton,
  StyledCloseButton,
  StyledAttchmentIcon,
};
