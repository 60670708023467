import React, {
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

import useAppTheme from '../../../../hooks/useAppTheme';
import CircleCheckIcon from '../../../../components/Icons/CircleCheckIcon';
import { ProgressStatus } from '../../progressStatus';
import {
  StatusCheckmarkContainer,
  Circle,
} from './styles';

const StatusCheckmark = ({
  progressStatus,
  image,
}) => {
  const { colors } = useAppTheme();

  const imageByStatus = useMemo(() => ({
    [ProgressStatus.COMPLETED]: CircleCheckIcon,
    [ProgressStatus.AWAITING]: () => <Circle $backgroundColor={colors.system.alpha} />,
    [ProgressStatus.INACTIVE]: () => <Circle $backgroundColor={colors.system.background} />,
  }), [
    colors,
  ]);

  const StatusImage = image || imageByStatus[progressStatus];

  return (
    <StatusCheckmarkContainer>
      <StatusImage />
    </StatusCheckmarkContainer>
  );
};

StatusCheckmark.propTypes = {
  progressStatus: PropTypes.oneOf(Object.values(ProgressStatus)).isRequired,
  image: PropTypes.elementType,
};

StatusCheckmark.defaultProps = {
  image: null,
};

export default StatusCheckmark;
