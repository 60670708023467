import styled from 'styled-components';

import Note from '../../Note';

import {
  body1,
  body2,
} from '../../../styles/text';

const CircuitContainer = styled.div`
  margin-bottom: ${({ $enableCircuitMargin }) => ($enableCircuitMargin ? '8px' : 0)};
`;

const CircuitHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 16px 15px;
  min-height: 28px;
`;

const CircuitName = styled.div`
  ${body1}
  color: ${({ theme }) => theme.colors.base.primary};
`;

const Rounds = styled.div`
  ${body2}
  min-width: 65px;
  text-align: right;
  color: ${({ theme }) => theme.colors.base.primary};
`;

const CircuitNote = styled(Note)`
  opacity: 0.4;
  margin: 15px;
`;

export {
  CircuitContainer,
  CircuitHeader,
  CircuitName,
  Rounds,
  CircuitNote,
};
