import React, {
  useEffect,
  useContext,
  useCallback,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import UserNutritionInfoContext, { withUserNutritionInfoContextReady } from '../../../context/UserNutritionInfoContext';
import { withLayout, HeaderContext } from '../../../components/Layout';
import { CronometerIntegration, CronometerUnlinkBanner } from '../../../components/Cronometer';

import {
  SettingsCard,
  SettingsCardTitle,
} from '../components/styles';
import {
  Container,
} from './styles';
import texts from './texts.json';

const ManageConnections = () => {
  const { setTitle } = useContext(HeaderContext);
  const {
    cronometerTokenDoc,
  } = useContext(UserNutritionInfoContext);

  useEffect(() => {
    setTitle(texts.manageConnections);
  }, [
    setTitle,
  ]);

  const renderCronometerCard = useCallback(() => {
    const hasCronometerIntegration = !!cronometerTokenDoc.active;
    if (hasCronometerIntegration) {
      return (
        <CronometerUnlinkBanner />
      );
    }
    return (
      <CronometerIntegration />
    );
  }, [
    cronometerTokenDoc,
  ]);

  return (
    <Container>
      <SettingsCard>
        <SettingsCardTitle>{texts.nutrition}</SettingsCardTitle>
        {renderCronometerCard()}
      </SettingsCard>
    </Container>
  );
};

export default compose(
  withLayout,
  withUserNutritionInfoContextReady,
  observer,
)(ManageConnections);
