import React from 'react';
import PropTypes from 'prop-types';

import defaultAvatar from '../../assets/avatar.svg';

import { Image } from './styles';

const Avatar = ({
  url,
  className,
  ...props
}) => {
  const avatarUrl = url || defaultAvatar;
  return (
    <Image
      src={avatarUrl}
      alt="Avatar"
      className={className}
      {...props}
    />
  );
};

Avatar.propTypes = {
  url: PropTypes.string,
  className: PropTypes.string,
};

Avatar.defaultProps = {
  url: '',
  className: '',
};

export default Avatar;
