import format from 'string-template';

import { fontFamily } from '../../../styles/text';

import texts from './texts.json';

const getChartOptions = (colors) => ({
  type: 'doughnut',
  options: {
    aspectRatio: 2,
    responsive: true,
    maintainAspectRatio: true,
    cutout: '85%',
    borderAlign: 'inner',
    borderRadius: 80,
    events: [],
    plugins: {
      legend: {
        display: true,
        position: 'right',
        onClick: null,
        labels: {
          boxWidth: 6,
          color: colors.shades.primary80,
          font: (context) => {
            // Setting labels font size based on chart width
            const { width } = context.chart;
            const size = Math.round(width / 24);
            return {
              size,
              family: fontFamily.sansSerif,
            };
          },
        },
      },
    },
  },
});

const getChartPlugins = (nutritionStats, colors) => {
  const plugins = [];
  const { calories, caloriesUnit } = nutritionStats;
  // This custom plugin draws the calories for the given item and a dotted line circle inside the chart
  const innerDrawPlugin = {
    id: 'innerDraw',
    beforeDraw: (chart) => {
      const { chartArea, ctx } = chart;
      const { width, height } = chartArea;

      // Font size for the inner part of the chart, calculated based on chart height
      const fontSize = ((height / 100) * 1.4).toFixed(2);

      // Save canvas options for restoring them later
      ctx.save();

      const dottedLineDash = 7;
      const chartCenterX = width / 2;
      const chartCenterY = height / 2;
      const circleRadius = Math.round(width * 0.29);
      const circleLineWidth = Math.floor(parseInt(fontSize, 10));

      // Drawing dotted line circle
      ctx.beginPath();
      ctx.setLineDash([dottedLineDash, dottedLineDash]);
      ctx.beginPath();
      ctx.arc(chartCenterX, chartCenterY, circleRadius, 0, Math.PI * 2);
      ctx.closePath();
      ctx.strokeStyle = colors.shades.primary20;
      ctx.lineWidth = circleLineWidth;
      ctx.stroke();

      // Restore and save canvas options before drawing text
      ctx.restore();
      ctx.save();

      ctx.font = `${fontSize}em ${fontFamily.serif}`;
      ctx.textBaseline = 'middle';
      ctx.fillStyle = colors.shades.primary80;

      // Drawing top text
      const topText = calories;
      const topTextLineHeight = fontSize * 3;
      const topTextX = Math.round((width - ctx.measureText(topText).width) / 2);
      const topTextY = chartCenterY - topTextLineHeight;
      ctx.fillText(topText, topTextX, topTextY);

      // Drawing bottom text
      ctx.font = `${fontSize / 2}em ${fontFamily.serif}`;
      const bottomText = caloriesUnit.charAt(0).toUpperCase() + caloriesUnit.slice(1);
      const bottomTextLineHeight = fontSize * 8;
      const bottomTextX = Math.round((width - ctx.measureText(bottomText).width) / 2);
      const bottomTextY = chartCenterY + bottomTextLineHeight;
      ctx.fillText(bottomText, bottomTextX, bottomTextY);

      // Restore original canvas options
      ctx.restore();
    },
  };
  plugins.push(innerDrawPlugin);
  return plugins;
};

const getChartData = (nutritionStats) => {
  const {
    percentFat,
    percentProtein,
    percentCarbs,
    fat,
    protein,
    carbs,
  } = nutritionStats;

  const fatLabel = format(texts.graph.labels.fat, fat);
  const proteinLabel = format(texts.graph.labels.protein, protein);
  const carbsLabel = format(texts.graph.labels.carbs, carbs);

  return {
    labels: [
      fatLabel,
      proteinLabel,
      carbsLabel,
    ],
    datasets: [{
      data: [percentFat, percentProtein, percentCarbs],
      backgroundColor: [
        'rgb(86, 191, 219)',
        'rgb(250, 18, 79)',
        'rgb(110, 217, 64)',
      ],
    }],
  };
};

export {
  getChartOptions,
  getChartPlugins,
  getChartData,
};
