import styled from 'styled-components';
import { body2 } from '../../../styles/text';
import LoadingButton from '../../Button/LoadingButton';

const SendFeedbackButton = styled(LoadingButton)`
  display: flex;
  width: 100%;
  height: 70px;
  margin: 0 0 20px 0;
  justify-content: center;
  align-items: center;
  border: none;
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.base.secondary};
`;

const FeedbackDateText = styled.div`
  ${body2}
  margin-bottom: 15px;
`;

export {
  SendFeedbackButton,
  FeedbackDateText,
};
