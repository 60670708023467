import React from 'react';
import PropTypes from 'prop-types';

import Summary from './Summary';
import colors from './colors';
import texts from './texts.json';
import {
  SummaryDataContainer,
} from './styles';

const SummaryDataSection = ({
  summaryData,
  className,
}) => {
  const {
    energyBurned = 0,
    energyBurnedGoal = 0,
    exerciseTime = 0,
    exerciseTimeGoal = 0,
    standHours = 0,
    standHoursGoal = 0,
  } = summaryData || {};

  return (
    <SummaryDataContainer className={className}>
      <Summary
        value={energyBurned}
        goal={energyBurnedGoal}
        unit={texts.energyUnit}
        color={colors.activeEnergy}
      />
      <Summary
        value={exerciseTime}
        goal={exerciseTimeGoal}
        unit={texts.timeUnit}
        color={colors.exercise}
      />
      <Summary
        value={standHours}
        goal={standHoursGoal}
        unit={texts.standTimeUnit}
        color={colors.standHours}
      />
    </SummaryDataContainer>
  );
};

SummaryDataSection.propTypes = {
  className: PropTypes.string,
  summaryData: PropTypes.shape({
    energyBurned: PropTypes.number.isRequired,
    energyBurnedGoal: PropTypes.number.isRequired,
    exerciseTime: PropTypes.number.isRequired,
    exerciseTimeGoal: PropTypes.number.isRequired,
    standHours: PropTypes.number.isRequired,
    standHoursGoal: PropTypes.number.isRequired,
  }),
};

SummaryDataSection.defaultProps = {
  className: '',
  summaryData: null,
};

export default SummaryDataSection;
