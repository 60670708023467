import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useContext,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import logEvent from '../../../../utils/logger';
import useComponentMounted from '../../../../hooks/useComponentMounted';
import { ExerciseTagCategory } from '../../../../models/ExerciseTag';
import UserExerciseOverridesContext from '../../../../context/UserExerciseOverridesContext';
import {
  getExercisesTagsByCategory,
  getExerciseTagsByCategory,
  defaultTagsByCategory,
} from '../../utils/exerciseTags';
import {
  filterByTags,
} from '../../exercisesSearch';
import ExerciseOverrideSelectorContext from './ExerciseOverrideSelectorContext';

const ExerciseOverrideSelectorContextProvider = ({
  activity,
  onOverrideDone,
  children,
}) => {
  const allExercisesRef = useRef([]);
  const originalExerciseRef = useRef(null);
  // Exercises array filtered by selected tags
  const [exercises, setExercises] = useState([]);
  const [selectedTagsByCategory, setSelectedTagsByCategory] = useState(null);
  const [tagsByCategory, setTagsByCategory] = useState({});
  // Shared status across exercise override related components
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [searchText, setSearchText] = useState('');
  // Readiness status
  const [isReady, setIsReady] = useState(false);

  const {
    userExerciseOverridesDoc,
    getExerciseById,
    calculateExercisesForSelection,
    updateActiveExerciseOverride,
  } = useContext(UserExerciseOverridesContext);

  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    if (!isReady) {
      const init = async () => {
        // Setup original exercise and selected exercise if any
        const {
          exerciseId: currentExerciseId,
        } = activity;

        let originalExerciseData;
        let allExercises;
        let currentSelectedTags;
        let filteredExercises;

        const exercisesForSelection = await calculateExercisesForSelection();

        if (currentExerciseId) {
          const originalExerciseDoc = await getExerciseById(currentExerciseId);

          originalExerciseData = {
            id: originalExerciseDoc.id,
            name: originalExerciseDoc.name,
            videoPreviewUrl: originalExerciseDoc.videoPreviewUrl,
            videoPreviewThumbnail: originalExerciseDoc.videoPreviewThumbnail,
            videoSrc: originalExerciseDoc.videoSrc,
          };

          // Setup selected tags
          currentSelectedTags = getExerciseTagsByCategory(originalExerciseDoc);

          // Exclude the exercise being replaced
          allExercises = exercisesForSelection
            .filter((exercise) => originalExerciseDoc.id !== exercise.id);

          /*
            Prepare the initial filtered exercises for selection, when tags are preselected. In case
            we cannot guarantee a result for the given combination of tags, start removing tags until
            there's a filtered result that contains exercises to show to the users.
          */
          const categories = Object.keys(currentSelectedTags);
          let iterationCount = 0;
          filteredExercises = filterByTags(allExercises, currentSelectedTags);

          while (filteredExercises.length === 0 && iterationCount <= categories.length) {
            iterationCount += 1;
            const category = categories[categories.length - iterationCount];

            currentSelectedTags[category] = [];
            filteredExercises = filterByTags(allExercises, currentSelectedTags);
          }
        } else {
          const {
            name,
            videoUrl,
            videoPreviewUrl,
            videoPreviewThumbnail,
          } = activity;

          originalExerciseData = {
            name,
            videoPreviewUrl,
            videoPreviewThumbnail,
            videoSrc: videoPreviewUrl || videoUrl,
          };
          allExercises = exercisesForSelection;
          currentSelectedTags = defaultTagsByCategory();
          filteredExercises = allExercises;
        }

        if (isComponentMountedRef.current) {
          allExercisesRef.current = allExercises;
          originalExerciseRef.current = originalExerciseData;
          setExercises(filteredExercises);
          setSelectedTagsByCategory(currentSelectedTags);
          setIsReady(true);
        }
      };

      init();
    }
  }, [
    isReady,
    activity,
    userExerciseOverridesDoc,
    getExerciseById,
    calculateExercisesForSelection,
    isComponentMountedRef,
  ]);

  /**
   * Calculates the available list of exercises for selection. This list is filtered by the current
   * selected tags or search text if any.
   */
  useEffect(() => {
    /*
      When some category tag is selected, filter the entire exericses list by tags.
      By default, when there's no filter selected, all available exercises will be returned.
    */
    const filteredExercises = filterByTags(allExercisesRef.current, selectedTagsByCategory);
    setExercises(filteredExercises);
    setTagsByCategory(getExercisesTagsByCategory(filteredExercises));
  }, [
    selectedTagsByCategory,
  ]);

  /**
   * Update the selected tag from tag's filters.
   *
   * @param {string} categoryId
   * @param {Array} tags
   */
  const updateSelectedTag = useCallback((categoryId, newTag) => {
    // NOTE: for now, we only support a single selected tag per category
    setSelectedTagsByCategory((currentValue) => {
      const newValue = {
        ...currentValue,
        [categoryId]: newTag ? [newTag] : [],
      };

      if (categoryId === ExerciseTagCategory.BODY_REGION_BASIC && !newTag) {
        // When there's no selection for BODY_REGION_BASIC, remove BODY_REGION_ADVANCED selection
        newValue[ExerciseTagCategory.BODY_REGION_ADVANCED] = [];
      }

      return newValue;
    });
  }, []);

  const applyExerciseOverride = useCallback((selectedExercise) => {
    if (selectedExercise) {
      updateActiveExerciseOverride(originalExerciseRef.current, selectedExercise);
    }
  }, [
    updateActiveExerciseOverride,
  ]);

  const logExerciseOverrideEvent = useCallback((eventName, eventProps = {}) => {
    const {
      name: originalExerciseName,
      id: originalExerciseId,
    } = originalExerciseRef.current;

    logEvent(eventName, {
      ...eventProps,
      originalExerciseName,
      originalExerciseId,
    });
  }, []);

  const context = useMemo(() => ({
    exercises,
    allExercisesRef,
    tagsByCategory,
    originalExerciseRef,
    selectedTagsByCategory,
    searchText,
    activity,
    isSearchEnabled,
    isReady,
    onOverrideDone,
    setSearchText,
    updateSelectedTag,
    applyExerciseOverride,
    setIsSearchEnabled,
    logExerciseOverrideEvent,
  }), [
    exercises,
    tagsByCategory,
    originalExerciseRef,
    selectedTagsByCategory,
    searchText,
    activity,
    isSearchEnabled,
    isReady,
    onOverrideDone,
    setSearchText,
    updateSelectedTag,
    applyExerciseOverride,
    setIsSearchEnabled,
    logExerciseOverrideEvent,
  ]);

  return (
    <ExerciseOverrideSelectorContext.Provider value={context}>
      {children}
    </ExerciseOverrideSelectorContext.Provider>
  );
};

ExerciseOverrideSelectorContextProvider.propTypes = {
  activity: PropTypes.object.isRequired,
  onOverrideDone: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(ExerciseOverrideSelectorContextProvider);
