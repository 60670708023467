import React from 'react';

import {
  Container,
} from './styles';
import text from './text.json';

const SuccessPage = () => (
  <Container>
    {text.reonboardingSuccessText}
  </Container>
);

export default SuccessPage;
