import styled, { css } from 'styled-components';

import { fontFamily } from '../../../styles/text';

const baseTextCss = css`
  font-family: ${fontFamily.sansSerif};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
`;

const Container = styled.div`
  ${baseTextCss};
`;

const Item = styled.div`
  color: ${({ theme }) => theme.colors.shades.primary80};
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
`;

const Indicator = styled.div`
  font-weight: 700;
  padding: 0 8px 0 0;
`;

export {
  Container,
  Item,
  Indicator,
};
