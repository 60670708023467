import React from 'react';
import styled from 'styled-components';
import { IonAlert } from '@ionic/react';

// eslint-disable-next-line react/prop-types
const StyledAlert = ({ className, ...other }) => <IonAlert cssClass={className} {...other} />;

const CommentAlert = styled(StyledAlert)`
  textarea.commentTextArea  {
    background-color: ${({ theme }) => theme.colors.system.gamma6};
    height: 125px;
    border: none;
  }
`;

export {
  CommentAlert,
};
