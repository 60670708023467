import { Collection } from 'firestorter';

import { firestorePaths } from '../utils/firebasePaths';
import BaseDocument from './BaseDocument';

class UserNutritionLog extends BaseDocument {
  constructor(id, opts) {
    super(`${firestorePaths.USER_NUTRITION_LOG}/${id}`, opts);
  }

  get user() {
    return this.data.user;
  }

  get startTime() {
    return this.data.startTime;
  }

  get endTime() {
    return this.data.endTime;
  }

  get meals() {
    return this.data.meals || [];
  }

  get macros() {
    return this.data.macros || {};
  }

  // TODO: remove once 'terra' is completely deprecated [S2-3036]
  get source() {
    return this.data.source || '';
  }

  static async getUserNutritionLogs(user, fromDate, toDate) {
    const userNutritionLogsCollection = new Collection(firestorePaths.USER_NUTRITION_LOG, {
      createDocument: (src, opts) => new UserNutritionLog(src.id, opts),
      query: (ref) => ref
        .where('user', '==', user)
        .where('startTime', '>=', fromDate)
        .where('startTime', '<=', toDate)
        .orderBy('startTime', 'asc'),
    });
    await userNutritionLogsCollection.fetch();
    return userNutritionLogsCollection;
  }
}

export default UserNutritionLog;
