/**
 * Converts a data URI to Blob.
 *
 * @param {string} dataURI
 * @return {Object} Blob object.
 */
const dataURItoBlob = (dataURI) => {
  // convert base64 to raw binary data held in a string
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component. Example: dataURI = 'data:image/jpeg;base64,<base64_here>' -> result: image/jpeg
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const typedArray = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    typedArray[i] = byteString.charCodeAt(i);
  }

  const dataView = new DataView(arrayBuffer);

  return new Blob([dataView], { type: mimeString });
};

/**
 * Converts a data URI to a Blob URL.
 * NOTE: Always call URL.revokeObjectURL(blobURL); once it is not longer used. Otherwise a memory leak will happen.
 *
 * @param {string} dataURI
 * @return {string} Blob URL.
 */
const dataURItoBlobURL = (dataURI) => URL.createObjectURL(dataURItoBlob(dataURI));

/**
 * Downloads a file from firebase storage and returns a blob URL
 * @param storagePath
 * @param storage firebase storage instance
 * @returns {Promise<string>} Blob URL.
 */
const storageURLtoBlobURL = async (storagePath, storage) => {
  const downloadUrl = await storage
    .ref()
    .child(storagePath)
    .getDownloadURL();
  const fileData = await fetch(downloadUrl);
  const fileBlob = await fileData.blob();
  return URL.createObjectURL(fileBlob);
};

const blobToBase64 = (blob) => new Promise((resolve) => {
  const reader = new FileReader();
  reader.onload = () => resolve(reader.result);
  reader.readAsDataURL(blob);
});

const fileURLtoFile = async (fileURL, fileName, defaultFileType) => {
  const fileData = await fetch(fileURL);
  const blob = await fileData.blob();
  const type = blob.type || defaultFileType;
  const file = new File([blob], fileName, { type });
  return file;
};

export {
  dataURItoBlob,
  dataURItoBlobURL,
  storageURLtoBlobURL,
  blobToBase64,
  fileURLtoFile,
};
