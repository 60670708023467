import React from 'react';
import PropTypes from 'prop-types';

import { FeedbackDateText, SendFeedbackButton } from './styles';

const SendFeedbackSection = ({
  isLoading,
  isFeedbackSent,
  message,
  buttonText,
  onFeedbackSubmit,
}) => (
  <>
    <FeedbackDateText>
      {message}
    </FeedbackDateText>
    <SendFeedbackButton
      type="submit"
      isLoading={isLoading}
      feedbackSent={isFeedbackSent}
      onClick={onFeedbackSubmit}
    >
      {buttonText}
    </SendFeedbackButton>
  </>
);

SendFeedbackSection.propTypes = {
  message: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isFeedbackSent: PropTypes.bool.isRequired,
  onFeedbackSubmit: PropTypes.func.isRequired,
};

SendFeedbackSection.defaultProps = {
  message: '',
};

export default SendFeedbackSection;
