import styled, { css } from 'styled-components';

import Card from '../../../../components/Card';
import MealImage from '../../../../components/MealImage';
import { sansSerif } from '../../../../styles/text';

const commonPadding = css`
  padding: 0 24px;
`;

const commonMargin = css`
  margin-bottom: 8px;
`;

const StyledCard = styled(Card)`
  ${sansSerif.headlineFontStyles}
  color: ${({ theme }) => theme.colors.base.primary};
  padding: 24px 0;
  display: flex;
  flex-direction: column;
`;

const StyledMealImage = styled(MealImage)`
  height: auto;
`;

const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 220px;
  max-height: 220px;
  margin-bottom: 16px;
`;

const Name = styled.div`
  ${commonPadding}
  ${commonMargin}
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
`;

const NutrientsContainer = styled.div`
  ${commonPadding}
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Nutrient = styled.div`
  margin-right: 5px;
  line-height: 14px;
  > span {
    font-weight: bold;
  }
`;

export {
  StyledCard,
  ImageContainer,
  Name,
  NutrientsContainer,
  StyledMealImage,
  Nutrient,
};
