import styled from 'styled-components';

import {
  body2,
} from '../../styles/text';
import Thumbnail from '../Thumbnail';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const StyledThumbnail = styled(Thumbnail)`
  margin-right: 8px;
`;

const BasicInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const Name = styled.div`
  ${body2}
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.base.primary};
`;

export {
  Container,
  StyledThumbnail,
  BasicInfoWrapper,
  Name,
};
