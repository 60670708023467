import React, {
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';
import { loadStripe } from '@stripe/stripe-js';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';

import config from '../../config';
import ExternalCoach from '../../models/ExternalCoach';
import useComponentMounted from '../../hooks/useComponentMounted';
import UserContext from '../UserContext';
import StripeContext from './StripeContext';

const StripeContextProvider = ({
  children,
}) => {
  const isComponentMountedRef = useComponentMounted();
  const [isReady, setIsReady] = useState(false);
  const [stripePromise, setStripePromise] = useState(false);
  const [error, setError] = useState(false);
  const [stripeAccountId, setStripeAccountId] = useState(null);

  const {
    userDoc: {
      assignedCoach,
      id: userId,
    },
  } = useContext(UserContext);

  useEffect(() => {
    const init = async () => {
      let loadStripePromise;

      const externalCoachDoc = new ExternalCoach(assignedCoach);
      await externalCoachDoc.init();

      try {
        if (externalCoachDoc.exists) {
          const {
            stripeAccountId: externalCoachStripeAccountId,
          } = externalCoachDoc;

          if (!externalCoachStripeAccountId) {
            throw new Error('Unable to get Stripe account id of assigned coach');
          }
          if (isComponentMountedRef.current) {
            setStripeAccountId(externalCoachStripeAccountId);
          }

          // Use External coach Stripe Connect account
          loadStripePromise = loadStripe(config.stripe.publicKey, {
            stripeAccount: externalCoachStripeAccountId,
          });
        } else {
          // No external coach, use S2 stripe account
          loadStripePromise = loadStripe(config.stripe.publicKey);
        }
        if (isComponentMountedRef.current) {
          setStripePromise(loadStripePromise);
          setIsReady(true);
        }
      } catch (err) {
        Sentry.captureException(err, {
          extra: {
            assignedCoach,
            userId,
          },
        });
        if (isComponentMountedRef.current) {
          setError(true);
          setIsReady(true);
        }
      }
    };

    if (!isReady) {
      init();
    }
  }, [
    assignedCoach,
    userId,
    isReady,
    isComponentMountedRef,
  ]);

  const context = useMemo(() => ({
    isReady,
    stripePromise,
    stripeAccountId,
    error,
  }), [
    isReady,
    stripePromise,
    stripeAccountId,
    error,
  ]);

  return (
    <StripeContext.Provider value={context}>
      {children}
    </StripeContext.Provider>
  );
};

StripeContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StripeContextProvider;
