import React from 'react';

const LoggedInUserContext = React.createContext({
  isReady: false,
  userId: null,
  userDoc: null,
  userConfigDoc: null,
});

export default LoggedInUserContext;
