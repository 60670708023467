import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';

import ActivityContext from '../../../context/ActivityContext';
import GameplayContext from '../../../context/GameplayContext';

import ManualNextAction from './ManualNextAction';

const ManualNextActionContainer = ({
  sizeConfig,
}) => {
  const {
    onAdvanceToNextActivity,
    activityExecutor: {
      logActivityEvent,
    },
  } = useContext(ActivityContext);

  const {
    actionElementRef,
  } = useContext(GameplayContext);

  const onNextClick = useCallback(() => {
    logActivityEvent('manualNextIconClicked');
    onAdvanceToNextActivity();
  }, [
    logActivityEvent,
    onAdvanceToNextActivity,
  ]);

  return (
    <ManualNextAction
      actionElementRef={actionElementRef}
      sizeConfig={sizeConfig}
      onNextClick={onNextClick}
    />
  );
};

ManualNextActionContainer.propTypes = {
  sizeConfig: PropTypes.shape({
    top: PropTypes.number.isRequired,
    bottom: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }).isRequired,
};

export default ManualNextActionContainer;
