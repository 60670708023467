import React from 'react';
import PropTypes from 'prop-types';

import {
  Header,
  StyledExercisesSection,
} from '../commonStyles';
import texts from './texts.json';
import {
  Container,
  StyledSearchbar,
  StyledButton,
} from './styles';

const SearchExercises = ({
  exercises,
  text,
  onTextChange,
  onCancel,
  className,
}) => (
  <Container className={className}>
    <Header>
      <StyledSearchbar
        inputmode="text"
        mode="ios" /* NOTE: iOS mode is used in all platforms, due to custom styles applied */
        value={text}
        placeholder={texts.searchPlaceholder}
        onIonChange={onTextChange}
      />
      <StyledButton
        full={false}
        onClick={onCancel}
        trackingProps={{
          actionName: 'exerciseOverrideCancelSearch',
        }}
      >
        {texts.actionCancel}
      </StyledButton>
    </Header>
    <StyledExercisesSection
      showOriginalExercise
      exercises={exercises}
    />
  </Container>
);

SearchExercises.propTypes = {
  exercises: PropTypes.array.isRequired,
  text: PropTypes.string.isRequired,
  onTextChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  className: PropTypes.string,
};

SearchExercises.defaultProps = {
  className: '',
};

export default SearchExercises;
