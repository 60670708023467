import React, {
  useContext,
  useEffect,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import moment from 'moment';

import CalendarSlider from '../../../components/CalendarSlider';
import { DateFormat } from '../../../utils/date';
import LogContext from '../../../context/LogContext';
import { Title } from '../BodyMeasurement/styles';

import BodyMeasurement from './BodyMeasurement';
import HabitLogComponent from './HabitLog';
import Health from './Health';
import {
  Container,
  StyledContainer,
} from './styles';
import texts from './texts.json';

const Log = () => {
  const { setCurrentSelectedDate, currentSelectedDate } = useContext(LogContext);

  useEffect(() => (() => (
    setCurrentSelectedDate(moment().format(DateFormat.DEFAULT_DATE_FORMAT))
  )), [setCurrentSelectedDate]);

  return (
    <>
      <Container>
        <CalendarSlider
          currentSelectedDateTime={currentSelectedDate}
          onChange={(e) => setCurrentSelectedDate(moment(e.detail.value).format(DateFormat.DEFAULT_DATE_FORMAT))}
        />
      </Container>
      <Container>
        <StyledContainer>
          <BodyMeasurement />
        </StyledContainer>
      </Container>
      <Container>
        <Title>{texts.habitTitle}</Title>
        <StyledContainer>
          <HabitLogComponent />
        </StyledContainer>
      </Container>
      <Container>
        <Title>{texts.healthTitle}</Title>
        <StyledContainer>
          <Health />
        </StyledContainer>
      </Container>
    </>
  );
};

export default compose(
  observer,
)(Log);
