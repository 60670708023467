import {
  isPastDay,
  isFutureDay,
} from '../../../utils/date';

const AssignmentDayType = {
  PAST: 'PAST',
  TODAY: 'TODAY',
  FUTURE: 'FUTURE',
};

/**
 * Calculates the AssignmentDayType based on the workout feed view dates.
 *
 * @param {Object} startDate A moment date instance representing the start date.
 * @returns {string} One of AssignmentDayType.
 */
const getAssignmentDayType = (startDate) => {
  if (isPastDay(startDate)) {
    return AssignmentDayType.PAST;
  }
  if (isFutureDay(startDate)) {
    return AssignmentDayType.FUTURE;
  }
  return AssignmentDayType.TODAY;
};

export default AssignmentDayType;
export {
  getAssignmentDayType,
};
