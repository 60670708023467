import { IonCheckbox } from '@ionic/react';
import styled from 'styled-components';
import { sansSerif } from '../../../../styles/text';

const HabitText = styled.span`
  ${sansSerif.headlineFontStyles}
  font-size: 16px;
  line-height: 22px;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  margin-top: 8px;
  background-color: ${({ theme }) => theme.colors.system.background};
  border-radius: 10px;
`;

const TextContainer = styled.div`
  display: flex;
  flex: 1;
`;

const StyledCheckBox = styled(IonCheckbox)`
  --background-checked: ${({ theme }) => theme.colors.system.beta};
  --border-color-checked: ${({ theme }) => theme.colors.system.beta};
`;

export {
  HabitText,
  ItemContainer,
  TextContainer,
  StyledCheckBox,
};
