import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext,
} from 'react';

import AppContext from '../../../context/AppContext';
import DebugSelfie from '../DebugSelfie';
import { SelfieVideoContext } from '../SelfieVideoContext';
import {
  DebugActionsContainer,
  BasicControls,
} from './styles';

/*
  This value is used for increasing the accuracy of the time slider, when using a debug video.
  If we don't use a multiplier, then the time slider minimum unit would be 1 second.
  In this case, the minimun time value would be 1s / 5 = 200ms
*/
const debugVideoMultiplier = 5;

const TestVideo = () => {
  const [currentTime, setCurrentTime] = useState(0);
  const [showDebugControls, setShowDebugControls] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [throttling, setThrottling] = useState(0);
  const debugSelfie = useRef(null);
  const previewRef = useRef(null);
  const {
    takePic,
    isDebugPlaying,
    setDebugSelfieRef,
    setPreviewSelfieRef,
    ready,
  } = useContext(SelfieVideoContext);

  const {
    debugSettings: {
      testVideoServerURL,
      testVideoName,
    },
  } = useContext(AppContext);

  const handleTimeSliderChange = (event) => {
    const { value } = event.target;
    const newTime = value / debugVideoMultiplier;
    setCurrentTime(newTime);
    previewRef.current.currentTime = newTime;
  };

  const handleThrottlingChange = (event) => setThrottling(Number(event.target.value));

  const handlePlayPauseVideo = useCallback(() => {
    if (!previewRef.current) {
      return;
    }

    setIsPlaying(!isPlaying);

    if (previewRef.current.paused) {
      previewRef.current.play();
    } else {
      previewRef.current.pause();
    }
  }, [
    isPlaying,
    previewRef,
    setIsPlaying,
  ]);

  const handleStopVideo = useCallback(() => {
    if (!previewRef.current) {
      return;
    }

    previewRef.current.pause();
    previewRef.current.currentTime = 0;

    setIsPlaying(false);
  }, [
    previewRef,
    setIsPlaying,
  ]);

  useEffect(() => {
    if (previewRef.current) {
      if (isDebugPlaying) {
        previewRef.current.play();
        setIsPlaying(true);
      } else {
        handleStopVideo();
      }
    }
  }, [
    previewRef,
    isDebugPlaying,
    handleStopVideo,
    setIsPlaying,
  ]);

  useEffect(() => {
    if (previewRef.current && debugSelfie.current) {
      setDebugSelfieRef(debugSelfie);
      setPreviewSelfieRef(previewRef);
    }
  }, [
    debugSelfie,
    previewRef,
    setDebugSelfieRef,
    setPreviewSelfieRef,
  ]);

  useEffect(() => {
    previewRef.current.crossOrigin = 'anonymous';
    previewRef.current.src = encodeURI(`${testVideoServerURL}/${testVideoName}`);
    previewRef.current.loop = true;
    previewRef.current.muted = true;

    // Enable controls for this video
    // Debug function that controls the time slider
    previewRef.current.ontimeupdate = () => setCurrentTime(previewRef.current.currentTime);
    // Debug function that enables the video controls as soon as the video has loaded.
    previewRef.current.onloadeddata = () => setShowDebugControls(true);

    // start processing images
    ready();
  }, [
    takePic,
    previewRef,
    ready,
    testVideoServerURL,
    testVideoName,
  ]);

  const inputMax = previewRef.current && previewRef.current.duration
    ? previewRef.current.duration * debugVideoMultiplier
    : 0;

  return (
    <>
      <video
        alt="test selfie video"
        ref={previewRef}
        autoPlay
        playsInline
        controls={false}
        style={{ display: 'none' }}
      />
      <DebugSelfie>
        <canvas ref={debugSelfie} />
        <DebugActionsContainer>
          {showDebugControls && (
            <BasicControls>
              <button
                type="button"
                disabled={!previewRef.current}
                onClick={handlePlayPauseVideo}
              >
                {isPlaying ? '❙ ❙' : '▶'}
              </button>
              <button
                type="button"
                disabled={!previewRef.current}
                onClick={handleStopVideo}
              >
                ■
              </button>
              <input
                type="range"
                min={0}
                max={inputMax}
                value={currentTime * debugVideoMultiplier}
                onChange={handleTimeSliderChange}
              />
            </BasicControls>
          )}
          <div>
            <span>Take pic throttling in ms:</span>
            <input
              type="number"
              min="0"
              step="1"
              value={throttling}
              onChange={handleThrottlingChange}
            />
          </div>
        </DebugActionsContainer>
      </DebugSelfie>
    </>
  );
};

export default TestVideo;
