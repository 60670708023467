import posthog from 'posthog-js';
import config from '../config';

const {
  posthog: { apiHost, apiKey },
} = config;

posthog.init(apiKey, { api_host: apiHost });

const logEvent = (eventType, eventProperties = {}, sampleRate = 1.0) => {
  // We log events to Segment and Posthog
  if (Math.random() < sampleRate) {
    window.analytics.track(eventType, eventProperties);
    posthog.capture(eventType, eventProperties);
  }
};

const logPageViewEvent = (pageName) => {
  window.analytics.page(pageName);
  posthog.capture('$pageview');
};

const identify = (userId, traits) => {
  window.analytics.identify(userId, traits);
  posthog.identify(userId, traits);
};

const setUserTraits = (traits) => {
  window.analytics.identify(traits);
  posthog.setPersonProperties(traits);
};

const reset = () => {
  window.analytics.reset();
  posthog.reset();
};

export default logEvent;

export {
  logPageViewEvent,
  identify,
  setUserTraits,
  reset,
};
