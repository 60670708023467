import React, {
  useContext,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { useIonToast } from '@ionic/react';

import UserContext from '../../../context/UserContext';
import FirebaseContext from '../../../context/FirebaseContext';
import { withLayout, HeaderContext } from '../../../components/Layout';
import Avatar from '../../../components/Avatar';
import AvatarUpload from '../../../components/AvatarUpload';

import {
  SettingsCard,
  SettingsCardTitle,
} from '../components/styles';
import {
  Container,
  AvatarCard,
  StyledInput,
  StyledButton,
} from './styles';
import texts from './texts.json';

const toastDurationMs = 3000;

const ProfileSettings = () => {
  const { setTitle } = useContext(HeaderContext);
  const { userDoc } = useContext(UserContext);
  const { firebase: { auth } } = useContext(FirebaseContext);

  const [showToast] = useIonToast();

  const {
    data: {
      avatarUrl,
      firstName: userFirstName,
      lastName: userLastName,
      email: userEmail,
    },
  } = userDoc;

  // Input states
  const [firstName, setFirstName] = useState(userFirstName);
  const [lastName, setLastName] = useState(userLastName);
  const [email, setEmail] = useState(userEmail);

  const [isSaving, setIsSaving] = useState(false);

  // Set layout title
  useEffect(() => {
    setTitle(texts.updateProfile);
  }, [setTitle]);

  const handleSaveDetails = useCallback(async () => {
    setIsSaving(true);
    try {
      // Save Firebase auth changes
      const authUser = auth.currentUser;
      await authUser.updateEmail(email);
      await authUser.updateProfile({ displayName: firstName });

      // Save userDoc changes
      await userDoc.updateFields({
        firstName,
        lastName,
        email,
      });

      setIsSaving(false);
      showToast({
        message: texts.successToast,
        duration: toastDurationMs,
        color: 'success',
      });
    } catch (error) {
      setIsSaving(false);
      showToast({
        message: error.message,
        duration: toastDurationMs,
        color: 'warning',
      });
    }
  }, [
    auth,
    firstName,
    lastName,
    email,
    userDoc,
    showToast,
  ]);

  return (
    <Container>
      <AvatarCard>
        <Avatar url={avatarUrl} isLarge />
        <AvatarUpload userDoc={userDoc} />
      </AvatarCard>
      <SettingsCard>
        <SettingsCardTitle>{texts.section.name}</SettingsCardTitle>
        <StyledInput
          value={firstName}
          title={texts.field.firstName}
          onChange={(value) => setFirstName(value)}
        />
        <StyledInput
          value={lastName}
          title={texts.field.lastName}
          onChange={(value) => setLastName(value)}
        />
      </SettingsCard>
      <SettingsCard>
        <SettingsCardTitle>{texts.section.email}</SettingsCardTitle>
        <StyledInput
          value={email}
          title={texts.field.email}
          onChange={(value) => setEmail(value?.toLowerCase())}
        />
      </SettingsCard>
      <StyledButton
        onClick={handleSaveDetails}
        disabled={isSaving}
      >
        {texts.saveButton}
      </StyledButton>
    </Container>
  );
};

export default compose(
  withLayout,
  observer,
)(ProfileSettings);
