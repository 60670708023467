import CollectionName from '../utils/collections';
import BaseDocument from './BaseDocument';

const DEFAULT_MACRO_PERCENTAGES = {
  protein: 30,
  carbs: 50,
  fat: 20,
};

class UserNutritionMacroGoals extends BaseDocument {
  constructor(id, opts) {
    super(`${CollectionName.USER_NUTRITION_MACRO_GOALS}/${id}`, opts);
  }

  get bmr() {
    return this.data.bmr || 0;
  }

  get tdee() {
    return this.data.tdee || 0;
  }

  get carbs() {
    return this.data.carbs || 0;
  }

  get fat() {
    return this.data.fat || 0;
  }

  get protein() {
    return this.data.protein || 0;
  }

  get totalCarbsCalories() {
    return this.data.totalCarbsCalories || 0;
  }

  get totalProteinCalories() {
    return this.data.totalProteinCalories || 0;
  }

  get totalFatCalories() {
    return this.data.totalFatCalories || 0;
  }

  get totalDailyCalories() {
    const totalCaloriesValue = this.data.totalDailyCalories
      || (this.totalCarbsCalories + this.totalProteinCalories + this.totalFatCalories);

    return Math.round(totalCaloriesValue);
  }

  get proteinPercentage() {
    return this.data.proteinPercentage || DEFAULT_MACRO_PERCENTAGES.protein;
  }

  get carbsPercentage() {
    return this.data.carbsPercentage || DEFAULT_MACRO_PERCENTAGES.carbs;
  }

  get fatPercentage() {
    return this.data.fatPercentage || DEFAULT_MACRO_PERCENTAGES.fat;
  }

  get macroAverages() {
    return this.data.macroAverages || {};
  }
}

export default UserNutritionMacroGoals;
export {
  DEFAULT_MACRO_PERCENTAGES,
};
