import styled from 'styled-components';

import CloseButton from '../CloseButton';

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: 12px;
  left: 12px;
`;

export {
  StyledCloseButton,
};
