import BaseDocument from './BaseDocument';

const ExerciseTagTypes = {
  BASE: 'BASE',
  CUSTOM: 'CUSTOM',
};

const ExerciseTagCategory = {
  EXERCISE_TYPE: 'EXERCISE_TYPE',
  EQUIPMENT: 'EQUIPMENT',
  BODY_REGION_BASIC: 'BODY_REGION_BASIC',
  BODY_REGION_ADVANCED: 'BODY_REGION_ADVANCED',
};

class ExerciseTag extends BaseDocument {
  get type() {
    return this.data.type;
  }

  get category() {
    return this.data.category;
  }

  get coach() {
    return this.data.coach || '';
  }

  get tag() {
    return this.data.tag;
  }
}

export default ExerciseTag;
export {
  ExerciseTagTypes,
  ExerciseTagCategory,
};
