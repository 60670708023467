import { AttachmentActions } from './utils';

const texts = {
  actionsPopover: {
    [AttachmentActions.PHOTO]: 'Upload Image',
    [AttachmentActions.VIDEO]: 'Upload Video',
  },
};

export default texts;
