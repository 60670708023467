import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';

import useComponentLoadingTime from '../../hooks/useComponentLoadingTime';
import AggregatedData from '../../models/AggregatedData';

const AggregatedDataContext = React.createContext();

const AggregatedDataContextProvider = ({ children }) => {
  const { params: { userId } } = useRouteMatch();

  const [isReady, setIsReady] = useState(false);
  const [aggregatedDataDoc, setAggregatedDataDoc] = useState(null);

  const {
    startLoading,
    finishLoading,
  } = useComponentLoadingTime('AggregatedDataContextProvider');

  useEffect(() => {
    startLoading();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let shouldUpdate = true;

    if (!isReady) {
      const init = async () => {
        const dataDoc = new AggregatedData(`/aggregated/${userId}`);
        await dataDoc.init();
        if (shouldUpdate) {
          setAggregatedDataDoc(dataDoc);
          setIsReady(true);

          finishLoading();
        }
      };
      init();
    }

    return () => {
      shouldUpdate = false;
    };
  }, [
    userId,
    isReady,
    finishLoading,
  ]);

  const context = useMemo(() => ({
    isReady,
    aggregatedDataDoc,
  }), [
    isReady,
    aggregatedDataDoc,
  ]);

  return (
    <AggregatedDataContext.Provider value={context}>
      {children}
    </AggregatedDataContext.Provider>
  );
};

AggregatedDataContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AggregatedDataContext;
export {
  AggregatedDataContextProvider,
};
