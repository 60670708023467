import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Camera, CameraResultType } from '@capacitor/camera';

import { withFirebase } from '../../context/FirebaseContext';
import { ErrorMessage, UploadButton } from './styles';
import texts from './texts.json';

const AvatarUpload = ({ firebase, userDoc }) => {
  const [avatarError, setAvatarError] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [avatar, setAvatar] = useState(null);

  const takePicture = useCallback(async () => {
    const image = await Camera.getPhoto({
      // max width we render the avatar is 140px
      width: 280,
      allowEditing: true,
      resultType: CameraResultType.DataUrl,
      webUseInput: true,
    });

    if (image) {
      const newAvatar = image.dataUrl;

      setIsUploading(true);
      setAvatar(newAvatar);
    }
  }, [
    setIsUploading,
    setAvatar,
  ]);

  useEffect(() => {
    let shouldUpdate = true;

    const updateAvatar = async () => {
      const { id: userId } = userDoc;
      const storageRef = firebase.storage.ref();

      const uploadTask = storageRef
        .child(`user/${userId}/images/avatar.jpeg`)
        .putString(avatar, 'data_url');

      uploadTask.on(
        'state_changed',
        null,
        (error) => {
          if (shouldUpdate) {
            setAvatarError(error);
            setIsUploading(false);
          }
        },
        async () => {
          const avatarUrl = await uploadTask.snapshot.ref.getDownloadURL();
          userDoc.updateFields({ avatarUrl });
          if (shouldUpdate) {
            setAvatarError(null);
            setIsUploading(false);
          }
        },
      );
    };
    if (avatar) {
      updateAvatar();
    }
    return () => {
      shouldUpdate = false;
    };
  }, [
    avatar,
    firebase.storage,
    userDoc,
  ]);

  return (
    <>
      <UploadButton
        disabled={isUploading}
        onClick={takePicture}
      >
        {texts.actionUpload}
      </UploadButton>
      {!!avatarError && (
        <ErrorMessage>
          {avatarError}
        </ErrorMessage>
      )}
    </>
  );
};

AvatarUpload.propTypes = {
  userDoc: PropTypes.object.isRequired,
  firebase: PropTypes.object.isRequired,
};

export default withFirebase(AvatarUpload);
