import React from 'react';
import PropTypes from 'prop-types';

import MessageCardType from './cardType';
import {
  StyledCard,
  CardContent,
  Message,
  Description,
  CardBackground,
  ActiveCardBackground,
  InactiveCardBackground,
} from './styles';

const MessageCard = ({
  message,
  description,
  type,
  noMargin,
  onClick,
}) => (
  <StyledCard onClick={onClick} noMargin={noMargin}>
    <CardBackground>
      {type === MessageCardType.ACTIVE
        ? <ActiveCardBackground />
        : <InactiveCardBackground />}
    </CardBackground>
    <CardContent>
      <Message>{message}</Message>
      <Description>{description}</Description>
    </CardContent>
  </StyledCard>
);

MessageCard.propTypes = {
  message: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(MessageCardType)),
  noMargin: PropTypes.bool,
  onClick: PropTypes.func,
};

MessageCard.defaultProps = {
  type: MessageCardType.DEFAULT,
  noMargin: false,
  onClick: () => {},
};

export default MessageCard;
