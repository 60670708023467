import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';

import texts from './texts.json';

const FileSelectButton = ({
  ButtonComponent,
  onSelect,
  multiple,
  children,
  className,
  extraInputProps,
  extraButtonProps,
}) => {
  const inputRef = useRef(null);
  return (
    <>
      <input
        hidden
        multiple={multiple}
        type="file"
        ref={inputRef}
        onChange={(event) => onSelect(event)}
        // This workaround enables selecting the same files again, so the onChange is triggered
        // even when the files are the same as before.
        onClick={(event) => {
          // eslint-disable-next-line no-param-reassign
          event.target.value = '';
        }}
        {...extraInputProps}
      />
      <ButtonComponent
        onClick={() => inputRef.current.click()}
        className={className}
        {...extraButtonProps}
      >
        {children}
      </ButtonComponent>
    </>
  );
};

FileSelectButton.propTypes = {
  ButtonComponent: PropTypes.elementType,
  children: PropTypes.node,
  onSelect: PropTypes.func,
  multiple: PropTypes.bool,
  className: PropTypes.string,
  extraInputProps: PropTypes.object,
  extraButtonProps: PropTypes.object,
};

FileSelectButton.defaultProps = {
  ButtonComponent: Button,
  children: texts.selectAttachments,
  onSelect: () => {},
  multiple: true,
  className: '',
  extraInputProps: {},
  extraButtonProps: {},
};

export default FileSelectButton;
