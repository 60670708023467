const drawActionAreas = (ctx, actionAreas, processingArea) => {
  const color = '#00FF3A';
  ctx.lineWidth = 3;
  ctx.strokeStyle = color;
  ctx.fillStyle = color;

  actionAreas.forEach(({ actionArea, actionAreaBoundingBox }) => {
    const {
      xMin,
      xMax,
      yMin,
      yMax,
    } = actionAreaBoundingBox(actionArea, processingArea);

    ctx.beginPath();
    ctx.moveTo(xMin * ctx.canvas.width, yMin * ctx.canvas.height);
    ctx.lineTo(xMin * ctx.canvas.width, yMax * ctx.canvas.height);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(xMin * ctx.canvas.width, yMax * ctx.canvas.height);
    ctx.lineTo(xMax * ctx.canvas.width, yMax * ctx.canvas.height);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(xMax * ctx.canvas.width, yMax * ctx.canvas.height);
    ctx.lineTo(xMax * ctx.canvas.width, yMin * ctx.canvas.height);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(xMax * ctx.canvas.width, yMin * ctx.canvas.height);
    ctx.lineTo(xMin * ctx.canvas.width, yMin * ctx.canvas.height);
    ctx.stroke();
  });
};

export default drawActionAreas;
