import React, {
  useState,
  useContext,
} from 'react';

import CameraContext from '../../../../context/CameraContext';
import PlaceDevice from '../PlaceDevice';
import ActiveGameplay from '../ActiveGameplay';

const WorkoutStarter = () => {
  const { isCameraEnabled } = useContext(CameraContext);
  const [workoutInProgress, setWorkoutInProgress] = useState(false);

  // Control whether to display the PlaceDevice component.
  const placeDeviceEnabled = true;

  const showWorkoutInProgress = !placeDeviceEnabled || workoutInProgress || !isCameraEnabled;

  const onWorkoutStart = () => {
    setWorkoutInProgress(true);
  };

  return showWorkoutInProgress
    ? <ActiveGameplay />
    : <PlaceDevice onWorkoutStart={onWorkoutStart} />;
};

export default WorkoutStarter;
