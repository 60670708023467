import { Collection } from 'firestorter';

import BaseExerciseTag, { ExerciseTagTypes } from '../../models/ExerciseTag';
import CollectionName from '../../utils/collections';

class ExerciseTag extends BaseExerciseTag {
  static async getGlobalTags() {
    const tagsCollection = new Collection(() => CollectionName.EXERCISE_TAG, {
      createDocument: (src, opts) => new ExerciseTag(src, opts),
      query: (ref) => ref.where('type', '==', ExerciseTagTypes.BASE),
    });

    await tagsCollection.fetch();
    return tagsCollection;
  }

  static async getCoachTags(coachId) {
    const tagsCollection = new Collection(() => CollectionName.EXERCISE_TAG, {
      createDocument: (src, opts) => new ExerciseTag(src, opts),
      query: (ref) => ref
        .where('type', '==', ExerciseTagTypes.CUSTOM)
        .where('coach', '==', coachId),
    });

    await tagsCollection.fetch();
    return tagsCollection;
  }
}

export default ExerciseTag;
export * from '../../models/ExerciseTag';
