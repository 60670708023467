import { Collection } from 'firestorter';

import BaseExercise, { ExerciseType } from '../../models/Exercise';
import CollectionName from '../../utils/collections';

class Exercise extends BaseExercise {
  static async getBaseAndLegacyExercises() {
    const exercisesCollection = new Collection(() => CollectionName.EXERCISE, {
      createDocument: (src, opts) => new Exercise(src, opts),
      query: (ref) => ref.where('type', '==', ExerciseType.BASE),
    });

    await exercisesCollection.fetch();
    return exercisesCollection;
  }

  static async getBaseExercises() {
    return BaseExercise.getBaseExercises();
  }

  static async getCoachExercises(coachId, exerciseType) {
    return BaseExercise.getCoachExercises(coachId, exerciseType);
  }
}

export default Exercise;
export {
  ExerciseType,
};
