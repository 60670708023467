import styled from 'styled-components';

import { ReactComponent as Icon } from '../../../assets/icons/upload-file-icon.svg';

const UploadFileIcon = styled(Icon)`
  circle {
    fill: ${({ theme }) => theme.colors.base.secondary};
  }
`;

export default UploadFileIcon;
