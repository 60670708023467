import styled from 'styled-components';
import Button from '../../../components/Button';

import { headlineFontStyles, sansSerif } from '../../../styles/text';

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0;
  text-align: center;
  overflow: auto;
  background-color: ${({ theme }) => theme.colors.system.alpha};
`;

const CountdownContent = styled.div`
  color: ${({ theme }) => theme.colors.base.secondaryVariant};
  margin: 42px 52px;
`;

const CountdownBackground = styled.div`
  width: 100%;
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  aspect-ratio: 4/3;
`;

const Title = styled.div`
  ${headlineFontStyles}
  font-weight: 500;
  font-size: 25px;
  line-height: 138%;
  letter-spacing: -0.5px;
  padding-bottom: 4px;
`;

const Subtitle = styled.div`
  ${sansSerif.headlineFontStyles}
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 137.5%;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
`;

export {
  StyledContainer,
  StyledButton,
  CountdownContent,
  Title,
  Subtitle,
  CountdownBackground,
};
