import { createGlobalStyle } from 'styled-components';

const TextFontFamily = 'Neue Montreal';

const GlobalStyles = createGlobalStyle`
  html,
  body {
    /*
      The background must be transparent to be able to place a native UI (iOS/Android)
      behind the Capacitor WebView.
      Using !important to override any Ionic framework value.
    */
    background: transparent !important;
  }

  html,
  body,
  #root {
    height: 100%;
    box-sizing: border-box;
    user-select: none;
  }

  body {
    margin: 0;
    font-family: '${TextFontFamily}', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /*
    Disable the adjustment that ionic does for non-ionic elements when they are on focus. Regular html inputs will cause
    this value to be greater than 0, which adds a huge padding at the bottom. By forcing this custom property to 0,
    we are avoiding weird padding values when dealing with regular inputs and forms.
  */
  ion-content {
    --keyboard-offset: 0 !important;
  }
`;

export default GlobalStyles;
