import React from 'react';
import PropTypes from 'prop-types';

import {
  formatSecondsToMinForThreshold,
} from '../../../../../../../utils/time';
import ActivitySideIndicator from '../ActivitySideIndicator';
import {
  StyledActivityPanel,
  Title,
  Subtitle,
  SideIndicatorContainer,
  ActivityPanelExtraInfo,
} from '../styles';

const TimeActivityPanel = ({
  active,
  value,
  title,
  activitySide,
}) => (
  <StyledActivityPanel active={active}>
    <Title>{title}</Title>
    <ActivityPanelExtraInfo>
      <Subtitle>{formatSecondsToMinForThreshold(value)}</Subtitle>
      {
        !!activitySide && (
          <SideIndicatorContainer>
            <ActivitySideIndicator activitySide={activitySide} />
          </SideIndicatorContainer>
        )
      }
    </ActivityPanelExtraInfo>
  </StyledActivityPanel>
);

TimeActivityPanel.propTypes = {
  active: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  activitySide: PropTypes.string,
};

TimeActivityPanel.defaultProps = {
  activitySide: '',
};

export default TimeActivityPanel;
