import styled, { css } from 'styled-components';

const avatarSize = ({ isLarge }) => {
  const size = isLarge ? '100px' : '50px';
  return css`
    width: ${size};
    height: ${size};
  `;
};

const Image = styled.img`
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
  ${(props) => avatarSize(props)}
`;

export {
  Image,
};
