import {
  decorate, action, computed, observable, runInAction,
} from 'mobx';
import * as Sentry from '@sentry/browser';

import logEvent from '../../utils/logger';

import { ActivityTypes } from '../../models/BaseActivity';
import activityStatus, { isActiveStatus } from './activityStatus';
import getProcessorByType from './processors/index';

const DEFAULT_START_COUNTDOWN = 4;
const ACTIVITY_SIDE_DEFAULT_COUNT = 4;

class ActivityExecutor {
  constructor(gameplayStore, activity) {
    /**
     * Reference to the GameplayStore instance.
     * @type {object}
     */
    this.gameplayStore = gameplayStore;

    /**
     * Current activity state. The activity must be always initialized in IDLE state.
     * @type {number}
     */
    this.activityState = activityStatus.IDLE;

    /** Previous activity state.
     * @type {number|null}
     */
    this.prevActivityState = null;

    /**
     * Activity session ID.
     * @type {string|null}
     */
    this.activitySessionId = null;

    /**
     * @type {object}
     */
    this.activityProcessor = null;

    /**
     * The activity object
     */
    this.activity = activity;

    const ActivityProcessor = getProcessorByType(activity.type);

    /**
     * Initializes the activity processor
     */
    this.activityProcessor = new ActivityProcessor(this, activity);

    /**
     * This controls a countdown shown at the beginning of each activity (except for rest).
     */
    this.startActivityCount = activity.startCountdown || DEFAULT_START_COUNTDOWN;

    this.startActivityCountdownTimeout = null;

    /**
     * This controls the side information that can be provided with an activity.
     * This is shown even before the start countdown.
     */
    this.activitySideCount = ACTIVITY_SIDE_DEFAULT_COUNT;

    this.activitySideCountdownTimeout = null;
  }

  /**
   * Activity log event function to keep track of key aspects of the activity execution
   * @param {string} eventName The event name
   * @param {object} eventProps Any extra properties
   * @param {object=} sampleRate Sample ratio to send event
   */
  logActivityEvent = (eventName, eventProps, sampleRate) => {
    try {
      const {
        activity: {
          name: activityName,
          type: activityType,
        },
        prevActivityState,
        activityState,
        gameplayStore: {
          gameplaySessionId,
          activitySession,
        },
      } = this;

      const event = {
        activityContext: {
          prevActivityState,
          activityState,
          activity: {
            name: activityName,
            type: activityType,
          },
          gameplaySessionId,
          activitySessionId: activitySession ? activitySession.id : null,
        },
        ...eventProps,
      };

      logEvent(eventName, event, sampleRate);
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  /**
   * Clear timers. This function must be called on "componentWillUnmount".
   */
  reset = () => {
    this.clearTimers();
  }

  processStepResult = (stepResult) => {
    this.activityProcessor.processStepResult(stepResult);
  }

  /**
   * Starts the execution of the activity. The state transitions to RUNNING,
   * and starts the running loop countdown until reaching the activity
   * required goal.
   */
  start = () => {
    this.gameplayStore.setActivityStartTime();
    this.gameplayStore.setFirstIncompleteActivityWorkoutStartTime(Date.now());
    this.transitionToState(activityStatus.RUNNING);
    this.logActivityEvent('activityStarted');
  }

  finish = async () => {
    if (this.activityState === activityStatus.FINISHING
      || this.activityState === activityStatus.FINISHED) {
      return;
    }

    this.clearTimers();
    this.transitionToState(activityStatus.FINISHING);

    this.logActivityEvent('activityFinishing');

    /*
      Only save the gameplay session if the gameplay was RUNNING
      otherwise there's no data of a gameplay when
      the user is IDLE.
    */
    const shouldSaveSession = (this.prevActivityState === activityStatus.RUNNING
      || this.prevActivityState === activityStatus.PAUSED);

    const result = this.saveResult({ shouldSaveSession });
    const { activitySessionId, complete } = result;

    this.logActivityEvent('activityFinished', { complete });

    runInAction(() => {
      this.activitySessionId = activitySessionId;
      this.transitionToState(activityStatus.FINISHED);
    });
  }

  quit = async () => {
    this.logActivityEvent('activityQuitCalled');

    if (this.activityState !== activityStatus.QUITTING) {
      this.clearTimers();
      this.transitionToState(activityStatus.QUITTING);

      this.logActivityEvent('activityQuitting');

      await this.saveResult({ isQuitting: true });
      this.transitionToState(activityStatus.QUITTED);

      this.logActivityEvent('activityQuitted');
    }
  }

  prepare = async () => {
    if (this.activityState !== activityStatus.PREPARING) {
      this.transitionToState(activityStatus.PREPARING);

      this.logActivityEvent('activityPreparing');

      await this.gameplayStore.startNewActivity();

      this.logActivityEvent('newActivitySessionCreated');

      if (this.activity.type !== ActivityTypes.REST) {
        // If the activity specifies a certain side, we show a message to the user even before the activity countdown.
        if (this.activity.side) {
          this.runActivitySideCountdown();
        } else {
          this.runStartActivityCountdown();
        }
      } else {
        this.transitionToState(activityStatus.READY);
        this.logActivityEvent('activityPrepared');
      }
    }
  }

  /**
   * Saves the result of the workout.
   * @param {Object} options
   */
  saveResult = async (options = {}) => {
    const saveOptions = {
      ...options,
    };

    if (typeof saveOptions.isQuitting !== 'boolean') {
      saveOptions.isQuitting = false;
    }

    if (typeof saveOptions.shouldSaveSession !== 'boolean') {
      saveOptions.shouldSaveSession = true;
    }

    const complete = this.isRequiredGoalAchieved;

    this.logActivityEvent('savingActivityResult', { complete, ...options });

    const activityWorkoutResult = this.activityProcessor.workoutValueObj;
    const activitySessionId = await this.gameplayStore.saveToFirebase(activityWorkoutResult, complete, saveOptions);

    this.logActivityEvent('activitySaveResultToFirebaseCalled');

    runInAction(() => {
      this.activitySessionId = activitySessionId;
    });

    return {
      activitySessionId,
      complete,
    };
  }

  /**
   * Transitions the current gameplay state to the specified. It saves
   * the previous state.
   * @param {number} newState Next state to transition to.
   */
  transitionToState = (newState) => {
    this.prevActivityState = this.activityState;
    this.activityState = newState;
    this.logActivityEvent('activityStateTransition');
  }

  /**
   * Clear all of the timeouts if any.
   */
  clearTimers = () => {
    this.activityProcessor.clearTimers();

    if (this.startActivityCountdownTimeout) {
      clearTimeout(this.startActivityCountdownTimeout);
    }

    if (this.activitySideCountdownTimeout) {
      clearTimeout(this.activitySideCountdownTimeout);
    }
  }

  pause = () => {
    if (this.activityState !== activityStatus.PAUSED) {
      this.transitionToState(activityStatus.PAUSED);
    }
  }

  resume = () => {
    if (this.activityState === activityStatus.PAUSED) {
      this.transitionToState(activityStatus.RESUMING);
    }
  }

  restart = () => {
    this.gameplayStore.setFirstIncompleteActivityWorkoutStartTime(Date.now());
    this.transitionToState(activityStatus.RUNNING);
  }

  runActivitySideCountdown = () => {
    if (this.activityState !== activityStatus.PREPARING) {
      return;
    }

    if (this.activitySideCount > 0) {
      this.activitySideCountdownTimeout = setTimeout(() => {
        this.activitySideCount -= 1;
        this.runActivitySideCountdown();
      }, 1000);
    } else {
      clearTimeout(this.activitySideCountdownTimeout);
      this.activitySideCountdownTimeout = null;
      this.activitySideCount = ACTIVITY_SIDE_DEFAULT_COUNT;

      this.runStartActivityCountdown();
    }
  }

  /**
   * Runs countdown. Once it reaches 0, the state transitions to READY.
   */
  runStartActivityCountdown = () => {
    if (this.activityState !== activityStatus.PREPARING) {
      return;
    }

    if (this.startActivityCount > 0) {
      this.startActivityCountdownTimeout = setTimeout(() => {
        this.runStartActivityCountdown();
        this.startActivityCount -= 1;
      }, 1000);
    } else {
      // set state ready!
      clearTimeout(this.startActivityCountdownTimeout);
      this.startActivityCountdownTimeout = null;

      this.startActivityCount = 3;
      this.transitionToState(activityStatus.READY);
      this.logActivityEvent('activityPrepared');
    }
  }

  get isInitialized() {
    return this.activityProcessor !== null;
  }

  get isActivityActive() {
    return isActiveStatus(this.activityState);
  }

  /**
   * Checks if the activity is in some state related to frame processing.
   * The states are: READY, RUNNING and RESUMING.
   *
   * @returns {boolean} True when in frame processing state, otherwise false.
   */
  get isActivityInFrameProcessingState() {
    switch (this.activityState) {
      case activityStatus.READY:
      case activityStatus.RUNNING:
      case activityStatus.RESUMING:
        return true;
      default:
        return false;
    }
  }

  get isActivityFinished() {
    return this.activityState === activityStatus.FINISHED;
  }

  get isActivityPaused() {
    return this.activityState === activityStatus.PAUSED;
  }

  get isActivityRunning() {
    return this.activityState === activityStatus.RUNNING;
  }

  get isActivityIdle() {
    return this.activityState === activityStatus.IDLE;
  }

  get isActivityQuitted() {
    return this.activityState === activityStatus.QUITTED;
  }

  get isActivityAutoPauseable() {
    return this.activity.isAutoPauseable;
  }

  get isPreviousStateActive() {
    return isActiveStatus(this.prevActivityState);
  }

  get workoutValue() {
    return this.activityProcessor.workoutValue;
  }

  get remainingWorkoutValue() {
    return this.activityProcessor.remainingWorkoutValue;
  }

  get workoutValueObj() {
    return this.activityProcessor.workoutValueObj;
  }

  get isRequiredGoalAchieved() {
    return this.activityProcessor.isCompleted;
  }

  get showStartActivityCountdown() {
    return (
      this.activity.type !== ActivityTypes.REST
      && this.activityState === activityStatus.PREPARING
      && this.startActivityCountdownTimeout !== null
      && this.startActivityCount !== 0
    );
  }

  get showActivitySideInfo() {
    return (
      this.activityState === activityStatus.PREPARING
      && this.activitySideCountdownTimeout !== null
      && this.activitySideCount !== 0
    );
  }
}

decorate(ActivityExecutor, {
  activityState: observable,
  prevActivityState: observable,
  startActivityCountdownTimeout: observable,
  startActivityCount: observable,
  activitySideCountdownTimeout: observable,
  activitySideCount: observable,
  workoutValue: computed,
  remainingWorkoutValue: computed,
  workoutValueObj: computed,
  isRequiredGoalAchieved: computed,
  activitySessionId: observable,
  isActivityActive: computed,
  isActivityIdle: computed,
  isActivityRunning: computed,
  isActivityPaused: computed,
  isActivityFinished: computed,
  isPreviousStateActive: computed,
  isActivityQuitted: computed,
  isActivityAutoPauseable: computed,
  showStartActivityCountdown: computed,
  isActivityInFrameProcessingState: computed,
  showActivitySideInfo: computed,
  /*
    Since these functions modifies the state and/or they have timers,
    they must be decorated as action.
  */
  init: action,
  prepare: action,
  start: action,
  finish: action,
  reset: action,
  quit: action,
  saveResult: action,
  transitionToState: action,
});

export default ActivityExecutor;
