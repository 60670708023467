import React, {
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import InAppBrowserContext from './InAppBrowserContext';

import defaultOptions, { toStringOptions } from './browserOptions';

const InAppBrowserContextProvider = ({
  children,
}) => {
  const browserRef = useRef(undefined);

  useEffect(() => () => {
    browserRef.current = undefined;
  }, []);

  const close = useCallback(() => {
    if (browserRef.current) {
      browserRef.current.close();
    }
  }, []);

  const open = useCallback((url, options = {}, eventListeners) => {
    const {
      target = '_blank',
      ...browserOptions
    } = options;

    const updatedOptions = {
      ...defaultOptions,
      ...browserOptions,
    };
    if (window.cordova && window.cordova.InAppBrowser) {
      const newBrowserRef = window.cordova.InAppBrowser.open(url, target, toStringOptions(updatedOptions));
      browserRef.current = newBrowserRef;
      // Subscribe to standard actions
      browserRef.current.addEventListener('message', ({ data: { action } }) => {
        if (action && action === 'close') {
          close();
        }
      });
      if (eventListeners) {
        eventListeners.forEach(({ eventName, callback }) => browserRef.current.addEventListener(eventName, callback));
      }
    } else {
      window.open(url, '_blank');
    }
  }, [
    close,
  ]);

  const addMessageEventListener = useCallback((callback) => {
    if (browserRef.current) {
      browserRef.current.addEventListener('message', callback);
    }
  }, []);

  const context = useMemo(() => ({
    open,
    close,
    addMessageEventListener,
  }), [
    open,
    close,
    addMessageEventListener,
  ]);

  return (
    <InAppBrowserContext.Provider value={context}>
      {children}
    </InAppBrowserContext.Provider>
  );
};

InAppBrowserContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InAppBrowserContextProvider;
