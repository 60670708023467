import styled from 'styled-components';
import { fontFamily } from '../../../styles/text';

const Container = styled.div`
  font-family: ${fontFamily.sansSerif};
  margin: 16px 0;
  text-align: center;
  white-space: pre-wrap;
  color: ${({ theme }) => theme.colors.base.primary};
`;

export {
  Container,
};
