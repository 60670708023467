import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import * as Sentry from '@sentry/browser';

import { SubscriptionStatus } from '../../utils/stripe';
import AppContext from '../../context/AppContext';
import UserContext from '../../context/UserContext';
import MessageBannerContext, {
  withMessageBannerContextProvider,
  BannerType,
  bannerTexts,
} from '../../context/MessageBannerContext';
import SubscriptionSlideDrawerContext from '../../context/SubscriptionSlideDrawerContext';
import HealthDataSyncContext from '../../context/HealthDataSyncContext';
import UserContractContext from '../../context/UserContractContext';
import useSessionStore from '../../hooks/useSessionStore';
import useCurrentLoggedInUser from '../../hooks/useCurrentLoggedInUser';
import useHealthData from '../../hooks/useHealthData';
import useUserNavigation from '../../hooks/useUserNavigation';
import HealthDataExplainer from '../../components/HealthDataExplainer';
import LoadingPage from '../../components/LoadingPage';
import usePostPaymentForm from '../../hooks/usePostPaymentForm ';
import Home from './Home';
import texts from './texts.json';

const HomeContainer = () => {
  const {
    userConfigDoc: {
      isHealthDataExplainerShown,
      areNotificationsEnabled,
      areNotificationsPermissionGranted,
    },
    userDoc: {
      isActive: userSubscriptionIsActive,
      isDelinquent: isUserDelinquent,
      subscriptionStatus,
      firstPaymentSuccessful,
    },
  } = useContext(UserContext);

  const {
    networkStatus,
    isBackOnline,
  } = useContext(AppContext);

  const {
    showBanner,
    hideBanner,
  } = useContext(MessageBannerContext);

  const {
    arePermissionsRequested,
    requestAllPermissions,
  } = useContext(HealthDataSyncContext);

  const { openSlider: openSubscriptionSlider } = useContext(SubscriptionSlideDrawerContext);
  const {
    isNewContractAvailable,
    setIsReonboardingModalOpen,
  } = useContext(UserContractContext);

  const { isCoachOrAdmin } = useSessionStore();
  const { isCurrentLoggedInUserInPath } = useCurrentLoggedInUser();

  const { userCanUseHealthData } = useHealthData();

  const { navigateToUserPath } = useUserNavigation();

  /*
    When health data access permissions must be requested in the home page without an explainer,
    show a loading progress or any other component, to avoid rendering the Home component, so the
    app can ask all permissions at once and there's no other race condition between this container
    and any other component within the Home that might attempt to access health data.
  */
  const [shouldRequestPermissions, setShouldRequestPermissions] = useState(() => (
    userCanUseHealthData && isHealthDataExplainerShown && !arePermissionsRequested
  ));

  const isPaymentFailed = useMemo(() => (
    isUserDelinquent
    || subscriptionStatus === SubscriptionStatus.PAST_DUE.toUpperCase()
    || subscriptionStatus === SubscriptionStatus.INCOMPLETE.toUpperCase()
  ), [
    isUserDelinquent,
    subscriptionStatus,
  ]);

  const { checkAndShowPostPaymentForm } = usePostPaymentForm();

  useEffect(() => {
    checkAndShowPostPaymentForm();
  }, [checkAndShowPostPaymentForm]);

  useEffect(() => {
    if (shouldRequestPermissions) {
      const requestPermissions = async () => {
        try {
          await requestAllPermissions();
        } catch (error) {
          Sentry.captureException(error, {
            extra: {
              description: 'Error requesting health permissions in home page',
            },
          });
        } finally {
          setShouldRequestPermissions(false);
        }
      };
      requestPermissions();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /*
    This effect handles the message banner display.
      - We show the "No internet" banner first.
      - If the user is connected, check whether we need to show an information banner (first payment
        failed / subscription not active).
      - If the user has push notifications turned off, or if it's on but permissions are not granted.
      - If no information banner is needed, then we check if the "online" banner is needed.
      - Hide the banner.
  */
  useEffect(() => {
    if (!networkStatus.connected) {
      showBanner({
        message: bannerTexts.noInternet,
        type: BannerType.WARNING,
      });
    } else if (firstPaymentSuccessful
      && ((!userSubscriptionIsActive) || isPaymentFailed)) {
      /*
        Coaches cannot open the subscription slide drawer or open the contract modal when using view-as, but they
        can see the banner to quickly check if a user is active or not, or if that user is flagged as delinquent by
        Stripe. The banner is hidden when coaches or admins are using the app.
      */
      const shouldRenderBanner = !isCoachOrAdmin || (isCoachOrAdmin && !isCurrentLoggedInUserInPath);
      let bannerClickHandler;
      let bannerText;

      if (isPaymentFailed) {
        /*
          First check if the user is delinquent or if the subsciption is in the 'past_due' state,
          so the payment method is changed before renewing the subscription (if needed)
        */
        bannerClickHandler = () => navigateToUserPath('settings/subscriptions');
        bannerText = texts.paymentFailedMessage;
      } else if (!userSubscriptionIsActive) {
        bannerClickHandler = isNewContractAvailable
          ? () => setIsReonboardingModalOpen(true)
          : openSubscriptionSlider;
        bannerText = isNewContractAvailable ? texts.newTermsAvailable : texts.subscriptionMessage;
      }

      // Coaches and admins does not have access to banner actions
      if (isCoachOrAdmin) {
        bannerClickHandler = () => { };
      }

      if (shouldRenderBanner) {
        showBanner({
          message: bannerText,
          onClickHandler: bannerClickHandler,
        });
      }
    } else if (!areNotificationsEnabled || !areNotificationsPermissionGranted) {
      /**
       * Show a banner if the user has their notifications turned off from the app or permissions are not granted.
       * We will redirect the user to the notification settings page upon clicking on the banner.
       */
      showBanner({
        message: !areNotificationsEnabled
          ? bannerTexts.enablePushNotifications
          : bannerTexts.needPushNotificationPermission,
        type: BannerType.WARNING,
        onClickHandler: () => navigateToUserPath('settings/notifications'),
      });
    } else if (isBackOnline) {
      showBanner({
        message: bannerTexts.online,
        type: BannerType.SUCCESS,
      });
    } else {
      hideBanner();
    }
  }, [
    networkStatus,
    isBackOnline,
    isCoachOrAdmin,
    userSubscriptionIsActive,
    isPaymentFailed,
    isCurrentLoggedInUserInPath,
    firstPaymentSuccessful,
    isNewContractAvailable,
    showBanner,
    hideBanner,
    openSubscriptionSlider,
    navigateToUserPath,
    areNotificationsEnabled,
    areNotificationsPermissionGranted,
    setIsReonboardingModalOpen,
  ]);

  if (shouldRequestPermissions) {
    return <LoadingPage />;
  }

  return (userCanUseHealthData && !isHealthDataExplainerShown)
    ? <HealthDataExplainer />
    : <Home />;
};

export default compose(
  withMessageBannerContextProvider,
  observer,
)(HomeContainer);
