import { isNative } from './platform';

/**
 * Module wrapper for the screen orientation plugin:
 *  https://cordova.apache.org/docs/en/latest/reference/cordova-plugin-screen-orientation/
 */

const OrientationTypes = {
  LANDSCAPE: 'landscape',
  PORTRAIT: 'portrait',
  PORTRAIT_PRIMARY: 'portrait-primary',
  PORTRAIT_SECONDARY: 'portrait-secondary',
  LANDSCAPE_PRIMARY: 'landscape-primary',
  LANDSCAPE_SECONDARY: 'landscape-secondary',
};

const lock = (newOrientation) => {
  // TODO: Verify the locking mechanism, since it stopped working in iOS 16.4
  if (isNative && window?.screen?.orientation?.lock) {
    return window.screen.orientation.lock(newOrientation);
  }
  return Promise.resolve();
};

const unlock = () => {
  if (isNative && window?.screen?.orientation?.unlock) {
    window.screen.orientation.unlock();
  }
};

const getOrientationType = () => {
  if (window.screen.orientation) {
    return window.screen.orientation.type;
  }
  // Return landscape as default
  return OrientationTypes.LANDSCAPE;
};

const isPortrait = () => {
  switch (getOrientationType()) {
    case OrientationTypes.PORTRAIT:
    case OrientationTypes.PORTRAIT_PRIMARY:
    case OrientationTypes.PORTRAIT_SECONDARY:
      return true;
    default:
      return false;
  }
};

const isLandscape = () => {
  switch (getOrientationType()) {
    case OrientationTypes.LANDSCAPE:
    case OrientationTypes.LANDSCAPE_PRIMARY:
    case OrientationTypes.LANDSCAPE_SECONDARY:
      return true;
    default:
      return false;
  }
};

/*
  window.screen.orientation is not available in Safari and Safari mobile,
  where the orientation will be landscape by default
*/
const addOrientationChangeEventListener = (callback) => {
  if (window.screen.orientation) {
    window.screen.orientation.addEventListener('change', callback);
  }
};

const removeOrientationChangeEventListener = (callback) => {
  if (window.screen.orientation) {
    window.screen.orientation.removeEventListener(callback);
  }
};

export {
  lock,
  unlock,
  isPortrait,
  isLandscape,
  OrientationTypes,
  getOrientationType,
  addOrientationChangeEventListener,
  removeOrientationChangeEventListener,
};
