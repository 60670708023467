import styled from 'styled-components';
import {
  safeAreaFromTop,
  safeAreaFromLeft,
  safeAreaFromRight,
  safeAreaFromBottom,
} from '../../styles/mixins';

const PageContent = styled.div`
  height: 100%;
  padding-top: ${safeAreaFromTop()};
  padding-left: ${safeAreaFromLeft()};
  padding-right: ${safeAreaFromRight()};
  padding-bottom: ${safeAreaFromBottom()};
`;

export default PageContent;
