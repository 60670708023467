import styled from 'styled-components';
import { IonPage } from '@ionic/react';

const StyledIonPage = styled(IonPage)`
  ion-content {
    --overflow: hidden;
    --background: transparent;
  }
`;

export {
  StyledIonPage,
};
