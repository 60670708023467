import React from 'react';
import { ChannelSearch } from 'stream-chat-react';
import CustomResultItem from './CustomResultItem/CustomResultItem';
import SearchBox from './SearchBox';
import {
  ChannelSearchWrapper,
  SearchStateContainer,
} from './styles';
import texts from './texts.json';

const MessagingChannelSearch = (props) => (
  <ChannelSearchWrapper>
    <ChannelSearch
      SearchInput={SearchBox}
      SearchResultItem={CustomResultItem}
      SearchEmpty={() => <SearchStateContainer>{texts.searchEmpty}</SearchStateContainer>}
      SearchLoading={() => <SearchStateContainer>{texts.searchLoading}</SearchStateContainer>}
      {...props}
    />
  </ChannelSearchWrapper>
);

export default MessagingChannelSearch;
