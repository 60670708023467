import styled from 'styled-components';

import { headlineFontStyles, sansSerif } from '../../../../styles/text';
import { ReactComponent as AngleArrow } from '../../../../assets/angle-arrow.svg';

import CalendarDay from './components/CalendarDay';

const cardPadding = 24;

const CardContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.system.alpha};
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  padding: ${cardPadding}px 0 ${cardPadding}px 0;
`;

const StyledTitle = styled.div`
  ${headlineFontStyles}
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  font-feature-settings: 'liga' off;
  margin: 8px ${cardPadding}px;
`;

const StyledFooter = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.system.gamma1};
  margin: 0 ${cardPadding}px;
`;

const StyledActionText = styled.span`
  color: ${({ theme }) => theme.colors.base.secondaryVariant};
  ${sansSerif.headlineFontStyles}
  font-size: 16px;
  line-height: 19px;
  flex: 1;
`;

const MealsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 0 16px;
`;

const IconContainer = styled.div`
  height: 24px;
  width: 24px;
  margin: 0 10px 0 0;
`;

const NavigationIconContainer = styled.div`
  width: 24px;
  height: 24px;
  justify-self: flex-end;
`;

const Action = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  margin-top: 8px;
  border-radius: 10px;
`;

const StyledCalendarDay = styled(CalendarDay)`
  margin: 0 ${cardPadding}px;
`;

const NavigateIcon = styled(AngleArrow)`
  transform: rotate(-90deg);

  > path {
    fill: ${({ theme }) => theme.colors.base.secondaryVariant};
  }
`;

export {
  CardContainer,
  StyledTitle,
  StyledFooter,
  StyledActionText,
  MealsContainer,
  IconContainer,
  Action,
  StyledCalendarDay,
  NavigationIconContainer,
  NavigateIcon,
};
