import styled from 'styled-components';

import AppleActivityRings from '../../../../../components/AppleActivityRings';
import { WorkoutDate } from '../styles';

const Container = styled.div`
  display: flex;
  padding: 0 24px 0 52px;
  align-items: center;
  width: 100%;
`;

const InfoSection = styled.div`
  flex-grow: 1;
`;

const StyledWorkoutDate = styled(WorkoutDate)`
  padding: 0 0 8px 0;
`;

const StyledAppleActivityRings = styled(AppleActivityRings)`
  width: 46px;
  height: 46px;
  min-width: 46px;
  min-height: 46px;
  margin-left: 4px;
`;

export {
  Container,
  InfoSection,
  StyledWorkoutDate,
  StyledAppleActivityRings,
};
