import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { debounce } from 'throttle-debounce';

import UserContext from '../../../../context/UserContext';
import FPSStats from '../../../../utils/stats/FPSStats';
import AppContext from '../../../../context/AppContext';
import CameraContext from '../../../../context/CameraContext';
import useSound from '../../../../hooks/useSound';
import ActivityExecutor, { getValidatorByActivityType } from '../../../../services/activityExecutor';
import { workoutStatus } from '../../../../services/workoutExecutor';
import GameplayActionsExecutor, { ActionTypes } from '../../../../services/gameplayActions';
import beepSound from '../../../../assets/audio/beep.mp3';
import GameplayContext from '../GameplayContext';

import ActivityContext from './ActivityContext';
import { getConfigByActivityType } from './config';

const ADVANCE_NEXT_ACTIVITY_ACTION_DEBOUNCE_TIME = 150;

const ActivityContextProvider = ({
  children,
}) => {
  const {
    workoutExecutor,
    gameplayStore,
    onGameplayActivityStarted,
    onGameplayActivityFinished,
    onGameplayActivityQuitted,
    onWorkoutPaused,
    onWorkoutResumed,
    cameraViewportRef,
    actionElementRef,
  } = useContext(GameplayContext);

  const { camera: { isVideoStreamEnabled } } = useContext(CameraContext);

  const {
    userConfigDoc: {
      areAudioCuesEnabled,
    },
  } = useContext(UserContext);

  const { currentExecutableActivity, isWorkoutPaused } = workoutExecutor;

  const [activityConfig, setActivityConfig] = useState(() => getConfigByActivityType(currentExecutableActivity.type));
  const [activityExecutor, setActivityExecutor] = useState(() => (
    new ActivityExecutor(gameplayStore, currentExecutableActivity)
  ));

  const [fpsStats, setFPSStats] = useState(() => new FPSStats());
  const [displayActivityDetails, setDisplayActivityDetails] = useState(false);
  const { isActive: isAppActive, isPortraitMode } = useContext(AppContext);

  const gameplayActionsExecutor = useRef(null);

  /*
    Since useRef doesn't support lazy initialization, to avoid creating many instances
    on each render, we follow the recommended approach:
      https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
  */
  if (!gameplayActionsExecutor.current) {
    gameplayActionsExecutor.current = new GameplayActionsExecutor();
  }

  const [advanceToNextActivitySound] = useSound(beepSound);

  useEffect(() => {
    if (activityExecutor && activityExecutor.initialized) {
      activityExecutor.reset();
    }
    // Create new Activity Executor
    const newActivitExecutor = new ActivityExecutor(gameplayStore, currentExecutableActivity);
    setActivityExecutor(newActivitExecutor);

    // Set activity config
    const newActivityConfig = getConfigByActivityType(currentExecutableActivity.type);
    setActivityConfig(newActivityConfig);

    // Set new FPSStats instance for the new activity
    const newFPSStats = new FPSStats();
    setFPSStats(newFPSStats);
    /**
     * This hook is meant to be executed only on currentExecutableActivity changed and its purpose it to:
     * - Tear down any existing activityExecutor (reset them)
     * - Set up all the new entities for activity execution (including the executor itself)
     * This is the reason why only currentExecutableActivity is considered in the deps array. Because otherwise
     * this logic actually re-calculated a new activity executor and if we consider activity executor
     * in the array deps this will end up in an unecessary re-trigger of this logic.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentExecutableActivity,
  ]);

  useEffect(() => {
    if ((workoutExecutor.isWorkoutPrepared || workoutExecutor.isWorkoutRunning)
      && activityExecutor.isActivityIdle) {
      activityExecutor.prepare();
    }
  }, [
    workoutExecutor.isWorkoutPrepared,
    workoutExecutor.isWorkoutRunning,
    activityExecutor,
  ]);

  const onActivityStart = useCallback(async () => {
    await onGameplayActivityStarted();
  }, [
    onGameplayActivityStarted,
  ]);

  useEffect(() => {
    if (workoutExecutor.state === workoutStatus.PREPARED) {
      onActivityStart();
    }
  }, [
    activityExecutor,
    onActivityStart,
    workoutExecutor.state,
  ]);

  const shouldAddToWorkoutHistory = useMemo(() => (
    getValidatorByActivityType(currentExecutableActivity.type)
  ), [
    currentExecutableActivity.type,
  ]);

  // Gameplay actions processing area calculation

  const processingAreaTime = useRef({
    startingTime: 0,
    currentTime: 0,
  });

  useEffect(() => {
    const totalExecutionTime = 3500;
    const timeInterval = 250;

    let timerId;

    processingAreaTime.current.startingTime = Date.now();

    const updateProcessingArea = () => {
      processingAreaTime.current.currentTime = Date.now() - processingAreaTime.current.startingTime;

      const requiredTimeReached = processingAreaTime.current.currentTime >= totalExecutionTime;

      if (cameraViewportRef.current && actionElementRef.current) {
        const cameraViewportClientRect = cameraViewportRef.current.getBoundingClientRect();
        const vnbActionClientRect = actionElementRef.current.getBoundingClientRect();

        gameplayActionsExecutor.current.updateProcessingArea({
          cameraViewportClientRect,
          vnbActionClientRect,
        });
      }

      if (requiredTimeReached) {
        clearTimeout(timerId);
      } else {
        timerId = setTimeout(() => updateProcessingArea(), timeInterval);
      }
    };

    updateProcessingArea();

    return () => {
      clearTimeout(timerId);
    };
  }, [
    isPortraitMode,
    cameraViewportRef,
    actionElementRef,
    gameplayActionsExecutor,
  ]);

  /**
   * Processes gameplay actions.
   */
  const processGameplayAction = useCallback((detectionResult) => {
    if (detectionResult) {
      const { predictionModels } = detectionResult;

      return gameplayActionsExecutor.current.processAction(predictionModels, {
        cameraViewportElement: cameraViewportRef.current,
        actionElement: actionElementRef.current,
      });
    }
    return null;
  }, [
    cameraViewportRef,
    actionElementRef,
  ]);

  /**
   * Processes the gameplay loop by processing current activity executor step only.
   */
  const loopProcessed = useCallback(() => {
    const {
      isInitialized,
      isActivityInFrameProcessingState,
    } = activityExecutor;

    if (!isActivityInFrameProcessingState || !isInitialized) {
      return false;
    }

    // Process Data for Gameplay
    activityExecutor.processStepResult();
    return true;
  }, [
    activityExecutor,
  ]);

  /**
   * Processes a frame from the camera along with skeletal data if any.
   *
   * @param {object=} result
   * @param {string=} result.image
   * @param {object=} result.detectionResult
   */
  const frameProcessed = useCallback((result = {}) => {
    const shouldContinue = loopProcessed();

    if (!shouldContinue) {
      return null;
    }

    const {
      isActivityRunning,
      isActivityPaused,
      activityState,
    } = activityExecutor;
    const {
      image,
      detectionResult,
    } = result;

    // Process Actions data for Gameplay only when activity is running or paused
    const gameplayActionProcessResult = (detectionResult && (isActivityRunning || isActivityPaused))
      ? processGameplayAction(detectionResult)
      : {};

    const {
      predictionModelResult,
      ...gameplayActionProcessResultObj
    } = gameplayActionProcessResult;

    const fps = fpsStats.calculateFPS();

    // Send analytics event
    let eventData = {
      fps,
    };

    if (predictionModelResult) {
      eventData = {
        ...eventData,
        ...predictionModelResult.debugData,
        partsDefinition: predictionModelResult.keypoints,
      };
    }

    activityExecutor.logActivityEvent('frameProcessed', eventData, 0.2);

    if (shouldAddToWorkoutHistory(activityState) && gameplayStore.isStoreWorkoutFramesEnabled) {
      const historyResult = {
        time: Date.now(),
        ...activityExecutor.workoutValueObj,
        predictionModelResult,
        fps,
        gameplayActionProcessResult: gameplayActionProcessResultObj,
      };

      if (!isVideoStreamEnabled && image) {
        // When the new video stream feature is disabled, upload the image to firebase storage
        historyResult.image = image;
      }

      gameplayStore.addToWorkoutHistory(historyResult);
    }

    return predictionModelResult;
  }, [
    activityExecutor,
    gameplayStore,
    shouldAddToWorkoutHistory,
    fpsStats,
    isVideoStreamEnabled,
    processGameplayAction,
    loopProcessed,
  ]);

  /**
   * Finishes current executing activity explicitly.
   *
   * Note: this is actually the way to advance to a next activity as this
   * ensures the current activiy ends as expected and once the FINISHED state
   * is detected by this same ActivityContextProvider it will report that back to
   * GameplayContextProvider making the gameplay itself move forward.
   */
  const onActivityFinish = useCallback(async () => {
    await onWorkoutResumed();
    await activityExecutor.finish();
  }, [
    onWorkoutResumed,
    activityExecutor,
  ]);

  /**
   * Pauses current activity and the workout itself.
   */
  const onActivityPaused = useCallback(() => {
    const { pause: pauseActivity, isActivityPaused } = activityExecutor;
    if (!isActivityPaused) {
      pauseActivity();
    }
    onWorkoutPaused();
  }, [
    activityExecutor,
    onWorkoutPaused,
  ]);

  /**
   * Resumes current activity and the workout itself.
   */
  const onActivityResumed = useCallback(() => {
    const { resume: resumeActivity, isActivityRunning } = activityExecutor;
    if (!isActivityRunning) {
      resumeActivity();
    }
    onWorkoutResumed();
  }, [
    activityExecutor,
    onWorkoutResumed,
  ]);

  /**
   * Toggle activity execution state between play/pause.
   */
  const onPlayPauseActivity = useCallback(() => {
    if (activityExecutor.isActivityIdle) {
      // Resume the workout explicitly.
      onWorkoutResumed();
    } else if (activityExecutor.isActivityPaused) {
      onActivityResumed();
    } else {
      onActivityPaused();
    }
  }, [
    activityExecutor,
    onActivityPaused,
    onActivityResumed,
    onWorkoutResumed,
  ]);

  /**
   * Finishes current executing activity explicitly and plays a sound that
   * helps users to know that the action is in place.
   */
  const onAdvanceToNextActivity = useMemo(() => debounce(ADVANCE_NEXT_ACTIVITY_ACTION_DEBOUNCE_TIME, async () => {
    if (areAudioCuesEnabled) {
      advanceToNextActivitySound();
    }
    await onActivityFinish();
  }), [
    areAudioCuesEnabled,
    advanceToNextActivitySound,
    onActivityFinish,
  ]);

  useEffect(() => {
    if (activityExecutor.isActivityFinished) {
      const fpsStatsResult = fpsStats.stats();

      activityExecutor.logActivityEvent('activityFPSStats', fpsStatsResult);

      onGameplayActivityFinished();
    }
  }, [
    activityExecutor,
    activityExecutor.isActivityFinished,
    onGameplayActivityFinished,
    fpsStats,
  ]);

  /**
   * Auto-pause if the workout is paused too.
   * This won't apply for those activities that are considerd not
   * to be auto-pauseable.
   */
  useEffect(() => {
    if (activityExecutor.isActivityRunning
      && activityExecutor.isActivityAutoPauseable
      && workoutExecutor.isWorkoutPaused) {
      onActivityPaused();
    }
  }, [
    onActivityPaused,
    activityExecutor.isActivityRunning,
    activityExecutor.isActivityAutoPauseable,
    workoutExecutor.isWorkoutPaused,
  ]);

  /**
   * Enable auto-pause whenever we detect that the app is no longer active (it's in the background).
   * When the app is active again, we need to check if the current activity is not autopausable (for example, REST
   * activities) and resume the workout in that case.
   */
  useEffect(() => {
    if (!isAppActive && activityExecutor.isActivityRunning) {
      activityExecutor.logActivityEvent('autoPausedTriggered');
      onWorkoutPaused();
    } else if (
      isAppActive
      && !activityExecutor.isActivityAutoPauseable
      && activityExecutor.isActivityRunning
      && isWorkoutPaused
    ) {
      onWorkoutResumed();
    }
  }, [
    activityExecutor,
    activityExecutor.isActivityRunning,
    onWorkoutPaused,
    onWorkoutResumed,
    isAppActive,
    isWorkoutPaused,
  ]);

  useEffect(() => {
    if (activityExecutor.isActivityQuitted) {
      onGameplayActivityQuitted();
    }
  }, [
    activityExecutor.isActivityQuitted,
    onGameplayActivityQuitted,
  ]);

  useEffect(() => (
    () => {
      // Make sure to reset the state of gameplayActionsExecutor on component will unmount
      gameplayActionsExecutor.current.reset();
    }
  ), []);

  // Gameplay actions
  useEffect(() => {
    const toNextIfRequired = async () => {
      if ((activityExecutor.isActivityRunning || activityExecutor.isActivityPaused)
        && gameplayActionsExecutor.current.actionProcessed === ActionTypes.HAND_ADVANCE_NEXT) {
        // The action has been detected and processed, finish current activity
        await onAdvanceToNextActivity();
        gameplayActionsExecutor.current.actionDone();

        activityExecutor.logActivityEvent('advanceToNextActivityVirtualActionDone');
      } else if (!activityExecutor.isActivityRunning && !activityExecutor.isActivityPaused) {
        // Clean up the current GameplayActionsExecutor state
        gameplayActionsExecutor.current.restart();
      }
    };
    toNextIfRequired();
  }, [
    activityExecutor,
    gameplayActionsExecutor.current.actionProcessed,
    activityExecutor.isActivityRunning,
    activityExecutor.isActivityPaused,
    onAdvanceToNextActivity,
  ]);

  const showGameplay = activityExecutor.isActivityActive
    && activityExecutor.isPreviousStateActive;

  const isActivityWorkoutPaused = activityExecutor.isActivityPaused || workoutExecutor.isWorkoutPaused;

  // Build the context object once.
  const contextValue = useMemo(() => ({
    // Activity execution
    activityExecutor,

    // Activity and Workout combined state,
    isActivityWorkoutPaused,

    // Activity details view (show video and notes)
    displayActivityDetails,
    setDisplayActivityDetails,

    // Specific config for the current activity.
    activityType: currentExecutableActivity.type,

    // General config and state.
    showGameplay,

    // Available methods.
    frameProcessed,
    loopProcessed,

    // Activitty config
    activityConfig,

    // Activity Actions
    onActivityFinish,
    onActivityPaused,
    onActivityResumed,
    onPlayPauseActivity,
    onAdvanceToNextActivity,

    // Gameplay actions and gestures
    processGameplayAction,
    gameplayActionsExecutor,
  }), [
    activityExecutor,
    displayActivityDetails,
    setDisplayActivityDetails,
    currentExecutableActivity.type,
    showGameplay,
    frameProcessed,
    loopProcessed,
    activityConfig,
    onActivityFinish,
    onActivityPaused,
    onActivityResumed,
    onPlayPauseActivity,
    onAdvanceToNextActivity,
    processGameplayAction,
    isActivityWorkoutPaused,
  ]);

  return (
    <ActivityContext.Provider value={contextValue}>
      {children}
    </ActivityContext.Provider>
  );
};

ActivityContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(ActivityContextProvider);
