import styled from 'styled-components';
import { getSafeAreaFromHeader } from '../../../styles/mixins';

const StyledHeader = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${({ theme }) => theme.colors.base.primary};
  width: 100%;
  height: ${getSafeAreaFromHeader()};
  padding-left: 25px;
  padding-right: 25px;
  padding-top: env(safe-area-inset-top);

  top: 0;
  position: fixed;
  border-bottom: 1px solid ${({ theme }) => theme.colors.system.gamma1};
  z-index: 1;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  > div:first-child {
    width: 50%;
  }
  > div:last-child {
    width: 50%;
  }
`;

const HeaderContent = styled(Container)`
  width: fit-content;
  height: 30px;
`;

const MenuWrapper = styled.div`
  height: 100%;
`;

export {
  StyledHeader,
  Container,
  MenuWrapper,
  HeaderContent,
};
