import moment from 'moment';

import { firestorePaths } from '../utils/firebasePaths';
import BaseDocument from './BaseDocument';

class PaymentMethod extends BaseDocument {
  constructor(paymentMethodId, opts) {
    super(`${firestorePaths.STRIPE_PAYMENT_METHODS_COLLECTION}/${paymentMethodId}`, opts);
  }

  get paymentMethodDefinition() {
    return this.data.paymentMethod || {};
  }

  get cardInformation() {
    return this.paymentMethodDefinition.card || {};
  }

  get lastFourDigits() {
    return this.cardInformation.last4 || '';
  }

  get cardBrand() {
    return this.cardInformation.brand || '';
  }

  get expMonth() {
    return this.cardInformation.exp_month;
  }

  get expYear() {
    return this.cardInformation.exp_year;
  }

  get billingDetails() {
    return this.paymentMethodDefinition.billing_details || {};
  }

  get email() {
    return this.billingDetails.email || null;
  }

  get name() {
    return this.billingDetails.name || null;
  }

  get phone() {
    return this.billingDetails.phone || null;
  }

  isExpired() {
    const today = moment().utc();
    const expDate = moment().utc()
      .set('year', this.expYear)
      .set('month', this.expMonth)
      .startOf('month');

    const monthsDifference = today.diff(expDate, 'months');
    return monthsDifference > 1;
  }
}

export default PaymentMethod;
