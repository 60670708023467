import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.shades.secondary20};
  margin-bottom: 16px;
`;

export {
  Container,
};
