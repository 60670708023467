import React from 'react';
import PropTypes from 'prop-types';

import AssignmentDayType from '../../viewModel/assignmentType';
import StreakCard from '../../components/StreakCard';
import DayGroup from '../../components/DayGroup';
import WorkoutItem from '../../components/WorkoutItem';

const CalendarDayCard = ({
  workoutDay,
}) => {
  const {
    date,
    items,
    completedItems,
    assignmentDayType,
    progressStatus,
    trackedActivitySummary,
    streak,
  } = workoutDay;

  const renderViewItems = () => {
    const workoutItems = [
      ...completedItems,
      ...items,
    ];
    return workoutItems.map((item) => {
      const {
        props: {
          id,
          ...cardProps
        },
        component: CardComponent,
      } = item;

      return (
        <WorkoutItem
          key={id}
          $enableExtraSpace
        >
          <CardComponent
            {...cardProps}
          />
        </WorkoutItem>
      );
    });
  };

  return (
    <DayGroup
      date={date}
      assignmentDayType={assignmentDayType}
      progressStatus={progressStatus}
      trackedActivitySummary={trackedActivitySummary}
      streak={streak}
    >
      {!!streak && streak.isFinalStreakDay && (
        <StreakCard count={streak.totalCount} />
      )}
      {renderViewItems()}
    </DayGroup>
  );
};

CalendarDayCard.propTypes = {
  workoutDay: PropTypes.shape({
    date: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
    completedItems: PropTypes.array.isRequired,
    assignmentDayType: PropTypes.oneOf(Object.values(AssignmentDayType)).isRequired,
    progressStatus: PropTypes.string.isRequired,
    trackedActivitySummary: PropTypes.object,
    streak: PropTypes.object,
  }).isRequired,
};

export default CalendarDayCard;
