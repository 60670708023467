import styled from 'styled-components';
import { IonSpinner, IonProgressBar } from '@ionic/react';

import { sansSerif } from '../../../../../styles/text';
import CollapsiblePanel, {
  Header,
  Title,
  SubTitle,
  Collapsible,
} from '../../../../../components/CollapsiblePanel';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const WorkoutDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled.span`
  ${sansSerif.headlineFontStyles}
  font-size: 12px;
  line-height: 22px;
  margin-top: 2px;
`;

const CountLabel = styled.span`
  ${sansSerif.headlineFontStyles}
  font-size: 48px;
  line-height: 58px;
  margin-top: 2px;
`;

const Description = styled.span`
  ${sansSerif.headlineFontStyles}
  font-size: 10px;
  line-height: 12px;
  margin-top: 2px;
  color: ${({ theme }) => theme.colors.system.gamma2};
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 46%;
  padding: 6px 12px;
  background-color: ${({ theme }) => theme.colors.system.background};
`;

const LoaderWrapper = styled.div`
  height: 45px;
  max-height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Loading = styled(IonSpinner)`
  width: 100%;
`;

const StyledWorkoutProgress = styled(IonProgressBar)`
  width: 100%;
  height: 8px;
  border-radius: 25px;
  margin: 5px 0;
  --background: ${({ theme }) => theme.colors.system.gamma1};
  --progress-background: ${({ theme }) => theme.colors.base.secondaryVariant};
`;

const StyledCollapsiblePanel = styled(CollapsiblePanel)`
  width: 100%;
  margin-top: 10px;
  ${Header} {
    background-color: ${({ theme }) => theme.colors.system.background};
    padding: 0 12px;
    align-items: left;
  }

  ${Title}, ${SubTitle} {
    ${sansSerif.headlineFontStyles}
    font-size: 12px;
    line-height: 26px;
  }

  ${Collapsible} {
    overflow: auto;
  }
`;

export {
  Container,
  ItemContainer,
  StyledTitle,
  CountLabel,
  Description,
  LoaderWrapper,
  Loading,
  StyledWorkoutProgress,
  DataContainer,
  StyledCollapsiblePanel,
  WorkoutDetails,
};
