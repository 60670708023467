import styled from 'styled-components';

import { body2 } from '../../styles/text';

const ChipsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${body2}
`;

const Label = styled.span`
  margin-right: 8px;
`;

const Item = styled.div`
  color: ${({ theme }) => theme.colors.base.primary};
  padding: 4px;
  margin: 4px 8px 4px 0;
  border-radius: 6px;
  width: fit-content;
  background: ${({ theme }) => theme.colors.shades.secondary20};
`;

export {
  ChipsContainer,
  Label,
  Item,
};
