import { Collection } from 'firestorter';

import CollectionName from '../utils/collections';
import BaseDocument from './BaseDocument';

class CheckIn extends BaseDocument {
  get created() {
    return this.data.created.toDate();
  }

  get submitted() {
    return this.data.submitted?.toDate();
  }

  get lastUpdated() {
    return this.data.lastUpdated?.toDate();
  }

  get weight() {
    return this.data.weight;
  }

  get thigh() {
    return this.data.thigh;
  }

  get stomach() {
    return this.data.stomach;
  }

  get chest() {
    return this.data.chest;
  }

  get upperArm() {
    return this.data.upperArm;
  }

  get waist() {
    return this.data.waist;
  }

  get hip() {
    return this.data.hip;
  }

  get glutes() {
    return this.data.glutes;
  }

  get unitType() {
    return this.data.unitType;
  }

  get lengthUnitType() {
    return this.data.lengthUnitType;
  }

  get weightUnitType() {
    return this.data.weightUnitType;
  }

  get comment() {
    return this.data.comment;
  }

  get attachments() {
    return this.data.attachments || [];
  }

  get isSubmitted() {
    return !!this.data.isSubmitted;
  }

  get status() {
    return this.data.status || '';
  }

  get win() {
    return this.data.win || '';
  }

  get year() {
    return this.data.year;
  }

  get week() {
    return this.data.week;
  }

  get progress() {
    return this.data.progress || 0;
  }

  get questions() {
    return this.data.questions || {};
  }

  get weekText() {
    return this.data.weekText;
  }

  get isEditing() {
    return !!this.data.isEditing;
  }

  static addDoc = async (data) => {
    const checkInCollection = new Collection(CollectionName.CHECK_IN);
    const checkInDoc = await checkInCollection.add(data);
    const checkIn = new CheckIn(checkInDoc.ref);
    return checkIn;
  };

  static getCheckInByYearAndWeek = async (userId, year, week) => {
    const checkInCollection = new Collection(() => CollectionName.CHECK_IN, {
      createDocument: (src, opts) => new CheckIn(src, opts),
      query: (ref) => ref
        .where('user', '==', userId)
        .where('year', '==', year)
        .where('week', '==', week),
    });
    await checkInCollection.fetch();
    return checkInCollection;
  }
}

export default CheckIn;
