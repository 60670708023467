import React from 'react';
import PropTypes from 'prop-types';

import Button from './Button';
import { Spinner } from './styles';

const LoadingButton = ({ children, isLoading, ...otherProps }) => (
  <Button disabled={isLoading} {...otherProps}>
    {children}
    {isLoading && <Spinner name="crescent" />}
  </Button>
);

LoadingButton.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default LoadingButton;
