import React, {
  forwardRef,
  useContext,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';

import ContentSwitcher from '../ContentSwitcher';
import WorkoutInfoSwitcherContext from './context';

const WorkoutInfoSwitcherAPI = forwardRef(({
  sectionsConfig,
}, ref) => {
  const {
    setCurrentActivity,
  } = useContext(WorkoutInfoSwitcherContext);

  const ContentSwitcherRef = useRef();

  useEffect(() => {
    if (ref && ContentSwitcherRef) {
      // eslint-disable-next-line
      ref.current = {
        setCurrentActivity,
        ContentSwitcher: ContentSwitcherRef.current,
      };
    }
  }, [
    ref,
    setCurrentActivity,
  ]);

  return (
    <ContentSwitcher
      sectionsConfig={sectionsConfig}
      ref={ContentSwitcherRef}
    />
  );
});

WorkoutInfoSwitcherAPI.propTypes = {
  sectionsConfig: PropTypes.array.isRequired,
};

export default WorkoutInfoSwitcherAPI;
