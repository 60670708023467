import React, {
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { debounce } from 'throttle-debounce';
import { IonAlert } from '@ionic/react';
import format from 'string-template';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { v4 as uuidv4 } from 'uuid';

import {
  nowInDateOnlyFormat,
  DateFormat,
} from '../../../../utils/date';
import useCurrentLoggedInUser from '../../../../hooks/useCurrentLoggedInUser';
import LogRow from './LogRow';
import RowHeader from './RowHeader';
import {
  Row,
  StyledDateRow,
  AddSetButton,
} from './styles';
import text from './text.json';
import transitionsConfig from './transitionsConfig';

const DateRow = ({
  date,
  weight,
  reps,
  sets,
  comment,
  onRowUpdate,
  isDisabled,
  measurementSystem,
}) => {
  const [weightValue, setWeightValue] = useState(weight);
  const [repsValue, setRepsValue] = useState(reps);
  const [setsInfo, setSetsInfo] = useState(sets.map((item) => ({ ...item, id: uuidv4() })));

  // These variables control which row to delete, and whether or not we show the delete alert
  const [rowIndexToDelete, setRowIndexToDelete] = useState(0);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const { isCurrentLoggedInUserInPath } = useCurrentLoggedInUser();

  const onValueUpdate = useMemo(() => debounce(500, (
    weightVal,
    repsVal,
    setsVal,
    commentVal,
  ) => {
    const setsValue = setsVal.map((item) => {
      const setsData = {
        reps: item.reps,
        weight: item.weight,
        measurementSystem: item.measurementSystem || measurementSystem,
      };

      return setsData;
    });
    onRowUpdate(date, weightVal, repsVal, setsValue, commentVal);
  }), [
    onRowUpdate,
    date,
    measurementSystem,
  ]);

  const onLogUpdate = ({ weight: w, reps: r, setIndex }) => {
    const newSets = setsInfo.slice();

    // Check if we have sets information.
    if (setIndex !== -1) {
      // Add the values provided to the set specified.
      newSets[setIndex].weight = w;
      newSets[setIndex].reps = r;
      newSets[setIndex].measurementSystem = measurementSystem;
      setSetsInfo(newSets);
    } else {
      // Add the values to the date directly.
      setWeightValue(w);
      setRepsValue(r);
    }

    onValueUpdate(w, r, newSets, comment);
  };

  const onNewSetAdded = () => {
    let newSets = setsInfo.slice();

    // If we have sets information, we duplicate the last value.
    if (newSets.length) {
      newSets = [
        ...newSets,
        { ...newSets[newSets.length - 1], id: uuidv4() },
      ];
    } else {
      // We create a new array with the same value we had for the day.
      newSets = [{
        weight: weightValue,
        reps: repsValue,
        id: uuidv4(),
        measurementSystem,
      }];
    }

    setSetsInfo(newSets);
    onValueUpdate(weightValue, repsValue, newSets, comment);
  };

  const deleteRow = () => {
    let newSets = setsInfo.slice();
    let w;
    let r;
    let m;

    // If we still have extra rows in the sets section, we need to just remove the one specified.
    if (newSets.length > 1) {
      newSets.splice(rowIndexToDelete, 1);
    } else {
      // We extract these values from the set, and we store them for the day, since we don't have any other sets data.
      [{
        weight: w,
        reps: r,
        measurementSystem: m,
      }] = newSets;
      setWeightValue(w);
      setRepsValue(r);

      // We need to restore the sets info since this is going to be stored in the firebase doc.
      newSets = [];
    }

    setSetsInfo(newSets);
    setShowDeleteAlert(false);
    onValueUpdate(w, r, newSets, comment, m);
  };

  const onRowDelete = (rowIndex) => {
    setRowIndexToDelete(rowIndex);
    setShowDeleteAlert(true);
  };

  const onDeleteAlertDismissed = () => {
    setShowDeleteAlert(false);
  };

  const onCommentUpdatedHandler = (commentValue) => {
    onValueUpdate(weightValue, repsValue, setsInfo.slice(), commentValue);
  };

  const todayDate = nowInDateOnlyFormat();
  const dateLabel = date === todayDate ? text.today : moment(date).format(DateFormat.SHORT_DATE_FORMAT);

  const renderRows = () => {
    const isCommentEnabled = !!weightValue || !!repsValue || !!setsInfo.length;

    if (setsInfo.length) {
      const rows = setsInfo.map(({
        weight: w,
        reps: r,
        id,
      }, index) => (
        <CSSTransition
          timeout={transitionsConfig.delay}
          classNames={transitionsConfig.classNames.logRows}
          key={`${id}-${measurementSystem}`}
        >
          <LogRow
            label={`Set ${index + 1}`}
            weight={w}
            reps={r}
            onLogUpdate={onLogUpdate}
            setIndex={index}
            isDisabled={isDisabled}
            onRowDelete={onRowDelete}
            measurementSystem={measurementSystem}
          />
        </CSSTransition>
      ));

      return (
        <>
          <Row>
            <RowHeader
              label={dateLabel}
              isHighlighted={date === todayDate}
              enableComment={isCommentEnabled}
              comment={comment}
              onCommentUpdated={onCommentUpdatedHandler}
            />
          </Row>
          <TransitionGroup component={null}>
            {rows}
          </TransitionGroup>
        </>
      );
    }

    return (
      <LogRow
        label={dateLabel}
        weight={weightValue}
        reps={repsValue}
        onLogUpdate={onLogUpdate}
        isDisabled={isDisabled}
        isHighlighted={date === todayDate}
        onCommentUpdated={onCommentUpdatedHandler}
        enableComment={isCommentEnabled}
        comment={comment}
        measurementSystem={measurementSystem}
      />
    );
  };

  return (
    <>
      <IonAlert
        isOpen={showDeleteAlert}
        header={format(text.deleteModal.message, { setNumber: rowIndexToDelete + 1 })}
        buttons={[
          {
            text: text.deleteModal.cancel,
            role: 'cancel',
            handler: onDeleteAlertDismissed,
          },
          {
            text: text.deleteModal.accept,
            handler: deleteRow,
          },
        ]}
      />
      <StyledDateRow>
        {renderRows()}
        {isCurrentLoggedInUserInPath && (
          <AddSetButton onClick={onNewSetAdded}>
            {text.addSet}
          </AddSetButton>
        )}
      </StyledDateRow>
    </>
  );
};

DateRow.propTypes = {
  date: PropTypes.string.isRequired,
  onRowUpdate: PropTypes.func.isRequired,
  weight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  reps: PropTypes.number,
  sets: PropTypes.arrayOf(
    PropTypes.shape({
      weight: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]).isRequired,
      reps: PropTypes.number.isRequired,
    }),
  ),
  measurementSystem: PropTypes.string.isRequired,
  comment: PropTypes.string,
  isDisabled: PropTypes.bool,
};

DateRow.defaultProps = {
  weight: 0,
  reps: 0,
  isDisabled: false,
  sets: [],
  comment: '',
};

export default DateRow;
