import React, {
  useContext,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import ReactHtmlParser from 'react-html-parser';
import SubscriptionContext, {
  withSubscriptionContextProvider,
  withSubscriptionContextReady,
} from '../../../../context/SubscriptionContext';
import {
  withStripeContextProvider,
  withStripeContextReady,
} from '../../../../context/StripeContext';
import texts from '../../texts.json';
import { ErrorText } from '../../../../components/BillingInformation/styles';
import InvoicesList from './InvoiceList';

const InvoiceListContainer = () => {
  const { invoiceList, invoiceError } = useContext(SubscriptionContext);

  if (invoiceError) {
    return <ErrorText>{ReactHtmlParser(texts.errorMessage)}</ErrorText>;
  }

  return (
    <InvoicesList invoices={invoiceList} />
  );
};

export default compose(
  withSubscriptionContextProvider,
  withSubscriptionContextReady,
  withStripeContextProvider,
  withStripeContextReady,
  observer,
)(InvoiceListContainer);
