import React from 'react';
import Auth from './Auth';

const withAuthentication = (Component) => (props) => (
  <Auth>
    <Component {...props} />
  </Auth>
);

export default withAuthentication;
