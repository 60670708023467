import React from 'react';

import useChat from '../../hooks/useChat';
import Chat from '../Chat';

/**
 * Injects the Chat to the given Component
 * @param {Object} Component
 * @returns A react node with chat integration
 */
const withChat = (Component) => (props) => {
  const { isChatEnabled } = useChat();

  if (isChatEnabled) {
    return (
      <Chat>
        <Component {...props} />
      </Chat>
    );
  }

  return <Component {...props} />;
};

export default withChat;
