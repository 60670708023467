import React from 'react';
import PropTypes from 'prop-types';

import ExerciseDetail from '../ExerciseDetail';
import texts from './texts.json';
import {
  Container,
  ActionsContainer,
  StyledOriginalExercise,
  Label,
  CheckboxWrapper,
  StyledIonCheckbox,
  StyledButton,
} from './styles';

const OverrideSelection = ({
  selectedExercise,
  originalExercise,
  isReplaceActionEnabled,
  showOriginalExercise,
  onReplaceExercise,
  onToggleReplaceAllAction,
  className,
}) => (
  <Container className={className}>
    {selectedExercise && (
      <ExerciseDetail exercise={selectedExercise} />
    )}

    {showOriginalExercise && (
      <StyledOriginalExercise exercise={originalExercise} />
    )}

    <CheckboxWrapper
      onClick={onToggleReplaceAllAction}
    >
      <StyledIonCheckbox
        mode="md"
        checked={isReplaceActionEnabled}
      />
      <Label>{texts.replacementDescription}</Label>
    </CheckboxWrapper>

    <ActionsContainer>
      <StyledButton
        onClick={onReplaceExercise}
        disabled={!isReplaceActionEnabled}
        trackingProps={{
          actionName: 'exerciseOverrideApplyOverride',
        }}
      >
        {texts.actionSave}
      </StyledButton>
    </ActionsContainer>
  </Container>
);

OverrideSelection.propTypes = {
  onReplaceExercise: PropTypes.func.isRequired,
  selectedExercise: PropTypes.object,
  originalExercise: PropTypes.object.isRequired,
  isReplaceActionEnabled: PropTypes.bool.isRequired,
  showOriginalExercise: PropTypes.bool.isRequired,
  onToggleReplaceAllAction: PropTypes.func.isRequired,
  className: PropTypes.string,
};

OverrideSelection.defaultProps = {
  selectedExercise: null,
  className: '',
};

export default OverrideSelection;
