import React from 'react';

import WorkoutExecutionInfoContextProvider from './WorkoutExecutionInfoContextProvider';

const withWorkoutExecutionInfoContextProvider = (Component) => (props) => (
  <WorkoutExecutionInfoContextProvider>
    <Component {...props} />
  </WorkoutExecutionInfoContextProvider>
);

export {
  withWorkoutExecutionInfoContextProvider,
};
