import React from 'react';
import PropTypes from 'prop-types';

import useAppTheme from '../../../hooks/useAppTheme';

const BackArrowIcon = ({ color, className }) => {
  const { colors } = useAppTheme();
  const fillColor = color || colors.system.beta;

  return (
    <svg
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        /* eslint-disable-next-line max-len */
        d="M7.52935 14.0303C7.23646 14.3232 6.76159 14.3232 6.46869 14.0303L0.218693 7.78033C-0.0741997 7.48744 -0.0741997 7.01256 0.218693 6.71967L6.46869 0.46967C6.76159 0.176777 7.23646 0.176777 7.52935 0.46967C7.82225 0.762563 7.82225 1.23744 7.52935 1.53033L2.55968 6.5H16.999C17.4132 6.5 17.749 6.83579 17.749 7.25C17.749 7.66421 17.4132 8 16.999 8H2.55968L7.52935 12.9697C7.82225 13.2626 7.82225 13.7374 7.52935 14.0303Z"
        fill={fillColor}
      />
    </svg>
  );
};

BackArrowIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

BackArrowIcon.defaultProps = {
  color: '',
  className: '',
};

export default BackArrowIcon;
