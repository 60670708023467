import styled, { css } from 'styled-components';

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';

import Button from '../Button';
import PaymentMethodIcon from '../PaymentMethodIcon';

import { fontFamily } from '../../styles/text';

const Container = styled.div`
  width: 100%;
`;

const ErrorText = styled.div`
  text-align: center;
  margin: 10px;
  > a {
    font-weight: bold;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  justify-content: space-between;
`;

const CardNumber = styled.div`
  font-family: ${fontFamily.sansSerif};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-right: 20px;
`;

const StyledPaymentMethodIcon = styled(PaymentMethodIcon)`
  display: flex;
  width: 45px;
  height: auto;
  margin-right: 20px;
`;

const EditButton = styled(Button)`
  padding: 5px;
  font-size: 14px;
  width: unset;
  margin-left: auto;
`;

const UploadButton = styled(Button)`
  margin-top: 15px;
  font-size: 12px;
  padding: 12px 30px;
  width: 100%;
  max-width: 295px;
  color: ${({ theme }) => theme.colors.fonts.primary};

  @media (min-width: 375px) {
    font-size: 16px;
  }
`;

const EditContainer = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  column-gap: 5px;
  row-gap: 5px;
  align-items: center;
  > :first-child {
    margin-top: 15px;
    grid-column: span 2;
  }
  > :last-child {
    grid-column: span 2;
    justify-self: center;
  }
`;

const stripeFieldStyles = css`
  width: 100%;
  font-size: 16px;
  padding: 12px;
  box-shadow: none;
  &.StripeElement {
    background-color: ${({ theme }) => theme.colors.system.background};
  }
`;

const StyledCardNumberElement = styled(CardNumberElement)`
  ${stripeFieldStyles};
`;

const StyledCardCvcElement = styled(CardCvcElement)`
  ${stripeFieldStyles};
`;

const StyledCardExpiryElement = styled(CardExpiryElement)`
  ${stripeFieldStyles};
`;

export {
  Container,
  ErrorText,
  InfoContainer,
  CardNumber,
  StyledPaymentMethodIcon,
  EditButton,
  UploadButton,
  EditContainer,
  StyledCardNumberElement,
  StyledCardCvcElement,
  StyledCardExpiryElement,
};
