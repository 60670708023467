import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import moment from 'moment';
import format from 'string-template';

import UserContractContext from '../../context/UserContractContext';
import FirebaseContext from '../../context/FirebaseContext';
import SubscriptionContext, {
  withSubscriptionContextProvider,
} from '../../context/SubscriptionContext';
import { formatCurrencyCents } from '../../utils/formatters';
import { DateFormat } from '../../utils/date';
import useComponentMounted from '../../hooks/useComponentMounted';
import useCurrentLoggedInUser from '../../hooks/useCurrentLoggedInUser';
import LoadingPage from '../LoadingPage';
import NotificationModal from '../NotificationModal/NotificationModal';

import ErrorCard from './ErrorCard';
import {
  extractError,
  reonboardingPageOrder,
  reonboardingPages,
  reonboardingPagesConfig,
} from './utils';
import texts from './texts.json';

const ReonboardingModal = () => {
  const {
    isNewContractAvailable,
    newContractDoc,
    isReonboardingModalOpen: isModalOpen,
    setIsReonboardingModalOpen: setIsModalOpen,
  } = useContext(UserContractContext);
  const { firebase: { remote } } = useContext(FirebaseContext);
  const {
    defaultPaymentMethod: {
      id: paymentMethodId,
    } = {},
    isReady: isPaymentMethodReady,
  } = useContext(SubscriptionContext);
  const [currentPage, setCurrentPage] = useState(reonboardingPageOrder[0]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showActions, setShowActions] = useState(true);
  const isComponentMountedRef = useComponentMounted();
  const { isCurrentLoggedInUserInPath } = useCurrentLoggedInUser();

  /* Change the modal open state based on the new contract availability and payment method readiness,
   * only on terms(initial) page. In any other page, we don't want to close the modal based on the new
   * contract availability since we want to show the success page after activating the contract */
  useEffect(() => {
    const shouldOpenModal = isNewContractAvailable && isPaymentMethodReady;
    if (currentPage === reonboardingPages.TERMS) {
      setIsModalOpen(shouldOpenModal);
    }
  }, [
    isNewContractAvailable,
    currentPage,
    isPaymentMethodReady,
    setIsModalOpen,
  ]);

  /* We will mark the contract as IN_PROGRESS when the modal opens up
   * to avoid any edits from the coach dashboard on the contract. Then we
   * revert back the status to PENDING if the modal is closed while the contract
   * status is still _IN_PROGRESS
   */
  useEffect(() => {
    const updateContract = async () => {
      if (isModalOpen) {
        setCurrentPage(reonboardingPageOrder[0]);
        await newContractDoc.markInProgress();
      } else {
        await newContractDoc.markPending();
      }
    };

    if (isCurrentLoggedInUserInPath && newContractDoc) {
      updateContract();
    }
  }, [
    isModalOpen,
    newContractDoc,
    isCurrentLoggedInUserInPath,
  ]);

  const renderContent = useCallback(() => {
    const {
      component: ModalContent,
    } = reonboardingPagesConfig[currentPage];

    if (ModalContent) {
      return (
        <>
          <ModalContent setShowActions={setShowActions} />
          {isLoading && <LoadingPage fullHeight={false} />}
          {!!error && <ErrorCard error={error} />}
        </>
      );
    }

    return null;
  }, [
    currentPage,
    isLoading,
    error,
  ]);

  const {
    initialCharge,
    chargeDate,
  } = useMemo(() => {
    if (newContractDoc) {
      const {
        startDate,
        initialPaymentInCents,
        currency,
      } = newContractDoc;

      const initialChargeText = formatCurrencyCents(initialPaymentInCents, currency);
      const startDateMoment = moment(startDate);
      const formattedStartDate = startDateMoment.isAfter(moment())
        ? `${startDateMoment.fromNow()} (${startDateMoment.format(DateFormat.DAY_WITH_SHORT_MONTH)})`
        : texts.now;

      return {
        initialCharge: initialChargeText,
        chargeDate: formattedStartDate,
      };
    }
    return {};
  }, [
    newContractDoc,
  ]);

  const activateContract = useCallback(async () => {
    if (newContractDoc) {
      setError('');
      setIsLoading(true);
      const {
        userEmail: email,
        leadId = '',
        startDate: startAt,
        coupon = '',
      } = newContractDoc;
      const clientUtcOffset = moment().utcOffset();
      try {
        const apiResponse = await remote('createStripeSubscriptionFromContract', {
          email,
          paymentMethodId,
          contractId: newContractDoc.id,
          leadId,
          startAt: moment(startAt).valueOf(),
          coupon,
          clientUtcOffset,
        });

        const stripeResponse = await apiResponse.json();
        if (isComponentMountedRef.current) {
          if (stripeResponse.error) {
            setError(extractError(stripeResponse).error.message);
          } else {
            setCurrentPage(reonboardingPages.SUCCESS);
          }
        }
      } catch (renewalError) {
        if (isComponentMountedRef.current) {
          setError(extractError(renewalError).error.message);
        }
      } finally {
        if (isComponentMountedRef.current) {
          setIsLoading(false);
        }
      }
    }
  }, [
    newContractDoc,
    paymentMethodId,
    remote,
    isComponentMountedRef,
  ]);

  const handleActions = useCallback(async () => {
    switch (currentPage) {
      case reonboardingPages.TERMS:
        setCurrentPage(reonboardingPages.PAYMENT);
        break;
      case reonboardingPages.PAYMENT:
        await activateContract();
        break;
      case reonboardingPages.SUCCESS:
        setIsModalOpen(false);
        break;
      default:
        break;
    }
  }, [
    currentPage,
    activateContract,
    setIsModalOpen,
  ]);

  return (
    <NotificationModal
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      actions={[
        {
          label: reonboardingPagesConfig[currentPage].actionLabel,
          action: handleActions,
          disabled: !showActions || isLoading || !isCurrentLoggedInUserInPath,
        },
      ]}
      title={format(reonboardingPagesConfig[currentPage].title, {
        initialCharge,
      })}
      description={format(reonboardingPagesConfig[currentPage].description, {
        initialCharge,
        chargeDate,
      })}
      Icon={reonboardingPagesConfig[currentPage].icon}
    >
      {renderContent()}
    </NotificationModal>
  );
};

export default compose(
  withSubscriptionContextProvider,
  observer,
)(ReonboardingModal);
