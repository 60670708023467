import React, {
  useCallback,
  useContext,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import logEvent from '../../../utils/logger';
import { withFirebase } from '../../../context/FirebaseContext';
import CoachContactContext from '../../../context/CoachContactContext';
import AppContext from '../../../context/AppContext';
import ChatContext from '../../../chat/context';

import useDevice from '../../../hooks/useDevice';
import useUserNavigation from '../../../hooks/useUserNavigation';
import useSessionStore from '../../../hooks/useSessionStore';

import { ReactComponent as SignoutIcon } from '../../../assets/menu/signout.svg';
import { ReactComponent as AppSettingsIcon } from '../../../assets/menu/app-settings.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/menu/phone-icon.svg';
import { ReactComponent as ChangeLogIcon } from '../../../assets/menu/change-log.svg';

import MenuItem from './MenuItem/MenuItem';

import {
  Container,
  NavigationList,
  NavigationBody,
  NavigationItem,
  NavigationFooter,
  FooterElement,
  IconContainer,
  AppVersion,
} from './styles';

import texts from './texts.json';

const NavigationMenu = ({
  firebase,
  onItemAction,
  className,
}) => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { deactivateDevice } = useDevice();
  const { disconnectUser } = useContext(ChatContext);
  const { pathname: userHomePath } = useUserNavigation();
  const { isAdmin } = useSessionStore();
  const { canContactCoach } = useContext(CoachContactContext);
  const { appVersion } = useContext(AppContext);

  const signOut = useCallback(async (event) => {
    event.preventDefault();

    logEvent('signOutClick');

    try {
      /*
        In this case we can't be offline tolerant because we need to make
        sure that the device got deactivated before actually doing the logout.
        Otherwise the operation can not be done once we don't have an active
        user anymore. During offline, this will hang and recovers as soon as the
        connection is back. Even on slow 3G this is not something that can be
        perceived as Signout action being slow.
      */
      await deactivateDevice();

      /**
       * Disconnect user from current chat
       */
      await disconnectUser();
    } catch (err) {
      // Do not prevent the actual signout from happening!
      Sentry.captureException(err);
    }

    await firebase.signOut();
    history.push('/');
  }, [
    disconnectUser,
    deactivateDevice,
    firebase,
    history,
  ]);

  const createItemClick = useCallback((menuItem) => {
    if (menuItem.url) {
      return onItemAction;
    }

    return (event) => {
      menuItem.onClick(event);
      onItemAction();
    };
  }, [onItemAction]);

  const isItemActive = (url) => url === pathname;

  const menuItems = useMemo(() => {
    const items = [
      {
        key: 'settings',
        url: `${userHomePath}/settings`,
        Icon: AppSettingsIcon,
        title: texts.settings,
      },
    ];

    if (canContactCoach) {
      items.push({
        key: 'coach-call',
        url: `${userHomePath}/scheduleCall`,
        Icon: PhoneIcon,
        title: texts.scheduleCoachCall,
      });
    }

    items.push({
      key: 'changelog',
      url: `${userHomePath}/changelog`,
      Icon: ChangeLogIcon,
      title: texts.changelog,
    });

    if (isAdmin) {
      items.push({
        key: 'debug',
        url: `${userHomePath}/d/settings`,
        Icon: AppSettingsIcon,
        title: 'Debug Settings',
      });
    }

    return items;
  }, [
    isAdmin,
    canContactCoach,
    userHomePath,
  ]);

  return (
    <Container className={className}>
      <NavigationBody>
        <NavigationList>
          {
            menuItems.map((menuItem) => {
              const onClick = createItemClick(menuItem);

              const {
                Icon,
                key,
                url,
                isExternalUrl,
                title,
              } = menuItem;

              return (
                <NavigationItem key={key}>
                  <MenuItem
                    url={`${url}${search}`}
                    isExternalUrl={isExternalUrl}
                    onClick={onClick}
                    active={isItemActive(url)}
                  >
                    <IconContainer>
                      <Icon />
                    </IconContainer>
                    <span>{title}</span>
                  </MenuItem>
                </NavigationItem>
              );
            })
          }
        </NavigationList>
      </NavigationBody>
      <NavigationFooter>
        <FooterElement>
          <NavigationList>
            <NavigationItem>
              <MenuItem onClick={signOut}>
                <IconContainer>
                  <SignoutIcon />
                </IconContainer>
                <span>{texts.signout}</span>
              </MenuItem>
            </NavigationItem>
          </NavigationList>
        </FooterElement>
        <FooterElement>
          <AppVersion>{appVersion.displayVersions}</AppVersion>
        </FooterElement>
      </NavigationFooter>
    </Container>
  );
};

NavigationMenu.propTypes = {
  firebase: PropTypes.object.isRequired,
  onItemAction: PropTypes.func.isRequired,
  className: PropTypes.string,
};

NavigationMenu.defaultProps = {
  className: '',
};

export default compose(
  withFirebase,
  observer,
)(NavigationMenu);
