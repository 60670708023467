import styled from 'styled-components';

import EstimatedDuration from '../../../components/EstimatedDuration';
import { FlatCard } from '../../../components/Card';
import { headline2 } from '../../../styles/text';

const StyledCardItem = styled(FlatCard)`
  flex-direction: column;
`;

const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  ${StyledCardItem} {
    margin-bottom: 8px;
  }
`;

const Title = styled.div`
  ${headline2}
  font-size: 28px;
  color: ${({ theme }) => theme.colors.base.primary};
`;

const StyledEstimatedDuration = styled(EstimatedDuration)`
  margin-top: 8px;
`;

export {
  StyledList,
  StyledCardItem,
  Title,
  StyledEstimatedDuration,
};
