import CollectionName from '../utils/collections';
import BaseDocument from './BaseDocument';

class AppCustomTheme extends BaseDocument {
  constructor(id, opts) {
    super(`${CollectionName.APP_CUSTOM_THEME}/${id}`, opts);
  }

  get colors() {
    return this.data.colors || {};
  }

  static async getById(id) {
    const appCustomThemeDoc = new AppCustomTheme(id);
    await appCustomThemeDoc.init();
    return appCustomThemeDoc.exists ? appCustomThemeDoc : null;
  }
}

export default AppCustomTheme;
