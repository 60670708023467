import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import Button from '../../../../components/Button';
import FormikInput from '../../../../components/FormikInput';
import { validationSchema, initialValues } from './validation';

const LoginForm = ({ onSubmit, onEmailChange }) => (
  <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    {({
      errors, touched, isSubmitting, handleChange,
    }) => (
      <Form>
        <FormikInput
          name="email"
          placeholder="Email"
          onChange={(e) => { onEmailChange(e); handleChange(e); }}
          error={errors.email}
          touched={touched.email}
        />
        <FormikInput
          name="password"
          placeholder="Password"
          error={errors.password}
          touched={touched.password}
          type="password"
        />
        <Button disabled={isSubmitting} type="submit">Log In</Button>
      </Form>
    )}
  </Formik>
);

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onEmailChange: PropTypes.func.isRequired,
};

export default LoginForm;
