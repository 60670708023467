import React from 'react';
import PropTypes from 'prop-types';

import { ActivityName } from '../styles';
import {
  FeedbackText,
  StyledFeedbackForm,
} from './styles';

const FeedbackForm = ({
  name,
  feedbackText,
  isSelected,
  onFeedbackChange,
}) => (
  <StyledFeedbackForm
    isSelected={isSelected}
  >
    <ActivityName>{name}</ActivityName>
    <FeedbackText
      type="text"
      value={feedbackText}
      onChange={onFeedbackChange}
    />
  </StyledFeedbackForm>
);

FeedbackForm.propTypes = {
  name: PropTypes.string.isRequired,
  feedbackText: PropTypes.string,
  isSelected: PropTypes.bool.isRequired,
  onFeedbackChange: PropTypes.func.isRequired,
};

FeedbackForm.defaultProps = {
  feedbackText: '',
};

export default FeedbackForm;
