/* eslint class-methods-use-this: off */

import {
  hMiddlePoint,
  vMiddlePoint,
  degrees,
} from '../utils/math';

const DEFAULT_ASPECT_RATIO = 456 / 256;

class BasePrediction {
  constructor(aspectRatio) {
    this.keypoints = {};

    this.aspectRatio = aspectRatio || DEFAULT_ASPECT_RATIO;

    this.debugData = {
      aspectRatio: this.aspectRatio,
    };
  }

  keypointsDefinition() {
    throw new Error('"keypointsDefinition" is not implemented');
  }

  /**
   * Returns (xMin, yMin, xMax, yMax) of the predicted object.
   */
  get boundingBox() {
    let xMax = -Infinity;
    let xMin = Infinity;
    let yMax = -Infinity;
    let yMin = Infinity;

    Object.values(this.keypointsDefinition()).forEach((joint) => {
      const bodyPart = this.keypoints[joint];

      if (bodyPart) {
        xMin = Math.min(xMin, bodyPart[0]);
        yMin = Math.min(yMin, bodyPart[1]);
        xMax = Math.max(xMax, bodyPart[0]);
        yMax = Math.max(yMax, bodyPart[1]);
      }
    });

    const result = [xMin, yMin, xMax, yMax];

    this.debugData.boundingBox = result;

    return result;
  }

  get boundingBoxWidth() {
    const box = this.boundingBox;
    const width = box[2] - box[0];
    this.debugData.boundingBoxWidth = width;
    return width;
  }

  get boundingBoxHeight() {
    const box = this.boundingBox;
    const height = box[3] - box[1];
    this.debugData.boundingBoxHeight = height;
    return height;
  }

  get boundingBoxMiddlePoint() {
    const [xMin, yMin, xMax, yMax] = this.boundingBox;

    const min = [xMin, yMin];
    const max = [xMax, yMax];

    const xMiddle = hMiddlePoint(min, max);
    const yMiddle = vMiddlePoint(min, max);

    return [xMiddle, yMiddle];
  }

  /**
   * Returns the x and y coordinates of a keypoint relative
   * to the bounding box width, given its type.
   */
  coords(keypoint) {
    let x;
    let y;
    const part = this.keypoints[keypoint];
    if (part) {
      x = ((part[0] - this.boundingBox[0]) / this.boundingBoxWidth);
      y = (((part[1] - this.boundingBox[1]) / this.aspectRatio) / this.boundingBoxWidth);
    }
    return [x, y];
  }

  /**
   * Calculates and returns the x and y coordinates of a prediction part (keypoint) in the frame.
   *
   * @param {number} keypoint
   * @returns {Array<number>} An array of coordinates in x,y.
   */
  baseCoords(keypoint) {
    const part = this.keypoints[keypoint];
    let x;
    let y;
    if (part) {
      [x, y] = part;
    }
    return [x, y];
  }

  /**
   * Given a keypoint, returns how far the keypoint is from the bottom
   * of the bounding box relative to the height of the bounding box.
   */
  fromBottom(keypoint) {
    const yCoord = this.coords(keypoint)[1];
    return 1 - yCoord * ((this.boundingBoxWidth / this.boundingBoxHeight) * this.aspectRatio);
  }

  /**
   * Returns the angle at a particular joint.
   *
   * Takes in 3 keypoints, A, B, C, assumes they are connected B--A--C.
   * Returns the angle at keypoint A, which is in the range between 0 and 180 degrees.
   */
  keypointAngle(keypointA, keypointB, keypointC) {
    const aCoords = this.coords(keypointA);
    const bCoords = this.coords(keypointB);
    const cCoords = this.coords(keypointC);
    let angle = Math.abs(
      degrees(Math.atan2(cCoords[1] - aCoords[1], cCoords[0] - aCoords[0]))
        - degrees(Math.atan2(bCoords[1] - aCoords[1], bCoords[0] - aCoords[0])),
    );
    if (angle > 180) {
      angle = 360 - angle;
    }
    return angle;
  }

  /**
   * Returns if true if there are coords for the keypoint, false otherwise
   */
  visible(keypoint) {
    return (keypoint in this.keypoints);
  }
}

export default BasePrediction;
