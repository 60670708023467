import styled from 'styled-components';

const CardContainer = styled.div`
  background: ${({ theme }) => theme.colors.system.background};
  border-radius: 8px;
  padding: 16px;
  margin: 16px 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.system.error};
  border: 1px solid ${({ theme }) => theme.colors.system.error};
`;

export {
  CardContainer,
};
