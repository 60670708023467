import { Digest } from 'tdigest';

const HIGHER_VALUE_PERCENTILE = 0.9;

const LOWEST_VALUE_PERCENTILE = 1 - HIGHER_VALUE_PERCENTILE;

const MEDIAN_PERCENTILE = 0.5;

class Stats {
  constructor() {
    this.digest = new Digest();
  }

  stats() {
    this.digest.compress();

    const [
      percentileLowestValue,
      median,
      percentileHighestValue,
    ] = this.digest.percentile([LOWEST_VALUE_PERCENTILE, MEDIAN_PERCENTILE, HIGHER_VALUE_PERCENTILE]);

    return {
      percentileLowestValue,
      median,
      percentileHighestValue,
    };
  }

  addValue(value) {
    this.digest.push(value);
  }
}

export default Stats;
