import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import Container, { ErrorMessage } from './styles';

const FormikInput = ({
  name,
  placeholder,
  error,
  touched,
  type,
  isSmall,
  className,
  as,
  ...other
}) => (
  <Container isSmall={isSmall} className={className}>
    <Field
      name={name}
      type={type}
      placeholder={placeholder}
      className={className}
      as={as}
      {...other}
    />
    <ErrorMessage name={name} component="div" />
  </Container>
);

FormikInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.bool,
  type: PropTypes.string,
  isSmall: PropTypes.bool,
  className: PropTypes.string,
  as: PropTypes.string,
};

FormikInput.defaultProps = {
  placeholder: '',
  error: '',
  touched: false,
  type: 'text',
  isSmall: false,
  className: 'formikInput',
  as: 'input',
};

export default FormikInput;
