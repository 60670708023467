import React from 'react';
import PropTypes from 'prop-types';

import Header from '../Header';

import {
  StyledInfoPanel,
  InfoPanelContent,
} from './styles';

const InfoPanel = ({
  title,
  LeftIcon,
  RightIcon,
  onLeftIconClick,
  onRightIconClick,
  children,
  className,
}) => (
  <StyledInfoPanel className={className}>
    <Header
      title={title}
      LeftIcon={LeftIcon}
      RightIcon={RightIcon}
      onLeftIconClick={onLeftIconClick}
      onRightIconClick={onRightIconClick}
    />
    <InfoPanelContent>{children}</InfoPanelContent>
  </StyledInfoPanel>
);

InfoPanel.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  LeftIcon: PropTypes.elementType,
  RightIcon: PropTypes.elementType,
  onLeftIconClick: PropTypes.func,
  onRightIconClick: PropTypes.func,
  className: PropTypes.string,
};

InfoPanel.defaultProps = {
  LeftIcon: null,
  RightIcon: null,
  onLeftIconClick: () => {},
  onRightIconClick: () => {},
  className: '',
};

export default InfoPanel;
