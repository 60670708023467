import React, {
  useCallback,
  useMemo,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import merge from 'lodash.merge';
import moment from 'moment';

import { DateFormat } from '../../utils/date';
import Button from '../../components/Button';
import PhotoPicker from '../../components/PhotoPicker';
import { validationSchema, initialValues } from './validation';
import texts from './text.json';
import {
  ContainerForm,
  FileDeleteIcon,
  FooterContainer,
  SpacedCard,
  StyledFormikDatePicker,
  StyledFormikInput,
  FileUploaderContainerCard,
} from './styles';

const MAX_TEXT_AREA_HEIGHT = 100;

const CustomWorkout = ({
  onSubmit,
  selectedFileDetail,
  workoutData,
  shouldAllowActions,
}) => {
  // This is to stop glitches due to async event dispatches.
  const [selectedDataUrl, setSelectedDataUrl] = useState(() => selectedFileDetail.dataUrl);
  const [fileChangeDetail, setFileChangeDetail] = useState({});
  const commentTextAreaElementRef = useRef(null);

  const adjustHeight = useCallback(() => {
    const textArea = commentTextAreaElementRef.current;
    textArea.style.height = `${Math.min(textArea.scrollHeight, MAX_TEXT_AREA_HEIGHT)}px`;
  }, []);

  const onFormSubmission = useCallback((data) => {
    const comment = commentTextAreaElementRef.current.value;
    onSubmit({ ...data, comment, selectedFileDetail: { ...fileChangeDetail } });
  }, [onSubmit, fileChangeDetail]);

  // First sets the local state and then calls the container functions to avoid glitches.
  const onFileSelection = useCallback((detail) => {
    setSelectedDataUrl(detail.dataUrl);
    setFileChangeDetail(detail);
  }, []);

  const onDeletion = useCallback(() => {
    setSelectedDataUrl(null);
    setFileChangeDetail({ isFileChanged: true });
  }, []);

  useEffect(() => {
    const {
      comment,
    } = workoutData || {};

    if (commentTextAreaElementRef.current
      && commentTextAreaElementRef.current.value === ''
      && comment
    ) {
      commentTextAreaElementRef.current.value = comment;
    }
  }, [
    workoutData,
  ]);

  const getFileHandlingComponent = useCallback(() => {
    if (selectedDataUrl) {
      return (
        <FileUploaderContainerCard>
          {shouldAllowActions && <FileDeleteIcon onClick={onDeletion} />}
          <img
            src={selectedDataUrl}
            alt="custom-workout-attachment"
          />
        </FileUploaderContainerCard>
      );
    }

    return shouldAllowActions && (
      <PhotoPicker
        actionText={texts.photoAddMessage}
        onFileSelected={onFileSelection}
      />
    );
  }, [
    shouldAllowActions,
    selectedDataUrl,
    onDeletion,
    onFileSelection,
  ]);

  const formInitialValues = useMemo(() => {
    if (workoutData) {
      const {
        comment,
        title: exerciseName,
        startDate,
      } = workoutData;

      return merge({ ...initialValues }, {
        comment,
        exerciseName,
        date: startDate
          ? startDate.format(DateFormat.DEFAULT_DATE_TIME_FORMAT)
          : undefined,
      });
    }

    return initialValues;
  }, [
    workoutData,
  ]);

  const datePickerRange = {
    min: moment().subtract(1, 'year').startOf('year').format(DateFormat.DEFAULT_DATE_FORMAT),
    max: moment().format(DateFormat.DEFAULT_DATE_FORMAT),
  };

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={onFormSubmission}
    >
      {({
        errors, touched, isSubmitting,
      }) => (
        <ContainerForm>
          <SpacedCard>
            <StyledFormikInput
              name="exerciseName"
              placeholder={texts.placeholder.exerciseName}
              error={errors.exerciseName}
              touched={touched.exerciseName}
              autoComplete="off"
              disabled={!shouldAllowActions}
            />
            <StyledFormikDatePicker
              name="date"
              disabled={!shouldAllowActions}
              {...datePickerRange}
            />
            <StyledFormikInput
              name="comment"
              as="textarea"
              placeholder={texts.placeholder.workoutComment}
              error={errors.comment}
              touched={touched.comment}
              rows="1"
              ref={commentTextAreaElementRef}
              onInput={adjustHeight}
              autoComplete="off"
              disabled={!shouldAllowActions}
            />
          </SpacedCard>
          {getFileHandlingComponent()}
          {shouldAllowActions
            && (
              <FooterContainer>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                >
                  {texts.mainActionName}
                </Button>
              </FooterContainer>
            )}
        </ContainerForm>
      )}
    </Formik>
  );
};

CustomWorkout.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  selectedFileDetail: PropTypes.object.isRequired,
  workoutData: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    comment: PropTypes.string,
    startDate: PropTypes.object,
  }),
  shouldAllowActions: PropTypes.bool.isRequired,
};

CustomWorkout.defaultProps = {
  workoutData: null,
};

export default CustomWorkout;
