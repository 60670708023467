import { useContext, useMemo } from 'react';
import { MobXProviderContext } from 'mobx-react';

const useSessionStore = () => {
  const { sessionStore } = useContext(MobXProviderContext);

  return useMemo(() => sessionStore, [sessionStore]);
};

export default useSessionStore;
