import styled, { css } from 'styled-components';

import { body1, body2, subtitle2 } from '../../styles/text';
import Card from '../Card';

const CoachFeedbackContainer = styled.div`
  padding: 10px 0;
  background: ${({ theme }) => theme.colors.system.alpha};
`;

const MainContainer = styled.div`
  padding: 10px 20px 20px 20px;
`;

const ActivityName = styled.div`
  ${body2}
  margin-bottom: 5px;
  text-transform: capitalize;
`;

const FeedbackNote = styled.div`
  ${body1}
`;

const ActivityFeedbackCard = styled(Card)`
  flex-direction: column;
  box-shadow: none;
  ${({ isSelected }) => (isSelected
    ? css`background: ${({ theme }) => theme.colors.base.tertiaryVariant};`
    : '')};
`;

const ActivityNameHeaderContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
`;

const ActivityNameHeader = styled.div`
  ${subtitle2}
  color: ${({ theme }) => theme.colors.system.beta};
`;

const TimelineContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

const ActivityItem = styled.div`
  display: flex;
  width: 100%;
  height: 20px;
  margin: 0 1px;
  ${({ isSelected, feedbackExists }) => {
    if (isSelected) {
      if (feedbackExists) {
        return css`
          background: ${({ theme }) => theme.colors.base.secondary};
          border-top: 5px solid ${({ theme }) => theme.colors.base.tertiary};
          border-bottom: 5px solid ${({ theme }) => theme.colors.base.tertiary};
        `;
      }
      return css`background: ${({ theme }) => theme.colors.base.tertiary};`;
    }
    if (feedbackExists) {
      return css`background: ${({ theme }) => theme.colors.base.secondary};`;
    }
    return css`
      background: ${({ theme }) => theme.colors.system.secondary5};
      height: 8px; margin: 6px 1px;
    `;
  }};
  border-radius: 2px;
`;

const SliderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`;

export {
  CoachFeedbackContainer,
  MainContainer,
  ActivityFeedbackCard,
  FeedbackNote,
  ActivityName,
  ActivityNameHeader,
  ActivityNameHeaderContainer,
  TimelineContainer,
  ActivityItem,
  SliderContainer,
};
