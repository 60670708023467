import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { initFirestorter } from 'firestorter';

import logEvent from '../../utils/logger';
import { createTimeout } from '../../utils/timeout';
import useComponentLoadingTime from '../../hooks/useComponentLoadingTime';
import {
  shouldClearCache,
  updateLastTimeFirestoreCacheCleared,
} from './persistence';
import FirebaseContext from './FirebaseContext';

const FirebaseContextProvider = ({ firebase, children }) => {
  const [isReady, setIsReady] = useState(false);
  const { startLoading, finishLoading } = useComponentLoadingTime('firebaseContext');

  useEffect(() => {
    let shouldUpdate = true;
    const init = async () => {
      startLoading();
      // Clear firestore persistence
      if (shouldClearCache()) {
        const startTime = (window.performance || Date).now();

        logEvent('firestoreStartingCacheClear', {
          startTime,
        });

        try {
          const clearPersistencePromise = firebase.firestore.clearPersistence();

          Promise.race([
            createTimeout('Timeout waiting for firestore.clearPersistence'),
            clearPersistencePromise,
          ])
            .catch((error) => {
              if (error.isOperationTimeout) {
                logEvent('firestoreClearCacheTimeout');
                Sentry.captureException(error);
              }
            });

          await clearPersistencePromise;
          updateLastTimeFirestoreCacheCleared(Date.now());
          logEvent('firestoreCacheCleared', {
            totalTime: (window.performance || Date).now() - startTime,
          });
        } catch (error) {
          logEvent('firestoreCacheClearFailed', {
            totalTime: (window.performance || Date).now() - startTime,
          });

          Sentry.captureException(error, {
            extra: {
              description: 'Error trying to clean up firestore local storage',
            },
          });
        }
      }

      try {
        const enablePersistencePromise = firebase.firestore.enablePersistence({ synchronizeTabs: true });

        Promise.race([
          createTimeout('Timeout waiting firestore.enablePersistence'),
          enablePersistencePromise,
        ])
          .catch((error) => {
            if (error.isOperationTimeout) {
              /*
                Track and report this error only for troubleshooting and analysis purpose.
                The initialization workflow will continue:
                - an error is reported but after the timeout the operation is completed successfully or,
                - the app will remain in a non ready state
              */
              logEvent('firestoreEnablePersistenceTimeout');
              Sentry.captureException(error);
            }
          });

        await enablePersistencePromise;
      } catch (err) {
        Sentry.captureException(err);
      }

      initFirestorter({ firebase: firebase.app });

      if (shouldUpdate) {
        setIsReady(true);
        finishLoading();
      }
    };

    if (!isReady) {
      init();
    }
    return () => {
      shouldUpdate = false;
    };
  }, [
    isReady,
    firebase,
    startLoading,
    finishLoading,
  ]);

  const context = useMemo(() => ({
    isReady,
    firebase,
  }), [
    isReady,
    firebase,
  ]);

  return (
    <FirebaseContext.Provider value={context}>
      {children}
    </FirebaseContext.Provider>
  );
};

FirebaseContextProvider.propTypes = {
  firebase: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default FirebaseContextProvider;
