import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import useCurrentLoggedInUser from '../../../../hooks/useCurrentLoggedInUser';
import {
  convertUnits,
  WeightUnit,
  MeasurementSystem,
} from '../../../../utils/measurement';
import DateRow from './DateRow';

const ActivityLogRows = ({
  activityName,
  rowsData,
  onRowUpdate,
  measurementSystem,
}) => {
  const { isCurrentLoggedInUserInPath } = useCurrentLoggedInUser();

  return (
    rowsData.map(({
      date,
      weight,
      reps,
      sets = [],
      comment,
      measurementSystem: rowMeasurementSystem = MeasurementSystem.IMPERIAL,
    }) => {
      const preparedWeight = convertUnits(weight, WeightUnit[rowMeasurementSystem], WeightUnit[measurementSystem]);
      const preparedSets = sets.map((set) => ({
        ...set,
        weight: convertUnits(
          set.weight,
          WeightUnit[set.measurementSystem || MeasurementSystem.IMPERIAL],
          WeightUnit[measurementSystem],
        ),
        measurementSystem,
      }));

      return (
        <DateRow
          key={`${activityName}-${date}-${measurementSystem}`}
          date={date}
          weight={preparedWeight}
          measurementSystem={measurementSystem}
          reps={reps}
          sets={preparedSets}
          comment={comment}
          onRowUpdate={onRowUpdate}
          isDisabled={!isCurrentLoggedInUserInPath}
        />
      );
    })
  );
};

ActivityLogRows.propTypes = {
  activityName: PropTypes.string.isRequired,
  rowsData: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRowUpdate: PropTypes.func.isRequired,
  measurementSystem: PropTypes.string.isRequired,
};

export default compose(
  observer,
)(ActivityLogRows);
