import { IonSpinner } from '@ionic/react';
import styled from 'styled-components';

import { sansSerif } from '../../../../styles/text';

const HabitText = styled.span`
  ${sansSerif.headlineFontStyles}
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.system.gamma2};
  margin-bottom: 6px;
`;

const StatText = styled.span`
  ${sansSerif.headlineFontStyles}
  font-size: 16px;
  line-height: 22px;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin-top: 8px;
  background-color: ${({ theme }) => theme.colors.system.background};
  border-radius: 10px;
`;

const TextContainer = styled.div`
  display: flex;
`;

const LoaderWrapper = styled.div`
  height: 45px;
  max-height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Loading = styled(IonSpinner)`
  width: 100%;
`;

export {
  HabitText,
  ItemContainer,
  TextContainer,
  StatText,
  LoaderWrapper,
  Loading,
};
