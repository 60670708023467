import React, {
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';
import * as Sentry from '@sentry/browser';

import { isIOS } from '../../utils/platform';
import { createTimeout } from '../../utils/timeout';
import logEvent from '../../utils/logger';
import { baseHabitList, baseHabitsDefinition } from '../../utils/habits';
import { baseCheckInQuestionList, baseCheckInQuestionDefinition } from '../../utils/checkIn';
import {
  updateNativeAppIcon,
  setupWebAppIcon,
  getWebAppIconURL,
  defaultLogo,
} from '../../services/appCustomization';
import useComponentMounted from '../../hooks/useComponentMounted';
import useComponentLoadingTime from '../../hooks/useComponentLoadingTime';
import Product from '../../models/Product';
import AppCustomization from '../../models/AppCustomization';
import FirebaseContext from '../FirebaseContext';
import UserContext from '../UserContext';
import AppCustomizationContext from './AppCustomizationContext';
import useAppCustomThemeSetup from './useAppCustomThemeSetup';

const APP_ICON_SETUP_TIMEOUT = 1500;

const AppCustomizationContextProvider = ({
  children,
}) => {
  const [isReady, setIsReady] = useState(false);
  const [isCustomProduct, setIsCustomProduct] = useState(false);
  const [productDoc, setProductDoc] = useState(null);
  const [appCustomizationDoc, setAppCustomizationDoc] = useState(null);
  const [appIcon, setAppIcon] = useState(defaultLogo);
  const isComponentMountedRef = useComponentMounted();
  const [habitList, setHabitList] = useState(baseHabitList);
  const [habitsDefinition, setHabitsDefinition] = useState(baseHabitsDefinition);
  const [checkInQuestionList, setCheckInQuestionList] = useState(baseCheckInQuestionList);
  const [checkInQuestionDefinition, setCheckInQuestionDefinition] = useState(baseCheckInQuestionDefinition);
  const [shouldUploadImage, setShouldUploadImage] = useState(false);
  const { startLoading, finishLoading } = useComponentLoadingTime('appCustomizationContext');

  const {
    userDoc,
  } = useContext(UserContext);
  const { firebase } = useContext(FirebaseContext);

  const { setupAppTheme } = useAppCustomThemeSetup();

  useEffect(() => {
    const init = async () => {
      startLoading();
      let product = null;
      let appCustomization = null;
      let isCustomProductValue = false;

      // Setup app icon as much as earlier as we can
      const iconUrl = await getWebAppIconURL();
      if (isComponentMountedRef.current) {
        setAppIcon(iconUrl);
      }

      // Setup Product and AppCustomization documents
      if (userDoc.product) {
        product = new Product(userDoc.product);
        await product.init();

        if (product.coach) {
          appCustomization = await AppCustomization.getAppCustomizationByCoach(product.coach);
        }

        isCustomProductValue = !product.isS2Product;
      }

      /*
        Setup web app icon if it's required. Wait up to APP_ICON_SETUP_TIMEOUT ms,
        so this process do not delay the rest of the initialization process
      */
      const setupIconPromise = setupWebAppIcon(firebase, appCustomization);

      setupIconPromise.then(async () => {
        const url = await getWebAppIconURL();
        if (isComponentMountedRef.current) {
          setAppIcon(url);
        }
      });

      try {
        await Promise.race([
          createTimeout('Timeout setting up web app icon', APP_ICON_SETUP_TIMEOUT),
          setupIconPromise,
        ]);
      } catch (error) {
        /*
          Do not report the error back to Sentry, we just want to track this using analytics
          in order to improve the logic and adjust the setup time if needed.
        */
        logEvent('timeoutSettingUpWebAppIcon', {
          error,
        });
      }

      /*
        Setup app custom colors theme at this point, before setting this context provider to ready so there's
        no glitch in the UI when a color theme is applied.
      */
      try {
        await setupAppTheme(appCustomization);
      } catch (error) {
        Sentry.captureException(error, {
          extra: {
            description: 'An error happened while setting up app custom theme (colors)',
          },
        });
      }

      if (isComponentMountedRef.current) {
        setProductDoc(product);
        setAppCustomizationDoc(appCustomization);
        setIsCustomProduct(isCustomProductValue);
        setIsReady(true);
        finishLoading();
      }
    };

    init();
  }, [
    userDoc,
    firebase,
    isComponentMountedRef,
    setupAppTheme,
    startLoading,
    finishLoading,
  ]);

  useEffect(() => {
    let disposer;
    const setDoc = async () => {
      if (appCustomizationDoc) {
        let customHabitList = null;
        let customHabitsDefinition = null;
        let customCheckInQuestionList = null;
        let customCheckInQuestionDefinition = null;
        let isImageCompulsory = false;
        const habitDoc = await appCustomizationDoc.getBaseHabitDoc();
        const checkInDoc = await appCustomizationDoc.getBaseCheckinDoc();

        disposer = autorun(() => {
          if (habitDoc) {
            const { habits, habitsDefinition: customDefinition } = habitDoc;
            if (habits && habits.length > 0) {
              customHabitList = habits;
            }
            if (customDefinition && habits && habits.length > 0) {
              customHabitsDefinition = customDefinition;
            }
          }

          if (checkInDoc) {
            const {
              checkInQuestions,
              checkInQuestionDefinition: customDefinition,
              isImageUploadCompulsory,
            } = checkInDoc;
            if (checkInQuestions && checkInQuestions.length > 0) {
              customCheckInQuestionList = checkInQuestions;
            }
            if (customDefinition
              && checkInQuestions
              && checkInQuestions.length > 0) {
              customCheckInQuestionDefinition = customDefinition;
            }
            if (isImageUploadCompulsory) {
              isImageCompulsory = true;
            }
          }

          if (isComponentMountedRef.current) {
            if (customHabitList) {
              setHabitList(customHabitList);
            }
            if (customHabitsDefinition) {
              setHabitsDefinition(customHabitsDefinition);
            }
            if (customCheckInQuestionList) {
              setCheckInQuestionList(customCheckInQuestionList);
            }
            if (customCheckInQuestionDefinition) {
              setCheckInQuestionDefinition(customCheckInQuestionDefinition);
            }
            if (isImageCompulsory) {
              setShouldUploadImage(isImageCompulsory);
            }
          }
        });
      }
    };
    setDoc();
    return disposer;
  }, [
    appCustomizationDoc,
    isComponentMountedRef,
  ]);

  useEffect(() => {
    // Apply custom native app icon if any
    if (isIOS() && isReady && !userDoc.dontSetNativeAppIcon) {
      updateNativeAppIcon(appCustomizationDoc);
    }
  }, [
    appCustomizationDoc,
    isReady,
    userDoc,
  ]);

  const contextValue = useMemo(() => ({
    isReady,
    isCustomProduct,
    productDoc,
    appCustomizationDoc,
    appIcon,
    habitList,
    habitsDefinition,
    checkInQuestionList,
    checkInQuestionDefinition,
    shouldUploadImage,
  }), [
    isReady,
    isCustomProduct,
    productDoc,
    appCustomizationDoc,
    appIcon,
    habitList,
    habitsDefinition,
    checkInQuestionList,
    checkInQuestionDefinition,
    shouldUploadImage,
  ]);

  return (
    <AppCustomizationContext.Provider value={contextValue}>
      {children}
    </AppCustomizationContext.Provider>
  );
};

AppCustomizationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default observer(AppCustomizationContextProvider);
