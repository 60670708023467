import styled from 'styled-components';

const ChannelSearchWrapper = styled.div`
  .str-chat__channel-search {
    padding: 0;
    background-color: ${({ theme }) => theme.colors.system.support};
    flex-direction: column;

    &-container {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: initial;
      padding: 0;
      top: initial;
    }
  }

`;

const SearchStateContainer = styled.div`
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.system.beta};
  background: ${({ theme }) => theme.colors.system.alpha};
  text-align: center;
  padding: 20px;
`;

export {
  ChannelSearchWrapper,
  SearchStateContainer,
};
