import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import { ActivityTypes } from '../../models/BaseActivity';
import { prepareActivities } from '../../utils/prepareActivities';
import { convertIndexToLetter } from '../../utils/text';

import ActivityCard, { SimpleActivityCard } from './ActivityCard';
import Circuit from './Circuit';

const ActivitiesList = ({
  activities,
  ActivityExtraContent,
  ExtraContentWidth,
  renderExtraOnHighlightOnly,
  currentActivityId,
  displayReducedData,
}) => {
  const preparedActivities = useMemo(() => prepareActivities(activities), [activities]);

  const renderActivities = () => (
    preparedActivities.map((activity, index) => {
      const activityLetter = convertIndexToLetter(index);

      if (activity.type === ActivityTypes.CIRCUIT) {
        return (
          <Circuit
            activity={activity}
            circuitTag={activityLetter}
            key={activityLetter}
            ActivityExtraContent={ActivityExtraContent}
            ExtraContentWidth={ExtraContentWidth}
            currentActivityId={currentActivityId}
            displayReducedData={displayReducedData}
            renderExtraOnHighlightOnly={renderExtraOnHighlightOnly}
          />
        );
      }

      if (displayReducedData && activity.type === ActivityTypes.REST) {
        return null;
      }

      const isHighlighted = currentActivityId === activityLetter;
      const shouldRenderExtra = renderExtraOnHighlightOnly
        ? isHighlighted && activity.type !== ActivityTypes.REST
        : true;

      // Choose the type of activity card to render
      const ActivityToRender = displayReducedData ? SimpleActivityCard : ActivityCard;

      return (
        <ActivityToRender
          activity={activity}
          tag={activityLetter}
          key={activityLetter}
          isHighlighted={isHighlighted}
          ExtraContent={shouldRenderExtra ? ActivityExtraContent : null}
          ExtraContentWidth={ExtraContentWidth}
        />
      );
    })
  );

  return (
    <>
      {renderActivities()}
    </>
  );
};

ActivitiesList.propTypes = {
  activities: PropTypes.array.isRequired,
  ActivityExtraContent: PropTypes.elementType,
  currentActivityId: PropTypes.string,
  displayReducedData: PropTypes.bool,
  ExtraContentWidth: PropTypes.string,
  renderExtraOnHighlightOnly: PropTypes.bool,
};

ActivitiesList.defaultProps = {
  ActivityExtraContent: null,
  currentActivityId: '',
  displayReducedData: false,
  ExtraContentWidth: '70px',
  renderExtraOnHighlightOnly: false,
};

export default compose(
  observer,
)(ActivitiesList);
