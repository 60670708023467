import React from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';
import ReactHtmlParser from 'react-html-parser';

import ClockIcon from '../../../components/Icons/ClockIcon';
import IngredientsIcon from '../../../components/Icons/IngredientsIcon';
import MealImage from '../../../components/MealImage';
import {
  StyledCard,
  ImageWrapper,
  ImageContainer,
  Title,
  Description,
  InfoSection,
  InfoContainer,
  InfoItemWrapper,
  InfoItem,
} from './styles';
import texts from './texts.json';

const MealDescription = ({
  name,
  description,
  imageSrc,
  cookingTime,
  preparationTime,
  ingredientsCount,
}) => (
  <StyledCard>
    <ImageWrapper>
      <ImageContainer>
        <MealImage src={imageSrc} />
      </ImageContainer>
    </ImageWrapper>
    <Title>{name}</Title>
    {!!description && (<Description>{description}</Description>)}
    <InfoSection>
      <InfoContainer>
        {preparationTime > 0 && (
          <InfoItemWrapper>
            <ClockIcon />
            <InfoItem>{ReactHtmlParser(format(texts.preparationTime, preparationTime))}</InfoItem>
          </InfoItemWrapper>
        )}
        {cookingTime > 0 && (
          <InfoItemWrapper>
            <ClockIcon />
            <InfoItem>{ReactHtmlParser(format(texts.cookingTime, cookingTime))}</InfoItem>
          </InfoItemWrapper>
        )}
      </InfoContainer>
      <InfoContainer>
        {ingredientsCount > 0 && (
          <InfoItemWrapper>
            <IngredientsIcon />
            <InfoItem>
              {ReactHtmlParser(
                format(texts.ingredients, {
                  ingredientsCount,
                  ingredientUnit: ingredientsCount > 1 ? texts.units.ingredients : texts.units.ingredient,
                }),
              )}
            </InfoItem>
          </InfoItemWrapper>
        )}
      </InfoContainer>
    </InfoSection>
  </StyledCard>
);

MealDescription.propTypes = {
  ingredientsCount: PropTypes.number,
  cookingTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  preparationTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  imageSrc: PropTypes.string.isRequired,
};

MealDescription.defaultProps = {
  description: '',
  ingredientsCount: 0,
  cookingTime: 0,
  preparationTime: 0,
};

export default MealDescription;
