import styled, { css } from 'styled-components';
import { sansSerif } from '../../../styles/text';

const Option = styled.button`
  ${sansSerif.headlineFontStyles}
  background: rgba(255, 255, 255, 0.4);
  border-radius: 6px;
  display: grid;
  place-items: center;
  padding: 0 14px;
  color: ${({ theme }) => theme.colors.base.primary};
  font-size: 20px;
  line-height: 22px;
  height: 34px;
  margin-right: 8px;
  transition: all .3s;
  white-space: nowrap;

  &:last-of-type {
    margin-right: 0;
  }

  ${({ $isActive }) => $isActive && css`
    background-color: ${({ theme }) => theme.colors.base.secondaryVariant};
    color: ${({ theme }) => theme.colors.system.alpha};
  `}
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  background-color: ${({ theme }) => theme.colors.base.primary};
  padding: 16px;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export {
  Container,
  Option,
};
