import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as ClockIcon } from '../../../../assets/icons/health-activity/clock.svg';
import { ReactComponent as CaloriesIcon } from '../../../../assets/icons/health-activity/calories.svg';
import AssignmentDayType from '../../viewModel/assignmentType';
import { formatDuration } from '../../../../utils/time';
import WorkoutPreviewImage from '../../../../components/WorkoutPreviewImage';
import DynamicTitle from '../DynamicTitle';
import {
  WorkoutCardLoading,
  DetailsContainer,
  StyledContainer,
  WorkoutDataContainer,
  WorkoutDataItem,
} from './styles';

const WorkoutCard = ({
  title,
  hasPreview,
  previewImage,
  onClick,
  assignmentDayType,
  isActive,
  activeCalories,
  duration,
  week,
}) => {
  const renderPreviewImage = useCallback(() => (
    previewImage
      ? <WorkoutPreviewImage previewImage={previewImage} />
      : <WorkoutCardLoading />
  ), [
    previewImage,
  ]);

  const renderStats = useCallback(() => {
    const stats = [];

    if (duration > 0) {
      stats.push({
        id: 'duration',
        value: formatDuration(duration),
        Icon: ClockIcon,
      });
    }

    const activeEnergyBurned = Math.floor(activeCalories);
    if (activeEnergyBurned > 0) {
      stats.push({
        id: 'calories',
        value: `${activeEnergyBurned} Kcal`,
        Icon: CaloriesIcon,
      });
    }

    if (week) {
      stats.push({
        id: 'week',
        value: week,
      });
    }

    if (stats.length > 0) {
      return (
        <WorkoutDataContainer>
          {stats.map(({
            id,
            value,
            Icon,
          }) => (
            <WorkoutDataItem key={id}>
              {Icon && <Icon />}
              {value}
            </WorkoutDataItem>
          ))}
        </WorkoutDataContainer>
      );
    }

    return null;
  }, [
    activeCalories,
    duration,
    week,
  ]);

  return (
    <StyledContainer
      isActive={isActive}
      dayType={assignmentDayType}
      onClick={onClick}
    >
      <DynamicTitle
        isActive={isActive}
      >
        {title}
      </DynamicTitle>
      {renderStats()}
      {isActive && hasPreview && (
        <DetailsContainer>
          {renderPreviewImage()}
        </DetailsContainer>
      )}
    </StyledContainer>
  );
};

WorkoutCard.propTypes = {
  title: PropTypes.string.isRequired,
  hasPreview: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  previewImage: PropTypes.string,
  assignmentDayType: PropTypes.oneOf(Object.values(AssignmentDayType)).isRequired,
  isActive: PropTypes.bool.isRequired,
  activeCalories: PropTypes.number,
  duration: PropTypes.number,
  week: PropTypes.string,
};

WorkoutCard.defaultProps = {
  onClick: () => {},
  previewImage: null,
  activeCalories: null,
  duration: null,
  week: '',
};

export default WorkoutCard;
