import React from 'react';
import PropTypes from 'prop-types';
import { withChat } from '@fitmoola/system2-chat';
import { compose } from 'recompose';

import { withChatContextProvider } from '../../context';
import withChatProps from '../withChatProps';
import ChatPage from '../../pages';

/**
 * Adds chat support
 *
 * @param {Object} Component - The child component to be rendered once after adding the chat functionality
 * @return {ElementType} - Anonymous React element type that adds the action for opening the chat.
 */
const Chat = ({ children }) => (
  <>
    <ChatPage />
    {children}
  </>
);

Chat.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  withChatProps,
  withChat,
  withChatContextProvider,
)(Chat);
