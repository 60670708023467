import React from 'react';
import PropTypes from 'prop-types';
import { StyledCameraViewportMessage } from './styles';

const CameraViewportMessage = ({
  children,
  color,
  borderColor,
  showBorder,
}) => (
  <StyledCameraViewportMessage
    color={color}
    borderColor={borderColor}
    showBorder={showBorder}
  >
    {children}
  </StyledCameraViewportMessage>
);

CameraViewportMessage.propTypes = {
  children: PropTypes.node.isRequired,
  borderColor: PropTypes.string,
  color: PropTypes.string,
  showBorder: PropTypes.bool,
};

CameraViewportMessage.defaultProps = {
  color: '',
  borderColor: '',
  showBorder: false,
};

export default CameraViewportMessage;
