import React from 'react';
import { observer } from 'mobx-react';

import useFirestoreLocalCache from './useFirestoreLocalCache';

const withFirestoreLocalCache = (Component) => observer((props) => {
  useFirestoreLocalCache();

  return <Component {...props} />;
});

export default withFirestoreLocalCache;
