import React, {
  useEffect,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useRouteMatch } from 'react-router-dom';
import { compose } from 'recompose';

import User from '../../models/User';
import useComponentLoadingTime from '../../hooks/useComponentLoadingTime';
import UserConfig from '../../models/UserConfig';
import { setUserTraits } from '../../utils/logger';
import UserContext from './UserContext';

const UserContextProvider = ({
  children,
}) => {
  const { params: { userId } } = useRouteMatch();

  const [userDoc, setUserDoc] = useState(null);
  const [userConfigDoc, setUserConfigDoc] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const { startLoading, finishLoading } = useComponentLoadingTime('userContext');

  useEffect(() => {
    let shouldUpdate = true;

    const init = async () => {
      startLoading();
      const userDocument = new User(`/user/${userId}`);
      const userConfigDocument = new UserConfig(`/config/${userId}`);
      await Promise.all([
        userDocument.init(),
        userConfigDocument.init(),
      ]);
      if (shouldUpdate) {
        setUserDoc(userDocument);
        setUserConfigDoc(userConfigDocument);
        setIsReady(true);
        finishLoading();
      }
    };
    init();
    return () => {
      /* This cleanup function is used to prevent updating the state
      when the component is unmounted */
      shouldUpdate = false;
    };
  }, [
    userId,
    startLoading,
    finishLoading,
  ]);

  useEffect(() => {
    if (userDoc) {
      // Add user status to user properties.
      const {
        isActive,
        subscriptionStatus,
      } = userDoc;
      setUserTraits({
        isActive,
        subscriptionStatus,
      });
    }
  }, [userDoc]);

  const context = useMemo(() => ({
    userId,
    userDoc,
    userConfigDoc,
    isReady,
  }), [
    userId,
    userDoc,
    userConfigDoc,
    isReady,
  ]);

  return (
    <UserContext.Provider value={context}>
      {children}
    </UserContext.Provider>
  );
};

UserContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(UserContextProvider);
