import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../components/Button';
import SelfieVideo, { SelfieVideoContext } from '../../../../components/SelfieVideo';
import TipsSection from './TipsSection';
import config from './config';
import {
  Container,
  VideoContainer,
  ControlPanel,
  ActionsContainer,
  GlobalStyles,
} from './styles';

const TrackerPlayground = ({
  activityType,
  count,
  isActivityCompleted,
  reasonText,
  tipText,
  repetitionsGoal,
  isPlaying,
  configByType,
  handleActivityTypeChange,
  handleRepetitionsGoalChange,
  handleStartStop,
}) => {
  const { setIsDebugPlaying } = useContext(SelfieVideoContext);

  useEffect(() => {
    setIsDebugPlaying(isPlaying);
  }, [
    isPlaying,
    setIsDebugPlaying,
  ]);

  return (
    <>
      <GlobalStyles />
      <Container>
        <VideoContainer>
          <SelfieVideo
            showGameplay
          />
        </VideoContainer>
        <ControlPanel>
          {!!configByType.enableGoal && (
            <div>{`Count: ${count}`}</div>
          )}
          {isActivityCompleted && (
            <div>Finished!</div>
          )}
          <div>{`Reasons: ${reasonText}`}</div>
          <TipsSection
            text={tipText}
          />
          {!!configByType.enableGoal && (
            <div>
              <span>Repetitions Goal</span>
              <input type="number" min="1" step="1" value={repetitionsGoal} onChange={handleRepetitionsGoalChange} />
            </div>
          )}
          <ActionsContainer>
            {activityType && (
              <select
                value={activityType}
                onChange={handleActivityTypeChange}
              >
                {config.supportedTypes.map((type) => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
            )}
            <Button
              full={false}
              variant="secondary"
              onClick={handleStartStop}
            >
              {isPlaying ? 'Stop' : 'Start'}
            </Button>
          </ActionsContainer>
        </ControlPanel>
      </Container>
    </>
  );
};

TrackerPlayground.propTypes = {
  activityType: PropTypes.string,
  count: PropTypes.number,
  isActivityCompleted: PropTypes.bool.isRequired,
  reasonText: PropTypes.string.isRequired,
  repetitionsGoal: PropTypes.number.isRequired,
  isPlaying: PropTypes.bool.isRequired,
  configByType: PropTypes.object.isRequired,
  handleActivityTypeChange: PropTypes.func.isRequired,
  handleRepetitionsGoalChange: PropTypes.func.isRequired,
  handleStartStop: PropTypes.func.isRequired,
  tipText: PropTypes.string,
};

TrackerPlayground.defaultProps = {
  activityType: null,
  tipText: null,
  count: 0,
};

export default TrackerPlayground;
