import React, {
  useEffect,
  useContext,
  useState,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { useIonToast } from '@ionic/react';

import { withLayout, HeaderContext } from '../../../components/Layout';
import {
  Container,
} from '../styles';
import { changeNativeAppIcon, resetNativeAppIcon } from '../../../services/appCustomization';
import AppCustomizationContext from '../../../context/AppCustomizationContext';
import { CardTitle } from '../../../components/Card';
import { MenuItemContainer } from '../components/styles';
import texts from './texts.json';
import {
  StyledButton,
  StyledCard,
  StyledImage,
} from './styles';

const toastDurationMs = 3000;

const ManageAppIcon = () => {
  const { setTitle } = useContext(HeaderContext);
  const [isUpdating, setIsUpdating] = useState(false);

  const {
    appCustomizationDoc: { appIconName },
  } = useContext(AppCustomizationContext);

  const [showToast] = useIonToast();

  useEffect(() => {
    setTitle(texts.manageAppIcon);
  }, [
    setTitle,
  ]);

  const resetAppIcon = async () => {
    setIsUpdating(true);
    await resetNativeAppIcon();
    setIsUpdating(false);
    showToast({
      message: texts.appIconResetSuccessfully,
      duration: toastDurationMs,
      color: 'success',
    });
  };

  const changeAppIcon = async () => {
    setIsUpdating(true);
    await changeNativeAppIcon(appIconName);
    setIsUpdating(false);
    showToast({
      message: texts.appIconChangedSuccessfully,
      duration: toastDurationMs,
      color: 'success',
    });
  };

  if (!appIconName) {
    return (
      <Container>
        <MenuItemContainer>{texts.noCustomIconFound}</MenuItemContainer>
      </Container>
    );
  }

  return (
    <Container>
      <StyledCard>
        <CardTitle>{texts.coachIcon}</CardTitle>
        <StyledImage
          src={`/assets/${appIconName}.png`}
          alt="iOS App Icon"
        />
        <StyledButton
          onClick={changeAppIcon}
          disabled={isUpdating}
        >
          {texts.setAppIcon}
        </StyledButton>
      </StyledCard>
      <StyledButton
        onClick={resetAppIcon}
        disabled={isUpdating}
      >
        {texts.resetAppIcon}
      </StyledButton>
    </Container>
  );
};

export default compose(
  withLayout,
  observer,
)(ManageAppIcon);
