import React, {
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import ExerciseOverrideSelectorContext, {
  withExerciseOverrideSelectorContextReady,
} from './context/ExerciseOverrideSelectorContext';
import ExerciseFilterContent from './ExercisesFilterContent';
import SearchExercises from './SearchExercises';
import {
  Container,
} from './styles';

const ExerciseOverrideSelector = ({
  onCancel,
  className,
}) => {
  const {
    isSearchEnabled,
  } = useContext(ExerciseOverrideSelectorContext);

  return (
    <Container className={className}>
      {isSearchEnabled
        ? <SearchExercises />
        : <ExerciseFilterContent onCancel={onCancel} />}
    </Container>
  );
};

ExerciseOverrideSelector.propTypes = {
  onCancel: PropTypes.func.isRequired,
  className: PropTypes.string,
};

ExerciseOverrideSelector.defaultProps = {
  className: '',
};

export default compose(
  withExerciseOverrideSelectorContextReady,
  observer,
)(ExerciseOverrideSelector);
