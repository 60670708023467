import styled, { css } from 'styled-components';

import colors from '../../../../styles/baseColors';
import { fontFamily } from '../../../../styles/text';

const BadgeContainer = styled.div`
  background-color: ${colors.system.success};
  color: ${colors.system.base};
  padding: 0 8px;
  border-radius: 50px;
  ${({ $isExpired }) => $isExpired && css`
    background-color: ${colors.system.error};
    color: ${colors.system.alpha};
  `}
`;

const BadgeText = styled.p`
  font-family: ${fontFamily.sansSerif};
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
`;

export {
  BadgeContainer,
  BadgeText,
};
