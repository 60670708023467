import React, {
  useCallback,
  useContext,
} from 'react';
import { useChannelStateContext } from 'stream-chat-react';
import PropTypes from 'prop-types';

import ActionItem from '../../../components/Layout/Header/ActionItem';
import BurgerIcon from '../../../assets/burger-icon.svg';

import ChatContext from '../../context';
import useChannel from '../../hooks/useChannel';
import {
  SingleChannelNameContainer,
  MultipleChannelNameContainer,
  ChatListBurgerContainer,
  CloseButtonComponent,
  HeaderContainer,
} from './styles';
import texts from './texts.json';

const MessagingChannelHeader = ({
  emptyChat,
}) => {
  const { channel, loading } = useChannelStateContext();
  const { getChannelName } = useChannel(channel);

  const {
    toggleChatModal,
    isMultiChannelView,
    totalUnreadCount,
    toggleChatMenu,
  } = useContext(ChatContext);

  const isLoading = typeof loading === 'boolean' ? loading : true;

  const getTitle = useCallback(() => {
    if (emptyChat) {
      return texts.chat;
    }
    return isLoading ? texts.loadingChat : getChannelName();
  }, [
    emptyChat,
    isLoading,
    getChannelName,
  ]);

  const getChannelHeader = useCallback(() => {
    if (isMultiChannelView) {
      return (
        <>
          <ChatListBurgerContainer>
            {(!isLoading || emptyChat) && (
              <ActionItem
                iconSrc={BurgerIcon}
                altText="Chat Menu"
                onClick={toggleChatMenu}
                maxDigits={2}
                notificationsNumber={totalUnreadCount}
              />
            )}
          </ChatListBurgerContainer>
          <MultipleChannelNameContainer>
            {getTitle()}
          </MultipleChannelNameContainer>
        </>
      );
    }
    return <SingleChannelNameContainer>{texts.chatWithCoach}</SingleChannelNameContainer>;
  }, [
    totalUnreadCount,
    isLoading,
    isMultiChannelView,
    emptyChat,
    getTitle,
    toggleChatMenu,
  ]);

  return (
    <HeaderContainer>
      {getChannelHeader()}
      <CloseButtonComponent onClick={toggleChatModal} />
    </HeaderContainer>
  );
};

MessagingChannelHeader.propTypes = {
  emptyChat: PropTypes.bool,
};

MessagingChannelHeader.defaultProps = {
  emptyChat: false,
};

export default MessagingChannelHeader;
