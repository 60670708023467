import React from 'react';
import PropTypes from 'prop-types';

import { config } from '../../../../../../../components/ActivitySideConfig';
import {
  ActivitySideMessageContainer,
  Message,
} from './styles';

const ActivitySideMessage = ({
  activitySide,
}) => {
  const {
    text,
    icons: {
      big: Icon,
    },
  } = config[activitySide];

  return (
    <ActivitySideMessageContainer>
      <Icon />
      <Message>{text}</Message>
    </ActivitySideMessageContainer>
  );
};

ActivitySideMessage.propTypes = {
  activitySide: PropTypes.string.isRequired,
};

export default ActivitySideMessage;
