import styled from 'styled-components';

const StyledDraggablePanel = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  background-color:rgba(255, 255, 255, 0.85);
  color: black;
  position: fixed;
  left: 0;
  top: 0;
  width: 360px;
  z-index: 100;
  cursor: grab;
  user-select: none;

  &:active {
    cursor: grabbing;
  }
`;

export default StyledDraggablePanel;
