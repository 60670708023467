import styled from 'styled-components';

import Toggle from '../../../../components/Toggle';
import Select from '../../Select';

const StyledToggle = styled(Toggle)`
  width: fit-content;
  padding: 2px;
  align-self: flex-end;
  z-index: 0;

  ion-toggle {
    width: 40px;
  }
`;

const StyledTagsSelect = styled(Select)`
  opacity: 0;
  z-index: -1;
  margin-top: -40px;
  padding-bottom: 10px;
  transition: all 0.2s ease-in-out;

  &.tagsTransition {
    &-enter {
      opacity: 0;
      margin-top: -40px;

      &-active,
      &-done {
        opacity: 1;
        margin-top: 0;
        z-index: unset;
      }
    }

    &-exit {
      opacity: 1;
      margin-top: 0;
      z-index: unset;

      &-active,
      &-done {
        opacity: 0;
        margin-top: -40px;
        z-index: -1;
      }
    }
  }
`;

export {
  StyledToggle,
  StyledTagsSelect,
};
