import styled from 'styled-components';

import { headlineStyles, body1 } from '../../styles/text';

import Card from '../Card';

const StyledCard = styled(Card)`
  padding: 16px;
  margin-bottom: 16px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  > svg {
    flex: 1;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 4;
`;

const Title = styled.div`
  ${headlineStyles}
  font-size: 27px;
  line-height: 36.72px;
  margin-bottom: 10px;
`;

const Body = styled.div`
  ${body1}
`;

export {
  StyledCard,
  Container,
  TextContainer,
  Title,
  Body,
};
