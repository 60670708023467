import styled from 'styled-components';

import { ReactComponent as CronometerIcon } from '../../../../assets/icons/cronometer-icon.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  padding: 15px;
`;

const StyledCronometerIcon = styled(CronometerIcon)`
  height: auto;
  width: 80px;
`;

export {
  Container,
  StyledCronometerIcon,
};
