import {
  useState,
  useCallback,
  useMemo,
} from 'react';
import { useRouteMatch } from 'react-router-dom';

import { IonRouteAction } from '../utils/router';
import useNavContext from './useNavContext';

/**
 * This React Hook allows navigation to the user home with minimum dependencies
 * from the client.
 * The goal to use this Hook is to prevent using React Router redirects to "/",
 * due to re-creation of Context Providers.
 */
const useHomeNavigation = () => {
  const {
    navigate,
    routeInfo: {
      search,
    },
  } = useNavContext();
  const { params: { userId } } = useRouteMatch();

  const [pathname] = useState(`/u/${userId}/home`);

  const navigateBackHome = useCallback((routeAction = IonRouteAction.POP) => (
    navigate(pathname, 'back', routeAction, undefined, { search })
  ), [
    navigate,
    pathname,
    search,
  ]);

  return useMemo(() => ({
    navigateBackHome,
    pathname,
  }), [
    navigateBackHome,
    pathname,
  ]);
};

export default useHomeNavigation;
