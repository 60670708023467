const CLEAR_CACHE_KEY = 'shouldClearFirestoreCache';
const CACHE_CLEARED_LAST_TIME = 'firestoreCacheClearedLastTime';

/**
 * Check if the Firestore local cache should be cleaned up.
 *
 * @returns {boolean}
 */
const shouldClearCache = () => localStorage.getItem(CLEAR_CACHE_KEY) === 'true';

/**
 * Update the CLEAR_CACHE_KEY value.
 *
 * @param {boolean} shouldClear
 */
const updateClearCache = (shouldClear) => {
  localStorage.setItem(CLEAR_CACHE_KEY, shouldClear);
};

/**
 * Get the last timestamp value at which the Firestore cache was cleared.
 */
const lastTimeFirestoreCacheCleared = () => {
  const time = localStorage.getItem(CACHE_CLEARED_LAST_TIME);

  return time ? parseInt(time, 10) : null;
};

/**
 * Update the CACHE_CLEARED_LAST_TIME value.
 *
 * @param {number} value Last time firestore cache was cleared timestamp.
 */
const updateLastTimeFirestoreCacheCleared = (value) => {
  localStorage.setItem(CACHE_CLEARED_LAST_TIME, value);
};

export {
  shouldClearCache,
  updateClearCache,
  lastTimeFirestoreCacheCleared,
  updateLastTimeFirestoreCacheCleared,
};
