import React from 'react';
import { useField, useFormikContext } from 'formik';
import { PropTypes } from 'prop-types';

import { DateFormat } from '../../utils/date';
import Container, { ErrorMessage } from '../FormikInput/styles';
import CalendarSlider from '../CalendarSlider';

const FormikDatePicker = ({
  name,
  className,
  displayFormat,
  onChange,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField({ ...props, name });

  const handleChange = (event) => {
    setFieldValue(field.name, event.detail.value);
    onChange(event);
  };

  return (
    <Container className={className}>
      <CalendarSlider
        currentSelectedDateTime={field.value}
        {...props}
        onChange={handleChange}
      />
      <ErrorMessage name={name} component="div" />
    </Container>
  );
};

FormikDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  displayFormat: PropTypes.string,
  onChange: PropTypes.func,
};

FormikDatePicker.defaultProps = {
  className: 'formikDateInputElement',
  displayFormat: DateFormat.DATE_FORMAT_SLASH,
  onChange: () => { },
};

export default FormikDatePicker;
