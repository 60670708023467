import { Collection } from 'firestorter';
import CollectionName from '../utils/collections';
import BaseDocument from './BaseDocument';

export const TERRA_PROVIDERS = {
  CRONOMETER: 'CRONOMETER',
};

class UserTerraIntegration extends BaseDocument {
  get isActive() {
    return this.data.active;
  }

  get provider() {
    return this.data.provider;
  }

  get terraUserId() {
    return this.data.terraUserId;
  }

  static async getUserActiveConnections(userId, provider) {
    const collection = new Collection(() => `${CollectionName.INTEGRATIONS}/terra/user`, {
      createDocument: (src, opts) => new UserTerraIntegration(src, opts),
      query: (ref) => {
        const query = ref
          .where('user', '==', userId)
          .where('active', '==', true)
          .where('provider', '==', provider)
          .orderBy('updatedAt', 'desc');
        return query;
      },
    });
    await collection.fetch();
    return collection;
  }
}

export default UserTerraIntegration;
