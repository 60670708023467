import React, { useContext, useEffect } from 'react';
import { SearchInput } from 'stream-chat-react';
import PropTypes from 'prop-types';

import ChatContext from '../../../context';
import { SearchIcon, SearchInputContainer } from './styles';

const SearchBox = ({
  query,
  ...props
}) => {
  const { onSearch, searchQuery } = useContext(ChatContext);

  useEffect(() => {
    onSearch(query);
  }, [query, onSearch]);

  return (
    <SearchInputContainer>
      <SearchIcon />
      <SearchInput
        query={searchQuery}
        {...props}
      />
    </SearchInputContainer>
  );
};

SearchBox.propTypes = {
  query: PropTypes.string.isRequired,
};

export default SearchBox;
