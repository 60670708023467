import { autorun } from 'mobx';
import {
  useCallback, useContext, useState, useEffect,
} from 'react';
import InAppBrowserContext from '../context/InAppBrowserContext';
import PostPaymentFormContext from '../context/PostPaymentFormContext/PostPaymentFormContext';
import config from '../config';
import useCurrentLoggedInUser from './useCurrentLoggedInUser';

const { appUrl } = config;

const usePostPaymentForm = () => {
  const { open, close } = useContext(InAppBrowserContext);
  const { postPaymentFormURL, answers } = useContext(PostPaymentFormContext);
  const { isCurrentLoggedInUserInPath } = useCurrentLoggedInUser();

  const [browserOpened, setBrowserOpened] = useState(false);

  useEffect(() => {
    // close the inapp browser when user submits the post-payment form
    const disposer = autorun(() => {
      if (answers.hasDocs && browserOpened) {
        close();
        setBrowserOpened(false);
      }
    });
    return disposer;
  }, [browserOpened, close, answers.hasDocs]);

  // show the postpayment form if product has a post payment form link and user has not completed one before
  const checkAndShowPostPaymentForm = useCallback(async () => {
    if (isCurrentLoggedInUserInPath && postPaymentFormURL && !answers.hasDocs && !browserOpened) {
      // close inappbrowser on redirect
      const redirectEventListener = {
        eventName: 'loadstart',
        callback: ({ url }) => {
          if (url.startsWith(appUrl)) {
            close();
            setBrowserOpened(false);
          }
        },
      };
      open(postPaymentFormURL, undefined, [redirectEventListener]);
      setBrowserOpened(true);
    }
  }, [
    answers.hasDocs,
    postPaymentFormURL,
    open,
    close,
    browserOpened,
    isCurrentLoggedInUserInPath,
  ]);

  return {
    checkAndShowPostPaymentForm,
  };
};

export default usePostPaymentForm;
