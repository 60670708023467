import ActionTypes from '../gameplayActions/actionTypes';
import { TrackerTypes, TrackersByType } from './trackers';
import { advanceNextAction } from './activities/advanceNextAction';

/**
 * Trackers mapping by workout Activity and/or Actions/Gestures.
 *
 * NOTE: For simplicity, both types of activities to track with the tracking system
 * (workout Activities and Actions/Gestures) are kept in this mapping, but as
 * we keep adding more tracking activities we could split them up.
 *
 * @type {Object}
 */
const trackersMapping = {
  /* Example of workout Activity:
    [ActivityTypes.PLANKING]: {
      type: TrackerTypes.STATIC,
      activity: forearmPlank,
      name: 'ForearmPlank',
    },
  */
  // Actions/gestures setup
  [ActionTypes.HAND_ADVANCE_NEXT]: {
    type: TrackerTypes.STATIC,
    activity: advanceNextAction,
    options: {
      smootheningInterval: 100,
    },
  },
};

/**
 * Creates an instance of a tracker based on the activity type.
 *
 * @param {string} trackedActivityType One of workout ActivityTypes or ActionTypes.
 * @returns {Object} Tracker instance.
 */
const createTrackerByActivityType = (trackedActivityType) => {
  const trackedActivityConfig = trackersMapping[trackedActivityType];

  if (trackedActivityConfig) {
    const { type, activity, options } = trackedActivityConfig;
    const Tracker = TrackersByType[type];

    return new Tracker(activity, options);
  }

  return null;
};

export default createTrackerByActivityType;
