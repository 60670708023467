import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  BarChart,
  XAxis,
  YAxis,
  ReferenceLine,
  Bar,
  ResponsiveContainer,
} from 'recharts';
import format from 'string-template';

import useAppTheme from '../../../../../hooks/useAppTheme';
import UserNutritionInfoContext from '../../../../../context/UserNutritionInfoContext';
import MealPlanContext from '../../../../../context/MealPlanContext';
import CheckInContext from '../../../../../context/CheckInContext';
import CoachInfoContext from '../../../../../context/CoachInfoContext';

import { StyledCollapsiblePanel } from '../WorkoutStat/styles';
import {
  Container,
  ChartContainer,
  TitleText,
  UnitLabel,
  ValueText,
  StatContainer,
  Stat,
  StatSub,
  NutritionLogMealContainer,
  NutritionLogMealTitle,
  NutritionLogMealMacro,
  NutritionLogMealContent,
  NutritionLogMeals,
} from './styles';
import texts from './texts.json';

const CalorieIntakeChart = ({
  userNutritionDocs,
  updateDocumentFields,
}) => {
  const { colors } = useAppTheme();
  const {
    userNutritionMacroGoalsDoc,
  } = useContext(UserNutritionInfoContext);
  const { mealPlanAssignmentDoc } = useContext(MealPlanContext);
  const { checkInDay } = useContext(CheckInContext);
  const {
    coachInfo: {
      mealPlanConfig: {
        macrosInfoDisabled,
      } = {},
    } = {},
  } = useContext(CoachInfoContext);
  const [isReady, setIsReady] = useState(false);

  const {
    totalDailyCalories = 0,
    macroAverages: {
      percentages: userMacroPercentages = {},
    } = {},
  } = userNutritionMacroGoalsDoc || {};
  const {
    macroAverages: {
      percentages = {},
    } = {},
  } = mealPlanAssignmentDoc || {};

  const {
    calories = 0,
    dailyCalorieIntake = {},
    totalMacros = {},
  } = useMemo(() => {
    let calorieCount = 0;
    const totalMacrosCount = {
      protein: 0,
      carbs: 0,
      fat: 0,
    };

    const dailyCalorieIntakeAnalysis = userNutritionDocs.reduce((prev, doc) => {
      const {
        macros,
        startTime,
        meals = [],
      } = doc;
      const dayName = moment.utc(startTime.toDate()).format('dddd');
      const calorieIntakeObject = { ...prev };
      if (!Object.keys(prev).includes(dayName)) {
        calorieIntakeObject[dayName] = {
          calorieIntake: macros.calories,
        };
        calorieIntakeObject[dayName].meals = meals;
        calorieIntakeObject[dayName].macros = macros;
        calorieIntakeObject[dayName].day = moment.utc(startTime.toDate()).format('ll');
      } else {
        calorieIntakeObject[dayName].meals.join(meals);
        calorieIntakeObject[dayName].calorieIntake += macros.calories;
      }
      calorieCount += macros.calories;
      totalMacrosCount.protein += macros.protein;
      totalMacrosCount.carbs += macros.carbs;
      totalMacrosCount.fat += macros.fat;
      return calorieIntakeObject;
    }, {});

    return {
      dailyCalorieIntake: dailyCalorieIntakeAnalysis,
      calories: calorieCount,
      totalMacros: totalMacrosCount,
    };
  }, [userNutritionDocs]);

  // 'empty' bars are used to show a grey bar for days where no data was recorded
  const dailyCalorieData = useMemo(() => {
    const weekdays = moment.weekdays();
    // order the weekdays from check-in day to check-in day
    weekdays.push(...weekdays.splice(0, (checkInDay + 1)));
    return weekdays.map((dayName) => {
      const isDataRecorded = Object.keys(dailyCalorieIntake).includes(dayName);
      const dayData = dailyCalorieIntake[dayName];
      const dayCalories = dayData ? dayData.calorieIntake : 0;
      const fullName = dayName?.toUpperCase();
      return {
        name: fullName.substring(0, 1),
        fullName,
        value: dayCalories?.toFixed(0),
        empty: (!isDataRecorded ? (totalDailyCalories / 10) : 0),
        meals: dayData?.meals || [],
        macros: dayData?.macros || {},
      };
    });
  }, [
    dailyCalorieIntake,
    totalDailyCalories,
    checkInDay,
  ]);

  const calorieAvg = (calories / userNutritionDocs.length || 0);
  const macrosAvg = useMemo(() => ({
    protein: (totalMacros.protein / userNutritionDocs.length || 0),
    carbs: (totalMacros.carbs / userNutritionDocs.length || 0),
    fat: (totalMacros.fat / userNutritionDocs.length || 0),
  }), [
    totalMacros,
    userNutritionDocs,
  ]);

  useEffect(() => {
    if (!isReady) {
      const mealsSummary = {
        // calorie goals
        mealPlan: {
          calories: (totalDailyCalories || 0),
          macrosPercentages: {
            protein: (userMacroPercentages.protein || percentages.protein || 0),
            carbs: (userMacroPercentages.carbs || percentages.carbs || 0),
            fat: (userMacroPercentages.fat || percentages.fat || 0),
          },
        },
      };
      mealsSummary.dailyCalorieIntake = dailyCalorieIntake;
      if (userNutritionDocs.length) {
        mealsSummary.calories = calorieAvg;
        mealsSummary.macros = macrosAvg;
      }
      updateDocumentFields({
        mealsSummary,
        userMealsSubmitted: !!userNutritionDocs.length,
      });
      setIsReady(true);
    }
  }, [
    isReady,
    dailyCalorieIntake,
    totalDailyCalories,
    calorieAvg,
    macrosAvg,
    percentages,
    userMacroPercentages,
    updateDocumentFields,
    mealPlanAssignmentDoc,
    userNutritionDocs,
  ]);

  return (
    <Container>
      <ChartContainer>
        <StatContainer>
          <Stat>
            <TitleText>{macrosInfoDisabled ? texts.dailyAverage : texts.dailyGoal}</TitleText>
            <ValueText>{macrosInfoDisabled ? calorieAvg.toFixed(0) : totalDailyCalories}</ValueText>
            <UnitLabel>{texts.calories}</UnitLabel>
          </Stat>
          {!macrosInfoDisabled && (
            <StatSub>
              {format(texts.dailyAverage, { calories: calorieAvg.toFixed(0) })}
            </StatSub>
          )}
        </StatContainer>
        <ResponsiveContainer width="100%" height="100%" aspect={2.5}>
          <BarChart
            data={dailyCalorieData}
            stackOffset="sign"
            width="100%"
          >
            <XAxis
              fontSize={10}
              axisLine={false}
              tickLine={false}
              dataKey="name"
            />
            <YAxis
              width={40}
              fontSize={10}
              axisLine={false}
              tickLine={false}
              tickSize={10}
              domain={(calories === 0 || !macrosInfoDisabled) ? [0, totalDailyCalories] : [0, 'dataMax']}
            />
            {!macrosInfoDisabled && (
              <ReferenceLine
                y={totalDailyCalories}
                stroke={colors.system.error}
                strokeDasharray="3 3"
              />
            )}
            <Bar
              dataKey="value"
              stackId="stack"
              fill={colors.base.secondary}
              barSize={10}
              radius={10}
            />
            <Bar
              dataKey="empty"
              stackId="stack"
              fill={colors.system.gamma1}
              barSize={10}
              radius={10}
            />
          </BarChart>
        </ResponsiveContainer>
      </ChartContainer>
      <StyledCollapsiblePanel
        title={texts.weekMealsIntake}
      >
        {dailyCalorieData.map(({
          fullName,
          meals,
          macros,
        }) => (
          <StyledCollapsiblePanel
            title={fullName}
            subTitle={!meals?.length ? texts.noMealsLogged : format(texts.nutritionLogMacroDescription, {
              calories: Math.round(macros.calories || 0),
              protein: Math.round(macros.protein || 0),
              carbs: Math.round(macros.carbs || 0),
              fat: Math.round(macros.fat || 0),
            })}
            key={fullName}
          >
            <NutritionLogMeals>
              {meals.map((meal) => (
                <NutritionLogMealContainer>
                  <NutritionLogMealContent>
                    <NutritionLogMealTitle>
                      {meal.name}
                    </NutritionLogMealTitle>
                    <NutritionLogMealMacro>
                      <NutritionLogMealMacro highlight>
                        {format(texts.nutritionLogMealCalories, {
                          calories: Math.round(meal.macros.kcal),
                        })}
                      </NutritionLogMealMacro>
                      <NutritionLogMealMacro>
                        {format(texts.nutritionLogMealMacroDescription, {
                          protein: Math.round(meal.macros.protein),
                          carbs: Math.round(meal.macros.total_carbs),
                          fat: Math.round(meal.macros.fat),
                        })}
                      </NutritionLogMealMacro>
                    </NutritionLogMealMacro>
                  </NutritionLogMealContent>
                </NutritionLogMealContainer>
              ))}
            </NutritionLogMeals>

          </StyledCollapsiblePanel>
        ))}
      </StyledCollapsiblePanel>
    </Container>
  );
};

CalorieIntakeChart.propTypes = {
  userNutritionDocs: PropTypes.arrayOf(PropTypes.object),
  updateDocumentFields: PropTypes.func.isRequired,
};

CalorieIntakeChart.defaultProps = {
  userNutritionDocs: [],
};

export default CalorieIntakeChart;
