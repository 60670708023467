import ActionTypes from '../../gameplayActions/actionTypes';
import actionsConfig from '../../gameplayActions/actionsConfig';
import { wristInRightArea } from '../detectors/poses/wrist';
import { openHandInRightArea } from '../detectors/poses/hands';

const { boundingBoxConfig } = actionsConfig[ActionTypes.HAND_ADVANCE_NEXT];

const advanceNextAction = {
  step: {
    name: 'OpenHandInRightArea',
    validatorFn: openHandInRightArea({
      boundingBoxConfig,
    }),
    calibration: [],
  },
  name: 'HandAdvanceNextAction',
};

const wristAdvanceNextAction = {
  step: {
    name: 'WristInRightArea',
    validatorFn: wristInRightArea({
      boundingBoxConfig,
    }),
    calibration: [],
  },
  name: 'WristAdvanceNextAction',
};

export {
  advanceNextAction,
  wristAdvanceNextAction,
};
