import styled from 'styled-components';
import {
  IonSlides,
  IonSlide,
} from '@ionic/react';

const StyledContentSwitcher = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ContentWrapper = styled.div`
  flex: 1;
  max-height: 100%;
`;

const StyledSlides = styled(IonSlides)`
  height: 100%;
`;

const StyledSlide = styled(IonSlide)`
  display: block;
  background-color: ${({ theme }) => theme.colors.system.background}
`;

export {
  StyledContentSwitcher,
  ContentWrapper,
  StyledSlides,
  StyledSlide,
};
