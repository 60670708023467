import React from 'react';
import { PropTypes } from 'prop-types';
import { compose } from 'recompose';

import { DateFormat } from '../../utils/date';
import withPortal from '../withPortal';
import SlideDrawer from '../SlideDrawer';
import {
  StyledDatePicker,
  DatePickerContainer,
} from './styles';

const CalendarSlideDrawer = ({
  isOpen,
  onClose,
  currentSelectedDateTime,
  onChange,
  displayFormat,
  ...props
}) => (
  <SlideDrawer
    isOpen={isOpen}
    dragDownThreshold={0.5}
    onClose={onClose}
    fitContent
  >
    <DatePickerContainer>
      <StyledDatePicker
        displayFormat={displayFormat}
        value={currentSelectedDateTime}
        presentation="date"
        preferWheel
        onIonChange={onChange}
        {...props}
      />
    </DatePickerContainer>
  </SlideDrawer>
);

CalendarSlideDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  currentSelectedDateTime: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  displayFormat: PropTypes.string,
};

CalendarSlideDrawer.defaultProps = {
  displayFormat: DateFormat.LONG_MONTH_WITH_DAY_YEAR,
};

export default compose(
  withPortal('calendarSlideDrawer'),
)(CalendarSlideDrawer);
