import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import TrackedActivitySummaryDayCard from './TrackedActivitySummaryDayCard';

const TrackedActivitySummaryCardContainer = ({
  date,
  trackedActivitySummary,
}) => {
  const hasActivityData = !!(trackedActivitySummary && trackedActivitySummary.data);

  const activitySummary = useMemo(() => {
    if (!hasActivityData) {
      return null;
    }

    const energyBurned = hasActivityData ? parseInt(trackedActivitySummary.data.energyBurned, 10) : 0;
    const energyBurnedGoal = hasActivityData ? parseInt(trackedActivitySummary.data.energyBurnedGoal, 10) : 0;
    const exerciseTime = hasActivityData ? parseInt(trackedActivitySummary.data.exerciseTime, 10) : 0;
    const exerciseTimeGoal = hasActivityData ? parseInt(trackedActivitySummary.data.exerciseTimeGoal, 10) : 0;
    const standHours = hasActivityData ? parseInt(trackedActivitySummary.data.standHours, 10) : 0;
    const standHoursGoal = hasActivityData ? parseInt(trackedActivitySummary.data.standHoursGoal, 10) : 0;

    return {
      energyBurned,
      energyBurnedGoal,
      exerciseTime,
      exerciseTimeGoal,
      standHours,
      standHoursGoal,
    };
  }, [
    hasActivityData,
    trackedActivitySummary.data,
  ]);

  return (
    <TrackedActivitySummaryDayCard
      date={date}
      activitySummary={activitySummary}
    />
  );
};

TrackedActivitySummaryCardContainer.propTypes = {
  date: PropTypes.string.isRequired,
  trackedActivitySummary: PropTypes.object,
};

TrackedActivitySummaryCardContainer.defaultProps = {
  trackedActivitySummary: null,
};

export default TrackedActivitySummaryCardContainer;
