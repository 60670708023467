import React, {
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import Countdown from '../../../components/Countdown';
import CoachInfoContext from '../../../context/CoachInfoContext';
import {
  StyledContainer,
  CountdownContent,
  Title,
  Subtitle,
  CountdownBackground,
  StyledButton,
} from './styles';

import texts from './texts.json';

const StartAtCountdown = ({
  serviceStartAt,
  onCountdownEnds,
  isSkippable,
}) => {
  const { coachInfo } = useContext(CoachInfoContext);

  return (
    <StyledContainer>
      <CountdownBackground url={coachInfo.avatar} />
      <CountdownContent>
        <Title>{texts.signUpTitle}</Title>
        <Subtitle>{texts.signUpSubtitle}</Subtitle>
      </CountdownContent>
      <Countdown onCountdownEnds={onCountdownEnds} targetDate={serviceStartAt} />
      {isSkippable && <StyledButton onClick={onCountdownEnds}>{texts.skip}</StyledButton>}
    </StyledContainer>
  );
};

StartAtCountdown.propTypes = {
  onCountdownEnds: PropTypes.func,
  serviceStartAt: PropTypes.number.isRequired,
  isSkippable: PropTypes.bool,
};

StartAtCountdown.defaultProps = {
  onCountdownEnds: () => {},
  isSkippable: false,
};

export default StartAtCountdown;
