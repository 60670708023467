import React from 'react';

const initialState = {
  isReady: false,
  loadMoreLogs: () => {},
  measurementsLogs: [],
  isLoadingMore: false,
  isLastMeasurementLogInCurrentWeek: () => {},
  currentMeasurementLogDoc: null,
  currentSelectedDate: null,
  getDatesElapsedFromLastLog: () => {},
  isMeasurementSliderOpen: false,
  setIsMeasurementSliderOpen: () => {},
};

const MeasurementLogContext = React.createContext(initialState);

export default MeasurementLogContext;
export {
  initialState,
};
