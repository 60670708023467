import { IonDatetime } from '@ionic/react';
import styled from 'styled-components';

import { ReactComponent as Calendar } from '../../assets/icons/icon-calendar.svg';

const DateContainer = styled.div`
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

const StyledCalendarIcon = styled(Calendar)`
  align-self: flex-start;
`;

const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const StyledDatePicker = styled(IonDatetime)`
  margin: auto;
`;

export {
  DateContainer,
  IconContainer,
  StyledCalendarIcon,
  DatePickerContainer,
  StyledDatePicker,
};
