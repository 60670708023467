import styled from 'styled-components';

import { subtitle2 } from '../../styles/text';
import Card from '../Card';

const StyledCard = styled(Card)`
  ${subtitle2}
  display: flex;
  flex-direction: row;
  margin: 2px 0;
  padding: 24px;
  height: fit-content;
  overflow: visible;
`;

const ActionContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const ActionText = styled.span`
  display: flex;
  align-items: center;
  font-size: 27px;
  line-height: 1.36;
  color: ${({ theme }) => theme.colors.base.primary};
  font-family: 'Recoleta';
`;

export {
  StyledCard,
  ActionContainer,
  ActionText,
};
