import React from 'react';

import NotificationsPermissionsChecker from './NotificationsPermissionsChecker';

const withNotifications = (Component) => (props) => (
  <NotificationsPermissionsChecker>
    <Component {...props} />
  </NotificationsPermissionsChecker>
);

export default withNotifications;
