import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { IonAlert } from '@ionic/react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import format from 'string-template';

import WorkoutContext, { withWorkoutContextReady } from '../../../context/WorkoutContext';
import { ActivityTypes } from '../../models/BaseActivity';
import ActivityDefinition from '../../models/ActivityDefinition';
import { ActivityActionTypes } from '../../models/ActivityAction';
import { ExerciseContextProvider } from '../../context/ExerciseContext';
import ActivityEditor from '../ActivityEditor';
import CircuitEditor from '../CircuitEditor';
import WorkoutInfoModal from '../WorkoutInfoModal';
import WorkoutEditorDetails from './WorkoutEditorDetails';
import { StyledPageContent } from './styles';
import texts from './texts.json';

const WorkoutEditorDetailsContainer = () => {
  const { workoutDoc } = useContext(WorkoutContext);
  const { workoutDefinition: workoutDef } = workoutDoc;
  const { activities } = workoutDef;

  const location = useLocation() || {};
  const urlParams = new URLSearchParams(location.search);
  const isEditorMode = urlParams.get('edit') === 'true';

  const [showAddActivityModal, setShowAddActivityModal] = useState(false);
  const [showEditActivityModal, setShowEditActivityModal] = useState(false);
  const [editActivity, setEditActivity] = useState();
  const [pathAttrValue, setPathAttr] = useState();
  const [typeValue, setNewActivityType] = useState();
  const [showDeleteActivityAlert, setShowDeleteActivityAlert] = useState(false);
  const [showWorkoutInfoModal, setShowWorkoutInfoModal] = useState(false);

  const onAddActivityOrCircuit = ({ path, action }) => {
    setNewActivityType(action === ActivityActionTypes.ADD_CIRCUIT ? ActivityTypes.CIRCUIT : ActivityTypes.TIMED);
    const nextPath = (path.length === 2) ? [path[0], path[1] + 1] : path;
    setPathAttr(nextPath);
    setEditActivity(null); // Clear existing activity data
    setShowAddActivityModal(true);
  };

  const onEditActivity = ({ path, activity }) => {
    setNewActivityType(activity.type);
    setPathAttr(path);
    // Set activity data and pass it to form
    setEditActivity(new ActivityDefinition(activity));
    setShowEditActivityModal(true);
  };

  const onPaste = useCallback(async ({ path }) => {
    const newPath = path;
    const copyItem = localStorage.getItem('copy-item');
    if (copyItem) {
      const item = JSON.parse(copyItem);
      newPath[newPath.length - 1] = newPath[newPath.length - 1] + 1;
      const { name } = item.activity;
      const payload = { ...item.activity, name };

      if (!name) {
        delete payload.name;
      }

      if (payload.activities) {
        payload.activities = payload.activities.map((activityItem) => ({ ...activityItem }));
      }

      if (payload.type === ActivityTypes.CIRCUIT && !!payload.name) {
        const circuitDefinition = {
          activities: payload.activities,
          name: payload.name,
          note: payload.note,
          rounds: payload.rounds,
          type: payload.type,
        };

        await workoutDoc.addCircuit(circuitDefinition, newPath);
        toast.success(
          format(texts.pasteMessage, {
            pastedType: texts.circuitLabel,
            pastedName: payload.name,
          }),
        );
      } else {
        await workoutDoc.addActivity([payload], newPath);
        toast.success(
          format(texts.pasteMessage, {
            pastedType: texts.activityLabel,
            pastedName: payload.activities[0].name,
          }),
        );
      }
    }
  }, [workoutDoc]);

  const onAdd = useCallback((type) => {
    const path = [activities.length];
    let activityType = ActivityTypes.TIMED;
    if (type === ActivityActionTypes.ADD_CIRCUIT) activityType = ActivityTypes.CIRCUIT;

    setNewActivityType(activityType);
    setPathAttr(path);

    setEditActivity(null); // Clear existing activity data
    setShowAddActivityModal(true);
  }, [activities]);

  const onAddModalClose = () => {
    if (showEditActivityModal) setShowEditActivityModal(false);
    else setShowAddActivityModal(false);
  };

  const onSaved = async () => {
    onAddModalClose();
  };

  const onDeleteConfirmation = ({ path }) => {
    setPathAttr(path);
    setShowDeleteActivityAlert(true);
  };

  const onDeleteActivity = useCallback(async () => {
    workoutDoc.deleteActivity(pathAttrValue);
    setShowDeleteActivityAlert(false);
  }, [pathAttrValue, workoutDoc, setShowDeleteActivityAlert]);

  const cancelActivityDeletion = () => {
    setShowDeleteActivityAlert(false);
  };

  const onCopy = ({ activity }) => {
    localStorage.setItem('copy-item', JSON.stringify({ activity }));
    const {
      name,
      activities: copiedActivities,
      type,
    } = activity;

    const copiedType = !name || type === ActivityTypes.REST ? texts.activityLabel : texts.circuitLabel;
    const copiedName = name || copiedActivities[0].name;

    toast.info(
      format(texts.copyMessage, {
        copiedType,
        copiedName,
      }),
    );
  };

  const onMoveUpDown = useCallback(async ({ path, action }) => {
    workoutDoc.moveActivity(path, action);
  }, [
    workoutDoc,
  ]);

  // setup the mapping
  const actionsByTypes = useMemo(() => ({
    [`${ActivityActionTypes.ADD}`]: onAddActivityOrCircuit,
    [`${ActivityActionTypes.ADD_CIRCUIT}`]: onAddActivityOrCircuit,
    [`${ActivityActionTypes.EDIT}`]: onEditActivity,
    [`${ActivityActionTypes.DELETE}`]: onDeleteConfirmation,
    [`${ActivityActionTypes.MOVE_UP}`]: onMoveUpDown,
    [`${ActivityActionTypes.MOVE_DOWN}`]: onMoveUpDown,
    [`${ActivityActionTypes.COPY}`]: onCopy,
    [`${ActivityActionTypes.PASTE}`]: onPaste,
  }), [
    onMoveUpDown,
    onPaste,
  ]);

  const onEditorModeActionPerform = useCallback(async (params) => {
    if (!params) return;
    const {
      type,
      action,
      activity,
      path = [0],
    } = params;

    if (
      type === ActivityTypes.CIRCUIT
        && (action === ActivityActionTypes.ADD || action === ActivityActionTypes.ADD_CIRCUIT)
    ) {
      // Use the last position when adding to a circuit
      const positionInsideCircuit = (activity.activities && activity.activities.length) || 0;
      if (path.length === 1) {
        path.push(positionInsideCircuit);
      } else if (path.length === 2) {
        path[1] += 1;
      }
    }
    const actionFn = actionsByTypes[action];
    actionFn({ path, action, activity });
  }, [actionsByTypes]);

  const renderActivityEditor = () => {
    if (showAddActivityModal || showEditActivityModal) {
      const Component = typeValue === ActivityTypes.CIRCUIT ? CircuitEditor : ActivityEditor;
      const activity = showEditActivityModal ? editActivity : {};

      return (
        <Component
          onClose={onAddModalClose}
          activity={activity}
          pathAttr={pathAttrValue}
          isEdit={showEditActivityModal}
          onSaved={onSaved}
        />
      );
    }

    return null;
  };

  const onWorkoutInfoModalClose = () => {
    setShowWorkoutInfoModal(false);
  };

  const onWorkoutInfoModalSave = async (name, note) => {
    await workoutDoc.updateWorkoutInfo(name, note);
    onWorkoutInfoModalClose();
  };

  const renderWorkoutInfoModal = () => {
    if (showWorkoutInfoModal) {
      return (
        <WorkoutInfoModal
          name={workoutDef.name}
          note={workoutDef.note}
          onClose={onWorkoutInfoModalClose}
          onSave={onWorkoutInfoModalSave}
        />
      );
    }

    return null;
  };

  return (
    <>
      <IonAlert
        isOpen={showDeleteActivityAlert}
        header={texts.deleteActivityAlert.title}
        message={texts.deleteActivityAlert.message}
        buttons={[
          {
            text: texts.deleteActivityAlert.cancelAction,
            role: 'cancel',
            handler: cancelActivityDeletion,
          },
          {
            text: texts.deleteActivityAlert.deleteAction,
            handler: onDeleteActivity,
          },
        ]}
      />
      <StyledPageContent>
        <ExerciseContextProvider>
          <WorkoutEditorDetails
            isEditorMode={isEditorMode}
            onEditorModeActionPerform={onEditorModeActionPerform}
            onAdd={onAdd}
            onEditWorkoutInfoClick={() => setShowWorkoutInfoModal(true)}
            workoutDoc={workoutDoc}
          />
          {renderActivityEditor()}
          {renderWorkoutInfoModal()}
        </ExerciseContextProvider>
      </StyledPageContent>
    </>
  );
};

export default compose(
  withWorkoutContextReady,
  observer,
)(WorkoutEditorDetailsContainer);
