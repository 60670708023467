import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';

import { filterOption } from '../../../../utils/search';
import ExerciseContext from '../../../context/ExerciseContext';
import Select from '../../Select';
import texts from './texts.json';

const ExerciseSelector = ({
  selectedTags,
  selectedExercise,
  onChange,
}) => {
  const {
    isReady: isExerciseReady,
    exerciseList: completeList,
  } = useContext(ExerciseContext);

  const exerciseList = useMemo(() => {
    let listToProcess = [...completeList];

    // Filter by tags before building the options array
    if (selectedTags && selectedTags.length) {
      listToProcess = listToProcess.filter(({ tags }) => {
        if (tags) {
          return selectedTags.every(
            (selectedTag) => (
              tags.some((tag) => selectedTag.value === tag.id)
            ),
          );
        }
        return false;
      });
    }

    return listToProcess.map((item) => ({
      label: item.name,
      value: {
        ...item.data,
        id: item.id,
      },
    }));
  }, [
    completeList,
    selectedTags,
  ]);

  return (
    <Select
      label={texts.name}
      name={texts.name}
      value={selectedExercise}
      isClearable="true"
      isSearchable="true"
      isLoading={!isExerciseReady}
      options={exerciseList}
      onChange={onChange}
      filterOption={filterOption}
      required
    />
  );
};

ExerciseSelector.propTypes = {
  selectedTags: PropTypes.arrayOf(PropTypes.object),
  selectedExercise: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

ExerciseSelector.defaultProps = {
  selectedTags: [],
  selectedExercise: null,
};

export default ExerciseSelector;
