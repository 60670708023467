const createMapKey = (date) => date.toISOString();

class WorkoutDaysGroup {
  constructor() {
    this.daysMap = new Map();
  }

  hasDay = (date) => {
    const key = createMapKey(date);
    return this.daysMap.has(key);
  }

  getDay = (date) => {
    const key = createMapKey(date);
    return this.daysMap.get(key);
  }

  addDay = (date, assignmentDayType, progressStatus) => {
    const key = createMapKey(date);

    this.daysMap.set(key, {
      items: [],
      completedItems: [],
      assignmentDayType,
      date,
      progressStatus,
    });
  }
}

export default WorkoutDaysGroup;
