import React, {
  useEffect,
  useRef,
} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Chart,
  LineController,
  LineElement,
  PointElement,
} from 'chart.js';

import { addAlpha } from '../../../../utils/colorUtils';
import { DateFormat } from '../../../../utils/date';
import useAppTheme from '../../../../hooks/useAppTheme';
import texts from '../../Log/Health/OptionInput/texts.json';
import { healthStats } from '../../utils';

import config from './config';
import { ChartContainer } from './styles';

Chart.register(
  LineController,
  LineElement,
  PointElement,
);

const LogsChart = ({
  logs,
  stat,
}) => {
  const graphRef = useRef(null);
  const { colors } = useAppTheme();

  useEffect(() => {
    const {
      values,
      dates,
    } = logs
      .reduce((prev, log) => {
        const { date, [stat]: statValue } = log;
        const formattedDate = moment(date).format(DateFormat.LONG_MONTH_WITH_DAY);

        const newData = {
          dates: [formattedDate, ...prev.dates],
          values: [statValue, ...prev.values],
        };

        return newData;
      }, {
        values: [],
        dates: [],
      });

    let yValues = values;
    if (stat === healthStats.mood.name) {
      yValues = values.map((value) => texts.options[stat].findIndex((option) => option.text === value));
    }
    const data = {
      labels: dates,
      datasets: [
        {
          data: yValues,
          borderColor: addAlpha(colors.base.primary, 0.5),
          backgroundColor: colors.base.primary,
        },
      ],
    };

    const chartConfig = {
      ...config,
      data,
    };

    const chart = new Chart(graphRef.current, chartConfig);

    return () => {
      chart.destroy();
    };
  }, [
    logs,
    stat,
    colors,
  ]);

  return (
    <ChartContainer>
      <canvas ref={graphRef} />
    </ChartContainer>
  );
};

LogsChart.propTypes = {
  logs: PropTypes.array.isRequired,
  stat: PropTypes.string,
};

LogsChart.defaultProps = {
  stat: 'weight',
};

export default LogsChart;
