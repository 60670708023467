import posthog from 'posthog-js';

const FeatureFlag = {
  // Enable the new chat experience
  NEW_CHAT: 'newChat',
  // when enabled, the app will use Firebase Functions to update the workout assignment documents
  USE_FIREBASE_FUNC_FOR_WORKOUT_UPDATE: 'UseFirebaseFuncForWorkoutUpdate',
  USE_TRANSACTION_FOR_WORKOUT_UPDATE: 'UseTransactionForWorkoutUpdate',
};

const isFeatureEnabled = (featureFlag) => posthog.isFeatureEnabled(featureFlag);

const shoudUseFirebaseFuncForWorkoutUpdate = () => isFeatureEnabled(FeatureFlag.USE_FIREBASE_FUNC_FOR_WORKOUT_UPDATE);
const shoudUseTransactionForWorkoutUpdate = () => isFeatureEnabled(FeatureFlag.USE_TRANSACTION_FOR_WORKOUT_UPDATE);

const isNewChatEnabled = () => isFeatureEnabled(FeatureFlag.NEW_CHAT);

export {
  isNewChatEnabled,
  shoudUseFirebaseFuncForWorkoutUpdate,
  shoudUseTransactionForWorkoutUpdate,
};
