import CollectionName from '../utils/collections';
import { MeasurementSystem } from '../utils/measurement';
import BaseDocument from './BaseDocument';

const WeeklyWeightGoal = {
  GAIN_WEIGHT: 'GAIN_WEIGHT',
  MAINTAIN_WEIGHT: 'MAINTAIN_WEIGHT',
  LOSE_LITTLE_WEIGHT: 'LOSE_LITTLE_WEIGHT',
  LOSE_MORE_WEIGHT: 'LOSE_MORE_WEIGHT',
};

const DietaryRestriction = {
  GLUTEN_FREE: 'GLUTEN_FREE',
  SOY_FREE: 'SOY_FREE',
  DAIRY_FREE: 'DAIRY_FREE',
  VEGETARIAN: 'VEGETARIAN',
  VEGAN: 'VEGAN',
};

class UserNutritionProfile extends BaseDocument {
  constructor(id, opts) {
    super(`${CollectionName.USER_NUTRITION_PROFILE}/${id}`, opts);
  }

  get dietaryRestrictions() {
    return this.data.dietaryRestrictions || [];
  }

  get weeklyWeightGoal() {
    return this.data.weeklyWeightGoal;
  }

  get measurementUnit() {
    return this.data.currentWeightLbs
      ? MeasurementSystem.IMPERIAL
      : MeasurementSystem.METRIC;
  }

  get height() {
    return this.measurementUnit === MeasurementSystem.METRIC
      ? this.data.heightCm
      : { ft: this.data.heightFt, in: this.data.heightIn };
  }
}

export default UserNutritionProfile;
export {
  WeeklyWeightGoal,
  DietaryRestriction,
};
