import { Collection } from 'firestorter';
import { v4 as uuidv4 } from 'uuid';

import BaseDocument from './BaseDocument';
import { workoutAssignmentStatuses } from './WorkoutAssignment';

const WorkoutFeedViewType = {
  COACH_ASSIGNED_WORKOUT: 'coach_assigned_workout',
  SELF_ASSIGNED_WORKOUT: 'self_assigned_workout',
  CUSTOM_WORKOUT: 'custom_workout',
  TRACKED_ACTIVITY_SUMMARY: 'tracked_activity_summary',
  TRACKED_ACTIVITY: 'tracked_activity',
  CHECK_IN: 'check_in',
};

const isWorkoutAssignmentType = (workoutFeedViewType) => (
  workoutFeedViewType === WorkoutFeedViewType.COACH_ASSIGNED_WORKOUT
  || workoutFeedViewType === WorkoutFeedViewType.SELF_ASSIGNED_WORKOUT
);

class WorkoutFeedView extends BaseDocument {
  static COLLECTION = '/workoutFeedView';

  constructor(id = uuidv4(), opts) {
    super(`${WorkoutFeedView.COLLECTION}/${id}`, opts);
  }

  async init(initOpts) {
    await super.init(initOpts);
  }

  get isCompleted() {
    return this.data.status === workoutAssignmentStatuses.COMPLETED;
  }

  get title() {
    return this.data.title;
  }

  get user() {
    return this.data.user;
  }

  get startDate() {
    return this.data.startDate;
  }

  get endDate() {
    return this.data.endDate;
  }

  get status() {
    return this.data.status;
  }

  get type() {
    return this.data.type;
  }

  get activeCalories() {
    return this.data.activeCalories;
  }

  get duration() {
    return this.data.duration;
  }

  get week() {
    return this.data.week;
  }

  get weekDate() {
    return this.data.weekDate;
  }

  updateStatus = (nextStatus, lastUpdatedDateTime) => this.updateFields({
    status: nextStatus,
    lastUpdatedDateTime,
  });

  static async getWorkoutFeedViewByDate(userId, start, end) {
    const workoutFeedViewCollection = new Collection(() => WorkoutFeedView.COLLECTION, {
      createDocument: (src, opts) => new WorkoutFeedView(src.id, opts),
      query: (ref) => ref
        .where('user', '==', userId)
        .where('startDate', '>=', start.toDate())
        .where('startDate', '<', end.toDate())
        .orderBy('startDate', 'desc'),
    });

    await workoutFeedViewCollection.fetch();
    return workoutFeedViewCollection;
  }
}

export default WorkoutFeedView;
export {
  WorkoutFeedViewType,
  isWorkoutAssignmentType,
};
