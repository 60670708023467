import styled, { css } from 'styled-components';
import { GeneralMessage } from '../styles';

const RestMessages = styled(GeneralMessage)`
  ${({ theme }) => theme.isPortrait && css`
    flex-direction: column;
    align-items: center;
  `}
`;

const RestTimer = styled.div`
  font-variant-numeric: tabular-nums;
  text-align: right;
  margin-right: 10px;
  flex: 2;

  ${({ theme }) => theme.isPortrait && css`
    text-align: initial;
    margin: 0;
  `}
`;

const Message = styled.div`
  margin-left: 10px;
  flex: 3;

  ${({ theme }) => theme.isPortrait && css`
    margin: 0;
  `}
`;

export {
  RestMessages,
  RestTimer,
  Message,
};
