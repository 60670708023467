import styled from 'styled-components';

const StepperContainer = styled.ol`
  display: flex;
  flex: 1;
`;

const StepperItem = styled.li`
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  &:before {
    position: relative;
    z-index: 1;
    display: block;
    width: 25px;
    height: 21px;
    border: 1px solid ${({ active, theme }) => (active ? theme.colors.base.primary : theme.colors.system.gamma1)};
    border-radius: 50%;
    margin: 10px auto;
    padding-top: 4px;
    font-weight: bold;
    content: "${({ content }) => content}";
    color: ${({ active, theme }) => (active ? theme.colors.system.alpha : theme.colors.base.primary)};
    background-color: ${({ active, theme }) => (active ? theme.colors.base.primary : theme.colors.system.gamma1)};
    font-size: 12px;
  }
  &:not(:last-child):after {
    content: "";
    position: relative;
    top: 24px;
    left: 50%;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.system.gamma1};
    order: -1;
  }
`;

export {
  StepperContainer,
  StepperItem,
};
