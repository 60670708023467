import styled from 'styled-components';

import Card from '../../../../components/Card';

const StyledCard = styled(Card)`
  flex-direction: column;
`;

const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  overflow: hidden;
`;

const GraphContainer = styled.div`
  margin-top: 30px;
`;

export {
  StyledCard,
  InfoContainer,
  GraphContainer,
};
