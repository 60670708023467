import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as ChatIcon } from '../../../../../../../chat/assets/icon/chat.svg';

import {
  StyledActionButton,
  IconContainer,
  Label,
} from './styles';

const ChatButton = ({
  onClick,
}) => (
  <StyledActionButton onClick={onClick}>
    <IconContainer>
      <ChatIcon />
    </IconContainer>
    <Label>Chat</Label>
  </StyledActionButton>
);

ChatButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ChatButton;
