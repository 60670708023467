import React, {
  useState,
  useEffect,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { Collection } from 'firestorter';

import logEvent from '../../../utils/logger';
import useComponentMounted from '../../../hooks/useComponentMounted';
import useUserNavigation from '../../../hooks/useUserNavigation';
import Workout from '../../../models/Workout';
import WorkoutSelection from './WorkoutSelection';

import {
  StyledLoader,
} from './styles';

const WorkoutSelectionContainer = ({
  ...props
}) => {
  const isComponentMountedRef = useComponentMounted();
  const { navigateToUserPath } = useUserNavigation();
  const [workoutsCollection, setWorkoutsCollection] = useState(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const init = async () => {
      const workouts = new Collection(() => '/workout', {
        createDocument: (src, opts) => new Workout(src, opts),
        query: (ref) => ref.where('visibility', '==', 'PUBLIC'),
      });

      await workouts.fetch();

      workouts.docs.replace(workouts.docs.slice().sort((workoutA, workoutB) => {
        const orderA = workoutA.data.order || 0;
        const orderB = workoutB.data.order || 0;
        return orderA - orderB;
      }));

      if (isComponentMountedRef.current) {
        setWorkoutsCollection(workouts);
        setIsReady(true);
      }
    };

    if (!isReady) {
      init();
    }
  }, [
    isReady,
    isComponentMountedRef,
  ]);

  const onWorkoutSelected = ({ id }) => {
    logEvent('workoutSelectedForLogging', {
      id,
    });
    navigateToUserPath(`workoutSelector/${id}`);
  };

  if (!isReady) {
    return <StyledLoader />;
  }

  return (
    <WorkoutSelection
      {...props}
      workoutsCollection={workoutsCollection}
      onWorkoutSelected={onWorkoutSelected}
    />
  );
};

export default compose(
  observer,
)(WorkoutSelectionContainer);
