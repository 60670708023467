import React from 'react';
import PropTypes from 'prop-types';

import {
  formatSecondsToMinForThreshold,
} from '../../../../../../../utils/time';
import {
  RestTimer,
  Message,
  RestMessages,
} from './styles';

const RestMessage = ({
  remainingRestTime,
}) => (
  <RestMessages>
    <RestTimer>
      {formatSecondsToMinForThreshold(remainingRestTime)}
    </RestTimer>
    <Message>Rest</Message>
  </RestMessages>
);

RestMessage.propTypes = {
  remainingRestTime: PropTypes.number.isRequired,
};

export default RestMessage;
