import styled from 'styled-components';

import ExercisesSection from './ExercisesSection';

const Header = styled.div`
  display: flex;
  width: 100%;
  min-height: 56px;
  padding: 0 16px;
  align-items: center;
`;

const StyledExercisesSection = styled(ExercisesSection)`
  margin: 10px 0 0 0;
  flex: 1;
  overflow-y: auto;
`;

export {
  Header,
  StyledExercisesSection,
};
