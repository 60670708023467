import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import * as Sentry from '@sentry/browser';

import defaultLogo from '../../assets/logo.svg';
import logEvent from '../../utils/logger';
import { dataURItoBlobURL, blobToBase64 } from '../../utils/dataProcessing';
import {
  LocalStorageKey,
  DeviceFileSystemPath,
  fileExists,
} from './deviceStorage';

/**
 * Calculates the web app icon name to identify the asset locally-on device.
 *
 * @returns {string}
 */
const calculateWebAppIconName = (webAppIconRef) => `icon${webAppIconRef.replaceAll('/', '-')}`;

/**
 * Gets the icon path within the device file system.
 *
 * @returns {string}
 */
const calculateWebAppIconPath = (name) => `${DeviceFileSystemPath.WEB_APP_ICON}/${name}`;

/**
 * Removes the web app icon directory, recursively.
 */
const removeWebAppIconDir = async () => {
  logEvent('removeWebAppIconDir');
  try {
    await Filesystem.rmdir({
      path: DeviceFileSystemPath.WEB_APP_ICON,
      directory: Directory.Data,
      recursive: true,
    });
  } catch (error) {
    logEvent('removeWebAppIconDirError', {
      error,
    });
    Sentry.captureException(error);
  }
};

/**
 * Gets the web app icon download URL from firebase storage.
 *
 * @param {string} webAppIconRef
 * @param {Object} firebase
 * @returns
 */
const getIconURL = async (webAppIconRef, firebase) => {
  let iconUrl = null;
  try {
    iconUrl = await firebase.storage.ref().child(webAppIconRef).getDownloadURL();
  } catch (error) {
    logEvent('getDownloadURLForWebAppIconError', {
      webAppIconRef,
      error,
    });
    Sentry.captureException(error);
  }
  return iconUrl;
};

/**
 * Saves the icon name in local storage.
 *
 * @param {string} iconName The new icon name.
 */
const prepareIconNameCache = (iconName) => {
  const existentIconName = localStorage.getItem(LocalStorageKey.WEB_APP_ICON_NAME);
  if (existentIconName !== iconName) {
    localStorage.setItem(LocalStorageKey.WEB_APP_ICON_NAME, iconName);
  }
};

/**
 * Removes icon from device. Delete it from the cache as well from the storage directory.
 */
const removeIcon = async () => {
  const existentIconName = localStorage.getItem(LocalStorageKey.WEB_APP_ICON_NAME);

  if (existentIconName) {
    localStorage.removeItem(LocalStorageKey.WEB_APP_ICON_NAME);
    await removeWebAppIconDir();
  }
};

/**
 * Setup the custom web app icon if any. If a custom web app icon exists for the given customization,
 * execute all the required steps to download the icon and save it locally on the device file system, only
 * if the asset doesn't already exists in the file system.
 * If there's no custom app icon specified by the customization, empty the app icons directory.
 *
 * @param {Object} firebase
 * @param {Object=} appCustomizationDoc
 * @returns
 */
const setupWebAppIcon = async (firebase, appCustomizationDoc) => {
  const { webAppIconRef } = appCustomizationDoc || {};
  if (webAppIconRef) {
    const iconName = calculateWebAppIconName(webAppIconRef);
    // Save the icon name in local storage
    prepareIconNameCache(iconName);

    const iconPath = calculateWebAppIconPath(iconName);
    // Check if the web app icon is already downloaded and available
    const isIconInFS = await fileExists(iconPath);

    logEvent('setupWebAppIcon', {
      isIconInFS,
    });

    if (!isIconInFS) {
      // Download and save the icon to the device filesystem
      const iconURL = await getIconURL(webAppIconRef, firebase);
      let data;

      try {
        const fileData = await fetch(iconURL);
        const blob = await fileData.blob();
        data = await blobToBase64(blob);
      } catch (error) {
        logEvent('webAppIconFetchAndConvertionError', {
          error,
        });
        Sentry.captureException(error, {
          extra: {
            description: 'Error fetching and converting web app icon to base64',
          },
        });
        return;
      }

      try {
        await Filesystem.writeFile({
          path: iconPath,
          data,
          recursive: true,
          directory: Directory.Data,
          encoding: Encoding.UTF8,
        });
      } catch (error) {
        logEvent('saveWebAppIconError', {
          error,
        });
        Sentry.captureException(error);
      }
    }
  } else {
    // There's no web app icon configured
    await removeIcon();
  }
};

/**
 * Gets the web app icon path based on the given app customization document. If no document or webAppIconRef
 * field exists, then the local cached name will be used.
 *
 * @param {Object} appCustomizationDoc
 * @returns {string|null} The icon path.
 */
const getWebAppIconPath = (appCustomizationDoc) => {
  const webAppIconRef = appCustomizationDoc ? appCustomizationDoc.webAppIconRef : null;

  const iconName = webAppIconRef
    ? calculateWebAppIconName(webAppIconRef)
    : localStorage.getItem(LocalStorageKey.WEB_APP_ICON_NAME);

  return iconName
    ? calculateWebAppIconPath(iconName)
    : null;
};

/**
 * Gets the web app icon URL to use.
 *
 * @param {Object} appCustomizationDoc
 * @returns {string} The web app icon URL.
 */
const getWebAppIconURL = async (appCustomizationDoc) => {
  const iconPath = getWebAppIconPath(appCustomizationDoc);

  if (iconPath) {
    // Check if the web app icon is already downloaded and available
    const isIconInFS = await fileExists(iconPath);

    if (isIconInFS) {
      try {
        const { data } = await Filesystem.readFile({
          path: iconPath,
          directory: Directory.Data,
          encoding: Encoding.UTF8,
        });

        // convert it to a blob URL
        return dataURItoBlobURL(data);
      } catch (error) {
        Sentry.captureException(error, {
          extra: {
            iconPath,
            description: 'Error trying to read the web app icon from the device file system',
          },
        });
      }
    }
  }

  // Use default icon
  return defaultLogo;
};

export {
  setupWebAppIcon,
  getWebAppIconURL,
  removeIcon,
  defaultLogo,
};
