import BaseRecorder from './BaseRecorder';

/**
 * Image quality to use when generating the image. A quality 0.85 seems like it reduces file size by about 80-90%
 * without major visual degradation. So this number must be between 0.85 and 1.
 *
 * @type {number}
 */
const JPG_QUALITY = 1;

const VideoState = {
  HAVE_ENOUGH_DATA: 4,
};

class SelfieMediaRecorder extends BaseRecorder {
  constructor(drawParts) {
    super(drawParts);
    this.captureCanvas = document.createElement('canvas');
    this.captureCanvas.width = this.selfieConfig.width;

    this.readyTimerId = null;
    this.aspectRatio = 1;
    this.shouldContinue = true;
  }

  /**
   * Consumers should call this function to finish the initialization and setup process.
   * The onReady callback will be called once the last steps are completed: setting up the
   * previewSelfieRef size.
   *
   * @param {Function} onReady Function to notify callers that the initialization process is completed.
   */
  ready = (onReady) => {
    if (this.hasValidPreview) {
      const previewHeight = this.previewSelfieRef.current.videoHeight;

      const hasValidHeight = previewHeight;

      if (hasValidHeight) {
        this.previewSelfieRef.current.height = previewHeight;
        const width = this.previewSelfieRef.current.videoWidth;
        this.previewSelfieRef.current.width = width;

        if (this.shouldContinue) {
          onReady();
        }
      } else if (this.shouldContinue) {
        this.readyTimerId = setTimeout(this.ready.bind(this, onReady), 100);
      }
    }
  }

  aspectRatioChanged = (ratio) => {
    this.aspectRatio = ratio;

    const { width } = this.selfieConfig;
    const height = width / this.aspectRatio;

    this.selfieElement.height = height;
    this.captureCanvas.height = height;
  }

  /**
   * Calculates the aspect ratio.
   * The reason is that it was calculated in this way in FlowHuman before and due to
   * the operations/calculations done.
   */
  getAspectRatio = () => {
    const height = this.previewSelfieRef.current.videoHeight;
    const width = this.previewSelfieRef.current.videoWidth;
    return width / height;
  }

  /**
   * The instance is ready to capture images when the preview has a valid reference, and the video
   * state is HAVE_ENOUGH_DATA.
   *
   * @returns {Boolean} True when it is ready to capture images, otherwise false.
   */
  isReadyToCapture = () => (this.hasValidPreview
    && this.previewSelfieRef.current.readyState === VideoState.HAVE_ENOUGH_DATA);

  /**
   * Capture an image from the video streaming by using a canvas element to set the required image size.
   *
   * @return {Promise}
   */
  captureImage = async () => {
    this.captureCanvas
      .getContext('2d')
      .drawImage(this.previewSelfieRef.current, 0, 0,
        this.captureCanvas.width, this.captureCanvas.height);

    // Check the capture aspect ratio. If it has changed, then update the canvas elements dimensions.
    const ratio = this.getAspectRatio();
    const hasAspectRatioChanged = ratio !== this.aspectRatio;

    if (hasAspectRatioChanged) {
      this.aspectRatioChanged(ratio);
    }

    const cameraData = await new Promise((resolve) => this.captureCanvas.toBlob(resolve, 'image/jpeg', JPG_QUALITY));

    return {
      cameraData,
      hasAspectRatioChanged,
      aspectRatio: this.aspectRatio,
    };
  }

  clear = () => {
    this.shouldContinue = false;

    // clear timers and animation frames
    clearTimeout(this.readyTimerId);
  }
}

export default SelfieMediaRecorder;
