import { Collection } from 'firestorter';

import { pathPlaceholder } from '../utils/firebasePaths';

const CoachNotificationType = {
  WORKOUT_DONE: 'WORKOUT_DONE',
};

const CoachNotificationStatus = {
  QUEUED: 'QUEUED',
};

const createNewCoachNotification = (data) => {
  const { user } = data;
  const coachNotification = new Collection(pathPlaceholder.COACH_NOTIFICATION, {
    /*
      Specify the query by user, so the document can be added to the collection, due to
      specific firestore rules that applied to this collection.
    */
    query: (ref) => ref.where('user', '==', user).limit(1),
  });
  coachNotification.add({
    ...data,
    status: CoachNotificationStatus.QUEUED,
  });
};

export {
  CoachNotificationType,
  createNewCoachNotification,
};
