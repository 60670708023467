import React, { useEffect, useState } from 'react';

import { SubscriptionContextProvider } from '../../context/SubscriptionContext';

import SubscriptionSlideDrawer from './SubscriptionSlideDrawer';
import useSubscriptionSlideDrawer from './useSubscriptionSlideDrawer';

const withSubscriptionSlideDrawer = (Component) => (props) => {
  const { isSlideDrawerEnabled } = useSubscriptionSlideDrawer();
  const [shouldRenderSlider, setShouldRenderSlider] = useState(isSlideDrawerEnabled);

  useEffect(() => {
    if (isSlideDrawerEnabled) {
      /*
        Once we render the subscription slider, we lock the shoudlRenderSlide value to true. We do this to avoid
        destroying the slider once the client re-subscribes. If the subscription is active again and the slider is
        still rendered, we can hide it by using its regular animations. Otherwise, the slider would get destroyed as
        soon as the subscription is active again, and it'd dissapear abruptly. The slider will not be rendered next
        time the app is opened.
      */
      setShouldRenderSlider(true);
    }
  }, [isSlideDrawerEnabled]);

  return (
    <>
      <Component {...props} />
      {shouldRenderSlider && (
        <SubscriptionContextProvider>
          <SubscriptionSlideDrawer />
        </SubscriptionContextProvider>
      )}
    </>
  );
};

export default withSubscriptionSlideDrawer;
