import styled, { css } from 'styled-components';

import { IonSpinner } from '@ionic/react';
import { button } from '../../styles/text';

import variants from './variants';

const buttonVariant = ({ variant, theme }) => {
  let backgroundColor;
  let color;
  const { colors } = theme;

  switch (variant) {
    case variants.PRIMARY:
      backgroundColor = colors.base.secondary;
      color = colors.base.primary;
      break;
    case variants.SECONDARY:
      backgroundColor = colors.base.primary;
      color = colors.system.alpha;
      break;
    case variants.TERTIARY:
      backgroundColor = colors.base.tertiary;
      color = colors.system.beta;
      break;
    case variants.DARK:
      backgroundColor = colors.base.primary;
      color = colors.base.secondary;
      break;
    case variants.LINK:
      backgroundColor = 'transparent';
      color = colors.system.highlight;
      break;
    case variants.SEMI_TRANSPARENT:
      backgroundColor = 'rgba(0,0,0,0.5)';
      color = colors.system.alpha;
      break;
    default:
      // primary variant by default
      backgroundColor = colors.base.secondary;
      color = colors.system.beta;
  }

  return css`
    background-color: ${backgroundColor};
    color: ${color};
  `;
};

const buttonSize = ({ full }) => (full ? css`width: 100%;` : '');

const buttonStyles = css`
  ${button}
  padding: 16px;
  border: 0px;
  ${(props) => buttonVariant(props)}
  ${(props) => buttonSize(props)}

  /*
    On mobile devices, the hover state is stuck after tapping on a button because tapping is not the same as an
    onclick event. Because of that, we use the active pseudo-class to only change the button while it's pressed.
  */
  &:active {
    opacity: 0.8;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.system.background};
    color: ${({ theme }) => theme.colors.system.beta};
    opacity: 0.6;
  }
`;

const StyledButton = styled.button`
  ${buttonStyles}
`;

const Spinner = styled(IonSpinner)`
  margin-left: 10px;
`;

export default StyledButton;
export {
  buttonStyles,
  Spinner,
};
