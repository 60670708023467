import React from 'react';
import PropTypes from 'prop-types';

import {
  StepperContainer,
  StepperItem,
} from './styles';

const Stepper = ({
  activeIndex,
  items,
  className,
  onItemClick,
}) => (
  <StepperContainer>
    {items.map((item, index) => (
      <StepperItem
        key={item}
        content={index + 1}
        active={index === activeIndex}
        className={className}
        onClick={() => onItemClick(index)}
      />
    ))}
  </StepperContainer>
);

Stepper.propTypes = {
  items: PropTypes.array.isRequired,
  activeIndex: PropTypes.number.isRequired,
  className: PropTypes.string,
  onItemClick: PropTypes.func,
};

Stepper.defaultProps = {
  className: '',
  onItemClick: () => {},
};

export default Stepper;
