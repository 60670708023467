import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import useSessionStore from '../../../hooks/useSessionStore';
import FeedbackForm from './FeedbackForm';

const FeedbackFormContainer = ({
  name,
  feedback,
  isSelected,
  pathArr,
  setWorkoutFeedback,
  activities,
  setActivities,
  setUnsaved,
}) => {
  const {
    authUser: {
      uid: loggedInUserId,
    },
  } = useSessionStore();
  const [feedbackText, setFeedbackText] = useState(() => feedback);
  const activityArray = activities;

  useEffect(() => {
    setFeedbackText(feedback);
  }, [
    feedback,
  ]);

  const onFeedbackChange = (event) => {
    const text = event.target.value;
    setFeedbackText(text);
    if (pathArr.length === 0) { // workout feedback
      setWorkoutFeedback(text);
    } else {
      if (pathArr.length === 1) { // not circuit
        activityArray[pathArr[0]].coachFeedback = text;
        activityArray[pathArr[0]].coachFeedbackId = loggedInUserId;
      } else { // circuits
        activityArray[pathArr[0]].rounds[pathArr[1]].activities[pathArr[2]].coachFeedback = text;
        activityArray[pathArr[0]].rounds[pathArr[1]].activities[pathArr[2]].coachFeedbackId = loggedInUserId;
      }
      setActivities(activityArray);
    }
    setUnsaved(true);
  };

  return (
    <FeedbackForm
      name={name}
      feedbackText={feedbackText}
      isSelected={isSelected}
      onFeedbackChange={onFeedbackChange}
    />
  );
};

FeedbackFormContainer.propTypes = {
  name: PropTypes.string.isRequired,
  feedback: PropTypes.string,
  isSelected: PropTypes.bool.isRequired,
  pathArr: PropTypes.array.isRequired,
  setWorkoutFeedback: PropTypes.func.isRequired,
  activities: PropTypes.array.isRequired,
  setActivities: PropTypes.func.isRequired,
  setUnsaved: PropTypes.func.isRequired,
};

FeedbackFormContainer.defaultProps = {
  feedback: '',
};

export default FeedbackFormContainer;
