import { Collection } from 'firestorter';

import { pathPlaceholder, firestorePaths } from '../utils/firebasePaths';
import { ActivityGoalType, ActivityStatuses, ActivityTypes } from './BaseActivity';
import ActivityExecution from './ActivityExecution';
import BaseDocument from './BaseDocument';
import { createNewCoachNotification, CoachNotificationType } from './CoachNotification';
import TrackedActivity from './TrackedActivity';

const GameplayStatuses = {
  ASSIGNED: 'ASSIGNED',
  COMPLETED: 'COMPLETED',
  PARTIALLY_COMPLETED: 'PARTIALLY_COMPLETED',
};

const WorkoutVideoProcessingStatuses = {
  NOT_REQUESTED_YET: 'NOT_REQUESTED_YET',
  REQUESTED: 'REQUESTED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
};

const VonageVideoStatus = {
  STARTED: 'started',
  PAUSED: 'paused',
  STOPPED: 'stopped',
  UPLOADED: 'uploaded',
};

/**
 * Video streaming statuses. This statuses combines a simplified series of status that covers
 * the session ID/token setup, start streaming attempt and some Vonage session connection events
 * https://github.com/Fitmoola/capacitor-camera-stream/blob/master/src/definitions.ts#L33: sessionConnected,
 * sessionReconnected and sessionFailedToConnect.
 */
const VideoStreamStatus = {
  SESSION_ID_ASSIGNED: 'SESSION_ID_ASSIGNED', // The session ID and a token is assigned to the gampelay session
  PREPARED: 'PREPARED', // Preparation is ready and camera stream is called
  SESSION_CONNECTED: 'SESSION_CONNECTED', // The vonage session is connected or it has been reconnected
  SESSION_FAILED_CONNECT: 'SESSION_FAILED_CONNECT', // Mapped to sessionFailedToConnect event
  START_STREAM_FAILED: 'START_STREAM_FAILED',
};

const doneGameplayStatuses = [
  GameplayStatuses.PARTIALLY_COMPLETED,
  GameplayStatuses.COMPLETED,
];

class GameplaySession extends BaseDocument {
  /**
   * Finishes a GameplaySession deriving the status from its activities
   * or setting PARTIALLY_COMPLETED if it's forced to finish
   * @param {boolean} force If the session is forced to finish
   */
  finish(force = false) {
    const status = force
      ? GameplayStatuses.PARTIALLY_COMPLETED
      : GameplaySession.getDerivedStatusFromActivities(this.activities);

    this.updateFields({
      status,
      endTime: Date.now(),
    });
  }

  setCameraEnabled(isCameraEnabled) {
    this.updateFields({
      isCameraEnabled,
    });
  }

  get isDone() {
    return doneGameplayStatuses.includes(this.data.status);
  }

  get videoProcessingStatus() {
    return this.data.videoProcessingStatus || WorkoutVideoProcessingStatuses.NOT_REQUESTED_YET;
  }

  /**
   * Get the legacy videoRef if any.
   *
   * @returns {string}
   */
  get legacyVideoRef() {
    return (this.data.videoProcessingStatus === WorkoutVideoProcessingStatuses.COMPLETED)
      ? this.data.videoRef
      : '';
  }

  /**
   * Get the latest video object from the videos array. New workout video upload.
   *
   * @returns {Object} The video object
   */
  get latestVideo() {
    const { videos } = this.data;

    if (!videos || videos.length === 0) {
      return null;
    }

    // Get the latest video within the array that is not an ending state with failure
    const reversedVideos = [...videos].reverse();

    return reversedVideos.find((video) => !video.isEmptyVideo);
  }

  /**
   * Get all the videos generated using Vonage.
   *
   * @returns {Array|null} An array of videos or null when it doesn't exists.
   */
  get videosRef() {
    const { videos } = this.data;

    if (!videos || videos.length === 0) {
      return null;
    }

    return videos;
  }

  /**
   * Gets all videos generated within the session if any, by priority: first it will try to get all the videos generated
   * using Vonage, if no videos, no references are available then, it will return the legacy video ref if any or
   * an empty array.
   *
   * @returns {Array} An array of videos.
   */
  get videos() {
    const workoutVideos = this.videosRef;

    if (workoutVideos) {
      // Return only those videos that has valid videoRef
      return workoutVideos.reduce((validVideos, currentVideo) => {
        if (currentVideo.videoRef) {
          validVideos.push(currentVideo);
        }
        return validVideos;
      }, []);
    }

    const legacyVideo = this.legacyVideoRef;

    return legacyVideo
      ? [{ videoRef: legacyVideo }]
      : [];
  }

  /**
   * Get the latest video ref from the videos array. New workout video upload.
   *
   * @returns {string}
   */
  get latestVideoRef() {
    const { videoRef } = this.latestVideo || {};
    return videoRef || '';
  }

  /**
   * Gets the videoRef if any by priority: first it will try to get the latest videoRef
   * from the GameplaySession.videos array, if no video is available, then it will return
   * the legacy videoRef if it's available.
   *
   * @returns {string}
   */
  get videoRef() {
    return this.latestVideoRef || this.legacyVideoRef;
  }

  get hasWorkoutVideo() {
    return !!this.videoRef;
  }

  get isCameraEnabled() {
    return typeof this.data.isCameraEnabled === 'boolean' ? this.data.isCameraEnabled : true;
  }

  get activities() {
    return this.data.activities.map((jsonActivity) => new ActivityExecution(jsonActivity));
  }

  get coachFeedback() {
    return this.data.coachFeedback || '';
  }

  get startTime() {
    return this.data.startTime || 0;
  }

  get endTime() {
    return this.data.endTime || 0;
  }

  get feedbackSendTime() {
    return this.data.feedbackSendTime;
  }

  get user() {
    return this.data.user;
  }

  get isVideoReviewRequested() {
    return !!this.data.isVideoReviewRequested;
  }

  get isVideoInProgress() {
    const video = this.latestVideo;
    if (video && !video.videoRef && this.isVonageVideoInProgress(video)) {
      return true;
    }

    // check for legacy mode
    const legacyVideoStatus = this.videoProcessingStatus;
    if (!video && legacyVideoStatus && this.isLegacyVideoInProgress(legacyVideoStatus)) {
      return true;
    }
    return false;
  }

  isVonageVideoInProgress = (video) => video && (
    video.status === VonageVideoStatus.STARTED
    || video.status === VonageVideoStatus.PAUSED
    || video.status === VonageVideoStatus.STOPPED
    || video.status === VonageVideoStatus.UPLOADED
  );

  isLegacyVideoInProgress = (legacyVideoStatus) => legacyVideoStatus && (
    legacyVideoStatus === WorkoutVideoProcessingStatuses.REQUESTED
    || legacyVideoStatus === WorkoutVideoProcessingStatuses.IN_PROGRESS
  );

  requestVideoCreation = () => {
    this.updateFields({
      videoProcessingStatus: WorkoutVideoProcessingStatuses.REQUESTED,
    });

    const videoRequests = new Collection(pathPlaceholder.GAMEPLAY_SESSION_VIDEO_REQUEST);
    videoRequests.add({
      gameplaySessionId: this.id,
      user: this.data.user,
    });
  }

  requestSendCoachWorkoutDoneNotification = (extraOptions = {}) => {
    createNewCoachNotification({
      gameplaySessionId: this.id,
      user: this.data.user,
      type: CoachNotificationType.WORKOUT_DONE,
      extras: {
        isDone: this.isDone,
        isCameraEnabled: this.isCameraEnabled,
        ...extraOptions,
      },
    });
  }

  assignVideoStreamingSessionId = (openTokSessionId) => {
    this.updateFields({
      openTokSessionId,
      videoStreamStatus: VideoStreamStatus.SESSION_ID_ASSIGNED,
    });
  }

  getTrackedActivities = async () => {
    const collection = new Collection(firestorePaths.TRACKED_ACTIVITY, {
      createDocument: (src, opts) => new TrackedActivity(src, opts),
      query: (ref) => ref
        .where('user', '==', this.data.user)
        .where('gameplaySession', '==', this.id)
        .orderBy('startDate', 'asc'),
    });
    await collection.fetch();
    return collection;
  }

  /**
   * Returns all statuses of all related activities. If any activity is a circuit
   * it also appends the status of all activities and rounds as a result.
   * @param {Array} activities The activities execution array.
   * @returns {Array} activitiesStatus All the activities status
   */
  static getActivitiesStatus = (activities) => (
    activities.reduce((allActivitiesStatus, activity) => {
      /*
        Do not consider REST activities status for final activities statuses
        as gameplay itself should not be marked as PARTIALLY_COMPLETED if the
        user just skipped a REST activity
      */
      if (activity.type === ActivityTypes.REST) {
        return allActivitiesStatus;
      }
      if (activity.type === ActivityTypes.CIRCUIT) {
        const circuitStatuses = activity.rounds.reduce((allRoundsStatus, round) => ([
          ...allRoundsStatus,
          ...GameplaySession.getActivitiesStatus(round.activities),
        ]), []);
        return [
          ...allActivitiesStatus,
          ...circuitStatuses,
        ];
      }
      return [
        ...allActivitiesStatus,
        activity.status,
      ];
    }, [])
  );

  /**
   * Given an array of execution activities it determines the status of the whole
   * gameplay based on each individual status of the inner activities.
   * @param {Array} activities An array of execution activities.
   * @return {number} A gameplay status
   */
  static getDerivedStatusFromActivities = (activities) => {
    const allActivitiesStatus = GameplaySession.getActivitiesStatus(activities);
    if (allActivitiesStatus.includes(ActivityStatuses.SKIPPED)) {
      return GameplayStatuses.PARTIALLY_COMPLETED;
    }
    if (allActivitiesStatus.every((activityStatus) => activityStatus === ActivityStatuses.COMPLETED)) {
      return GameplayStatuses.COMPLETED;
    }
    return GameplayStatuses.ASSIGNED;
  }

  /**
   * Transforms an array of activities to an activities execution array.
   */
  static toActivitiesExecutionFormat = (activities) => (
    activities.reduce((activitiesExecutionFormat, activity) => {
      const {
        name,
        type,
        rounds,
        activities: subActivities,
        restTime,
        ...otherProps
      } = activity;

      let activityExecutionFormat = {
        name,
        type,
      };

      if (type === ActivityTypes.CIRCUIT) {
        activityExecutionFormat.rounds = activityExecutionFormat.rounds || [];
        for (let i = 0; i < rounds; i++) {
          activityExecutionFormat.rounds.push({
            activities: GameplaySession.toActivitiesExecutionFormat(subActivities),
          });
        }
      } else {
        activityExecutionFormat = {
          ...activityExecutionFormat,
          ...otherProps,
          status: ActivityStatuses.ASSIGNED,
        };
      }
      const activitiesToAdd = [activityExecutionFormat];
      if (restTime) {
        activitiesToAdd.push({
          type: ActivityTypes.REST,
          name: 'Rest',
          [ActivityGoalType.DURATION]: restTime,
          status: ActivityStatuses.ASSIGNED,
        });
      }
      return [...activitiesExecutionFormat, ...activitiesToAdd];
    }, [])
  );
}

export default GameplaySession;
export {
  GameplayStatuses,
  WorkoutVideoProcessingStatuses,
  VideoStreamStatus,
};
