import React, {
  useContext,
  useCallback,
} from 'react';
import { IonHeader } from '@ionic/react';

import useNavContext from '../../../hooks/useNavContext';
import { HeaderContext } from '../context';
import useHeaderParamConfig from '../hooks/useHeaderParamConfig';
import Header from './Header';

const HeaderContainer = () => {
  const { title, actions, backHandler } = useContext(HeaderContext);
  const { goBack } = useNavContext();
  const { enableHeaderActions } = useHeaderParamConfig();

  /**
   * A navigate back function.
   *
   * NOTE: This has to be implemented in this way: by creating a function that calls to `goBack`. If `goBack` is
   * passed directly to Header as a prop, certain back navigation scenarios won't work, for example:
   * - The app starts in a page different than the home page, when the user navigates back, the back
   * navigation doesn't work, it does not navigate to any page.
   */
  const navigateBack = useCallback(() => {
    if (backHandler) {
      backHandler();
      return;
    }
    goBack();
  }, [
    goBack,
    backHandler,
  ]);

  return (
    <IonHeader>
      <Header
        title={title}
        actions={actions}
        navigateBack={navigateBack}
        enableHeaderActions={enableHeaderActions}
      />
    </IonHeader>
  );
};

export default HeaderContainer;
