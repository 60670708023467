import React from 'react';
import PropTypes from 'prop-types';

import Label from '../Label';
import {
  InputWrapper,
  InputText,
} from './styles';

const Input = ({
  label,
  type,
  value,
  onChange,
  name,
  className,
  ...otherProps
}) => (
  <InputWrapper className={className}>
    {!!label && (
      <Label htmlFor={name}>
        {label}
      </Label>
    )}
    <InputText
      type={type}
      value={value}
      onChange={onChange}
      name={name}
      {...otherProps}
    />
  </InputWrapper>
);

Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
  className: PropTypes.string,
};

Input.defaultProps = {
  onChange: () => {},
  label: '',
  className: '',
};

export default Input;
