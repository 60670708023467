import { ReactComponent as VisaIcon } from '../../assets/paymentMethods/visa.svg';
import { ReactComponent as MastercardIcon } from '../../assets/paymentMethods/mastercard.svg';
import { ReactComponent as AmexIcon } from '../../assets/paymentMethods/amex.svg';
import { ReactComponent as DiscoverIcon } from '../../assets/paymentMethods/discover.svg';
import { ReactComponent as GenericIcon } from '../../assets/paymentMethods/generic.svg';

/*
  In this enum object, we are using the payment method name as the key, in lowercase to make it match the payment
  method name reported by stripe. The full list can be found here: https://stripe.com/docs/card-brand-choice#card-brands
*/
const PaymentMethodIcons = {
  visa: VisaIcon,
  mastercard: MastercardIcon,
  amex: AmexIcon,
  discover: DiscoverIcon,
  generic: GenericIcon,
};

const getIcon = (paymentMethod) => {
  const icon = PaymentMethodIcons[paymentMethod] || PaymentMethodIcons.generic;
  return icon;
};

export { getIcon };
