import Stats from './Stats';

const FPS_TIMES_ARRAY_SIZE = 5;
const FPS_TOTAL_VALUES_MS = FPS_TIMES_ARRAY_SIZE * 1000;

class FPSStats extends Stats {
  constructor() {
    super();

    this.times = [];
    this.fps = 0;
  }

  calculateFPS() {
    const currentTime = (window.performance || Date).now();
    this.times.push(currentTime);

    if (this.times.length >= FPS_TIMES_ARRAY_SIZE) {
      const earliestTime = this.times.shift();
      const currentFPS = parseFloat((FPS_TOTAL_VALUES_MS / (currentTime - earliestTime)).toFixed(2));

      this.fps = currentFPS;

      // Add currentFPS value to the values history
      this.addValue(currentFPS);
    }

    return this.fps;
  }

  stats() {
    const {
      percentileHighestValue: percentileBestFPS,
      percentileLowestValue: percentileWorstFPS,
      ...otherValues
    } = super.stats();

    return {
      percentileBestFPS,
      percentileWorstFPS,
      ...otherValues,
    };
  }
}

export default FPSStats;
