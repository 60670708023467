const workoutStatus = {
  IDLE: 'IDLE',
  INITIALIZED: 'INITIALIZED',
  PREPARED: 'PREPARED',
  RUNNING: 'RUNNING',
  PAUSED: 'PAUSED',
  FINISHING: 'FINISHING',
  FINISHED: 'FINISHED',
  QUITTING: 'QUITTING',
  QUITTED: 'QUITTED',
};

export default workoutStatus;
