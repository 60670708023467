import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import format from 'string-template';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';

import { DateFormat } from '../../utils/date';
import { CURRENCIES } from '../../utils/currency';
import { getCurrencySymbol } from '../../utils/formatters';
import UserContract from '../../models/UserContract';

import {
  ContractContent,
  ContractTitle,
  Wrapper,
  HIGHLIGHT_CLASSNAME,
} from './styles';
import texts from './texts.json';

const ContractTerms = ({
  contractDoc,
  hideTitle,
  className,
}) => {
  const getAcceptedTermsDateCopy = () => {
    const { subscriptionCreatedAt } = contractDoc;
    if (!subscriptionCreatedAt) {
      return null;
    }

    const formattedDate = moment(subscriptionCreatedAt.toDate()).format(DateFormat.DATE_FORMAT_SLASH);

    return <p>{format(texts.termsAccepted, { date: formattedDate })}</p>;
  };

  const renderTerms = useCallback(() => {
    if (!contractDoc.terms || !contractDoc.terms.length) {
      return texts.noContractFound;
    }

    const datePattern = /\b(?:\w+)\s\d{1,2},\s\d{4}\b/g;

    // Build the price pattern based on the currencies we support.
    const currenciesPattern = CURRENCIES.map((currency) => {
      const symbol = getCurrencySymbol(currency.code);

      // If the currency symbol includes the dollar sign, then we need to escape it for the regex to work.
      return symbol.replaceAll('$', '\\$');
    }).join('|');

    const pricePattern = new RegExp(`(${currenciesPattern})\\d+(\\.\\d{2})?\\b`, 'g');
    const monthPattern = /\d+\smonth\(s\)/g;
    const wrapperPattern = `<span class="${HIGHLIGHT_CLASSNAME}">$&</span>`;

    return contractDoc.terms.map((paragraph) => {
      const highlightedText = paragraph.replaceAll(datePattern, wrapperPattern)
        .replaceAll(pricePattern, wrapperPattern)
        .replaceAll(monthPattern, wrapperPattern);
      return <p key={uuidv4()}>{ReactHtmlParser(highlightedText)}</p>;
    });
  }, [contractDoc]);

  return (
    <Wrapper className={className}>
      {!hideTitle && <ContractTitle>{texts.terms}</ContractTitle>}
      <ContractContent>
        {getAcceptedTermsDateCopy()}
        {renderTerms()}
      </ContractContent>
      {!!contractDoc?.coachConditions && (
        <ContractContent>
          {/* Adding ReactHtmlParser in case the custom conditions have html tags like <p>, and <br /> */}
          {ReactHtmlParser(contractDoc.coachConditions)}
        </ContractContent>
      )}
    </Wrapper>
  );
};

ContractTerms.propTypes = {
  contractDoc: PropTypes.instanceOf(UserContract),
  hideTitle: PropTypes.bool,
  className: PropTypes.string,
};

ContractTerms.defaultProps = {
  contractDoc: null,
  hideTitle: true,
  className: '',
};

export default compose(
  observer,
)(ContractTerms);
