import { ActivityTypes } from '../../../models/BaseActivity';

import ActivityTimeProcessor from './ActivityTimeProcessor';
import ActivityRepetitionsProcessor from './ActivityRepetitionsProcessor';

const getProcessorByType = (type) => {
  if (type === ActivityTypes.TIMED || type === ActivityTypes.REST) {
    return ActivityTimeProcessor;
  }
  return ActivityRepetitionsProcessor;
};

export default getProcessorByType;
