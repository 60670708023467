import styled from 'styled-components';
import { safeAreaFromBottom, safeAreaFromTop } from '../../styles/mixins';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: ${safeAreaFromTop()};
  padding-bottom: ${safeAreaFromBottom()};
`;

export {
  Container,
};
