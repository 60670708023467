import React from 'react';
import PropTypes from 'prop-types';

import WorkoutItem from '../WorkoutItem';
import MessageCard, { MessageCardType } from '../MessageCard';
import {
  Container,
} from './styles';

const CompletedWorkoutsCard = ({
  items,
  quote,
  description,
  showQuote,
}) => {
  const renderViewItem = ({
    props: {
      id,
      ...componentProps
    },
    component: CardComponent,
  }) => (
    <WorkoutItem
      key={id}
      $showCardBackground={showQuote}
    >
      <CardComponent
        {...componentProps}
      />
    </WorkoutItem>
  );

  return (
    <Container>
      {showQuote && (
        <MessageCard
          message={quote}
          description={description}
          type={MessageCardType.ACTIVE}
        />
      )}
      {items.map(renderViewItem)}
    </Container>
  );
};

CompletedWorkoutsCard.propTypes = {
  items: PropTypes.array.isRequired,
  quote: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  showQuote: PropTypes.bool.isRequired,
};

export default CompletedWorkoutsCard;
