import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import { nowInDateOnlyFormat } from '../../../../../../../utils/date';
import { MeasurementSystem, WeightUnit, convertUnits } from '../../../../../../../utils/measurement';
import UserContext from '../../../../../../../context/UserContext';
import {
  StyledNotesBelt,
  NotesTitle,
  NotesWrapper,
  WeightNote,
  RepsNote,
  Separator,
} from './styles';
import text from './text.json';

const NotesBelt = ({
  reps,
  weight,
  measurementSystem,
  date,
  onClick,
}) => {
  const showSeparator = !!reps && !!weight;
  const dateIsToday = nowInDateOnlyFormat() === date;

  const {
    userConfigDoc: {
      measurementSystem: userMeasurementSystem,
    },
  } = useContext(UserContext);

  const weightUnit = userMeasurementSystem === 'IMPERIAL' ? text.lbs : text.kgs;
  const weightValue = convertUnits(
    weight, WeightUnit[measurementSystem], WeightUnit[userMeasurementSystem],
  );

  return (
    <StyledNotesBelt onClick={onClick}>
      <NotesTitle>{dateIsToday ? text.todayValue : text.pastValue}</NotesTitle>
      <NotesWrapper>
        {!!weight && (
          <WeightNote>{`${weightValue} ${weightUnit}`}</WeightNote>
        )}
        {showSeparator && (
          <Separator />
        )}
        {!!reps && (
          <RepsNote>{`x${reps}`}</RepsNote>
        )}
      </NotesWrapper>
    </StyledNotesBelt>
  );
};

NotesBelt.propTypes = {
  reps: PropTypes.number,
  weight: PropTypes.number,
  measurementSystem: PropTypes.string,
  date: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

NotesBelt.defaultProps = {
  reps: 0,
  weight: 0,
  measurementSystem: MeasurementSystem.IMPERIAL,
  onClick: () => {},
};

export default compose(
  observer,
)(NotesBelt);
