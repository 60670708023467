const config = {
  type: 'line',
  data: {},
  options: {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          drawBorder: false,
          color: 'transparent',
        },
      },
      y: {
        grid: {
          drawBorder: false,
        },
      },
    },
  },
};

export default config;
