import styled from 'styled-components';

import colors from '../../../styles/baseColors';

const TextAreaWrapper = styled.div`
  display: flex;
`;

const StyledTextArea = styled.textarea`
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: ${colors.system.beta};
  background-color: ${colors.system.alpha};
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  display: flex;
  width: 100%;
  height: 100px;
  margin: 5px 0;
`;

export {
  TextAreaWrapper,
  StyledTextArea,
};
