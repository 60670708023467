import React, {
  useContext,
  useCallback,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import WorkoutExecutionInfoContext from '../../../../context/WorkoutExecutionInfoContext';
import CameraContext from '../../../../../../context/CameraContext';
import ActivityContext from '../../../../context/ActivityContext';
import GameplayContext from '../../../../context/GameplayContext';

import ActivityPanel from './ActivityPanel';

const ActivityPanelContainer = () => {
  const {
    onWorkoutPaused,
  } = useContext(GameplayContext);

  const {
    displayInfo: {
      name: activityName,
      goalType: activityGoalType,
      side: activitySide,
      remainingWorkoutValue,
      videoPreviewUrl,
      videoUrl,
    },
    isCurrentActivityRunning,
    activity,
  } = useContext(WorkoutExecutionInfoContext);

  const {
    setDisplayActivityDetails,
    activityExecutor: {
      isActivityRunning,
      logActivityEvent,
    },
  } = useContext(ActivityContext);

  const {
    isCameraEnabled,
  } = useContext(CameraContext);

  const showActivityDetails = useCallback(() => {
    if (!videoPreviewUrl && !videoUrl) {
      return;
    }

    logActivityEvent('showActivityDetailsOpen');
    onWorkoutPaused();
    setDisplayActivityDetails(true);
  }, [
    videoUrl,
    videoPreviewUrl,
    onWorkoutPaused,
    setDisplayActivityDetails,
    logActivityEvent,
  ]);

  return (
    <ActivityPanel
      activityGoalType={activityGoalType}
      remainingWorkoutValue={remainingWorkoutValue}
      activityName={activityName}
      isPlaying={isActivityRunning}
      isCurrentActivityRunning={isCurrentActivityRunning}
      videoPreviewUrl={videoPreviewUrl}
      showActivityDetails={showActivityDetails}
      isFullscreenVideoAvailable={!!videoPreviewUrl || !!videoUrl}
      activitySide={activitySide}
      isCameraEnabled={isCameraEnabled}
      isS2Activity={activity.isS2Activity}
      isCAActivity={activity.isCAActivity}
    />
  );
};

export default compose(
  observer,
)(ActivityPanelContainer);
