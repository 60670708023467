import { selfieVideoModes } from '../../../../components/SelfieVideo';

/**
 * Default activity configuration. This can be used as a base to extend when creating custom configurations
 * for specific activities or this will be used as the default one when no custom config is found for the
 * given activity type.
 *
 * Example of SelfieVideo component custom props:
 *
 *  const defaultActivityConfig = {
 *    selfieVideoContextProvider: {
 *      selfieVideoMode: selfieVideoModes.IMAGE_CAPTURE_ONLY,
 *    },
 *    selfieVideo: {
 *      showAlwaysOutline: false,
 *    },
 *  };
 */
const defaultActivityConfig = {
  // SelfieVideoContextProvider component custom props
  selfieVideoContextProvider: {
    selfieVideoMode: selfieVideoModes.IMAGE_CAPTURE_ONLY,
  },
};

const config = {
};

const getConfigByActivityType = (type) => (config[type] || defaultActivityConfig);

export default config;
export {
  getConfigByActivityType,
  defaultActivityConfig,
};
