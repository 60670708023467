import React from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';
import ReactHtmlParser from 'react-html-parser';

import {
  Container,
  Subtitle,
  CaloriesText,
  Divider,
  MacrosContainer,
  MacrosText,
} from './styles';
import texts from './texts.json';

const MacroGoals = ({
  className,
  totalCalories,
  protein,
  carbs,
  fat,
  proteinPercentage: percentProtein,
  carbsPercentage: percentCarbs,
  fatPercentage: percentFat,
}) => {
  const fatGrams = Math.round(fat);
  const proteinGrams = Math.round(protein);
  const carbsGrams = Math.round(carbs);

  const totalCaloriesText = format(texts.totalCalories, {
    totalDailyCalories: parseInt(totalCalories, 10),
  });

  return (
    <Container className={className}>
      <Subtitle>{texts.dailyRecommendedCalories}</Subtitle>
      <CaloriesText>{ReactHtmlParser(totalCaloriesText)}</CaloriesText>
      <Divider />
      <MacrosContainer>
        <Container>
          <Subtitle $secondary>{texts.protein}</Subtitle>
          <MacrosText>{format(texts.macroAmount, { amount: proteinGrams })}</MacrosText>
          <Subtitle>{format(texts.macroPercentage, { percentage: percentProtein })}</Subtitle>
        </Container>
        <Container>
          <Subtitle $secondary>{texts.carbs}</Subtitle>
          <MacrosText>{format(texts.macroAmount, { amount: carbsGrams })}</MacrosText>
          <Subtitle>{format(texts.macroPercentage, { percentage: percentCarbs })}</Subtitle>
        </Container>
        <Container>
          <Subtitle $secondary>{texts.fat}</Subtitle>
          <MacrosText>{format(texts.macroAmount, { amount: fatGrams })}</MacrosText>
          <Subtitle>{format(texts.macroPercentage, { percentage: percentFat })}</Subtitle>
        </Container>
      </MacrosContainer>
    </Container>
  );
};

MacroGoals.propTypes = {
  className: PropTypes.string,
  totalCalories: PropTypes.number.isRequired,
  protein: PropTypes.number.isRequired,
  carbs: PropTypes.number.isRequired,
  fat: PropTypes.number.isRequired,
  proteinPercentage: PropTypes.number.isRequired,
  carbsPercentage: PropTypes.number.isRequired,
  fatPercentage: PropTypes.number.isRequired,
};

MacroGoals.defaultProps = {
  className: '',
};

export default MacroGoals;
