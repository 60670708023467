import styled from 'styled-components';

const VideoContainer = styled.div`
  flex-basis: 60%;
  width: 334px;
  height: 209px;
  margin: auto;
`;

export {
  VideoContainer,
};
