import React, {
  useContext,
  useEffect,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { createGesture } from '@ionic/react';

import logEvent from '../../../../utils/logger';
import { isIOS } from '../../../../utils/platform';
import GameplaySessionContext from '../../../../context/GameplaySessionContext';
import { GameplayContextProvider } from '../../context/GameplayContext';
import { ActivityContextProvider } from '../../context/ActivityContext';
import GesturePriority from '../../utils/gesturePriorities';
import WorkoutInProgressContainer from '../WorkoutInProgress';
import { GameplayMenuContextProvider } from '../../context/GameplayMenuContext';

const ActiveGameplay = () => {
  const { gameplaySessionDoc } = useContext(GameplaySessionContext);

  useEffect(() => {
    logEvent('activeGameplayLoaded');

    let disableSwipeBackGesture;

    if (isIOS()) {
      /*
        NOTE: this is a hack to ignore/disable/override swipe back gesture that navigates
        back in the history, in this specific gameplay page. Review the gesture priority of
        swipe back gesture every time ionic framework is updated, as well as review this
        fake gesture if we need to add new gestures or support gestures in ionic components
        or new features.
      */
      disableSwipeBackGesture = createGesture({
        el: document.getElementById('gameplay-page'),
        threshold: 0,
        gestureName: 'disableSwipeBackGesture',
        gesturePriority: GesturePriority.SWIPE_BACK_OVERRIDE,
        onMove: () => {}, // Nothing to do
      });

      disableSwipeBackGesture.enable(true);
    }

    return () => {
      if (disableSwipeBackGesture) {
        disableSwipeBackGesture.destroy();
      }
    };
  }, []);

  return (
    <GameplayContextProvider initialGameplaySessionDoc={gameplaySessionDoc}>
      <ActivityContextProvider>
        <GameplayMenuContextProvider>
          <WorkoutInProgressContainer />
        </GameplayMenuContextProvider>
      </ActivityContextProvider>
    </GameplayContextProvider>
  );
};

export default compose(
  observer,
)(ActiveGameplay);
