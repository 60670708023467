import React, { useState } from 'react';
import PropTypes from 'prop-types';

import useCurrentLoggedInUser from '../../../../hooks/useCurrentLoggedInUser';
import {
  StyledDeleteButton,
  RowLabel,
  CommentWrapper,
  Comment,
  StyledCommentIcon,
} from './styles';
import { ClientCommentAlert, CoachCommentAlert } from './Comment';

const RowHeader = ({
  label,
  isHighlighted,
  enableComment,
  comment,
  onCommentUpdated,
  onDelete,
}) => {
  const [isCommentOpen, setIsCommentOpen] = useState(false);

  const { isCurrentLoggedInUserInPath } = useCurrentLoggedInUser();

  const openCommentAlert = () => setIsCommentOpen(true);
  const closeCommentAlert = () => setIsCommentOpen(false);

  const onCommentClick = () => {
    if (enableComment) {
      openCommentAlert();
    }
  };

  const onCommentChange = (value) => {
    closeCommentAlert();
    onCommentUpdated(value.comment);
  };

  const renderCommentComponent = () => {
    if (enableComment) {
      if (!comment && isCurrentLoggedInUserInPath) {
        return <StyledCommentIcon />;
      }

      return (
        <Comment>
          {comment}
        </Comment>
      );
    }

    return null;
  };

  const renderCommentAlert = () => {
    if (!enableComment) {
      return null;
    }

    const AlertComponent = isCurrentLoggedInUserInPath ? ClientCommentAlert : CoachCommentAlert;

    return (
      <AlertComponent
        isOpen={isCommentOpen}
        label={label}
        comment={comment}
        onCloseCommentAlert={closeCommentAlert}
        onCommentChange={onCommentChange}
      />
    );
  };

  return (
    <>
      {renderCommentAlert()}
      {onDelete && (
        <StyledDeleteButton onClick={onDelete} />
      )}
      <RowLabel $isHighlighted={isHighlighted}>
        {label}
      </RowLabel>
      <CommentWrapper onClick={onCommentClick}>
        {renderCommentComponent()}
      </CommentWrapper>
    </>
  );
};

RowHeader.propTypes = {
  label: PropTypes.string.isRequired,
  isHighlighted: PropTypes.bool,
  onDelete: PropTypes.func,
  enableComment: PropTypes.bool,
  comment: PropTypes.string,
  onCommentUpdated: PropTypes.func,
};

RowHeader.defaultProps = {
  isHighlighted: false,
  onDelete: null,
  enableComment: false,
  comment: '',
  onCommentUpdated: null,
};

export default RowHeader;
