import React, { useContext } from 'react';
import LoadingPage from '../../components/LoadingPage';

import SubscriptionContext from './SubscriptionContext';
import SubscriptionContextProvider from './SubscriptionContextProvider';

const withSubscriptionContextProvider = (Component) => (props) => (
  <SubscriptionContextProvider>
    <Component {...props} />
  </SubscriptionContextProvider>
);

const withSubscriptionContextReady = (Component) => (props) => {
  const { isReady } = useContext(SubscriptionContext);
  return isReady ? <Component {...props} /> : <LoadingPage fullHeight={false} />;
};

export {
  withSubscriptionContextProvider,
  withSubscriptionContextReady,
};
