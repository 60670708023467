import moment from 'moment';
import app from 'firebase/app';

import { HealthDataTypes } from '../../services/health';
import { CustomMetadataKey } from './metadata';

/**
 * Returns the heart rate data for a given period of time aggregated by minute.
 *
 * It returns for the given period of time:
 *
 * - Max Heart rate
 * - Min Heart Rate
 * - AVG Heart Rate
 * - Max/Min heart rate values per minute
 *
 * @param {Date} startDate The starting date
 * @param {Date} endDate The end date
 * @returns {Object} The heart rate data for the given period of time.
 */
const getHeartRateData = (startDate, endDate) => new Promise((resolve, reject) => {
  const options = {
    startDate,
    endDate,
    dataType: HealthDataTypes.HEART_RATE,
    limit: 0,
  };

  window.navigator.health.query(options, (dataPoints) => {
    if (dataPoints.length === 0) {
      resolve({});
    }

    let minHeartRate = Number.MAX_SAFE_INTEGER;
    let maxHeartRate = Number.MIN_SAFE_INTEGER;

    let allHeartRatesValues = 0;

    const heartRateDataPoints = {};

    dataPoints.forEach((dataPoint) => {
      const {
        // Heart rate values are taken in a snapshot point of time. startDate === endDate
        startDate: dataPointStartDate,
        value,
      } = dataPoint;

      const heartRateMinuteDate = moment(dataPointStartDate)
        .seconds(0);
      const heartRateMinuteDateId = heartRateMinuteDate.valueOf();

      heartRateDataPoints[heartRateMinuteDateId] = heartRateDataPoints[heartRateMinuteDateId] || {
        date: app.firestore.Timestamp.fromDate(heartRateMinuteDate.toDate()),
        minHeartRate: Number.MAX_SAFE_INTEGER,
        maxHeartRate: Number.MIN_SAFE_INTEGER,
      };

      const minHeartRateDataPoint = heartRateDataPoints[heartRateMinuteDateId].minHeartRate;
      const maxHeartRateDataPoint = heartRateDataPoints[heartRateMinuteDateId].maxHeartRate;

      heartRateDataPoints[heartRateMinuteDateId].minHeartRate = Math.min(value, minHeartRateDataPoint);
      heartRateDataPoints[heartRateMinuteDateId].maxHeartRate = Math.max(value, maxHeartRateDataPoint);

      minHeartRate = Math.min(value, minHeartRate);
      maxHeartRate = Math.max(value, maxHeartRate);

      allHeartRatesValues += value;
    });

    resolve({
      minHeartRate,
      maxHeartRate,
      averageHeartRate: Math.round(allHeartRatesValues / dataPoints.length),
      heartRateDataPoints: Object.entries(heartRateDataPoints)
        .sort((dataPoint, nextDataPoint) => dataPoint[0] - nextDataPoint[0])
        .map((dataPoint) => dataPoint[1]),
    });
  }, reject);
});

/**
 * Gets the basal energy burned for the given period of time.
 *
 * @param {Date} startDate The starting date.
 * @param {Date} endDate The end date.
 * @returns {Number} The basal energy burned in kcal.
 */
const getBasalEnergyBurned = (startDate, endDate) => new Promise((resolve, reject) => {
  const options = {
    startDate,
    endDate,
    dataType: HealthDataTypes.BASAL_CALORIES,
  };

  window.navigator.health.queryAggregated(options, (result) => {
    const { value } = result;
    resolve(value);
  }, reject);
});

/**
 * Calculates all extra fields a base activity needs to have be considered a full activity. This basically adds
 * all the extra info that can only be obtained by doing extra queries.
 *
 * @param {Object} baseActivity The base activity
 * @returns {Object} The full activity with all extra fields populated.
 */
const toFullActivity = async (baseActivity) => {
  const {
    id,
    value,
    startDate,
    endDate,
    calories,
    distance,
    duration,
    sourceBundleId,
    sourceName,
    metadata,
  } = baseActivity;

  const heartRateData = await getHeartRateData(startDate, endDate);

  const basalEnergyBurned = await getBasalEnergyBurned(startDate, endDate);

  const fullActivityData = {
    activityId: id,
    activityType: value,
    startDate: app.firestore.Timestamp.fromDate(startDate),
    endDate: app.firestore.Timestamp.fromDate(endDate),
    activeCalories: calories,
    basalEnergyBurned,
    distance,
    sourceBundleId,
    sourceName,
    ...heartRateData,
  };

  if (duration) {
    fullActivityData.duration = duration;
  }

  if (metadata) {
    fullActivityData.metadata = metadata;
  }

  return fullActivityData;
};

/**
 * It queries for activities for a given period of time.
 * @param {Date} startDate The start date
 * @param {Date} endDate The end date
 * @returns {Array} An array of activities with all the necessary data.
 */
const queryActivities = (startDate, endDate) => new Promise((resolve, reject) => {
  const options = {
    startDate,
    endDate,
    dataType: HealthDataTypes.WORKOUTS,
    metadataKeys: Object.values(CustomMetadataKey),
  };

  window.navigator.health.query(options, async (activities) => {
    const fullActivitiesPromises = activities.map((activity) => toFullActivity(activity));
    const fullActivities = await Promise.all(fullActivitiesPromises);
    resolve(fullActivities);
  }, reject);
});

/**
 * Query activity summary data for a given range of dates. Sync the result with System2 database.
 */
const queryActivitiesSummary = (startDate, endDate) => new Promise((resolve, reject) => {
  const options = {
    startDate,
    endDate,
  };
  navigator.health.queryActivitySummaryType(options, resolve, reject);
});

/**
 * Query the steps count for the given date.
 */
const queryDailyStepCount = (date) => new Promise((resolve, reject) => {
  const options = {
    startDate: moment(date).startOf('day').toDate(),
    endDate: moment(date).endOf('day').toDate(),
    dataType: HealthDataTypes.STEPS,
    bucket: 'day',
  };

  navigator.health.queryAggregated(options, resolve, reject);
});

export {
  queryActivitiesSummary,
  queryActivities,
  queryDailyStepCount,
};
