const browserOptions = {
  // Common settings
  location: 'no',
  // Android only settings
  zoom: 'no',
  hideurlbar: 'yes',
  footer: 'no',
  hardwareback: 'no',
  // iOS
  toolbar: 'no',
  disallowoverscroll: 'yes',
  hidespinner: 'yes',
};

const toStringOptions = (opts) => (
  Object.entries(opts).map(([key, value]) => `${key}=${value}`).join(',')
);

export default browserOptions;

export {
  toStringOptions,
};
