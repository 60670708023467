const TimeUnit = {
  SECONDS: 'SECONDS',
  MINUTES: 'MINUTES',
};

const SECONDS_PER_MINUTE = 60;

const convertSecondsTo = (timeInSeconds = 0, unit = TimeUnit.SECONDS) => (
  unit === TimeUnit.MINUTES ? timeInSeconds / SECONDS_PER_MINUTE : timeInSeconds
);

const getSecondsFrom = (time = 0, unit = TimeUnit.SECONDS) => (
  unit === TimeUnit.MINUTES ? time * SECONDS_PER_MINUTE : time
);

export {
  TimeUnit,
  convertSecondsTo,
  getSecondsFrom,
};
