import React from 'react';

import WatchContextProvider from './WatchContextProvider';

const withWatchContextProvider = (Component) => (props) => (
  <WatchContextProvider>
    <Component {...props} />
  </WatchContextProvider>
);

export {
  withWatchContextProvider,
};
