import React from 'react';

import ChatContextProvider from './ChatContextProvider';

const withChatContextProvider = (Component) => (props) => (
  <ChatContextProvider>
    <Component {...props} />
  </ChatContextProvider>
);

export default withChatContextProvider;
