import React from 'react';

import HealthDataSyncContextProvider from './HealthDataSyncContextProvider';

/**
 * Inserts HealthDataSyncContextProvider in the react tree
 * before adding the given component.
 *
 * @param {Object} Component The component that needs the context provider.
 * @returns {Object} The component to render wrapped with the provider.
 */
const withHealthDataSyncContextProvider = (Component) => (props) => (
  <HealthDataSyncContextProvider>
    <Component {...props} />
  </HealthDataSyncContextProvider>
);

export {
  withHealthDataSyncContextProvider,
};
