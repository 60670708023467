import React, {
  useContext,
  useCallback,
  useState,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import { ReactComponent as HistoryIcon } from '../../../../assets/icons/history-icon.svg';
import UserActivityHistoryContext from '../../../../context/UserActivityHistoryContext';
import UserContext from '../../../../context/UserContext';
import { nowInDateOnlyFormat } from '../../../../utils/date';
import { DEFAULT_MEASUREMENT_SYSTEM, MeasurementSystem } from '../../../../utils/measurement';
import useCurrentLoggedInUser from '../../../../hooks/useCurrentLoggedInUser';
import { ContentSwitcherContext } from '../../../ContentSwitcher';
import Thumbnail from '../../../Thumbnail';
import Header from '../../../Header';
import { LinkButton } from '../../../Button';
import SegmentsSelector from '../../../SegmentsSelector';
import WorkoutInfoSwitcherContext from '../../context/WorkoutInfoSwitcherContext';
import Section from '../../section';
import text from './text.json';

import ActivityLogRows from './ActivityLogRows';
import {
  StyledActivityLog,
  ActivityHeader,
  ActivityName,
  HeaderNavigation,
  PreviousIcon,
  NextIcon,
  TableHeader,
  HeaderDate,
  HeaderLabel,
  RowsWrapper,
  StyledListIcon,
  ActivityWrapper,
  SegmentsWrapper,
} from './styles';

const MEASUREMENT_OPTIONS = Object.values(MeasurementSystem).map((unit) => ({
  value: unit,
  label: text.MeasurementLabels[unit],
}));

const ActivityLog = () => {
  const {
    activityHistory,
    addHistoryValue,
  } = useContext(UserActivityHistoryContext);
  const { goToSectionByName } = useContext(ContentSwitcherContext);
  const {
    currentActivity: {
      name = '',
      videoPreviewThumbnail,
    },
    loadPreviousActivity,
    loadNextActivity,
  } = useContext(WorkoutInfoSwitcherContext);

  const {
    userConfigDoc: {
      measurementSystem: userMeasurementSystem,
      updateMeasurementSystem,
    },
  } = useContext(UserContext);

  const { isCurrentLoggedInUserInPath } = useCurrentLoggedInUser();

  const [measurementSystem, setMeasurementSystem] = useState(userMeasurementSystem || DEFAULT_MEASUREMENT_SYSTEM);

  const goToActivityHistory = () => {
    goToSectionByName(Section.ACTIVITY_HISTORY);
  };

  const goToWorkoutRoutine = () => {
    goToSectionByName(Section.WORKOUT_ROUTINE);
  };

  const onRowUpdate = useCallback(async (
    date,
    weight = 0,
    reps = 0,
    sets = [],
    comment = '',
    rowMeasurementSystem = measurementSystem,
  ) => {
    let historyData;
    if (sets.length) {
      historyData = {
        sets,
      };
    } else {
      // Date-related data
      historyData = {
        weight,
        reps,
        measurementSystem: rowMeasurementSystem,
      };
    }

    // Add the comment if provided
    historyData.comment = comment;

    await addHistoryValue(name, date, historyData);
  }, [
    addHistoryValue,
    name,
    measurementSystem,
  ]);

  const onMeasurementSystemChange = useCallback((measurementSystemValue) => {
    setMeasurementSystem(measurementSystemValue);

    if (isCurrentLoggedInUserInPath) {
      updateMeasurementSystem(measurementSystemValue);
    }
  }, [
    updateMeasurementSystem,
    isCurrentLoggedInUserInPath,
  ]);

  const getRowsData = useCallback(() => {
    const rowsDataArray = [];

    const todayDate = nowInDateOnlyFormat();

    // Get the doc for the current activity
    const doc = activityHistory[name];

    const todayEmptyRowData = {
      date: todayDate,
      reps: 0,
      weight: 0,
      sets: [],
      comment: '',
      measurementSystem,
    };

    // If there's no historyDoc, we enable today's date only.
    if (!doc) {
      rowsDataArray.push(todayEmptyRowData);
    } else {
      doc.history.forEach(({
        date,
        weight,
        reps,
        sets,
        comment,
        measurementSystem: rowMeasurementSystem,
      }, index) => {
        /*
          The first item should always be today's data. If the document has today's data already, then we just update
          the label shown. If the document does not contain today's data, we add a new empty row.
        */
        if (index === 0 && date !== todayDate) {
          rowsDataArray.push(todayEmptyRowData);
        }

        const rowData = {
          date,
          weight,
          reps,
          sets,
          comment,
        };

        if (rowMeasurementSystem) {
          rowData.measurementSystem = rowMeasurementSystem;
        }

        rowsDataArray.push(rowData);
      });
    }

    return rowsDataArray;
  }, [
    activityHistory,
    name,
    measurementSystem,
  ]);

  return (
    <StyledActivityLog>
      <ActivityWrapper>
        <Header
          title={text.title}
          LeftIcon={HistoryIcon}
          RightIcon={StyledListIcon}
          onLeftIconClick={goToActivityHistory}
          onRightIconClick={goToWorkoutRoutine}
        />
        <ActivityHeader>
          <HeaderNavigation>
            <LinkButton onClick={loadPreviousActivity}>
              <PreviousIcon />
            </LinkButton>
            {!!videoPreviewThumbnail && (
              <Thumbnail url={videoPreviewThumbnail} />
            )}
            <LinkButton onClick={loadNextActivity}>
              <NextIcon />
            </LinkButton>
          </HeaderNavigation>
          <ActivityName>{name}</ActivityName>
        </ActivityHeader>
        <SegmentsWrapper>
          <SegmentsSelector
            currentValue={measurementSystem}
            onChange={onMeasurementSystemChange}
            segments={MEASUREMENT_OPTIONS}
          />
        </SegmentsWrapper>
        <TableHeader>
          <HeaderDate>{text.date}</HeaderDate>
          <HeaderLabel>{text.weight}</HeaderLabel>
          <HeaderLabel>{text.reps}</HeaderLabel>
        </TableHeader>
      </ActivityWrapper>
      <RowsWrapper>
        <ActivityLogRows
          activityName={name}
          rowsData={getRowsData()}
          onRowUpdate={onRowUpdate}
          measurementSystem={measurementSystem}
        />
      </RowsWrapper>
    </StyledActivityLog>
  );
};

export default compose(
  observer,
)(ActivityLog);
