import format from 'string-template';
import { Collection } from 'firestorter';

import { CalendlyType } from '../utils/calendlyUtil';
import {
  firestorePaths,
  pathPlaceholder,
} from '../utils/firebasePaths';

import User from './User';
import BaseDocument from './BaseDocument';

class Coach extends BaseDocument {
  get name() {
    return this.data.name;
  }

  get avatar() {
    const {
      avatarUrl = '',
    } = this.data;

    return avatarUrl;
  }

  get calendlySchedulerURL() {
    const { calendlyURLs } = this.data;
    return calendlyURLs?.[CalendlyType.FOLLOW_UP_CALL] || calendlyURLs?.[CalendlyType.DEFAULT];
  }

  get mealPlanConfig() {
    return this.data.mealPlanConfig || {};
  }

  get coachAssistant() {
    return this.data.coachAssistant || {};
  }

  static async isCoach(userId) {
    const coachDoc = new Coach(format(firestorePaths.COACH_DOC, {
      [pathPlaceholder.COACH_ID]: userId,
    }));

    await coachDoc.init();

    return coachDoc.exists;
  }

  static async getBroadcastMessageUsers(coachId) {
    const userCollection = new Collection(firestorePaths.USER,
      {
        query: (ref) => ref
          .where('assignedCoach', '==', coachId)
          .where('breakEndDate', '<', new Date())
          .where('flags.isActive', '==', true),
        createDocument: (source, options) => new User(source, options),
      });
    await User.initCollection(userCollection);
    return userCollection.hasDocs ? userCollection.docs : [];
  }
}

export default Coach;
