import React, {
  useMemo,
  useState,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import * as Sentry from '@sentry/browser';
import { CameraStatus, VideoStreamConnectionEvent } from 'capacitor-camera-stream';
import { Network } from '@capacitor/network';

import logEvent from '../../../../utils/logger';
import { VideoStreamStatus } from '../../../../models/GameplaySession';
import WorkoutExecutor, { workoutStatus } from '../../../../services/workoutExecutor';
import WorkoutContext, { withWorkoutContextReady } from '../../../../context/WorkoutContext';
import CameraContext from '../../../../context/CameraContext';
import AppContext from '../../../../context/AppContext';
import useGameplayNavigation from '../../../../hooks/useGameplayNavigation';
import useWorkoutNavigation from '../../../../hooks/useWorkoutNavigation';
import useVideoStream from '../../../../hooks/useVideoStream';
import FirebaseContext, { withFirebase } from '../../../../context/FirebaseContext';
import useAppCustomization from '../../../../hooks/useAppCustomization';
import { Feature } from '../../../../context/AppCustomizationContext';

import useGameplayGoBack from '../../hooks/useGameplayGoBack';
import VideoStreamPreparingStatus from '../../utils/videoStreamStatus';
import GameplayContext from './GameplayContext';

/**
 * The GameplayContextProvider orchestrates the execution of the entire gameplay experience and holds general
 * configurations that will be used during the gameplay.
 *
 * The "workout" is considered to be the execution of a series of activities.
 *
 * The workout is started when the first activity starts and finishes once the latest activity ends.
 */
const GameplayContextProvider = ({
  children,
  gameplayStore,
  initialGameplaySessionDoc,
  firebase: {
    firestore,
  },
}) => {
  const { navigateToWorkout } = useWorkoutNavigation();
  const { navigateToGameplaySession } = useGameplayNavigation();
  const navigateBack = useGameplayGoBack();
  const { prepareSessionForGameplaySession } = useVideoStream();
  const { workoutAssignmentDoc, activitiesWithOverrides } = useContext(WorkoutContext);
  const {
    camera,
    isCameraEnabled,
  } = useContext(CameraContext);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const { isFeatureEnabled } = useAppCustomization();

  const [workoutExecutor] = useState(() => new WorkoutExecutor(
    gameplayStore,
    workoutAssignmentDoc,
    activitiesWithOverrides,
    initialGameplaySessionDoc,
    {
      isVideoRequestEnabled: !camera.isVideoStreamEnabled && isCameraEnabled,
      notifyCoachOnGameplayActions: isFeatureEnabled(Feature.CHAT),
    },
    firestore,
  ));

  const {
    debugSettings: {
      isDebugMode,
    },
  } = useContext(AppContext);

  const { firebase: { remote } } = useContext(FirebaseContext);

  // Video stream related references
  const videoStreamSessionRef = useRef(null);
  const videoStreamPreparingStatusRef = useRef(VideoStreamPreparingStatus.NOT_PREPARED);

  // Gameplay actions detection UI elements ref required to determine processing area
  const cameraViewportRef = useRef(null);
  const actionElementRef = useRef(null);

  const onWorkoutStart = useCallback(() => {
    if (workoutExecutor.state === workoutStatus.IDLE) {
      const isStoreWorkoutFramesEnabled = isDebugMode || !camera.isVideoStreamEnabled;

      workoutExecutor.initialize({
        isStoreWorkoutFramesEnabled,
        isCameraEnabled,
      });
    }
  }, [
    workoutExecutor,
    isDebugMode,
    camera.isVideoStreamEnabled,
    isCameraEnabled,
  ]);

  const onGameplayActivityStarted = useCallback(async () => {
    if (workoutExecutor.state === workoutStatus.PREPARED) {
      navigateToGameplaySession({
        workoutAssignmentId: workoutAssignmentDoc.id,
        gameplaySessionId: gameplayStore.gameplaySessionId,
      }, {
        shouldReplaceURL: true,
      });

      workoutExecutor.start();
    }
  }, [
    workoutAssignmentDoc.id,
    navigateToGameplaySession,
    workoutExecutor,
    gameplayStore.gameplaySessionId,
  ]);

  const onGameplayActivityFinished = useCallback(async () => {
    await workoutExecutor.toNextActivity(remote);
  }, [
    workoutExecutor,
    remote,
  ]);

  const onWorkoutPaused = useCallback(async () => {
    const { isWorkoutPaused } = workoutExecutor;
    if (!isWorkoutPaused) {
      // Pause video streaming/uploading when the workout is paused
      camera.pauseStream();
      if (camera.isVideoStreamEnabled) {
        workoutExecutor.logWorkoutEvent('cameraVideoStreamPaused');
      }
      await workoutExecutor.pause();
    }
  }, [
    camera,
    workoutExecutor,
  ]);

  const onWorkoutResumed = useCallback(async () => {
    const { isWorkoutRunning } = workoutExecutor;
    if (!isWorkoutRunning) {
      // Resume video streaming/uploading when the workout is resumed
      camera.resumeStream();
      if (camera.isVideoStreamEnabled) {
        workoutExecutor.logWorkoutEvent('cameraVideoStreamResumed');
      }
      await workoutExecutor.resume();
    }
  }, [
    camera,
    workoutExecutor,
  ]);

  const onGameplayActivityQuitted = useCallback(async () => {
    // Once an activity is quitted, we're quitting the workout as well
    workoutExecutor.quit();

    setShouldRedirect(true);
  }, [
    workoutExecutor,
  ]);

  const onShowResults = useCallback(() => {
    logEvent('showResults');
    setShouldRedirect(true);
  }, []);

  /**
   * Prepare and start Vonage video session:
   * - Acquire an OpenTok session ID and token for the current GameplaySession document
   * - Make sure the GameplaySession document is updated with the OpenTok session ID to use
   * - Start the video stream/video uploading
   *
   * Start preparing OpenTok session ID/token and start the streaming session only when the
   * status is not PREPARING or DONE. The statuses that allows to execute this preparation steps
   * and starting the streaming are NOT_PREPARED and ERROR. ERROR state is not an ending state, if
   * later the execution flow determines that "startVideoStreaming" should be called even when the
   * current status is ERROR, transitions from ERROR state PREPARING are allowed, as a way of retrying
   * later in a future point.
   */
  const startVideoStreaming = useCallback(async () => {
    if (videoStreamPreparingStatusRef.current === VideoStreamPreparingStatus.PREPARING
      || videoStreamPreparingStatusRef.current === VideoStreamPreparingStatus.DONE
    ) {
      return;
    }

    workoutExecutor.logWorkoutEvent('videoStreamPreparingSessionAndToken');
    videoStreamPreparingStatusRef.current = VideoStreamPreparingStatus.PREPARING;

    const gameplaySessionDoc = gameplayStore.getGameplaySessionDoc();
    const { isNewGameplaySession } = gameplayStore;

    try {
      const result = await prepareSessionForGameplaySession(gameplaySessionDoc, isNewGameplaySession);

      if (result) {
        videoStreamSessionRef.current = result;
        workoutExecutor.logWorkoutEvent('videoStreamSessionAndTokenReady');

        const { sessionId, token } = result;
        await camera.startStream(sessionId, token);

        gameplaySessionDoc.update({
          videoStreamStatus: VideoStreamStatus.PREPARED,
        });
        videoStreamPreparingStatusRef.current = VideoStreamPreparingStatus.PREPARED;

        workoutExecutor.logWorkoutEvent('cameraStartStreamCalled');
      } else {
        videoStreamPreparingStatusRef.current = VideoStreamPreparingStatus.ERROR;
        gameplaySessionDoc.update({
          videoStreamStatus: VideoStreamStatus.START_STREAM_FAILED,
        });

        workoutExecutor.logWorkoutEvent('videoStreamSessionAndTokenNotGenerated');
      }
    } catch (error) {
      videoStreamPreparingStatusRef.current = VideoStreamPreparingStatus.ERROR;
      gameplaySessionDoc.update({
        videoStreamStatus: VideoStreamStatus.START_STREAM_FAILED,
      });

      Sentry.captureException(error, {
        extra: {
          description: 'Error when preparing video session connection',
          isNewGameplaySession,
          gameplaySessionDocId: gameplaySessionDoc.id,
        },
      });
    }
  }, [
    workoutExecutor,
    prepareSessionForGameplaySession,
    gameplayStore,
    camera,
  ]);

  /**
   * We can't resume a gameplay if it's already DONE!
   */
  useEffect(() => {
    if (workoutExecutor.state === workoutStatus.IDLE
      && initialGameplaySessionDoc
      && initialGameplaySessionDoc.isDone) {
      navigateToWorkout(workoutAssignmentDoc.id, undefined, { shouldReplaceURL: true });
    }
  }, [
    initialGameplaySessionDoc,
    navigateToWorkout,
    workoutAssignmentDoc.id,
    workoutExecutor.state,
  ]);

  useEffect(() => {
    if (shouldRedirect) {
      // The useGameplayGoBack custom hook knows how to handle back navigation
      navigateBack();
    }
  }, [
    shouldRedirect,
    navigateBack,
  ]);

  /**
   * Effect that handles the initial video session preparation and starting streaming video.
   */
  useEffect(() => {
    if (camera.isVideoStreamEnabled
      && isCameraEnabled
      // Only allow the status to be NOT_PREPARED
      && videoStreamPreparingStatusRef.current === VideoStreamPreparingStatus.NOT_PREPARED
      && camera.cameraStatus === CameraStatus.started
      && workoutExecutor.state !== workoutStatus.IDLE
    ) {
      startVideoStreaming();
    }
  }, [
    camera.isVideoStreamEnabled,
    camera.cameraStatus,
    isCameraEnabled,
    startVideoStreaming,
    workoutExecutor.state,
    workoutExecutor,
  ]);

  /**
   * Handle INITIALIZED status. The workout transitions to the PREPARED state and starts the processing
   * of preparing for video uploading/streaming.
   */
  useEffect(() => {
    if (workoutExecutor.state === workoutStatus.INITIALIZED) {
      workoutExecutor.prepare();
    }
  }, [
    workoutExecutor.state,
    workoutExecutor,
  ]);

  /**
   * Setup event listeners for the camera video stream status updates, publisher status updates and errors related to
   * video uploading/stream. The goal is to keep track of these events and status changes in Amplitude to monitor
   * and troubleshoot the new video upload functionality.
   * To track these events, workoutExecutor.logWorkoutEvent function is used in order to get all the gameplay, activity
   * and workout data at the exact moment in which the stream status and/or publisher status events are received.
   */
  useEffect(() => {
    let videoConnectionChangeListener;
    let publisherStatusListener;
    let errorListener;
    let networkStatusListener;
    let cameraGeneralErrorListener;

    if (isCameraEnabled && camera.isVideoStreamEnabled) {
      const onVideoStreamConnectionChange = (eventData) => {
        workoutExecutor.logWorkoutEvent('cameraVideoStreamConnectionChange', eventData);

        const { event } = eventData;
        let gameplayVideoStreamStatus;

        switch (event) {
          case VideoStreamConnectionEvent.sessionConnected:
          case VideoStreamConnectionEvent.sessionReconnected:
            gameplayVideoStreamStatus = VideoStreamStatus.SESSION_CONNECTED;
            break;
          case VideoStreamConnectionEvent.sessionFailedToConnect:
            gameplayVideoStreamStatus = VideoStreamStatus.SESSION_FAILED_CONNECT;
            break;
          default:
            gameplayVideoStreamStatus = null;
        }

        if (gameplayVideoStreamStatus) {
          const gameplaySessionDoc = gameplayStore.getGameplaySessionDoc();
          gameplaySessionDoc.update({
            videoStreamStatus: gameplayVideoStreamStatus,
          });
        }
      };

      const onVideoPublisherStatusUpdate = (eventData) => {
        workoutExecutor.logWorkoutEvent('cameraVideoPublisherStatusUpdate', eventData);
      };

      /*
        Handles video stream errors. Critical errors requires our attention, so use Sentry for that.The app should work
        as usual, video creation and upload might not work. Reporting to Sentry is required in order to monitor,
        troubleshoot and understand when this errors happen in order to propose a proper solution.
      */
      const onVideoStreamError = ({ errorCode, errorMessage, isCritical }) => {
        if (isCritical) {
          Sentry.captureException(new Error(`CameraVideoStreamError: ${errorCode}, ${errorMessage}`));
        } else {
          workoutExecutor.logWorkoutEvent('cameraVideoStreamError', {
            errorCode,
            errorMessage,
          });
        }
      };

      /*
        Handle network status changes, when the user switches bewteen networks, disconnects and connect. When
        the network status is connected and the conditions are met, a connection to the streaming platform
        should be done in order to keep recording the workout. The conditions are:
          - network status is connected
          - the workout executor is in one of the running states: RUNNING or PAUSED and,
          - the video stream session is one of the states that requires performing a connection again
      */
      const onNetworkStatusChange = async (networkStatus) => {
        workoutExecutor.logWorkoutEvent('gameplayNetworkStatusChange', {
          networkStatus,
        });

        const isWorkoutInRunningStates = workoutExecutor.isWorkoutRunning || workoutExecutor.isWorkoutPaused;

        if (networkStatus.connected && isWorkoutInRunningStates) {
          const shouldReconnect = await camera.shouldReconnectVideoStreamSession();

          if (shouldReconnect) {
            // When the session information exists, reuse it
            if (videoStreamSessionRef.current) {
              workoutExecutor.logWorkoutEvent('videoStreamReconnectOnNetworkStatusChange');

              const { sessionId, token } = videoStreamSessionRef.current;
              try {
                await camera.startStream(sessionId, token);
              } catch (error) {
                Sentry.captureException(error, {
                  extra: {
                    description: 'Error trying to reconnect Vonage session by calling camera.startStream',
                  },
                });
              }
            } else {
              /*
                When no session information exists, means the user started the gameplay offline, and then connects
                to internet, so execute the "onPrepareVideoSession" to acquire a session ID/token and start
                the video streaming process
              */
              workoutExecutor.logWorkoutEvent('videoStreamConnectOnNetworkStatusChange');
              videoStreamPreparingStatusRef.current = VideoStreamPreparingStatus.NOT_PREPARED;

              try {
                await startVideoStreaming();
              } catch (error) {
                workoutExecutor.logWorkoutEvent('videoStreamConnectOnNetworkStatusChangeFailed');
                Sentry.captureException(error, {
                  extra: {
                    description: 'Error on video streaming start',
                  },
                });
              }
            }
          }
        }
      };

      const onCameraGeneralError = async (result) => {
        const { errorMessage } = result || { errorMessage: 'Unknown error while using the camera' };
        Sentry.captureMessage(errorMessage, {
          extra: {
            pluginEvent: 'cameraGeneralError',
          },
        });
      };

      videoConnectionChangeListener = camera.addVideoStreamConnectionChangeListener(onVideoStreamConnectionChange);
      publisherStatusListener = camera.addVideoPublisherStatusListener(onVideoPublisherStatusUpdate);
      errorListener = camera.addVideoStreamErrorListener(onVideoStreamError);
      networkStatusListener = Network.addListener('networkStatusChange', onNetworkStatusChange);
      cameraGeneralErrorListener = camera.addCameraGeneralErrorListener(onCameraGeneralError);
    }

    return () => {
      if (videoConnectionChangeListener) {
        videoConnectionChangeListener.remove();
      }
      if (publisherStatusListener) {
        publisherStatusListener.remove();
      }
      if (errorListener) {
        errorListener.remove();
      }
      if (networkStatusListener) {
        networkStatusListener.remove();
      }
      if (cameraGeneralErrorListener) {
        cameraGeneralErrorListener.remove();
      }
    };
  }, [
    camera,
    isCameraEnabled,
    workoutExecutor,
    gameplayStore,
    startVideoStreaming,
  ]);

  /**
   * Stops the camera stream if enabled (controlled by the Camera provider) when the WorkoutExecutor
   * transitions to the FINISHED state.
   */
  useEffect(() => {
    if (workoutExecutor.isWorkoutFinished) {
      camera.stopStream();
    }
  }, [
    workoutExecutor.isWorkoutFinished,
    camera,
  ]);

  /**
   * This useEffect is being used as a component will unmount lifecycle callback.
   */
  useEffect(() => (
    () => {
      workoutExecutor.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);

  const context = useMemo(() => ({
    // Workout Executor
    workoutExecutor,
    onWorkoutStart,
    onWorkoutPaused,
    onWorkoutResumed,

    // Activities actions
    onGameplayActivityStarted,
    onGameplayActivityFinished,
    onGameplayActivityQuitted,

    // Gameplay Store
    gameplayStore,

    // Page navigation
    onShowResults,

    // Gameplay actions processing area ref
    cameraViewportRef,
    actionElementRef,
  }), [
    workoutExecutor,
    onWorkoutStart,
    onWorkoutPaused,
    onWorkoutResumed,
    onGameplayActivityStarted,
    onGameplayActivityFinished,
    onGameplayActivityQuitted,
    gameplayStore,
    onShowResults,
  ]);

  return (
    <GameplayContext.Provider value={context}>
      {children}
    </GameplayContext.Provider>
  );
};

GameplayContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  gameplayStore: PropTypes.object.isRequired,
  initialGameplaySessionDoc: PropTypes.object,
  firebase: PropTypes.shape({
    firestore: PropTypes.object.isRequired,
  }).isRequired,
};

GameplayContextProvider.defaultProps = {
  initialGameplaySessionDoc: null,
};

export default compose(
  inject('gameplayStore'),
  withWorkoutContextReady,
  withFirebase,
  observer,
)(GameplayContextProvider);
