import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Option,
} from './styles';

const ViewSelector = ({
  selected,
  setSelected,
  options,
}) => {
  const handleClick = (mealSelected) => {
    setSelected(mealSelected);
  };

  return (
    <Container>
      {options.map((option) => (
        <Option
          $isActive={selected.value === option.value}
          onClick={() => handleClick(option)}
          key={option.value}
        >
          {option.name.toUpperCase()}
        </Option>
      ))}
    </Container>
  );
};

ViewSelector.propTypes = {
  selected: PropTypes.shape({
    value: PropTypes.any.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  setSelected: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
};

export default ViewSelector;
