import React from 'react';
import PropTypes from 'prop-types';

import ReportRecipeSlider from '../../../components/ReportRecipeSlider/ReportRecipeSlider';

import {
  Card,
  CardTitle,
} from '../commonStyles';

import {
  Container,
  Item,
  Indicator,
} from './styles';
import texts from './texts.json';

const InstructionsCard = ({
  instructions,
  recipeId,
  allowReport,
}) => (
  <Card>
    <CardTitle>{texts.title}</CardTitle>
    <Container>
      {instructions.map((text, index) => {
        const count = index + 1;
        return (
          <Item key={text}>
            <Indicator>{`${count}.`}</Indicator>
            <div>{text}</div>
          </Item>
        );
      })}
      {allowReport && <ReportRecipeSlider recipeId={recipeId} />}
    </Container>
  </Card>
);

InstructionsCard.propTypes = {
  instructions: PropTypes.array.isRequired,
  recipeId: PropTypes.string.isRequired,
  allowReport: PropTypes.bool,
};

InstructionsCard.defaultProps = {
  allowReport: false,
};

export default InstructionsCard;
