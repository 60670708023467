import BaseDocument from './BaseDocument';

class TrackedActivity extends BaseDocument {
  get activityType() {
    return this.data.activityType;
  }

  get name() {
    return TrackedActivity.getActivityName(this.activityType);
  }

  get startDate() {
    return this.data.startDate;
  }

  get endDate() {
    return this.data.endDate;
  }

  get duration() {
    return this.data.duration || 0;
  }

  get activeCalories() {
    return this.data.activeCalories || 0;
  }

  get basalEnergyBurned() {
    return this.data.basalEnergyBurned || 0;
  }

  get totalEnergyBurned() {
    return this.basalEnergyBurned + this.activeCalories;
  }

  get distance() {
    return this.data.distance || 0;
  }

  get minHeartRate() {
    return this.data.minHeartRate;
  }

  get maxHeartRate() {
    return this.data.maxHeartRate;
  }

  get averageHeartRate() {
    return this.data.averageHeartRate;
  }

  get heartRateDataPoints() {
    return this.data.heartRateDataPoints;
  }

  get sourceBundleId() {
    return this.data.sourceBundleId;
  }

  get sourceName() {
    return this.data.sourceName;
  }

  get customTitle() {
    return this.data.customTitle;
  }

  get comment() {
    return this.data.comment;
  }

  get attachments() {
    return this.data.attachments || [];
  }

  get heartRateMeasurements() {
    const points = this.heartRateDataPoints || [];
    return points.map(({ date, minHeartRate, maxHeartRate }) => ({
      minHeartRate,
      maxHeartRate,
      date: date.toDate(),
    }));
  }

  hasSameHeartRateDataPoints(dataPoints = []) {
    const currentHeartRateDataPoints = this.heartRateDataPoints || [];

    if (currentHeartRateDataPoints.length !== dataPoints.length) {
      return false;
    }

    // Check data point by data point. If there's any difference then the arrays are not the same.
    return !(currentHeartRateDataPoints.some((heartRateDataPoint, index) => {
      const {
        date,
        minHeartRate,
        maxHeartRate,
      } = heartRateDataPoint;

      const {
        date: dataPointDate,
        minHeartRate: dataPointMinHeartRate,
        maxHeartRate: dataPointMaxHeartRate,
      } = dataPoints[index];

      const heartRateChecking = (date.toDate() - dataPointDate.toDate()) !== 0
        || minHeartRate !== dataPointMinHeartRate
        || maxHeartRate !== dataPointMaxHeartRate;

      return heartRateChecking;
    }));
  }

  hasSameAttachments(attachments = []) {
    const currentAttachments = this.attachments;

    if (currentAttachments.length !== attachments.length) {
      return false;
    }

    return currentAttachments.every((attachment, index) => attachment === attachments[index]);
  }

  hasSameData(data) {
    const {
      activityType,
      startDate,
      endDate,
      activeCalories,
      basalEnergyBurned,
      distance,
      minHeartRate,
      maxHeartRate,
      averageHeartRate,
      heartRateDataPoints,
      duration,
      sourceBundleId,
      sourceName,
      customTitle,
      comment,
      attachments,
    } = data;

    return this.activityType === activityType
      && (this.startDate.toDate() - startDate.toDate()) === 0
      && (this.endDate.toDate() - endDate.toDate()) === 0
      && this.activeCalories === activeCalories
      && this.duration === duration
      && this.distance === distance
      && this.minHeartRate === minHeartRate
      && this.maxHeartRate === maxHeartRate
      && this.averageHeartRate === averageHeartRate
      && this.sourceBundleId === sourceBundleId
      && this.sourceName === sourceName
      && this.customTitle === customTitle
      && this.comment === comment
      && this.basalEnergyBurned === basalEnergyBurned
      && this.hasSameAttachments(attachments)
      && this.hasSameHeartRateDataPoints(heartRateDataPoints);
  }

  static getActivityName(activityType) {
    if (activityType.length > 0) {
      return activityType[0].toUpperCase() + activityType.replace(/_|\./g, ' ').slice(1);
    }
    return '';
  }

  static sanitizeData(activityFields) {
    const sanitizedFields = {
      ...activityFields,
    };

    if (Object.hasOwnProperty.call(sanitizedFields, 'distance') && typeof sanitizedFields.distance !== 'number') {
      delete sanitizedFields.distance;
    }

    if (Object.hasOwnProperty.call(sanitizedFields, 'duration') && typeof sanitizedFields.duration !== 'number') {
      delete sanitizedFields.duration;
    }

    if (Object.hasOwnProperty.call(sanitizedFields, 'activeCalories')
      && typeof sanitizedFields.activeCalories !== 'number'
    ) {
      delete sanitizedFields.activeCalories;
    }

    if (Object.hasOwnProperty.call(sanitizedFields, 'basalEnergyBurned')
      && typeof sanitizedFields.basalEnergyBurned !== 'number'
    ) {
      delete sanitizedFields.basalEnergyBurned;
    }

    return sanitizedFields;
  }
}

export default TrackedActivity;
