import React, {
  useCallback,
  useState,
  useEffect,
  useContext,
  useMemo,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { forEachSeries } from 'p-iteration';
import * as Sentry from '@sentry/browser';

import CheckIn from '../../../models/CheckIn';
import useStorage from '../../../hooks/useStorage';
import { getYearAndWeekFromDate } from '../../../utils/date';
import AppCustomization from '../../../context/AppCustomizationContext/AppCustomizationContext';
import { fileExists } from '../../../services/appCustomization/deviceStorage';
import {
  Container as InputContainer,
  Title,
  PageContainer,
} from '../styles';

import ImageUpload from './components/ImageUpload';
import texts from './texts.json';
import {
  StyledStatusInput,
  ImageContainer,
} from './styles';
import { checkInImageConfig } from './util';

const CheckInImages = ({
  currentCheckInDoc,
  updateDocumentFields,
  currentSelectedDate,
  shouldAllowActions,
  getImageFile,
}) => {
  const [checkInImages, setCheckInImages] = useState({});
  const [checkInQuestions, setCheckInQuestions] = useState(currentCheckInDoc.questions);
  const { checkInQuestionDefinition, checkInQuestionList } = useContext(AppCustomization);

  const {
    getBlobUrls,
  } = useStorage();

  const CHECK_IN_IMAGES_PATH = 'checkIn/images';

  const handleFileSelection = useCallback(async (data) => {
    const imageFormat = data.format?.includes(texts.JPG) ? texts.JPEG : data.format;
    const contentType = `${texts.image}/${imageFormat}`;
    const updatedData = {
      ...data,
      week: currentCheckInDoc.week,
      year: currentCheckInDoc.year,
      contentType,
    };
    const imagePath = `${CHECK_IN_IMAGES_PATH}/${updatedData.imageType}.${updatedData.format}`;
    try {
      await Filesystem.writeFile({
        path: imagePath,
        data: updatedData.dataUrl,
        recursive: true,
        directory: Directory.Data,
        encoding: Encoding.UTF8,
      });

      updatedData.dataUrl = imagePath;
      localStorage.setItem(data.imageType, JSON.stringify(updatedData));
    } catch (error) {
      Sentry.captureException({
        user: currentCheckInDoc.user,
        error,
      });
    }
    setCheckInImages((prev) => ({
      ...prev,
      [data.imageType]: data.dataUrl,
    }));
  }, [
    currentCheckInDoc,
  ]);

  // save check in questions on unmount
  useEffect(() => {
    const updatedCheckInQuestions = checkInQuestions;
    return () => {
      updateDocumentFields({
        questions: updatedCheckInQuestions,
      });
    };
  }, [
    updateDocumentFields,
    checkInQuestions,
  ]);

  useEffect(() => {
    if (currentCheckInDoc && currentCheckInDoc.questions) {
      setCheckInQuestions(currentCheckInDoc.questions);
    }
  }, [
    currentCheckInDoc,
  ]);

  const activeCheckInQuestionList = useMemo(() => (
    checkInQuestionList.filter((question) => !!checkInQuestionDefinition[question]?.active)
  ), [
    checkInQuestionList,
    checkInQuestionDefinition,
  ]);

  useEffect(() => {
    setCheckInImages({});
    const init = async () => {
      let attachmentData = [];
      const attachments = currentCheckInDoc.attachments || [];
      if (attachments.length > 0) {
        const blobUrls = await getBlobUrls(attachments.filter((attachment) => attachment));
        attachmentData = attachments.map((attachment) => blobUrls
          .find((blobUrl) => blobUrl.storagePath === attachment));
      }

      const images = {};
      await forEachSeries(Object.keys(checkInImageConfig), (async (type, index) => {
        if (attachmentData[index]) {
          images[type] = attachmentData[index]?.dataUrl || '';
        } else {
          const imageData = localStorage.getItem(type);

          if (imageData) {
            const parsedImageData = JSON.parse(imageData);
            const { year, week } = getYearAndWeekFromDate(currentSelectedDate);
            const imageFile = await getImageFile(parsedImageData.dataUrl);
            images[type] = ((parsedImageData.year === year) && (parsedImageData.week === week))
              ? imageFile
              : '';
          }
        }
      }));
      setCheckInImages(images);
    };

    init();
  }, [
    currentCheckInDoc,
    getBlobUrls,
    currentSelectedDate,
    getImageFile,
  ]);

  const onImageDelete = useCallback(async (type) => {
    const imageData = localStorage.getItem(type);
    const parsedImageData = JSON.parse(imageData);
    if (parsedImageData) {
      const doesFileExist = await fileExists(parsedImageData.dataUrl);
      if (doesFileExist) {
        await Filesystem.deleteFile({
          path: parsedImageData.dataUrl,
          directory: Directory.Data,
        });
      }
    }
    localStorage.removeItem(type);
    const attachments = currentCheckInDoc.attachments.slice();
    if (attachments[checkInImageConfig[type].index]) {
      attachments[checkInImageConfig[type].index] = '';
    }
    await updateDocumentFields({
      attachments,
    });
    setCheckInImages((prev) => ({
      ...prev,
      [type]: '',
    }));
  }, [
    currentCheckInDoc.attachments,
    updateDocumentFields,
  ]);

  const handleChange = useCallback((event, checkInObject) => {
    const { value } = event.target;
    setCheckInQuestions((prev) => ({
      ...prev,
      [checkInObject.id]: {
        ...checkInObject,
        value,
      },
    }));
  }, []);

  return (
    <PageContainer>
      <InputContainer>
        <Title>{texts.pictures}</Title>
        <ImageContainer>
          {Object.keys(checkInImageConfig).map((type) => (
            <ImageUpload
              key={type}
              type={type}
              icon={checkInImageConfig[type].icon}
              onFileSelected={handleFileSelection}
              image={checkInImages[type]}
              shouldAllowActions={shouldAllowActions}
              onDeletion={onImageDelete}
            />
          ))}
        </ImageContainer>
      </InputContainer>
      {Object.values(activeCheckInQuestionList).map((checkInQuestionId) => (
        <InputContainer key={checkInQuestionId}>
          <Title>{checkInQuestionDefinition[checkInQuestionId].question}</Title>
          <StyledStatusInput
            placeholder={checkInQuestionDefinition[checkInQuestionId].prompt}
            rows={4}
            value={checkInQuestions[checkInQuestionId]?.value}
            onChange={(evt) => handleChange(evt, checkInQuestionDefinition[checkInQuestionId])}
            disabled={!shouldAllowActions}
            onBlur={() => updateDocumentFields({
              questions: checkInQuestions,
            })}
          />
        </InputContainer>
      ))}
    </PageContainer>
  );
};

CheckInImages.propTypes = {
  currentCheckInDoc: PropTypes.instanceOf(CheckIn).isRequired,
  updateDocumentFields: PropTypes.func.isRequired,
  currentSelectedDate: PropTypes.object.isRequired,
  shouldAllowActions: PropTypes.bool.isRequired,
  getImageFile: PropTypes.func.isRequired,
};

export default compose(
  observer,
)(CheckInImages);
