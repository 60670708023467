import React from 'react';
import PropTypes from 'prop-types';

import {
  ProgressBarContainer,
  Segment,
} from './styles';

const ProgressBar = ({
  currentActiveIndex,
  totalSegments,
  enableHighContrast,
}) => {
  const renderSegments = () => {
    const segments = [];

    for (let index = 0; index < totalSegments; index++) {
      segments.push(
        <Segment
          key={`segment_${index}`}
          $active={index === currentActiveIndex}
          $filled={index <= currentActiveIndex}
          $enableHighContrast={enableHighContrast}
        />,
      );
    }

    return segments;
  };

  return (
    <ProgressBarContainer>
      {renderSegments()}
    </ProgressBarContainer>
  );
};

ProgressBar.propTypes = {
  currentActiveIndex: PropTypes.number.isRequired,
  totalSegments: PropTypes.number.isRequired,
  enableHighContrast: PropTypes.bool,
};

ProgressBar.defaultProps = {
  enableHighContrast: false,
};

export default ProgressBar;
