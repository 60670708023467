import React from 'react';

import MessagingChannelHeader from '../MessagingChannelHeader';
import {
  Container,
  EmptyChat,
} from './styles';

const ChatEmptyView = () => (
  <Container>
    <MessagingChannelHeader emptyChat />
    <EmptyChat />
  </Container>
);

export default ChatEmptyView;
