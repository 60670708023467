import styled, { css } from 'styled-components';
import Button from '../Button';
import { body1 } from '../../styles/text';

const buttonStyles = css`
  margin-top: 15px;
  font-size: 12px;
  padding: 12px 30px;
  width: 100%;
  max-width: 295px;
  color: ${({ theme }) => theme.colors.fonts.primary};

  @media (min-width: 375px) {
    font-size: 16px;
  }
`;

const UploadButton = styled(Button)`
  ${buttonStyles}
`;

const ErrorMessage = styled.p`
  ${body1};
  color: ${({ theme }) => theme.colors.system.error};
`;

export {
  UploadButton,
  ErrorMessage,
};
