import React from 'react';
import { ImagePreviewer, FilePreviewer } from 'react-file-utils';
import { useChannelStateContext } from 'stream-chat-react';
import PropTypes from 'prop-types';

const UploadsPreview = ({
  fileOrder,
  fileUploads,
  imageOrder,
  imageUploads,
  numberOfUploads,
  removeFile,
  removeImage,
  uploadFile,
  uploadImage,
  uploadNewFiles,
}) => {
  const {
    maxNumberOfFiles,
    multipleUploads,
  } = useChannelStateContext();

  return (
    <>
      {imageOrder.length > 0 && (
        <ImagePreviewer
          imageUploads={imageOrder.map((id) => imageUploads[id])}
          handleRemove={removeImage}
          handleRetry={uploadImage}
          handleFiles={uploadNewFiles}
          multiple={multipleUploads}
          disabled={
            maxNumberOfFiles !== undefined
            && numberOfUploads >= maxNumberOfFiles
          }
        />
      )}
      {fileOrder.length > 0 && (
        <FilePreviewer
          uploads={fileOrder.map((id) => fileUploads[id])}
          handleRemove={removeFile}
          handleRetry={uploadFile}
          handleFiles={uploadNewFiles}
        />
      )}
    </>
  );
};

UploadsPreview.propTypes = {
  fileOrder: PropTypes.array,
  imageOrder: PropTypes.array,
  fileUploads: PropTypes.object,
  imageUploads: PropTypes.object,
  numberOfUploads: PropTypes.number,
  removeFile: PropTypes.func,
  removeImage: PropTypes.func,
  uploadFile: PropTypes.func,
  uploadImage: PropTypes.func,
  uploadNewFiles: PropTypes.func,
};

UploadsPreview.defaultProps = {
  fileOrder: [],
  imageOrder: [],
  fileUploads: {},
  imageUploads: {},
  numberOfUploads: 0,
  removeFile: null,
  removeImage: null,
  uploadFile: null,
  uploadImage: null,
  uploadNewFiles: null,
};

export default UploadsPreview;
