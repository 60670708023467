import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import withPortal from '../withPortal';
import Button, { variants } from '../Button';

import {
  StyledOverlay,
  StyledModal,
  ModalContent,
  ModalDescription,
  ModalHeader,
  ActionsContainer,
  StyledCloseButton,
  IconWrapper,
} from './styles';

const NotificationModal = ({
  isOpen,
  onClose,
  actions,
  title,
  description,
  Icon,
  children,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <StyledOverlay>
      <StyledModal>
        <ModalContent>
          <StyledCloseButton onClick={onClose} />
          {!!Icon && <IconWrapper><Icon /></IconWrapper>}
          <ModalHeader>{title}</ModalHeader>

          <ModalDescription>
            {description}
            {!!children && children}
          </ModalDescription>

        </ModalContent>
        <ActionsContainer>
          {actions.length > 0 && actions.map((act) => (
            <Button
              variant={variants.DARK}
              key={act.label}
              onClick={act.action}
              disabled={!!act.disabled}
            >
              {act.label}
            </Button>
          ))}
        </ActionsContainer>
      </StyledModal>
    </StyledOverlay>
  );
};

NotificationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  Icon: PropTypes.elementType,
  children: PropTypes.node,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      action: PropTypes.func,
    }),
  ),
  description: PropTypes.string,
};
NotificationModal.defaultProps = {
  Icon: null,
  children: null,
  actions: [],
  description: '',
};
export default compose(
  withPortal('notificationModal'),
)(NotificationModal);
