import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useContext,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import useSessionStore from '../../hooks/useSessionStore';
import { ActivityTypes } from '../../models/BaseActivity';
import ActivitySession from '../../models/ActivitySession';
import { convertIndexToLetter } from '../../utils/text';
import Slider from '../Slider';
import { VideoPlaylistContext } from '../VideoPlaylist';
import useAppCustomization from '../../hooks/useAppCustomization';
import { Feature } from '../../context/AppCustomizationContext';
import {
  ActivityNameHeader,
  CoachFeedbackContainer,
  ActivityNameHeaderContainer,
  TimelineContainer,
  ActivityItem,
  SliderContainer,
  MainContainer,
} from './styles';
import EditFeedbackSectionContainer from './EditFeedbackSection';
import ActivityCardContainer from './ActivityCardContainer';
import SendFeedbackSectionContainer from './SendFeedbackSection';

const CoachFeedback = ({
  latestGameplaySession,
}) => {
  const [activityName, setActivityName] = useState('');
  const [activityFeedbacks, setActivityFeedbacks] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [showTimeSlider, setShowTimeSlider] = useState(false);

  const {
    hasPlayerForEveryVideo,
    duration,
    setSliderSeekTime,
  } = useContext(VideoPlaylistContext);

  const { isCoach, isCoachAssistant } = useSessionStore();

  const { isFeatureEnabled } = useAppCustomization();

  /**
   * This function update the UI according to the time selected on the slider.
   * The min and the max of the slider will be startTime of first activity (min) and
   * endTime of the last activity (max). The slider value is defined between those
   * two values.
   *
   * Video time is divided by the slider range (max - min) and this ratio is used to
   * navigate the video proportionate to the slider value and seek the video to that
   * particular second (rounded to seconds)
   *
   * selectedTime is set to this particular time in the slider which is used to determine
   * which activity is to be highlighted based on the current slider value.
   *
   * @type {function(*): void}
   */
  const onSliderChange = useCallback(({ value }) => {
    if (typeof value !== 'number') {
      return;
    }

    setSelectedActivity(value);

    if (hasPlayerForEveryVideo) {
      const videoLengthInMS = duration * 1000;
      const { startTime: selectedActivityStartTime } = activityFeedbacks[value];

      const workoutLength = endTime - startTime;
      const ratio = videoLengthInMS / workoutLength;
      const videoTime = Math.round(((selectedActivityStartTime - startTime) * ratio) / 1000);
      setSliderSeekTime(videoTime);
    }
  }, [
    hasPlayerForEveryVideo,
    startTime,
    endTime,
    activityFeedbacks,
    duration,
    setSliderSeekTime,
  ]);

  // Update activity name title based on the selected activity index
  useEffect(() => {
    if (activityFeedbacks.length > 0) {
      const selected = activityFeedbacks[selectedActivity];

      if (selected) {
        setActivityName(selected.name);
      }
    }
  }, [
    selectedActivity,
    activityFeedbacks,
  ]);

  // Update Slider section visiblity based on the activity feedbacks array size
  useEffect(() => {
    setShowTimeSlider(activityFeedbacks.length > 0);
  }, [
    activityFeedbacks,
  ]);

  // Display the send feedback option if chat feature is enabled for the user in path and the logged user is a coach
  const showSendFeedbackSection = (isCoach || isCoachAssistant) && isFeatureEnabled(Feature.CHAT);

  /**
   * Note: This functions is to be refactored later for simplicity. Need to be divided into
   * several functions.
   *
   * This function recursively traverse the gameplay session document and build up the
   * list of activities as an array. Activities could be in the top level of the document
   * or in activities -> rounds -> activities.
   *
   * Params :-
   * activities : activities array provided from gameplay session
   * feedbacks : data structure containing the coach feedbacks and properties like startTime and endTime.
   * slices: time slices which contains the activity timeline to be displayed in the timeline.
   * sessionPromises: set of promises triggered when each activity session is fetched.
   * isCircuit: whether a circuit or basic activity.
   * circuitID: circuit index.
   * pathArr: this array contains the path to the coachFeedback. Ex: activity 0 -> round 1 -> activity 1 ===> [0, 1, 1]
   */
  const buildCoachFeedbackStruct = useCallback((activities, feedbacks, slices,
    sessionPromises, isCircuit, circuitID, roundID, pathArr) => {
    let noRestCount = 1;
    for (let i = 0; i < activities.length; i++) {
      const activity = activities[i];
      const { type, sessions } = activity;

      if (type === ActivityTypes.CIRCUIT) {
        const { rounds } = activity;

        for (let j = 0; j < rounds.length; j++) {
          const arr = [];
          arr.push(i);
          arr.push(j);

          buildCoachFeedbackStruct(activity.rounds[j].activities, feedbacks, slices,
            sessionPromises, true, i + 1, j + 1, arr);
        }
      } else if (type !== ActivityTypes.REST && sessions && sessions.length > 0) {
        const gameplaySessionPath = sessions[sessions.length - 1];
        const sessionID = gameplaySessionPath.slice(gameplaySessionPath.lastIndexOf('/') + 1);
        const displayName = `${isCircuit ? `${convertIndexToLetter(circuitID - 1)}${noRestCount} · `
          : ''} Set ${roundID} - ${activity.name}`;
        noRestCount += 1;
        const activitySession = ActivitySession.get(latestGameplaySession.id, sessionID);

        sessionPromises.push(activitySession.fetch()
          .then((session) => {
            const arr = [...pathArr, i];
            feedbacks.push({
              sessionID,
              name: displayName,
              startTime: session.data.startTime,
              endTime: session.data.endTime,
              coachFeedback: activity.coachFeedback,
              pathArr: arr,
              feedbackExists: !!activity.coachFeedback,
            });
          }));
      }
    }
  }, [
    latestGameplaySession,
  ]);

  useEffect(() => {
    let shouldUpdate = true;
    const init = async () => {
      const feedbacks = [];
      const slices = [];
      const sessionPromises = [];

      buildCoachFeedbackStruct(latestGameplaySession.activities, feedbacks, slices, sessionPromises,
        false, 1, 1, []);

      if (shouldUpdate) { // TODO: this check should go inside the Promise.all method. This will be refactored later.
        Promise.all(sessionPromises)
          .then(() => {
            setActivityFeedbacks(feedbacks.sort((a, b) => a.startTime - b.startTime));

            if (activityFeedbacks.length > 0) {
              let sTime = Number.MAX_SAFE_INTEGER;
              let eTime = Number.MIN_SAFE_INTEGER;

              for (let i = 0; i < activityFeedbacks.length; i++) {
                const { startTime: startingTime, endTime: endingTime } = activityFeedbacks[i];
                if (startingTime < sTime) {
                  sTime = startingTime;
                }
                if (endingTime > eTime) {
                  eTime = endingTime;
                }
              }

              setStartTime(sTime);
              setEndTime(eTime);
            }

            setIsReady(true);
          });
      }
    };

    if (!isReady) {
      init();
    }

    return () => {
      shouldUpdate = false;
    };
  }, [
    buildCoachFeedbackStruct,
    latestGameplaySession,
    onSliderChange,
    isReady,
    setIsReady,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    hasPlayerForEveryVideo,
    setShowTimeSlider,
    activityFeedbacks,
  ]);

  const activitiesSliderOptions = useMemo(() => (
    [...Array(activityFeedbacks.length).keys()]
  ), [activityFeedbacks]);

  return (
    <CoachFeedbackContainer>
      <MainContainer>
        {!!activityName && (
          <ActivityNameHeaderContainer>
            <ActivityNameHeader>{activityName}</ActivityNameHeader>
          </ActivityNameHeaderContainer>
        )}
        {hasPlayerForEveryVideo && (
          <>
            <TimelineContainer>
              {activityFeedbacks.map((slice, index) => (
                <ActivityItem
                  key={slice.sessionID}
                  isSelected={index === selectedActivity}
                  feedbackExists={slice.feedbackExists}
                />
              ))}
            </TimelineContainer>
            {showTimeSlider && (
              <SliderContainer>
                <Slider
                  options={activitiesSliderOptions}
                  initialOptionSelected={selectedActivity}
                  onOptionSelected={onSliderChange}
                  showStepLabelSection={false}
                />
              </SliderContainer>
            )}
            {showSendFeedbackSection && (
              <SendFeedbackSectionContainer
                latestGameplaySession={latestGameplaySession}
                activityFeedbacks={activityFeedbacks}
              />
            )}
          </>
        )}
        {(isCoach || isCoachAssistant) && (
          <EditFeedbackSectionContainer
            latestGameplaySession={latestGameplaySession}
            selectedActivityIndex={selectedActivity}
            activityFeedbacks={activityFeedbacks}
            setIsReady={setIsReady}
          />
        )}
      </MainContainer>
      <ActivityCardContainer
        latestGameplaySession={latestGameplaySession}
        selectedActivityIndex={selectedActivity}
        activityFeedbacks={activityFeedbacks}
      />
    </CoachFeedbackContainer>
  );
};

CoachFeedback.propTypes = {
  latestGameplaySession: PropTypes.object.isRequired,
};

export default compose(
  observer,
)(CoachFeedback);
