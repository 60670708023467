import styled from 'styled-components';

import { sansSerif } from '../../../../styles/text';
import {
  Wrapper as BaseWrapper,
  HighlightSection as BaseHighlightSection,
} from '../commonStyles';

const Wrapper = styled(BaseWrapper)`
  ${sansSerif.headlineFontStyles}
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.base.primary};
`;

const List = styled.ul`
  margin: 0 0 16px;
  padding: 0 16px;
`;

const ListItem = styled.li`
  > span {
    font-weight: bold;
  }
`;

const HighlightSection = styled(BaseHighlightSection)`
  width: fit-content;
`;

const GridItem = styled.div`
  padding: 8px;
  text-align: center;
  font-weight: bold;
  background-color: ${({ theme }) => theme.colors.system.alpha};
`;

const GridItemHeader = styled(GridItem)`
  background-color: ${({ theme }) => theme.colors.system.secondary6};
  font-weight: normal;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-radius: 6px;
  border: 2px solid transparent;
  background-color: rgba(20, 34, 47, 0.1);
  grid-gap: 2px;
  margin: 8px 0 32px 0;
  overflow: hidden;
`;

export {
  Wrapper,
  List,
  ListItem,
  HighlightSection,
  Grid,
  GridItem,
  GridItemHeader,
};
