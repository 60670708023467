import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as ClipboardIcon } from '../../assets/icons/icon_clipboard.svg';
import Label from '../Label';
import EstimatedDuration from '../EstimatedDuration';
import EquipmentList from '../EquipmentList';
import {
  Container,
  Name,
} from './styles';
import text from './text.json';

const WorkoutBaseData = ({
  name,
  note,
  estimatedDuration,
  activities,
  className,
}) => (
  <Container className={className}>
    {!!name && <Name>{name}</Name>}
    {!!note && (
      <Label
        Icon={ClipboardIcon}
        text={note}
      />
    )}
    {!!estimatedDuration && (
      <EstimatedDuration
        duration={estimatedDuration}
        label={text.estimatedDurationLabel}
      />
    )}
    <EquipmentList activityList={activities} />
  </Container>
);

WorkoutBaseData.propTypes = {
  name: PropTypes.string,
  note: PropTypes.string,
  estimatedDuration: PropTypes.number,
  activities: PropTypes.array,
  className: PropTypes.string,
};

WorkoutBaseData.defaultProps = {
  name: '',
  note: '',
  estimatedDuration: 0,
  className: '',
  activities: [],
};

export default WorkoutBaseData;
