import React from 'react';
import PropTypes from 'prop-types';

import WorkoutItem from './WorkoutItem';
import {
  StyledList,
} from './styles';

const WorkoutsList = ({
  workouts,
  className,
  onWorkoutSelected,
}) => (
  <StyledList className={className}>
    {workouts.docs.map((workoutDoc) => {
      const { id, name, estimatedDuration } = workoutDoc;
      const onClick = () => onWorkoutSelected(workoutDoc);
      return (
        <WorkoutItem
          key={id}
          name={name}
          duration={estimatedDuration}
          onClick={onClick}
        />
      );
    })}
  </StyledList>
);

WorkoutsList.propTypes = {
  workouts: PropTypes.object.isRequired,
  onWorkoutSelected: PropTypes.func.isRequired,
  className: PropTypes.string,
};

WorkoutsList.defaultProps = {
  className: '',
};

export default WorkoutsList;
