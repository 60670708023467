import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import StyledDraggablePanel from './styles';

/**
 * This is a very simple draggable panel used by the Debug Panel. This is not meant for use in prod.
 */
const DraggablePanel = ({
  children,
}) => {
  const panelRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragPosition, setDragPosition] = useState({ x: 0, y: 0 });
  const [panelPosition, setPanelPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (panelRef.current) {
      // This is where the panel gets updated based on the cursor position (or tap position).
      panelRef.current.style.left = `${panelPosition.x}px`;
      panelRef.current.style.top = `${panelPosition.y}px`;
    }
  }, [panelRef, panelPosition.x, panelPosition.y]);

  // We need to set the initial tap or mouse position so that we can calculate the panel movement.
  const setInitialDragPosition = (x, y) => {
    setDragPosition({ x, y });
    setIsDragging(true);
  };

  const onDragEnd = () => {
    setIsDragging(false);
  };

  const setInitialTouchPosition = (event) => {
    const {
      clientX: x,
      clientY: y,
    } = event.touches[0];

    setInitialDragPosition(x, y);
  };

  const setInitialMousePosition = (event) => {
    setInitialDragPosition(event.clientX, event.clientY);
  };

  const updatePosition = (newPositionX, newPositionY) => {
    if (isDragging) {
      const deltaX = newPositionX - dragPosition.x;
      const deltaY = newPositionY - dragPosition.y;

      const x = panelPosition.x + deltaX;
      const y = panelPosition.y + deltaY;

      setPanelPosition({ x, y });
      setDragPosition({ x: newPositionX, y: newPositionY });
    }
  };

  const onMouseMove = (event) => {
    updatePosition(event.clientX, event.clientY);
  };

  const onTouchMove = (event) => {
    const {
      clientX,
      clientY,
    } = event.touches[0];

    updatePosition(clientX, clientY);
  };

  return (
    <StyledDraggablePanel
      ref={panelRef}
      onMouseDown={setInitialMousePosition}
      onMouseMove={onMouseMove}
      onMouseUp={onDragEnd}
      onTouchStart={setInitialTouchPosition}
      onTouchMove={onTouchMove}
      onTouchEnd={onDragEnd}
    >
      {children}
    </StyledDraggablePanel>
  );
};

DraggablePanel.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DraggablePanel;
