import styled, { css } from 'styled-components';

import { body1 } from '../../../styles/text';
import VideoPlayer from '../../VideoPlayer';

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
`;

const VideoContainer = styled.div`
  flex-basis: 60%;
  min-height: 250px;
  max-width: 350px;
  width: 100%;
  overflow: hidden;
  align-self: center;
`;

const StyledVideoPlayer = styled(VideoPlayer)`
  ${({ $enlarge }) => ($enlarge && css`
    transform: translateY(16%) scale(1.4);
  `)}
`;

const Description = styled.p`
  ${body1}
  overflow: auto;
  white-space: break-spaces;
  margin: 16px 0 0 0;
`;

export {
  Container,
  VideoContainer,
  StyledVideoPlayer,
  Description,
};
