import React, { useContext } from 'react';
import { Channel } from 'stream-chat-react';
import { IonRouterOutlet } from '@ionic/react';

import { channelConfiguration } from '../../util';
import ChatFeedView from '../ChatFeedView';
import ChatFeedContainer from '../ChatFeedContainer';
import ChatEmptyView from '../ChatEmptyView';
import ChatContext from '../../context';

import CustomTriggerProvider from '../CustomTriggerProvider';
import MultipleChannelList from './MultipleChannelList';

const MultipleChannelView = () => {
  const { onMarkChannelAsRead } = useContext(ChatContext);

  return (
    <>
      <MultipleChannelList />
      <IonRouterOutlet id="app-chat-content">
        <ChatFeedContainer>
          <Channel
            EmptyPlaceholder={<ChatEmptyView />}
            maxNumberOfFiles={channelConfiguration.maxFileUploadLimit}
            multipleUploads
            doMarkReadRequest={onMarkChannelAsRead}
            TriggerProvider={CustomTriggerProvider}
          >
            <ChatFeedView />
          </Channel>
        </ChatFeedContainer>
      </IonRouterOutlet>
    </>
  );
};

export default MultipleChannelView;
