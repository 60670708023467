import {
  decorate,
  observable,
} from 'mobx';

import BaseWorkoutAssignment from '../../models/WorkoutAssignment';
import WorkoutDefinition from '../../models/WorkoutDefinition';
import { estimateDuration } from '../utils/workout';

class WorkoutAssignment extends BaseWorkoutAssignment {
  /**
   * Update the activities field of the workout assignment.
   * @param {Array} activities The new activities to store.
   */
  updateActivities = async (activities) => {
    // Calculate the new estimated duration of the workout.
    const estimatedDuration = estimateDuration(activities);

    const workoutContent = {
      ...this.data.workoutContent,
      activities,
      estimatedDuration,
    };
    await this.updateFields({
      workoutContent,
    });

    this.workoutDefinition = new WorkoutDefinition(this.data.workoutContent);
  };

  updateWorkoutInfo = async (name, note) => {
    const workoutContent = {
      ...this.data.workoutContent,
      name,
      note,
    };
    await this.updateFields({
      workoutContent,
    });
    this.workoutDefinition = new WorkoutDefinition(this.data.workoutContent);
  }
}

decorate(WorkoutAssignment, {
  workoutDefinition: observable,
});

export default WorkoutAssignment;
