import React, {
  useContext,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import {
  Elements,
} from '@stripe/react-stripe-js';

import SubscriptionContext, {
  withSubscriptionContextProvider,
  withSubscriptionContextReady,
} from '../../context/SubscriptionContext';
import StripeContext, {
  withStripeContextProvider,
  withStripeContextReady,
} from '../../context/StripeContext';

import BillingInformation from './BillingInformation';
import { ErrorText } from './styles';
import texts from './texts.json';

const BillingInformationContainer = ({
  isEditable,
}) => {
  const { stripePromise, error: stripeError } = useContext(StripeContext);
  const { error: subscriptionError } = useContext(SubscriptionContext);

  if (stripeError || subscriptionError) {
    return <ErrorText>{ReactHtmlParser(texts.errorMessage)}</ErrorText>;
  }

  return (
    <Elements stripe={stripePromise}>
      <BillingInformation isEditable={isEditable} />
    </Elements>
  );
};

BillingInformationContainer.propTypes = {
  isEditable: PropTypes.bool,
};

BillingInformationContainer.defaultProps = {
  isEditable: false,
};

export default compose(
  withSubscriptionContextProvider,
  withSubscriptionContextReady,
  withStripeContextProvider,
  withStripeContextReady,
  observer,
)(BillingInformationContainer);
