import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledCard,
  ResultContainer,
  Result,
  Description,
} from './styles';
import texts from './texts.json';

const WorkoutsResultSummary = ({
  completedWorkouts,
  totalExercises,
}) => (
  <StyledCard>
    <ResultContainer>
      <Result>{completedWorkouts}</Result>
      <Description>{texts.workoutsCompleted}</Description>
    </ResultContainer>
    <ResultContainer>
      <Result>{totalExercises}</Result>
      <Description>{texts.setsPerformed}</Description>
    </ResultContainer>
  </StyledCard>
);

WorkoutsResultSummary.propTypes = {
  completedWorkouts: PropTypes.number,
  totalExercises: PropTypes.number,
};

WorkoutsResultSummary.defaultProps = {
  completedWorkouts: 0,
  totalExercises: 0,
};

export default WorkoutsResultSummary;
