import styled from 'styled-components';

import { ReactComponent as Icon } from '../../../assets/icons/alternate-sides-icon-big.svg';

const AlternateSidesBigIcon = styled(Icon)`
  rect {
    fill: ${({ theme }) => theme.colors.base.secondaryVariant};
  }
`;

export default AlternateSidesBigIcon;
