import styled from 'styled-components';

import {
  labelStyles,
} from '../../../../styles/text';

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 0 10px 0;
`;

const WorkoutDate = styled.div`
  ${labelStyles}
  letter-spacing: 3px;
  text-transform: uppercase;
  padding: 0 0 10px 52px;
`;

export {
  HeaderContainer,
  WorkoutDate,
};
