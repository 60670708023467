import React, {
  useContext,
  useMemo,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import AppContext from '../../../../context/AppContext';
import UserContext from '../../../../context/UserContext';
import CameraContext from '../../../../context/CameraContext';
import { SelfieVideoContextProvider, selfieVideoModes } from '../../../../components/SelfieVideo';
import {
  withWorkoutExecutionInfoContextProvider,
} from '../../context/WorkoutExecutionInfoContext';
import ActivityContext from '../../context/ActivityContext';

import WorkoutDetailsMenu from './components/WorkoutDetailsMenu';

import WorkoutInProgressContainer from './WorkoutInProgressContainer';

const WorkoutInProgressWithSelfieVideo = () => {
  const {
    frameProcessed,
    loopProcessed,
    activityConfig,
    gameplayActionsExecutor,
  } = useContext(ActivityContext);

  const {
    debugSettings: {
      isDebugMode,
    },
  } = useContext(AppContext);

  const {
    userConfigDoc: {
      isVNBActionEnabled,
    },
  } = useContext(UserContext);

  const {
    camera,
    isCameraEnabled,
  } = useContext(CameraContext);

  const selfieVideoContextProviderProps = useMemo(() => {
    let customProps = activityConfig.selfieVideoContextProvider
      ? activityConfig.selfieVideoContextProvider
      : {};

    /*
      When gameplay actions are enabled (VNB action), then the selfieVideoMode required for actions
      takes precedence over any other mode configured.
    */
    if (!isCameraEnabled) {
      customProps = {
        ...customProps,
        selfieVideoMode: selfieVideoModes.LOOP_ONLY,
      };
    } else if (isVNBActionEnabled) {
      customProps = {
        ...customProps,
        selfieVideoMode: selfieVideoModes.IMAGE_PROCESSING,
      };
    } else if (camera.isVideoStreamEnabled) {
      /*
        When the camera supports video streaming, set the SelfieVideo mode to LOOP_ONLY.
        This mode won't capture any frame.
      */
      customProps = {
        ...customProps,
        selfieVideoMode: selfieVideoModes.LOOP_ONLY,
      };
    }

    return customProps;
  }, [
    activityConfig.selfieVideoContextProvider,
    isVNBActionEnabled,
    camera.isVideoStreamEnabled,
    isCameraEnabled,
  ]);

  return (
    <>
      <WorkoutDetailsMenu />
      <SelfieVideoContextProvider
        frameProcessed={frameProcessed}
        loopProcessed={loopProcessed}
        debugMode={isDebugMode}
        gameplayActionsProcessingArea={gameplayActionsExecutor.current.processingArea}
        {...selfieVideoContextProviderProps}
      >
        <WorkoutInProgressContainer />
      </SelfieVideoContextProvider>
    </>
  );
};

export default compose(
  withWorkoutExecutionInfoContextProvider,
  observer,
)(WorkoutInProgressWithSelfieVideo);
