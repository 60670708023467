import styled from 'styled-components';

import { body1 } from '../../styles/text';

const NoteContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    min-width: 17px;
  }
`;

const NoteContent = styled.div`
  ${body1}
  margin-left: 20px;
`;

export {
  NoteContainer,
  NoteContent,
};
