import styled from 'styled-components';
import { sansSerif } from '../../../styles/text';
import {
  Card,
} from '../commonStyles';

const StyledCard = styled(Card)`
  align-items: center;
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
`;

const Item = styled.li`
  ${sansSerif.headline4}
  color: ${({ theme }) => theme.colors.shades.primary80};
  letter-spacing: normal;
  line-height: 19px;
  background-color: ${({ theme }) => theme.colors.system.secondary6};
  padding: 8px;
  width: 100%;

  &:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.system.alpha};
  }
`;

export {
  StyledCard,
  List,
  Item,
};
