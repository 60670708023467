import React from 'react';
import PropTypes from 'prop-types';

import {
  LoginContainer,
  LoginFormsContainer,
  BackButton,
  BackgroundImage,
  FormWrapper,
  FormHeading,
} from './styles';

const PageContainer = ({
  onBackButtonClick,
  formHeading,
  children,
}) => (
  <LoginContainer>
    <LoginFormsContainer>
      <BackgroundImage />
      {!!onBackButtonClick && <BackButton onClick={onBackButtonClick} />}
      <FormWrapper>
        <FormHeading>{formHeading}</FormHeading>
        {children}
      </FormWrapper>
    </LoginFormsContainer>
  </LoginContainer>
);

PageContainer.propTypes = {
  onBackButtonClick: PropTypes.func,
  formHeading: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

PageContainer.defaultProps = {
  onBackButtonClick: null,
};

export default PageContainer;
