import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledCollapsiblePanel,
  Content,
} from './styles';

const CollapsibleCard = ({
  children,
  ...otherProps
}) => (
  <StyledCollapsiblePanel {...otherProps}>
    <Content>
      {children}
    </Content>
  </StyledCollapsiblePanel>
);

CollapsibleCard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CollapsibleCard;
