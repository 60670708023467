import { v4 as uuidv4 } from 'uuid';

import BaseDocument from './BaseDocument';

class CustomWorkout extends BaseDocument {
  static COLLECTION = '/customWorkout';

  constructor(id = uuidv4(), opts) {
    super(`${CustomWorkout.COLLECTION}/${id}`, opts);
  }
}

export default CustomWorkout;
