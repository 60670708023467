const safeAreaFromTop = () => `
  env(safe-area-inset-top)
`;

const safeAreaFromBottom = () => `
  env(safe-area-inset-bottom)
`;

const safeAreaFromLeft = () => `
  env(safe-area-inset-left)
`;

const safeAreaFromRight = () => `
  env(safe-area-inset-right)
`;

const getSafeAreaFromHeader = () => `
  calc(${safeAreaFromTop()} + 56px)
`;

export {
  getSafeAreaFromHeader,
  safeAreaFromTop,
  safeAreaFromBottom,
  safeAreaFromLeft,
  safeAreaFromRight,
};
