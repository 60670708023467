import React from 'react';

const initialState = {
  currentHabitLogDoc: null,
  currentSelectedDate: null,
  isReady: false,
  loadMoreLogs: () => {},
  habitLogs: [],
  isLoadingMore: false,
};

const HabitLogContext = React.createContext(initialState);

export default HabitLogContext;
export {
  initialState,
};
