import React from 'react';
import PropTypes from 'prop-types';

import { config } from '../../../../../../../components/ActivitySideConfig';
import Tooltip from '../../../../../../../components/Tooltip';

const ActivitySideIndicator = ({
  activitySide,
}) => {
  const {
    text,
    icons: {
      small: Icon,
    },
  } = config[activitySide];

  return (
    <Tooltip text={text}>
      <Icon />
    </Tooltip>
  );
};

ActivitySideIndicator.propTypes = {
  activitySide: PropTypes.string.isRequired,
};

export default ActivitySideIndicator;
