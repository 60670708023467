import React, {
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import BaseActivity, { ActivityTypes } from '../../../../../../models/BaseActivity';
import ActivityContext from '../../../../context/ActivityContext';
import CameraViewportMessage from '../CameraViewportMessage';
import RestMessage from './RestMessage';
import ActivitySideMessage from './ActivitySideMessage';

import {
  GeneralMessage,
} from './styles';

const WorkoutMessages = () => {
  const {
    isActivityWorkoutPaused,
    activityExecutor: {
      activity: {
        side: activitySide,
        type: activityType,
        goalField,
      },
      startActivityCount,
      showStartActivityCountdown,
      showActivitySideInfo,
      remainingWorkoutValue,
    },
  } = useContext(ActivityContext);

  const showRestCountdown = useMemo(() => (
    activityType === ActivityTypes.REST
  ), [
    activityType,
  ]);

  const remainingRestTime = useMemo(() => (
    showRestCountdown ? BaseActivity.toDisplayableValue(goalField, remainingWorkoutValue) : 0
  ), [
    goalField,
    remainingWorkoutValue,
    showRestCountdown,
  ]);

  const shouldRender = showStartActivityCountdown
    || showRestCountdown
    || showActivitySideInfo
    || isActivityWorkoutPaused;

  const getMessageContent = useCallback(() => {
    let message = '';

    if (showStartActivityCountdown) {
      message = startActivityCount;
    } else if (showActivitySideInfo) {
      message = <ActivitySideMessage activitySide={activitySide} />;
    } else if (showRestCountdown) {
      message = <RestMessage remainingRestTime={remainingRestTime} />;
    } else if (isActivityWorkoutPaused) {
      message = <GeneralMessage>Paused</GeneralMessage>;
    }

    return message;
  }, [
    showActivitySideInfo,
    activitySide,
    isActivityWorkoutPaused,
    showStartActivityCountdown,
    startActivityCount,
    remainingRestTime,
    showRestCountdown,
  ]);

  if (!shouldRender) {
    return null;
  }

  return <CameraViewportMessage>{getMessageContent()}</CameraViewportMessage>;
};

export default compose(
  observer,
)(WorkoutMessages);
