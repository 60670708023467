import React, {
  useRef,
  useLayoutEffect,
} from 'react';
import PropTypes from 'prop-types';

import OverrideSelection from '../OverrideSelection';

import {
  ExerciseItemContainer,
  StyledShortExerciseItem,
  IconContainer,
  IconArrow,
} from './styles';

const ExerciseItem = ({
  exercise,
  isExpanded,
  showOriginalExercise,
  onExpand,
}) => {
  const containerRef = useRef();

  useLayoutEffect(() => {
    if (isExpanded && containerRef.current) {
      containerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [
    isExpanded,
  ]);

  return (
    <ExerciseItemContainer
      ref={containerRef}
    >
      <StyledShortExerciseItem
        exercise={exercise}
        onClick={() => onExpand(exercise)}
      >
        <IconContainer>
          <IconArrow $isExpanded={isExpanded} />
        </IconContainer>

      </StyledShortExerciseItem>
      {isExpanded && (
        <OverrideSelection
          selectedExercise={exercise}
          showOriginalExercise={showOriginalExercise}
        />
      )}
    </ExerciseItemContainer>
  );
};

ExerciseItem.propTypes = {
  exercise: PropTypes.object.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  onExpand: PropTypes.func.isRequired,
  showOriginalExercise: PropTypes.bool.isRequired,
};

export default ExerciseItem;
