import React, {
  useContext,
  useCallback,
  useState,
  useEffect,
} from 'react';
import format from 'string-template';

import WorkoutContext from '../../../../../../context/WorkoutContext';
import Button from '../../../../../../components/Button';
import FeedbackPanel from '../FeedbackPanel';

import { ReactComponent as Medal } from '../../../../../../assets/medal.svg';

import GameplayContext from '../../../../context/GameplayContext';
import useGameplayGoBack from '../../../../hooks/useGameplayGoBack';
import texts from './texts.json';
import {
  StyledFinishedWorkout,
  MedalContainer,
  MessageContainer,
  Title,
  Message,
} from './styles';

const FinishedWorkout = () => {
  const {
    workoutDef: {
      name: workoutName,
    },
  } = useContext(WorkoutContext);
  const {
    workoutExecutor: {
      logWorkoutEvent,
    },
  } = useContext(GameplayContext);

  const navigateBack = useGameplayGoBack();

  const [isFeedbackPanelOpen, setIsFeedbackPanelOpen] = useState(false);

  useEffect(() => {
    logWorkoutEvent('congratsMessageShown');
  }, [
    logWorkoutEvent,
  ]);

  const onCongratsButtonClick = useCallback(() => {
    logWorkoutEvent('congratsButtonClicked');

    // Always open the feedback panel at the end of a workout.
    setIsFeedbackPanelOpen(true);
  }, [
    logWorkoutEvent,
  ]);

  return (
    <StyledFinishedWorkout>
      <MedalContainer><Medal /></MedalContainer>
      <MessageContainer>
        <Title>{texts.title}</Title>
        <Message>
          {format(texts.finishedMessage, { workoutName })}
        </Message>
        <Button onClick={onCongratsButtonClick}>{texts.buttonText}</Button>
      </MessageContainer>
      <FeedbackPanel
        isOpen={isFeedbackPanelOpen}
        onDoneClick={navigateBack}
        onCloseButtonClick={navigateBack}
      />
    </StyledFinishedWorkout>
  );
};

export default FinishedWorkout;
