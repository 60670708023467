import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

const WorkoutDetailsContext = React.createContext(null);

const WorkoutDetailsContextProvider = ({
  enableActivityDetails: initialEnableActivityDetails,
  children,
}) => {
  const [enableActivityDetails] = useState(initialEnableActivityDetails);

  const context = useMemo(() => ({
    enableActivityDetails,
  }), [
    enableActivityDetails,
  ]);

  return (
    <WorkoutDetailsContext.Provider value={context}>
      {children}
    </WorkoutDetailsContext.Provider>
  );
};

WorkoutDetailsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  enableActivityDetails: PropTypes.bool,
};

WorkoutDetailsContextProvider.defaultProps = {
  enableActivityDetails: true,
};

export default WorkoutDetailsContext;
export {
  WorkoutDetailsContextProvider,
};
