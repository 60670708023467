import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import VideoPlaylistContext from './VideoPlaylistContext';
import PlaylistVideoPlayer from './PlaylistVideoPlayer';
import {
  Arrow,
  PreviousButton,
  NextButton,
  Playlist,
  PlaylistContainer,
} from './styles';

const TRANSITION_DURATION = 300; // in ms

const VideoPlaylist = ({
  showSpinnerOverlay,
}) => {
  const {
    playableVideos,
    sliderSeekTime,
    hasPlayerForEveryVideo,
    duration,
  } = useContext(VideoPlaylistContext);

  const [selectedVideo, setSelectedVideo] = useState(0);
  const [previousSliderSeekTime, setPreviousSliderSeekTime] = useState(0);

  const onVideoEnded = () => {
    if (playableVideos.length - 1 === selectedVideo) {
      return;
    }

    const newSelectedVideo = selectedVideo + 1;
    setSelectedVideo(newSelectedVideo);
    const { player } = playableVideos[newSelectedVideo];

    // May not be a player if the first video ended and the other players aren't ready
    if (!player) {
      return;
    }

    player.seekTo(0, 'seconds');

    const internalPlayer = player.getInternalPlayer();

    // Timeout to play the video after the move animation ended.
    setTimeout(() => {
      internalPlayer.play();
    }, TRANSITION_DURATION + 50);
  };

  const nextVideo = () => {
    if (selectedVideo < playableVideos.length - 1) {
      setSelectedVideo(selectedVideo + 1);
    }
  };

  const previousVideo = () => {
    if (selectedVideo >= 1) {
      setSelectedVideo(selectedVideo - 1);
    }
  };

  useEffect(() => {
    if (!hasPlayerForEveryVideo || sliderSeekTime > duration || sliderSeekTime === previousSliderSeekTime) {
      return;
    }

    let videoToSeek;
    let timeSum = 0;
    let videoIndex = 0;

    for (let index = 0; index < playableVideos.length; index++) {
      const video = playableVideos[index];
      const videoDuration = video.player.getDuration();
      videoToSeek = video;
      videoIndex = index;
      if (sliderSeekTime < (timeSum + videoDuration)) {
        break;
      }
      timeSum += videoDuration;
    }

    const timeOnVideoPlayer = sliderSeekTime - timeSum;
    const isSeekEqualToCurrent = Math.floor(videoToSeek.player.getCurrentTime()) === Math.floor(timeOnVideoPlayer);

    if (!isSeekEqualToCurrent) {
      videoToSeek.player.seekTo(timeOnVideoPlayer, 'seconds');
    }

    setSelectedVideo(videoIndex);
    setPreviousSliderSeekTime(sliderSeekTime);
  },
  [
    sliderSeekTime,
    hasPlayerForEveryVideo,
    playableVideos,
    duration,
    previousSliderSeekTime,
  ]);

  return (
    <PlaylistContainer>
      <Playlist
        selectedVideo={selectedVideo}
        transitionDuration={TRANSITION_DURATION}
      >
        {playableVideos.map((video) => (
          <PlaylistVideoPlayer
            key={video.archiveId}
            video={video}
            showSpinnerOverlay={showSpinnerOverlay}
            onEnded={onVideoEnded}
          />
        ))}
      </Playlist>
      {selectedVideo < playableVideos.length - 1 && (
        <NextButton onClick={nextVideo}>
          <Arrow />
        </NextButton>
      )}
      {selectedVideo > 0 && (
        <PreviousButton onClick={previousVideo}>
          <Arrow />
        </PreviousButton>
      )}
    </PlaylistContainer>
  );
};

VideoPlaylist.propTypes = {
  showSpinnerOverlay: PropTypes.bool,
};

VideoPlaylist.defaultProps = {
  showSpinnerOverlay: false,
};

export default VideoPlaylist;
