import styled, { css } from 'styled-components';

import { addAlpha } from '../../utils/colorUtils';
import transitionsConfig from './transitionsConfig';

const transitionsProperty = css`
  transition: all ${transitionsConfig.delay}ms ease-in-out;
`;

const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => addAlpha(theme.colors.system.beta, 0.3)};
  border-radius: 0;
  /* This z-index value is necessary for rendering this component on top of the side menu and the header. */
  z-index: 9900;

  &.${transitionsConfig.classNames.overlay}-enter,
  &.${transitionsConfig.classNames.overlay}-appear {
    opacity: 0;
  }

  &.${transitionsConfig.classNames.overlay}-appear-active,
  &.${transitionsConfig.classNames.overlay}-enter-active {
    opacity: 1;
    ${transitionsProperty}
  }

  &.${transitionsConfig.classNames.overlay}-exit {
    opacity: 1;
  }

  &.${transitionsConfig.classNames.overlay}-exit-active {
    opacity: 0;
    ${transitionsProperty}
  }
`;

export {
  Overlay,
};
