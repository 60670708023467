import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as BronzeBadge } from '../../../../assets/badges/bronze-badge.svg';
import { ReactComponent as SilverBadge } from '../../../../assets/badges/silver-badge.svg';
import { ReactComponent as GoldBadge } from '../../../../assets/badges/gold-badge.svg';
import logEvent from '../../../../utils/logger';
import {
  Container,
  Bar,
  CounterContainer,
  BadgeContainer,
  Counter,
  Description,
} from './styles';

const counterThreshold = {
  SILVER: 7,
  GOLD: 21,
};

const StreakCard = ({
  count,
}) => {
  let Badge;

  if (count >= counterThreshold.GOLD) {
    Badge = GoldBadge;
  } else if (count >= counterThreshold.SILVER) {
    Badge = SilverBadge;
  } else {
    Badge = BronzeBadge;
  }

  const onCardClick = () => {
    // Send this analytics events to help analyzing if users are interested in seeing streak/stats details
    logEvent('streakCardClicked', {
      streak: count,
    });
  };

  return (
    <Container onClick={onCardClick}>
      <Bar />
      <CounterContainer>
        <BadgeContainer>
          <Badge />
        </BadgeContainer>
        <Counter>
          {count}
        </Counter>
        <Description>
          Day
          <br />
          streak
        </Description>
      </CounterContainer>
    </Container>
  );
};

StreakCard.propTypes = {
  count: PropTypes.number.isRequired,
};

export default StreakCard;
