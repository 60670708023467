import React, {
  useContext,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { KeepAwake } from '@capacitor-community/keep-awake';

import AppContext from '../../../../context/AppContext';
import { SelfieVideoContext } from '../../../../components/SelfieVideo';
import logEvent from '../../../../utils/logger';
import { isNative } from '../../../../utils/platform';
import LoadingPage, { LoadingPageTheme } from '../../../../components/LoadingPage';
import DebugPanel from '../../../../components/DebugPanel';
import useSound from '../../../../hooks/useSound';

import { ActivityTypes } from '../../../../models/BaseActivity';
import useRefreshCameraPreview from '../../../../hooks/useRefreshCameraPreview';
import CameraContext from '../../../../context/CameraContext';
import UserContext from '../../../../context/UserContext';
import ActivityContext from '../../context/ActivityContext';
import GameplayContext from '../../context/GameplayContext';
import WorkoutExecutionInfoContext from '../../context/WorkoutExecutionInfoContext';
import useGameplayWatchActions from '../../hooks/useGameplayWatchActions';

// Sounds effects
import activityStartSound from '../../../../assets/audio/activityStart.mp3';
import activityEndSound from '../../../../assets/audio/activityEnd.mp3';
import countdownSound from '../../../../assets/audio/countdown.mp3';
import WorkoutInProgress from './WorkoutInProgress';

const selfieVideoThrottling = {
  FAST: 75,
  SLOW: 170,
};

const WorkoutInProgressContainer = ({
  gameplayStore,
}) => {
  const [playCountdownSound] = useSound(countdownSound);
  const [playActivityStartSound] = useSound(activityStartSound);
  const [playActivityEndSound] = useSound(activityEndSound);

  const {
    activityConfig,
    displayActivityDetails,
    setDisplayActivityDetails,
    activityExecutor: {
      activity,
      isRequiredGoalAchieved,
      isActivityInFrameProcessingState,
      startActivityCount,
      showStartActivityCountdown,
    },
  } = useContext(ActivityContext);

  const {
    onWorkoutStart,
    workoutExecutor: {
      isWorkoutFinished,
    },
    cameraViewportRef,
  } = useContext(GameplayContext);

  const {
    activity: activityForDetailsPage,
    displayInfo: {
      videoUrl,
      videoPreviewUrl,
    },
  } = useContext(WorkoutExecutionInfoContext);

  const {
    setThrottling,
    enableFrameProcessing,
    disableFrameProcessing,
  } = useContext(SelfieVideoContext);

  const {
    userConfigDoc: {
      areAudioCuesEnabled,
    },
  } = useContext(UserContext);

  const {
    debugSettings: {
      isDebugMode,
    },
  } = useContext(AppContext);

  const {
    cameraAspectRatio,
    isCameraEnabled,
    camera: {
      isVideoStreamEnabled,
    },
  } = useContext(CameraContext);

  const [isReady, setIsReady] = useState(false);

  useGameplayWatchActions();

  useEffect(() => {
    // Set a custom throttling for all of the activities
    const throttling = isVideoStreamEnabled
      ? selfieVideoThrottling.FAST
      : selfieVideoThrottling.SLOW;

    setThrottling(throttling);

    if (isNative) {
      KeepAwake.keepAwake();
    }
    // Start workout right away
    setIsReady(true);
    onWorkoutStart();

    return () => {
      if (isNative) {
        KeepAwake.allowSleep();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Refresh camera preview when isPortraitMode changes
  useRefreshCameraPreview();

  useEffect(() => {
    logEvent('gameplayWorkoutInProgressPageShown');
  }, []);

  useEffect(() => {
    if (!isActivityInFrameProcessingState || activityConfig.disableFrameProcessing) {
      // disable frame processing when activity is not in a frame processing state or requires processing to be disabled
      disableFrameProcessing();
    } else {
      enableFrameProcessing();
    }
  }, [
    activityConfig.disableFrameProcessing,
    isActivityInFrameProcessingState,
    disableFrameProcessing,
    enableFrameProcessing,
  ]);

  useEffect(() => {
    if (areAudioCuesEnabled && showStartActivityCountdown && startActivityCount) {
      playCountdownSound();
    }
  }, [areAudioCuesEnabled, showStartActivityCountdown, startActivityCount, playCountdownSound]);

  useEffect(() => {
    if (areAudioCuesEnabled && startActivityCount === 0) {
      playActivityStartSound();
    }
  }, [areAudioCuesEnabled, startActivityCount, playActivityStartSound]);

  useEffect(() => {
    const { REST, REPETITIONS } = ActivityTypes;
    const { type } = activity;

    /* The repetitions activities are initialized as completed. This makes the end sound to be played at the beginning.
       For this reason, we ignore both REST and REPETITIONS activities, for now. */
    if (areAudioCuesEnabled && isRequiredGoalAchieved && !(type === REST || type === REPETITIONS)) {
      playActivityEndSound();
    }
  }, [areAudioCuesEnabled, isRequiredGoalAchieved, activity, playActivityEndSound]);

  const onDetailsClose = useCallback(() => {
    setDisplayActivityDetails(false);
  }, [
    setDisplayActivityDetails,
  ]);

  if (!isReady) {
    return <LoadingPage theme={LoadingPageTheme.DARK} />;
  }

  return (
    <>
      <WorkoutInProgress
        isWorkoutFinished={isWorkoutFinished}
        onDetailsClose={onDetailsClose}
        displayActivityDetails={displayActivityDetails}
        activityForDetailsPage={activityForDetailsPage}
        cameraAspectRatio={cameraAspectRatio.numericValue}
        isCameraEnabled={isCameraEnabled}
        videoUrl={videoPreviewUrl || videoUrl}
        cameraViewportRef={cameraViewportRef}
        isS2Activity={activityForDetailsPage.isS2Activity}
        isCAActivity={activityForDetailsPage.isCAActivity}
      />
      {isDebugMode && <DebugPanel hist={gameplayStore.latestWorkoutResult} />}
    </>
  );
};

WorkoutInProgressContainer.propTypes = {
  gameplayStore: PropTypes.object.isRequired,
};

export default compose(
  inject('gameplayStore'),
  observer,
)(WorkoutInProgressContainer);
