import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

import useSessionStore from './useSessionStore';

/**
 * This React Hook allows to check if the logged in user is the one that appears on the url.
 * The goal of this hook is to prevent executing actions on other user's urls (for example when a coach uses
 * the view-as feature).
 */
const useCurrentLoggedInUser = () => {
  const {
    authUser: {
      uid: authUserId,
    },
  } = useSessionStore();

  const {
    params: {
      userId,
    },
  } = useRouteMatch();

  return useMemo(() => ({
    isCurrentLoggedInUserInPath: authUserId === userId,
  }), [
    authUserId,
    userId,
  ]);
};

export default useCurrentLoggedInUser;
