import styled from 'styled-components';

import { sansSerif } from '../../../styles/text';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Paragraph = styled.p`
  ${sansSerif.headlineFontStyles}
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.base.primary};
  margin-top: 0;
  > span {
    font-weight: 700;
  }
`;

const Title = styled.div`
  ${sansSerif.headlineFontStyles}
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  margin: 0 0 8px;
`;

const HighlightList = styled.ul`
  margin: 0 0 16px;
  padding: 0 16px;
  columns: 2;
`;

const HighlightTitle = styled.div`
  text-transform: uppercase;
  margin-bottom: 16px;
`;

const HighlightContainer = styled.div`
  ${sansSerif.headlineFontStyles}
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  padding: 16px;
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.base.primary};
  background-color: ${({ theme }) => theme.colors.system.secondary6};
  margin: 0 0 8px;

  ${HighlightList} {
    &:last-child {
      margin: 0;
    }
  }
`;

const HighlightSection = styled.div`
  ${sansSerif.headlineFontStyles}
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.base.primary};
  padding: 2px 4px;
  background: ${({ theme }) => theme.colors.system.secondary6};
  margin-bottom: 4px;
`;

export {
  Wrapper,
  Paragraph,
  Title,
  HighlightContainer,
  HighlightTitle,
  HighlightList,
  HighlightSection,
};
