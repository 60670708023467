import React from 'react';
import PropTypes from 'prop-types';

import { ProgressStatus } from '../../progressStatus';
import StatusCheckmark from './StatusCheckmark';
import {
  Container,
  Connector,
  Ribbon,
} from './styles';

const ProgressConnector = ({
  progressStatus,
  showBar,
  showRibbon,
  enableRibbonStartOffset,
  enableRibbonEndOffset,
  checkMarkImage,
  className,
}) => (
  <Container className={className}>
    <StatusCheckmark
      progressStatus={progressStatus}
      image={checkMarkImage}
    />
    {showBar && <Connector progressStatus={progressStatus} />}
    {showBar && showRibbon && (
      <Ribbon
        enableRibbonStartOffset={enableRibbonStartOffset}
        enableRibbonEndOffset={enableRibbonEndOffset}
      />
    )}
  </Container>
);

ProgressConnector.propTypes = {
  progressStatus: PropTypes.oneOf(Object.values(ProgressStatus)),
  showBar: PropTypes.bool,
  showRibbon: PropTypes.bool,
  enableRibbonStartOffset: PropTypes.bool,
  enableRibbonEndOffset: PropTypes.bool,
  checkMarkImage: PropTypes.elementType,
  className: PropTypes.string,
};

ProgressConnector.defaultProps = {
  progressStatus: ProgressStatus.INACTIVE,
  showBar: true,
  showRibbon: false,
  enableRibbonStartOffset: false,
  enableRibbonEndOffset: true,
  checkMarkImage: null,
  className: '',
};

export default ProgressConnector;
