import moment from 'moment';

import {
  convertToLocalDate,
  todayStartDate,
} from '../../../utils/date';
import RecoveryDayCard from '../components/RecoveryDayCard';
import { ProgressStatus } from '../progressStatus';
import AssignmentDayType, { getAssignmentDayType } from './assignmentType';
import {
  getWorkoutGroupTypeByDate,
  getWorkoutGroupTypeByAssignmentType,
} from './workoutGroupType';

const DISPLAY_FUTURE_DAYS = 7;

/**
 * Creates and add to the workout feed view models data structure, the requested amount of
 * recovery days.
 *
 * @param {Object} startDate The moment date instance representing the start date.
 * @param {number} amount The amount of recovery days to create and add to the data structure.
 * @param {Object} workoutFeedViewGroups The workout feed view models data structure.
 */
const addRecoveryDays = (startDate, amount, workoutFeedViewGroups) => {
  for (let i = 0; i <= amount; ++i) {
    const startDay = moment(startDate)
      .add(i, 'days')
      .startOf('day');

    // Create a new recovery day for the given day and add it to the Map
    const assignmentDayType = getAssignmentDayType(startDay);
    const workoutGroupType = getWorkoutGroupTypeByAssignmentType(assignmentDayType);
    const progressStatus = ProgressStatus.INACTIVE;

    const workoutDayGroup = workoutFeedViewGroups[workoutGroupType];
    if (!workoutDayGroup.hasDay(startDay)) {
      workoutDayGroup.addDay(startDay, assignmentDayType, progressStatus);
    }

    const isToday = assignmentDayType === AssignmentDayType.TODAY;
    const dayInfo = workoutDayGroup.getDay(startDay);

    if (!isToday && (dayInfo.items.length === 0 && dayInfo.completedItems.length === 0)) {
      dayInfo.progressStatus = progressStatus;
      dayInfo.items.push({
        props: {
          id: `recovery-day-${startDay.valueOf()}`,
          assignmentDayType,
        },
        component: RecoveryDayCard,
        isCompleted: false,
      });
    }
  }
};

/**
 * Check if recovery days are required to fill the gaps between days. In the case there's some gap between
 * previous day and current day, create all necessary recovery days.
 *
 * @param {Object} prevWorkoutFeedView Previous Workout feed view document.
 * @param {Object} currentDate Moment date instance.
 * @param {Object} workoutFeedViewGroups
 */
const addRecoveryDaysIfNeeded = (prevWorkoutFeedView, currentDate, workoutFeedViewGroups) => {
  const {
    data: {
      startDate: prevDate,
    },
  } = prevWorkoutFeedView;

  const prevMoment = convertToLocalDate(prevDate.toMillis()).startOf('day');
  const currentMoment = moment(currentDate).startOf('day');
  const daysDiff = currentMoment.diff(prevMoment, 'days');

  if (daysDiff > 0) {
    // previous day and current day are not the same day
    const workoutGroupType = getWorkoutGroupTypeByDate(prevMoment);
    const workoutDayGroup = workoutFeedViewGroups[workoutGroupType];
    const prevDayInfo = workoutDayGroup.getDay(prevMoment);

    /*
      Recovery days should be added when one of these conditions are met:
      - The difference in days between previous day and current days is more than 1 day or,
      - The previous dayInfo from the days map doesn't have items
      Check if there are workout items in the previous dayInfo object
    */
    if (daysDiff > 1 || (prevDayInfo && (prevDayInfo.items.length === 0 || prevDayInfo.completedItems.length === 0))) {
      const amount = daysDiff - 1;
      addRecoveryDays(prevMoment, amount, workoutFeedViewGroups);
    }
  }
};

/**
 * Create and add future recovery days.
 *
 * @param {Object} lastLocalStartMomentDate The moment date instance representing the last
 * start time in local time.
 * @param {Object} workoutFeedViewModels The workout feed view models data structure.
 */
const addFutureRecoveryDays = (lastLocalStartMomentDate, workoutFeedViewModels) => {
  const lastWorkoutDayStart = moment(lastLocalStartMomentDate).startOf('day');

  const additionalRecoveryDaysDate = moment()
    .subtract(moment().utcOffset(), 'minutes')
    .add(DISPLAY_FUTURE_DAYS, 'days')
    .startOf('day');

  const additionalRecoveryDays = Math.max(additionalRecoveryDaysDate.diff(lastWorkoutDayStart, 'days'), 0);

  if (additionalRecoveryDays > 0) {
    addRecoveryDays(lastWorkoutDayStart, additionalRecoveryDays, workoutFeedViewModels);
  }
};

/**
 * Creates and adds today to the workout feed
 * @param {Object} workoutFeedViewGroups The workout feed view models data structure.
 */
const addToday = (workoutFeedViewGroups) => {
  const today = todayStartDate();
  // Create a new recovery day for the given day and add it to the Map
  const assignmentDayType = getAssignmentDayType(today);
  const workoutGroupType = getWorkoutGroupTypeByAssignmentType(assignmentDayType);
  const progressStatus = ProgressStatus.INACTIVE;

  const workoutDayGroup = workoutFeedViewGroups[workoutGroupType];

  if (!workoutDayGroup.hasDay(today)) {
    workoutDayGroup.addDay(today, assignmentDayType, progressStatus);
  }
};

export {
  addRecoveryDaysIfNeeded,
  addFutureRecoveryDays,
  addToday,
};
