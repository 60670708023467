import styled from 'styled-components';

const CustomSuggestionItemComponent = styled.div`
  display: flex;
  font-size: 12px;
  padding: 5px 16px 5px;
`;

export {
  CustomSuggestionItemComponent,
};
