import { useMemo } from 'react';
import { useTheme } from 'styled-components';

const useAppTheme = () => {
  const {
    colors,
  } = useTheme();

  return useMemo(() => ({
    colors,
  }), [
    colors,
  ]);
};

export default useAppTheme;
