import format from 'string-template';

import {
  formatSecondsToMinForThreshold,
  TimeFormatType,
} from '../../utils/time';

const ActivityTypes = {
  REST: 'REST',
  TIMED: 'TIMED',
  REPETITIONS: 'REPETITIONS',
  CIRCUIT: 'CIRCUIT',
};

const ActivityGoalType = {
  REPETITIONS: 'repetitions',
  DURATION: 'duration',
};

const GOAL_FORMAT_REPETITIONS = 'x{goalValue}';

const formatGoal = (goalValue, activityGoalType) => {
  if (activityGoalType === ActivityGoalType.DURATION) {
    return formatSecondsToMinForThreshold(goalValue, TimeFormatType.SHORT_DISPLAY);
  }
  return format(GOAL_FORMAT_REPETITIONS, {
    goalValue,
  });
};

class BaseActivity {
  /**
   * Constructs a new activity out of a JSON activity definition.
   * @param {Object} jsonActivity The activity in JSON format.
   * @param {Boolean} isNew Flag to create new object without any property value,
   * Its will use to create new object for editable workout.
   * For new object we don't have any property to assing so bypass throwing error
   */
  constructor(jsonActivity, isNew = false) {
    const {
      exerciseId,
      type,
      name,
      note,
      description,
      videoUrl,
      videoPreviewUrl,
      videoPreviewThumbnail,
      restTime,
      restTimeDisplayUnit,
      side,
      repetitions,
      duration,
      durationDisplayUnit,
      tags,
    } = jsonActivity;

    // Required fields
    this.exerciseId = exerciseId;
    this.type = type;
    /*
      Name is required for all activities except for CIRCUIT activities.
      For CIRCUIT activities we do still want to be able to save correctly to
      firebase and that's why we need to initialize with an empty string,
      otherwise firebase does not considers undefined as a valid value.
    */
    this.name = name || '';

    if (this.type === ActivityTypes.REPETITIONS) {
      this.repetitions = repetitions || '';
    }

    if (this.type === ActivityTypes.TIMED || this.type === ActivityTypes.REST) {
      this.duration = duration || '';
      this.durationDisplayUnit = durationDisplayUnit;
    }

    this.tags = tags || [];

    // If no name is provided for a rest activity just call it "Rest"
    if (!this.name && this.type === ActivityTypes.REST) {
      this.name = 'Rest';
    }

    if (!this.type) {
      throw new Error('Error loading activity. Missing required field: type');
    }

    if (this.type !== ActivityTypes.CIRCUIT && !this.name && !isNew) {
      throw new Error('Error loading activity. Missing required field: name');
    }

    this.note = note || '';

    this.description = description || '';

    if (restTime) {
      this.restTime = restTime;
      this.restTimeDisplayUnit = restTimeDisplayUnit;
    }

    if (this.type !== ActivityTypes.CIRCUIT && !isNew) {
      if (videoUrl) {
        this.videoUrl = videoUrl || '';
      }
      // TODO: Update this property once we fix the exercises that are missing the preview url.
      this.videoPreviewUrl = videoPreviewUrl || videoUrl || '';
      this.videoPreviewThumbnail = videoPreviewThumbnail || '';
      this.side = side || '';
    }
  }

  get hasDetailsInfo() {
    return !!(this.videoPreviewUrl || this.videoUrl || this.description);
  }

  get goal() {
    if (this.type !== ActivityTypes.CIRCUIT) {
      const goalFieldName = this.type === ActivityTypes.REPETITIONS
        ? ActivityGoalType.REPETITIONS
        : ActivityGoalType.DURATION;

      const goalValue = this[goalFieldName];

      return formatGoal(goalValue, goalFieldName);
    }
    return '';
  }
}

export default BaseActivity;
export {
  ActivityTypes,
  ActivityGoalType,
};
