import React, {
  useState,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import EditableWorkout from '../../models/EditableWorkout';
import WorkoutContext from '../../../context/WorkoutContext';
import CollectionName from '../../../utils/collections';
import { firestorePaths } from '../../../utils/firebasePaths';

const WorkoutEditorContextProviderFromUrl = ({ children }) => {
  const {
    params: {
      workoutId,
      assignmentId,
    },
  } = useRouteMatch();

  const [isWorkoutReady, setIsWorkoutReady] = useState(false);
  const [workoutDoc, setWorkoutDoc] = useState(null);

  useEffect(() => {
    let shouldUpdate = true;

    const init = async () => {
      const path = assignmentId
        ? `${firestorePaths.WORKOUT_ASSIGNMENT}/${assignmentId}`
        : `${firestorePaths.WORKOUT}/${workoutId}`;
      const type = assignmentId ? CollectionName.WORKOUT_ASSIGNMENT : CollectionName.WORKOUT;

      const doc = new EditableWorkout(path, type);

      await doc.init();

      if (shouldUpdate) {
        setWorkoutDoc(doc);
        setIsWorkoutReady(true);
      }
    };

    init();

    return () => {
      shouldUpdate = false;
    };
  }, [
    workoutId,
    assignmentId,
  ]);

  const workout = useMemo(() => ({
    isWorkoutReady,
    workoutDoc,
  }), [
    workoutDoc,
    isWorkoutReady,
  ]);

  return (
    <WorkoutContext.Provider value={workout}>
      {children}
    </WorkoutContext.Provider>
  );
};

WorkoutEditorContextProviderFromUrl.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(WorkoutEditorContextProviderFromUrl);
