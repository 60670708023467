import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import VideoPlaylistContext from './VideoPlaylistContext';

const VideoPlaylistContextProvider = ({
  children,
}) => {
  const [videos, setVideos] = useState([]);
  const [hasPlayerForEveryVideo, setHasPlayerForEveryVideo] = useState(false);
  const [duration, setDuration] = useState(0);
  const [sliderSeekTime, setSliderSeekTime] = useState(0);

  /**
  * Add the player to the video in the array. Check if the video can be played.
  *
  * @param {string} id - id of the video
  * @param {player} player - instance of the player of the video
  */
  const addPlayerToVideo = useCallback((id, player) => {
    if (hasPlayerForEveryVideo) {
      return;
    }

    const newVideos = videos.map((video) => {
      if (video.archiveId === id && !video.player) {
        const videoDuration = player.getDuration();
        return {
          ...video,
          player,
          canPlay: typeof videoDuration === 'number' && videoDuration > 0,
        };
      }
      return {
        ...video,
      };
    });
    setVideos(newVideos);
  }, [
    videos,
    setVideos,
    hasPlayerForEveryVideo,
  ]);

  /**
   * Update if all the videos have player and the sum of their durations
   */
  useEffect(() => {
    const videosWithPlayer = videos.filter((vid) => !!vid.player);
    const doAllVideosHavePlayer = videosWithPlayer.length === videos.length;
    setHasPlayerForEveryVideo(doAllVideosHavePlayer);

    if (doAllVideosHavePlayer) {
      const durationsSum = videos.reduce((sum, { player }) => {
        // Duration could be null if the video didn't load properly
        const currentDuration = player.getDuration() || 0;
        return (sum + currentDuration);
      }, 0);
      setDuration(durationsSum);
    }
  }, [
    videos,
  ]);

  const playableVideos = useMemo(() => (
    videos.filter((video) => !!video.canPlay)
  ), [
    videos,
  ]);

  const context = useMemo(() => ({
    videos,
    hasPlayerForEveryVideo,
    duration,
    sliderSeekTime,
    playableVideos,
    setVideos,
    setHasPlayerForEveryVideo,
    setDuration,
    setSliderSeekTime,
    addPlayerToVideo,
  }), [
    videos,
    hasPlayerForEveryVideo,
    duration,
    sliderSeekTime,
    playableVideos,
    setVideos,
    setHasPlayerForEveryVideo,
    setDuration,
    setSliderSeekTime,
    addPlayerToVideo,
  ]);

  return (
    <VideoPlaylistContext.Provider value={context}>
      {children}
    </VideoPlaylistContext.Provider>
  );
};

VideoPlaylistContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const withVideoPlaylistContextProvider = (Component) => (props) => (
  <VideoPlaylistContextProvider>
    <Component {...props} />
  </VideoPlaylistContextProvider>
);

export default VideoPlaylistContextProvider;
export {
  withVideoPlaylistContextProvider,
};
