import styled from 'styled-components';

import { fontFamily } from '../../../styles/text';
import { isIOS } from '../../../utils/platform';

const ChatFeedContainer = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;

  .str-chat__modal__close-button {
    z-index: 999999;
    background: ${({ theme }) => theme.colors.base.primary} !important;
    border-radius: 10px;
    top: ${isIOS() ? '70px' : '10px'};
    right: 10px;
  }

  .str-chat__message {
    font-family: ${fontFamily.sansSerif} !important;
  }

  .str-chat__message-text-inner {
    background-color: ${({ theme }) => theme.colors.system.background} !important;
  }

  .str-chat__message--me
  .str-chat__message-text-inner {
    background-color: ${({ theme }) => theme.colors.base.primary} !important;
    color: ${({ theme }) => theme.colors.system.alpha};
  }
`;

export {
  ChatFeedContainer,
};
