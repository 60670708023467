import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import VideoPlayer, { withVideoPlayerContextProvider } from '../../../../../../components/VideoPlayer';

import {
  VideoPreviewContainer,
  ClickOverlay,
  ShadowOverlay,
} from './styles';

const VideoPreview = ({
  videoUrl,
  onClick,
  className,
  showShadowOverlay,
}) => (
  <VideoPreviewContainer className={className}>
    <VideoPlayer
      url={videoUrl}
      loop
      autoPlay
      enableAutoPause={false}
      playsinline
      muted
      volume={0}
    />
    {showShadowOverlay && <ShadowOverlay />}
    <ClickOverlay onClick={onClick} />
  </VideoPreviewContainer>
);

VideoPreview.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  showShadowOverlay: PropTypes.bool,
};

VideoPreview.defaultProps = {
  onClick: () => {},
  className: '',
  showShadowOverlay: false,
};

export default compose(
  withVideoPlayerContextProvider,
)(VideoPreview);
