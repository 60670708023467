import styled from 'styled-components';

import {
  headlineFontStyles,
  sansSerif,
} from '../../../../styles/text';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100px;
  width: 100%;
  padding: 0 24px 0 0;
  background-color: ${({ theme }) => theme.colors.base.tertiary};
  margin-bottom: 16px;
`;

const Bar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 45px;
  background-color: ${({ theme }) => theme.colors.system.secondary6};

  /* This draws an arrow */
  &::after {
    position: absolute;
    content: '';
    display: inline-block;
    color: ${({ theme }) => theme.colors.base.tertiary};
    background-color: ${({ theme }) => theme.colors.system.secondary6};
    border-right: 50px solid;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    right: -50px;
  }
`;

const CounterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

const BadgeContainer = styled.div`
  height: 100%;
  z-index: 1;

  > svg {
    height: 100%;
    max-height: 90px;
    width: 100%;
  }
`;

const Counter = styled.div`
  ${headlineFontStyles}
  font-size: 70px;
  text-transform: uppercase;
  text-align: right;
  color: ${({ theme }) => theme.colors.fonts.primary};
  margin-right: 10px;
  margin-left: 20px;
`;

const Description = styled.div`
  ${sansSerif.headlineFontStyles}
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.fonts.primary};
`;

export {
  Container,
  Bar,
  CounterContainer,
  BadgeContainer,
  Counter,
  Description,
};
