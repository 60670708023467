import styled from 'styled-components';

import Card from '../../../components/Card';
import Button from '../../../components/Button';
import {
  Description,
} from '../commonStyles';

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
`;

const StyledDescription = styled(Description)`
  padding-bottom: 23px;
`;

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.base.primary};
  color: ${({ theme }) => theme.colors.system.alpha};
  font-size: 14px;
  line-height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    margin-right: 12px;
    width: 18px;
    height: 18px;
  }
`;

const ActivityItem = styled.div`
  background: ${({ theme }) => theme.colors.system.background};
  border: 1px solid rgba(11, 28, 79, 0.4);
  border-radius: 2px;
  padding: 9px 4px;
  text-align: center;
`;

const ActivitiesContainer = styled.div`
  display: grid;
  column-gap: 8px;
  row-gap: 8px;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  margin-bottom: 10px;

  ${ActivityItem} {
    align-self: center;
  }
`;

export {
  StyledCard,
  StyledDescription,
  StyledButton,
  ActivitiesContainer,
  ActivityItem,
};
