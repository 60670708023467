import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledCardItem,
  Title,
  StyledEstimatedDuration,
} from './styles';

const WorkoutItem = ({
  name,
  duration,
  onClick,
}) => (
  <StyledCardItem onClick={onClick}>
    <Title>{name}</Title>
    {!!duration && (
      <StyledEstimatedDuration duration={duration} />
    )}
  </StyledCardItem>
);

WorkoutItem.propTypes = {
  name: PropTypes.string.isRequired,
  duration: PropTypes.number,
  onClick: PropTypes.func.isRequired,
};

WorkoutItem.defaultProps = {
  duration: null,
};

export default WorkoutItem;
