import React, {
  useEffect,
  useContext,
  useState,
} from 'react';
import {
  IonAlert,
  setupIonicReact,
} from '@ionic/react';
import {
  Route,
  Redirect,
  Switch,
  useLocation,
} from 'react-router-dom';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

import useSessionStore from '../hooks/useSessionStore';
import PrivateRoute from '../components/PrivateRoute';
import UserRoutes from '../routes/UserRoutes';
import AppContext, { withAppContextProvider, withAppContextReady } from '../context/AppContext';
import { withInAppBrowserContextProvider } from '../context/InAppBrowserContext';
import CoachRoutes from '../routes/CoachRoutes';
import { withFirebaseContextReady } from '../context/FirebaseContext';
import { withStyledThemeProvider } from '../context/StyledThemeContext';
import { withWatchContextProvider } from '../context/WatchContext';
import { withAppCustomThemeProvider } from '../context/AppCustomThemeContext';
import LoginPage from '../pages/Login';
import TrackerPlayground from '../services/trackingSystem/testComponents/TrackerPlayground';
import config from '../config';
import { logPageViewEvent } from '../utils/logger';
import withAuthentication from './Auth';
import GlobalStyles from './styles';
import texts from './texts.json';

const App = () => {
  setupIonicReact({});
  const { authUser = {} } = useSessionStore();
  const location = useLocation();

  const {
    isLowPowerModeEnabled,
  } = useContext(AppContext);
  const [showLowPowerModeAlert, setShowLowPowerModeAlert] = useState(isLowPowerModeEnabled);

  useEffect(() => {
    logPageViewEvent(location.pathname.split('/')[1]);
  }, [location]);

  useEffect(() => {
    setShowLowPowerModeAlert(isLowPowerModeEnabled);
  }, [isLowPowerModeEnabled]);

  const onLowPowerModeAlertDismissed = () => {
    setShowLowPowerModeAlert(false);
  };

  const { search, state } = location;
  const uid = authUser ? authUser.uid : '';
  const { enablePlayground } = config;

  return (
    <>
      <GlobalStyles />
      <IonAlert
        isOpen={showLowPowerModeAlert}
        header={texts.lowPowerModeHeader}
        message={texts.lowPowerModeMessage}
        onDidDismiss={onLowPowerModeAlertDismissed}
        buttons={[
          {
            text: 'OK',
            handler: onLowPowerModeAlertDismissed,
          },
        ]}
      />
      <Switch>
        <Route exact path="/login" component={LoginPage} />
        <PrivateRoute path="/u/:userId" component={UserRoutes} />
        <PrivateRoute exact path="/">
          <Redirect to={{ pathname: `/u/${uid}/home`, search, state }} />
        </PrivateRoute>
        <PrivateRoute exact path="/cronometerCallback">
          <Redirect to={{ pathname: `/u/${uid}/cronometerCallback`, search, state }} />
        </PrivateRoute>

        <PrivateRoute path="/coach" component={CoachRoutes} />

        <Route
          exact
          path="/playground"
          render={(props) => (
            enablePlayground
              ? <TrackerPlayground {...props} />
              : <Redirect to="/" />
          )}
        />

        {/* Redirect to the root if the route did not match any of the ones listed in here */}
        <Redirect to="/" />
      </Switch>
    </>
  );
};

export default compose(
  withFirebaseContextReady,
  withAppContextProvider,
  withAppContextReady,
  withStyledThemeProvider,
  withAppCustomThemeProvider,
  withAuthentication,
  withWatchContextProvider,
  withInAppBrowserContextProvider,
  observer,
)(App);
