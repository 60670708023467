import React from 'react';
import {
  MessageSimple,
  Thread,
} from 'stream-chat-react';

import MessagingInput from '../MessagingInput';
import ThreadHeader from './ThreadHeader';
import './MessagingThread.css';

const MessagingThread = () => (
  <Thread
    Message={MessageSimple}
    MessageInput={MessagingInput}
    ThreadHeader={ThreadHeader}
  />
);

export default MessagingThread;
