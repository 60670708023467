import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { IonAlert } from '@ionic/react';
import { useRouteMatch } from 'react-router-dom';
import format from 'string-template';
import * as Sentry from '@sentry/browser';

import { CapacitorError } from '../../../../../utils/capacitor';
import texts from './texts.json';
import {
  Container,
  IconContainer,
  ImageUploadButtonContainer,
  StyledAddImageIcon,
  StyledImage,
  ImageWrapper,
  FileDeleteIcon,
  StyledLoadingPage,
} from './styles';

const ImageUpload = ({
  icon: CheckInImageIcon,
  onFileSelected,
  type: imageType,
  image,
  shouldAllowActions,
  onDeletion,
}) => {
  const [isImageLoading, setIsImageLoading] = useState(false);
  const { params: { userId } } = useRouteMatch();
  const [error, setError] = useState('');
  const openFileSelector = useCallback(async () => {
    if (!shouldAllowActions) {
      return;
    }
    try {
      setIsImageLoading(true);
      const cameraPhoto = await Camera.getPhoto({
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Prompt,
        quality: 100,
        webUseInput: true,
      });

      onFileSelected({
        dataUrl: cameraPhoto.dataUrl,
        format: cameraPhoto.format,
        isFileChanged: true,
        imageType,
      });
    } catch (imageError) {
      setIsImageLoading(false);
      setError(imageError.message);
      // report error to sentry only if it's not a user cancellation
      if (imageError.message !== CapacitorError.USER_CANCELLED) {
        Sentry.captureException(imageError, {
          extra: {
            userId,
            imageType,
          },
        });
      }
    }
  }, [
    onFileSelected,
    imageType,
    shouldAllowActions,
    userId,
  ]);

  return (
    <Container>
      <IconContainer>
        {(image || isImageLoading) ? (
          <>
            {shouldAllowActions && image && <FileDeleteIcon onClick={() => onDeletion(imageType)} />}
            <ImageWrapper onClick={openFileSelector}>
              <StyledImage
                onLoad={() => setIsImageLoading(false)}
                src={image}
                $isLoading={isImageLoading}
              />
            </ImageWrapper>
            {isImageLoading && <StyledLoadingPage fullHeight={false} />}
          </>
        ) : <CheckInImageIcon onClick={openFileSelector} />}
      </IconContainer>
      {shouldAllowActions && (
        <ImageUploadButtonContainer onClick={openFileSelector}>
          <StyledAddImageIcon />
          {texts.uploadPhoto}
        </ImageUploadButtonContainer>
      )}
      <IonAlert
        isOpen={!!error}
        message={format(texts.selectImageError, { error })}
        header={texts.importError}
        backdropDismiss
        buttons={
          [
            {
              text: texts.okay,
              handler: () => setError(''),
            },
          ]
        }
      />
    </Container>
  );
};

ImageUpload.propTypes = {
  icon: PropTypes.elementType.isRequired,
  onFileSelected: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  image: PropTypes.string,
  shouldAllowActions: PropTypes.bool.isRequired,
  onDeletion: PropTypes.func.isRequired,
};

ImageUpload.defaultProps = {
  image: '',
};

export default ImageUpload;
