const baseCheckInQuestionList = [
  'win',
  'status',
];

const baseCheckInQuestionDefinition = {
  win: {
    question: 'What is a win from last week?',
    prompt: 'What is something to celebrate?',
    id: 'win',
    active: true,
    isDefault: true,
  },
  status: {
    question: 'How are you?',
    prompt: 'How have you been feeling since your last checkin? What can I help you with this week?',
    id: 'status',
    active: true,
    isDefault: true,
  },
};

export {
  baseCheckInQuestionList,
  baseCheckInQuestionDefinition,
};
