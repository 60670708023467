const getPixelsNumber = (element, percentage) => {
  const elementHeight = element.clientHeight;
  return elementHeight * (percentage / 100);
};

const getPixelsValue = (element, percentage) => `${getPixelsNumber(element, percentage)}px`;

export {
  getPixelsNumber,
  getPixelsValue,
};
