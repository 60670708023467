import React, {
  useMemo,
  useContext,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import { breakpoints } from '../../styles/breakpoints';
import { ReactComponent as OverrideIcon } from '../../assets/icons/icon_override.svg';
import { ReactComponent as RevertIcon } from '../../assets/icons/icon_revert.svg';
import BaseActivity from '../../models/BaseActivity';
import { isVimeoVideo } from '../../utils/video';
import { isNative } from '../../utils/platform';
import texts from './texts.json';
import {
  Container,
  VideoContainer,
  StyledVideoPlayer,
  DescriptionContainer,
  Title,
  Description,
  ActionContainer,
  TransparentButton,
  ActionText,
  StyledOriginalExercise,
} from './styles';
import ActivityDetailsContext from './context';

const ActivityDetails = ({
  activity: {
    videoUrl,
    videoPreviewUrl,
    name,
    description,
    isS2Activity,
  },
  originalExercise,
  isPortraitOrientation,
  isLowPowerModeEnabled,
  onRevertExercise,
  onShowOverride,
}) => {
  const {
    enableExerciseOverride,
  } = useContext(ActivityDetailsContext);

  const videoSrc = useMemo(() => (
    videoUrl || videoPreviewUrl
  ), [videoPreviewUrl, videoUrl]);

  const isVimeoVideoLoaded = useMemo(() => isVimeoVideo(videoSrc), [videoSrc]);

  const isTablet = useMediaQuery({ query: `(min-width: ${breakpoints.tablet}px)` });

  // We show the portrait mode in two situations: On a phone on protrait, and on a browser (non-native device)
  const enablePortraitLayout = (!isTablet && isPortraitOrientation) || !isNative;

  const renderAction = useCallback(() => {
    let onAction = onShowOverride;
    let text = texts.replaceExerciseAction;
    let IconComponent = OverrideIcon;

    if (originalExercise) {
      onAction = onRevertExercise;
      text = texts.revertExerciseAction;
      IconComponent = RevertIcon;
    }

    return (
      <TransparentButton
        full={false}
        onClick={onAction}
      >
        <IconComponent />
        <ActionText>{text}</ActionText>
      </TransparentButton>
    );
  }, [
    onShowOverride,
    onRevertExercise,
    originalExercise,
  ]);

  // If we only have a videoPreview, then we need to mute the player to avoid loop errors.
  const isMutedS2Video = (isVimeoVideoLoaded && !videoUrl) || isS2Activity;

  return (
    <Container enablePortraitLayout={enablePortraitLayout}>
      {!!videoSrc && (
        <VideoContainer>
          <StyledVideoPlayer
            url={videoSrc}
            playsinline
            autoPlay={isMutedS2Video}
            volume={isMutedS2Video ? 0 : 1}
            muted={isMutedS2Video}
            loop
            showCustomControls={isMutedS2Video && isLowPowerModeEnabled}
            autoHideCustomControls
            controls={!isMutedS2Video}
            // S2 videos need to be enlarged because they have extra safe margins.
            $enlarge={isMutedS2Video}
          />
        </VideoContainer>
      )}
      <DescriptionContainer enablePortraitLayout={enablePortraitLayout}>
        <Title>{name}</Title>
        {!!description && (
          <Description enablePortraitLayout={enablePortraitLayout}>{description}</Description>
        )}
        {!!originalExercise && (
          <StyledOriginalExercise exercise={originalExercise} />
        )}
        {enableExerciseOverride && (
          <ActionContainer>
            {renderAction()}
          </ActionContainer>
        )}
      </DescriptionContainer>
    </Container>
  );
};

ActivityDetails.propTypes = {
  activity: PropTypes.instanceOf(BaseActivity).isRequired,
  originalExercise: PropTypes.object,
  isPortraitOrientation: PropTypes.bool.isRequired,
  onRevertExercise: PropTypes.func.isRequired,
  isLowPowerModeEnabled: PropTypes.bool.isRequired,
  onShowOverride: PropTypes.func.isRequired,
};

ActivityDetails.defaultProps = {
  originalExercise: null,
};

export default ActivityDetails;
