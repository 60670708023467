import React, {
  useEffect,
  useState,
  useRef,
} from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import moment from 'moment';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import Label from '../../../components/Label';
import SegmentsSelector from '../../../components/SegmentsSelector';
import { DateFormat } from '../../../utils/date';
import { UnitType, UnitTypeLabel } from '../../../utils/units';

import texts from './texts.json';
import { defaultValues, validationSchema } from './validation';
import {
  BodyMeasurementContainer,
  Container,
  FormInputContainer,
  StyledForm,
  StyledFormikInput,
  Title,
  StyledCalendarIcon,
  DateContainer,
} from './styles';

const BodyMeasurement = ({
  handleFormSubmit,
  shouldAllowActions,
  initialValues,
  date,
  isOpen,
}) => {
  const [units, setUnits] = useState(UnitType.IMPERIAL);
  const prevIsOpenRef = useRef();
  const formInnerRef = useRef();

  const handleSubmit = (formData) => {
    if (moment(date).isSame(moment(), 'date') && (formData !== initialValues)) {
      handleFormSubmit({
        ...formData,
        unitType: units,
      });
    }
  };

  useEffect(() => {
    if (initialValues.unitType) {
      setUnits(initialValues.unitType);
    } else {
      setUnits(UnitType.IMPERIAL);
    }
  }, [
    initialValues,
  ]);

  useEffect(() => {
    if (!isOpen && prevIsOpenRef.current) {
      formInnerRef.current.submitForm();
    }
    prevIsOpenRef.current = isOpen;
  }, [
    isOpen,
  ]);

  return (
    <Formik
      initialValues={initialValues.data ? initialValues.data : initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      innerRef={formInnerRef}
      enableReinitialize
    >
      {({
        handleSubmit: formikHandleSubmit,
      }) => (
        <StyledForm onSubmit={formikHandleSubmit}>
          <FormInputContainer>
            <DateContainer>
              <Label
                Icon={StyledCalendarIcon}
                text={moment(date).format(DateFormat.LONG_MONTH_WITH_DAY_YEAR)}
              />
            </DateContainer>
            <>
              <Container>
                <Title>{texts.bodyMeasurementTitle}</Title>
                <SegmentsSelector
                  currentValue={units}
                  onChange={setUnits}
                  segments={[
                    { value: UnitType.IMPERIAL, label: UnitTypeLabel[UnitType.IMPERIAL] },
                    { value: UnitType.METRIC, label: UnitTypeLabel[UnitType.METRIC] },
                  ]}
                />
                <BodyMeasurementContainer>
                  <StyledFormikInput
                    placeholder={texts.metricPlaceholders.weight.text}
                    name="weight"
                    disabled={!shouldAllowActions}
                    type="number"
                    inputMode="decimal"
                  />
                  <StyledFormikInput
                    placeholder={texts.metricPlaceholders.thigh.text}
                    name="thigh"
                    disabled={!shouldAllowActions}
                    type="number"
                    inputMode="decimal"
                  />
                  <StyledFormikInput
                    placeholder={texts.metricPlaceholders.stomach.text}
                    name="stomach"
                    disabled={!shouldAllowActions}
                    type="number"
                    inputMode="decimal"
                  />
                  <StyledFormikInput
                    placeholder={texts.metricPlaceholders.chest.text}
                    name="chest"
                    disabled={!shouldAllowActions}
                    type="number"
                    inputMode="decimal"
                  />
                  <StyledFormikInput
                    placeholder={texts.metricPlaceholders.upperArm.text}
                    name="upperArm"
                    disabled={!shouldAllowActions}
                    type="number"
                    inputMode="decimal"
                  />
                  <StyledFormikInput
                    placeholder={texts.metricPlaceholders.waist.text}
                    name="waist"
                    disabled={!shouldAllowActions}
                    type="number"
                    inputMode="decimal"
                  />
                  <StyledFormikInput
                    placeholder={texts.metricPlaceholders.hip.text}
                    name="hip"
                    disabled={!shouldAllowActions}
                    type="number"
                    inputMode="decimal"
                  />
                </BodyMeasurementContainer>
              </Container>
            </>
          </FormInputContainer>
        </StyledForm>
      )}
    </Formik>
  );
};

BodyMeasurement.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
  shouldAllowActions: PropTypes.bool,
  initialValues: PropTypes.object,
  date: PropTypes.any,
  isOpen: PropTypes.bool,
};

BodyMeasurement.defaultProps = {
  shouldAllowActions: false,
  initialValues: defaultValues,
  date: defaultValues.date,
  isOpen: false,
};

export default compose(
  observer,
)(BodyMeasurement);
