import styled from 'styled-components';

import Button from '../../../../../components/Button';
import CloseButton from '../../../../../components/CloseButton';
import { ReactComponent as MicIcon } from '../../../../assets/icon/mic-icon.svg';
import { ReactComponent as StopIcon } from '../../../../assets/icon/stop-icon.svg';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  align-items: center;
  column-gap: 10px;
  background: ${({ theme }) => theme.colors.system.alpha};
  padding: 6px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.system.gamma2};
  overflow-x: auto;
  overflow-y: hidden;
`;

const AudioRecorderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: center;
`;

const AudioPlayerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  justify-content: space-between;
  align-items: center;
`;

const StyledAudioPlayer = styled.audio`
  width: 100%;
  height: 32px;
`;

const StyledButton = styled(Button)`
  display: flex;
  background-color: ${({ theme }) => theme.colors.base.tertiary};
  color: ${({ theme }) => theme.colors.fonts.primary};
  padding: 10px 14px 10px ;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  height: 32px;
  flex-shrink: 0;
  width: fit-content;
  line-height: 30px;
  font-weight: 500;
  border-radius: 6px;
`;

const StyledMicIcon = styled(MicIcon)`
  display: flex;
  height: 14px;
  width: 14px;
  color: ${({ theme }) => theme.colors.fonts.primary};
  margin-right: 4px;
`;

const StyledStopIcon = styled(StopIcon)`
  display: flex;
  height: 14px;
  width: 14px;
  color: ${({ theme }) => theme.colors.system.error};
`;

const StyledClearButton = styled(CloseButton)`
  display: flex;
  background-color: ${({ theme }) => theme.colors.base.tertiary};
  color: ${({ theme }) => theme.colors.fonts.primary};
  padding: 10px 14px 10px ;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  height: 32px;
  flex-shrink: 0;
  width: fit-content;
  line-height: 30px;
  font-weight: 500;
  border-radius: 6px;
  svg {
    height: 14px;
    width: 14px;
    > path {
      stroke: ${({ theme }) => theme.colors.system.alpha};
    }
  }
`;

export {
  Container,
  AudioRecorderWrapper,
  StyledAudioPlayer,
  StyledButton,
  AudioPlayerWrapper,
  StyledMicIcon,
  StyledStopIcon,
  StyledClearButton,
};
