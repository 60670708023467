import styled from 'styled-components';

const SelectWrapper = styled.div`
  display: flex;
`;

const ValueWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const RequiredInput = styled.input`
  width: 100%;
  position: absolute;
  top: 0;
  opacity: 0;
`;

export {
  SelectWrapper,
  ValueWrapper,
  RequiredInput,
};
