import React from 'react';
import PropTypes from 'prop-types';

import ShortExerciseItem from '../ShortExerciseItem';
import texts from './texts.json';
import {
  Container,
  Title,
  HeaderSection,
  StyledOverrideIcon,
} from './styles';

const OriginalExercise = ({
  exercise,
  className,
}) => (
  <Container className={className}>
    <HeaderSection>
      <StyledOverrideIcon />
      <Title>
        {texts.title}
      </Title>
    </HeaderSection>
    <ShortExerciseItem exercise={exercise} />
  </Container>
);

OriginalExercise.propTypes = {
  exercise: PropTypes.object.isRequired,
  className: PropTypes.string,
};

OriginalExercise.defaultProps = {
  className: '',
};

export default OriginalExercise;
