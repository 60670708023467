import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import {
  CardContainer,
  SupportMessage,
} from '../commonStyles';
import text from './text.json';

const ErrorCard = () => (
  <CardContainer>
    <SupportMessage>{ReactHtmlParser(text.errorMessage)}</SupportMessage>
  </CardContainer>
);

export default ErrorCard;
