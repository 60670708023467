import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as CalendarIcon } from '../../../../../../assets/icons/icon_calendar.svg';

import {
  Container,
  Content,
  IconContainer,
  Name,
} from './styles';

const CalendarDay = ({
  dayName,
  className,
}) => (
  <Container className={className}>
    <Content>
      <IconContainer>
        <CalendarIcon />
      </IconContainer>
      <Name>{dayName}</Name>
    </Content>
  </Container>
);

CalendarDay.propTypes = {
  dayName: PropTypes.string.isRequired,
  className: PropTypes.string,
};

CalendarDay.defaultProps = {
  className: '',
};

export default CalendarDay;
