import React from 'react';
import useAppTheme from '../../../../../../hooks/useAppTheme';

import CloseButton from '../../../../../../components/CloseButton';

const FeedbackPanelCloseButton = (props) => {
  const { colors } = useAppTheme();
  return (
    <CloseButton color={colors.system.beta} {...props} />
  );
};

export default FeedbackPanelCloseButton;
