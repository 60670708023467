import { ExerciseTagCategory } from '../models/ExerciseTag';

const ActivitySideType = {
  ALTERNATE_SIDES: 'ALTERNATE_SIDES',
  LEFT_SIDE: 'LEFT_SIDE',
  RIGHT_SIDE: 'RIGHT_SIDE',
  LEFT_THEN_RIGHT_SIDE: 'LEFT_THEN_RIGHT_SIDE',
};

const BODY_WEIGHT_TAG = 'bodyweight';

const getRequiredEquipment = (activities = []) => {
  let equipment = {};

  activities.forEach((activity) => {
    if (activity.activities) {
      const circuitEquipment = getRequiredEquipment(activity.activities);
      equipment = {
        ...equipment,
        ...circuitEquipment,
      };
    } else {
      const { tags = [] } = activity;
      const equipmentTags = tags.filter((tag) => tag.category === ExerciseTagCategory.EQUIPMENT);
      equipmentTags.forEach(({ id, tag }) => {
        equipment[id] = tag;
      });
    }
  });

  return equipment;
};

const getEquipmentList = (activities = []) => {
  const equipment = getRequiredEquipment(activities);
  const equipmentList = Object.values(equipment);

  const bodyWeightTagIndex = equipmentList.indexOf(BODY_WEIGHT_TAG);

  if (equipmentList.length > 1 && bodyWeightTagIndex >= 0) {
    // Remove bodyweight if we have other pieces of equipment listed.
    equipmentList.splice(bodyWeightTagIndex, 1);
  }
  return equipmentList;
};

export {
  ActivitySideType,
  getEquipmentList,
};
