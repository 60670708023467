import ActivityDefinition from '../models/ActivityDefinition';
import { ActivityGoalType, ActivityTypes } from '../models/BaseActivity';

/**
 * Creates a new rest activity with the given restTime
 * as its duration.
 * @param restTime The restTime duration (in seconds)
 * @returns {ActivityDefinition} The rest activity in its definition format
 */
const createRestActivity = (restTime) => (
  new ActivityDefinition({
    type: ActivityTypes.REST,
    [ActivityGoalType.DURATION]: restTime,
  })
);

/**
 * Adds a restNote to the given activity
 * @param {ActivityDefinition} restActivity The Rest activity to use for building the note
 */
const buildRestNote = (restActivity) => (
  `Rest: ${restActivity.goal}`
);

/**
 * Prepare the activities array for rendering. The Rest activities are removed, and the requirement for the rest
 * activity (the number of seconds to rest) is added as a note to the previous activity.
 * @param {Array} activities
 * @return {Array} the prepared activities
 */
const prepareActivities = (activities) => (
  activities.reduce((preparedActivities, activity) => {
    if (activity.type === ActivityTypes.REST) {
      // We need to add the rest note to the previous activity, which is the last item pushed to the array.
      const previousActivity = preparedActivities[preparedActivities.length - 1];

      // We must show a rest card if it appears right after a circuit.
      if (!previousActivity || previousActivity.type === ActivityTypes.CIRCUIT) {
        return [...preparedActivities, activity];
      }

      // Build the Rest Note
      previousActivity.restNote = buildRestNote(activity);

      // We do not need to add the rest activity since it is not rendered. Return the same list we already have.
      return preparedActivities;
    }

    if (activity.type === ActivityTypes.CIRCUIT) {
      // Extract all properties for the circuit
      const circuit = { ...activity };

      // Prepare the circuit activities. The rest activities will be removed to calculate the connectors correctly.
      const preparedCircuitActivities = prepareActivities(activity.activities);

      // Replace the old activities list with the ones that were prepared.
      circuit.activities = preparedCircuitActivities;

      const circuitActivities = [circuit];

      if (circuit.restTime) {
        const circuitRest = createRestActivity(circuit.restTime);
        circuitActivities.push(circuitRest);
      }

      // Add the complete circuit to the result since we needed it for the FE to render.
      return [...preparedActivities, ...circuitActivities];
    }

    if (activity.restTime) {
      const activityRest = createRestActivity(activity.restTime);
      // Build the Rest Note
      activity.restNote = buildRestNote(activityRest); // eslint-disable-line no-param-reassign
    }

    // If it was any other type of activity, it means that nothing special is needed. Add it to the result.
    return [...preparedActivities, activity];
  }, [])
);

export {
  buildRestNote,
  prepareActivities,
};
