import styled from 'styled-components';

const StyledBurger = styled.div`
  display: flex;
  align-items: center;
`;

export {
  StyledBurger,
};
