import { IonSlides, IonSlide } from '@ionic/react';
import styled from 'styled-components';
import Card from '../Card';
import { ReactComponent as UploadedFileDeleteIcon } from '../../assets/icons/uploaded-file-delete-icon.svg';

const CardContainer = styled(Card)`
  margin: 2px 0;
  height: fit-content;
  padding: 16px;
`;

const FileDeleteIcon = styled(UploadedFileDeleteIcon)`
  align-self: flex-end;
`;

const StyledSlides = styled(IonSlides)`
  max-height: 315px;
  height: fit-content;
  width: 100%;
`;

const StyledSlide = styled(IonSlide)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledImg = styled.img`
  height: 100%;
  width: auto;
`;

const ImageWrapper = styled.div`
  height: 290px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  CardContainer,
  FileDeleteIcon,
  StyledSlides,
  StyledSlide,
  StyledImg,
  ImageWrapper,
};
