import styled from 'styled-components';

import PageContent from '../../../components/PageContent';
import colors from '../../../styles/baseColors';

const StyledPageContent = styled(PageContent)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: ${colors.system.alpha};
`;

export {
  StyledPageContent,
};
