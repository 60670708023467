import React from 'react';

const initialValues = {
  isReady: false,
  mealPlanDoc: null,
  hasMealPlan: false,
  mealPlanAssignmentDoc: null,
  mealPlanCollection: null,
};

const MealPlanContext = React.createContext(initialValues);

export default MealPlanContext;
export {
  initialValues,
};
