import styled from 'styled-components';
import Input from '../Input';
import Select from '../Select';

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const StyledInput = styled(Input)`
  flex: 1;
`;

const StyledSelect = styled(Select)`
  width: 150px;
  margin-left: 5px;
`;

export {
  Container,
  StyledInput,
  StyledSelect,
};
