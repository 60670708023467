import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';
import moment from 'moment';

import { ReactComponent as StopwatchIcon } from '../../assets/icons/icon_stopwatch.svg';
import Label from '../Label';
import text from './text.json';

const EstimatedDuration = ({
  duration,
  label,
}) => {
  const getEstimatedDuration = useCallback(() => {
    const momentDuration = moment.duration(duration, 'seconds');

    /*
      If we detect there are extra seconds in the duration, then we round it up to the next minute value. Let moment
      adjust the whole duration object.
    */
    if (momentDuration.seconds() > 0) {
      momentDuration.add(1, 'minutes');
    }
    const durationInHours = momentDuration.hours();
    const durationInMinutes = momentDuration.minutes();

    let hoursString = '';
    let minutesString = '';

    // If the duration is longer than an hour, we'll need to render the hours.
    if (durationInHours > 0) {
      hoursString = format(text.hoursString, {
        durationInHours,
        hoursLabel: durationInHours > 1 ? text.hours : text.hour, // Singular or plural based on the number of hours.
      });
    }

    // If there are minutes to show, then we render them.
    if (durationInMinutes > 0) {
      minutesString = format(text.minutesString, {
        durationInMinutes,
      });
    }

    const estimationString = `${hoursString}${minutesString}`;

    return label ? `${label}: ${estimationString}` : estimationString;
  }, [
    duration,
    label,
  ]);

  return (
    <Label
      Icon={StopwatchIcon}
      text={getEstimatedDuration()}
    />
  );
};

EstimatedDuration.propTypes = {
  duration: PropTypes.number.isRequired,
  label: PropTypes.string,
};

EstimatedDuration.defaultProps = {
  label: '',
};

export default EstimatedDuration;
