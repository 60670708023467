import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';
import { compose } from 'recompose';
import moment from 'moment';

import UserContext from '../UserContext';
import AppCustomizationContext from '../AppCustomizationContext';
import CheckIn from '../../models/CheckIn';
import UserNutritionLog from '../../models/UserNutritionLog';
import { getYearAndWeekFromDate } from '../../utils/date';
import useComponentMounted from '../../hooks/useComponentMounted';
import useComponentLoadingTime from '../../hooks/useComponentLoadingTime';
import useCurrentLoggedInUser from '../../hooks/useCurrentLoggedInUser';
import { LengthUnit, WeightUnit } from '../../utils/units';

import CheckInContext, { initialState } from './CheckInContext';

// This is the initial progress value for the checkin that is created.
const INITIAL_PROGRESS = 0;
const FIRST_WEEK_OF_YEAR = 1;
const LAST_CHECK_IN_WEEK = 52;

const CheckInContextProvider = ({
  children,
}) => {
  // We allow clients to update their past week's checkin until 6th day of the current week.
  // so if client check-in day is wednesday (3), then they can update their past week's checkin
  // until tuesday (2) of the current week.
  const getCurrentWeekDate = useCallback((checkInDay) => {
    // offsetting the date to sunday of the current week.
    const offsetDate = moment().subtract(checkInDay, 'days');
    if (offsetDate.isBefore(offsetDate.clone().startOf('isoWeek').day(6).endOf('day'))) {
      return offsetDate.subtract(1, 'week');
    }
    return offsetDate;
  }, []);

  const {
    userId,
    userDoc: {
      checkInDay = 0,
    },
  } = useContext(UserContext);
  const [isReady, setIsReady] = useState(initialState.isReady);
  const [currentCheckInDoc, setCurrentCheckInDoc] = useState(initialState.currentCheckInDoc);
  const [currentSelectedDate, setCurrentSelectedDate] = useState(getCurrentWeekDate(checkInDay));
  const [userNutritionLogsCollection, setUserNutritionLogsCollection] = useState();
  const { checkInQuestionDefinition, shouldUploadImage } = useContext(AppCustomizationContext);

  const isComponentMountedRef = useComponentMounted();
  const { isCurrentLoggedInUserInPath } = useCurrentLoggedInUser();
  const { startLoading, finishLoading } = useComponentLoadingTime('checkInContext');

  useEffect(() => {
    let disposer;
    const setDoc = async () => {
      startLoading();
      const { year, week } = getYearAndWeekFromDate(currentSelectedDate);
      const weekOfPreviousCheckIn = week === FIRST_WEEK_OF_YEAR ? LAST_CHECK_IN_WEEK : week - 1;
      const yearOfPreviousCheckIn = week === FIRST_WEEK_OF_YEAR ? year - 1 : year;
      const checkInCollection = await CheckIn.getCheckInByYearAndWeek(userId, year, week);
      const nutritionLogsCollection = await UserNutritionLog.getUserNutritionLogs(
        userId,
        moment(currentSelectedDate.clone().startOf('isoWeek')).add(checkInDay, 'days').utc(true).toDate(),
        moment(currentSelectedDate.clone().endOf('isoWeek')).add(checkInDay, 'days').utc(true).toDate(),
      );
      // Load previous checkIn details
      const previousWeekCheckInCollection = await CheckIn.getCheckInByYearAndWeek(
        userId,
        yearOfPreviousCheckIn,
        weekOfPreviousCheckIn,
      );
      const isCurrentCheckInSubmitted = checkInCollection?.docs[0]?.isSubmitted ?? true;
      const isWeightUnitsDifferent = previousWeekCheckInCollection.hasDocs
        && (checkInCollection?.docs[0]?.weightUnitType !== previousWeekCheckInCollection.docs[0]?.weightUnitType);
      const isLengthUnitsDifferent = previousWeekCheckInCollection.hasDocs
        && (checkInCollection?.docs[0]?.lengthUnitType !== previousWeekCheckInCollection?.docs[0]?.lengthUnitType);

      /* If current checkIn details are not submitted and measurements units are different,
        the current checkIn unit of measurements types are updated with the previous ones
      */
      if (previousWeekCheckInCollection.hasDocs && !isCurrentCheckInSubmitted
      && (isWeightUnitsDifferent || isLengthUnitsDifferent)) {
        const updatedCheckInData = {};
        if (isWeightUnitsDifferent) {
          updatedCheckInData.weightUnitType = previousWeekCheckInCollection.docs[0]?.weightUnitType;
        }
        if (isLengthUnitsDifferent) {
          updatedCheckInData.lengthUnitType = previousWeekCheckInCollection.docs[0]?.lengthUnitType;
        }
        await checkInCollection.docs[0].updateFields(updatedCheckInData);
      }

      disposer = autorun(() => {
        if (checkInCollection.hasDocs) {
          if (isComponentMountedRef.current) {
            setCurrentCheckInDoc(checkInCollection.docs[0]);
          }
        } else {
          const initalCheckInQuestions = Object.keys(checkInQuestionDefinition)
            .reduce((prev, checkIn) => {
              if (checkInQuestionDefinition[checkIn].active) {
                return {
                  ...prev,
                  [checkIn]: {
                    ...checkInQuestionDefinition[checkIn],
                    value: '',
                  },
                };
              }
              return prev;
            }, {});
          const newDocData = {
            createdAt: new Date(),
            isSubmitted: false,
            year,
            week,
            user: userId,
            lengthUnitType: LengthUnit.INCHES,
            weightUnitType: WeightUnit.POUNDS,
            questions: initalCheckInQuestions,
            progress: INITIAL_PROGRESS,
          };

          /*
            If the current user is in the path, we can add the doc to the collection. Otherwise, use just the data,
            since this is a coach using view-as.
          */
          if (isCurrentLoggedInUserInPath) {
            CheckIn.addDoc(newDocData);
          } else {
            setCurrentCheckInDoc(newDocData);
          }
        }

        if (isComponentMountedRef.current) {
          setUserNutritionLogsCollection(nutritionLogsCollection);
          setIsReady(true);
          finishLoading();
        }
      });
    };
    setDoc();
    return disposer;
  }, [
    currentSelectedDate,
    userId,
    isComponentMountedRef,
    checkInQuestionDefinition,
    isCurrentLoggedInUserInPath,
    checkInDay,
    startLoading,
    finishLoading,
  ]);

  const value = useMemo(() => ({
    isReady,
    currentCheckInDoc,
    currentSelectedDate,
    userNutritionLogsCollection,
    setCurrentSelectedDate,
    getCurrentWeekDate,
    checkInDay,
    shouldUploadImage,
  }), [
    isReady,
    currentCheckInDoc,
    currentSelectedDate,
    userNutritionLogsCollection,
    setCurrentSelectedDate,
    getCurrentWeekDate,
    checkInDay,
    shouldUploadImage,
  ]);

  return (
    <CheckInContext.Provider value={value}>
      {children}
    </CheckInContext.Provider>
  );
};

CheckInContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(CheckInContextProvider);
