import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import CalendarSlider from '../../../../components/CalendarSlider';
import { TimeFormat } from '../../../../utils/time';

import { Container } from './styles';

const TimePicker = ({
  name,
  initialValue,
  minuteValues,
  onChange,
}) => {
  const onIonChange = useCallback(({ detail: { value } }) => onChange(value), [onChange]);

  return (
    <Container>
      {name}
      <CalendarSlider
        currentSelectedDateTime={initialValue}
        onChange={onIonChange}
        presentation="time"
        minuteValues={minuteValues}
        displayFormat={TimeFormat.HOUR_MINUTE}
      />
    </Container>
  );
};

TimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  minuteValues: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

TimePicker.defaultProps = {
  initialValue: null,
  minuteValues: '0,10,20,30,40,50',
};

export default TimePicker;
