/* eslint class-methods-use-this: off */

import BasePrediction from './BasePrediction';

/*
  Figure 2. 21 hand landmarks definition.
  https://google.github.io/mediapipe/solutions/hands
*/
const HandLandmark = {
  WRIST: 0,
  THUMB_CMC: 1,
  THUMB_MCP: 2,
  THUMB_IP: 3,
  THUMB_TIP: 4,
  INDEX_FINGER_MCP: 5,
  INDEX_FINGER_PIP: 6,
  INDEX_FINGER_DIP: 7,
  INDEX_FINGER_TIP: 8,
  MIDDLE_FINGER_MCP: 9,
  MIDDLE_FINGER_PIP: 10,
  MIDDLE_FINGER_DIP: 11,
  MIDDLE_FINGER_TIP: 12,
  RING_FINGER_MCP: 13,
  RING_FINGER_PIP: 14,
  RING_FINGER_DIP: 15,
  RING_FINGER_TIP: 16,
  PINKY_FINGER_MCP: 17,
  PINKY_FINGER_PIP: 18,
  PINKY_FINGER_DIP: 19,
  PINKY_FINGER_TIP: 20,
};

const Finger = {
  THUMB: 'THUMB',
  INDEX: 'INDEX',
  MIDDLE: 'MIDDLE',
  RING: 'RING',
  PINKY: 'PINKY',
};

const FingerLookupIndices = {
  [Finger.THUMB]: [
    HandLandmark.WRIST,
    HandLandmark.THUMB_CMC,
    HandLandmark.THUMB_MCP,
    HandLandmark.THUMB_IP,
    HandLandmark.THUMB_TIP,
  ],
  [Finger.INDEX]: [
    HandLandmark.WRIST,
    HandLandmark.INDEX_FINGER_MCP,
    HandLandmark.INDEX_FINGER_PIP,
    HandLandmark.INDEX_FINGER_DIP,
    HandLandmark.INDEX_FINGER_TIP,
  ],
  [Finger.MIDDLE]: [
    HandLandmark.WRIST,
    HandLandmark.MIDDLE_FINGER_MCP,
    HandLandmark.MIDDLE_FINGER_PIP,
    HandLandmark.MIDDLE_FINGER_DIP,
    HandLandmark.MIDDLE_FINGER_TIP,
  ],
  [Finger.RING]: [
    HandLandmark.WRIST,
    HandLandmark.RING_FINGER_MCP,
    HandLandmark.RING_FINGER_PIP,
    HandLandmark.RING_FINGER_DIP,
    HandLandmark.RING_FINGER_TIP,
  ],
  [Finger.PINKY]: [
    HandLandmark.WRIST,
    HandLandmark.PINKY_FINGER_MCP,
    HandLandmark.PINKY_FINGER_PIP,
    HandLandmark.PINKY_FINGER_DIP,
    HandLandmark.PINKY_FINGER_TIP,
  ],
};

const DEFAULT_CONFIDENCE_LEVEL = 0.90;

const isValueInRange = (value, minValue, maxValue) => (
  value <= maxValue && value >= minValue
);

class Hand extends BasePrediction {
  constructor(handDefinition, aspectRatio) {
    super(aspectRatio);

    this.keypoints = handDefinition;
  }

  keypointsDefinition() {
    return HandLandmark;
  }

  /**
   * Check if the given finger is stretched based on the confidence level.
   *
   * @param {string} finger One of Finger.
   * @param {number=} confidence Confidence level, a number between 0 and 1.
   * @returns True when the finger is stretched, otherwise false.
   */
  isFingerStretched(finger, confidence = DEFAULT_CONFIDENCE_LEVEL) {
    const confidenceLevel = (confidence >= 0 && confidence <= 1)
      ? confidence
      : DEFAULT_CONFIDENCE_LEVEL;

    const fingerIndices = FingerLookupIndices[finger];

    if (!fingerIndices) {
      return false;
    }

    const anglePIP = this.keypointAngle(fingerIndices[2], fingerIndices[1], fingerIndices[3]);
    const angleDIP = this.keypointAngle(fingerIndices[3], fingerIndices[2], fingerIndices[4]);

    const maxAngle = 180;
    const minAngle = maxAngle * confidenceLevel;

    return isValueInRange(anglePIP, minAngle, maxAngle) && isValueInRange(angleDIP, minAngle, maxAngle);
  }

  /**
   * Check if all fingers are stretched with the given confidence level.
   *
   * @param {number} confidence A number between 0 and 1.
   * @returns True when all fingers are stretched, otherwise false.
   */
  areAllFingersStretched(confidence = DEFAULT_CONFIDENCE_LEVEL) {
    return Object.values(Finger).every((finger) => (
      this.isFingerStretched(finger, confidence)
    ));
  }
}

export default Hand;
export {
  HandLandmark,
  FingerLookupIndices,
  Finger,
};
