import React, { useCallback } from 'react';

import useUserNavigation from '../../../../../hooks/useUserNavigation';
import { actionText } from './texts.json';
import {
  StyledButton,
} from './styles';

const LogActivityCard = () => {
  const { navigateToUserPath } = useUserNavigation();

  const onGoToWorkoutSelector = useCallback(() => (
    navigateToUserPath('workoutSelector')
  ), [
    navigateToUserPath,
  ]);

  return (
    <StyledButton onClick={onGoToWorkoutSelector}>
      {actionText}
    </StyledButton>
  );
};

export default LogActivityCard;
