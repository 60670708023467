import { getFirestore } from 'firestorter';

import Collection from '../utils/collections';
import BaseDocument from './BaseDocument';

class Plan extends BaseDocument {
  get planCode() {
    return this.data.planCode;
  }

  get totalPriceInCents() {
    return this.data.totalPriceInCents;
  }

  get currency() {
    return this.data.currency || 'usd';
  }

  get recurringBillingMonths() {
    return this.data.recurringBillingMonths || 0;
  }

  get nextPlan() {
    return this.data.nextPlan || '';
  }

  static async getPlan(planId) {
    if (!planId) {
      return null;
    }
    const db = getFirestore();

    const planQuerySnapshot = await db.collectionGroup(Collection.PRICE)
      .where('priceId', '==', planId)
      .limit(1)
      .get();

    if (!planQuerySnapshot.empty) {
      const planDoc = planQuerySnapshot.docs[0];
      const plan = new Plan(planDoc.ref);
      await plan.init();
      return plan;
    }

    return null;
  }
}

export default Plan;
