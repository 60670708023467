import styled from 'styled-components';

import { labelStyles, fontFamily } from '../../styles/text';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 13px;
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 16px;
`;

const TextContainer = styled.div`
  font-family: ${fontFamily.sansSerif};
  ${labelStyles};
  color: ${({ theme }) => theme.colors.system.gamma2};
`;

export {
  Container,
  IconContainer,
  TextContainer,
};
