import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import UserContractContext from '../../../context/UserContractContext';
import ContractTerms from '../../ContractTerms';
import LoadingPage from '../../LoadingPage';
import ScrollHandler from '../../ScrollHandler';
import {
  TermsContainer,
} from './styles';

const NewContractTerms = ({
  setShowActions,
}) => {
  const { newContractDoc, isReady } = useContext(UserContractContext);

  useEffect(() => {
    setShowActions(false);
  }, [
    setShowActions,
  ]);

  if (!isReady) {
    <LoadingPage fullHeight={false} />;
  }

  return (
    <TermsContainer>
      <ScrollHandler onScrollToBottom={() => setShowActions(true)}>
        {!!newContractDoc && <ContractTerms contractDoc={newContractDoc} />}
      </ScrollHandler>
    </TermsContainer>
  );
};

NewContractTerms.propTypes = {
  setShowActions: PropTypes.func,
};

NewContractTerms.defaultProps = {
  setShowActions: () => {},
};

export default NewContractTerms;
