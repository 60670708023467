import React from 'react';
import PropTypes from 'prop-types';

import {
  ActivityFeedbackCard,
  ActivityName,
  FeedbackNote,
} from './styles';

const ActivityFeedback = ({
  name,
  note,
  isSelected,
}) => (
  <ActivityFeedbackCard isSelected={isSelected}>
    <ActivityName>{name}</ActivityName>
    <FeedbackNote>{note}</FeedbackNote>
  </ActivityFeedbackCard>
);

ActivityFeedback.propTypes = {
  name: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default ActivityFeedback;
