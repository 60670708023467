import React from 'react';
import PropTypes from 'prop-types';

import {
  Title,
  Description,
} from '../commonStyles';
import WorkoutsList from '../WorkoutsList';
import texts from './texts.json';
import {
  Container,
  StyledCard,
} from './styles';

const WorkoutSelection = ({
  workoutsCollection,
  onWorkoutSelected,
  className,
}) => (
  <Container className={className}>
    <StyledCard>
      <Title>{texts.title}</Title>
      <Description>{texts.description}</Description>
    </StyledCard>
    {!!workoutsCollection && (
      <WorkoutsList
        workouts={workoutsCollection}
        onWorkoutSelected={onWorkoutSelected}
      />
    )}
  </Container>
);

WorkoutSelection.propTypes = {
  className: PropTypes.string,
  workoutsCollection: PropTypes.object,
  onWorkoutSelected: PropTypes.func.isRequired,
};

WorkoutSelection.defaultProps = {
  className: '',
  workoutsCollection: null,
};

export default WorkoutSelection;
