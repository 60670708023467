import styled from 'styled-components';

import { ReactComponent as OpenArrow } from '../../assets/icons/open-arrow.svg';
import { sansSerif } from '../../styles/text';

const StyledHeader = styled.div`
  font-family: 'Neue Montreal'; /* TODO: refactor this with mobile issue #1313 */
  font-size: 16px;
  letter-spacing: 3px;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const Title = styled.div`
  ${sansSerif.headline3};
  font-size: 16px;
  text-transform: uppercase;
`;

const DefaultLeftIcon = styled(OpenArrow)`
  transform: rotate(-90deg);

  path {
    stroke: ${({ theme }) => theme.colors.system.beta};
  }
`;

const DefaultRightIcon = styled(DefaultLeftIcon)`
  transform: rotate(90deg);
`;

export {
  StyledHeader,
  IconWrapper,
  Title,
  DefaultLeftIcon,
  DefaultRightIcon,
};
