import styled from 'styled-components';
import { IonSearchbar } from '@ionic/react';

import { fontFamily } from '../../../styles/text';
import Button from '../../Button';
import {
  Header,
} from '../commonStyles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  ${Header} {
    padding: 8px 16px;
    align-items: center;
  }
`;

const StyledSearchbar = styled(IonSearchbar)`
  padding: 0 !important;
  height: 50px !important;

  --background: ${({ theme }) => theme.colors.system.background};
  --border-radius: 6px;
  --placeholder-color: rgb(20, 34, 47);
  --placeholder-opacity: 0.4;

  .searchbar-input-container {
    height: 50px !important;
  }

  .searchbar-input {
    font-family: ${fontFamily.sansSerif} !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 14px !important;
    padding-top: 13px !important;
    padding-bottom: 13px !important;
    padding-left: 16px !important;
    height: 50px !important;
  }

  .searchbar-search-icon {
    display: none !important;
  }
`;

const StyledButton = styled(Button)`
  background: transparent;
  border: none;
  height: 100%;
  font-family: ${fontFamily.sansSerif};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
`;

export {
  Container,
  StyledSearchbar,
  StyledButton,
};
