import React from 'react';
import { PropTypes } from 'prop-types';
import { IonLabel } from '@ionic/react';

import {
  StyledUnitSegment,
  StyledUnitSegmentButton,
} from './styles';

const SegmentsSelector = ({
  segments,
  currentValue,
  onChange,
  className,
  ...props
}) => {
  const renderSegments = () => (
    segments.map(({ value, label }) => (
      <StyledUnitSegmentButton value={value} key={value}>
        <IonLabel>{label}</IonLabel>
      </StyledUnitSegmentButton>
    ))
  );

  const onIonChange = (event) => onChange(event.detail.value);

  return (
    <StyledUnitSegment
      className={className}
      mode="ios"
      value={currentValue}
      onIonChange={onIonChange}
      {...props}
    >
      {renderSegments()}
    </StyledUnitSegment>
  );
};

SegmentsSelector.propTypes = {
  segments: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  currentValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

SegmentsSelector.defaultProps = {
  className: '',
};

export default SegmentsSelector;
