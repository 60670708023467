import React from 'react';
import PropTypes from 'prop-types';

import { WorkoutDetailsContextProvider } from '../../components/WorkoutDetails';
import CoachFeedback from '../../components/CoachFeedback/CoachFeedback';
import WorkoutBaseData from '../../components/WorkoutBaseData';
import WorkoutInfoSwitcher from '../../components/WorkoutInfoSwitcher';
import WorkoutVideoContainer from './WorkoutVideo';
import TrackedStats from './TrackedStats';
import {
  StyledWorkoutDetailsPage,
  WorkoutDetailsContainer,
  ContentSection,
  FooterSection,
  StyledButton,
} from './styles';

const WorkoutDetailsPage = ({
  workoutName,
  note,
  estimatedDuration,
  activities,
  latestGameplaySession,
  trackedActivities,
  showCoachFeedback,
  showActionButton,
  actionButtonText,
  onActionButtonClicked,
}) => (
  <WorkoutDetailsContextProvider>
    <StyledWorkoutDetailsPage>
      <ContentSection>
        <WorkoutBaseData
          name={workoutName}
          note={note}
          estimatedDuration={estimatedDuration}
          activities={activities}
        />
        {!!latestGameplaySession && (
          <WorkoutVideoContainer
            latestGameplaySession={latestGameplaySession}
          />
        )}
        {showCoachFeedback && (
          <CoachFeedback
            latestGameplaySession={latestGameplaySession}
          />
        )}
        {trackedActivities && trackedActivities.docs.length > 0 && (
          <TrackedStats
            trackedActivities={trackedActivities}
          />
        )}
        <WorkoutDetailsContainer>
          <WorkoutInfoSwitcher />
        </WorkoutDetailsContainer>
      </ContentSection>
      {showActionButton && (
        <FooterSection>
          <StyledButton onClick={onActionButtonClicked}>{actionButtonText}</StyledButton>
        </FooterSection>
      )}
    </StyledWorkoutDetailsPage>
  </WorkoutDetailsContextProvider>
);

WorkoutDetailsPage.propTypes = {
  workoutName: PropTypes.string.isRequired,
  note: PropTypes.string,
  estimatedDuration: PropTypes.number,
  activities: PropTypes.array,
  latestGameplaySession: PropTypes.object,
  trackedActivities: PropTypes.object,
  showCoachFeedback: PropTypes.bool.isRequired,
  showActionButton: PropTypes.bool.isRequired,
  actionButtonText: PropTypes.string.isRequired,
  onActionButtonClicked: PropTypes.func.isRequired,
};

WorkoutDetailsPage.defaultProps = {
  note: '',
  estimatedDuration: 0,
  latestGameplaySession: null,
  trackedActivities: null,
  activities: [],
};

export default WorkoutDetailsPage;
