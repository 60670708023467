import React, { useContext } from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import InAppBrowserContext from '../../../../context/InAppBrowserContext';
import { ReactComponent as CronometerIcon } from '../../../../assets/icons/cronometer-icon.svg';
import { getCronometerAuthUrl } from '../../utils';

import texts from './texts.json';

import {
  CardContainer,
  Description,
  Title,
  TitleHighlight,
  TextWrapper,
  Wrapper,
} from './styles';

const CronometerIntegration = () => {
  const { open } = useContext(InAppBrowserContext);

  const handleOpen = () => {
    const url = getCronometerAuthUrl();
    // Open in native browser
    open(url, { target: '_system' });
  };

  return (
    <Wrapper>
      <CardContainer onClick={handleOpen}>
        <CronometerIcon />
        <TextWrapper>
          <Title>
            {texts.title}
            <TitleHighlight>{texts.cronometer}</TitleHighlight>
          </Title>
          <Description>{texts.description}</Description>
        </TextWrapper>
      </CardContainer>
    </Wrapper>
  );
};

export default compose(observer)(CronometerIntegration);
