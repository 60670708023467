import React from 'react';
import PropTypes from 'prop-types';
import {
  ActionItemContainer,
  NotificationNumber,
} from './styles';

const ActionItem = ({
  iconSrc,
  altText,
  onClick,
  notificationsNumber,
  maxDigits,
}) => {
  /*
    Replaces the number of notifications with a +9 (maxDigits times of 9s)
    if the number's digits are higher than maxDigits
  */
  const isOverMaxDigits = String(notificationsNumber).length > maxDigits;
  const notificationsNumberText = isOverMaxDigits ? `+${String('9').repeat(maxDigits)}` : notificationsNumber;
  return (
    <ActionItemContainer onClick={onClick}>
      <img src={iconSrc} alt={altText} />
      {notificationsNumber > 0 && (
        <NotificationNumber>
          {notificationsNumberText}
        </NotificationNumber>
      )}
    </ActionItemContainer>
  );
};

ActionItem.propTypes = {
  iconSrc: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  notificationsNumber: PropTypes.number,
  maxDigits: PropTypes.number,
};

ActionItem.defaultProps = {
  notificationsNumber: 0,
  maxDigits: 1,
};

export default ActionItem;
