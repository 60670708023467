import React, {
  useEffect,
  useState,
  useMemo,
} from 'react';
import {
  MessageInputContextProvider,
  useChannelStateContext,
  useChatContext,
  useMessageInputContext,
} from 'stream-chat-react';
import format from 'string-template';
import shuffle from 'lodash.shuffle';
import PropTypes from 'prop-types';

import MessageSuggestion from '../../../models/MessageSuggestion';
import { getDayofWeek } from '../../../utils/date';
import CustomSuggestionItem from '../CustomSuggestionItem';

const CustomTriggerProvider = ({ children }) => {
  const currentContextValue = useMessageInputContext();
  const { client } = useChatContext();
  const { channel } = useChannelStateContext();
  const [isReady, setIsReady] = useState(false);
  const [suggestionList, setSuggestionList] = useState([]);

  const weekDay = getDayofWeek();
  const coach = client.user.name;
  const clientOfCoach = channel?.data?.name || channel?.data?.id;

  const customTrigger = useMemo(() => ({
    component: CustomSuggestionItem,
    dataProvider: (query, _, onReady) => {
      const filteredSuggestions = shuffle(suggestionList).slice(0, 10)
        .filter((suggestion) => suggestion.message.toLowerCase().includes(query.toLowerCase()))
        .map((suggestion) => ({ name: suggestion.message, id: suggestion.id }));
      onReady(filteredSuggestions, query);
    },
    output: (entity) => ({
      caretPosition: 'next',
      key: entity.name,
      text: entity.name,
    }),
  }), [
    suggestionList,
  ]);

  const customTriggers = useMemo(() => ({
    '#': customTrigger,
    ...currentContextValue.autocompleteTriggers,
  }), [
    customTrigger,
    currentContextValue.autocompleteTriggers,
  ]);

  useEffect(() => {
    let shouldUpdate = true;

    const init = async () => {
      const messageSuggestionCollection = await MessageSuggestion.get();
      const suggestions = messageSuggestionCollection.docs.map((messageSuggestion) => ({
        id: messageSuggestion.id,
        message: format(messageSuggestion.message, {
          CLIENT: clientOfCoach?.split(' ')[0],
          COACH: coach,
          WEEKDAY: weekDay,
        }),
      }));

      if (shouldUpdate) {
        setSuggestionList(suggestions);
        setIsReady(true);
      }
    };
    init();
    return () => {
      /* This cleanup function is used to prevent updating the state
      when the component is unmounted */
      shouldUpdate = false;
    };
  }, [
    clientOfCoach,
    coach,
    weekDay,
  ]);

  const updatedContextValue = useMemo(() => ({
    ...currentContextValue,
    suggestionList,
    autocompleteTriggers: customTriggers,
  }), [
    currentContextValue,
    customTriggers,
    suggestionList,
  ]);

  return (
    <MessageInputContextProvider value={isReady ? updatedContextValue : currentContextValue}>
      {children}
    </MessageInputContextProvider>
  );
};

CustomTriggerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CustomTriggerProvider;
