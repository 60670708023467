import React, { useState } from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';

import useAuthentication, {
  AuthErrorCode,
} from '../../../../hooks/useAuthentication';
import { SuccessMessage, ErrorMessage, ActionButton } from '../../styles';
import texts from './texts.json';
import ForgotPasswordContainer from './styles';

const ForgotPassword = ({
  sessionStore,
  email,
}) => {
  const {
    sendPasswordResetEmail,
  } = useAuthentication(sessionStore);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const onForgotPasswordClick = async () => {
    try {
      await sendPasswordResetEmail(email);
      setSuccessMessage(format(texts.resetPasswordMessage, email));
      setErrorMessage(null);
    } catch (error) {
      if (error.code === AuthErrorCode.USER_NOT_FOUND) {
        setSuccessMessage(format(texts.resetPasswordMessage, email));
        setErrorMessage(null);
      } else {
        setErrorMessage(error.message);
      }
    }
  };

  return (
    <ForgotPasswordContainer>
      <ActionButton onClick={onForgotPasswordClick}>
        {texts.forgotPassword}
      </ActionButton>
      {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
    </ForgotPasswordContainer>
  );
};

ForgotPassword.propTypes = {
  email: PropTypes.string.isRequired,
  sessionStore: PropTypes.object.isRequired,
};

export default ForgotPassword;
