import React, {
  useContext,
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import ExerciseOverrideSelectorContext from '../context/ExerciseOverrideSelectorContext';
import OverrideSelection from './OverrideSelection';

const OverrideSelectionContainer = ({
  selectedExercise,
  showOriginalExercise,
}) => {
  const {
    originalExerciseRef,
    applyExerciseOverride,
    logExerciseOverrideEvent,
    onOverrideDone,
  } = useContext(ExerciseOverrideSelectorContext);

  const [isReplaceAllActionEnabled, setIsReplaceAllActionEnabled] = useState(false);

  const onToggleReplaceAllAction = useCallback(() => setIsReplaceAllActionEnabled((prevValue) => !prevValue), []);

  const onReplaceExercise = useCallback(() => {
    logExerciseOverrideEvent('exerciseOverrideReplaceAlertAccepted');
    applyExerciseOverride(selectedExercise);
    onOverrideDone();
  }, [
    selectedExercise,
    applyExerciseOverride,
    onOverrideDone,
    logExerciseOverrideEvent,
  ]);

  return (
    <OverrideSelection
      selectedExercise={selectedExercise}
      originalExercise={originalExerciseRef.current}
      isReplaceActionEnabled={isReplaceAllActionEnabled}
      onToggleReplaceAllAction={onToggleReplaceAllAction}
      showOriginalExercise={showOriginalExercise}
      onReplaceExercise={onReplaceExercise}
    />
  );
};

OverrideSelectionContainer.propTypes = {
  selectedExercise: PropTypes.object,
  showOriginalExercise: PropTypes.bool,
};

OverrideSelectionContainer.defaultProps = {
  selectedExercise: null,
  showOriginalExercise: false,
};

export default OverrideSelectionContainer;
