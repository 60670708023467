import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledCard,
  Container,
  TextContainer,
  Title,
  Body,
} from './styles';

const ActionCard = ({
  title,
  description,
  onClick,
  icon: Icon,
  className,
}) => (
  <StyledCard
    onClick={onClick}
    className={className}
  >
    <Container>
      <TextContainer>
        <Title>
          {title}
        </Title>
        <Body>
          {description}
        </Body>
      </TextContainer>
      {!!Icon && <Icon onClick={onClick} />}
    </Container>
  </StyledCard>
);

ActionCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.object,
  className: PropTypes.string,
};

ActionCard.defaultProps = {
  icon: null,
  className: '',
};

export default ActionCard;
