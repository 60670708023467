import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TagsContainer = styled.div`
  display: grid;
  justify-items: stretch;
  grid-template-columns: 1fr 1fr;
  column-gap: 5px;
  row-gap: 5px;
`;

export {
  Container,
  TagsContainer,
};
