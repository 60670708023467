import {
  decorate,
  observable,
  action,
  computed,
} from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import { convertIndexToLetter } from '../../utils/text';
import { ActivityStatuses, ActivityTypes } from '../../models/BaseActivity';

/**
 * Knows how to iterate over a list of activities for execution.
 */
class ActivitiesExecutor {
  constructor(activities) {
    /**
     * The list of activities to execute
     */
    this.activities = activities;

    /**
     * Set current activity to the very first element of the activities list.
     */
    this.currentActivityIndex = 0;

    /**
     * The current exercise index is also set to 0 so that we initially point to the beginning of the tape.
     */
    this.currentExerciseIndex = 0;

    /**
     * The execution tape is a linear data structure for simplifying the execution of the activities array.
     */
    this.executionTape = ActivitiesExecutor.getExecutionTape(this.activities);

    /**
     * The exercises execution tape is also a linear data structure, but it only contains actual exercises (no rest).
     */
    this.exercisesExecutionTape = ActivitiesExecutor.getExercisesExecutionTape(this.executionTape);

    /**
     * Initialize internal indexes based on current activities provided statuses.
     */
    this.initializeTapeIndexes();
  }

  static hasCircuitActivities(activities) {
    return activities.some((activity) => activity.type === ActivityTypes.CIRCUIT);
  }

  static calculateActivityDisplayId(displayIdIndex, circuitsInfo = []) {
    /*
      Root activity, always use letters for ids
    */
    if (circuitsInfo.length === 0) {
      return convertIndexToLetter(displayIdIndex - 1);
    }
    const parentCircuit = circuitsInfo[0];
    const { roundActivities } = parentCircuit;
    /*
      Check if this is a single circuit with only one activity
      and a rest activity. In this case, also use letters for ids.
    */
    if (circuitsInfo.length === 1) {
      const nonRestActivities = roundActivities.filter((roundActivity) => roundActivity.type !== ActivityTypes.REST);
      if (nonRestActivities.length === 1) {
        return parentCircuit.circuitDisplayId;
      }
    }
    let activityDisplayId = displayIdIndex;
    if (ActivitiesExecutor.hasCircuitActivities(roundActivities)) {
      activityDisplayId = convertIndexToLetter(displayIdIndex - 1);
    }
    return `${parentCircuit.circuitDisplayId}${activityDisplayId}`;
  }

  /**
   * Transforms an array of executable activities into an execution tape where
   * we create a flatten data structure to make it easier to progress in a workout.
   * @param {Array} activities The array of activities in execution format
   * @param {Array} circuitsInfo An array of circuits information
   */
  static getExecutionTape(activities, circuitsInfo = []) {
    let displayIdIndex = 0;
    return activities.reduce((activitiesTape, activity, activityIndex) => {
      /*
        Progress the label index only if the activity is not REST or it's REST but
        the previous activity is a CIRCUIT.
      */
      if (activity.type === ActivityTypes.REST) {
        const prevActivity = activities[activityIndex - 1];
        if (prevActivity && prevActivity.type === ActivityTypes.CIRCUIT) {
          displayIdIndex += 1;
        }
      } else {
        displayIdIndex += 1;
      }
      if (activity.type === ActivityTypes.CIRCUIT) {
        const { name: circuitName, rounds } = activity;
        const circuitDisplayId = ActivitiesExecutor.calculateActivityDisplayId(displayIdIndex, circuitsInfo);
        const circuitId = uuidv4();
        const circuitInfo = {
          circuitName,
          circuitId,
          circuitDisplayId,
          circuitTotalRounds: rounds.length,
        };
        const circuitActivitiesTape = rounds.reduce((roundsTape, round, roundIndex) => {
          const { activities: roundActivities } = round;
          const roundCircuitInfo = {
            ...circuitInfo,
            round: roundIndex + 1,
            roundId: uuidv4(),
            roundActivities,
          };
          const activityCircuits = [
            roundCircuitInfo,
            ...circuitsInfo,
          ];
          return [
            ...roundsTape,
            ...ActivitiesExecutor.getExecutionTape(roundActivities, activityCircuits),
          ];
        }, []);
        return [
          ...activitiesTape,
          ...circuitActivitiesTape,
        ];
      }
      return [
        ...activitiesTape,
        {
          circuitsInfo,
          activity,
          activityDisplayId: ActivitiesExecutor.calculateActivityDisplayId(displayIdIndex, circuitsInfo),
        },
      ];
    }, []);
  }

  /**
   * Get the exercises execution tape. This tape is similar to executionTape, but it strips all rest activities.
   * @param {Array} tape The tape to process
   * @returns {Array} The new tape, which does not include rest activities.
   */
  static getExercisesExecutionTape(tape) {
    return tape.filter((tapeItem) => tapeItem.activity.type !== 'REST');
  }

  static getExecutableActivity(activityTapeRef) {
    if (!activityTapeRef) {
      return null;
    }
    return activityTapeRef.activity;
  }

  static getActivityId(activityTapeRef) {
    if (!activityTapeRef) {
      return null;
    }
    return activityTapeRef.activityDisplayId;
  }

  static getActivityCircuits(activityTapeRef) {
    if (!activityTapeRef) {
      return null;
    }
    const { circuitsInfo } = activityTapeRef;
    if (circuitsInfo.length > 0) {
      const circuitsWithNames = circuitsInfo.filter((circuitInfo) => !!circuitInfo.circuitName);
      if (circuitsWithNames.length === 0) {
        return null;
      }
      return circuitsWithNames
        .map((circuitInfo) => circuitInfo.circuitName)
        .reverse()
        .join(' > ');
    }
    return null;
  }

  static getActivityCircuitRound(activityTapeRef) {
    if (!activityTapeRef) {
      return null;
    }
    const { circuitsInfo } = activityTapeRef;
    if (!circuitsInfo || circuitsInfo.length === 0) {
      return 1;
    }
    return circuitsInfo[0].round;
  }

  static getCircuitTotalRounds(activityTapeRef) {
    if (!activityTapeRef) {
      return 0;
    }
    const { circuitsInfo } = activityTapeRef;
    if (!circuitsInfo || circuitsInfo.length === 0) {
      return 1;
    }
    return circuitsInfo[0].circuitTotalRounds;
  }

  static findFirstAssignedActivityIndex(tape) {
    return tape.findIndex((tapeRef) => {
      const { activity } = tapeRef;
      return activity.status === ActivityStatuses.ASSIGNED;
    });
  }

  /**
   * Initializes the currentActivityIndex by looking for the very first activity whose status
   * is ASSIGNED. It also inits the currentExerciseIndex based on the currentActivityIndex.
   */
  initializeTapeIndexes() {
    const firstAssignedActivityIndex = ActivitiesExecutor.findFirstAssignedActivityIndex(this.executionTape);
    if (firstAssignedActivityIndex === -1) {
      throw Error('Trying to resume a gameplay session that is already finished');
    }

    const firstAssignedExerciseIndex = ActivitiesExecutor.findFirstAssignedActivityIndex(this.exercisesExecutionTape);

    this.currentExerciseIndex = firstAssignedExerciseIndex;

    /*
      When the user resumes a workout, they could start from a rest activity (if they quitted the workout during a
      rest). If the current activity index corresponds to a Rest activity, then the exercise index should be the
      previous one.
    */
    if (this.executionTape[firstAssignedActivityIndex].activity.type === ActivityTypes.REST) {
      this.currentExerciseIndex = firstAssignedExerciseIndex - 1;
    }

    this.currentActivityIndex = firstAssignedActivityIndex;
  }

  get currentActivityTapeRef() {
    return this.executionTape[this.currentActivityIndex];
  }

  get nextActivityTapeRef() {
    return this.executionTape[this.currentActivityIndex + 1] || null;
  }

  get currentExerciseTapeRef() {
    return this.exercisesExecutionTape[this.currentExerciseIndex];
  }

  get nextExerciseTapeRef() {
    return this.exercisesExecutionTape[this.currentExerciseIndex + 1] || null;
  }

  get currentExecutableActivity() {
    return ActivitiesExecutor.getExecutableActivity(this.currentActivityTapeRef);
  }

  get nextExecutableActivity() {
    return ActivitiesExecutor.getExecutableActivity(this.nextActivityTapeRef);
  }

  get currentActivityId() {
    return ActivitiesExecutor.getActivityId(this.currentActivityTapeRef);
  }

  get nextActivityId() {
    return ActivitiesExecutor.getActivityId(this.nextActivityTapeRef);
  }

  get currentExerciseId() {
    return ActivitiesExecutor.getActivityId(this.currentExerciseTapeRef);
  }

  get nextExerciseId() {
    return ActivitiesExecutor.getActivityId(this.nextExerciseTapeRef);
  }

  get currentActivityCircuits() {
    return ActivitiesExecutor.getActivityCircuits(this.currentActivityTapeRef);
  }

  get nextActivityCircuits() {
    return ActivitiesExecutor.getActivityCircuits(this.nextActivityTapeRef);
  }

  get currentExerciseCircuits() {
    return ActivitiesExecutor.getActivityCircuits(this.currentExerciseTapeRef);
  }

  get nextExerciseCircuits() {
    return ActivitiesExecutor.getActivityCircuits(this.nextExerciseTapeRef);
  }

  get currentCircuitTotalRounds() {
    return ActivitiesExecutor.getCircuitTotalRounds(this.currentActivityTapeRef);
  }

  get nextActivityCircuitTotalRounds() {
    return ActivitiesExecutor.getCircuitTotalRounds(this.nextActivityTapeRef);
  }

  get currentExerciseCircuitTotalRounds() {
    return ActivitiesExecutor.getCircuitTotalRounds(this.currentExerciseTapeRef);
  }

  get nextExerciseCircuitTotalRounds() {
    return ActivitiesExecutor.getCircuitTotalRounds(this.nextExerciseTapeRef);
  }

  get currentCircuitRound() {
    return ActivitiesExecutor.getActivityCircuitRound(this.currentActivityTapeRef);
  }

  get nextActivityCircuitRound() {
    return ActivitiesExecutor.getActivityCircuitRound(this.nextActivityTapeRef);
  }

  get currentExerciseCircuitRound() {
    return ActivitiesExecutor.getActivityCircuitRound(this.currentExerciseTapeRef);
  }

  get nextExerciseCircuitRound() {
    return ActivitiesExecutor.getActivityCircuitRound(this.nextExerciseTapeRef);
  }

  get totalExercises() {
    return this.exercisesExecutionTape.length;
  }

  get currentExercise() {
    return ActivitiesExecutor.getExecutableActivity(this.currentExerciseTapeRef);
  }

  get nextExercise() {
    return ActivitiesExecutor.getExecutableActivity(this.nextExerciseTapeRef);
  }

  toNextActivity() {
    if (this.currentActivityIndex + 1 < this.executionTape.length) {
      this.currentActivityIndex += 1;
      const { activity } = this.executionTape[this.currentActivityIndex];
      if (activity.type !== 'REST') {
        this.currentExerciseIndex += 1;
      }
      return activity;
    }
    return null;
  }
}

decorate(ActivitiesExecutor, {
  currentActivityIndex: observable,
  currentActivityTapeRef: computed,
  currentExerciseIndex: observable,
  totalExercises: computed,
  nextActivityTapeRef: computed,
  currentExecutableActivity: computed,
  nextExecutableActivity: computed,
  currentActivityId: computed,
  nextActivityId: computed,
  currentActivityCircuits: computed,
  nextActivityCircuits: computed,
  currentExerciseCircuits: computed,
  nextExerciseCircuits: computed,
  currentCircuitRound: computed,
  nextActivityCircuitRound: computed,
  currentExerciseCircuitRound: computed,
  nextExerciseCircuitRound: computed,
  currentCircuitTotalRounds: computed,
  nextActivityCircuitTotalRounds: computed,
  currentExerciseCircuitTotalRounds: computed,
  nextExerciseCircuitTotalRounds: computed,
  currentExercise: computed,
  nextExercise: computed,
  currentExerciseId: computed,
  nextExerciseId: computed,
  toNextActivity: action,
});

export default ActivitiesExecutor;
