import styled from 'styled-components';

import { ReactComponent as Icon } from '../../../assets/icon/magnifying-glass.svg';

const SearchInputContainer = styled.div`
  padding: 10px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.base.primary};
  position: relative;

  .str-chat__channel-search-input {
    border: none;
    border-radius: 999px;
    font-size: 16px;
    width: 100%;
    outline: none;
    padding: 5px 20px 5px 30px;
  }
`;

const SearchIcon = styled(Icon)`
  position: absolute;
  height: 40%;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);

  path {
    stroke: ${({ theme }) => theme.colors.base.primary};
  }
`;

export {
  SearchInputContainer,
  SearchIcon,
};
