import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import DraggablePanel from './DraggablePanel';
import {
  DebugPanelTitle,
  DebugPanelContent,
} from './styles';

const DebugPanel = ({
  hist,
  extraData,
}) => {
  const [showPanelContent, setShowPanelContent] = useState(true);

  const togglePanelContent = useCallback(() => {
    setShowPanelContent(!showPanelContent);
  }, [
    showPanelContent,
    setShowPanelContent,
  ]);

  if (!hist) {
    return null;
  }

  /*
    NOTE: for now, if there's a stepResult at root level use it, otherwise check
    if there's one in the gameplayActionProcessResult.
  */
  const stepResult = hist.stepResult
    || (hist.gameplayActionProcessResult && hist.gameplayActionProcessResult.stepResult)
    || {};

  const {
    poseDetectionResult,
    inPoseProcessed,
    qualityProcessed,
  } = stepResult || {};

  // Calculate debug specific data
  const debugData = {
    FPS: hist.fps,
    Time: (typeof hist.workoutTime === 'number')
      ? Number(hist.workoutTime / 1000).toFixed(2) : 'no time',
    Repetitions: (typeof hist.workoutRepetitions === 'number')
      ? hist.workoutRepetitions : 'no reps',
    'In-Pose (direct)': (poseDetectionResult && poseDetectionResult.inPose),
    'In-Pose (processed)': inPoseProcessed ? 'YES' : 'NO',
    'Quality (direct)': (poseDetectionResult && poseDetectionResult.quality),
    'Quality (processed)': qualityProcessed,
    Orientation: hist.orientation,
  };

  const { predictionModelResult } = hist;
  if (predictionModelResult && predictionModelResult.debugData) {
    Object.keys(predictionModelResult.debugData).forEach((key) => {
      const val = predictionModelResult.debugData[key];
      debugData[key] = Array.isArray(val) ? val.map((x) => Number(x).toFixed(2)) : val;
    });
  }

  debugData.Reasons = [];

  if (poseDetectionResult) {
    if (poseDetectionResult.reasons) {
      debugData.Reasons.push(
        ...poseDetectionResult.reasons.map((k) => `${k.quality}: ${k.key}`),
      );
    }

    if (poseDetectionResult.debugData) {
      Object.keys(poseDetectionResult.debugData).forEach((key) => {
        const val = poseDetectionResult.debugData[key];
        debugData[key] = Array.isArray(val) ? val.map((x) => Number(x).toFixed(2)) : val;
      });
    }
  }

  if (stepResult.debugData) {
    Object.keys(stepResult.debugData).forEach((key) => {
      debugData[key] = stepResult.debugData[key];
    });
  }

  let data = Object.entries(debugData);
  if (extraData) {
    data = data.concat(extraData);
  }

  return (
    <DraggablePanel>
      <DebugPanelTitle
        onClick={togglePanelContent}
      >
        Debug panel
      </DebugPanelTitle>
      {showPanelContent && (
        <DebugPanelContent>
          {
            data.map((e) => (
              <li key={e[0] + String(e[1])}>
                {`${e[0]} : ${Array.isArray(e[1]) ? e[1].join(', ') : String(e[1])}`}
              </li>
            ))
          }
        </DebugPanelContent>
      )}
    </DraggablePanel>
  );
};

DebugPanel.propTypes = {
  hist: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  extraData: PropTypes.array,
};

DebugPanel.defaultProps = {
  extraData: [],
  hist: [],
};

export default DebugPanel;
