import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { Channel } from 'stream-chat-react';
import * as Sentry from '@sentry/browser';

import useComponentMounted from '../../../hooks/useComponentMounted';
import ChatContext from '../../context';
import { channelConfiguration } from '../../util';
import ChatFeedView from '../ChatFeedView';
import ChatNotificationView from '../Notification';
import ChatFeedContainer from '../ChatFeedContainer';
import ChatLoadingView from '../ChatLoadingView';
import texts from './texts.json';

const SingleChannelView = () => {
  const {
    chatClient,
    userId,
    onMarkChannelAsRead,
  } = useContext(ChatContext);
  const [isLoadingContent, setLoadingContent] = useState(true);
  const [channel, setChannel] = useState(null);
  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    const findChannel = async () => {
      const channels = await chatClient.queryChannels(
        channelConfiguration.filters(userId),
        channelConfiguration.sort,
        channelConfiguration.options,
      );
      if (channels && channels.length > 0 && isComponentMountedRef.current) {
        const selectedChannel = channels[0];
        setChannel(selectedChannel);
      }
      if (isComponentMountedRef.current) {
        setLoadingContent(false);
      }
    };
    if (isLoadingContent) {
      findChannel();
    }
  }, [
    isLoadingContent,
    userId,
    chatClient,
    isComponentMountedRef,
  ]);

  if (isLoadingContent) {
    return <ChatLoadingView />;
  }

  if (!channel) {
    Sentry.captureException(
      `The user ${userId} doesn't have a channel created for in-app messaging`,
      {
        extra: {
          userId,
        },
      },
    );
    return <ChatNotificationView notificationMessage={texts.channelWithNoMessage} />;
  }

  return (
    <ChatFeedContainer>
      <Channel
        maxNumberOfFiles={channelConfiguration.maxFileUploadLimit}
        multipleUploads
        channel={channel}
        doMarkReadRequest={onMarkChannelAsRead}
      >
        <ChatFeedView />
      </Channel>
    </ChatFeedContainer>
  );
};

export default SingleChannelView;
