import styled, { css } from 'styled-components';

import { Heading1 } from '../../../../components/Typography';
import { mixins } from '../../../../styles/breakpoints';
import PreviousButton from '../../../../components/BackButton';
import { ReactComponent as BackgroundImageSvg } from '../../../../assets/signup_login_bg.svg';

const LoginContainer = styled.div`
  max-width: unset;
  padding: 0;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.system.alpha};
  overflow: auto;

  ${mixins.tabletMedia(css`
    background-color: ${({ theme }) => theme.colors.system.background};
  `)}
`;

const LoginFormsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.system.alpha};

  ${mixins.tabletMedia(css`
    background-color: ${({ theme }) => theme.colors.system.background};
  `)}
`;

const BackButton = styled(PreviousButton)`
  position: fixed;
  left: 0;
  top: 0;
  margin: 25px 0 0 30px;
  width: 10px;
  height: 8px;
  z-index: 1;
`;

const FormWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 0;
  padding: 25px 25px 85px 25px;
  max-width: 400px;
  background: transparent;
  box-shadow: none;

  ${mixins.tabletMedia(css`
    margin-top: 88px;
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.1);
    background: ${({ theme }) => theme.colors.system.alpha};
  `)}
`;

const FormHeading = styled(Heading1)`
  text-align: center;
  padding: 40px 0;
  font-size: 40px;

  ${mixins.tabletMedia(css`
    padding: 0 0 22px 0;
    font-size: 28px;
  `)}
`;

const BackgroundImage = styled(BackgroundImageSvg)`
  position: fixed;
  display: none;

  ${mixins.tabletMedia(css`
    display: block;
  `)}
`;

export {
  LoginContainer,
  LoginFormsContainer,
  BackButton,
  BackgroundImage,
  FormWrapper,
  FormHeading,
};
