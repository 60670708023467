import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import AssignmentDayType from '../../viewModel/assignmentType';
import WorkoutFeedView, { WorkoutFeedViewType } from '../../../../models/WorkoutFeedView';
import { withFirebase } from '../../../../context/FirebaseContext';
import useWorkoutNavigation from '../../../../hooks/useWorkoutNavigation';
import useCheckInNavigation from '../../../../hooks/useCheckInNavigation';
import CheckInContext from '../../../../context/CheckInContext';
import {
  ProgressStatus,
  isActiveProgressStatus,
} from '../../progressStatus';

import WorkoutCard from './WorkoutCard';

const WorkoutCardContainer = ({
  workoutFeedView,
  progressStatus,
  assignmentDayType,
  firebase,
}) => {
  const [previewImage, setPreviewImage] = useState(null);

  const { navigateToWorkout } = useWorkoutNavigation();
  const { navigateCheckInPageByPageIndex } = useCheckInNavigation();
  const { setCurrentSelectedDate } = useContext(CheckInContext);

  useEffect(() => {
    let shouldUpdate = true;

    const init = async () => {
      const downloadUrl = await firebase.storage
        .ref()
        .child(workoutFeedView.data.previewImageLink)
        .getDownloadURL();
      const imageData = await fetch(downloadUrl);
      const imageBlob = await imageData.blob();
      const url = URL.createObjectURL(imageBlob);
      if (shouldUpdate) {
        setPreviewImage(url);
      }
    };

    if (assignmentDayType !== AssignmentDayType.FUTURE && workoutFeedView.data.previewImageLink) {
      init();
    }

    return () => {
      shouldUpdate = false;
    };
  }, [
    workoutFeedView.data.previewImageLink,
    firebase.storage,
    assignmentDayType,
  ]);

  const onGoToWorkoutDetails = useCallback(() => {
    if (workoutFeedView.type === WorkoutFeedViewType.CHECK_IN) {
      setCurrentSelectedDate(moment(workoutFeedView.weekDate.toDate()));
      navigateCheckInPageByPageIndex(0);
      return;
    }
    navigateToWorkout(workoutFeedView.id, workoutFeedView.type);
  }, [
    navigateToWorkout,
    workoutFeedView.id,
    workoutFeedView.type,
    workoutFeedView.weekDate,
    navigateCheckInPageByPageIndex,
    setCurrentSelectedDate,
  ]);

  const isActive = isActiveProgressStatus(progressStatus);

  return (
    <WorkoutCard
      title={workoutFeedView.title}
      hasPreview={!!workoutFeedView.data.previewImageLink}
      previewImage={previewImage}
      onClick={onGoToWorkoutDetails}
      progressStatus={progressStatus}
      isActive={isActive}
      assignmentDayType={assignmentDayType}
      duration={workoutFeedView.duration}
      activeCalories={workoutFeedView.activeCalories}
      week={workoutFeedView.week}
    />
  );
};

WorkoutCardContainer.propTypes = {
  workoutFeedView: PropTypes.instanceOf(WorkoutFeedView).isRequired,
  progressStatus: PropTypes.oneOf(Object.values(ProgressStatus)).isRequired,
  firebase: PropTypes.object.isRequired,
  assignmentDayType: PropTypes.oneOf(Object.values(AssignmentDayType)).isRequired,
};

export default withFirebase(WorkoutCardContainer);
