import styled from 'styled-components';

import Button from '../../components/Button';

const PageContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const StyledWorkoutDetailsPage = styled.div`
  padding-top: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
`;

const WorkoutDetailsContainer = styled.div`
  flex: 1;
  margin-top: 30px;
`;

const ContentSection = styled.div`
  flex: 1;
  overflow: auto;
`;

const FooterSection = styled.div`
  margin-top: 10px;
`;

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colors.fonts.primary};
`;

export {
  PageContentWrapper,
  StyledWorkoutDetailsPage,
  WorkoutDetailsContainer,
  ContentSection,
  FooterSection,
  StyledButton,
};
