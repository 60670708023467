import React, {
  useEffect,
  useState,
  useMemo,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import format from 'string-template';
import { firestorePaths, pathPlaceholder } from '../../utils/firebasePaths';

import User from '../../models/User';
import UserConfig from '../../models/UserConfig';

import useSessionStore from '../../hooks/useSessionStore';
import useComponentMounted from '../../hooks/useComponentMounted';
import useComponentLoadingTime from '../../hooks/useComponentLoadingTime';

import UserContext from '../UserContext';

import LoggedInUserContext from './LoggedInUserContext';

const LoggedInUserContextProvider = ({
  children,
}) => {
  const {
    userId: userInRouteId,
    userDoc: userInRouteDoc,
    userConfigDoc: userInRouteConfigDoc,
  } = useContext(UserContext);

  const isComponentMountedRef = useComponentMounted();

  const { authUser: { uid: authUserId } = {} } = useSessionStore();

  const [isReady, setIsReady] = useState(authUserId === userInRouteId);
  const [userDoc, setUserDoc] = useState(() => (isReady ? userInRouteDoc : null));
  const [userConfigDoc, setUserConfigDoc] = useState(() => (isReady ? userInRouteConfigDoc : null));
  const { startLoading, finishLoading } = useComponentLoadingTime('loggedInUserContext');

  useEffect(() => {
    const init = async () => {
      startLoading();
      const loggedInUserDoc = new User(format(firestorePaths.USER_DOC, {
        [pathPlaceholder.USER_ID]: authUserId,
      }));

      const loggedInUserConfigDoc = new UserConfig(format(firestorePaths.USER_CONFIG_DOC, {
        [pathPlaceholder.USER_ID]: authUserId,
      }));

      await Promise.all([
        loggedInUserDoc.init(),
        loggedInUserConfigDoc.init(),
      ]);

      if (isComponentMountedRef.current) {
        setUserDoc(loggedInUserDoc);
        setUserConfigDoc(loggedInUserConfigDoc);
        setIsReady(true);
        finishLoading();
      }
    };

    if (!isReady) {
      init();
    }
  }, [
    isReady,
    authUserId,
    isComponentMountedRef,
    startLoading,
    finishLoading,
  ]);

  const context = useMemo(() => ({
    userId: authUserId,
    userDoc,
    userConfigDoc,
    isReady,
    setIsReady,
  }), [
    authUserId,
    userDoc,
    userConfigDoc,
    isReady,
    setIsReady,
  ]);

  return (
    <LoggedInUserContext.Provider value={context}>
      {children}
    </LoggedInUserContext.Provider>
  );
};

LoggedInUserContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(LoggedInUserContextProvider);
