import styled from 'styled-components';
import { IonLabel } from '@ionic/react';

import Button from '../../../components/Button';
import { ReactComponent as CloseButtonIcon } from '../../assets/icon/close-thread-icon.svg';

const CloseButtonComponent = styled(CloseButtonIcon)`
  height: 25px;
  margin: 15px 20px 0 0;
  align-self: flex-end;
  justify-self: center;
  z-index: 2;
`;

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const StyledLabel = styled(IonLabel)`
  margin-bottom: 10px;
`;

const StyledButton = styled(Button)`
  width: 80%;
`;

export {
  StyledLabel,
  CloseButtonComponent,
  StyledContentContainer,
  StyledButton,
};
