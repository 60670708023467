import styled from 'styled-components';
import colors from '../../../styles/baseColors';

const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: ${colors.system.alpha};
  z-index: 1;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  padding-left: 18px;
`;

const ModalHead = styled.h2`
  position: absolute;
  left: 50%;
  top: 0;
  margin: 10px;
  transform: translateX(-50%);
`;

const Form = styled.form`
  width: 100%;
  max-width: 580px;
  margin: 30px auto 0;
  overflow: auto;
  display: flex;
  flex-direction: column;

  button {
    width: calc(100% - 130px);
    margin-left: auto;
  }
`;

export {
  Container,
  ActionsContainer,
  ModalHead,
  Form,
};
