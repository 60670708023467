import {
  decorate, action, computed, observable,
} from 'mobx';

import logEvent from '../../../utils/logger';
import RuntimeStats from '../../../utils/stats/RuntimeStats';
import activityStatus from '../activityStatus';

const COUNTDOWN_TIMER_VALUE = 100;

class ActivityTimeProcessor {
  constructor(activityExecutor, activity) {
    /**
     * Total workout time in milliseconds during the execution of the gameplay.
     * @type {number}
     */
    this.workoutValue = 0;
    this.prevWorkoutValue = 0;
    this.requiredGoalMillis = activity.goalValue * 1000;
    this.activityExecutor = activityExecutor;
    this.countdownTimerId = null;

    this.countdownTimerStats = new RuntimeStats();
  }

  processStepResult = () => {
    if (this.activityExecutor.activityState === activityStatus.READY) {
      this.activityExecutor.start();
      // run timer
      this.runCountdown();
    } else if (this.activityExecutor.activityState === activityStatus.RESUMING) {
      this.prevWorkoutValue = this.workoutValue;
      this.activityExecutor.restart();
      this.runCountdown();
    }
  }

  calculateNewWorkoutValue = () => {
    const { gameplayStore: { firstIncompleteActivityWorkoutStartTime } } = this.activityExecutor;
    return (Date.now() - firstIncompleteActivityWorkoutStartTime) + this.prevWorkoutValue;
  }

  /**
   * Runs the gameplay timer. Once it reaches the required duration
   * it will finish the gameplay.
   */
  runCountdown = () => {
    // Only run the gameplay counter when the gameplay is in a RUNNING state
    if (this.activityExecutor.activityState !== activityStatus.RUNNING) {
      this.countdownTimerStats.resetCurrentValue();
      return;
    }

    // Run performance stats. This will measure this timer execution.
    this.countdownTimerStats.calculateTime();

    this.workoutValue = this.calculateNewWorkoutValue();

    if (this.isCompleted) {
      if (this.countdownTimerId) {
        clearTimeout(this.countdownTimerId);

        // Reset countdown timer ID to the default value
        this.countdownTimerId = null;
      }
      this.activityExecutor.finish();

      this.calculateCountdownTimerStats();
    } else {
      this.countdownTimerId = setTimeout(() => this.runCountdown(), COUNTDOWN_TIMER_VALUE);
    }
  }

  resetWorkoutValue = () => {
    this.workoutValue = 0;
  }

  clearTimers() {
    if (this.countdownTimerId) {
      clearTimeout(this.countdownTimerId);
      /*
        Reaching to this point means the countdown timer is still running and is not
        done yet, so calculate the countdown timer stats at this point to send
        analytics data about performance execution.
      */
      this.calculateCountdownTimerStats();
      this.countdownTimerId = null;
    }
  }

  calculateCountdownTimerStats() {
    const runtimeStats = this.countdownTimerStats.stats();

    logEvent('activityCountdownTimerStats', {
      ...runtimeStats,
      isCompleted: this.isCompleted,
      timerValue: COUNTDOWN_TIMER_VALUE,
    });
  }

  get isCompleted() {
    return this.workoutValue >= this.requiredGoalMillis;
  }

  get workoutValueObj() {
    return {
      workoutTime: this.workoutValue,
    };
  }

  get remainingWorkoutValue() {
    const remainingValue = this.requiredGoalMillis - this.workoutValue;
    if (remainingValue > 0) {
      return remainingValue;
    }
    return 0;
  }
}

decorate(ActivityTimeProcessor, {
  workoutValue: observable,
  isCompleted: computed,
  workoutValueObj: computed,
  processStepResult: action,
  runCountdown: action,
  resetWorkoutValue: action,
});

export default ActivityTimeProcessor;
