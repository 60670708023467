import React, { useContext, useCallback } from 'react';

import CameraContext from '../../../../../../context/CameraContext';
import WorkoutExecutionInfoContext from '../../../../context/WorkoutExecutionInfoContext';
import ActivityContext from '../../../../context/ActivityContext';
import GameplayContext from '../../../../context/GameplayContext';

import ProgressInfoPanel from './ProgressInfoPanel';

const ProgressInfoPanelContainer = () => {
  const {
    displayInfo: {
      circuits,
      circuitRound,
      circuitTotalRounds,
      currentExerciseIndex,
      totalExercises,
    },
  } = useContext(WorkoutExecutionInfoContext);

  const {
    isActivityWorkoutPaused,
  } = useContext(ActivityContext);

  const {
    workoutExecutor,
  } = useContext(GameplayContext);

  const {
    isCameraEnabled,
  } = useContext(CameraContext);

  const currentTimeUpdated = useCallback((time) => {
    workoutExecutor.updateWorkoutProgressTime(time);
  }, [
    workoutExecutor,
  ]);

  return (
    <ProgressInfoPanel
      circuitName={circuits}
      circuitRound={circuitRound}
      circuitTotalRounds={circuitTotalRounds}
      currentExerciseIndex={currentExerciseIndex}
      totalExercises={totalExercises}
      isActivityPaused={isActivityWorkoutPaused}
      currentTimeUpdated={currentTimeUpdated}
      enableHighContrast={!isCameraEnabled}
    />
  );
};

export default ProgressInfoPanelContainer;
