import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import format from 'string-template';
import * as Sentry from '@sentry/browser';

import {
  IonAlert,
} from '@ionic/react';

import Coach from '../../../models/Coach';
import ChatContext from '../../context/ChatContext';

import { storagePaths, pathPlaceholder } from '../../../utils/firebasePaths';
import useStorage, { StorageProcessState } from '../../../hooks/useStorage';
import useComponentMounted from '../../../hooks/useComponentMounted';
import UserContext from '../../../context/UserContext';

import AttachmentsContainer from '../../../components/AttachmentsContainer';
import LoadingOverlay from '../../../components/LoadingOverlay';
import AudioAttachmentsContainer from './components/AudioAttachmentsContainer';

import {
  Container,
  SendBroadcastButton,
  StyledLabel,
  CloseButton,
  StyledHeader,
  TitleContainer,
  ModalContent,
  SelectedRecipientContainer,
  ScheduleButtonsContainer,
  StyledChatIcon,
  TabContainer,
  StyledIonLabel,
  StyledTextarea,
  StyledAutoComplete,
} from './styles';

import text from './text.json';

const filterTag = {
  ALL: 'ALL',
};

const MAX_FILE_UPLOAD_SIZE = 20000000; // 20MB

const MessagingBroadcastView = () => {
  // Modal states
  const [isReady, setIsReady] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  // Broadcast message values states
  const [broadcastMessage, setBroadcastMessage] = useState('');
  const [fileAttachments, setFileAttachments] = useState([]); // Array of Files
  const [audioAttachment, setAudioAttachment] = useState(null); // File
  // Recipients states
  const [filters, setFilters] = useState([]);
  const [filterTags, setFilterTags] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [filteredRecipients, setFilteredRecipients] = useState([]);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [alertHeader, setAlertHeader] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const {
    uploadAttachments,
    deleteAttachments,
  } = useStorage();
  const {
    sendBroadcastMessage,
    chatClient,
    setIsBroadcastOpen,
  } = useContext(ChatContext);
  const isComponentMountedRef = useComponentMounted();
  const { userId } = useContext(UserContext);

  const openAlert = useCallback((alertMessage, error = false) => {
    setAlertHeader(error ? text.error : text.success);
    setMessage(alertMessage);
    setIsAlertOpen(true);
  }, []);

  useEffect(() => {
    const init = async () => {
      const usersListOfCoach = await Coach.getBroadcastMessageUsers(userId);
      const sortedUsersListOfCoach = [...usersListOfCoach].sort((a, b) => (a.name > b.name ? 1 : -1));

      const tagList = [...new Set(sortedUsersListOfCoach
        .map((user) => [...user.tags, ...user.customTags]).flat(1))];

      const clientList = sortedUsersListOfCoach.map((user) => ({
        id: user.id,
        userDoc: user,
        tags: [...user.tags, ...user.customTags],
      }));

      if (isComponentMountedRef.current) {
        setFilterTags([filterTag.ALL, ...tagList].map((tag) => ({ value: tag, label: tag })));
        setRecipients(clientList);
        setIsReady(true);
        setIsLoading(false);
      }
    };

    if (!isReady) {
      setIsLoading(true);
      init();
    }
  }, [
    isComponentMountedRef,
    userId,
    isReady,
    chatClient,
  ]);

  const filterList = useCallback((selectedFilterTags) => {
    if (!selectedFilterTags) {
      setFilters([]);
      setFilteredRecipients([]);
      return;
    }

    const selectedFilters = selectedFilterTags.map((filter) => filter.value);
    setFilters(selectedFilterTags);
    if (selectedFilters.includes(filterTag.ALL)) {
      setFilteredRecipients(recipients);
    } else if (selectedFilters.length > 0) {
      setFilteredRecipients([...recipients
        .filter((recipient) => recipient.tags.some((tag) => selectedFilters.includes(tag)))]);
    } else {
      setFilteredRecipients([]);
    }
  }, [
    recipients,
  ]);

  const resetFields = useCallback(() => {
    setBroadcastMessage(null);
    setFilters([]);
    setFileAttachments([]);
    setAudioAttachment(null);
  }, []);

  const uploadMessageAttachments = useCallback(async () => {
    // We will store firebase storage references in the broadcast document
    const attachmentsRefs = [];

    // If this function is called there is at least one file attachment or an audio attachment
    const allAttachments = audioAttachment ? [...fileAttachments, audioAttachment] : fileAttachments;

    const filesToAdd = allAttachments.map((file) => {
      const lastDotIndex = file.name.lastIndexOf('.');
      return {
        // This is then revoked once the upload finishes (correctly or not)
        dataUrl: URL.createObjectURL(file),
        format: file.name.slice(lastDotIndex + 1),
        fileName: file.name.slice(0, lastDotIndex),
        fileType: file.type,
      };
    });
    const storagePath = format(storagePaths.COACH_BROADCAST_MESSAGES_ATTACHMENTS, {
      [pathPlaceholder.COACH_ID]: userId,
    });

    setIsUploading(true);
    try {
      const result = await uploadAttachments(filesToAdd, storagePath);

      let storageTaskFailed = false;
      result.forEach((res) => {
        if (res.state === StorageProcessState.SUCCESS) {
          attachmentsRefs.push(res.fileRef);
        } else {
          storageTaskFailed = true;
        }
      });
      if (storageTaskFailed) {
        // If some upload fails, we should stop the sending process
        // and delete the files that were uploaded (if there is any)
        if (attachmentsRefs.length) {
          const filesToDelete = attachmentsRefs.map((fileRef) => ({ storagePath: fileRef }));
          await deleteAttachments(filesToDelete);
        }
      }
      setIsUploading(false);
      return { attachmentsRefs, storageTaskFailed };
    } catch (error) {
      setIsUploading(false);
      Sentry.captureException(error, {
        extra: {
          userId,
        },
      });
      // Returning this to handle error messages from the caller function
      return { storageTaskFailed: true };
    }
  }, [
    fileAttachments,
    audioAttachment,
    userId,
    uploadAttachments,
    deleteAttachments,
  ]);

  const getAdditionalFields = useCallback(async () => {
    const additionalFields = {};
    if (fileAttachments.length || audioAttachment) {
      const { storageTaskFailed, attachmentsRefs } = await uploadMessageAttachments();
      if (storageTaskFailed) {
        setFileAttachments([]);
        setAudioAttachment(null);
        return { error: true };
      }
      additionalFields.attachmentsRefs = attachmentsRefs;
    }
    return { additionalFields, error: false };
  }, [
    audioAttachment,
    fileAttachments,
    uploadMessageAttachments,
  ]);

  const handleBroadcastSend = useCallback(async () => {
    const { additionalFields, error } = await getAdditionalFields();
    if (error) {
      openAlert(text.uploadError, true);
      return;
    }
    await sendBroadcastMessage(filteredRecipients, broadcastMessage, additionalFields);
    resetFields();
    openAlert(text.broadcastMessageScheduled);
  }, [
    broadcastMessage,
    filteredRecipients,
    resetFields,
    sendBroadcastMessage,
    getAdditionalFields,
    openAlert,
  ]);

  const onFilesSelected = useCallback((event) => {
    const { files } = event.target;
    if (files.length) {
      const filesArray = [...files];
      // Stream supports sending files of any type, just check file size
      const validFiles = filesArray.every((file) => file.size < MAX_FILE_UPLOAD_SIZE);
      if (validFiles) {
        setFileAttachments(filesArray);
      } else {
        openAlert(format(text.fileSizeError, {
          maxFileSize: MAX_FILE_UPLOAD_SIZE / 1000000, // Bytes to MB
        }), true);
      }
    }
  }, [
    openAlert,
  ]);

  const onAudioRecorded = useCallback((audioFile) => {
    setAudioAttachment(audioFile);
  }, []);

  const removeFileByIndex = useCallback((index) => {
    const resultAttachments = [...fileAttachments];
    resultAttachments.splice(index, 1);
    setFileAttachments(resultAttachments);
  }, [
    fileAttachments,
  ]);

  const removeAudioAttachment = useCallback(() => {
    setAudioAttachment(null);
  }, []);

  const handleAlertAction = useCallback(() => {
    if (alertHeader === text.success) {
      setIsBroadcastOpen(false);
    }
    setIsAlertOpen(false);
  }, [
    alertHeader,
    setIsBroadcastOpen,
  ]);

  return (
    <Container>
      <StyledHeader>
        <TitleContainer>{text.broadcastMessageTitle}</TitleContainer>
        <CloseButton onClick={() => setIsBroadcastOpen(false)} />
      </StyledHeader>
      <ModalContent>
        <TabContainer>
          <SelectedRecipientContainer>
            <StyledLabel>{text.toLabel}</StyledLabel>
            <StyledAutoComplete
              name={text.toLabel}
              label={text.toLabel}
              isMulti
              value={filters}
              options={filterTags}
              onChange={filterList}
            />
            <StyledLabel>
              {format(text.selectedNumberOfClientsText, {
                numberOfClients: filteredRecipients.length,
              })}
            </StyledLabel>
            <StyledLabel>
              {text.messageLabel}
              <StyledIonLabel
                onClick={() => setBroadcastMessage((prevText) => `${prevText}${text.clientNamePlaceholder}`)}
              >
                [
                {text.clientName}
                ]
              </StyledIonLabel>

            </StyledLabel>
            <StyledTextarea
              label={text.messageLabel}
              labelPlacement="floating"
              fill="outline"
              placeholder={text.enterMessage}
              autoGrow
              mode="ios"
              onIonInput={(e) => setBroadcastMessage(e.target.value)}
              value={broadcastMessage}
              rows={4}
            />
          </SelectedRecipientContainer>
          <AttachmentsContainer
            fileAttachments={fileAttachments}
            onFilesSelected={onFilesSelected}
            onFileRemoved={removeFileByIndex}
            isUploading={isUploading}
          />
          <AudioAttachmentsContainer
            onAudioRecorded={onAudioRecorded}
            onRemove={removeAudioAttachment}
            isUploading={isUploading}
          />
          <ScheduleButtonsContainer>
            <SendBroadcastButton
              onClick={handleBroadcastSend}
              disabled={filteredRecipients.length === 0 || !broadcastMessage}
            >
              <StyledChatIcon />
              {text.sendBroadcastMessage}
            </SendBroadcastButton>
          </ScheduleButtonsContainer>
        </TabContainer>
      </ModalContent>
      <IonAlert
        isOpen={isAlertOpen}
        message={message}
        header={alertHeader}
        backdropDismiss
        buttons={
          [
            {
              text: text.okay,
              handler: handleAlertAction,
            },
          ]
        }
      />
      <LoadingOverlay isLoading={isLoading} />
    </Container>
  );
};

export default MessagingBroadcastView;
