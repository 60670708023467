import { Collection } from 'firestorter';
import moment from 'moment';
import format from 'string-template';

import CollectionName from '../utils/collections';
import { firestorePaths, pathPlaceholder } from '../utils/firebasePaths';
import BaseDocument from './BaseDocument';

class MeasurementLog extends BaseDocument {
  get date() {
    return this.data.date.toDate();
  }

  get weight() {
    return this.data.weight;
  }

  get thigh() {
    return this.data.thigh;
  }

  get stomach() {
    return this.data.stomach;
  }

  get chest() {
    return this.data.chest;
  }

  get upperArm() {
    return this.data.upperArm;
  }

  get waist() {
    return this.data.waist;
  }

  get hip() {
    return this.data.hip;
  }

  get unitType() {
    return this.data.unitType;
  }

  get comment() {
    return this.data.comment;
  }

  get status() {
    return this.data.status || '';
  }

  get year() {
    return this.data.year;
  }

  get week() {
    return this.data.week;
  }

  get attachments() {
    return this.data.attachments;
  }

  static addDoc = async (data) => {
    const measurementLogCollection = new Collection(CollectionName.MEASUREMENTS_LOG);
    const measurementLogDoc = await measurementLogCollection.add(data);
    const measurementLog = new MeasurementLog(measurementLogDoc.ref);
    return measurementLog;
  };

  static getById = async (id) => {
    const docPath = format(firestorePaths.MEASUREMENTS_LOG_DOC, {
      [pathPlaceholder.MEASUREMENT_LOG_ID]: id,
    });

    const doc = new MeasurementLog(docPath);
    await doc.init();
    if (doc.hasData) {
      return doc;
    }
    return null;
  };

  static getLogsByDate = async (userId, date) => {
    const startDate = moment.utc(date).startOf('day').toDate();
    const measurementLogCollection = new Collection(() => CollectionName.MEASUREMENTS_LOG, {
      createDocument: (src, opts) => new MeasurementLog(src, opts),
      query: (ref) => ref
        .where('user', '==', userId)
        .where('date', '==', startDate),
    });
    await measurementLogCollection.fetch();
    return measurementLogCollection;
  }

  static getLogs(userId, options) {
    const {
      startAfterDoc = null,
      limit = 15,
    } = options;
    return new Collection(() => CollectionName.MEASUREMENTS_LOG, {
      createDocument: (src, opts) => new MeasurementLog(src, opts),
      query: (ref) => {
        const query = ref
          .where('user', '==', userId)
          .orderBy('date', 'desc')
          .limit(limit);
        if (startAfterDoc) {
          return query.startAfter(startAfterDoc.snapshot);
        }
        return query;
      },
    });
  }
}

export default MeasurementLog;
