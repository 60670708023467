import styled, { css } from 'styled-components';

import { safeAreaFromRight } from '../../../../styles/mixins';

const iconSize = 54;
const portraitActionPanelHeight = 159;
const landscapeActionPanelHeight = 90;

/*
  The bottom and top properties of the actions container should take into account the size of the
  lower action panel buttons (play/pause and menu buttons), so they don't overlap on smaller screens.
  These have different height values based on the device's orientation.
  We also use a sizeConfig prop to set the bottom and top properties, to set the action area for the
  virtual next button to work properly.
*/
const getSizeProps = ({
  top = 20,
  bottom = 20,
  width = 15,
  theme: {
    isPortrait,
  },
}) => {
  const actionPanelHeight = isPortrait ? portraitActionPanelHeight : landscapeActionPanelHeight;
  return (
    css`
      /* If sizeConfigBottom < actionPanelHeight, use actionPanelHeight, this way buttons won't overlap */
      bottom: max(${bottom}%, ${actionPanelHeight}px);
      /* top = min((100% - bottom - iconSize), sizeConfigTop) */
      top: min(calc(100% - max(${bottom}%, ${actionPanelHeight}px) - ${iconSize}px), ${top}%);
      width: ${width}%;
      min-width: ${iconSize}px;
      min-height: ${iconSize}px;
    `
  );
};

const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 3;
  right: ${safeAreaFromRight()};
  /* Configurable size */
  ${(props) => getSizeProps(props)}

  ${({ theme }) => theme.isPortrait && css`
    right: calc(${safeAreaFromRight()} + 15px);
    justify-content: flex-end;
  `}
`;

const IconContainer = styled.div`
  height: ${iconSize}px;
  width: ${iconSize}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
`;

export {
  ActionsContainer,
  IconContainer,
};
