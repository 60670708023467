import moment from 'moment';
import { DateFormat } from '../../utils/date';

import BodyMeasurement from './BodyMeasurement';
import CheckInImages from './CheckInImages';
import CheckInSummary from './CheckInSummary';
import texts from './texts.json';

const checkInPages = {
  images: 'images',
  measurements: 'measurements',
  summary: 'summary',
};

const checkInPageOrder = [
  checkInPages.images,
  checkInPages.measurements,
  checkInPages.summary,
];

const checkInPagesConfig = {
  [checkInPages.images]: {
    label: texts.pageLabels[checkInPages.images],
    component: CheckInImages,
  },
  [checkInPages.measurements]: {
    label: texts.pageLabels[checkInPages.measurements],
    component: BodyMeasurement,
  },
  [checkInPages.summary]: {
    label: texts.pageLabels[checkInPages.summary],
    component: CheckInSummary,
  },
};

const getCheckInWeekString = (date, add) => {
  const startDate = moment(date).startOf('isoWeek');
  const endDate = moment(date).endOf('isoWeek');
  if (add) {
    startDate.add(add, 'days');
    endDate.add(add, 'days');
  }
  return `${startDate.format(DateFormat.DAY_WITH_SHORT_MONTH)} - ${endDate.format(DateFormat.DAY_WITH_SHORT_MONTH)}`;
};

export {
  checkInPages,
  checkInPagesConfig,
  checkInPageOrder,
  getCheckInWeekString,
};
