import { CameraStatus, CameraStreamPlugin, VideoStreamSessionStatus } from 'capacitor-camera-stream';
import {
  decorate,
  action,
  observable,
} from 'mobx';

import config from '../../../config';
import BaseCameraProvider from './BaseCameraProvider';

/**
 * Camera provider that uses the capacitor-camera-stream plugin to interact with the native camera
 * and perform video recording upload/streaming. The video upload/streaming functionality will only
 * be enabled when the feature is enabled, the internal functions check for "isVideoStreamEnabled".
 */
class NativeCameraProvider extends BaseCameraProvider {
  constructor() {
    super();
    const { vonage: { apiKey } } = config;

    this.cameraStream = new CameraStreamPlugin();
    this.cameraStream.initialize({
      apiKey,
    });

    this.cameraStatus = this.cameraStream.cameraStatus || CameraStatus.ready;
    this.cameraStream.addListener('cameraStatusChange', this.handleCameraStatusChange);
  }

  handleCameraStatusChange = ({ status }) => {
    this.cameraStatus = status;
  };

  /**
   * Starts the camera streaming and previewing (if enabled) using the native API.
   *
   * @param {Object} options
   * @returns {Promise} A promise resolved when the camera streaming has started.
   */
  startCamera = async (options) => this.cameraStream.startCapture(options);

  /**
   * Stops the camera streaming done in the native side, and releases resources.
   */
  stopCamera = () => {
    // Force the status to 'stopping', synchronously.
    this.cameraStatus = CameraStatus.stopping;
    return this.cameraStream.stop();
  };

  /**
   * Captures an image using the native API. The image is encoded in base64, if specified by the options,
   * the image can be converted to a Blob object.
   *
   * @param {Object} options
   * @returns {Promise} The image capture result.
   */
  captureImage = async (options) => this.cameraStream.captureImage(options);

  // NOTE: video streaming has support for iOS/Android both
  hasVideoStreamSupport = () => true;

  startStream = async (sessionId, token) => {
    if (this.isVideoStreamEnabled) {
      return this.cameraStream.startStream({
        sessionId,
        token,
      });
    }
    return Promise.reject(new Error('Video stream is not enabled'));
  }

  pauseStream = () => {
    if (this.isVideoStreamEnabled) {
      this.cameraStream.pauseStream();
    }
  }

  resumeStream = () => {
    if (this.isVideoStreamEnabled) {
      this.cameraStream.resumeStream();
    }
  }

  stopStream = async () => {
    if (this.isVideoStreamEnabled) {
      this.cameraStream.stopStream();
    }
  }

  videoStreamSessionStatus = () => this.cameraStream.videoStreamSessionStatus();

  shouldReconnectVideoStreamSession = async () => {
    const { status } = await this.videoStreamSessionStatus();

    switch (status) {
      case VideoStreamSessionStatus.notCreated:
      case VideoStreamSessionStatus.notConnected:
      case VideoStreamSessionStatus.disconnecting:
      case VideoStreamSessionStatus.failed:
        return true;
      case VideoStreamSessionStatus.contextNotCreated:
      case VideoStreamSessionStatus.connected:
      case VideoStreamSessionStatus.connecting:
      case VideoStreamSessionStatus.reconnecting:
        return false;
      default:
        return false;
    }
  }

  clear = () => this.cameraStream.clear();

  addCameraStatusListener = (callback) => this.cameraStream.addListener('cameraStatusChange', callback);

  addVideoStreamConnectionChangeListener = (callback) => (
    this.cameraStream.addListener('videoStreamConnectionChange', callback)
  );

  addVideoPublisherStatusListener = (callback) => this.cameraStream.addListener('publisherStatusChange', callback);

  addVideoStreamErrorListener = (callback) => this.cameraStream.addListener('videoStreamError', callback);

  addCameraGeneralErrorListener = (callback) => this.cameraStream.addListener('cameraGeneralError', callback);

  refreshPreview = () => this.cameraStream.refreshPreviewDimensions();
}

decorate(NativeCameraProvider, {
  cameraStream: observable,
  cameraStatus: observable,
  startCamera: action,
  stopCamera: action,
  startStream: action,
  pauseStream: action,
  resumeStream: action,
  clear: action,
  addCameraStatusListener: action,
  addVideoStreamConnectionChangeListener: action,
  addVideoStreamErrorListener: action,
});

export default NativeCameraProvider;
