import styled from 'styled-components';

const StyledThumbnail = styled.img``;

const ThumbnailWrapper = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: solid 1px rgba(11, 28, 79, 0.4);
  border-radius: 6px;

  ${StyledThumbnail} {
    max-width: 140%;
  }
`;

export {
  ThumbnailWrapper,
  StyledThumbnail,
};
