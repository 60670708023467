import React, {
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';

import RowInput from './RowInput';
import RowHeader from './RowHeader';
import {
  Row,
} from './styles';

const LogRow = ({
  label,
  weight,
  reps,
  onLogUpdate,
  onRowDelete,
  setIndex,
  isDisabled,
  isHighlighted,
  onRowActive,
  onRowInactive,
  onCommentUpdated,
  enableComment,
  comment,
  measurementSystem,
}) => {
  const [isWeightActive, setIsWeightActive] = useState(false);
  const [isRepsActive, setIsRepsActive] = useState(false);

  const onValueUpdate = useMemo(() => debounce(500, (weightVal, repsVal) => {
    onLogUpdate({
      weight: weightVal,
      reps: repsVal,
      setIndex,
    });
  }), [
    onLogUpdate,
    setIndex,
  ]);

  const onWeightChange = (value) => {
    onValueUpdate(value, reps);
  };

  const onRepsChange = (value) => {
    onValueUpdate(weight, value);
  };

  const onRowDeleteClick = () => {
    onRowDelete(setIndex);
  };

  const onInputActiveChange = useMemo(() => debounce(50, (repsActive, weightActive) => {
    if (repsActive || weightActive) {
      onRowActive();
    } else {
      onRowInactive();
    }
  }), [
    onRowActive,
    onRowInactive,
  ]);

  const onRepsActive = () => {
    setIsRepsActive(true);
    onInputActiveChange(true, isWeightActive);
  };

  const onRepsInactive = () => {
    setIsRepsActive(false);
    onInputActiveChange(false, isWeightActive);
  };

  const onWeightActive = () => {
    setIsWeightActive(true);
    onInputActiveChange(isRepsActive, true);
  };

  const onWeightInactive = () => {
    setIsWeightActive(false);
    onInputActiveChange(isRepsActive, false);
  };

  return (
    <Row>
      <RowHeader
        label={label}
        onDelete={onRowDelete ? onRowDeleteClick : null}
        isHighlighted={isHighlighted}
        onCommentUpdated={onCommentUpdated}
        enableComment={enableComment}
        comment={comment}
      />
      <RowInput
        value={weight}
        onChange={onWeightChange}
        onFocus={onWeightActive}
        onBlur={onWeightInactive}
        allowDecimalValues
        disabled={isDisabled}
        rowInputKey={measurementSystem}
      />
      <RowInput
        value={reps}
        onChange={onRepsChange}
        onFocus={onRepsActive}
        onBlur={onRepsInactive}
        disabled={isDisabled}
      />
    </Row>
  );
};

LogRow.propTypes = {
  onLogUpdate: PropTypes.func.isRequired,
  label: PropTypes.string,
  weight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  reps: PropTypes.number,
  setIndex: PropTypes.number,
  isDisabled: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  enableComment: PropTypes.bool,
  comment: PropTypes.string,
  measurementSystem: PropTypes.string,
  onRowDelete: PropTypes.func,
  onRowActive: PropTypes.func,
  onRowInactive: PropTypes.func,
  onCommentUpdated: PropTypes.func,
};

LogRow.defaultProps = {
  label: '',
  weight: 0,
  reps: 0,
  setIndex: -1,
  isDisabled: false,
  isHighlighted: false,
  onRowDelete: null,
  enableComment: false,
  comment: '',
  measurementSystem: '',
  onRowActive: () => {},
  onRowInactive: () => {},
  onCommentUpdated: () => {},
};

export default LogRow;
