import styled from 'styled-components';

import { ReactComponent as CloseThreadIcon } from '../../assets/icon/close-thread-icon.svg';

const CloseThreadIconComponent = styled(CloseThreadIcon)`
  height: 30px;
  width: 30px;
  margin-right: 20px;
`;

export {
  CloseThreadIconComponent,
};
