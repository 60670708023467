import styled from 'styled-components';

import { sansSerif } from '../../../styles/text';

const Container = styled.div`
  padding: 16px;
  margin-bottom: 8px;
  background-color: ${({ theme }) => theme.colors.system.alpha};
  color: ${({ theme }) => theme.colors.system.beta};
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

const StyledContainer = styled.div`
  width: 100%;
  margin-top: 8px;
`;

const Title = styled.h1`
  ${sansSerif.headlineFontStyles}
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin: 0;
  width: 100%;
`;

export {
  Container,
  StyledContainer,
  Title,
};
