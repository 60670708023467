import styled, { css } from 'styled-components';

const VideoPreviewContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  transform: scale(1.5) translateY(-18%);

  ${({ theme }) => theme.isPortrait && css`
    transform:  scale(1.3) translateY(-10%);
  `}
`;

const ClickOverlay = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1;
`;

// We need to make the shadow overlay slightly bigger than the video to avoid grey borders.
const overlayPadding = 2;

const ShadowOverlay = styled.div`
  position: absolute;
  top: -${overlayPadding}px;
  left: -${overlayPadding}px;
  width: calc(100% + ${overlayPadding * 2}px);
  height: calc(100% + ${overlayPadding * 2}px);
  box-shadow: inset 0px 0px 19px 29px #FFFFFF;
`;

export {
  VideoPreviewContainer,
  ClickOverlay,
  ShadowOverlay,
};
