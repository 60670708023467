import React, { useContext, useCallback } from 'react';
import { observer } from 'mobx-react';
import { IonMenu } from '@ionic/react';
import { NavLink, useLocation } from 'react-router-dom';

import Avatar from '../../Avatar';
import ChatContext from '../../../chat/context';
import UserContext from '../../../context/UserContext';
import AppContext from '../../../context/AppContext';
import {
  NavWrapper,
  HeaderContainer,
  Profile,
  AccountInfo,
  StyledNavigationMenu,
} from './styles';

const SideNavigation = () => {
  const {
    userDoc: {
      data: {
        firstName,
        avatarUrl,
      },
      id,
    },
  } = useContext(UserContext);
  const { sideMenuRef } = useContext(AppContext);
  const { isChatModalOpen } = useContext(ChatContext);

  const { search } = useLocation();

  const closeMenu = useCallback(() => {
    if (sideMenuRef.current) {
      sideMenuRef.current.close();
    }
  }, [
    sideMenuRef,
  ]);

  return (
    <IonMenu
      ref={sideMenuRef}
      contentId="app-side-menu-content"
      id="app-side-menu"
      disabled={isChatModalOpen}
    >
      <NavWrapper>
        <HeaderContainer>
          <Profile>
            <NavLink to={`/u/${id}/settings/profile${search}`}>
              <Avatar url={avatarUrl} />
            </NavLink>
            <AccountInfo>
              <div>{firstName}</div>
            </AccountInfo>
          </Profile>
        </HeaderContainer>
        <StyledNavigationMenu
          onItemAction={closeMenu}
        />
      </NavWrapper>
    </IonMenu>
  );
};

export default observer(SideNavigation);
