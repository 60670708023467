import styled from 'styled-components';

const CardContainer = styled.div`
  background: ${({ theme }) => theme.colors.system.background};
  border-radius: 8px;
  padding: 16px;
  margin: 16px 16px 40px;
`;

const SupportMessage = styled.div`
  text-align: center;
  color: rgba(20, 34, 47, 0.4);

  a {
    color: rgba(20, 34, 47, 0.4);
  }
`;

export {
  CardContainer,
  SupportMessage,
};
