import styled from 'styled-components';

import { fontFamily } from '../../styles/text';
import { addAlpha } from '../../utils/colorUtils';

const FooterNote = styled.div`
  color: ${({ theme }) => addAlpha(theme.colors.system.beta, 0.6)};
  font-family: ${fontFamily.sansSerif};
  font-size: 12px;
  padding: 16px;
`;

export {
  FooterNote,
};
