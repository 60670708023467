import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { debounce } from 'throttle-debounce';

import { Container } from './styles';

const SCROLL_DEBOUNCE_TIME = 100;

const ScrollHandler = React.forwardRef((
  {
    onScrollToTop,
    onScrollToBottom,
    children,
    className,
  },
  ref,
) => {
  const onScrollDebounced = useMemo(() => debounce(SCROLL_DEBOUNCE_TIME, (event) => {
    const {
      target: {
        scrollTop,
        offsetHeight,
        scrollHeight,
      },
    } = event;
    const currentPos = scrollTop + offsetHeight;

    // For rare occurence of a fractional currentPos value larger than scrollHeight
    if (Math.abs(currentPos - scrollHeight) < 1) {
      onScrollToBottom();
    } else if (scrollTop === 0) {
      onScrollToTop();
    }
  }), [
    onScrollToBottom,
    onScrollToTop,
  ]);

  const onScroll = (event) => onScrollDebounced({ ...event });

  return (
    <Container
      onScroll={onScroll}
      className={className}
      ref={ref}
    >
      {children}
    </Container>
  );
});

ScrollHandler.propTypes = {
  children: PropTypes.node.isRequired,
  onScrollToTop: PropTypes.func,
  onScrollToBottom: PropTypes.func,
  className: PropTypes.string,
};

ScrollHandler.defaultProps = {
  onScrollToTop: () => {},
  onScrollToBottom: () => {},
  className: '',
};

export default styled(ScrollHandler)``;
