import styled from 'styled-components';

const StyledIFrame = styled.iframe`
  width: 100%;
  height: 100%;
`;

export {
  StyledIFrame,
};
