const config = {
  cards: [
    {
      id: 'challenging',
      title: 'How challenging was the workout?',
      initialOptionSelected: 0,
      options: [
        'No Exertion',
        'Very Easy',
        'Easy',
        'Somewhat Moderate',
        'Moderate 💪',
        'Somewhat Hard 💪💪',
        'Hard 💪💪💪️',
        'Very hard 🔥',
        'Extremely hard 🔥🔥',
        'Maximum Effort 🔥🔥🔥',
      ],
    },
    {
      id: 'variation',
      title: 'How was the amount of variation?',
      initialOptionSelected: 1,
      options: [
        'Prefer Less',
        'Just Right',
        'Prefer More',
      ],
    },
    {
      id: 'length',
      title: 'How was the workout length?',
      initialOptionSelected: 1,
      options: [
        'Prefer Shorter',
        'Just Right',
        'Prefer Longer',
      ],
    },
    {
      id: 'objectives',
      title: 'Did the workout help you move towards your objectives?',
      initialOptionSelected: 0,
      options: [
        'Yes',
        'Somewhat Yes',
        'Somewhat No',
        'No',
      ],
    },
  ],
};

const getCardsOrderedByID = () => {
  const { cards } = config;
  return cards.reduce((acc, { id, ...rest }) => ({
    ...acc,
    [id]: { ...rest },
  }), {});
};

export default config;
export {
  getCardsOrderedByID,
};
