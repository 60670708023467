import React from 'react';
import PropTypes from 'prop-types';
import { IonPage, IonContent } from '@ionic/react';

import MenuHeader from './MenuHeader';
import PageContent from './PageContent';
import { HeaderContextProvider } from './context';

/**
 * Renders the page layout that contains a header with the navigation side menu.
 * It also adds the {HeaderContextProvider} to all the descendants including the {MenuHeader}.
 */
const NavigationMenuLayout = ({ children }) => (
  <IonPage>
    <HeaderContextProvider>
      <MenuHeader />
      <IonContent>
        <PageContent>
          {children}
        </PageContent>
      </IonContent>
    </HeaderContextProvider>
  </IonPage>
);

NavigationMenuLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NavigationMenuLayout;
