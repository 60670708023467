import styled, { css } from 'styled-components';

import { ReactComponent as InfoIcon } from '../../../../../../assets/icons/info-icon.svg';

import { sansSerif } from '../../../../../../styles/text';
import VideoPreview from '../VideoPreview';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font-style: normal;
  text-transform: uppercase;
  font-feature-settings: 'liga' off;
  color: ${({ theme }) => theme.colors.system.beta};
  flex: 1;

  ${({ theme }) => theme.isPortrait && css`
    position: relative;
  `}
`;

const StyledActivityPanel = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px 18px;
  color: ${({ theme }) => theme.colors.system.beta};
  transition: all 0.4s;
  ${({ active = true }) => css`opacity: ${active ? 1 : 0.6};`}
  z-index: 1;

  ${({ theme }) => theme.isPortrait && css`
    position: relative;
    padding: 10px 16px;
  `}
`;

const SubIdentifier = styled.span`
  font-size: 40px;
`;

const SideLabel = styled.span`
  font-size: 28px;
  text-transform: lowercase;

  ${({ theme }) => theme.isPortrait && css`
    display: block;
    height: 30px;
    line-height: 30px;
    text-align: right;
  `}
`;

const InfoLine = styled.div`
  width: 100%;
  text-align: left;

  ${({ theme }) => theme.isPortrait && css`
    width: initial;
  `}
`;

const Title = styled(InfoLine)`
  ${sansSerif.headline2}
  font-weight: 700;
  font-size: 34px;
  line-height: 37px;
  max-height: 110px;
  flex: 1;

  ${({ theme }) => theme.isPortrait && css`
    max-height: 140px;
    width: initial;
    position: relative;
    margin-right: 10px;
    font-size: 30px;
    line-height: 36px;
  `}
`;

const ActivityPanelExtraInfo = styled.div`
  position: relative;


  ${({ theme }) => theme.isPortrait && css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: -1px;
  `}
`;

const Subtitle = styled(InfoLine)`
  text-align: right;
  min-width: 122px;
  font-variant-numeric: tabular-nums;
  font-weight: bold;
  font-size: 55px;

  ${({ theme }) => theme.isPortrait && css`
    line-height: 55px;
  `}
`;

const SideIndicatorContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 20px;

  ${({ theme }) => theme.isPortrait && css`
    position: relative;
    bottom: initial;
    right: initial;
    margin-top: 10px;
  `}
`;

const ExtraInfoContainer = styled.div`
  width: 100%;

  ${({ theme }) => theme.isPortrait && css`
    position: absolute;
    bottom: 0;
  `}
`;

const StyledInfoIcon = styled(InfoIcon)`
  position: absolute;
  bottom: 20px;
  left: 25px;

  ${({ theme }) => theme.isPortrait && css`
    right: 16px;
    left: initial;
  `}
`;

const GenericVideoPreview = styled(VideoPreview)`
  transform: scale(0.9);
`;

export {
  Container,
  StyledActivityPanel,
  Title,
  ActivityPanelExtraInfo,
  Subtitle,
  SideIndicatorContainer,
  SubIdentifier,
  SideLabel,
  ExtraInfoContainer,
  StyledInfoIcon,
  GenericVideoPreview,
};
