import React from 'react';
import { compose } from 'recompose';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { WorkoutDetailsContextProvider } from '../../../components/WorkoutDetails';
import { withWorkoutEditorContextProviderFromUrl } from '../../context/WorkoutEditorContextFromUrl';
import WorkoutEditorDetailsContainer from '../../components/WorkoutEditorDetails';

import { StyledStandaloneWorkoutEditorDetails } from './styles';

const StandaloneWorkoutEditorDetails = () => (
  <StyledStandaloneWorkoutEditorDetails>
    <WorkoutDetailsContextProvider>
      <WorkoutEditorDetailsContainer />
      <ToastContainer />
    </WorkoutDetailsContextProvider>
  </StyledStandaloneWorkoutEditorDetails>
);

export default compose(
  withWorkoutEditorContextProviderFromUrl,
)(StandaloneWorkoutEditorDetails);
