import styled from 'styled-components';

import { ReactComponent as Icon } from '../../../assets/icons/yellow-circle-check.svg';

const CircleCheckIcon = styled(Icon)`
  [data-element="background"] {
    fill: ${({ theme }) => theme.colors.base.secondary};
  }

  [data-element="border"] {
    fill: ${({ theme }) => theme.colors.base.secondaryVariant};
  }

  [data-element="check"] {
    fill: ${({ theme }) => theme.colors.system.support};
    stroke: ${({ theme }) => theme.colors.base.secondaryVariant};
  }
`;

export default CircleCheckIcon;
