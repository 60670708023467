import styled from 'styled-components';
import { headline1, headline2, headline3 } from '../../styles/text';

const Heading1 = styled.h1`
  ${headline1}
`;

const Heading2 = styled.h2`
  ${headline2}
`;

const Heading3 = styled.h3`
  ${headline3}
`;

export {
  Heading1,
  Heading2,
  Heading3,
};
