import React from 'react';
import PropTypes from 'prop-types';

import CloseButton from '../../../components/CloseButton';
import colors from '../../../styles/baseColors';

import {
  Wrapper,
  ActionsWrapper,
  ModalHead,
  FormSubmit,
  StyledForm,
  SubmitWrapper,
} from './styles';
import texts from './texts.json';

const FormWrapper = ({
  onClose,
  title,
  onSubmit,
  children,
}) => (
  <Wrapper>
    <ActionsWrapper>
      <CloseButton onClick={onClose} color={colors.system.beta} />
      <ModalHead>{title}</ModalHead>
    </ActionsWrapper>

    <StyledForm onSubmit={onSubmit}>
      {children}
      <SubmitWrapper>
        <FormSubmit type="submit" value={texts.submitButton} />
      </SubmitWrapper>
    </StyledForm>
  </Wrapper>
);

FormWrapper.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default FormWrapper;
