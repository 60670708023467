/* eslint class-methods-use-this: off */

import {
  degrees,
  hdist,
  vdist,
  vMiddlePoint,
  hMiddlePoint,
} from '../utils/math';
import BasePrediction from './BasePrediction';

const FlowJoint = {
  Nose: 1,
  Neck: 2,
  LElbow: 3,
  RElbow: 4,
  LShoulder: 5,
  RShoulder: 6,
  LWrist: 7,
  RWrist: 8,
  LHip: 9,
  RHip: 10,
  LAnkle: 11,
  RAnkle: 12,
  LKnee: 13,
  RKnee: 14,
  LEye: 15,
  REye: 16,
  REar: 18,
  LEar: 17,
};

const PartMapping = {
  [FlowJoint.Nose]: 16,
  [FlowJoint.Neck]: 8,
  [FlowJoint.LElbow]: 14,
  [FlowJoint.RElbow]: 11,
  [FlowJoint.LShoulder]: 13,
  [FlowJoint.RShoulder]: 12,
  [FlowJoint.LWrist]: 15,
  [FlowJoint.RWrist]: 10,
  [FlowJoint.LHip]: 3,
  [FlowJoint.RHip]: 2,
  [FlowJoint.LAnkle]: 5,
  [FlowJoint.RAnkle]: 0,
  [FlowJoint.LKnee]: 4,
  [FlowJoint.RKnee]: 1,
  [FlowJoint.LEye]: 19,
  [FlowJoint.REye]: 17,
  [FlowJoint.LEar]: 20,
  [FlowJoint.REar]: 18,
};

/**
 * @class Class that represents a single human detected by our skeletal tracking system.
 */
class FlowHuman extends BasePrediction {
  /**
   * Creates an instance of FlowHuman
   *
   * @constructor
   * @param {Object} human A dict of body parts with joints as keys and joint coordinates as values
   * @param {number} aspectRatio
   */
  constructor(human, aspectRatio) {
    super(aspectRatio);
    this.generateBodyParts(human);
    this.human = human;
  }

  keypointsDefinition() {
    return FlowJoint;
  }

  /**
   * Uses a human object passed in to populate the internal parts representation.
   */
  generateBodyParts(human) {
    this.keypoints = {};
    Object.values(FlowJoint).forEach((joint) => {
      const jointIndex = PartMapping[joint];
      const part = [human[2 * jointIndex], human[2 * jointIndex + 1]];
      if (((part[0] > 0) && (part[1] > 0))) {
        this.keypoints[joint] = part;
      }
    });
    this.pctJointsVisible = (Object.keys(this.keypoints).length * 100)
      / Object.keys(FlowJoint).length;
  }

  /**
   * Check if this instance is equal-ish to the given FlowHuman instance based on the
   * threshold. This compares joint by joint to determine if they're equal-ish.
   *
   * @param {Object} flowHuman FlowHuman instance to compare with.
   * @param {number=} threshold A number between 0 and 100.
   * @returns {boolean}
   */
  equalishTo(flowHuman, threshold = 2) {
    if (this.pctJointsVisible !== flowHuman.pctJointsVisible) {
      return false;
    }

    // both are different when on joint coordinates doesn't meet
    // the criteria
    const areDifferent = Object.entries(this.keypoints).some(([joint, coords]) => {
      if (flowHuman.keypoints[joint]) {
        const compareCoords = flowHuman.keypoints[joint];
        return (Math.floor(Math.abs(coords[0] - compareCoords[0]) * 100) > threshold
          || Math.floor(Math.abs(coords[1] - compareCoords[1]) * 100) > threshold);
      }
      return false;
    });

    return !areDifferent;
  }

  /**
   * Calculates the bounding box defined by shoulders and knees joints.
   *
   * @returns {Array} The bounding box result.
   */
  get mainCoreBoundingBox() {
    let xMax = -Infinity;
    let xMin = Infinity;
    let yMax = -Infinity;
    let yMin = Infinity;

    const hasShoulder = this.keypoints[FlowJoint.LShoulder] || this.keypoints[FlowJoint.RShoulder];
    const hasKnee = this.keypoints[FlowJoint.LKnee] || this.keypoints[FlowJoint.RKnee];

    /*
      Calculates the bounding box only when at least one shoulder
      and one knee is identified.
    */
    if (hasShoulder && hasKnee) {
      const requiredJoints = [
        FlowJoint.LShoulder,
        FlowJoint.RShoulder,
        FlowJoint.LKnee,
        FlowJoint.RKnee,
      ];

      requiredJoints.forEach((joint) => {
        const bodyPart = this.keypoints[joint];
        if (bodyPart) {
          xMin = Math.min(xMin, bodyPart[0]);
          yMin = Math.min(yMin, bodyPart[1]);
          xMax = Math.max(xMax, bodyPart[0]);
          yMax = Math.max(yMax, bodyPart[1]);
        }
      });
    }

    const result = [xMin, yMin, xMax, yMax];
    this.debugData.mainCoreBoundingBox = result;
    return result;
  }

  /**
   * Calculates the height of the human based on the bounding
   * box defined by shoulders and knees.
   *
   * @returns {number} The height value.
   */
  get mainCoreBoundingBoxHeight() {
    const box = this.mainCoreBoundingBox;
    const yMin = box[1];
    const yMax = box[3];
    const result = yMax - yMin;
    this.debugData.mainCoreBoundingBoxHeight = result;
    return result;
  }

  /**
   * Given 2 joints, returns the angle in degrees from the Y axis.
   */
  angleFromY(joint1, joint2) {
    const j1Coords = this.coords(joint1);
    const j2Coords = this.coords(joint2);
    return Math.abs(degrees(Math.atan2((j1Coords[0] - j2Coords[0]), (j1Coords[1] - j2Coords[1]))));
  }

  /**
   * Returns the angles in degress at for the left and right sides of the hip.
   */
  get hipAngles() {
    const lAngle = this.keypointAngle(FlowJoint.LHip, FlowJoint.LKnee, FlowJoint.LShoulder);
    const rAngle = this.keypointAngle(FlowJoint.RHip, FlowJoint.RKnee, FlowJoint.RShoulder);
    const ret = [lAngle, rAngle];
    this.debugData.hipAngles = ret;
    return ret;
  }

  /**
   * Returns the angle in degrees for each arm to that side of the body.
   */
  get upperArmToBody() {
    const lAngle = this.keypointAngle(FlowJoint.LShoulder, FlowJoint.LHip, FlowJoint.LElbow);
    const rAngle = this.keypointAngle(FlowJoint.RShoulder, FlowJoint.RHip, FlowJoint.RElbow);
    const ret = [lAngle, rAngle];
    this.debugData.uppperAToBody = ret;
    return ret;
  }

  /**
   * Returns the angles in degrees at the left and right knees.
   */
  get kneeAngles() {
    const lAngle = this.keypointAngle(FlowJoint.LKnee, FlowJoint.LAnkle, FlowJoint.LHip);
    const rAngle = this.keypointAngle(FlowJoint.RKnee, FlowJoint.RAnkle, FlowJoint.RHip);
    const ret = [lAngle, rAngle];
    this.debugData.kneeAngles = ret;
    return ret;
  }

  get upperArmAnglesFromY() {
    // Arms up: 0, Arms down: 180
    const ret = [
      this.angleFromY(FlowJoint.LShoulder, FlowJoint.LElbow),
      this.angleFromY(FlowJoint.RShoulder, FlowJoint.RElbow),
    ];
    this.debugData.upperArmAnglesFromY = ret;
    return ret;
  }

  get lowerArmAnglesFromY() {
    // Arms up: 0, Arms down: 180
    const ret = [
      this.angleFromY(FlowJoint.LElbow, FlowJoint.LWrist),
      this.angleFromY(FlowJoint.RElbow, FlowJoint.RWrist),
    ];
    this.debugData.lowerArmAnglesFromY = ret;
    return ret;
  }

  get wristsFromBottom() {
    const ret = [
      this.fromBottom(FlowJoint.LWrist),
      this.fromBottom(FlowJoint.RWrist),
    ];
    this.debugData.wristsFromBottom = ret;
    return ret;
  }

  get elbowsFromBottom() {
    const ret = [
      this.fromBottom(FlowJoint.LElbow),
      this.fromBottom(FlowJoint.RElbow),
    ];
    this.debugData.elbowsFromBottom = ret;
    return ret;
  }

  get hipsFromBottom() {
    const ret = [
      this.fromBottom(FlowJoint.LHip),
      this.fromBottom(FlowJoint.RHip),
    ];
    this.debugData.hipsFromBottom = ret;
    return ret;
  }

  get kneesFromBottom() {
    const ret = [
      this.fromBottom(FlowJoint.LKnee),
      this.fromBottom(FlowJoint.RKnee),
    ];
    this.debugData.kneesFromBottom = ret;
    return ret;
  }

  /**
   * Calculate the base coordinates of the middle point
   * between two joints.
   *
   * @param {number} jointA
   * @param {number} jointB
   * @returns {Array}
   */
  jointsBaseCoordsMiddlePoint(jointA, jointB) {
    const xCoord = hMiddlePoint(
      this.baseCoords(jointA),
      this.baseCoords(jointB),
    );
    const yCoord = vMiddlePoint(
      this.baseCoords(jointA),
      this.baseCoords(jointB),
    );
    return [xCoord, yCoord];
  }

  get shouldersMiddlePoint() {
    const result = this.jointsBaseCoordsMiddlePoint(FlowJoint.LShoulder, FlowJoint.RShoulder);
    this.debugData.shouldersMiddlePoint = result;
    return result;
  }

  get hipsMiddlePoint() {
    const result = this.jointsBaseCoordsMiddlePoint(FlowJoint.LHip, FlowJoint.RHip);
    this.debugData.hipsMiddlePoint = result;
    return result;
  }

  get anklesMiddlePoint() {
    const result = this.jointsBaseCoordsMiddlePoint(FlowJoint.LAnkle, FlowJoint.RAnkle);
    this.debugData.anklesMiddlePoint = result;
    return result;
  }

  /**
   * Get the head position in the Y-axis by using both eyes as
   * main joints to calculate it.
   *
   * @returns {Array} coordinates
   */
  get headPointInY() {
    const result = vMiddlePoint(this.baseCoords(FlowJoint.LEye),
      this.baseCoords(FlowJoint.REye));
    this.debugData.headPointInY = result;
    return result;
  }

  /**
   * Check if the body is vertical, by checking the hips joints
   *
   * @param {number=} threshold
   * @returns {boolean}
   */
  isBodyVertical(threshold = 0.5) {
    const lHipShoulderHDist = hdist(this.coords(FlowJoint.LHip),
      this.coords(FlowJoint.LShoulder));
    const rHipShoulderHDist = hdist(this.coords(FlowJoint.RHip),
      this.coords(FlowJoint.RShoulder));

    const result = (lHipShoulderHDist <= threshold || rHipShoulderHDist <= threshold);
    this.debugData.isBodyVertical = [result, lHipShoulderHDist, rHipShoulderHDist];

    return result;
  }

  /**
   * Check if knees are vertically aligned.
   *
   * @param {number=} threshold
   * @returns {boolean}
   */
  areKneesVerticallyAligned(threshold = 0.4) {
    const kneesDist = vdist(this.coords(FlowJoint.LKnee),
      this.coords(FlowJoint.RKnee));

    const result = (kneesDist <= threshold);
    this.debugData.areKneesVerticallyAligned = result;

    return result;
  }

  /**
   * Check if at least one of the ankles and one of the facial features are visible
   */
  wholeBodyVisible() {
    const ret = ((this.visible(FlowJoint.LAnkle) || this.visible(FlowJoint.RAnkle))
        && (this.visible(FlowJoint.Nose)
            || this.visible(FlowJoint.LEar) || this.visible(FlowJoint.REar)));
    this.debugData.wholeBodyVisible = ret;
    return ret;
  }

  static areJointsOnTheFloor(jointsValues, threshold = 0.15) {
    // Extract the position of the joints
    const [leftJoint, rightJoint] = jointsValues;

    // Check if the joints are on the floor
    const isLeftJointOnTheFloor = leftJoint <= threshold;
    const isRightJointOnTheFloor = rightJoint <= threshold;

    return [isLeftJointOnTheFloor, isRightJointOnTheFloor];
  }

  /**
   * Check if wrists are on the floor.
   *
   * @returns {boolean[]}
   */
  areWristsOnTheFloor() {
    // Extract the position of the wrists
    const [
      isLeftWristOnFloor,
      isRightWristOnFloor,
    ] = FlowHuman.areJointsOnTheFloor(this.wristsFromBottom);

    // Build the return array and add some debug data.
    const ret = [isLeftWristOnFloor, isRightWristOnFloor];
    this.debugData.areWristsOnTheFloor = ret;

    return ret;
  }

  /**
   * Check if knees are on the floor.
   *
   * @returns {boolean[]}
   */
  areKneesOnTheFloor() {
    // Check if knees are on the floor
    const [
      isLeftKneeOnFloor,
      isRightKneeOnFloor,
    ] = FlowHuman.areJointsOnTheFloor(this.kneesFromBottom);

    const ret = [isLeftKneeOnFloor, isRightKneeOnFloor];
    this.debugData.areKneesOnTheFloor = ret;

    return ret;
  }

  /**
   * Check if hips are on the floor.
   *
   * @returns {boolean[]}
   */
  areHipsOnTheFloor() {
    // Check if hips are on the floor
    const [
      isLeftHipOnFloor,
      isRightHipOnFloor,
    ] = FlowHuman.areJointsOnTheFloor(this.hipsFromBottom);

    const ret = [isLeftHipOnFloor, isRightHipOnFloor];
    this.debugData.areHipsOnTheFloor = ret;

    return ret;
  }
}

export default FlowHuman;
export {
  FlowJoint,
  PartMapping,
};
