import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { Formik } from 'formik';

import Button from '../../components/Button';
import Slides from '../../components/Slides';
import TrackedActivity from '../../models/TrackedActivity';
import PhotoPicker from '../../components/PhotoPicker';
import TrackedStats from './components/TrackedStats';

import {
  ContainerForm,
  StyledFormikInput,
  SpacedCard,
  ContentContainer,
  FooterContainer,
} from './styles';
import texts from './texts.json';
import { initialValues, validationSchema } from './validation';

const MAX_TEXT_AREA_HEIGHT = 100;

const TrackedActivityInfo = ({
  trackedActivity,
  onSubmit,
  shouldAllowActions,
  attachmentsDataUrl,
}) => {
  const {
    name,
  } = trackedActivity;

  const [selectedDataUrl, setSelectedDataUrl] = useState(attachmentsDataUrl);
  const [fileChangesDetails, setFileChangesDetails] = useState([]);
  const [slidesReady, setSlidesReady] = useState(true);
  const commentTextAreaElementRef = useRef(null);

  const adjustHeight = useCallback(() => {
    const textArea = commentTextAreaElementRef.current;
    textArea.style.height = `${Math.min(textArea.scrollHeight, MAX_TEXT_AREA_HEIGHT)}px`;
  }, []);

  useEffect(() => {
    const {
      comment,
    } = trackedActivity;

    if (commentTextAreaElementRef.current
      && commentTextAreaElementRef.current.value === ''
      && comment
    ) {
      commentTextAreaElementRef.current.value = comment;
      adjustHeight();
    }
  }, [
    trackedActivity,
    adjustHeight,
  ]);

  const onFormSubmission = useCallback((data) => {
    const comment = commentTextAreaElementRef.current.value;
    onSubmit({ ...data, comment, fileChangesDetails });
  }, [
    onSubmit,
    fileChangesDetails,
  ]);

  const onFileSelection = useCallback((detail) => {
    setSlidesReady(false);
    setSelectedDataUrl([...selectedDataUrl, ...[{ dataUrl: detail.dataUrl }]]);
    setFileChangesDetails([...fileChangesDetails, ...[{ ...{ action: 'UPLOAD' }, ...detail }]]);
  }, [
    selectedDataUrl,
    fileChangesDetails,
  ]);

  const onDeletion = useCallback((index) => {
    const { dataUrl, storagePath } = selectedDataUrl[index];
    setSlidesReady(false);
    selectedDataUrl.splice(index, 1);
    if (storagePath) {
      // If it's an already uploaded attachment, add it as a DELETE action
      const deleteAction = { action: 'DELETE', dataUrl, storagePath };
      setFileChangesDetails([
        ...fileChangesDetails,
        deleteAction,
      ]);
    } else {
      // If it's not uploaded yet, remove the corresponding UPLOAD action from the array
      setFileChangesDetails(fileChangesDetails.filter((value) => dataUrl !== value.dataUrl));
    }
  }, [
    selectedDataUrl,
    fileChangesDetails,
  ]);

  // Workaround to the following issue with ionic-slides
  // https://github.com/ionic-team/ionic-framework/issues/18782
  useLayoutEffect(() => {
    setSlidesReady(true);
  }, [slidesReady, selectedDataUrl]);

  const formInitialValues = useMemo(() => {
    if (trackedActivity) {
      const {
        comment,
        customTitle,
      } = trackedActivity;

      const title = customTitle || name;

      return {
        ...initialValues,
        ...{
          title,
          comment,
        },
      };
    }

    return initialValues;
  }, [
    trackedActivity,
    name,
  ]);

  const getImagesSlidesComponent = useCallback(() => {
    const imagesUrls = selectedDataUrl.map((item) => item.dataUrl);
    return slidesReady
      && (
        <Slides
          pager
          imagesUrls={imagesUrls}
          allowDelete={shouldAllowActions}
          onDeletion={onDeletion}
        />
      );
  }, [
    shouldAllowActions,
    selectedDataUrl,
    slidesReady,
    onDeletion,
  ]);

  const getFileHandlingComponent = useCallback(() => (
    shouldAllowActions
    && selectedDataUrl.length < 10
    && (
      <PhotoPicker
        actionText={texts.photoAddMessage}
        onFileSelected={onFileSelection}
      />
    )
  ), [
    shouldAllowActions,
    selectedDataUrl,
    onFileSelection,
  ]);

  return (
    <>
      <Formik
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={onFormSubmission}
      >
        {({
          errors, touched, isSubmitting,
        }) => (
          <ContainerForm>
            <ContentContainer>
              <SpacedCard>
                <StyledFormikInput
                  name="title"
                  placeholder={texts.placeholder.title}
                  error={errors.title}
                  touched={touched.title}
                  autoComplete="off"
                  disabled={!shouldAllowActions}
                />
                <StyledFormikInput
                  name="comment"
                  as="textarea"
                  placeholder={texts.placeholder.comment}
                  error={errors.comment}
                  touched={touched.comment}
                  rows="1"
                  ref={commentTextAreaElementRef}
                  onInput={adjustHeight}
                  autoComplete="off"
                  disabled={!shouldAllowActions}
                />
              </SpacedCard>
              {getImagesSlidesComponent()}
              {getFileHandlingComponent()}
              <TrackedStats trackedActivity={trackedActivity} />
            </ContentContainer>
            {shouldAllowActions && (
              <FooterContainer>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                >
                  {texts.mainActionName}
                </Button>
              </FooterContainer>
            )}
          </ContainerForm>
        )}
      </Formik>
    </>
  );
};

TrackedActivityInfo.propTypes = {
  trackedActivity: PropTypes.instanceOf(TrackedActivity).isRequired,
  onSubmit: PropTypes.func.isRequired,
  shouldAllowActions: PropTypes.bool.isRequired,
  attachmentsDataUrl: PropTypes.array.isRequired,
};

export default compose(
  observer,
)(TrackedActivityInfo);
