import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import UserContext from '../../../context/UserContext';
import useCurrentLoggedInUser from '../../../hooks/useCurrentLoggedInUser';
import useComponentMounted from '../../../hooks/useComponentMounted';

import MeasurementLog from '../../../models/MeasurementLog';
import SlideDrawer from '../../../components/SlideDrawer';
import MeasurementLogContext from '../../../context/MeasurementLogContext';
import withPortal from '../../../components/withPortal';
import logEvent from '../../../utils/logger';

import BodyMeasurement from './BodyMeasurement';
import {
  defaultValues,
} from './validation';

const BodyMeasurementContainer = ({
  isOpen,
  closeDrawer,
  date,
}) => {
  const [initialValues, setInitialValues] = useState(defaultValues);

  const isComponentMountedRef = useComponentMounted();

  const { userId } = useContext(UserContext);
  const { currentMeasurementLogDoc: measurementLogDoc } = useContext(MeasurementLogContext);

  const {
    isCurrentLoggedInUserInPath: shouldAllowActions,
  } = useCurrentLoggedInUser();

  useEffect(() => {
    const init = async () => {
      if (isComponentMountedRef.current) {
        if (measurementLogDoc && measurementLogDoc.hasData) {
          setInitialValues(measurementLogDoc);
        } else {
          setInitialValues(defaultValues);
        }
      }
    };
    init();
  }, [
    measurementLogDoc,
    isComponentMountedRef,
  ]);

  const handleFormSubmit = useCallback(async (data) => {
    const {
      ...formFields
    } = data;
    const docData = {
      user: userId,
      ...formFields,
      date: moment.utc(date).startOf('day').toDate(),
      updatedAt: new Date(),
    };

    let measurementLogDocument = measurementLogDoc;

    if (!measurementLogDocument) {
      docData.createdAt = new Date();
      measurementLogDocument = await MeasurementLog.addDoc(docData);
      logEvent('measurementLogCreated');
    } else {
      measurementLogDocument.update(docData);
    }
  }, [
    measurementLogDoc,
    userId,
    date,
  ]);

  return (
    <SlideDrawer
      isOpen={isOpen}
      maxHeight={70}
      dragDownThreshold={0.5}
      onClose={closeDrawer}
    >
      <BodyMeasurement
        handleFormSubmit={handleFormSubmit}
        shouldAllowActions={shouldAllowActions}
        initialValues={initialValues}
        date={date}
        isOpen={isOpen}
      />
    </SlideDrawer>
  );
};

BodyMeasurementContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
};

export default compose(
  observer,
  withPortal('measurementSlider'),
)(BodyMeasurementContainer);
