import styled from 'styled-components';
import PropTypes from 'prop-types';
import { buttonStyles } from './styles';
import variants from './variants';

const Link = styled.a`
  ${buttonStyles}
  display: inline-block;

  :hover {
    text-decoration: none;
    color: inherit;
  }
`;

Link.propTypes = {
  variant: PropTypes.oneOf(Object.values(variants)),
  full: PropTypes.bool,
};

Link.defaultProps = {
  variant: variants.PRIMARY,
  full: true,
};

export default Link;
