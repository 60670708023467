import styled from 'styled-components';

import LoadingPage from '../../../components/LoadingPage';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const StyledLoadingPage = styled(LoadingPage)`
  height: 100%;
`;

export {
  Container,
  StyledLoadingPage,
};
