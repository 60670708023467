import styled, { css } from 'styled-components';

import { Heading3 } from '../Typography/Heading';
import { subtitle2 } from '../../styles/text';

import AngleArrow from '../../assets/angle-arrow.svg';

const TITLE_HEIGHT = '50px';
const TRANSITION_TIME = '0.3s';

const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  /* The minimum height has to be equal to the title height on all states (expanded, collapsed) */
  min-height: ${TITLE_HEIGHT};
`;

const Header = styled.div`
  height: ${TITLE_HEIGHT};
  min-height: ${TITLE_HEIGHT};
  background-color: ${({ theme }) => theme.colors.system.gamma6};
  padding: 0 30px;
  cursor: pointer;
  display: flex;
  align-items: center;

  order: ${({ headerAtBottom }) => (headerAtBottom ? 1 : 0)};
  justify-content: ${({ hasTitle }) => (hasTitle ? 'space-between' : 'center')};
`;

const HeaderArrow = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${AngleArrow});
  background-repeat: no-repeat;
  background-position: center;
  transition: all ${TRANSITION_TIME};

  transform: rotate( ${(props) => (props.isOpen ? '180deg' : '0deg')} );
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const Title = styled(Heading3)`
  font-size: 12px;
  margin: 0;
`;

const TitleDescription = styled(Heading3)`
  font-size: 10px;
  margin: 2px 0 0 0;
  display: flex;
  align-items: center;
`;

const SubTitle = styled.div`
  ${subtitle2};
`;

const Collapsible = styled.div`
  height: ${({ collapsibleHeight }) => {
    if (typeof collapsibleHeight === 'number') {
      return css`${collapsibleHeight}px`;
    }
    return collapsibleHeight;
  }};
  overflow: hidden;
  margin: 0;
  display: flex;
  flex-direction: column;
  transition: height ${TRANSITION_TIME};
`;

const Content = styled.div`
  margin: 0;
`;

export {
  PanelContainer,
  Header,
  HeaderArrow,
  Title,
  Collapsible,
  Content,
  SubTitle,
  TitleContainer,
  TitleDescription,
};
