import styled, { css } from 'styled-components';

import { SemiTransparentButton } from '../../Button';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
`;

const RoundButton = styled(SemiTransparentButton)`
  width: 54px;
  height: 54px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  transition: all 0.3s;
`;

const ButtonContainer = styled.div`
  width: 54px;
  height: 54px;
`;

const StyledPlayPauseButton = styled(RoundButton)`
  ${({ isPlaying, theme: { colors } }) => !isPlaying && css`background-color: ${colors.base.secondary};`}
  opacity: ${({ hideButton }) => (hideButton ? 0 : 1)};
  transition: opacity 0.5s;

  &:hover {
    opacity: ${({ hideButton }) => (hideButton ? 0 : 1)};
  }
`;

export {
  Container,
  ButtonContainer,
  StyledPlayPauseButton,
};
