import React, { useContext } from 'react';
import LoadingPage from '../../components/LoadingPage';
import WorkoutContext from './WorkoutContext';

const withWorkoutContextReady = (Component) => (props) => {
  const { isWorkoutReady } = useContext(WorkoutContext);

  return isWorkoutReady ? <Component {...props} /> : <LoadingPage />;
};

export default withWorkoutContextReady;
