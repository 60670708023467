import styled from 'styled-components';
import { IonSpinner } from '@ionic/react';

import ScrollHandler from '../../../components/ScrollHandler';
import WorkoutReminderCard from '../../../components/WorkoutReminderCard';

const ScrollableContainer = styled(ScrollHandler)`
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.system.background};
  position: relative;
`;

const ScrollIntoViewContainer = styled.div`
  width: 100%;
`;

const LoaderWrapper = styled.div`
  height: 45px;
  max-height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Loading = styled(IonSpinner)`
  width: 100%;
`;

const StyledWorkoutReminderCard = styled(WorkoutReminderCard)`
  margin-top: 16px;
`;

export {
  ScrollableContainer,
  ScrollIntoViewContainer,
  LoaderWrapper,
  Loading,
  StyledWorkoutReminderCard,
};
