import React from 'react';
import PropTypes from 'prop-types';

import Label from '../Label';
import {
  TextAreaWrapper,
  StyledTextArea,
} from './styles';

const TextArea = ({
  label,
  name,
  value,
  onChange,
}) => (
  <TextAreaWrapper>
    {!!label && (
      <Label htmlFor={name}>
        {label}
      </Label>
    )}
    <StyledTextArea
      value={value}
      onChange={onChange}
    />
  </TextAreaWrapper>
);

TextArea.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TextArea;
