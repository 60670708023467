import { addAlpha } from '../utils/colorUtils';

const generateShadesColors = (baseColors, fixedShades = {}) => {
  const calculatedShades = {
    primary80: addAlpha(baseColors.primary, 0.8),
    primary60: addAlpha(baseColors.primary, 0.6),
    primary40: addAlpha(baseColors.primary, 0.4),
    primary20: addAlpha(baseColors.primary, 0.2),
    primary10: addAlpha(baseColors.primary, 0.1),
    primary05: addAlpha(baseColors.primary, 0.05),
    secondary40: addAlpha(baseColors.secondary, 0.4),
    secondary20: addAlpha(baseColors.secondary, 0.2),
  };

  return {
    ...calculatedShades,
    // Apply the fixed shades
    ...fixedShades,
  };
};

/**
 * Colors that are part of the main color palette should be defined in "base".
 * If we have variations of the base colors, ligther or darker versions, those should
 * go under "shades".
 */
const base = {
  // main brand colors
  primary: '#14222F', // Dark Blue
  secondary: '#F9D74D', // Yellow
  secondaryVariant: '#EABE5D', // Yellow variation, it should be #EABE5D
  tertiary: '#C7D6E8', // Sky
  tertiaryVariant: '#DDE6F1', // Sky variation
};

// Generate default light theme shades
const shades = generateShadesColors(base, {
  secondary40: '#FDEFB8',
  secondary20: '#FEF7DB',
});

const system = {
  background: '#EDEEE9', // Grey
  success: '#99ED64', // Green
  success2: '#4DB1C1', // light blue/cyan - for success message
  highlight: '#3A7CFD', // Blue
  error: '#FF5A5F', // Red
  error2: '#FA7E7E', // Light Red
  /*
    NOTE: For now, "alpha" and "beta" matches to "surface" and "text" in
    light color palette. Gradually review the usage in order to identify
    cases in which there's a need of migration.
  */
  alpha: '#FFFFFF', // White
  beta: '#000000', // Black
  /*
    NOTE: This colors needs to be properly migrated to use existent "shades" or define
    new ones. These names belongs to the former implementation, so there's no relationship
    between these colors and the base ones.
  */
  support: '#F4F4F4', // Grey
  support2: '#F9F9F9', // Light grey
  secondary2: '#4E88FD', // blue - white with opacity 0.1
  secondary5: '#DFE0E2', // sky with opacity 0.14
  secondary6: '#EFF7FF', // light blue
  gamma1: '#E2E2E2', // grey - black with opacity 0.1
  gamma2: '#999999', // grey - black with opacity 0.4
  gamma3: '#C7C7C7', // grey border
  gamma4: '#FEFFFF', // grey
  gamma5: '#91999F', // inactive grey
  gamma6: '#EEEEEE', // feedback background
};

const fonts = {
  primary: '#000000',
};

const colors = {
  base,
  shades,
  system,
  fonts,
};

export default colors;
export {
  generateShadesColors,
};
