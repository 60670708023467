import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { todayStartDate, DateFormat } from '../../../../utils/date';
import { getRandom } from '../../../../utils/random';
import { quoteCard } from '../../texts.json';
import CompletedWorkoutsCard from './CompletedWorkoutsCard';

const CompletedWorkoutsCardContainer = ({
  items,
  showQuote,
}) => {
  const {
    workoutDone: {
      description,
    },
  } = quoteCard;

  const [quote] = useState(() => {
    const {
      workoutDone: {
        quotes,
      },
    } = quoteCard;

    // Pick a random quote based on the date (start of day)
    const today = todayStartDate().format(DateFormat.DEFAULT_DATE_FORMAT);
    const selectedIndex = Math.floor(getRandom(today) * quotes.length);

    return quotes[selectedIndex];
  });

  return (
    <CompletedWorkoutsCard
      items={items}
      quote={quote}
      description={description}
      showQuote={showQuote}
    />
  );
};

CompletedWorkoutsCardContainer.propTypes = {
  items: PropTypes.array.isRequired,
  showQuote: PropTypes.bool,
};

CompletedWorkoutsCardContainer.defaultProps = {
  showQuote: true,
};

export default CompletedWorkoutsCardContainer;
