import React from 'react';

import { WorkoutDetailsContextProvider } from '../../../../../../components/WorkoutDetails';
import WorkoutDetails from './WorkoutDetails';
import { Container } from './styles';

const WorkoutDetailsSection = () => (
  <Container>
    <WorkoutDetailsContextProvider enableActivityDetails={false}>
      <WorkoutDetails />
    </WorkoutDetailsContextProvider>
  </Container>
);

export default WorkoutDetailsSection;
