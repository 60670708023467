import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as ListIcon } from '../../../../../../../assets/icons/list-icon.svg';

import {
  StyledActionButton,
  IconContainer,
  Label,
} from './styles';

const ViewWorkoutButton = ({
  onClick,
}) => (
  <StyledActionButton onClick={onClick}>
    <IconContainer><ListIcon /></IconContainer>
    <Label>Workout</Label>
  </StyledActionButton>
);

ViewWorkoutButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ViewWorkoutButton;
