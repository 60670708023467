import styled, { css } from 'styled-components';
import {
  IonModal,
} from '@ionic/react';

import { safePaddings } from '../../../styles/areas';
import { mixins } from '../../../styles/breakpoints';

const StyledIonModal = styled(IonModal)`
  ${safePaddings}

  ${mixins.tabletMedia(css`
    .ion-page {
      padding: 0;
    }
  `)}
`;

export {
  StyledIonModal,
};
