import styled from 'styled-components';

import { fontFamily } from '../../../styles/text';

import {
  Card,
} from '../commonStyles';

const StyledCard = styled(Card)`
  padding: 0 0 16px;
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`;

const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(auto-fit, minmax(0px, 1fr));
  place-items: center;
  width: 100%;
  max-width: 500px;
  max-height: 312px;
  min-height: 312px;

  > * {
    grid-column: 1 / 3;
  }
`;

const Title = styled.div`
  font-family: ${fontFamily.serif};
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  color: ${({ theme }) => theme.colors.base.primary};
  margin-bottom: 16px;
  padding: 0 24px;
`;

const Description = styled.div`
  font-family: ${fontFamily.sansSerif};
  padding: 0 24px;
  font-size: 15px;
  margin-bottom: 16px;
`;

const InfoSection = styled.div`
  font-family: ${fontFamily.sansSerif};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding: 0 24px;
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 8px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
`;

const InfoItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  > svg {
    width: 20px;
    margin-right: 8px;
  }
`;

const InfoItem = styled.div`
  > span {
    font-weight: 700;
  }
`;

export {
  StyledCard,
  ImageWrapper,
  ImageContainer,
  Title,
  Description,
  InfoSection,
  InfoContainer,
  InfoItemWrapper,
  InfoItem,
};
