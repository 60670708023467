import { ChannelBuckets } from '../../util/buckets';

const texts = {
  noChannelsPlaceholder: 'All done here!',
  channelBuckets: {
    [`${ChannelBuckets.ACTIVE}`]: 'Active',
    [`${ChannelBuckets.ARCHIVED}`]: 'Archived',
    [`${ChannelBuckets.ALL}`]: 'All',
  },
};

export default texts;
