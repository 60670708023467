import React from 'react';
import PropTypes from 'prop-types';

import {
  ChipsContainer,
  Label,
  Item,
} from './styles';

const Chips = ({
  label,
  items,
  className,
}) => (
  <ChipsContainer className={className}>
    {!!label && <Label>{label}</Label>}
    {items.map((item) => (
      <Item key={item}>{item}</Item>
    ))}
  </ChipsContainer>
);

Chips.propTypes = {
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
};

Chips.defaultProps = {
  label: '',
  className: '',
};

export default Chips;
