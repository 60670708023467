import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledCard,
  MacroGoals,
} from './styles';

const MacroGoalsCard = ({
  className,
  children,
  ...otherProps
}) => (
  <StyledCard className={className}>
    {children}
    <MacroGoals {...otherProps} />
  </StyledCard>
);

MacroGoalsCard.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

MacroGoalsCard.defaultProps = {
  className: '',
  children: [],
};

export default MacroGoalsCard;
// Export macro goals styled component to allow full customization of the card content
export {
  MacroGoals,
};
