import ActivityRingsTheme from './activityRingsTheme';

/**
 * Custom colors definition for Apple activity rings.
 *
 * @type {Object}
 */
const colors = {
  background: {
    [ActivityRingsTheme.DARK]: 'black',
    [ActivityRingsTheme.LIGHT]: 'transparent',
  },
  activeEnergy: {
    step0: '#E00216',
    step100: '#FF3187',
    background: 'rgb(224, 2, 22, 0.23)',
  },
  exercise: {
    step0: '#39DC00',
    step100: '#BAFF00',
    background: 'rgb(57, 220, 0, 0.23)',
  },
  standHours: {
    step0: '#00BAE1',
    step100: '#0EFCCF',
    background: 'rgb(0, 186, 225, 0.23)',
  },
};

export default colors;
