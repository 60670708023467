import {
  Filesystem,
  Directory,
} from '@capacitor/filesystem';

const LocalStorageKey = {
  WEB_APP_ICON_NAME: 'appCustomizationWebAppIconName',
};

/**
 * Local on-device file system paths used for app customizations.
 *
 * @type {Object}
 */
const DeviceFileSystemPath = {
  BASE: 'customizations',
};

DeviceFileSystemPath.WEB_APP_ICON = `${DeviceFileSystemPath.BASE}/webAppIcon`;

/**
 * Checks if the given file path exists within the file system.
 *
 * @param {string} path The path to the file to check if it exists.
 * @returns {Boolean} True when the file exists, otherwise false.
 */
const fileExists = async (path, directory = Directory.Data) => {
  try {
    await Filesystem.stat({
      path,
      directory,
    });
    return true;
  } catch (error) {
    return false;
  }
};

export {
  DeviceFileSystemPath,
  LocalStorageKey,
  fileExists,
};
