import styled, { css } from 'styled-components';
import { ReactComponent as DownArrow } from '../../assets/angle-arrow.svg';
import VideoPlayer from '../VideoPlayer';

const PlaylistContainer = styled.div`
  overflow-x: hidden;
  position: relative;
`;

const Playlist = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  left: 0;
  transition: ${({ transitionDuration = 0 }) => `${transitionDuration}ms`};

  ${({ selectedVideo }) => selectedVideo && css`
    left: calc(${selectedVideo} * -100%);
  `}
`;

const StyledVideoPlayer = styled(VideoPlayer)`
  flex: 1 0 100%;
`;

const NextButton = styled.div`
  height: 30px;
  width: 30px;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PreviousButton = styled(NextButton)`
  transform: translateY(-50%) rotate(180deg);
  right: initial;
  left: 10px;
`;

const Arrow = styled(DownArrow)`
  transform: rotate(-90deg);
  margin-right: -2px;
  path {
    fill: white;
  }
`;

export {
  PlaylistContainer,
  Playlist,
  StyledVideoPlayer,
  NextButton,
  PreviousButton,
  Arrow,
};
