const ActivityActionTypes = {
  ADD: 'ADD',
  ADD_CIRCUIT: 'ADD_CIRCUIT',
  EDIT: 'EDIT',
  COPY: 'COPY',
  PASTE: 'PASTE',
  DELETE: 'DELETE',
  MOVE_UP: 'MOVE_UP',
  MOVE_DOWN: 'MOVE_DOWN',
};

export {
  ActivityActionTypes,
};
