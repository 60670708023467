import React, {
  useMemo,
  useState,
  useCallback,
} from 'react';
import { PropTypes } from 'prop-types';
import * as Sentry from '@sentry/browser';

import ContentSwitcherContext from './ContentSwitcherContext';

const ContentSwitcherContextProvider = ({
  children,
  sectionsConfig,
}) => {
  const [currentSection, setCurrentSection] = useState(0);
  const [sectionsData] = useState(() => sectionsConfig.map(({ name }) => ({
    name,
  })));

  const goToSection = useCallback(async (newSectionIndex) => {
    setCurrentSection(newSectionIndex);
  }, []);

  const goToPreviousSection = useCallback(() => {
    const nextSection = currentSection - 1;
    if (nextSection >= 0) {
      goToSection(nextSection);
    }
  }, [
    currentSection,
    goToSection,
  ]);

  const goToNextSection = useCallback(() => {
    const nextSection = currentSection + 1;
    if (nextSection < sectionsData.length) {
      goToSection(nextSection);
    }
  }, [
    currentSection,
    sectionsData,
    goToSection,
  ]);

  const goToSectionByName = useCallback((sectionName) => {
    const sectionIndex = sectionsData.findIndex(({ name }) => name === sectionName);

    if (sectionIndex > -1) {
      goToSection(sectionIndex);
    } else {
      Sentry.captureException(new Error(`${sectionName} not found for the content switcher`));
    }
  }, [
    goToSection,
    sectionsData,
  ]);

  const context = useMemo(() => ({
    sectionsConfig,
    currentSection,
    goToSection,
    goToPreviousSection,
    goToNextSection,
    goToSectionByName,
  }), [
    sectionsConfig,
    currentSection,
    goToSection,
    goToPreviousSection,
    goToNextSection,
    goToSectionByName,
  ]);

  return (
    <ContentSwitcherContext.Provider value={context}>
      {children}
    </ContentSwitcherContext.Provider>
  );
};

ContentSwitcherContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  sectionsConfig: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      Content: PropTypes.elementType.isRequired,
    }),
  ).isRequired,
};

export default ContentSwitcherContextProvider;
