import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Paragraph,
} from '../commonStyles';

const MultiLineTextRegex = /[^\r\n]+/g;

const MealPlanTextSection = ({ content }) => {
  const introParagraphs = useMemo(
    () => content.match(MultiLineTextRegex) || [],
    [content],
  );

  return introParagraphs.map((paragraphMessage) => (
    <Paragraph key={paragraphMessage}>
      { paragraphMessage }
    </Paragraph>
  ));
};

MealPlanTextSection.propTypes = {
  content: PropTypes.string,
};

MealPlanTextSection.defaultProps = {
  customMealPlanIntroMessage: '',
};

export default MealPlanTextSection;
