import React, {
  useState,
  useCallback,
  useContext,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import SlideDrawer from '../../../../components/SlideDrawer';
import HabitLogContext from '../../../../context/HabitLogContext';

import {
  healthStats,
} from '../../utils';
import LogsChart from '../LogsChart';

import Timeline from './Timeline';
import {
  ItemContainer,
  Container,
  IconContainer,
  StatLabel,
  Value,
  InfoLabel,
  SliderContainer,
  ValueContainer,
} from './styles';
import texts from './texts.json';

const Health = () => {
  const [focusedHealthStat, setFocusedHealthStat] = useState(healthStats.waterIntake.name);
  const [inputSliderOpen, setInputSliderOpen] = useState(false);
  const { habitLogs, isLoadingMore } = useContext(HabitLogContext);

  const onHealthItemClickHandle = (stat) => {
    setFocusedHealthStat(stat);
    setInputSliderOpen(true);
  };

  const renderSliderView = useCallback(() => {
    const logs = habitLogs.filter((log) => log[focusedHealthStat]);
    return (
      <>
        <LogsChart
          logs={logs}
          stat={focusedHealthStat}
        />
        <Timeline
          logs={logs}
          stat={focusedHealthStat}
          isLoadingMore={isLoadingMore}
        />
      </>
    );
  }, [
    focusedHealthStat,
    habitLogs,
    isLoadingMore,
  ]);

  const getHealthStat = () => {
    if (habitLogs.length === 0) {
      return <InfoLabel>{texts.noData}</InfoLabel>;
    }

    return Object.keys(healthStats).map((stat) => {
      const { label, icon: StatIcon } = healthStats[stat];

      // if the health stat is not available in any of the documents, should not render specific card
      // since we can not render any historical data
      if (habitLogs.filter((log) => log[stat]).length === 0) {
        return null;
      }

      return (
        <ItemContainer onClick={() => onHealthItemClickHandle(stat)} key={stat}>
          <IconContainer>
            <StatIcon />
          </IconContainer>
          <StatLabel>{label}</StatLabel>
          {habitLogs[0] && habitLogs[0][stat] && (
            <ValueContainer>
              <Value>{habitLogs[0][stat]}</Value>
              {healthStats[stat].units && (
                <Value>
                  {healthStats[stat].units}
                </Value>
              )}
            </ValueContainer>
          )}
        </ItemContainer>
      );
    });
  };

  return (
    <>
      <Container>
        {getHealthStat()}
      </Container>
      <SlideDrawer
        isOpen={inputSliderOpen}
        onClose={() => setInputSliderOpen(false)}
        maxHeight={70}
      >
        <SliderContainer>
          {renderSliderView()}
        </SliderContainer>
      </SlideDrawer>
    </>
  );
};

export default compose(
  observer,
)(Health);
