import {
  decorate, action, computed, observable,
} from 'mobx';
import PoseQuality from '../trackingSystem/pose/poseQuality';

import gameplayActionStatuses from './gameplayActionStatuses';

const DETECTING_TIMEOUT = 50;
const PROCESSING_TIMEOUT = 100;

const DEFAULT_DETECTION_TIME = 500;
const DEFAULT_PROCESSING_TIME = 1500;

class GameplayActionExecutor {
  constructor(id, actionData, actionTracker) {
    /**
     * Action identifier.
     *
     * @type {string}
     */
    this.id = id;

    /**
     * The action data object that contains the action configuration.
     */
    this.actionData = actionData;

    /**
     * Current action state. The action must be always initialized in IDLE state.
     *
     * @type {number}
     */
    this.actionState = gameplayActionStatuses.IDLE;

    /**
     * Previous action state.
     *
     * @type {number|null}
     */
    this.prevActionState = null;

    /**
     * Total detection time value in milliseconds.
     *
     * @type {number}
     */
    this.totalDetectionTime = this.actionData.totalDetectionTime || DEFAULT_DETECTION_TIME;

    /**
     * Total processing time value in milliseconds.
     *
     * @type {number}
     */
    this.totalProcessingTime = this.actionData.totalProcessingTime || DEFAULT_PROCESSING_TIME;

    /**
     * Timestamp value that indicates the time in which the action is being detected.
     *
     * @type {number}
     */
    this.detectionTimeStarted = 0;

    /**
     * Timestamp value that indicates the time in which the action starts being processed.
     *
     * @type {number}
     */
    this.processingTimeStarted = 0;

    /**
     * Current time value while the action is being detected.
     *
     * @type {number}
     */
    this.currentDetectionTime = 0;

    /**
     * Current time value while the action is being processed.
     *
     * @type {number}
     */
    this.currentProcessingTime = 0;

    /**
     *
     * @type {Object}
     */
    this.actionTracker = actionTracker;
  }

  processAction = (predictionModels, processingArea) => {
    if (this.isActionProcessed) {
      return {
        id: this.id,
        stepResult: null,
        predictionModelResult: null,
      };
    }

    const predictionModelResult = this.actionTracker.bestPrediction(predictionModels);
    const stepResult = this.actionTracker.analyzeStep(predictionModelResult, processingArea);

    if (stepResult) {
      const { qualityProcessed } = stepResult;

      if (qualityProcessed >= PoseQuality.Good) {
        // Start detecting
        if (this.actionState === gameplayActionStatuses.IDLE) {
          this.start();
        }
      } else if (qualityProcessed < PoseQuality.Good) {
        switch (this.actionState) {
          case gameplayActionStatuses.DETECTING:
            this.stopDetectionTimer();
            break;
          case gameplayActionStatuses.PROCESSING:
            this.stopProcessingTimer();
            break;
          default:
        }
      }
    }

    return {
      id: this.id,
      stepResult,
      predictionModelResult,
    };
  }

  /**
   * Runs the detection timer. Once the gameplay action is detected, it transitions to the
   * DETECTING state in which a timer of x time of duration starts to make sure the action
   * remains detected in that period of time. Once the required time is reached, the
   * "detected" function is called and the action state transitions to the next state.
   */
  runDetectionTimer = () => {
    // Only run the gameplay counter when the gameplay is in a RUNNING state
    if (this.actionState !== gameplayActionStatuses.DETECTING) {
      return;
    }

    this.currentDetectionTime = Date.now() - this.detectionTimeStarted;

    const requiredTimeReached = this.currentDetectionTime >= this.totalDetectionTime;

    if (requiredTimeReached) {
      clearTimeout(this.detectionTimerId);
      this.detected();
    } else {
      this.detectionTimerId = setTimeout(() => this.runDetectionTimer(), DETECTING_TIMEOUT);
    }
  }

  /**
   * Runs the processing timer. Once the action is confirmed to be detected, the executor transitions
   * to the PROCESSING state in which a timer of x time of duration starts to make sure that the action
   * last for the given period of time. Once the required time is reached, the "processed" function is called
   * and the action state transitions to the next state: PROCESSED.
   */
  runProcessingTimer = () => {
    if (this.actionState !== gameplayActionStatuses.PROCESSING) {
      return;
    }

    this.currentProcessingTime = Date.now() - this.processingTimeStarted;

    const requiredTimeReached = this.currentProcessingTime >= this.totalProcessingTime;

    if (requiredTimeReached) {
      clearTimeout(this.processingTimerId);
      this.processed();
    } else {
      this.processingTimerId = setTimeout(() => this.runProcessingTimer(), PROCESSING_TIMEOUT);
    }
  }

  /**
   * Stops the detection timer execution, cleanup the internal instance state and transition to the IDLE state.
   * This must be called only when the action being detected has not been detected anymore for the
   * period of time specified in the action configuration.
   */
  stopDetectionTimer = () => {
    if (this.actionState === gameplayActionStatuses.DETECTING) {
      clearTimeout(this.detectionTimerId);

      this.currentDetectionTime = 0;
      this.detectionTimeStarted = 0;

      this.transitionToState(gameplayActionStatuses.IDLE);
    }
  }

  /**
   * Stops the processing timer execution, cleanup the internal instance state and transition to the IDLE state.
   * This must be called only when the action being processed has not been proplery detected anymore for the
   * period of time specified in the action configuration.
   */
  stopProcessingTimer = () => {
    if (this.actionState === gameplayActionStatuses.PROCESSING) {
      clearTimeout(this.processingTimerId);

      this.currentProcessingTime = 0;
      this.processingTimeStarted = 0;

      this.transitionToState(gameplayActionStatuses.IDLE);
    }
  }

  /**
   * Starts the action detection and processing workflow. It transitions the state to DETECTING.
   * This must be called as soon as the action has been properly detected.
   */
  start = () => {
    if (this.actionState === gameplayActionStatuses.IDLE) {
      this.detectionTimeStarted = Date.now();
      this.transitionToState(gameplayActionStatuses.DETECTING);

      this.runDetectionTimer();
    }
  }

  /**
   * Transition from DETECTING state to PROCESSING. This must be called to indicate that the detection
   * process has successfully finished and the next process should start: processing the action for the
   * configured period of time.
   */
  detected = async () => {
    if (this.actionState === gameplayActionStatuses.DETECTING) {
      this.clearTimers();
      this.processingTimeStarted = Date.now();
      this.transitionToState(gameplayActionStatuses.PROCESSING);

      this.runProcessingTimer();
    }
  }

  /**
   * Transition from PROCESSING state to PROCESSED, indicating that the action has been properly detected and
   * processed following the given times in the activity configuration.
   * At this point, it is safe for the callers to perform any operation associated to the action processed.
   */
  processed = async () => {
    if (this.actionState === gameplayActionStatuses.PROCESSING) {
      this.transitionToState(gameplayActionStatuses.PROCESSED);

      // Cleanup current values state
      this.resetValues();
    }
  }

  /**
   * Restarts the gameplay action executor and set it to the initial state: IDLE.
   * This must be called when the caller has performed some action after the it was detected and processed
   * and this executor should be prepared and ready to handle the action detection again, i.e.
   *
   * NOTE: to support a wide variety of scenarios, this function can be called regardless the current
   * action state.
   */
  restart = () => {
    // Cleanup current values state
    this.clearTimers();
    this.resetValues();

    this.transitionToState(gameplayActionStatuses.IDLE);
  }

  /**
   * Clear all the timers. This function must be called on "componentWillUnmount".
   * Do all the cleanup needed to prevent updating the state when the caller component is unmounted.
   */
  reset = () => {
    this.clearTimers();
  }

  /**
   * Cleanup current state values.
   */
  resetValues = () => {
    this.detectionTimeStarted = 0;
    this.processingTimeStarted = 0;
    this.currentDetectionTime = 0;
    this.currentProcessingTime = 0;
  }

  /**
   * Transitions the current action executor state to the specified. It saves the previous state.
   *
   * @param {number} newState Next state to transition to.
   */
  transitionToState = (newState) => {
    this.prevActionState = this.actionState;
    this.actionState = newState;
  }

  /**
   * Clear all of the timers if any.
   */
  clearTimers = () => {
    clearTimeout(this.detectionTimerId);
    clearTimeout(this.processingTimerId);
  }

  get isDetectingAction() {
    return this.actionState === gameplayActionStatuses.DETECTING;
  }

  get isProcessingAction() {
    return this.actionState === gameplayActionStatuses.PROCESSING;
  }

  get isActionProcessed() {
    return this.actionState === gameplayActionStatuses.PROCESSED;
  }

  get isActionTracked() {
    return this.isDetectingAction
      || this.isProcessingAction
      || this.isActionProcessed;
  }
}

decorate(GameplayActionExecutor, {
  actionState: observable,
  prevActionState: observable,
  isDetectingAction: computed,
  isProcessingAction: computed,
  isActionProcessed: computed,
  isActionTracked: computed,
  start: action,
  detected: action,
  processed: action,
  restart: action,
  reset: action,
  stopDetectionTimer: action,
  stopProcessingTimer: action,
  transitionToState: action,
});

export default GameplayActionExecutor;
