import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  email: Yup.string().trim()
    .email('Email is invalid')
    .required('Email is required'),
  password: Yup.string()
    .required('Password is required'),
});

const initialValues = {
  email: '',
  password: '',
};

export { validationSchema, initialValues };
