import styled from 'styled-components';

const IconContainer = styled.div`
  display: flex;
  width: 24px;
  height: auto;
`;

export {
  IconContainer,
};
