import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { Collection } from 'firestorter';
import moment from 'moment';
import { compose } from 'recompose';
import { observer, useAsObservableSource } from 'mobx-react';
import { autorun } from 'mobx';

import logEvent from '../../utils/logger';
import { firestorePaths } from '../../utils/firebasePaths';
import { calculateUTCDay } from '../../utils/date';
import useComponentLoadingTime from '../../hooks/useComponentLoadingTime';
import WorkoutFeedView from '../../models/WorkoutFeedView';
import Streak from '../../models/Streak';
import HealthDataSyncContext from '../HealthDataSyncContext';
import WorkoutFeedViewsContext from './WorkoutFeedViewsContext';
import FetchWorkoutsType from './fetchWorkoutsTypes';

const WORKOUT_FEED_VIEW_LIMIT = 14;

const createWorkoutFeedViewCollection = (userId, intervalLimitMomentDate, options) => {
  const { fetchPast = false, startAfterDoc = null } = options;

  return new Collection(() => WorkoutFeedView.COLLECTION, {
    createDocument: (src, opts) => new WorkoutFeedView(src.id, opts),
    query: (ref) => {
      const query = ref
        .where('user', '==', userId)
        .where('startDate', fetchPast ? '<=' : '>', intervalLimitMomentDate.toDate())
        .orderBy('startDate', fetchPast ? 'desc' : 'asc')
        .limit(WORKOUT_FEED_VIEW_LIMIT);

      if (startAfterDoc) {
        return query.startAfter(startAfterDoc.snapshot);
      }
      return query;
    },
  });
};

const WorkoutFeedViewsContextProvider = ({ children }) => {
  const { params: { userId } } = useRouteMatch();

  const [isReady, setIsReady] = useState(false);
  const [startDate, setStartDate] = useState(() => {
    const { startDate: startDayUTC } = calculateUTCDay(moment());
    return moment(startDayUTC);
  });
  const [endDate, setEndDate] = useState(startDate);
  // First past date from the initial past workouts loaded
  const [firstPastDate, setFirstPastDate] = useState(null);

  /*
    Start date in which the feed view is showing active workouts. Active workouts are past and current
    day's workout not considering the future workouts.
  */
  const [activeStartDate, setActiveStartDate] = useState(() => {
    const { endDate: endDayUTC } = calculateUTCDay(moment());
    return moment(endDayUTC);
  });

  // Workout feed view collections organized in past and future arrays
  const [workoutFeedViewCollections, setWorkoutFeedViewCollections] = useState({
    past: [],
    future: [],
  });

  // Ordered workout feed view documents array
  const [workoutFeedViews, setWorkoutFeedViews] = useState([]);
  const [isLoadingMoreViews, setIsLoadingMoreViews] = useState(() => ({
    [FetchWorkoutsType.PAST]: false,
    [FetchWorkoutsType.FUTURE]: false,
  }));

  // Streak document
  const [streakDoc, setStreakDoc] = useState(() => ({
    data: {
      streaks: null,
    },
  }));
  const [activeStreaks, setActiveStreaks] = useState([]);

  const [hasMoreViews, setHasMoreViews] = useState(() => ({
    [FetchWorkoutsType.PAST]: true,
    [FetchWorkoutsType.FUTURE]: true,
  }));

  const { syncMoreHealthData } = useContext(HealthDataSyncContext);

  const {
    startLoading,
    finishLoading,
  } = useComponentLoadingTime('WorkoutFeedViewsContextProvider');

  useEffect(() => {
    startLoading();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let shouldUpdate = true;

    const init = async () => {
      const pastWorkoutFeedViews = createWorkoutFeedViewCollection(userId, startDate, { fetchPast: true });
      const futureWorkoutFeedViews = createWorkoutFeedViewCollection(userId, startDate, { fetchPast: false });

      const streakDocument = new Streak(`${firestorePaths.STREAK}/${userId}`);

      await Promise.all([
        pastWorkoutFeedViews.fetch(),
        futureWorkoutFeedViews.fetch(),
        streakDocument.init(),
      ]);

      if (shouldUpdate) {
        const newWorkoutFeedViews = {
          past: [pastWorkoutFeedViews],
          future: [futureWorkoutFeedViews],
        };

        setStreakDoc(streakDocument);
        setWorkoutFeedViewCollections(newWorkoutFeedViews);
        setHasMoreViews({
          [FetchWorkoutsType.PAST]: pastWorkoutFeedViews.docs.length === WORKOUT_FEED_VIEW_LIMIT,
          [FetchWorkoutsType.FUTURE]: futureWorkoutFeedViews.docs.length === WORKOUT_FEED_VIEW_LIMIT,
        });

        if (pastWorkoutFeedViews.docs.length > 0) {
          const firstWorkoutFeedViewDoc = pastWorkoutFeedViews.docs[pastWorkoutFeedViews.docs.length - 1];

          const newStartDate = moment(firstWorkoutFeedViewDoc.startDate.toDate());
          setStartDate(newStartDate);
          setFirstPastDate(newStartDate);
        }

        if (futureWorkoutFeedViews.docs.length > 0) {
          const lastWorkoutFeedViewDoc = futureWorkoutFeedViews.docs[futureWorkoutFeedViews.docs.length - 1];

          const newEndDate = moment(lastWorkoutFeedViewDoc.startDate.toDate());
          setEndDate(newEndDate); // Fetching all workouts ordered by startDate
        }

        setIsReady(true);
        finishLoading();
      }
    };

    if (!isReady) {
      init();
    }

    return () => {
      shouldUpdate = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Calculate new active start date (the oldest past date)
    const pastCollection = workoutFeedViewCollections.past[workoutFeedViewCollections.past.length - 1];
    const pastWorkoutFeedViewDoc = pastCollection
      ? pastCollection.docs[pastCollection.docs.length - 1]
      : null;

    if (pastWorkoutFeedViewDoc) {
      const newStartDate = moment(pastWorkoutFeedViewDoc.startDate.toMillis());
      setActiveStartDate(newStartDate);

      if (!firstPastDate) {
        // Only set the first past date when it hasn't been done as part of the context provider initialization
        setFirstPastDate(newStartDate);
      }
    }
  }, [
    workoutFeedViewCollections.past,
    firstPastDate,
  ]);

  // Calculates the latest past startDate in order to fetch and sync more activity summary data
  useEffect(() => {
    if (activeStartDate) {
      logEvent('workoutFeedViewShouldSyncHealthData');

      // Sync more health data
      syncMoreHealthData({ fromDate: activeStartDate });
    } else {
      logEvent('workoutFeedViewNoSyncHealthData');
    }
  }, [
    activeStartDate,
    syncMoreHealthData,
  ]);

  useEffect(() => {
    /*
      Keep as part of WorkoutFeedViewContext all streaks already sorted.
      The reason to have them all rather than filtered by date ranges is to prevent a glitch in the feed scrolling
      when fetching past workouts and fetching streaks.
    */
    if (streakDoc.data.streaks) {
      setActiveStreaks(streakDoc.streaks);
    }
  }, [
    streakDoc,
    streakDoc.data.streaks, // This is intentionally added, so when streaks Map changes, this useEffect is called
  ]);

  const loadMore = useCallback(async (loadMoreType) => {
    if (isLoadingMoreViews[FetchWorkoutsType.PAST]
      || isLoadingMoreViews[FetchWorkoutsType.FUTURE]
      || !hasMoreViews[loadMoreType]) {
      return;
    }

    setIsLoadingMoreViews({
      ...isLoadingMoreViews,
      [loadMoreType]: true,
    });

    const fetchPast = loadMoreType === FetchWorkoutsType.PAST;
    const newIntervalLimitDate = fetchPast ? startDate : endDate;

    // Get the latest document to use in the cursor query
    const lastCollection = fetchPast
      ? workoutFeedViewCollections.past[workoutFeedViewCollections.past.length - 1]
      : workoutFeedViewCollections.future[workoutFeedViewCollections.future.length - 1];

    const startAfterDoc = lastCollection.docs[lastCollection.docs.length - 1];

    const newWorkoutFeedViews = createWorkoutFeedViewCollection(userId, newIntervalLimitDate, {
      fetchPast,
      startAfterDoc,
    });
    await newWorkoutFeedViews.fetch();

    if (loadMoreType === FetchWorkoutsType.PAST) {
      setWorkoutFeedViewCollections({
        past: [...workoutFeedViewCollections.past, newWorkoutFeedViews],
        future: workoutFeedViewCollections.future,
      });
      setStartDate(newIntervalLimitDate);
    } else {
      setWorkoutFeedViewCollections({
        past: workoutFeedViewCollections.past,
        future: [...workoutFeedViewCollections.future, newWorkoutFeedViews],
      });
      setEndDate(newIntervalLimitDate);
    }

    setHasMoreViews({
      ...hasMoreViews,
      [loadMoreType]: newWorkoutFeedViews.docs.length === WORKOUT_FEED_VIEW_LIMIT,
    });

    setIsLoadingMoreViews({
      ...isLoadingMoreViews,
      [loadMoreType]: false,
    });
  }, [
    hasMoreViews,
    isLoadingMoreViews,
    workoutFeedViewCollections.past,
    workoutFeedViewCollections.future,
    startDate,
    endDate,
    userId,
  ]);

  /*
    Make the workoutFeedViewCollections a mobx observable object, in order to use it in the below
    useEffect and autorun, to create a new workoutFeedViews and keep the observable nature.
  */
  const observableWorkoutFeedViews = useAsObservableSource(workoutFeedViewCollections);

  useEffect(() => {
    const workoutFeedViewDisposer = autorun(() => {
      let views = [];
      observableWorkoutFeedViews.past.forEach((col) => {
        views = [
          ...col.docs.slice().reverse(),
          ...views,
        ];
      });
      observableWorkoutFeedViews.future.forEach((col) => {
        views = [
          ...views,
          ...col.docs,
        ];
      });
      setWorkoutFeedViews(views);
    });

    return workoutFeedViewDisposer;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const context = useMemo(() => ({
    isReady,
    workoutFeedViews,
    loadMore,
    isLoadingMoreViews,
    hasMoreViews,
    activeStreaks,
    firstPastDate,
  }), [
    isReady,
    workoutFeedViews,
    isLoadingMoreViews,
    hasMoreViews,
    loadMore,
    activeStreaks,
    firstPastDate,
  ]);

  return (
    <WorkoutFeedViewsContext.Provider value={context}>
      {children}
    </WorkoutFeedViewsContext.Provider>
  );
};

WorkoutFeedViewsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(WorkoutFeedViewsContextProvider);
