import firebase from 'firebase/app';
import {
  useContext,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import logEvent from '../../../utils/logger';
import RemoteConfigContext from '../../RemoteConfigContext';
import UserContext from '../UserContext';

const UserRemoteLogging = ({
  children,
}) => {
  const { userDoc } = useContext(UserContext);

  const {
    isReady: remoteConfigContextReady,
    remoteLoggingLevel,
    usersForFirebaseRemoteLogging,
    remoteLoggingSampleRate,
  } = useContext(RemoteConfigContext);

  useEffect(() => {
    if (remoteConfigContextReady && usersForFirebaseRemoteLogging.includes(userDoc.id)) {
      firebase.setLogLevel(remoteLoggingLevel);
      firebase.onLog(({ type, level, message }) => {
        logEvent('firebaseLogging', {
          type,
          level,
          message,
        }, remoteLoggingSampleRate);
      });
    }
  }, [
    remoteLoggingSampleRate,
    remoteConfigContextReady,
    usersForFirebaseRemoteLogging,
    remoteLoggingLevel,
    userDoc.id,
  ]);

  return children;
};

UserRemoteLogging.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserRemoteLogging;
