import React, {
  useMemo,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

import CoachInfoContext from '../../context/CoachInfoContext';

import MealDescription from './MealDescription';
import NutritionalInfoCard from './NutritionalInfoCard';
import IngredientsCard from './IngredientsCard';
import InstructionsCard from './InstructionsCard';

import {
  FooterNote,
} from './styles';

import texts from './texts.json';

const MealDetails = ({
  meal,
  recommendedServings,
  showFooterNote,
  allowReport,
}) => {
  const {
    coachInfo: {
      mealPlanConfig: {
        recipeNutritionInfoDisabled,
      } = {},
    } = {},
  } = useContext(CoachInfoContext);

  const {
    recipe: {
      ingredients,
      instructions,
      name,
      description,
      servings: recipeServings,
      cookingTime = 0,
      preparationTime = 0,
      totalCalories: calories,
      carbs,
      fat,
      isScalingDisabled,
      protein,
      proteinPercentage,
      carbsPercentage,
      fatPercentage,
      image = '',
      id: recipeId,
    },
  } = meal;

  // If any ingredient is not scalable, then the whole recipe is not scalable
  // Ingredient can be selected to not be scalable
  const isScalable = useMemo(() => {
    const isRecipeScalable = ingredients.every(({ name: ingredientName, amount }) => !!ingredientName && !!amount);
    if (!isRecipeScalable) {
      /*
        This is something we expect to happen only in dev, were some recipes have been created with a script.
        We need to fix recipes that are not scalable to show the correct values to users.
      */
      Sentry.captureMessage(`Recipe ${recipeId} is not scalable`);
    }
    return !isScalingDisabled && isRecipeScalable;
  }, [
    ingredients,
    recipeId,
    isScalingDisabled,
  ]);

  const mealServings = useMemo(() => {
    if (isScalable) {
      return recommendedServings || recipeServings;
    }
    return recipeServings;
  }, [
    isScalable,
    recommendedServings,
    recipeServings,
  ]);

  const nutritionStats = useMemo(() => {
    const parsedNutrients = {
      carbs: parseInt(carbs * mealServings, 10) || 0,
      fat: parseInt(fat * mealServings, 10) || 0,
      protein: parseInt(protein * mealServings, 10) || 0,
      calories: parseInt(calories * mealServings, 10) || 0,
    };

    return {
      ...parsedNutrients,
      percentFat: fatPercentage,
      percentProtein: proteinPercentage,
      percentCarbs: carbsPercentage,
      caloriesUnit: 'Calories',
    };
  }, [
    calories,
    protein,
    carbs,
    fat,
    proteinPercentage,
    carbsPercentage,
    fatPercentage,
    mealServings,
  ]);

  return (
    <div>
      <MealDescription
        name={name}
        description={description}
        imageSrc={image}
        cookingTime={cookingTime}
        preparationTime={preparationTime}
        ingredientsCount={ingredients.length}
      />
      {!recipeNutritionInfoDisabled && (
        <NutritionalInfoCard
          stats={nutritionStats}
        />
      )}
      <IngredientsCard
        ingredients={ingredients}
        isScalable={isScalable}
        recipeServings={recipeServings}
        recommendedServings={mealServings}
      />
      <InstructionsCard
        instructions={instructions}
        recipeId={recipeId}
        allowReport={allowReport}
      />
      {showFooterNote && (
        <FooterNote>{texts.note}</FooterNote>
      )}
    </div>
  );
};

MealDetails.propTypes = {
  meal: PropTypes.shape({
    recipe: PropTypes.shape({
      ingredients: PropTypes.array.isRequired,
      instructions: PropTypes.array.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      servings: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      totalCalories: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      carbs: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      fat: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      protein: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      cookingTime: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      preparationTime: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      image: PropTypes.string,
      proteinPercentage: PropTypes.number.isRequired,
      carbsPercentage: PropTypes.number.isRequired,
      fatPercentage: PropTypes.number.isRequired,
      id: PropTypes.string.isRequired,
      isScalingDisabled: PropTypes.bool,
    }).isRequired,
  }).isRequired,
  recommendedServings: PropTypes.number,
  showFooterNote: PropTypes.bool,
  allowReport: PropTypes.bool,
};

MealDetails.defaultProps = {
  recommendedServings: 0,
  showFooterNote: false,
  allowReport: false,
};

export default MealDetails;
