import MediaRecorderEncoder from 'opus-media-recorder';
import * as Sentry from '@sentry/browser';
import { toast } from 'react-toastify';

// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved,import/extensions
import EncoderWorker from 'worker-loader!opus-media-recorder/encoderWorker.js';
import { VoiceRecorder } from 'capacitor-voice-recorder';
import { MediaRecorder, MediaRecorderState } from '@fitmoola/system2-chat';

import { isNative } from '../../../utils/platform';
import { dataURItoBlob } from '../../../utils/dataProcessing';

import texts from './texts.json';

/*
  Keys in this object are the possible errors that are generated by the `capacitor-voice-recorder` plugin as stated:
  https://github.com/tchvu3/capacitor-voice-recorder/blob/c386234601c1e2b4cc128f2f3bfc56495d3978da/ios/Plugin/Messages.swift
  https://github.com/tchvu3/capacitor-voice-recorder/blob/c386234601c1e2b4cc128f2f3bfc56495d3978da/android/src/main/java/com/tchvu3/capacitorvoicerecorder/Messages.java
  Except for DEFAULT key which will be used for any other errors generated.
*/
const ErrorMessage = {
  MISSING_PERMISSION: texts.recorderErrorTexts.MISSING_PERMISSION,
  MICROPHONE_BEING_USED: texts.recorderErrorTexts.MICROPHONE_BEING_USED,
  FAILED_TO_RECORD: texts.recorderErrorTexts.FAILED_TO_RECORD,
  FAILED_TO_FETCH_RECORDING: texts.recorderErrorTexts.FAILED_TO_FETCH_RECORDING,
  DEFAULT: texts.recorderErrorTexts.DEFAULT,
};

class NativeMediaRecorder extends MediaRecorder {
  async checkForPermissions() {
    if (this.permissionsGranted) {
      return true;
    }

    try {
      if (isNative) {
        const permission = await VoiceRecorder.hasAudioRecordingPermission();
        this.permissionsGranted = !!permission?.value;
        if (!this.permissionsGranted) {
          const { value } = await VoiceRecorder.requestAudioRecordingPermission();
          this.permissionsGranted = !!value;
        }
        if (!this.permissionsGranted) {
          toast.error(ErrorMessage.MISSING_PERMISSION);
        }
      } else {
        const permissionsGranted = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.permissionsGranted = !!permissionsGranted;
      }
      return this.permissionsGranted;
    } catch (error) {
      toast.error(texts.errorMessage.permissions);
      Sentry.captureException(error, {
        extra: {
          description: texts.errorMessage.permissions,
        },
      });
      return false;
    }
  }

  async startRecording(onDataAvailable) {
    await super.startRecording();
    if (isNative) {
      try {
        await VoiceRecorder.startRecording();
        this.recorder = VoiceRecorder;
      } catch (error) {
        let errorMessage = ErrorMessage.DEFAULT;
        if (error in ErrorMessage) {
          errorMessage = ErrorMessage[error];
        }
        toast.error(errorMessage);
        const description = texts.errorMessage.nativeMediaRecording;
        Sentry.captureException(error, {
          extra: {
            description,
            isNative,
            errorMessage,
          },
        });
      }
    } else {
      const encoderWorkerFactory = () => new EncoderWorker();

      const workerOptions = {
        encoderWorkerFactory,
        OggOpusEncoderWasmPath: `${process.env.PUBLIC_URL}/opus-media-recorder/OggOpusEncoder.wasm`,
        WebMOpusEncoderWasmPath: `${process.env.PUBLIC_URL}/opus-media-recorder/WebMOpusEncoder.wasm`,
      };
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const options = { mimeType: 'audio/wave' };
      const recorder = new MediaRecorderEncoder(stream, options, workerOptions);

      recorder.addEventListener('dataavailable', (audioData) => {
        onDataAvailable(audioData);
      });

      recorder.addEventListener('error', (error) => {
        this.state = MediaRecorderState.ERROR;
        toast.error(texts.errorMessage.default);
        const description = texts.errorMessage.webMediaRecording;
        Sentry.captureException(error, {
          extra: {
            description,
          },
        });
      });
      recorder.start();

      this.recorder = recorder;
    }
  }

  async stopRecording(onDataAvailable) {
    if (isNative) {
      try {
        const audioFile = await this.recorder.stopRecording();
        const blob = dataURItoBlob(`data:audio/aac;base64,${audioFile.value.recordDataBase64}`);
        const audioData = {
          data: blob,
        };
        onDataAvailable(audioData);
      } catch (error) {
        let errorMessage = ErrorMessage.DEFAULT;
        if (error in ErrorMessage) {
          errorMessage = ErrorMessage[error];
        }
        toast.error(errorMessage);
        const description = texts.errorMessage.nativeMediaRecording;
        Sentry.captureException(error, {
          extra: {
            description,
            isNative,
            errorMessage,
          },
        });
      }
    } else {
      this.recorder.stop();
    }
    this.state = MediaRecorderState.INACTIVE;
  }
}

export default NativeMediaRecorder;
