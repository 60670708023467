import styled from 'styled-components';
import { IonSpinner } from '@ionic/react';

import Stepper from '../../components/Stepper';
import Button from '../../components/Button';
import { sutbtitleStyles } from '../../styles/text';

const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const StyledHeader = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  flex-direction: row;
  width: 100%;
  z-index: 99;
  background-color: ${({ theme }) => theme.colors.system.background}
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 0px;
  align-self: center;
  margin: 10px 10px 10px 20px;
`;

const StyledStepper = styled(Stepper)`
  flex: 1;
  margin-right: 10px;
`;

const StyledButton = styled(Button)`
  position: sticky; 
  bottom: 0;
  z-index: 999;
`;

const DateText = styled.div`
  display: flex;
  font-size: 14px;
`;

const Title = styled.div`
  ${sutbtitleStyles}
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  text-align: left;
  margin: 10px 0;
  width: 100%;
`;

const Container = styled.div`
  padding: 16px 24px;
  margin-bottom: 8px;
  background-color: ${({ theme }) => theme.colors.system.alpha};
  color: ${({ theme }) => theme.colors.system.beta};
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
`;

const Spinner = styled(IonSpinner)`
  margin-left: 10px;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.system.background};
  overflow: scroll;
`;

export {
  StyledContainer,
  StyledHeader,
  IconContainer,
  DateContainer,
  StyledStepper,
  DateText,
  StyledButton,
  Title,
  Container,
  Spinner,
  PageContainer,
};
