import styled from 'styled-components';

import { fontFamily } from '../../../styles/text';

import { ReactComponent as CloseButtonIcon } from '../../assets/icon/close-thread-icon.svg';

const MultipleChannelNameContainer = styled.div`
  font-family: ${fontFamily.serif} !important;
  color: ${({ theme }) => theme.colors.system.alpha};
  flex: 1;
  font-weight: bold;
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  padding: 0 15px;
`;

const SingleChannelNameContainer = styled(MultipleChannelNameContainer)`
  padding-left: 50px;
`;

const ChatListBurgerContainer = styled.div`
  position: relative;
  width: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 25px;
`;

const CloseButtonComponent = styled(CloseButtonIcon)`
  height: 25px;
  margin-right: 20px;
`;

const HeaderContainer = styled.div`
  transform: matrix(1, 0, 0, 1, 0, 0);
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  justify-self: flex-start;
  background: ${({ theme }) => theme.colors.base.primary};
  width: 100%;
`;

export {
  MultipleChannelNameContainer,
  SingleChannelNameContainer,
  ChatListBurgerContainer,
  CloseButtonComponent,
  HeaderContainer,
};
