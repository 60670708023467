import React, {
  useContext,
  useEffect,
  useCallback,
  useRef,
  useLayoutEffect,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import smoothscroll from 'smoothscroll-polyfill';

import UserContext from '../../../context/UserContext';
import MealPlanContext from '../../../context/MealPlanContext';
import ChatContext from '../../../chat/context';
import WorkoutFeedViewsContext, {
  withWorkoutFeedViewsContextProvider,
  FetchWorkoutsType,
} from '../../../context/WorkoutFeedViewsContext';
import LoadingContent from '../../../components/LoadingContent';
import { prepareWorkoutFeedViews } from '../viewModel/workoutFeedViewModel';
import WorkoutGroupType from '../viewModel/workoutGroupType';
import WorkoutFeed from './WorkoutFeed';
import {
  ScrollableContainer,
  Loading,
  LoaderWrapper,
} from './styles';

smoothscroll.polyfill();

const WorkoutFeedContainer = ({
  onfinishLoadingEvent,
}) => {
  const {
    isReady: isWorkoutFeedContextReady,
    workoutFeedViews,
    activeStreaks,
    loadMore,
    isLoadingMoreViews,
    hasMoreViews,
    firstPastDate,
  } = useContext(WorkoutFeedViewsContext);
  const {
    userConfigDoc: {
      workoutReminderNotificationTime,
    },
  } = useContext(UserContext);

  const {
    showGreetingMessageIfAny,
    shouldCheckForGreetingMessage,
  } = useContext(ChatContext);

  const {
    hasMealPlan,
  } = useContext(MealPlanContext);

  const containerRef = useRef();
  const activeWorkoutRef = useRef();
  const prevContainerScrollHeightRef = useRef(0);
  const scrollStatusRef = useRef({
    isFirstScroll: true,
    scrolledToActiveWorkout: false,
  });

  const workoutFeed = prepareWorkoutFeedViews(workoutFeedViews, activeStreaks, firstPastDate,
    !hasMoreViews[FetchWorkoutsType.FUTURE]);
  const activeWorkoutFeedViews = workoutFeed[WorkoutGroupType.ACTIVE];
  const futureWorkoutFeedViews = workoutFeed[WorkoutGroupType.FUTURE];

  useEffect(() => {
    /*
      NOTE: if later this component has some "isReady" state, this function has to be executed
      when the internal state is ready instead of being executed in component did mount.
    */
    onfinishLoadingEvent();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /*
    Effect is used for two things:
    - Scroll to active workouts on first load using ref to them
    - Scroll to a past workout that was in the view before older workouts were loaded
    The scroll jumping isn't actually visible on the UI
  */
  useLayoutEffect(() => {
    const scrollContainer = containerRef.current;
    if (scrollContainer) {
      const containerScrollHeight = scrollContainer.scrollHeight;
      const prevScrollHeight = prevContainerScrollHeightRef.current;
      const { isFirstScroll, scrolledToActiveWorkout } = scrollStatusRef.current;

      // On first load need to scroll to active workouts
      if (isFirstScroll && activeWorkoutRef.current && !scrolledToActiveWorkout) {
        scrollContainer.scrollTo({
          top: activeWorkoutRef.current.offsetTop,
          behavior: 'smooth',
          block: 'start',
        });
        scrollStatusRef.current.isFirstScroll = false;
      } else if (scrolledToActiveWorkout && !!prevScrollHeight && prevScrollHeight < containerScrollHeight) {
        /*
          After first load if we uploaded more past workouts and the height of the container changed.
          We scroll down by that additional new height of the container to stay near the same workout
          that was in the view
        */
        const newWorkoutDaysHeight = containerScrollHeight - prevScrollHeight;
        scrollContainer.scrollTop = newWorkoutDaysHeight;
      }

      // Saving container height for future comparison
      prevContainerScrollHeightRef.current = scrollContainer.scrollHeight;
    }
  }, [
    workoutFeed,
  ]);

  const loadMoreWorkouts = useCallback(async (type) => {
    if (isWorkoutFeedContextReady) {
      scrollStatusRef.current.scrolledToActiveWorkout = true;
      if (hasMoreViews[type]) {
        await loadMore(type);
      }
    }
  }, [
    isWorkoutFeedContextReady,
    loadMore,
    hasMoreViews,
  ]);

  const loadMorePastWorkouts = useCallback(() => (
    loadMoreWorkouts(FetchWorkoutsType.PAST)
  ), [
    loadMoreWorkouts,
  ]);

  const loadMoreFutureWorkouts = useCallback(() => (
    loadMoreWorkouts(FetchWorkoutsType.FUTURE)
  ), [
    loadMoreWorkouts,
  ]);

  useEffect(() => {
    if (isWorkoutFeedContextReady && shouldCheckForGreetingMessage) {
      showGreetingMessageIfAny();
    }
  },
  [
    isWorkoutFeedContextReady,
    showGreetingMessageIfAny,
    shouldCheckForGreetingMessage,
  ]);

  if (!isWorkoutFeedContextReady) {
    return <LoadingContent />;
  }

  return (
    <ScrollableContainer
      onScrollToTop={loadMorePastWorkouts}
      onScrollToBottom={loadMoreFutureWorkouts}
      ref={containerRef}
    >
      {hasMoreViews[FetchWorkoutsType.PAST] && (
        <LoaderWrapper>
          {isLoadingMoreViews[FetchWorkoutsType.PAST] && (
            <Loading name="crescent" />
          )}
        </LoaderWrapper>
      )}
      <WorkoutFeed
        ref={activeWorkoutRef}
        activeWorkoutFeedViews={activeWorkoutFeedViews}
        futureWorkoutFeedViews={futureWorkoutFeedViews}
        scrollableContainerRef={containerRef}
        showWorkoutReminderCard={!workoutReminderNotificationTime}
        canUseMealPlan={hasMealPlan}
      />
      {isLoadingMoreViews[FetchWorkoutsType.FUTURE] && (
        <LoaderWrapper>
          <Loading name="crescent" />
        </LoaderWrapper>
      )}
    </ScrollableContainer>
  );
};

WorkoutFeedContainer.propTypes = {
  onfinishLoadingEvent: PropTypes.func,
};

WorkoutFeedContainer.defaultProps = {
  onfinishLoadingEvent: () => { },
};

export default compose(
  withWorkoutFeedViewsContextProvider,
  observer,
)(WorkoutFeedContainer);
