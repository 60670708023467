import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import Label from '../Label';
import {
  SelectWrapper,
  ValueWrapper,
  RequiredInput,
} from './styles';

const Select = ({
  label,
  name,
  value,
  options,
  onChange,
  required,
  className,
  ...otherProps
}) => (
  <SelectWrapper className={className}>
    {!!label && (
      <Label>
        {label}
      </Label>
    )}
    <ValueWrapper>
      {required && (
        <RequiredInput
          required
          value={value || ''}
          onChange={() => {}}
        />
      )}
      <ReactSelect
        className="sideSelector"
        value={value}
        name={name}
        label={name}
        options={options}
        onChange={onChange}
        {...otherProps}
      />
    </ValueWrapper>
  </SelectWrapper>
);

Select.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Select.defaultProps = {
  label: '',
  value: null,
  required: false,
  className: '',
};

export default Select;
