import styled from 'styled-components';

import { headline3 } from '../../styles/text';

const Container = styled.div`
  padding: 24px 18px 10px;
  background: ${({ theme }) => theme.colors.system.alpha};
`;

const Name = styled.div`
  ${headline3}
  text-transform: none;
  color: ${({ theme }) => theme.colors.base.primary};
  margin-bottom: 13px;
`;

export {
  Container,
  Name,
};
