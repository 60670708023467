import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useContext,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { forEach } from 'p-iteration';
import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import UserExerciseOverrides, { UserExerciseOverrideType } from '../../models/UserExerciseOverrides';
import Exercise, {
  ExerciseType,
  calculateExerciseId,
} from '../../models/Exercise';
import { ActivityTypes } from '../../models/BaseActivity';
import useComponentMounted from '../../hooks/useComponentMounted';
import useComponentLoadingTime from '../../hooks/useComponentLoadingTime';
import UserContext from '../UserContext';
import UserExerciseOverridesContext from './UserExerciseOverridesContext';

const UserExerciseOverridesContextProvider = ({
  children,
}) => {
  const [userExerciseOverridesDoc, setUserExerciseOverridesDoc] = useState({
    data: null,
  });
  const exercises = useRef({});
  const allSelectableExercisesRef = useRef(null);
  const [isReady, setIsReady] = useState(false);
  const { startLoading, finishLoading } = useComponentLoadingTime('userExerciseOverridesContext');

  const {
    userId,
    userDoc: {
      assignedCoach,
    },
  } = useContext(UserContext);
  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    // Initializes user exercise overrides document
    const init = async () => {
      startLoading();
      const userExerciseOverridesDocument = await UserExerciseOverrides.getById(userId);

      if (isComponentMountedRef.current) {
        setUserExerciseOverridesDoc(userExerciseOverridesDocument);
        setIsReady(true);
        finishLoading();
      }
    };
    init();
  }, [
    userId,
    isComponentMountedRef,
    startLoading,
    finishLoading,
  ]);

  /**
   * Prepare and return all exercises available for selection as replacement. When the exercises are not cached yet,
   * it fetches, loads them and save them in the cache for reusing it later on multiple calls.
   *
   * @returns {Object} A collection of exercises.
   */
  const calculateExercisesForSelection = useCallback(async () => {
    if (!allSelectableExercisesRef.current) {
      // Fetch list of exercises for override selection
      const baseExercisesCollection = await Exercise.getBaseExercises();
      const editedExercisesCollection = await Exercise.getCoachExercises(assignedCoach, ExerciseType.EDITED);
      const customExercisesCollection = await Exercise.getCoachExercises(assignedCoach, ExerciseType.CUSTOM);

      // Remove all base exercises that were edited by the coach
      const baseExercises = [...baseExercisesCollection.docs];
      editedExercisesCollection.docs.forEach(({ originalExercise }) => {
        const index = baseExercises.findIndex(({ id }) => id === originalExercise);
        if (index !== -1) {
          baseExercises.splice(index, 1);
        }
      });

      // Sort all available exercises by name (base, edited and custom exercises by coach) and keep it in a ref
      const selectableExercises = [
        ...baseExercises,
        ...editedExercisesCollection.docs,
        ...customExercisesCollection.docs,
      ];

      allSelectableExercisesRef.current = selectableExercises.sort(
        (a, b) => ((a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 1),
      );
    }

    return allSelectableExercisesRef.current;
  }, [
    assignedCoach,
  ]);

  /**
   * Fetches and initializes an exercise by ID, then add it to the exercises cache.
   *
   * @returns {Object} The Exercise document.
   */
  const fetchExerciseById = useCallback(async (id) => {
    const exerciseDoc = await Exercise.getById(id);

    if (isComponentMountedRef.current) {
      exercises.current[id] = exerciseDoc;
    }

    return exerciseDoc;
  }, [
    isComponentMountedRef,
  ]);

  /**
   * Gets the exercise document by ID. When the document exists in cache it use that value, otherwise
   * it will try to fetch it and initialize it.
   *
   * @returns {Object} The Exercise document.
   */
  const getExerciseById = useCallback(async (id) => {
    const exerciseDoc = exercises[id];
    return exerciseDoc
      ? exercises[id]
      : fetchExerciseById(id);
  }, [
    fetchExerciseById,
  ]);

  /**
   * Prepares the activities by looking for exercise overrides and apply them.
   *
   * @param {Array} activities
   * @returns {Array} An array of activities with the exercise overrides applied.
   */
  const prepareActivitiesWithOverrides = useCallback(async (activities) => {
    if (!userExerciseOverridesDoc.data) {
      return activities;
    }

    const parsedActivities = cloneDeep(activities);

    const setup = async (rawActivities) => {
      await forEach(rawActivities, async (activity, currentIndex, values) => {
        const {
          type,
        } = activity;

        if (type === ActivityTypes.CIRCUIT) {
          await setup(activity.activities);
        } else if (type !== ActivityTypes.REST) {
          const exerciseOverride = userExerciseOverridesDoc.activeOverrideForExercise({
            id: activity.exerciseId,
            name: activity.name,
          });

          if (exerciseOverride) {
            const {
              overrideExerciseId,
              id: userExerciseOverrideId,
            } = exerciseOverride;

            const exerciseDoc = await getExerciseById(overrideExerciseId);
            const {
              name,
              description,
              id,
              videoPreviewUrl,
              videoPreviewThumbnail,
              videoUrl,
            } = exerciseDoc;

            // Apply the exercise override to the activity defined
            const currentActivity = values[currentIndex];
            currentActivity.originalActivity = {
              ...activity,
            };
            currentActivity.exerciseId = id;
            currentActivity.name = name;
            currentActivity.description = description;
            currentActivity.videoPreviewUrl = videoPreviewUrl;
            currentActivity.videoPreviewThumbnail = videoPreviewThumbnail;
            currentActivity.videoUrl = videoUrl;
            currentActivity.userExerciseOverrideId = userExerciseOverrideId;
          }
        }
      });
    };

    await setup(parsedActivities);

    return parsedActivities;
  }, [
    userExerciseOverridesDoc,
    getExerciseById,
  ]);

  /**
   * Update the active exercise override. If an override exercise is valid, then a new active override will be
   * created. Otherwise, if there's an active override for the given original exercise, it will be deleted,
   * restoring the original exercise.
   *
   * @param {Object} originalExercise
   * @param {string=} originalExercise.id The exercise ID.
   * @param {string} originalExercise.name The exercise name.
   * @param {Object|null} overrideExerciseDoc The exercise document object that replaces the original exercise or null.
   * @param {string=} type The replacement type, by default UserExerciseOverrideType.PERMANENT.
   */
  const updateActiveExerciseOverride = useCallback((
    originalExercise,
    overrideExerciseDoc = null,
    type = UserExerciseOverrideType.PERMANENT,
  ) => {
    const exerciseOverride = userExerciseOverridesDoc.overrideForExercise(originalExercise);

    if (!exerciseOverride && !overrideExerciseDoc) {
      return;
    }

    const updatedExerciseOverride = exerciseOverride ? cloneDeep(exerciseOverride) : {};
    const { active: activeOverride } = updatedExerciseOverride;

    // Check if the exercise to override has an active override. If it does, move it to the history
    if (activeOverride) {
      if (!updatedExerciseOverride.history) {
        updatedExerciseOverride.history = [];
      }
      updatedExerciseOverride.history.push(activeOverride);
    }

    const originalExerciseId = calculateExerciseId(originalExercise);

    /*
      Update the current active field with the new override exercise, if the later does not exists
      remove the active field
    */
    if (overrideExerciseDoc) {
      // Setup the new active exercise override
      updatedExerciseOverride.active = {
        date: moment().toDate(),
        id: `${originalExerciseId}-${uuidv4()}`,
        overrideExerciseId: overrideExerciseDoc.id,
        type,
      };
    } else {
      delete updatedExerciseOverride.active;
    }

    // Save the changes for the exerciseId
    userExerciseOverridesDoc.updateFields({
      [originalExerciseId]: updatedExerciseOverride,
    });
  }, [
    userExerciseOverridesDoc,
  ]);

  const context = useMemo(() => ({
    userExerciseOverridesDoc,
    getExerciseById,
    prepareActivitiesWithOverrides,
    updateActiveExerciseOverride,
    calculateExercisesForSelection,
    isReady,
  }), [
    userExerciseOverridesDoc,
    getExerciseById,
    prepareActivitiesWithOverrides,
    updateActiveExerciseOverride,
    calculateExercisesForSelection,
    isReady,
  ]);

  return (
    <UserExerciseOverridesContext.Provider value={context}>
      {children}
    </UserExerciseOverridesContext.Provider>
  );
};

UserExerciseOverridesContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(UserExerciseOverridesContextProvider);
