import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import * as Sentry from '@sentry/browser';

import { isNative } from '../../../utils/platform';
import { withCameraContext } from '../../../context/CameraContext';
import {
  getHandposeInstance,
  findHands,
} from '../../../services/trackingSystem';
import actionsConfig from '../../../services/gameplayActions/actionsConfig';
import selfieVideoModes from '../modes';
import SelfieCameraStreamRecorder from './recorders/SelfieCameraStreamRecorder';
import DebugPreview from './utils/DebugPreview';
import SelfieWebRTC from './recorders/SelfieMediaRecorder';
import SelfieVideoContext from './SelfieVideoContext';

const SELFIE_RECORDER_ACTIONS_DRAW_PARTS = 3;

class SelfieVideoContextProvider extends PureComponent {
  constructor() {
    super();

    this.state = {
      isReady: false,
      throttling: 0,
      isDebugPlaying: false,
      isFrameProcessingEnabled: true,
    };

    this.debugPreview = null;
    this.takePicTimer = null;
    this.detectionModel = null;
    this.cameraRecorder = isNative
      ? new SelfieCameraStreamRecorder(SELFIE_RECORDER_ACTIONS_DRAW_PARTS)
      : new SelfieWebRTC(SELFIE_RECORDER_ACTIONS_DRAW_PARTS);

    /*
      This flag is used by the takePic method to prevent keep executing when the component was unmounted.
      Even when we clean up the timers in componentWillUnmount, a new timer can be started (takePic) after that.
      Also is used by some timers that can update the component state.
    */
    this.shouldContinue = true;

    // Calculates all available gameplay actions that has action area defined
    this.actionAreas = [];

    Object.keys(actionsConfig).forEach((actionType) => {
      const { boundingBoxConfig } = actionsConfig[actionType] || {};

      if (boundingBoxConfig) {
        this.actionAreas.push(boundingBoxConfig);
      }
    });
  }

  /**
   * Checks if the selfie video mode requires image capturing. Only 2 modes for now requires
   * image capturing: IMAGE_CAPTURE_ONLY and IMAGE_PROCESSING.
   *
   * @returns {boolean} True when the image capturing is required, otherwise false.
   */
  get isImageCaptureRequired() {
    const {
      selfieVideoMode,
    } = this.props;

    return selfieVideoMode === selfieVideoModes.IMAGE_CAPTURE_ONLY
      || selfieVideoMode === selfieVideoModes.IMAGE_PROCESSING;
  }

  componentWillUnmount = () => {
    this.shouldContinue = false;

    this.cameraRecorder.clear();

    // clear timers and animation frames
    this.clearTakePicTimer();
  }

  clearTakePicTimer = () => {
    clearTimeout(this.takePicTimer);
    cancelAnimationFrame(this.takePicTimer);
  }

  enableFrameProcessing = () => {
    const { isFrameProcessingEnabled } = this.state;

    if (!isFrameProcessingEnabled) {
      this.setState({
        isFrameProcessingEnabled: true,
      }, () => {
        this.clearTakePicTimer();

        this.shouldContinue = true;
        this.takePic();
      });
    }
  }

  disableFrameProcessing = () => {
    const { isFrameProcessingEnabled } = this.state;

    if (isFrameProcessingEnabled) {
      this.setState({
        isFrameProcessingEnabled: false,
      }, () => {
        this.shouldContinue = false;
        this.clearTakePicTimer();
      });
    }
  }

  /**
   * Set the previewSelfieRef and setup the width, height properties required later.
   * If the preview element is a Video element, it use "videoHeight" and "videoWidth",
   * otherwise, an Image element is required and it use "naturalHeight" and "naturalWidth".
   * In the case of non video, it attaches a load event handler to update the
   * selfie element when the image is loaded.
   *
   * @param {Object} ref A reference to an HTML Video or Image element.
   */
  setPreviewSelfieRef = (ref) => {
    this.cameraRecorder.setPreviewSelfieRef(ref);
  }

  setDebugSelfieRef = (ref) => {
    if (!this.debugPreview) {
      this.debugPreview = new DebugPreview(ref);
    }
  }

  setIsDebugPlaying = (isDebugPlaying) => this.setState({ isDebugPlaying });

  setThrottling = (throttling) => this.setState({ throttling });

  ready = () => {
    const { isReady } = this.state;

    if (!isReady) {
      this.cameraRecorder.ready(() => {
        this.setState({
          isReady: true,
        });

        // Start capturing images and processing them if enabled
        this.takePic();
      });
    }
  }

  processCapturedImage = async (cameraData, aspectRatio) => {
    if (!this.detectionModel) {
      // Create the detection model instance the first time it needs to be used
      this.detectionModel = await getHandposeInstance();
    }

    await this.cameraRecorder.prepareForImageProcessing(cameraData, aspectRatio);

    const predictionResult = await findHands(this.detectionModel, this.cameraRecorder.selfieElement, aspectRatio);

    return {
      ...predictionResult,
      aspectRatio,
    };
  }

  takePic = async () => {
    const {
      frameProcessed,
      debugMode,
      enableAutoTrottling,
      selfieVideoMode,
      cameraContext,
    } = this.props;

    const {
      isFrameProcessingEnabled,
    } = this.state;

    // Do not process the frame if processing is disabled via props
    if (!isFrameProcessingEnabled) {
      return;
    }

    const startTime = Date.now();

    if (this.isImageCaptureRequired) {
      if (this.cameraRecorder.isReadyToCapture()) {
        try {
          const {
            cameraData,
            hasAspectRatioChanged,
            aspectRatio,
          } = await this.cameraRecorder.captureImage(cameraContext);

          if (hasAspectRatioChanged && debugMode && this.debugPreview) {
            this.debugPreview.updateSize(aspectRatio);
          }

          if (selfieVideoMode === selfieVideoModes.IMAGE_PROCESSING) {
            const detectionResult = await this.processCapturedImage(cameraData, aspectRatio);

            const predictionModelResult = frameProcessed({
              image: cameraData,
              detectionResult,
            });

            if (debugMode) {
              const { gameplayActionsProcessingArea } = this.props;
              this.debugPreview.drawProcessingResult(this.cameraRecorder.selfieElement, predictionModelResult,
                this.actionAreas, gameplayActionsProcessingArea);
            }
          } else {
            frameProcessed({
              image: cameraData,
            });

            if (debugMode) {
              this.debugPreview.drawImage(this.cameraRecorder.selfieElement);
            }
          }
        } catch (err) {
          Sentry.captureException(err);
        }

        this.cameraRecorder.cleanCapturedImage();
      }
    } else {
      // Image capturing is disabled, so just keep working as a gameplay loop
      const { loopProcessed } = this.props;
      loopProcessed();
    }

    if (this.shouldContinue) {
      if (enableAutoTrottling) {
        const cooldown = 450 - (Date.now() - startTime);
        this.takePicTimer = setTimeout(this.takePic, cooldown > 0 ? cooldown : 0);
      } else {
        const { throttling } = this.state;

        if (throttling === 0) {
          cancelAnimationFrame(this.takePicTimer);
          this.takePicTimer = requestAnimationFrame(this.takePic);
        } else {
          this.takePicTimer = setTimeout(this.takePic, throttling);
        }
      }
    }
  }

  render() {
    const {
      children,
      debugMode,
    } = this.props;
    const {
      isReady,
      isDebugPlaying,
      isFrameProcessingEnabled,
      throttling,
    } = this.state;

    const contextValue = {
      isDebugPlaying,
      isReady,
      isFrameProcessingEnabled,
      debugMode,
      throttling,
      previewSelfieRef: this.cameraRecorder.previewSelfieRef,
      setIsDebugPlaying: this.setIsDebugPlaying,
      setPreviewSelfieRef: this.setPreviewSelfieRef,
      setDebugSelfieRef: this.setDebugSelfieRef,
      setThrottling: this.setThrottling,
      ready: this.ready,
      enableFrameProcessing: this.enableFrameProcessing,
      disableFrameProcessing: this.disableFrameProcessing,
    };

    return (
      <SelfieVideoContext.Provider value={contextValue}>
        {children}
      </SelfieVideoContext.Provider>
    );
  }
}

SelfieVideoContextProvider.propTypes = {
  cameraContext: PropTypes.object.isRequired,
  frameProcessed: PropTypes.func.isRequired,
  loopProcessed: PropTypes.func.isRequired,
  selfieVideoMode: PropTypes.oneOf(Object.values(selfieVideoModes)).isRequired,
  enableAutoTrottling: PropTypes.bool,
  debugMode: PropTypes.bool,
  gameplayActionsProcessingArea: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

SelfieVideoContextProvider.defaultProps = {
  enableAutoTrottling: false,
  debugMode: false,
};

export default compose(
  withCameraContext,
  observer,
)(SelfieVideoContextProvider);
