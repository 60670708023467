import React from 'react';
import PropTypes from 'prop-types';

import {
  CardContainer,
} from './styles';
import text from './text.json';

const ErrorCard = ({
  error,
}) => (
  <CardContainer>
    {error || text.defaultErrorMessage}
  </CardContainer>
);

ErrorCard.propTypes = {
  error: PropTypes.string,
};

ErrorCard.defaultProps = {
  error: '',
};

export default ErrorCard;
