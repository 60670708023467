import React, {
  useContext,
  useCallback,
} from 'react';

import { ReactComponent as Calendar } from '../../../../assets/icons/icon-calendar.svg';
import MeasurementLogContext from '../../../../context/MeasurementLogContext';
import BodyMeasurementContainer from '../../BodyMeasurement/BodyMeasurementContainer';

import {
  BodyMeasurementText,
  ItemContainer,
  IconContainer,
  TextContainer,
} from './styles';
import texts from './texts.json';

const BodyMeasurement = () => {
  const {
    isLastMeasurementLogInCurrentWeek,
    currentSelectedDate: date,
    isMeasurementSliderOpen,
    setIsMeasurementSliderOpen,
  } = useContext(MeasurementLogContext);

  const onNewMeasurement = useCallback(() => {
    setIsMeasurementSliderOpen(true);
  }, [
    setIsMeasurementSliderOpen,
  ]);

  return (
    <>
      <ItemContainer
        isCompleted={isLastMeasurementLogInCurrentWeek()}
        onClick={onNewMeasurement}
      >
        <TextContainer>
          <BodyMeasurementText>
            {texts.logMeasurement}
          </BodyMeasurementText>
        </TextContainer>
        <IconContainer>
          <Calendar />
        </IconContainer>
      </ItemContainer>
      <BodyMeasurementContainer
        isOpen={isMeasurementSliderOpen}
        closeDrawer={() => setIsMeasurementSliderOpen(false)}
        date={date}
      />
    </>
  );
};

export default BodyMeasurement;
