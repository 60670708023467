import { firestorePaths } from '../utils/firebasePaths';

import BaseDocument from './BaseDocument';

class CronometerToken extends BaseDocument {
  constructor(id, opts) {
    super(`${firestorePaths.CRONOMETER_TOKEN}/${id}`, opts);
  }

  get active() {
    return !!this.data.active;
  }
}

export default CronometerToken;
