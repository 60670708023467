import styled, { css } from 'styled-components';
import { IonSpinner } from '@ionic/react';

import FormikInput from '../../../components/FormikInput';
import FormikTextarea from '../../../components/FormikTextarea';
import Button from '../../../components/Button';
import { sansSerif } from '../../../styles/text';
import { addAlpha } from '../../../utils/colorUtils';

import { ReactComponent as ArrowDown } from '../../../assets/angle-arrow.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/uploaded-file-delete-icon.svg';
import { ReactComponent as Calendar } from '../../../assets/icons/icon-calendar.svg';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
`;

const FormInputContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.system.gamma};
  flex-basis: 100%;
  overflow-y: auto;
  margin-bottom: 10px;
`;

const Container = styled.div`
  padding: 24px;
  margin-bottom: 8px;
  background-color: ${({ theme }) => theme.colors.system.alpha};
  color: ${({ theme }) => theme.colors.system.beta};
`;

const DateContainer = styled.div`
  padding: 6px;
  background-color: ${({ theme }) => theme.colors.system.alpha};
  color: ${({ theme }) => theme.colors.system.gamma2};
  display: flex;
  justify-content: space-around;
`;

const BodyMeasurementContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const PhotoPickerContainer = styled.div`
  margin-bottom: 8px;
  background-color: ${({ theme }) => theme.colors.system.alpha};
`;

const inputStyles = css`
  padding: 8px 10px 8px 2px;
  border-bottom: 1px solid ${({ theme }) => addAlpha(theme.colors.base.primary, 0.34)};
  transition: border .3s;
  margin: 0;
  background-color: ${({ theme }) => theme.colors.system.alpha};
`;

const focusStyles = css`
  border-bottom: 1px solid ${({ theme }) => theme.colors.base.primary};
  background-color: ${({ theme }) => theme.colors.system.alpha};
`;

const StyledFormikInput = styled(FormikInput)`
  margin: 0 2% 30px 2%;
  display: flex;
  flex-direction: column;
  flex-basis: 46%;
  input {
    ${inputStyles}

    + div {
      position: relative;
    }

    &:focus,
    &:active,
    &:not([value=""]) {
      ${focusStyles}
    }
  }
`;

const StyledFormTextArea = styled(FormikTextarea)`
  width: 100%;

  textarea {
    ${inputStyles}
    width: 100%;

    &:focus,
    &:active,
    &:not(:empty) {
      ${focusStyles}
    }
  }
`;

const FileUploaderContainerCard = styled.div`
  padding: 16px;
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  align-self: flex-end;
  margin-bottom: 10px;
`;

const StyledCalendarIcon = styled(Calendar)`
  align-self: flex-start;
`;

const StyledSpinner = styled(IonSpinner)`
  width: 100%;
  margin: 30px 0;
`;

const IconContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding-left: 8px;
`;

const IconArrow = styled(ArrowDown)`
  transition: all 0.4s;
  transform: rotate(${({ $isExpanded }) => ($isExpanded ? '180deg' : '0deg')});

  > path {
    fill: ${({ theme }) => theme.colors.system.secondary2};
  }
`;

const Title = styled.h1`
  ${sansSerif.headlineFontStyles}
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin: 0;
  width: 100%;
`;

const StyledButton = styled(Button)`
  margin: 0 0 31px;
`;

export {
  Container,
  PhotoPickerContainer,
  FormInputContainer,
  StyledForm,
  StyledFormikInput,
  StyledFormTextArea,
  FileUploaderContainerCard,
  StyledDeleteIcon,
  StyledSpinner,
  IconContainer,
  IconArrow,
  Title,
  BodyMeasurementContainer,
  StyledCalendarIcon,
  DateContainer,
  StyledButton,
};
