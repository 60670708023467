import { useContext, useEffect } from 'react';
import AppContext from '../context/AppContext';
import CameraContext from '../context/CameraContext';

/**
 * Custom hooks that refresh the preview dimensions of the camera
 * when the isPortraitMode context variable changes
 */
const useRefreshCameraPreview = () => {
  const { isPortraitMode } = useContext(AppContext);
  const { camera } = useContext(CameraContext);

  useEffect(() => {
    let shouldUpdate = true;
    const duration = 3000;
    const interval = 50;
    const startTime = new Date();

    const intervalId = setInterval(() => {
      if (new Date() - startTime >= duration) {
        clearInterval(intervalId);
        return;
      }
      if (shouldUpdate) {
        camera.refreshPreview();
      }
    }, interval);

    return () => {
      shouldUpdate = false;
      clearInterval(intervalId);
    };
  }, [camera, isPortraitMode]);
};

export default useRefreshCameraPreview;
