import React from 'react';
import {
  Route,
  Redirect,
  useRouteMatch,
} from 'react-router-dom';
import { IonRouterOutlet } from '@ionic/react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import { WorkoutContextProvider } from '../context/WorkoutContext';
import { UserActivityHistoryContextProvider } from '../context/UserActivityHistoryContext';
import { withUserExerciseOverridesContextReady } from '../context/UserExerciseOverridesContext';

import WorkoutDetails from '../pages/WorkoutDetails';
import GameplayRoutes from './GameplayRoutes';

/**
 * Routes used for workout-related pages.
 *
 * NOTE: the route to the WorkoutDetails goes to  `/details` due to an unexpected behavior
 * in the transition animation when navigating back from the Gameplay to the Workout Details, in the
 * following scenario:
 * - Both workout details page and gameplay are subroutes in a nested router outlet component
 * - The workout details path looks like /a/:id and gameplay path looks like /a/:id/b
 * So, this trick makes the transition between pages to work as expected.
 */
const WorkoutRoutes = () => {
  const {
    path,
    params: {
      userId,
      workoutAssignmentId,
    },
  } = useRouteMatch();

  return (
    <WorkoutContextProvider>
      <UserActivityHistoryContextProvider>
        <IonRouterOutlet ionPage>
          <Route path={`${path}/play`} component={GameplayRoutes} />
          <Route exact path={`${path}/details`} component={WorkoutDetails} />

          <Route exact path={path}>
            <Redirect to={{ pathname: `/u/${userId}/workoutAssignment/${workoutAssignmentId}/details` }} />
          </Route>
        </IonRouterOutlet>
      </UserActivityHistoryContextProvider>
    </WorkoutContextProvider>
  );
};

export default compose(
  withUserExerciseOverridesContextReady,
  observer,
)(WorkoutRoutes);
