import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { getStatsViewModel } from '../../../services/trackedActivity/statsViewModel';
import Stat from '../../../components/Stat';
import HeartRateGraph from '../../../components/HeartRateGraph';

import {
  InfoContainer,
  Container,
  GraphContainer,
} from './styles';

const TrackedActivityStats = ({
  trackedActivity,
}) => {
  const getStats = useCallback(() => {
    const stats = getStatsViewModel(trackedActivity);

    const statTheme = {
      padding: '10px',
      statFontSize: '18px',
      descriptionFontSize: '16px',
      iconWidth: '18px',
    };

    return stats.map((stat) => (
      <Stat
        key={stat.description}
        theme={statTheme}
        {...stat}
      />
    ));
  }, [
    trackedActivity,
  ]);

  const {
    heartRateMeasurements,
  } = trackedActivity;

  return (
    <Container>
      <InfoContainer>
        {getStats()}
      </InfoContainer>
      {heartRateMeasurements.length > 0 && (
        <GraphContainer>
          <HeartRateGraph dataPoints={heartRateMeasurements} />
        </GraphContainer>
      )}
    </Container>
  );
};

TrackedActivityStats.propTypes = {
  trackedActivity: PropTypes.object.isRequired,
};

export default TrackedActivityStats;
