import { Collection } from 'firestorter';

import { firestorePaths } from '../utils/firebasePaths';
import BaseDocument from './BaseDocument';

class UserActivityHistory extends BaseDocument {
  get user() {
    return this.data.user;
  }

  get activityName() {
    return this.data.activityName;
  }

  get history() {
    return this.data.history || [];
  }

  get comment() {
    return this.data.comment || '';
  }

  get latestValue() {
    let comment;
    let date;
    let sets;

    // If we have history stored, check if there is sets information, first.
    if (this.history.length) {
      // Get the sets information of the first item in the history array.
      ([{ sets, comment, date }] = this.history);
    }

    // Return the values of the last set, if provided.
    if (sets && sets.length) {
      return {
        comment,
        date,
        ...sets[sets.length - 1],
      };
    }

    // Return the day value if no sets info was found.
    return this.history[0];
  }

  /**
   * Returns the User Activity History document.
   * @param {string} userId The user id.
   * @param {string} activityName The name of the activity to look for.
   * @returns {Object|null} The document found, or null if no history was found for the combination userId-activityName.
   */
  static async get(userId, activityName) {
    const userActivityHistoryCollection = new Collection(() => firestorePaths.USER_ACTIVITY_HISTORY, {
      query: (ref) => ref.where('user', '==', userId)
        .where('activityName', '==', activityName)
        .orderBy('createdAt', 'desc')
        .limit(1),
    });
    await userActivityHistoryCollection.fetch();

    if (userActivityHistoryCollection.hasDocs) {
      const doc = userActivityHistoryCollection.docs[0];
      const historyDoc = new UserActivityHistory(doc.path);
      await historyDoc.init();
      return historyDoc;
    }
    return null;
  }
}

export default UserActivityHistory;
