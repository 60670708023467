import { Collection } from 'firestorter';

import { firestorePaths } from '../utils/firebasePaths';
import BaseDocument from './BaseDocument';

class Device extends BaseDocument {
  get deviceInfo() {
    return this.data.deviceInfo;
  }

  get fcmToken() {
    return this.data.fcmToken;
  }

  get user() {
    return this.data.userId;
  }

  get isActive() {
    return !!this.data.active;
  }

  activate() {
    return this.updateFields({
      active: true,
    });
  }

  deactivate() {
    return this.updateFields({
      active: false,
    });
  }

  updateLastAccess() {
    return this.updateFields({
      lastAccess: Date.now(),
    });
  }

  /**
   * Returns the device with the corresponding deviceId that belongs
   * to the provided userId passed as param.
   * @param {string} userId The user id
   * @param {string} deviceId The uuid as provided by Capacitor Device plugin
   */
  static async get(userId, deviceId) {
    const userDeviceCollection = new Collection(() => firestorePaths.USER_DEVICE, {
      query: (ref) => ref.where('user', '==', userId)
        .where('uuid', '==', deviceId),
    });
    await userDeviceCollection.fetch();
    if (userDeviceCollection.docs.length > 0) {
      const userDevice = new Device(userDeviceCollection.docs[0].path);
      await userDevice.fetch();
      return userDevice;
    }
    return null;
  }
}

export default Device;
