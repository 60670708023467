import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { CameraStream } from 'capacitor-camera-stream';
import * as Sentry from '@sentry/browser';

import logEvent from '../../utils/logger';
import { PermissionState } from '../../utils/permission';
import { isNative } from '../../utils/platform';
import UserContext from '../UserContext';
import NativeCameraProvider from './providers/NativeCameraProvider';
import BrowserCameraProvider from './providers/BrowserCameraProvider';
import useCameraAspectRatio from './useCameraAspectRatio';
import CameraContext from './CameraContext';

const CameraContextProvider = ({
  children,
}) => {
  const [camera] = useState(() => {
    // Enable NativeCameraProvider for Android and iOS platforms
    const CameraHandler = isNative ? NativeCameraProvider : BrowserCameraProvider;
    return new CameraHandler();
  });

  const {
    userConfigDoc: {
      isAlwaysStartWithCameraOffEnabled,
      updateAlwaysStartWithCameraOffConfig,
    },
  } = useContext(UserContext);
  const [isCameraGranted, setIsCameraGranted] = useState(false);
  const [isCameraEnabled, setIsCameraEnabled] = useState(!isAlwaysStartWithCameraOffEnabled);

  /**
   * Check if the camera permission is granted in the system.
   */
  const isCameraPermissionGranted = useCallback(async () => {
    try {
      const { camera: state } = await CameraStream.checkPermissions();
      return state === PermissionState.GRANTED;
    } catch (error) {
      if (isNative) {
        // Only report this error to Sentry when platform is Native. It is expected to fail in certain Browsers
        Sentry.captureException(error, {
          extra: {
            description: 'Error when calling "CameraStream.checkPermissions" in "isCameraPermissionGranted"',
          },
        });
      }
      logEvent('checkPermissionsFailed', {
        error: JSON.stringify(error),
      });
      /*
        The API fails when it is not supported in the current platform, for example in certain Browsers.
        In those cases, assume the permission is granted, so when the app tries to access the camera
        the permission request dialog will be shown late at that moment.
      */
      return true;
    }
  }, []);

  /**
   * Check if the camera permission is denied in the system.
   */
  const isCameraPermissionDenied = useCallback(async () => {
    try {
      const { camera: state } = await CameraStream.checkPermissions();
      return state === PermissionState.DENIED;
    } catch (error) {
      if (isNative) {
        Sentry.captureException(error, {
          extra: {
            description: 'Error when calling "CameraStream.checkPermissions" in "isCameraPermissionDenied"',
          },
        });
      }
      logEvent('checkPermissionsFailed', {
        error: JSON.stringify(error),
      });
      return false;
    }
  }, []);

  const stopCamera = useCallback(() => {
    camera.stopCamera();
    camera.clear();
  }, [camera]);

  useEffect(() => () => {
    // Stop the camera feed when the component is unmounted.
    stopCamera();
  }, [
    stopCamera,
  ]);

  const cameraAspectRatio = useCameraAspectRatio();

  const disableCamera = useCallback((disablePermanently = false) => {
    updateAlwaysStartWithCameraOffConfig(disablePermanently);
    setIsCameraEnabled(false);
  }, [
    updateAlwaysStartWithCameraOffConfig,
  ]);

  const enableCamera = useCallback(() => {
    setIsCameraEnabled(true);
  }, []);

  const skipCameraPermission = useCallback(() => {
    setIsCameraEnabled(false);
  }, []);

  const context = useMemo(() => ({
    camera,
    isCameraGranted,
    cameraAspectRatio,
    stopCamera,
    isCameraPermissionGranted,
    isCameraPermissionDenied,
    setIsCameraGranted,
    skipCameraPermission,
    isCameraEnabled,
    enableCamera,
    disableCamera,
  }), [
    camera,
    isCameraGranted,
    cameraAspectRatio,
    stopCamera,
    isCameraPermissionGranted,
    isCameraPermissionDenied,
    setIsCameraGranted,
    skipCameraPermission,
    isCameraEnabled,
    enableCamera,
    disableCamera,
  ]);

  return (
    <CameraContext.Provider value={context}>
      {children}
    </CameraContext.Provider>
  );
};

CameraContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(CameraContextProvider);
