import React, { useCallback, useContext, useEffect } from 'react';
import {
  useChannelStateContext,
  useChannelActionContext,
  MessageInput,
  MessageSimple,
  Window,
  MessageList,
} from 'stream-chat-react';
import { logChatPromiseExecution } from 'stream-chat';

import { logPageViewEvent } from '../../../utils/logger';
import MessagingChannelHeader from '../MessagingChannelHeader';
import MessagingInput from '../MessagingInput';
import MessagingThread from '../MessagingThread';
import ChatContext from '../../context';
import { FeedViewContainer } from './styles';

const ChatFeedView = () => {
  const { error, messages } = useChannelStateContext();
  const { onChatError, isMultiChannelView } = useContext(ChatContext);

  if (error) {
    onChatError();
  }

  const {
    sendMessage,
  } = useChannelActionContext();

  const overrideSubmitHandler = useCallback((message) => {
    const sendMessagePromise = sendMessage(message);
    logChatPromiseExecution(sendMessagePromise, 'send message');
  }, [
    sendMessage,
  ]);

  useEffect(() => {
    logPageViewEvent('ChatFeedView');
  }, []);

  return (
    <FeedViewContainer>
      <Window>
        <MessagingChannelHeader />
        <MessageList
          Message={MessageSimple}
          messages={messages.filter((message) => (
            isMultiChannelView || !message.isFeedbackMessage || message.reply_count > 0
          ))}
        />
        <MessageInput
          focus={!isMultiChannelView}
          Input={MessagingInput}
          overrideSubmitHandler={overrideSubmitHandler}
        />
      </Window>
      <MessagingThread />
    </FeedViewContainer>
  );
};

export default ChatFeedView;
