import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

import logEvent from '../../utils/logger';
import { isIOS } from '../../utils/platform';
import AppContext from '../../context/AppContext';
import useComponentMounted from '../../hooks/useComponentMounted';
import VideoPlayerContext from './VideoPlayerContext';
import PlayPauseButton from './PlayPauseButton';
import {
  Container,
  StyledPlayer,
  StyledIonSpinner,
  SpinnerOverlay,
} from './styles';

const VideoPlayer = ({
  url,
  onEnded,
  className,
  showCustomControls,
  autoHideCustomControls,
  showSpinnerOverlay,
  autoPlay,
  enableAutoPause,
  controls,
  onVideoReady,
  onVideoError,
  ...otherPlayerProps
}) => {
  const isComponentMountedRef = useComponentMounted();
  const playerRef = useRef(null);
  const [videoUrl, setVideoUrl] = useState(url);
  const [showVideo, setShowVideo] = useState(true);

  const { isActive: isAppActive } = useContext(AppContext);
  const shouldRestoreVideo = useRef(false);

  const {
    isLoading,
    isPlaying,
    setIsLoading,
    setIsPlaying,
    setPlayer,
  } = useContext(VideoPlayerContext);

  /* setRatio */
  const [ratio] = useState(0);

  // This will keep track of the video, to pause it if it's not visible
  const [inViewRef, inView] = useInView({ threshold: 0.8 });

  /**
   * This useEffect is executed when the app is sent to the background and when it is active again. This is useful for
   * cleaning up the player while in the background. When the app becomes active again, the player is reloaded, so we
   * prevent the video from getting stuck.
   * This is only needed by videos with an autoplay configuration on iOS.
   */
  useEffect(() => {
    if (isComponentMountedRef.current && autoPlay && isIOS()) {
      if (!isAppActive) {
        setShowVideo(false);
        shouldRestoreVideo.current = true;
      } else if (shouldRestoreVideo.current) {
        setIsLoading(true);
        setShowVideo(true);
      }
    }
  }, [
    isComponentMountedRef,
    autoPlay,
    isAppActive,
    setIsLoading,
  ]);

  /**
   * This useEffect is executed when the url provided via props changes. This is useful for reusing the same player.
   */
  useEffect(() => {
    if (isComponentMountedRef.current && url !== videoUrl && !isLoading) {
      setVideoUrl(url);
      setIsLoading(true);
    }
  }, [
    url,
    videoUrl,
    isLoading,
    setIsLoading,
    isComponentMountedRef,
  ]);

  const onReady = useCallback(
    () => {
      if (isComponentMountedRef.current) {
        /*
          If the video url changed before the ready event, we need to load the new url and ignore the current one. This
          is a bug of React Player itself. This is the issue that was filed. It says it is fixed, but it is not.
          https://github.com/CookPete/react-player/issues/413
        */
        if (url !== videoUrl) {
          setVideoUrl(url);
          setIsLoading(true);
        } else {
          setIsLoading(false);
          setPlayer(playerRef.current);
          onVideoReady(playerRef.current);
          setIsPlaying(autoPlay);
        }
      }
    },
    [
      url,
      videoUrl,
      setIsLoading,
      setPlayer,
      autoPlay,
      setIsPlaying,
      isComponentMountedRef,
      onVideoReady,
    ],
  );

  const onPause = useCallback(
    () => {
      if (isComponentMountedRef.current) {
        setIsPlaying(false);
      }
    },
    [
      setIsPlaying,
      isComponentMountedRef,
    ],
  );

  const onPlay = useCallback(() => {
    if (isComponentMountedRef.current) {
      logEvent('playVideoClicked');
      setIsPlaying(true);
    }
  }, [
    setIsPlaying,
    isComponentMountedRef,
  ]);

  const onError = useCallback((event) => {
    onVideoError(event, playerRef.current);
  }, [
    onVideoError,
  ]);

  useEffect(() => {
    if (isComponentMountedRef.current && enableAutoPause && !inView && playerRef) {
      // Pause the video if it's not currently in the viewport.
      setIsPlaying(false);
    }
  }, [
    enableAutoPause,
    playerRef,
    inView,
    setIsPlaying,
    isComponentMountedRef,
  ]);

  const onCustomPlayPauseClick = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <Container
      ratio={ratio}
      className={className}
      ref={inViewRef}
    >
      {isLoading && <StyledIonSpinner name="crescent" />}
      {
        showVideo && (
          <StyledPlayer
            ref={playerRef}
            url={videoUrl}
            controls={!showCustomControls && controls}
            playing={isPlaying}
            onReady={onReady}
            onPause={onPause}
            onPlay={onPlay}
            onError={onError}
            onEnded={onEnded}
            width="100%"
            height="100%"
            config={{
              youtube: {
                playerVars: {
                  origin: window.location.host,
                  controls: 1,
                },
              },
            }}
            {...otherPlayerProps}
          />
        )
      }
      {
        showVideo && showCustomControls && (
          <PlayPauseButton
            isPlaying={isPlaying}
            onClick={onCustomPlayPauseClick}
            autoHide={autoHideCustomControls}
          />
        )
      }
      {showSpinnerOverlay && (
        <>
          <SpinnerOverlay />
          <StyledIonSpinner name="crescent" />
        </>
      )}
    </Container>
  );
};

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  onEnded: PropTypes.func,
  className: PropTypes.string,
  showCustomControls: PropTypes.bool,
  autoHideCustomControls: PropTypes.bool,
  controls: PropTypes.bool,
  autoPlay: PropTypes.bool,
  showSpinnerOverlay: PropTypes.bool,
  enableAutoPause: PropTypes.bool,
  onVideoReady: PropTypes.func,
  onVideoError: PropTypes.func,
};

VideoPlayer.defaultProps = {
  onEnded: () => {},
  className: '',
  showCustomControls: false,
  autoHideCustomControls: false,
  controls: false,
  autoPlay: false,
  showSpinnerOverlay: false,
  enableAutoPause: true,
  onVideoReady: () => {},
  onVideoError: () => {},
};

export default VideoPlayer;
