import moment from 'moment';

import {
  convertToLocalDate,
  todayStartDate,
  isFutureDay,
  isToday,
  convertToLocalStartMomentDate,
} from '../../../utils/date';
import { WorkoutFeedViewType } from '../../../models/WorkoutFeedView';
import WorkoutCard from '../components/WorkoutCard';
import ActiveWorkoutCardContainer from '../components/ActiveWorkoutCard';
import { ProgressStatus } from '../progressStatus';
import AssignmentDayType, { getAssignmentDayType } from './assignmentType';
import WorkoutGroupType, {
  getWorkoutGroupTypeByAssignmentType,
  getWorkoutGroupTypeByDate,
} from './workoutGroupType';
import {
  addToday,
} from './fillerDays';
import WorkoutDaysGroup from './WorkoutDaysGroup';

/**
 * Calculates the progress status for the day. Completion statuses like ProgressStatus.COMPLETED
 * takes precedence over any other status.
 * When at least a single workout feed view has a completion progress status, then the day is considered
 * in a completion status as well.
 *
 * @param {string} dayStatus Day group progress status, one of AssignmentDayType.
 * @param {string} workoutItemStatus Workout view progress status, one of AssignmentDayType.
 * @returns {string} One of AssignmentDayType.
 */
const calculateDayProgressStatus = (dayStatus, workoutItemStatus) => {
  if (dayStatus === ProgressStatus.COMPLETED) {
    return dayStatus;
  }

  if (workoutItemStatus === ProgressStatus.COMPLETED) {
    return workoutItemStatus;
  }

  return workoutItemStatus;
};

/**
 * Calculates the workout progress status for the given day type and workout completion status.
 *
 * @param {string} dayType One of AssignmentDayType.
 * @param {boolean} isCompleted True when the day is completed, otherwise false.
 * @returns {string} One of AssignmentDayType.
 */
const calculateWorkoutProgressStatus = (dayType, isCompleted) => {
  if (dayType === AssignmentDayType.FUTURE) {
    return ProgressStatus.INACTIVE;
  }

  if (dayType === AssignmentDayType.TODAY && !isCompleted) {
    return ProgressStatus.AWAITING;
  }

  return isCompleted ? ProgressStatus.COMPLETED : ProgressStatus.INACTIVE;
};

/**
 * Review and re-calculate all streaks since today to the past days defined, in order to quickly show the final result
 * to the user, until the update workflow that happens in the cloud finishes:
 * - Workouts are updated in Firestore
 * - Cloud functions finishes the workout calendar updates
 * - Cloud function finishes streaks calculation,
 * and the client app gets the latest update.
 *
 * @param {Map} workoutFeedViewGroups Workout feed views groups with all calculated (recovery days and streaks).
 * @param {Object} firstPastDate Moment date instance indicating the very first past date.
 * @param {number=} pastDays Total past days as of today, to recalculate streaks.
 */
const calculateLastStreaks = (workoutFeedViewGroups, firstPastDate) => {
  const activeGroup = workoutFeedViewGroups[WorkoutGroupType.ACTIVE];

  /*
    The firstPastDate is the initial date used for re-calculating streaks, but the workout day
    associated to the firstPastDate won't be modified, since it might need past data that
    the client didn't load yet.
  */
  const initialDate = moment(convertToLocalDate(firstPastDate)).startOf('day');

  let currentDate = moment(initialDate).add(1, 'days').startOf('day');

  while (!isFutureDay(currentDate)) {
    const currentDay = activeGroup.getDay(currentDate);

    if (currentDay) {
      const prevDay = activeGroup.getDay(moment(currentDate).subtract(1, 'days').startOf('day'));
      let nextDay = null;

      if (!isToday(currentDate)) {
        const nextDate = moment(currentDate).add(1, 'days').startOf('day');
        nextDay = activeGroup.getDay(nextDate);
      }

      const hasCompletedItems = currentDay.completedItems.length > 0;
      const hasPrevDayStreak = prevDay && prevDay.streak;
      let shouldRemoveCurrentStreak = !hasCompletedItems;

      if (hasCompletedItems) {
        /*
          The current day has completed items, calculate the streak information.
          For prevDay assume that the streak information is up to date, so it's safe to use it.
        */
        let isFirstStreakDay = true;
        let count = 1;
        let totalCount = 1;

        if (hasPrevDayStreak) {
          count = prevDay.streak.count + 1;
          totalCount = count;
          isFirstStreakDay = false;
        }

        const hasNextDayCompletedItems = nextDay && nextDay.completedItems.length > 0;
        const hasNewCurrentStreak = hasNextDayCompletedItems || count > 1;

        shouldRemoveCurrentStreak = !hasNewCurrentStreak;

        if (hasNewCurrentStreak) {
          if (hasPrevDayStreak) {
            prevDay.streak.totalCount = totalCount;
            prevDay.streak.isFinalStreakDay = false;
          }

          currentDay.streak = {
            count,
            totalCount,
            isFirstStreakDay,
            isFinalStreakDay: !nextDay || !hasNextDayCompletedItems,
          };
        }
      }

      if (shouldRemoveCurrentStreak) {
        /*
          Remove current streak and update prev day streak when:
          1. there's no completed workouts for today or,
          2. Current day has completed workouts but streak count is 1 or next day doesn't have completed workouts
        */
        currentDay.streak = null;
        if (hasPrevDayStreak) {
          prevDay.streak.isFinalStreakDay = true;
        }
      }
    }

    currentDate = moment(currentDate).add(1, 'days').startOf('day');
  }
};

/**
 * Add streaks information in the workout feed view days.
 *
 * @param {Map} workoutFeedViewGroups
 * @param {Array} activeStreaks
 * @param {Object} firstPastDate
 */
const addStreaks = (workoutFeedViewGroups, activeStreaks, firstPastDate) => {
  activeStreaks.forEach((activeStreak) => {
    const { endDate, streak } = activeStreak;
    const localEndMomentDate = convertToLocalDate(endDate);

    for (let i = 0; i < streak; i++) {
      const currentStreak = streak - i;
      const currentDate = moment(localEndMomentDate).subtract(i, 'days').startOf('day');
      const workougGroupType = getWorkoutGroupTypeByDate(currentDate);
      const workoutDayGroup = workoutFeedViewGroups[workougGroupType];
      const workoutDay = workoutDayGroup.getDay(currentDate);

      if (workoutDay) {
        workoutDay.streak = {
          count: currentStreak,
          totalCount: streak,
          isFirstStreakDay: (i === streak - 1),
          isFinalStreakDay: i === 0,
        };
      }
    }
  });

  // Recalculate latest streaks so the client app can show the latest results
  if (firstPastDate) {
    calculateLastStreaks(workoutFeedViewGroups, firstPastDate);
  }

  // Check if yesterday has a streak so today can show keep up the streak
  const today = todayStartDate();
  const yesterday = moment(today).subtract(1, 'days').startOf('day');
  const activeGroup = workoutFeedViewGroups[WorkoutGroupType.ACTIVE];
  const yesterdayWorkoutDay = activeGroup.getDay(yesterday);

  if (yesterdayWorkoutDay
    && yesterdayWorkoutDay.streak
    && yesterdayWorkoutDay.streak.isFinalStreakDay
  ) {
    const todayWorkoutDay = activeGroup.getDay(today);

    if (todayWorkoutDay) {
      if (!todayWorkoutDay.streak) {
        todayWorkoutDay.streak = {};
      }
      todayWorkoutDay.streak.shouldKeepUpStreak = true;
    }
  }
};

/**
 * Creates the workout feed views model. The resulting data structure looks like this:
 *  ```
 *    const result = {
 *      [WorkoutGroupType.ACTIVE]: Map<string, {
 *        assignmentDayType: string,
 *        date: Moment,
 *        progressStatus: string,
 *        items: Array,
 *        trackedActivitySummary?: object,
 *        streak?: {
 *          isFirstStreakDay: Boolean,
 *          isFinalStreakDay: Boolean,
 *          shouldKeepUpStreak: Boolean?,
 *          count: number,
 *        },
 *      }>
 *      [WorkoutGroupType.FUTURE]: new Map(), // same than above
 *    };
 *  ```
 * in which the map key is the date in string format.
 *
 * @param {Array} workoutFeedViews
 * @param {Array} activeStreaks
 * @param {Object} firstPastDate
 * @param {boolean} shouldAddFutureRecoveryDays
 * @returns {Object} The workout feed views model.
 */
const prepareWorkoutFeedViews = (
  workoutFeedViews,
  activeStreaks,
  firstPastDate,
) => {
  const workoutFeedViewGroups = {
    [WorkoutGroupType.ACTIVE]: new WorkoutDaysGroup(),
    [WorkoutGroupType.FUTURE]: new WorkoutDaysGroup(),
  };

  let lastLocalStartMomentDate = null;
  const trackedActivitySummaries = [];

  workoutFeedViews.forEach((currentWorkoutFeedView) => {
    const {
      id,
      startDate,
      isCompleted,
      type,
    } = currentWorkoutFeedView;

    const isTrackedActivitySummary = type === WorkoutFeedViewType.TRACKED_ACTIVITY_SUMMARY;
    if (isTrackedActivitySummary) {
      trackedActivitySummaries.push(currentWorkoutFeedView);
      return;
    }

    /*
      It's important to explicitly consider the startOf day here as it's the date that will be used for activities
      grouping. (This allows activities having specific start/end dates to be grouped with other activities that
      do have the start date being the start of day already)
    */
    const localStartMomentDate = convertToLocalStartMomentDate(startDate.toMillis());

    const assignmentDayType = getAssignmentDayType(localStartMomentDate);
    const workoutGroupType = getWorkoutGroupTypeByAssignmentType(assignmentDayType);

    if (!lastLocalStartMomentDate) {
      lastLocalStartMomentDate = localStartMomentDate;
    }

    // Override isCompleted status for tracked activity summary type
    const isWorkoutCompleted = type !== WorkoutFeedViewType.TRACKED_ACTIVITY_SUMMARY && isCompleted;
    const currentStatus = calculateWorkoutProgressStatus(assignmentDayType, isWorkoutCompleted);

    // Adding a workout day group if it doesn't exists yet, and then add the specific workout information
    const workoutDayGroup = workoutFeedViewGroups[workoutGroupType];

    if (!workoutDayGroup.hasDay(localStartMomentDate)) {
      workoutDayGroup.addDay(localStartMomentDate, assignmentDayType, currentStatus);
    }

    const currentDayInfo = workoutDayGroup.getDay(localStartMomentDate);
    currentDayInfo.progressStatus = calculateDayProgressStatus(currentDayInfo.progressStatus, currentStatus);

    const component = assignmentDayType === AssignmentDayType.TODAY && !isCompleted
      ? ActiveWorkoutCardContainer
      : WorkoutCard;

    const workoutItem = {
      props: {
        id,
        workoutFeedView: currentWorkoutFeedView,
        assignmentDayType,
        progressStatus: currentStatus,
      },
      component,
      isCompleted,
    };

    if (isCompleted) {
      currentDayInfo.completedItems.push(workoutItem);
    } else {
      currentDayInfo.items.push(workoutItem);
    }

    lastLocalStartMomentDate = localStartMomentDate;
  });

  // Adds summaries, if there's already a day with workouts/tracked activities
  trackedActivitySummaries.forEach((trackedActivitySummary) => {
    const {
      startDate,
      workoutStartedDate,
    } = trackedActivitySummary;
    const startDateValue = workoutStartedDate || startDate;
    const localStartMomentDate = convertToLocalStartMomentDate(startDateValue.toMillis());

    const assignmentDayType = getAssignmentDayType(localStartMomentDate);
    const workoutGroupType = getWorkoutGroupTypeByAssignmentType(assignmentDayType);
    const workoutDayGroup = workoutFeedViewGroups[workoutGroupType];
    if (workoutDayGroup.hasDay(localStartMomentDate)) {
      const currentDayInfo = workoutDayGroup.getDay(localStartMomentDate);
      currentDayInfo.trackedActivitySummary = trackedActivitySummary;
    }
  });

  // Adds today
  addToday(workoutFeedViewGroups);

  // Add streaks information
  addStreaks(workoutFeedViewGroups, activeStreaks, firstPastDate);

  const result = {
    [WorkoutGroupType.ACTIVE]: workoutFeedViewGroups[WorkoutGroupType.ACTIVE].daysMap,
    [WorkoutGroupType.FUTURE]: workoutFeedViewGroups[WorkoutGroupType.FUTURE].daysMap,
  };

  return result;
};

export {
  prepareWorkoutFeedViews,
  // only for testing
  calculateLastStreaks,
};
