import React, {
  useContext,
  useEffect,
  useState,

} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import ReactHtmlParser from 'react-html-parser';

import { withLayout, HeaderContext } from '../../components/Layout';
import LoadingPage from '../../components/LoadingPage';
import { withUserContextReady } from '../../context/UserContext';
import AppContext from '../../context/AppContext';
import ChangelogModel from '../../models/Changelog';
import useComponentMounted from '../../hooks/useComponentMounted';
import {
  convertToLocalDate,
  DateFormat,
} from '../../utils/date';
import { removePadding } from '../../utils/text';

import {
  ChangeLogContainer,
  ChangelogWrapper,
  ChangelogTitle,
  ChangelogContent,
  ChangelogReleaseDate,
  ReleaseDateLabel,
} from './styles';
import texts from './texts.json';

const ChangeLog = () => {
  const { setTitle } = useContext(HeaderContext);
  const { appVersion } = useContext(AppContext);
  const isComponentMountedRef = useComponentMounted();
  const [isLoading, setIsLoading] = useState(true);
  const [changelogCollection, setChangelogCollection] = useState([]);

  useEffect(() => {
    setTitle(texts.title);
  }, [setTitle]);

  useEffect(() => {
    const init = async () => {
      const changelog = await ChangelogModel.get(appVersion.appPackageVersion);
      if (isComponentMountedRef.current) {
        setChangelogCollection(changelog);
        setIsLoading(false);
      }
    };
    init();
  }, [
    isComponentMountedRef,
    appVersion]);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <ChangeLogContainer>
      {changelogCollection.docs.map((changelog) => (
        <ChangelogWrapper key={changelog.version}>
          <ChangelogTitle>
            {`Version ${removePadding(changelog.version)}`}
          </ChangelogTitle>
          <ChangelogContent>{ReactHtmlParser(changelog.content)}</ChangelogContent>
          <ChangelogReleaseDate>
            <ReleaseDateLabel>{texts.releaseDateLabel}</ReleaseDateLabel>
            {`: ${convertToLocalDate(changelog.releaseDate).format(DateFormat.DEFAULT_DATE_FORMAT)}`}
          </ChangelogReleaseDate>
        </ChangelogWrapper>
      ))}
    </ChangeLogContainer>
  );
};

export default compose(
  withUserContextReady,
  withLayout,
  observer,
)(ChangeLog);
