import styled, { css } from 'styled-components';
import { FlatCard } from '../../../../components/Card';

import { headlineFontStyles, sansSerif } from '../../../../styles/text';

const TextContent = styled.div`
  color: ${({ theme }) => theme.colors.system.alpha};
  position: absolute;
  bottom: 10px;
  padding: 0 10px;
  width: inherit;
`;

const HeroImageContainer = styled.div`
  width: 100%;
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  aspect-ratio: 4/3;
`;

const Title = styled.div`
  ${headlineFontStyles}
  font-weight: 500;
  font-size: 25px;
  line-height: 138%;
  letter-spacing: -0.5px;
  padding-bottom: 4px;
`;

const Subtitle = styled.div`
  ${sansSerif.headlineFontStyles}
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 137.5%;
`;

const StyledCard = styled(FlatCard)`
  position: relative;
  padding: 0;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  box-shadow: none;
  min-height: 200px;

  ${({ noMargin }) => (
    css`
      margin-bottom: ${noMargin ? 0 : 16}px;
    `
  )}
`;

const Backdrop = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.95), transparent);
`;

export {
  Title,
  Subtitle,
  HeroImageContainer,
  StyledCard,
  TextContent,
  Backdrop,
};
