import {
  useCallback,
} from 'react';
import { checkInPageOrder } from '../pages/CheckIn/utils';

import useUserNavigation from './useUserNavigation';

const CHECK_IN_BASE_PATHNAME = 'check-in';

/**
 * This React Hook allows navigation to meal plan paths.
 */
const useCheckInNavigation = () => {
  const { navigateToUserPath } = useUserNavigation();

  const navigateCheckInPageByPageIndex = useCallback((index) => {
    const pageIndex = (index < checkInPageOrder.length) ? index : (checkInPageOrder.length - 1);
    const pageId = checkInPageOrder[pageIndex];
    const path = `${CHECK_IN_BASE_PATHNAME}/${pageId}`;
    navigateToUserPath(path);
  }, [
    navigateToUserPath,
  ]);

  const navigateCheckInPageByPageId = useCallback((pageId) => {
    const path = `${CHECK_IN_BASE_PATHNAME}/${pageId}`;
    navigateToUserPath(path);
  }, [
    navigateToUserPath,
  ]);

  return {
    navigateCheckInPageByPageIndex,
    navigateCheckInPageByPageId,
  };
};

export default useCheckInNavigation;
export {
  CHECK_IN_BASE_PATHNAME,
};
