import React, { useContext } from 'react';

import CameraContext from './CameraContext';
import CameraContextProvider from './CameraContextProvider';

const withCameraContextProvider = (Component) => (props) => (
  <CameraContextProvider>
    <Component {...props} />
  </CameraContextProvider>
);

const withCameraContext = (Component) => (props) => {
  const cameraContext = useContext(CameraContext);

  return (
    <Component {...props} cameraContext={cameraContext} />
  );
};

export {
  withCameraContextProvider,
  withCameraContext,
};
