import React from 'react';
import {
  Redirect,
  useLocation,
} from 'react-router-dom';

import useHomeNavigation from '../../hooks/useHomeNavigation';
import useAppCustomization from '../../hooks/useAppCustomization';

/**
 * High Order Component (HOC) that renders the given component only when the feature is enabled, otherwise
 * it performs a redirect.
 *
 * @param {Object} featureId Feature ID.
 */
const withFeatureEnabled = (featureId) => (Component) => (
  (props) => {
    const { search, state = {} } = useLocation();
    const { pathname: homePathName } = useHomeNavigation();
    const { isFeatureEnabled } = useAppCustomization();

    if (isFeatureEnabled(featureId)) {
      return <Component {...props} />;
    }

    // Redirecting to home page if the feature is not enabled
    return <Redirect to={{ pathname: homePathName, search, state }} />;
  });

export default withFeatureEnabled;
