import styled, { css } from 'styled-components';

import { ProgressStatus } from '../../progressStatus';

const CONTAINER_STARTING_POINT = '10px';
const STATUS_INDICATOR_TOP_VALUE = '26px';
const COMMON_SPACING = '16px';

const connectorStyles = (progressStatus, theme) => {
  let color;
  let display = 'block';

  const { colors } = theme;

  switch (progressStatus) {
    case ProgressStatus.COMPLETED:
      color = colors.base.secondary;
      break;
    case ProgressStatus.INACTIVE:
      color = colors.system.gamma5;
      break;
    default:
      color = 'unset';
      display = 'none';
      break;
  }

  return css`
    background-color: ${color};
    display: ${display};
  `;
};

const Container = styled.div`
  position: absolute;
  left: ${CONTAINER_STARTING_POINT};
  min-width: 30px;
  z-index: 1;
  height: 100%;
`;

const Connector = styled.div`
  display: block;
  height: 100%;
  width: 2px;
  margin-top: 0;
  margin-left: 50%;
  transform: translateX(-50%);
  ${({ progressStatus, theme }) => connectorStyles(progressStatus, theme)}
`;

const Ribbon = styled.div`
  position: absolute;
  margin-left: -${CONTAINER_STARTING_POINT};
  background-color: ${({ theme }) => theme.colors.base.secondary};
  opacity: 0.42;
  /*
    This value is the result of testing it, and find the best value that makes the Ribbon to
    fill the space until the connector line.
  */
  width: 80%;

  ${({
    enableRibbonStartOffset = false,
    enableRibbonEndOffset = true,
  }) => {
    const top = enableRibbonStartOffset
      ? STATUS_INDICATOR_TOP_VALUE
      : COMMON_SPACING;

    let height = '100%';
    if (enableRibbonStartOffset) {
      height = `calc(100% - ${STATUS_INDICATOR_TOP_VALUE} + ${COMMON_SPACING})`;
    } else if (!enableRibbonEndOffset) {
      height = `calc(100% - ${COMMON_SPACING})`;
    }

    return css`
      top: ${top};
      height: ${height};
    `;
  }}
`;

const StatusCheckmarkContainer = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  z-index: 1;
  /* Value required in order to align the circle with the header text */
  top: ${STATUS_INDICATOR_TOP_VALUE};
`;

const Circle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.system.gamma5};
  background-color: ${({ $backgroundColor = 'transparent' }) => $backgroundColor};
`;

export {
  Container,
  Connector,
  StatusCheckmarkContainer,
  Circle,
  Ribbon,
};
