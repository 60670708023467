import React from 'react';
import PropTypes from 'prop-types';

import { ActivitySideType } from '../../../../../../../utils/activities';
import ActivitySideIndicator from '../ActivitySideIndicator';
import {
  StyledActivityPanel,
  Title,
  Subtitle,
  SubIdentifier,
  SideLabel,
  SideIndicatorContainer,
  ActivityPanelExtraInfo,
} from '../styles';

const RepetitionsActivityPanel = ({
  active,
  value,
  title,
  activitySide,
}) => {
  const showSideLabel = activitySide === ActivitySideType.ALTERNATE_SIDES
    || activitySide === ActivitySideType.LEFT_THEN_RIGHT_SIDE;

  return (
    <StyledActivityPanel active={active}>
      <Title>{title}</Title>
      <ActivityPanelExtraInfo>
        <Subtitle>
          <SubIdentifier>x</SubIdentifier>
          {value}
          {showSideLabel && (
            <SideLabel>/side</SideLabel>
          )}
        </Subtitle>
        {!!activitySide && (
          <SideIndicatorContainer>
            <ActivitySideIndicator activitySide={activitySide} />
          </SideIndicatorContainer>
        )}
      </ActivityPanelExtraInfo>
    </StyledActivityPanel>
  );
};

RepetitionsActivityPanel.propTypes = {
  active: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  activitySide: PropTypes.string,
};

RepetitionsActivityPanel.defaultProps = {
  activitySide: '',
};

export default RepetitionsActivityPanel;
