import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { IonLoading, IonAlert } from '@ionic/react';

import { withLayout } from '../../components/Layout';
import LoadingPage from '../../components/LoadingPage';
import TrackedActivityContext, {
  withTrackedActivityContextProvider,
  withTrackedActivityContextReady,
} from '../../context/TrackedActivityContext';
import FirebaseContext from '../../context/FirebaseContext';
import UserContext from '../../context/UserContext';
import useCurrentLoggedInUser from '../../hooks/useCurrentLoggedInUser';
import useNavContext from '../../hooks/useNavContext';
import useComponentMounted from '../../hooks/useComponentMounted';
import {
  persistTrackedActivityDetail,
  getStoragePath,
} from '../../services/trackedActivity';
import useStorage, { StorageProcessState } from '../../hooks/useStorage';
import TrackedActivityInfo from './TrackedActivityInfo';
import texts from './texts.json';

const TrackedActivityPageContainer = () => {
  const { userId } = useContext(UserContext);
  const { trackedActivity } = useContext(TrackedActivityContext);
  const { isCurrentLoggedInUserInPath } = useCurrentLoggedInUser();
  const { goBack } = useNavContext();
  const {
    getBlobUrls,
    uploadAttachments,
    deleteAttachments,
  } = useStorage();
  const {
    firebase: {
      storage,
      firestore,
    },
  } = useContext(FirebaseContext);
  const isComponentMountedRef = useComponentMounted();
  const [attachmentsDataUrl, setAttachmentsDataUrl] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [isSavingChanges, setIsSavingChanges] = useState(false);
  const [isEditionComplete, setIsEditionComplete] = useState(false);
  const [storageProcessState, setStorageProcessState] = useState(StorageProcessState.INITIAL);

  const onSubmit = async (data) => {
    const {
      title: customTitle,
      comment,
      fileChangesDetails,
    } = data;

    const {
      attachments: existingAttachments,
      id: trackedActivityId,
      activityType,
    } = trackedActivity;

    const trackedActivityDetail = {
      customTitle,
      comment,
      attachments: existingAttachments,
    };

    const filesToAdd = fileChangesDetails.filter((value) => value.action === 'UPLOAD');
    const filesToDelete = fileChangesDetails.filter((value) => value.action === 'DELETE');

    setIsSavingChanges(true);

    let storageTaskFailed = false;

    const storagePath = getStoragePath(userId, trackedActivityId);

    if (filesToAdd && filesToAdd.length !== 0) {
      const result = await uploadAttachments(filesToAdd, storagePath);

      if (result) {
        result.forEach((res) => {
          if (res.state === StorageProcessState.SUCCESS) {
            trackedActivityDetail.attachments.push(res.fileRef);
          } else {
            storageTaskFailed = true;
          }
        });
      }
    }

    if (filesToDelete && filesToDelete.length !== 0) {
      const result = await deleteAttachments(filesToDelete);

      if (result) {
        result.forEach((res) => {
          if (res.state === StorageProcessState.SUCCESS) {
            trackedActivityDetail.attachments = trackedActivityDetail.attachments.filter(
              (value) => value !== res.fileRef,
            );
          } else {
            storageTaskFailed = true;
          }
        });
      }
    }

    await persistTrackedActivityDetail({
      ...trackedActivityDetail,
      activityType,
    }, trackedActivityId, firestore);

    if (isComponentMountedRef.current) {
      if (storageTaskFailed) {
        setStorageProcessState(StorageProcessState.FAILED);
      } else {
        setStorageProcessState(StorageProcessState.SUCCESS);
      }
      setIsSavingChanges(false);
      setIsEditionComplete(true);
    }
  };

  const getMessage = useCallback(() => (
    storageProcessState === StorageProcessState.FAILED
      ? texts.notification.storageFailed
      : texts.notification.editionCompleted
  ), [
    storageProcessState,
  ]);

  useEffect(() => {
    const initializeTrackedActivity = async () => {
      if (trackedActivity.attachments) {
        const attachments = await getBlobUrls(trackedActivity.attachments);
        if (isComponentMountedRef.current) {
          setAttachmentsDataUrl(attachments);
        }
      }
      if (isComponentMountedRef.current) {
        setIsReady(true);
      }
    };
    if (!isReady) {
      initializeTrackedActivity();
    }
  }, [
    trackedActivity,
    isReady,
    storage,
    attachmentsDataUrl,
    isComponentMountedRef,
    getBlobUrls,
  ]);

  if (!isReady) {
    return <LoadingPage />;
  }

  return (
    <>
      <TrackedActivityInfo
        trackedActivity={trackedActivity}
        onSubmit={onSubmit}
        shouldAllowActions={isCurrentLoggedInUserInPath}
        attachmentsDataUrl={attachmentsDataUrl}
      />
      <IonLoading
        isOpen={isSavingChanges}
        message={texts.notification.editionInProgress}
        spinner="crescent"
      />
      <IonAlert
        isOpen={isEditionComplete}
        message={getMessage()}
        backdropDismiss={false}
        buttons={
          [
            {
              text: texts.buttons.agreeButton,
              handler: goBack,
            },
          ]
        }
      />
    </>
  );
};

export default compose(
  withLayout,
  withTrackedActivityContextProvider,
  withTrackedActivityContextReady,
  observer,
)(TrackedActivityPageContainer);
