import {
  decorate,
  action,
  computed,
  observable,
  runInAction,
} from 'mobx';
import * as Sentry from '@sentry/browser';
import format from 'string-template';
import { Network } from '@capacitor/network';

import logEvent from '../../utils/logger';
import { parseTimeInMillis } from '../../utils/time';
import CollectionName from '../../utils/collections';
import ActivityDefinition from '../../models/ActivityDefinition';
import GameplaySession from '../../models/GameplaySession';
import ActivityExecution from '../../models/ActivityExecution';
import { ActivityTypes } from '../../models/BaseActivity';
import WorkoutFeedView from '../../models/WorkoutFeedView';
import User from '../../models/User';
import WorkoutAssignment, { workoutAssignmentStatuses } from '../../models/WorkoutAssignment';
import { firestorePaths, pathPlaceholder } from '../../utils/firebasePaths';
import { shoudUseFirebaseFuncForWorkoutUpdate, shoudUseTransactionForWorkoutUpdate } from '../../utils/featureFlags';
import workoutStatus from './workoutStatus';
import ActivitiesExecutor from './ActivitiesExecutor';

const DEFAULT_OPTIONS = {
  isVideoRequestEnabled: true,
  notifyCoachOnGameplayActions: true,
};

/**
 * Knows how to execute a workout and keeps track of its progression.
 */
class WorkoutExecutor {
  constructor(gameplayStore,
    workoutAssignment,
    activities,
    initialGameplaySessionDoc = null,
    options = DEFAULT_OPTIONS,
    firestore) {
    /**
     * The workout assignment doc
     */
    this.workoutAssignment = workoutAssignment;

    /**
     * Reference to the GameplayStore instance.
     * @type {object}
     */
    this.gameplayStore = gameplayStore;

    /**
     * Optional initial gameplay session doc
     * @type {object}
     */
    this.initialGameplaySessionDoc = initialGameplaySessionDoc;

    /**
     * Current workout state.
     * @type {number}
     */
    this.state = workoutStatus.IDLE;

    /**
     * Custom options to enable/disable certain features like requesting a video creation.
     * @type {{isVideoRequestEnabled: boolean, notifyCoachOnGameplayActions: boolean}}
     */
    this.options = options;

    /**
     * Firestore client instance.
     * @type {object}
     */
    this.firestore = firestore;

    let activitiesToExecute;

    if (this.initialGameplaySessionDoc) {
      activitiesToExecute = this.initialGameplaySessionDoc.activities;
    } else {
      activitiesToExecute = WorkoutExecutor.getExecutionActivities(activities);
    }

    /**
     * The activities executor that knows how to progress from one activity
     * to another depending its type, rounds, etc.
     */
    this.activitiesExecutor = new ActivitiesExecutor(activitiesToExecute);

    /**
     * Workout live progress time. It doesn't include the time in which the activities are paused.
     *
     * @type {number}
     */
    this.workoutProgressTime = 0;
  }

  /**
   * Returns an object with the last activity for a certain activities array
   * and the actual array (or sub-array) that activity actually belongs to.
   * @param {Array} activities An array of execution activities
   * @returns {object} An object with the actual last activity and the array
   *                   it belongs to.
   */
  static getLastActivity(activities) {
    if (activities.length === 0) {
      return null;
    }
    const lastActivity = activities[activities.length - 1];
    if (lastActivity.type === ActivityTypes.CIRCUIT) {
      const lastRound = lastActivity.rounds[lastActivity.rounds.length - 1];
      return WorkoutExecutor.getLastActivity(lastRound.activities);
    }
    return {
      activities,
      lastActivity,
    };
  }

  /**
   * It sanitizes an array of execution activities.
   * (i.e: by removing a REST activity if it happens to be the last one in a
   * workout)
   * @param {Array} executionActivities
   * @returns {Array} An array of sanitized execution activities
   */
  static sanitizeActivitiesForExecution(executionActivities) {
    const {
      activities,
      lastActivity,
    } = WorkoutExecutor.getLastActivity(executionActivities);
    if (lastActivity && lastActivity.type === ActivityTypes.REST) {
      activities.splice(-1);
      return WorkoutExecutor.sanitizeActivitiesForExecution(executionActivities);
    }
    return executionActivities;
  }

  /**
   * Given an array of workout activities, convert the activities to the execution format required.
   * @param {Array} rawWorkoutActivities
   */
  static getExecutionActivities(rawWorkoutActivities) {
    const workoutActivities = rawWorkoutActivities
      .map((rawWorkoutActivity) => new ActivityDefinition(rawWorkoutActivity));
    const activitiesExecutionFormat = GameplaySession.toActivitiesExecutionFormat(workoutActivities);
    const activitiesToExecute = activitiesExecutionFormat
      .map((activityExecutionFormat) => new ActivityExecution(activityExecutionFormat));
    return WorkoutExecutor.sanitizeActivitiesForExecution(activitiesToExecute);
  }

  /**
   * Workout log event function to keep track of key aspects of the workout execution
   * @param {string} eventName The event name
   * @param {object} props Any extra properties
   */
  logWorkoutEvent = (eventName, props) => {
    try {
      const {
        currentExecutableActivity: {
          type: activityType,
          name: activityName,
        },
        workoutAssignment: {
          id: workoutAssignmentId,
          name: workoutAssignmentName,
        },
        gameplayStore: {
          gameplaySessionId,
          activitySession,
        },
        prevState: previousWorkoutState,
        state: workoutState,
        currentActivityId,
        currentActivityCircuits: circuits,
        currentCircuitRound: round,
      } = this;

      logEvent(eventName, {
        workoutContext: {
          workoutAssignment: {
            id: workoutAssignmentId,
            name: workoutAssignmentName,
          },
          previousWorkoutState,
          workoutState,
          currentActivityId,
          activity: {
            type: activityType,
            name: activityName,
          },
          circuits,
          round,
          gameplaySessionId,
          activitySessionId: activitySession ? activitySession.id : null,
        },
        ...props,
      });
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  /**
   * Transitions the current workout state to the specified. It saves
   * the previous state.
   * @param {number} newState Next state to transition to.
   */
  transitionToState = (newState) => {
    runInAction(() => {
      this.prevState = this.state;
      this.state = newState;
    });
    this.logWorkoutEvent('workoutStateTransition');
  }

  toNextActivity = async (remote) => {
    const nextActivity = this.activitiesExecutor.toNextActivity();
    if (!nextActivity) {
      this.logWorkoutEvent('workoutNoMoreNextActivity');
      await this.finish(false, remote);
    }
  }

  initialize = ({
    isStoreWorkoutFramesEnabled,
    isCameraEnabled,
  }) => {
    this.logWorkoutEvent('settingUpWorkout');
    const {
      data: {
        user: userId,
        coach: coachId,
      },
      id: workoutAssignmentId,
    } = this.workoutAssignment;

    let isVideoReviewRequested = false;
    if (this.initialGameplaySessionDoc) {
      isVideoReviewRequested = this.initialGameplaySessionDoc.isVideoReviewRequested;
    }

    this.gameplayStore.setupStore(
      userId,
      coachId,
      workoutAssignmentId,
      this,
      isStoreWorkoutFramesEnabled,
      isCameraEnabled,
      isVideoReviewRequested,
    );
    this.gameplayStore.startNewWorkout();

    if (!isCameraEnabled) {
      this.options.isVideoRequestEnabled = isCameraEnabled;
    }

    this.transitionToState(workoutStatus.INITIALIZED);
    this.logWorkoutEvent('workoutInitialized');
  }

  prepare = () => {
    this.transitionToState(workoutStatus.PREPARED);
    this.logWorkoutEvent('workoutPrepared');
  }

  start = () => {
    this.transitionToState(workoutStatus.RUNNING);
    this.logWorkoutEvent('workoutRunning');
  }

  pause = () => {
    this.transitionToState(workoutStatus.PAUSED);
    this.logWorkoutEvent('workoutPaused');
  }

  resume = () => {
    this.transitionToState(workoutStatus.RUNNING);
    this.logWorkoutEvent('workoutResumed');
  }

  // use firebase function to update the workout assignment status
  updateWorkoutAssignmentStatus = async (remote, force, gameplaySessionDoc, lastUpdatedTimestamp) => remote(
    'onWorkoutComplete',
    {
      workoutAssignmentId: this.workoutAssignment.id,
      workoutAssignmentStatus: force ? workoutAssignmentStatuses.PARTIALLY_COMPLETED
        : workoutAssignmentStatuses.COMPLETED,
      workoutFeedViewStatus: workoutAssignmentStatuses.COMPLETED,
      completedBy: gameplaySessionDoc.ref.path,
      lastUpdatedTimestamp,
    },
  );

  finishWorkout = async (force = false, remote) => {
    const gameplaySessionDoc = await this.gameplayStore.finishWorkout(force);
    if (gameplaySessionDoc.isDone) {
      const currentTime = new Date();
      const {
        user: userId,
        id: workoutAssignmentId,
      } = this.workoutAssignment;
      const userDoc = new User(format(firestorePaths.USER_DOC, {
        [pathPlaceholder.USER_ID]: userId,
      }));
      userDoc.updateFields({
        lastWorkoutCompletedAt: new Date(),
      });
      const workoutFeedView = new WorkoutFeedView(workoutAssignmentId);
      if (shoudUseTransactionForWorkoutUpdate() && (await Network.getStatus()).connected) {
        this.logWorkoutEvent('usingTransactionForWorkoutUpdate');
        // run transaction to update the workout assignment status
        this.firestore.runTransaction(async (transaction) => {
          transaction.update(this.workoutAssignment.ref, {
            status: force ? workoutAssignmentStatuses.PARTIALLY_COMPLETED
              : workoutAssignmentStatuses.COMPLETED,
            completedBy: gameplaySessionDoc.ref.path,
            lastUpdatedTimestamp: currentTime,
          });
          transaction.update(workoutFeedView.ref, {
            status: workoutAssignmentStatuses.COMPLETED,
            lastUpdatedTimestamp: currentTime,
          });
        });
      } else {
        this.workoutAssignment.complete(gameplaySessionDoc, currentTime, force);
        workoutFeedView.updateStatus(workoutAssignmentStatuses.COMPLETED, currentTime);
      }

      // Check if we should use Firebase Functions to update the workout assignment status.
      if (shoudUseFirebaseFuncForWorkoutUpdate()) {
        const networkStatus = await Network.getStatus();
        if (networkStatus.connected) {
          // check and update the workout assignment status using Firebase Function
          this.logWorkoutEvent('usingFirebaseFunctionForWorkoutUpdate');
          this.updateWorkoutAssignmentStatus(remote, force, gameplaySessionDoc, currentTime);
        }
      }
      const {
        originalWorkoutAssignment,
        workoutStartedDate,
      } = this.workoutAssignment;

      if (originalWorkoutAssignment && workoutStartedDate) {
        /*
          We need to add a reference to the origin past workout assignment that was not done in the assigned date,
          to indicate that it was not completed on that assigned date, but it is completed now in this new cloned
          workout assignment.
        */
        const documentPath = `${CollectionName.WORKOUT_ASSIGNMENT}/${originalWorkoutAssignment}`;
        const originWorkoutAssignment = new WorkoutAssignment(documentPath);

        // Only update this if the past origin workout assignment was not completed in any other day before
        if (!originWorkoutAssignment.completedByWorkoutAssignment) {
          originWorkoutAssignment.updateFields({
            completedByWorkoutAssignment: this.workoutAssignment.id,
          });
        }
      }

      this.requestVideoCreation(gameplaySessionDoc);
      if (this.options.notifyCoachOnGameplayActions) {
        this.requestSendCoachWorkoutDoneNotification(gameplaySessionDoc);
      }

      this.logWorkoutEvent('workoutFinished', { isDone: gameplaySessionDoc.isDone });
    }
  };

  finish = async (force, remote) => {
    this.logWorkoutEvent('workoutFinishCalled');
    if (this.state !== workoutStatus.FINISHING) {
      this.logWorkoutEvent('finishingWorkout');
      this.transitionToState(workoutStatus.FINISHING);
      this.finishWorkout(force, remote);
      /*
        We do an early transition to the FINISHED state of the workout executor which
        doesn't necessarily means that the gameplay session document has been finalized
        completely. We do this to make the finish action a non-blocking action.
        (it's expected to be resolved later on in background)
      */
      this.transitionToState(workoutStatus.FINISHED);
    }
  };

  quit = async (options = {}) => {
    this.logWorkoutEvent('workoutQuitCalled');
    if (this.state !== workoutStatus.QUITTING) {
      this.logWorkoutEvent('quittingWorkout');
      this.transitionToState(workoutStatus.QUITTING);
      const gameplaySessionDoc = await this.gameplayStore.quitWorkout();

      this.requestVideoCreation(gameplaySessionDoc);
      if (this.options.notifyCoachOnGameplayActions) {
        this.requestSendCoachWorkoutDoneNotification(gameplaySessionDoc, options);
      }
      this.transitionToState(workoutStatus.QUITTED);
    }
  }

  requestVideoCreation = (gameplaySessionDoc) => {
    if (this.options.isVideoRequestEnabled) {
      gameplaySessionDoc.requestVideoCreation();
      this.logWorkoutEvent('workoutVideoCreationRequested');
    }
  }

  /**
   * Request to send coach notification when the workout has been done by the user, where done can be partially done,
   * completed or the user just quits. "Done" in here means the user enters to the gameplay and attempts or not to
   * do something.
   *
   * @param {Object} gameplaySessionDoc The GameplaySession document.
   * @param {Object} options Optional data to pass to the coach notification.
   */
  requestSendCoachWorkoutDoneNotification = (gameplaySessionDoc, options = {}) => {
    const extraOptions = {
      formattedWorkoutProgressTime: parseTimeInMillis(this.workoutProgressTime),
      ...options,
    };

    gameplaySessionDoc.requestSendCoachWorkoutDoneNotification(extraOptions);

    this.logWorkoutEvent('sendCoachWorkoutDoneNotificationRequested', {
      workoutProgressTime: this.workoutProgressTime,
    });
  }

  updateWorkoutProgressTime = (time) => {
    this.workoutProgressTime = time;
  }

  clearTimers = () => { }

  /**
   * Clear timers. This function must be called on "componentWillUnmount".
   */
  reset = () => {
    this.clearTimers();
  }

  get activities() {
    return this.activitiesExecutor.activities;
  }

  get jsonActivities() {
    return JSON.parse(JSON.stringify(this.activities));
  }

  get currentExecutableActivity() {
    return this.activitiesExecutor.currentExecutableActivity;
  }

  get isWorkoutPrepared() {
    return this.state === workoutStatus.PREPARED;
  }

  get isWorkoutFinished() {
    return this.state === workoutStatus.FINISHED;
  }

  get isWorkoutFinishing() {
    return this.state === workoutStatus.FINISHING;
  }

  get isWorkoutRunning() {
    return this.state === workoutStatus.RUNNING;
  }

  get isWorkoutPaused() {
    return this.state === workoutStatus.PAUSED;
  }

  get currentActivityId() {
    return this.activitiesExecutor.currentActivityId;
  }

  get currentActivityCircuits() {
    return this.activitiesExecutor.currentActivityCircuits;
  }

  get currentCircuitRound() {
    return this.activitiesExecutor.currentCircuitRound;
  }

  get nextExecutableActivity() {
    return this.activitiesExecutor.nextExecutableActivity;
  }

  get nextActivityId() {
    return this.activitiesExecutor.nextActivityId;
  }

  get nextActivityCircuits() {
    return this.activitiesExecutor.nextActivityCircuits;
  }

  get nextActivityCircuitRound() {
    return this.activitiesExecutor.nextActivityCircuitRound;
  }

  get currentCircuitTotalRounds() {
    return this.activitiesExecutor.currentCircuitTotalRounds;
  }

  get nextActivityCircuitTotalRounds() {
    return this.activitiesExecutor.nextActivityCircuitTotalRounds;
  }

  get currentExerciseIndex() {
    return this.activitiesExecutor.currentExerciseIndex;
  }

  get totalExercises() {
    return this.activitiesExecutor.totalExercises;
  }

  get currentExercise() {
    return this.activitiesExecutor.currentExercise;
  }

  get nextExercise() {
    return this.activitiesExecutor.nextExercise;
  }

  get currentExerciseId() {
    return this.activitiesExecutor.currentExerciseId;
  }

  get nextExerciseId() {
    return this.activitiesExecutor.nextExerciseId;
  }

  get currentExerciseCircuits() {
    return this.activitiesExecutor.currentExerciseCircuits;
  }

  get nextExerciseCircuits() {
    return this.activitiesExecutor.nextExerciseCircuits;
  }

  get currentExerciseCircuitTotalRounds() {
    return this.activitiesExecutor.currentExerciseCircuitTotalRounds;
  }

  get nextExerciseCircuitTotalRounds() {
    return this.activitiesExecutor.nextExerciseCircuitTotalRounds;
  }

  get currentExerciseCircuitRound() {
    return this.activitiesExecutor.currentExerciseCircuitRound;
  }

  get nextExerciseCircuitRound() {
    return this.activitiesExecutor.nextExerciseCircuitRound;
  }
}

decorate(WorkoutExecutor, {
  state: observable,
  workoutProgressTime: observable,
  currentExecutableActivity: computed,
  nextExecutableActivity: computed,
  currentActivityId: computed,
  nextActivityId: computed,
  currentActivityCircuits: computed,
  nextActivityCircuits: computed,
  currentCircuitRound: computed,
  currentCircuitTotalRounds: computed,
  nextActivityCircuitTotalRounds: computed,
  nextActivityCircuitRound: computed,
  currentExerciseIndex: computed,
  currentExercise: computed,
  nextExercise: computed,
  currentExerciseId: computed,
  nextExerciseId: computed,
  currentExerciseCircuits: computed,
  nextExerciseCircuits: computed,
  currentExerciseCircuitTotalRounds: computed,
  nextExerciseCircuitTotalRounds: computed,
  currentExerciseCircuitRound: computed,
  nextExerciseCircuitRound: computed,
  totalExercises: computed,
  isWorkoutFinished: computed,
  isWorkoutFinishing: computed,
  isWorkoutRunning: computed,
  isWorkoutPaused: computed,
  isWorkoutPrepared: computed,
  start: action,
  stop: action,
  finish: action,
  toNextActivity: action,
  updateWorkoutProgressTime: action,
});

export default WorkoutExecutor;
