import React, { useContext, useMemo } from 'react';
import { observer } from 'mobx-react';

import UserContext from '../../../../context/UserContext';
import { ActionTypes } from '../../../../services/gameplayActions';
import actionsConfig from '../../../../services/gameplayActions/actionsConfig';
import ActivityContext from '../../context/ActivityContext';

import ManualNextAction from './ManualNextAction';
import VirtualNextAction from './VirtualNextAction';

const GameplayActions = () => {
  const {
    gameplayActionsExecutor,
  } = useContext(ActivityContext);

  const {
    userConfigDoc: {
      isVNBActionEnabled,
    },
  } = useContext(UserContext);

  const {
    actionTracked,
    actionProcessed,
    actionProcessing,
  } = gameplayActionsExecutor.current;

  const {
    boundingBoxConfig: {
      actionArea,
    },
  } = actionsConfig[ActionTypes.HAND_ADVANCE_NEXT];

  // Converts the values detection area coordinates values to button size config
  const size = useMemo(() => {
    const {
      yMin,
      yMax,
      xMax,
    } = actionArea;

    return {
      top: yMin * 100,
      bottom: (1 - yMax) * 100,
      width: xMax * 100,
    };
  }, [
    actionArea,
  ]);

  if (isVNBActionEnabled && actionTracked === ActionTypes.HAND_ADVANCE_NEXT) {
    const shouldBlink = !!actionProcessing || !!actionProcessed;

    return (
      <VirtualNextAction
        sizeConfig={size}
        shouldBlink={shouldBlink}
      />
    );
  }

  return (
    <ManualNextAction
      sizeConfig={size}
    />
  );
};

export default observer(GameplayActions);
