import * as Sentry from '@sentry/browser';
import logEvent from './logger';

const getFirebaseVideoUrl = async (firebase, assetURL) => {
  let videoUrl = '';

  try {
    videoUrl = await firebase.storage.ref().child(assetURL).getDownloadURL();
  } catch (error) {
    Sentry.captureException(error);
    logEvent('VideoNotAvailableInFirebaseStorage', {
      assetURL,
      error,
    });
  }

  return videoUrl;
};

const isVimeoVideo = (videoUrl = '') => videoUrl.startsWith('https://vimeo.com/');

export {
  getFirebaseVideoUrl,
  isVimeoVideo,
};
