import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { variants } from '../../../Button';

import {
  StyledButton,
  Container,
} from './styles';

const MenuItem = ({
  url,
  children,
  onClick,
  isExternalUrl,
  active,
}) => {
  let content;
  if (!url) {
    content = (
      <StyledButton
        variant={variants.LINK}
        type="button"
        onClick={onClick}
      >
        {children}
      </StyledButton>
    );
  } else {
    content = (
      isExternalUrl
        ? <a href={url}>{children}</a>
        : <NavLink to={url} onClick={onClick}>{children}</NavLink>
    );
  }
  return (
    <Container active={active}>
      {content}
    </Container>
  );
};

MenuItem.propTypes = {
  url: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  isExternalUrl: PropTypes.bool,
  active: PropTypes.bool,
};

MenuItem.defaultProps = {
  url: null,
  isExternalUrl: false,
  active: false,
};

export default MenuItem;
