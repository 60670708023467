import { metersToMiles } from '../../../utils/distance';
import { formatDuration } from '../../../utils/time';
import clockIconUrl from '../../../assets/icons/health-activity/clock.svg';
import caloriesIconUrl from '../../../assets/icons/health-activity/calories.svg';
import shoeIconUrl from '../../../assets/icons/health-activity/shoe.svg';
import heartRateIconUrl from '../../../assets/icons/health-activity/heartRate.svg';

import texts from './texts.json';

const getStatsViewModel = (trackedActivity) => {
  const {
    activeCalories,
    totalEnergyBurned,
    distance,
    minHeartRate,
    maxHeartRate,
    duration,
  } = trackedActivity;

  const stats = [];

  if (duration) {
    stats.push({
      iconUrl: clockIconUrl,
      stat: formatDuration(duration),
      description: texts.activeTime,
    });
  }

  if (distance > 0) {
    const distanceInMiles = metersToMiles(distance).toFixed(2);
    stats.push({
      iconUrl: shoeIconUrl,
      stat: distanceInMiles,
      description: texts.distance,
    });
  }

  const activeEnergyBurnedCals = Math.floor(activeCalories);
  if (activeEnergyBurnedCals > 0) {
    stats.push({
      iconUrl: caloriesIconUrl,
      stat: activeEnergyBurnedCals,
      description: texts.calories,
    });

    const totalEnergyBurnedCals = Math.floor(totalEnergyBurned);

    // Only display total energy burned if active and total > 0
    if (totalEnergyBurned > 0) {
      stats.push({
        iconUrl: caloriesIconUrl,
        stat: totalEnergyBurnedCals,
        description: texts.totalEnergy,
      });
    }
  }

  if (minHeartRate && maxHeartRate) {
    const maxMinHeartRates = `${minHeartRate}/${maxHeartRate}`;

    stats.push({
      iconUrl: heartRateIconUrl,
      stat: maxMinHeartRates,
      description: texts.heartRate,
    });
  }

  return stats;
};

export {
  getStatsViewModel,
};
