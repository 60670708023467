import React from 'react';

import MessagingChannelHeader from '../MessagingChannelHeader';

import {
  Container,
  StyledLoadingPage,
} from './styles';

const ChatLoadingView = () => (
  <Container>
    <MessagingChannelHeader />
    <StyledLoadingPage />
  </Container>
);

export default ChatLoadingView;
