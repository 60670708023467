import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Chart,
  DoughnutController,
  ArcElement,
  Legend,
} from 'chart.js';

import useAppTheme from '../../../hooks/useAppTheme';
import {
  getChartOptions,
  getChartData,
  getChartPlugins,
} from './config';
import { StyledCanvas } from './styles';

Chart.register(
  DoughnutController,
  ArcElement,
  Legend,
);

const NutritionStats = ({
  stats,
}) => {
  const graphRef = useRef(null);

  const { colors } = useAppTheme();

  useEffect(() => {
    const chartOptions = getChartOptions(colors);
    const data = getChartData(stats);
    const plugins = getChartPlugins(stats, colors);
    const graphOpts = {
      ...chartOptions,
      data,
      plugins,
    };

    const chart = new Chart(graphRef.current, graphOpts);

    return () => {
      chart.destroy();
    };
  }, [
    stats,
    colors,
  ]);

  return (
    <StyledCanvas ref={graphRef} />
  );
};

NutritionStats.propTypes = {
  stats: PropTypes.object.isRequired,
};

export default NutritionStats;
