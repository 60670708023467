import {
  decorate, action, computed, observable,
} from 'mobx';
import activityStatus from '../activityStatus';

class ActivityRepetitionsProcessor {
  constructor(activityExecutor, activity) {
    /**
     * Total of repetitions done by the user.
     *
     * We assume for now that the user will do all the repetitions
     * before hitting the next button. (this is not AI-tracked yet)
     * That's why, for now, we initialize this with the goalValue of
     * the activity itself.
     * @type {number}
     */
    this.workoutValue = activity.goalValue;

    /**
     * Total of repetitions required to complete the challenge.
     * @type {number}
     */
    this.requiredGoalRepetitions = activity.goalValue;

    /**
     * @type {object}
     */
    this.activityExecutor = activityExecutor;
  }

  processStepResult = () => {
    if (this.activityExecutor.activityState === activityStatus.READY) {
      this.activityExecutor.start();
    } else if (this.activityExecutor.activityState === activityStatus.RESUMING) {
      this.activityExecutor.restart();
    }
  }

  resetWorkoutValue = () => {
    this.workoutValue = 0;
  }

  clearTimers = () => {
    // keep it, part of interface
  }

  get isCompleted() {
    return (this.workoutValue >= this.requiredGoalRepetitions);
  }

  get workoutValueObj() {
    return {
      workoutRepetitions: this.workoutValue,
    };
  }

  get remainingWorkoutValue() {
    return this.requiredGoalRepetitions;
  }
}

decorate(ActivityRepetitionsProcessor, {
  workoutValue: observable,
  isCompleted: computed,
  workoutValueObj: computed,
  processStepResult: action,
  resetWorkoutValue: action,
  updateWorkoutValue: action,
});

export default ActivityRepetitionsProcessor;
