import styled from 'styled-components';

import { LinkButton } from '../../../../../../../components/Button';
import { caption1 } from '../../../../../../../styles/text';

const StyledActionButton = styled(LinkButton)`
  width: 80px;
  height: 60px;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:disabled {
    background: transparent;
    opacity: 0.25;
  }
`;

const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Label = styled.div`
  ${caption1};
  font-size: 11px;
  text-align: center;
  color: ${({ theme }) => theme.colors.system.alpha};
`;

export {
  StyledActionButton,
  IconContainer,
  Label,
};
