import React from 'react';
import PropTypes from 'prop-types';

import useAppTheme from '../../hooks/useAppTheme';

import {
  StyledCloseButton,
} from './styles';

const PageCloseButton = ({
  onClick,
  color,
  className,
}) => {
  const { colors } = useAppTheme();
  const colorValue = color || colors.system.beta;

  return (
    <StyledCloseButton
      onClick={onClick}
      color={colorValue}
      className={className}
    />
  );
};

PageCloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
};

PageCloseButton.defaultProps = {
  color: '',
  className: '',
};

export default PageCloseButton;
