import {
  useCallback,
  useMemo,
  useContext,
} from 'react';

import MealPlanContext from '../context/MealPlanContext';
import UserNutritionInfoContext from '../context/UserNutritionInfoContext';

const useMealServings = () => {
  const {
    mealPlanAssignmentDoc: {
      mealTimes,
    },
  } = useContext(MealPlanContext);

  const {
    userNutritionMacroGoalsDoc: {
      totalDailyCalories,
    },
  } = useContext(UserNutritionInfoContext);

  const getMealServings = useCallback((meal, bucket) => {
    const {
      recipe: {
        totalCalories: recipeCalories,
      },
      servings,
    } = meal;

    const { caloricSplit } = mealTimes.find(({ name: mealTimeName }) => mealTimeName === bucket) || {};

    if (caloricSplit && recipeCalories > 0) {
      // Get bucket target calories using meal times caloric split
      const bucketTargetCalories = totalDailyCalories * (caloricSplit / 100);
      // Fit recipe calories in bucket target
      const bucketRecipeRatio = bucketTargetCalories / recipeCalories;
      // Round to nearest 0.5 multiple (supporting half servings)
      const calculatedServings = Math.round(bucketRecipeRatio / 0.5) * 0.5;
      // If calculatedServings is 0 then use half a serving
      return calculatedServings || 0.5;
    }
    return servings;
  }, [
    totalDailyCalories,
    mealTimes,
  ]);

  return useMemo(() => ({
    getMealServings,
  }), [
    getMealServings,
  ]);
};

export default useMealServings;
