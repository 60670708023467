import React, {
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as OverrideIcon } from '../../../assets/icons/icon_override.svg';
import BaseActivity from '../../../models/BaseActivity';
import Chips from '../../Chips';
import ActivityDetailsModal from '../../ActivityDetailsModal';
import { config as sideConfig } from '../../ActivitySideConfig';

import {
  ActivityContainer,
  ActivityTagContainer,
  ActivityTag,
  StyledActivityCard,
  InfoHeaderSection,
  ActivityName,
  ActivityNote,
  RestNote,
  ExtraContentWrapper,
  ActivityInfo,
  BasicInfoWrapper,
  StyledThumbnail,
  OverrideIconWrapper,
} from './styles';

const ActivityCard = ({
  activity,
  tag,
  showTopConnector,
  showBottomConnector,
  ExtraContent,
  ExtraContentWidth,
  isHighlighted,
  enableActivityDetailsModal,
}) => {
  const {
    name,
    note,
    goal,
    restNote,
    hasDetailsInfo,
    side,
    videoPreviewThumbnail,
    originalActivity,
  } = activity;

  const [showActivityDetails, setShowActivityDetails] = useState(false);

  const onViewDetailsClick = useCallback(() => {
    if (enableActivityDetailsModal && hasDetailsInfo) {
      setShowActivityDetails(true);
    }
  }, [
    hasDetailsInfo,
    enableActivityDetailsModal,
    setShowActivityDetails,
  ]);

  const onDetailsClose = () => setShowActivityDetails(false);

  const renderLabels = () => {
    // We only display the goal by default.
    let requirement = goal;

    // If there is any side information, then we add this to the requirement text.
    if (side) {
      const { text: sideText } = sideConfig[side];
      requirement = `${requirement} - ${sideText}`;
    }

    return <Chips items={[requirement]} />;
  };

  return (
    <>
      <ActivityContainer $isHighlighted={isHighlighted}>
        <ActivityTagContainer>
          <ActivityTag
            showTopConnector={showTopConnector}
            showBottomConnector={showBottomConnector}
          >
            {tag}
          </ActivityTag>
        </ActivityTagContainer>
        <StyledActivityCard onClick={onViewDetailsClick}>
          <ActivityInfo>
            {!!videoPreviewThumbnail && (
              <StyledThumbnail url={videoPreviewThumbnail} />
            )}
            <BasicInfoWrapper>
              <InfoHeaderSection>
                <ActivityName>{name}</ActivityName>
                {!!originalActivity && (
                  <OverrideIconWrapper>
                    <OverrideIcon />
                  </OverrideIconWrapper>
                )}
              </InfoHeaderSection>
              {renderLabels()}
            </BasicInfoWrapper>
          </ActivityInfo>
          {!!note && <ActivityNote>{note}</ActivityNote>}
          {!!restNote && <RestNote>{restNote}</RestNote>}
        </StyledActivityCard>

        {!!ExtraContent && (
          <ExtraContentWrapper $width={ExtraContentWidth}>
            <ExtraContent
              activity={activity}
              tag={tag}
              isHighlighted={isHighlighted}
            />
          </ExtraContentWrapper>
        )}
      </ActivityContainer>
      <ActivityDetailsModal
        isOpen={showActivityDetails}
        onClose={onDetailsClose}
        activity={activity}
      />
    </>
  );
};

ActivityCard.propTypes = {
  activity: PropTypes.instanceOf(BaseActivity).isRequired,
  tag: PropTypes.string.isRequired,
  showTopConnector: PropTypes.bool,
  showBottomConnector: PropTypes.bool,
  ExtraContent: PropTypes.elementType,
  ExtraContentWidth: PropTypes.string,
  isHighlighted: PropTypes.bool,
  enableActivityDetailsModal: PropTypes.bool,
};

ActivityCard.defaultProps = {
  showTopConnector: false,
  showBottomConnector: false,
  ExtraContent: null,
  ExtraContentWidth: '70px', // This has to be a valid css value for the width property. eg: 70px, 30%, etc.
  isHighlighted: false,
  enableActivityDetailsModal: true,
};

export default ActivityCard;
