import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import NotificationModalContext, { initialState } from './NotificationModalContext';

const NotificationModalContextProvider = ({
  children: contextChildren,
}) => {
  const [isOpen, setIsOpen] = useState(initialState.isOpen);
  const [title, setTitle] = useState(initialState.title);
  const [description, setDescription] = useState(initialState.description);
  const [Icon, setIcon] = useState(initialState.Icon);
  const [content, setContent] = useState(initialState.content);
  const [actions, setActions] = useState(initialState.actions);

  const showNotificationModal = useCallback(({
    title: notificationTitle,
    description: notificationDescription,
    Icon: notificationIcon,
    actions: notificationActions,
    content: notificationContent,
  }) => {
    setIsOpen(true);
    setTitle(notificationTitle);
    setDescription(notificationDescription);
    setIcon(notificationIcon);
    setContent(notificationContent);
    setActions(notificationActions);
  }, []);

  const hideNotificationModal = useCallback(() => {
    setIsOpen(false);
    setTitle(initialState.title);
    setDescription(initialState.description);
    setIcon(initialState.Icon);
    setContent(initialState.content);
    setActions(initialState.actions);
  }, []);

  const value = useMemo(() => ({
    isOpen,
    title,
    description,
    Icon,
    content,
    actions,
    showNotificationModal,
    hideNotificationModal,
  }), [
    isOpen,
    title,
    description,
    Icon,
    content,
    actions,
    showNotificationModal,
    hideNotificationModal,
  ]);

  return (
    <NotificationModalContext.Provider value={value}>
      {contextChildren}
    </NotificationModalContext.Provider>
  );
};

NotificationModalContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NotificationModalContextProvider;
