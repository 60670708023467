import React, { useContext } from 'react';

import LoadingPage from '../../components/LoadingPage';
import MealPlanContextProvider from './MealPlanContextProvider';
import MealPlanContext from './MealPlanContext';

/**
 * Inserts MealPlanContextProvider and initializes it with the currently assigned meal plan
 * that the user has.
 *
 * @param {Object} Component The component that needs the context provider.
 * @returns {Object} The component to render wrapped with the provider.
 */
const withMealPlanContextProvider = (Component) => (props) => (
  <MealPlanContextProvider>
    <Component {...props} />
  </MealPlanContextProvider>
);

/**
 * Renders the given component when MealPlanContext is ready.
 *
 * @param {Object} Component The component to render.
 * @param {Object} Component The rendered component.
 * @returns {Object} The component to render or the LoadingPage component.
 */
const withMealPlanContextReady = (Component) => (props) => {
  const { isReady } = useContext(MealPlanContext);
  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withMealPlanContextProvider,
  withMealPlanContextReady,
};
