import styled from 'styled-components';

import { sansSerif } from '../../../../../../styles/text';

const Container = styled.div``;

const Content = styled.div`
  display: inline-flex;
  align-items: center;
  border-radius: 6px;
  padding: 4px;
  background: ${({ theme }) => theme.colors.system.background};
`;

const Name = styled.div`
  ${sansSerif.sansSerifHeadlineFontStyles}
  font-size: 12px;
  line-height: 14px;
  color: rgba(20, 34, 47, 0.6);
  text-transform: uppercase;
`;

const IconContainer = styled.div`
  height: 18px;
  width: 16px;
  margin: 0 10px 0 0;
`;

export {
  Container,
  Name,
  IconContainer,
  Content,
};
