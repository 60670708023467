import styled from 'styled-components';

import { ReactComponent as FoodPlate } from '../../assets/food-plate-w-cover.svg';

const StyledImage = styled.img`
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

const StyledPlaceholderImage = styled(FoodPlate)`
  width: 100%;
  height: auto;
  padding: 8px;
`;

export {
  StyledImage,
  StyledPlaceholderImage,
};
