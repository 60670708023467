import styled from 'styled-components';

import { subtitle1, overline, fontFamily } from '../../styles/text';
import Avatar from '../Avatar';

const Container = styled.div`
  overflow-x: hidden;
`;

const SubscriptionBg = styled.div`
  position: absolute;
  width: 150%;
  height: 0;
  padding-bottom: 100%;
  background: linear-gradient(180deg,rgba(20,34,47,0) 6.25%,#14222F 100%);
  border-radius: 50%;
  bottom: calc(100% - 135px);
  left: -25%;
`;

const Title = styled.div`
  ${fontFamily.sansSerif};
  ${overline};
  text-align: center;
  width: 230px;
  margin: 35px auto 0;
  color: ${({ theme }) => theme.colors.system.alpha};
`;

const CoachAvatar = styled(Avatar)`
  position: absolute;
  width: 80px;
  height: 80px;
  top: 0;
  left: 50%;
  transform: translate(-50%, 95px);
`;

const Message = styled.div`
  ${subtitle1};
  font-size: 20px;
  margin: 130px 24px 40px;
  text-align: center;
`;

export {
  Container,
  SubscriptionBg,
  Title,
  CoachAvatar,
  Message,
};
