import styled, { css } from 'styled-components';

import AssignmentDayType from '../../viewModel/assignmentType';
import { isActiveProgressStatus } from '../../progressStatus';
import ProgressConnector from '../ProgressConnector';

const StyledProgressConnector = styled(ProgressConnector)``;

const COMMON_SPACING = '16px';

const DayGroupWrapper = styled.div`
  position: relative;
  margin-bottom: ${COMMON_SPACING};

  > ${StyledProgressConnector} {
    margin-bottom: 0 !important;
    top: -${COMMON_SPACING};
    height: calc(100% + ${COMMON_SPACING});
  }

  ${({
    assignmentDayType,
    progressStatus,
    theme,
  }) => {
    const backgroundColor = assignmentDayType === AssignmentDayType.TODAY || isActiveProgressStatus(progressStatus)
      ? theme.colors.system.alpha
      : theme.colors.system.support;
    return css`
      background-color: ${backgroundColor};
    `;
  }}
`;

export {
  DayGroupWrapper,
  StyledProgressConnector,
};
