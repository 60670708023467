import React, {
  useState,
  useMemo,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { ChatChannelListContext } from '@fitmoola/system2-chat';

import ChatIcon from '../../../chat/assets/icon/chat.svg';
import useChat from '../../../chat/hooks/useChat';
import { isNewChatEnabled } from '../../../utils/featureFlags';
import ChatContext from '../../../chat/context';

import HeaderContext from './HeaderContext';

const actiondIds = {
  CHAT: 'native-chat',
};

const CHAT_ACTION = {
  id: actiondIds.CHAT,
  iconSrc: ChatIcon,
  altText: 'native-chat',
  maxDigits: 2,
};

const HeaderContextProvider = ({
  children,
  addChat,
}) => {
  const [title, setTitle] = useState('');
  const [actions, setActions] = useState([]);
  const [backHandler, setBackHandler] = useState(null);

  const upsertAction = useCallback((newAction) => {
    setActions((currentActions) => {
      const newActions = currentActions.reduce((actionsConfig, action, index, arr) => {
        if (action.id === newAction.id) {
          return {
            updated: true,
            actions: [
              ...actionsConfig.actions,
              newAction,
            ],
          };
        }

        const shouldAddNewAction = index === arr.length - 1 && !actionsConfig.updated;

        return {
          ...actionsConfig,
          actions: [
            ...actionsConfig.actions,
            action,
            ...((shouldAddNewAction) ? [newAction] : []),
          ],
        };
      }, {
        actions: [],
        updated: false,
      }).actions;
      return newActions.length > 0 ? newActions : [newAction];
    });
  }, []);

  const removeAction = useCallback((actionId) => {
    setActions((currentActions) => {
      const actionIndex = currentActions.findIndex(({ id }) => actionId === id);
      if (actionIndex > -1) {
        currentActions.splice(actionIndex, 1);
      }
      return [
        ...currentActions,
      ];
    });
  }, []);

  const {
    toggleChatModal,
    totalUnreadCount: oldChatTotalUnreadCount,
  } = useContext(ChatContext);
  const { totalUnreadCount: newChatTotalUnreadCount } = useContext(ChatChannelListContext);

  const { isChatEnabled } = useChat();

  const shouldAddChatAction = isChatEnabled
    && toggleChatModal
    && addChat;

  useEffect(() => {
    if (shouldAddChatAction) {
      upsertAction({
        ...CHAT_ACTION,
        onClick: toggleChatModal,
        notificationsNumber: isNewChatEnabled() ? newChatTotalUnreadCount : oldChatTotalUnreadCount,
      });
    } else {
      removeAction(CHAT_ACTION.id);
    }
  }, [
    shouldAddChatAction,
    upsertAction,
    removeAction,
    newChatTotalUnreadCount,
    oldChatTotalUnreadCount,
    toggleChatModal,
  ]);

  const context = useMemo(() => ({
    title,
    actions,
    setTitle,
    upsertAction,
    removeAction,
    backHandler,
    setBackHandler,
  }), [
    title,
    actions,
    setTitle,
    upsertAction,
    removeAction,
    backHandler,
    setBackHandler,
  ]);

  return (
    <HeaderContext.Provider value={context}>
      {children}
    </HeaderContext.Provider>
  );
};

HeaderContextProvider.propTypes = {
  addChat: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

HeaderContextProvider.defaultProps = {
  addChat: true,
};

export default compose(
  observer,
)(HeaderContextProvider);
