import React from 'react';
import PropTypes from 'prop-types';

import { StyledRowInput } from './styles';

const RowInput = ({
  value,
  onChange,
  allowDecimalValues,
  min,
  rowInputKey,
  ...otherProps
}) => {
  const inputValue = value || '';

  const onInputChange = (event) => {
    // If the field is empty, we force the value to 0.
    let targetValue = event.target.value ? event.target.value : '0';

    /*
      We need to use text inputs because, depending on the locale config, the keyboard shows a comma instead of a dot
      (when using number inputs), and the onChange event is not triggered at all after that character is introduced.
      By using a text input, we enable all characters, and we then replace the comma by a dot, if necessary. The
      keyboard shown to the user is still a numeric keyboard, which we control with the inputMode attribute.
    */
    targetValue = targetValue.replace(',', '.');

    // Use float numbers when we are allowed to display decimal numbers. Otherwise, parse integers.
    const numberValue = allowDecimalValues
      ? Number.parseFloat(targetValue)
      : Number.parseInt(targetValue, 10);

    if (numberValue >= min) {
      onChange(numberValue);
    }
  };

  return (
    <React.Fragment key={rowInputKey}>
      <StyledRowInput
        defaultValue={inputValue}
        onChange={onInputChange}
        placeholder="-"
        type="text"
        inputMode={allowDecimalValues ? 'decimal' : 'numeric'}
        {...otherProps}
      />
    </React.Fragment>
  );
};

RowInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  allowDecimalValues: PropTypes.bool,
  min: PropTypes.number,
  rowInputKey: PropTypes.string,
};

RowInput.defaultProps = {
  allowDecimalValues: false,
  min: 0,
  rowInputKey: '',
};

export default RowInput;
