import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';

import { ActivityTypes } from '../../../../models/BaseActivity';
import ActivityActionBar from '../../ActivityActionBar';
import Activity from '../Activity';
import {
  CircuitContainer,
  CircuitHeader,
  CircuitName,
  Rounds,
  CircuitNote,
  RestWarning,
} from './styles';
import texts from './texts.json';

const Circuit = ({
  activity,
  path,
  circuitTag,

  isEditorMode,
  onEditorModeActionPerform,
  showRestWarning,
}) => {
  const {
    name,
    rounds,
    note,
    activities: circuitActivities,
    type,
  } = activity;

  const showRounds = rounds > 1;
  const showHeader = !!name || showRounds;
  const enableMargins = showHeader || circuitActivities.length > 1;

  const onActionButtonClick = useCallback((event, action) => {
    event.preventDefault();
    event.stopPropagation();
    onEditorModeActionPerform({
      action,
      activity,
      path,
      type: ActivityTypes.CIRCUIT,
    });
  }, [
    activity,
    path,
    onEditorModeActionPerform,
  ]);

  const renderActionBar = () => {
    if (isEditorMode && !!name && type !== ActivityTypes.REST) {
      return (
        <ActivityActionBar
          onActionButtonClick={onActionButtonClick}
          hasAddCircuit
          isCircuit
        />
      );
    }
    return null;
  };

  const onActionPerformed = (props) => {
    const newParams = {
      ...props,
    };

    if (newParams.action === 'COPY' && !newParams.activity.activities) { // single-activity
      newParams.activity = {
        activities: [props.activity],
        type: 'CIRCUIT',
        rounds: name ? 1 : rounds,
      };
    }

    onEditorModeActionPerform(newParams);
  };

  const circuitType = name ? texts.circuitType.circuit : texts.circuitType.activity;
  const RestWarningText = format(texts.restWarningText, { circuitType });

  return (
    <CircuitContainer enableMargins={enableMargins}>
      {showHeader && (
        <CircuitHeader>
          <CircuitName>{name}</CircuitName>

          {renderActionBar()}

          {showRounds && (
            <Rounds>{format(texts.rounds, { rounds })}</Rounds>
          )}
        </CircuitHeader>
      )}
      {note && <CircuitNote>{note}</CircuitNote>}
      {circuitActivities.map(
        (cActivity, index) => {
          /*
            The tag is a combination of a letter and a number. eg: A1, A2, etc. If the circuit holds only one activity,
            then use the letter only
          */
          const tag = circuitActivities.length === 1 ? `${circuitTag}` : `${circuitTag}${index + 1}`;

          // Show the top connector for every activity except the first one.
          const showTopConnector = index !== 0;

          // Show the bottom connector for every activity except the last one.
          const showBottomConnector = index < circuitActivities.length - 1;

          return (
            <Activity
              activity={cActivity}
              path={[path[0], index]}
              tag={tag}
              showTopConnector={showTopConnector}
              showBottomConnector={showBottomConnector}
              key={tag}
              isEditorMode={isEditorMode}
              onEditorModeActionPerform={onActionPerformed}
              isSingleActivity={circuitActivities.length === 1 && !name}
            />
          );
        },
      )}
      {showRestWarning && (
        <RestWarning>{RestWarningText}</RestWarning>
      )}
    </CircuitContainer>
  );
};

Circuit.propTypes = {
  activity: PropTypes.object.isRequired,
  path: PropTypes.arrayOf(PropTypes.number).isRequired,
  circuitTag: PropTypes.string.isRequired,

  onEditorModeActionPerform: PropTypes.func.isRequired,
  isEditorMode: PropTypes.bool.isRequired,
  showRestWarning: PropTypes.bool,
};

Circuit.defaultProps = {
  showRestWarning: false,
};

export default Circuit;
