import React, { useCallback, useContext, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { useIonToast } from '@ionic/react';

import FirebaseContext from '../../../../context/FirebaseContext';
import UserContext from '../../../../context/UserContext';

import texts from './texts.json';
import {
  CardContainer,
  Title,
  TextWrapper,
  StyledButton,
} from './styles';

const toastDurationMs = 2000;

const CronometerUnlinkBanner = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [present] = useIonToast();

  const showToast = useCallback(
    (message, isError) => {
      present({
        message,
        duration: toastDurationMs,
        color: isError ? 'warning' : 'success',
      });
    },
    [present],
  );
  const {
    firebase: { remote },
  } = useContext(FirebaseContext);
  const { userId } = useContext(UserContext);

  const onUnlink = async () => {
    setIsLoading(true);
    try {
      const response = await remote('unlinkCronometerUser');
      const { success } = await response.json();
      if (success) {
        showToast(texts.success, false);
      }
    } catch (error) {
      Sentry.captureException({
        message: 'Error while unlinking Cronometer',
        error,
        userId,
      });
      showToast(texts.error, true);
    }
    setIsLoading(false);
  };

  return (
    <CardContainer>
      <TextWrapper>
        <Title>{texts.title}</Title>
      </TextWrapper>
      <StyledButton disabled={isLoading} onClick={onUnlink}>
        {texts.unlink}
      </StyledButton>
    </CardContainer>
  );
};

export default compose(observer)(CronometerUnlinkBanner);
