import styled from 'styled-components';

import { ReactComponent as PencilIcon } from '../../../../assets/icons/pencil-icon.svg';
import { ReactComponent as ListIcon } from '../../../../assets/icons/list-icon.svg';
import {
  body1,
  sansSerif,
} from '../../../../styles/text';

const StyledHistoryData = styled.div`
  font-family: 'Neue Montreal';
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const DateWrapper = styled.div`
  ${body1}
  color: rgba(11, 28, 79, 0.4);
  height: 22px;
`;

const ValuesWrapper = styled.div`
  display: flex;
`;

const HistoryValue = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Value = styled.div`
  ${sansSerif.headline3}
`;

const Label = styled.div`
  font-size: 12px;
  text-transform: capitalize;
`;

const StyledPencilIcon = styled(PencilIcon)`
  align-self: center;
  path {
    fill: rgba(11, 28, 79, 0.4);
  }
`;

const StyledListIcon = styled(ListIcon)`
  path {
    fill: ${({ theme }) => theme.colors.system.beta};
  }
`;

export {
  StyledHistoryData,
  DateWrapper,
  ValuesWrapper,
  HistoryValue,
  Value,
  Label,
  StyledPencilIcon,
  StyledListIcon,
};
