import React from 'react';
import PropTypes from 'prop-types';

import {
  SummaryContainer,
  InfoSeparator,
  InfoSection,
} from './styles';

const Summary = ({
  value,
  goal,
  unit,
  color,
}) => (
  <SummaryContainer>
    <InfoSeparator $color={color} />
    <InfoSection>
      <span>
        {(value !== 0 && goal !== 0)
          ? `${value}/${goal}`
          : value}
      </span>
      <span>
        {unit}
      </span>
    </InfoSection>
  </SummaryContainer>
);

Summary.propTypes = {
  value: PropTypes.number.isRequired,
  goal: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default Summary;
