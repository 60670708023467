import React from 'react';

const initialValues = {
  userNutritionProfileDoc: null,
  userNutritionMacroGoalsDoc: null,
  cronometerTokenDoc: {},
  isReady: false,
};

const UserNutritionInfoContext = React.createContext(initialValues);

export default UserNutritionInfoContext;
export {
  initialValues,
};
