import {
  useState,
  useCallback,
} from 'react';
import { useRouteMatch } from 'react-router-dom';

import useUserNavigation from './useUserNavigation';

const MEAL_PLAN_BASE_PATHNAME = 'mealPlan';

/**
 * This React Hook allows navigation to meal plan paths.
 */
const useMealPlanNavigation = () => {
  const { navigateToUserPath } = useUserNavigation();
  const { params: { userId } } = useRouteMatch();

  const [pathname] = useState(`/u/${userId}/${MEAL_PLAN_BASE_PATHNAME}`);

  const navigateToMealPlanPage = useCallback(() => (
    navigateToUserPath(MEAL_PLAN_BASE_PATHNAME)
  ), [
    navigateToUserPath,
  ]);

  const navigateToMealDetailsPage = useCallback((bucket, mealNumber) => {
    const path = `${MEAL_PLAN_BASE_PATHNAME}/bucket/${bucket}/meal/${mealNumber}/details`;
    navigateToUserPath(path);
  }, [
    navigateToUserPath,
  ]);

  return {
    pathname,
    navigateToMealPlanPage,
    navigateToMealDetailsPage,
  };
};

export default useMealPlanNavigation;
export {
  MEAL_PLAN_BASE_PATHNAME,
};
