import moment from 'moment';

const calculateActivitySummaryId = (user, date) => {
  const startDateTime = moment(date)
    .startOf('day')
    .toDate()
    .getTime();

  return `${user}_${startDateTime}`;
};

const calculateTrackedActivityId = (user, activityId) => (
  `${user}_${activityId}`
);

export {
  calculateActivitySummaryId,
  calculateTrackedActivityId,
};
