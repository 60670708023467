import { ContentSwitcherContext, ContentSwitcherContextProvider } from '../ContentSwitcher';
import WorkoutInfoSwitcher from './WorkoutInfoSwitcher';
import Section from './section';
import WorkoutInfoSwitcherContext from './context';

export default WorkoutInfoSwitcher;
export {
  Section,
  WorkoutInfoSwitcherContext,
  ContentSwitcherContext,
  ContentSwitcherContextProvider,
};
