import styled from 'styled-components';

import {
  safeAreaFromTop,
  safeAreaFromBottom,
  safeAreaFromLeft,
} from '../../../styles/mixins';

import NavigationMenu from '../NavigationMenu';

const NavWrapper = styled.div`
  background: ${({ theme }) => theme.colors.system.gamma4};
  height: 100%;
  padding-bottom: ${safeAreaFromBottom()};
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  position: relative;
  padding-top: calc(${safeAreaFromTop()} + 64px);
  padding-left: calc(${safeAreaFromLeft()} + 26px);
  padding-right: 26px;
  padding-bottom: 26px;
  background-color: ${({ theme }) => theme.colors.system.background};
`;

const BurgerContainer = styled.div`
  position: absolute;
  top: ${safeAreaFromTop()};
`;

const Profile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0 0 auto;
`;

const AccountInfo = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.system.beta};
  padding-left: 15px;
`;

const StyledNavigationMenu = styled(NavigationMenu)`
  flex: 1;
  padding-left: ${safeAreaFromLeft()};
  overflow-x: hidden;
  overflow-y: auto;
`;

export {
  NavWrapper,
  HeaderContainer,
  Profile,
  AccountInfo,
  StyledNavigationMenu,
  BurgerContainer,
};
