import styled from 'styled-components';

import CollapsibleCard from './CollapsibleCard';
import MacroGoalsCard from './MacroGoalsCard';

const StyledMacroGoalsCard = styled(MacroGoalsCard)`
  margin-bottom: 8px;
`;

const StyledCollapsibleCard = styled(CollapsibleCard)`
  margin-bottom: 8px;
`;

export {
  StyledCollapsibleCard,
  StyledMacroGoalsCard,
};
