import styled, { css } from 'styled-components';

import Button from '../../../components/Button';
import { ReactComponent as BroadcastIcon } from '../../../assets/icons/broadcast-icon.svg';

const StyledBroadcastIcon = styled(BroadcastIcon)`
  margin-right: 8px;
  width: 14px;
  height: 14px;
  > path {
    fill: ${({ theme }) => theme.colors.system.gamma5};
    stroke: ${({ theme }) => theme.colors.system.beta};
  }
`;

const StyledContainer = styled.div`
  align-self: center;
  margin-left: auto;
`;

const buttonStyle = css`
  display: flex;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.system.gamma5};
  color: ${({ theme }) => theme.colors.system.beta};
  font-weight: 450;
  width: fit-content;
  height: fit-content;
  padding: 10px 14px;
  font-size: 13px;
  line-height: 15px;
  align-items: center;
  justify-content: center;
`;

const BroadcastMessageButton = styled(Button)`
  ${buttonStyle};
`;

export {
  BroadcastMessageButton,
  StyledContainer,
  StyledBroadcastIcon,
};
