import React from 'react';
import PropTypes from 'prop-types';

import {
  CardTitle,
} from '../commonStyles';
import NutritionStats from './NutritionStats';
import {
  StyledCard,
} from './styles';
import texts from './texts.json';

const NutritionalInfoCard = ({
  stats,
}) => (
  <StyledCard>
    <CardTitle>{texts.title}</CardTitle>
    <NutritionStats stats={stats} />
  </StyledCard>
);

NutritionalInfoCard.propTypes = {
  stats: PropTypes.object.isRequired,
};

export default NutritionalInfoCard;
