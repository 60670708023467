import React from 'react';

const initialState = {
  isSliderOpen: false,
  openSlider: () => {},
  closeSlider: () => {},
};

const SubscriptionSlideDrawerContext = React.createContext(initialState);

export default SubscriptionSlideDrawerContext;
export {
  initialState,
};
