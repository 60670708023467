import React from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import {
  withCameraContextProvider,
  withCameraPermissionInitializatorReady,
} from '../../context/CameraContext';
import {
  withGameplaySessionContextProvider,
  withGameplaySessionContextReady,
} from '../../context/GameplaySessionContext';
import { ActivityDetailsContextProvider } from '../../components/ActivityDetailsModal';
import Gameplay from './Gameplay';

const GameplayContainer = () => (
  <ActivityDetailsContextProvider
    enableExerciseOverride={false}
  >
    <Gameplay />
  </ActivityDetailsContextProvider>
);

export default compose(
  withCameraContextProvider,
  withCameraPermissionInitializatorReady,
  withGameplaySessionContextProvider,
  withGameplaySessionContextReady,
  observer,
)(GameplayContainer);
