import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import TrackedActivityStats from './TrackedActivityStats';
import {
  StyledCard,
  StyledSlides,
  StyledSlide,
} from './styles';

const TrackedStatsContainer = ({
  trackedActivities,
}) => {
  const getActivityStats = () => {
    if (trackedActivities.docs.length > 1) {
      return (
        <StyledSlides pager>
          {trackedActivities.docs.map((trackedActivity) => (
            <StyledSlide key={trackedActivity.id}>
              <TrackedActivityStats
                trackedActivity={trackedActivity}
              />
            </StyledSlide>
          ))}
        </StyledSlides>
      );
    }

    return (
      <TrackedActivityStats
        trackedActivity={trackedActivities.docs[0]}
      />
    );
  };

  return (
    <StyledCard>
      {getActivityStats()}
    </StyledCard>
  );
};

TrackedStatsContainer.propTypes = {
  trackedActivities: PropTypes.object.isRequired,
};

export default compose(
  observer,
)(TrackedStatsContainer);
