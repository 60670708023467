import styled from 'styled-components';

const ChangeLogContainer = styled.div`
  padding: 40px 40px;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-bottom: 15px;
`;

const ChangelogWrapper = styled.div`
  margin-bottom: 15px;
`;

const ChangelogTitle = styled.div`
  text-align: left;
  font-weight: bold;
  border-bottom: ${({ theme }) => theme.colors.system.beta} solid 1px;
  padding-bottom: 5px;
  margin-bottom: 20px;
`;

const ChangelogContent = styled.div`
  display: content;
  padding-bottom: 5px;
`;

const ChangelogReleaseDate = styled.div`
  display: flex;
  text-align: left;
  padding-bottom: 5px;
`;

const ReleaseDateLabel = styled.span`
  font-weight: bold;
`;

export {
  ChangeLogContainer,
  ChangelogWrapper,
  ChangelogTitle,
  ChangelogContent,
  ChangelogReleaseDate,
  ReleaseDateLabel,
};
