import React from 'react';

const UserContext = React.createContext({
  userId: null,
  userDoc: null,
  userConfigDoc: null,
  isReady: false,
});

export default UserContext;
