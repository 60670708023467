import styled, { css } from 'styled-components';
import { IonSpinner } from '@ionic/react';

import { sansSerif } from '../../../../../styles/text';
import CollapsiblePanel, { Header, Title, SubTitle } from '../../../../../components/CollapsiblePanel';
import { ReactComponent as MarkedCompletedIcon } from '../../../../../assets/marked-completed.svg';
import { ReactComponent as MarkedIncompleteIcon } from '../../../../../assets/marked-incomplete.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const StyledTitle = styled.span`
  ${sansSerif.headlineFontStyles}
  font-size: 12px;
  font-weight: bold;
  line-height: 22px;
`;

const WorkoutItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const WorkoutStatusContainer = styled.div`
  display: flex;
  margin-right: 10px;
`;

const WorkoutName = styled.div`
  ${sansSerif.headlineFontStyles}
  font-size: 12px;
  margin: 5px 0;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px 12px;
`;

const LoaderWrapper = styled.div`
  height: 45px;
  max-height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Loading = styled(IonSpinner)`
  width: 100%;
`;

const StyledCollapsiblePanel = styled(CollapsiblePanel)`
  width: 100%;
  margin-top: 2px;
  min-height: 35px;
  ${Header} {
    background-color: ${({ theme }) => theme.colors.system.gamma1};
    padding: 0 12px;
    align-items: left;
    height: 35px;
    min-height: 35px;
  }

  ${Title} {
    ${sansSerif.headlineFontStyles}
    font-size: 12px;
    font-weight: bold;
    line-height: 26px;
    text-transform: capitalize;
  }

  ${SubTitle} {
    ${sansSerif.headlineFontStyles}
    font-size: 10px;
    line-height: 26px;
  }
`;

const iconStyle = css`
  height: 15px;
  width: 15px;
`;

const CompletedIcon = styled(MarkedCompletedIcon)`
  ${iconStyle};
`;

const IncompletIcon = styled(MarkedIncompleteIcon)`
  ${iconStyle};
`;

export {
  Container,
  ItemContainer,
  StyledTitle,
  WorkoutItem,
  WorkoutStatusContainer,
  WorkoutName,
  LoaderWrapper,
  Loading,
  DataContainer,
  StyledCollapsiblePanel,
  CompletedIcon,
  IncompletIcon,
};
