import moment from 'moment';
import * as Yup from 'yup';

import { DateFormat } from '../../utils/date';

const validationSchema = Yup.object().shape({
  exerciseName: Yup.string()
    .required('Exercise name is required.'),
  date: Yup.date()
    .required('Workout date is required'),
  comment: Yup.string(),
});

const initialValues = {
  exerciseName: '',
  date: moment().format(DateFormat.DEFAULT_DATE_TIME_FORMAT),
  comment: '',
};

export {
  validationSchema,
  initialValues,
};
