import styled from 'styled-components';

const TooltipContainer = styled.div`
  position: relative;
`;

const Bubble = styled.div`
  position: absolute;
  right: -5px;
  top: calc(100% + 6px);
  width: max-content;
  padding: 10px;
  background-color: rgba(20, 34, 47,0.6);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 21px;
  text-transform: none;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.system.alpha};

  :before {
    content: " ";
    right: 13px;
    top: -14px;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 7px;
    border-bottom-color: rgba(20, 34, 47,0.6);
  }
`;

export {
  TooltipContainer,
  Bubble,
};
