import React from 'react';
import PropTypes from 'prop-types';

import { CloseThreadIconComponent } from './styles';
import './MessagingThread.css';
import texts from './texts.json';

const ThreadHeader = ({ closeThread, thread }) => {
  const getReplyCount = () => {
    const { reply_count: replyCount } = thread;
    if (!replyCount) return '';
    if (replyCount === 1) return `${texts.singleReplyMessage}`;
    return `${replyCount} ${texts.multipleReplyPartialMessage}`;
  };

  return (
    <div className="custom-thread-header">
      <div className="custom-thread-header__left">
        <p className="custom-thread-header__left-title">{texts.title}</p>
        <p className="custom-thread-header__left-count">{getReplyCount()}</p>
      </div>
      <CloseThreadIconComponent onClick={closeThread} />
    </div>
  );
};

ThreadHeader.propTypes = {
  closeThread: PropTypes.object.isRequired,
  thread: PropTypes.shape({
    reply_count: PropTypes.number,
  }),
};

ThreadHeader.defaultProps = {
  thread: {},
};

export default ThreadHeader;
