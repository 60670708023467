import React, { useContext } from 'react';
import LoadingPage from '../../components/LoadingPage';
import FirebaseContext from './FirebaseContext';

const withFirebase = (Component) => (props) => {
  const { firebase } = useContext(FirebaseContext);

  return (
    <Component {...props} firebase={firebase} />
  );
};

const withFirebaseContextReady = (Component) => (props) => {
  const { isReady } = useContext(FirebaseContext);
  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withFirebase,
  withFirebaseContextReady,
};
