import { useEffect } from 'react';

import logEvent from '../utils/logger';

const usePageShown = (pageName) => {
  useEffect(() => {
    const eventName = `${pageName}Shown`;

    logEvent(eventName);
  }, [pageName]);
};

export default usePageShown;
