import styled, { css } from 'styled-components';
import { sansSerif } from '../../../styles/text';

import { ReactComponent as CheckInFront } from '../../../assets/check-in-front.svg';
import { ReactComponent as CheckInBack } from '../../../assets/check-in-back.svg';
import { ReactComponent as CheckInSide } from '../../../assets/check-in-side.svg';

const StyledStatusInput = styled.textarea`
  ${sansSerif.headlineFontStyles};
  background-color: ${({ theme }) => theme.colors.system.background};
  padding: 10px;
  font-size: 12px;
  margin: 10px 0;
  width: 100%;
  border: none;
  border-radius: 0;
  &:focus {
    outline: none;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.system.alpha};
  width: 100%;
  padding: 10px 0;
  overflow: scroll;
`;

const iconStyle = css`
  display: flex;
  margin: 20px 30px;
`;

const CheckInFrontImage = styled(CheckInFront)`
  ${iconStyle};
`;

const CheckInBackImage = styled(CheckInBack)`
  ${iconStyle};
`;

const CheckInSideImage = styled(CheckInSide)`
  ${iconStyle};
`;

export {
  StyledStatusInput,
  CheckInFrontImage,
  CheckInBackImage,
  CheckInSideImage,
  ImageContainer,
};
