import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import AssignmentDayType from '../viewModel/assignmentType';
import NoWorkoutsCard from '../components/NoWorkoutsCard';
import CalendarDayCard from './CalendarDayCard';
import CalendarTodayCard from './CalendarTodayCard';
import {
  StyledWorkoutReminderCard,
  ScrollIntoViewContainer,
} from './styles';
import MealPlanCard from './MealPlanCard';
import CheckInCard from './CheckInCard';

const WorkoutFeed = React.forwardRef(({
  activeWorkoutFeedViews,
  futureWorkoutFeedViews,
  showWorkoutReminderCard,
  canUseMealPlan,
}, ref) => {
  const renderWorkoutFeedViews = (workoutFeedViewMap) => {
    const views = [];

    // NOTE: this is a Map instance, so forEach or for..of is the only way of iterating it in insertion order
    workoutFeedViewMap.forEach((workoutDay) => {
      const {
        date,
        assignmentDayType,
      } = workoutDay;

      const key = date.toISOString();

      if (assignmentDayType === AssignmentDayType.TODAY) {
        views.push(
          <ScrollIntoViewContainer
            ref={ref}
            key={key}
          >
            <CalendarTodayCard workoutDay={workoutDay} />
            <CheckInCard />
            {canUseMealPlan && (
              <MealPlanCard />
            )}
          </ScrollIntoViewContainer>,
        );
      } else {
        views.push(
          <CalendarDayCard
            key={key}
            workoutDay={workoutDay}
          />,
        );
      }
    });

    return views;
  };

  return (
    <>
      {renderWorkoutFeedViews(activeWorkoutFeedViews)}
      {showWorkoutReminderCard && <StyledWorkoutReminderCard />}
      {renderWorkoutFeedViews(futureWorkoutFeedViews)}
      {!futureWorkoutFeedViews?.size && <NoWorkoutsCard />}
    </>
  );
});

WorkoutFeed.propTypes = {
  activeWorkoutFeedViews: PropTypes.instanceOf(Map).isRequired,
  futureWorkoutFeedViews: PropTypes.instanceOf(Map).isRequired,
  showWorkoutReminderCard: PropTypes.bool.isRequired,
  canUseMealPlan: PropTypes.bool.isRequired,
};

export default compose(
  observer,
)(WorkoutFeed);
