import React from 'react';
import PropTypes from 'prop-types';

import CrossIcon from '../Icons/CrossIcon';
import StyledButton from './styles';

const CloseButton = ({
  onClick,
  className,
  ...crossIconProps
}) => (
  <StyledButton onClick={onClick} className={className}>
    <CrossIcon {...crossIconProps} />
  </StyledButton>
);

CloseButton.defaultProps = {
  className: '',
};

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default CloseButton;
