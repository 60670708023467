import React, { useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

import UploadFileIcon from '../Icons/UploadFileIcon';
import {
  StyledCard,
  ActionContainer,
  ActionText,
} from './styles';

const PhotoPicker = ({
  actionText,
  onFileSelected,
}) => {
  const openFileSelector = useCallback(async () => {
    try {
      const cameraPhoto = await Camera.getPhoto({
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Prompt,
        quality: 100,
        webUseInput: true,
      });

      onFileSelected({
        dataUrl: cameraPhoto.dataUrl,
        format: cameraPhoto.format,
        isFileChanged: true,
      });
    } catch (error) {
      // Not doing anything since mostly it's related to photo cancellation in native environments.
    }
  }, [onFileSelected]);

  return (
    <StyledCard>
      {!!actionText && (
        <ActionText>{actionText}</ActionText>
      )}
      <ActionContainer>
        <UploadFileIcon onClick={openFileSelector} />
      </ActionContainer>
    </StyledCard>
  );
};

PhotoPicker.propTypes = {
  onFileSelected: PropTypes.func.isRequired,
  actionText: PropTypes.string,
};

PhotoPicker.defaultProps = {
  actionText: '',
};

export default PhotoPicker;
