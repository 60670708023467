import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import {
  StyledCard,
  CountdownContainer,
  TimeDescription,
  Time,
} from './styles';
import texts from './texts.json';

const ONE_SECOND = 1000;
const countdownKeys = ['days', 'hours', 'minutes', 'seconds'];

const Countdown = ({ targetDate, onCountdownEnds }) => {
  const calculateCountdown = useCallback((futureDate) => {
    const difference = moment.duration(futureDate.diff(moment()));

    const updatedTimeLeft = {
      days: Math.floor(difference.asDays()),
      hours: difference.hours(),
      minutes: difference.minutes(),
      seconds: difference.seconds(),
      difference: difference.asSeconds(),
    };

    return updatedTimeLeft;
  }, []);

  const [timeLeft, setTimeLeft] = useState(calculateCountdown(moment.unix(targetDate)));
  const intervalRef = useRef(null);

  useEffect(() => {
    if (timeLeft.difference <= 0) {
      clearInterval(intervalRef.current);
      onCountdownEnds();
    }
  }, [timeLeft.difference, onCountdownEnds]);

  useEffect(() => {
    const setUpTimer = () => {
      const futureDate = moment.unix(targetDate);
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      setTimeLeft(calculateCountdown(futureDate));
      const id = setInterval(() => {
        setTimeLeft(calculateCountdown(futureDate));
      }, ONE_SECOND);
      return id;
    };

    const timerId = setUpTimer();
    intervalRef.current = timerId;

    return () => {
      clearInterval(timerId);
    };
  }, [targetDate, calculateCountdown]);

  return (
    <StyledCard>
      <CountdownContainer>
        {countdownKeys.map((key) => (
          <Time key={key}>
            {timeLeft[key].toString().padStart(2, '0')}
            <TimeDescription>{texts[key]}</TimeDescription>
          </Time>
        ))}
      </CountdownContainer>
    </StyledCard>
  );
};

Countdown.propTypes = {
  targetDate: PropTypes.number,
  onCountdownEnds: PropTypes.func,
};

Countdown.defaultProps = {
  targetDate: 0,
  onCountdownEnds: () => {},
};

export default Countdown;
