import React, {
  useState,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as Sentry from '@sentry/browser';

import MeasurementLog from '../../../../../../models/MeasurementLog';
import HabitLog from '../../../../../../models/HabitLog';
import { DateFormat } from '../../../../../../utils/date';
import useComponentMounted from '../../../../../../hooks/useComponentMounted';
import useStorage from '../../../../../../hooks/useStorage';

import { BodyMeasurements, healthStats } from '../../../../utils';
import texts from '../../../BodyMeasurementHistory/texts.json';
import { isNumeric } from '../../../../../../utils/number';
import {
  DateElement,
  StyledConnector,
  ItemContainer,
  StatElement,
  CommentElement,
  ImageContainer,
  StyledSpinner,
  MeasurementContainer,
  Measurement,
  Label,
  Value,
} from './styles';

const TimelineItem = ({
  log,
  stat,
}) => {
  const isComponentMountedRef = useComponentMounted();

  const {
    getBlobUrl,
  } = useStorage();

  const [attachmentLoaded, setAttachmentLoaded] = useState(null);
  const [attachmentUrl, setAttachmentUrl] = useState(null);
  const [isLoadingUrl, setIsLoadingUrl] = useState(false);

  const { weight, ...measurementsExceptWeight } = BodyMeasurements;

  useEffect(() => {
    const getUrl = async () => {
      setIsLoadingUrl(true);
      let url;
      const attachmentToLoad = log.attachments[log.attachments.length - 1];
      try {
        url = await getBlobUrl(attachmentToLoad);
      } catch (error) {
        Sentry.captureException(error);
      }

      if (isComponentMountedRef.current) {
        setIsLoadingUrl(false);
        setAttachmentLoaded(attachmentToLoad);
        if (url) {
          setAttachmentUrl(url);
        }
      }
    };
    if (log.attachments
      && log.attachments.length > 0
      && log.attachments[log.attachments.length - 1] !== attachmentLoaded) {
      getUrl();
    } else if (log.attachments?.length === 0) {
      setAttachmentUrl(null);
      setAttachmentLoaded(null);
    }
  }, [
    attachmentUrl,
    log.attachments,
    isComponentMountedRef,
    getBlobUrl,
    attachmentLoaded,
  ]);

  const unit = useMemo(() => {
    // no need to append unit to historical sleep data as value itself is a string like "Less than 8 hours"
    if (stat === healthStats.sleep.name && !isNumeric(log[stat])) {
      return '';
    }
    return (stat && healthStats[stat]?.units) || '';
  }, [log, stat]);

  if ((log instanceof HabitLog) && stat) {
    return (
      <ItemContainer>
        <StyledConnector />
        <DateElement>
          {moment(log.date).format(DateFormat.SHORT_DAY_DATE_FORMAT_SLASH)}
        </DateElement>
        <StatElement>
          {`${log[stat]} ${unit}`}
        </StatElement>
      </ItemContainer>
    );
  }
  if (log instanceof MeasurementLog) {
    return (
      <ItemContainer>
        <StyledConnector />
        <DateElement>
          {moment(log.date).format(DateFormat.SHORT_DAY_DATE_FORMAT_SLASH)}
        </DateElement>
        <StatElement>
          {`${log[weight]} ${log.unitType ? texts.unitLabel[log.unitType][weight] : texts.unitLabel.IMPERIAL[weight]}`}
        </StatElement>
        <CommentElement>
          {`Measurements (${log.unitType ? texts.unitLabel[log.unitType].length : texts.unitLabel.IMPERIAL.length})`}
        </CommentElement>
        <MeasurementContainer>
          {Object.keys(measurementsExceptWeight).map((measurement) => (
            log[measurement] && (
              <Measurement key={measurement}>
                <Value>{log[measurement]}</Value>
                <Label>{measurement}</Label>
              </Measurement>
            )))}
        </MeasurementContainer>
        {log.attachments && log.attachments.length > 0 && (
          <ImageContainer>
            {isLoadingUrl ? (
              <StyledSpinner />
            ) : (!!attachmentUrl && (
              <img src={attachmentUrl} alt="attachment" />
            ))}
          </ImageContainer>
        )}
      </ItemContainer>
    );
  }

  return null;
};

TimelineItem.propTypes = {
  log: PropTypes.object.isRequired,
  stat: PropTypes.string,
};

TimelineItem.defaultProps = {
  stat: null,
};

export default TimelineItem;
