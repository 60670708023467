import styled, { css } from 'styled-components';

import { ReactComponent as ChevronRightIcon } from '../../../assets/icons/icon_chevron_right.svg';

import Card, { CardTitle } from '../../../components/Card';
import Toggle from '../../../components/Toggle';
import { LinkButton } from '../../../components/Button';
import { sansSerif } from '../../../styles/text';

const MenuItemContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  font-size: 16px;
  line-height: 22px;
  padding: 15px 25px;
  column-gap: 15px;
  background-color: ${({ theme }) => theme.colors.system.alpha};
  &:not(:last-child)::after {
    content: '';
    position: absolute;
    margin: auto;
    right: 0;
    bottom: 0;
    left: 0;
    width: calc(100% - 50px);
    height: 1px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.system.gamma1};
  }
`;

const StyledChevronRightIcon = styled(ChevronRightIcon)`
  height: 14px;
  width: auto;
  margin-left: auto;
`;

const StyledLinkButton = styled(LinkButton)`
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.base.secondaryVariant};
  padding: 0;
  margin-left: auto;
`;

const commonCardStyles = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

const SettingsCard = styled(Card)`
  ${commonCardStyles};
  padding: 0px 30px 20px;
`;

const SettingsCardTitle = styled(CardTitle)`
  ${sansSerif.headlineFontStyles};
  width: 100%;
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 22px;
  letter-spacing: 2px;
  color: ${({ theme }) => theme.colors.system.gamma};
  margin: 10px 0;
  padding: 0;
`;

const StyledToggle = styled(Toggle)`
  flex-shrink: 0;
`;

export {
  MenuItemContainer,
  StyledChevronRightIcon,
  StyledLinkButton,
  SettingsCard,
  SettingsCardTitle,
  commonCardStyles,
  StyledToggle,
};
