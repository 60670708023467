import { Collection } from 'firestorter';

import { firestorePaths } from '../utils/firebasePaths';
import { formatCurrencyCents } from '../utils/formatters';
import BaseDocument from './BaseDocument';

const ContractState = {
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  PAUSED: 'PAUSED',
  DELINQUENT: 'DELINQUENT',
  IN_PROGRESS: 'IN_PROGRESS',
  CANCELED: 'CANCELED',
};

class UserContract extends BaseDocument {
  constructor(id, opts) {
    super(`${firestorePaths.USER_CONTRACT}/${id}`, opts);
  }

  get current() {
    return typeof this.data.current === 'boolean' ? this.data.current : true;
  }

  get leadId() {
    return this.data.leadId;
  }

  get coach() {
    return this.data.coach;
  }

  get coachName() {
    return this.data.coachName;
  }

  get insideSalesId() {
    return this.data.insideSalesId;
  }

  get productId() {
    return this.data.productId;
  }

  get price() {
    return this.data.price || {};
  }

  get priceId() {
    return this.price.priceId || '';
  }

  get totalPriceInCents() {
    return this.price.totalPriceInCents;
  }

  get totalPriceInCentsStr() {
    return formatCurrencyCents(this.totalPriceInCents, this.currency);
  }

  get currency() {
    return this.price.currency;
  }

  get minSubscriptionMonths() {
    return this.price.minSubscriptionMonths;
  }

  get recurringBillingMonths() {
    return this.price.recurringBillingMonths || 1;
  }

  get initialPaymentInCents() {
    return this.price.initialPaymentInCents;
  }

  get initialTerm() {
    return this.price.initialTerm;
  }

  get cancelAtPeriodEnd() {
    return !!this.price.cancelAtPeriodEnd;
  }

  get startDate() {
    return this.data.startDate;
  }

  get userEmail() {
    return this.data.userEmail;
  }

  get userName() {
    return this.data.userName;
  }

  get userId() {
    return this.data.userId;
  }

  get status() {
    return this.data.status;
  }

  get createdAt() {
    return this.data.createdAt;
  }

  get updatedAt() {
    return this.data.updatedAt;
  }

  get subscriptionCreatedAt() {
    return this.data.subscriptionCreatedAt;
  }

  get coachConditions() {
    return this.data.coachConditions;
  }

  get terms() {
    return this.data.terms || [];
  }

  get coupon() {
    return this.data.coupon;
  }

  markInProgress() {
    return this.update({
      status: ContractState.IN_PROGRESS,
    });
  }

  markPending() {
    if (this.status === ContractState.IN_PROGRESS) {
      return this.update({
        status: ContractState.PENDING,
      });
    }

    return null;
  }

  static async getContractsBy(property, value) {
    const contractsCollection = new Collection(() => firestorePaths.USER_CONTRACT, {
      createDocument: ({ id }, opts) => new UserContract(id, opts),
      query: (ref) => ref
        .where(property, '==', value),
    });
    await contractsCollection.fetch();
    return contractsCollection;
  }

  static async getContractByLeadId(leadId) {
    const contractsCollection = await UserContract.getContractsBy('leadId', leadId);
    if (contractsCollection.hasDocs) {
      return contractsCollection.docs[0];
    }

    return null;
  }

  /**
   * @deprecated This method should be removed in favor of getAllContractsByUserId.
   * @param {string} userId
   * @returns {Object}
   */
  static async getContractByUserId(userId) {
    const contractsCollection = await UserContract.getContractsBy('userId', userId);
    if (contractsCollection.hasDocs) {
      return contractsCollection.docs[0];
    }

    return null;
  }

  /**
   * Get all contracts by userId
   * @param {string} userId
   * @returns {Collection<UserContract>}
   */
  static async getAllContractsByUserId(userId) {
    const contractsCollection = await UserContract.getContractsBy('userId', userId);
    return contractsCollection;
  }

  static async getPendingContractCollectionByUserId(userId) {
    const contractsCollection = new Collection(() => firestorePaths.USER_CONTRACT, {
      createDocument: ({ id }, opts) => new UserContract(id, opts),
      query: (ref) => ref
        .where('userId', '==', userId)
        .where('status', 'in', [ContractState.PENDING, ContractState.IN_PROGRESS]),
    });
    await contractsCollection.fetch();
    return contractsCollection;
  }
}

export default UserContract;
export {
  ContractState,
};
