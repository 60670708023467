import BillingInformation from '../BillingInformation';
import NewContractTerms from './NewContractTerms';
import SuccessPage from './SuccessPage';
import texts from './texts.json';
import {
  StyledRenewSubscriptionIcon,
  StyledRenewSubscriptionSuccessIcon,
  StyledPaymentMethodIcon,
} from './styles';

const reonboardingPages = {
  TERMS: 'TERMS',
  PAYMENT: 'PAYMENT',
  SUCCESS: 'SUCCESS',
};

const reonboardingPageOrder = [
  reonboardingPages.TERMS,
  reonboardingPages.PAYMENT,
  reonboardingPages.SUCCESS,
];

const reonboardingPagesConfig = {
  [reonboardingPages.TERMS]: {
    title: texts.pageLabels[reonboardingPages.TERMS],
    actionLabel: texts.actionLabels[reonboardingPages.TERMS],
    component: NewContractTerms,
    description: texts.pageDescriptions[reonboardingPages.TERMS],
    icon: StyledRenewSubscriptionIcon,
  },
  [reonboardingPages.PAYMENT]: {
    title: texts.pageLabels[reonboardingPages.PAYMENT],
    actionLabel: texts.actionLabels[reonboardingPages.PAYMENT],
    component: BillingInformation,
    description: texts.pageDescriptions[reonboardingPages.PAYMENT],
    icon: StyledPaymentMethodIcon,
  },
  [reonboardingPages.SUCCESS]: {
    title: texts.pageLabels[reonboardingPages.SUCCESS],
    component: SuccessPage,
    actionLabel: texts.actionLabels[reonboardingPages.SUCCESS],
    description: texts.pageDescriptions[reonboardingPages.SUCCESS],
    icon: StyledRenewSubscriptionSuccessIcon,
  },
};

const extractError = (errorResponse) => {
  let error;
  if (errorResponse.error) {
    error = errorResponse.error;
  } else {
    error = errorResponse.raw || errorResponse;
  }
  return {
    error: {
      message: error.message || errorResponse.message || texts.defaultErrorMessage,
      code: error.code,
    },
  };
};

export {
  reonboardingPages,
  reonboardingPageOrder,
  reonboardingPagesConfig,
  extractError,
};
