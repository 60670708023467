import { useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';
import hash from 'object-hash';

const AppThemeCacheKey = {
  APP_THEME_VERSION: 'appThemeVersion',
  APP_THEME: 'appTheme',
};

/**
 * Custom hook that setups and intializes the app custom theme.
 * This must only be used as part of the app customization initilization process.
 *
 * @returns {Object}
 */
const useAppCustomThemeSetup = () => {
  const { updateColorsTheme } = useTheme();

  const setupAppTheme = useCallback(async (appCustomizationDoc) => {
    const hasAppCustomThemeConfigured = appCustomizationDoc && appCustomizationDoc.baseTheme;

    // Check if there is theme cached already
    const appThemeVersion = localStorage.getItem(AppThemeCacheKey.APP_THEME_VERSION);

    if (!hasAppCustomThemeConfigured && appThemeVersion) {
      // There's no longer a custom theme to apply, clean up local cache
      localStorage.removeItem(AppThemeCacheKey.APP_THEME_VERSION);
      localStorage.removeItem(AppThemeCacheKey.APP_THEME);
      return;
    }

    const appThemeCached = JSON.parse(localStorage.getItem(AppThemeCacheKey.APP_THEME));

    // When there's a cached theme, merge it with the built-in theme and apply it as soon as possible
    if (appThemeVersion && appThemeCached) {
      const { colors } = appThemeCached;
      updateColorsTheme(colors);
    }

    /*
      Check if there are changes in the app custom theme from the customization document and
      update the cached theme
    */
    if (hasAppCustomThemeConfigured) {
      const baseThemeDoc = await appCustomizationDoc.getBaseThemeDoc();

      if (baseThemeDoc) {
        const { colors } = baseThemeDoc;
        const currentTheme = { colors };
        const currentAppThemeVersion = hash(currentTheme);

        if (currentAppThemeVersion !== appThemeVersion) {
          // Save the latest theme content and version
          localStorage.setItem(AppThemeCacheKey.APP_THEME, JSON.stringify(currentTheme));
          localStorage.setItem(AppThemeCacheKey.APP_THEME_VERSION, currentAppThemeVersion);

          // Apply the latest colors theme
          updateColorsTheme(colors);
        }
      }
    }
  }, [
    updateColorsTheme,
  ]);

  return useMemo(() => ({
    setupAppTheme,
  }), [
    setupAppTheme,
  ]);
};

export default useAppCustomThemeSetup;
