import styled from 'styled-components';
import { IonToggle } from '@ionic/react';

import { body1 } from '../../styles/text';

const StyledToggle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ToggleName = styled.div`
  ${body1}
`;

const StyledToggleButton = styled(IonToggle)`
  width: 60px;
  --background-checked: ${({ theme }) => theme.colors.base.secondary}
`;

export {
  StyledToggle,
  ToggleName,
  StyledToggleButton,
};
