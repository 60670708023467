import styled from 'styled-components';

import Card from '../../../../components/Card';
import Button from '../../../../components/Button';
import { headline3 } from '../../../../styles/text';

const Title = styled.div`
  ${headline3}
  margin-bottom: 15px;
  text-align: center;
`;

const StyledCard = styled(Card)`
  flex-direction: column;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.base.primary};
  margin-bottom: 16px;

  ${Title} {
    color: ${({ theme }) => theme.colors.system.alpha};
  }
`;

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colors.fonts.primary};
`;

export {
  StyledCard,
  Title,
  StyledButton,
};
