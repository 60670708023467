import React, {
  forwardRef,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

import { WorkoutInfoSwitcherContextProvider } from './context';
import WorkoutInfoSwitcherWithAPI from './WorkoutInfoSwitcherWithAPI';
import { getConfig } from './config';

const WorkoutInfoSwitcher = forwardRef(({
  WorkoutRoutineComponent,
}, ref) => {
  const sectionsConfig = useMemo(() => getConfig({
    WorkoutRoutineComponent,
  }), [
    WorkoutRoutineComponent,
  ]);

  return (
    <WorkoutInfoSwitcherContextProvider>
      <WorkoutInfoSwitcherWithAPI
        sectionsConfig={sectionsConfig}
        ref={ref}
      />
    </WorkoutInfoSwitcherContextProvider>
  );
});

WorkoutInfoSwitcher.propTypes = {
  WorkoutRoutineComponent: PropTypes.elementType,
};

WorkoutInfoSwitcher.defaultProps = {
  WorkoutRoutineComponent: null,
};

export default WorkoutInfoSwitcher;
