import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.system.background};
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  height: 100%;
  padding: 8px 0;
  overflow-y: auto;
`;

export {
  Container,
  Content,
};
