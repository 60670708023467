import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

import { firestorePaths } from '../../utils/firebasePaths';
import useCurrentLoggedInUser from '../../hooks/useCurrentLoggedInUser';
import useComponentMounted from '../../hooks/useComponentMounted';
import useAppCustomization from '../../hooks/useAppCustomization';
import useComponentLoadingTime from '../../hooks/useComponentLoadingTime';
import Coach from '../../models/Coach';
import { Feature } from '../AppCustomizationContext';
import UserContext from '../UserContext';
import CoachContactContext from './CoachContactContext';

const CoachContactContextProvider = ({ children }) => {
  const [isReady, setReady] = useState(false);
  const [canContactCoach, setCanContactCoach] = useState(false);
  const [coachDoc, setCoachDoc] = useState(null);
  const { userDoc, userId } = useContext(UserContext);
  const { isCurrentLoggedInUserInPath } = useCurrentLoggedInUser();
  const isComponentMountedRef = useComponentMounted();
  const { isFeatureEnabled } = useAppCustomization();
  const { startLoading, finishLoading } = useComponentLoadingTime('coachContactContext');

  const calendlySchedulerURL = coachDoc?.calendlySchedulerURL;
  const isEnabled = isFeatureEnabled(Feature.COACH_CALL_SCHEDULING);

  useEffect(() => {
    if (coachDoc && isEnabled) {
      // There's a coach assignment with a valid coach information
      const hasURL = !!calendlySchedulerURL;
      setCanContactCoach(hasURL);

      if (!hasURL && isCurrentLoggedInUserInPath) {
        // Only report this scenario to Sentry when the logged in user is the same user in the path
        Sentry.captureException(
          new Error('Calendly URL for followup calls with the coach could not be found.'),
          {
            extra: {
              coachId: coachDoc.id,
              userId,
            },
          },
        );
      }
    } else {
      setCanContactCoach(false);
    }
  }, [
    userId,
    coachDoc,
    calendlySchedulerURL,
    isCurrentLoggedInUserInPath,
    isEnabled,
  ]);

  useEffect(() => {
    const init = async () => {
      startLoading();
      const assignedCoachDoc = new Coach(`${firestorePaths.COACH}/${userDoc.assignedCoach}`);
      await assignedCoachDoc.init();

      if (isComponentMountedRef.current) {
        setCoachDoc(assignedCoachDoc);
        setReady(true);
        finishLoading();
      }
    };

    if ((!isReady && isEnabled) || (isReady && isEnabled && !coachDoc)) {
      init();
    } else {
      setReady(true);
    }
  }, [
    isReady,
    isEnabled,
    userDoc,
    coachDoc,
    isComponentMountedRef,
    startLoading,
    finishLoading,
  ]);

  const value = useMemo(() => ({
    coachDoc,
    canContactCoach,
    isReady,
  }), [
    coachDoc,
    canContactCoach,
    isReady,
  ]);

  return (
    <CoachContactContext.Provider value={value}>
      {children}
    </CoachContactContext.Provider>
  );
};

CoachContactContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(CoachContactContextProvider);
