import React from 'react';
import { ChannelBuckets } from '../util/buckets';
import ChatState from './states';

const chatContextInitialState = {
  isMultiChannelView: false,
  isChatModalOpen: false,
  isChatReady: false,
  toggleChatModal: () => {},
  chatClient: null,
  totalUnreadCount: 0,
  setTotalUnreadCount: () => {},
  onChatRefresh: () => {},
  onChatError: () => {},
  chatState: ChatState.CHAT_NOT_INITIALIZED,
  initialChatMenuOpen: false,
  setInitialChatMenuOpen: () => {},
  disconnectUser: () => {},
  openChatModal: () => {},
  userId: null,
  readOnlyMode: false,
  onMarkChannelAsRead: () => {},
  customActiveChannel: null,
  isSearching: false,
  searchQuery: '',
  shouldCheckForGreetingMessage: false,
  showGreetingMessageIfAny: () => {},
  selectedBucket: ChannelBuckets.ACTIVE,
  chatMenuRef: null,
  toggleChatMenu: () => {},
  openChatMenu: () => {},
  isBroadcastOpen: false,
  setIsBroadcastOpen: () => {},
  sendBroadcastMessage: () => {},
};

const ChatContext = React.createContext(chatContextInitialState);

export default ChatContext;
