const ProgressStatus = {
  COMPLETED: 'COMPLETED',
  AWAITING: 'AWAITING',
  INACTIVE: 'INACTIVE',
};

const isActiveProgressStatus = (status) => (
  status !== ProgressStatus.AWAITING && status !== ProgressStatus.INACTIVE
);

export {
  ProgressStatus,
  isActiveProgressStatus,
};
