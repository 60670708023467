import React, { useEffect, useContext, useCallback } from 'react';
import { compose } from 'recompose';
import { IonToggle, IonInput } from '@ionic/react';

import AppContext from '../../context/AppContext';
import { withLayout, HeaderContext } from '../../components/Layout';
import { CardTitle } from '../../components/Card';
import {
  Container,
  StyledCard,
  Item,
} from './styles';

const DebugSettings = () => {
  const { setTitle } = useContext(HeaderContext);
  const {
    debugSettings: {
      isDebugMode,
      setIsDebugMode,
      isTestVideoEnabled,
      setIsTestVideoEnabled,
      testVideoName,
      setTestVideoName,
      testVideoServerURL,
      setTestVideoServerURL,
    },
  } = useContext(AppContext);

  useEffect(() => {
    setTitle('Debug Settings');
  }, [setTitle]);

  const onDebugModeToggle = useCallback((event) => setIsDebugMode(event.detail.checked), [setIsDebugMode]);

  const onTestVideoToggle = useCallback(
    (event) => setIsTestVideoEnabled(event.detail.checked),
    [setIsTestVideoEnabled],
  );

  const onTestVideoNameChanged = useCallback((event) => setTestVideoName(event.detail.value), [setTestVideoName]);

  const onTestVideoURLChanged = useCallback(
    (event) => setTestVideoServerURL(event.detail.value),
    [setTestVideoServerURL],
  );

  return (
    <Container>
      <StyledCard>
        <CardTitle>Debug Mode</CardTitle>
        <Item>
          <span>
            Enable debug mode
          </span>
          <IonToggle
            checked={isDebugMode}
            onIonChange={onDebugModeToggle}
          />
        </Item>
      </StyledCard>
      <StyledCard>
        <CardTitle>Gameplay Test Video</CardTitle>
        <Item>
          <span>
            Enable test video
          </span>
          <IonToggle
            checked={isTestVideoEnabled}
            onIonChange={onTestVideoToggle}
          />
        </Item>
        <Item column>
          <span>
            Video Server URL
          </span>
          <IonInput
            value={testVideoServerURL}
            placeholder="URL"
            onIonChange={onTestVideoURLChanged}
            disabled={!isTestVideoEnabled}
            clearInput
          />
        </Item>
        <Item column>
          <span>
            Video name
          </span>
          <IonInput
            value={testVideoName}
            placeholder="vide-name.mp4"
            onIonChange={onTestVideoNameChanged}
            disabled={!isTestVideoEnabled}
            clearInput
          />
        </Item>
      </StyledCard>
    </Container>
  );
};

export default compose(
  withLayout,
)(DebugSettings);
