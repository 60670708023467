import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import logEvent from '../../utils/logger';
import SubscriptionSlideDrawerContext, { initialState } from './SubscriptionSlideDrawerContext';

const SubscriptionSlideDrawerContextProvider = ({
  children,
}) => {
  const [isSliderOpen, setIsSliderOpen] = useState(initialState.isSliderOpen);

  const openSlider = useCallback(() => {
    logEvent('subscriptionSlideDrawerOpen');
    setIsSliderOpen(true);
  }, []);

  const closeSlider = useCallback(() => {
    logEvent('subscriptionSlideDrawerClose');
    setIsSliderOpen(false);
  }, []);

  const value = useMemo(() => ({
    isSliderOpen,
    openSlider,
    closeSlider,
  }), [
    isSliderOpen,
    openSlider,
    closeSlider,
  ]);

  return (
    <SubscriptionSlideDrawerContext.Provider value={value}>
      {children}
    </SubscriptionSlideDrawerContext.Provider>
  );
};

SubscriptionSlideDrawerContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SubscriptionSlideDrawerContextProvider;
