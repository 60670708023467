import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import { ReactComponent as AddIcon } from '../../../assets/icons/icon_plus.svg';
import { ReactComponent as AddCircuitIcon } from '../../../assets/icons/icon_plus_square.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/icon_edit.svg';

import WorkoutContext from '../../../context/WorkoutContext';
import ActivityDefinition from '../../models/ActivityDefinition';
import { ActivityActionTypes } from '../../models/ActivityAction';
import ActivitiesList from '../WorkoutDetails/ActivitiesList';
import ActionButton from '../ActionButton';

import {
  BtnGroup,
  StyledWorkoutDetails,
  WorkHeader,
  WorkoutName,
  StyledWorkoutBaseData,
} from './styles';
import texts from './texts.json';

const WorkoutEditorDetails = ({
  isEditorMode,
  onEditorModeActionPerform,
  onAdd,
  onEditWorkoutInfoClick,
}) => {
  const { workoutDoc } = useContext(WorkoutContext);
  const {
    workoutDefinition: {
      name,
      note,
      estimatedDuration,
    },
  } = workoutDoc;

  const activities = workoutDoc.jsonActivities();

  const preparedActivities = useMemo(() => (
    activities.map((activityItem) => new ActivityDefinition(activityItem))
  ), [activities]);

  return (
    <>
      <StyledWorkoutDetails>
        <WorkHeader>
          <WorkoutName>{name}</WorkoutName>
          {isEditorMode && (
            <BtnGroup>
              <ActionButton
                name={texts.editWorkoutInfo}
                title={texts.editWorkoutInfo}
                onClick={onEditWorkoutInfoClick}
              >
                <EditIcon />
              </ActionButton>
              <ActionButton
                name={texts.addActivity}
                title={texts.addActivity}
                onClick={() => onAdd(ActivityActionTypes.ADD)}
              >
                <AddIcon />
              </ActionButton>
              <ActionButton
                name={texts.addCircuit}
                title={texts.addCircuit}
                onClick={() => onAdd(ActivityActionTypes.ADD_CIRCUIT)}
              >
                <AddCircuitIcon />
              </ActionButton>
            </BtnGroup>
          )}
        </WorkHeader>
        <StyledWorkoutBaseData
          note={note}
          estimatedDuration={estimatedDuration}
          activities={preparedActivities}
        />
        <ActivitiesList
          activities={preparedActivities}
          isEditorMode={isEditorMode}
          onEditorModeActionPerform={onEditorModeActionPerform}
        />
      </StyledWorkoutDetails>
    </>
  );
};

WorkoutEditorDetails.propTypes = {
  isEditorMode: PropTypes.bool.isRequired,
  onEditorModeActionPerform: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onEditWorkoutInfoClick: PropTypes.func.isRequired,
};

export default compose(
  observer,
)(WorkoutEditorDetails);
