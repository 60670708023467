import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { SentryLightSDK } from 'capacitor-sentry-light-sdk';

import { withFirebase } from '../../context/FirebaseContext';
import User from '../../models/User';
import UserConfigDoc from '../../models/UserConfig';
import useSessionStore from '../../hooks/useSessionStore';
import { identify, reset } from '../../utils/logger';

const Auth = ({
  firebase,
  children,
}) => {
  const sessionStore = useSessionStore();
  const [isSessionInitialized, setIsSessionInitialized] = useState(false);

  /*
    Initialize the sessionStore authUser only once, the first time the render is called.
    This simulates a constructor-like behaviour.
  */
  if (!isSessionInitialized) {
    const coachAssistantStored = localStorage.getItem('isCoachAssistant');
    const coachAssistantValue = coachAssistantStored === 'undefined' ? null : coachAssistantStored;

    sessionStore.setAuthUser(
      JSON.parse(localStorage.getItem('authUser')),
      JSON.parse(localStorage.getItem('userClaims')),
      JSON.parse(coachAssistantValue),
    );
    setIsSessionInitialized(true);
  }

  const onAuthUserChanged = async (authUser) => {
    /*
      firebase.auth.onAuthStateChanged is called right after we create a new anonymous user but anonymous user might
      be deleted if we detect, during email association, that that email is already used by another anonymous user.
      There isn't other way to determine this as
      https://firebase.google.com/docs/reference/js/firebase.auth.Auth#fetchsigninmethodsforemail won't return anything
      when the e-mail provided is associated to another anonymous user.
      So, we make sure that we do this session initialization only when the provided user does have an email associated
      (even if it's anonymous)
    */
    if (authUser && authUser.email) {
      const { claims } = await authUser.getIdTokenResult();

      localStorage.setItem('authUser', JSON.stringify(authUser));
      localStorage.setItem('userClaims', JSON.stringify({ ...claims }));

      let userDoc;
      try {
        userDoc = new User(`/user/${authUser.uid}`);
        await userDoc.init();
      } catch (error) {
        Sentry.captureException(error);
      }

      const isCoachAssistant = userDoc && userDoc.isCoachAssistant;
      localStorage.setItem('isCoachAssistant', isCoachAssistant);

      sessionStore.setAuthUser(authUser, claims, isCoachAssistant);

      // Update the analytics user data
      const {
        displayName,
        email,
        emailVerified,
      } = authUser;

      const {
        uid,
      } = authUser;

      identify(uid, {
        displayName,
        email,
        emailVerified,
      });

      // Setup user in Sentry (web and native SDKs)
      Sentry.setUser({ id: uid });
      SentryLightSDK.setUser({ id: uid });

      // Update the user doc in the profile
      const userConfigDoc = new UserConfigDoc(`/config/${uid}`);
      await userConfigDoc.init();
      userConfigDoc.updateLastVisit();
    } else {
      localStorage.removeItem('authUser');
      localStorage.removeItem('userClaims');
      localStorage.removeItem('isCoachAssistant');
      sessionStore.setAuthUser(null, null, false);

      // Log out from analytics as well
      reset();

      // Clear Sentry user
      Sentry.configureScope((scope) => scope.setUser(null));
      SentryLightSDK.clearUser();
    }
  };

  useEffect(() => {
    const listener = firebase.auth.onAuthStateChanged(onAuthUserChanged);
    sessionStore.onAuthUserChanged(onAuthUserChanged);

    return () => {
      listener();
      sessionStore.removeListeners();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <>{children}</>;
};

Auth.propTypes = {
  firebase: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default compose(
  withFirebase,
)(Auth);
