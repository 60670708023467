import React from 'react';

import {
  Title,
  Paragraph,
  Wrapper,
  HighlightContainer,
  HighlightTitle,
  HighlightList,
} from '../commonStyles';
import texts from './texts.json';

const MealPlanFaqs = () => {
  const renderHighlights = (highlights) => (
    <>
      {
        highlights.map(({
          id: highlightKey,
          title,
          items,
          description,
        }) => (
          <HighlightContainer key={highlightKey}>
            {!!title && <HighlightTitle>{title}</HighlightTitle>}
            {!!items && items.length > 0 && (
              <HighlightList>
                {items.map((item) => <li key={item}>{item}</li>)}
              </HighlightList>
            )}
            {!!description && <div>{description}</div>}
          </HighlightContainer>
        ))
      }
    </>
  );

  const renderFaqs = () => {
    const { faqs } = texts;

    return faqs.map(({
      id,
      question,
      answer,
      highlights,
    }) => (
      <Wrapper key={id}>
        <Title>{question}</Title>
        {!!answer && <Paragraph>{answer}</Paragraph>}
        {!!highlights && highlights.length > 0 && renderHighlights(highlights)}
      </Wrapper>
    ));
  };

  return (
    <>
      {renderFaqs()}
    </>
  );
};

export default MealPlanFaqs;
