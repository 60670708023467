import styled from 'styled-components';

import Button from '../../Button';

const StyledButton = styled(Button)`
  width: 32px;
  height: 32px;
  background: transparent;
  border: none;
  padding: 0;
  position: relative;

  > svg {
    height: 18px;
  }
`;

export default StyledButton;
