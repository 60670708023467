import React from 'react';

import LogContextProvider from './LogContextProvider';

/**
 * Inserts LogContextProvider in the react tree
 * before adding the given component.
 *
 * @param {Object} Component The component that needs the context provider.
 * @returns {Object} The component to render wrapped with the provider.
 */
const withLogContextProvider = (Component) => (props) => (
  <LogContextProvider>
    <Component {...props} />
  </LogContextProvider>
);

export {
  withLogContextProvider,
};
