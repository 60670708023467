import styled, { css } from 'styled-components';

const ProgressBarContainer = styled.div`
  height: 15px;
  display: flex;
  align-items: center;
`;

const getSegmentPropRules = ({
  $filled,
  $active,
  $enableHighContrast,
  theme,
}) => {
  const heightValue = $active ? '15px' : '5px';
  const flexValue = $active ? 1.5 : 1;
  const filterValue = $enableHighContrast ? 'none' : 'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))';
  let backgroundColor;
  const { colors } = theme;

  if ($filled) {
    backgroundColor = colors.base.secondary;
  } else if ($enableHighContrast) {
    backgroundColor = colors.system.gamma2;
  } else {
    backgroundColor = colors.system.alpha;
  }

  return css`
    background-color: ${backgroundColor};
    height: ${heightValue};
    flex: ${flexValue};
    filter: ${filterValue};
  `;
};

const Segment = styled.div`
  border-radius: 2px;
  margin: 0 2px;
  transition: all 0.3s;

  ${(props) => getSegmentPropRules(props)};
`;

export {
  ProgressBarContainer,
  Segment,
};
