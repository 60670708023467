import React, {
  useMemo,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

import UserContext from '../../../../context/UserContext';
import AssignmentDayType from '../../viewModel/assignmentType';
import {
  isSameMonth,
  isFirstDayOfMonth,
  toWeekdayDate,
  toWeekdayDateOfMonth,
} from '../../../../utils/date';

import DefaultDayCard from './DefaultDayCard';
import TrackedActivitySummaryDayCardContainer from './TrackedActivitySummaryDayCard';
import texts from './texts.json';

const DayCardContainer = ({
  date,
  assignmentDayType,
  trackedActivitySummary,
}) => {
  const {
    userConfigDoc: {
      isHealthDataEnabled,
    },
  } = useContext(UserContext);

  const headerDate = useMemo(() => {
    if (assignmentDayType === AssignmentDayType.TODAY) {
      return texts.todayText;
    }

    if (isSameMonth(date) && !isFirstDayOfMonth(date)) {
      return toWeekdayDate(date);
    }

    return toWeekdayDateOfMonth(date);
  }, [
    assignmentDayType,
    date,
  ]);

  const dayCardProps = {
    date: headerDate,
  };

  /*
    Displaying TrackedActivitySummary is enabled when:
      - The user has enabled health data for summary in app settings (user config doc)
      - Tracked Activity Summary data exists
  */
  return (isHealthDataEnabled && trackedActivitySummary)
    ? (
      <TrackedActivitySummaryDayCardContainer
        {...dayCardProps}
        trackedActivitySummary={trackedActivitySummary}
      />
    )
    : <DefaultDayCard {...dayCardProps} />;
};

DayCardContainer.propTypes = {
  date: PropTypes.object.isRequired,
  assignmentDayType: PropTypes.oneOf(Object.values(AssignmentDayType)).isRequired,
  trackedActivitySummary: PropTypes.object,
};

DayCardContainer.defaultProps = {
  trackedActivitySummary: null,
};

export default DayCardContainer;
