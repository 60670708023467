import {
  useCallback,
  useMemo,
  useContext,
  useRef,
  useEffect,
} from 'react';
import moment from 'moment';
import * as Sentry from '@sentry/browser';

import CollectionName from '../utils/collections';
import logEvent from '../utils/logger';
import {
  calculateUTCDay,
  convertToLocalDate,
  isFutureDay,
} from '../utils/date';
import WorkoutAssignment, {
  workoutAssignmentStatuses,
  createWorkoutAssignmentId,
} from '../models/WorkoutAssignment';
import WorkoutFeedView, { WorkoutFeedViewType } from '../models/WorkoutFeedView';
import UserContext from '../context/UserContext';

const NEW_WORKOUT_ASSIGNMENT_INIT_TIMER = 1000;

const useWorkoutAssignmentEdition = () => {
  const {
    userId,
    userDoc: {
      firstName: userName,
      assignedCoach,
    },
  } = useContext(UserContext);

  const initNewWorkoutTimerIdRef = useRef();

  /**
   * Create a new WorkoutAssignment and WorkoutFeedView documents using the given data.
   *
   * @param {Object} data Workout assignment data.
   * @param {string} workoutName The name of the Workout.
   * @returns {Object} The new WorkoutAssignment instance created.
   */
  const createWorkoutAssignmentFromData = useCallback((data, workoutName) => {
    const workoutAssignmentId = createWorkoutAssignmentId(userName, workoutName, moment());
    const workoutAssignment = new WorkoutAssignment(`/${CollectionName.WORKOUT_ASSIGNMENT}/${workoutAssignmentId}`);
    const workoutFeedView = new WorkoutFeedView(workoutAssignmentId);

    // Prepare WorkoutFeedView data
    const {
      status,
      startDate,
      endDate,
      user,
      workoutContent,
      assignedBy,
      // Optional fields
      previewImageLink,
      originalWorkoutAssignment,
      workoutStartedDate,
      workoutEndDate,
    } = data;

    const type = assignedBy === user
      ? WorkoutFeedViewType.SELF_ASSIGNED_WORKOUT
      : WorkoutFeedViewType.COACH_ASSIGNED_WORKOUT; // By default, a coach assigned workout

    const workoutFeedViewData = {
      status,
      startDate,
      endDate,
      user,
      type,
      title: workoutContent ? workoutContent.name : '',
      lastUpdatedDateTime: moment.utc().toDate(),
    };

    if (previewImageLink) {
      workoutFeedViewData.previewImageLink = previewImageLink;
    }

    if (originalWorkoutAssignment) {
      workoutFeedViewData.originalWorkoutAssignment = originalWorkoutAssignment;
    }

    if (workoutStartedDate) {
      /*
        Replace the assigned startDate by the real workout started date, so queries done in the client
        works as expected: workout feed views ordered by startDate.
      */
      workoutFeedViewData.startDate = workoutStartedDate;
      workoutFeedViewData.originalStartDate = startDate;

      // Update endDate when workoutEndDate exists, to have consistency beetween startDate and endDate
      if (workoutEndDate) {
        workoutFeedViewData.endDate = workoutEndDate;
        workoutFeedViewData.originalEndDate = endDate;
      }
    }

    workoutAssignment.set(data);
    workoutFeedView.set(workoutFeedViewData);

    return workoutAssignment;
  }, [
    userName,
  ]);

  /**
   * Initialize and fetch the newly cloned WorkoutAssignment document. This process is done in a maximum
   * number of attempts due to a specific behaviour of firestore rules and this scenario:
   * - The document is created first in the local cache.
   * - Then it is "initialized", internally calls fetch and try to sync with the remote server.
   * - At that exact point, the document is not ready in the server.
   * - The WorkoutAssignment "read" Firestore rules applies, and even if we set permissions to read "empty" documents,
   * that doesn't work. https://github.com/Fitmoola/system2-server/blob/master/firestore.rules#L70
   * - As a result, the user gets a "Missing or insufficient permissions error" and the firestorter document
   * instance doesn't have data available (no snapshot available).
   *
   * @param {Object} workoutAssignmentDoc WorkoutAssignment document instance to initialize.
  */
  const initNewWorkoutAssignment = useCallback(async (workoutAssignmentDoc) => (
    new Promise((resolve, reject) => {
      let retries = 0;
      const totalRetries = 15;
      const { id: newWorkoutAssignmentId } = workoutAssignmentDoc;

      const init = async () => {
        try {
          await workoutAssignmentDoc.init();

          logEvent('workoutAssignmentInitialized', {
            totalRetries: retries,
            newWorkoutAssignmentId,
          });

          clearTimeout(initNewWorkoutTimerIdRef.current);
          resolve();
        } catch (error) {
          if (retries < totalRetries) {
            retries += 1;
            // Try again
            initNewWorkoutTimerIdRef.current = setTimeout(init, NEW_WORKOUT_ASSIGNMENT_INIT_TIMER);
          } else {
            Sentry.captureException(error, {
              extra: {
                description: 'Error trying to initialize/fetch cloned WorkoutAssignment',
                attempts: totalRetries,
                newWorkoutAssignmentId,
              },
            });

            logEvent('newWorkoutAssignmentInitFailed', {
              totalRetries: retries,
              newWorkoutAssignmentId,
            });

            reject(error);
          }
        }
      };

      init();
    })
  ), []);

  /**
   * Create a new workout assignment from the given workout.
   *
   * @param {Object} workoutDoc
   * @param {Object} workoutAssignmentDoc
   * @returns {Object} The newly created WorkoutAssignment document instance.
   */
  const createWorkoutAssignment = useCallback(async (workoutDoc) => {
    const now = moment();

    const {
      id: workoutId,
      data: workoutData,
      name: workoutName,
    } = workoutDoc;

    const {
      startDate,
      endDate,
    } = calculateUTCDay(now);

    const data = {
      coach: assignedCoach,
      user: userId,
      assignedBy: userId,
      startDate,
      endDate,
      createdAt: moment.utc(now).toDate(),
      lastUpdatedTimestamp: moment.utc().toDate(),
      status: workoutAssignmentStatuses.ASSIGNED,
      workout: workoutId,
      workoutContent: {
        __metadata: {
          segments: ['workout', workoutId],
        },
        _id: workoutId,
        ...workoutData,
      },
    };

    const newWorkoutAssignmentDoc = createWorkoutAssignmentFromData(data, workoutName);

    await initNewWorkoutAssignment(newWorkoutAssignmentDoc);

    return newWorkoutAssignmentDoc;
  }, [
    userId,
    assignedCoach,
    createWorkoutAssignmentFromData,
    initNewWorkoutAssignment,
  ]);

  /**
   * Clone an existent workout assignment, creating a new workout assignment instance.
   *
   * @param {Object} workoutAssignmentDoc The WorkoutAssignment instance to clone.
   * @return {Object} New workout assignment that clones the given one.
   */
  const cloneWorkoutAssignment = useCallback(async (workoutAssignmentDoc) => {
    const {
      id: originalWorkoutAssignment,
      name,
      coach,
      assignedBy,
    } = workoutAssignmentDoc;

    const {
      startDate,
      endDate,
    } = calculateUTCDay(moment());

    // Clone the workout to create a new workout with today's date
    const {
      completedBy,
      ...otherWorkoutAssignmentFields
    } = workoutAssignmentDoc.data;

    const data = {
      ...otherWorkoutAssignmentFields,
      status: workoutAssignmentStatuses.ASSIGNED,
      assignedBy: assignedBy || coach,
      lastUpdatedTimestamp: moment.utc().toDate(),
      workoutStartedDate: startDate,
      workoutEndDate: endDate,
      originalWorkoutAssignment,
    };

    const newWorkoutAssignmentDoc = createWorkoutAssignmentFromData(data, name);
    await initNewWorkoutAssignment(newWorkoutAssignmentDoc);

    return newWorkoutAssignmentDoc;
  }, [
    createWorkoutAssignmentFromData,
    initNewWorkoutAssignment,
  ]);

  /**
   * Update the given workout assignment indicating that it's ready to start.
   * This sets the start and end dates to today's date, for both documents: workout assignment
   * and the workout feed view counterpart.
   */
  const updateWorkoutAssignmentToStart = useCallback(async (workoutAssignmentDoc) => {
    const {
      startDate,
      endDate,
    } = calculateUTCDay(moment());

    workoutAssignmentDoc.updateFields({
      workoutStartedDate: startDate,
      workoutEndDate: endDate,
    });

    // Update workout feed view
    const workoutFeedView = new WorkoutFeedView(workoutAssignmentDoc.id);
    workoutFeedView.updateFields({
      startDate,
      endDate,
      originalStartDate: workoutAssignmentDoc.startDate,
      originalEndDate: workoutAssignmentDoc.endDate,
    });

    return workoutAssignmentDoc;
  }, []);

  const prepareWorkoutAssignmentForToday = useCallback(async (workoutAssignmentDoc) => {
    const startMomentDate = moment(workoutAssignmentDoc.latestWorkoutStartDate.toMillis());
    const startMomentLocalDate = convertToLocalDate(startMomentDate);
    const isFutureWorkout = isFutureDay(startMomentLocalDate);

    if (workoutAssignmentDoc.isCompleted || isFutureWorkout) {
      // Clone the workout if it's already completed or is a workout assigned to a future date
      return cloneWorkoutAssignment(workoutAssignmentDoc);
    }
    // Update current workout assignment and workout feed view
    return updateWorkoutAssignmentToStart(workoutAssignmentDoc);
  }, [
    cloneWorkoutAssignment,
    updateWorkoutAssignmentToStart,
  ]);

  useEffect(() => (
    () => clearTimeout(initNewWorkoutTimerIdRef.current)
  ), []);

  return useMemo(() => ({
    createWorkoutAssignment,
    prepareWorkoutAssignmentForToday,
    updateWorkoutAssignmentToStart,
  }), [
    createWorkoutAssignment,
    prepareWorkoutAssignmentForToday,
    updateWorkoutAssignmentToStart,
  ]);
};

export default useWorkoutAssignmentEdition;
