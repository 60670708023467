import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import format from 'string-template';

import { WorkoutFeedViewType, isWorkoutAssignmentType } from '../models/WorkoutFeedView';
import useUserNavigation from './useUserNavigation';

const pathPlaceholder = {
  userPath: 'userPath',
  workoutTypePath: 'workoutTypePath',
};

const workoutTypePaths = {
  [WorkoutFeedViewType.CUSTOM_WORKOUT]: 'customWorkout',
  [WorkoutFeedViewType.COACH_ASSIGNED_WORKOUT]: 'workoutAssignment',
  [WorkoutFeedViewType.TRACKED_ACTIVITY]: 'trackedActivity',
};

const WORKOUT_PATH_TEMPLATE = `{${pathPlaceholder.userPath}}/{${pathPlaceholder.workoutTypePath}}`;

const getWorkoutTypePath = (userPathName, workoutType) => {
  const workoutTypePath = workoutTypePaths[workoutType] || workoutTypePaths[WorkoutFeedViewType.COACH_ASSIGNED_WORKOUT];
  return format(WORKOUT_PATH_TEMPLATE, {
    [pathPlaceholder.userPath]: userPathName,
    [pathPlaceholder.workoutTypePath]: workoutTypePath,
  });
};

/**
 * This React Hook allows navigation to the specified workout path.
 */
const useWorkoutNavigation = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { pathname: userPathName } = useUserNavigation();

  const getWorkoutPathname = useCallback((workoutId, workoutType = WorkoutFeedViewType.COACH_ASSIGNED_WORKOUT) => {
    let pathname = `${getWorkoutTypePath(userPathName, workoutType)}/${workoutId}`;

    if (isWorkoutAssignmentType(workoutType)) {
      pathname = `${pathname}/details`;
    }

    return pathname;
  }, [
    userPathName,
  ]);

  const customWorkoutPath = useMemo(() => (
    getWorkoutTypePath(userPathName, WorkoutFeedViewType.CUSTOM_WORKOUT)
  ), [userPathName]);
  const workoutAssignmentPath = useMemo(() => (
    getWorkoutTypePath(userPathName, WorkoutFeedViewType.COACH_ASSIGNED_WORKOUT)
  ), [userPathName]);
  const trackedActivityPath = useMemo(() => (
    getWorkoutTypePath(userPathName, WorkoutFeedViewType.TRACKED_ACTIVITY)
  ), [userPathName]);

  const navigateToWorkout = useCallback((
    workoutId,
    type = WorkoutFeedViewType.COACH_ASSIGNED_WORKOUT,
    other = {},
  ) => {
    const opts = {
      ...other,
      pathname: getWorkoutPathname(workoutId, type),
      search,
    };

    const { shouldReplaceURL = false } = other;

    if (shouldReplaceURL) {
      history.replace(opts);
    } else {
      history.push(opts);
    }
  }, [
    getWorkoutPathname,
    history,
    search,
  ]);

  return {
    navigateToWorkout,
    getWorkoutPathname,
    customWorkoutPath,
    workoutAssignmentPath,
    trackedActivityPath,
  };
};

export default useWorkoutNavigation;
