import React from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';

import text from './text.json';
import {
  CommentAlert,
} from './styles';

const CoachCommentAlert = ({
  isOpen,
  label,
  onCloseCommentAlert,
  comment,
}) => (
  <CommentAlert
    isOpen={isOpen}
    header={format(text.commentModal.coach.message, { label })}
    buttons={[{
      text: text.commentModal.coach.close,
      role: 'cancel',
      handler: onCloseCommentAlert,
    }]}
    inputs={[
      {
        type: 'textarea',
        name: 'comment',
        value: comment,
        cssClass: 'commentTextArea',
        attributes: {
          disabled: true,
        },
      },
    ]}
  />
);

CoachCommentAlert.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onCloseCommentAlert: PropTypes.func.isRequired,
  comment: PropTypes.string.isRequired,
};

export default CoachCommentAlert;
