import React from 'react';
import ReactDOM from 'react-dom';
import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import * as Sentry from '@sentry/browser';
import { Provider } from 'mobx-react';
import { SentryLightSDK } from 'capacitor-sentry-light-sdk';
import { posthog } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

import { FirebaseContextProvider } from './context/FirebaseContext';
import { RemoteConfigContextProvider } from './context/RemoteConfigContext';

import App from './App';
import store from './stores';
import config from './config';
import { REACT_APP_ROOT_ID } from './utils/constants';

const {
  sessionStore,
  gameplayStore,
  firebase,
} = store;

const {
  posthog: { apiHost, apiKey },
  sentry: { projectId, organization },
} = config;

posthog.init(apiKey, { api_host: apiHost });

const setupSentry = () => {
  const {
    sentry: { dsn, environment },
  } = config;

  const options = {
    enableOutOfMemoryTracking: false,
    dsn,
    release: process.env.REACT_APP_VERSION,
    environment,
    integrations: [new posthog.SentryIntegration(
      posthog,
      organization,
      projectId,
      ['error', 'info'],
    )],
  };

  Sentry.init(options);
  // Initializes native Sentry SDK
  SentryLightSDK.initNativeSdk({
    options,
  }).catch((error) => {
    Sentry.captureException(error, {
      extra: {
        description: 'Failed to initialize native Sentry SDK',
      },
    });
  });
};

setupSentry();

ReactDOM.render(
  <PostHogProvider client={posthog}>
    <IonApp>
      <Provider
        sessionStore={sessionStore}
        gameplayStore={gameplayStore}
      >
        <FirebaseContextProvider firebase={firebase}>
          <RemoteConfigContextProvider firebaseRemoteConfig={firebase.remoteConfig}>
            <IonReactRouter>
              <App />
            </IonReactRouter>
          </RemoteConfigContextProvider>
        </FirebaseContextProvider>
      </Provider>
    </IonApp>
  </PostHogProvider>,
  document.getElementById(REACT_APP_ROOT_ID),
);
