import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import * as Sentry from '@sentry/browser';
import { SentryLightSDK } from 'capacitor-sentry-light-sdk';
import { Device as DevicePlugin } from '@capacitor/device';

import Device from '../../models/Device';
import { firestorePaths } from '../../utils/firebasePaths';
import useCurrentLoggedInUser from '../../hooks/useCurrentLoggedInUser';
import useComponentLoadingTime from '../../hooks/useComponentLoadingTime';

import UserContext from '../UserContext';
import FirebaseContext from '../FirebaseContext';
import FCMContext from '../FCMContext';

import DeviceContext from './DeviceContext';

const DeviceContextProvider = ({
  children,
}) => {
  const { firebase } = useContext(FirebaseContext);
  const { userDoc } = useContext(UserContext);
  const { fcmToken } = useContext(FCMContext);

  const { isCurrentLoggedInUserInPath } = useCurrentLoggedInUser();
  const { startLoading, finishLoading } = useComponentLoadingTime('deviceContext');

  /*
    Consider this context to be ready if the current logged in user doesn't match the userId
    in the UserRoutes path. This will prevent any device-related initialization.
    Consumers of this context should always handle "null" as a possible value of device.
  */
  const [isReady, setIsReady] = useState(!isCurrentLoggedInUserInPath);
  const [device, setDevice] = useState(null);

  const addDevice = useCallback(async () => {
    const {
      operatingSystem,
      osVersion,
      platform,
      model,
      manufacturer,
      isVirtual,
    } = await DevicePlugin.getInfo();

    const {
      identifier: uuid,
    } = await DevicePlugin.getId();

    const newDeviceData = {
      uuid,
      user: userDoc.id,
      deviceInfo: {
        operatingSystem,
        osVersion,
        platform,
        model,
        manufacturer,
        isVirtual,
      },
    };

    const userDeviceCollection = firebase.firestore.collection(firestorePaths.USER_DEVICE);
    const newUserDeviceDoc = userDeviceCollection.doc();
    const userDeviceDoc = new Device(newUserDeviceDoc.path);
    userDeviceDoc.set(newDeviceData);
    return userDeviceDoc;
  }, [
    firebase,
    userDoc.id,
  ]);

  useEffect(() => {
    let shouldUpdate = true;

    const init = async () => {
      startLoading();
      let userDevice = null;

      try {
        const { identifier: uuid } = await DevicePlugin.getId();

        userDevice = await Device.get(userDoc.id, uuid);

        if (!userDevice) {
          userDevice = await addDevice();
        }

        if (!userDevice.isActive) {
          userDevice.activate();
        }

        // Setup system2.deviceId tag in Sentry
        const deviceIdTagName = 'system2.deviceId';
        Sentry.setTag(deviceIdTagName, uuid);
        SentryLightSDK.setTags({
          tags: [{
            key: deviceIdTagName,
            value: uuid,
          }],
        });

        userDevice.updateLastAccess();
      } catch (err) {
        Sentry.captureException(err);
      }

      if (shouldUpdate) {
        setDevice(userDevice);
        setIsReady(true);
        finishLoading();
      }
    };

    if (!isReady) {
      init();
    }

    return () => {
      shouldUpdate = false;
    };
  }, [
    isReady,
    userDoc.id,
    addDevice,
    startLoading,
    finishLoading,
  ]);

  useEffect(() => {
    if (device && fcmToken && device.fcmToken !== fcmToken) {
      device.updateFields({
        fcmToken,
      });
    }
  }, [
    device,
    fcmToken,
  ]);

  const context = useMemo(() => ({
    device,
    isReady,
  }), [
    device,
    isReady,
  ]);

  return (
    <DeviceContext.Provider value={context}>
      {children}
    </DeviceContext.Provider>
  );
};

DeviceContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(DeviceContextProvider);
