import styled, { css } from 'styled-components';

import { headlineFontStyles } from '../../../../../styles/text';

const Option = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px;
  margin-top: 8px;
  border-radius: 10px;
  ${({ selected }) => (selected
    ? css`
    background-color: ${({ theme }) => theme.colors.base.secondary};`
    : css`
    background-color: ${({ theme }) => theme.colors.system.background};`)}
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 30px;
`;

const Title = styled.h1`
  ${headlineFontStyles}
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  margin: 0;
`;

export {
  Title,
  OptionContainer,
  Option,
};
