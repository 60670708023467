import { css } from 'styled-components';

const breakpoints = {
  mediumTablet: 640,
  tablet: 768,
  desktop: 1024,
};

const mediaQuery = (property, cssRules) => css`
  @media screen and (${property}) {
    ${cssRules};
  }
`;

// Mobile first mixins
const mediumTabletMedia = (cssRules) => mediaQuery(`min-width: ${breakpoints.mediumTablet}px`, cssRules);
const tabletMedia = (cssRules) => mediaQuery(`min-width: ${breakpoints.tablet}px`, cssRules);
const desktopMedia = (cssRules) => mediaQuery(`min-width: ${breakpoints.desktop}px`, cssRules);

// Mixins for targetting devices up to the specified type.
const upToMediumTabletMedia = (cssRules) => mediaQuery(`max-width: ${breakpoints.mediumTablet - 1}px`, cssRules);
const upToTabletMedia = (cssRules) => mediaQuery(`max-width: ${breakpoints.tablet - 1}px`, cssRules);
const upToDesktopMedia = (cssRules) => mediaQuery(`max-width: ${breakpoints.desktop - 1}px`, cssRules);

const mixins = {
  mediumTabletMedia,
  tabletMedia,
  desktopMedia,
  upToMediumTabletMedia,
  upToTabletMedia,
  upToDesktopMedia,
};

export {
  breakpoints,
  mixins,
};
