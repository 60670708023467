import React from 'react';
import { AggregatedDataContextProvider } from './AggregatedDataContext';

/**
 * Inserts AggregatedDataContextProvider in the react tree
 * before adding the given component.
 *
 * @param {Object} Component The component that needs the context provider.
 * @returns {Object} The component to render wrapped with the provider.
 */
const withAggregatedDataContextProvider = (Component) => (props) => (
  <AggregatedDataContextProvider>
    <Component {...props} />
  </AggregatedDataContextProvider>
);

export {
  withAggregatedDataContextProvider,
};
