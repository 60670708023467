import styled from 'styled-components';
import { IonLabel, IonTextarea } from '@ionic/react';

import { ReactComponent as ChatIcon } from '../../assets/icon/chat.svg';
import { ReactComponent as CloseButtonIcon } from '../../assets/icon/close-thread-icon.svg';
import AutoComplete from '../../../components/Select';
import Button from '../../../components/Button';
import { fontFamily } from '../../../styles/text';

const Container = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const RecipientActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const TabContainer = styled.div`
  padding: 0 4px;
`;

const TitleContainer = styled.div`
  font-family: ${fontFamily.serif} !important;
  color: ${({ theme }) => theme.colors.system.alpha};
  flex: 1;
  font-weight: bold;
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  padding: 0 15px;
`;

const ScheduleButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  margin: 8px 0;
`;

const StyledHeader = styled.div`
  transform: matrix(1, 0, 0, 1, 0, 0);
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  justify-self: flex-start;
  background: ${({ theme }) => theme.colors.base.primary};
  width: 100%;
`;

const SendBroadcastButton = styled(Button)`
  display: flex;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.base.primary};
  color: ${({ theme }) => theme.colors.system.alpha};
  height: fit-content;
  padding: 10px 14px;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  justify-content: center;
`;

const StyledChatIcon = styled(ChatIcon)`
  width: 14px;
  height: 16px;
  margin-right: 12px;
  > path {
    stroke: ${({ theme }) => theme.colors.system.alpha};
  }
`;

const StyledLabel = styled(IonLabel)`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.system.primary};
`;

const CloseButton = styled(CloseButtonIcon)`
  position: absolute;
  height: 26px;
  margin-right: 10px;
  right: 0;
`;

const ModalContent = styled.div`
  margin: 20px;
  overflow-y: auto;
`;

const SelectedRecipientContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const StyledListContainer = styled.div`
  max-height: 230px;
  overflow-y: scroll;
  border-radius: 5px;
`;

const StyledIonLabel = styled(IonLabel)`
  color: ${({ theme }) => theme.colors.system.beta};
  margin-left: auto;
  text-transform: uppercase;
`;

const StyledTextarea = styled(IonTextarea)`
   border: 1px solid ${({ theme }) => theme.colors.system.gamma2};
   border-radius: 5px;
   padding: 10px;
   background-color: ${({ theme }) => theme.colors.system.secondary6};
   color: ${({ theme }) => theme.colors.system.beta};
  --placeholder-color: ${({ theme }) => theme.colors.system.beta};
`;

const StyledAutoComplete = styled(AutoComplete)`
  border: 1px solid ${({ theme }) => theme.colors.system.gamma2};
  border-radius: 5px;
  z-index: 99;
  flex-direction: row;
  .react-select__value-container {
    flex-direction: row;
  }
`;

export {
  Container,
  CloseButton,
  TitleContainer,
  ModalContent,
  TabContainer,
  RecipientActionsContainer,
  ScheduleButtonsContainer,
  SelectedRecipientContainer,
  SendBroadcastButton,
  StyledLabel,
  StyledHeader,
  StyledChatIcon,
  StyledListContainer,
  StyledIonLabel,
  StyledTextarea,
  StyledAutoComplete,
};
