import config from '../../config';

const CRONOMETER_AUTHORIZE_URL = 'https://cronometer.com/oauth/authorize';

const getCronometerAuthUrl = () => {
  const url = new URL(CRONOMETER_AUTHORIZE_URL);
  // Required query params
  url.searchParams.append('response_type', 'code');
  url.searchParams.append('client_id', config.cronometer.clientId);
  url.searchParams.append('redirect_uri', config.cronometer.callbackUrl);

  return url;
};

export {
  getCronometerAuthUrl,
};
