import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { parseTimeInMillis } from '../../utils/time';

import {
  StyledTimeCounter,
} from './styles';

const TimeCounter = ({
  run,
  className,
  currentTimeUpdated,
}) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [timerId, setTimerId] = useState(null);
  const shouldUpdate = useRef(true);

  useEffect(() => {
    const updateTime = () => {
      // Get a time snapshot at the beginning to later compare it and calculate the precise elapsed time.
      const initialTime = performance.now();

      const id = setTimeout(() => {
        const timeoutTime = performance.now();

        // Compute how many millisecods have actually passed
        const elapsedime = timeoutTime - initialTime;

        if (shouldUpdate.current) {
          setCurrentTime(currentTime + elapsedime);
          setTimerId(null);
        }
      }, 250);

      // Save the timer id in case we need to clear this timeout
      setTimerId(id);
    };

    if (run && !timerId) {
      updateTime();
    }
  }, [
    run,
    currentTime,
    setTimerId,
    timerId,
  ]);

  useEffect(() => {
    if (currentTimeUpdated) {
      currentTimeUpdated(currentTime);
    }
  }, [
    currentTime,
    currentTimeUpdated,
  ]);

  // TODO: Move this logic to a separate hook
  useEffect(() => () => {
    shouldUpdate.current = false;
  }, []);

  return (
    <StyledTimeCounter className={className}>
      {parseTimeInMillis(currentTime)}
    </StyledTimeCounter>
  );
};

TimeCounter.propTypes = {
  run: PropTypes.bool.isRequired,
  className: PropTypes.string,
  currentTimeUpdated: PropTypes.func,
};

TimeCounter.defaultProps = {
  className: '',
  currentTimeUpdated: null,
};

export default TimeCounter;
