import Collection from '../utils/collections';
import BaseDocument from './BaseDocument';

class Product extends BaseDocument {
  constructor(id, opts) {
    super(`${Collection.PRODUCT}/${id}`, opts);
  }

  get coach() {
    return this.data.coach;
  }

  get features() {
    return this.data.features;
  }

  get isS2Product() {
    return this.data.isS2Product;
  }

  isFeatureEnabled(featureId) {
    if (this.features) {
      const feature = this.features.find(({ id }) => id === featureId);
      if (feature && typeof feature.enabled === 'boolean') {
        return feature.enabled;
      }
    }
    // By default, all features are enabled unless it is explicitly specified
    return true;
  }

  get postPaymentForm() {
    return this.data.postPaymentForm;
  }
}

export default Product;
