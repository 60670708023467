import styled from 'styled-components';

const HighlightImageContainer = styled.div`
  position: relative;
  text-align: center;
  height: 100%;
`;

const HighlightImage = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

export {
  HighlightImageContainer,
  HighlightImage,
};
