import React from 'react';
import PropTypes from 'prop-types';

import {
  MenuItemContainer,
  StyledChevronRightIcon,
} from './styles';

const SettingsMenuItem = ({
  icon: IconComponent,
  name,
  handleClick,
}) => (
  <MenuItemContainer onClick={handleClick}>
    {IconComponent && <IconComponent />}
    {name}
    <StyledChevronRightIcon />
  </MenuItemContainer>
);

SettingsMenuItem.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.elementType,
  handleClick: PropTypes.func,
};

SettingsMenuItem.defaultProps = {
  icon: null,
  handleClick: () => {},
};

export default SettingsMenuItem;
