import moment from 'moment';

import BaseDocument from './BaseDocument';

class Streak extends BaseDocument {
  get streaks() {
    let allStreaks = [];

    if (this.hasStreaksData) {
      allStreaks = Object.values(this.data.streaks)
        .reduce((result, streakItem) => {
          const { final, streak, date } = streakItem;
          if (streak > 1 && final) {
            result.push({
              ...streakItem,
              startDate: moment(date.toMillis()).subtract(streak - 1, 'days'),
              endDate: moment(date.toMillis()),
            });
          }
          return result;
        }, []);

      allStreaks.sort(({ endDate: endDateA }, { endDate: endDateB }) => {
        const isBefore = moment(endDateA).isBefore(moment(endDateB));
        // streaks items cannot be equal since the key is the date, so the date is less or greater than other value
        return isBefore ? -1 : 1;
      });
    }

    return allStreaks;
  }

  get hasStreaksData() {
    return !!this.data.streaks;
  }

  findStreaksInRange(startDate, endDate) {
    return this.streaks.filter(({ endDate: streakEndDate }) => (
      moment(streakEndDate).isBetween(startDate, endDate, undefined, '[]')
    ));
  }
}

export default Streak;
