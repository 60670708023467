import React, {
  useContext,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import WorkoutContext, { withWorkoutContextReady } from '../../context/WorkoutContext';
import WorkoutDetails from './WorkoutDetails';

const WorkoutDetailsWithWorkoutContext = () => {
  const { activitiesWithOverrides } = useContext(WorkoutContext);

  return (
    <WorkoutDetails activities={activitiesWithOverrides} />
  );
};

export default compose(
  withWorkoutContextReady,
  observer,
)(WorkoutDetailsWithWorkoutContext);
