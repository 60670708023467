import React from 'react';
import PropTypes from 'prop-types';

import useAppTheme from '../../../../../../../hooks/useAppTheme';
import CrossIcon from '../../../../../../../components/Icons/CrossIcon';

import {
  StyledActionButton,
  IconContainer,
  Label,
} from './styles';

const ExitButton = ({
  onClick,
}) => {
  const { colors } = useAppTheme();

  return (
    <StyledActionButton onClick={onClick}>
      <IconContainer><CrossIcon color={colors.system.alpha} /></IconContainer>
      <Label>Exit</Label>
    </StyledActionButton>
  );
};

ExitButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ExitButton;
