import React, { useContext } from 'react';

import LoadingPage from '../../components/LoadingPage';

import DeviceContext from './DeviceContext';
import DeviceContextProvider from './DeviceContextProvider';

const withDeviceContextProvider = (Component) => (props) => (
  <DeviceContextProvider>
    <Component {...props} />
  </DeviceContextProvider>
);

const withDeviceContextReady = (Component) => (props) => {
  const { isReady } = useContext(DeviceContext);
  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withDeviceContextProvider,
  withDeviceContextReady,
};
