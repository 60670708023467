import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  SliderContainer,
  StyledIonRange,
  DynamicLabel,
  StepLabelsContainer,
  StepLabel,
} from './styles';

const Slider = ({
  options,
  initialOptionSelected,
  onOptionSelected,
  showDynamicLabel,
  showStepLabelSection,
  disabled,
}) => {
  const [value, setValue] = useState(initialOptionSelected);

  // This controls the maximum possible value for the range slider.
  const maxValue = options.length - 1;

  // On every ionChange event, we update the internal state and we notify about the change via props.
  const onIonChange = useCallback((event) => {
    const {
      detail: {
        value: newValue,
      },
    } = event;

    /* This scenario is reached when using the labels to change the state of the slider. By clicking on the labels,
      the state value is changed, and then IonRange fires an event (IonChange). If we set the same value again in our
      state variable, then a second unnecessary render occurs. This if clause solves the second render problem. */
    if (!Number.isNaN(newValue) && newValue !== value) {
      setValue(newValue);
    }

    // Notify about the change.
    onOptionSelected({ index: newValue, value: options[newValue] });
  }, [onOptionSelected, value, options]);

  const renderStepLabels = () => (
    <StepLabelsContainer>
      {
        options.map((option, index) => {
          // If we need to show dynamic labels, then numbers are rendered. Otherwise, the option itself is rendered.
          const labelToRender = showDynamicLabel ? index + 1 : option;
          return (
            <StepLabel
              selected={index === value}
              onClick={() => !disabled && setValue(index)}
              key={option}
            >
              {labelToRender}
            </StepLabel>
          );
        })
      }
    </StepLabelsContainer>
  );

  return (
    <SliderContainer>
      {showDynamicLabel && <DynamicLabel>{options[value]}</DynamicLabel>}
      {showStepLabelSection && renderStepLabels()}
      <StyledIonRange
        min={0}
        max={maxValue}
        step={1}
        onIonChange={onIonChange}
        value={value}
        optionsLength={options.length}
        ticks={false}
        snaps
        disabled={disabled}
      />
    </SliderContainer>
  );
};

Slider.propTypes = {
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  initialOptionSelected: PropTypes.number,
  showDynamicLabel: PropTypes.bool,
  showStepLabelSection: PropTypes.bool,
  onOptionSelected: PropTypes.func,
  disabled: PropTypes.bool,
};

Slider.defaultProps = {
  initialOptionSelected: 0,
  showDynamicLabel: false,
  showStepLabelSection: true,
  onOptionSelected: () => {},
  disabled: false,
};

export default Slider;
