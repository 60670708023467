import {
  useContext,
  useMemo,
} from 'react';
import * as Sentry from '@sentry/browser';

import { MeasurementSystem } from '../../../../utils/measurement';
import { WeeklyWeightGoal } from '../../../../models/UserNutritionProfile';
import UserNutritionInfoContext from '../../../../context/UserNutritionInfoContext';

import commonTexts from '../commonTexts.json';

const WeightGoalText = {
  [WeeklyWeightGoal.GAIN_WEIGHT]: commonTexts.weightGoalGainWeight,
  [WeeklyWeightGoal.MAINTAIN_WEIGHT]: commonTexts.weightGoalMaintain,
  [WeeklyWeightGoal.LOSE_LITTLE_WEIGHT]: {
    [MeasurementSystem.IMPERIAL]: commonTexts.weightGoalLoseLittleLbs,
    [MeasurementSystem.METRIC]: commonTexts.weightGoalLoseLittleKgs,
  },
  [WeeklyWeightGoal.LOSE_MORE_WEIGHT]: {
    [MeasurementSystem.IMPERIAL]: commonTexts.weightGoalLoseMoreLbs,
    [MeasurementSystem.METRIC]: commonTexts.weightGoalLoseMoreKgs,
  },
};

const useWeightGoalDisplayText = () => {
  const {
    userNutritionProfileDoc,
  } = useContext(UserNutritionInfoContext);

  const text = useMemo(() => {
    const weightGoalType = userNutritionProfileDoc.weeklyWeightGoal;
    const result = WeightGoalText[weightGoalType];

    if (result) {
      return typeof result === 'string'
        ? result
        : result[userNutritionProfileDoc.measurementUnit];
    }

    Sentry.captureMessage(
      `There's no display weight goal for ${weightGoalType}. Using ${WeeklyWeightGoal.MAINTAIN_WEIGHT} as fallback.`,
    );

    return WeightGoalText[WeeklyWeightGoal.MAINTAIN_WEIGHT];
  }, [
    userNutritionProfileDoc.weeklyWeightGoal,
    userNutritionProfileDoc.measurementUnit,
  ]);

  return text;
};

export default useWeightGoalDisplayText;
