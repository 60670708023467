import React from 'react';

import SubscriptionSlideDrawerContextProvider from './SubscriptionSlideDrawerContextProvider';

/**
 * Inserts SubscriptionSliderContextProvider in the react tree
 * before adding the given component.
 *
 * @param {Object} Component The component that needs the context provider.
 * @returns {Object} The component to render wrapped with the provider.
 */
const withSubscriptionSlideDrawerContextProvider = (Component) => (props) => (
  <SubscriptionSlideDrawerContextProvider>
    <Component {...props} />
  </SubscriptionSlideDrawerContextProvider>
);

export default withSubscriptionSlideDrawerContextProvider;
