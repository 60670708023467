import React from 'react';

import ActivityHistory from './sectionComponents/ActivityHistory';
import WorkoutDetailsSection from './sectionComponents/WorkoutDetailsSection';
import ActivityLog from './sectionComponents/ActivityLog';
import Section from './section';

const getConfig = ({
  WorkoutRoutineComponent,
}) => [
  {
    name: Section.WORKOUT_ROUTINE,
    Content: () => <WorkoutDetailsSection WorkoutRoutineComponent={WorkoutRoutineComponent} />,
  },
  {
    name: Section.ACTIVITY_HISTORY,
    Content: ActivityHistory,
  },
  {
    name: Section.ACTIVITY_LOG,
    Content: ActivityLog,
  },
];

export {
  getConfig,
};
