import styled, { css } from 'styled-components';
import { IonSpinner, IonCheckbox } from '@ionic/react';

import Button, { LinkButton } from '../../../../components/Button';
import CameraPreview from '../../../../components/CameraPreview';
import { sansSerif } from '../../../../styles/text';
import { safeAreaFromBottom } from '../../../../styles/mixins';

const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  position: relative;

  ${({ theme }) => theme.isPortrait && css`
    flex-direction: column;
  `}
`;

const PlaceDeviceInfoContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledLinkButton = styled(LinkButton)`
  color: ${({ theme }) => theme.colors.system.beta};
`;

const PlaceDeviceMessage = styled.div`
  ${sansSerif.headline2}
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  margin: 30px 0;

  ${({ theme }) => theme.isPortrait && css`
    margin: 10px 0;
  `}
`;

const StyledIonSpinner = styled(IonSpinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  --color: ${({ theme }) => theme.colors.system.beta};
`;

const StyledCameraPreview = styled(CameraPreview)`
  transform: scaleX(-1);
`;

const NEXT_BUTTON_MARGIN = 24;

const StyledNextButton = styled(Button)`
  position: absolute;
  bottom: calc(${safeAreaFromBottom()} + ${NEXT_BUTTON_MARGIN}px);
  margin: 0 ${NEXT_BUTTON_MARGIN}px;
  width: calc(100% - ${NEXT_BUTTON_MARGIN * 2}px);
  color: ${({ theme }) => theme.colors.fonts.primary};
`;

const StyledNoCameraButton = styled(Button)`
  color: ${({ theme }) => theme.colors.fonts.primary};
`;

const StyledCheckbox = styled(IonCheckbox)`
  --background-checked: ${({ theme }) => theme.colors.system.beta};
  --border-color-checked: ${({ theme }) => theme.colors.system.beta};
  --border-radius: 2px;
  --size: 16px;
  --checkmark-width: 3px;
  margin-right: 10px;
`;

export {
  Content,
  PlaceDeviceInfoContainer,
  PlaceDeviceMessage,
  StyledNoCameraButton,
  StyledLinkButton,
  StyledIonSpinner,
  StyledCameraPreview,
  StyledNextButton,
  StyledCheckbox,
};
