import React from 'react';
import PropTypes from 'prop-types';

import ScrollHandler from '../../ScrollHandler';
import ExerciseItem from './ExerciseItem';
import {
  ExercisesList,
  ExerciseListItem,
} from './styles';

const ExerciseList = ({
  exercises,
  expandedItem,
  showOriginalExercise,
  onItemExpanded,
  onLoadMoreData,
  className,
}) => (
  <ScrollHandler
    onScrollToBottom={onLoadMoreData}
    className={className}
  >
    <ExercisesList>
      {exercises.map((exercise) => (
        <ExerciseListItem key={exercise.id}>
          <ExerciseItem
            exercise={exercise}
            isExpanded={!!(expandedItem && expandedItem.id === exercise.id)}
            showOriginalExercise={showOriginalExercise}
            onExpand={onItemExpanded}
          />
        </ExerciseListItem>
      ))}
    </ExercisesList>
  </ScrollHandler>
);

ExerciseList.propTypes = {
  exercises: PropTypes.array.isRequired,
  expandedItem: PropTypes.object,
  showOriginalExercise: PropTypes.bool.isRequired,
  onItemExpanded: PropTypes.func.isRequired,
  onLoadMoreData: PropTypes.func.isRequired,
  className: PropTypes.string,
};

ExerciseList.defaultProps = {
  expandedItem: null,
  className: '',
};

export default ExerciseList;
