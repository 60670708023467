import styled from 'styled-components';

const DebugPanelTitle = styled.div`
  text-transform: uppercase;
  text-align: center;
  height: 20px;
  font-size: 15px;
  line-height: 20px;
  background-color: grey;
  color: white;
`;

const DebugPanelContent = styled.div`
  padding: 10px;
`;

export {
  DebugPanelTitle,
  DebugPanelContent,
};
