import styled from 'styled-components';

import colors from '../../../styles/baseColors';

const Wrapper = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  flex-direction: column;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  padding-left: 18px;
`;

const ModalHead = styled.h2`
  position: absolute;
  left: 50%;
  top: 0;
  margin: 10px;
  transform: translateX(-50%);
`;

const FormSubmit = styled.input`
  display: flex;
  width: 250px !important;
  height: 40px;
  margin-left: auto;
  justify-content: center;
  align-items: center;
  border: none;
  font-weight: bold;
`;

const StyledForm = styled.form`
  display: block;
  max-width: 750px;
  min-height: 250px;
  width: 100%;
  margin: 20px auto;
  padding: 10px;
  padding-bottom: 0;
  overflow: auto;

  input, textarea, select {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: ${colors.system.beta};
    background-color: ${colors.system.alpha};
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }

  input[type=button], input[type=reset], input[type=submit]{
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 159.45%;
    -webkit-letter-spacing: 0.16em;
    -moz-letter-spacing: 0.16em;
    -ms-letter-spacing: 0.16em;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    font-feature-settings: 'liga' off;
    user-select: none;
    padding: 16px;
    border: 0px;
    background-color: ${colors.base.secondary};
    color: ${colors.system.beta};
    height: auto;
  }

  input:disabled {
    color: ${colors.system.gamma2};
  }
`;

const SubmitWrapper = styled.div`
  width: 100%;
  padding-top: 15px;
  margin-top: 30px;
  position: sticky;
  bottom: 0;
  background-color: ${colors.system.alpha};
  box-shadow: 0px -25px 25px 0 ${colors.system.alpha};
`;

export {
  Wrapper,
  ActionsWrapper,
  ModalHead,
  FormSubmit,
  StyledForm,
  SubmitWrapper,
};
