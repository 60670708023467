import styled from 'styled-components';

import { subtitle1 } from '../../../../styles/text';
import PageContent from '../../../../components/PageContent';
import Button from '../../../../components/Button';

const StyledPageContent = styled(PageContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow: auto;
  background-color: ${({ theme }) => theme.colors.system.beta};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
  position: relative;
  width: 100%;
`;

const ImageContainer = styled.div`
  display: ${({ shown }) => (shown ? 'flex' : 'none')};
`;

const Message = styled.div`
  ${subtitle1}
  text-align: center;
  margin-top: 22px;
  color: ${({ theme }) => theme.colors.system.alpha};
`;

const VideoContainer = styled.div`
  max-height: calc(100% - 290px);
  flex: 1;
  display: ${({ shown }) => (shown ? 'block' : 'none')};
  min-width: 100%;

  video,
  img {
    width: 100%;
    height: 100%;
  }

  video {
    transform: scaleX(-1);
  }
`;

const ActionsContainer = styled.div`
  width: 100%;
  max-width: 768px;
`;

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colors.fonts.primary};
`;

export {
  StyledPageContent,
  VideoContainer,
  ImageContainer,
  Message,
  Content,
  ActionsContainer,
  StyledButton,
};
