import React from 'react';

import MessageBannerContextProvider from './MessageBannerContextProvider';

/**
 * Inserts MessageBannerContextProvider in the react tree
 * before adding the given component.
 *
 * @param {Object} Component The component that needs the context provider.
 * @returns {Object} The component to render wrapped with the provider.
 */
const withMessageBannerContextProvider = (Component) => (props) => (
  <MessageBannerContextProvider>
    <Component {...props} />
  </MessageBannerContextProvider>
);

export default withMessageBannerContextProvider;
