const calculateCalibrationData = (calibrators, predictionModels, prevCalibrationData = {}) => (
  calibrators.reduce((calibrationData, calibrationFn) => {
    const result = calibrationFn(predictionModels, calibrationData);
    if (result) {
      return {
        ...calibrationData,
        ...result,
      };
    }
    return calibrationData;
  }, prevCalibrationData)
);

export default calculateCalibrationData;
