import styled, { css } from 'styled-components';

import Button from '../../../Button';

const StyledButton = styled(Button)`
  text-transform: none;
  text-align: left;
  letter-spacing: normal;
  padding: 0;
  font-weight: normal;
  display: flex;
  align-items: center;

`;

const Container = styled.div`
  cursor: pointer;
  padding-left: 26px;

  span {
    padding-left: 0;
    color: ${({ active, theme: { colors } }) => (active ? colors.base.secondary : colors.system.beta)};
  }

  a {
    text-decoration: none;
    display: flex;
    align-items: center;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.base.secondary};
  }

  svg {
    height: 100%;
    width: 100%;
    ${({ active }) => active && css`
      path {
        fill: ${({ theme }) => theme.colors.base.secondary};
      }
    `}
  }
`;

export {
  StyledButton,
  Container,
};
