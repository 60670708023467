import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../components/Button';
import useUserNavigation from '../../../hooks/useUserNavigation';
import {
  StyledContainer,
  FailedPaymentIcon,
  FailedPaymentTitle,
  FailedPaymentMessage,
  FailedPaymentMessageTitle,
  StyledButton,
} from './styles';

import texts from './texts.json';

const FailedPayment = ({
  isSkippable,
  onSkipped,
}) => {
  const { navigateToUserPath } = useUserNavigation();

  return (
    <StyledContainer>
      <FailedPaymentIcon />
      <FailedPaymentTitle>{texts.failedPaymentTitle}</FailedPaymentTitle>
      <FailedPaymentMessageTitle>{texts.failedPaymentMessageTitle}</FailedPaymentMessageTitle>
      <FailedPaymentMessage>{texts.failedPaymentMessage}</FailedPaymentMessage>
      <FailedPaymentMessage>{texts.failedPaymentAltMessage}</FailedPaymentMessage>
      <Button
        disabled={isSkippable}
        onClick={() => navigateToUserPath('settings/subscriptions')}
      >
        {texts.failedPaymentButton}
      </Button>
      {isSkippable && <StyledButton onClick={onSkipped}>{texts.skip}</StyledButton>}
    </StyledContainer>
  );
};

FailedPayment.propTypes = {
  onSkipped: PropTypes.func,
  isSkippable: PropTypes.bool,
};

FailedPayment.defaultProps = {
  onSkipped: () => {},
  isSkippable: false,
};

export default FailedPayment;
