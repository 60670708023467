import moment from 'moment';

const DateFormat = {
  DEFAULT_DATE_TIME_FORMAT: 'YYYY-MM-DDTHH:mm:ss',
  DEFAULT_DATE_FORMAT: 'YYYY-MM-DD',
  DEFAULT_DATE_WITH_TIME_FORMAT: 'YYYY-MM-DD-HH-mm-ss',
  SHORT_DATE_FORMAT: 'MM-DD-YY',
  DATE_FORMAT_SLASH: 'MM/DD/YYYY',
  LONG_MONTH_WITH_DAY: 'MMMM DD',
  LONG_MONTH_WITH_DAY_YEAR: 'DD MMMM YYYY',
  DAY_WITH_SHORT_MONTH: 'Do MMM',
  WEEK_DAY_NAME: 'dddd',
  ABBREVIATED_MONTH_WITH_DATE_YEAR: 'DD MMM YYYY',
};

DateFormat.SHORT_DAY_DATE_FORMAT_SLASH = `ddd ${DateFormat.DATE_FORMAT_SLASH}`;
DateFormat.DAY_WITH_DATE_FORMAT_SLASH = `DDDD ${DateFormat.DATE_FORMAT_SLASH}`;

const isYesterday = (momentDate) => {
  const yesterday = moment().subtract(1, 'days').startOf('day');
  return momentDate.isSame(yesterday, 'day');
};

const isPastDay = (momentDate) => {
  const startOfNow = moment().startOf('day');
  return momentDate.isBefore(startOfNow, 'day');
};

const isToday = (momentDate) => {
  const today = moment();
  return today.isSame(momentDate, 'day');
};

const isTomorrow = (momentDate) => {
  const tomorrow = moment().add(1, 'days');
  return tomorrow.isSame(momentDate, 'day');
};

const isFutureDay = (momentDate) => {
  const startOfNow = moment().startOf('day');
  return startOfNow.isBefore(momentDate, 'day');
};

const toWeekdayDate = (momentDate) => (
  momentDate.format('ddd D')
);

const toWeekdayDateOfMonth = (momentDate) => (
  momentDate.format('ddd D MMM')
);

const getDayofWeek = () => (
  moment(new Date()).format('dddd')
);

const isSameMonth = (momentDate) => {
  const today = moment();
  return momentDate.isSame(today, 'month');
};

const isFirstDayOfMonth = (momentDate) => (
  parseInt(momentDate.format('D'), 10) === 1
);

const todayStartDate = () => (
  moment().startOf('day')
);

/**
 * Converts a UTC date to a local user date time.
 *
 * @param {Object} date Date to convert to local time date.
 * @returns {Object} A moment date instance.
 */
const convertToLocalDate = (date) => {
  const momentDate = moment(date);
  const localMomentDate = momentDate.subtract(momentDate.utcOffset(), 'minutes');

  return localMomentDate;
};

const nowInDateOnlyFormat = () => moment().format(DateFormat.DEFAULT_DATE_FORMAT);

const convertToLocalStartMomentDate = (date) => convertToLocalDate(date).startOf('day');

const calculateUTCDay = (date) => {
  const utcDate = moment(date).utc(true);

  const startDate = utcDate.clone().startOf('day').toDate();
  const endDate = utcDate.clone().endOf('day').toDate();

  return {
    startDate,
    endDate,
  };
};

const getYearAndWeekFromDate = (date) => {
  const year = moment(date).year();
  const week = moment(date).isoWeek();
  return {
    year,
    week,
  };
};

export {
  isYesterday,
  isPastDay,
  isToday,
  isTomorrow,
  isFutureDay,
  isSameMonth,
  isFirstDayOfMonth,
  toWeekdayDate,
  toWeekdayDateOfMonth,
  getDayofWeek,
  convertToLocalDate,
  todayStartDate,
  nowInDateOnlyFormat,
  convertToLocalStartMomentDate,
  calculateUTCDay,
  DateFormat,
  getYearAndWeekFromDate,
};
