import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import Workout from '../../../../models/Workout';
import useComponentMounted from '../../../../hooks/useComponentMounted';
import UserExerciseOverridesContext from '../../../../context/UserExerciseOverridesContext';
import WorkoutSelectorContext from './WorkoutSelectorContext';

const WorkoutSelectorContextProvider = ({ children }) => {
  const {
    params: {
      workoutId,
    },
  } = useRouteMatch();
  const {
    userExerciseOverridesDoc,
    prepareActivitiesWithOverrides,
  } = useContext(UserExerciseOverridesContext);
  const isComponentedMountedRef = useComponentMounted();

  const [isReady, setIsReady] = useState(false);
  const [workoutDoc, setWorkoutDoc] = useState(null);
  const [activitiesWithOverrides, setActivitiesWithOverrides] = useState([]);

  useEffect(() => {
    if (!isReady) {
      const init = async () => {
        const workoutDocument = new Workout(`/workout/${workoutId}`);
        await workoutDocument.init();

        if (isComponentedMountedRef.current) {
          setWorkoutDoc(workoutDocument);
          /*
            NOTE: setIsReady will be call after setting up activities with overrides in a
            specific useEffect callback.
          */
        }
      };

      init();
    }
  }, [
    workoutId,
    isComponentedMountedRef,
    isReady,
  ]);

  useEffect(() => {
    if (workoutDoc) {
      const setup = async () => {
        const {
          workoutDefinition: {
            activities,
          },
        } = workoutDoc;

        const parsedActivities = await prepareActivitiesWithOverrides(activities);

        if (isComponentedMountedRef.current) {
          setActivitiesWithOverrides(parsedActivities);
          // Once activities with overrides is configured, the context provider is ready
          setIsReady(true);
        }
      };
      setup();
    }
  }, [
    workoutDoc,
    prepareActivitiesWithOverrides,
    isComponentedMountedRef,
    /*
      NOTE: Execute this useEffect when the overrides document content changes to recalculate
      activities with overrides.
    */
    userExerciseOverridesDoc.data,
  ]);

  const workout = useMemo(() => ({
    workoutDoc,
    activitiesWithOverrides,
    isReady,
  }), [
    workoutDoc,
    activitiesWithOverrides,
    isReady,
  ]);

  return (
    <WorkoutSelectorContext.Provider value={workout}>
      {children}
    </WorkoutSelectorContext.Provider>
  );
};

WorkoutSelectorContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(WorkoutSelectorContextProvider);
