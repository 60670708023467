import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

import {
  PanelContainer,
  Header,
  HeaderArrow,
  Title,
  Collapsible,
  Content,
  SubTitle,
  TitleContainer,
  TitleDescription,
} from './styles';

const COLLAPSED_CONTENT_HEIGHT = 0;
const AUTO_HEIGHT = 'auto';

const CollapsiblePanel = ({
  title,
  startOpen,
  headerAtBottom,
  className,
  disabled,
  children,
  subTitle,
  titleDescription,
}) => {
  const [isOpen, setIsOpen] = useState(startOpen);
  const [expandedHeight, setExpandedHeight] = useState(AUTO_HEIGHT);

  const contentRef = useRef(null);

  useEffect(() => {
    if ((expandedHeight === AUTO_HEIGHT || expandedHeight === 0) && contentRef.current) {
      // Get the actual content height. This is used for toggling the collapsed/expanded state.
      const { height } = contentRef.current.getBoundingClientRect();

      // When height is 0, set auto height to prevent not rendering an element that should be displayed
      // until the real height can be calculated by the browser
      const heightValue = height > 0 ? height : AUTO_HEIGHT;

      setExpandedHeight(heightValue);
    }
  }, [
    contentRef,
    isOpen,
    expandedHeight,
  ]);

  // This controls the height the collapsibe section should have.
  const collapsibleComponentHeight = useMemo(() => (
    isOpen ? expandedHeight : COLLAPSED_CONTENT_HEIGHT
  ), [isOpen, expandedHeight]);

  const togglePanel = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <PanelContainer className={className}>
      <Header onClick={togglePanel} headerAtBottom={headerAtBottom} hasTitle={!!title}>
        <TitleContainer>
          <Title>{title}</Title>
          {!!titleDescription && (
            <TitleDescription>
              {titleDescription}
            </TitleDescription>
          )}
        </TitleContainer>
        {subTitle
          ? <SubTitle>{subTitle}</SubTitle>
          : <HeaderArrow isOpen={isOpen} />}
      </Header>
      <Collapsible collapsibleHeight={collapsibleComponentHeight}>
        <Content ref={contentRef}>{children}</Content>
      </Collapsible>
    </PanelContainer>
  );
};

CollapsiblePanel.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  startOpen: PropTypes.bool,
  headerAtBottom: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  subTitle: PropTypes.string,
  titleDescription: PropTypes.string,
};

CollapsiblePanel.defaultProps = {
  title: '',
  startOpen: false,
  headerAtBottom: false,
  className: '',
  disabled: false,
  subTitle: '',
  titleDescription: '',
};

export default CollapsiblePanel;
export {
  Header,
  Title,
  Collapsible,
  SubTitle,
};
