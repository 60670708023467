function degrees(radians) {
  return radians * (180 / Math.PI);
}

/**
 * Calculates the vertical distance (Y axis) between two points.
 *
 * @param {Array} a Coordinates X and Y represented in an array [x, y]
 * @param {Array} b Coordinates X and Y represented in an array [x, y]
 * @returns {number}
 */
function vdist(a, b) {
  return Math.abs((b[1] - a[1]));
}

/**
 * Calculates the horizontal distance (X axis) between two points.
 *
 * @param {Array} a Coordinates X and Y represented in an array [x, y]
 * @param {Array} b Coordinates X and Y represented in an array [x, y]
 * @returns {number}
 */
function hdist(a, b) {
  return Math.abs((b[0] - a[0]));
}

/**
 * Calculate the vertical middle point (Y axis) between two points.
 *
 * @param {Array} a Coordinates X and Y represented in an array [x, y]
 * @param {Array} b Coordinates X and Y represented in an array [x, y]
 * @returns {number}
 */
function vMiddlePoint(a, b) {
  return (a[1] + b[1]) / 2;
}

/**
 * Calculate the horizontal middle point (X axis) between two points.
 *
 * @param {Array} a Coordinates X and Y represented in an array [x, y]
 * @param {Array} b Coordinates X and Y represented in an array [x, y]
 * @returns {number}
 */
function hMiddlePoint(a, b) {
  return (a[0] + b[0]) / 2;
}

/**
 * Check if the given point is inside the given bounding box.
 *
 * @param {Array<number>} point Coordinates X and Y represented in an array [x, y]
 * @param {Object} boundingBox The bounding box definition
 * @param {number} boundingBox.xMin
 * @param {number} boundingBox.yMin
 * @param {number} boundingBox.xMax
 * @param {number} boundingBox.yMax
 * @returns {Boolean} True when the point is inside the bounding box, otherwise false.
 */
function isPointInBoundingBox(point, boundingBox) {
  const [x, y] = point;
  const {
    xMin,
    yMin,
    xMax,
    yMax,
  } = boundingBox;

  return (x > xMin && x < xMax && y > yMin && y < yMax);
}

export {
  degrees,
  vdist,
  hdist,
  vMiddlePoint,
  hMiddlePoint,
  isPointInBoundingBox,
};
