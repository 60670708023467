import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { MeasurementSystem } from '../../../utils/measurement';

import {
  StyledMeasurementIcon,
} from '../styles';
import {
  MenuItemContainer,
  StyledLinkButton,
} from './styles';
import texts from './texts';

const MeasurementMenuItem = ({
  value,
  handleClick,
}) => {
  const handleMeasurementChange = useCallback(() => {
    const newValue = value === MeasurementSystem.IMPERIAL ? MeasurementSystem.METRIC : MeasurementSystem.IMPERIAL;
    handleClick(newValue);
  }, [
    value,
    handleClick,
  ]);

  return (
    <MenuItemContainer>
      <StyledMeasurementIcon />
      {texts.measurement}
      <StyledLinkButton onClick={handleMeasurementChange}>
        {texts.labels[value]}
      </StyledLinkButton>
    </MenuItemContainer>
  );
};

MeasurementMenuItem.propTypes = {
  value: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
};

MeasurementMenuItem.defaultProps = {
  handleClick: () => {},
};

export default MeasurementMenuItem;
