import styled, { css } from 'styled-components';

import colors from '../../../styles/baseColors';
import { sansSerif } from '../../../styles/text';

const Form = styled.form`
  width: 100%;
  max-width: 580px;
  margin: auto;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const HeaderTitle = styled.h1`
  ${sansSerif.headline1};
  text-align: center;
  margin: 15px 0;
`;

const baseInputStyles = css`
  min-height: 40px;
  margin-bottom: 15px;
  line-height: 1.5;
  color: ${colors.system.beta};
  background-color: ${colors.system.alpha};
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
`;

const StyledInput = styled.input`
  ${baseInputStyles};
`;

const StyledTextArea = styled.textarea`
  ${baseInputStyles};
  min-height: 100px;
`;

export {
  Form,
  HeaderTitle,
  StyledInput,
  StyledTextArea,
};
