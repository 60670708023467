import styled, { css } from 'styled-components';
import { IonModal } from '@ionic/react';

import Button from '../Button';
import VideoPlayer from '../VideoPlayer';
import CloseButton from '../CloseButton';
import OriginalExercise from '../OriginalExercise';
import { safeAreaFromBottom, safeAreaFromTop } from '../../styles/mixins';
import { headline3, body1, sansSerif } from '../../styles/text';

const containerStyles = ({ enablePortraitLayout }) => {
  const flexDirection = enablePortraitLayout ? 'column' : 'row-reverse';

  return css`
    flex-direction: ${flexDirection};
  `;
};

const descriptionContainerStyles = ({ enablePortraitLayout }) => {
  if (enablePortraitLayout) {
    return css`
      height: 50%;
    `;
  }

  return css`
    padding-top: 0;
    margin-top: 56px;
    height: auto;
  `;
};

const actionsContainerStyles = ({ enablePortraitLayout }) => {
  const customStyles = enablePortraitLayout
    ? css`
      width: 100%;`
    : css`
      position: absolute;
      top: 0;
      left: 0;`;

  return customStyles;
};

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;

  ${(props) => containerStyles(props)}
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 56px;
  padding-left: 16px;
  padding-top: ${safeAreaFromTop()};

  ${(props) => actionsContainerStyles(props)}
`;

const DescriptionContainer = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;

  ${(props) => descriptionContainerStyles(props)}
`;

const VideoContainer = styled.div`
  flex-basis: 45%;
  overflow: hidden;
`;

/*
  This styled component uses transient props (notice how the prop starts with a dollar sign). This feature allows to
  send props that are only consumed by this styled component. This way the prop is not passed to the underlying
  VideoPlayer, and definitely not to the DOM, which would throw an error since the prop is not a string.
  More info here: https://styled-components.com/docs/api#transient-props
*/
const StyledVideoPlayer = styled(VideoPlayer)`
  ${({ $enlarge }) => ($enlarge && css`
    transform: translateY(-2%) scale(1.4);
  `)}
`;

const Title = styled.div`
  ${headline3}
  font-size: 20px;
  text-transform: unset;
`;

const Description = styled.p`
  ${body1}
  overflow: auto;
  white-space: break-spaces;
  margin: 16px 0 0 0;
`;

const StyledModal = styled(IonModal)`
  .ion-page {
    background: ${({ theme }) => theme.colors.system.alpha};
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
  padding-top: calc(${safeAreaFromTop()} + 56px);
  padding-bottom: ${safeAreaFromBottom()};
`;

const TransparentButton = styled(Button)`
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  padding: 6px 0;
  margin-top: 10px;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  margin: 16px 0 0 0;

  &::before {
    content: ' ';
    height: 1px;
    width: 100%;
    border-top: 1px solid ${({ theme }) => theme.colors.system.background};
  }
`;

const ActionText = styled.div`
  ${sansSerif.headlineFontStyles}
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  padding: 4px 8px;
`;

const StyledCloseButton = styled(CloseButton)`
  z-index: 2;
`;

const StyledOriginalExercise = styled(OriginalExercise)`
  margin: 16px 0 0 0;
`;

export {
  Container,
  ActionsContainer,
  VideoContainer,
  StyledVideoPlayer,
  DescriptionContainer,
  Description,
  Title,
  StyledModal,
  ModalContent,
  ActionContainer,
  TransparentButton,
  ActionText,
  StyledCloseButton,
  StyledOriginalExercise,
};
