import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { ActivityGoalType } from '../../../../../../models/BaseActivity';

import VideoPreview from '../VideoPreview';
import RepetitionsActivityPanel from './RepetitionsActivityPanel/RepetitionsActivityPanel';
import TimeActivityPanel from './TimeActivityPanel/TimeActivityPanel';

import {
  Container,
  ExtraInfoContainer,
  StyledInfoIcon,
  GenericVideoPreview,
} from './styles';

const ActivityPanel = ({
  activityGoalType,
  remainingWorkoutValue,
  activityName,
  isPlaying,
  isCurrentActivityRunning,
  videoPreviewUrl,
  isFullscreenVideoAvailable,
  showActivityDetails,
  activitySide,
  isCameraEnabled,
  isS2Activity,
  isCAActivity,
}) => {
  const renderActivityPanel = useCallback(() => {
    let Panel = null;

    switch (activityGoalType) {
      case ActivityGoalType.REPETITIONS:
        Panel = RepetitionsActivityPanel;
        break;
      case ActivityGoalType.DURATION:
        Panel = TimeActivityPanel;
        break;
      default:
        Panel = null;
    }

    return !!Panel && (
      <Panel
        active={isCurrentActivityRunning && isPlaying}
        value={remainingWorkoutValue}
        title={activityName}
        activitySide={activitySide}
      />
    );
  }, [
    activityGoalType,
    activityName,
    remainingWorkoutValue,
    isCurrentActivityRunning,
    isPlaying,
    activitySide,
  ]);

  const renderExtraInfo = useCallback(() => {
    let componentToRender = null;

    if (videoPreviewUrl && isCameraEnabled) {
      const VideoPreviewComponent = isS2Activity ? VideoPreview : GenericVideoPreview;
      componentToRender = (
        <VideoPreviewComponent
          videoUrl={videoPreviewUrl}
          onClick={showActivityDetails}
          showShadowOverlay={isCAActivity}
        />
      );
    } else if (isFullscreenVideoAvailable) {
      componentToRender = (
        <StyledInfoIcon onClick={showActivityDetails} />
      );
    }

    return componentToRender;
  }, [
    videoPreviewUrl,
    showActivityDetails,
    isFullscreenVideoAvailable,
    isCameraEnabled,
    isS2Activity,
    isCAActivity,
  ]);

  return (
    <Container isPlaying={isPlaying}>
      {renderActivityPanel()}
      <ExtraInfoContainer>
        {renderExtraInfo()}
      </ExtraInfoContainer>
    </Container>
  );
};

ActivityPanel.propTypes = {
  isPlaying: PropTypes.bool.isRequired,
  activityGoalType: PropTypes.string.isRequired,
  activityName: PropTypes.string.isRequired,
  remainingWorkoutValue: PropTypes.number.isRequired,
  isCurrentActivityRunning: PropTypes.bool.isRequired,
  videoPreviewUrl: PropTypes.string.isRequired,
  isCameraEnabled: PropTypes.bool.isRequired,
  isFullscreenVideoAvailable: PropTypes.bool,
  showActivityDetails: PropTypes.func,
  activitySide: PropTypes.string,
  isS2Activity: PropTypes.bool,
  isCAActivity: PropTypes.bool,
};

ActivityPanel.defaultProps = {
  isFullscreenVideoAvailable: false,
  showActivityDetails: () => {},
  activitySide: '',
  isS2Activity: true,
  isCAActivity: false,
};

export default ActivityPanel;
