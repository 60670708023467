import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import Input, { InputType } from '../../../components/Input';
import {
  LengthUnit,
  UnitTypeLabel,
  WeightUnit,
} from '../../../utils/units';
import CheckIn from '../../../models/CheckIn';

import {
  Title,
  Container as MeasurementContainer,
  PageContainer,
} from '../styles';

import texts from './texts.json';
import {
  weightMeasurements,
  circumferenceMeasurements,
  measurement as measurementObject,
} from './utils';
import {
  StyledContainer,
  TitleContainer,
  StyledSegmentSelector,
} from './styles';

const BodyMeasurement = ({
  currentCheckInDoc,
  updateDocumentFields,
  shouldAllowActions,
}) => {
  const [measurements, setMeasurements] = useState({});

  // load existing measurements from check-in doc
  useEffect(() => {
    if (currentCheckInDoc) {
      const existingMeasurementObject = {};
      Object.values(measurementObject).forEach((key) => {
        if (currentCheckInDoc[key]) {
          existingMeasurementObject[key] = currentCheckInDoc[key];
        }
      });
      setMeasurements(existingMeasurementObject);
    }
  }, [
    currentCheckInDoc,
  ]);

  // update check-in doc with new measurements on component unmount
  useEffect(() => {
    const updatedMeasurements = measurements;
    return () => {
      updateDocumentFields(updatedMeasurements);
    };
  }, [
    updateDocumentFields,
    measurements,
  ]);

  const weightUnitSegments = Object.keys(WeightUnit).map((unit) => ({
    value: unit,
    label: UnitTypeLabel[unit],
  }));

  const lengthUnitSegments = Object.keys(LengthUnit).map((unit) => ({
    value: unit,
    label: UnitTypeLabel[unit],
  }));

  const handleChange = useCallback((value, measurementKey) => {
    setMeasurements((prev) => ({
      ...prev,
      [measurementKey]: value,
    }));
  }, []);

  return (
    <PageContainer>
      <MeasurementContainer>
        <TitleContainer>
          <Title>{texts.bodyWeight}</Title>
          <StyledSegmentSelector
            segments={weightUnitSegments}
            currentValue={currentCheckInDoc.weightUnitType}
            onChange={(unit) => {
              updateDocumentFields({
                weightUnitType: unit,
              });
            }}
            disabled={!shouldAllowActions}
          />
        </TitleContainer>
        <StyledContainer>
          {weightMeasurements.map((measurement) => (
            <Input
              key={measurement}
              value={measurements[measurement] || ''}
              onChange={(val) => handleChange(val, measurement)}
              onBlur={() => updateDocumentFields(measurements)}
              title={texts.measurementLabels[measurement]}
              unit={UnitTypeLabel[currentCheckInDoc.weightUnitType]}
              displayZeroValue={false}
              min={0}
              disabled={!shouldAllowActions}
              type={InputType.NUMBER}
            />
          ))}
        </StyledContainer>
      </MeasurementContainer>
      <MeasurementContainer>
        <TitleContainer>
          <Title>{texts.measurements}</Title>
          <StyledSegmentSelector
            segments={lengthUnitSegments}
            currentValue={currentCheckInDoc.lengthUnitType}
            onChange={(unit) => {
              updateDocumentFields({
                lengthUnitType: unit,
              });
            }}
            disabled={!shouldAllowActions}
          />
        </TitleContainer>
        <StyledContainer>
          {circumferenceMeasurements.map((measurement) => (
            <Input
              key={measurement}
              value={measurements[measurement] || ''}
              onChange={(val) => handleChange(val, measurement)}
              onBlur={() => updateDocumentFields(measurements)}
              title={texts.measurementLabels[measurement]}
              unit={UnitTypeLabel[currentCheckInDoc.lengthUnitType]}
              displayZeroValue={false}
              min={0}
              disabled={!shouldAllowActions}
              type={InputType.NUMBER}
            />
          ))}
        </StyledContainer>
      </MeasurementContainer>
    </PageContainer>
  );
};

BodyMeasurement.propTypes = {
  currentCheckInDoc: PropTypes.instanceOf(CheckIn).isRequired,
  updateDocumentFields: PropTypes.func.isRequired,
  shouldAllowActions: PropTypes.bool.isRequired,
};

export default compose(
  observer,
)(BodyMeasurement);
