import moment from 'moment';
import get from 'lodash.get';

import { HEALTH_DATA_VERSION } from '../services/health';
import { DEFAULT_MEASUREMENT_SYSTEM } from '../utils/measurement';

import BaseDocument from './BaseDocument';

const configEntries = {
  AUDIO_CUES: 'audioCuesEnabled',
  NOTIFICATIONS_PREFERENCE: 'notificationsEnabled',
  NOTIFICATIONS_PERMISSION: 'pushNotificationPermissionGranted',
  CAMERA_ACCESS: 'cameraAccessGranted',
  VNB_ACTION: 'vnbActionEnabled',
  WORKOUT_REMINDER_NOTIFICATION_TIME: 'workoutReminderNotificationTime',
  HEALTH_DATA_ENABLED: 'healthDataEnabled',
  WATCH_AUTO_START_WORKOUT_RECORDING: 'watchAutoStartWorkoutRecordingEnabled',
  ALWAYS_START_WITH_CAMERA_OFF: 'alwaysStartWithCameraOffEnabled',
  MEASUREMENT_SYSTEM: 'measurementSystem',
};

class UserConfig extends BaseDocument {
  /**
   * Returns a config entry value from user config data with an optional defaultValue
   * in case the config entry itself is not set yet.
   * @param configEntryKey The config entry key to query
   * @param [defaultValue=true] Optional value to be used as defaultValue
   * @returns {boolean|string|object} The actual config entry value
   */
  getConfigEntry(configEntryKey, defaultValue = true) {
    const configEntriesData = this.data || {};

    return get(configEntriesData, configEntryKey, defaultValue);
  }

  /**
   * Returns a flag indicating if the user has granted camera permissions for the app already.
   * @returns {boolean}
   */
  get isCameraAccessGranted() {
    return this.getConfigEntry(configEntries.CAMERA_ACCESS, false);
  }

  /**
   * Returns a flag indicating if audio cues are enabled or not for the app.
   * @returns {boolean}
   */
  get areAudioCuesEnabled() {
    return this.getConfigEntry(configEntries.AUDIO_CUES);
  }

  /**
   * Returns a flag indicating if notifications are turned on or off for the app.
   * We consider that notifications are enabled by default for new users.
   * @returns {boolean}
   */
  get areNotificationsEnabled() {
    return this.getConfigEntry(configEntries.NOTIFICATIONS_PREFERENCE, true);
  }

  /**
   * Returns a flag indicating if notifications permission is granted for the app.
   * @returns {boolean}
   */
  get areNotificationsPermissionGranted() {
    return this.getConfigEntry(configEntries.NOTIFICATIONS_PERMISSION, false);
  }

  /**
   * Check if Virtual Next Button (VNB) action is enabled in the app.
   *
   * @returns {boolean} True when it is enabled, otherwise false.
   */
  get isVNBActionEnabled() {
    return this.getConfigEntry(configEntries.VNB_ACTION, false);
  }

  /**
   * Get the workout reminder notification time.
   *
   * @returns {string|null} The date value in String format or null.
   */
  get workoutReminderNotificationTime() {
    return this.getConfigEntry(configEntries.WORKOUT_REMINDER_NOTIFICATION_TIME, null);
  }

  /**
   * Check if health data is enabled by the user.
   *
   * @returns {boolean} True when health data is enabled, otherwise false.
   */
  get isHealthDataEnabled() {
    return this.getConfigEntry(configEntries.HEALTH_DATA_ENABLED, false);
  }

  get isHealthDataExplainerShown() {
    return this.data.isHealthDataExplainerShown === HEALTH_DATA_VERSION;
  }

  /**
   * Check if auto start workout recording with watch App is enabled by the user.
   *
   * @returns {boolean} True when it is enabled, otherwise false.
   */
  get isWatchAutoStartWorkoutRecordingEnabled() {
    return this.getConfigEntry(configEntries.WATCH_AUTO_START_WORKOUT_RECORDING);
  }

  /**
   * Check if the gameplay should alwayst start with the camera off.
   */
  get isAlwaysStartWithCameraOffEnabled() {
    return this.getConfigEntry(configEntries.ALWAYS_START_WITH_CAMERA_OFF, false);
  }

  /**
   * Get the configured measurement system. Options: IMPERIAL or METRIC.
   */
  get measurementSystem() {
    return this.getConfigEntry(configEntries.MEASUREMENT_SYSTEM, DEFAULT_MEASUREMENT_SYSTEM);
  }

  /**
   * Update the userConfigDoc last visit time.
   *
   * It will also keep track of the lastest know utc offset of the user.
   */
  updateLastVisit = () => {
    const visitDate = moment();
    this.updateFields({
      lastVisited: visitDate.toDate(),
      lastUTCOffset: visitDate.utcOffset(),
    });
  };

  healthDataExplainerShown = () => {
    this.updateFields({
      isHealthDataExplainerShown: HEALTH_DATA_VERSION,
    });
  };

  /**
   * Update "cameraAccessGranted" flag to true.
   */
  grantCameraAccess = () => {
    this.updateFields({
      [configEntries.CAMERA_ACCESS]: true,
    });
  };

  updateAudioCuesConfig = (newConfigValue = false) => {
    this.updateFields({
      [configEntries.AUDIO_CUES]: newConfigValue,
    });
  };

  updateNotificationsPreferenceConfig = (newConfigValue = false) => {
    this.updateFields({
      [configEntries.NOTIFICATIONS_PREFERENCE]: newConfigValue,
    });
  };

  updateNotificationsPermissionConfig = (newConfigValue = false) => {
    this.updateFields({
      [configEntries.NOTIFICATIONS_PERMISSION]: newConfigValue,
    });
  };

  updateVNBActionConfig = (newConfigValue = false) => {
    this.updateFields({
      [configEntries.VNB_ACTION]: newConfigValue,
    });
  };

  updateWorkoutReminderNotificationTime = (newConfigValue) => {
    this.updateFields({
      [configEntries.WORKOUT_REMINDER_NOTIFICATION_TIME]: newConfigValue,
    });
  }

  updateHealthDataEnabledConfig = (newConfigValue = false) => {
    this.updateFields({
      [configEntries.HEALTH_DATA_ENABLED]: newConfigValue,
    });
  }

  updateWatchAutoStartWorkoutRecordingConfig = (newConfigValue = false) => {
    this.updateFields({
      [configEntries.WATCH_AUTO_START_WORKOUT_RECORDING]: newConfigValue,
    });
  }

  updateAlwaysStartWithCameraOffConfig = (newConfigValue = false) => {
    this.updateFields({
      [configEntries.ALWAYS_START_WITH_CAMERA_OFF]: newConfigValue,
    });
  }

  updateMeasurementSystem = (measurementSystem = DEFAULT_MEASUREMENT_SYSTEM) => {
    this.updateFields({
      [configEntries.MEASUREMENT_SYSTEM]: measurementSystem,
    });
  }
}

export default UserConfig;
