import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as AppleHealthIcon } from '../../../assets/apple-health-icon.svg';
import PageCloseButton from '../../PageCloseButton';
import Button from '../../Button';
import {
  StyledPageContent,
  Content,
  InfoContainer,
  Title,
  Message,
  ActionsContainer,
} from '../styles';
import texts from './texts.json';

const HealthDataExplainer = ({
  onHealthDataEnabled,
  onClose,
}) => (
  <StyledPageContent>
    <Content>
      <PageCloseButton
        onClick={onClose}
      />
      <InfoContainer>
        <AppleHealthIcon />
        <Title>
          {texts.title}
        </Title>
        <Message>
          {texts.description}
        </Message>
      </InfoContainer>
      <ActionsContainer>
        <Button
          variant="primary"
          onClick={onHealthDataEnabled}
        >
          {texts.action}
        </Button>
      </ActionsContainer>
    </Content>
  </StyledPageContent>
);

HealthDataExplainer.propTypes = {
  onHealthDataEnabled: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default HealthDataExplainer;
