import styled, { css } from 'styled-components';

import { ReactComponent as ProfileIcon } from '../../assets/icons/icon_profile.svg';
import { ReactComponent as CardIcon } from '../../assets/icons/icon_card.svg';
import { ReactComponent as ConnectionsIcon } from '../../assets/icons/icon_connections.svg';
import { ReactComponent as WorkoutPrefsIcon } from '../../assets/icons/icon_lifeline.svg';
import { ReactComponent as NotificationsIcon } from '../../assets/icons/icon_notifications.svg';
import { ReactComponent as MeasurementIcon } from '../../assets/icons/icon_measurement.svg';
import { ReactComponent as TosIcon } from '../../assets/icons/icon_tos.svg';
import { ReactComponent as PrivacyIcon } from '../../assets/icons/icon_privacy_policy.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/icon_arrow_up.svg';

const Container = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
`;

const SectionContainer = styled.div`
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const commonIconStyles = css`
  path {
    stroke: ${({ theme }) => theme.colors.base.tertiary};
  }
  width: 16px;
  height: auto;
`;

const StyledProfileIcon = styled(ProfileIcon)`
  ${commonIconStyles};
`;

const StyledCardIcon = styled(CardIcon)`
  ${commonIconStyles};
`;

const StyledConnectionsIcon = styled(ConnectionsIcon)`
  ${commonIconStyles};
`;

const StyledWorkoutPrefsIcon = styled(WorkoutPrefsIcon)`
  ${commonIconStyles};
`;

const StyledNotificationsIcon = styled(NotificationsIcon)`
  ${commonIconStyles};
`;

const StyledMeasurementIcon = styled(MeasurementIcon)`
  ${commonIconStyles};
`;

const StyledTosIcon = styled(TosIcon)`
  ${commonIconStyles};
`;

const StyledPrivacyIcon = styled(PrivacyIcon)`
  ${commonIconStyles};
`;

const StyledArrowUpIcon = styled(InfoIcon)`
  ${commonIconStyles};
`;

export {
  Container,
  SectionContainer,
  StyledProfileIcon,
  StyledCardIcon,
  StyledConnectionsIcon,
  StyledWorkoutPrefsIcon,
  StyledNotificationsIcon,
  StyledMeasurementIcon,
  StyledTosIcon,
  StyledPrivacyIcon,
  StyledArrowUpIcon,
};
