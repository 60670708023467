const drawHuman = (ctx, flowHuman) => {
  const bonePairs = [
    [0, 1],
    [1, 2],
    [5, 4],
    [4, 3],
    [2, 3],
    [2, 12],
    [3, 13],
    [12, 13],
    [13, 14],
    [14, 15],
    [12, 11],
    [11, 10],
  ];

  const joints = [16, 8, 14, 11, 13, 12, 15, 10, 3, 2, 5, 0, 4, 1, 19, 17, 20, 18];

  const color = '#C86432';
  ctx.lineWidth = 3;
  ctx.strokeStyle = color;
  ctx.fillStyle = color;

  const { human } = flowHuman;
  if (human) {
    joints.forEach((joint) => {
      const x = human[2 * joint];
      const y = human[2 * joint + 1];

      if (x > 0 && y > 0) {
        ctx.beginPath();
        ctx.arc(x * ctx.canvas.width, y * ctx.canvas.height, 5, 0, 2 * Math.PI);
        ctx.fill();
      }
    });

    bonePairs.forEach((pair) => {
      const p0x = human[2 * pair[0]];
      const p0y = human[2 * pair[0] + 1];
      const p1x = human[2 * pair[1]];
      const p1y = human[2 * pair[1] + 1];
      if (p0x > 0 && p0y > 0 && p1x > 0 && p1y > 0) {
        ctx.beginPath();
        ctx.moveTo(p0x * ctx.canvas.width, p0y * ctx.canvas.height);
        ctx.lineTo(p1x * ctx.canvas.width, p1y * ctx.canvas.height);
        ctx.stroke();
      }
    });
  }
};

export default drawHuman;
