import React, { useEffect, useRef, useState } from 'react';
import { createGesture } from '@ionic/core/components';
import PropTypes from 'prop-types';

import { getPixelsNumber } from '../../utils/dimensions';
import { SlideDrawerAlignment } from './SlideDrawerAlignment';
import {
  Backdrop,
  Drawer,
  SlideDrawerContainer,
  Container,
  OverflowHidden,
} from './styles';

/**
 * This component adds a drawer that appears from the bottom.
 */
const SlideDrawer = ({
  children,
  isOpen,
  onClose,
  dragDownThreshold, // percentage of the height the user has to drag down to close the drawer
  closeOnBackdropTouch,
  draggableAreaPercentage,
  maxHeight,
  fitContent,
  alignment,
  className,
  closeGesturePriority,
  useTransparentHandle,
}) => {
  const drawerRef = useRef(null);
  const [drawerHeight, setDrawerHeight] = useState(() => getPixelsNumber(document.body, maxHeight));
  const [drawerPositionY, setDrawerPositionY] = useState(0);
  const gestureRef = useRef(null);

  const handleBackdropClick = () => {
    if (closeOnBackdropTouch) {
      onClose();
    }
  };

  useEffect(() => {
    const newHeight = getPixelsNumber(document.body, maxHeight);
    setDrawerHeight(newHeight);
  }, [
    maxHeight,
  ]);

  useEffect(() => {
    if (isOpen && !gestureRef.current) {
      const drawerElement = drawerRef.current;

      const gestureConfig = {
        el: drawerElement,
        name: 'closeDrawer',
        threshold: 0,
        direction: 'y',
        canStart: ({ startY }) => {
          const activationAreaSize = drawerElement.clientHeight * (draggableAreaPercentage / 100);
          const maxStartingPoint = drawerElement.offsetTop + activationAreaSize;
          return startY <= maxStartingPoint;
        },
        onMove: (detail) => {
          /*
            Deactivate transitions while the panel is being dragged.
            This way, the panel will follow the finger on the screen without any delays.
          */
          drawerElement.style.transition = 'none';
          const {
            deltaY,
          } = detail;
          if (deltaY < 0) {
            return;
          }
          setDrawerPositionY(detail.deltaY);
        },
        onEnd: (detail) => {
          // Restore the original transition config when the drag ends.
          drawerElement.style.transition = '';
          const {
            deltaY,
          } = detail;
          const height = drawerElement.offsetHeight;
          if (deltaY > height * dragDownThreshold) {
            onClose();
          }
          setDrawerPositionY(0);
        },
      };

      if (closeGesturePriority) {
        gestureConfig.gesturePriority = closeGesturePriority;
      }

      gestureRef.current = createGesture(gestureConfig);
      gestureRef.current.enable(true);
    } else if (!isOpen && gestureRef.current) {
      // Destroy the gesture when the slider is closed, to prevent the gesture to be active overriding other gestures
      gestureRef.current.destroy();
      gestureRef.current = null;
    }
  }, [
    isOpen,
    dragDownThreshold,
    closeGesturePriority,
    onClose,
    draggableAreaPercentage,
  ]);

  return (
    <Container isOpen={isOpen}>
      {isOpen && <OverflowHidden />}
      <Backdrop onClick={handleBackdropClick} />
      <SlideDrawerContainer
        ref={drawerRef}
        className={className}
        $height={fitContent ? 'auto' : `${drawerHeight}px`}
        $positionY={drawerPositionY}
        $alignment={alignment}
      >
        <Drawer $useTransparentHandle={useTransparentHandle}>
          {children}
        </Drawer>
      </SlideDrawerContainer>
    </Container>
  );
};

SlideDrawer.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  dragDownThreshold: PropTypes.number,
  closeOnBackdropTouch: PropTypes.bool,
  draggableAreaPercentage: PropTypes.number,
  maxHeight: PropTypes.number,
  fitContent: PropTypes.bool,
  alignment: PropTypes.oneOf(Object.values(SlideDrawerAlignment)),
  className: PropTypes.string,
  closeGesturePriority: PropTypes.number,
  useTransparentHandle: PropTypes.bool,
};

SlideDrawer.defaultProps = {
  isOpen: false,
  onClose: () => {},
  dragDownThreshold: 0.5,
  closeOnBackdropTouch: true,
  draggableAreaPercentage: 15,
  maxHeight: 90,
  fitContent: false,
  alignment: SlideDrawerAlignment.CENTER,
  className: '',
  closeGesturePriority: undefined,
  useTransparentHandle: false,
};

export default SlideDrawer;
