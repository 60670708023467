import styled from 'styled-components';

import { ReactComponent as CloseButtonIcon } from '../../assets/icon/close-thread-icon.svg';

const CloseButton = styled(CloseButtonIcon)`
  position: absolute;
  height: 26px;
  margin: 10px;
  right: 0;
`;

const BroadcastButtonContainer = styled.div`
  position: absolute;
  right: 16px;
  top: 116px;
`;

export {
  CloseButton,
  BroadcastButtonContainer,
};
