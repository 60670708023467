import React, { useContext } from 'react';

import LoadingPage from '../../components/LoadingPage';

import MeasurementLogContextProvider from './MeasurementLogContextProvider';
import MeasurementLogContext from './MeasurementLogContext';

/**
 * Inserts MeasurementLogContextProvider in the react tree
 * before adding the given component.
 *
 * @param {Object} Component The component that needs the context provider.
 * @returns {Object} The component to render wrapped with the provider.
 */
const withMeasurementLogContextProvider = (Component) => (props) => (
  <MeasurementLogContextProvider>
    <Component {...props} />
  </MeasurementLogContextProvider>
);

/**
 * Renders the given component when MeasurementLogContext is ready.
 *
 * @param {Object} Component The component to render.
 * @param {Object} Component The rendered component.
 * @returns {Object} The component to render or the LoadingPage component.
 */
const withMeasurementLogContextReady = (Component) => (props) => {
  const { isReady } = useContext(MeasurementLogContext);
  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withMeasurementLogContextReady,
  withMeasurementLogContextProvider,
};
