import React, {
  useContext,
  useCallback,
  useEffect,
} from 'react';

import UserContext from '../../context/UserContext';
import logEvent from '../../utils/logger';
import AppleHealthExplainerContainer from './AppleHealthExplainer';

const HealthDataExplainer = () => {
  const {
    userConfigDoc: {
      healthDataExplainerShown,
      updateHealthDataEnabledConfig,
    },
  } = useContext(UserContext);

  useEffect(() => {
    logEvent('healthDataExplainerPageShown');
  }, []);

  const onHealthDataEnabled = useCallback(async () => {
    logEvent('healthDataEnabled');
    updateHealthDataEnabledConfig(true);
    healthDataExplainerShown();
  }, [
    updateHealthDataEnabledConfig,
    healthDataExplainerShown,
  ]);

  const onClose = useCallback(() => {
    logEvent('healthDataExplainerClosed');
    healthDataExplainerShown();
  }, [
    healthDataExplainerShown,
  ]);

  /*
    NOTE: once Google Fit or any other integration is supported, this component should manage
    which explainer content to show, AppleHealthExplainer or any other, based on platform.
  */
  return (
    <AppleHealthExplainerContainer
      onHealthDataEnabled={onHealthDataEnabled}
      onClose={onClose}
    />
  );
};

export default HealthDataExplainer;
