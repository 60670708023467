/**
 * Permissions defined by Capacitor
 *  https://github.com/ionic-team/capacitor/blob/3.2.0/core/src/definitions.ts#L124
 */
const PermissionState = {
  PROMPT: 'prompt',
  PROMPT_WITH_RATIONALE: 'prompt-with-rationale',
  GRANTED: 'granted',
  DENIED: 'denied',
};

export {
  PermissionState,
};
