import styled from 'styled-components';
import { IonSegment } from '@ionic/react';

const Profile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0 0 auto;
`;

const AccountInfo = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.system.alpha};
  padding-left: 15px;
`;

const StyledSegment = styled(IonSegment)`
  margin-top: 15px;
  --background: ${({ theme }) => theme.colors.system.gamma5};
`;

const EmptyListPlaceholder = styled.div`
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.system.beta};
  text-align: center;
  padding: 20px;
`;

const ChannelListContainer = styled.div`
  padding: 20px 10px 10px 10px;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.base.primary};
`;

const ChannelListHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 15px;
`;

export {
  Profile,
  AccountInfo,
  EmptyListPlaceholder,
  StyledSegment,
  ChannelListContainer,
  ChannelListHeader,
};
