import { useContext, useMemo } from 'react';

import UserContext from '../../context/UserContext';
import useSessionStore from '../../hooks/useSessionStore';

const useSubscriptionSlideDrawer = () => {
  const {
    userDoc: {
      isActive,
    },
  } = useContext(UserContext);
  const { isCoachOrAdmin } = useSessionStore();

  return useMemo(() => ({
    isSlideDrawerEnabled: !isCoachOrAdmin && !isActive,
  }), [
    isCoachOrAdmin,
    isActive,
  ]);
};

export default useSubscriptionSlideDrawer;
