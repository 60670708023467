const ReportTypes = {
  ERROR: 'error',
  USER_PREFERENCE: 'userPreference',
};

const INITIAL_STEP = 0;
const SECOND_STEP = 1;
const LAST_STEP = 2;

export {
  ReportTypes,
  INITIAL_STEP,
  SECOND_STEP,
  LAST_STEP,
};
