import React, {
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { debounce } from 'throttle-debounce';

import { withLayout, HeaderContext } from '../../components/Layout';
import UserContext from '../../context/UserContext';
import useUserNavigation from '../../hooks/useUserNavigation';
import { isIOS } from '../../utils/platform';
import { SETTING_CHANGE_DEBOUNCE_TIME } from './utils';
import {
  SettingsMenuItem,
  MeasurementMenuItem,
} from './components';
import {
  Container,
  SectionContainer,
  StyledProfileIcon,
  StyledCardIcon,
  StyledConnectionsIcon,
  StyledWorkoutPrefsIcon,
  StyledNotificationsIcon,
  StyledTosIcon,
  StyledPrivacyIcon,
  StyledArrowUpIcon,
} from './styles';
import texts from './texts.json';

const Settings = () => {
  const { setTitle } = useContext(HeaderContext);
  const { userConfigDoc } = useContext(UserContext);
  const { userDoc } = useContext(UserContext);
  const { navigateToUserPath } = useUserNavigation();

  useEffect(() => {
    setTitle(texts.title);
  }, [setTitle]);

  const navigateToSettingsPage = useCallback((page) => (
    navigateToUserPath(`settings/${page}`)
  ), [
    navigateToUserPath,
  ]);

  const onMeasurementSystemChange = useMemo(() => debounce(SETTING_CHANGE_DEBOUNCE_TIME, (value) => {
    userConfigDoc.updateMeasurementSystem(value);
  }), [userConfigDoc]);

  return (
    <Container>
      <SectionContainer>
        <SettingsMenuItem
          icon={StyledProfileIcon}
          name={texts.updateProfile}
          handleClick={() => navigateToSettingsPage('profile')}
        />
        <SettingsMenuItem
          icon={StyledCardIcon}
          name={texts.manageSubscription}
          handleClick={() => navigateToSettingsPage('subscriptions')}
        />
        <SettingsMenuItem
          icon={StyledConnectionsIcon}
          name={texts.manageConnections}
          handleClick={() => navigateToSettingsPage('connections')}
        />
        <SettingsMenuItem
          icon={StyledWorkoutPrefsIcon}
          name={texts.workoutPreferences}
          handleClick={() => navigateToSettingsPage('workout')}
        />
        <SettingsMenuItem
          icon={StyledNotificationsIcon}
          name={texts.manageNotifications}
          handleClick={() => navigateToSettingsPage('notifications')}
        />
        {isIOS() && userDoc.dontSetNativeAppIcon && (
          <SettingsMenuItem
            icon={StyledArrowUpIcon}
            name={texts.appIcon}
            handleClick={() => navigateToSettingsPage('appIcon')}
          />
        )}
      </SectionContainer>
      <SectionContainer>
        <MeasurementMenuItem
          value={userConfigDoc.measurementSystem}
          handleClick={onMeasurementSystemChange}
        />
      </SectionContainer>
      <SectionContainer>
        <SettingsMenuItem
          icon={StyledTosIcon}
          name={texts.termsOfService}
          handleClick={() => navigateToSettingsPage('termsOfService')}
        />
        <SettingsMenuItem
          icon={StyledPrivacyIcon}
          name={texts.privacyPolicy}
          handleClick={() => navigateToSettingsPage('privacyPolicy')}
        />
      </SectionContainer>
    </Container>
  );
};

export default compose(
  withLayout,
  observer,
)(Settings);
