import styled from 'styled-components';

import { fontFamily } from '../../../styles/text';
import CollapsiblePanel from '../../../components/CollapsiblePanel';
import colors from '../../../styles/baseColors';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  padding: 20px 0;
`;

const SubscriptionTitle = styled.div`
  width: 100%;
  text-align: left;
  font-family: ${fontFamily.sansSerif};
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 10px;
`;

const StyledCollapsiblePanel = styled(CollapsiblePanel)`
  border: 1px solid ${colors.shades.primary10};

  h3 {
    font-family: ${fontFamily.sansSerif};
    font-size: 14px;
  }
`;

const TermsContainer = styled.div`
  padding: 0 20px;
`;

export {
  Container,
  SubscriptionTitle,
  StyledCollapsiblePanel,
  TermsContainer,
};
