import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import FirebaseContext from '../FirebaseContext';
import Plan from '../../models/Plan';
import Customer from '../../models/Customer';
import PaymentMethod from '../../models/PaymentMethod';
import UserContract from '../../models/UserContract';
import useComponentMounted from '../../hooks/useComponentMounted';
import logEvent from '../../utils/logger';
import UserContext from '../UserContext';
import StripeContext from '../StripeContext';
import SubscriptionContext from './SubscriptionContext';

const SubscriptionContextProvider = ({ children }) => {
  const {
    firebase: {
      remote,
    },
  } = useContext(FirebaseContext);
  const [isReady, setIsReady] = useState(false);
  const [hasData, setHasData] = useState(false);
  const [currentPlan, setCurrentPlan] = useState({});
  const [nextPlan, setNextPlan] = useState({});
  const [stripeCustomer, setStripeCustomer] = useState({});
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState({});
  const [error, setError] = useState(false);
  const [invoiceList, setInvoiceList] = useState([]);
  const [invoiceError, setInvoiceError] = useState(false);
  const { stripeAccountId } = useContext(StripeContext);

  const isComponentMountedRef = useComponentMounted();

  const {
    userId,
    userDoc: {
      planId,
    },
  } = useContext(UserContext);

  useEffect(() => {
    const fetchData = async () => {
      let customer;
      let stripeResponseInvoiceList = [];

      try {
        customer = await Customer.getCustomerByUserId(userId);

        const userContract = await UserContract.getContractByUserId(userId);
        const { price = {} } = userContract || {};

        // We save the price info coming from the contract as the default value.
        let currentPlanDoc = price;
        let nextPlanDoc = {};

        /*
          If we have a priceId, then it means the user subscribed to a regular product and price. Otherwise, they
          subscribed to a dynamic price. Older users had the planId saved in the userDoc, so we should also extract
          the info from there.
        */
        const priceIdValue = price.priceId || planId;
        if (priceIdValue) {
          // Get the pricing information for the current and next plan associated to the user.
          currentPlanDoc = await Plan.getPlan(priceIdValue) || price;
          nextPlanDoc = await Plan.getPlan(currentPlanDoc?.nextPlan) || currentPlanDoc;
        }

        // Get the payment information document. This is independent of the subscription the user has.
        let paymentMethodDoc;
        if (customer) {
          // Extract the default payment method configured for this user.
          const {
            stripeCustomer: {
              default_source: defaultSourceId,
              invoice_settings: {
                default_payment_method: defaultInvoicePaymentMethodId,
              },
            },
          } = customer;

          const defaultPaymentMethodId = defaultInvoicePaymentMethodId || defaultSourceId;

          paymentMethodDoc = new PaymentMethod(defaultPaymentMethodId);
          await paymentMethodDoc.fetch();

          // Get and save invoice list into state for context export
          try {
            const apiResponse = await remote('listInvoicesByUserId', {
              userId,
              stripeAccountId,
            });
            stripeResponseInvoiceList = await apiResponse.json();
          } catch (errorObj) {
            setInvoiceError(true);
          }
        } else {
          throw new Error(`No customer found for user ${userId}`);
        }

        if (isComponentMountedRef.current) {
          setCurrentPlan(currentPlanDoc);
          setNextPlan(nextPlanDoc);
          setStripeCustomer(customer);
          setInvoiceList(stripeResponseInvoiceList);

          if (paymentMethodDoc.exists) {
            setDefaultPaymentMethod(paymentMethodDoc);
            setHasData(true);
          } else {
            setError(true);
          }

          setIsReady(true);
        }
      } catch (errorObj) {
        logEvent('subscriptionContextInitializationFailed', {
          errorMessage: errorObj.message || errorObj,
          userId,
          customer: customer?.id,
        });
        setIsReady(true);
        setError(true);
      }
    };

    if (!isReady) {
      fetchData();
    }
  }, [
    isReady,
    planId,
    isComponentMountedRef,
    userId,
    remote,
    stripeAccountId,
  ]);

  const onPaymentMethodChanged = useCallback(async (paymentMethodId) => {
    const paymentMethodDoc = new PaymentMethod(paymentMethodId);
    await paymentMethodDoc.fetch();
    if (isComponentMountedRef.current) {
      setDefaultPaymentMethod(paymentMethodDoc);
    }
  }, [
    isComponentMountedRef,
  ]);

  const value = useMemo(() => ({
    isReady,
    error,
    hasData,
    currentPlan,
    nextPlan,
    stripeCustomer,
    defaultPaymentMethod,
    onPaymentMethodChanged,
    invoiceList,
    invoiceError,
  }), [
    isReady,
    error,
    hasData,
    currentPlan,
    nextPlan,
    stripeCustomer,
    defaultPaymentMethod,
    onPaymentMethodChanged,
    invoiceList,
    invoiceError,
  ]);

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
};

SubscriptionContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(SubscriptionContextProvider);
