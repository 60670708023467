import React from 'react';
import PropTypes from 'prop-types';

import {
  ActionsContainer,
} from '../styles';

import {
  IconContainer,
  StyledNextIcon,
} from './styles';

const VirtualNextAction = ({
  sizeConfig,
  shouldBlink,
}) => (
  <ActionsContainer {...sizeConfig}>
    <IconContainer shouldBlink={shouldBlink}>
      <StyledNextIcon />
    </IconContainer>
  </ActionsContainer>
);

VirtualNextAction.propTypes = {
  sizeConfig: PropTypes.shape({
    top: PropTypes.number.isRequired,
    bottom: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }).isRequired,
  shouldBlink: PropTypes.bool,
};

VirtualNextAction.defaultProps = {
  shouldBlink: false,
};

export default VirtualNextAction;
