import CollectionName from '../utils/collections';
import BaseDocument from './BaseDocument';

class AppCustomCheckin extends BaseDocument {
  constructor(id, opts) {
    super(`${CollectionName.APP_CUSTOM_CHECK_IN}/${id}`, opts);
  }

  get checkInQuestions() {
    return this.data.checkInQuestions || [];
  }

  get checkInQuestionDefinition() {
    return this.data.checkInQuestionDefinition;
  }

  get isImageUploadCompulsory() {
    return !!this.data.isImageUploadCompulsory;
  }

  static async getById(id) {
    const appCustomCheckInDoc = new AppCustomCheckin(id);
    await appCustomCheckInDoc.init();
    return appCustomCheckInDoc.exists ? appCustomCheckInDoc : null;
  }
}

export default AppCustomCheckin;
