import React, {
  useContext,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import useAppTheme from '../../../hooks/useAppTheme';
import BackButton from '../../BackButton';
import ExerciseOverrideSelectorContext from '../context/ExerciseOverrideSelectorContext';
import SearchButton from '../SearchButton';
import {
  Header,
  StyledExercisesSection,
} from '../commonStyles';
import texts from './texts.json';
import {
  Title,
  Name,
  StyledTagFilters,
} from './styles';

const ExercisesFilterContent = ({
  onCancel,
}) => {
  const {
    exercises,
    originalExerciseRef,
    setIsSearchEnabled,
    logExerciseOverrideEvent,
  } = useContext(ExerciseOverrideSelectorContext);

  const { colors } = useAppTheme();

  useEffect(() => {
    logExerciseOverrideEvent('exerciseOverrideSelectorView');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onShowSearch = useCallback(() => setIsSearchEnabled(true), [setIsSearchEnabled]);

  const {
    name,
  } = originalExerciseRef.current;

  return (
    <>
      <Header>
        <BackButton
          onClick={onCancel}
          color={colors.system.beta}
          trackingProps={{
            actionName: 'exerciseOverrideGoBack',
          }}
        />
        <Title>{texts.title}</Title>
        <SearchButton
          onClick={onShowSearch}
        />
      </Header>
      <Name>{name}</Name>
      <StyledTagFilters />
      <StyledExercisesSection exercises={exercises} />
    </>
  );
};

ExercisesFilterContent.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default ExercisesFilterContent;
