import React from 'react';
import {
  IonItem,
  IonList,
} from '@ionic/react';
import PropTypes from 'prop-types';

import { AttachmentActions } from './utils';
import {
  StyledVideoIcon,
  StyledImageIcon,
} from './styles';
import texts from './texts';

const MessagingAttachmentPopover = ({
  handleAttachmentAction,
}) => (
  <IonList>
    <IonItem button detail={false} onClick={() => handleAttachmentAction(AttachmentActions.PHOTO)}>
      <StyledImageIcon />
      {texts.actionsPopover[AttachmentActions.PHOTO]}
    </IonItem>
    <IonItem button detail={false} onClick={() => handleAttachmentAction(AttachmentActions.VIDEO)}>
      <StyledVideoIcon />
      {texts.actionsPopover[AttachmentActions.VIDEO]}
    </IonItem>
  </IonList>
);

MessagingAttachmentPopover.propTypes = {
  handleAttachmentAction: PropTypes.func.isRequired,
};

export default MessagingAttachmentPopover;
