import React, {
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';

import MealPlanAssignment from '../../models/MealPlanAssignment';
import useComponentMounted from '../../hooks/useComponentMounted';
import useAppCustomization from '../../hooks/useAppCustomization';
import useComponentLoadingTime from '../../hooks/useComponentLoadingTime';
import UserContext from '../UserContext';
import { Feature } from '../AppCustomizationContext';

import MealPlanContext, { initialValues } from './MealPlanContext';

const MealPlanContextProvider = ({
  children,
}) => {
  const [mealPlanAssignmentDoc, setMealPlanAssignmentDoc] = useState(initialValues.mealPlanAssignmentDoc);
  const [mealPlanCollection, setMealPlanCollection] = useState(initialValues.mealPlanCollection);
  const [isReady, setIsReady] = useState(false);
  const { startLoading, finishLoading } = useComponentLoadingTime('mealPlanContext');

  const {
    userId,
  } = useContext(UserContext);
  const { isFeatureEnabled } = useAppCustomization();
  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    const run = async () => {
      startLoading();
      const assignmentsCollection = await MealPlanAssignment.getCurrentAssignmentCollection(userId);

      if (isComponentMountedRef.current) {
        if (assignmentsCollection?.hasDocs) {
          setMealPlanAssignmentDoc(assignmentsCollection.docs[0]);
        }
        setMealPlanCollection(assignmentsCollection);
        setIsReady(true);
        finishLoading();
      }
    };

    run();
  }, [
    isComponentMountedRef,
    userId,
    startLoading,
    finishLoading,
  ]);

  // This useEffect listens for changes in the assignment collection to set the assignment doc
  useEffect(() => {
    const disposer = autorun(() => {
      if (mealPlanCollection?.hasDocs) {
        setMealPlanAssignmentDoc(mealPlanCollection.docs[0]);
      } else {
        setMealPlanAssignmentDoc(initialValues.mealPlanAssignmentDoc);
      }
    });
    return disposer;
  }, [
    mealPlanCollection,
  ]);

  const hasMealPlan = useMemo(() => (
    isFeatureEnabled(Feature.NUTRITION_PLAN)
      && ((mealPlanAssignmentDoc && mealPlanAssignmentDoc.exists)
      || initialValues.hasMealPlan)
  ), [
    mealPlanAssignmentDoc,
    isFeatureEnabled,
  ]);

  const context = useMemo(() => ({
    isReady,
    hasMealPlan,
    mealPlanAssignmentDoc,
  }), [
    isReady,
    hasMealPlan,
    mealPlanAssignmentDoc,
  ]);

  return (
    <MealPlanContext.Provider value={context}>
      {children}
    </MealPlanContext.Provider>
  );
};

MealPlanContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(MealPlanContextProvider);
