import moment from 'moment';

import { SubscriptionStatus } from '../utils/stripe';
import BaseDocument from './BaseDocument';

class User extends BaseDocument {
  get firstName() {
    return this.data.firstName;
  }

  get lastName() {
    return this.data.lastName;
  }

  get name() {
    const lastName = this.lastName ? ` ${this.lastName}` : '';
    return `${this.firstName}${lastName}`;
  }

  get email() {
    return this.data.email;
  }

  get streamToken() {
    return this.data.streamToken;
  }

  get avatarUrl() {
    return this.data.avatarUrl;
  }

  get serviceStartAtSeconds() {
    return this.data.serviceStartAt?.seconds || 0;
  }

  get experimental() {
    return this.data.experimental;
  }

  get flags() {
    return this.data.flags || {};
  }

  get isDayZero() {
    return !!this.flags.dayZero;
  }

  get isActive() {
    return !!this.flags.isActive;
  }

  get isDelinquent() {
    return !!this.flags.delinquent;
  }

  get firstPaymentSuccessful() {
    return !!this.flags.firstPaymentSuccessful;
  }

  get subscriptionStatus() {
    return this.flags.subscriptionStatus || '';
  }

  get assignedCoach() {
    return this.data.assignedCoach;
  }

  get openTokSessions() {
    return this.data.openTokSessions;
  }

  get assignedMealPlan() {
    return this.data.assignedMealPlan;
  }

  get product() {
    return this.data.product;
  }

  get planId() {
    return this.data.planId;
  }

  get isCoachAssistant() {
    return !!this.data.isCoachAssistant;
  }

  get checkInDay() {
    return this.data.checkInDay || 0;
  }

  get dontSetNativeAppIcon() {
    return !!this.data.dontSetNativeAppIcon;
  }

  get tags() {
    return this.data.tags || [];
  }

  get customTags() {
    return this.data.customTags || [];
  }

  get breakEndDate() {
    return this.data?.breakEndDate?.toDate();
  }

  get isOnBreak() {
    return !!this.breakEndDate && moment(this.breakEndDate).isAfter(moment());
  }

  get isCanceled() {
    return this.subscriptionStatus === SubscriptionStatus.CANCELED.toUpperCase();
  }

  /**
   * Returns the last session from openTokSession pool in user document
   * @returns {Promise<null|{sessionId: *, token: *}>}
   */
  getLastOpenTokSession = async () => {
    if (this.openTokSessions && this.openTokSessions.length > 0) {
      const sessions = JSON.parse(JSON.stringify(this.openTokSessions));

      sessions.sort((x, y) => y.expireTime - x.expireTime);

      // valid session
      if (sessions[0].expireTime > Date.now()) {
        // removing this session and update firestore because it is used now
        const [{ sessionId, token }] = sessions.splice(0, 1);

        // need to update the whole array as firestorter does not support dotted notation
        this.update({
          openTokSessions: sessions,
        });

        return {
          token,
          sessionId,
        };
      }
    }
    return null;
  };
}

export default User;
