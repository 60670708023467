import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { compose } from 'recompose';
import { IonAlert } from '@ionic/react';
import { observer } from 'mobx-react';
import moment from 'moment';
import * as Sentry from '@sentry/browser';

import WorkoutContext, {
  withWorkoutContextReady,
} from '../../context/WorkoutContext';
import { withLayout } from '../../components/Layout';
import LoadingPage from '../../components/LoadingPage';
import {
  VideoPlaylistContext,
  withVideoPlaylistContextProvider,
} from '../../components/VideoPlaylist';
import { convertToLocalDate, isToday } from '../../utils/date';
import logEvent from '../../utils/logger';
import useGameplayNavigation from '../../hooks/useGameplayNavigation';
import useCurrentLoggedInUser from '../../hooks/useCurrentLoggedInUser';
import useComponentLoadingTime from '../../hooks/useComponentLoadingTime';
import useComponentMounted from '../../hooks/useComponentMounted';
import useWorkoutAssignmentEdition from '../../hooks/useWorkoutAssignmentEdition';
import useAppCustomization from '../../hooks/useAppCustomization';
import useSessionStore from '../../hooks/useSessionStore';
import HealthDataSyncContext from '../../context/HealthDataSyncContext';
import WatchContext from '../../context/WatchContext';
import { Feature } from '../../context/AppCustomizationContext';
import UserContext from '../../context/UserContext';
import SubscriptionSlideDrawerContext from '../../context/SubscriptionSlideDrawerContext';
import { withActivityDetailsContextProvider } from '../../components/ActivityDetailsModal';

import WorkoutDetails from './WorkoutDetails';
import FullScreenLoadingPage from './FullScreenLoadingPage';
import texts from './texts.json';
import { PageContentWrapper } from './styles';

const COACH_FEEDBACK_MIN_VIDEO_DURATION = 60; // in seconds

const WorkoutDetailsContainer = () => {
  const { navigateToGameplaySession } = useGameplayNavigation();
  /*
    Let's keep a reference to the collection instead of the document, so when new gameplay sessions
    are created, the query runs again and the collection gets updated with the latest gameplay session
    that the workout details needs.
  */
  const [latestGameplaySessionCollection, setLatestGameplaySessionCollection] = useState(null);
  const [trackedActivitiesCollection, setTrackedActivitiesCollection] = useState(null);
  const [showResumeAlert, setShowResumeAlert] = useState(false);
  const [showAddWorkoutAlert, setShowAddWorkoutAlert] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [showProgressIndicator, setShowProgressIndicator] = useState(false);

  const {
    workoutAssignmentDoc,
    updateWorkoutAssignment,
    workoutDef: {
      name: workoutName,
      note,
      estimatedDuration,
    },
    activitiesWithOverrides,
  } = useContext(WorkoutContext);

  const {
    hasPlayerForEveryVideo,
    duration,
  } = useContext(VideoPlaylistContext);

  const {
    syncActivitiesData,
  } = useContext(HealthDataSyncContext);

  const {
    isWatchAvailable,
  } = useContext(WatchContext);

  const {
    userDoc: {
      isActive: isUserActive,
    },
  } = useContext(UserContext);

  const { openSlider: openSubscriptionSlider } = useContext(SubscriptionSlideDrawerContext);
  const { isCoachOrAdmin } = useSessionStore();

  const { isCurrentLoggedInUserInPath } = useCurrentLoggedInUser();
  const {
    updateWorkoutAssignmentToStart,
    prepareWorkoutAssignmentForToday,
  } = useWorkoutAssignmentEdition();
  const isComponentMountedRef = useComponentMounted();

  const { isFeatureEnabled } = useAppCustomization();

  // Page/component loading time measuremnt APIs
  const { startLoading, finishLoading } = useComponentLoadingTime('workoutDetails');

  const goToGameplay = useCallback((gameplaySessionId = '') => {
    navigateToGameplaySession({
      workoutAssignmentId: workoutAssignmentDoc.id,
      gameplaySessionId,
    });
  }, [
    navigateToGameplaySession,
    workoutAssignmentDoc.id,
  ]);

  const startOtherDayWorkoutToday = useCallback(async () => {
    logEvent('startOtherDayWorkoutTodayCalled', {
      isOtherDayWorkoutAssignmentCompleted: workoutAssignmentDoc.isCompleted,
    });

    setShowProgressIndicator(true);
    setShowAddWorkoutAlert(false);

    let todayWorkoutAssignment;

    try {
      todayWorkoutAssignment = await prepareWorkoutAssignmentForToday(workoutAssignmentDoc);
    } catch (error) {
      logEvent('startOtherDayWorkoutTodayInitFailed');

      if (isComponentMountedRef.current) {
        setShowProgressIndicator(false);
      }
      return;
    }

    const { id: newWorkoutAssignmentId } = todayWorkoutAssignment;

    logEvent('updateOtherDayWorkoutReady', {
      newWorkoutAssignmentId,
    });

    if (isComponentMountedRef.current) {
      // Update context provider with newly created WorkoutAssignment
      updateWorkoutAssignment(todayWorkoutAssignment);
      setShowProgressIndicator(false);
      /*
        Navigate to the newly created WorkoutAssignment gameplay page. This will change the
        history navigation URL with a new URL.
      */
      navigateToGameplaySession({
        workoutAssignmentId: todayWorkoutAssignment.id,
      }, {
        shouldReplaceURL: true,
      });
    }

    logEvent('updateOtherDayWorkoutDone', {
      newWorkoutAssignmentId,
    });
  }, [
    workoutAssignmentDoc,
    updateWorkoutAssignment,
    navigateToGameplaySession,
    prepareWorkoutAssignmentForToday,
    isComponentMountedRef,
  ]);

  const startWorkout = useCallback(async () => {
    setShowResumeAlert(false);

    const startMomentDate = moment(workoutAssignmentDoc.latestWorkoutStartDate.toMillis());
    const startMomentLocalDate = convertToLocalDate(startMomentDate);
    const isTodayWorkout = isToday(startMomentLocalDate);

    if (isTodayWorkout) {
      updateWorkoutAssignmentToStart(workoutAssignmentDoc);
      goToGameplay();
    } else {
      setShowAddWorkoutAlert(true);
    }
  }, [
    goToGameplay,
    workoutAssignmentDoc,
    setShowAddWorkoutAlert,
    updateWorkoutAssignmentToStart,
  ]);

  const resumeWorkout = useCallback(() => {
    setShowResumeAlert(false);

    if (latestGameplaySessionCollection && latestGameplaySessionCollection.docs.length === 1) {
      goToGameplay(latestGameplaySessionCollection.docs[0].id);
    }
  }, [
    latestGameplaySessionCollection,
    goToGameplay,
  ]);

  const cancelResumeAlert = useCallback(() => setShowResumeAlert(false), []);

  const cancelAddWorkoutAlert = useCallback(() => setShowAddWorkoutAlert(false), []);

  const onActionButtonClicked = useCallback(() => {
    if (isUserActive || (isCoachOrAdmin && isCurrentLoggedInUserInPath)) {
      const gameplaySession = latestGameplaySessionCollection && latestGameplaySessionCollection.docs[0];
      if (gameplaySession && !gameplaySession.isDone) {
        // User might want to resume. Show an alert about that!
        setShowResumeAlert(true);
      } else {
        // Start the workout
        startWorkout();
      }
    } else {
      openSubscriptionSlider();
    }
  }, [
    startWorkout,
    latestGameplaySessionCollection,
    setShowResumeAlert,
    isUserActive,
    isCoachOrAdmin,
    isCurrentLoggedInUserInPath,
    openSubscriptionSlider,
  ]);

  useEffect(() => {
    // Measure workout details page start loading time
    startLoading();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Measure workout details pages finish loading time
    if (isReady) {
      finishLoading();
    }
  }, [
    isReady,
    finishLoading,
  ]);

  useEffect(() => {
    const init = async () => {
      const latestGameplaySessionCol = await workoutAssignmentDoc.latestGameplaySessionCollection();
      if (isComponentMountedRef.current) {
        setLatestGameplaySessionCollection(latestGameplaySessionCol);
        setIsReady(true);
      }
    };
    if (!isReady) {
      init();
    }
  }, [
    isReady,
    workoutAssignmentDoc,
    isComponentMountedRef,
  ]);

  const latestGameplaySession = latestGameplaySessionCollection && latestGameplaySessionCollection.docs.length === 1
    ? latestGameplaySessionCollection.docs[0]
    : null;

  const sessionStartTime = latestGameplaySession ? latestGameplaySession.startTime : 0;
  const sessionEndTime = latestGameplaySession ? latestGameplaySession.endTime : 0;

  useEffect(() => {
    // Sync tracked activities that might been created during the gameplay session
    if (isWatchAvailable && sessionStartTime > 0) {
      (async function syncTrackedActivities() {
        const fromDate = moment(sessionStartTime).toDate();

        // Calculate the toDate value in the date range. If there's no endTime, use the
        // end of the startTime day. Always add 1 day, to ensure we handle corner scenarios
        const currentEndTime = sessionEndTime > 0 ? sessionEndTime : sessionStartTime;
        const toDate = moment(currentEndTime).add(1, 'days').endOf('day').toDate();

        try {
          await syncActivitiesData(fromDate, toDate);
        } catch (error) {
          Sentry.captureException(error, {
            extra: {
              description: 'Error synchronizing activities for GameplaySession in workout details',
            },
          });
        }
      }());
    }
  }, [
    isWatchAvailable,
    sessionStartTime,
    sessionEndTime,
    syncActivitiesData,
  ]);

  // Fetch tracked activities if any
  useEffect(() => {
    if (latestGameplaySession) {
      (async function fetchTrackedActivities() {
        const trackedActivities = await latestGameplaySession.getTrackedActivities();

        if (isComponentMountedRef.current) {
          setTrackedActivitiesCollection(trackedActivities);
        }
      }());
    } else {
      setTrackedActivitiesCollection(null);
    }
  }, [
    latestGameplaySession,
    isComponentMountedRef,
  ]);

  const {
    isCompleted,
  } = workoutAssignmentDoc;

  const actionButtonText = isCompleted ? texts.buttonTexts.restart : texts.buttonTexts.start;

  /*
    Only show coach feedback component when the workout video feedback feature is enabled for the
    user's product, the players are available, the video URL exists and the
    GameplaySession contains a video that has at least a specific duration.
  */
  const showCoachFeedback = isFeatureEnabled(Feature.WORKOUT_VIDEO_FEEDBACK)
    && hasPlayerForEveryVideo
    && duration >= COACH_FEEDBACK_MIN_VIDEO_DURATION;

  if (!isReady) {
    return <LoadingPage />;
  }

  return (
    <>
      <IonAlert
        isOpen={showResumeAlert}
        header={texts.resumeAlert.title}
        message={texts.resumeAlert.message}
        buttons={[
          {
            text: texts.resumeAlert.startOverAction,
            handler: startWorkout,
          },
          {
            text: texts.resumeAlert.resumeAction,
            handler: resumeWorkout,
          },
          {
            text: texts.cancelAction,
            role: 'cancel',
            handler: cancelResumeAlert,
          },
        ]}
      />
      <IonAlert
        isOpen={showAddWorkoutAlert}
        header={texts.addWorkoutToday.title}
        message={texts.addWorkoutToday.message}
        buttons={[
          {
            text: texts.addWorkoutToday.addToToday,
            handler: startOtherDayWorkoutToday,
          },
          {
            text: texts.cancelAction,
            role: 'cancel',
            handler: cancelAddWorkoutAlert,
          },
        ]}
      />
      <PageContentWrapper>
        <WorkoutDetails
          workoutName={workoutName}
          note={note}
          estimatedDuration={estimatedDuration}
          activities={activitiesWithOverrides}
          latestGameplaySession={latestGameplaySession}
          trackedActivities={trackedActivitiesCollection}
          showCoachFeedback={showCoachFeedback}
          showActionButton={isCurrentLoggedInUserInPath}
          actionButtonText={actionButtonText}
          onActionButtonClicked={onActionButtonClicked}
        />
        {showProgressIndicator && (
          <FullScreenLoadingPage />
        )}
      </PageContentWrapper>
    </>
  );
};

export default compose(
  withLayout,
  withWorkoutContextReady,
  withVideoPlaylistContextProvider,
  withActivityDetailsContextProvider,
  observer,
)(WorkoutDetailsContainer);
