import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const EmptyChat = styled.div`
  width: 100%;
  height: 100%;
`;

export {
  Container,
  EmptyChat,
};
