import styled from 'styled-components';
import { IonModal } from '@ionic/react';

import { ReactComponent as Medal } from '../../../../../../assets/medal.svg';

import {
  headline2,
  body1,
} from '../../../../../../styles/text';
import { safeAreaFromTop, safeAreaFromBottom } from '../../../../../../styles/mixins';

import Card, { CardTitle } from '../../../../../../components/Card';
import Button from '../../../../../../components/Button';
import Toggle from '../../../../../../components/Toggle';
import FeedbackPanelCloseButton from './FeedbackPanelCloseButton';

const StyledFeedbackModal = styled(IonModal)`
  .ion-page {
    padding-top: ${safeAreaFromTop()}
  }
`;

const StyledFeedbackPanel = styled.div`
  height: 100%;
  overflow: auto;
  background-color: ${({ theme }) => theme.colors.system.gamma6};
  padding-bottom: ${safeAreaFromBottom()};
  padding-top: ${safeAreaFromTop()};

  /*
    Overriding padding-bottom in this specific media query specified by ionic framework for modals.
    NOTE: update this breakpoints only when ionic modal media query changes.
  */
  @media only screen and (min-width: 768px) and (min-height: 600px) {
    padding-bottom: unset;
  }
`;

const StyledCloseButton = styled(FeedbackPanelCloseButton)`
  position: absolute;
  margin-top: 15px;
`;

const StyledMedal = styled(Medal)`
  display: block;
  height: 170px;
  margin: auto;
`;

const Title = styled.div`
  ${headline2};
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  line-height: 49px;

  margin-top: ${({ addMargin }) => (addMargin ? '45px' : '0')};
  margin-bottom: 20px;
`;

const Subtitle = styled.div`
  ${body1};
  text-align: center;
`;

const StyledCard = styled(Card)`
  flex-direction: column;
  margin: ${({ noMargins }) => (noMargins ? 0 : '15px 0')};
  box-shadow: none;
  padding: 30px 16px 10px;
`;

const HeaderCard = styled(StyledCard)`
  margin: 0 0 5px;
  padding-top: 0;
  padding-bottom: 40px;
  position: relative;
`;

const StyledCardTitle = styled(CardTitle)`
  font-size: 20px;
  line-height: 27px;
  font-weight: 700;
  margin-bottom: 22px;
  text-align: center;
`;

const Comments = styled.textarea`
  outline: none;
  width: 100%;
  height: 120px;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.system.background};
  color: ${({ theme }) => theme.colors.base.primary};
  resize: none;
  transition: all 0.3s;

  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.colors.base.primary};
  }
`;

const StyledToggle = styled(Toggle)`
  padding: 20px 24px;
  margin: 15px 0;
  background-color: ${({ theme }) => theme.colors.system.alpha};
`;

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colors.fonts.primary};
`;

export {
  StyledFeedbackModal,
  StyledFeedbackPanel,
  HeaderCard,
  StyledCloseButton,
  StyledMedal,
  Title,
  Subtitle,
  StyledCard,
  StyledCardTitle,
  Comments,
  StyledToggle,
  StyledButton,
};
