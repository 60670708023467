import React, { useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import CameraContext from '../../../context/CameraContext';

const VideoCameraPreview = ({
  previewRefReady,
  className,
}) => {
  const previewRef = useRef(null);
  const { camera: { stream } } = useContext(CameraContext);

  useEffect(() => {
    // If the ref is ready, and the camera is on, then attach the mediaStream as the source.
    if (previewRef.current && stream) {
      previewRef.current.srcObject = stream;
    }
  }, [stream, previewRef]);

  useEffect(() => {
    if (previewRef.current && previewRefReady) {
      previewRefReady(previewRef);
    }
  }, [
    previewRef,
    previewRefReady,
  ]);

  return (
    <video
      ref={previewRef}
      autoPlay
      playsInline
      controls={false}
      poster="noposter"
      className={className}
    />
  );
};

VideoCameraPreview.propTypes = {
  previewRefReady: PropTypes.func,
  className: PropTypes.string,
};

VideoCameraPreview.defaultProps = {
  previewRefReady: null,
  className: '',
};

export default compose(
  observer,
)(VideoCameraPreview);
