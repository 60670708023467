import React from 'react';
import PropTypes from 'prop-types';

import { v4 as uuidv4 } from 'uuid';

import FileSelectButton from '../FileSelectButton';
import LoadingOverlay from '../LoadingOverlay';
import {
  FileUploadContainer,
  FileList,
  FileItem,
  FileName,
  StyledButton,
  StyledAttchmentIcon,
  StyledCloseButton,
} from './styles';
import texts from './texts.json';

const AttachmentsContainer = ({
  fileAttachments,
  onFilesSelected,
  onFileRemoved,
  isUploading,
  className,
}) => {
  const openFileInNewTab = (url) => {
    if (url) {
      window.open(url, '_blank');
    }
  };
  return (
    <FileUploadContainer className={className}>
      <FileSelectButton
        ButtonComponent={StyledButton}
        onSelect={onFilesSelected}
        multiple
      >
        <StyledAttchmentIcon />
        {texts.attachFile}
      </FileSelectButton>
      <FileList>
        {fileAttachments.map((file, index) => (
          <FileItem key={`${file.name}-${uuidv4()}`}>
            <FileName
              onClick={() => openFileInNewTab(file.dataUrl)}
            >
              {file.name}
            </FileName>

            <StyledCloseButton onClick={() => onFileRemoved(index)} />
          </FileItem>
        ))}
      </FileList>
      <LoadingOverlay isLoading={isUploading && fileAttachments.length !== 0} />
    </FileUploadContainer>
  );
};

AttachmentsContainer.propTypes = {
  fileAttachments: PropTypes.array.isRequired,
  onFilesSelected: PropTypes.func.isRequired,
  onFileRemoved: PropTypes.func.isRequired,
  isUploading: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

AttachmentsContainer.defaultProps = {
  className: '',
};

export default AttachmentsContainer;
