import React, {
  useEffect,
  useContext,
  useCallback,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import format from 'string-template';

import { withLayout, HeaderContext } from '../../../components/Layout';
import UserContractContext from '../../../context/UserContractContext';
import BillingInformationContainer from '../../../components/BillingInformation';
import LoadingPage from '../../../components/LoadingPage';
import SubscriptionTerms from '../../../components/ContractTerms';
import InvoiceListContainer from '../components/InvoiceList/InvoiceListContainer';

import {
  SettingsCard,
  SettingsCardTitle,
} from '../components/styles';

import {
  Container,
  SubscriptionTitle,
  StyledCollapsiblePanel,
  TermsContainer,
} from './styles';

import texts from './texts.json';

const ManageSubscriptions = () => {
  const { setTitle } = useContext(HeaderContext);
  const { userContractCollection, isReady } = useContext(UserContractContext);

  useEffect(() => {
    setTitle(texts.manageSubscriptions);
  }, [
    setTitle,
  ]);

  const renderContractTerms = useCallback(() => {
    if (userContractCollection.hasDocs) {
      // Sort the contracts by creation date, so that the most recent one is shown first.
      const sortedContracts = userContractCollection.docs.sort((a, b) => b.createdAt - a.createdAt);

      return sortedContracts.map((contractDoc) => {
        const title = contractDoc.current
          ? format(texts.contractHistory.current, { status: contractDoc.status })
          : texts.contractHistory.expired;

        return (
          <StyledCollapsiblePanel
            title={title}
            key={contractDoc.id}
            startOpen={contractDoc.current}
          >
            <TermsContainer>
              <SubscriptionTerms
                contractDoc={contractDoc}
              />
            </TermsContainer>
          </StyledCollapsiblePanel>
        );
      });
    }

    return null;
  }, [
    userContractCollection,
  ]);

  return (
    <Container>
      <SettingsCard>
        <SettingsCardTitle>{texts.creditCard}</SettingsCardTitle>
        <BillingInformationContainer isEditable />
      </SettingsCard>
      <SettingsCard>
        <SettingsCardTitle>{texts.subscription}</SettingsCardTitle>
        <SubscriptionTitle>{texts.termsAndConditions}</SubscriptionTitle>
        {isReady ? renderContractTerms() : <LoadingPage fullHeight={false} />}
      </SettingsCard>
      <SettingsCard>
        <SettingsCardTitle>{texts.invoices}</SettingsCardTitle>
        <InvoiceListContainer />
      </SettingsCard>
    </Container>
  );
};

export default compose(
  withLayout,
  observer,
)(ManageSubscriptions);
