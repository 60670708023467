import styled from 'styled-components';
import { IonCheckbox } from '@ionic/react';

import { sansSerif } from '../../../styles/text';
import OriginalExercise from '../../OriginalExercise';
import Button from '../../Button';

const ActionsContainer = styled.div`
  display: flex;
  width: 100%;
`;

const StyledOriginalExercise = styled(OriginalExercise)``;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 8px 8px 13px;
  background: ${({ theme }) => theme.colors.system.background};
  border-radius: 4px;
`;

const Label = styled.label`
  ${sansSerif.headlineFontStyles}
  padding: 0 0 0 9px;
  color: rgba(20, 34, 47, 0.8);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
`;

const StyledIonCheckbox = styled(IonCheckbox)`
  flex: 0 0 auto;

  --background: transparent;
  --border-color: #24292E;
  --background-checked: #24292E;
  --border-color-checked: #24292E;
  --border-radius: 4px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  padding: 16px 0;

  /* stylelint-disable selector-type-no-unknown */
  ${StyledOriginalExercise},
  ${CheckboxWrapper},
  ${ActionsContainer} {
    margin: 24px 0 0 0;
  }
  /* stylelint-enable selector-type-no-unknown */
`;

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colors.fonts.primary};
`;

export {
  Container,
  ActionsContainer,
  StyledOriginalExercise,
  CheckboxWrapper,
  Label,
  StyledIonCheckbox,
  StyledButton,
};
