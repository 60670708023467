import {
  drawHuman,
  drawHands,
  drawActionAreas,
  FlowHuman,
  Hand,
} from '../../../../services/trackingSystem';
import videoSizeConfig from '../config';

class DebugSelfie {
  constructor(canvasRef) {
    this.canvasRef = canvasRef;
  }

  drawImage(previewElement) {
    const { width, height } = this.canvasRef.current;
    const selfieCtx = this.canvasRef.current.getContext('2d');
    selfieCtx.drawImage(previewElement, 0, 0, width, height);
  }

  drawProcessingResult(previewElement, predictionModelResult, actionAreas, processingArea) {
    this.drawImage(previewElement);
    const selfieCtx = this.canvasRef.current.getContext('2d');
    selfieCtx.save();

    if (predictionModelResult instanceof FlowHuman) {
      drawHuman(selfieCtx, predictionModelResult);
    } else if (predictionModelResult instanceof Hand) {
      drawHands(selfieCtx, [predictionModelResult]);
    }

    drawActionAreas(selfieCtx, actionAreas, processingArea);

    selfieCtx.restore();
  }

  updateSize(aspectRatio) {
    const { debug: { width } } = videoSizeConfig;

    this.canvasRef.current.width = width;
    this.canvasRef.current.height = width / aspectRatio;
  }
}

export default DebugSelfie;
