import React from 'react';
import PropTypes from 'prop-types';

import ExerciseList from '../ExerciseList';
import EmptyExercisesMessage from './EmptyExercisesMessage';
import {
  Container,
} from './styles';

const ExerciseSection = ({
  showOriginalExercise,
  exercises,
  searchText,
  className,
}) => (
  <Container className={className}>
    {exercises.length > 0
      ? (
        <ExerciseList
          exercises={exercises}
          searchText={searchText}
          showOriginalExercise={showOriginalExercise}
        />
      )
      : <EmptyExercisesMessage />}
  </Container>
);

ExerciseSection.propTypes = {
  exercises: PropTypes.array.isRequired,
  showOriginalExercise: PropTypes.bool,
  searchText: PropTypes.string,
  className: PropTypes.string,
};

ExerciseSection.defaultProps = {
  showOriginalExercise: false,
  searchText: '',
  className: '',
};

export default ExerciseSection;
