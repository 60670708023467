import styled, { css } from 'styled-components';
import { ReactComponent as ImageIcon } from '../../../assets/icons/photo-attachment.svg';
import { ReactComponent as VideoIcon } from '../../../assets/icons/video-attachment.svg';

const iconStyles = css`
  height: auto;
  width: 20px;
  padding-right: 5px;
`;

const StyledImageIcon = styled(ImageIcon)`
  ${iconStyles}
`;

const StyledVideoIcon = styled(VideoIcon)`
  ${iconStyles}
`;

export {
  StyledImageIcon,
  StyledVideoIcon,
};
