import React from 'react';
import PropTypes from 'prop-types';

import useAppTheme from '../../hooks/useAppTheme';
import {
  BackButtonContainer,
  StyledBackArrowIcon,
} from './styles';

const BackButton = ({
  color,
  ...buttonProps
}) => {
  const { colors } = useAppTheme();
  const colorValue = color || colors.system.alpha;

  return (
    <BackButtonContainer {...buttonProps}>
      <StyledBackArrowIcon color={colorValue} />
    </BackButtonContainer>
  );
};

BackButton.propTypes = {
  color: PropTypes.string,
};

BackButton.defaultProps = {
  color: '', // Default value is defined by the theme
};

export default BackButton;
