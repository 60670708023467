import React from 'react';
import {
  Route,
  useRouteMatch,
} from 'react-router-dom';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { IonRouterOutlet } from '@ionic/react';

import Settings from '../pages/Settings';
import ProfileSettings from '../pages/Settings/ProfileSettings';
import ManageSubscriptions from '../pages/Settings/ManageSubscriptions';
import ManageConnections from '../pages/Settings/ManageConnections';
import WorkoutPreferences from '../pages/Settings/WorkoutPreferences';
import ManageNotifications from '../pages/Settings/ManageNotifications';
import TermsOfService from '../pages/Settings/TermsOfService';
import PrivacyPolicy from '../pages/Settings/PrivacyPolicy';
import ManageAppIcon from '../pages/Settings/ManageAppIcon';

/**
 * Routes used for settings
 */
const SettingsRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <IonRouterOutlet ionPage>
      <Route exact path={path} component={Settings} />
      <Route exact path={`${path}/profile`} component={ProfileSettings} />
      <Route exact path={`${path}/subscriptions`} component={ManageSubscriptions} />
      <Route exact path={`${path}/connections`} component={ManageConnections} />
      <Route exact path={`${path}/workout`} component={WorkoutPreferences} />
      <Route exact path={`${path}/notifications`} component={ManageNotifications} />
      <Route exact path={`${path}/appIcon`} component={ManageAppIcon} />
      <Route exact path={`${path}/termsOfService`} component={TermsOfService} />
      <Route exact path={`${path}/privacyPolicy`} component={PrivacyPolicy} />
    </IonRouterOutlet>
  );
};

export default compose(
  observer,
)(SettingsRoutes);
