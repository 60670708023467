import React, { useContext } from 'react';
import LoadingPage from '../../components/LoadingPage';

import TrackedActivityContext from './TrackedActivityContext';
import TrackedActivityContextProvider from './TrackedActivityContextProvider';

const withTrackedActivityContextProvider = (Component) => (props) => (
  <TrackedActivityContextProvider>
    <Component {...props} />
  </TrackedActivityContextProvider>
);

const withTrackedActivityContextReady = (Component) => (props) => {
  const { isReady } = useContext(TrackedActivityContext);
  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withTrackedActivityContextProvider,
  withTrackedActivityContextReady,
};
