import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import HabitLogModel from '../../../../models/HabitLog';
import useComponentMounted from '../../../../hooks/useComponentMounted';
import UserContext from '../../../../context/UserContext';
import HabitLogContext from '../../../../context/HabitLogContext';
import AppCustomizationContext from '../../../../context/AppCustomizationContext';

import logEvent from '../../../../utils/logger';
import {
  HabitText,
  ItemContainer,
  TextContainer,
  StyledCheckBox,
} from './styles';

const MAX_HABITS = 3;

const HabitLog = ({
  showAllHabits,
}) => {
  const { userId: user } = useContext(UserContext);
  const { currentHabitLogDoc: habitLogDoc, currentSelectedDate: date } = useContext(HabitLogContext);
  const isComponentMountedRef = useComponentMounted();
  const { habitList, habitsDefinition } = useContext(AppCustomizationContext);
  const [habitValues, setHabitValues] = useState();

  useEffect(() => {
    if (isComponentMountedRef.current) {
      if (habitLogDoc) {
        setHabitValues(habitLogDoc);
      } else {
        setHabitValues(null);
      }
    }
  }, [
    habitLogDoc,
    isComponentMountedRef,
    habitList,
  ]);

  const onHabitClick = useCallback(async (habit) => {
    if (!habitLogDoc) {
      HabitLogModel.addDoc({
        user,
        date: moment.utc(date).startOf('day').toDate(),
        habits: {
          [habit]: true,
        },
      });
    } else {
      logEvent('UpdatingHabitLog', {
        docId: habitLogDoc.id,
        habits: {
          [habit]: habitLogDoc.habits ? !habitLogDoc.habits[habit] : true,
        },
      });
      habitLogDoc.update({
        habits: {
          ...habitLogDoc.habits,
          [habit]: habitLogDoc.habits ? !habitLogDoc.habits[habit] : true,
        },
      });
      logEvent('UpdatedHabitLog', {
        docId: habitLogDoc.id,
        habits: {
          [habit]: habitLogDoc.habits ? !habitLogDoc.habits[habit] : true,
        },
      });
    }
  }, [
    habitLogDoc,
    date,
    user,
  ]);

  const habits = useMemo(() => {
    const activeHabitList = habitList.filter((habit) => habitsDefinition
      && !!habitsDefinition[habit].active);
    return (showAllHabits
      ? activeHabitList
      : activeHabitList.slice(0, MAX_HABITS));
  }, [
    showAllHabits,
    habitList,
    habitsDefinition,
  ]);

  return habits.map((habitId) => (
    <ItemContainer onClick={() => onHabitClick(habitId)} key={habitId}>
      <TextContainer>
        <HabitText>
          {habitsDefinition[habitId].text}
        </HabitText>
      </TextContainer>
      <StyledCheckBox
        checked={habitValues && habitValues.habits && habitValues.habits[habitId]}
        slot="end"
      />
    </ItemContainer>
  ));
};

HabitLog.propTypes = {
  showAllHabits: PropTypes.bool,
};

HabitLog.defaultProps = {
  showAllHabits: true,
};

export default compose(
  observer,
)(HabitLog);
