import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as PlayIcon } from '../../../assets/icons/play-icon.svg';
import { ReactComponent as PauseIcon } from '../../../assets/icons/pause-icon.svg';

import {
  Container,
  StyledPlayPauseButton,
} from './styles';

const VISIBILITY_TIMEOUT = 1500;

const PlayPauseButton = ({
  isPlaying,
  onClick,
  autoHide,
}) => {
  const [isButtonShown, setIsButtonShown] = useState(true);

  useEffect(() => {
    let shouldUpdate = true;
    let timerId;

    if (autoHide && isPlaying && isButtonShown) {
      timerId = setTimeout(() => {
        if (shouldUpdate) {
          setIsButtonShown(false);
        }
      }, VISIBILITY_TIMEOUT);
    }

    return () => {
      shouldUpdate = false;
      clearTimeout(timerId);
    };
  }, [
    autoHide,
    isPlaying,
    isButtonShown,
  ]);

  const onContainerClick = () => {
    setIsButtonShown(true);
  };

  return (
    <Container onClick={onContainerClick}>
      <StyledPlayPauseButton
        onClick={onClick}
        isPlaying={isPlaying}
        hideButton={!isButtonShown}
      >
        {isPlaying ? <PauseIcon /> : <PlayIcon />}
      </StyledPlayPauseButton>
    </Container>
  );
};

PlayPauseButton.propTypes = {
  isPlaying: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  autoHide: PropTypes.bool,
};

PlayPauseButton.defaultProps = {
  autoHide: false,
};

export default PlayPauseButton;
