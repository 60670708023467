import { MeasurementSystem } from '../../../utils/measurement';

const texts = {
  measurement: 'Measurement',
  labels: {
    [MeasurementSystem.IMPERIAL]: 'Imperial',
    [MeasurementSystem.METRIC]: 'Metric',
  },
  errorMessage: 'There was an error loading your invoices',
  paidInvoiceDateKey: 'Paid:',
  createdInvoiceDateKey: 'Created:',
};

export default texts;
