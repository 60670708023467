import React, {
  useContext,
  useEffect,
} from 'react';
import format from 'string-template';

import ExerciseOverrideSelectorContext from '../context/ExerciseOverrideSelectorContext';
import texts from './texts.json';
import {
  Text,
} from './styles';

const EmptyExercisesMessage = () => {
  const {
    searchText,
    logExerciseOverrideEvent,
  } = useContext(ExerciseOverrideSelectorContext);

  const message = searchText
    ? format(texts.noResultsFoundByName, searchText)
    : texts.noResultsFound;

  useEffect(() => {
    logExerciseOverrideEvent('exerciseOverrideEmptyExercisesResult', {
      searchText,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Text>
      {message}
    </Text>
  );
};

export default EmptyExercisesMessage;
