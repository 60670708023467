import React, {
  useState,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import useComponentMounted from '../../../hooks/useComponentMounted';
import useSessionStore from '../../../hooks/useSessionStore';
import Exercise, { ExerciseType } from '../../models/Exercise';
import ExerciseTag from '../../models/ExerciseTag';
import { getQueryVariable } from '../../../utils/queryParams';
import { orderByName } from '../../utils/exercises';
import ExerciseContext from './ExerciseContext';

const ExerciseContextProvider = ({
  children,
}) => {
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [exerciseList, setExerciseList] = useState([]);
  const [exerciseTags, setExerciseTags] = useState([]);

  const isComponentMountedRef = useComponentMounted();

  const {
    authUser: {
      uid: loggedInCoachId,
    },
  } = useSessionStore();

  useEffect(() => {
    const initData = async () => {
      const coachIdParam = getQueryVariable('coachId');
      const coachId = coachIdParam || loggedInCoachId;

      const baseExercisesCollection = await Exercise.getBaseExercises();
      const editedExercisesCollection = await Exercise.getCoachExercises(coachId, ExerciseType.EDITED);
      const customExercisesCollection = await Exercise.getCoachExercises(coachId, ExerciseType.CUSTOM);

      const globalTags = await ExerciseTag.getGlobalTags();
      const coachTags = await ExerciseTag.getCoachTags(coachId);

      // Get the exercise docs in separate arrays
      const baseExercises = [...baseExercisesCollection.docs];
      const editedExercises = [...editedExercisesCollection.docs];
      const customExercises = [...customExercisesCollection.docs];

      // Remove the original exercises that were edited
      editedExercises.forEach(({ originalExercise }) => {
        const docIndex = baseExercises.findIndex((item) => item.id === originalExercise);
        baseExercises.splice(docIndex, 1);
      });

      // The exercises we show is a combination of all exercise types, for the given coach.
      const allDocs = [
        ...baseExercises,
        ...customExercises,
        ...editedExercises,
      ];

      // The list needs to be ordered by name
      const exerciseDocs = orderByName(allDocs);

      if (isComponentMountedRef.current) {
        setExerciseList(exerciseDocs);

        setExerciseTags([
          ...globalTags.docs,
          ...coachTags.docs,
        ]);

        setIsReady(true);
      }
    };

    if (!isReady && !isFetchingData) {
      setIsFetchingData(true);
      initData();
    }
  }, [
    isComponentMountedRef,
    isFetchingData,
    isReady,
    loggedInCoachId,
  ]);

  const context = useMemo(() => ({
    exerciseList,
    isReady,
    exerciseTags,
  }), [
    exerciseList,
    exerciseTags,
    isReady,
  ]);

  return (
    <ExerciseContext.Provider value={context}>
      {children}
    </ExerciseContext.Provider>
  );
};

ExerciseContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(ExerciseContextProvider);
