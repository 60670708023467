import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import {
  Container,
  ErrorMessage,
} from './styles';

const FormikTextarea = ({
  name,
  placeholder,
  type,
  isSmall,
  className,
  ...other
}) => (
  <Container isSmall={isSmall} className={className}>
    <Field
      name={name}
      type={type}
      placeholder={placeholder}
      component="textarea"
      {...other}
    />
    <ErrorMessage name={name} component="div" />
  </Container>
);

FormikTextarea.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  isSmall: PropTypes.bool,
  className: PropTypes.string,
};

FormikTextarea.defaultProps = {
  placeholder: '',
  type: 'text',
  isSmall: false,
  className: 'formikInput',
};

export default FormikTextarea;
