import React from 'react';
import {
  Route,
  Redirect,
  useRouteMatch,
} from 'react-router-dom';
import { compose } from 'recompose';
import { IonRouterOutlet } from '@ionic/react';

import withFirestoreLocalCache from '../firebase/withFirestoreLocalCache';
import { withUserContextProvider, withUserContextReady } from '../context/UserContext';
import { withLoggedInUserContextProvider, withLoggedInUserContextReady } from '../context/LoggedInUserContext';
import withUserRemoteLogging from '../context/UserContext/UserRemoteLogging';
import { withDeviceContextProvider, withDeviceContextReady } from '../context/DeviceContext';
import { withHealthDataSyncContextProvider } from '../context/HealthDataSyncContext';
import { withCoachContactContextProvider } from '../context/CoachContactContext';
import { withFCMContext } from '../context/FCMContext';
import {
  withAppCustomizationContextProvider,
  withAppCustomizationContextReady,
} from '../context/AppCustomizationContext';
import { withUserExerciseOverridesContextProvider } from '../context/UserExerciseOverridesContext';
import { withMealPlanContextProvider } from '../context/MealPlanContext';
import { withUserNutritionInfoContextProvider } from '../context/UserNutritionInfoContext';
import { withSubscriptionSlideDrawerContextProvider } from '../context/SubscriptionSlideDrawerContext';
import { withLogContextProvider } from '../context/LogContext/withLogContext';
import {
  withMeasurementLogContextProvider,
  withMeasurementLogContextReady,
} from '../context/MeasurementLogContext/withMeasurementLogContext';
import {
  withCheckInContextProvider,
  withCheckInContextReady,
} from '../context/CheckInContext/withCheckInContext';
import {
  withHabitLogContextProvider,
  withHabitLogContextReady,
} from '../context/HabitLogContext/withHabitLogContext';
import { withCoachInfoContextProvider } from '../context/CoachInfoContext';
import {
  withPostPaymentFormContextProvider,
  withPostPaymentFormContextReady,
} from '../context/PostPaymentFormContext/withPostPaymentFormContextProvider';
import { withNotificationModalContextProvider } from '../context/NotificationModalContext';
import {
  withUserContractContextProvider,
  withUserContractContextReady,
} from '../context/UserContractContext';
import withChat from '../chat/components/withChat';
import CronometerCallback from '../components/Cronometer/components/CronometerCallback';
import { withReonboardingModal } from '../components/ReonboardingModal';
import { SideNavigation } from '../components/Navigation';
import withCurrentUserAccess from '../components/withCurrentUserAccess';
import withNotifications from '../components/withNotifications';
import { withSubscriptionSlideDrawer } from '../components/SubscriptionSlideDrawer';
import HostedPageContainer from '../pages/HostedPage';
import HomeContainer from '../pages/Home';
import WorkoutSelector from '../pages/WorkoutSelector';
import WorkoutDetailsSelector from '../pages/WorkoutDetailsSelector';
import ScheduleCoachCall from '../pages/ScheduleCoachCall';
import TrackedActivity from '../pages/TrackedActivity';
import CustomWorkout from '../pages/CustomWorkout';
import ChangeLog from '../pages/ChangeLog';
import Measurements from '../pages/Measurements';
import CheckIn from '../pages/CheckIn';

import WorkoutRoutes from './WorkoutRoutes';
import DebugRoutes from './DebugRoutes';
import MealPlanRoutes from './MealPlanRoutes';
import SettingsRoutes from './SettingsRoutes';

const UserRoutes = () => {
  const { path, params: { userId } } = useRouteMatch();

  return (
    <>
      <SideNavigation />
      <IonRouterOutlet id="app-side-menu-content" ionPage>
        <Route exact path={`${path}/home`} component={HomeContainer} />
        <Route path={`${path}/trackedActivity/:trackedActivityId`} component={TrackedActivity} />
        <Route path={`${path}/workoutAssignment/:workoutAssignmentId`} component={WorkoutRoutes} />
        <Route path={`${path}/customWorkout`} component={CustomWorkout} />
        <Route exact path={`${path}/customWorkout/:customWorkoutId`} component={CustomWorkout} />
        <Route exact path={`${path}/workoutSelector/:workoutId`} component={WorkoutDetailsSelector} />
        <Route exact path={`${path}/workoutSelector`} component={WorkoutSelector} />
        <Route exact path={`${path}/measurements`} component={Measurements} />
        <Route path={`${path}/settings`} component={SettingsRoutes} />
        <Route exact path={`${path}/scheduleCall`} component={ScheduleCoachCall} />
        <Route exact path={`${path}/changelog`} component={ChangeLog} />
        <Route path={`${path}/d`} component={DebugRoutes} />
        <Route path={`${path}/mealPlan`} component={MealPlanRoutes} />
        <Route path={`${path}/check-in/:pageId`} component={CheckIn} />
        <Route path={`${path}/cronometerCallback`} component={CronometerCallback} />

        {/* Hosted pages are defined as well as part of user routes so transition between app pages
        and hosted pages are animated. */}
        <Route path={`${path}/h/:hostedPage`} component={HostedPageContainer} />

        <Route exact path={path}>
          <Redirect to={`/u/${userId}/home`} />
        </Route>
      </IonRouterOutlet>
    </>
  );
};

export default compose(
  withCurrentUserAccess(),
  // The order in this list is important!
  withUserContextProvider,
  withUserContextReady,
  withLoggedInUserContextProvider,
  withLoggedInUserContextReady,
  withAppCustomizationContextProvider,
  withAppCustomizationContextReady,
  // Initializes the local cache handling and snapshots listeners required for cache cleanup
  withFirestoreLocalCache,
  withUserRemoteLogging,
  withHealthDataSyncContextProvider,
  withCoachContactContextProvider,
  withChat,
  // FCM needs to be after `withChat` because it needs to open the chat dialog when a new message arrives.
  withFCMContext,
  withDeviceContextProvider,
  withDeviceContextReady,
  withNotifications,
  withUserExerciseOverridesContextProvider,
  withMealPlanContextProvider,
  withUserNutritionInfoContextProvider,
  withCoachInfoContextProvider,
  withUserContractContextProvider,
  withUserContractContextReady,
  withReonboardingModal,
  withSubscriptionSlideDrawerContextProvider,
  withSubscriptionSlideDrawer,
  withLogContextProvider,
  withMeasurementLogContextProvider,
  withMeasurementLogContextReady,
  withHabitLogContextProvider,
  withHabitLogContextReady,
  withCheckInContextProvider,
  withCheckInContextReady,
  withPostPaymentFormContextProvider,
  withPostPaymentFormContextReady,
  withNotificationModalContextProvider,
)(UserRoutes);
