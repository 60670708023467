import Stats from './Stats';

class RuntimeStats extends Stats {
  constructor() {
    super();
    this.lastExecTime = null;
  }

  calculateTime() {
    const currentExecTime = (window.performance || Date).now();

    if (this.lastExecTime) {
      const timerExecutionDiff = currentExecTime - this.lastExecTime;

      this.addValue(timerExecutionDiff);
    }

    this.lastExecTime = currentExecTime;
  }

  stats() {
    const {
      percentileHighestValue: percentileWorstTime,
      percentileLowestValue: percentileBestTime,
      ...otherValues
    } = super.stats();

    return {
      percentileBestTime,
      percentileWorstTime,
      ...otherValues,
    };
  }

  /**
   * Resets the current lastExecTime value to the default initial value.
   * Call this when there will be a timelapse between the last execution time and the new execution time,
   * and that value should not be included as part of the calculation to avoid polluting the data.
   */
  resetCurrentValue() {
    this.lastExecTime = null;
  }
}

export default RuntimeStats;
