import {
  useEffect,
  useState,
  useMemo,
} from 'react';

import logEvent from '../utils/logger';

/**
 * Custom hook that exposes APIs to measure the starting and finishing loading times.
 * This is done by not assuming the component is mounted or has a readiness state, in order to allow
 * the consumers to determine what is the proper condition and interactions between components to determine
 * starting and finishing points in time.
 * After the star time and end time are setted up, the "pageLoadingTimes" analytics event is sent.
 *
 * @param {string} name Page name.
 */
const useComponentLoadingTime = (name) => {
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);

  useEffect(() => {
    if (startTime > 0 && endTime > 0) {
      logEvent('componentLoadingTimes', {
        name,
        startTime,
        endTime,
        totalTime: endTime - startTime,
      });
    }
  }, [
    name,
    startTime,
    endTime,
  ]);

  return useMemo(() => ({
    startLoading: () => {
      setStartTime((window.performance || Date).now());
    },
    finishLoading: () => {
      setEndTime((window.performance || Date).now());
    },
  }), [
    setStartTime,
    setEndTime,
  ]);
};

export default useComponentLoadingTime;
