import styled, { css } from 'styled-components';

import { SemiTransparentButton } from '../../../../../../components/Button';

import {
  safeAreaFromBottom,
  safeAreaFromRight,
} from '../../../../../../styles/mixins';

const StyledActionsPanel = styled.div`
  width: calc(100% - ${safeAreaFromRight()});
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: flex-end;

  /*
    The padding values take the boundaries of the next button into account. The virtual next button container's width is
    equal to 15% of the available space. The button itself is 54px wide and since that button has symmetric margins,
    then we need to divide this value by 2. By doing this calculation, we can align the Action Panel with the position
    of the virtual next button.
  */
  padding-right: calc((15% - 54px) / 2);
  padding-left: calc((15% - 54px) / 2);
  z-index: 2;

  ${({ theme }) => theme.isPortrait && css`
    /*
      In portrait mode, we generally don't have safe areas at the sides of the screen. Because of that, we add 15px to
      each side so that the buttons are not right on the edge of the screen. The same strategy is applied to the
      virtual next button.
    */
    padding-right: 15px;
    padding-left: 15px;
    align-items: flex-end;
    flex-direction: column-reverse;
    margin-bottom: ${safeAreaFromBottom()};
  `}
`;

const RoundButton = styled(SemiTransparentButton)`
  width: 54px;
  height: 54px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  transition: all 0.3s;
`;

const PlayPauseButton = styled(RoundButton)`
  ${(props) => !props.isPlaying && css`
    background-color: ${({ theme }) => theme.colors.base.secondary};

    svg {
      fill: ${({ theme }) => theme.colors.fonts.primary};

      path {
        stroke: ${({ theme }) => theme.colors.fonts.primary};
      }
    }
  `}
`;

const PlayPauseButtonContainer = styled.div`
  width: 54px;
  height: 54px;
  margin-top: 10px;

  ${({ theme }) => theme.isPortrait && css`
    margin-top: 0;
  `}
`;

const SlidingPanelsContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 0 15px 0 0;
  position: relative;

  ${({ theme }) => theme.isPortrait && css`
    margin: 15px 0 0;
  `}
`;

const NotesWrapper = styled.div`
  margin: 10px 0 0 10px;
  flex: 1;
  opacity: 1;
  transition: all 0.2s;

  ${({ $isHidden }) => $isHidden && css`
    opacity: 0;
  `};

  ${({ theme }) => theme.isPortrait && css`
    margin: 0;
    justify-self: center;
  `}
`;

const AddNotes = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  width: 55px;
  background: rgba(0, 0, 0, 0.5);
  color: ${({ theme }) => theme.colors.system.alpha};
  border-radius: 10px;
`;

export {
  StyledActionsPanel,
  RoundButton,
  PlayPauseButtonContainer,
  PlayPauseButton,
  NotesWrapper,
  SlidingPanelsContainer,
  AddNotes,
};
