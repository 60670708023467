import React, {
  useMemo,
  useCallback,
  useRef,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useRouteMatch } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import logEvent from '../../utils/logger';
import useHealthData from '../../hooks/useHealthData';
import { requestAppPermissions } from '../../services/health';

import {
  queryActivities,
  queryActivitiesSummary,
  queryDailyStepCount,
} from './healthQueries';
import HealthDataSyncContext from './HealthDataSyncContext';
import useHealthDataStorage from './useActivitiesDataStorage';

const DEFAULT_TOTAL_DAYS = 15;
const TOTAL_DAYS_QUERY_THRESHOLD = 30;

const HealthDataSyncContextProvider = ({
  children,
}) => {
  const { params: { userId } } = useRouteMatch();

  const { isHealthEnabled } = useHealthData();

  const queryDates = useRef({
    startDate: moment().startOf('day'),
    endDate: moment(),
  });

  const [arePermissionsRequested, setPermissionsRequested] = useState(false);
  const queriesQueue = useRef([]);
  const isSyncInProgress = useRef(false);

  const {
    saveActivitiesSummary,
    saveActivitiesData,
  } = useHealthDataStorage();

  /**
   * Read more activity summary from health and synchronize it with the firestore database.
   *
   * @param {Object} fromDate Date instance.
   * @param {Object} toDate Date instance.
   */
  const syncActivitiesSummary = useCallback(async (fromDate, toDate) => {
    logEvent('syncActivitiesSummaryCalled', {
      fromDate,
      toDate,
    });

    let summaryResults;

    const startTime = (window.performance || Date).now();

    try {
      summaryResults = await queryActivitiesSummary(fromDate, toDate);
      logEvent('activitiesSummaryQueryDone', {
        fromDate,
        toDate,
        totalTime: (window.performance || Date).now() - startTime,
      });
    } catch (error) {
      logEvent('activitiesSummaryQueryFailed', {
        fromDate,
        toDate,
        totalTime: (window.performance || Date).now() - startTime,
      });
      Sentry.captureException(error, {
        extra: {
          userId,
          description: `Error reading activity summary data from Health on ${fromDate}`,
        },
      });
    }

    if (summaryResults && summaryResults.length > 0) {
      const savingStartTime = (window.performance || Date).now();

      try {
        await saveActivitiesSummary(summaryResults);
        logEvent('saveActivitiesSummaryDone', {
          count: summaryResults.length,
          totalTime: (window.performance || Date).now() - savingStartTime,
        });
      } catch (error) {
        logEvent('saveActivitiesSummaryFailed', {
          totalTime: (window.performance || Date).now() - savingStartTime,
        });
        throw error;
      }
    } else {
      logEvent('activitiesSummaryEmptyResults');
    }
  }, [
    userId,
    saveActivitiesSummary,
  ]);

  /**
   * Syncs activities data from health store and stores it in firebase database
   * @param {Date} fromDate The from date
   * @param {Date} toDate The to date
   */
  const syncActivitiesData = useCallback(async (fromDate, toDate) => {
    logEvent('syncActivitiesDataCalled', {
      fromDate,
      toDate,
    });

    if (!isHealthEnabled) {
      return;
    }

    let activitiesDataResults;

    const startTime = (window.performance || Date).now();

    try {
      activitiesDataResults = await queryActivities(fromDate, toDate);
      logEvent('activitiesDataQueryDone', {
        fromDate,
        toDate,
        totalTime: (window.performance || Date).now() - startTime,
      });
    } catch (error) {
      logEvent('activitiesDataQueryFailed', {
        fromDate,
        toDate,
        totalTime: (window.performance || Date).now() - startTime,
      });
      Sentry.captureException(error, {
        extra: {
          userId,
          description: `Error reading activities data from Health on ${fromDate}`,
        },
      });
    }

    if (activitiesDataResults && activitiesDataResults.length > 0) {
      const savingStartTime = (window.performance || Date).now();

      try {
        await saveActivitiesData(activitiesDataResults);
        logEvent('saveActivitiesDataDone', {
          count: activitiesDataResults.length,
          totalTime: (window.performance || Date).now() - savingStartTime,
        });
      } catch (error) {
        logEvent('saveActivitiesDataFailed', {
          totalTime: (window.performance || Date).now() - savingStartTime,
        });
        throw error;
      }
    } else {
      logEvent('activitiesDataEmptyResults');
    }
  }, [
    userId,
    isHealthEnabled,
    saveActivitiesData,
  ]);

  /**
   * Execute the queries from the queries queue.
   */
  const execQueryIfAny = useCallback(async () => {
    if (queriesQueue.current.length > 0 && !isSyncInProgress.current) {
      isSyncInProgress.current = true;
      const [syncFn] = queriesQueue.current.splice(0, 1);

      await syncFn();

      isSyncInProgress.current = false;

      setTimeout(async () => {
        await execQueryIfAny();
      }, 0);
    }
  }, []);

  /**
   * Prepare the dates ranges and enqueues a new sync activity summary & sync activities data requests.
   * When the dates ranges to query exceeds the threshold, partial queries with smaller days ranges are created and
   * added to the queue. This is done in order to avoid quering a lot of data to health and also to avoid creating
   * many documents in firestore at once.
   *
   * The sync queries are done always from the most present date to the past day. Meaning that, the "startDate" is a day
   * in the past, and the "endDate" is the most present day in the date ranges requested.
   *
   * @param {Object} options Options to configure the dates ranges.
   * @param {Object=} options.fromDate New moment date as starting point for the queries. If this value is passed,
   * then it will be used, and the remaining options will be ignored.
   * @param {Object=} options.totalDays Total days to query data. If no value is passed for options.fromDate
   * this configuration will be used. By default if no value is passed, DEFAULT_TOTAL_DAYS will be use as total days.
   */
  const syncMoreHealthData = useCallback(async (options = {}) => {
    logEvent('syncMoreHealthDataCalled', {
      isHealthEnabled,
    });

    if (!isHealthEnabled) {
      return;
    }

    const { startDate } = queryDates.current;
    let newEndDate = moment(startDate).endOf('day');
    let newStartDate;

    if (options.fromDate) {
      const fromDate = options.fromDate.startOf('day');
      const diffDays = fromDate.diff(startDate, 'day', true);

      if (diffDays > 0) {
        // It's not a past date, so reset the endDate to the most recent time: now
        newEndDate = moment().endOf('day');
      }

      newStartDate = fromDate;
    } else {
      const totalDays = options.totalDays || DEFAULT_TOTAL_DAYS;
      newStartDate = moment(startDate).startOf('day').subtract(totalDays, 'days');
    }

    queryDates.current = {
      startDate: newStartDate,
      endDate: newEndDate,
    };

    const daysRange = newEndDate.diff(newStartDate, 'day', true);

    if (daysRange > TOTAL_DAYS_QUERY_THRESHOLD) {
      logEvent('trackedActivitySummaryQueryExceedMaxDays', {
        daysRange,
        totalDaysQueryThreshold: TOTAL_DAYS_QUERY_THRESHOLD,
      });

      const totalPartialQueries = Math.ceil(daysRange / TOTAL_DAYS_QUERY_THRESHOLD);

      let startPartialDate = null;
      let endPartialDate = null;

      for (let i = 0; i < totalPartialQueries; i++) {
        endPartialDate = endPartialDate
          ? moment(startPartialDate).subtract(1, 'days').endOf('day')
          : newEndDate;

        const totalDaysToStartDate = endPartialDate.diff(newStartDate, 'day');
        const daysToSubtract = totalDaysToStartDate > TOTAL_DAYS_QUERY_THRESHOLD
          ? (TOTAL_DAYS_QUERY_THRESHOLD - 1)
          : totalDaysToStartDate;

        startPartialDate = moment(endPartialDate).startOf('day').subtract(daysToSubtract, 'days');

        const partialFromDate = startPartialDate.toDate();
        const partialToDate = endPartialDate.toDate();

        queriesQueue.current.push(() => (
          syncActivitiesSummary(partialFromDate, partialToDate)
        ), () => (
          syncActivitiesData(partialFromDate, partialToDate)
        ));
      }
    } else {
      queriesQueue.current.push(() => (
        syncActivitiesSummary(newStartDate.toDate(), newEndDate.toDate())
      ), () => (
        syncActivitiesData(newStartDate.toDate(), newEndDate.toDate())
      ));
    }

    await execQueryIfAny();
  }, [
    isHealthEnabled,
    syncActivitiesSummary,
    syncActivitiesData,
    execQueryIfAny,
  ]);

  /**
   * Request all app permissions required, both read and write, and update the internal state.
   */
  const requestAllPermissions = useCallback(async () => {
    try {
      await requestAppPermissions();
    } finally {
      setPermissionsRequested(true);
    }
  }, []);

  const retrieveDailyStepCount = useCallback(async (date) => {
    const stepCount = await queryDailyStepCount(date);
    return stepCount;
  }, []);

  /**
   * Sync an initial load of activities summary as soon as health data is enabled and permissions are requested.
   * This useEffect should only be triggered when isHealthEnabled and arePermissionsRequested values changes.
   */
  useEffect(() => {
    if (isHealthEnabled && arePermissionsRequested) {
      logEvent('syncInitialHealthData');
      syncMoreHealthData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isHealthEnabled,
    arePermissionsRequested,
  ]);

  const context = useMemo(() => ({
    isHealthEnabled,
    arePermissionsRequested,
    syncMoreHealthData,
    syncActivitiesData,
    requestAllPermissions,
    retrieveDailyStepCount,
  }), [
    isHealthEnabled,
    arePermissionsRequested,
    syncMoreHealthData,
    syncActivitiesData,
    requestAllPermissions,
    retrieveDailyStepCount,
  ]);

  return (
    <HealthDataSyncContext.Provider value={context}>
      {children}
    </HealthDataSyncContext.Provider>
  );
};

HealthDataSyncContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(HealthDataSyncContextProvider);
