import styled from 'styled-components';
import { ReactComponent as AttentionIcon } from '../../assets/attention.svg';

const Note = styled.div`
  text-align: center;
  padding: 6px;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: bold;
`;

const StyledAttentionIcon = styled(AttentionIcon)`
  height: 80px;
  width: auto;
`;

export {
  Note,
  StyledAttentionIcon,
};
