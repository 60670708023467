import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { IonLoading } from '@ionic/react';

import UserContext from '../../context/UserContext';
import useCurrentLoggedInUser from '../../hooks/useCurrentLoggedInUser';
import useSessionStore from '../../hooks/useSessionStore';
import useNavContext from '../../hooks/useNavContext';
import useStorage, { StorageProcessState } from '../../hooks/useStorage';
import { convertToLocalDate } from '../../utils/date';
import logEvent from '../../utils/logger';
import CustomWorkoutProcessState, {
  persistWorkoutDetail,
  createIdForCustomWorkout,
  getStoragePath,
} from '../../services/customWorkout';
import { withFirebase } from '../../context/FirebaseContext';
import {
  HeaderContext,
  withCustomizedLayout,
} from '../../components/Layout';
import LoadingPage from '../../components/LoadingPage';
import CustomWorkoutModel from '../../models/CustomWorkout';
import CustomWorkout from './CustomWorkout';
import EditionResultAlert from './EditionResultAlert';
import texts from './text.json';

const CustomWorkoutContainer = ({
  firebase: {
    storage,
    firestore,
  },
}) => {
  const { userId } = useContext(UserContext);
  const { setTitle } = useContext(HeaderContext);
  const { goBack } = useNavContext();
  const { params: { customWorkoutId } } = useRouteMatch();
  const location = useLocation();
  const { authUser: { uid: loggedInUserId } } = useSessionStore();
  const { isCurrentLoggedInUserInPath } = useCurrentLoggedInUser();
  const {
    uploadAttachment,
    deleteAttachment,
    getBlobUrl,
  } = useStorage();

  const [processState, setProcessState] = useState(CustomWorkoutProcessState.INITIAL);
  const [uploadedFileResult, setUploadedFileResult] = useState({});
  const [selectedFileDetail, setSelectedFileDetail] = useState({});
  const [workoutData, setWorkoutData] = useState(null);
  const [customWorkoutDetail, setCustomWorkoutDetail] = useState({});
  const [isReady, setIsReady] = useState(false);
  const shouldUpdateRef = useRef(true);

  useEffect(() => {
    setTitle(texts.title);
  }, [
    setTitle,
  ]);

  useEffect(() => {
    shouldUpdateRef.current = true;
    return () => {
      shouldUpdateRef.current = false;
    };
  }, []);

  useEffect(() => {
    const initializeCustomWorkoutContainer = async () => {
      if (customWorkoutId) {
        // The page will show the details of an existent custom workout
        const customWorkoutRef = new CustomWorkoutModel(customWorkoutId);
        await customWorkoutRef.init();
        const customWorkout = customWorkoutRef.data;
        const startDate = convertToLocalDate(customWorkout.startDate.toMillis());

        const existingCustomWorkout = {
          ...customWorkout,
          id: customWorkoutRef.id,
          startDate,
        };

        if (customWorkout.attachmentRef) {
          const dataUrl = await getBlobUrl(customWorkout.attachmentRef);
          existingCustomWorkout.dataUrl = dataUrl;
          if (shouldUpdateRef.current) {
            setSelectedFileDetail({ dataUrl });
          }
        }
        if (shouldUpdateRef.current) {
          setWorkoutData(existingCustomWorkout);
        }
      } else {
        // Check if the add custom workout page should be pre-filled with some data
        const urlParams = new URLSearchParams(location.search);
        const workoutName = urlParams.get('title');

        if (workoutName) {
          // This is a new workout with pre-filled data
          setWorkoutData({
            title: workoutName,
          });
        }
      }

      if (shouldUpdateRef.current) {
        setIsReady(true);
      }
    };

    if (!isReady) {
      initializeCustomWorkoutContainer();
    }
  }, [
    getBlobUrl,
    isReady,
    customWorkoutId,
    storage,
    location.search,
  ]);

  const updateFileUploadResult = useCallback(({ state: nextState, result = {} }) => {
    if (nextState.processCompleted && !nextState.isSuccess) {
      logEvent('customWorkoutProcessStateChanged', {
        currentState: processState,
        nextState,
      });
    }
    setUploadedFileResult(result);
    setProcessState(nextState);
  }, [
    processState,
    setProcessState,
    setUploadedFileResult,
  ]);

  useEffect(() => {
    const updateStorage = async () => {
      const {
        selectedFileDetail: {
          dataUrl,
          format: fileFormat,
          isFileChanged = false,
        },
      } = customWorkoutDetail;

      const {
        id: existingId,
        attachmentRef: existingFileRef,
      } = workoutData || {};

      const workoutId = existingId || createIdForCustomWorkout(loggedInUserId);
      if (!isFileChanged && shouldUpdateRef.current) {
        updateFileUploadResult({
          state: CustomWorkoutProcessState.SUCCESSFULLY_COMPLETED_ATTACHMENT_PROCESSING,
          result: {
            isFileChanged,
            workoutId,
          },
        });
        return;
      }

      const storagePath = getStoragePath(userId, workoutId);

      let result = {};
      if (dataUrl) {
        if (existingFileRef) {
          // Delete previous attachment
          result = await deleteAttachment(existingFileRef);
        }
        if (result.state !== StorageProcessState.FAILED) {
          result = await uploadAttachment(dataUrl, fileFormat, storagePath);
        }
      } else {
        result = await deleteAttachment(existingFileRef);
      }
      if (shouldUpdateRef.current) {
        if (result.state === StorageProcessState.FAILED) {
          updateFileUploadResult({
            state: CustomWorkoutProcessState.PROCESSING_ATTACHMENT_FAILED,
          });
        } else {
          const fileRef = dataUrl ? result.fileRef : null;
          updateFileUploadResult({
            state: CustomWorkoutProcessState.SUCCESSFULLY_COMPLETED_ATTACHMENT_PROCESSING,
            result: {
              fileRef,
              workoutId,
              isFileChanged,
            },
          });
        }
      }
    };

    if (processState === CustomWorkoutProcessState.INITIAL && customWorkoutDetail.isReady) {
      setProcessState(CustomWorkoutProcessState.PROCESSING_ATTACHMENT);
      updateStorage();
    }
  }, [
    storage,
    customWorkoutDetail,
    updateFileUploadResult,
    processState,
    userId,
    loggedInUserId,
    workoutData,
    uploadAttachment,
    deleteAttachment,
  ]);

  useEffect(() => {
    const persistCustomWorkout = async () => {
      const {
        exerciseName: title,
        date,
        comment,
      } = customWorkoutDetail;

      const workoutDetail = {
        title,
        comment,
        date,
        user: userId,
        fileUploadResult: uploadedFileResult,
      };

      const persistenceState = await persistWorkoutDetail(workoutDetail, firestore, storage);
      if (shouldUpdateRef.current) {
        setProcessState(persistenceState);
      }
    };

    if (processState === CustomWorkoutProcessState.SUCCESSFULLY_COMPLETED_ATTACHMENT_PROCESSING) {
      setProcessState(CustomWorkoutProcessState.PERSISTING_IN_FIRESTORE);
      persistCustomWorkout();
    }
  }, [
    customWorkoutDetail,
    uploadedFileResult,
    processState,
    userId,
    firestore,
    storage,
  ]);

  const resetState = useCallback(() => {
    logEvent('customWorkoutProcessStateChanged', {
      currentState: processState,
      nextState: CustomWorkoutProcessState.INITIAL,
    });
    setSelectedFileDetail({});
    setCustomWorkoutDetail({});
    setUploadedFileResult({});
    setWorkoutData(null);
    setProcessState(CustomWorkoutProcessState.INITIAL);

    goBack();
  }, [
    processState,
    setProcessState,
    goBack,
  ]);

  const onSubmit = useCallback((data) => {
    setCustomWorkoutDetail({ ...data, isReady: true });
  }, []);

  if (!isReady) {
    return <LoadingPage />;
  }

  return (
    <>
      <CustomWorkout
        onSubmit={onSubmit}
        processState={processState}
        resetState={resetState}
        selectedFileDetail={selectedFileDetail}
        shouldAllowActions={isCurrentLoggedInUserInPath}
        workoutData={workoutData}
      />
      <IonLoading
        isOpen={processState !== CustomWorkoutProcessState.INITIAL && !processState.processCompleted}
        message={texts.notification.editionInProgress}
        spinner="crescent"
      />
      <EditionResultAlert
        isOpen={processState !== CustomWorkoutProcessState.INITIAL && processState.processCompleted}
        resetState={resetState}
        state={processState}
      />
    </>
  );
};

CustomWorkoutContainer.propTypes = {
  firebase: PropTypes.shape({
    storage: PropTypes.object.isRequired,
    firestore: PropTypes.object.isRequired,
  }).isRequired,
};

export default compose(
  withCustomizedLayout({ enablePadding: false }),
  withFirebase,
  observer,
)(CustomWorkoutContainer);
