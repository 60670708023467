const MeasurementSystem = {
  IMPERIAL: 'IMPERIAL',
  METRIC: 'METRIC',
};

const WeightUnit = {
  [MeasurementSystem.IMPERIAL]: 'LBS',
  [MeasurementSystem.METRIC]: 'KG',
};

const ConvertionRates = {
  KG: {
    LBS: 2.2046226218,
  },
  LBS: {
    KG: 0.45359237,
  },
};

const DEFAULT_MEASUREMENT_SYSTEM = MeasurementSystem.IMPERIAL;

const convertUnits = (value, from, to, roundDigits = 1) => {
  if (from === to) {
    return value;
  }

  const rate = ConvertionRates[from][to];
  const roundedValue = value * rate;
  const fixedValue = roundedValue.toFixed(roundDigits);
  const computedValue = fixedValue % 1 === 0 ? Number.parseInt(fixedValue, 10) : fixedValue;
  return computedValue;
};

export {
  MeasurementSystem,
  convertUnits,
  DEFAULT_MEASUREMENT_SYSTEM,
  WeightUnit,
};
