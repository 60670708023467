import React, {
  useEffect,
  useContext,
  useMemo,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { debounce } from 'throttle-debounce';

import UserContext from '../../../context/UserContext';
import FCMContext from '../../../context/FCMContext';
import { withLayout, HeaderContext } from '../../../components/Layout';

import {
  MenuItemContainer,
  StyledToggle,
} from '../components/styles';
import {
  Container,
  SectionContainer,
} from '../styles';
import { SETTING_CHANGE_DEBOUNCE_TIME } from '../utils';

import texts from './texts.json';

const ManageNotifications = () => {
  const { setTitle } = useContext(HeaderContext);
  const { userConfigDoc } = useContext(UserContext);
  const { canUseNotifications } = useContext(FCMContext);

  useEffect(() => {
    setTitle(texts.manageNotifications);
  }, [
    setTitle,
  ]);

  const onNotificationsChange = useMemo(() => debounce(SETTING_CHANGE_DEBOUNCE_TIME, (newNotificationsValue) => {
    userConfigDoc.updateNotificationsPreferenceConfig(newNotificationsValue);
  }), [userConfigDoc]);

  if (!canUseNotifications) {
    return (
      <Container>
        <MenuItemContainer>{texts.noNotificationSettings}</MenuItemContainer>
      </Container>
    );
  }

  return (
    <Container>
      <SectionContainer>
        <MenuItemContainer>
          <StyledToggle
            name={texts.settings.notifications}
            onChange={onNotificationsChange}
            isChecked={userConfigDoc.areNotificationsEnabled}
          />
        </MenuItemContainer>
      </SectionContainer>
    </Container>
  );
};

export default compose(
  withLayout,
  observer,
)(ManageNotifications);
