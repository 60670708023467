import styled from 'styled-components';

import { headline2, sansSerif } from '../../../styles/text';
import { ReactComponent as FailedPaymentSvg } from '../../../assets/icons/icon_failed_payment.svg';
import Button from '../../../components/Button';

const failedPaymentMessageStyles = `
  ${sansSerif.headlineFontStyles}
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 137.5%;
  margin-top: 0;
`;

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  overflow: auto;
  background-color: ${({ theme }) => theme.colors.system.alpha};
  padding: 32px;
`;

const FailedPaymentIcon = styled(FailedPaymentSvg)`
  margin-bottom: 16px;
`;

const FailedPaymentTitle = styled.div`
  ${headline2}
  font-weight: 500;
  line-height: 138%;
  letter-spacing: -0.5px;
  margin-bottom: 16px;
`;

const FailedPaymentMessageTitle = styled.div`
  ${failedPaymentMessageStyles};
  font-weight: 500;
`;

const FailedPaymentMessage = styled.p`
  ${failedPaymentMessageStyles}
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
`;

export {
  StyledContainer,
  FailedPaymentIcon,
  FailedPaymentTitle,
  FailedPaymentMessage,
  FailedPaymentMessageTitle,
  StyledButton,
};
