import {
  decorate,
  action,
  observable,
} from 'mobx';

import videoSizeConfig from '../config';

/**
 * Base class for implementing new camera recorders.
 */
class BaseRecorder {
  constructor(drawParts) {
    this.drawParts = drawParts;
    this.selfieConfig = videoSizeConfig.selfie;
    this.previewSelfieRef = null;
    this.selfieElement = document.createElement('canvas');
    this.selfieElement.width = this.selfieConfig.width;
  }

  /**
   * Checks if the previewSelfieRef has a valid value, meaning it has been set.
   *
   * @returns {boolean} True when it is valid, otherwise false.
   */
  get hasValidPreview() {
    return !!(this.previewSelfieRef && this.previewSelfieRef.current);
  }

  /**
   * Set the previewSelfieRef reference only when it has not been already set.
   *
   * @param {Object} ref A reference to an HTML element.
   */
  setPreviewSelfieRef = (ref) => {
    if (!this.previewSelfieRef) {
      this.previewSelfieRef = ref;
    }
  }

  /**
   * Indicates that the recorder instance is ready and has to be initialized.
   * This must be called only once.
   */
  ready = () => {
    throw new Error('"BaseRecorder.ready" is not implemented');
  }

  /**
   * Checks if the recorder instance is ready and prepared for capturing images.
   *
   * @returns {boolean} True when it is ready to capture images, otherwise false.
   */
  isReadyToCapture = () => {
    throw new Error('"BaseRecorder.isReadyToCapture" is not implemented');
  }

  /**
   * Capture the image when requested.
   *
   * @returns {Promise}
   */
  captureImage = async (cameraContext) => { // eslint-disable-line no-unused-vars
    throw new Error('"BaseRecorder.captureImage" not implemented');
  }

  /**
   * Draw the capture image in the selfie canvas.
   *
   * Crop the image rendered in the previewElement according to the "drawParts"
   * configuration and take the last section. This is done in order to handle a model
   * limitation, so the only visible and real part from the image is the determined area.
   */
  drawSelfie = (previewElement, aspectRatio) => {
    this.selfieElement.height = this.selfieElement.width / aspectRatio;
    const sourceWidth = previewElement.width / this.drawParts;
    const destinationWidth = this.selfieElement.width / this.drawParts;

    // NOTE: sx and dx is set to 0 since the image is flipped using css
    this.selfieElement
      .getContext('2d')
      .drawImage(previewElement,
        0, 0, sourceWidth, previewElement.height,
        0, 0, destinationWidth, this.selfieElement.height);
  }

  /**
   * Prepares everything required in order to process the captured image later.
   *
   * @param {Object} cameraData A Blob image file.
   * @param {number} aspectRatio The aspect ratio of cameraData.
   */
  prepareForImageProcessing = async (cameraData, aspectRatio) => { // eslint-disable-line no-unused-vars
    this.drawSelfie(this.previewSelfieRef.current, aspectRatio);
  }

  /**
   * Clean up everything that might required to be released after capturing images to avoid
   * memory leaks.
   */
  cleanCapturedImage = () => {}

  /**
   * Clear internal state before releasing resources or unmounting the caller component.
   */
  clear = () => {}
}

decorate(BaseRecorder, {
  previewSelfieRef: observable,
  selfieElement: observable,
  setPreviewSelfieRef: action,
});

export default BaseRecorder;
