import { useCallback, useContext } from 'react';

import { IonRouteAction } from '../../../utils/router';
import WorkoutContext from '../../../context/WorkoutContext';
import useNavContext from '../../../hooks/useNavContext';
import useWorkoutNavigation from '../../../hooks/useWorkoutNavigation';

/**
 * Custom hook that handles gameplay's back navigation. All pages/components that belongs to the gameplay routes
 * must use this custom hook in order to navigate back.
 * In order to navigate back, it checks how the navigation to the gameplay (/play) was done:
 * - If the user reached the gameplay by navigating from a workout assignment pathname, then it's safe to use `goBack`.
 * - If the user reached the gameplay by any other route, like workout selector or a past workout that was recently
 * cloned, then it uses `navigate` API with a special behaviour: a back transition and replace action, so the current
 * page is removed from Ionic router stack, the DOM and the new page is inserted.
 */
const useGameplayGoBack = () => {
  const {
    navigate,
    goBack,
    routeInfo,
  } = useNavContext();
  const { getWorkoutPathname } = useWorkoutNavigation();
  const { workoutAssignmentDoc } = useContext(WorkoutContext);

  const navigateBack = useCallback(() => {
    const { pushedByRoute } = routeInfo;

    if (workoutAssignmentDoc) {
      const currentWorkoutPath = getWorkoutPathname(workoutAssignmentDoc.id);

      const isDetailsBackInHistory = !!pushedByRoute && pushedByRoute.startsWith(currentWorkoutPath);

      if (isDetailsBackInHistory) {
        goBack();
      } else {
        navigate(currentWorkoutPath, 'back', IonRouteAction.REPLACE);
      }
    } else {
      goBack();
    }
  }, [
    navigate,
    goBack,
    getWorkoutPathname,
    routeInfo,
    workoutAssignmentDoc,
  ]);

  return navigateBack;
};

export default useGameplayGoBack;
