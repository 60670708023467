import { IonSpinner } from '@ionic/react';
import styled, { css } from 'styled-components';

import Button from '../../../../components/Button';
import FormikInput from '../../../../components/FormikInput';
import { headlineFontStyles, sansSerif } from '../../../../styles/text';

const StyledSpinner = styled(IonSpinner)`
  width: 100%;
  margin: 30px 0;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  flex-basis: calc(50% - 8px);
  margin-top: 8px;
  background-color: ${({ theme }) => theme.colors.system.background};
  border-radius: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  margin-bottom: 4px;
`;

const StatLabel = styled.span`
  ${sansSerif.headlineFontStyles}
  font-size: 16px;
  line-height: 22px;
  text-align: center;
`;

const Value = styled.span`
  ${sansSerif.headlineFontStyles}
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  text-align: center;
  padding: 0 2px;
`;

const ValueContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const QuestionText = styled.span`
  ${headlineFontStyles}
  font-size: 22px;
  line-height: 38px;
  text-align: center;
`;

const RecommendationText = styled.span`
  ${sansSerif.headlineFontStyles}
  font-size: 14px;
  color: ${({ theme }) => theme.colors.system.gamma2};
`;

const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  align-items: center;
  margin: 0 0 31px;
`;

const focusStyles = css`
  background-color: ${({ theme }) => theme.colors.system.alpha};
  font-size: 28px;
`;

const InputWithUnits = styled(FormikInput)`
  input {
    text-align: center;
    width: 50px;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    margin: 0;
    padding: 0;
    background-color: ${({ theme }) => theme.colors.system.alpha};

    &:focus,
    &:active,
    &:not([value=""]) {
      ${focusStyles}
    }
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 6px;
  align-items: center;
`;

const UnitContainer = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.system.background};
  padding: 10px;
  align-items: center;
  border-radius: 10px;
`;

const DateContainer = styled.div`
  padding: 6px;
  background-color: ${({ theme }) => theme.colors.system.alpha};
  color: ${({ theme }) => theme.colors.system.gamma2};
  display: flex;
  justify-content: space-around;
`;

const StepCount = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  margin-top: 10px;
`;

export {
  ItemContainer,
  StyledSpinner,
  Container,
  IconContainer,
  StatLabel,
  Value,
  QuestionText,
  RecommendationText,
  SliderContainer,
  InputWithUnits,
  InputContainer,
  UnitContainer,
  DateContainer,
  StepCount,
  ValueContainer,
  StyledButton,
};
