import {
  useContext,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { Watch } from 'capacitor-watch-app-plugin';
import * as Sentry from '@sentry/browser';

import logEvent from '../../../utils/logger';
import BaseActivity from '../../../models/BaseActivity';
import WatchContext from '../../../context/WatchContext';
import UserContext from '../../../context/UserContext';
import { workoutStatus } from '../../../services/workoutExecutor';
import ActivityContext from '../context/ActivityContext';
import GameplayContext from '../context/GameplayContext';

const GameplayWatchEvent = {
  TOGGLE_PLAY: 'togglePlay',
  ADVANCE_TO_NEXT_ACTIVITY: 'advanceToNextActivity',
  WORKOUT_SESSION_STARTED: 'workoutSessionStarted',
};

const GAMEPLAY_APP_CONTEXT = 'gameplay';

const WorkoutSessionAction = {
  STOP_WORKOUT_SESSION: 'stopWorkoutSession',
};

const useGameplayWatchActions = () => {
  const {
    workoutExecutor: {
      workoutAssignment: {
        name: workoutName,
      },
      finish,
      state: workoutState,
    },
    gameplayStore: {
      gameplaySessionId,
    },
  } = useContext(GameplayContext);

  const {
    isActivityWorkoutPaused,
    onPlayPauseActivity,
    onAdvanceToNextActivity,
    activityExecutor: {
      activity: {
        name: currentActivityName,
        goalField: currentActivityGoalType,
      },
      quit: quitCurrentActivity,
      remainingWorkoutValue: currentActivityRemainingWorkoutValue,
    },
  } = useContext(ActivityContext);

  const {
    userConfigDoc: {
      isWatchAutoStartWorkoutRecordingEnabled,
    },
  } = useContext(UserContext);

  const {
    isWatchAvailable,
    updateAppContext,
  } = useContext(WatchContext);

  const watchMessageListenerRef = useRef();

  /**
   * Setup an event listener for new incoming messages from the watchOS app.
   */
  useEffect(() => {
    if (isWatchAvailable) {
      if (watchMessageListenerRef.current) {
        watchMessageListenerRef.current.remove();
      }

      watchMessageListenerRef.current = Watch.addListener('watchMessageReceived', ({ message }) => {
        const { event } = message;

        switch (event) {
          case GameplayWatchEvent.TOGGLE_PLAY:
            logEvent('watchAppTogglePlayReceived');
            onPlayPauseActivity();
            break;
          case GameplayWatchEvent.ADVANCE_TO_NEXT_ACTIVITY:
            logEvent('watchAppAdvanceToNextActivityReceived');
            onAdvanceToNextActivity();
            break;
          default:
            // Nothing to do here. The watchOS app could send messages that are not meant for the Gameplay
        }
      });
    }
  }, [
    isWatchAvailable,
    onPlayPauseActivity,
    onAdvanceToNextActivity,
    finish,
    quitCurrentActivity,
  ]);

  const currentRemainingWorkoutValue = BaseActivity.toDisplayableValue(currentActivityGoalType,
    currentActivityRemainingWorkoutValue);

  useEffect(() => {
    const data = {
      currentActivity: {
        activityName: currentActivityName,
        activityGoalType: currentActivityGoalType,
        remainingWorkoutValue: currentRemainingWorkoutValue,
      },
      isPlaying: !isActivityWorkoutPaused,
      workoutName,
      workoutState,
    };

    if (gameplaySessionId) {
      data.gameplaySessionId = gameplaySessionId;
    }

    updateAppContext(GAMEPLAY_APP_CONTEXT, data);
  }, [
    updateAppContext,
    isActivityWorkoutPaused,
    workoutName,
    currentActivityName,
    currentActivityGoalType,
    currentRemainingWorkoutValue,
    workoutState,
    gameplaySessionId,
  ]);

  useEffect(() => () => {
    // Remove gameplay app context data from app context on component unmount
    updateAppContext(GAMEPLAY_APP_CONTEXT, undefined);
  }, [
    updateAppContext,
  ]);

  useEffect(() => () => {
    if (watchMessageListenerRef.current) {
      watchMessageListenerRef.current.remove();
    }
  }, []);

  const startWatchWorkoutSession = useCallback(async () => {
    logEvent('startWatchWorkoutSession');

    try {
      // 1. Start watch app and start workout session
      await Watch.startWatchApp();
    } catch (error) {
      logEvent('startWatchWorkoutSessionFailed', {
        gameplaySessionId,
        error,
      });
      Sentry.captureException(error, {
        extra: {
          description: 'Error starting watch app from iOS companion app',
        },
      });
      return;
    }

    // 2. Send gameplaySessionId as soon as possible, using transferUserInfo
    try {
      await Watch.transferUserInfo({
        userInfo: {
          gameplaySessionId,
        },
      });
    } catch (error) {
      logEvent('transferGameplaySessionIdToWatchFailed', {
        gameplaySessionId,
        error,
      });
      Sentry.captureException(error, {
        extra: {
          description: 'Error transfering gameplaySession ID to watch app',
        },
      });
    }
  }, [
    gameplaySessionId,
  ]);

  const stopWatchWorkoutSession = useCallback(async () => {
    logEvent('stopWatchWorkoutSession');

    try {
      await Watch.sendMessage({
        message: {
          workoutSessionAction: WorkoutSessionAction.STOP_WORKOUT_SESSION,
        },
      });
    } catch (error) {
      logEvent('stopWatchWorkoutSessionFailed', {
        error,
      });
      Sentry.captureException(error, {
        extra: {
          description: 'Error sending message to watch app to stop the workout session',
        },
      });
    }
  }, []);

  // Handle start/stop workout session recording in watch, if it's enabled
  useEffect(() => {
    if (isWatchAvailable && isWatchAutoStartWorkoutRecordingEnabled) {
      const init = async () => {
        switch (workoutState) {
          case workoutStatus.PREPARED:
            await startWatchWorkoutSession();
            break;
          case workoutStatus.FINISHED:
          case workoutStatus.QUITTING:
            await stopWatchWorkoutSession();
            break;
          default:
            // Nothing to do
        }
      };
      init();
    }
  }, [
    isWatchAvailable,
    isWatchAutoStartWorkoutRecordingEnabled,
    workoutState,
    startWatchWorkoutSession,
    stopWatchWorkoutSession,
  ]);
};

export default useGameplayWatchActions;
