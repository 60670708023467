import moment from 'moment';
import React, {
  useState,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import format from 'string-template';

import SlideDrawer from '../../../../components/SlideDrawer';
import MeasurementLogContext from '../../../../context/MeasurementLogContext';
import { DateFormat } from '../../../../utils/date';
import Timeline from '../HealthHistory/Timeline';
import LogsChart from '../LogsChart';

import {
  BodyMeasurementText,
  ItemContainer,
  TextContainer,
  SliderContainer,
} from './styles';
import texts from './texts.json';

const BodyMeasurementHistory = () => {
  const [measurementHistoryOpen, setMeasurementHistoryOpen] = useState(false);
  const {
    measurementsLogs,
    isLoadingMore,
  } = useContext(MeasurementLogContext);

  const handleClick = () => {
    setMeasurementHistoryOpen(true);
  };

  const logsWithWeight = useMemo(() => measurementsLogs
    .filter((log) => log.weight), [
    measurementsLogs,
  ]);

  const renderSliderView = useCallback(() => (
    <>
      <LogsChart
        logs={logsWithWeight}
      />
      <Timeline
        logs={logsWithWeight}
        isLoadingMore={isLoadingMore}
      />
    </>
  ), [
    isLoadingMore,
    logsWithWeight,
  ]);

  const renderText = useMemo(() => (
    logsWithWeight.length > 0
      ? format(texts.lastRecorded, {
        weight: logsWithWeight[0].weight,
        unit: logsWithWeight[0].unitType
          ? texts.unitLabel[logsWithWeight[0].unitType].weight
          : texts.unitLabel.IMPERIAL.weight,
        date: moment(logsWithWeight[0].date).format(DateFormat.LONG_MONTH_WITH_DAY_YEAR),
      })
      : texts.noData
  ), [
    logsWithWeight,
  ]);

  return (
    <>
      <ItemContainer
        onClick={handleClick}
      >
        <TextContainer>
          <BodyMeasurementText>
            {renderText}
          </BodyMeasurementText>
        </TextContainer>
      </ItemContainer>
      <SlideDrawer
        isOpen={measurementHistoryOpen}
        onClose={() => setMeasurementHistoryOpen(false)}
        maxHeight={70}
      >
        <SliderContainer>
          {renderSliderView()}
        </SliderContainer>
      </SlideDrawer>
    </>
  );
};

export default BodyMeasurementHistory;
