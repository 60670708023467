const round = (number, decimals = 0) => {
  const base = 10 ** decimals;
  return Math.round(((number + Number.EPSILON) * base)) / base;
};

const getPercentages = (nums, decimals = 0) => {
  const total = nums.reduce((acc, num) => acc + num, 0);
  const { percs: percentages } = nums.reduce(({ percs, accPerc }, num, index) => {
    const isLastItem = index === nums.length - 1;
    let currentPerc = round(((num / total) * 100), decimals);
    const currentAcc = accPerc + currentPerc;

    if (isLastItem && currentAcc !== 100) {
      currentPerc += round(100 - currentAcc, decimals);
    }

    return {
      percs: [
        ...percs,
        currentPerc,
      ],
      accPerc: (accPerc + currentPerc),
    };
  }, {
    percs: [],
    accPerc: 0,
  });
  return percentages;
};

const isEmpty = (value) => typeof value === 'undefined' || value === null || value === false || value === '';
const isNumeric = (value) => !isEmpty(value) && !Number.isNaN(Number(value));

export {
  round,
  getPercentages,
  isNumeric,
};
