import styled from 'styled-components';

import CollapsiblePanel, { Header, Title } from '../../../../components/CollapsiblePanel';
import { sansSerif } from '../../../../styles/text';

const StyledCollapsiblePanel = styled(CollapsiblePanel)`
  ${Header} {
    background-color: ${({ theme }) => theme.colors.system.alpha};
    padding: 0 24px;
  }

  ${Title} {
    ${sansSerif.headlineFontStyles}
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 3px;
    text-transform: uppercase;
  }
`;

const Content = styled.div`
  padding: 16px 24px;
  background-color: ${({ theme }) => theme.colors.system.alpha};
`;

export {
  StyledCollapsiblePanel,
  Content,
};
