import { ExerciseTagCategory } from '../../../models/ExerciseTag';

const defaultTagsByCategory = () => ({
  [ExerciseTagCategory.EXERCISE_TYPE]: [],
  [ExerciseTagCategory.EQUIPMENT]: [],
  [ExerciseTagCategory.BODY_REGION_BASIC]: [],
  [ExerciseTagCategory.BODY_REGION_ADVANCED]: [],
});

const getExerciseTagsByCategory = ({ tags }, initialTags = defaultTagsByCategory()) => (
  tags.reduce((accTags, tag) => {
    const { category } = tag;
    const currentTags = accTags[category];

    if (currentTags) {
      const currentIndex = currentTags.findIndex(({ id }) => tag.id === id);
      if (currentIndex === -1) {
        currentTags.push(tag);
      }
    }

    return accTags;
  }, initialTags)
);

const getExercisesTagsByCategory = (exercises) => (
  exercises.reduce((accTags, exerciseDoc) => {
    if (exerciseDoc.tags) {
      return getExerciseTagsByCategory(exerciseDoc, accTags);
    }
    return accTags;
  }, defaultTagsByCategory())
);

export {
  getExerciseTagsByCategory,
  getExercisesTagsByCategory,
  defaultTagsByCategory,
};
