import styled from 'styled-components';
import { IonSpinner } from '@ionic/react';

const LogsContainer = styled.div``;

const LoaderWrapper = styled.div`
  height: 45px;
  max-height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Loading = styled(IonSpinner)`
  width: 100%;
`;

const Container = styled.div`
  padding: 16px;
  margin-bottom: 8px;
  background-color: ${({ theme }) => theme.colors.system.alpha};
  color: ${({ theme }) => theme.colors.system.beta};
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

const StyledContainer = styled.div`
  width: 100%;
  margin-top: 8px;
`;

export {
  LogsContainer,
  LoaderWrapper,
  Loading,
  Container,
  StyledContainer,
};
