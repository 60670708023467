import * as Sentry from '@sentry/browser';

import CollectionName from '../../utils/collections';
import { ActivityActionTypes } from './ActivityAction';
import WorkoutAssignment from './WorkoutAssignment';
import Workout from './Workout';

class EditableWorkout {
  constructor(docPath, workoutType) {
    if (workoutType === CollectionName.WORKOUT_ASSIGNMENT) {
      this.doc = new WorkoutAssignment(docPath);
    } else {
      this.doc = new Workout(docPath);
    }
  }

  async init(initOpts) {
    await this.doc.init(initOpts);
  }

  updateWorkoutInfo(name, note) {
    return this.doc.updateWorkoutInfo(name, note);
  }

  get workoutDefinition() {
    return this.doc.workoutDefinition;
  }

  jsonActivities = () => {
    const { activities = [] } = this.doc.data.workoutContent ? this.doc.data.workoutContent : this.doc.data;
    return JSON.parse(JSON.stringify(activities));
  }

  /**
   * Add circuit in existing list
   * @param {Object} Circuit object with value
   * @param {Array} pathAttr contain position of newly adding cirsuit
   */
  async addCircuit(circuit, pathAttr) {
    try {
      const activities = this.jsonActivities();
      if (pathAttr.length > 0) {
        activities.splice(pathAttr[0] + 1, 0, circuit);
      }
      await this.updateActivities(activities);
      return true;
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          description: 'Error while adding the workout circuit',
        },
      });
      return false;
    }
  }

  /**
   * Add activities to the workout
   * @param {Array} activitiesToAdd A list of the activities to add to the workout
   * @param {Array} pathAttr contain position of newly adding activity
   */
  async addActivity(activitiesToAdd, pathAttr) {
    try {
      const activities = this.jsonActivities();
      if (pathAttr.length === 1) {
        activities.splice(pathAttr[0], 0, ...activitiesToAdd);
      } else if (pathAttr.length === 2) {
        // This is a single activity. The circuit wrapper does not have a name, which is required for circuits
        if (activities[pathAttr[0]].activities.length === 1 && !activities[pathAttr[0]].name) {
          activities.splice(pathAttr[0] + 1, 0, ...activitiesToAdd);
        } else {
          const singleActivityToAdd = activitiesToAdd.length === 1;
          // TODO: We need to improve this with mobile issue 1187
          const activityToAdd = singleActivityToAdd && activitiesToAdd[0].activities
            ? activitiesToAdd[0].activities
            : activitiesToAdd;
          activities[pathAttr[0]].activities.splice(pathAttr[1], 0, ...activityToAdd);
        }
      }
      await this.updateActivities(activities);
      return true;
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          description: 'Error while adding the workout activity',
        },
      });
      return false;
    }
  }

  /**
   * Edit an existing activity.
   * @param {Array} activitiesArray This array contains the activity that was edited (the first item of the array).
   *                                The aditional items are extra activities to add (this happens when the edited
   *                                activity is split into two activities based on the side information)
   * @param {Array} pathAttr contain position of activity
   * @param {number} rounds The number of rounds of the activity
   */
  async editActivity(activitiesArray, pathAttr, rounds) {
    try {
      const activities = this.jsonActivities();
      if (pathAttr.length === 1) {
        activities.splice(pathAttr[0], 1, ...activitiesArray);
      } else if (pathAttr.length === 2) {
        const circuitWrapper = activities[pathAttr[0]];

        // If the circuit wrapper does not have a name, then it is a single activity.
        if (!circuitWrapper.name) {
          circuitWrapper.rounds = rounds;
          if (activitiesArray.length === 1) {
            circuitWrapper.activities.splice(pathAttr[1], 1, ...activitiesArray);
          } else {
            // This happens when the edited activity was split into two activities.
            circuitWrapper.activities.splice(pathAttr[1], 1, activitiesArray[0]);

            /*
              We need to create a separate circuit in this case. Otherwise, we would be adding a second activity to a
              circuit that was used for a single activity.
            */
            const newCircuit = { ...circuitWrapper };
            newCircuit.activities = [activitiesArray[1]];
            activities.splice(pathAttr[0] + 1, 0, newCircuit);
          }
        } else {
          // Editing an activity inside a regular circuit
          circuitWrapper.activities.splice(pathAttr[1], 1, ...activitiesArray);
        }
      }
      await this.updateActivities(activities);
      return true;
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          description: 'Error while editing the workout activity',
        },
      });
      return false;
    }
  }

  /**
   * delete activity from existing list
   * @param {Array} pathAttr contain position of activity
   */
  async deleteActivity(pathAttr) {
    try {
      const activities = this.jsonActivities();
      if (pathAttr.length === 1) {
        activities.splice(pathAttr[0], 1);
      } else if (pathAttr.length === 2) {
        // If only one activity in circuit,then delete activity with circuit.
        // Change request : https://github.com/Fitmoola/system2-mobile/pull/780#issuecomment-773632144
        if (activities[pathAttr[0]].activities.length === 1) activities.splice([pathAttr[0]], 1);
        else activities[pathAttr[0]].activities.splice(pathAttr[1], 1);
      }
      await this.updateActivities(activities);
      return true;
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          description: 'Error while deleting the workout activity',
        },
      });
      return false;
    }
  }

  /**
   * delete circuit from existing list
   * @param {Array} pathAttr contain position of circuit
   */
  async deleteCircuit(pathAttr) {
    try {
      const activities = this.jsonActivities();
      if (pathAttr.length >= 1) {
        activities.splice(pathAttr[0], 1);
      } else return false;
      await this.updateActivities(activities);
      return true;
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          description: 'Error while deleting the workout circuit',
        },
      });
      return false;
    }
  }

  /**
   * Move the array position base on an action (Move up/down only change position in array).
   * @param {Array} arr Original array reference
   * @param {number} oldIndex Existing index of object which we have to move
   * @param {number} newIndex New index of moving object
   */
  moveArray = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length + 1;
      while (k) {
        arr.push(undefined);
        k -= 1;
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  }

  /**
   * Move activity/circuit position up/down
   * @param {Array} pathAttr contain existing position of activity/circuit
   * @param {string} type type of action, MOVE_UP/MOVE_DOWN
   */
  async moveActivity(pathAttr, type) {
    try {
      const activities = this.jsonActivities();
      let oldIndex = -1;
      let newIndex = -1;
      let newIndexOperation = 0;
      // For move up decrease index by 1 and for up increase index by 1
      if (type === ActivityActionTypes.MOVE_UP) {
        newIndexOperation = -1;
      } else if (type === ActivityActionTypes.MOVE_DOWN) {
        newIndexOperation = 1;
      }
      let path = pathAttr;
      // If moving activity inside circuit and have only a activity, move circuit
      if (pathAttr.length === 2 && activities[path[0]].activities.length === 1) {
        const pathValue = pathAttr[0];
        if (type === ActivityActionTypes.MOVE_UP && pathValue === 0) return false;
        path = [pathValue];
      }

      if (path.length === 1) {
        const { 0: index } = path;
        oldIndex = index;
        newIndex = oldIndex + newIndexOperation;
        if (newIndex < 0) {
          newIndex = 0;
        }
        this.moveArray(activities, oldIndex, newIndex);
      } else if (path.length === 2) {
        const { 1: index } = path;
        oldIndex = index;
        newIndex = oldIndex + newIndexOperation;
        this.moveArray(activities[path[0]].activities, oldIndex, newIndex);
      }

      await this.updateActivities(activities);
      return true;
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          description: `Error while moving the workout activity with type  "${type}"`,
        },
      });
      return false;
    }
  }

  async updateActivities(activities) {
    await this.doc.updateActivities(activities);
  }
}

export default EditableWorkout;
