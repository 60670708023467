import styled from 'styled-components';

import { fontFamily } from '../../../../styles/text';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Subtitle = styled.div`
  font-family: ${fontFamily.sansSerif};
  font-weight: 500;
  font-size: 13px;
  font-feature-settings: 'liga' off;
  color: ${({ theme, $secondary }) => ($secondary ? theme.colors.system.gamma2 : theme.colors.system.primary)};
`;

const CaloriesText = styled.div`
  font-family: ${fontFamily.sansSerif};
  font-weight: 500;
  font-size: 48px;
  font-feature-settings: 'liga' off;
  color: ${({ theme }) => theme.colors.system.primary};

  > span {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
`;

const Divider = styled.div`
  width: 100%;
  border: 0.5px solid ${({ theme }) => theme.colors.base.secondaryVariant};
  margin: 8px 0;
`;

const MacrosContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: space-between;
`;

const MacrosText = styled.div`
  font-family: ${fontFamily.sansSerif};
  font-weight: 500;
  font-size: 30px;
  font-feature-settings: 'liga' off;
  color: ${({ theme }) => theme.colors.system.primary};
`;

export {
  Container,
  Subtitle,
  CaloriesText,
  Divider,
  MacrosContainer,
  MacrosText,
};
