import styled from 'styled-components';

const DebugActionsContainer = styled.div`
  position: absolute;
  bottom: 0;
  color: ${({ theme }) => theme.colors.system.beta};
  background-color: ${({ theme }) => theme.colors.system.alpha};
  border: solid 1px #CCC;
  padding: 10px;
  width: 100%;
`;

const BasicControls = styled.div`
  display: flex;

  button {
    margin: 0 5px;
    padding: 0;
    width: 30px;
  }

  input[type="range"] {
    flex: 1;
    margin-left: 10px;
  }
`;

export {
  DebugActionsContainer,
  BasicControls,
};
