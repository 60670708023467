import { useMemo } from 'react';

import { isPortrait } from '../../utils/orientation';
import { availableAspectRatios } from '../../utils/aspectRatio';

/**
 * This hook calculates the best aspect ratio the device can use for the camera. We need to lock the camera viewport to
 * a well know aspect ratio (instead of making it grow or shrink based on space). This is needed because the camera
 * and the streaming service use very specific aspect ratios, and if we change that the generated videos may be broken.
 * We try to use 16:9 whenever possible (this is the default value). if there is no enough space for that,
 * we use 4:3, instead.
 *
 * @returns {Object} The selected aspect ratio object. It is one of the entries listed in `availableAspectRatios`.
 */
const useCameraAspectRatio = () => (
  useMemo(() => {
    // Minimum width reserved for the extra panels (not the camera)
    const minReservedWidth = 250;

    const {
      availWidth,
      availHeight,
    } = window.screen;

    let width;
    let height;

    // Calculate the aspect ratio in landscape mode. If it's in portrait mode, we need to swap width and height.
    if (isPortrait) {
      width = availHeight;
      height = availWidth;
    } else {
      width = availWidth;
      height = availHeight;
    }

    // Try 16:9 first
    const minimalRequiredWidth = availableAspectRatios.HD.numericValue * height;
    const isHDPossible = width - minimalRequiredWidth > minReservedWidth;

    // If we don't have enough space for HD (16:9) then we fallback to the standard aspect ratio (4:3)
    return isHDPossible ? availableAspectRatios.HD : availableAspectRatios.STANDARD;
  }, [])
);

export default useCameraAspectRatio;
