import React from 'react';
import PropTypes from 'prop-types';

import CrossIcon from '../Icons/CrossIcon';

const CustomClearIndicator = (props) => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;

  return (
    <div
      {...restInnerProps} /* eslint-disable-line react/jsx-props-no-spreading */
      ref={ref}
      style={getStyles('clearIndicator', props)}
    >
      <CrossIcon color="rgba(20, 34, 47, 0.8)" />
    </div>
  );
};

CustomClearIndicator.propTypes = {
  getStyles: PropTypes.func.isRequired,
  innerProps: PropTypes.object,
};

CustomClearIndicator.defaultProps = {
  innerProps: {},
};

export default CustomClearIndicator;
