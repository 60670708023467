import styled from 'styled-components';

import Card from '../../../components/Card';
import Input from '../../../components/Input';
import Button from '../../../components/Button';

import { commonCardStyles } from '../components/styles';

const Container = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
`;

const AvatarCard = styled(Card)`
  ${commonCardStyles};
  padding: 30px 20px;
`;

const StyledInput = styled(Input)`
  padding: 0;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colors.fonts.primary};
  font-size: 16px;
`;

export {
  Container,
  AvatarCard,
  StyledInput,
  StyledButton,
};
