import React, { forwardRef } from 'react';
import { PropTypes } from 'prop-types';

import { ContentSwitcherContextProvider } from './context';

import ContextSwitcher from './ContentSwitcher';
import ContentSwitcherAPI from './ContentSwitcherAPI';

const ContentSwitcherContainer = forwardRef(({
  sectionsConfig,
  ...props
}, ref) => (
  <ContentSwitcherContextProvider sectionsConfig={sectionsConfig}>
    <ContentSwitcherAPI ref={ref}>
      <ContextSwitcher {...props} />
    </ContentSwitcherAPI>
  </ContentSwitcherContextProvider>
));

ContentSwitcherContainer.propTypes = {
  sectionsConfig: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      Content: PropTypes.elementType.isRequired,
    }),
  ).isRequired,
};

export default ContentSwitcherContainer;
