import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import moment from 'moment';

import { withNavigationMenuLayout } from '../../components/Layout';
import useComponentLoadingTime from '../../hooks/useComponentLoadingTime';
import { withAggregatedDataContextProvider } from '../../context/AggregatedDataContext';
import UserContext from '../../context/UserContext';
import useSessionStore from '../../hooks/useSessionStore';

import WorkoutFeedContainer from './WorkoutFeed';
import StartAtCountdown from './StartAtCountdown/StartAtCountdown';
import FailedPayment from './FailedPayment/FailedPayment';

const Home = () => {
  const { startLoading, finishLoading } = useComponentLoadingTime('workoutHomeFeed');

  const { userDoc } = useContext(UserContext);

  const {
    isCoachOrAdmin,
  } = useSessionStore();

  const [hasServiceStarted, setHasServiceStarted] = useState(
    userDoc.serviceStartAtSeconds === 0 || moment.unix(userDoc.serviceStartAtSeconds).isBefore(moment()),
  );
  const [isPaymentFailBannerSkipped, setIsPaymentFailBannerSkipped] = useState(false);

  useEffect(() => {
    /*
      Measure home page start loading time when this component is mounted. The finished time
      is measured by the component mount lifecycle hook of the WorkoutFeed component. At that
      specific moment is safe to determine the total time, since we want to mesure the time that
      passes between this page is loading data (from all of the context providers required) until
      the WorkoutFeed is injected into the virtual DOM.

      NOTE: this doesn't measure the WorkoutFeed internal components rendering time, since that's
      not the scope of this, and if we plan to measure that, we should have a good reason to do it
      in order to impact the performance of this page, by using performance tools like React.Profiler
      component.
    */
    if (hasServiceStarted && userDoc.firstPaymentSuccessful) {
      startLoading();
    }

    // update hasServiceStarted state when the serviceStartAtSeconds changes to show the banner
    if (moment.unix(userDoc.serviceStartAtSeconds).isAfter(moment())) {
      setHasServiceStarted(false);
    }
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    hasServiceStarted,
    userDoc.firstPaymentSuccessful,
    userDoc.serviceStartAtSeconds,
  ]);

  if (!hasServiceStarted) {
    return (
      <StartAtCountdown
        serviceStartAt={userDoc.serviceStartAtSeconds}
        onCountdownEnds={() => setHasServiceStarted(true)}
        isSkippable={isCoachOrAdmin}
      />
    );
  }

  if (!userDoc.firstPaymentSuccessful && !isPaymentFailBannerSkipped) {
    return (
      <FailedPayment
        isSkippable={isCoachOrAdmin}
        onSkipped={() => setIsPaymentFailBannerSkipped(true)}
      />
    );
  }

  return (
    <>
      <WorkoutFeedContainer onfinishLoadingEvent={finishLoading} />
    </>
  );
};

export default compose(
  withAggregatedDataContextProvider,
  withNavigationMenuLayout,
  observer,
)(Home);
