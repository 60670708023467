import {
  CheckInSideImage,
  CheckInFrontImage,
  CheckInBackImage,
} from './styles';

const CheckinImageType = {
  FRONT: 'FRONT',
  BACK: 'BACK',
  SIDE: 'SIDE',
};

const checkInImageConfig = {
  [CheckinImageType.FRONT]: {
    icon: CheckInFrontImage,
    index: 0,
  },
  [CheckinImageType.BACK]: {
    icon: CheckInBackImage,
    index: 1,
  },
  [CheckinImageType.SIDE]: {
    icon: CheckInSideImage,
    index: 2,
  },
};

export {
  CheckinImageType,
  checkInImageConfig,
};
