import * as Sentry from '@sentry/browser';
import { AppIcon } from '@capacitor-community/app-icon';

const resetNativeAppIcon = async () => {
  try {
    await AppIcon.reset();
  } catch (error) {
    Sentry.captureException(error, {
      extra: {
        description: 'Error trying to reset application icon',
      },
    });
  }
};

const changeNativeAppIcon = async (appIconName) => {
  try {
    await AppIcon.change({
      name: appIconName,
      suppressNotification: true,
    });
  } catch (error) {
    Sentry.captureException(error, {
      extra: {
        description: 'Error trying to change application icon',
      },
    });
  }
};

const updateNativeAppIcon = async (appCustomizationDoc) => {
  let currentIconName;
  try {
    const result = await AppIcon.getName();
    currentIconName = result.value;
  } catch (error) {
    Sentry.captureException(error, {
      extra: {
        description: 'Error trying to check the current app icon name',
      },
    });
  }

  if (appCustomizationDoc && appCustomizationDoc.appIconName) {
    const { appIconName } = appCustomizationDoc;
    if (currentIconName !== appIconName) {
      await changeNativeAppIcon(appIconName);
    }
  } else if (currentIconName) {
    // Reset icon name to default icon
    await resetNativeAppIcon();
  }
};

export {
  updateNativeAppIcon,
  resetNativeAppIcon,
  changeNativeAppIcon,
};
