import React from 'react';
import PropTypes from 'prop-types';

import { InputType } from './utils';
import {
  InputContainer,
  StyledInput,
  InputLabel,
  InputTitle,
} from './styles';

const Input = ({
  onChange,
  value,
  min,
  max,
  title,
  unit,
  displayZeroValue,
  allowDecimalValues,
  type,
  className,
  ...props
}) => {
  let inputValue = value;
  if (type === InputType.NUMBER && value === 0 && !displayZeroValue) {
    inputValue = '';
  }

  const onInputChange = (event) => {
    if (type === InputType.NUMBER) {
      // If the field is emptied, we force the value to 0.
      const targetValue = event.target.value ? event.target.value : 0;

      // Use float numbers when we are allowed to display decimal numbers. Otherwise, parse integers.
      const numberValue = allowDecimalValues
        ? Number.parseFloat(targetValue)
        : Number.parseInt(targetValue, 10);

      if (numberValue >= min && numberValue <= max) {
        onChange(numberValue);
      }
    } else {
      onChange(event.target.value);
    }
  };

  const inputMode = type === InputType.NUMBER ? 'decimal' : 'text';

  return (
    <InputContainer className={className}>
      {!!title && (
        <InputTitle>{title}</InputTitle>
      )}
      <StyledInput
        min={min}
        max={max}
        value={inputValue}
        onChange={onInputChange}
        inputMode={inputMode}
        type={type}
        {...props}
      />
      {!!unit && (
        <InputLabel>{unit}</InputLabel>
      )}
    </InputContainer>
  );
};

Input.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  min: PropTypes.number,
  max: PropTypes.number,
  title: PropTypes.string,
  unit: PropTypes.string,
  displayZeroValue: PropTypes.bool,
  allowDecimalValues: PropTypes.bool,
  type: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

Input.defaultProps = {
  value: 0,
  min: 0,
  max: Number.MAX_SAFE_INTEGER,
  title: '',
  unit: '',
  displayZeroValue: false,
  allowDecimalValues: true,
  type: InputType.STRING,
  className: '',
};

export default Input;
