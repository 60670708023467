import { Collection } from 'firestorter';
import {
  decorate,
  observable,
} from 'mobx';

import BaseWorkout, { WorkoutVisibility } from '../../models/Workout';
import WorkoutDefinition from '../../models/WorkoutDefinition';
import { firestorePaths } from '../../utils/firebasePaths';
import { estimateDuration } from '../utils/workout';

class Workout extends BaseWorkout {
  /**
   * Update the activities field of the workout.
   * @param {Array} activities The new activities to store.
   */
  updateActivities = async (activities) => {
    // Calculate the new estimated duration of the workout.
    const estimatedDuration = estimateDuration(activities);
    await this.updateFields({
      activities,
      estimatedDuration,
    });
    this.workoutDefinition = new WorkoutDefinition(this.data);
  };

  updateWorkoutInfo = async (name, note) => {
    await this.updateFields({
      name,
      note,
    });
    this.workoutDefinition = new WorkoutDefinition(this.data);
  }

  static createWorkoutDoc = async (name, note, coach) => {
    const workoutCollection = new Collection(firestorePaths.WORKOUT);

    const newWorkoutDoc = await workoutCollection.add({
      coach,
      name,
      note,
      isArchived: false,
      createdAt: Date.now(),
      activities: [],
      visibility: WorkoutVisibility.PRIVATE,
    });

    return newWorkoutDoc;
  };
}

decorate(Workout, {
  workoutDefinition: observable,
});

export default Workout;
