import AssignmentDayType, { getAssignmentDayType } from './assignmentType';

const WorkoutGroupType = {
  ACTIVE: 'ACTIVE',
  FUTURE: 'FUTURE',
};

const getWorkoutGroupTypeByAssignmentType = (assigmentType) => {
  if (assigmentType === AssignmentDayType.PAST || assigmentType === AssignmentDayType.TODAY) {
    return WorkoutGroupType.ACTIVE;
  }
  return WorkoutGroupType.FUTURE;
};

const getWorkoutGroupTypeByDate = (date) => {
  const assignmentType = getAssignmentDayType(date);
  return getWorkoutGroupTypeByAssignmentType(assignmentType);
};

export default WorkoutGroupType;
export {
  getWorkoutGroupTypeByAssignmentType,
  getWorkoutGroupTypeByDate,
};
