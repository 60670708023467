import React, { useContext } from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import InfoPanel from '../../../InfoPanel';
import { ContentSwitcherContext } from '../../../ContentSwitcher';
import ActivitiesList from '../../../ActivitiesList';
import Section from '../../section';
import WorkoutInfoSwitcherContext from '../../context';
import ActivityHistoryData from './ActivityHistoryData';
import texts from './texts.json';
import { StyledListIcon } from './styles';

const ActivityHistory = () => {
  const { goToSectionByName } = useContext(ContentSwitcherContext);
  const { activitiesTape } = useContext(WorkoutInfoSwitcherContext);

  return (
    <InfoPanel
      title={texts.title}
      LeftIcon={StyledListIcon}
      onLeftIconClick={() => goToSectionByName(Section.WORKOUT_ROUTINE)}
    >
      <ActivitiesList
        activities={activitiesTape}
        ActivityExtraContent={ActivityHistoryData}
        displayReducedData
        ExtraContentWidth="115px"
      />
    </InfoPanel>
  );
};

export default compose(
  observer,
)(ActivityHistory);
