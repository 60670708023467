import React, { useEffect, useContext } from 'react';
import { compose } from 'recompose';

import { Feature } from '../../context/AppCustomizationContext';
import useAppCustomization from '../../hooks/useAppCustomization';
import { withLayout, HeaderContext } from '../../components/Layout';
import LogActivityContainer from './LogActivity';
import WorkoutSelectionContainer from './WorkoutSelection';
import texts from './texts.json';
import {
  Container,
  Section,
} from './styles';

const WorkoutSelector = () => {
  const { setTitle } = useContext(HeaderContext);

  const { isFeatureEnabled } = useAppCustomization();

  useEffect(() => setTitle(texts.title), [setTitle]);

  return (
    <Container>
      <LogActivityContainer />
      {isFeatureEnabled(Feature.SYSTEM2_WORKOUTS) && (
        <Section>
          <WorkoutSelectionContainer />
        </Section>
      )}
    </Container>
  );
};

export default compose(
  withLayout,
)(WorkoutSelector);
