import styled from 'styled-components';

import { headlineFontStyles } from '../../../styles/text';
import TagFilters from '../TagFilters';

const Title = styled.div`
  ${headlineFontStyles}
  font-size: 20px;
  line-height: 27px;
  color: ${({ theme }) => theme.colors.base.primary};
  flex: 1;
  text-align: center;
`;

const Name = styled.div`
  ${headlineFontStyles}
  font-size: 28px;
  line-height: 38px;
  padding: 8px 16px;
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.colors.base.primary};
`;

const StyledTagFilters = styled(TagFilters)`
  padding-right: 16px;
  padding-left: 16px;
`;

export {
  Title,
  Name,
  StyledTagFilters,
};
