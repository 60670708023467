import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledHeader,
  IconWrapper,
  Title,
} from './styles';

const Header = ({
  title,
  LeftIcon,
  onLeftIconClick,
  RightIcon,
  onRightIconClick,
}) => (
  <StyledHeader>
    <IconWrapper onClick={onLeftIconClick}>
      {!!LeftIcon && <LeftIcon />}
    </IconWrapper>
    <Title>{title}</Title>
    <IconWrapper onClick={onRightIconClick}>
      {!!RightIcon && <RightIcon />}
    </IconWrapper>
  </StyledHeader>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
  LeftIcon: PropTypes.elementType,
  onLeftIconClick: PropTypes.func,
  RightIcon: PropTypes.elementType,
  onRightIconClick: PropTypes.func,
};

Header.defaultProps = {
  LeftIcon: null,
  onLeftIconClick: () => {},
  RightIcon: null,
  onRightIconClick: () => {},
};

export default Header;
