import styled, { css } from 'styled-components';

const actionsContainerStyles = ({ isCircuit }) => {
  const customStyles = !isCircuit && css`
      position: absolute;
      right: 4px;
      top: 0;`;

  return customStyles;
};

const ActionsBarContainer = styled.div`
  margin-left: auto;
  opacity: 0.15;
  z-index: 1;
  transition: opacity 0.25s ease-in-out;

  &:hover {
    opacity: 1;
  }

  ${(props) => actionsContainerStyles(props)}
`;

export {
  ActionsBarContainer,
};
