import React, {
  useContext,
  useEffect,
  useRef,
  useCallback,
  useState,
} from 'react';
import * as Sentry from '@sentry/browser';

import useAppTheme from '../../hooks/useAppTheme';
import CameraContext from '../../context/CameraContext';
import logEvent from '../../utils/logger';
import DebugSelfie from './DebugSelfie';
import { SelfieVideoContext } from './SelfieVideoContext';
import videoSizeConfig from './SelfieVideoContext/config';
import { StyledCameraPreview } from './styles';

const SelfieVideo = () => {
  const {
    camera: {
      startCamera,
      hasCameraSupport,
    },
    cameraAspectRatio,
  } = useContext(CameraContext);
  const {
    takePic,
    setDebugSelfieRef,
    previewSelfieRef,
    setPreviewSelfieRef,
    ready,
    debugMode,
  } = useContext(SelfieVideoContext);

  const debugSelfieRef = useRef(null);
  const [isReady, setIsReady] = useState(false);

  const { colors } = useAppTheme();

  const previewRefReady = useCallback((ref) => {
    setPreviewSelfieRef(ref);
    setIsReady(true);
  }, [
    setPreviewSelfieRef,
    setIsReady,
  ]);

  useEffect(() => {
    if (debugSelfieRef.current) {
      setDebugSelfieRef(debugSelfieRef);
    }
  }, [
    debugSelfieRef,
    setDebugSelfieRef,
  ]);

  useEffect(() => {
    let shouldUpdate = true;
    let timerSetup;

    if (isReady && previewSelfieRef) {
      if (!hasCameraSupport()) {
        throw new Error('No media or camera support');
      }

      logEvent('startAccessingCamera');

      const setup = async (retries = 0) => {
        try {
          await startCamera({
            mediaOptions: {
              video: {
                ...videoSizeConfig.video,
                facingMode: 'user',
              },
            },
            htmlView: previewSelfieRef.current,
            enablePreview: !debugMode,
            backgroundColor: colors.system.alpha,
            aspectRatio: cameraAspectRatio.name,
          });

          logEvent('cameraAccessSuccess');

          if (shouldUpdate) {
            // start processing images
            ready();
          }
        } catch (error) {
          const details = {};
          if (error.message) {
            details.message = error.message;
          }
          if (error.name) {
            details.name = error.name;
          }

          logEvent('cameraAccessError', details);

          if (retries < 5) {
            timerSetup = setTimeout(() => {
              setup(retries + 1);
            }, 1000);
          } else {
            // report the error if we couldn't initialize the camera after all the retries.
            Sentry.captureException(error);
          }
        }
      };

      setup();
    }

    return () => {
      shouldUpdate = false;
      clearTimeout(timerSetup);
    };
  }, [
    isReady,
    startCamera,
    takePic,
    ready,
    hasCameraSupport,
    previewSelfieRef,
    debugMode,
    cameraAspectRatio.name,
    colors.system.alpha,
  ]);

  return (
    <>
      <StyledCameraPreview
        previewRefReady={previewRefReady}
        show={!debugMode}
      />
      {debugMode && (
        <DebugSelfie>
          <canvas ref={debugSelfieRef} />
        </DebugSelfie>
      )}
    </>
  );
};

export default SelfieVideo;
