import moment from 'moment';

import texts from './texts.json';

const WeekDayNameByDayNumber = {
  1: texts.weekDayName.monday,
  2: texts.weekDayName.tuesday,
  3: texts.weekDayName.wednesday,
  4: texts.weekDayName.thursday,
  5: texts.weekDayName.friday,
  6: texts.weekDayName.saturday,
  7: texts.weekDayName.sunday,
};

const todayWeekDayNumber = () => moment().isoWeekday();

const weekDayNumbers = () => Array.from({ length: 7 }, (_, i) => i + 1);

const isValidWeekDayNumber = (day) => weekDayNumbers().includes(day);

export {
  todayWeekDayNumber,
  weekDayNumbers,
  isValidWeekDayNumber,
  WeekDayNameByDayNumber,
};
