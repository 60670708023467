import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import BackButton from '../../BackButton';
import HeaderLogo from '../HeaderLogo';
import ActionItem from './ActionItem';
import {
  StyledHeader,
  Container,
  BackActionContainer,
  Title,
  Actions,
} from './styles';

const Header = ({
  title,
  actions,
  navigateBack,
  enableHeaderActions,
}) => {
  const backButtonTrackingProps = useMemo(() => {
    const props = {
      button: 'navigationBackButton',
    };
    if (title) {
      props.pageTitle = title;
    }
    return props;
  }, [title]);

  return (
    <StyledHeader>
      <Container>
        {enableHeaderActions && (
          <BackActionContainer>
            <BackButton trackingProps={backButtonTrackingProps} onClick={navigateBack} />
          </BackActionContainer>
        )}
        <Title>
          {title || <HeaderLogo />}
        </Title>
        {enableHeaderActions && !!actions && (
          <Actions>
            {actions.map(({ id, ...props }) => <ActionItem {...props} key={id} />)}
          </Actions>
        )}
      </Container>
    </StyledHeader>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.array,
  navigateBack: PropTypes.func.isRequired,
  enableHeaderActions: PropTypes.bool,
};

Header.defaultProps = {
  title: null,
  actions: null,
  enableHeaderActions: true,
};

export default Header;
