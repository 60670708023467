import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import AppCustomizationContext from '../AppCustomizationContext';
import useComponentMounted from '../../hooks/useComponentMounted';
import useComponentLoadingTime from '../../hooks/useComponentLoadingTime';
import UserContext from '../UserContext';
import PostPaymentFormAnswer from '../../models/PostPaymentFormAnswer';
import PostPaymentFormContext from './PostPaymentFormContext';

const PostPaymentFormField = {
  USER_ID: 'userid',
  NAME: 'name',
  EMAIL: 'email',
};

const PostPaymentFormContextProvider = ({ children }) => {
  const [isReady, setIsReady] = useState(false);
  const [answers, setAnswers] = useState();
  const { userDoc, userId } = useContext(UserContext);
  const { productDoc } = useContext(AppCustomizationContext);

  const isComponentMountedRef = useComponentMounted();
  const { startLoading, finishLoading } = useComponentLoadingTime('postPaymentFormContext');

  const postPaymentFormURL = useMemo(() => {
    let redirectURL = null;
    if (productDoc?.postPaymentForm) {
      const formHiddenFields = {
        [PostPaymentFormField.USER_ID]: userDoc.id,
        [PostPaymentFormField.NAME]: userDoc.name,
        [PostPaymentFormField.EMAIL]: userDoc.email,
      };
      const formURL = new URL(productDoc.postPaymentForm);
      // New forms are in Paperform, so sending pre-filled fields in query params.
      Object.entries(formHiddenFields).forEach(([key, value]) => (
        formURL.searchParams.set(key, value)
      ));
      redirectURL = formURL.toString();
    }
    return redirectURL;
  }, [
    userDoc,
    productDoc,
  ]);

  useEffect(() => {
    const init = async () => {
      startLoading();
      const answerCollection = await PostPaymentFormAnswer.getPostPaymentAnswersByUserId(userId);
      if (isComponentMountedRef.current) {
        setAnswers(answerCollection);
        setIsReady(true);
        finishLoading();
      }
    };

    if (!isReady) {
      init();
    }
  }, [
    isReady,
    userId,
    isComponentMountedRef,
    startLoading,
    finishLoading,
  ]);

  const value = useMemo(() => ({
    isReady,
    postPaymentFormURL,
    answers,
  }), [
    isReady,
    postPaymentFormURL,
    answers,
  ]);

  return (
    <PostPaymentFormContext.Provider value={value}>
      {children}
    </PostPaymentFormContext.Provider>
  );
};

PostPaymentFormContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(PostPaymentFormContextProvider);
