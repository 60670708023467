import React from 'react';
import PropTypes from 'prop-types';

import MealImage from '../../../../../../components/MealImage';
import {
  Container,
  Title,
  Description,
  Wrapper,
} from './styles';

const MealItem = ({
  title,
  description,
  imageSrc,
  onClick,
}) => (
  <Container onClick={onClick}>
    <Title>{title}</Title>
    <Wrapper>
      <MealImage
        key={imageSrc}
        src={imageSrc}
      />
    </Wrapper>
    <Description>{description}</Description>
  </Container>
);

MealItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageSrc: PropTypes.string,
  onClick: PropTypes.func,
};

MealItem.defaultProps = {
  onClick: () => {},
  imageSrc: '',
};

export default MealItem;
