import styled, { css } from 'styled-components';

const WorkoutItem = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 30px;
  width: 100%;
  ${({ $showCardBackground = false, theme }) => $showCardBackground && css`
    background: ${theme.colors.shades.secondary20};
  `}

  &:not(:last-child)::after {
    content: '';
    height: 1px;
    background: ${({ theme }) => theme.colors.system.gamma1};
    margin: 0 16px;

    ${({ $enableExtraSpace = false }) => $enableExtraSpace && css`
      margin-left: 52px;
    `}
  }
`;

export default WorkoutItem;
