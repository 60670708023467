const DEFAULT_TIMEOUT = 5000;

/**
 * Creates a promise that will be rejected after the specified timeout value or
 * DEFAULT_TIMEOUT by default.
 * This can be used with Promise.race.
 *
 * @param {string} message
 * @param {number=} timeout
 * @returns {Promise<Error>} A promise rejected after the tiemout value.
 */
const createTimeout = (message, timeout = DEFAULT_TIMEOUT) => new Promise((resolve, reject) => {
  setTimeout(() => {
    const description = `${message}. The operation took more than ${timeout} ms`;
    const error = new Error(description);
    // Mark this error as a custom timeout error
    error.isOperationTimeout = true;

    reject(error);
  }, timeout);
});

export {
  createTimeout,
};
