import React from 'react';

import CameraPermissionInitializator from './CameraPermissionInitializator';

const withCameraPermissionInitializatorReady = (Component) => (props) => (
  <CameraPermissionInitializator>
    <Component {...props} />
  </CameraPermissionInitializator>
);

export default withCameraPermissionInitializatorReady;
