import React from 'react';
import PropTypes from 'prop-types';

import texts from './texts.json';
import {
  StyledCard,
  Title,
  StyledButton,
} from './styles';

const ActiveWorkoutCard = ({
  title,
  onStartWorkout,
}) => (
  <StyledCard>
    <Title>{title}</Title>
    <StyledButton onClick={onStartWorkout}>
      {texts.startWorkoutAction}
    </StyledButton>
  </StyledCard>
);

ActiveWorkoutCard.propTypes = {
  title: PropTypes.string.isRequired,
  onStartWorkout: PropTypes.func.isRequired,
};

export default ActiveWorkoutCard;
