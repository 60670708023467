import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import WorkoutAssignment from '../../models/WorkoutAssignment';
import useComponentMounted from '../../hooks/useComponentMounted';
import UserExerciseOverridesContext from '../UserExerciseOverridesContext';

import WorkoutContext from './WorkoutContext';

const WorkoutContextProvider = ({ children }) => {
  const {
    params: {
      workoutAssignmentId,
    },
  } = useRouteMatch();
  const {
    userExerciseOverridesDoc,
    prepareActivitiesWithOverrides,
  } = useContext(UserExerciseOverridesContext);
  const isComponentedMountedRef = useComponentMounted();

  const [isWorkoutReady, setIsWorkoutReady] = useState(false);
  const [workoutAssignmentDoc, setWorkoutAssignmentDoc] = useState(null);
  const [workoutDef, setWorkoutDef] = useState(null);
  const [activitiesWithOverrides, setActivitiesWithOverrides] = useState([]);

  const updateWorkoutAssignment = useCallback((newWorkoutAssignmentDoc) => {
    const {
      workoutDefinition,
    } = newWorkoutAssignmentDoc;

    setWorkoutAssignmentDoc(newWorkoutAssignmentDoc);
    setWorkoutDef(workoutDefinition);
  }, []);

  useEffect(() => {
    if (!isWorkoutReady) {
      const init = async () => {
        const assignmentDoc = new WorkoutAssignment(`/workoutAssignment/${workoutAssignmentId}`);

        await assignmentDoc.init();

        if (isComponentedMountedRef.current) {
          updateWorkoutAssignment(assignmentDoc);
          setIsWorkoutReady(true);
        }
      };

      init();
    }
  }, [
    isWorkoutReady,
    workoutAssignmentId,
    updateWorkoutAssignment,
    isComponentedMountedRef,
  ]);

  useEffect(() => {
    if (workoutAssignmentDoc) {
      const setup = async () => {
        const {
          workoutDefinition: {
            activities,
          },
        } = workoutAssignmentDoc;

        const parsedActivities = await prepareActivitiesWithOverrides(activities);
        if (isComponentedMountedRef.current) {
          setActivitiesWithOverrides(parsedActivities);
        }
      };

      setup();
    }
  }, [
    workoutAssignmentDoc,
    prepareActivitiesWithOverrides,
    isComponentedMountedRef,
    /*
      NOTE: Execute this useEffect when the overrides document content changes to recalculate
      activities with overrides.
    */
    userExerciseOverridesDoc.data,
  ]);

  const workout = useMemo(() => ({
    isWorkoutReady,
    workoutAssignmentDoc,
    workoutDef,
    activitiesWithOverrides,
    updateWorkoutAssignment,
  }), [
    isWorkoutReady,
    workoutAssignmentDoc,
    workoutDef,
    activitiesWithOverrides,
    updateWorkoutAssignment,
  ]);

  return (
    <WorkoutContext.Provider value={workout}>
      {children}
    </WorkoutContext.Provider>
  );
};

WorkoutContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(WorkoutContextProvider);
