import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import moment from 'moment';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import format from 'string-template';
import PropTypes from 'prop-types';

import CheckInContext from '../../../../../context/CheckInContext';
import WorkoutFeedView, { WorkoutFeedViewType } from '../../../../../models/WorkoutFeedView';
import UserContext from '../../../../../context/UserContext';
import { DateFormat } from '../../../../../utils/date';

import WorkoutDayPanel from '../WorkoutDayPanel';
import {
  Container,
  ItemContainer,
  StyledTitle,
  CountLabel,
  Description,
  StyledWorkoutProgress,
  LoaderWrapper,
  Loading,
  DataContainer,
  StyledCollapsiblePanel,
  WorkoutDetails,
} from './styles';
import texts from './texts.json';

const WorkoutStat = ({
  updateDocumentFields,
}) => {
  const { currentSelectedDate, checkInDay } = useContext(CheckInContext);
  const { userId } = useContext(UserContext);
  const [workoutList, setWorkoutList] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const ONE_DAY = 1;

  const { startDate, endDate } = useMemo(() => {
    const start = moment(currentSelectedDate.clone().startOf('isoWeek')).add(checkInDay, 'days').utc(true);
    const end = moment(currentSelectedDate.clone().endOf('isoWeek')).add(checkInDay, 'days').utc(true);

    return {
      startDate: start,
      endDate: end,
    };
  }, [
    currentSelectedDate,
    checkInDay,
  ]);

  useEffect(() => {
    const init = async () => {
      const workoutFeedViewCollection = await WorkoutFeedView.getWorkoutFeedViewByDate(userId, startDate, endDate);
      if (workoutFeedViewCollection.hasDocs) {
        setWorkoutList(workoutFeedViewCollection.docs);
      }
      setIsReady(true);
    };

    init();
  }, [
    startDate,
    endDate,
    userId,
  ]);

  useEffect(() => {
    const workoutStat = workoutList.map((workout) => ({
      title: workout.title,
      startDate: workout.startDate,
      endDate: workout.endDate,
      type: workout.type,
      status: workout.status,
      isCompleted: workout.isCompleted,
    }));
    updateDocumentFields({
      workoutStat,
    });
  }, [
    workoutList,
    updateDocumentFields,
  ]);

  const workoutsByType = useMemo(() => {
    const initialObject = Object.keys(WorkoutFeedViewType).reduce((prev, type) => {
      const newObject = { ...prev };
      newObject[WorkoutFeedViewType[type]] = [];
      return newObject;
    }, {});

    return workoutList.reduce((prev, curr) => {
      const previous = { ...prev };
      previous[curr.type].push(curr);
      return previous;
    }, initialObject);
  }, [
    workoutList,
  ]);

  const workoutsByDayAndType = useMemo(() => {
    const initialObject = moment.weekdays().reduce((prev, current) => {
      const newObject = { ...prev };
      newObject[current] = {};
      Object.keys(WorkoutFeedViewType).forEach((type) => {
        newObject[current][WorkoutFeedViewType[type]] = [];
      });
      return newObject;
    }, {});

    return workoutList.reduce((prev, curr) => {
      const weekDay = moment.utc(curr.startDate.toDate()).format(DateFormat.WEEK_DAY_NAME);
      const previous = { ...prev };
      previous[weekDay][curr.type].push(curr);
      return previous;
    }, initialObject);
  }, [
    workoutList,
  ]);

  const completedWorkoutCount = (workouts) => workouts
    .filter((workout) => !!workout.isCompleted)
    .length;

  const percentageCompleted = useMemo(() => (
    completedWorkoutCount(workoutsByType[WorkoutFeedViewType.COACH_ASSIGNED_WORKOUT])
    / workoutsByType[WorkoutFeedViewType.COACH_ASSIGNED_WORKOUT].length
  ), [
    workoutsByType,
  ]);

  if (!isReady) {
    return (
      <LoaderWrapper>
        <Loading />
      </LoaderWrapper>
    );
  }

  return (
    <Container>
      <DataContainer>
        <ItemContainer>
          <StyledTitle>{texts.workouts}</StyledTitle>
          <CountLabel>{completedWorkoutCount(workoutsByType[WorkoutFeedViewType.COACH_ASSIGNED_WORKOUT])}</CountLabel>
          <Description>
            {format(texts.goal, {
              goalCount: workoutsByType[WorkoutFeedViewType.COACH_ASSIGNED_WORKOUT].length,
            })}
          </Description>
          <StyledWorkoutProgress
            type="determinate"
            value={percentageCompleted || 0}
          />
        </ItemContainer>
        <ItemContainer>
          <StyledTitle>{texts.customActivities}</StyledTitle>
          <CountLabel>{completedWorkoutCount(workoutsByType[WorkoutFeedViewType.CUSTOM_WORKOUT])}</CountLabel>
          <Description>{texts.logged}</Description>
        </ItemContainer>
      </DataContainer>
      <StyledCollapsiblePanel
        title={texts.workoutDetails}
      >
        <WorkoutDetails>
          {[...moment.weekdays().slice(checkInDay + ONE_DAY), ...moment.weekdays().slice(0, checkInDay + ONE_DAY)]
            .map((dayName, index) => {
              const dayString = index === 0
                ? startDate.format(DateFormat.DAY_WITH_SHORT_MONTH)
                : startDate.add(ONE_DAY, 'days').format(DateFormat.DAY_WITH_SHORT_MONTH);
              return (
                <WorkoutDayPanel
                  key={dayName}
                  dayName={dayName}
                  workoutList={workoutsByDayAndType[dayName]}
                  dayString={dayString}
                />
              );
            })}
        </WorkoutDetails>
      </StyledCollapsiblePanel>
    </Container>
  );
};

WorkoutStat.propTypes = {
  updateDocumentFields: PropTypes.func.isRequired,
};

export default compose(
  observer,
)(WorkoutStat);
