import moment from 'moment';
import React from 'react';

import { DateFormat } from '../../utils/date';

const initialState = {
  setCurrentSelectedDate: () => {},
  currentSelectedDate: moment.utc().format(DateFormat.DEFAULT_DATE_FORMAT),
};

const LogContext = React.createContext(initialState);

export default LogContext;
export {
  initialState,
};
