import styled from 'styled-components';

const StyledStandaloneWorkoutEditorDetails = styled.div`
  padding: 30px;
  height: 100%;
  overflow: auto;
`;

export {
  StyledStandaloneWorkoutEditorDetails,
};
