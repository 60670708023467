import React, {
  useContext,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import useMealServings from '../../../hooks/useMealServings';
import useMealPlanNavigation from '../../../hooks/useMealPlanNavigation';
import MealPlanContext from '../../../context/MealPlanContext';
import MealCard from './MealCard';

import {
  Container,
} from './styles';

const MealPlanBucket = ({
  bucket,
}) => {
  const {
    mealPlanAssignmentDoc,
  } = useContext(MealPlanContext);

  const {
    navigateToMealDetailsPage,
  } = useMealPlanNavigation();

  const { getMealServings } = useMealServings();

  const meals = useMemo(() => (
    mealPlanAssignmentDoc.getMealsByBucketName(bucket)
  ), [
    bucket,
    mealPlanAssignmentDoc,
  ]);

  return (
    <Container>
      {meals.map((meal, index) => {
        const {
          recipe: {
            id,
            name,
            image = '',
            protein,
            carbs,
            fat,
            servings: mealServings,
            isScalingDisabled,
            proteinPercentage,
            carbsPercentage,
            fatPercentage,
            totalCalories,
          },
        } = meal;
        const servings = isScalingDisabled ? mealServings : getMealServings(meal, bucket);
        const totalNutrients = {
          protein: protein * servings,
          carbs: carbs * servings,
          fat: fat * servings,
          calories: totalCalories * servings,
          percentProtein: proteinPercentage,
          percentCarbs: carbsPercentage,
          percentFat: fatPercentage,
        };
        return (
          <MealCard
            {...totalNutrients}
            key={id}
            name={name}
            imageSrc={image}
            onClick={() => navigateToMealDetailsPage(encodeURIComponent(bucket), index)}
          />
        );
      })}
    </Container>
  );
};

MealPlanBucket.propTypes = {
  bucket: PropTypes.string.isRequired,
};

export default compose(
  observer,
)(MealPlanBucket);
