const defaultHandActionArea = {
  xMin: 0,
  xMax: 0.2,
  yMin: 0.3,
  yMax: 0.8,
};

export {
  defaultHandActionArea,
};
