import styled, { css } from 'styled-components';

import { safeAreaFromBottom } from '../../../../../../styles/mixins';
import SlideDrawer from '../../../../../../components/SlideDrawer';

const Container = styled.div`
  font-family: 'Neue Montreal'; /* TODO: refactor this with mobile issue #1313 */
  padding-top: 30px;
  padding-bottom: calc(30px + ${safeAreaFromBottom()});
  height: 100%;
  overflow: auto;
  background-color: ${({ theme }) => theme.colors.system.background};
  pointer-events: all; /* Ionic disables this so that it can control gestures. We need to enable it on our component */
`;

const StyledSlideDrawer = styled(SlideDrawer)`
  background-color: ${({ theme }) => theme.colors.system.background};
  border-radius: 16px 16px 0 0;

  ${({ theme }) => (!theme.isPortrait && css`
    max-height: 100%;
    border-radius: 0;
  `)}
`;

export {
  Container,
  StyledSlideDrawer,
};
