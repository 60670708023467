import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  IconContainer,
  TextContainer,
} from './styles';

const Label = ({
  text,
  Icon,
  onClick,
}) => (
  <Container onClick={onClick}>
    {Icon && (
      <IconContainer>
        <Icon />
      </IconContainer>
    )}
    <TextContainer>{text}</TextContainer>
  </Container>
);

Label.propTypes = {
  text: PropTypes.string.isRequired,
  Icon: PropTypes.elementType,
  onClick: PropTypes.func,
};

Label.defaultProps = {
  Icon: null,
  onClick: () => {},
};

export default Label;
