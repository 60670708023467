import styled, { css, keyframes } from 'styled-components';
import ActivityRingsTheme from '../activityRingsTheme';

const Swatch = styled.div`
  position: relative;
`;

const centeredStyles = css`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
`;

const Svg = styled.svg`
  ${centeredStyles}
`;

const Ring = styled.div`
  ${centeredStyles}
  border-radius: 100%;
`;

const dotStyles = ({ isVisible }) => {
  const display = isVisible ? 'block' : 'none';

  return css`display: ${display};`;
};

const dotShadowStyles = ({ theme }) => {
  // Hack to make the tip of the progress ring to not look so ugly and bad on each theme
  const boxShadow = theme === ActivityRingsTheme.DARK
    ? '2px 0 1px rgb(0 0 0 / 40%)'
    : '1px 0 1px rgb(0 0 0 / 40%)';

  return css`
    box-shadow: ${boxShadow};
  `;
};

const Dot = styled.div`
  z-index: 2;
  position: absolute;
  height: 100%;
  width: 100%;
  transition: transform 4s;
  transform: rotate(0);

  &:after {
    content: "";
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    height: 12.8%;
    width: 12.8%;
    background: transparent;
    border-radius: 100%;

    ${(props) => dotShadowStyles(props)}
  }

  ${(props) => dotStyles(props)}
`;

const ringProgressAnimation = ({ circleLength }) => keyframes`
  0% {
    stroke-dasharray: 0 ${circleLength};
  }
`;

const progressRingStyles = (props) => {
  const animation = ringProgressAnimation(props);
  return css`
    animation: ${animation} 4s;
  `;
};

const ProgressRingCircle = styled.circle`
  transform: rotate(270deg);
  transform-origin: 50% 50%;
  ${(props) => progressRingStyles(props)}
`;

const BackgroundRingCircle = styled(ProgressRingCircle)`
  transition: none;
`;

export {
  Swatch,
  Svg,
  Ring,
  BackgroundRingCircle,
  ProgressRingCircle,
  Dot,
};
