import BaseDocument from './BaseDocument';

class AggregatedData extends BaseDocument {
  get completedWorkouts() {
    return this.data.completedWorkouts;
  }

  get totalExercises() {
    return this.data.totalExercises;
  }

  get referralsLeft() {
    return this.data.referralsLeft || 0;
  }
}

export default AggregatedData;
