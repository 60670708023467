const orderByName = (exercisesList = []) => (
  exercisesList.slice().sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    return 1;
  })
);

export {
  orderByName,
};
