const measurement = {
  STOMACH: 'stomach',
  HIP: 'hip',
  UPPER_ARM: 'upperArm',
  WAIST: 'waist',
  CHEST: 'chest',
  THIGH: 'thigh',
  GLUTES: 'glutes',
  WEIGHT: 'weight',
};

const weightMeasurements = [
  measurement.WEIGHT,
];

const circumferenceMeasurements = [
  measurement.STOMACH,
  measurement.HIP,
  measurement.UPPER_ARM,
  measurement.WAIST,
  measurement.CHEST,
  measurement.THIGH,
  measurement.GLUTES,
];

export {
  measurement,
  weightMeasurements,
  circumferenceMeasurements,
};
