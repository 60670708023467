import { Collection } from 'firestorter';

import { firestorePaths } from '../utils/firebasePaths';
import BaseDocument from './BaseDocument';

class Customer extends BaseDocument {
  get stripeCustomer() {
    return this.data.stripeCustomer;
  }

  static async getCustomerByUserId(userId) {
    if (!userId) {
      return null;
    }

    const stripeCustomerCollection = new Collection(() => firestorePaths.STRIPE_CUSTOMERS_COLLECTION, {
      query: (ref) => ref
        .where('user', '==', userId)
        .limit(1),
    });
    await stripeCustomerCollection.fetch();

    if (stripeCustomerCollection.docs.length === 1) {
      const customer = new Customer(stripeCustomerCollection.docs[0].ref);
      await customer.init();
      return customer;
    }
    return null;
  }
}

export default Customer;
