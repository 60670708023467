import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import WorkoutExecutionInfoContext from '../../../../context/WorkoutExecutionInfoContext';

import {
  Wrapper,
  SetsInfoLabel,
  SetsInfoValue,
} from './styles';

const CircuitActivityExtraContent = ({
  isHighlighted,
}) => {
  const {
    displayInfo: {
      circuitRound,
      circuitTotalRounds,
    },
  } = useContext(WorkoutExecutionInfoContext);

  const currentCircuitRoundOverTotal = (
    circuitTotalRounds > 1
      ? `${circuitRound}/${circuitTotalRounds}`
      : ''
  );

  const renderSetsInfo = () => (
    <>
      <SetsInfoLabel>Set</SetsInfoLabel>
      <SetsInfoValue>{currentCircuitRoundOverTotal}</SetsInfoValue>
    </>
  );

  return (
    <>
      {!!currentCircuitRoundOverTotal && (
        <Wrapper>
          {isHighlighted && renderSetsInfo()}
        </Wrapper>
      )}
    </>
  );
};

CircuitActivityExtraContent.propTypes = {
  isHighlighted: PropTypes.bool,
};

CircuitActivityExtraContent.defaultProps = {
  isHighlighted: false,
};

export default CircuitActivityExtraContent;
