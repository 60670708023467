import { FingerLookupIndices } from '../../models/Hand';

const color = '#C86432';

const drawPoint = (y, x, r, ctx) => {
  ctx.beginPath();
  ctx.arc(x * ctx.canvas.width - 2, y * ctx.canvas.height - 2, r, 0, 2 * Math.PI);
  ctx.fill();
};

const drawPath = (points, closePath, ctx) => {
  const region = new Path2D();
  region.moveTo(points[0][0] * ctx.canvas.width, points[0][1] * ctx.canvas.height);
  for (let i = 1; i < points.length; i++) {
    const point = points[i];
    region.lineTo(point[0] * ctx.canvas.width, point[1] * ctx.canvas.height);
  }

  if (closePath) {
    region.closePath();
  }
  ctx.stroke(region);
};

const drawHands = (ctx, hands) => {
  ctx.strokeStyle = color;
  ctx.fillStyle = color;

  hands.forEach((hand) => {
    const { keypoints } = hand;

    Object.values(keypoints).forEach((keypoint) => {
      const y = keypoint[0];
      const x = keypoint[1];
      drawPoint(x, y, 3, ctx);
    });

    const fingers = Object.keys(FingerLookupIndices);
    for (let i = 0; i < fingers.length; i++) {
      const finger = fingers[i];
      const points = FingerLookupIndices[finger].map((idx) => keypoints[idx]);
      drawPath(points, false, ctx);
    }
  });
};

export default drawHands;
