import styled from 'styled-components';

const StyledInfoPanel = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
`;

const InfoPanelContent = styled.div`
  flex: 1;
  overflow: auto;
`;

export {
  StyledInfoPanel,
  InfoPanelContent,
};
