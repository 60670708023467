import React, {
  useCallback,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import merge from 'lodash.merge';

import baseColors, {
  generateShadesColors,
} from '../../styles/baseColors';

const AppCustomThemeProvider = ({
  children,
}) => {
  const [colors, setColors] = useState(baseColors);

  const updateColorsTheme = useCallback((customColors = {}) => {
    // Pick the exact colors definitions that are allowed for overrides
    const {
      base = {},
      shades = {},
      system = {},
      fonts = {},
    } = customColors;

    const colorTheme = merge(
      {},
      baseColors,
      {
        base,
        system,
        shades,
        fonts,
      },
    );
    /*
      Generate new shades from the resulting base colors. Use already defined shades
      as fixed shades (2nd parameter)
    */
    const computedShades = generateShadesColors(colorTheme.base, shades);

    const colorThemeResult = {
      ...colorTheme,
      shades: computedShades,
    };

    setColors(colorThemeResult);
  }, []);

  const theme = useMemo(() => ({
    colors,
    updateColorsTheme,
  }), [
    colors,
    updateColorsTheme,
  ]);

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
};

AppCustomThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(AppCustomThemeProvider);
