import React, {
  useState,
  useEffect,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import LoadingPage from '../../../components/LoadingPage';
import UserContext from '../../UserContext';
import CameraContext from '../CameraContext';

/**
 * Renders the given component when the camera access (permission) is ready:
 * - It checks the system API to get the current state value (Android: denied by default,
 * iOS: prompt by default).
 * - It checks the UserConfig document to verify if the camera access was
 * granted/accepted by the user.
 * It updates the CameraContext state by setting "isCameraGranted" value.
 *
 * NOTE: This doesn't update the camera permission status based on permissions changes.
 * The update happens only when the UserConfig.isCameraAccessGranted changes.
 *
 * @requires UserContext
 * @param {Object} Component The component to render.
 * @param {Object} Component The rendered component.
 * @returns {Object} The component to render or the LoadingPage component.
 */
const CameraPermissionInitializator = ({
  children,
}) => {
  const { userConfigDoc } = useContext(UserContext);
  const {
    isCameraPermissionGranted,
    setIsCameraGranted,
  } = useContext(CameraContext);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    let shouldUpdate = true;

    const init = async () => {
      const isPermissionGranted = await isCameraPermissionGranted();

      if (shouldUpdate) {
        setIsCameraGranted(userConfigDoc.isCameraAccessGranted && isPermissionGranted);
        setIsReady(true);
      }
    };

    init();

    return () => {
      shouldUpdate = false;
    };
  }, [
    userConfigDoc.isCameraAccessGranted,
    isCameraPermissionGranted,
    setIsCameraGranted,
  ]);

  return isReady ? children : <LoadingPage />;
};

CameraPermissionInitializator.propTypes = {
  children: PropTypes.node.isRequired,
};

export default observer(CameraPermissionInitializator);
