import measure from 'measuring-cup';

// Parses the ingredient fields to a readable string
const getIngredientLine = ({
  amount,
  unit,
  secondAmount,
  secondUnit,
  name,
  preparationComment,
}) => {
  // amount is always required
  let result = `${amount}`;

  // unit is optional
  if (unit) {
    result = `${result} ${unit}`;
  }

  // secoundAmount and secondUnit are both optional
  if (secondAmount && secondUnit) {
    result = `${result} (${secondAmount} ${secondUnit})`;
  } else if (secondAmount) {
    result = `${result} (${secondAmount})`;
  } else if (secondUnit) {
    result = `${result} (${secondUnit})`;
  }

  // name is always required
  result = `${result} ${name}`;

  // preparationComment is optional
  if (preparationComment) {
    result = `${result}, ${preparationComment}`;
  }

  return result;
};

const proteinToCalories = (protein) => protein * 4;
const carbsToCalories = (carbs) => carbs * 4;
const fatToCalories = (fat) => fat * 9;

const caloriesToProtein = (calories) => calories / 4;
const caloriesToCarbs = (calories) => calories / 4;
const caloriesToFat = (calories) => calories / 9;

const UNIT = {
  GRAM: 'g',
  MILLILITER: 'ml',
  CLOVE: 'clove',
  DROP: 'drop',
  CUBE: 'cube',
  LEAF: 'leaf',
  CUP: 'cup',
  OUNCE: 'oz',
  TEASPOON: 'tsp',
  TABLESPOON: 'tbsp',
  POUND: 'lb',
  SCOOP: 'scoop',
  CAN: 'can',
  TIN: 'tin',
  PACK: 'pack',
  SPRIG: 'sprig',
  STALK: 'stalk',
  SLICE: 'slice',
};

const unitMetadata = {
  [UNIT.GRAM]: {
    names: ['g', 'gram', 'grams'],
    scales: [1],
  },
  [UNIT.MILLILITER]: {
    names: ['ml', 'milliliter', 'milliliters'],
    scales: [1],
  },
  [UNIT.CLOVE]: {
    names: ['clove', 'cloves'],
    scales: [1],
  },
  [UNIT.DROP]: {
    names: ['drop', 'drops'],
    scales: [1],
  },
  [UNIT.CUBE]: {
    names: ['cube', 'cubes'],
    scales: [1],
  },
  [UNIT.LEAF]: {
    names: ['leaf', 'leaves'],
    scales: [1],
  },
  [UNIT.CUP]: {
    names: ['cup', 'cups'],
    scales: [1 / 4, 1 / 3],
  },
  [UNIT.OUNCE]: {
    names: ['oz', 'ounce', 'ounces'],
    scales: [1 / 2],
  },
  [UNIT.TEASPOON]: {
    names: ['tsp', 'tsps', 'teaspoon', 'teaspoons'],
    scales: [1 / 8],
  },
  [UNIT.TABLESPOON]: {
    names: ['tbsp', 'tbsps', 'tablespoon', 'tablespoons'],
    scales: [1 / 2],
  },
  [UNIT.POUND]: {
    names: ['lb', 'lbs', 'pound', 'pounds'],
    scales: [1 / 4],
  },
  [UNIT.SCOOP]: {
    names: ['scoop', 'scoops'],
    scales: [1 / 4],
  },
  [UNIT.CAN]: {
    names: ['can', 'cans'],
    scales: [1 / 4, 1 / 3],
  },
  [UNIT.TIN]: {
    names: ['tin', 'tins'],
    scales: [1 / 4, 1 / 3],
  },
  [UNIT.PACK]: {
    names: ['pack', 'packs'],
    scales: [1 / 4, 1 / 3],
  },
  [UNIT.SPRIG]: {
    names: ['sprig', 'sprigs'],
    scales: [1 / 2],
  },
  [UNIT.STALK]: {
    names: ['stalk', 'stalks'],
    scales: [1 / 2],
  },
  [UNIT.SLICE]: {
    names: ['slice', 'slices'],
    scales: [1 / 2],
  },
};

const getUnitByName = (name = '') => {
  let unit = name;
  if (name) {
    const sanitizedString = name.trim().toLowerCase();
    unit = Object.keys(unitMetadata).find((key) => (
      unitMetadata[key].names.includes(sanitizedString)
    )) || [name];
  }
  return unit;
};

const OUNCES_CONVERSION_LOWER_LIMIT = 0.25;
const OUNCES_CONVERSION_UPPER_LIMIT = 2;

const convertUnitIfNeeded = (amount, unit) => {
  if ([UNIT.TEASPOON, UNIT.TABLESPOON, UNIT.CUP].includes(unit)) {
    // For tsp, tbsp and cups we convert to ounces and then back to one of the units
    const targetInOunces = measure(`${amount} ${unit}`).toOunces();
    if (targetInOunces < OUNCES_CONVERSION_LOWER_LIMIT) {
      return [measure(`${targetInOunces} ${UNIT.OUNCE}`).toTeaspoons(), UNIT.TEASPOON];
    }
    if (targetInOunces < OUNCES_CONVERSION_UPPER_LIMIT) {
      return [measure(`${targetInOunces} ${UNIT.OUNCE}`).toTablespoons(), UNIT.TABLESPOON];
    }
    return [measure(`${targetInOunces} ${UNIT.OUNCE}`).toCups(), UNIT.CUP];
  }
  if ([UNIT.OUNCE, UNIT.POUND].includes(unit)) {
    /*
      The measuring-cup library uses volume ounces, here we are reffering to
      weight ounces. We will convert units manually. Conversion: 16 Oz = 1 lb
    */
    if (unit === UNIT.OUNCE && amount >= 16) {
      return [amount / 16, UNIT.POUND];
    }
    if (unit === UNIT.POUND && amount < 0.25) {
      return [amount * 16, UNIT.OUNCE];
    }
  }
  return [amount, unit];
};

/*
  Takes a target and an array of scales that can be used to aproximate to the target.
  Returns the scaled amount.
*/
const scaleIngredient = (target, scales) => {
  let minDif = target;
  let scaledAmount = target;

  scales.forEach((scale) => {
    // Use the scale that aproximates the closest to the target
    const aproximate = Math.round(target / scale) * scale;
    const dif = Math.abs(target - aproximate);
    if (dif < minDif) {
      minDif = dif;
      scaledAmount = aproximate;
    }
  });

  return scaledAmount;
};

export {
  carbsToCalories,
  proteinToCalories,
  fatToCalories,
  caloriesToProtein,
  caloriesToCarbs,
  caloriesToFat,
  getIngredientLine,
  UNIT,
  unitMetadata,
  getUnitByName,
  convertUnitIfNeeded,
  scaleIngredient,
};
