import { Collection } from 'firestorter';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { firestorePaths } from '../utils/firebasePaths';
import BaseDocument from './BaseDocument';
import Workout from './Workout';
import GameplaySession from './GameplaySession';
import WorkoutDefinition from './WorkoutDefinition';

const workoutAssignmentStatuses = {
  COMPLETED: 'COMPLETED',
  ASSIGNED: 'ASSIGNED',
  PARTIALLY_COMPLETED: 'PARTIALLY_COMPLETED',
};

const completedWorkoutAssignmentStatuses = [
  workoutAssignmentStatuses.COMPLETED,
  workoutAssignmentStatuses.PARTIALLY_COMPLETED,
];

const isCompletedStatus = (status) => completedWorkoutAssignmentStatuses.includes(status);

const createWorkoutAssignmentId = (userName, workoutName, date) => {
  const formattedDate = moment.utc(moment(date).startOf('day')).format('DDMMMYY');
  const id = `${userName}-${formattedDate}-${workoutName}-${uuidv4()}`;

  return id.replace(/[^\w]/g, '-');
};

class WorkoutAssignment extends BaseDocument {
  async init(initOpts) {
    await super.init(initOpts);

    if (this.data.workoutContent) {
      this.workoutDefinition = new WorkoutDefinition(this.data.workoutContent);
    } else {
      // Fallback to workoutDefinition from Workout entity for backward compatibility
      const workoutDoc = new Workout(() => `workout/${this.data.workout}`);
      await workoutDoc.init();
      this.workoutDefinition = workoutDoc.workoutDefinition;
    }
  }

  get isCompleted() {
    return isCompletedStatus(this.data.status);
  }

  get name() {
    return this.workoutDefinition.name;
  }

  get user() {
    return this.data.user;
  }

  get coach() {
    return this.data.coach;
  }

  get assignedBy() {
    return this.data.assignedBy;
  }

  get startDate() {
    return this.data.startDate;
  }

  get endDate() {
    return this.data.endDate;
  }

  get originalWorkoutAssignment() {
    return this.data.originalWorkoutAssignment;
  }

  get workoutStartedDate() {
    return this.data.workoutStartedDate;
  }

  get status() {
    return this.data.status;
  }

  get workout() {
    return this.data.workout;
  }

  get completedBy() {
    return this.data.completedBy;
  }

  get latestWorkoutStartDate() {
    return this.workoutStartedDate || this.startDate;
  }

  async latestGameplaySessionCollection() {
    const collection = new Collection(firestorePaths.GAMEPLAY_SESSION_COLLECTION, {
      createDocument: (src, opts) => new GameplaySession(src, opts),
      query: (ref) => ref.where('user', '==', this.user)
        .where('workoutAssignment', '==', this.id)
        .orderBy('startTime', 'desc')
        .limit(1),
    });
    await collection.fetch();
    return collection;
  }

  /**
   * Update the workout assignment document state to completed.
   *
   * @param {Object} gameplaySessionDoc Updated GameplaySession Firestore Document.
   * @param {Date} lastUpdatedTimestamp Time of the update.
   * @param {boolean} force indicates that the completion was forced
   */
  complete = (gameplaySessionDoc, lastUpdatedTimestamp, force = false) => this.updateFields({
    status: force ? workoutAssignmentStatuses.PARTIALLY_COMPLETED : workoutAssignmentStatuses.COMPLETED,
    completedBy: gameplaySessionDoc.ref.path,
    lastUpdatedTimestamp,
  });
}

export default WorkoutAssignment;
export {
  workoutAssignmentStatuses,
  completedWorkoutAssignmentStatuses,
  createWorkoutAssignmentId,
};
