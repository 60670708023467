import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { IonContent } from '@ionic/react';

import logEvent from '../../utils/logger';
import CameraContext from '../../context/CameraContext';
import WorkoutStarter from './components/WorkoutStarter';
import GrantCameraAccessContainer from './components/GrantCameraAccess';
import {
  StyledIonPage,
} from './styles';

const Gameplay = () => {
  const {
    isCameraGranted,
    isCameraEnabled,
  } = useContext(CameraContext);

  useEffect(() => {
    logEvent('gameplayPageLoaded');
  }, []);

  /*
    isCameraEnabled starts with a truthy value, so the GrantCameraAccess component is rendered at the begining.
    If the client skips the Camera Access Prompt, then isCameraEnabled is set to false, thus rendering the
    WorkoutStarter, which will start the workout right away, without camera support.
  */
  return (
    <StyledIonPage id="gameplay-page">
      <IonContent>
        {(isCameraGranted || !isCameraEnabled)
          ? <WorkoutStarter />
          : <GrantCameraAccessContainer />}
      </IonContent>
    </StyledIonPage>
  );
};

export default compose(
  observer,
)(Gameplay);
