import React, {
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import LogContext, { initialState } from './LogContext';

const LogContextProvider = ({
  children,
}) => {
  const [currentSelectedDate, setCurrentSelectedDate] = useState(initialState.currentSelectedDate);

  const value = useMemo(() => ({
    setCurrentSelectedDate,
    currentSelectedDate,
  }), [
    setCurrentSelectedDate,
    currentSelectedDate,
  ]);

  return (
    <LogContext.Provider value={value}>
      {children}
    </LogContext.Provider>
  );
};

LogContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LogContextProvider;
