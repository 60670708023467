import styled from 'styled-components';

import Card from '../../../../components/Card';
import BaseMacroGoals from '../MacroGoals';

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
`;

const MacroGoals = styled(BaseMacroGoals)``;

export {
  StyledCard,
  MacroGoals,
};
