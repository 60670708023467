import React from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';

import text from './text.json';
import {
  CommentAlert,
} from './styles';

// This is the max number of characters allowed in a comment.
const COMMENT_MAX_LENGTH = 150;

const ClientCommentAlert = ({
  isOpen,
  label,
  comment,
  onCloseCommentAlert,
  onCommentChange,
}) => (
  <CommentAlert
    isOpen={isOpen}
    header={format(text.commentModal.client.message, { label })}
    buttons={[
      {
        text: text.commentModal.client.cancel,
        role: 'cancel',
        handler: onCloseCommentAlert,
      },
      {
        text: text.commentModal.client.accept,
        handler: onCommentChange,
      },
    ]}
    inputs={[
      {
        type: 'textarea',
        name: 'comment',
        value: comment,
        cssClass: 'commentTextArea',
        attributes: {
          maxLength: COMMENT_MAX_LENGTH,
        },
      },
    ]}
  />
);

ClientCommentAlert.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  onCloseCommentAlert: PropTypes.func.isRequired,
  onCommentChange: PropTypes.func.isRequired,
};

export default ClientCommentAlert;
