import React from 'react';
import PropTypes from 'prop-types';
import { IonSpinner } from '@ionic/react';

import LoadingPageTheme from './loadingPageTheme';
import LoadingWrapper from './styles';

const LoadingPage = ({
  fullHeight,
  theme,
  className,
}) => {
  const spinnerProps = {
    name: 'crescent',
  };

  /*
    NOTE: for now, let's just use the "theme" value only for spinner color. There are many instances
    of a LoadingPage in the same page, all of them should share the same configuration related to the page.
  */
  if (theme === LoadingPageTheme.DARK) {
    spinnerProps.color = 'light';
  }

  return (
    <LoadingWrapper className={className} $fullHeight={fullHeight}>
      <IonSpinner {...spinnerProps} />
    </LoadingWrapper>
  );
};

LoadingPage.propTypes = {
  fullHeight: PropTypes.bool,
  className: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(LoadingPageTheme)),
};

LoadingPage.defaultProps = {
  fullHeight: true,
  className: '',
  theme: LoadingPageTheme.LIGHT,
};

export default LoadingPage;
