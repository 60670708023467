import React, {
  useEffect,
  useContext,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { compose } from 'recompose';
import {
  useRouteMatch,
} from 'react-router-dom';
import { observer } from 'mobx-react';
import format from 'string-template';
import { IonAlert, IonLoading } from '@ionic/react';
import moment from 'moment';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { forEachSeries } from 'p-iteration';
import * as Sentry from '@sentry/browser';

import {
  withLayout,
  HeaderContext,
} from '../../components/Layout';
import { ReactComponent as CalendarIcon } from '../../assets/icons/icon-calendar.svg';
import CheckInContext from '../../context/CheckInContext';
import useStorage, { StorageProcessState } from '../../hooks/useStorage';
import { pathPlaceholder, storagePaths } from '../../utils/firebasePaths';
import UserContext from '../../context/UserContext';
import useUserNavigation from '../../hooks/useUserNavigation';
import WorkoutFeedView, { WorkoutFeedViewType } from '../../models/WorkoutFeedView';
import useCurrentLoggedInUser from '../../hooks/useCurrentLoggedInUser';
import { getYearAndWeekFromDate } from '../../utils/date';
import { fileExists } from '../../services/appCustomization/deviceStorage';
import useAppCustomization from '../../hooks/useAppCustomization';
import useNavContext from '../../hooks/useNavContext';
import { Feature } from '../../context/AppCustomizationContext';
import logEvent from '../../utils/logger';
import { CheckinImageType } from './CheckInImages/util';

import texts from './texts.json';
import {
  StyledContainer,
  StyledHeader,
  DateContainer,
  IconContainer,
  StyledStepper,
  DateText,
  StyledButton,
} from './styles';
import {
  checkInPageOrder,
  checkInPagesConfig,
  getCheckInWeekString,
} from './utils';

const FIRST_WEEK_OF_YEAR = 1;
const LAST_CHECK_IN_WEEK = 52;

const CheckIn = () => {
  const { params: { pageId } } = useRouteMatch();
  const { goBack } = useNavContext();
  const { setTitle, setBackHandler } = useContext(HeaderContext);
  const {
    userId,
    userDoc,
  } = useContext(UserContext);
  const {
    currentCheckInDoc,
    currentSelectedDate,
    userNutritionLogsCollection,
    setCurrentSelectedDate,
    getCurrentWeekDate,
    checkInDay,
    shouldUploadImage,
  } = useContext(CheckInContext);
  const [currentPage, setCurrentPage] = useState(pageId);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmissionComplete, setIsSubmissionComplete] = useState(false);
  const [message, setMessage] = useState();
  const [header, setHeader] = useState();
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);

  const {
    uploadAttachments,
  } = useStorage();
  const {
    navigateToUserPath,
  } = useUserNavigation();
  const {
    isCurrentLoggedInUserInPath,
  } = useCurrentLoggedInUser();
  const { isFeatureEnabled } = useAppCustomization();

  useEffect(() => {
    if (currentCheckInDoc.isEditing) {
      setBackHandler(() => () => setIsSaveModalOpen(true));
    }
    return () => setBackHandler(null);
  }, [
    setBackHandler,
    currentCheckInDoc.isEditing,
  ]);

  useEffect(() => setTitle(texts.title), [setTitle]);

  useEffect(() => {
    setCurrentPage(pageId);
  }, [
    pageId,
  ]);

  useEffect(() => {
    const markCheckInAsEditing = async () => {
      if (currentCheckInDoc) {
        const { year: checkInYear, week: checkInWeek } = currentCheckInDoc;
        const { year, week } = getYearAndWeekFromDate(getCurrentWeekDate(checkInDay));
        const weekOfPreviousCheckIn = week === FIRST_WEEK_OF_YEAR ? LAST_CHECK_IN_WEEK : week - 1;
        const yearOfPreviousCheckIn = week === FIRST_WEEK_OF_YEAR ? year - 1 : year;
        const isCurrentCheckInLatest = checkInYear === year && checkInWeek === week;
        const isCurrentCheckInPrevious = checkInYear === yearOfPreviousCheckIn && checkInWeek === weekOfPreviousCheckIn;
        const isCurrentCheckInSubmitted = currentCheckInDoc.isSubmitted;
        const isCheckInEditable = (isCurrentCheckInLatest || isCurrentCheckInPrevious) && !!isCurrentCheckInSubmitted;

        if (isCheckInEditable && isCurrentLoggedInUserInPath) {
          await currentCheckInDoc.updateFields({
            progress: 0,
            isEditing: true,
            editingStartedAt: new Date(),
          });
        }
      }
    };

    markCheckInAsEditing();
  }, [
    currentCheckInDoc,
    getCurrentWeekDate,
    checkInDay,
    isCurrentLoggedInUserInPath,
  ]);

  useEffect(() => () => setCurrentSelectedDate(getCurrentWeekDate(checkInDay)), [
    setCurrentSelectedDate,
    getCurrentWeekDate,
    checkInDay,
  ]);

  const isLastPage = useMemo(() => (
    checkInPageOrder.indexOf(currentPage) === checkInPageOrder.length - 1
  ), [
    currentPage,
  ]);

  const shouldAllowActions = useMemo(() => (
    isCurrentLoggedInUserInPath && (!currentCheckInDoc.isSubmitted || currentCheckInDoc.isEditing)
  ), [
    isCurrentLoggedInUserInPath,
    currentCheckInDoc.isSubmitted,
    currentCheckInDoc.isEditing,
  ]);

  const updateDocumentFields = useCallback(async (data, progressStage) => {
    if (shouldAllowActions) {
      const progress = (progressStage >= currentCheckInDoc.progress) ? progressStage : currentCheckInDoc.progress;
      const updateData = {
        lastUpdated: new Date(),
        progress,
        ...data,
      };
      await currentCheckInDoc.updateFields(updateData);
    }
  }, [
    shouldAllowActions,
    currentCheckInDoc,
  ]);

  const getImageFile = useCallback(async (ImagePath) => {
    const doesFileExist = await fileExists(ImagePath);
    if (doesFileExist) {
      const { data } = await Filesystem.readFile({
        path: ImagePath,
        directory: Directory.Data,
        encoding: Encoding.UTF8,
      });
      return data;
    }
    return '';
  }, []);

  const renderPage = useMemo(() => {
    const { component: CheckInPage } = checkInPagesConfig[currentPage];
    return (
      <CheckInPage
        currentCheckInDoc={currentCheckInDoc}
        updateDocumentFields={(data) => updateDocumentFields(data, checkInPageOrder.indexOf(currentPage) + 1)}
        currentSelectedDate={currentSelectedDate}
        shouldAllowActions={shouldAllowActions}
        getImageFile={getImageFile}
        userNutritionLogsCollection={userNutritionLogsCollection}
      />
    );
  }, [
    currentPage,
    currentCheckInDoc,
    userNutritionLogsCollection,
    updateDocumentFields,
    currentSelectedDate,
    shouldAllowActions,
    getImageFile,
  ]);

  const getStoragePath = useCallback((checkInDocId) => {
    const storagePath = format(storagePaths.CHECK_IN_DOC, {
      [pathPlaceholder.USER_ID]: userId,
      [pathPlaceholder.CHECK_IN_ID]: checkInDocId,
    });
    return storagePath;
  }, [
    userId,
  ]);

  const handleButtonClick = useCallback(async (forceSubmit = false) => {
    if (isLastPage || forceSubmit === true) {
      logEvent('CheckInSubmitting', { docId: currentCheckInDoc.id });
      setIsSubmitting(true);
      const storagePath = getStoragePath(currentCheckInDoc.id);

      const filesToUpload = [];
      const filesToUploadIndex = {};

      try {
        await forEachSeries(Object.keys(CheckinImageType), (async (type, index) => {
          const imageObject = localStorage.getItem(type);
          if (imageObject) {
            const parsedImageData = JSON.parse(imageObject);
            const { year, week } = getYearAndWeekFromDate(currentSelectedDate);
            if ((parsedImageData.year === year) && (parsedImageData.week === week)) {
              const imageUrl = await getImageFile(parsedImageData.dataUrl);
              if (imageUrl) {
                parsedImageData.dataUrl = imageUrl;
                filesToUpload.push(parsedImageData);
                filesToUploadIndex[filesToUpload.length - 1] = index;
              }
            }
          }
        }));
      } catch (error) {
        Sentry.captureException('Checkin error while parsing images to upload', {
          user: userId,
          error,
        });
      }

      const attachments = currentCheckInDoc.attachments.slice();
      if (filesToUpload.length === 0 && shouldUploadImage && attachments.length === 0) {
        setMessage(texts.noImages);
        setHeader(texts.warningLabel);
        setIsSubmissionComplete(true);
        setIsSubmitting(false);
        return;
      }

      // default to true since we don't want to block the user
      // if there are no images to upload
      let allUploadsSucceded = true;
      if (filesToUpload.length > 0) {
        const uploadResults = await uploadAttachments(
          filesToUpload,
          storagePath,
        );

        allUploadsSucceded = uploadResults.every((result, index) => {
          if (result.state === StorageProcessState.SUCCESS) {
            attachments[filesToUploadIndex[index]] = result.fileRef;
            return true;
          }
          return false;
        });
      }

      const filteredAttachments = Array.from(attachments, (attachment) => attachment || '');
      if (allUploadsSucceded) {
        await updateDocumentFields({
          attachments: filteredAttachments,
          isEditing: false,
          isSubmitted: true,
          submittedAt: new Date(),
          weekText: getCheckInWeekString(currentSelectedDate, checkInDay),
          checkInDay,
        });
        logEvent('CheckInSubmitted', { docId: currentCheckInDoc.id });

        const workoutFeedView = new WorkoutFeedView(currentCheckInDoc.id);
        const workoutFeedViewData = {
          startDate: moment().utc(true).startOf('day').toDate(),
          endDate: moment().utc(true).endOf('day').toDate(),
          type: WorkoutFeedViewType.CHECK_IN,
          user: userId,
          status: 'COMPLETED',
          title: texts.weeklyCheckIn,
          week: getCheckInWeekString(currentSelectedDate, checkInDay),
          weekDate: moment(currentSelectedDate).toDate(),
        };

        await workoutFeedView.set(workoutFeedViewData);

        // delete images from local storage after upload
        if (filesToUpload.length > 0) {
          await forEachSeries(Object.keys(CheckinImageType), (async (type) => {
            const imageData = localStorage.getItem(type);
            if (imageData) {
              const parsedImageData = JSON.parse(imageData);
              const doesFileExist = await fileExists(parsedImageData.dataUrl);
              if (doesFileExist) {
                await Filesystem.deleteFile({
                  path: parsedImageData.dataUrl,
                  directory: Directory.Data,
                });
              }
            }
          }));
        }

        const successMessage = isFeatureEnabled(Feature.CHECK_IN_NOTIFICATION)
          ? texts.successWithNotification
          : texts.successNoNotification;
        setMessage(successMessage);
        setHeader(texts.successLabel);
      } else {
        setMessage(texts.failure);
        setHeader(texts.errorLabel);
      }
      setIsSubmitting(false);
      setIsSubmissionComplete(true);
      setIsSaveModalOpen(false);
    } else {
      setCurrentPage((prevPage) => checkInPageOrder[checkInPageOrder.indexOf(prevPage) + 1]);
    }
  }, [
    isLastPage,
    userId,
    getStoragePath,
    currentCheckInDoc,
    updateDocumentFields,
    uploadAttachments,
    currentSelectedDate,
    getImageFile,
    checkInDay,
    shouldUploadImage,
    isFeatureEnabled,
  ]);

  const handleAlertButtonClick = useCallback(() => {
    if (message === texts.successWithNotification || message === texts.successNoNotification) {
      setIsSubmissionComplete(false);
      navigateToUserPath('home');
    } else {
      setIsSubmissionComplete(false);
    }
  }, [
    navigateToUserPath,
    message,
  ]);

  const getButtonText = () => {
    if (isLastPage) {
      if (userDoc.isCanceled || userDoc.isOnBreak || !isFeatureEnabled(Feature.CHECK_IN_NOTIFICATION)) {
        return texts.buttonLabel.save;
      }
      if (currentCheckInDoc.isEditing) {
        return texts.buttonLabel.resubmit;
      }
      return texts.buttonLabel.submit;
    }
    return texts.buttonLabel.next;
  };

  return (
    <StyledContainer>
      <StyledHeader>
        <DateContainer>
          <IconContainer>
            <CalendarIcon />
          </IconContainer>
          <DateText>{currentCheckInDoc?.weekText || getCheckInWeekString(currentSelectedDate, checkInDay)}</DateText>
        </DateContainer>
        <StyledStepper
          items={checkInPageOrder}
          activeIndex={checkInPageOrder.indexOf(currentPage)}
          onItemClick={(index) => setCurrentPage(checkInPageOrder[index])}
        />
      </StyledHeader>
      {renderPage}
      {!(isLastPage && currentCheckInDoc.isSubmitted && !currentCheckInDoc.isEditing) && (
        <StyledButton
          onClick={handleButtonClick}
        >
          {getButtonText()}
        </StyledButton>
      )}
      <IonLoading
        isOpen={isSubmitting}
        message={texts.submitting}
        spinner="crescent"
      />
      <IonAlert
        isOpen={isSubmissionComplete}
        message={message}
        header={header}
        backdropDismiss={false}
        buttons={
          [
            {
              text: texts.okay,
              handler: handleAlertButtonClick,
            },
          ]
        }
      />
      <IonAlert
        isOpen={isSaveModalOpen}
        message={texts.alertText}
        header={header}
        backdropDismiss={false}
        buttons={
          [
            {
              text: texts.discardChanges,
              handler: () => goBack(),
            },
            {
              text: texts.saveChanges,
              handler: () => handleButtonClick(true),
            },
          ]
        }
      />
    </StyledContainer>
  );
};

export default compose(
  withLayout,
  observer,
)(CheckIn);
