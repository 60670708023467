import React from 'react';

import UserRemoteLogging from './UserRemoteLogging';

const withUserRemoteLogging = (Component) => (props) => (
  <UserRemoteLogging>
    <Component {...props} />
  </UserRemoteLogging>
);

export default withUserRemoteLogging;
