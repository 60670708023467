import styled, { css } from 'styled-components';

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  line-height: 14px;
  margin-right: 20px;
  justify-content: center;
  align-items: center;

  &:last-child {
    margin-right: 0;
  }
`;

const InfoSeparator = styled.span`
  display: block;
  height: 83%;
  border-radius: 2px;
  width: 2px;
  margin-right: 6px;

  ${({ $color }) => css`background-color: ${$color};`}
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.base.primary};
`;

const SummaryDataContainer = styled.div`
  display: flex;
  /*
    NOTE: this padding left exists to compensate the alignment between the top header text and this container.
    It is required due to a font issue, so if in the future the font is changed, revisit this.
  */
  padding-left: 1px;
`;

export {
  SummaryContainer,
  InfoSeparator,
  InfoSection,
  SummaryDataContainer,
};
