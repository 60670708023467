const Category = {
  PLATFORM: 'PLATFORM',
  EXERCISE_TYPE: 'EXERCISE_TYPE',
  EQUIPMENT: 'EQUIPMENT',
  BODY_REGION_BASIC: 'BODY_REGION_BASIC',
  BODY_REGION_ADVANCED: 'BODY_REGION_ADVANCED',
};

const Platform = {
  CA: 'ca',
  S2: 's2',
};

export {
  Category,
  Platform,
};
