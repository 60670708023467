import styled, { css } from 'styled-components';
import { Form } from 'formik';
import { IonBackdrop } from '@ionic/react';

import { ReactComponent as UploadedFileDeleteIcon } from '../../assets/icons/uploaded-file-delete-icon.svg';
import { body1 } from '../../styles/text';
import FormikInput from '../../components/FormikInput';
import Card from '../../components/Card';
import FormikDatePicker from '../../components/FormikDatePicker';

const backgroundColorStyle = css`
  background-color: ${({ theme }) => theme.colors.system.alpha};
`;

const borderStyle = css`
  border: none;
  border-radius: 0;
  border-bottom-color: ${(props) => (
    !!props.error && !!props.touched
      ? ({ theme }) => theme.colors.base.primary
      : ({ theme }) => theme.colors.system.gamma2
  )};
  border-bottom-width: 1px;
  border-bottom-style: solid;
`;

const dataEntryElementStyle = css`
  ${borderStyle}
  ${backgroundColorStyle}
  ${body1}
  padding: 8px 5px;
  color: ${({ theme }) => theme.colors.base.primary};
`;

const StyledFormikInput = styled(FormikInput)`
  resize: none;
  input {
    ${dataEntryElementStyle}
    &:focus {
      ${borderStyle}
      ${backgroundColorStyle}
      border-bottom-color: ${({ theme }) => theme.colors.base.primary};
      outline: 0;
    }
  }
`;

const StyledFormikDatePicker = styled(FormikDatePicker)` 
  margin-bottom: 20px;
  padding-bottom: 5px;
  ${borderStyle}
`;

const SpacedCard = styled(Card)`
  display: flex;
  flex-direction: column;
  margin: 2px 0;
  height: fit-content;
  > textarea {
    ${dataEntryElementStyle}
     &::placeholder {
      opacity: 0.4;
    }
    &:focus {
      ${borderStyle}
      ${backgroundColorStyle}
      outline: 0;
      border-bottom-color: ${({ theme }) => theme.colors.base.primary};
    }
  }
`;

const FileUploaderContainerCard = styled(SpacedCard)`
  padding: 16px;
`;

const FooterContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
`;

const UploadButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const ContainerForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100%;
  > div {
    margin-top: 0;
    margin-bottom: 0;
  }
  > div:first-child {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  > div:last-child {
    margin-bottom: 0;
  }
`;

const FileDeleteIcon = styled(UploadedFileDeleteIcon)`
  align-self: flex-end;
  margin-bottom: 10px;
`;

const StyledIonBackdrop = styled(IonBackdrop)`
  opacity: 0.3;
`;

export {
  ContainerForm,
  FileDeleteIcon,
  FooterContainer,
  SpacedCard,
  StyledFormikDatePicker,
  StyledFormikInput,
  FileUploaderContainerCard,
  UploadButtonContainer,
  StyledIonBackdrop,
};
