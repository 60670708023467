import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CloseButton from '../../../components/CloseButton';
import Button from '../../../components/Button';
import colors from '../../../styles/baseColors';
import Input from '../Input';
import TextArea from '../TextArea';
import {
  Container,
  ActionsContainer,
  ModalHead,
  Form,
} from './styles';
import texts from './texts.json';

const WorkoutInfoModal = ({
  name: workoutName,
  note: workoutNote,
  onClose,
  onSave,
}) => {
  const [name, setName] = useState(workoutName);
  const [note, setNote] = useState(workoutNote);

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const onNoteChange = (event) => {
    setNote(event.target.value);
  };

  const onSaveHandler = async (event) => {
    event.preventDefault();
    onSave(name, note);
  };

  return (
    <Container>
      <ActionsContainer>
        <CloseButton onClick={onClose} color={colors.system.beta} />
        <ModalHead>{texts.workoutInfoTitle}</ModalHead>
      </ActionsContainer>
      <Form>
        <Input
          type="text"
          name={texts.name}
          onChange={onNameChange}
          value={name}
          label={texts.name}
          required
        />
        <TextArea
          name={texts.notes}
          onChange={onNoteChange}
          value={note}
          label={texts.notes}
        />
        <Button disabled={!name} onClick={onSaveHandler}>Save</Button>
      </Form>
    </Container>
  );
};

WorkoutInfoModal.propTypes = {
  name: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default WorkoutInfoModal;
