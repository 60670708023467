import React, { useCallback, useContext } from 'react';
import format from 'string-template';

import CoachInfoContext from '../../../../context/CoachInfoContext';
import useUserNavigation from '../../../../hooks/useUserNavigation';
import MessageCard, { MessageCardType } from '../MessageCard';
import texts from './texts.json';

const NoWorkoutsCard = () => {
  const { coachInfo } = useContext(CoachInfoContext);
  const { navigateToUserPath } = useUserNavigation();

  const onGoToWorkoutSelector = useCallback(() => (
    navigateToUserPath('workoutSelector')
  ), [
    navigateToUserPath,
  ]);

  return (
    <MessageCard
      message={format(texts.message, { coachName: coachInfo.coachAssistant?.name || coachInfo.name })}
      description={texts.description}
      type={MessageCardType.DEFAULT}
      onClick={onGoToWorkoutSelector}
    />
  );
};

export default NoWorkoutsCard;
