import React, {
  useState,
  useEffect,
  useContext,
} from 'react';
import * as Sentry from '@sentry/browser';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import UserNutritionInfoContext from '../../../../context/UserNutritionInfoContext';
import {
  Title,
  Paragraph,
} from '../commonStyles';
import {
  Wrapper,
} from './styles';
import texts from './texts.json';

const CustomizedNeeds = () => {
  const [restrictions, setRestrictions] = useState([]);

  const {
    userNutritionProfileDoc,
  } = useContext(UserNutritionInfoContext);

  useEffect(() => {
    const displayRestrictions = [];

    userNutritionProfileDoc.dietaryRestrictions.forEach((type) => {
      if (texts.dietaryRestrictions[type]) {
        displayRestrictions.push({
          key: type,
          ...texts.dietaryRestrictions[type],
        });
      } else {
        Sentry.captureMessage(`There's no title and description to display for dietary restriction type "${type}"`);
      }
    });

    setRestrictions(displayRestrictions);
  }, [
    userNutritionProfileDoc.dietaryRestrictions,
  ]);

  return (
    <>
      {restrictions.map(({ title, description, key }) => (
        <Wrapper key={key}>
          <Title>{title}</Title>
          <Paragraph>{description}</Paragraph>
        </Wrapper>
      ))}
    </>
  );
};

export default compose(
  observer,
)(CustomizedNeeds);
