/*
 * Custom colors definition for Invoice status tags.
 */

const colors = {
  paidInvoiceTag: {
    tagBackground: '#10A643',
    tagBorder: '#017E2B',
  },
};

export default colors;
