import React from 'react';
import {
  Redirect,
  useLocation,
} from 'react-router-dom';

import useHomeNavigation from '../../hooks/useHomeNavigation';
import useCurrentLoggedInUser from '../../hooks/useCurrentLoggedInUser';
import useSessionStore from '../../hooks/useSessionStore';
import logEvent from '../../utils/logger';

/**
 * High Order Component (HOC) that renders the given component only for the current user or admin/coach
 *
 * @requires sessionStore To access authorized user id
 *
 * @param {Object} [options] Additional options to configure the HOC
 * @param {boolean} [options.forceSameUser=false] Forces to render the provided component only if the logged in user
 *                                             is actually the user specified in the route.
 */
const withCurrentUserAccess = (options = { forceSameUser: false }) => (Component) => (
  (props) => {
    const { search, state = {} } = useLocation();
    const { pathname: homePathName } = useHomeNavigation();
    const { isCurrentLoggedInUserInPath } = useCurrentLoggedInUser();
    const { userClaims } = useSessionStore();
    const { forceSameUser } = options;

    let isCoachOrAdmin = false;
    if (userClaims) {
      const { coach, admin } = userClaims;
      isCoachOrAdmin = coach || admin;
    } else {
      logEvent('userClaims are not set correctly');
    }

    if (!isCurrentLoggedInUserInPath && (!isCoachOrAdmin || forceSameUser)) {
      // Redirecting to home page if the user doesn't have access to this route
      return <Redirect to={{ pathname: homePathName, search, state }} />;
    }

    return <Component {...props} />;
  });

export default withCurrentUserAccess;
