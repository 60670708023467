import { ActivityTypes } from '../../models/BaseActivity';
import activityStatus from './activityStatus';

/**
 * @param {Object} activityState
 * @returns {boolean} True when it should be added to the history, otherwise false.
 */
const shouldAddToHistory = (activityState) => (
  activityState === activityStatus.RUNNING
);

/**
 * Certain type of activities don't need to record anything.
 */
const shouldNotAddToHistory = () => false;

/**
 * Get the workout history validator function by ActivityTYpe.
 *
 * @param {string} type Activity Type
 * @returns {Function} The validator function that checks if the result should
 * be added as part of the history.
 */
const getValidatorByActivityType = (type) => {
  if (type === ActivityTypes.REST) {
    return shouldNotAddToHistory;
  }
  // By default
  return shouldAddToHistory;
};

export default getValidatorByActivityType;
