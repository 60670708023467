import styled from 'styled-components';

import { sansSerif } from '../../styles/text';
import { ReactComponent as OverrideIcon } from '../../assets/icons/icon_override.svg';

const Container = styled.div``;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 0 8px 0;
  align-items: center;
`;

const Title = styled.div`
  ${sansSerif.headlineFontStyles}
  padding: 0 0 0 8px;
  color: rgba(20, 34, 47, 0.4);
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
`;

const StyledOverrideIcon = styled(OverrideIcon)`
  path {
    fill: rgba(20, 34, 47, 0.4);
  }
`;

export {
  Container,
  HeaderSection,
  Title,
  StyledOverrideIcon,
};
