import { useContext } from 'react';

import FirebaseContext from '../context/FirebaseContext';
import config from '../config';

const SIGN_IN_LINK_EMAIL_ACCOUNT_KEY = 'signInEmail';

/**
 * @typedef {string} AuthErrorCode
 */
const AuthErrorCode = {
  INVALID_EMAIL: 'auth/invalid-email',
  EMAIL_ALREADY_IN_USE: 'auth/email-already-in-use',
  REQUIRES_RECENT_LOGIN: 'auth/requires-recent-login',
  USER_NOT_FOUND: 'auth/user-not-found',
};

/**
 * @typedef {string} SignInMethod
 */
const SignInMethod = {
  NO_ACCOUNT: 'noAccount',
  EMAIL_PASSWORD: 'password',
  EMAIL_LINK: 'link',
};

const { appUrl } = config;

const useAuthentication = (sessionStore) => {
  const { firebase: { auth } } = useContext(FirebaseContext);

  /**
   * Given an e-mail address it returns the sign in method for this e-mail.
   * @param {string} email The e-mail address to query about
   * @returns {Promise<SignInMethod>} A promise that would resolve to the preferred sign in method for the provided
   * email address.
   */
  const getSignInMethod = async (email) => {
    const signInMethods = await auth.fetchSignInMethodsForEmail(email);
    if (signInMethods.length === 0) {
      return SignInMethod.NO_ACCOUNT;
    }
    return signInMethods.includes(SignInMethod.EMAIL_PASSWORD)
      ? SignInMethod.EMAIL_PASSWORD
      : SignInMethod.EMAIL_LINK;
  };

  /**
   * Sends an authentication e-mail to the user to be able to access to the app at the
   * specificed destinationPath. It also stores an item in local storage with the given email to simplify the
   * re-authentication. (If that is not provided we need to ask the e-mail explicitly)
   * @param {string} email The email address we want the authorization e-mail to be sent to
   * @param {string} destinationPath The destination path we should forward the user to
   */
  const sendAuthenticationEmail = async (email, destinationPath = '/home') => {
    await auth.sendSignInLinkToEmail(email, {
      url: `${appUrl}/authLink?destination=${destinationPath}`,
      handleCodeInApp: true,
    });
  };

  /**
   * Creates a new anonymous account and assigns the given e-mail address to it.
   * It also created a new user record in the database.
   *
   * @param {string} email The e-mail address to associate
   * @returns A promise that is resolved once the user gets created and e-mail is successfully associated.
   * The promise can be rejected with the following codes:
   * https://firebase.google.com/docs/reference/js/firebase.User#error-codes_13
   */
  const createAnonymousAccountWithEmail = async (email) => {
    const { user } = await auth.signInAnonymously();
    try {
      await user.updateEmail(email);
    } catch (err) {
      if (err.code === AuthErrorCode.EMAIL_ALREADY_IN_USE) {
        await user.delete();
        throw err;
      }
    }
    sessionStore.changeUser(user);
    return user;
  };

  /**
   * Sends a password reset e-mail to the provided email address
   * @param {string} email The e-mail address to send the e-mail to
   */
  const sendPasswordResetEmail = async (email) => {
    await auth.sendPasswordResetEmail(email);
  };

  /**
   * Signs a user in using e-mail and password
   * @param {string} email The user email
   * @param {string} password The user password
   */
  const signInWithEmailAndPassword = async (email, password) => {
    await auth.signInWithEmailAndPassword(email, password);
  };

  /**
   * Signs the user in using a custom token
   * @param {string} token Custom Token
   */
  const signInWithCustomToken = async (token) => {
    await auth.signInWithCustomToken(token);
  };

  return {
    getSignInMethod,
    sendAuthenticationEmail,
    createAnonymousAccountWithEmail,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithCustomToken,
  };
};

export default useAuthentication;

export {
  SignInMethod,
  AuthErrorCode,
  SIGN_IN_LINK_EMAIL_ACCOUNT_KEY,
};
