import { ActivitySideType } from '../../utils/activities';

import AlternateSidesBigIcon from '../Icons/AlternateSidesBigIcon';
import LeftRightSideBigIcon from '../Icons/LeftRightSideBigIcon';
import { ReactComponent as AlternateSidesSmallIcon } from '../../assets/icons/alternate-sides-icon.svg';
import { ReactComponent as LeftRightSideSmallIcon } from '../../assets/icons/left-right-side-icon.svg';
import texts from './texts.json';
import {
  LeftSideBigIcon,
  RightSideBigIcon,
  LeftSideSmallIcon,
  RightSideSmallIcon,
} from './styles';

const ActivitySideIndicatorConfig = {
  [ActivitySideType.ALTERNATE_SIDES]: {
    text: texts.alternateSides,
    icons: {
      big: AlternateSidesBigIcon,
      small: AlternateSidesSmallIcon,
    },
  },
  [ActivitySideType.LEFT_SIDE]: {
    text: texts.leftSide,
    icons: {
      big: LeftSideBigIcon,
      small: LeftSideSmallIcon,
    },
  },
  [ActivitySideType.RIGHT_SIDE]: {
    text: texts.rightSide,
    icons: {
      big: RightSideBigIcon,
      small: RightSideSmallIcon,
    },
  },
  [ActivitySideType.LEFT_THEN_RIGHT_SIDE]: {
    text: texts.leftThenRight,
    icons: {
      big: LeftRightSideBigIcon,
      small: LeftRightSideSmallIcon,
    },
  },
};

export default ActivitySideIndicatorConfig;
