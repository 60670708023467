import {
  useMemo,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import { NativeAudio } from '@capacitor-community/native-audio';

import AppContext from '../../context/AppContext';

const REGEX_MULTIPLE_FORWARD_SLASHES = /([^:]\/)\/+/;
const REGEX_START_AS_URL = /^https?:\/{2}/;
const REGEX_START_AS_FILE_URI = /^(file|content):\/{2}/;

const IONIC_BUILT_SNAPSHOT_FOLDER_NAME = 'ionic_built_snapshots';

/**
 * Custom hook that allows play sound using the @capacitor-community/native-audio plugin.
 * NOTE: This hook implements a similar interface than use-sound library https://github.com/joshwcomeau/use-sound.
 * So based on the current platform, one or another hook can be used.
 */
const useNativeSound = (audioAsset) => {
  const {
    appVersion: {
      liveUpdateVersionId,
    },
  } = useContext(AppContext);

  useEffect(() => {
    const setup = async () => {
      let assetPath;
      let isUri = false;

      if (REGEX_START_AS_URL.test(audioAsset)) {
        // Audio asset is an HTTP URL
        assetPath = audioAsset;
      } else if (REGEX_START_AS_FILE_URI.test(audioAsset)) {
        // Audio asset is a file URI
        assetPath = audioAsset;
        isUri = true;
      } else if (liveUpdateVersionId) {
        // Audio asset is a relative path and a live update build is being used
        // Get the ionic data directory folder to calculate the file path location
        const { dataDirectory: dataDirectoryUri } = await window.IonicCordova.getAppDetails();

        const filePath = `${IONIC_BUILT_SNAPSHOT_FOLDER_NAME}/${liveUpdateVersionId}/${audioAsset}`
          .replace(REGEX_MULTIPLE_FORWARD_SLASHES, '$1');

        // dataDirectoryUri already has a forward slash
        assetPath = `${dataDirectoryUri}${filePath}`;
        isUri = true;
      } else {
        // Audio asset is a relative path
        assetPath = `public/${audioAsset}`.replace(REGEX_MULTIPLE_FORWARD_SLASHES, '$1');
      }

      NativeAudio.preload({
        assetId: audioAsset,
        assetPath,
        /*
          NOTE: The plugin handle 3 types of asset path: file URI, relative path and streams (HTTP URLs).
          The later was an addition done in the custom fork with the goal of modifying the minimum as possible
          of the plugin, so the meaning of "isUrl" remains the same according to the original plugin author.
          "isUrl" indicates if the given assetPath is a file URI. In case of a real URL, this parameter is ignored.
        */
        isUrl: isUri,
      });
    };

    setup();

    return () => {
      NativeAudio.unload({
        assetId: audioAsset,
      });
    };
  }, [
    audioAsset,
    liveUpdateVersionId,
  ]);

  const play = useCallback(() => (
    NativeAudio.play({
      assetId: audioAsset,
    })
  ), [audioAsset]);

  const pause = useCallback(() => (
    NativeAudio.pause({
      assetId: audioAsset,
    })
  ), [audioAsset]);

  const stop = useCallback(() => (
    NativeAudio.stop({
      assetId: audioAsset,
    })
  ), [audioAsset]);

  return useMemo(() => ([
    play,
    {
      pause,
      stop,
    },
  ]), [
    play,
    pause,
    stop,
  ]);
};

export default useNativeSound;
