const activityStatus = {
  IDLE: 'IDLE',
  PREPARING: 'PREPARING',
  READY: 'READY',
  RUNNING: 'RUNNING',
  PAUSED: 'PAUSED',
  RESUMING: 'RESUMING',
  FINISHING: 'FINISHING',
  FINISHED: 'FINISHED',
  QUITTING: 'QUITTING',
  QUITTED: 'QUITTED',
};

const activeStatus = [
  activityStatus.READY,
  activityStatus.RUNNING,
  activityStatus.PAUSED,
  activityStatus.RESUMING,
  activityStatus.FINISHING,
  activityStatus.FINISHED,
];

const isActiveStatus = (status) => activeStatus.includes(status);

const activityStatusValues = Object.values(activityStatus);

export default activityStatus;
export {
  isActiveStatus,
  activityStatusValues,
};
