import React from 'react';
import Layout from './Layout';

/**
 * High Order Component (HOC) that renders the given component with the
 * default application Layout.
 *
 * @param {Object} Component React Component to place as page content.
 */
const withLayout = (Component) => (props) => (
  <Layout>
    <Component {...props} />
  </Layout>
);

/**
 * High Order Component (HOC) that renders the given component with the
 * default application Layout enabling the consumers to specify some
 * configurations, like enable/disable paddings.
 *
 * @param {Object} options Options to customize the layout component.
 */
const withCustomizedLayout = ({
  enablePadding = false,
  addChat = true,
}) => (Component) => (props) => (
  <Layout
    enablePadding={enablePadding}
    addChat={addChat}
  >
    <Component {...props} />
  </Layout>
);

export default withLayout;
export {
  withCustomizedLayout,
};
