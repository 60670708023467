import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

const VideoPlayerContext = React.createContext(null);

const VideoPlayerContextProvider = ({
  children,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [player, setPlayer] = useState(null);

  const context = useMemo(() => ({
    isLoading,
    isPlaying,
    player,
    setIsLoading,
    setIsPlaying,
    setPlayer,
  }), [
    isLoading,
    isPlaying,
    player,
    setIsLoading,
    setIsPlaying,
    setPlayer,
  ]);

  return (
    <VideoPlayerContext.Provider value={context}>
      {children}
    </VideoPlayerContext.Provider>
  );
};

VideoPlayerContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const withVideoPlayerContextProvider = (Component) => (props) => (
  <VideoPlayerContextProvider>
    <Component {...props} />
  </VideoPlayerContextProvider>
);

export default VideoPlayerContext;
export {
  VideoPlayerContextProvider,
  withVideoPlayerContextProvider,
};
