import React from 'react';

import Button from './Button';
import variants from './variants';

const SemiTransparentButton = (props) => (
  <Button
    full={false}
    {...props}
    variant={variants.SEMI_TRANSPARENT}
  />
);

export default SemiTransparentButton;
