import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import logEvent from '../../utils/logger';
import { withVideoPlayerContextProvider } from '../VideoPlayer';
import VideoPlaylistContext from './VideoPlaylistContext';
import { StyledVideoPlayer } from './styles';

const PlaylistVideoPlayer = ({
  video,
  showSpinnerOverlay,
  onEnded,
}) => {
  const {
    addPlayerToVideo,
  } = useContext(VideoPlaylistContext);

  const onVideoReady = useCallback((player) => {
    addPlayerToVideo(video.archiveId, player);
  }, [
    video,
    addPlayerToVideo,
  ]);

  const onVideoError = useCallback((event, player) => {
    /*
      The video cannot be reproduced, but the player exists. So call "addPlayerToVideo", since "onVideoReady"
      won't be called. The underline implementation will set the player instance to the video only if
      the video doesn't have a player instance already.
      NOTE: "event" doesn't contain any valuable information about the error, more than type="error".
    */
    addPlayerToVideo(video.archiveId, player);

    logEvent('errorLoadingWorkoutVideoFromPlaylist', {
      archiveId: video.archiveId,
    });
  }, [
    video,
    addPlayerToVideo,
  ]);

  return (
    <StyledVideoPlayer
      url={video.url}
      onVideoReady={onVideoReady}
      onVideoError={onVideoError}
      showSpinnerOverlay={showSpinnerOverlay}
      onEnded={onEnded}
      playsinline
      controls
    />
  );
};

PlaylistVideoPlayer.propTypes = {
  video: PropTypes.object.isRequired,
  showSpinnerOverlay: PropTypes.bool.isRequired,
  onEnded: PropTypes.func.isRequired,
};

export default compose(
  withVideoPlayerContextProvider,
)(PlaylistVideoPlayer);
