import * as Sentry from '@sentry/browser';
import { useContext, useCallback } from 'react';

import FirebaseContext from '../context/FirebaseContext';
import UserContext from '../context/UserContext';
import logEvent from '../utils/logger';

/**
 * Hook that allows clients to create new OpenTok sessions and tokens to stream/publish videos.
 */
const useVideoStream = () => {
  const { firebase: { remote } } = useContext(FirebaseContext);
  const { userDoc } = useContext(UserContext);

  /**
   * Create a new OpenTok session ID and token for the given Gameplay Session by calling a cloud function.
   *
   * @param {string} gameplaySessionId The gameplay session ID.
   * @returns {Promise<Object>} An object with the sessionId and token.
   */
  const createSession = useCallback(async (gameplaySessionId) => {
    logEvent('openTokCreateSessionCalled', {
      gameplaySessionId,
    });

    let response = {};

    try {
      response = await remote('vonageVideoStreaming/createSession', {
        gameplaySessionId,
      });
    } catch (error) {
      const description = `Error while calling "vonageVideoStreaming/createSession" to create
      a new OpenTok Session ID and token`;

      Sentry.captureException(error, {
        extra: {
          description,
          gameplaySessionId,
        },
      });
    }

    if (response.ok) {
      logEvent('openTokSessionTokenCreated', {
        gameplaySessionId,
      });

      const { sessionId, token } = await response.json();
      return {
        sessionId,
        token,
      };
    }

    logEvent('noOpenTokSessionTokenCreated', {
      gameplaySessionId,
    });

    // Report this scenario to Sentry
    const error = new Error(
      'OpenTok session/token not created using "vonageVideoStreaming/createSession" cloud function',
    );
    Sentry.captureException(error, {
      extra: {
        responseStatus: response.status,
        responseStatusText: response.statusText,
        gameplaySessionId,
      },
    });

    return null;
  }, [remote]);

  /**
   * Creates a new token for the given OpenTok session ID by calling a cloud function.
   *
   * @param {string} sessionId The OpenTok session ID.
   * @return {Promise<string>} The token for the given session ID.
   */
  const createToken = useCallback(async (sessionId) => {
    logEvent('openTokCreateTokenCalled', {
      sessionId,
    });

    let response = {};

    try {
      response = await remote('vonageVideoStreaming/createToken', {
        sessionId,
      });
    } catch (error) {
      const description = `Error while calling "vonageVideoStreaming/createToken" to create
      a new token for an existent OpenTok Session ID`;

      Sentry.captureException(error, {
        extra: {
          description,
          sessionId,
        },
      });
    }

    if (response.ok) {
      logEvent('openTokTokenCreated');

      const { token } = await response.json();
      return token;
    }

    logEvent('noOpenTokTokenCreated');
    Sentry.captureException(
      new Error('OpenToken tokennot created when calling "vonageVideoStreaming/createToken" cloud function'),
      {
        extra: {
          responseStatus: response.status,
          responseStatusText: response.statusText,
          sessionId,
        },
      },
    );

    return null;
  }, [remote]);

  /**
   * Prepares the OpenTok Session ID and token for a new GameplaySession document.
   *
   * NOTE: the implementation doesn't call to gameplaySessionDoc.init() due to some permissions errors
   * due to probably a race condition between a recently created document and permissions checks.
   *
   * @param {Object} gameplaySessionDoc The GameplaySession instance.
   * @returns {Promise<Object|null>} An object with the sessionId and token, or null when it was not possible
   * to get an OpenTok session ID and token.
   */
  const prepareSessionForNewGameplaySession = useCallback(async (gameplaySessionDoc) => {
    const gameplaySessionId = gameplaySessionDoc.id;
    const eventProps = {
      gameplaySessionId,
    };

    logEvent('prepareOpenTokSessionCalled', eventProps);

    const lastSession = await userDoc.getLastOpenTokSession();

    if (lastSession) {
      logEvent('prepareOpenTokSessionUsingLastSessionId', eventProps);

      // update the sessionId in the gameplay session
      gameplaySessionDoc.assignVideoStreamingSessionId(lastSession.sessionId);

      logEvent('openTokSessionPreparedWithExistentSession', eventProps);

      return lastSession;
    }

    /*
      Create a new session ID and token when the user doesn't have a Session ID available
      to use within the pool of session IDs.
    */
    const result = await createSession(gameplaySessionId);
    if (result) {
      logEvent('openTokSessionPreparedWithNewSessionId', eventProps);

      const { sessionId, token } = result;
      return {
        sessionId,
        token,
      };
    }

    logEvent('noOpenTokSessionTokenCreatedForNewGameplaySession', eventProps);

    return null;
  }, [
    userDoc,
    createSession,
  ]);

  const prepareSessionForGameplaySession = useCallback(async (gameplaySessionDoc, isNewGameplaySession) => {
    const eventProps = {
      gameplaySessionId: gameplaySessionDoc.id,
    };

    logEvent('prepareSessionForGameplaySessionCalled', eventProps);

    // Check if the GameplaySession is a new one to get a new SessionID/token for it
    if (isNewGameplaySession) {
      return prepareSessionForNewGameplaySession(gameplaySessionDoc);
    }

    await gameplaySessionDoc.init();

    // Check if a session ID already exists, meaning the user is resuming an existent GameplaySession
    const { openTokSessionId } = gameplaySessionDoc.data;

    if (openTokSessionId) {
      const token = await createToken(openTokSessionId);

      logEvent('openTokSessionForGameplaySessionReady', eventProps);

      return {
        sessionId: openTokSessionId,
        token,
      };
    }

    /*
      At this point, the user doesn't have a pool of sessions IDs and the gameplay session already
      exists without an available session ID, let's try as a fallback to create one for it
    */
    const result = await createSession(gameplaySessionDoc.id);
    if (result) {
      logEvent('newCreatedOpenTokSessionForGameplaySessionReady', eventProps);

      const { sessionId, token } = result;
      return {
        sessionId,
        token,
      };
    }

    // At this point, there was no way to generate session information
    logEvent('noOpenTokSessionTokenCreatedForGameplaySession', eventProps);

    return null;
  }, [
    createSession,
    createToken,
    prepareSessionForNewGameplaySession,
  ]);

  return {
    createSession,
    createToken,
    prepareSessionForGameplaySession,
  };
};

export default useVideoStream;
