import styled, { css } from 'styled-components';

import PageContent from '../../../../components/PageContent';
import { safeAreaFromLeft, safeAreaFromTop } from '../../../../styles/mixins';

const InfoContainer = styled.div`
  display: flex;
  min-width: calc(250px + ${safeAreaFromLeft()});
  padding-left: ${safeAreaFromLeft()};
  flex: 1;
  background-color: ${({ theme }) => theme.colors.system.alpha};
  overflow: hidden;
  margin: 0 auto;

  ${({ theme }) => theme.isPortrait && css`
    margin: 0;
    min-height: calc(250px + ${safeAreaFromTop()});
    width: 100%;
  `}
`;

const CameraViewport = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  height: 100vh;
  background-color: transparent;
  background: transparent;

  ${({ $aspectRatio }) => css`
    width: calc(${$aspectRatio} * 100vh);
  `}

  ${({ $aspectRatio, theme }) => theme.isPortrait && css`
    width: 100%;
    height: calc(${$aspectRatio} * 100vw);
    overflow: hidden;

    video {
      width: 100%;
    }
  `}
`;

const Container = styled(PageContent)`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-height: 100vh;
  background-color: transparent;
  background: transparent;
  color: white;
  padding: 0;

  ${({ $enableBackground = false }) => $enableBackground && css`
    background: ${({ theme }) => theme.colors.system.alpha};
  `}

  ${({ theme }) => theme.isPortrait && css`
    flex-direction: column;
    padding-top: ${safeAreaFromTop()};
  `}
`;

export {
  Container,
  InfoContainer,
  CameraViewport,
};
