import React, {
  useEffect,
  useContext,
  useState,
  useCallback,
} from 'react';
import { compose } from 'recompose';
import * as Sentry from '@sentry/browser';
import {
  useLocation,
} from 'react-router-dom';

import FirebaseContext from '../../../../context/FirebaseContext';
import UserContext from '../../../../context/UserContext';
import { TERRA_PROVIDERS } from '../../../../models/UserTerraIntegration';

import {
  withLayout,
} from '../../../Layout';

import {
  Container,
  StyledCronometerIcon,
} from './styles';
import texts from './texts.json';

const CronometerCallback = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);

  const { search } = useLocation();

  const { userId } = useContext(UserContext);
  const {
    firebase: {
      remote,
    },
  } = useContext(FirebaseContext);

  /*
   * Unlinks the cronometer integration access authorized through terra (if any).
   */
  const unLinkFromTerra = useCallback(async () => {
    try {
      const response = await remote('terraUnlink', {
        provider: TERRA_PROVIDERS.CRONOMETER,
      });
      const { success } = await response.json();
      return success;
    } catch (error) {
      throw new Error(`Error unlinking cronometer from terra. ${error}`);
    }
  }, [remote]);

  /*
   * Links the cronometer account through the cronometer API endpoint.
   */
  const linkCronometerUser = useCallback(async () => {
    const urlParams = new URLSearchParams(search);
    const code = urlParams.get('code');

    if (code) {
      try {
        const response = await remote('linkCronometerUser', {
          code,
        });
        // No need to log errors to sentry here, we will do it on the server side.
        const { success } = await response.json();
        return success;
      } catch (error) {
        throw new Error(`Error linking cronometer user. ${error}`);
      }
    } else {
      throw new Error('Missing authorization code on Cronometer callback');
    }
  }, [
    search,
    remote,
  ]);

  useEffect(() => {
    const run = async () => {
      setIsLoading(true);
      try {
        const unlinkSuccess = await unLinkFromTerra();
        if (!unlinkSuccess) {
          throw new Error('Could not unlink cronometer from terra');
        }
        const linkSuccess = await linkCronometerUser();
        if (!linkSuccess) {
          throw new Error('Could not link cronometer user');
        }
        setIsSuccess(true);
      } catch (error) {
        Sentry.captureException(error, {
          extra: {
            userId,
          },
        });
        setIsSuccess(false);
      }
      setIsLoading(false);
    };
    run();
  }, [
    userId,
    unLinkFromTerra,
    linkCronometerUser,
  ]);

  const getText = () => {
    if (isLoading) {
      return texts.loading;
    }
    if (isSuccess) {
      return texts.success;
    }
    return texts.error;
  };

  return (
    <Container>
      <StyledCronometerIcon />
      {getText()}
    </Container>
  );
};

export default compose(
  withLayout,
)(CronometerCallback);
