import { search } from '../../utils/search';
import { ExerciseTagCategory } from '../../models/ExerciseTag';

/**
 * Search exercises by name.
 *
 * @param {Array} exercises
 * @param {string} searchText
 * @returns {Array}
 */
const searchByName = (exercises, searchText) => exercises.filter(({ name }) => search(name, searchText));

/**
 * Filter exercises by tags. When no tags exists, then returns the entire list of exercises received by parameters.
 *
 * @param {Array} exercises
 * @param {Object} selectedTagsByCategory
 */
const filterByTags = (exercises, selectedTagsByCategory) => {
  if (selectedTagsByCategory && (selectedTagsByCategory[ExerciseTagCategory.EQUIPMENT].length !== 0
    || selectedTagsByCategory[ExerciseTagCategory.EXERCISE_TYPE].length !== 0
    || selectedTagsByCategory[ExerciseTagCategory.BODY_REGION_BASIC].length !== 0
    || selectedTagsByCategory[ExerciseTagCategory.BODY_REGION_ADVANCED].length !== 0)
  ) {
    // When some category tag is selected, filter the entire exericses list by tags
    const selectedTags = Object.values(selectedTagsByCategory).reduce((acc, tags) => ([
      ...acc,
      ...tags,
    ]), []);

    return exercises.filter(({ tags }) => (
      selectedTags.every(({ id }) => (
        tags.findIndex(({ id: currentExerciseTagId }) => currentExerciseTagId === id) !== -1
      ))
    ));
  }

  // By default, no filter is selected, return all available exercises
  return exercises;
};

export {
  searchByName,
  filterByTags,
};
