import React, { useContext } from 'react';

import LoadingPage from '../../components/LoadingPage';
import UserContractContextProvider from './UserContractContextProvider';
import UserContractContext from './UserContractContext';

/**
 * Inserts UserContractContextProvider and initializes it
 *
 * @param {Object} Component The component that needs the context provider.
 * @returns {Object} The component to render wrapped with the provider.
 */
const withUserContractContextProvider = (Component) => (props) => (
  <UserContractContextProvider>
    <Component {...props} />
  </UserContractContextProvider>
);

/**
 * Renders the given component when UserContractContext is ready.
 *
 * @param {Object} Component The component to render.
 * @param {Object} Component The rendered component.
 * @returns {Object} The component to render or the LoadingPage component.
 */
const withUserContractContextReady = (Component) => (props) => {
  const { isReady } = useContext(UserContractContext);
  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withUserContractContextProvider,
  withUserContractContextReady,
};
