import React from 'react';
import PropTypes from 'prop-types';
import { IonPage, IonContent } from '@ionic/react';

import { HeaderContextProvider } from './context';
import Header from './Header';
import PageContent from './PageContent';

/**
 * Main application Layout component that makes sure of:
 * - Place the header component. In this case the default header:
 *   - back navigation.
 *   - dynamic title by configuration.
 *   - dynamic actions by configuration.
 * - Define the Page content where every page component will be rendered.
 * - Define and place the footer if any.
 */
const Layout = ({
  enablePadding,
  addChat,
  children,
}) => (
  <IonPage>
    <HeaderContextProvider addChat={addChat}>
      <Header />
      <IonContent>
        <PageContent enablePadding={enablePadding}>
          {children}
        </PageContent>
      </IonContent>
    </HeaderContextProvider>
  </IonPage>
);

Layout.propTypes = {
  enablePadding: PropTypes.bool,
  addChat: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  addChat: true,
  enablePadding: false,
};

export default Layout;
