import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import UserContext from '../../../../context/UserContext';

const TestUserContextProvider = ({
  children,
}) => {
  const userContextValues = useMemo(() => ({
    userConfigDoc: {
      isCameraAccessGranted: true,
    },
  }), []);

  return (
    <UserContext.Provider value={userContextValues}>
      {children}
    </UserContext.Provider>
  );
};

TestUserContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const withTestUserContextProvider = (Component) => (props) => (
  <TestUserContextProvider>
    <Component {...props} />
  </TestUserContextProvider>
);

export default withTestUserContextProvider;
