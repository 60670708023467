class BaseCameraProvider {
  constructor() {
    this.stream = null;
  }

  /**
   * Check if video stream is enabled. By default, it is enabled as long as the
   * provider has video stream support.
   * In the future, this can be combined with a feature toggle as well.
   *
   * @return {boolean} True if enabled, otherwise false.
   */
  get isVideoStreamEnabled() {
    return this.hasVideoStreamSupport();
  }

  captureImage = () => null;

  hasCameraSupport = () => true;

  clear = () => null;

  // APIs specific to video streaming support, by default this is not supported

  hasVideoStreamSupport = () => false;

  startStream = async () => {}

  pauseStream = () => {}

  resumeStream = () => {}

  stopStream = async () => {}

  videoStreamSessionStatus = () => {}

  shouldReconnectVideoStreamSession = () => Promise.resolve(false);

  addCameraStatusListener = () => {
    throw new Error('"addCameraStatusListener" not supported');
  }

  addVideoStreamConnectionChangeListener = () => {
    throw new Error('"addVideoStreamConnectionChangeListener" not supported');
  }

  addVideoPublisherStatusListener = () => {
    throw new Error('"addVideoPublisherStatusListener" not supported');
  }

  addVideoStreamErrorListener = () => {
    throw new Error('"addVideoStreamErrorListener" not supported');
  }

  addCameraGeneralErrorListener = () => {
    throw new Error('"addCameraGeneralErrorListener" not supported');
  }

  refreshPreview = () => {};
}

export default BaseCameraProvider;
