import React, {
  useContext,
  useMemo,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import {
  Redirect,
  useRouteMatch,
} from 'react-router-dom';

import MealPlanContext, {
  withMealPlanContextReady,
} from '../../context/MealPlanContext';
import useMealPlanNavigation from '../../hooks/useMealPlanNavigation';
import useMealServings from '../../hooks/useMealServings';
import {
  withCustomizedLayout,
} from '../../components/Layout';
import MealDetails from './MealDetails';

const MealDetailsContainer = () => {
  const {
    mealPlanAssignmentDoc,
  } = useContext(MealPlanContext);
  const {
    params: {
      bucket,
      meal: mealNumber,
    },
  } = useRouteMatch();

  const { pathname: mealPlanPathname } = useMealPlanNavigation();

  const { getMealServings } = useMealServings();

  const decodedBucket = decodeURIComponent(bucket);

  const meals = useMemo(() => (
    mealPlanAssignmentDoc.getMealsByBucketName(decodedBucket)
  ), [
    decodedBucket,
    mealPlanAssignmentDoc,
  ]);

  if (!meals || !meals[mealNumber]) {
    return <Redirect to={mealPlanPathname} />;
  }

  const meal = meals[mealNumber];

  const mealServings = getMealServings(meal, decodedBucket);

  return (
    <MealDetails meal={meal} recommendedServings={mealServings} allowReport />
  );
};

export default compose(
  withCustomizedLayout({ enablePadding: false }),
  withMealPlanContextReady,
  observer,
)(MealDetailsContainer);
