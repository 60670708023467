import React from 'react';

import { BannerType } from './utils';

const initialState = {
  isBannerVisible: false,
  onClick: () => {},
  messageText: '',
  showBanner: () => {},
  hideBanner: () => {},
  bannerType: BannerType.INFO,
};

const MessageBannerContext = React.createContext(initialState);

export default MessageBannerContext;
export {
  initialState,
};
