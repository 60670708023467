/**
 * The loaders defined in this module are extracted from handpose library, in order to support loading
 * local bundled models: https://github.com/tensorflow/tfjs-models/blob/master/handpose/src/index.
 * If something changes when upgrading the library version, then we must take a look at the internals
 * of the model loading process to determine if we need to make some adjustments in this implementation.
 *
 * @module handposeLoader
 */

import * as handpose from '@tensorflow-models/handpose';
import { HandDetector } from '@tensorflow-models/handpose/dist/hand';
import { HandPipeline } from '@tensorflow-models/handpose/dist/pipeline';
import * as tf from '@tensorflow/tfjs-core';
import * as tfconv from '@tensorflow/tfjs-converter';
import '@tensorflow/tfjs-backend-webgl';
import '@tensorflow/tfjs-backend-cpu';

const LOCAL_MODEL_BASE_URL = `${window.location.origin}/mediapipe/tfjs-model`;

const MESH_MODEL_INPUT_WIDTH = 256;
const MESH_MODEL_INPUT_HEIGHT = 256;

/**
 * https://github.com/tensorflow/tfjs-models/blob/master/handpose/src/index.ts#L26
 */
const loadHandDetectorModel = async () => {
  const handDetectModelPath = `${LOCAL_MODEL_BASE_URL}/handdetector/1/default/1/model.json`;
  return tfconv.loadGraphModel(handDetectModelPath);
};

/**
 * https://github.com/tensorflow/tfjs-models/blob/master/handpose/src/index.ts#L36
 */
const loadHandPoseModel = () => {
  const handposeModelPath = `${LOCAL_MODEL_BASE_URL}/handskeleton/1/default/1/model.json`;
  return tfconv.loadGraphModel(handposeModelPath);
};

/**
 * https://github.com/tensorflow/tfjs-models/blob/master/handpose/src/index.ts#L45
 */
const loadAnchors = async () => {
  const result = await tf.util.fetch(`${LOCAL_MODEL_BASE_URL}/handskeleton/1/default/1/anchors.json`);

  return result.json();
};

/**
 * https://github.com/tensorflow/tfjs-models/blob/master/handpose/src/index.ts#L71
 */
const load = async ({
  maxContinuousChecks = Infinity,
  detectionConfidence = 0.8,
  iouThreshold = 0.3,
  scoreThreshold = 0.5,
} = {}) => {
  const [anchors, handDetectorModel, handPoseModel] = await Promise.all([
    loadAnchors(),
    loadHandDetectorModel(),
    loadHandPoseModel(),
  ]);

  const detector = new HandDetector(
    handDetectorModel, MESH_MODEL_INPUT_WIDTH, MESH_MODEL_INPUT_HEIGHT,
    anchors, iouThreshold, scoreThreshold,
  );

  const pipeline = new HandPipeline(
    detector, handPoseModel, MESH_MODEL_INPUT_WIDTH, MESH_MODEL_INPUT_HEIGHT,
    maxContinuousChecks, detectionConfidence,
  );

  const model = new handpose.HandPose(pipeline);

  return model;
};

const getHandposeInstance = async () => {
  await tf.setBackend('webgl');

  return load();
};

export default getHandposeInstance;
