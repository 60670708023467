import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledActionButton,
  StyledTooltip,
} from './styles';

const ActionButton = ({
  name,
  title,
  onClick,
  children,
}) => (
  <StyledActionButton
    name={name}
    onClick={onClick}
  >
    <StyledTooltip
      text={title}
      activateOnHover
    >
      {children}
    </StyledTooltip>
  </StyledActionButton>
);

ActionButton.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ActionButton;
