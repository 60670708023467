import styled, { css } from 'styled-components';

import { headlineFontStyles, sansSerif } from '../../../../styles/text';
import { ReactComponent as ActiveCardBackgroundImage } from '../../../../assets/workout-feed/card-bg-active.svg';
import { ReactComponent as InactiveCardBackgroundImage } from '../../../../assets/workout-feed/card-bg-inactive.svg';
import { FlatCard } from '../../../../components/Card';

const cardTheme = css`
  [data-color="primary"] {
    fill: ${({ theme }) => theme.colors.base.primary};
  }

  [data-color="secondary"] {
    fill: ${({ theme }) => theme.colors.base.secondary};
  }

  [data-color="secondary20"] {
    fill: ${({ theme }) => theme.colors.shades.secondary20};
  }

  [data-color="secondary40"] {
    fill: ${({ theme }) => theme.colors.shades.secondary40};
  }

  [data-color="tertiary"] {
    fill: ${({ theme }) => theme.colors.base.tertiary};
  }

  [data-color="tertiaryVariant"] {
    fill: ${({ theme }) => theme.colors.base.tertiaryVariant};
  }

  [data-color="background"] {
    fill: ${({ theme }) => theme.colors.system.background};
  }
`;

/*
  NOTE: the data attributes data-color indicates the color from the theme to use for
  the element, fill content in all cases.
  When updating the SVG, make sure that every element that has a color for fill, stroke etc
  add the data attribute required to apply customization.
*/
const ActiveCardBackground = styled(ActiveCardBackgroundImage)`
  ${cardTheme}
`;

const InactiveCardBackground = styled(InactiveCardBackgroundImage)`
  ${cardTheme}
`;

const StyledCard = styled(FlatCard)`
  position: relative;
  padding: 0;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  box-shadow: none;
  min-height: 200px;

  ${({ noMargin }) => (
    css`
      margin-bottom: ${noMargin ? 0 : 16}px;
    `
  )}
`;

const CardBackground = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;

  svg {
    height: 100%;
    width: 100%;
  }
`;

const CardContent = styled.div`
  padding: 16px;
  z-index: 1;
`;

const Message = styled.div`
  ${headlineFontStyles}
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  padding-bottom: 4px;
  color: ${({ theme }) => theme.colors.base.primary};
`;

const Description = styled.div`
  ${sansSerif.headlineFontStyles}
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.base.primary};
`;

export {
  StyledCard,
  CardContent,
  Message,
  Description,
  CardBackground,
  ActiveCardBackground,
  InactiveCardBackground,
};
