import { firestorePaths } from '../utils/firebasePaths';
import AppCustomCheckin from './AppCustomCheckin';
import AppCustomHabit from './AppCustomHabit';
import AppCustomTheme from './AppCustomTheme';
import BaseDocument from './BaseDocument';

class AppCustomization extends BaseDocument {
  constructor(id, opts) {
    super(`${firestorePaths.APP_CUSTOMIZATION}/${id}`, opts);
  }

  get texts() {
    return this.data.texts || {};
  }

  get appIconName() {
    return this.data.appIconName;
  }

  get webAppIconRef() {
    return this.data.webAppIconRef;
  }

  get theme() {
    return this.data.theme || {};
  }

  get baseTheme() {
    return this.theme.baseTheme;
  }

  get baseHabit() {
    return this.data.baseHabit;
  }

  get baseCheckin() {
    return this.data.baseCheckin;
  }

  async getBaseThemeDoc() {
    if (this.baseTheme) {
      return AppCustomTheme.getById(this.baseTheme);
    }
    return null;
  }

  async getBaseHabitDoc() {
    if (this.baseHabit) {
      return AppCustomHabit.getById(this.baseHabit);
    }
    return null;
  }

  async getBaseCheckinDoc() {
    if (this.baseCheckin) {
      return AppCustomCheckin.getById(this.baseCheckin);
    }
    return null;
  }

  static getAppCustomizationByCoach = async (coachId) => {
    const appCustomizationDoc = new AppCustomization(coachId);
    await appCustomizationDoc.init();
    return appCustomizationDoc.exists ? appCustomizationDoc : null;
  };
}

export default AppCustomization;
