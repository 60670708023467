import styled, { css } from 'styled-components';

import { ReactComponent as RenewSubscriptionIcon } from '../../assets/renew-subscription-icon.svg';
import { ReactComponent as RenewSubscriptionSuccessIcon } from '../../assets/subscription-renewal-success.svg';
import { ReactComponent as PaymentMethodIcon } from '../../assets/payment-icon.svg';

const iconStyles = css`
  height: 80px;
  width: auto;
`;

const StyledRenewSubscriptionIcon = styled(RenewSubscriptionIcon)`
  ${iconStyles}
`;

const StyledRenewSubscriptionSuccessIcon = styled(RenewSubscriptionSuccessIcon)`
  ${iconStyles}
`;

const StyledPaymentMethodIcon = styled(PaymentMethodIcon)`
  ${iconStyles}
`;

export {
  StyledRenewSubscriptionIcon,
  StyledRenewSubscriptionSuccessIcon,
  StyledPaymentMethodIcon,
};
