import React from 'react';
import WorkoutFeedViewsContextProvider from './WorkoutFeedViewsContextProvider';

/**
 * Inserts WorkoutFeed in the react tree
 * before adding the given component.
 *
 * @param {Object} Component The component that needs the context provider.
 * @returns {Object} The component to render wrapped with the provider.
 */
const withWorkoutFeedViewsContextProvider = (Component) => (props) => (
  <WorkoutFeedViewsContextProvider>
    <Component {...props} />
  </WorkoutFeedViewsContextProvider>
);

export {
  withWorkoutFeedViewsContextProvider,
};
