import { ActivityTypes } from '../models/BaseActivity';

/**
 * Get a linear array of unique activities. Circuits are not included (only their internal activities).
 * Rest activities are ignored as well.
 *
 * @param {array} activities An array of activities to process.
 * @returns {array} The tape generated.
 */
const prepareUniqueActivitiesTape = (activities) => {
  const preparedActivities = [];
  const activitiesSet = {};

  // Verifiy if the activity was already added. If not, update the activitiesSet and push the new prepared activity
  const pushUniqueActivity = (activity) => {
    if (!activitiesSet[activity.name]) {
      preparedActivities.push(activity);

      // Save this activity into the set.
      activitiesSet[activity.name] = activity;
    }
  };

  activities.forEach((activity) => {
    if (activity.type === ActivityTypes.CIRCUIT) {
      // We use a recursive method to cycle through the activities in a Circuit.
      const circuitActivities = prepareUniqueActivitiesTape(activity.activities);

      // Push each individual activity. This is done this way in case any activity in the circuit was already added.
      circuitActivities.forEach((circuitActivity) => {
        pushUniqueActivity(circuitActivity);
      });
    } else if (activity.type !== ActivityTypes.REST) {
      pushUniqueActivity(activity);
    }
  });

  return preparedActivities;
};

/**
 * Get the index of an activity inside a tape, by using the activity name.
 *
 * @param {array} tape The tape to use for search.
 * @param {string} name The name of the activity to look for.
 * @returns {number} The index of the selected activity.
 */
const getActivityIndexByName = (tape, name) => {
  // TODO: We need to change the way we identify activities. Use the ID instead of the name. [s2/230]
  const currentActivityIndex = tape.findIndex(((activity) => activity.name === name));
  return currentActivityIndex;
};

export {
  prepareUniqueActivitiesTape,
  getActivityIndexByName,
};
