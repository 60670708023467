import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { IonSpinner } from '@ionic/react';
import { compose } from 'recompose';
import { CSSTransition } from 'react-transition-group';

import withPortal from '../withPortal';
import transitionsConfig from './transitionsConfig';
import { Overlay } from './styles';

// TODO: create more generic component for loading spinner combining all the
// spinners we have in the app
const LoadingOverlay = ({
  isLoading,
}) => {
  const overlayRef = useRef(null);

  return (
    <CSSTransition
      timeout={transitionsConfig.delay}
      classNames={transitionsConfig.classNames.overlay}
      in={isLoading}
      unmountOnExit
      nodeRef={overlayRef}
    >
      <Overlay ref={overlayRef}>
        <IonSpinner name="crescent" />
      </Overlay>
    </CSSTransition>
  );
};

LoadingOverlay.propTypes = {
  isLoading: PropTypes.bool,
};

LoadingOverlay.defaultProps = {
  isLoading: false,
};

export default compose(
  withPortal('loadingOverlay'),
)(LoadingOverlay);
