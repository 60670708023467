import {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from 'react';

import WorkoutContext from '../context/WorkoutContext';
import {
  prepareUniqueActivitiesTape,
  getActivityIndexByName,
} from '../utils/circularTape';

const useUniqueActivitiesTape = () => {
  const {
    activitiesWithOverrides,
  } = useContext(WorkoutContext);

  // This will hold a tape of unique activities (without rest) so that we can cycle through them.
  const [activitiesTape, setActivitiesTape] = useState([]);

  useEffect(() => {
    setActivitiesTape((prevActivitiesTape) => {
      // We need to prepare the activities if the tape hasn't been created.
      if (prevActivitiesTape.length === 0 && activitiesWithOverrides) {
        return prepareUniqueActivitiesTape(activitiesWithOverrides);
      }
      return prevActivitiesTape;
    });
  }, [
    activitiesWithOverrides,
  ]);

  const getNextActivity = useCallback((activityName) => {
    // Get the position of the current activity in the activity tape.
    const currentActivityIndex = getActivityIndexByName(activitiesTape, activityName);

    /*
      This allows for circular navigation. If the current activity is the last one of the tape, then we load the first
      activity we have.
    */
    const nextIndex = currentActivityIndex === activitiesTape.length - 1 ? 0 : currentActivityIndex + 1;
    return activitiesTape[nextIndex];
  }, [
    activitiesTape,
  ]);

  const getPreviousActivity = useCallback((activityName) => {
    const currentActivityIndex = getActivityIndexByName(activitiesTape, activityName);

    /*
      This allows backward circular navigation. If the current activity is the first one of the tape, then we load the
      last activity we have.
    */
    const nextIndex = currentActivityIndex === 0 ? activitiesTape.length - 1 : currentActivityIndex - 1;
    return activitiesTape[nextIndex];
  }, [
    activitiesTape,
  ]);

  return useMemo(() => ({
    activitiesTape,
    getNextActivity,
    getPreviousActivity,
  }), [
    activitiesTape,
    getNextActivity,
    getPreviousActivity,
  ]);
};

export default useUniqueActivitiesTape;
