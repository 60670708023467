import seedrandom from 'seedrandom';

/**
 * Calculates a random number between 0 and 1, based on the given seed.
 *
 * @param {string} seed The seed to be used.
 * @returns A random number between 0 and 1.
 */
const getRandom = (seed) => seedrandom(seed)();

export {
  getRandom,
};
