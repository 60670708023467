import React, {
  useContext,
} from 'react';

import AggregatedDataContext from '../../../../../context/AggregatedDataContext';
import WorkoutsResultSummary from '../../../../../components/WorkoutsResultSummary';

import {
  ContentSection,
} from '../styles';

const WorkoutSummaryStats = () => {
  const {
    isReady,
    aggregatedDataDoc,
  } = useContext(AggregatedDataContext);

  return isReady
    ? (
      <ContentSection>
        <WorkoutsResultSummary
          completedWorkouts={aggregatedDataDoc.completedWorkouts}
          totalExercises={aggregatedDataDoc.totalExercises}
        />
      </ContentSection>
    )
    : null;
};

export default WorkoutSummaryStats;
