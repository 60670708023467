import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ChartContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.system.background};
  padding: 14px 12px;
  border-radius: 4px;
`;

const NutritionLogMeals = styled.ul`
  margin: 0;
  padding-bottom: 12px;
  background-color: ${({ theme }) => theme.colors.system.support2};
`;

const NutritionLogMealContainer = styled.li`
  margin-bottom: 10px;

  &::marker {
    color: ${({ theme }) => theme.colors.system.gamma3};
  }
`;

const NutritionLogMealContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const NutritionLogMealTitle = styled.p`
  font-size: 11px;
  line-height: 13px;
`;

const NutritionLogMealMacro = styled.span`
  font-size: 8px;
  line-height: 10px;
  color: ${({ highlight, theme }) => (highlight ? theme.colors.base.secondary : theme.colors.system.gamma3)}
`;

const TitleText = styled.div`
  font-size: 12px;
  line-height: 12px;
`;

const UnitLabel = styled.div`
  font-size: 8px;
  line-height: 10px;
  font-weight: 500;
  margin-top: 2px;
`;

const ValueText = styled.div`
  font-size: 48px;
  line-height: 50px;
  font-weight: 800;
  margin-top: 2px;
`;

const StatContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  justify-content: space-between;
  min-width: fit-content;
`;

const StatSub = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  margin-top: 8px;
  color: ${({ theme }) => theme.colors.system.gamma2};
`;

const Stat = styled.div`
  display: flex;
  flex-direction: column;
  min-width: fit-content;
  border-radius: 10px;
  margin-right: 10px;
`;

export {
  Container,
  ChartContainer,
  NutritionLogMeals,
  NutritionLogMealContainer,
  NutritionLogMealContent,
  NutritionLogMealTitle,
  NutritionLogMealMacro,
  TitleText,
  UnitLabel,
  ValueText,
  StatContainer,
  Stat,
  StatSub,
};
