import { Collection } from 'firestorter';
import BaseDocument from './BaseDocument';

class WorkoutAssignmentFeedback extends BaseDocument {
  static saveFeedback = (user, workoutAssignment, feedback) => {
    const workoutAssignmentFeedbackCollection = new Collection('workoutAssignmentFeedback');
    workoutAssignmentFeedbackCollection.add({
      user,
      workoutAssignment,
      feedback,
      createdAt: Date.now(),
      status: 'QUEUED',
    });
  }
}

export default WorkoutAssignmentFeedback;
