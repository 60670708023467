import React from 'react';
import PropTypes from 'prop-types';

import AssignmentDayType from '../../viewModel/assignmentType';
import DayCard from '../DayCard';
import {
  DayGroupWrapper,
  StyledProgressConnector,
} from './styles';

const DayGroup = ({
  date,
  assignmentDayType,
  progressStatus,
  trackedActivitySummary,
  streak,
  children,
}) => (
  <DayGroupWrapper
    progressStatus={progressStatus}
    assignmentDayType={assignmentDayType}
  >
    <StyledProgressConnector
      progressStatus={progressStatus}
      showBar={assignmentDayType === AssignmentDayType.PAST}
      showRibbon={!!streak}
      enableRibbonStartOffset={!!streak && streak.isFirstStreakDay}
      enableRibbonEndOffset={!!streak && !streak.isFinalStreakDay}
    />
    <DayCard
      date={date}
      assignmentDayType={assignmentDayType}
      trackedActivitySummary={trackedActivitySummary}
    />
    {children}
  </DayGroupWrapper>
);

DayGroup.propTypes = {
  date: PropTypes.object.isRequired,
  assignmentDayType: PropTypes.oneOf(Object.values(AssignmentDayType)).isRequired,
  progressStatus: PropTypes.string.isRequired,
  trackedActivitySummary: PropTypes.object,
  streak: PropTypes.object,
  children: PropTypes.any,
};

DayGroup.defaultProps = {
  trackedActivitySummary: null,
  streak: null,
  children: null,
};

export default DayGroup;
