import React, { useContext } from 'react';
import LoadingPage from '../../../../components/LoadingPage';
import WorkoutSelectorContext from './WorkoutSelectorContext';

const withWorkoutSelectorContextReady = (Component) => (props) => {
  const { isReady } = useContext(WorkoutSelectorContext);

  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export default withWorkoutSelectorContextReady;
