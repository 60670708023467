import styled from 'styled-components';

import Button from '../../../components/Button';
import Tooltip from '../../../components/Tooltip';

const StyledActionButton = styled(Button)`
  background-color: transparent;
  padding: 0px;
  margin: 0px 4px;
  width: 28px;
  height: 28px;
  line-height: 0;
`;

const StyledTooltip = styled(Tooltip)`
  font-size: 14px;
  font-weight: 500;
  z-index: 1;
  top: calc(100% + 15px);
  padding: 15px;
`;

export {
  StyledActionButton,
  StyledTooltip,
};
