import React from 'react';
import PropTypes from 'prop-types';

import texts from '../texts';
import { invoiceStatusOptions } from './utils';

import {
  InvoiceListItemContainer,
  StyledChevronRightIconInvoiceList,
  InvoiceDetailsContainer,
  InvoiceID,
  InvoiceDataContainer,
  InvoiceDate,
  InvoiceAmount,
  InvoiceStatus,
} from './styles';

const InvoiceListItem = ({
  handleClick,
  invoiceId,
  invoiceAmount,
  invoiceDate,
  invoiceStatus,
  invoiceCurrency,
}) => {
  const isPaid = invoiceStatus === invoiceStatusOptions.PAID;
  return (
    <InvoiceListItemContainer onClick={handleClick}>
      <InvoiceDataContainer>
        <InvoiceDetailsContainer>
          <InvoiceID>{invoiceId}</InvoiceID>
          <InvoiceAmount>{`${invoiceCurrency} ${invoiceAmount}`}</InvoiceAmount>
        </InvoiceDetailsContainer>
        <InvoiceDetailsContainer>
          <InvoiceDate>{isPaid ? texts.paidInvoiceDateKey : texts.createdInvoiceDateKey}</InvoiceDate>
          <InvoiceDate>{invoiceDate}</InvoiceDate>
        </InvoiceDetailsContainer>
        <InvoiceStatus $invoiceStatus={invoiceStatus}>
          {invoiceStatus}
        </InvoiceStatus>
      </InvoiceDataContainer>
      <StyledChevronRightIconInvoiceList />
    </InvoiceListItemContainer>
  );
};

InvoiceListItem.propTypes = {
  handleClick: PropTypes.func,
  invoiceId: PropTypes.string.isRequired,
  invoiceDate: PropTypes.string.isRequired,
  invoiceAmount: PropTypes.string.isRequired,
  invoiceStatus: PropTypes.string.isRequired,
  invoiceCurrency: PropTypes.string.isRequired,
};

InvoiceListItem.defaultProps = {
  handleClick: () => {},
};

export default InvoiceListItem;
