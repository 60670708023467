import bannerTexts from './texts.json';

const BannerType = {
  INFO: 'INFO',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
};

export {
  BannerType,
  bannerTexts,
};
