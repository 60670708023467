import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { LoaderWrapper, Loading, LogsContainer } from './styles';
import TimelineItem from './TimelineItem';

const Timeline = ({
  logs,
  isLoadingMore,
  stat,
}) => {
  const renderLogs = useCallback(() => (
    logs.map((log) => <TimelineItem key={log.id} log={log} stat={stat} />)
  ), [
    logs,
    stat,
  ]);

  return (
    <>
      <LogsContainer>
        {logs.length > 0 && renderLogs()}
      </LogsContainer>
      {isLoadingMore && (
        <LoaderWrapper>
          <Loading />
        </LoaderWrapper>
      )}
    </>
  );
};

Timeline.propTypes = {
  logs: PropTypes.array.isRequired,
  isLoadingMore: PropTypes.bool.isRequired,
  stat: PropTypes.string,
};

Timeline.defaultProps = {
  stat: null,
};

export default Timeline;
