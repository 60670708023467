import Hand from '../../models/Hand';

const HAND_IN_VIEW_MIN_CONFIDENCE = 0.5;

/**
 * Parse TFJS Handpose prediction results into a custom data model with a coordinates system
 * whose values are in a range of [0, 1].
 *
 * @param {Array} predictions The predictions results of running the model.
 * @param {number} width Image width.
 * @param {number} height Image height.
 * @returns An Array of results.
 */
const parseTFHandpose = (predictions, width, height) => {
  const hands = [];

  predictions.forEach((handPrediction) => {
    // NOTE: using an object to make it compatible wit BasePrediction.prototype.keypoints interface
    const parsedHand = {};

    const { handInViewConfidence, landmarks } = handPrediction;

    if (handInViewConfidence >= HAND_IN_VIEW_MIN_CONFIDENCE) {
      landmarks.forEach((landmark, index) => {
        const [x, y] = landmark;
        parsedHand[index] = [];
        parsedHand[index][0] = x / width;
        parsedHand[index][1] = y / height;
      });

      hands.push(parsedHand);
    }
  });

  return hands;
};

/**
 * Run the TFJS Handpose model to find a Hand in the given image.
 *
 * @param {Object} model The handpose model instance.
 * @param {Object} imageElement The image element to analyze.
 * @returns An object with the predictions results.
 */
const findHands = async (model, imageElement, aspectRatio) => {
  const hands = await model.estimateHands(imageElement);

  const width = imageElement.naturalWidth || imageElement.width;
  const height = imageElement.naturalHeight || imageElement.height;

  const predictions = parseTFHandpose(hands, width, height);

  const predictionModels = predictions.map((handDefinition) => new Hand(handDefinition, aspectRatio));

  return {
    predictions,
    predictionModels,
  };
};

export {
  findHands,
  parseTFHandpose,
};
