const Feature = {
  COACH_CALL_SCHEDULING: 'COACH_CALL_SCHEDULING',
  REFERRALS: 'REFERRALS',
  CHAT: 'CHAT',
  WORKOUT_VIDEO_FEEDBACK: 'WORKOUT_VIDEO_FEEDBACK',
  NUTRITION_PLAN: 'NUTRITION_PLAN',
  SYSTEM2_WORKOUTS: 'SYSTEM2_WORKOUTS',
  CHECK_IN_NOTIFICATION: 'CHECK_IN_NOTIFICATION',
};

const isSupportedFeature = (id) => !!Feature[id];

export {
  Feature,
  isSupportedFeature,
};
