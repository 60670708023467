import React from 'react';
import PropTypes from 'prop-types';

import FeedbackFormContainer from '../FeedbackForm';
import { FeedbackSubmit } from '../FeedbackForm/styles';

const EditFeedbackSection = ({
  latestGameplaySession,
  activityFeedbacks,
  selectedActivityIndex,
  unsaved,
  setUnsaved,
  activities,
  setActivities,
  setWorkoutFeedback,
  onSubmitFeedbackClick,
  isLoading,
}) => (
  <>
    <FeedbackFormContainer
      key={`${latestGameplaySession.id}-form`}
      name="Workout Feedback"
      feedback={latestGameplaySession.coachFeedback}
      isSelected
      pathArr={[]}
      setWorkoutFeedback={setWorkoutFeedback}
      activities={activities}
      setActivities={setActivities}
      setUnsaved={setUnsaved}
    />
    {activityFeedbacks.map((feedback, index) => (
      <FeedbackFormContainer
        key={feedback.sessionID}
        name={feedback.name}
        feedback={feedback.coachFeedback}
        isSelected={index === selectedActivityIndex}
        pathArr={feedback.pathArr}
        setWorkoutFeedback={setWorkoutFeedback}
        activities={activities}
        setActivities={setActivities}
        setUnsaved={setUnsaved}
      />
    ))}
    <FeedbackSubmit
      disabled={!unsaved}
      onClick={onSubmitFeedbackClick}
      isLoading={isLoading}
    >
      Save Feedback
    </FeedbackSubmit>
  </>
);

EditFeedbackSection.propTypes = {
  latestGameplaySession: PropTypes.object.isRequired,
  activityFeedbacks: PropTypes.array.isRequired,
  selectedActivityIndex: PropTypes.number.isRequired,
  activities: PropTypes.array.isRequired,
  setActivities: PropTypes.func.isRequired,
  unsaved: PropTypes.bool.isRequired,
  setUnsaved: PropTypes.func.isRequired,
  setWorkoutFeedback: PropTypes.func.isRequired,
  onSubmitFeedbackClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default EditFeedbackSection;
