import React from 'react';
import PropTypes from 'prop-types';

import ActivityRingsTheme from './activityRingsTheme';
import colors from './colors';
import {
  Swatch,
  Svg,
  ActiveEnergyRing,
  ExerciseRing,
  StandHoursRing,
} from './styles';

const DEFAULT_STROKE_WIDTH = 32;

/**
 * React component implementation for the Apple HKActivityRingView.
 * UX guidelines:
 * - https://developer.apple.com/design/human-interface-guidelines/healthkit/overview/
 * - https://developer.apple.com/documentation/healthkit/hkactivityringview
 *
 * @param {Object} props
 * @returns {Object}
 */
const AppleActivityRings = ({
  activitySummary,
  theme,
  className,
}) => {
  const {
    energyBurned,
    energyBurnedGoal,
    standHours,
    standHoursGoal,
    exerciseTime,
    exerciseTimeGoal,
  } = activitySummary || {};

  const backgroundColor = colors.background[theme] || colors.background[ActivityRingsTheme.DARK];

  return (
    <Swatch className={className}>
      <Svg
        height="100%"
        width="100%"
        viewBox="0 0 250 250"
        preserveAspectRatio="xMidYMid meet"
      >
        <circle
          preserveAspectRatio="xMidYMid meet"
          fill={backgroundColor}
          r="125"
          cx="125"
          cy="125"
        />
      </Svg>

      <ActiveEnergyRing
        id="activeEnergyBurnedGradient"
        strokeWidth={DEFAULT_STROKE_WIDTH}
        stepColor0={colors.activeEnergy.step0}
        stepColor100={colors.activeEnergy.step100}
        backgroundColor={colors.activeEnergy.background}
        size={250}
        value={energyBurned}
        goal={energyBurnedGoal}
        theme={theme}
      />

      <ExerciseRing
        id="exerciseTimeGradient"
        strokeWidth={DEFAULT_STROKE_WIDTH}
        stepColor0={colors.exercise.step0}
        stepColor100={colors.exercise.step100}
        backgroundColor={colors.exercise.background}
        size={185}
        value={exerciseTime}
        goal={exerciseTimeGoal}
        theme={theme}
      />

      <StandHoursRing
        id="standHoursGradient"
        strokeWidth={DEFAULT_STROKE_WIDTH}
        stepColor0={colors.standHours.step0}
        stepColor100={colors.standHours.step100}
        backgroundColor={colors.standHours.background}
        size={122}
        value={standHours}
        goal={standHoursGoal}
        theme={theme}
      />
    </Swatch>
  );
};

AppleActivityRings.propTypes = {
  activitySummary: PropTypes.shape({
    energyBurned: PropTypes.number.isRequired,
    energyBurnedGoal: PropTypes.number.isRequired,
    standHours: PropTypes.number.isRequired,
    standHoursGoal: PropTypes.number.isRequired,
    exerciseTime: PropTypes.number.isRequired,
    exerciseTimeGoal: PropTypes.number.isRequired,
  }),
  theme: PropTypes.oneOf(Object.values(ActivityRingsTheme)),
  className: PropTypes.string,
};

AppleActivityRings.defaultProps = {
  activitySummary: null,
  theme: ActivityRingsTheme.DARK,
  className: '',
};

export default AppleActivityRings;
