import React, {
  useState,
  useMemo,
  useEffect,
} from 'react';
import Proptypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { useRouteMatch } from 'react-router-dom';
import format from 'string-template';

import GameplaySession from '../../models/GameplaySession';

import { firestorePaths, pathPlaceholder } from '../../utils/firebasePaths';

import GameplaySessionContext from './GameplaySessionContext';

const GameplaySessionContextProvider = ({
  children,
}) => {
  const {
    params: {
      gameplaySessionId,
    },
  } = useRouteMatch();

  // We consider the provider to be ready if there's no param of gameplaySessionId to load
  const [isReady, setIsReady] = useState(!gameplaySessionId);
  const [gameplaySessionDoc, setGameplaySessionDoc] = useState(null);

  useEffect(() => {
    let shouldUpdate = true;

    const init = async () => {
      const gameplaySessionDocument = new GameplaySession(format(firestorePaths.GAMEPLAY_SESSION_DOC, {
        [pathPlaceholder.GAMEPLAY_SESSION_ID]: gameplaySessionId,
      }));
      await gameplaySessionDocument.init();
      if (shouldUpdate) {
        setGameplaySessionDoc(gameplaySessionDocument);
        setIsReady(true);
      }
    };

    if (!isReady) {
      init();
    }

    return () => {
      shouldUpdate = false;
    };
  }, [
    gameplaySessionId,
    isReady,
  ]);

  const contextValue = useMemo(() => ({
    gameplaySessionDoc,
    isReady,
  }), [
    gameplaySessionDoc,
    isReady,
  ]);

  return (
    <GameplaySessionContext.Provider value={contextValue}>
      {children}
    </GameplaySessionContext.Provider>
  );
};

GameplaySessionContextProvider.propTypes = {
  children: Proptypes.node.isRequired,
};

export default compose(
  observer,
)(GameplaySessionContextProvider);
