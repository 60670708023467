import styled, { css } from 'styled-components';

import CameraPreview from '../CameraPreview';
import LoadingPage from '../LoadingPage';

const CameraPreviewWrapper = styled.div`
  width: 100%;
  position: relative;
  align-self: center;

  ${({ fullHeight }) => (fullHeight ? css`height: 100%;` : '')}

  ${({ theme }) => theme.isPortrait && css`
    height: 100%;
  `}

  video {
    height: auto;
    width: 100%;
    transform: scaleX(-1);

    ${({ theme }) => theme.isPortrait && css`
      height: 100%;
    `}
  }
`;

const Container = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  justify-items: center;

  ${({ transparentBackground, theme: { colors } }) => (
    transparentBackground
      ? css`backround: 'transparent';`
      : css`background-color: ${colors.system.alpha};`
  )}


  ${({ theme }) => theme.isPortrait && css`
    display: initial;
  `}
`;

const Placeholder = styled.div`
  width: 100%;
  z-index: 1;
  align-self: center;
  height: 55vh;
`;

const Loader = styled(LoadingPage)`
  height: 100%;
  position: absolute;
`;

const StyledCameraPreview = styled(CameraPreview)`
  ${({ show }) => (
    show ? '' : css`display: none;`
  )}
`;

export {
  Container,
  CameraPreviewWrapper,
  StyledCameraPreview,
  Placeholder,
  Loader,
};
