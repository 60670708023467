import styled from 'styled-components';

const DebugSelfie = styled.div`
  width: 100%;
  align-self: center;
  position: relative;

  canvas {
    width: 100%;
    transform: scaleX(-1);
  }
`;

export default DebugSelfie;
