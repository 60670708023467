import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import ProgressBar from '../ProgressBar';

import {
  ProgressInfoPanelContainer,
  InfoRow,
  CircuitInfo,
  StyledTimeCounter,
} from './styles';

const SINGLE_ACTIVITY_ROUNDS = 1;

const ProgressInfoPanel = ({
  circuitName,
  circuitRound,
  circuitTotalRounds,
  currentExerciseIndex,
  totalExercises,
  isActivityPaused,
  currentTimeUpdated,
  enableHighContrast,
}) => {
  const renderCircuitInfo = useCallback(() => {
    // circuitName can be null, so we force it to be a string here.
    const circuitNameString = circuitName || '';

    // Only show the circuit sets if it is not a single activity
    const showSets = circuitRound > 0 && circuitTotalRounds > SINGLE_ACTIVITY_ROUNDS;
    const setsInfo = showSets ? `Set ${circuitRound}/${circuitTotalRounds}` : '';
    const separator = !!circuitName && showSets ? ' - ' : '';

    return `${circuitNameString}${separator}${setsInfo}`;
  }, [
    circuitName,
    circuitRound,
    circuitTotalRounds,
  ]);

  return (
    <ProgressInfoPanelContainer $enableHighContrast={enableHighContrast}>
      <ProgressBar
        currentActiveIndex={currentExerciseIndex}
        totalSegments={totalExercises}
        enableHighContrast={enableHighContrast}
      />
      <InfoRow>
        <CircuitInfo>
          {renderCircuitInfo()}
        </CircuitInfo>
        <StyledTimeCounter
          run={!isActivityPaused}
          currentTimeUpdated={currentTimeUpdated}
        />
      </InfoRow>
    </ProgressInfoPanelContainer>
  );
};

ProgressInfoPanel.propTypes = {
  currentExerciseIndex: PropTypes.number.isRequired,
  totalExercises: PropTypes.number.isRequired,
  circuitName: PropTypes.string,
  circuitRound: PropTypes.number,
  circuitTotalRounds: PropTypes.number,
  isActivityPaused: PropTypes.bool.isRequired,
  currentTimeUpdated: PropTypes.func.isRequired,
  enableHighContrast: PropTypes.bool,
};

ProgressInfoPanel.defaultProps = {
  circuitName: '',
  circuitRound: 0,
  circuitTotalRounds: 0,
  enableHighContrast: false,
};

export default ProgressInfoPanel;
