import { IonSpinner } from '@ionic/react';
import styled, { css } from 'styled-components';
import { headlineFontStyles, sansSerif } from '../../../../../../styles/text';

const IMAGE_SIZE = 87;

const pseudoElementsStyles = css`
  z-index: 1;
  content: '';
  width: 2px;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.system.gamma2};
  left: -1px;
`;

const ItemContainer = styled.div`
  position: relative;
  display: grid;
  padding: 16px 16px 16px 26px;
  margin-bottom: 8px;
  background-color: ${({ theme }) => theme.colors.system.alpha};
  grid-template-columns: 1fr ${IMAGE_SIZE}px;
  min-height: ${IMAGE_SIZE}px;
  box-sizing: content-box;

  &::before {
    ${pseudoElementsStyles}
    top: 0;
    height: 15px; 
  }

  &::after {
    ${pseudoElementsStyles}
    top: 15px;
    height: 100%;
  }

  &:first-child::before {
    content: none;
  }

  &:last-child::after {
    content: none;
  }
`;

const DateElement = styled.span`
  ${sansSerif.headlineFontStyles}
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 3px;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  text-transform: uppercase;
`;

const StatElement = styled.span`
  ${headlineFontStyles}
  font-size: 28px;
  line-height: 38px;
  grid-column: 1 / 2;
  grid-row: 2 / 3;
`;

const MeasurementContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-column: 1 / 2;
  grid-row: 5 / 7;
  margin-top: 5px;
`;

const Measurement = styled.div`
  flex-direction: column;
  width: 30%;
  padding: 5px;
  justify-content: center;
`;

const Label = styled.div`
  ${headlineFontStyles}
  font-size: 10px;
  line-height: 14px;
  text-align: center;
`;
const Value = styled.div`
  ${headlineFontStyles}
  font-size: 14px;
  line-height: 14px;
  text-align: center;
`;

const CommentElement = styled.span`
  ${sansSerif.headlineFontStyles}
  color: ${({ theme }) => theme.colors.system.gamma2};
  font-size: 12px;
  line-height: 14px;
  grid-column: 1 / 2;
  grid-row: 3 / 4;
  margin-top: 5px;
`;

const StyledConnector = styled.div`
  position: absolute;
  top: 13px;
  transform: translateX(-50%);
  height: 15px;
  width: 15px;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.colors.system.gamma2};
  background-color: ${({ theme }) => theme.colors.system.gamma2};
  border-radius: 50%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EmojiText = styled.span`
  /* This centers the emoji */
  margin-left: -4px;
`;

const ImageContainer = styled.div`
  grid-row: 1 / 7;
  grid-column: 2 / 3;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  
  img {
    max-height: ${IMAGE_SIZE}px;
    max-width: ${IMAGE_SIZE}px;
  }
`;

const StyledSpinner = styled(IonSpinner)`
  width: 100%;
  margin: 30px 0;
`;

export {
  ItemContainer,
  DateElement,
  StatElement,
  CommentElement,
  StyledConnector,
  EmojiText,
  ImageContainer,
  StyledSpinner,
  MeasurementContainer,
  Measurement,
  Label,
  Value,
};
