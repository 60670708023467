import React from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import ActivitiesList from '../ActivitiesList';

const WorkoutDetails = ({
  activities,
}) => (
  <ActivitiesList activities={activities} />
);

WorkoutDetails.propTypes = {
  activities: PropTypes.array.isRequired,
};

export default compose(
  observer,
)(WorkoutDetails);
