import useSound from 'use-sound';

import { isNative } from '../../utils/platform';
import useNativeSound from './useNativeSound';

/**
 * Based on the current platform, use the custom hook to play sounds using the native plugin (only for Android and iOS)
 * or use the npm package use-sound (web target).
 */
export default isNative ? useNativeSound : useSound;
