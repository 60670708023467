import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { healthStats } from '../../../utils';
import {
  Option,
  OptionContainer,
  Title,
} from './styles';
import texts from './texts.json';

const OptionInput = ({
  initialValue,
  type,
  onOptionSelected,
}) => {
  const [selectedOption, setSelectedOption] = useState(initialValue);

  const options = useMemo(() => (
    texts.options[type]
      .reduce((prevSliderOptions, { text }) => ([...prevSliderOptions, text]), [])
  ), [
    type,
  ]);

  useEffect(() => {
    setSelectedOption(initialValue);
  }, [
    initialValue,
  ]);

  const handleOptionClick = useCallback((option) => {
    let currentOption = option;
    if (selectedOption === option) {
      currentOption = '';
    }
    setSelectedOption(currentOption);
    onOptionSelected(currentOption);
  }, [
    onOptionSelected,
    selectedOption,
  ]);

  return (
    <OptionContainer>
      {options.map((option) => (
        <Option
          key={option}
          onClick={() => handleOptionClick(option)}
          selected={option === selectedOption}
        >
          <Title>{option}</Title>
        </Option>
      ))}
    </OptionContainer>
  );
};

OptionInput.propTypes = {
  initialValue: PropTypes.string,
  type: PropTypes.string,
  onOptionSelected: PropTypes.func.isRequired,
};

OptionInput.defaultProps = {
  initialValue: null,
  type: healthStats.mood.name,
};

export default OptionInput;
