import React, { useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { IonAlert } from '@ionic/react';

import WorkoutContext from '../../../context/WorkoutContext';
import { ActivityTypes } from '../../models/BaseActivity';
import FormWrapper from '../FormWrapper';
import Input from '../Input';
import TextArea from '../TextArea';

import {
  StyledPageContent,
} from './styles';
import texts from './texts.json';

const CircuitEditor = ({
  activity,
  onClose,
  isEdit,
  onSaved,
  pathAttr,
}) => {
  const title = isEdit ? texts.editCircuit : texts.addCircuit;
  const { workoutDoc } = useContext(WorkoutContext);
  const [name, setName] = useState(activity.name || '');
  const [rounds, setRounds] = useState(activity.rounds || '');
  const [note, setNote] = useState(activity.note || '');
  const [showRoundsAlert, setShowRoundsAlert] = useState(false);

  const handleNameChange = ({ target: { value } }) => {
    setName(value);
  };

  const handleRoundsChange = ({ target: { value } }) => {
    setRounds(value);
  };

  const handleNoteChange = ({ target: { value } }) => {
    setNote(value);
  };

  const onCircuitSave = useCallback(async () => {
    const roundsNumber = Number(rounds || 1);

    const newDefinition = {
      name,
      note,
      rounds: roundsNumber,
      type: ActivityTypes.CIRCUIT,
      activities: [],
    };

    if (isEdit) {
      const editDefinition = {
        ...newDefinition,
      };
      if (activity.activities) {
        editDefinition.activities = activity.activities.map((activityItem) => ({ ...activityItem }));
      }
      await workoutDoc.editActivity([editDefinition], pathAttr, roundsNumber);
    } else {
      await workoutDoc.addCircuit(newDefinition, pathAttr);
    }

    onSaved();
  }, [
    workoutDoc,
    onSaved,
    pathAttr,
    name,
    note,
    isEdit,
    rounds,
    activity,
  ]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (rounds === '1') {
      setShowRoundsAlert(true);
    } else {
      onCircuitSave();
    }
  };

  const continueHandler = () => {
    setShowRoundsAlert(false);
    onCircuitSave();
  };

  const cancelHandler = () => {
    setShowRoundsAlert(false);
  };

  return (
    <StyledPageContent>
      <IonAlert
        isOpen={showRoundsAlert}
        backdropDismiss={false}
        header={texts.roundsAlert.header}
        message={texts.roundsAlert.message}
        buttons={[
          {
            text: texts.roundsAlert.cancelButton,
            role: 'cancel',
            handler: cancelHandler,
          },
          {
            text: texts.roundsAlert.okButton,
            handler: continueHandler,
          },
        ]}
      />
      <FormWrapper
        onClose={onClose}
        title={title}
        onSubmit={handleSubmit}
      >
        <Input
          label={texts.circuitName}
          name={texts.circuitName}
          type="text"
          value={name}
          onChange={handleNameChange}
          required
        />
        <Input
          label={texts.sets}
          name={texts.sets}
          type="number"
          value={rounds}
          onChange={handleRoundsChange}
          min={1}
          required
        />
        <TextArea
          name={texts.note}
          label={texts.note}
          value={note}
          onChange={handleNoteChange}
        />
      </FormWrapper>
    </StyledPageContent>
  );
};

CircuitEditor.propTypes = {
  activity: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  onSaved: PropTypes.func.isRequired,
  pathAttr: PropTypes.array.isRequired,
};

CircuitEditor.defaultProps = {
  activity: {},
};

export default CircuitEditor;
