import React from 'react';
import PropTypes from 'prop-types';
import ActivityFeedback from './ActivityFeedback';

const ActivityCardContainer = ({
  latestGameplaySession,
  activityFeedbacks,
  selectedActivityIndex,
}) => (
  <>
    {latestGameplaySession.coachFeedback && (
      <ActivityFeedback
        key={latestGameplaySession.id}
        name="Coach Feedback"
        note={latestGameplaySession.coachFeedback}
        isSelected={false}
      />
    )}
    {activityFeedbacks.map((feedback, index) => (
      feedback.coachFeedback
      && (
        <ActivityFeedback
          key={feedback.sessionID}
          name={feedback.name}
          note={feedback.coachFeedback}
          isSelected={selectedActivityIndex === index}
        />
      )
    ))}
  </>
);

ActivityCardContainer.propTypes = {
  latestGameplaySession: PropTypes.object.isRequired,
  activityFeedbacks: PropTypes.array.isRequired,
  selectedActivityIndex: PropTypes.number.isRequired,
};

export default ActivityCardContainer;
