import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as OpenArrow } from '../../../../../../../assets/icons/open-arrow.svg';
import events from '../../../../../../../utils/events';
import useChat from '../../../../../../../chat/hooks/useChat';

import {
  ExitButton,
  ViewWorkoutButton,
  LogActivityHistoryButton,
  ChatButton,
} from '../ActionButtons';

import {
  Container,
  MenuContainer,
  OpenMenuButton,
} from './styles';

const ActionsMenu = ({
  isOpen,
  onMenuOpen,
  onMenuClose,
  showWorkoutDetails,
  onExit,
  logActivityHistoryClicked,
  onChatButtonClicked,
}) => {
  useEffect(() => {
    if (isOpen) {
      window.addEventListener(events.CLICK, onMenuClose);
    } else {
      window.removeEventListener(events.CLICK, onMenuClose);
    }

    return () => {
      window.removeEventListener(events.CLICK, onMenuClose);
    };
  }, [isOpen, onMenuClose]);

  const { isChatEnabled } = useChat();

  return (
    <Container>
      <MenuContainer isMenuOpen={isOpen}>
        <ExitButton onClick={onExit} />
        <ViewWorkoutButton onClick={showWorkoutDetails} />
        <LogActivityHistoryButton onClick={logActivityHistoryClicked} />
        {isChatEnabled && <ChatButton onClick={onChatButtonClicked} />}
      </MenuContainer>
      <OpenMenuButton onClick={onMenuOpen} isMenuOpen={isOpen}>
        <OpenArrow />
      </OpenMenuButton>
    </Container>
  );
};

ActionsMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onMenuOpen: PropTypes.func.isRequired,
  onMenuClose: PropTypes.func.isRequired,
  showWorkoutDetails: PropTypes.func.isRequired,
  onExit: PropTypes.func.isRequired,
  logActivityHistoryClicked: PropTypes.func.isRequired,
  onChatButtonClicked: PropTypes.func.isRequired,
};

export default ActionsMenu;
