import styled, { css } from 'styled-components';
import { addAlpha } from '../../../utils/colorUtils';

import { ReactComponent as Avatar } from '../../../assets/avatar.svg';
import { ReactComponent as PopoverIcon } from '../../assets/icon/popover-icon.svg';

const channelContentHeight = 40;

const ChannelPreviewContainer = styled.button`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  padding: 10px;
  align-items: stretch;
  border-bottom: 1px solid ${({ theme }) => theme.colors.system.secondary5};

  ${({ isActive }) => isActive && css`
    background-color: ${({ theme }) => addAlpha(theme.colors.system.highlight, 0.4)};
  `}
  ${({ isAdmin }) => isAdmin && css`
    background-color: ${({ theme }) => theme.colors.base.secondary};
  `}
`;

const profilePicStyles = css`
  height: ${channelContentHeight}px;
  width: ${channelContentHeight}px;
  min-height: ${channelContentHeight}px;
  min-width: ${channelContentHeight}px;
  border-radius: 999px;
  margin-right: 10px;
`;

const ProfilePic = styled.img`
  ${profilePicStyles}
`;

const DefaultProfilePic = styled(Avatar)`
  ${profilePicStyles}
`;

const ChannelDescription = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  justify-content: space-evenly;
  overflow: hidden;
  min-height: ${channelContentHeight}px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  align-items: flex-start;
  justify-content: space-between;
  overflow: hidden;
  flex-grow: 1;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: flex-end;
`;

const ChannelName = styled.span`
  font-weight: bold;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: left;
`;

const LastMessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;

  ${({ transparent }) => transparent && css`
    color: transparent;
  `}
`;

const LastMessageText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 10px;
`;

const LastMessageDate = styled.span`
  flex-shrink: 0;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.system.gamma5};
  margin-top: auto;
`;

const IconContainer = styled.div`
  display: flex;
`;

const UnreadBadge = styled.span`
  border-radius: 999px;
  background-color: ${({ theme }) => theme.colors.system.highlight};
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UnreadText = styled.span`
  font-size: 10px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.system.alpha};
`;

const StyledChannelPopoverIcon = styled(PopoverIcon)`
  height: auto;
  width: 15px;
  padding: 5px 0;
`;

export {
  ChannelPreviewContainer,
  ProfilePic,
  DefaultProfilePic,
  ChannelDescription,
  TextContainer,
  InfoContainer,
  ChannelName,
  LastMessageContainer,
  LastMessageText,
  LastMessageDate,
  IconContainer,
  UnreadBadge,
  UnreadText,
  StyledChannelPopoverIcon,
};
