import React, { useContext } from 'react';

import LoadingPage from '../../components/LoadingPage';

import CheckInContextProvider from './CheckInContextProvider';
import CheckInContext from './CheckInContext';

/**
 * Inserts CheckInContextProvider in the react tree
 * before adding the given component.
 *
 * @param {Object} Component The component that needs the context provider.
 * @returns {Object} The component to render wrapped with the provider.
 */
const withCheckInContextProvider = (Component) => (props) => (
  <CheckInContextProvider>
    <Component {...props} />
  </CheckInContextProvider>
);

/**
 * Renders the given component when CheckInContext is ready.
 *
 * @param {Object} Component The component to render.
 * @param {Object} Component The rendered component.
 * @returns {Object} The component to render or the LoadingPage component.
 */
const withCheckInContextReady = (Component) => (props) => {
  const { isReady } = useContext(CheckInContext);
  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withCheckInContextReady,
  withCheckInContextProvider,
};
