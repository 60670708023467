import styled from 'styled-components';

import { subtitle2, caption2 } from '../../styles/text';
import Card from '../Card';

const StyledCard = styled(Card)`
  justify-content: space-around;
  padding-left: 0;
  padding-right: 0;
`;

const ResultContainer = styled.div`
  width: 50%;
`;

const Result = styled.div`
  ${subtitle2}
  width: 100%;
  font-size: 40px;
  line-height: 161.95%;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.base.primary};
`;

const Description = styled.div`
  ${caption2}
  width: 100%;
  text-align: center;
`;

export {
  StyledCard,
  ResultContainer,
  Result,
  Description,
};
