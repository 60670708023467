import styled from 'styled-components';

import { ReactComponent as AttentionIcon } from '../../../../assets/attention.svg';

const Content = styled.div`
  padding: 8px 16px;

`;

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.system.gamma1};

  &:first-child {
    border: none;
  }
`;

const StyledAttentionIcon = styled(AttentionIcon)`
  height: 80px;
  width: auto;
`;

export {
  Content,
  ContentSection,
  StyledAttentionIcon,
};
