import React, {
  useEffect,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { PushNotifications } from '@capacitor/push-notifications';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import moment from 'moment';

import NotificationModalContext, { withNotificationModalContextProvider } from '../../context/NotificationModalContext';
import { PermissionState } from '../../utils/permission';
import { isNative } from '../../utils/platform';
import UserContext from '../../context/UserContext';
import useAppSettings from '../../hooks/useAppSettings';
import FCMContext from '../../context/FCMContext';
import AppContext from '../../context/AppContext';
import texts from './texts.json';
import {
  Note,
  StyledAttentionIcon,
} from './styles';

const NOTIFICATION_MODAL_SHOWED_AT = 'notificationModalShowedAt';

const NotificationsPermissionsChecker = ({
  children,
}) => {
  const { pathname } = useLocation();

  const {
    isOpen,
    showNotificationModal,
    hideNotificationModal,
  } = useContext(NotificationModalContext);

  const { userConfigDoc } = useContext(UserContext);
  const {
    canUseNotifications,
    register: registerForNotifications,
  } = useContext(FCMContext);
  const { openAppSettings } = useAppSettings();
  const { isActive: isAppActive } = useContext(AppContext);

  const [isNotificationPermissionGranted, setIsNotificationPermissionsGranted] = useState(false);
  const [showOpenAppSettings, setShowOpenAppSettings] = useState(false);
  const [notificationPermissionsTimer, setNotificationPermissionsTimer] = useState(null);
  const [waitForAppNotificationPermission, setWaitForAppNotificationPermission] = useState(false);
  const [isReady, setIsReady] = useState(false);

  /**
   * Dismiss is called in either granting/no-granting scenarios as it is just
   * called whenever the modal is closed.
   */
  const onDismiss = useCallback(async () => {
    if (isNative) {
      const { receive } = await PushNotifications.checkPermissions();
      userConfigDoc.updateNotificationsPermissionConfig(receive === PermissionState.GRANTED);
    }
    setWaitForAppNotificationPermission(false);
    if (notificationPermissionsTimer) {
      clearTimeout(notificationPermissionsTimer);
    }
  }, [
    userConfigDoc,
    notificationPermissionsTimer,
  ]);

  const onAllow = useCallback(async () => {
    const { receive } = await PushNotifications.requestPermissions();
    if (receive === PermissionState.GRANTED) {
      setIsNotificationPermissionsGranted(true);
      hideNotificationModal();
    } else {
      // User didn't really allow so there's no other way to allow this now if it's not via app settings.
      setShowOpenAppSettings(true);
    }
  }, [
    hideNotificationModal,
  ]);

  const onOpenAppSettings = useCallback(() => {
    setWaitForAppNotificationPermission(true);
    openAppSettings();
  }, [openAppSettings]);

  /*
    Actively wait for the user to set up permission via app setting.
  */
  useEffect(() => {
    let shouldUpdate = true;
    const waitForPermissionsResponse = async () => {
      const { receive } = await PushNotifications.checkPermissions();
      if (shouldUpdate) {
        if (receive === PermissionState.GRANTED) {
          setIsNotificationPermissionsGranted(true);
          hideNotificationModal();
          setWaitForAppNotificationPermission(false);
        } else {
          const timerId = setTimeout(waitForPermissionsResponse, 500);
          setNotificationPermissionsTimer(timerId);
        }
      }
    };
    if (waitForAppNotificationPermission) {
      waitForPermissionsResponse();
    }
    return () => {
      shouldUpdate = false;
    };
  }, [
    waitForAppNotificationPermission,
    hideNotificationModal,
  ]);

  const showModal = useCallback(() => {
    localStorage.setItem(NOTIFICATION_MODAL_SHOWED_AT, moment().toISOString());
    showNotificationModal({
      title: texts.title,
      description: texts.askForNotificationPermissions,
      content: showOpenAppSettings && <Note>{texts.useAppSettingsForPermissions}</Note>,
      Icon: StyledAttentionIcon,
      actions: [
        showOpenAppSettings ? {
          label: texts.openAppSetting,
          action: () => {
            onOpenAppSettings();
          },
        } : {
          label: texts.enable,
          action: () => {
            onAllow();
          },
        },
      ],
    });
  }, [
    showNotificationModal,
    onAllow,
    onOpenAppSettings,
    showOpenAppSettings,
  ]);

  const updatePermissionConfig = useCallback((permissionGranted) => {
    userConfigDoc.updateNotificationsPermissionConfig(permissionGranted);
  }, [userConfigDoc]);

  /*
    Very first check of notifications permissions and trigger the permissions dialog if no permissions were
    provided just yet.
  */
  useEffect(() => {
    const init = async () => {
      if (canUseNotifications) {
        const { receive } = await PushNotifications.checkPermissions();
        const permissionGranted = receive === PermissionState.GRANTED;

        /**
         * We will update the permission granted flag if it's out of sync with application settings
         * whenever user freshly opens the app
         */
        if (userConfigDoc.areNotificationsPermissionGranted !== permissionGranted) {
          updatePermissionConfig(permissionGranted);
        }

        if (userConfigDoc.areNotificationsEnabled) {
          if (permissionGranted) {
            setIsNotificationPermissionsGranted(true);
          } else {
            showModal();
          }
        }
      }

      setIsReady(true);
    };

    init();
  }, [
    canUseNotifications,
    updatePermissionConfig,
    userConfigDoc.areNotificationsEnabled,
    userConfigDoc.areNotificationsPermissionGranted,
    showModal,
  ]);

  useEffect(() => {
    const pattern = /\/settings\/notifications$/;
    if (userConfigDoc.areNotificationsEnabled && !isNotificationPermissionGranted && pattern.test(pathname)) {
      showModal();
    }
  }, [
    pathname,
    isNotificationPermissionGranted,
    showModal,
    userConfigDoc.areNotificationsEnabled,
  ]);

  /*
    Initialize FCM module only when we detect that notifications are actually enabled via app setting
    and the app has the correct permissions for notifications to work.
  */
  useEffect(() => {
    if (userConfigDoc.areNotificationsEnabled && isNotificationPermissionGranted) {
      registerForNotifications();
    }
  }, [
    registerForNotifications,
    isNotificationPermissionGranted,
    userConfigDoc.areNotificationsEnabled,
    userConfigDoc.id,
  ]);

  useEffect(() => {
    if (!isOpen) {
      onDismiss();
    }
  }, [
    isOpen,
    onDismiss,
  ]);

  // Check push notification permissions every time the app becomes active
  useEffect(() => {
    if (isReady && isAppActive && !isNotificationPermissionGranted && userConfigDoc.areNotificationsEnabled) {
      const notificationModalShowedAt = localStorage.getItem(NOTIFICATION_MODAL_SHOWED_AT);
      if (notificationModalShowedAt) {
        const lastShown = moment(notificationModalShowedAt);
        const now = moment();
        // Show the modal again if it's been more than 24 hours since the last time it was shown
        if (now.diff(lastShown, 'hours') >= 24) {
          showModal();
        }
      }
    }
  }, [
    isReady,
    isAppActive,
    isNotificationPermissionGranted,
    showModal,
    userConfigDoc.areNotificationsEnabled,
  ]);

  return (
    <>
      {children}
    </>
  );
};

NotificationsPermissionsChecker.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  withNotificationModalContextProvider,
  observer,
)(NotificationsPermissionsChecker);
