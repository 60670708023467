import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  StyledThumbnail,
  BasicInfoWrapper,
  Name,
} from './styles';

const ShortExerciseItem = ({
  exercise,
  onClick,
  children,
}) => {
  const {
    name,
    videoPreviewThumbnail,
  } = exercise;

  return (
    <Container onClick={onClick}>
      {!!videoPreviewThumbnail && (
        <StyledThumbnail url={videoPreviewThumbnail} />
      )}
      <BasicInfoWrapper>
        <Name>{name}</Name>
      </BasicInfoWrapper>
      {children}
    </Container>
  );
};

ShortExerciseItem.propTypes = {
  exercise: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

ShortExerciseItem.defaultProps = {
  onClick: null,
  children: [],
};

export default ShortExerciseItem;
