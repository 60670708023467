import React, { useContext } from 'react';

import LoadingPage from '../../../LoadingPage';

import ExerciseOverrideSelectorContext from './ExerciseOverrideSelectorContext';

/**
 * Renders the given component when the context is ready.
 *
 * @param {Object} Component The component to render.
 * @param {Object} Component The rendered component.
 * @returns {Object} The component to render or the LoadingPage component.
 */
const withExerciseOverrideSelectorContextReady = (Component) => (props) => {
  const { isReady } = useContext(ExerciseOverrideSelectorContext);
  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withExerciseOverrideSelectorContextReady,
};
