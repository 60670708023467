import CollectionName from '../utils/collections';

import BaseDocument from './BaseDocument';

class Recipe extends BaseDocument {
  constructor(id, opts) {
    super(`${CollectionName.RECIPE}/${id}`, opts);
  }

  get image() {
    return this.data.image || '';
  }

  get totalCalories() {
    return this.data.totalCalories;
  }

  get name() {
    return this.data.name;
  }

  get description() {
    return this.data.description || '';
  }

  get protein() {
    return this.data.protein;
  }

  get carbs() {
    return this.data.carbs;
  }

  get fat() {
    return this.data.fat;
  }

  get proteinPercentage() {
    return this.data.proteinPercentage;
  }

  get carbsPercentage() {
    return this.data.carbsPercentage;
  }

  get fatPercentage() {
    return this.data.fatPercentage;
  }

  get ingredients() {
    return this.data.ingredients;
  }

  get instructions() {
    return this.data.instructions;
  }

  get preparationTime() {
    return this.data.preparationTime;
  }

  get cookingTime() {
    return this.data.cookingTime;
  }

  get allergenTags() {
    return this.data.allergenTags || [];
  }

  get tags() {
    return this.data.tags || [];
  }

  get servings() {
    return this.data.servings;
  }

  get isScalingDisabled() {
    return this.data.isScalingDisabled || false;
  }
}

export default Recipe;
