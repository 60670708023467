import { useMemo, useState } from 'react';

import { getQueryVariable } from '../../../utils/queryParams';

const useHeaderParamConfig = () => {
  /*
    NOTE: this should be used only in view-as mode to hide the header actions (back and custom actions)
    and prevent the coach to interact with it.
  */
  const [enableHeaderActions] = useState(() => {
    const showActions = getQueryVariable('pageHeaderActions');
    return !showActions || showActions !== 'false';
  });

  return useMemo(() => ({
    enableHeaderActions,
  }), [
    enableHeaderActions,
  ]);
};

export default useHeaderParamConfig;
