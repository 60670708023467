import styled, { css, keyframes } from 'styled-components';

const getStyles = ({
  color,
  borderColor,
  showBorder,
  theme,
}) => {
  const viewportColor = color || theme.colors.system.alpha;
  const viewportBorderColor = borderColor || 'rgba(191,252,135,0.6)';
  const border = `30px solid ${viewportBorderColor}`;

  return css`
    color: ${viewportColor};
    border: ${showBorder ? border : 'none'};
  `;
};

const fadeInAnimation = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1; }
`;

const StyledCameraViewportMessage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 100px;
  text-transform: uppercase;
  text-shadow: 0px 4px 18px rgba(0, 0, 0, 0.8);
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.4s;
  animation: ${fadeInAnimation} 0.3s;

  ${(props) => getStyles(props)}
`;

export {
  StyledCameraViewportMessage,
};
