import React, {
  useContext,
  useEffect,
  useRef,
} from 'react';

import { SlideDrawerAlignment } from '../../../../../../components/SlideDrawer';
import WorkoutInfoSwitcher from '../../../../../../components/WorkoutInfoSwitcher';
import WorkoutExecutionInfoContext from '../../../../context/WorkoutExecutionInfoContext';
import GameplayMenuContext from '../../../../context/GameplayMenuContext';
import GesturePriority from '../../../../utils/gesturePriorities';
import WorkoutDetailsSection from './WorkoutDetailsSection';
import { StyledSlideDrawer } from './styles';

const WorkoutDetailsMenu = () => {
  const {
    isMenuOpen,
    selectedSection,
    onGameplayMenuClose,
  } = useContext(GameplayMenuContext);

  const { activity } = useContext(WorkoutExecutionInfoContext);

  const WorkoutInfoSwitcherRef = useRef();

  useEffect(() => {
    if (isMenuOpen) {
      const {
        setCurrentActivity,
        ContentSwitcher: {
          goToSectionByName,
        },
      } = WorkoutInfoSwitcherRef.current;

      setCurrentActivity(activity);
      goToSectionByName(selectedSection);
    }
  }, [
    activity,
    isMenuOpen,
    selectedSection,
  ]);

  return (
    <StyledSlideDrawer
      isOpen={isMenuOpen}
      onClose={onGameplayMenuClose}
      alignment={SlideDrawerAlignment.RIGHT}
      closeGesturePriority={GesturePriority.CLOSE_SLIDE_DRAWER}
      fullHeightOnLandscape
    >
      <WorkoutInfoSwitcher
        WorkoutRoutineComponent={WorkoutDetailsSection}
        ref={WorkoutInfoSwitcherRef}
      />
    </StyledSlideDrawer>
  );
};

export default WorkoutDetailsMenu;
