import React, { useContext } from 'react';

import useSessionStore from '../../hooks/useSessionStore';
import UserContext from '../../context/UserContext';
import FirebaseContext from '../../context/FirebaseContext';
import logEvent from '../../utils/logger';
import NativeMediaRecorder from './NativeMediaRecorder';

const withChatProps = (Component) => (props) => {
  const { firebase } = useContext(FirebaseContext);
  const { userId: chatUserId } = useContext(UserContext);
  const sessionStore = useSessionStore();
  const nativeMediaRecorder = new NativeMediaRecorder();

  const chatProps = {
    firebase,
    sessionStore,
    chatUserId,
    mediaRecorder: nativeMediaRecorder,
    logEvent,
  };

  return <Component {...props} chatProps={chatProps} />;
};

export default withChatProps;
