import { ChannelBuckets } from './buckets';

const channelConfiguration = {
  options: {
    state: true,
    watch: true,
    presence: true,
    limit: 20,
  },
  sort: {
    has_unread: -1,
  },
  filters: (userId, selectedBucket = ChannelBuckets.ACTIVE) => {
    const baseFilters = {
      type: 'messaging',
      members: { $in: [userId] },
    };
    if (selectedBucket === ChannelBuckets.ACTIVE) {
      return {
        ...baseFilters,
        hidden: false,
      };
    }
    if (selectedBucket === ChannelBuckets.ARCHIVED) {
      return {
        ...baseFilters,
        hidden: true,
        archived: true,
      };
    }
    return {
      ...baseFilters,
      $or: [{ hidden: true }, { hidden: false }],
    };
  },
  maxFileUploadLimit: 10,
};

export default channelConfiguration;
