import merge from 'lodash.merge';

import PoseDetectionResult from '../../pose/PoseDetectionResult';
import PoseReasons from '../../pose/poseReasons';
import { FlowJoint } from '../../models/FlowHuman';
import { isPointInBoundingBox } from '../../utils/math';
import { defaultHandActionArea } from '../actionAreas/handActionArea';

const wristInRightArea = (opts = {}) => {
  const defaultOptions = {
    boundingBoxConfig: {
      actionArea: defaultHandActionArea,
    },
    xBodyMaxDiff: 0.15,
    armAngleMin: 55,
    armAngleMax: 135,
  };

  const options = merge({ ...defaultOptions }, opts);

  /*
    Check if the user has the arm extended towards VNB area, to minimize false triggers.
    Also fails if we didn't detect the elbow (lowerArmAngle undefined).
  */
  const handInAreaAndAngled = (wristPosition, boundingBox, lowerArmAngleFromY) => (
    isPointInBoundingBox(wristPosition, boundingBox)
      && lowerArmAngleFromY >= options.armAngleMin
      && lowerArmAngleFromY <= options.armAngleMax);

  return (flowHuman, processingArea) => {
    const res = new PoseDetectionResult();

    const lWristPosition = flowHuman.baseCoords(FlowJoint.LWrist);
    const rWristPosition = flowHuman.baseCoords(FlowJoint.RWrist);
    const [shouldersMidPointX] = flowHuman.shouldersMiddlePoint;
    const [lLowerArmAngleFromY, rLowerArmAngleFromY] = flowHuman.lowerArmAnglesFromY;

    const {
      xBodyMaxDiff,
      boundingBoxConfig: {
        actionAreaBoundingBox,
        actionArea,
      },
    } = options;

    const boundingBox = actionAreaBoundingBox(actionArea, processingArea);

    /*
      Check if the body, measured by the shoulders middle point is closer to the
      right area
    */
    if (shouldersMidPointX) {
      const shoulderToBoundingBoxDiffX = Math.abs(shouldersMidPointX - boundingBox.xMax);

      res.debugData.shoulderToBoundingBoxDiffX = shoulderToBoundingBoxDiffX;

      if (shoulderToBoundingBoxDiffX <= xBodyMaxDiff) {
        res.addReason(PoseReasons.GenericNotInPose_Fail);
      }
    }

    // Check if any wrist is in the given area of the frame
    const isAnyHandOnRightArea = handInAreaAndAngled(lWristPosition, boundingBox, lLowerArmAngleFromY)
      || handInAreaAndAngled(rWristPosition, boundingBox, rLowerArmAngleFromY);

    if (!isAnyHandOnRightArea) {
      res.addReason(PoseReasons.WristNotInArea_Fail);
    }

    return res;
  };
};

export {
  wristInRightArea,
};
