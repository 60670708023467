import styled, { css } from 'styled-components';

import Card from '../../../../components/Card';
import Note from '../../../../components/Note';

import colors from '../../../../styles/baseColors';
import {
  body1,
  body2,
  caption2,
} from '../../../../styles/text';

const topConnector = css`
  &:before {
    content: '';
    display: block;
    border-right: 1px solid ${colors.system.beta};
    height: 20px;
    position: absolute;
    top: 0;
    left: 50%;
  }
`;

const bottomConnector = css`
  &:after {
    content: '';
    display: block;
    border-right: 1px solid ${colors.system.beta};
    height: calc(100% - 50px);
    position: absolute;
    bottom: 0;
    left: 50%;
  }
`;

const connectorStyles = ({
  showTopConnector,
  showBottomConnector,
}) => css`
  ${(showTopConnector ? topConnector : null)}
  ${(showBottomConnector ? bottomConnector : null)}
`;

const ActivityContainer = styled.div`
  position: relative;
  display: flex;
`;

const ActivityTagContainer = styled.div`
  width: 30px;
  margin-right: 10px;
  position: absolute;
  left: 10px;
  height: 100%;
`;

const ActivityTag = styled.div`
  color: ${colors.base.primary};
  background: ${colors.system.background};
  border: 1px solid ${colors.base.primary};
  border-radius: 50%;
  min-width: 30px;
  height: 30px;
  margin-top: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  ${caption2}

  ${(props) => connectorStyles(props)}
`;

const ActivityCard = styled(Card)`
  margin-bottom: 8px;
  padding: 24px 52px;
  flex-direction: column;
`;

const ActivityName = styled.div`
  ${body2}
  margin-bottom: 5px;
  text-transform: capitalize;
  color: ${colors.base.primary};
`;

const Requirements = styled.div`
  display: flex;
`;

const ActivityRequirement = styled.div`
  ${body2}
  color: ${colors.base.primary};
  padding: 3px 5px;
  margin-right: 5px;
  border-radius: 6px;
  width: fit-content;
  background: ${colors.base.tertiary};
`;

const ActivityNote = styled(Note)`
  opacity: 0.4;
  margin: 20px 0 0;
  font-size: 12px;
  line-height: 18px;
`;

const RestNote = styled.div`
  ${body1}
  font-size: 12px;
  line-height: 18px;
  opacity: 0.4;
  margin-top: 10px;
  padding-top: 11px;
  border-top: 1px solid ${colors.system.gamma2};
`;

const ActivityInfo = styled.div`
  display: flex;
`;

const BasicInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const ThumbnailWrapper = styled.div`
  width: 60px;
  height: 60px;
  margin-right: 8px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: solid 1px rgba(11, 28, 79, 0.4);
  border-radius: 6px;
`;

const Thumbnail = styled.img`
  max-width: 140%;
`;

export {
  ActivityContainer,
  ActivityTagContainer,
  ActivityTag,
  ActivityCard,
  ActivityName,
  Requirements,
  ActivityRequirement,
  ActivityNote,
  RestNote,
  ActivityInfo,
  BasicInfoWrapper,
  ThumbnailWrapper,
  Thumbnail,
};
