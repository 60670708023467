import styled from 'styled-components';

const Container = styled.div`
  > * {
    margin-bottom: 8px;
  }
`;

export {
  Container,
};
