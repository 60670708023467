import React from 'react';
import PropTypes from 'prop-types';

import VideoPlayer from '../../../../components/VideoPlayer';
import Input from '../../Input';
import {
  VideoContainer,
} from './styles';
import texts from './texts.json';

const ExerciseVideo = ({
  videoUrl,
}) => (
  <>
    <Input
      type="text"
      name={texts.videoUrl}
      label={texts.videoUrl}
      value={videoUrl || ''}
      disabled
    />
    {!!videoUrl && (
      <VideoContainer>
        <VideoPlayer url={videoUrl} controls />
      </VideoContainer>
    )}
  </>
);

ExerciseVideo.propTypes = {
  videoUrl: PropTypes.string,
};

ExerciseVideo.defaultProps = {
  videoUrl: '',
};

export default ExerciseVideo;
