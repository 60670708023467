import React, {
  useCallback,
  useContext,
  useState,
  useMemo,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { withVideoPlayerContextProvider } from '../../../components/VideoPlayer';
import WorkoutContext from '../../../context/WorkoutContext';
import { ActivityTypes } from '../../models/BaseActivity';
import ExerciseContext from '../../context/ExerciseContext';
import {
  Side,
  SideLabel,
} from '../../utils/sides';
import {
  TimeUnit,
  convertSecondsTo,
  getSecondsFrom,
} from '../../utils/time';
import Input from '../Input';
import TextArea from '../TextArea';
import Select from '../Select';
import FormWrapper from '../FormWrapper';
import DurationInput from '../DurationInput';
import ExerciseSelector from './ExerciseSelector';
import TagsSelector from './TagsSelector';
import SideSelector from './SideSelector';
import ExerciseVideo from './ExerciseVideo';

import {
  ExerciseContainer,
  StyledPageContent,
} from './styles';
import texts from './texts.json';

const ActivityEditor = ({
  activity,
  onClose,
  isEdit,
  pathAttr,
  onSaved,
}) => {
  const { workoutDoc } = useContext(WorkoutContext);
  const {
    isReady: isExerciseReady,
  } = useContext(ExerciseContext);

  // The type of exercise we're editing
  const [type, setType] = useState(() => (
    activity.type ? ({ value: activity.type, label: activity.type }) : null
  ));

  // The information of the exercise selected
  const [exerciseItem, setExerciseItem] = useState(activity ? { label: activity.name, value: activity } : null);

  // The tags that were selected for filtering the exercise list
  const [selectedTags, setSelectedTags] = useState([]);

  // The duration of the activity (only TIMED AND REST)
  const [duration, setDuration] = useState(convertSecondsTo(activity.duration, activity.durationDisplayUnit) || '');

  // The duration unit
  const [durationDisplayUnit, setDurationDisplayUnit] = useState({
    label: activity?.durationDisplayUnit || TimeUnit.SECONDS,
    value: activity?.durationDisplayUnit || TimeUnit.SECONDS,
  });

  // The number of repetitions of the activity (only REPS based activities)
  const [repetitions, setRepetitions] = useState(activity.repetitions || '');

  // The amount of time to rest after this exercise
  const [restTime, setRestTime] = useState(convertSecondsTo(activity.restTime, activity.restTimeDisplayUnit) || '');

  // The rest time unit
  const [restTimeDisplayUnit, setRestTimeDisplayUnit] = useState({
    label: activity?.restTimeDisplayUnit || TimeUnit.SECONDS,
    value: activity?.restTimeDisplayUnit || TimeUnit.SECONDS,
  });

  // Visible notes to the client. The coach can add any notes they want, like extra directions, reminders, etc.
  const [note, setNote] = useState(activity.note || '');

  // The side in which this activity needs to be done. options: LEFT, RIGHT, ALTERNATE, FIRST LEFT THEN RIGHT
  const [side, setSide] = useState(() => (
    activity.side ? ({ value: activity.side, label: SideLabel[activity.side] }) : null
  ));

  // Compute whether this activity is inside a circuit
  const isActivityInsideCircuit = useMemo(() => (
    pathAttr.length === 2 && !!workoutDoc.jsonActivities()[pathAttr[0]].name
  ), [pathAttr, workoutDoc]);

  // Compute the number of rounds set for this activity
  const [rounds, setRounds] = useState(() => {
    const activities = workoutDoc.jsonActivities();
    if (!isActivityInsideCircuit && activity.type !== 'REST') {
      const circuitWrapper = activities[pathAttr[0]];
      return activity.rounds || (circuitWrapper && circuitWrapper.rounds) || '';
    }
    return '';
  });

  useEffect(() => {
    if (isExerciseReady && isEdit && !exerciseItem) {
      const item = {
        value: activity.name,
        label: activity.name,
      };
      setExerciseItem(item);
    }
  }, [
    isExerciseReady,
    isEdit,
    exerciseItem,
    activity.name,
  ]);

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    let activityDefinition;
    const roundsNumber = Number(rounds || 1);

    // Transform the string coming from the input, into a number
    let durationValue = Number(duration);

    // If the unit is minutes, then we need to adjust the value. The document will always save seconds.
    durationValue = getSecondsFrom(durationValue, durationDisplayUnit.value);

    if (type && type.value === ActivityTypes.REST) {
      activityDefinition = {
        type: ActivityTypes.REST,
        duration: durationValue,
        durationDisplayUnit: durationDisplayUnit.value,
      };
    } else {
      const {
        name,
        videoUrl = '',
        videoPreviewUrl = '',
        videoPreviewThumbnail = '',
        description = '',
        id: exerciseId = '',
        tags = [],
      } = exerciseItem.value;

      // Transform the rest string coming from the input, into a number
      let restValue = Number(restTime);

      // If the unit is minutes, then we need to adjust the value. The document will always save seconds.
      restValue = getSecondsFrom(restValue, restTimeDisplayUnit.value);

      activityDefinition = {
        name,
        note,
        type: type.value,
        restTime: restValue,
        restTimeDisplayUnit: restTimeDisplayUnit.value,
        videoUrl,
        videoPreviewUrl,
        videoPreviewThumbnail,
        description,
        side: side ? side.value : '',
        exerciseId,
        tags: tags.slice(),
      };
      if (type && type.value === ActivityTypes.TIMED) {
        activityDefinition.duration = durationValue;
        activityDefinition.durationDisplayUnit = durationDisplayUnit.value;
      } else if (type && type.value === ActivityTypes.REPETITIONS) {
        activityDefinition.repetitions = Number(repetitions);
      }
    }

    /*
      TODO: This shouldn't be done here. We need to refactor the ActivityEditor, which shouldn't know about this rule.
      The EditableWorkout abstraction could be the one doing this. Mobile issue 1187
    */
    const activities = [];
    if (activityDefinition.type === ActivityTypes.TIMED && activityDefinition.side === Side.LEFT_THEN_RIGHT_SIDE) {
      // split into two activities when the coach selects LEFT_THEN_RIGHT_SIDE on a TIMED activity.
      [Side.LEFT_SIDE, Side.RIGHT_SIDE].forEach((sideValue) => {
        const newActivity = {
          ...activityDefinition,
          side: sideValue,
        };
        activities.push(newActivity);
      });
    } else {
      activities.push({
        ...activityDefinition,
      });
    }

    if (isEdit) {
      await workoutDoc.editActivity(activities, pathAttr, roundsNumber);
    } else {
      const preparedActivities = activities.reduce((prepActivities, act) => {
        let newActivity = act;
        // Wrapping activity with Circuits if not inside activity. This should also be refactored with mobile issue 1187
        if (!isActivityInsideCircuit && type.value !== ActivityTypes.REST) {
          newActivity = {
            type: ActivityTypes.CIRCUIT,
            rounds: roundsNumber,
            activities: [act],
          };
        }

        return [
          ...prepActivities,
          newActivity,
        ];
      }, []);

      await workoutDoc.addActivity(preparedActivities, pathAttr);
    }

    onSaved();
  }, [
    workoutDoc, onSaved, pathAttr, repetitions,
    type, side, restTime, restTimeDisplayUnit, note, isEdit, rounds,
    duration, durationDisplayUnit, isActivityInsideCircuit, exerciseItem,
  ]);

  const handleActivityChange = useCallback((itemValue) => {
    setExerciseItem(itemValue || null);
  }, []);

  const handleRepetitionsChange = useCallback(({ target: { value } }) => {
    setRepetitions(value);
  }, [setRepetitions]);

  const handleRoundsChange = useCallback(({ target: { value } }) => {
    setRounds(value);
  }, [setRounds]);

  const handleDurationChange = useCallback(({ target: { value } }) => {
    setDuration(value);
  }, [setDuration]);

  const handleTypeChange = useCallback((selectedOption) => {
    setType(selectedOption);
  }, [setType]);

  const handleRestTimeChange = useCallback(({ target: { value } }) => {
    setRestTime(value);
  }, [setRestTime]);

  const handleNoteChange = useCallback(({ target: { value } }) => {
    setNote(value);
  }, [setNote]);

  const handleSideChange = (selectedOption) => {
    setSide(selectedOption);
  };

  const typesOptions = useMemo(() => {
    const ACTIVITY_TYPES = [ActivityTypes.TIMED, ActivityTypes.REPETITIONS, ActivityTypes.REST];

    return ACTIVITY_TYPES.map((item) => ({
      value: item,
      label: item,
    }));
  }, []);

  const renderObjectiveField = () => {
    if (!type) {
      return null;
    }

    if (type.value === ActivityTypes.REPETITIONS) {
      return (
        <Input
          type="number"
          value={repetitions}
          min={1}
          onChange={handleRepetitionsChange}
          label={side ? texts.repetitionsPerSide : texts.repetitions}
          name={texts.repetitions}
          required
        />
      );
    }

    return (
      <DurationInput
        value={duration}
        unitValue={durationDisplayUnit}
        onInputChange={handleDurationChange}
        onUnitChange={setDurationDisplayUnit}
        label={texts.duration}
        name={texts.duration}
        min={1}
        isRequired
      />
    );
  };

  return (
    <StyledPageContent>
      <FormWrapper
        onClose={onClose}
        title={isEdit ? texts.editActivity : texts.addActivity}
        onSubmit={handleSubmit}
      >
        <Select
          label={texts.activityType}
          name={texts.activityType}
          value={type}
          isClearable="true"
          options={typesOptions}
          onChange={handleTypeChange}
          required
        />

        {(!!type && type.value !== ActivityTypes.REST) && (
          <>
            <ExerciseContainer>
              <TagsSelector
                selectedTags={selectedTags}
                onChange={setSelectedTags}
              />
              <ExerciseSelector
                selectedExercise={exerciseItem}
                onChange={handleActivityChange}
                selectedTags={selectedTags}
              />
            </ExerciseContainer>
            <SideSelector
              side={side}
              onChange={handleSideChange}
            />
            <Input
              type="number"
              placeholder={isActivityInsideCircuit ? texts.roundsCircuitPlaceholder : ''}
              value={rounds}
              disabled={isActivityInsideCircuit}
              onChange={handleRoundsChange}
              required={isActivityInsideCircuit}
              min={1}
              label={texts.sets}
              name={texts.sets}
            />
          </>
        )}

        {renderObjectiveField()}

        {!!type && type.value !== ActivityTypes.REST && (
          <>
            <DurationInput
              value={restTime}
              unitValue={restTimeDisplayUnit}
              onInputChange={handleRestTimeChange}
              onUnitChange={setRestTimeDisplayUnit}
              label={texts.restTime}
              name={texts.restTime}
              min={0}
            />
            <TextArea
              name={texts.note}
              label={texts.note}
              value={note}
              onChange={handleNoteChange}
            />
            <ExerciseVideo
              videoUrl={exerciseItem?.value?.videoPreviewUrl || exerciseItem?.value?.videoUrl}
            />
          </>
        )}
      </FormWrapper>
    </StyledPageContent>
  );
};

ActivityEditor.propTypes = {
  activity: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  pathAttr: PropTypes.array.isRequired,
  onSaved: PropTypes.func.isRequired,
};
ActivityEditor.defaultProps = {
  activity: {},
};

export default compose(
  withVideoPlayerContextProvider,
)(ActivityEditor);
