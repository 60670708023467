import styled from 'styled-components';

import { subtitle2 } from '../../styles/text';
import Card from '../Card';

const StyledCard = styled(Card)`
  justify-content: space-around;
  padding-left: 0;
  padding-right: 0;
`;

const CountdownContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Time = styled.div`
  ${subtitle2}
  width: 64px;
  height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
  line-height: 136%;
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.colors.base.primary};
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.system.background};
  margin: 0 10px;
`;

const TimeDescription = styled.p`
  font-size: 12px;
  line-height: 136%;
  margin: 0;
`;

export {
  StyledCard,
  CountdownContainer,
  Time,
  TimeDescription,
};
