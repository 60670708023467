import styled from 'styled-components';

const StyledNotesBelt = styled.div`
  width: fit-content;
  height: 55px;
  background: rgba(0, 0, 0, 0.5);
  color: ${({ theme }) => theme.colors.system.alpha};
  border-radius: 10px;
  padding: 5px 10px 0;
`;

const NotesTitle = styled.div`
  font-size: 11px;
  text-transform: uppercase;
`;

const NotesWrapper = styled.div`
  display: flex;
  font-size: 28px;
  font-weight: bold;
  align-items: center;
`;

const WeightNote = styled.div`
  text-align: left;
`;

const RepsNote = styled.div`
  text-align: right;
`;

const Separator = styled.div`
  width: 2px;
  height: 25px;
  margin: 0 10px;
  background-color: ${({ theme }) => theme.colors.system.alpha};
  opacity: 0.24;
`;

export {
  StyledNotesBelt,
  NotesTitle,
  NotesWrapper,
  WeightNote,
  RepsNote,
  Separator,
};
