import styled, { css } from 'styled-components';

import { safeAreaFromBottom } from '../../../../../../../styles/mixins';
import { RoundButton } from '../styles';

const Container = styled.div`
  overflow: visible;
  width: 54px;
  height: 90px;
  padding-bottom: ${safeAreaFromBottom()};

  ${({ theme }) => theme.isPortrait && css`
    height: 75px;
  `};
`;

const MenuContainer = styled.div`
  width: 300px;
  height: calc(90px + ${safeAreaFromBottom()});
  right: 0;
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  padding: 12px 0;
  color: ${({ theme }) => theme.colors.system.beta};
  background: rgba(0, 0, 0, 0.5);
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  transition: transform 0.2s ease-in-out;

  ${({ theme }) => theme.isPortrait && css`
    width: 100%;
    left: 0;
  `}

  transform: translateY(${({ isMenuOpen }) => (isMenuOpen ? 0 : `calc(100px + ${safeAreaFromBottom()})`)});
`;

const OpenMenuButton = styled(RoundButton)`
  position: absolute;
  right: 0;
  top: 10px;
  transition: all 0.2s ease-in-out;

  ${({ theme }) => theme.isPortrait && css`
    top: 0;
  `}

  transform: translateY(${({ isMenuOpen }) => (isMenuOpen ? `calc(100px + ${safeAreaFromBottom()})` : 0)});
`;

export {
  Container,
  MenuContainer,
  OpenMenuButton,
};
