import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: 20px 0;
  background-color: ${({ theme }) => theme.colors.system.alpha};
`;

export {
  Container,
};
