import styled from 'styled-components';
import { fontFamily } from '../../styles/text';
import CloseButton from '../CloseButton';

const borderRadiusValue = '11px';

const StyledOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  padding: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledModal = styled.div`
  position: fixed;
  max-width: 350px;
  width: 85vw;
  height: fit-content;
  border-radius: ${borderRadiusValue};
  box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  background: white;
  padding: 0;
  margin: auto;
  box-sizing: border-box;
`;

const ModalDescription = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  text-align: center;
  flex-direction: column;
  height: 100%;
  gap: 20px;
`;

const ModalHeader = styled.h1`
  padding-top: 60px;
  font-family: ${fontFamily.serif};
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.base.primary};
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: fit-content;
  width: 100%;
  padding: 0 22px;
`;

const ActionsContainer = styled.div`
  *:first-child {
    border-bottom-left-radius: ${borderRadiusValue};
  }
  *:last-child {
    border-bottom-right-radius: ${borderRadiusValue};
  }
  
  margin-top: 30px;
  width: 100%;
  height: 58px;
  display: flex;
  justify-content: stretch;
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 2;
`;

const StyledCloseButton = styled(CloseButton)`
  border-radius: 100%;
  position: absolute;
  right: -18px;
  top: -18px;
  width: 36px;
  height: 36px;
  background-color: ${({ theme }) => theme.colors.base.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 3;

  &:hover {
    background-color: ${({ theme }) => theme.colors.shades.primary80};
  }
`;

export {
  StyledOverlay,
  StyledModal,
  ModalContent,
  StyledCloseButton,
  ModalDescription,
  ModalHeader,
  ActionsContainer,
  IconWrapper,
};
