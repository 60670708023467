import React, {
  useContext,
} from 'react';
import format from 'string-template';

import CoachInfoContext from '../../../../context/CoachInfoContext';
import {
  TextContent,
  Title,
  Subtitle,
  StyledCard,
  HeroImageContainer,
  Backdrop,
} from './styles';

import texts from './texts.json';

const CoachCard = () => {
  const { coachInfo } = useContext(CoachInfoContext);

  return (
    <StyledCard>
      <HeroImageContainer url={coachInfo.coachAssistant?.avatarUrl || coachInfo.avatar} />
      <Backdrop />
      <TextContent>
        <Title>{format(texts.title, { coachName: coachInfo.coachAssistant?.name || coachInfo.name })}</Title>
        <Subtitle>{texts.subTitle}</Subtitle>
      </TextContent>
    </StyledCard>
  );
};

export default CoachCard;
