import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { compose } from 'recompose';

import config from '../../config';
import { withLayout } from '../../components/Layout';

import HostedPage from './HostedPage';

const HostedPageContainer = () => {
  const { appUrl } = config;
  const { params: { hostedPage } } = useRouteMatch();
  const hostedPageURL = `${appUrl}/${hostedPage}`;
  return <HostedPage src={hostedPageURL} />;
};

export default compose(
  withLayout,
)(HostedPageContainer);
