import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as InfoIcon } from '../../assets/info-icon.svg';
import MessageBannerContext from '../../context/MessageBannerContext';
import { BannerType } from '../../context/MessageBannerContext/utils';
import {
  Container,
  IconContainer,
} from './styles';

const MessageBanner = ({
  staticBanner,
}) => {
  const {
    messageText,
    onClick,
    bannerType,
  } = useContext(MessageBannerContext);

  return (
    <Container
      onClick={onClick}
      type={bannerType}
      staticBanner={staticBanner}
    >
      {bannerType === BannerType.INFO && (
        <IconContainer>
          <InfoIcon />
        </IconContainer>
      )}
      {messageText}
    </Container>
  );
};

MessageBanner.propTypes = {
  staticBanner: PropTypes.bool,
};

MessageBanner.defaultProps = {
  staticBanner: false,
};

export default MessageBanner;
