import React from 'react';
import {
  Route,
  Redirect,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import useSessionStore from '../hooks/useSessionStore';
import StandaloneWorkoutEditorDetails from '../workoutEditor/pages/StandaloneWorkoutEditorDetails';
import CreateWorkout from '../workoutEditor/pages/CreateWorkout';
import { ActivityDetailsContextProvider } from '../components/ActivityDetailsModal';

/**
 * Routes accessible only by coaches.
 */
const CoachRoutes = () => {
  const {
    path,
  } = useRouteMatch();
  const {
    isCoachOrAdmin,
  } = useSessionStore();

  // If the user trying to get access to this page is not a coach or an admin, then redirect to the root.
  if (!isCoachOrAdmin) {
    return <Redirect to={{ pathname: '/' }} />;
  }

  return (
    <ActivityDetailsContextProvider
      enableExerciseOverride={false}
    >
      <Switch>
        <Route exact path={`${path}/w/:workoutId`} component={StandaloneWorkoutEditorDetails} />
        <Route exact path={`${path}/w`} component={CreateWorkout} />
        <Route exact path={`${path}/wa/:assignmentId`} component={StandaloneWorkoutEditorDetails} />
      </Switch>
    </ActivityDetailsContextProvider>
  );
};

export default compose(
  observer,
)(CoachRoutes);
