import styled from 'styled-components';

import Button from '../Button';
import { fontFamily } from '../../styles/text';
import { ReactComponent as ArrowDown } from '../../assets/angle-arrow.svg';

const SliderCardContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: end;
  width: 100%;
  height: 300px;
  padding-left: 20px;
  padding-right: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
`;

const ReportRecipeButton = styled(Button)`
  display: flex;
  border-radius: 50px;
  font-weight: 450;
  width: fit-content;
  height: fit-content;
  padding: 10px 14px;
  font-size: 14px;
  line-height: 15px;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const titleStyles = `
  font-family: ${fontFamily.serif};
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  margin-top: 20px;
  padding: 0 24px;
`;

const Title = styled.div`
  ${titleStyles}
  color: ${({ theme }) => theme.colors.base.primary};
`;

const ProblemSelectTitle = styled.div`
  color: ${({ theme }) => theme.colors.base.primary};
  ${titleStyles}
  padding-bottom: 30px;
`;

const Subtitle = styled.div`
  font-family: 'Neue Montreal';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.base.primary};
  padding: 4px 24px;
  text-align: center;
`;

const StyledButton = styled(Button)`
  display: flex;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.system.success2}20;
  color: ${({ theme }) => theme.colors.system.beta};
  border: 1px solid ${({ theme }) => theme.colors.system.success2}40;
  font-weight: 450;
  width: 100%;
  height: 60px;
  font-size: 15px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;

  &:last-of-type {
    margin-bottom: 60px;
  }
`;

const StyledArrow = styled(ArrowDown)`
  size: 20px;
  transform: rotate(-90deg);
`;

const StyledInput = styled.textarea`
  padding: 15px 15px;
  margin-bottom: 25px;
  margin-top: 15px;
  background: ${({ theme }) => theme.colors.system.background};
  border: none;
  line-height: 25px;
  width: 100%;
  font-size: 14px;
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.system.gamma2};
    outline: none;
  }

  &:focus::placeholder {
    color: transparent;
  }
`;

const SubmitButton = styled(Button)`
  margin-bottom: 25px;
`;

const TextContainer = styled.div`
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: end;
`;

export {
  SliderCardContainer,
  ButtonContainer,
  ReportRecipeButton,
  Title,
  ProblemSelectTitle,
  Subtitle,
  StyledButton,
  StyledArrow,
  StyledInput,
  SubmitButton,
  TextContainer,
};
