import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useChatContext } from 'stream-chat-react';
import ChatContext from '../../../context';
import ChannelPreview from '../../ChannelPreview';

const CustomResultItem = ({
  result: channel,
  selectResult,
}) => {
  const {
    onSearch,
    toggleChatMenu,
  } = useContext(ChatContext);
  const { channel: activeChannel } = useChatContext();
  const isActive = !!channel && channel.id === activeChannel?.id;
  const isAdmin = !!channel?.data?.admin;

  const handleClick = useCallback(() => {
    selectResult(channel);
    toggleChatMenu();
    onSearch('');
  }, [
    channel,
    selectResult,
    onSearch,
    toggleChatMenu,
  ]);

  const endUserId = channel.id;
  const endUser = channel.state.members[endUserId]?.user || {};
  const {
    image,
  } = endUser;
  const {
    messages,
    unreadCount,
  } = channel.state;
  const hasLastMessage = messages?.length > 0;
  const lastMessage = hasLastMessage ? messages[messages.length - 1] : {};

  return (
    <ChannelPreview
      onClick={handleClick}
      image={image}
      name={channel?.data?.name}
      lastMessage={lastMessage}
      unreadCount={unreadCount}
      isAdmin={isAdmin}
      isActive={isActive}
    />
  );
};

CustomResultItem.propTypes = {
  result: PropTypes.object.isRequired,
  selectResult: PropTypes.func.isRequired,
};

export default CustomResultItem;
