import { useState, useMemo, useCallback } from 'react';
import { useIonToast } from '@ionic/react';
import MediaRecorderEncoder from 'opus-media-recorder';
import * as Sentry from '@sentry/browser';
// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved,import/extensions
import EncoderWorker from 'worker-loader!opus-media-recorder/encoderWorker.js';
import { VoiceRecorder } from 'capacitor-voice-recorder';
import { isNative } from '../../utils/platform';
import { dataURItoBlob } from '../../utils/dataProcessing';

const MediaRecorderState = {
  NOT_INITIALIZED: 'notInitialized',
  RECORDING: 'recording',
  ERROR: 'error',
  INACTIVE: 'inactive',
};

/*
  Keys in this object are the possible errors that are generated by the `capacitor-voice-recorder` plugin as stated:
  https://github.com/tchvu3/capacitor-voice-recorder/blob/c386234601c1e2b4cc128f2f3bfc56495d3978da/ios/Plugin/Messages.swift
  https://github.com/tchvu3/capacitor-voice-recorder/blob/c386234601c1e2b4cc128f2f3bfc56495d3978da/android/src/main/java/com/tchvu3/capacitorvoicerecorder/Messages.java
  Except for DEFAULT key which will be used for any other errors generated.
*/
const ErrorMessage = {
  MISSING_PERMISSION: 'You haven\'t allowed System2 permission to access your Microphone',
  MICROPHONE_BEING_USED: 'You have another app using the microphone',
  FAILED_TO_RECORD: 'Recording Failed',
  FAILED_TO_FETCH_RECORDING: 'There was an error in acquiring your recording',
  DEFAULT: 'There was some problem in recording your voice message',
};

const useMediaRecorder = (onDataAvailable) => {
  const [state, setState] = useState(MediaRecorderState.NOT_INITIALIZED);

  const [webRecorder, setWebRecorder] = useState();

  const [present] = useIonToast();

  const showToast = useCallback((message) => {
    present({
      message,
      duration: 2000,
      color: 'warning',
    });
  }, [present]);

  const startRecording = useCallback(async () => {
    if (isNative) {
      const permission = await VoiceRecorder.hasAudioRecordingPermission();
      if (permission?.value) {
        try {
          await VoiceRecorder.startRecording();
          setState(MediaRecorderState.RECORDING);
        } catch (error) {
          if (error in ErrorMessage) {
            showToast(ErrorMessage[error]);
          } else {
            showToast(ErrorMessage.DEFAULT);
            const description = 'Error while using the native voice recorder in in-app-chat';
            Sentry.captureException(error, {
              extra: {
                description,
                isNative,
              },
            });
          }
        }
      } else {
        const { value } = await VoiceRecorder.requestAudioRecordingPermission();
        if (!value) {
          showToast(ErrorMessage.MISSING_PERMISSION);
        }
      }
    } else {
      const encoderWorkerFactory = () => new EncoderWorker();

      const workerOptions = {
        encoderWorkerFactory,
        OggOpusEncoderWasmPath: `${process.env.PUBLIC_URL}/opus-media-recorder/OggOpusEncoder.wasm`,
        WebMOpusEncoderWasmPath: `${process.env.PUBLIC_URL}/opus-media-recorder/WebMOpusEncoder.wasm`,
      };
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const options = { mimeType: 'audio/wave' };
      const recorder = new MediaRecorderEncoder(stream, options, workerOptions);
      recorder.addEventListener('dataavailable', (audioData) => {
        onDataAvailable(audioData);
      });
      recorder.addEventListener('error', (error) => {
        setState(MediaRecorderState.ERROR);
        showToast(ErrorMessage.DEFAULT);
        const description = 'Error while using the web MediaRecorder API in in-app-chat';
        Sentry.captureException(error, {
          extra: {
            description,
            isNative,
          },
        });
      });
      recorder.start();
      setWebRecorder(recorder);
      setState(MediaRecorderState.RECORDING);
    }
  }, [
    onDataAvailable,
    showToast,
  ]);

  const stopRecording = useCallback(async () => {
    if (isNative) {
      try {
        const audioFile = await VoiceRecorder.stopRecording();
        const blob = dataURItoBlob(`data:audio/aac;base64,${audioFile.value.recordDataBase64}`);
        const audioData = {
          data: blob,
        };
        onDataAvailable(audioData);
      } catch (error) {
        if (error in ErrorMessage) {
          showToast(ErrorMessage[error]);
        } else {
          showToast(ErrorMessage.DEFAULT);
          const description = 'Error while using the native voice recorder in in-app-chat';
          Sentry.captureException(error, {
            extra: {
              description,
              isNative,
            },
          });
        }
      }
    } else {
      webRecorder.stop();
    }
    setState(MediaRecorderState.INACTIVE);
  }, [
    webRecorder,
    onDataAvailable,
    showToast,
  ]);

  return useMemo(() => ({
    isRecording: state === MediaRecorderState.RECORDING,
    startRecording,
    stopRecording,
  }), [
    state,
    startRecording,
    stopRecording,
  ]);
};

export default useMediaRecorder;
