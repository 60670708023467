import { Collection } from 'firestorter';

import CollectionName from '../utils/collections';
import { firestorePaths } from '../utils/firebasePaths';
import BaseDocument from './BaseDocument';

const ExerciseType = {
  BASE: 'BASE',
  EDITED: 'EDITED',
  CUSTOM: 'CUSTOM',
};

class Exercise extends BaseDocument {
  get current() {
    return this.data.current;
  }

  get description() {
    return this.data.description;
  }

  get name() {
    return this.data.name;
  }

  get videoUrl() {
    return this.data.videoUrl || '';
  }

  get videoPreviewUrl() {
    return this.data.videoPreviewUrl || '';
  }

  get videoSrc() {
    return this.videoPreviewUrl || this.videoUrl;
  }

  get videoPreviewThumbnail() {
    return this.data.videoPreviewThumbnail || '';
  }

  get type() {
    return this.data.type;
  }

  get originalExercise() {
    return this.data.originalExercise || '';
  }

  get createdBy() {
    return this.data.createdBy || '';
  }

  get tags() {
    return this.data.tags || [];
  }

  static async getById(id) {
    const exerciseDoc = new Exercise(`${firestorePaths.EXERCISE}/${id}`);
    await exerciseDoc.init();

    return exerciseDoc;
  }

  static async getBaseExercises() {
    const exercisesCollection = new Collection(() => CollectionName.EXERCISE, {
      createDocument: (src, opts) => new Exercise(src, opts),
      query: (ref) => ref.where('type', '==', ExerciseType.BASE)
        .where('current', '==', true),
    });

    await exercisesCollection.fetch();
    return exercisesCollection;
  }

  static async getCoachExercises(coachId, exerciseType = ExerciseType.CUSTOM) {
    const exercisesCollection = new Collection(() => CollectionName.EXERCISE, {
      createDocument: (src, opts) => new Exercise(src, opts),
      query: (ref) => ref.where('type', '==', exerciseType)
        .where('createdBy', '==', coachId),
    });

    await exercisesCollection.fetch();
    return exercisesCollection;
  }
}

/**
 * Calculates the exercise ID to use across the app. If the ID is available, it will use it, otherwise
 * the exercise name is used as the ID.
 *
 * @param {Object} exerciseData
 * @param {string=} exerciseData.id The ID of the exercise
 * @param {string} exerciseData.name The name of the exercise
 * @returns {string} The exercise ID.
 */
const calculateExerciseId = (exerciseData) => exerciseData.id || encodeURI(exerciseData.name);

export default Exercise;
export {
  ExerciseType,
  calculateExerciseId,
};
