import {
  useContext,
  useMemo,
  useCallback,
} from 'react';

import AppCustomizationContext from '../context/AppCustomizationContext';

const useAppCustomization = () => {
  const {
    isCustomProduct,
    productDoc,
  } = useContext(AppCustomizationContext);

  const isFeatureEnabled = useCallback((id) => (
    // By default all features are defined unless it's specified by the product definition
    !isCustomProduct || productDoc.isFeatureEnabled(id)
  ), [
    isCustomProduct,
    productDoc,
  ]);

  return useMemo(() => ({
    isFeatureEnabled,
  }), [
    isFeatureEnabled,
  ]);
};

export default useAppCustomization;
