import styled from 'styled-components';

import { ReactComponent as DeleteButtonIcon } from '../../../assets/delete_button.svg';
import {
  body1,
} from '../../../styles/text';

const VideoHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background: ${({ theme }) => theme.colors.system.alpha};
`;

const HeaderText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
`;

const DeleteButton = styled(DeleteButtonIcon)`
  height: 24px;
  width: 24px;
  margin-left: auto;
`;

const VideoPlaceholder = styled.img`
  width: 100%;
`;

const WarningContainer = styled.div`
  ${body1}
  background-color: ${({ theme }) => theme.colors.system.error};
  color: ${({ theme }) => theme.colors.system.alpha};
  padding: 10px;
`;

const LoaderWrapper = styled.div`
  min-height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  HeaderText,
  DeleteButton,
  VideoHeaderContainer,
  VideoPlaceholder,
  WarningContainer,
  LoaderWrapper,
};
