import React, {
  useContext,
  useEffect,
} from 'react';
import { useChatContext } from 'stream-chat-react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

import ChatContext from '../../context';
import LoadingPage from '../../../components/LoadingPage';

const MessagingChannelList = ({
  children,
  error = false,
  loading,
}) => {
  const { client } = useChatContext();
  const {
    onChatError,
    initialChatMenuOpen,
    onInitialChatMenuOpened,
    isSearching,
    openChatMenu,
  } = useContext(ChatContext);
  const { id } = client?.user || {};

  useEffect(() => {
    const openMenuOnOpen = async () => {
      openChatMenu();
      onInitialChatMenuOpened();
    };

    if (!initialChatMenuOpen && !loading) {
      openMenuOnOpen();
    }
  }, [
    initialChatMenuOpen,
    onInitialChatMenuOpened,
    loading,
    openChatMenu,
  ]);

  if (error) {
    Sentry.captureException(`Error occurred while loading chats for the user ${id}`,
      {
        extra: {
          userId: id,
        },
      });
    onChatError();
    return null;
  }

  if (isSearching) {
    return null;
  }

  if (loading) {
    return <LoadingPage />;
  }

  return children;
};

MessagingChannelList.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.object,
  error: PropTypes.bool,
};

MessagingChannelList.defaultProps = {
  children: null,
  error: false,
};

export default MessagingChannelList;
