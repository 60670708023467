import styled from 'styled-components';

import Card from '../../../components/Card';
import LoadingPage from '../../../components/LoadingPage';

const Container = styled.div`
  padding-bottom: 21px;
  width: 100%;
  height: 100%;
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-bottom: 21px;
`;

const StyledLoader = styled(LoadingPage)`
  height: 100%;
`;

export {
  Container,
  StyledCard,
  StyledLoader,
};
