import { Collection } from 'firestorter';
import CollectionName from '../utils/collections';

import { firestorePaths } from '../utils/firebasePaths';
import BaseDocument from './BaseDocument';

class PostPaymentFormAnswer extends BaseDocument {
  constructor(id, opts) {
    super(`${CollectionName.POST_PAYMENT_FORM_ANSWER}/${id}`, opts);
  }

  /**
   * Returns the post payment answers
   * to the provided userId passed as param.
   * @param {string} userId The user id
   */
  static async getPostPaymentAnswersByUserId(userId) {
    const collection = new Collection(() => firestorePaths.POST_PAYMENT_FORM_ANSWER, {
      createDocument: ({ id }, opts) => new PostPaymentFormAnswer(id, opts),
      query: (ref) => ref.where('user', '==', userId),
    });
    await collection.fetch();
    return collection;
  }
}

export default PostPaymentFormAnswer;
