import React, { useContext } from 'react';
import LoadingPage from '../../components/LoadingPage';
import PostPaymentFormContext from './PostPaymentFormContext';
import PostPaymentFormContextProvider from './PostPaymentFormContextProvider';

/**
 * Inserts PostPaymentFormContextProvider in the react tree
 * before adding the given component.
 *
 * @param {Object} Component The component that needs the context provider.
 * @returns {Object} The component to render wrapped with the provider.
 */
const withPostPaymentFormContextProvider = (Component) => (props) => (
  <PostPaymentFormContextProvider>
    <Component {...props} />
  </PostPaymentFormContextProvider>
);

/**
 * Renders the given component when the user context is ready.
 *
 * @param {Object} Component The component to render.
 * @returns {Object} The component to render or the LoadingPage component.
 */
const withPostPaymentFormContextReady = (Component) => (props) => {
  const { isReady } = useContext(PostPaymentFormContext);
  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withPostPaymentFormContextReady,
  withPostPaymentFormContextProvider,
};
