const LETTER_A_DEC = 65;

const convertIndexToLetter = (index) => String.fromCharCode(LETTER_A_DEC + index);

const removePadding = (paddedVersion) => (
  paddedVersion
    .split('.')
    .map((paddedNum) => paddedNum
      .replace(/^0{1,3}/, ''))
    .join('.')
);

export {
  convertIndexToLetter,
  removePadding,
};
