import styled from 'styled-components';

import { fontFamily } from '../../../../../../styles/text';

const Container = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  margin: 8px;
  width: 122px;
`;

const Title = styled.div`
  font-family: ${fontFamily.sansSerif};
  color: ${({ theme }) => theme.colors.shades.primary40};
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.system.gamma1};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 165px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 10px;
`;

const Description = styled.div`
  font-family: ${fontFamily.sansSerif};
  color: ${({ theme }) => theme.colors.base.primary};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 16px;
`;

export {
  Container,
  Title,
  Description,
  Wrapper,
};
