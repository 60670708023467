import React from 'react';
import ReactDOM from 'react-dom';
import { createPortalElement } from '../../utils/portal';

/**
 * The HOC uses a portal to move the DOM element elsewhere
 *
 * @param {Object} Component - The child component to be rendered once after adding the below mentioned action.
 * @return {ReactComponent} - Anonymous react component that adds an action to trigger referral.
 */
const withPortal = (portalName) => (Component) => (props) => {
  const portalElement = createPortalElement(portalName);
  return ReactDOM.createPortal(
    <Component {...props} />,
    portalElement,
  );
};

export default withPortal;
