import { useEffect, useRef } from 'react';

const useComponentMounted = () => {
  const isComponentMountedRef = useRef(false);

  useEffect(() => {
    isComponentMountedRef.current = true;
    return () => {
      isComponentMountedRef.current = false;
    };
  }, []);

  return isComponentMountedRef;
};

export default useComponentMounted;
