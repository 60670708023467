import { useContext, useCallback } from 'react';
import DeviceContext from '../context/DeviceContext';

/**
 * Hooks used to perform actions on the current device instance.
 * @requires DeviceContext Loaded for this to work
 */
const useDevice = () => {
  const { device } = useContext(DeviceContext);

  /**
   * If there's an available device loaded in the DeviceContext
   * it will deactivate it.
   */
  const deactivateDevice = useCallback(() => {
    if (device) {
      return device.deactivate();
    }
    return Promise.resolve();
  }, [
    device,
  ]);

  const isActive = !!(device && device.isActive);

  return {
    isActive,
    deactivateDevice,
  };
};

export default useDevice;
