/**
 * Supported currency codes.
 */
const CurrencyCode = {
  AUD: 'AUD',
  GBP: 'GBP',
  BRL: 'BRL',
  CAD: 'CAD',
  EUR: 'EUR',
  USD: 'USD',
};

/**
 * List of currencies supported by the app. Each time we add a new currency, we need to also add a minimum
 * average warning value in the CurrencyMinimumAvgWarning object.
 */
const CURRENCIES = [
  {
    name: 'Australian Dollar',
    code: CurrencyCode.AUD,
  },
  {
    name: 'British Pound Sterling',
    code: CurrencyCode.GBP,
  },
  {
    name: 'Brazilian Real',
    code: CurrencyCode.BRL,
  },
  {
    name: 'Canadian Dollar',
    code: CurrencyCode.CAD,
  },
  {
    name: 'Euro',
    code: CurrencyCode.EUR,
  },
  {
    name: 'US Dollar',
    code: CurrencyCode.USD,
  },
];

/**
 * Default currency. This is the currency that will be selected by default when no currency is selected or saved.
 */
const DEFAULT_CURRENCY = {
  name: 'US Dollar',
  code: CurrencyCode.USD,
};

export {
  DEFAULT_CURRENCY,
  CURRENCIES,
};
