import { IonSpinner } from '@ionic/react';
import styled from 'styled-components';

import { sansSerif } from '../../../../styles/text';

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  margin-top: 8px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.system.background};
`;

const BodyMeasurementText = styled.span`
  ${sansSerif.headlineFontStyles}
  font-size: 16px;
  line-height: 22px;
`;

const StatusText = styled.span`
 ${sansSerif.headlineFontStyles}
  font-size: 12px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledSpinner = styled(IonSpinner)`
  width: 100%;
  margin: 30px 0;
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  justify-self: flex-end;
`;

export {
  ItemContainer,
  TextContainer,
  BodyMeasurementText,
  StatusText,
  StyledSpinner,
  IconContainer,
};
