import React, {
  useMemo,
  useCallback,
  useState,
  useEffect,
  useRef,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { FCM } from '@capacitor-community/fcm';
import { PushNotifications } from '@capacitor/push-notifications';
import { LocalNotifications } from '@capacitor/local-notifications';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import * as Sentry from '@sentry/browser';
import { ChatContext as NativeChatContext } from '@fitmoola/system2-chat';

import { isNewChatEnabled } from '../../utils/featureFlags';
import { PermissionState } from '../../utils/permission';
import { isNative } from '../../utils/platform';
import useComponentMounted from '../../hooks/useComponentMounted';
import useSessionStore from '../../hooks/useSessionStore';
import useWorkoutNavigation from '../../hooks/useWorkoutNavigation';
import useMealPlanNavigation from '../../hooks/useMealPlanNavigation';
import useCheckInNavigation from '../../hooks/useCheckInNavigation';
import ChatContext from '../../chat/context';

import FCMContext from './FCMContext';
import localNotificationActions from './localNotificationActions';
import Action from './action';

const topics = {
  GENERAL: 'general',
};

const FCMContextProvider = ({
  children,
}) => {
  const {
    authUser: {
      uid: userId,
    } = {},
  } = useSessionStore();

  const isComponentMountedRef = useComponentMounted();
  const [isRegistered, setIsRegistered] = useState(false);
  const [fcmToken, setFCMToken] = useState(null);
  const { navigateToWorkout } = useWorkoutNavigation();
  const { navigateToMealPlanPage } = useMealPlanNavigation();
  const { openChatModal } = useContext(ChatContext);
  const { setActiveChannelId } = useContext(NativeChatContext);
  const { navigateCheckInPageByPageIndex } = useCheckInNavigation();

  const latestDeliveredMessageIdRef = useRef(0);

  useEffect(() => {
    const initToken = async () => {
      const result = await FCM.getToken();
      if (isComponentMountedRef.current) {
        setFCMToken(result.token);
      }
    };
    if (isRegistered && !fcmToken) {
      initToken();
    }
  }, [
    fcmToken,
    isComponentMountedRef,
    isRegistered,
    setFCMToken,
  ]);

  const onPushNotificationReceived = useCallback(async (notification) => {
    const {
      data: notificationData,
      title,
      body,
    } = notification;

    latestDeliveredMessageIdRef.current += 1;

    const {
      userId: notificationUserId,
      actionType,
    } = notificationData;

    /*
      If the notification does not provide a userId, it means that the notification itself
      is probably coming from the general channel. Otherwise, we should always also get a userId.

      The notification will always be delivered ONLY if the userId of the notification does
      match the userId of the currently logged in user. This is to avoid delivering a notification
      to another user that might have logged in on a device where the deactivation process
      might have failed for a previous user.

      Chat-related notifications are explicitly ignored, so that they don't show up while the app is running.
      Once the app goes into background mode this logic is not executed and it's the native part the one in
      charge of delivering the messages.
    */
    if ((!notificationUserId || notificationUserId === userId) && actionType !== Action.OPEN_CHAT_CHANNEL) {
      LocalNotifications.schedule({
        notifications: [
          {
            title,
            body,
            id: latestDeliveredMessageIdRef.current,
            sound: null,
            extra: {
              ...notificationData,
            },
          },
        ],
      });
    }
  }, [
    userId,
  ]);

  const onNotificationActionPerformed = useCallback((notificationAction = {}) => {
    const { actionId, notification } = notificationAction;

    if (actionId !== localNotificationActions.TAP) {
      return;
    }

    const {
      data,
      extra,
    } = notification;

    const notificationData = data || extra || {};

    const {
      userId: notificationUserId,
      actionType,
    } = notificationData;

    if (notificationUserId !== userId) {
      Sentry.captureException(new Error('Notification received by incorrect user'), {
        extra: {
          notificationUserId,
          userId,
        },
      });
      return;
    }

    switch (actionType) {
      case Action.OPEN_WORKOUT_DETAILS: {
        const { workoutId } = notificationData;

        if (!workoutId) {
          Sentry.captureException(new Error('Open Workout Details notification triggered with no workout id'), {
            extra: {
              user: notificationUserId,
            },
          });
          return;
        }

        navigateToWorkout(workoutId);

        break;
      }
      case Action.OPEN_CHAT_CHANNEL: {
        const { channel, nativeChannel } = notificationData;

        if (isNewChatEnabled()) {
          setActiveChannelId(nativeChannel);
          openChatModal();
        } else {
          if (!channel) {
            Sentry.captureException(new Error('Error opening chat modal. No channel provided.'), {
              extra: {
                user: notificationUserId,
              },
            });
            return;
          }

          openChatModal(channel);
        }
        break;
      }
      case Action.OPEN_NUTRITION_PLAN: {
        navigateToMealPlanPage();
        break;
      }
      case Action.OPEN_CHECK_IN: {
        navigateCheckInPageByPageIndex(0);
        break;
      }
      default: {
        break;
      }
    }
  }, [
    openChatModal,
    navigateToWorkout,
    navigateToMealPlanPage,
    navigateCheckInPageByPageIndex,
    userId,
    setActiveChannelId,
  ]);

  const register = useCallback(async () => {
    if (!isRegistered) {
      const { receive } = await PushNotifications.requestPermissions();

      if (receive === PermissionState.GRANTED) {
        try {
          await PushNotifications.register();
        } catch (error) {
          Sentry.captureException(error, {
            extra: {
              description: 'An error happened when calling PushNotifications.register',
            },
          });
          return;
        }

        try {
          await FCM.subscribeTo({ topic: topics.GENERAL });
        } catch (error) {
          Sentry.captureException(error, {
            extra: {
              description: 'An error happened when calling FCM.subscribeTo',
            },
          });
          return;
        }

        PushNotifications.addListener('pushNotificationReceived', onPushNotificationReceived);

        /*
          We need to subscribe to both events here because:
          - If the app is closed `pushNotificationActionPerformed` will be called
          - If the app is opened `localNotificationActionPerformed` will be called
        */
        PushNotifications.addListener('pushNotificationActionPerformed', onNotificationActionPerformed);
        LocalNotifications.addListener('localNotificationActionPerformed', onNotificationActionPerformed);

        setIsRegistered(true);
      }
    }
  }, [
    isRegistered,
    onPushNotificationReceived,
    setIsRegistered,
    onNotificationActionPerformed,
  ]);

  const contextValue = useMemo(() => ({
    canUseNotifications: !!(FCM && isNative),
    register,
    fcmToken,
  }), [
    fcmToken,
    register,
  ]);

  return (
    <FCMContext.Provider value={contextValue}>
      {children}
    </FCMContext.Provider>
  );
};

FCMContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(FCMContextProvider);
