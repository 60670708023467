import React, {
  useCallback,
  useContext,
} from 'react';
import moment from 'moment';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import format from 'string-template';

import HabitLogContext from '../../../../context/HabitLogContext';
import AppCustomizationContext from '../../../../context/AppCustomizationContext/AppCustomizationContext';

import {
  HabitText,
  ItemContainer,
  TextContainer,
  StatText,
  LoaderWrapper,
  Loading,
} from './styles';
import texts from './texts.json';

const StatFrequency = {
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
};

const StatFrequencyDuration = {
  WEEKLY: 7,
  MONTHLY: 30,
};

const HabitHistory = () => {
  const { habitsDefinition } = useContext(AppCustomizationContext);
  const { habitLogs, isLoadingMore, isReady } = useContext(HabitLogContext);
  const dateBeforeWeek = moment.utc().startOf('day').subtract(7, 'days');
  const dateBeforeMonth = moment.utc().startOf('day').subtract(30, 'days');

  const getLogCount = useCallback((habit, frequency) => {
    const dateToCheck = frequency === StatFrequency.WEEKLY ? dateBeforeWeek : dateBeforeMonth;

    return habitLogs
      .filter((log) => log.habits && log.habits[habit])
      .filter((log) => moment(log.date).isSameOrAfter(dateToCheck))
      .length;
  }, [
    habitLogs,
    dateBeforeMonth,
    dateBeforeWeek,
  ]);

  if (isLoadingMore || !isReady) {
    return (
      <LoaderWrapper>
        <Loading />
      </LoaderWrapper>
    );
  }

  return Object.keys(habitsDefinition).map((habitId) => {
    // should not show stat for inactive habits which does not have any logs for the last month
    if (!habitsDefinition[habitId].active && getLogCount(habitId, StatFrequency.MONTHLY) === 0) {
      return null;
    }
    return (
      <ItemContainer key={habitId}>
        <TextContainer>
          <HabitText>
            {habitsDefinition[habitId].text}
          </HabitText>
        </TextContainer>
        {Object.keys(StatFrequency).map((frequency) => {
          const logCount = getLogCount(habitId, frequency);

          return (
            <TextContainer key={frequency}>
              <StatText>
                {format(texts.stat[frequency], {
                  count: logCount,
                  percentage: Math.round((logCount / StatFrequencyDuration[frequency]) * 100),
                })}
              </StatText>
            </TextContainer>
          );
        })}
      </ItemContainer>
    );
  });
};

export default compose(
  observer,
)(HabitHistory);
