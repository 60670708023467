import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import { compose } from 'recompose';
import { IonPage, IonContent } from '@ionic/react';

import AppContext, { withAppContextProvider } from '../../../../context/AppContext';
import { withCameraContextProvider, withCameraPermissionInitializatorReady } from '../../../../context/CameraContext';
import FPSStats from '../../../../utils/stats/FPSStats';
import { SelfieVideoContextProvider, selfieVideoModes } from '../../../../components/SelfieVideo';
import createTrackerByActivityType from '../../tracker';
import withTestUserContextProvider from './TestUserContextProvider';
import TrackerPlaygroundAdapter from './TrackerPlaygroundAdapter';
import config, { defaultActivityConfig } from './config';

const TrackerPlaygroundContainer = () => {
  const [isActive, setIsActive] = useState(true);
  const [count, setCount] = useState(0);
  const [repetitionsGoal, setRepetitionsGoal] = useState(15);
  const [stepResult, setStepResult] = useState({});
  const [predictionModelResult, setPredictionModelResult] = useState(null);
  const [configByType, setConfigByType] = useState(defaultActivityConfig);
  const [activityType, setActivityType] = useState('');
  const [activityTracker, setActivityTracker] = useState(null);
  const [fpsStats] = useState(() => new FPSStats());

  const {
    debugSettings: {
      isDebugMode,
    },
  } = useContext(AppContext);

  const handleActivityTypeChange = useCallback((event) => setActivityType(event.target.value), []);
  const handleRepetitionsGoalChange = useCallback((event) => setRepetitionsGoal(Number(event.target.value)), []);

  useEffect(() => {
    // By default, pick the first activity from the config on the component did mount
    const initialActivityType = config.supportedTypes.length > 0 ? config.supportedTypes[0] : '';
    setActivityType(initialActivityType);
  }, []); // eslint-disable-line

  useEffect(() => {
    const newConfigByType = config.activities[activityType] || defaultActivityConfig;

    const tracker = createTrackerByActivityType(activityType);
    setActivityTracker(tracker);
    setConfigByType(newConfigByType || {});
  }, [
    activityType,
    repetitionsGoal,
  ]);

  const onStartStop = useCallback(() => {
    setCount(0);
    setStepResult({});
    setPredictionModelResult(null);
  }, []);

  /**
   * Processes a frame from the camera along with skeletal data.
   * Returns null if there are no predictions.
   */
  const frameProcessed = useCallback((result = {}) => {
    if (!isActive) {
      return null;
    }

    const { detectionResult = {} } = result;

    const {
      predictionModels,
    } = detectionResult;

    if (predictionModels && predictionModels.length > 0 && activityTracker) {
      const resultModel = activityTracker.bestPrediction(predictionModels);

      if (resultModel) {
        const newStepResult = activityTracker.analyzeStep(resultModel);
        const { count: newCount } = newStepResult;

        fpsStats.calculateFPS();

        setPredictionModelResult(resultModel);
        setCount(newCount);
        setStepResult(newStepResult);
      }

      return resultModel;
    }

    return null;
  }, [
    activityTracker,
    isActive,
    fpsStats,
  ]);

  const displayReasons = (stepResult && stepResult.poseDetectionResult
    && stepResult.poseDetectionResult.displayReasons) || [];
  const reasonText = displayReasons.join('\n');
  const tipText = (stepResult && stepResult.tipResult)
    ? stepResult.tipResult.tip
    : null;
  const history = {
    stepResult,
    predictionModelResult,
    fps: fpsStats.fps,
  };

  return (
    <IonPage>
      <IonContent>
        <SelfieVideoContextProvider
          selfieVideoMode={selfieVideoModes.IMAGE_PROCESSING}
          debugMode={isDebugMode}
          frameProcessed={frameProcessed}
          loopProcessed={() => {}}
          gameplayActionsProcessingArea={{}}
        >
          <TrackerPlaygroundAdapter
            debugMode={isDebugMode}
            activityType={activityType}
            setIsActive={setIsActive}
            history={history}
            reasonText={reasonText}
            tipText={tipText}
            repetitionsGoal={repetitionsGoal}
            count={count}
            configByType={configByType}
            onStartStop={onStartStop}
            handleActivityTypeChange={handleActivityTypeChange}
            handleRepetitionsGoalChange={handleRepetitionsGoalChange}
          />
        </SelfieVideoContextProvider>
      </IonContent>
    </IonPage>
  );
};

export default compose(
  withAppContextProvider,
  withTestUserContextProvider,
  withCameraContextProvider,
  withCameraPermissionInitializatorReady,
)(TrackerPlaygroundContainer);
