import styled, { css } from 'styled-components';

import { ReactComponent as ArrowIcon } from '../../../../assets/icons/icon_arrow_up.svg';
import { ReactComponent as ListIcon } from '../../../../assets/icons/list-icon.svg';
import { ReactComponent as PencilIcon } from '../../../../assets/icons/pencil-icon.svg';
import { sansSerif } from '../../../../styles/text';
import { LinkButton } from '../../../Button';
import CloseButton from '../../../CloseButton';
import transitionsConfig from './transitionsConfig';

const transitionsProperty = css`
  transition: all ${transitionsConfig.delay}ms ease-in-out;
`;

const StyledActivityLog = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
`;

const ActivityWrapper = styled.div``;

const ActivityHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
  background-color: ${({ theme }) => theme.colors.system.alpha};
`;

const ActivityName = styled.div`
  ${sansSerif.headline3}
  margin-top: 8px;
`;

const HeaderNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const PreviousIcon = styled(ArrowIcon)`
  width: 24px;
  height: 24px;
  transform: rotate(-90deg);
  path {
    stroke: ${({ theme }) => theme.colors.system.beta};
  }
`;

const NextIcon = styled(PreviousIcon)`
  transform: rotate(90deg);
`;

const TableHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.base.tertiary};
  color: ${({ theme }) => theme.colors.fonts.primary};
  display: flex;
  text-transform: uppercase;
  height: 25px;
  text-align: left;
  padding: 0 16px;
  font-size: 12px;
  align-items: center;
`;

const HeaderDate = styled.div`
  flex: 1;
`;

const HeaderLabel = styled.div`
  width: 50px;
  margin-left: 12px;
  text-align: center;
`;

const RowsWrapper = styled.div`
  overflow: auto;
`;

const RowLabel = styled.div`
  text-align: left;
  text-transform: uppercase;
  font-size: 14px;
  flex-shrink: 0;

  ${({ $isHighlighted }) => ($isHighlighted && (
    css`
      font-weight: bold;
    `
  ))}
`;

const Row = styled.div`
  display: flex;
  height: 58px;
  align-items: center;

  &.${transitionsConfig.classNames.logRows}-enter,
  &.${transitionsConfig.classNames.logRows}-appear {
    opacity: 0;
    margin-top: -60px;
  }

  &.${transitionsConfig.classNames.logRows}-appear-active,
  &.${transitionsConfig.classNames.logRows}-enter-active {
    margin-top: 0;
    opacity: 1;
    ${transitionsProperty}
  }

  &.${transitionsConfig.classNames.logRows}-exit {
    margin-top: 0;
    opacity: 1;
  }

  &.${transitionsConfig.classNames.logRows}-exit-active {
    margin-top: -60px;
    opacity: 0;
    ${transitionsProperty}
  }
`;

const StyledRowInput = styled.input`
  width: 50px;
  height: 40px;
  padding: 0;
  background-color: ${({ theme }) => theme.colors.system.secondary6};
  border-radius: 6px;
  border: none;
  text-align: center;
  margin-left: 12px;

  &:focus::placeholder {
    color: transparent;
  }

  &:focus{
    outline: none;
  }
`;

const StyledDateRow = styled.div`
  background-color: ${({ theme }) => theme.colors.system.alpha};
  align-items: center;
  padding: 0 16px;

  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.colors.system.background};
  }
`;

const AddSetButton = styled(LinkButton)`
  color: ${({ theme }) => theme.colors.system.gamma2};
  font-size: 12px;

  &.${transitionsConfig.classNames.addButton}-appear {
    transform: translateY(-40px);
    opacity: 0;
    ${transitionsProperty}
  }

  &.${transitionsConfig.classNames.addButton}-appear-active {
    transform: translateY(0);
    opacity: 1;
    ${transitionsProperty}
  }

  &.${transitionsConfig.classNames.addButton}-exit {
    transform: translateY(0);
    opacity: 1;
  }

  &.${transitionsConfig.classNames.addButton}-exit-active {
    transform: translateY(-40px);
    opacity: 0;
    ${transitionsProperty}
  }
`;

const StyledListIcon = styled(ListIcon)`
  path {
    fill: ${({ theme }) => theme.colors.system.beta};
  }
`;

const StyledDeleteButton = styled(CloseButton)`
  height: 24px;
  margin-right: 20px;

  path {
    fill: ${({ theme }) => theme.colors.system.gamma2};
  }
`;

const CommentWrapper = styled.div`
  flex: 1;
  text-align: left;
  padding-left: 10px;
  overflow: hidden;
`;

const Comment = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.system.gamma2};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const StyledCommentIcon = styled(PencilIcon)`
  width: 20px;
  path {
    fill: ${({ theme }) => theme.colors.system.gamma3};
  }
`;

const SegmentsWrapper = styled.div`
  margin: 0 16px;
`;

export {
  StyledActivityLog,
  ActivityHeader,
  ActivityName,
  HeaderNavigation,
  PreviousIcon,
  NextIcon,
  TableHeader,
  HeaderDate,
  HeaderLabel,
  ActivityWrapper,
  RowsWrapper,
  Row,
  RowLabel,
  StyledRowInput,
  StyledDateRow,
  AddSetButton,
  StyledListIcon,
  StyledDeleteButton,
  CommentWrapper,
  Comment,
  StyledCommentIcon,
  SegmentsWrapper,
};
