import { ReactComponent as SleepIcon } from '../../assets/icons/icon-sleep.svg';
import { ReactComponent as WaterIntakeIcon } from '../../assets/icons/icon-water.svg';
import { ReactComponent as StepsIcon } from '../../assets/icons/icon-steps.svg';
import { ReactComponent as MoodIcon } from '../../assets/icons/icon-mood.svg';

import texts from './texts.json';

// Habits will be displayed in the order taken from this list
const habitList = {
  drinkHabit: texts.drinkHabit,
  walkHabit: texts.walkHabit,
  readHabit: texts.readHabit,
  writeHabit: texts.writeHabit,
};

const habitInitialValues = {
  drinkHabit: false,
  walkHabit: false,
  readHabit: false,
  writeHabit: false,
};

const healthStats = {
  waterIntake: {
    label: texts.healthStats.waterIntake.label,
    icon: WaterIntakeIcon,
    name: 'waterIntake',
    question: texts.healthStats.waterIntake.question,
    recommendationText: texts.healthStats.waterIntake.recommendationText,
    units: texts.healthStats.waterIntake.units,
  },
  sleep: {
    label: texts.healthStats.sleep.label,
    icon: SleepIcon,
    name: 'sleep',
    question: texts.healthStats.sleep.question,
    recommendationText: texts.healthStats.sleep.recommendationText,
    units: texts.healthStats.sleep.units,
  },
  mood: {
    label: texts.healthStats.mood.label,
    icon: MoodIcon,
    name: 'mood',
    question: texts.healthStats.mood.question,
  },
  steps: {
    label: texts.healthStats.steps.label,
    icon: StepsIcon,
    name: 'steps',
    question: texts.healthStats.steps.question,
    units: texts.healthStats.steps.units,
  },
};

const healthInitialValues = {
  waterIntake: 0,
  mood: null,
  sleep: null,
  steps: null,
};

const BodyMeasurements = {
  weight: 'weight',
  hip: 'hip',
  thigh: 'thigh',
  stomach: 'stomach',
  chest: 'chest',
  upperArm: 'upperArm',
  waist: 'waist',
};

export {
  healthStats,
  habitList,
  habitInitialValues,
  healthInitialValues,
  BodyMeasurements,
};
