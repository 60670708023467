import moment from 'moment';
import { DateFormat } from '../../utils/date';

/**
 * Formats a Date object text for the channel preview
 * @param {Date} date
 */
const formatDateForChannelPreview = (date) => {
  if (!date?.getHours() || !date?.getMinutes()) {
    return '';
  }

  return moment(date).calendar(null, {
    sameDay: 'LT',
    lastDay: '[Yesterday]',
    lastWeek: DateFormat.DATE_FORMAT_SLASH,
    sameElse: DateFormat.DATE_FORMAT_SLASH,
  });
};

export {
  formatDateForChannelPreview,
};
