import { useCallback } from 'react';

import { isNative } from '../utils/platform';
import logEvent from '../utils/logger';

const useAppSettings = () => {
  const openAppSettings = useCallback((type = 'application_details') => {
    if (isNative) {
      window.cordova.plugins.settings.open(type,
        () => {
          logEvent('AppSettingsOpened');
        },
        () => {
          /*
            NOTE: log this for now, then we can see if we should build a UX
            to handle this cases.
          */
          logEvent('AppSettingsFailedToOpen');
        });
    }
  }, []);

  return {
    openAppSettings,
  };
};

export default useAppSettings;
