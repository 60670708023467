import React, {
  useRef,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import { CameraPreviewPlaceholder } from './styles';

/**
 * This component renders an HTML Image element used as a placeholder for the native camera preview UI.
 * This element is only used to take the dimensions that later the capacitor-camera-stream plugin will use
 * to position the camera preview UI.
 *
 * @param {Object} props
 */
const ImageCameraPreview = ({
  previewRefReady,
  className,
}) => {
  const previewRef = useRef(null);

  useEffect(() => {
    let shouldUpdate = true;

    if (previewRef.current && previewRefReady) {
      previewRef.current.onload = () => {
        if (shouldUpdate) {
          previewRefReady(previewRef);
        }
      };
      // Set a small transparent image to not show a broken image, just a placeholder
      // eslint-disable-next-line max-len
      previewRef.current.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
    }

    return () => {
      shouldUpdate = false;
    };
  }, [
    previewRef,
    previewRefReady,
  ]);

  return (
    <CameraPreviewPlaceholder
      ref={previewRef}
      className={className}
    />
  );
};

ImageCameraPreview.propTypes = {
  previewRefReady: PropTypes.func,
  className: PropTypes.string,
};

ImageCameraPreview.defaultProps = {
  previewRefReady: null,
  className: '',
};

export default ImageCameraPreview;
