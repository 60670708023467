import styled from 'styled-components';

import { sansSerif, headlineFontStyles } from '../../styles/text';
import PageContent from '../PageContent';

const StyledPageContent = styled(PageContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow: auto;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 29px 16px;
  position: relative;
  width: 100%;
  height: 100%;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Message = styled.div`
  ${sansSerif.headline1}
  text-align: center;
  font-size: 18px;
  line-height: 27px;
  font-style: normal;
  font-weight: normal;
  margin-top: 17px;
  padding: 0 26px;
  color: ${({ theme }) => theme.colors.base.primary};
`;

const Title = styled.div`
  ${headlineFontStyles}
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
  margin-top: 37px;
  color: ${({ theme }) => theme.colors.base.primary};
`;

const ActionsContainer = styled.div`
  width: 100%;
  max-width: 768px;
  align-self: center;
`;

export {
  StyledPageContent,
  InfoContainer,
  Title,
  Message,
  Content,
  ActionsContainer,
};
