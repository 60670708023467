import React, {
  useCallback,
  useContext,
  useEffect,
} from 'react';
import moment from 'moment';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import format from 'string-template';
import PropTypes from 'prop-types';

import HabitLogContext from '../../../../../context/HabitLogContext';
import AppCustomizationContext from '../../../../../context/AppCustomizationContext/AppCustomizationContext';
import CheckInContext from '../../../../../context/CheckInContext';

import {
  HabitText,
  ItemContainer,
  TextContainer,
  StatText,
  LoaderWrapper,
  Loading,
  StyledHabitProgress,
} from './styles';
import texts from './texts.json';

const DAYS_PER_WEEK = 7;

const HabitStat = ({
  updateDocumentFields,
}) => {
  const { habitsDefinition } = useContext(AppCustomizationContext);
  const { habitLogs, isLoadingMore, isReady } = useContext(HabitLogContext);
  const { currentSelectedDate, checkInDay } = useContext(CheckInContext);

  const getLogCount = useCallback((habit) => {
    const startDate = moment(currentSelectedDate.clone().startOf('isoWeek')).add(checkInDay, 'days').utc(true);
    const endDate = moment(currentSelectedDate.clone().endOf('isoWeek')).add(checkInDay, 'days').utc(true);

    return habitLogs.filter((log) => {
      const logDate = moment(log.date);
      return log.habits
        && log.habits[habit]
        && logDate.isSameOrAfter(startDate)
        && logDate.isSameOrBefore(endDate);
    }).length;
  }, [
    habitLogs,
    currentSelectedDate,
    checkInDay,
  ]);

  // update check-in doc with habit stat to be used in the server function to generate
  // coach notification
  useEffect(() => {
    const habitStat = Object.keys(habitsDefinition).reduce((prev, habitId) => {
      const prevObject = { ...prev };
      const habitWeeklyCount = getLogCount(habitId);
      if (!habitsDefinition[habitId].active && habitWeeklyCount === 0) {
        return prevObject;
      }
      prevObject[habitId] = {
        text: habitsDefinition[habitId].text,
        completedCount: habitWeeklyCount,
      };
      return prevObject;
    }, {});
    updateDocumentFields({
      habitStat,
    });
  }, [
    habitLogs,
    updateDocumentFields,
    getLogCount,
    habitsDefinition,
  ]);

  if (isLoadingMore || !isReady) {
    return (
      <LoaderWrapper>
        <Loading />
      </LoaderWrapper>
    );
  }

  return Object.keys(habitsDefinition).map((habitId) => {
    const habitWeeklyCount = getLogCount(habitId);
    // should not show stat for inactive habits which does not have any logs for the last week
    if (!habitsDefinition[habitId].active && habitWeeklyCount === 0) {
      return null;
    }
    return (
      <ItemContainer key={habitId}>
        <StyledHabitProgress
          type="determinate"
          value={habitWeeklyCount / DAYS_PER_WEEK}
        />
        <TextContainer>
          <HabitText>
            {habitsDefinition[habitId].text}
          </HabitText>
          <StatText>
            {format(texts.statText, {
              count: habitWeeklyCount,
            })}
          </StatText>
        </TextContainer>
      </ItemContainer>
    );
  });
};

HabitStat.propTypes = {
  updateDocumentFields: PropTypes.func.isRequired,
};

export default compose(
  observer,
)(HabitStat);
