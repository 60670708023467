import styled, { css } from 'styled-components';

import AssignmentDayType from '../../viewModel/assignmentType';
import { sansSerif } from '../../../../styles/text';
import LoadingPage from '../../../../components/LoadingPage';
import Container from '../Container';

const StyledContainer = styled(Container)`
  cursor: pointer;
  flex-direction: column;

  ${({ dayType, isActive }) => (
    dayType === AssignmentDayType.TODAY && isActive
      && css`
        align-items: center;
      `
  )}
`;

const WorkoutCardLoading = styled(LoadingPage)`
  height: 100px;
`;

const DetailsContainer = styled.div`
  margin-top: 16px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const WorkoutDataItem = styled.div`
  ${sansSerif.headlineFontStyles}
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.base.primary};

  > * {
    padding-right: 4px;
  }

  > svg {
    width: 16px;
    height: 16px;
  }
`;

const WorkoutDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 8px;

  ${WorkoutDataItem} {
    &:not(:last-child) {
      padding-right: 20px;
    }
  }
`;

export {
  WorkoutCardLoading,
  DetailsContainer,
  StyledContainer,
  WorkoutDataContainer,
  WorkoutDataItem,
};
