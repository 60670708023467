import { Collection } from 'firestorter';
import { firestorePaths } from '../utils/firebasePaths';

import BaseDocument from './BaseDocument';

class MealPlanAssignment extends BaseDocument {
  get mealPlanId() {
    return this.data.mealPlanId;
  }

  get customMealPlanIntroMessage() {
    return this.data.customMealPlanIntroMessage || '';
  }

  get mealTimes() {
    return this.data.mealTimes;
  }

  get macroAverages() {
    return this.data.macroAverages;
  }

  get mealBuckets() {
    return this.mealTimes.map(({ name }) => name);
  }

  static async getCurrentAssignmentCollection(userId) {
    const mealPlanAssignments = new Collection(() => firestorePaths.MEAL_PLAN_ASSIGNMENT, {
      createDocument: (src, opts) => new MealPlanAssignment(src, opts),
      query: (ref) => ref
        .where('current', '==', true)
        .where('user', '==', userId),
    });
    await mealPlanAssignments.fetch();
    return mealPlanAssignments;
  }

  getMealsByBucketName(bucket) {
    const { meals: mealTimeMeals } = this.mealTimes.find(({ name }) => name === bucket) || {};
    return mealTimeMeals || [];
  }
}

export default MealPlanAssignment;
