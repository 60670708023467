import styled from 'styled-components';
import { IonSpinner } from '@ionic/react';

const LogsContainer = styled.div``;

const LoaderWrapper = styled.div`
  height: 45px;
  max-height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Loading = styled(IonSpinner)`
  width: 100%;
`;

export {
  LogsContainer,
  LoaderWrapper,
  Loading,
};
