import React, {
  useEffect,
  useCallback,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

import { SelfieVideoContext } from '../../../../components/SelfieVideo';
import DebugPanel from '../../../../components/DebugPanel';
import TrackerPlayground from './TrackerPlayground';

const TrackerPlaygroundAdapter = ({
  activityType,
  history,
  reasonText,
  tipText,
  repetitionsGoal,
  count,
  configByType,
  debugMode,
  setIsActive,
  onStartStop,
  handleActivityTypeChange,
  handleRepetitionsGoalChange,
}) => {
  const {
    isDebugPlaying,
    setIsDebugPlaying,
    setThrottling,
  } = useContext(SelfieVideoContext);

  useEffect(() => {
    setThrottling(0);
  }, []); // eslint-disable-line

  const handleStartStop = useCallback(() => {
    setIsDebugPlaying(!isDebugPlaying);
    onStartStop();
  }, [
    isDebugPlaying,
    setIsDebugPlaying,
    onStartStop,
  ]);

  const isActivityCompleted = useCallback(() => (
    !!(configByType.enableGoal && count === repetitionsGoal)
  ), [
    count,
    repetitionsGoal,
    configByType,
  ]);

  useEffect(() => {
    const isActive = isDebugPlaying && !isActivityCompleted();
    setIsActive(isActive);
  }, [
    isDebugPlaying,
    setIsActive,
    isActivityCompleted,
  ]);

  return (
    <>
      <TrackerPlayground
        activityType={activityType}
        reasonText={reasonText}
        tipText={tipText}
        repetitionsGoal={repetitionsGoal}
        count={count}
        configByType={configByType}
        isPlaying={isDebugPlaying}
        isActivityCompleted={isActivityCompleted()}
        handleActivityTypeChange={handleActivityTypeChange}
        handleRepetitionsGoalChange={handleRepetitionsGoalChange}
        handleStartStop={handleStartStop}
      />
      {debugMode && <DebugPanel hist={history} />}
    </>
  );
};

TrackerPlaygroundAdapter.propTypes = {
  debugMode: PropTypes.bool.isRequired,
  activityType: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  reasonText: PropTypes.string.isRequired,
  onStartStop: PropTypes.func.isRequired,
  configByType: PropTypes.object.isRequired,
  handleActivityTypeChange: PropTypes.func.isRequired,
  handleRepetitionsGoalChange: PropTypes.func.isRequired,
  setIsActive: PropTypes.func.isRequired,
  tipText: PropTypes.string,
  repetitionsGoal: PropTypes.number,
  count: PropTypes.number,
};

TrackerPlaygroundAdapter.defaultProps = {
  tipText: null,
  repetitionsGoal: 0,
  count: 0,
};

export default TrackerPlaygroundAdapter;
