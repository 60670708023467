import React, {
  useContext,
} from 'react';

import ChatContext from '../../context/ChatContext';
import {
  BroadcastMessageButton,
  StyledContainer,
  StyledBroadcastIcon,
} from './styles';
import text from './text.json';

const MessagingBroadcastButton = () => {
  const { setIsBroadcastOpen } = useContext(ChatContext);

  return (
    <StyledContainer>
      <BroadcastMessageButton
        onClick={() => setIsBroadcastOpen(true)}
      >
        <StyledBroadcastIcon />
        {text.broadcastMessageButtonLabel}
      </BroadcastMessageButton>
    </StyledContainer>
  );
};

export default MessagingBroadcastButton;
