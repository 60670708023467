import styled, { css } from 'styled-components';

import LoadingButton from '../../Button/LoadingButton';

const FeedbackText = styled.textarea`
  display: flex;
  width: 100%;
  height: 100px;
  margin: 5px 0;
  border: none;
  background-color: ${({ theme }) => theme.colors.system.gamma3};
  ${({ isSelected, theme }) => (isSelected
    ? css`color: ${theme.colors.system.highlight};`
    : css`color: ${theme.colors.system.beta};`
  )};
`;

const FeedbackSubmit = styled(LoadingButton)`
  display: flex;
  width: 100%;
  height: 40px;
  margin: 0 0 20px 0;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: ${({ theme }) => theme.colors.base.secondary};
  text-transform: none;
`;

const StyledFeedbackForm = styled.div`
  ${({ isSelected }) => (isSelected
    ? css`display: block;`
    : css`display: none;`
  )};
`;

export {
  FeedbackText,
  FeedbackSubmit,
  StyledFeedbackForm,
};
