import styled from 'styled-components';
import SegmentsSelector from '../../../components/SegmentsSelector';

const StyledContainer = styled.div`
  width: 100%;
  margin-top: 8px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const StyledSegmentSelector = styled(SegmentsSelector)`
  margin: 10px;
`;

export {
  StyledContainer,
  TitleContainer,
  StyledSegmentSelector,
};
