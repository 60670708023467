import React, {
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';

import ActivityRingsTheme from '../activityRingsTheme';
import {
  Ring,
  Svg,
  BackgroundRingCircle,
  ProgressRingCircle,
  Dot,
} from './styles';

const COMPLETED_RING_PERCENT = 100;

const ActivityRing = ({
  id,
  value,
  goal,
  stepColor0,
  stepColor100,
  backgroundColor,
  strokeWidth,
  size,
  theme,
  className,
}) => {
  const progressRef = useRef();
  const dotRef = useRef();

  const r = (size - strokeWidth) / 2;
  const progressRingLength = r * 2 * Math.PI;
  const hasValues = typeof goal === 'number' && typeof value === 'number';

  useEffect(() => {
    // Update progress indicator according to the value and goal changes
    if (hasValues && progressRef && progressRef.current && progressRingLength > 0) {
      const proportion = (goal > 0 && value > 0)
        ? value / goal
        : 0;

      const currentProgress = proportion * progressRingLength;
      progressRef.current.style.strokeDasharray = `${currentProgress} ${progressRingLength}`;

      const percent = proportion * COMPLETED_RING_PERCENT;
      dotRef.current.style.transform = `rotate(${percent * 3.6}deg)`;
    }
  }, [
    hasValues,
    goal,
    value,
    progressRingLength,
  ]);

  return (
    <Ring className={className}>
      <Dot
        ref={dotRef}
        isVisible={hasValues}
        theme={theme}
      />
      <Svg height="100%" width="100%" viewBox={`0 0 ${size} ${size}`}>
        <g
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          fill="transparent"
          preserveAspectRatio="xMidYMid meet"
        >
          <linearGradient id={id} x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor={stepColor0} />
            <stop offset="100%" stopColor={stepColor100} />
          </linearGradient>
          <BackgroundRingCircle
            stroke={backgroundColor}
            r={r}
            cx="50%"
            cy="50%"
          />
          {hasValues && (
            <ProgressRingCircle
              ref={progressRef}
              circleLength={progressRingLength}
              stroke={`url(#${id})`}
              strokeDashoffset="0"
              strokeDasharray={`0 ${progressRingLength}`}
              r={r}
              cx="50%"
              cy="50%"
            />
          )}
        </g>
      </Svg>
    </Ring>
  );
};

ActivityRing.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.number,
  goal: PropTypes.number,
  stepColor0: PropTypes.string.isRequired,
  stepColor100: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  strokeWidth: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  theme: PropTypes.oneOf(Object.values(ActivityRingsTheme)).isRequired,
  className: PropTypes.string,
};

ActivityRing.defaultProps = {
  value: null,
  goal: null,
  className: '',
};

export default ActivityRing;
