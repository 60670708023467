import styled, { css } from 'styled-components';

import { ReactComponent as ChevronRightIcon } from '../../../../assets/icons/icon_chevron_right.svg';

import { sansSerif } from '../../../../styles/text';
import { invoiceStatusOptions } from './utils';
import colors from './colors';

const InvoiceSectionContainer = styled.div`
  width: 100%;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InvoiceListItemContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-items: center;
  width: 100%;
  font-size: 16px;
  line-height: 22px;
  column-gap: normal;
  background-color: ${({ theme }) => theme.colors.system.alpha};
  &::after {
    content: '';
    position: absolute;
    margin: auto;
    right: 0;
    bottom: 0;
    left: 0;
    width: calc(100%);
    height: 1px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.system.gamma1};
  }
`;

const InvoiceDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-items: center;
`;

const InvoiceDataContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-right: 3%;
`;

const InvoiceID = styled.div`
  display: content;
  font-size: 10px;
  margin-top: 4%;
  ${sansSerif.headlineFontStyles};
`;

const StyledChevronRightIconInvoiceList = styled(ChevronRightIcon)`
  height: 14px;
  width: auto;
  margin-left: 0;
`;

const InvoiceDate = styled.div`
  display: content;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.system.gamma2};
`;

const InvoiceAmount = styled.div`
  display: content;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 4%;
  ${sansSerif.headlineFontStyles};
`;

const invoiceStatusStyles = ({ $invoiceStatus }) => {
  switch ($invoiceStatus) {
    case invoiceStatusOptions.PAID:
      return css`
        background-color: ${colors.paidInvoiceTag.tagBackground};
        border-color: ${colors.paidInvoiceTag.tagBorder};
        color: ${({ theme }) => theme.colors.system.alpha};
      `;
    case invoiceStatusOptions.OPEN:
      return css`
        background-color: ${({ theme }) => theme.colors.system.error2};
        border-color: ${({ theme }) => theme.colors.system.error};
        color: ${({ theme }) => theme.colors.system.alpha};
      `;
    default:
      return css`
        background-color: ${({ theme }) => theme.colors.system.background};
        border-color: ${({ theme }) => theme.colors.system.gamma1};
        color: ${({ theme }) => theme.colors.system.beta};
      `;
  }
};

const InvoiceStatus = styled.div`
  display: content;
  font-size: 10px;
  line-height: 10px;
  padding: 8px 12px;
  border: 1px solid;
  border-radius: 25px;
  align-items: center;
  ${sansSerif.headlineFontStyles};
  ${(props) => invoiceStatusStyles(props)};
`;

export {
  InvoiceSectionContainer,
  InvoiceListItemContainer,
  InvoiceDetailsContainer,
  InvoiceID,
  StyledChevronRightIconInvoiceList,
  InvoiceDataContainer,
  InvoiceDate,
  InvoiceAmount,
  InvoiceStatus,
};
