import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import {
  useIonViewDidEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import * as Sentry from '@sentry/browser';
import { CameraStatus } from 'capacitor-camera-stream';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import { variants } from '../../../../components/Button';
import PageCloseButton from '../../../../components/PageCloseButton';
import CameraContext from '../../../../context/CameraContext';
import logEvent from '../../../../utils/logger';
import useAppTheme from '../../../../hooks/useAppTheme';
import useNavContext from '../../../../hooks/useNavContext';
import useComponentMounted from '../../../../hooks/useComponentMounted';
import usePageShown from '../../../../hooks/usePageShown';
import useRefreshCameraPreview from '../../../../hooks/useRefreshCameraPreview';
import { ReactComponent as DeviceStand } from '../../../../assets/device_portrait.svg';
import {
  Container,
  InfoContainer,
  CameraViewport,
} from '../Layout';

import {
  Content,
  PlaceDeviceInfoContainer,
  PlaceDeviceMessage,
  StyledIonSpinner,
  StyledCameraPreview,
  StyledLinkButton,
  StyledNoCameraButton,
  StyledNextButton,
  StyledCheckbox,
} from './styles';
import texts from './texts.json';

const PlaceDevice = ({
  onWorkoutStart,
}) => {
  const [cameraPreviewElement, setCameraPreviewElement] = useState(null);
  const [cameraStarted, setCameraStarted] = useState(false);
  const [enableContentBackground, setEnableContentBackground] = useState(true);
  const [isViewVisible, setIsViewVisible] = useState(false);
  const [isNoCameraCheckboxChecked, setIsnoCameraCheckboxChecked] = useState(false);
  const { goBack } = useNavContext();

  const isComponentMountedRef = useComponentMounted();

  usePageShown('PlaceDevice');
  // Refresh camera preview when isPortraitMode changes
  useRefreshCameraPreview();

  const { colors } = useAppTheme();

  const {
    camera: {
      startCamera,
      stopCamera,
      cameraStatus,
    },
    disableCamera,
    cameraAspectRatio,
  } = useContext(CameraContext);

  useEffect(() => {
    if (!cameraStarted && cameraPreviewElement) {
      const initCamera = async () => {
        try {
          if (cameraStatus === CameraStatus.ready || cameraStatus === CameraStatus.stopped) {
            await startCamera({
              htmlView: cameraPreviewElement,
              position: 'back',
              fitPreview: false,
              backgroundColor: colors.system.alpha,
            });
            setCameraStarted(true);
          }
        } catch (error) {
          stopCamera();
          setCameraStarted(false);
          Sentry.captureException(error, {
            extra: {
              description: 'Error when starting the camera in place device screen',
            },
          });
        }
      };
      initCamera();
    }
  }, [
    cameraStatus,
    startCamera,
    stopCamera,
    cameraStarted,
    cameraPreviewElement,
    isComponentMountedRef,
    colors.system.alpha,
  ]);

  useEffect(() => {
    const shouldEnableBackground = !isViewVisible || !cameraStarted || cameraStatus !== CameraStatus.started;
    setEnableContentBackground(shouldEnableBackground);
  }, [
    cameraStarted,
    cameraStatus,
    isViewVisible,
  ]);

  // Stop the camera when this component is unmounted
  useEffect(() => (() => {
    stopCamera();
  }), []); // eslint-disable-line

  useIonViewDidEnter(() => {
    setIsViewVisible(true);
  }, []);

  useIonViewWillLeave(() => {
    if (isComponentMountedRef.current) {
      setEnableContentBackground(true);
      setIsViewVisible(false);
      stopCamera();
    }
  }, [
    stopCamera,
  ]);

  const onCameraPreviewRefReady = useCallback((previewRef) => {
    setCameraPreviewElement(previewRef.current);
  }, []);

  const onNext = async () => {
    await stopCamera();
    onWorkoutStart();
  };

  // If the user wants to use the no-camera mode, we report it and start the workout.
  const onNoCameraButtonClick = async () => {
    logEvent('NoCameraModeEnabled');
    disableCamera(isNoCameraCheckboxChecked);
    await stopCamera();
    onWorkoutStart();
  };

  const onClose = async () => {
    setEnableContentBackground(true);
    await stopCamera();
    goBack();
  };

  return (
    <Container
      $enableBackground={enableContentBackground}
    >
      <Content>
        <PageCloseButton onClick={onClose} />
        <InfoContainer>
          <PlaceDeviceInfoContainer>
            <DeviceStand />
            <PlaceDeviceMessage>
              {texts.placeDeviceMessage}
            </PlaceDeviceMessage>
            <StyledNoCameraButton
              onClick={onNoCameraButtonClick}
              variant={variants.TERTIARY}
            >
              {texts.buttons.noCamera}
            </StyledNoCameraButton>
            <StyledLinkButton onClick={() => setIsnoCameraCheckboxChecked((currentValue) => !currentValue)}>
              <StyledCheckbox checked={isNoCameraCheckboxChecked} />
              {texts.alwaysStartWithoutCameraText}
            </StyledLinkButton>
          </PlaceDeviceInfoContainer>
        </InfoContainer>
        <CameraViewport $aspectRatio={cameraAspectRatio.numericValue}>
          {!cameraStarted && <StyledIonSpinner name="crescent" />}
          <StyledCameraPreview previewRefReady={onCameraPreviewRefReady} />
          <StyledNextButton onClick={onNext}>{texts.buttons.next}</StyledNextButton>
        </CameraViewport>
      </Content>
    </Container>
  );
};

PlaceDevice.propTypes = {
  onWorkoutStart: PropTypes.func.isRequired,
};

export default compose(
  observer,
)(PlaceDevice);
