import {
  useContext,
  useMemo,
  useCallback,
} from 'react';
import { forEach } from 'p-iteration';
import moment from 'moment';
import format from 'string-template';
import { Collection } from 'firestorter';
import { useRouteMatch } from 'react-router-dom';

import { workoutAssignmentStatuses } from '../../models/WorkoutAssignment';
import TrackedActivity from '../../models/TrackedActivity';
import WorkoutFeedView, { WorkoutFeedViewType } from '../../models/WorkoutFeedView';

import CollectionName from '../../utils/collections';
import logEvent from '../../utils/logger';
import { firestorePaths, pathPlaceholder } from '../../utils/firebasePaths';

import FirebaseContext from '../FirebaseContext';

import {
  calculateActivitySummaryId,
  calculateTrackedActivityId,
} from './utils';
import { CustomMetadataKey } from './metadata';

const useHealthDataStorage = () => {
  const {
    firebase: {
      firestore,
    },
  } = useContext(FirebaseContext);

  const { params: { userId } } = useRouteMatch();

  /**
   * Creates a new TrackedActivitySummary and WorkoutFeedView for the given health activity summary.
   *
   * @param {Object} data Data required in order to add a new TrackedActivitySummary.
   * @param {Object} data.activitySummary The activity summary data from health.
   * @param {string} data.activitySummaryId The TrackedActivitySummary ID.
   * @param {Date} data.startDate The start date of the activity summary, the beginning of the day.
   * @param {Date} data.endDate The end date of the activity summary.
   */
  const addActivitySummary = useCallback((data) => {
    const {
      activitySummary,
      activitySummaryId,
      startDate,
      endDate,
    } = data;

    /*
      Check if the data is valuable, there are at least one non-zero value.
      Do not create a new summary entry when all fields are zero.
    */
    const { energyBurned, exerciseTime, standHours } = activitySummary;
    if (energyBurned === '0' && exerciseTime === '0' && standHours === '0') {
      logEvent('trackedActivitySummaryNothingToAdd');
      return;
    }

    const trackedActivitySummary = {
      user: userId,
      startDate,
      endDate,
      ...activitySummary,
      lastUpdatedDateTime: moment.utc().toDate(),
    };

    // Create a new entry in trackedActivitySummary collection
    firestore
      .collection(CollectionName.TRACKED_ACTIVITY_SUMMARY)
      .doc(activitySummaryId)
      .set(trackedActivitySummary);

    // Creates a new entry in workoutFeedView collection as well
    firestore
      .collection(CollectionName.WORKOUT_FEED_VIEW)
      .doc(activitySummaryId)
      .set({
        ...trackedActivitySummary,
        type: WorkoutFeedViewType.TRACKED_ACTIVITY_SUMMARY,
        status: workoutAssignmentStatuses.COMPLETED,
        title: '',
      });
  }, [
    userId,
    firestore,
  ]);

  /**
   * Update TrackedActivitySummary and WorkoutFeedView documents with the given data.
   *
   * @param {Object} data Data required in order to update the TrackedActivitySummary document.
   * @param {Object} data.activitySummary The activity summary data from health.
   * @param {string} data.activitySummaryId The TrackedActivitySummary ID.
   */
  const updateTrackedActivitySummary = useCallback((data) => {
    const { activitySummary, activitySummaryId } = data;

    const lastUpdatedDateTime = moment.utc().toDate();

    firestore
      .collection(CollectionName.TRACKED_ACTIVITY_SUMMARY)
      .doc(activitySummaryId)
      .update({
        ...activitySummary,
        lastUpdatedDateTime,
      });

    firestore
      .collection(CollectionName.WORKOUT_FEED_VIEW)
      .doc(activitySummaryId)
      .set({
        ...activitySummary,
        user: userId,
        lastUpdatedDateTime,
        type: WorkoutFeedViewType.TRACKED_ACTIVITY_SUMMARY,
      }, {
        merge: true,
      });
  }, [
    userId,
    firestore,
  ]);

  /**
   * Create and/or update the trackedActivitySummary collection based on the result of querying healthkit
   * data.
   *
   * @param {Array} summaryResults An array of activity summary results from health.
   */
  const saveActivitiesSummary = useCallback((summaryResults) => (
    forEach(summaryResults, async (summary) => {
      const { date, ...activitySummary } = summary;
      const parsedDate = parseInt(date, 10);
      const startDate = moment(parsedDate).utc(true).startOf('day').toDate();
      const endDate = moment(parsedDate).utc(true).endOf('day').toDate();

      // Check if an activity summary for this day exists
      const summaryDocCol = await firestore
        .collection(CollectionName.TRACKED_ACTIVITY_SUMMARY)
        .where('user', '==', userId)
        .where('startDate', '==', startDate)
        .limit(1)
        .get();

      // Calculates the activity summary ID based on specific data: user ID and activity date
      const activitySummaryId = calculateActivitySummaryId(userId, startDate);

      if (summaryDocCol.empty) {
        const activityData = {
          activitySummary,
          activitySummaryId,
          startDate,
          endDate,
        };

        addActivitySummary(activityData, firestore, userId);
      } else {
        const summaryDoc = summaryDocCol.docs[0];
        const summaryDocData = summaryDoc.data();

        const documentFields = [
          'energyBurned',
          'energyBurnedGoal',
          'exerciseTime',
          'exerciseTimeGoal',
          'standHours',
          'standHoursGoal',
        ];

        const documentChanged = documentFields.some((field) => summaryDocData[field] !== activitySummary[field]);

        // Check if there are changes in the document in order to update it
        if (documentChanged) {
          updateTrackedActivitySummary({ activitySummaryId, activitySummary }, firestore, userId);

          logEvent('trackedActivitySummaryUpdated', {
            activityStartDate: startDate,
            currentDate: Date.now(),
          });
        }
      }
    })
  ), [
    firestore,
    userId,
    addActivitySummary,
    updateTrackedActivitySummary,
  ]);

  /**
   * Creates and/or updates the tracked activities records.
   *
   * @param {Array} activitiesData An array with all the activities data
   */
  const saveActivitiesData = useCallback((activitiesData) => (
    forEach(activitiesData, async (activityData) => {
      const {
        activityId,
        activityType,
        startDate,
        endDate,
        metadata,
        ...otherActivityFields
      } = activityData;

      // Check if an activity summary for this day exists
      const activityDataCollection = new Collection(firestorePaths.TRACKED_ACTIVITY, {
        createDocument: (src, opts) => new TrackedActivity(src, opts),
        query: (ref) => ref
          .where('user', '==', userId)
          .where('startDate', '==', startDate)
          .limit(1),
      });

      await activityDataCollection.fetch();

      let updateScenario = false;

      if (activityDataCollection.hasDocs) {
        const activityDataDoc = activityDataCollection.docs[0];

        if (activityDataDoc.hasSameData(activityData)) {
          // Nothing to do here!
          return;
        }

        updateScenario = true;
      }

      const trackedActivityDocId = calculateTrackedActivityId(userId, activityId);

      const activityPath = format(firestorePaths.TRACKED_ACTIVITY_DOC, {
        [pathPlaceholder.TRACKED_ACTIVITY_ID]: trackedActivityDocId,
      });

      const trackedActivityDoc = new TrackedActivity(activityPath);

      const lastUpdatedDateTime = moment.utc().toDate();
      const startDay = moment(startDate.toDate()).utc(true).startOf('day').toDate();
      const endDay = moment(endDate.toDate()).utc(true).endOf('day').toDate();

      const sanitizedActivityFields = TrackedActivity.sanitizeData(otherActivityFields);
      const trackedActivityDocData = {
        user: userId,
        lastUpdatedDateTime,
        activityType,
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
        startDay,
        endDay,
        ...sanitizedActivityFields,
      };

      const hasGameplaySessionId = metadata && metadata[CustomMetadataKey.GAMEPLAY_SESSION_ID];

      if (hasGameplaySessionId) {
        trackedActivityDocData.gameplaySession = metadata[CustomMetadataKey.GAMEPLAY_SESSION_ID];
      }

      if (updateScenario) {
        trackedActivityDoc.update(trackedActivityDocData);
      } else {
        trackedActivityDoc.set(trackedActivityDocData);
      }

      await trackedActivityDoc.init();

      // Only edit the WorkoutFeedView if there's no gameplaySessionId associated to the TrackedActivity
      if (!hasGameplaySessionId) {
        const { customTitle } = trackedActivityDoc;

        const workoutFeedViewDoc = new WorkoutFeedView(trackedActivityDocId);
        const feedViewDocData = {
          user: userId,
          lastUpdatedDateTime,
          title: customTitle || TrackedActivity.getActivityName(activityType),
          startDate: startDay,
          endDate: endDay,
          status: workoutAssignmentStatuses.COMPLETED,
          type: WorkoutFeedViewType.TRACKED_ACTIVITY,
        };

        // Add stats fields
        if (trackedActivityDocData.activeCalories) {
          feedViewDocData.activeCalories = trackedActivityDocData.activeCalories;
        }
        if (trackedActivityDocData.duration) {
          feedViewDocData.duration = trackedActivityDocData.duration;
        }

        if (updateScenario) {
          workoutFeedViewDoc.update(feedViewDocData);
        } else {
          workoutFeedViewDoc.set(feedViewDocData);
        }
      }

      if (updateScenario) {
        logEvent('trackedActivityDataUpdated', {
          activityStartDate: startDate,
          currentDate: Date.now(),
        });
      }
    })
  ), [
    userId,
  ]);

  return useMemo(() => ({
    saveActivitiesSummary,
    saveActivitiesData,
  }), [
    saveActivitiesSummary,
    saveActivitiesData,
  ]);
};

export default useHealthDataStorage;
