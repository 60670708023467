import { IonSpinner } from '@ionic/react';
import styled from 'styled-components';

import { sansSerif } from '../../../../styles/text';

const StyledSpinner = styled(IonSpinner)`
  width: 100%;
  margin: 30px 0;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  margin: 2%;
  flex-basis: 46%;
  background-color: ${({ theme }) => theme.colors.system.background};
  border-radius: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  margin-bottom: 4px;
`;

const StatLabel = styled.span`
  ${sansSerif.headlineFontStyles}
  font-size: 16px;
  line-height: 22px;
  text-align: center;
`;

const Value = styled.span`
  ${sansSerif.headlineFontStyles}
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  text-align: center;
  padding: 0 2px;
`;

const ValueContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const InfoLabel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.system.gamma2};
  border-radius: 10px;
`;

const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  margin: 0 15px 31px;
`;

export {
  ItemContainer,
  StyledSpinner,
  Container,
  IconContainer,
  StatLabel,
  Value,
  InfoLabel,
  SliderContainer,
  ValueContainer,
};
