import styled, { css } from 'styled-components';

const GeneralMessage = styled.div`
  width: 100%;
  font-size: 70px;
  display: flex;
  justify-content: center;

  ${({ theme }) => theme.isPortrait && css`
    font-size: 55px;
  `};
`;

export {
  GeneralMessage,
};
