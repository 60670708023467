import styled, { css } from 'styled-components';

import AssignmentDayType from '../../viewModel/assignmentType';

const getContainerPadding = ({ dayType }) => {
  let padding;

  if (dayType === AssignmentDayType.FUTURE) {
    padding = '0 16px 5px 52px';
  } else if (dayType === AssignmentDayType.TODAY) {
    padding = '16px';
  } else {
    padding = '16px 16px 16px 52px';
  }

  return css`
    padding: ${padding};
  `;
};

const Container = styled.div`
  display: flex;
  min-width: 30px;
  flex-direction: row;
  background-color: transparent;

  ${(props) => getContainerPadding(props)}
`;

export default Container;
