import React from 'react';
import { PropTypes } from 'prop-types';
import {
  CardContainer,
  FileDeleteIcon,
  StyledSlides,
  StyledSlide,
  StyledImg,
  ImageWrapper,
} from './styles';

// See http://idangero.us/swiper/api/ for valid options.
const Slides = ({
  options,
  imagesUrls,
  allowDelete,
  onDeletion,
  ...other
}) => (
  imagesUrls
  && imagesUrls.length !== 0
  && (
    <CardContainer>
      <StyledSlides key={imagesUrls} options={options} {...other}>
        {imagesUrls.map((url, index) => (
          <StyledSlide key={url}>
            {allowDelete && <FileDeleteIcon onClick={() => onDeletion(index)} />}
            <ImageWrapper>
              <StyledImg
                src={url}
                alt="slide-image"
              />
            </ImageWrapper>
          </StyledSlide>
        ))}
      </StyledSlides>
    </CardContainer>
  )
);

Slides.propTypes = {
  options: PropTypes.object,
  imagesUrls: PropTypes.array.isRequired,
  allowDelete: PropTypes.bool,
  // onDeletion is required if allowDelete is true
  // eslint-disable-next-line react/require-default-props, consistent-return
  onDeletion: (props, propName) => {
    if ((props.allowDelete === true && (props[propName] === undefined || typeof (props[propName]) !== 'function'))) {
      return new Error('Please provide a onDeletion function!');
    }
  },
};

Slides.defaultProps = {
  options: {
    initialSlide: 0,
    spaceBetween: 16,
  },
  allowDelete: false,
};

export default Slides;
