import React, {
  useCallback,
  useContext,
  useRef,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import MealIcon from '../../../../components/Icons/MealIcon';
import useMealPlanNavigation from '../../../../hooks/useMealPlanNavigation';
import MealPlanContext from '../../../../context/MealPlanContext';
import {
  todayWeekDayNumber,
  WeekDayNameByDayNumber,
} from '../../../../utils/calendar';

import MealItem from './components/MealItem';
import {
  CardContainer,
  StyledTitle,
  StyledFooter,
  StyledActionText,
  MealsContainer,
  IconContainer,
  Action,
  StyledCalendarDay,
  NavigationIconContainer,
  NavigateIcon,
} from './styles';
import texts from './texts.json';

const MealPlanCard = () => {
  const weekDayNumberRef = useRef(null);

  const {
    mealPlanAssignmentDoc: {
      mealTimes,
    },
  } = useContext(MealPlanContext);

  const {
    navigateToMealPlanPage,
    navigateToMealDetailsPage,
  } = useMealPlanNavigation();

  if (weekDayNumberRef.current === null) {
    weekDayNumberRef.current = todayWeekDayNumber();
  }

  const renderMeals = useCallback(() => (
    <MealsContainer>
      {mealTimes.map(({ meals, name }) => {
        // If for some reason there's an empty bucket, skip rendering the preview
        if (meals.length === 0) {
          return null;
        }
        // Show meals based on the day of the week
        const mealIndex = weekDayNumberRef.current % meals.length;
        const {
          recipe: {
            id: recipeId,
            name: recipeName,
            image,
          },
        } = meals[mealIndex];

        return (
          <MealItem
            key={`${name}-${recipeId}`}
            title={name}
            description={recipeName}
            imageSrc={image}
            onClick={() => navigateToMealDetailsPage(name, mealIndex)}
          />
        );
      })}
    </MealsContainer>
  ), [
    mealTimes,
    weekDayNumberRef,
    navigateToMealDetailsPage,
  ]);

  return (
    <CardContainer>
      <StyledCalendarDay dayName={WeekDayNameByDayNumber[weekDayNumberRef.current]} />
      <StyledTitle>{texts.title}</StyledTitle>
      {renderMeals()}
      <StyledFooter>
        <Action onClick={navigateToMealPlanPage}>
          <IconContainer>
            <MealIcon />
          </IconContainer>
          <StyledActionText>
            {texts.viewFullPlan}
          </StyledActionText>
          <NavigationIconContainer>
            <NavigateIcon />
          </NavigationIconContainer>
        </Action>
      </StyledFooter>
    </CardContainer>
  );
};

export default compose(
  observer,
)(MealPlanCard);
