import { Collection } from 'firestorter';

import { firestorePaths } from '../utils/firebasePaths';
import BaseDocument from './BaseDocument';

class Changelog extends BaseDocument {
  get version() {
    return this.data.version;
  }

  get content() {
    return this.data.content;
  }

  get releaseDate() {
    return this.data.releaseDate.toDate();
  }

  /**
   * Returns the version information docs for the current channel.
   * @param {string} version The version user is currently on
   */
  static async get(version) {
    const paddedVersion = version.split('.').map((num) => num.padStart(4, 0)).join('.');
    const changelogCollection = new Collection(() => firestorePaths.CHANGELOG, {
      createDocument: (src, opts) => new Changelog(src, opts),
      query: (ref) => ref
        .where('version', '<=', paddedVersion)
        .orderBy('version', 'desc')
        .limit(10),
    });
    await changelogCollection.fetch();
    return changelogCollection;
  }
}

export default Changelog;
