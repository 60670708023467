import styled from 'styled-components';

import Button from '../../../../../components/Button';

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.base.tertiary};
  color: ${({ theme }) => theme.colors.fonts.primary};
  margin-bottom: 16px;
`;

export {
  StyledButton,
};
