import styled, { css } from 'styled-components';

import { safeAreaFromRight, safeAreaFromTop } from '../../../../../../styles/mixins';
import {
  headline1,
  body1,
} from '../../../../../../styles/text';

const StyledFinishedWorkout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 max(${safeAreaFromRight()}, 20px);
  background: rgba(0,0,0,0.9);
  display: flex;
  z-index: 3;
`;

const MedalContainer = styled.div`
  width: 50%;

  ${({ theme }) => theme.isPortrait && css`
    left: 50%;
    transform: translate(-50%);
    top: ${safeAreaFromTop()};
    width: initial;
    position: absolute;
  `}

  svg {
    width: 100%;
    height: 100%;
    max-height: 100vh;
  }
`;

const MessageContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  ${({ theme }) => theme.isPortrait && css`
    width: initial;
  `}
`;

const Title = styled.div`
  ${headline1}
  font-size: 30px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.system.alpha};
`;

const Message = styled.div`
  ${body1}
  color: ${({ theme }) => theme.colors.system.alpha};
  margin: 15px auto 30px auto;
  width: 80%;
`;

export {
  StyledFinishedWorkout,
  MedalContainer,
  MessageContainer,
  Title,
  Message,
};
