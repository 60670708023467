import React, { useContext } from 'react';

import LoadingPage from '../../components/LoadingPage';
import AppContext from './AppContext';
import AppContextProvider from './AppContextProvider';

const withAppContextProvider = (Component) => (props) => (
  <AppContextProvider>
    <Component {...props} />
  </AppContextProvider>
);

/**
 * Renders the given component when the app context is ready: app versions are loaded,
 * if not, it will render the LoadingPage component.
 *
 * @param {Object} Component The component to render.
 * @param {Object} Component The rendered component.
 * @returns {Object} The component to render or the LoadingPage component.
 */
const withAppContextReady = (Component) => (props) => {
  const { isReady } = useContext(AppContext);
  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withAppContextProvider,
  withAppContextReady,
};
