import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import { getStatsViewModel } from '../../../../services/trackedActivity/statsViewModel';
import TrackedActivity from '../../../../models/TrackedActivity';
import HeartRateGraph from '../../../../components/HeartRateGraph';
import Stat from '../../../../components/Stat';

import {
  StyledCard,
  InfoContainer,
  GraphContainer,
} from './styles';

const TrackedStats = ({
  trackedActivity,
}) => {
  const getStats = useCallback(() => {
    const stats = getStatsViewModel(trackedActivity);
    return stats.map((stat) => <Stat key={stat.description} {...stat} />);
  }, [
    trackedActivity,
  ]);

  const {
    heartRateMeasurements,
  } = trackedActivity;

  return (
    <StyledCard>
      <InfoContainer>
        {getStats()}
      </InfoContainer>
      {heartRateMeasurements.length > 0 && (
        <GraphContainer>
          <HeartRateGraph dataPoints={heartRateMeasurements} />
        </GraphContainer>
      )}
    </StyledCard>
  );
};

TrackedStats.propTypes = {
  trackedActivity: PropTypes.instanceOf(TrackedActivity).isRequired,
};

export default compose(
  observer,
)(TrackedStats);
