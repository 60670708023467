import { css } from 'styled-components';

import {
  safeAreaFromTop,
  safeAreaFromBottom,
  safeAreaFromLeft,
  safeAreaFromRight,
} from './mixins';

const safePaddings = css`
  padding-top: ${safeAreaFromTop()};
  padding-bottom: ${safeAreaFromBottom()};
  padding-left: ${safeAreaFromLeft()};
  padding-right: ${safeAreaFromRight()};
`;

export {
  safePaddings,
};
