import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as BurgerIcon } from '../../../assets/burger-icon.svg';
import { StyledBurger } from './styles';

const Burger = ({ onClick }) => (
  <StyledBurger onClick={onClick}>
    <BurgerIcon />
  </StyledBurger>
);

Burger.propTypes = {
  onClick: PropTypes.func,
};

Burger.defaultProps = {
  onClick: () => {},
};

export default Burger;
