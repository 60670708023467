import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';

import {
  StyledImage,
  StyledPlaceholderImage,
} from './styles';

const MealImage = ({
  src,
  className,
}) => {
  const [imageError, setImageError] = useState(false);

  const handleError = () => setImageError(true);

  return (!src || imageError)
    ? <StyledPlaceholderImage className={className} />
    : (
      <StyledImage
        src={src}
        onError={handleError}
        className={className}
      />
    );
};

MealImage.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
};

MealImage.defaultProps = {
  className: '',
};

export default MealImage;
