import styled from 'styled-components';

import WorkoutBaseData from '../../../components/WorkoutBaseData/WorkoutBaseData';
import PageContent from '../../../components/PageContent';
import colors from '../../../styles/baseColors';
import {
  overline,
} from '../../../styles/text';

const BtnGroup = styled.div`
  position: relative;
`;

const PrimaryBtn = styled.button`
  background-color: transparent;
  padding: 4px;
  margin: 4px;
  width: 28px;
  height: 28px;
`;

const StyledPageContent = styled(PageContent)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: ${colors.system.alpha};
`;

const StyledWorkoutDetails = styled.div`
  padding-top: 30px;
  overflow: auto;
  height: 100%;
  background-color: ${colors.system.background};
`;

const WorkHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const WorkoutName = styled.div`
  ${overline}
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 0 18px;
  font-size: 16px;
  color: ${colors.base.primary};
  text-transform: uppercase;
`;

const StyledWorkoutBaseData = styled(WorkoutBaseData)`
  background-color: transparent;
`;

export {
  BtnGroup,
  PrimaryBtn,
  StyledPageContent,
  StyledWorkoutDetails,
  WorkHeader,
  WorkoutName,
  StyledWorkoutBaseData,
};
