import { CameraStreamAspectRatio } from 'capacitor-camera-stream';

const availableAspectRatios = {
  STANDARD: {
    name: CameraStreamAspectRatio.standard,
    numericValue: 4 / 3,
  },
  HD: {
    name: CameraStreamAspectRatio.hd,
    numericValue: 16 / 9,
  },
};

export {
  availableAspectRatios,
};
