import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as NoteIcon } from '../../assets/icons/note_icon.svg';

import {
  NoteContainer,
  NoteContent,
} from './styles';

const Note = ({
  className,
  children,
}) => (
  <NoteContainer className={className}>
    <NoteIcon />
    <NoteContent>{children}</NoteContent>
  </NoteContainer>
);

Note.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Note.defaultProps = {
  className: '',
};

export default Note;
