import {
  decorate,
  action,
  observable,
} from 'mobx';
import { CameraStatus } from 'capacitor-camera-stream';

import BaseCameraProvider from './BaseCameraProvider';

class BrowserCameraProvider extends BaseCameraProvider {
  constructor() {
    super();
    this.cameraStatus = CameraStatus.ready;
  }

  static defaultMediaOptions = {
    audio: false,
    video: {
      facingMode: 'user',
    },
  };

  /**
   * Starts the camera stream by using WebRTC APIs.
   *
   * @param {Object} options
   * @returns {Promise} A promise resolved with the stream instance.
   */
  startCamera = async (options) => {
    this.cameraStatus = CameraStatus.starting;

    const mediaOptions = {
      ...BrowserCameraProvider.defaultMediaOptions,
      ...options.mediaOptions,
    };
    /*
      By explicitly using navigator media API, the permissions granting modal
      is displayed properly in Android without failing
    */
    this.stream = await navigator.mediaDevices.getUserMedia(mediaOptions);
    this.cameraStatus = CameraStatus.started;
    return this.stream;
  }

  /**
   * Stops the camera stream. Iterates all available stream tracks and stop them.
   */
  stopCamera = () => {
    this.cameraStatus = CameraStatus.stopping;

    if (this.stream) {
      this.stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    this.cameraStatus = CameraStatus.stopped;
    return Promise.resolve();
  }

  hasCameraSupport = () => !!navigator.mediaDevices.getUserMedia;
}

decorate(BrowserCameraProvider, {
  stream: observable,
  cameraStatus: observable,
  startCamera: action,
  stopCamera: action,
});

export default BrowserCameraProvider;
