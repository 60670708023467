import PoseQuality from './poseQuality';
import { PoseReasonData } from './poseReasons';

/** struct to represent a reason that a human is not in an optimal pose */
function Reason(key) {
  this.key = key;
  this.text = PoseReasonData[key].text;
  this.quality = PoseReasonData[key].quality;
}

/** @class Class that represents the result of detecting a pose for a human */
class PoseDetectionResult {
  constructor(reasons = []) {
    this.reasons = reasons;
    this.debugData = {};
  }

  toJSON() {
    return {
      reasons: this.reasons.map((reason) => ({ ...reason })),
      quality: this.quality,
      inPose: this.inPose,
      debugData: this.debugData,
    };
  }

  addReason(reason) {
    if (typeof (reason) !== 'string') {
      throw new Error('Each rule requires a reason.');
    }
    this.reasons.push(new Reason(reason));
  }

  /** Returns a list of reason texts of reasons that match the worst quality */
  get displayReasons() {
    return this.reasons
      .filter((reason) => reason.quality === this.quality)
      .map((reason) => reason.text);
  }

  /** Returns the name of the quality of the worst reason, or Perfect if there are no reasons. */
  get quality() {
    const qualities = this.reasons.map((r) => r.quality);
    const quality = Math.min(PoseQuality.Perfect, ...qualities);
    return quality;
  }

  /** Returns whether or not the pose was correctly detected. */
  get inPose() {
    return this.quality !== PoseQuality.NotInPose;
  }
}

export default PoseDetectionResult;
