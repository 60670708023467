import styled from 'styled-components';
import { sansSerif } from '../../../styles/text';

const StyledCommentInput = styled.textarea`
  ${sansSerif.headlineFontStyles};
  background-color: ${({ theme }) => theme.colors.system.background};
  padding: 10px;
  font-size: 12px;
  margin: 10px 0;
  width: 100%;
  border: none;
  border-radius: 0;
  &:focus {
    outline: none;
  }
`;

const HabitStatContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 4px 0;
`;

const WorkoutStatContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 10px 0;
`;

const NutritionalInformationContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin: 10px 0;
`;

export {
  StyledCommentInput,
  HabitStatContainer,
  WorkoutStatContainer,
  NutritionalInformationContainer,
};
