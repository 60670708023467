import React, { useContext } from 'react';

import LoadingPage from '../../components/LoadingPage';

import LoggedInUserContextProvider from './LoggedInUserContextProvider';
import LoggedInUserContext from './LoggedInUserContext';

/**
 * Inserts LoggedInUserContextProvider in the react tree
 * before adding the given component.
 *
 * @param {Object} Component The component that needs the context provider.
 * @returns {Object} The component to render wrapped with the provider.
 */
const withLoggedInUserContextProvider = (Component) => (props) => (
  <LoggedInUserContextProvider>
    <Component {...props} />
  </LoggedInUserContextProvider>
);

/**
 * Renders the given component when the logged in user context is ready.
 *
 * @param {Object} Component The component to render.
 * @param {Object} Component The rendered component.
 * @returns {Object} The component to render or the LoadingPage component.
 */
const withLoggedInUserContextReady = (Component) => (props) => {
  const { isReady } = useContext(LoggedInUserContext);
  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withLoggedInUserContextReady,
  withLoggedInUserContextProvider,
};
