import styled from 'styled-components';
import { IonSpinner, IonProgressBar } from '@ionic/react';

import { sansSerif } from '../../../../../styles/text';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 20px 0 0;
  padding: 15px;
  min-width: 38%;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.system.background};
  justify-content: space-between;
`;

const HabitText = styled.span`
  ${sansSerif.headlineFontStyles}
  font-size: 12px;
  line-height: 22px;
  margin-top: 2px;
`;

const StatText = styled.span`
  ${sansSerif.headlineFontStyles}
  font-size: 12px;
  line-height: 22px;
  margin-top: 2px;
  color: ${({ theme }) => theme.colors.system.gamma2};
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 6px 0;
  border-radius: 10px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LoaderWrapper = styled.div`
  height: 45px;
  max-height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Loading = styled(IonSpinner)`
  width: 100%;
`;

const StyledHabitProgress = styled(IonProgressBar)`
  width: 100%;
  height: 8px;
  border-radius: 25px;
  --background: ${({ theme }) => theme.colors.system.gamma1};
  --progress-background: ${({ theme }) => theme.colors.base.secondaryVariant};
`;

export {
  Container,
  HabitText,
  ItemContainer,
  TextContainer,
  StatText,
  LoaderWrapper,
  Loading,
  StyledHabitProgress,
};
