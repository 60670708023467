/* eslint-disable max-len */
import CollectionName from './collections';

const pathPlaceholder = {
  USER_ID: 'userId',
  COACH_ID: 'coachId',
  GAMEPLAY_SESSION_ID: 'gameplaySessionId',
  ACTIVITY_SESSION_ID: 'activitySessionId',
  WORKOUT_HISTORY_FRAME: 'workoutHistoryFrame',
  CUSTOM_WORKOUT_ID: 'customWorkoutId',
  FILE_NAME: 'fileName',
  COACH_NOTIFICATION: 'coachNotification',
  GAMEPLAY_SESSION_VIDEO_REQUEST: 'gameplaySessionVideoRequest',
  TRACKED_ACTIVITY_ID: 'trackedActivityId',
  MEASUREMENT_LOG_ID: 'measurementLogId',
  SPOONACULAR_RECIPE_ID: 'recipeId',
  SPOONACULAR_MENUITEM_ID: 'menuItemId',
  SPOONACULAR_PRODUCT_ID: 'productId',
  CHECK_IN_ID: 'checkInId',
};

const firestorePaths = {
  USER: `/${CollectionName.USER}`,
  USER_CONFIG: `/${CollectionName.CONFIG}`,
  GAMEPLAY_SESSION_COLLECTION: `/${CollectionName.GAMEPLAY_SESSION}`,
  USER_DEVICE: `/${CollectionName.USER_DEVICE}`,
  USER_ACTIVITY_HISTORY: `/${CollectionName.USER_ACTIVITY_HISTORY}`,
  USER_EXERCISE_OVERRIDES: `/${CollectionName.USER_EXERCISE_OVERRIDES}`,
  EXERCISE: `/${CollectionName.EXERCISE}`,
  COACH: `/${CollectionName.COACH}`,
  TRACKED_ACTIVITY_SUMMARY: `/${CollectionName.TRACKED_ACTIVITY_SUMMARY}`,
  WORKOUT: `/${CollectionName.WORKOUT}`,
  WORKOUT_ASSIGNMENT: `/${CollectionName.WORKOUT_ASSIGNMENT}`,
  STREAK: `/${CollectionName.STREAK}`,
  TRACKED_ACTIVITY: `/${CollectionName.TRACKED_ACTIVITY}`,
  REFERRAL_CODE: `/${CollectionName.REFERRAL_CODE}`,
  APP_CUSTOMIZATION: `/${CollectionName.APP_CUSTOMIZATION}`,
  CHANGELOG: `/${CollectionName.CHANGELOG}`,
  MEAL_PLAN_ASSIGNMENT: `/${CollectionName.MEAL_PLAN_ASSIGNMENT}`,
  MESSAGE_SUGGESTION: `/${CollectionName.MESSAGE_SUGGESTION}`,
  MEASUREMENTS_LOG: `/${CollectionName.MEASUREMENTS_LOG}`,
  INTEGRATIONS: `/${CollectionName.INTEGRATIONS}`,
  HABIT_LOG: `/${CollectionName.HABIT_LOG}`,
  POST_PAYMENT_FORM_ANSWER: `/${CollectionName.POST_PAYMENT_FORM_ANSWER}`,
  USER_CONTRACT: `/${CollectionName.USER_CONTRACT}`,
  USER_NUTRITION_LOG: `/${CollectionName.USER_NUTRITION_LOG}`,
  CHECK_IN: `/${CollectionName.CHECK_IN}`,
};

firestorePaths.GAMEPLAY_SESSION_DOC = `${firestorePaths.GAMEPLAY_SESSION_COLLECTION}/{${pathPlaceholder.GAMEPLAY_SESSION_ID}}`;
firestorePaths.USER_DOC = `${firestorePaths.USER}/{${pathPlaceholder.USER_ID}}`;
firestorePaths.USER_CONFIG_DOC = `${firestorePaths.USER_CONFIG}/{${pathPlaceholder.USER_ID}}`;
firestorePaths.COACH_DOC = `${firestorePaths.COACH}/{${pathPlaceholder.COACH_ID}}`;
firestorePaths.ACTIVITY_SESSION_COLLECTION = `${firestorePaths.GAMEPLAY_SESSION_DOC}/ActivitySessions`;
firestorePaths.ACTIVITY_SESSION_DOC = `${firestorePaths.ACTIVITY_SESSION_COLLECTION}/{${pathPlaceholder.ACTIVITY_SESSION_ID}}`;
firestorePaths.WORKOUT_FRAMES_COLLECTION = `${firestorePaths.ACTIVITY_SESSION_DOC}/WorkoutFrames`;
firestorePaths.TRACKED_ACTIVITY_DOC = `${firestorePaths.TRACKED_ACTIVITY}/{${pathPlaceholder.TRACKED_ACTIVITY_ID}}`;
firestorePaths.MEASUREMENTS_LOG_DOC = `${firestorePaths.MEASUREMENTS_LOG}/{${pathPlaceholder.MEASUREMENT_LOG_ID}}`;
firestorePaths.INTEGRATIONS_SPOONACULAR_COLLECTION = `${firestorePaths.INTEGRATIONS}/spoonacular`;
firestorePaths.SPOONACULAR_RECIPES_COLLECTION = `${firestorePaths.INTEGRATIONS_SPOONACULAR_COLLECTION}/recipes`;
firestorePaths.SPOONACULAR_MENUITEMS_COLLECTION = `${firestorePaths.INTEGRATIONS_SPOONACULAR_COLLECTION}/menuItems`;
firestorePaths.SPOONACULAR_PRODUCTS_COLLECTION = `${firestorePaths.INTEGRATIONS_SPOONACULAR_COLLECTION}/products`;
firestorePaths.SPOONACULAR_RECIPE_DOC = `${firestorePaths.SPOONACULAR_RECIPES_COLLECTION}/{${pathPlaceholder.SPOONACULAR_RECIPE_ID}}`;
firestorePaths.SPOONACULAR_MENUITEM_DOC = `${firestorePaths.SPOONACULAR_MENUITEMS_COLLECTION}/{${pathPlaceholder.SPOONACULAR_MENUITEM_ID}}`;
firestorePaths.SPOONACULAR_PRODUCT_DOC = `${firestorePaths.SPOONACULAR_PRODUCTS_COLLECTION}/{${pathPlaceholder.SPOONACULAR_PRODUCT_ID}}`;
firestorePaths.INTEGRATIONS_STRIPE_COLLECTION = `${firestorePaths.INTEGRATIONS}/stripe`;
firestorePaths.STRIPE_CUSTOMERS_COLLECTION = `${firestorePaths.INTEGRATIONS_STRIPE_COLLECTION}/customers`;
firestorePaths.STRIPE_PAYMENT_METHODS_COLLECTION = `${firestorePaths.INTEGRATIONS_STRIPE_COLLECTION}/paymentMethods`;
firestorePaths.INTEGRATIONS_CRONOMETER_COLLECTION = `${firestorePaths.INTEGRATIONS}/cronometer`;
firestorePaths.CRONOMETER_TOKEN = `${firestorePaths.INTEGRATIONS_CRONOMETER_COLLECTION}/token`;

const storagePaths = {
  USER: 'user',
  BROADCAST_MESSAGES_ATTACHMENTS: 'broadcastMessagesAttachments',
};

storagePaths.USER_COLLECTION = `${storagePaths.USER}/{${pathPlaceholder.USER_ID}}`;
storagePaths.GAMEPLAY_SESSION_COLLECTION = `${storagePaths.USER_COLLECTION}/GameplaySessions`;
storagePaths.GAMEPLAY_SESSION_DOC = `${storagePaths.GAMEPLAY_SESSION_COLLECTION}/{${pathPlaceholder.GAMEPLAY_SESSION_ID}}`;
storagePaths.ACTIVITY_SESSION_COLLECTION = `${storagePaths.GAMEPLAY_SESSION_DOC}/ActivitySessions`;
storagePaths.ACTIVITY_SESSION_DOC = `${storagePaths.ACTIVITY_SESSION_COLLECTION}/{${pathPlaceholder.ACTIVITY_SESSION_ID}}`;
storagePaths.WORKOUT_HISTORY_FRAME = `${storagePaths.ACTIVITY_SESSION_DOC}/{${pathPlaceholder.WORKOUT_HISTORY_FRAME}}.jpg`;
storagePaths.CUSTOM_WORKOUT_COLLECTION = `${storagePaths.USER_COLLECTION}/customWorkout`;
storagePaths.CUSTOM_WORKOUT_DOC = `${storagePaths.USER_COLLECTION}/customWorkout/{${pathPlaceholder.CUSTOM_WORKOUT_ID}}`;
storagePaths.CUSTOM_WORKOUT_ATTACHEMENT = `${storagePaths.CUSTOM_WORKOUT_DOC}/{${pathPlaceholder.FILE_NAME}}`;
storagePaths.TRACKED_ACTIVITY_COLLECTION = `${storagePaths.USER_COLLECTION}/trackedActivity`;
storagePaths.TRACKED_ACTIVITY_DOC = `${storagePaths.TRACKED_ACTIVITY_COLLECTION}/{${pathPlaceholder.TRACKED_ACTIVITY_ID}}`;
storagePaths.TRACKED_ACTIVITY_ATTACHEMENT = `${storagePaths.TRACKED_ACTIVITY_DOC}/{${pathPlaceholder.FILE_NAME}}`;
storagePaths.MEASUREMENT_LOG_COLLECTION = `${storagePaths.USER_COLLECTION}/measurementLog`;
storagePaths.MEASUREMENT_LOG_DOC = `${storagePaths.MEASUREMENT_LOG_COLLECTION}/{${pathPlaceholder.MEASUREMENT_LOG_ID}}`;
storagePaths.CHECK_IN_COLLECTION = `${storagePaths.USER_COLLECTION}/checkIn`;
storagePaths.CHECK_IN_DOC = `${storagePaths.CHECK_IN_COLLECTION}/{${pathPlaceholder.CHECK_IN_ID}}`;
storagePaths.COACH_BROADCAST_MESSAGES_ATTACHMENTS = `${storagePaths.BROADCAST_MESSAGES_ATTACHMENTS}/{${pathPlaceholder.COACH_ID}}`;

export {
  firestorePaths,
  storagePaths,
  pathPlaceholder,
};
