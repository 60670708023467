import styled, { css } from 'styled-components';

import { BannerType } from '../../context/MessageBannerContext/utils';

// Banner container styles based on the banner type
const containerStyles = ({ type, theme }) => {
  switch (type) {
    case BannerType.SUCCESS:
      return css`
        background-color: ${theme.colors.system.success};
        justify-content: center;
      `;
    case BannerType.WARNING:
      return css`
        background-color: ${theme.colors.base.secondary};
        justify-content: center;
      `;
    case BannerType.ERROR:
      return css`
        background-color: ${theme.colors.system.error2};
        justify-content: center;
      `;
    case BannerType.INFO:
    default:
      return css`
        border-bottom: ${theme.colors.system.beta} solid 1px;
        background-color: ${theme.colors.base.secondary};
      `;
  }
};

const Container = styled.div`
  position: ${({ staticBanner }) => (staticBanner ? 'static' : 'absolute')};
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 15px;
  left: 0;
  top: 100%;
  color: ${({ theme }) => theme.colors.base.primary};
  ${(props) => containerStyles(props)}
`;

/* eslint-disable max-len */
const IconContainer = styled.div`
  width: 30px;
  height: 30px;
  margin-right: 6px;
  background: linear-gradient(0deg, rgba(249, 215, 77, 0.4), rgba(249, 215, 77, 0.4)), ${({ theme }) => theme.colors.system.alpha};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
  }
`;
/* eslint-enable max-len */

export {
  Container,
  IconContainer,
};
