class StepResult {
  constructor(inPoseProcessed, qualityProcessed, poseDetectionResult) {
    this.inPoseProcessed = inPoseProcessed;
    this.qualityProcessed = qualityProcessed;
    this.poseDetectionResult = poseDetectionResult;
  }

  toJSON() {
    const poseDetectionResult = this.poseDetectionResult
      ? this.poseDetectionResult.toJSON()
      : null;

    return {
      poseDetectionResult,
      inPoseProcessed: this.inPoseProcessed,
      qualityProcessed: this.qualityProcessed,
    };
  }
}

export default StepResult;
