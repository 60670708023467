import styled from 'styled-components';

const CameraPreviewPlaceholder = styled.img`
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
`;

export {
  CameraPreviewPlaceholder,
};
