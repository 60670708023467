import { Collection } from 'firestorter';
import moment from 'moment';

import CollectionName from '../utils/collections';
import logEvent from '../utils/logger';
import BaseDocument from './BaseDocument';

class HabitLog extends BaseDocument {
  get date() {
    return this.data.date.toDate();
  }

  get sleep() {
    return this.data.sleep;
  }

  get mood() {
    return this.data.mood;
  }

  get waterIntake() {
    return this.data.waterIntake;
  }

  get steps() {
    return this.data.steps;
  }

  get habits() {
    return this.data.habits;
  }

  static addDoc = async (data) => {
    const habitLogCollection = new Collection(CollectionName.HABIT_LOG);
    const habitLogDoc = await habitLogCollection.add(data);
    logEvent('AddedHabitLog', { docId: habitLogDoc.id, habits: data.habits });
    return habitLogDoc;
  };

  static getLogsByDate = async (userId, date) => {
    const startDate = moment.utc(date).startOf('day').toDate();
    const habitLogCollection = new Collection(() => CollectionName.HABIT_LOG, {
      createDocument: (src, opts) => new HabitLog(src, opts),
      query: (ref) => ref
        .where('user', '==', userId)
        .where('date', '==', startDate),
    });
    await habitLogCollection.fetch();

    return habitLogCollection;
  }

  static getLogs(userId, options) {
    const {
      startAfterDoc = null,
      limit = 15,
    } = options;
    return new Collection(() => CollectionName.HABIT_LOG, {
      createDocument: (src, opts) => new HabitLog(src, opts),
      query: (ref) => {
        const query = ref
          .where('user', '==', userId)
          .orderBy('date', 'desc')
          .limit(limit);
        if (startAfterDoc) {
          return query.startAfter(startAfterDoc.snapshot);
        }
        return query;
      },
    });
  }
}

export default HabitLog;
