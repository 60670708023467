import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import useAppTheme from '../../../../hooks/useAppTheme';
import Button from '../../../../components/Button';
import PageCloseButton from '../../../../components/PageCloseButton';
import CameraPreview from '../../../../components/CameraPreview';
import { ReactComponent as CameraImage } from '../../../../assets/camera.svg';
import texts from './texts.json';
import {
  StyledPageContent,
  Content,
  ImageContainer,
  VideoContainer,
  Message,
  ActionsContainer,
  StyledButton,
} from './styles';

const GrantCameraAccess = ({
  isGranted,
  isDenied,
  onGrantCamera,
  onContinue,
  onContinueWithoutCamera,
  onClose,
  onCameraPreviewRefReady,
}) => {
  const [showCameraPreview, setShowCameraPreview] = useState(false);

  const { colors } = useAppTheme();

  const grantCamera = useCallback(() => {
    setShowCameraPreview(true);
    onGrantCamera();
  }, [
    onGrantCamera,
  ]);

  const renderActionButton = () => {
    // If the camera is already on, then display the continue button.
    const onClick = isGranted ? onContinue : grantCamera;
    let text;

    if (isGranted) {
      text = texts.actionNext;
    } else if (isDenied) {
      text = texts.actionAppSettings;
    } else {
      text = texts.actionGrantAccess;
    }

    return (
      <StyledButton
        variant="primary"
        onClick={onClick}
      >
        {text}
      </StyledButton>
    );
  };

  return (
    <StyledPageContent>
      <Content>
        <PageCloseButton
          onClick={onClose}
          color={colors.system.alpha}
        />
        <VideoContainer shown={showCameraPreview}>
          <CameraPreview previewRefReady={onCameraPreviewRefReady} />
        </VideoContainer>
        <ImageContainer shown={!isGranted}>
          <CameraImage />
        </ImageContainer>
        <Message>
          {texts.description}
        </Message>
        {isDenied && (
          <Message>
            {texts.deniedMessage}
          </Message>
        )}
      </Content>
      <ActionsContainer>
        {renderActionButton()}
        {!isGranted && (
          <Button
            onClick={onContinueWithoutCamera}
            variant="semi_transparent"
          >
            {texts.actionStartNoCamera}
          </Button>
        )}
      </ActionsContainer>
    </StyledPageContent>
  );
};

GrantCameraAccess.propTypes = {
  isGranted: PropTypes.bool.isRequired,
  isDenied: PropTypes.bool.isRequired,
  onGrantCamera: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  onContinueWithoutCamera: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onCameraPreviewRefReady: PropTypes.func.isRequired,
};

export default GrantCameraAccess;
