import moment from 'moment';
import * as Sentry from '@sentry/browser';
import { v4 as uuidv4 } from 'uuid';
import format from 'string-template';
import app from 'firebase/app';

import { DateFormat } from '../../utils/date';
import { pathPlaceholder, storagePaths } from '../../utils/firebasePaths';
import { workoutAssignmentStatuses } from '../../models/WorkoutAssignment';
import CustomWorkout from '../../models/CustomWorkout';
import WorkoutFeedView, { WorkoutFeedViewType } from '../../models/WorkoutFeedView';

/**
 *
 * @type {
 * {SUCCESSFULLY_COMPLETED_ATTACHMENT_PROCESSING: {processCompleted: boolean, value: string},
 * PERSISTING_IN_FIRESTORE: {processCompleted: boolean, value: string},
 * PROCESSING_ATTACHMENT: {processCompleted: boolean, value: string},
 * PROCESSING_ATTACHMENT_FAILED: {processCompleted: boolean, value: string, isSuccess: boolean},
 * INITIAL: {processCompleted: boolean, value: string},
 * FIRESTORE_PERSISTING_SUCCEEDED: {processCompleted: boolean, value: string, isSuccess: boolean},
 * FIRESTORE_PERSISTING_FAILED: {processCompleted: boolean, value: string, isSuccess: boolean},
 * INTERNAL_ERROR_OCCURRED: {processCompleted: boolean, value: string, isSuccess: boolean}}}
 */
const CustomWorkoutProcessState = {
  INITIAL: {
    value: 'INITIAL',
    processCompleted: false,
  },
  PROCESSING_ATTACHMENT: {
    value: 'PROCESSING_ATTACHMENT',
    processCompleted: false,
  },
  SUCCESSFULLY_COMPLETED_ATTACHMENT_PROCESSING: {
    value: 'SUCCESSFULLY_COMPLETED_ATTACHMENT_PROCESSING',
    processCompleted: false,
  },
  PROCESSING_ATTACHMENT_FAILED: {
    value: 'PROCESSING_ATTACHMENT_FAILED',
    processCompleted: true,
    isSuccess: false,
  },
  PERSISTING_IN_FIRESTORE: {
    value: 'PERSISTING_IN_FIRESTORE',
    processCompleted: false,
  },
  FIRESTORE_PERSISTING_FAILED: {
    value: 'FIRESTORE_PERSISTING_FAILED',
    processCompleted: true,
    isSuccess: false,
  },
  FIRESTORE_PERSISTING_SUCCEEDED: {
    value: 'FIRESTORE_PERSISTING_SUCCEEDED',
    processCompleted: true,
    isSuccess: true,
  },
  INTERNAL_ERROR_OCCURRED: {
    value: 'INTERNAL_ERROR_OCCURRED',
    processCompleted: true,
    isSuccess: false,
  },
};

const createIdForCustomWorkout = (loggedInUserId) => `${uuidv4()}_${loggedInUserId}_${Date.now()}`;

const getStoragePath = (userId, workoutId) => (
  format(storagePaths.CUSTOM_WORKOUT_ATTACHEMENT, {
    [pathPlaceholder.USER_ID]: userId,
    [pathPlaceholder.CUSTOM_WORKOUT_ID]: workoutId,
  })
);

const createCustomWorkoutData = ({
  title,
  comment,
  user,
  date,
  fileUploadResult,
}) => {
  let customWorkoutData = {
    title,
    comment,
    user,
    startDate: moment.utc(date, DateFormat.DEFAULT_DATE_TIME_FORMAT).startOf('day').toDate(),
    endDate: moment.utc(date, DateFormat.DEFAULT_DATE_TIME_FORMAT).endOf('day').toDate(),
    status: workoutAssignmentStatuses.COMPLETED,
    lastUpdatedDateTime: moment.utc().toDate(),
  };

  if (fileUploadResult.isFileChanged) {
    const fieldValue = fileUploadResult.fileRef || app.firestore.FieldValue.delete();
    customWorkoutData = {
      ...customWorkoutData,
      attachmentRef: fieldValue,
    };
  }
  return customWorkoutData;
};

const createWorkoutFeedViewData = ({
  title,
  comment,
  user,
  date,
  fileUploadResult,
}) => {
  let workoutFeedViewData = {
    title,
    comment,
    user,
    type: WorkoutFeedViewType.CUSTOM_WORKOUT,
    startDate: moment.utc(date, DateFormat.DEFAULT_DATE_TIME_FORMAT).startOf('day').toDate(),
    endDate: moment.utc(date, DateFormat.DEFAULT_DATE_TIME_FORMAT).endOf('day').toDate(),
    status: workoutAssignmentStatuses.COMPLETED,
    lastUpdatedDateTime: moment.utc().toDate(),
  };

  if (fileUploadResult.isFileChanged) {
    const fieldValue = fileUploadResult.fileRef || app.firestore.FieldValue.delete();
    workoutFeedViewData = {
      ...workoutFeedViewData,
      attachmentRef: fieldValue,
      // TODO - This would be removed once properly merged with home-feed PR.
      previewImageLink: fieldValue,
    };
  }
  return workoutFeedViewData;
};

const deleteAttachmentFromStorage = async (fileUploadResult, storage) => {
  if (fileUploadResult.fileRef) {
    try {
      const rootRef = storage.ref();
      await rootRef
        .child(fileUploadResult.fileRef)
        .delete();
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          message: `Deleting the file ${fileUploadResult.fileRef} failed with error`,
        },
      });
    }
  }
};

/**
 *
 * @param {Object} workoutDetail - List of values to be used in creating customWorkout and workoutFeedView.
 * @param {Object} firestore - Instance of the Firestore client.
 * @param {Object} storage - Instance of the Firestore Cloud Storage client.
 * @return {CustomWorkoutProcessState} Returns a Promise with the next state of the custom workout creation.
 */
const persistWorkoutDetail = async (workoutDetail, firestore, storage) => {
  try {
    const {
      fileUploadResult: {
        workoutId = createIdForCustomWorkout(workoutDetail.user),
      },
    } = workoutDetail;
    const customWorkoutRef = new CustomWorkout(workoutId).ref;
    const workoutFeedViewRef = new WorkoutFeedView(workoutId).ref;

    await firestore.runTransaction(async (transaction) => {
      transaction.set(customWorkoutRef, createCustomWorkoutData(workoutDetail), { merge: true });
      transaction.set(workoutFeedViewRef, createWorkoutFeedViewData({ ...workoutDetail, workoutId }), { merge: true });
    });

    return CustomWorkoutProcessState.FIRESTORE_PERSISTING_SUCCEEDED;
  } catch (error) {
    await deleteAttachmentFromStorage(workoutDetail.fileUploadResult, storage);
    Sentry.captureException(error, {
      extra: {
        message: 'Error occurred while persisting custom workout details',
      },
    });
    return CustomWorkoutProcessState.FIRESTORE_PERSISTING_FAILED;
  }
};

export default CustomWorkoutProcessState;
export {
  persistWorkoutDetail,
  createIdForCustomWorkout,
  getStoragePath,
};
