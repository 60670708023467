import styled from 'styled-components';

import PageContent from '../../../components/PageContent';
import { headline3, body1 } from '../../../styles/text';
import colors from '../../../styles/baseColors';

const DescriptionContainer = styled.div`
  padding: 20px;
  flex: 1;
`;

const Title = styled.div`
  ${headline3}
  text-transform: unset;
`;

const Description = styled.p`
  ${body1}
`;

const StyledPageContent = styled(PageContent)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: ${colors.system.alpha};
`;

const ExerciseContainer = styled.div`
  margin: 25px 0;
  display: flex;
  flex-direction: column;

  .nameSelector {
    z-index: 3;
    width: 100%;
  }
`;

export {
  DescriptionContainer,
  Description,
  Title,
  StyledPageContent,
  ExerciseContainer,
};
