import React from 'react';
import BodyMeasurementHistory from './BodyMeasurementHistory';

import HabitHistory from './HabitHistory';
import HealthHistory from './HealthHistory';
import {
  Container,
  StyledContainer,
  Title,
} from './styles';
import texts from './texts.json';

const History = () => (
  <>
    <Container>
      <Title>{texts.habits}</Title>
      <StyledContainer>
        <HabitHistory />
      </StyledContainer>
    </Container>
    <Container>
      <Title>{texts.myHealth}</Title>
      <StyledContainer>
        <HealthHistory />
      </StyledContainer>
    </Container>
    <Container>
      <Title>{texts.bodyMeasurement}</Title>
      <StyledContainer>
        <BodyMeasurementHistory />
      </StyledContainer>
    </Container>
  </>
);

export default History;
