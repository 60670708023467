import {
  observable,
  action,
  decorate,
} from 'mobx';

class SessionStore {
  authUser = null;

  userClaims = null;

  isCoachAssistantUser = false;

  userChangeListeners = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  setAuthUser = (authUser, claims, isCoachAssistant = false) => {
    this.authUser = authUser;
    this.userClaims = claims;
    this.isCoachAssistantUser = isCoachAssistant;
  };

  /**
   * Informs to the listeners that the current user has been explicitly changed.
   * @param {object} authUser A https://firebase.google.com/docs/reference/js/firebase.User user object.
   */
  changeUser = (authUser) => {
    this.userChangeListeners.forEach((listener) => listener(authUser));
  }

  onAuthUserChanged = (callback) => {
    this.userChangeListeners.push(callback);
  }

  removeListeners = () => {
    this.userChangeListeners = [];
  }

  get isAdmin() {
    const { admin } = this.userClaims || {};
    return !!admin;
  }

  get isCoach() {
    const { coach } = this.userClaims || {};
    return !!coach;
  }

  get isCoachOrAdmin() {
    return this.isCoach || this.isAdmin;
  }

  get hasUserClaims() {
    return !!this.userClaims;
  }

  get isCoachAssistant() {
    return this.isAdmin && this.isCoachAssistantUser;
  }
}

decorate(SessionStore, {
  authUser: observable,
  userClaims: observable,
  setAuthUser: action,
});

export default SessionStore;
