import styled from 'styled-components';

const StyledTimeCounter = styled.div`
  /* This property allows the numbers to be all the same width as if the font were monospaced. */
  font-variant-numeric: tabular-nums;
`;

export {
  StyledTimeCounter,
};
