import React, { useContext } from 'react';

import LoadingPage from '../../components/LoadingPage';
import CoachContactContextProvider from './CoachContactContextProvider';
import CoachContactContext from './CoachContactContext';

const withCoachContactContextProvider = (Component) => (props) => (
  <CoachContactContextProvider>
    <Component {...props} />
  </CoachContactContextProvider>
);

const withCoachContactContextReady = (Component) => (props) => {
  const { isReady } = useContext(CoachContactContext);

  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withCoachContactContextProvider,
  withCoachContactContextReady,
};
