import styled from 'styled-components';

const Label = styled.label`
  display: flex;
  align-items: center;
  min-width: 150px;
  justify-content: flex-end;
  padding-right: 10px;
`;

export default Label;
