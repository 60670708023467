import React from 'react';
import PropTypes from 'prop-types';

import {
  ActionsContainer,
  IconContainer,
} from '../styles';

import { StyledNextIcon } from './styles';

const ManualNextAction = ({
  sizeConfig,
  onNextClick,
  actionElementRef,
}) => (
  <ActionsContainer
    {...sizeConfig}
    ref={actionElementRef}
  >
    <IconContainer onClick={onNextClick}>
      <StyledNextIcon />
    </IconContainer>
  </ActionsContainer>
);

ManualNextAction.propTypes = {
  sizeConfig: PropTypes.shape({
    top: PropTypes.number.isRequired,
    bottom: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }).isRequired,
  onNextClick: PropTypes.func.isRequired,
  actionElementRef: PropTypes.object.isRequired,
};

export default ManualNextAction;
