import React, {
  useContext,
  useCallback,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { IonAlert } from '@ionic/react';
import format from 'string-template';

import logEvent from '../../utils/logger';
import useAppTheme from '../../hooks/useAppTheme';
import AppContext from '../../context/AppContext';
import UserExerciseOverridesContext from '../../context/UserExerciseOverridesContext';
import { withVideoPlayerContextProvider } from '../VideoPlayer';
import ActivityDetails from './ActivityDetails';
import texts from './texts.json';
import {
  ModalContent,
  ActionsContainer,
  StyledCloseButton,
} from './styles';

const ActivityDetailsModalContent = ({
  activity,
  onClose,
  onShowOverride,
}) => {
  const {
    isPortraitMode,
    isLowPowerModeEnabled,
  } = useContext(AppContext);

  const {
    updateActiveExerciseOverride,
  } = useContext(UserExerciseOverridesContext);

  const [showRevertAlert, setShowRevertAlert] = useState(false);

  const { colors } = useAppTheme();

  const originalExercise = useMemo(() => {
    const {
      originalActivity,
    } = activity;

    if (originalActivity) {
      const {
        exerciseId: originalExerciseId,
        name: originalName,
        videoPreviewThumbnail: originalVideoPreviewThumbnail,
      } = originalActivity;

      return {
        id: originalExerciseId,
        name: originalName,
        videoPreviewThumbnail: originalVideoPreviewThumbnail,
      };
    }

    return null;
  }, [activity]);

  const onRevertExercise = useCallback(() => setShowRevertAlert(true), [setShowRevertAlert]);

  const onApplyRevertExercise = useCallback(() => {
    if (originalExercise) {
      logEvent('exerciseOverrideRestoreAlertAccepted');
      setShowRevertAlert(false);
      updateActiveExerciseOverride(originalExercise, null);
      onClose();
    }
  }, [
    originalExercise,
    updateActiveExerciseOverride,
    onClose,
  ]);

  const onRevertAlertCancel = useCallback(() => {
    logEvent('exerciseOverrideRestoreAlertDismissed');
    setShowRevertAlert(false);
  }, [
    setShowRevertAlert,
  ]);

  const originalExerciseName = originalExercise && originalExercise.name;

  return (
    <>
      <ModalContent>
        <ActionsContainer isPortraitOrientation={isPortraitMode}>
          <StyledCloseButton
            onClick={onClose}
            color={colors.system.beta}
          />
        </ActionsContainer>
        <ActivityDetails
          activity={activity}
          originalExercise={originalExercise}
          isPortraitOrientation={isPortraitMode}
          isLowPowerModeEnabled={isLowPowerModeEnabled}
          onShowOverride={onShowOverride}
          onRevertExercise={onRevertExercise}
        />
      </ModalContent>
      <IonAlert
        isOpen={showRevertAlert}
        header={texts.alertTitle}
        message={format(texts.alertRevertMessage, originalExerciseName)}
        backdropDismiss={false}
        buttons={[
          {
            text: texts.alertActionCancel,
            role: 'cancel',
            handler: onRevertAlertCancel,
          },
          {
            text: texts.alertActionOK,
            handler: onApplyRevertExercise,
          },
        ]}
      />
    </>
  );
};

ActivityDetailsModalContent.propTypes = {
  activity: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onShowOverride: PropTypes.func.isRequired,
};

export default compose(
  withVideoPlayerContextProvider,
)(ActivityDetailsModalContent);
