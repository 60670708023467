import { firestorePaths } from '../utils/firebasePaths';
import BaseDocument from './BaseDocument';

import {
  calculateExerciseId,
} from './Exercise';

const UserExerciseOverrideType = {
  TIME_RANGE: 'TIME_RANGE',
  PERMANENT: 'PERMANENT',
};

class UserExerciseOverrides extends BaseDocument {
  static async getById(id) {
    const userExerciseOverridesDocument = new UserExerciseOverrides(`${firestorePaths.USER_EXERCISE_OVERRIDES}/${id}`);
    await userExerciseOverridesDocument.init();

    return userExerciseOverridesDocument;
  }

  /**
   * Get the override information for the given exercise, if any.
   *
   * @param {Object} exerciseData
   * @param {string=} exerciseData.id The ID of the exercise
   * @param {string} exerciseData.name The name of the exercise
   * @returns {Object|null}
   */
  overrideForExercise(exerciseData) {
    const exerciseId = calculateExerciseId(exerciseData);
    const override = this.data[exerciseId];
    return override || null;
  }

  /**
   * Get the active override for the given exercise, if any.
   *
   * @param {Object} exerciseData
   * @param {string=} exerciseData.id The ID of the exercise
   * @param {string} exerciseData.name The name of the exercise
   * @returns {Object|null}
   */
  activeOverrideForExercise(exerciseData) {
    const override = this.overrideForExercise(exerciseData);
    return override ? override.active : null;
  }
}

export default UserExerciseOverrides;
export {
  UserExerciseOverrideType,
};
