import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { Watch } from 'capacitor-watch-app-plugin';
import * as Sentry from '@sentry/browser';

import { isIOS } from '../../utils/platform';
import logEvent from '../../utils/logger';
import useComponentMounted from '../../hooks/useComponentMounted';
import useSessionStore from '../../hooks/useSessionStore';
import AppContext from '../AppContext';
import WatchContext from './WatchContext';

const AppContextCoreModule = {
  APP: 'app',
};

const WatchContextProvider = ({
  children,
}) => {
  const {
    isActive,
  } = useContext(AppContext);
  const isComponentMountedRef = useComponentMounted();

  const [isWatchSupported, setIsWatchSupported] = useState(false);
  const [isWatchAvailable, setIsWatchAvailable] = useState(false);
  const [appContext, setAppContext] = useState(() => ({
    app: {
      isActive,
    },
  }));

  const { authUser } = useSessionStore();

  const updateAppContext = useCallback((id, value) => {
    if (isWatchAvailable) {
      setAppContext((currentContext) => {
        const newAppContext = {
          ...currentContext,
          [id]: value,
        };
        return newAppContext;
      });
    }
  }, [
    isWatchAvailable,
  ]);

  /**
   * Check if Watch connectivity is supported for the current device, and update the context provider
   * state value.
   * Watch connectivity will only be supported in iOS devices that supports WatchConnectivity framework.
   */
  useEffect(() => {
    if (isIOS()) {
      const checkWatchConnectivitySupport = async () => {
        const { isSupported } = await Watch.isWatchConnectivitySupported();

        if (isComponentMountedRef.current) {
          const eventName = isSupported ? 'watchSupported' : 'watchNotSupported';
          logEvent(eventName);

          setIsWatchSupported(isSupported);
        }
      };
      checkWatchConnectivitySupport();
    }
  }, [
    isComponentMountedRef,
  ]);

  useEffect(() => {
    let sessionWatchStateChangedListener;
    if (isWatchSupported) {
      sessionWatchStateChangedListener = Watch.addListener('sessionWatchStateChanged', (state) => {
        if (isComponentMountedRef.current) {
          const { isWatchAppInstalled } = state;
          logEvent('watchSessionStateChanged', state);
          setIsWatchAvailable(isWatchAppInstalled);
        }
      });
    }
    return () => {
      if (sessionWatchStateChangedListener) {
        sessionWatchStateChangedListener.remove();
      }
    };
  }, [
    isWatchSupported,
    isComponentMountedRef,
  ]);

  /**
   * When the application active state changes, i.e the app transitions to the background and foreground,
   * update the  appContext state with a core context value: app activation state.
   * Also check if the watch is still available: iOS device has a paired watch and the watch app is installed.
   */
  useEffect(() => {
    if (isWatchSupported) {
      const checkAvailability = async () => {
        const {
          isPaired,
          isWatchAppInstalled,
        } = await Watch.getInfo();

        logEvent('watchInfo', {
          isPaired,
          isWatchAppInstalled,
        });

        if (isComponentMountedRef.current) {
          setIsWatchAvailable(isPaired && isWatchAppInstalled);
        }
      };
      checkAvailability();

      updateAppContext(AppContextCoreModule.APP, {
        isActive,
      });
    }
  }, [
    updateAppContext,
    isActive,
    isWatchSupported,
    isComponentMountedRef,
  ]);

  useEffect(() => {
    if (isWatchSupported) {
      const transferAuthUser = async () => {
        try {
          await Watch.transferUserInfo({
            userInfo: {
              auth: {
                userId: (authUser && authUser.uid) ? authUser.uid : '',
              },
            },
          });
        } catch (error) {
          Sentry.captureException(error, {
            extra: {
              description: 'Error transferring auth info to watch app',
            },
          });
        }
      };

      transferAuthUser();
    }
  }, [
    authUser,
    isWatchSupported,
  ]);

  /**
   * Update watch app application context when the appContext state changes.
   */
  useEffect(() => {
    if (isWatchAvailable) {
      const update = async () => {
        try {
          await Watch.updateApplicationContext({
            context: appContext,
          });
        } catch (error) {
          logEvent('errorUpdatingApplicationContext');
          Sentry.captureException(error, {
            extra: {
              description: 'Error when calling Watch.updateApplicationContext',
            },
          });
        }
      };
      update();
    }
  }, [
    appContext,
    isWatchAvailable,
  ]);

  const context = useMemo(() => ({
    isWatchAvailable,
    isWatchSupported,
    updateAppContext,
  }), [
    isWatchAvailable,
    isWatchSupported,
    updateAppContext,
  ]);

  return (
    <WatchContext.Provider value={context}>
      {children}
    </WatchContext.Provider>
  );
};

WatchContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(WatchContextProvider);
