import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import logEvent from '../../utils/logger';
import {
  lock,
  unlock,
  OrientationTypes,
} from '../../utils/orientation';
import ExerciseOverrideSelector from '../ExerciseOverrideSelector';
import ActivityDetailsModalContent from './ActivityDetailsModalContent';

import {
  StyledModal,
} from './styles';

const ActivityDetailsModalContainer = ({
  activity,
  isOpen,
  onClose,
}) => {
  const [shouldShowOverride, setShouldShowOverride] = useState(false);

  const onShowOverride = () => {
    const {
      name,
      exerciseId,
      type,
      duration,
      repetitions,
    } = activity;

    logEvent('showExerciseOverrideSelected', {
      activityName: name,
      activityType: type,
      duration,
      repetitions,
      exerciseId,
    });
    setShouldShowOverride(true);
  };

  const onCloseModal = () => {
    onClose();
    unlock();
  };

  const onCancelOverride = () => {
    setShouldShowOverride(false);
  };

  const onDidDismiss = () => {
    setShouldShowOverride(false);
    onCloseModal();
  };

  useEffect(() => {
    // NOTE: This modal requires running only in portrait mode by UX definition.
    if (isOpen) {
      lock(OrientationTypes.PORTRAIT);
    }
  }, [
    isOpen,
  ]);

  return (
    <StyledModal
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
    >
      {shouldShowOverride
        ? (
          <ExerciseOverrideSelector
            activity={activity}
            onCancel={onCancelOverride}
            onOverrideDone={onCloseModal}
          />
        )
        : (
          <ActivityDetailsModalContent
            activity={activity}
            onClose={onCloseModal}
            onShowOverride={onShowOverride}
          />
        )}
    </StyledModal>
  );
};

ActivityDetailsModalContainer.propTypes = {
  activity: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ActivityDetailsModalContainer;
