import { Collection } from 'firestorter';

import { firestorePaths } from '../utils/firebasePaths';
import BaseDocument from './BaseDocument';

const messageSuggestionTypes = {
  GREETING: 'GREETING',
};

class MessageSuggestion extends BaseDocument {
  get message() {
    return this.data.message;
  }

  get type() {
    return this.data.type;
  }

  /**
   * Returns messages from the suggestion message collection for a given type
   * @param {string} type type of message
   */
  static async get(type = messageSuggestionTypes.GREETING) {
    const messageSuggestionCollection = new Collection(() => firestorePaths.MESSAGE_SUGGESTION, {
      createDocument: (src, opts) => new MessageSuggestion(src, opts),
      query: (ref) => ref
        .where('type', '==', type)
        .limit(50),
    });
    await messageSuggestionCollection.fetch();
    return messageSuggestionCollection;
  }
}

export default MessageSuggestion;
export {
  messageSuggestionTypes,
};
