import React, {
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import merge from 'lodash.merge';

import {
  Chart,
  BarController,
  CategoryScale,
  LinearScale,
  BarElement,
  TimeScale,
} from 'chart.js';

import useAppTheme from '../../hooks/useAppTheme';

import CHART_CONFIG from './config';

Chart.register(
  BarController,
  CategoryScale,
  LinearScale,
  BarElement,
  TimeScale,
);

const VISIBLE_TICKS_FRACTION = 4;

const HeartRateGraph = ({
  dataPoints,
}) => {
  const graphRef = useRef(null);

  const { colors } = useAppTheme();

  useEffect(() => {
    const chartConfig = dataPoints
      .sort((pointA, pointB) => pointA.date - pointB.date)
      .reduce(({
        labels,
        data,
        heartRateLimits,
      }, {
        date,
        minHeartRate,
        maxHeartRate,
      }, index, array) => {
        // We only want to show in the x-axis the first, last and quarter times of the whole activity.
        const label = (((index % Math.ceil(array.length / VISIBLE_TICKS_FRACTION)) === 0)
          || index === array.length - 1)
          ? moment(date).format('HH:mm')
          : '';
        const dataPoint = [minHeartRate, maxHeartRate];

        return {
          labels: [...labels, label],
          data: [...data, dataPoint],
          heartRateLimits: {
            min: Math.min(heartRateLimits.min, minHeartRate),
            max: Math.max(heartRateLimits.max, maxHeartRate),
          },
        };
      }, {
        labels: [],
        data: [],
        heartRateLimits: {
          min: Number.MAX_SAFE_INTEGER,
          max: Number.MIN_SAFE_INTEGER,
        },
      });

    const graphOpts = merge(
      { ...CHART_CONFIG }, {
        data: {
          labels: chartConfig.labels,
          datasets: [{
            borderRadius: 20,
            borderSkipped: false,
            data: chartConfig.data,
            backgroundColor: colors.system.highlight,
          }],
        },
        options: {
          scales: {
            y: {
              min: chartConfig.heartRateLimits.min,
              max: chartConfig.heartRateLimits.max,
              ticks: {
                // This callback modifies y-axis ticks making sure we only show the min and max values.
                callback: (value) => ((value === chartConfig.heartRateLimits.min
                  || value === chartConfig.heartRateLimits.max) ? value : null),
              },
            },
          },
        },
      },
    );

    const chart = new Chart(graphRef.current, graphOpts);

    return () => {
      chart.destroy();
    };
  }, [
    dataPoints,
    colors.system.highlight,
  ]);

  return (
    <canvas ref={graphRef} />
  );
};

HeartRateGraph.propTypes = {
  dataPoints: PropTypes.arrayOf(PropTypes.shape({
    maxHeartRate: PropTypes.number,
    minHeartRate: PropTypes.number,
    date: PropTypes.instanceOf(Date),
  })).isRequired,
};

export default HeartRateGraph;
