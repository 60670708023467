import styled from 'styled-components';

import { ReactComponent as ArrowDown } from '../../../assets/angle-arrow.svg';
import ShortExerciseItem from '../../ShortExerciseItem';

const ExerciseListItem = styled.div``;

const ExercisesList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;

  ${ExerciseListItem} {
    padding: 8px 16px;
    margin-bottom: 4px;
    background: ${({ theme }) => theme.colors.system.alpha};
  }
`;

const ExerciseItemContainer = styled.div``;

const StyledShortExerciseItem = styled(ShortExerciseItem)`
  cursor: pointer;
`;

const IconContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: end;
  padding-left: 8px;
`;

const IconArrow = styled(ArrowDown)`
  transition: all 0.4s;
  transform: rotate(${({ $isExpanded }) => ($isExpanded ? '180deg' : '0deg')});

  > path {
    fill: ${({ theme }) => theme.colors.base.primary};
  }
`;

export {
  ExercisesList,
  ExerciseListItem,
  ExerciseItemContainer,
  StyledShortExerciseItem,
  IconContainer,
  IconArrow,
};
