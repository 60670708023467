import Collections from '../utils/collections';
import BaseDocument from './BaseDocument';

class ExternalCoach extends BaseDocument {
  constructor(id, opts) {
    super(`${Collections.EXTERNAL_COACH}/${id}`, opts);
  }

  get stripeAccountId() {
    return this.data.stripeAccountId;
  }
}

export default ExternalCoach;
