import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import MessageBannerContext from '../../../context/MessageBannerContext';
import AppContext from '../../../context/AppContext';
import Burger from '../../Icons/Burger';
import MessageBanner from '../../MessageBanner';
import HeaderLogo from '../HeaderLogo';
import { Actions } from '../Header/styles';
import ActionItem from '../Header/ActionItem';
import { HeaderContext } from '../context';
import useHeaderParamConfig from '../hooks/useHeaderParamConfig';
import {
  StyledHeader,
  Container,
  HeaderContent,
  MenuWrapper,
} from './styles';

const Header = () => {
  const { actions } = useContext(HeaderContext);

  const {
    isBannerVisible,
  } = useContext(MessageBannerContext);

  const {
    sideMenuRef,
  } = useContext(AppContext);

  const openMenu = async () => {
    if (sideMenuRef.current) {
      sideMenuRef.current.open();
    }
  };

  const { enableHeaderActions } = useHeaderParamConfig();

  return (
    <StyledHeader>
      <Container>
        {enableHeaderActions && (
          <MenuWrapper onClick={openMenu}>
            <Burger />
          </MenuWrapper>
        )}
        <HeaderContent>
          <HeaderLogo />
        </HeaderContent>
        {enableHeaderActions && !!actions && (
          <Actions>
            {actions.map(({ id, ...props }) => <ActionItem {...props} key={id} />)}
          </Actions>
        )}
      </Container>
      {!!isBannerVisible && (
        <MessageBanner />
      )}
    </StyledHeader>
  );
};

Header.propTypes = {
  actions: PropTypes.array,
};

Header.defaultProps = {
  actions: null,
};

export default Header;
