const UnitType = {
  METRIC: 'METRIC',
  IMPERIAL: 'IMPERIAL',
};

const WeightUnit = {
  POUNDS: 'POUNDS',
  KILOGRAMS: 'KILOGRAMS',
};

const LengthUnit = {
  INCHES: 'INCHES',
  CENTIMETERS: 'CENTIMETERS',
};

const VolumeUnit = {
  LITERS: 'LITERS',
  PINTS: 'PINTS',
};

const UnitTypeLabel = {
  [UnitType.METRIC]: 'kg/cm',
  [UnitType.IMPERIAL]: 'lbs/in',
  [WeightUnit.POUNDS]: 'lbs',
  [WeightUnit.KILOGRAMS]: 'kg',
  [LengthUnit.INCHES]: 'in',
  [LengthUnit.CENTIMETERS]: 'cm',
};

const Unit = {
  [UnitType.METRIC]: {
    WeightUnit: WeightUnit.KILOGRAMS,
    LengthUnit: LengthUnit.CENTIMETERS,
    VolumeUnit: VolumeUnit.LITERS,
  },
  [UnitType.IMPERIAL]: {
    WeightUnit: WeightUnit.POUNDS,
    LengthUnit: LengthUnit.INCHES,
    VolumeUnit: VolumeUnit.PINTS,
  },
};

export {
  WeightUnit,
  LengthUnit,
  VolumeUnit,
  UnitType,
  UnitTypeLabel,
  Unit,
};
