import { useCallback, useState } from 'react';
import {
  useRouteMatch,
  useHistory,
  useLocation,
} from 'react-router-dom';

/**
 * This React Hook allows navigation to the specified user path with minimum dependencies
 * and setup for the caller.
 */
const useUserNavigation = () => {
  const history = useHistory();
  const { params: { userId } } = useRouteMatch();
  const { search } = useLocation();
  const [pathname] = useState(`/u/${userId}`);

  const navigateToUserPath = useCallback((path, other = {}) => {
    const opts = {
      ...other,
      pathname: `${pathname}/${path}`,
      search,
    };

    const { shouldReplaceURL = false } = other;

    if (shouldReplaceURL) {
      history.replace(opts);
    } else {
      history.push(opts);
    }
  }, [
    history,
    pathname,
    search,
  ]);

  return {
    navigateToUserPath,
    pathname,
  };
};

export default useUserNavigation;
