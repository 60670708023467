import styled from 'styled-components';
import { getSafeAreaFromHeader } from '../../../styles/mixins';
import { overline } from '../../../styles/text';

const StyledHeader = styled.header`
  display: flex;
  flex-direction: row;
  background: ${({ theme }) => theme.colors.base.primary};
  width: 100%;
  height: ${getSafeAreaFromHeader()};
  padding-left: 25px;
  padding-right: 25px;
  padding-top: env(safe-area-inset-top);

  top: 0;
  position: fixed;
  z-index: 1;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  > div:first-child {
    width: 50%;
  }
  > div:last-child {
    width: 50%;
  }
`;

const BackActionContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  flex-grow: 1;
`;

const Title = styled.div`
  ${overline}
  opacity: 1;
  flex-grow: 2;
  flex-shrink: 0;
  font-size: 21px;
  line-height: 29px;
  letter-spacing: 0;
  height: 30px;
  font-family: 'Recoleta';
  color: ${({ theme }) => theme.colors.system.alpha};
  text-align: center;
  text-transform: none;
`;

const ActionItemContainer = styled.div`
  display: flex;
  cursor: pointer;
  height: 100%;
  max-height: 26px;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const Actions = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;

  ${ActionItemContainer} {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const NotificationNumber = styled.span`
  position: absolute;
  font-size: 10px;
  line-height: 12px;
  background-color: ${({ theme }) => theme.colors.system.error};
  color: ${({ theme }) => theme.colors.system.alpha};
  font-weight: 700;
  bottom: 0;
  right: 0;
  height: 12px;
  min-width: 12px;
  border-radius: 99px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(50%);
  padding: 0 2px;
`;

export {
  StyledHeader,
  Container,
  BackActionContainer,
  Title,
  Actions,
  ActionItemContainer,
  NotificationNumber,
};
