import styled, { createGlobalStyle, css } from 'styled-components';
import { mixins } from '../../../../styles/breakpoints';

const GlobalStyles = createGlobalStyle`
  html,
  body {
    background-color: #e5e5e5;
  }
`;

const VideoContainer = styled.div`
  display: flex;
  position: relative;
  flex: 1;
`;

const ControlPanel = styled.div`
  width: 100%;
  color: black;
  font-size: 16px;
  padding: 16px;
  z-index: 1000;
`;

const Container = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  height: 100%;
  padding: 20px;
  justify-content: center;

  ${mixins.desktopMedia(css`
    flex-flow: row wrap;

    ${VideoContainer} {
      flex: 70%;
    }

    ${ControlPanel} {
      flex: 30%;
    }
  `)}
`;

const ActionsContainer = styled.div`
  > * {
    margin: 5px;
  }
`;

export {
  Container,
  VideoContainer,
  ControlPanel,
  ActionsContainer,
  GlobalStyles,
};
