import React from 'react';
import PropTypes from 'prop-types';

import {
  ThumbnailWrapper,
  StyledThumbnail,
} from './styles';

const Thumbnail = ({
  url,
  className,
}) => (
  <ThumbnailWrapper className={className}>
    <StyledThumbnail src={url} />
  </ThumbnailWrapper>
);

Thumbnail.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Thumbnail.defaultProps = {
  className: '',
};

export default Thumbnail;
