import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import compose from 'recompose/compose';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import MealPlanContext from '../../context/MealPlanContext';
import { withLayout } from '../../components/Layout';
import { getQueryVariable } from '../../utils/queryParams';
import { withUserNutritionInfoContextReady } from '../../context/UserNutritionInfoContext';
import ViewSelector from './ViewSelector';
import MealPlanOverview from './MealPlanOverview';
import MealPlanBucket from './MealPlanBucket';
import {
  Container,
  Content,
} from './styles';
import texts from './texts.json';

const ViewType = {
  INTRODUCTION: 'intro',
  MEAL_BUCKET: 'bucket',
};

const MealPlan = () => {
  const {
    mealPlanAssignmentDoc: {
      mealBuckets,
    },
  } = useContext(MealPlanContext);

  const viewOptions = useMemo(() => {
    const bucketOptions = mealBuckets.map((bucket) => ({
      name: bucket,
      value: bucket,
      type: ViewType.MEAL_BUCKET,
    }));
    return [
      {
        name: texts.overviewOption,
        value: 'overview',
        type: ViewType.INTRODUCTION,
      },
      ...bucketOptions,
    ];
  }, [
    mealBuckets,
  ]);

  const [selectedViewOption, setSelectedViewOption] = useState(() => {
    const currentViewType = getQueryVariable('view');
    const currentValue = getQueryVariable('value');

    let index = viewOptions.findIndex(({ type, value }) => currentViewType === type && currentValue === value);
    index = index >= 0 ? index : 0;

    return viewOptions[index];
  });

  const history = useHistory();

  useEffect(() => {
    const {
      type,
      value,
    } = selectedViewOption;

    history.replace({
      search: `?view=${type}&value=${value}`,
    });
  }, [
    selectedViewOption,
    history,
  ]);

  const renderContent = useCallback(() => {
    const {
      type,
      value,
    } = selectedViewOption;

    // If the route bucket is not found, we render the MealPlanOverview.
    if (!mealBuckets.includes(value)) {
      return <MealPlanOverview />;
    }

    switch (type) {
      case ViewType.INTRODUCTION:
        return <MealPlanOverview />;
      case ViewType.MEAL_BUCKET:
        return <MealPlanBucket bucket={value} />;
      default:
        return <MealPlanOverview />;
    }
  }, [
    selectedViewOption,
    mealBuckets,
  ]);

  return (
    <Container>
      <ViewSelector
        selected={selectedViewOption}
        setSelected={setSelectedViewOption}
        options={viewOptions}
      />
      <Content>
        {renderContent()}
      </Content>
    </Container>
  );
};

export default compose(
  withLayout,
  withUserNutritionInfoContextReady,
  observer,
)(MealPlan);
