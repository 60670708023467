import styled from 'styled-components';

import { addAlpha } from '../../../utils/colorUtils';

import { ReactComponent as SendIcon } from '../../assets/icon/send-icon.svg';
import { ReactComponent as AttachmentIcon } from '../../assets/icon/attachment.svg';
import { ReactComponent as MicIcon } from '../../assets/icon/mic-icon.svg';
import { ReactComponent as StopIcon } from '../../assets/icon/stop-icon.svg';

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;
  height: fit-content;
  z-index: 100;
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.system.alpha};
  border: 2px solid ${({ theme }) => addAlpha(theme.colors.system.beta, 0.16)};
  transition: border 0.2s ease-in-out;
  padding-right: 12px;

  :focus-within {
    border: 2px solid ${({ theme }) => theme.colors.system.highlight};
  }
`;

const SendButton = styled.div`
  display: flex;
  opacity: 0.5;
  cursor: pointer;
  padding: 0 4px;
  transition: opacity 0.2s ease-in-out;

  svg path {
    transition: fill 0.2s ease-in-out;
    fill: ${({ theme }) => theme.colors.system.beta};
  }

  :hover {
    opacity: 1;
  }

  :hover svg path {
    fill: ${({ theme }) => theme.colors.system.highlight};
  }
`;

const InputSection = styled.div`
  background: ${({ theme }) => theme.colors.system.alpha};
  box-shadow: 0 0 4px ${({ theme }) => addAlpha(theme.colors.system.beta, 0.08)};
  display: flex;
  align-items: center;
  padding: 8px;
  position: relative;
  justify-self: flex-end;

  /* react-file-utils styles */
  .rfu-dropzone {
    width: 100%;
    :focus {
      outline: none;
    }
  }

  .rfu-image-previewer {
    flex: none;
    margin-left: 12px;
  }

  .rfu-image-previewer__image {
    margin-bottom: 0;
  }

  /* Autocomplete suggestions */
  .rta__autocomplete {
    z-index: 10;
    position: absolute;
    background: ${({ theme }) => theme.colors.system.alpha};
    margin: 4px 10px;
    border-radius: 16px;
    width: unset;
  }

  .rta__entity--selected {
    background: ${({ theme }) => theme.colors.system.highlight};
  }
`;

const MessagingInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.system.alpha};

  .str-chat__textarea {
    display: flex;
    align-items: center;

    textarea {
      background: ${({ theme }) => theme.colors.system.alpha};
      font-size: 14px;
      line-height: 16px;
      min-height: 0;
      transition: box-shadow 0.2s ease-in-out;
      color: ${({ theme }) => addAlpha(theme.colors.system.beta, 0.9)};
      border: none;
      outline: none;
      border-radius: 20px;

      :focus {
        border: none;
        outline: none;
        box-shadow: none;
      }
    }
  }
`;

const StyledSendIcon = styled(SendIcon)`
  height: 30px;
  width: 30px;
`;

const StyledAttachmentIcon = styled(AttachmentIcon)`
  display: flex;
  height: 22px;
  width: 20px;
`;

const StyledMicIcon = styled(MicIcon)`
  display: flex;
  height: 20px;
  width: 20px;
  color: ${({ theme }) => theme.colors.system.gamma3};
  margin-left: 2px;
`;

const StyledStopIcon = styled(StopIcon)`
  display: flex;
  height: 20px;
  width: 20px;
  color: ${({ theme }) => theme.colors.system.error};
  margin-left: 2px;
`;

const TriggerSuggestion = styled.button`
  font-size: 1em;
  margin: 0.5em;
  align-content: center;
  padding: 0.5em 1em;
  color: ${({ theme }) => theme.colors.system.highlight};
  background-color: ${({ theme }) => theme.colors.system.alpha};
  border: 2px solid ${({ theme }) => theme.colors.system.highlight};
  border-radius: 10px;
`;

const RecordingView = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;
  padding-right: 12px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.system.gamma2};
`;

const RecordingLabel = styled.div`
  display: flex;
  margin-left: 10px;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

const StyledButton = styled.div`
  display: flex;
`;

export {
  MessagingInputContainer,
  StyledSendIcon,
  StyledAttachmentIcon,
  StyledMicIcon,
  StyledStopIcon,
  TriggerSuggestion,
  RecordingView,
  RecordingLabel,
  InputWrapper,
  InputSection,
  SendButton,
  StyledButton,
};
