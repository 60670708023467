import React, {
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import Fraction from 'fraction.js';

import {
  CardTitle,
} from '../commonStyles';
import {
  getIngredientLine,
  unitMetadata,
  getUnitByName,
  convertUnitIfNeeded,
  scaleIngredient,
} from '../../../utils/meals';
import {
  StyledCard,
  List,
  Item,
} from './styles';
import texts from './texts.json';

const IngredientsCard = ({
  ingredients,
  isScalable,
  recipeServings,
  recommendedServings,
}) => {
  // We multiply an ingredient amount by this factor to scale it
  const scalingFactor = useMemo(() => (
    recommendedServings / recipeServings
  ), [
    recommendedServings,
    recipeServings,
  ]);

  const getScaledIngredient = useCallback((amount, unitName) => {
    if (isScalable) {
      try {
        // We will aproximate to this target as much as we can using the unit scales
        let amountTarget = new Fraction(amount.trim()).mul(scalingFactor).valueOf();
        let unit = getUnitByName(unitName);

        // Convert unit before scaling (teaspoons to tablespoons, for example)
        [amountTarget, unit] = convertUnitIfNeeded(amountTarget, unit);

        // Scale with whole units by default
        const { scales = [1] } = unitMetadata[unit] || {};

        const scaledAmount = scaleIngredient(amountTarget, scales);

        const amountFraction = new Fraction(scaledAmount).toFraction(true);
        /*
          The function above returns a string representation of the fraction with its
          whole part excluded (for example 5/2 = '2 1/2'). To avoid confusion we replace
          the white space between the whole part and the remaining part with a '&'.
        */
        return [amountFraction.replace(' ', ' & '), unit];
      } catch {
        /*
          Creating a fraction with amount can fail (amount can be a string).
          In that case use the original amount and unit.
        */
        return [amount, unitName];
      }
    }
    return [amount, unitName];
  }, [
    scalingFactor,
    isScalable,
  ]);

  return (
    <StyledCard>
      <CardTitle>{texts.title}</CardTitle>
      <List>
        {ingredients.map(({
          ingredientLine,
          amount,
          name,
          preparationComment,
          secondAmount,
          secondUnit,
          unit,
        }) => {
          let ingredient = ingredientLine;
          // name and amount are required, if these are not present use ingredientLine
          if (name && amount) {
            const [finalAmount, finalUnit] = getScaledIngredient(amount, unit);
            // secondAmount and secondUnit are optional
            const [finalSecondAmount, finalSecondUnit] = secondAmount
              ? getScaledIngredient(secondAmount, secondUnit)
              : [secondAmount, secondUnit];
            ingredient = getIngredientLine({
              amount: finalAmount,
              unit: finalUnit,
              secondAmount: finalSecondAmount,
              secondUnit: finalSecondUnit,
              name,
              preparationComment,
            });
          }
          return (
            <Item key={ingredient}>
              {ingredient}
            </Item>
          );
        })}
      </List>
    </StyledCard>
  );
};

IngredientsCard.propTypes = {
  ingredients: PropTypes.array.isRequired,
  isScalable: PropTypes.bool.isRequired,
  recipeServings: PropTypes.number.isRequired,
  recommendedServings: PropTypes.number.isRequired,
};

export default IngredientsCard;
