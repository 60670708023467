import React from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import CheckIn from '../../../models/CheckIn';
import {
  Container as DataContainer,
  Title,
  PageContainer,
} from '../styles';

import texts from './texts.json';
import {
  StyledCommentInput,
  HabitStatContainer,
  WorkoutStatContainer,
  NutritionalInformationContainer,
} from './styles';
import HabitStat from './components/HabitStat';
import WorkoutStat from './components/WorkoutStat';
import CalorieIntakeChart from './components/CalorieIntakeChart/CalorieIntakeChart';

const CheckInSummary = ({
  currentCheckInDoc,
  updateDocumentFields,
  userNutritionLogsCollection,
  shouldAllowActions,
}) => (
  <PageContainer>
    <DataContainer>
      <Title>{texts.weeklyWorkout}</Title>
      <WorkoutStatContainer>
        <WorkoutStat updateDocumentFields={updateDocumentFields} />
      </WorkoutStatContainer>
    </DataContainer>
    <DataContainer>
      <Title>{texts.calorieIntake}</Title>
      <NutritionalInformationContainer>
        <CalorieIntakeChart
          userNutritionDocs={userNutritionLogsCollection?.docs}
          updateDocumentFields={updateDocumentFields}
        />
      </NutritionalInformationContainer>
    </DataContainer>
    <DataContainer>
      <Title>{texts.habitsSummary}</Title>
      <HabitStatContainer>
        <HabitStat updateDocumentFields={updateDocumentFields} />
      </HabitStatContainer>
    </DataContainer>
    <DataContainer>
      <Title>{texts.comment}</Title>
      <StyledCommentInput
        placeholder={texts.commentPlaceholder}
        rows={5}
        defaultValue={currentCheckInDoc.comment}
        onBlur={(evt) => updateDocumentFields({
          comment: evt.target.value,
        })}
        disabled={!shouldAllowActions}
      />
    </DataContainer>
  </PageContainer>
);

CheckInSummary.propTypes = {
  currentCheckInDoc: PropTypes.instanceOf(CheckIn).isRequired,
  updateDocumentFields: PropTypes.func.isRequired,
  shouldAllowActions: PropTypes.bool.isRequired,
  userNutritionLogsCollection: PropTypes.object,
};

CheckInSummary.defaultProps = {
  userNutritionLogsCollection: {},
};

export default compose(
  observer,
)(CheckInSummary);
