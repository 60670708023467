import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  StatContainer,
  StatLine,
  StatIcon,
  StyledStat,
  Description,
} from './styles';

const Stat = ({
  iconUrl,
  stat,
  description,
  theme,
  className,
}) => (
  <StatContainer
    className={className}
    $theme={theme}
  >
    <StatLine>
      {!!iconUrl && (
        <StatIcon src={iconUrl} />
      )}
      <StyledStat>{stat}</StyledStat>
    </StatLine>
    <Description>{description}</Description>
  </StatContainer>
);

Stat.propTypes = {
  iconUrl: PropTypes.string,
  stat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  description: PropTypes.string.isRequired,
  className: PropTypes.string,
  theme: PropTypes.object,
};

Stat.defaultProps = {
  className: '',
  iconUrl: '',
  theme: {},
};

export default styled(Stat)``;
