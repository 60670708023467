import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'recompose';
import { observer } from 'mobx-react';
import UserContext from '../../../../context/UserContext';
import CheckInContext from '../../../../context/CheckInContext';
import NotificationModalContext from '../../../../context/NotificationModalContext';
import useCheckInNavigation from '../../../../hooks/useCheckInNavigation';
import useCurrentLoggedInUser from '../../../../hooks/useCurrentLoggedInUser';
import MessageCard, { MessageCardType } from '../../components/MessageCard';
import AssignmentDayType from '../../viewModel/assignmentType';
import StreakCard from '../../components/StreakCard';
import CoachCard from '../../components/CoachCard';
import CompletedWorkoutsCard from '../../components/CompletedWorkoutsCard';
import DayGroup from '../../components/DayGroup';
import LogActivity from './LogActivity';
import WorkoutSummaryStats from './WorkoutSummaryStats';
import {
  Content,
  StyledAttentionIcon,
} from './styles';
import texts from './texts.json';

const CalendarTodayCard = ({
  workoutDay,
}) => {
  const {
    date,
    items,
    completedItems,
    progressStatus,
    trackedActivitySummary,
    streak,
  } = workoutDay;

  const {
    userDoc,
  } = useContext(UserContext);
  const {
    currentCheckInDoc: {
      isSubmitted,
      progress,
    },
  } = useContext(CheckInContext);

  const {
    showNotificationModal,
    hideNotificationModal,
  } = useContext(NotificationModalContext);

  const { isCurrentLoggedInUserInPath } = useCurrentLoggedInUser();
  const { navigateCheckInPageByPageIndex } = useCheckInNavigation();

  const onCheckinCardClick = useCallback((progressValue) => {
    // Only the client should navigate to the checkin page. Coaches can use the dashboard to see the client's progress.
    if (isCurrentLoggedInUserInPath) {
      navigateCheckInPageByPageIndex(progressValue);
    }
  }, [
    isCurrentLoggedInUserInPath,
    navigateCheckInPageByPageIndex,
  ]);

  const handleCheckInClick = useCallback((progressValue) => {
    // Check if user is canceled or on a break, if so show warning modal
    if (userDoc.isOnBreak || userDoc.isCanceled) {
      // The "canceled" description takes precedence over "on a break" description
      const description = userDoc.isCanceled
        ? texts.checkinWarningModal.canceledDescription
        : texts.checkinWarningModal.onBreakDescription;

      showNotificationModal({
        title: texts.checkinWarningModal.title,
        description,
        Icon: StyledAttentionIcon,
        actions: [
          {
            label: texts.checkinWarningModal.ok,
            action: () => {
              onCheckinCardClick(progressValue);
              hideNotificationModal();
            },
          },
          {
            label: texts.checkinWarningModal.cancel,
            action: () => hideNotificationModal(),
          },
        ],
      });
    } else {
      onCheckinCardClick(progressValue);
    }
  }, [
    userDoc,
    showNotificationModal,
    onCheckinCardClick,
    hideNotificationModal,
  ]);

  const hasCompletedWorkouts = completedItems.length > 0;

  return (
    <DayGroup
      date={date}
      assignmentDayType={AssignmentDayType.TODAY}
      progressStatus={progressStatus}
      trackedActivitySummary={trackedActivitySummary}
      streak={streak}
    >
      <Content>
        {!!streak && streak.isFinalStreakDay && (
          <StreakCard count={streak.totalCount} />
        )}

        {/* Render done workouts card, but only show quote if is not day zero */}
        {hasCompletedWorkouts && (<CompletedWorkoutsCard items={completedItems} showQuote={!userDoc.isDayZero} />)}

        {/* Render workouts not done */}
        {items.map(({
          props: {
            id,
            ...cardProps
          },
          component: CardComponent,
        }) => (
          <CardComponent
            key={id}
            {...cardProps}
          />
        ))}
        {isSubmitted
          ? <CoachCard />
          : (
            <MessageCard
              message={texts.checkInStage[progress].message}
              description={texts.checkInStage[progress].description}
              type={MessageCardType.DEFAULT}
              onClick={() => handleCheckInClick(progress)}
            />
          )}
        <LogActivity />
        <WorkoutSummaryStats />
      </Content>
    </DayGroup>
  );
};

CalendarTodayCard.propTypes = {
  workoutDay: PropTypes.shape({
    date: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
    completedItems: PropTypes.array.isRequired,
    assignmentDayType: PropTypes.oneOf(Object.values(AssignmentDayType)).isRequired,
    progressStatus: PropTypes.string.isRequired,
    trackedActivitySummary: PropTypes.object,
    streak: PropTypes.object,
  }).isRequired,
};

export default compose(
  observer,
)(CalendarTodayCard);
