import format from 'string-template';

import { firestorePaths, pathPlaceholder } from '../utils/firebasePaths';

import BaseDocument from './BaseDocument';

class ActivitySession extends BaseDocument {
  /**
   * Returns the activity session with the given ids
   * @param gameplaySessionID session id of the gameplay
   * @param sessionID session id of the activity
   * @returns {ActivitySession}
   */
  static get = (gameplaySessionID, sessionID) => {
    const activitySessionCollectionPath = format(firestorePaths.ACTIVITY_SESSION_COLLECTION, {
      [pathPlaceholder.GAMEPLAY_SESSION_ID]: gameplaySessionID,
    });
    const activitySession = new ActivitySession(() => `${activitySessionCollectionPath}/${sessionID}`);

    return activitySession;
  };
}

export default ActivitySession;
