import React, {
  useCallback,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

import HealthDataSyncContext from '../../../context/HealthDataSyncContext';

import AppleHealthExplainer from './AppleHealthExplainer';

const AppleHealthExplainerContainer = ({
  onHealthDataEnabled,
  onClose,
}) => {
  const {
    requestAllPermissions,
  } = useContext(HealthDataSyncContext);

  const onAppleHealthDataEnabled = useCallback(async () => {
    try {
      await requestAllPermissions();
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          description: 'Error requesting health permissions in apple health explainer',
        },
      });
    } finally {
      onHealthDataEnabled();
    }
  }, [
    onHealthDataEnabled,
    requestAllPermissions,
  ]);

  return (
    <AppleHealthExplainer
      onHealthDataEnabled={onAppleHealthDataEnabled}
      onClose={onClose}
    />
  );
};

AppleHealthExplainerContainer.propTypes = {
  onHealthDataEnabled: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AppleHealthExplainerContainer;
