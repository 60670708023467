import styled from 'styled-components';

import ActivityRing, { Dot } from './ActivityRing';

const Swatch = styled.div`
  position: relative;
`;

const Svg = styled.svg`
  position: absolute;
`;

const ActiveEnergyRing = styled(ActivityRing)`
  height: 100%;
  width: 100%;
`;

const ExerciseRing = styled(ActivityRing)`
  height: 73%;
  width: 73%;

  ${Dot} {
    &:after {
      height: 17%;
      width: 17%;
    }
  }
`;

const StandHoursRing = styled(ActivityRing)`
  height: 46%;
  width: 46%;
  top: 49.9%;

  ${Dot} {
    &:after {
      width: 27%;
      height: 27%;
    }
  }
`;

export {
  Swatch,
  Svg,
  ActiveEnergyRing,
  ExerciseRing,
  StandHoursRing,
};
