import React from 'react';

const initialState = {
  isOpen: false,
  title: '',
  Icon: null,
  content: null,
  actions: [],
  description: '',
};

const NotificationModalContext = React.createContext(initialState);

export default NotificationModalContext;
export {
  initialState,
};
