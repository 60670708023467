import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import logEvent from '../../utils/logger';

import StyledButton from './styles';
import variants from './variants';

const Button = ({
  trackingProps,
  onClick,
  children,
  className,
  enableTracking,
  ...props
}) => {
  const buttonEl = useRef(null);
  const onButtonClick = (event) => {
    const evtProps = trackingProps || {};
    if (!evtProps.button
      && buttonEl.current
      && buttonEl.current.textContent.length > 0) {
      evtProps.button = buttonEl.current.textContent;
    }
    if (enableTracking && evtProps.button) {
      logEvent('buttonClicked', evtProps);
    }

    /*
      Remove focus from the button to avoid visibility issues on web and android:
      https://bugs.chromium.org/p/chromium/issues/detail?id=491828
    */
    buttonEl.current.blur();

    if (onClick) {
      onClick(event);
    }
  };
  return (
    <StyledButton
      ref={buttonEl}
      className={className}
      onClick={onButtonClick}
      {...props}
    >
      {children}
    </StyledButton>
  );
};

Button.propTypes = {
  variant: PropTypes.oneOf(Object.values(variants)),
  full: PropTypes.bool,
  trackingProps: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,
  enableTracking: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  variant: variants.PRIMARY,
  full: true,
  trackingProps: null,
  children: [],
  className: '',
  enableTracking: true,
  onClick: null,
};

export default styled(Button)``;
