import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { withVideoPlayerContextProvider } from '../VideoPlayer';
import {
  ExerciseOverrideSelectorContextProvider,
} from './context/ExerciseOverrideSelectorContext';
import ExerciseOverrideSelector from './ExerciseOverrideSelector';

const ExerciseOverrideSelectorContainer = ({
  activity,
  className,
  onCancel,
  onOverrideDone,
}) => (
  <ExerciseOverrideSelectorContextProvider
    activity={activity}
    onOverrideDone={onOverrideDone}
  >
    <ExerciseOverrideSelector
      className={className}
      onCancel={onCancel}
    />
  </ExerciseOverrideSelectorContextProvider>
);

ExerciseOverrideSelectorContainer.propTypes = {
  activity: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOverrideDone: PropTypes.func.isRequired,
  className: PropTypes.string,
};

ExerciseOverrideSelectorContainer.defaultProps = {
  className: '',
};

export default compose(
  withVideoPlayerContextProvider,
)(ExerciseOverrideSelectorContainer);
