import CollectionName from '../utils/collections';
import BaseDocument from './BaseDocument';

class AppCustomHabit extends BaseDocument {
  constructor(id, opts) {
    super(`${CollectionName.APP_CUSTOM_HABIT}/${id}`, opts);
  }

  get habits() {
    return this.data.habits || [];
  }

  get habitsDefinition() {
    return this.data.habitsDefinition;
  }

  static async getById(id) {
    const appCustomHabitDoc = new AppCustomHabit(id);
    await appCustomHabitDoc.init();
    return appCustomHabitDoc.exists ? appCustomHabitDoc : null;
  }
}

export default AppCustomHabit;
