import styled from 'styled-components';
import {
  Card,
} from '../commonStyles';

const StyledCard = styled(Card)`
  align-items: center;
`;

const canvasWidth = 400;

// Aspect ratio 2:1
const StyledCanvas = styled.canvas`
  max-width: ${canvasWidth}px;
  max-height: ${canvasWidth / 2}px;
`;

export {
  StyledCard,
  StyledCanvas,
};
