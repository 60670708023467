import styled from 'styled-components';

import LoadingPage from '../../../components/LoadingPage';

const StyledLoadingPage = styled(LoadingPage)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: rgba(237, 238, 233, 0.6);
`;

export {
  StyledLoadingPage,
};
