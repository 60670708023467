import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as SearchIcon } from '../../../assets/icons/icon_magnifying_glass.svg';
import StyledButton from './styles';

const SearchButton = ({
  onClick,
  className,
}) => (
  <StyledButton
    full={false}
    onClick={onClick}
    className={className}
    trackingProps={{
      actionName: 'exerciseOverrideSearch',
    }}
  >
    <SearchIcon />
  </StyledButton>
);

SearchButton.defaultProps = {
  className: '',
};

SearchButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default SearchButton;
