import styled from 'styled-components';

import { sansSerif } from '../../../../../../styles/text';

const Wrapper = styled.div`
  ${sansSerif.headline1}
  color: ${({ theme }) => theme.colors.system.alpha};
  font-weight: bold;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SetsInfoLabel = styled.div`
  ${sansSerif.headline3}
  color: ${({ theme }) => theme.colors.system.alpha};
  text-transform: uppercase;
  font-size: 14px;
`;

const SetsInfoValue = styled.div`
  ${sansSerif.headline2}
  color: ${({ theme }) => theme.colors.system.alpha};
  line-height: 30px;
`;

export {
  Wrapper,
  SetsInfoLabel,
  SetsInfoValue,
};
