import styled, { css } from 'styled-components';
import { Form } from 'formik';

import { body1, overline } from '../../styles/text';
import Card from '../../components/Card';
import FormikInput from '../../components/FormikInput';

const backgroundColorStyle = css`
  background-color: ${({ theme }) => theme.colors.system.alpha};
`;

const borderStyle = css`
  border: none;
  border-radius: 0;
  border-bottom-color: ${({ error, touched, theme }) => (
    !!error && !!touched
      ? theme.colors.base.primary
      : theme.colors.system.gamma2
  )};
  border-bottom-width: 1px;
  border-bottom-style: solid;
`;

const dataEntryElementStyle = css`
  ${borderStyle}
  ${backgroundColorStyle}
  ${body1}
  padding: 8px 5px;
  color: ${({ theme }) => theme.colors.base.primary};
`;

const Title = styled.div`
  ${overline}
  opacity: 1;
  font-weight: 900;
  margin: 16px;
`;

const ContainerForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledFormikInput = styled(FormikInput)`
  resize: none;
  input {
    ${dataEntryElementStyle}
    &:focus {
      ${borderStyle}
      ${backgroundColorStyle}
      border-bottom-color: ${({ theme }) => theme.colors.base.primary};
      outline: 0;
    }
  }
`;

const SpacedCard = styled(Card)`
  display: flex;
  flex-direction: column;
  margin: 2px 0;
  height: fit-content;
  > textarea {
    ${dataEntryElementStyle}
     &::placeholder {
      opacity: 0.4;
    }
    &:focus {
      ${borderStyle}
      ${backgroundColorStyle}
      outline: 0;
      border-bottom-color: ${({ theme }) => theme.colors.base.primary};
    }
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  overflow: auto;
  margin-top: 2px;
`;

const FooterContainer = styled.div`
  margin-top: 2px;
`;

export {
  Title,
  ContainerForm,
  StyledFormikInput,
  SpacedCard,
  ContentContainer,
  FooterContainer,
};
