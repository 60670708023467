import Firebase from '../firebase';
import SessionStore from './sessionStore';
import GameplayStore from './gameplayStore';

class RootStore {
  constructor() {
    this.sessionStore = new SessionStore(this);
    this.gameplayStore = new GameplayStore(this);
    this.firebase = new Firebase();
  }
}

const rootStore = new RootStore();

export default rootStore;
