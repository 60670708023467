import React, { useContext } from 'react';

import LoadingPage from '../../components/LoadingPage';
import UserNutritionInfoContextProvider from './UserNutritionInfoContextProvider';
import UserNutritionInfoContext from './UserNutritionInfoContext';

/**
 * Inserts UserNutritionInfoContextProvider.
 *
 * @param {Object} Component The component that needs the context provider.
 * @returns {Object} The component to render wrapped with the provider.
 */
const withUserNutritionInfoContextProvider = (Component) => (props) => (
  <UserNutritionInfoContextProvider>
    <Component {...props} />
  </UserNutritionInfoContextProvider>
);

/**
 * Renders the given component when UserNutritionInfoContext is ready.
 *
 * @param {Object} Component The component to render.
 * @param {Object} Component The rendered component.
 * @returns {Object} The component to render or the LoadingPage component.
 */
const withUserNutritionInfoContextReady = (Component) => (props) => {
  const { isReady } = useContext(UserNutritionInfoContext);
  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withUserNutritionInfoContextProvider,
  withUserNutritionInfoContextReady,
};
