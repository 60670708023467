import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

import Coach from '../../models/Coach';
import { firestorePaths } from '../../utils/firebasePaths';
import useComponentMounted from '../../hooks/useComponentMounted';
import useComponentLoadingTime from '../../hooks/useComponentLoadingTime';
import UserContext from '../UserContext';
import { setUserTraits } from '../../utils/logger';
import CoachInfoContext from './CoachInfoContext';

const CoachInfoContextProvider = ({ children }) => {
  const [coachInfo, setCoachInfo] = useState({});
  const [isReady, setIsReady] = useState(false);

  const { userDoc } = useContext(UserContext);
  const isComponentMountedRef = useComponentMounted();
  const { startLoading, finishLoading } = useComponentLoadingTime('coachInfoContext');

  useEffect(() => {
    const fetchCoachInfo = async () => {
      startLoading();
      const coachId = userDoc.assignedCoach;
      const assignedCoachDoc = new Coach(`${firestorePaths.COACH}/${coachId}`);
      await assignedCoachDoc.init();

      if (isComponentMountedRef.current) {
        setCoachInfo(assignedCoachDoc);
        setIsReady(true);
        finishLoading();
      }
    };

    if (!isReady) {
      fetchCoachInfo();
    }
  }, [
    isReady,
    userDoc,
    isComponentMountedRef,
    startLoading,
    finishLoading,
  ]);

  useEffect(() => {
    if (coachInfo) {
      // Add coach info to user properties
      setUserTraits({
        coachName: coachInfo.name,
        coach: coachInfo.id,
      });
    }
  }, [coachInfo]);

  const value = useMemo(() => ({
    coachInfo,
    isReady,
  }), [
    coachInfo,
    isReady,
  ]);

  return (
    <CoachInfoContext.Provider value={value}>
      {children}
    </CoachInfoContext.Provider>
  );
};

CoachInfoContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CoachInfoContextProvider;
