import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { Route, Redirect, useLocation } from 'react-router-dom';

import useSessionStore from '../../hooks/useSessionStore';

/**
 * A wrapper for <Route> that redirects to the login screen if you're not yet authenticated.
 */
const PrivateRoute = ({
  component: Component,
  children,
  ...rest
}) => {
  const { pathname, search, state = {} } = useLocation();
  const { authUser } = useSessionStore();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (authUser) {
          return Component ? <Component {...props} /> : children;
        }

        // Redirect to the login page
        return (
          <Redirect
            to={{
              pathname: '/login',
              search,
              state: {
                ...state,
                redirectAfterLogin: pathname,
              },
            }}
          />
        );
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
  children: PropTypes.node,
};

PrivateRoute.defaultProps = {
  children: null,
  component: null,
};

export default compose(
  observer,
)(PrivateRoute);
