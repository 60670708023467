import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import CardTitle from './CardTitle';
import CardDescription from './CardDescription';
import variants from './variants';

const cardVariant = ({ variant, theme: { colors } }) => {
  let backgroundColor;
  let color;

  switch (variant) {
    case variants.PRIMARY:
      backgroundColor = colors.system.alpha;
      color = colors.system.beta;
      break;
    case variants.SECONDARY:
      backgroundColor = colors.base.primary;
      color = colors.system.alpha;
      break;
    case variants.DARK:
      backgroundColor = colors.system.beta;
      color = colors.base.alpha;
      break;
    case variants.DANGER:
      backgroundColor = colors.system.error;
      color = colors.system.alpha;
      break;
    default:
      backgroundColor = colors.system.alpha;
      color = colors.system.beta;
  }

  return css`
    background-color: ${backgroundColor};
    color: ${color};
  `;
};

const textColor = ({ variant, theme: { colors } }) => {
  let color;

  switch (variant) {
    case variants.PRIMARY:
      color = colors.system.beta;
      break;
    case variants.SECONDARY:
      color = colors.system.alpha;
      break;
    case variants.DARK:
      color = colors.base.secondary;
      break;
    case variants.DANGER:
      color = colors.system.alpha;
      break;
    default:
      color = colors.system.beta;
  }

  return css`color: ${color};`;
};

const FlatCard = styled.div`
  display: flex;
  width: 100%;
  padding: 24px 16px;
  transition: all 0.4s linear;
  overflow: hidden;
  ${(props) => cardVariant(props)}
  ${(props) => props.onClick && 'cursor: pointer;'}

  ${CardTitle},
  ${CardDescription} {
    ${(props) => textColor(props)}
  }
`;

const Card = styled(FlatCard)`
  padding: 16px;
  transition: all 0.4s linear;
  overflow: hidden;
`;

Card.propTypes = {
  variant: PropTypes.oneOf(Object.values(variants)),
};

Card.defaultProps = {
  variant: variants.PRIMARY,
};

export default Card;
export { FlatCard };
