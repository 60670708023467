import merge from 'lodash.merge';

import PoseDetectionResult from '../../pose/PoseDetectionResult';
import PoseReasons from '../../pose/poseReasons';
import { isPointInBoundingBox } from '../../utils/math';
import { defaultHandActionArea } from '../actionAreas/handActionArea';

const openHandInRightArea = (opts = {}) => {
  const defaultOptions = {
    boundingBoxConfig: {
      actionArea: defaultHandActionArea,
    },
  };

  const options = merge({ ...defaultOptions }, opts);

  return (hand, processingArea) => {
    const res = new PoseDetectionResult();

    const {
      boundingBoxConfig: {
        actionAreaBoundingBox,
        actionArea,
      },
    } = options;

    const boundingBox = actionAreaBoundingBox(actionArea, processingArea);
    const handMiddlePoint = hand.boundingBoxMiddlePoint;

    // Check if the hand middle point (x, y) is in the detection area
    const isHandInDetectionArea = isPointInBoundingBox(handMiddlePoint, boundingBox);

    if (!isHandInDetectionArea) {
      res.addReason(PoseReasons.HandNotInArea_Fail);
    }

    // Check if the hand is opened by determining if the fingers are stretched
    const isHandOpened = hand.areAllFingersStretched();

    if (!isHandOpened) {
      res.addReason(PoseReasons.HandIsNotOpened_Fail);
    }

    return res;
  };
};

export {
  openHandInRightArea,
};
