import escapeStringRegexp from 'escape-string-regexp';

/**
 * Checks if the given search text exists in the provided text, by allowing any number of characters in between the
 * provided search words.
 *
 * @returns {Boolean} True if it matches, otherwise false.
 */
const search = (text, searchText) => {
  /*
    Create a new regexp from the input search. By replacing spaces with `.*`, we are allowing for any number of
    characters in between the provided search words.
  */
  const inputValueRegex = new RegExp(escapeStringRegexp(searchText).replace(' ', '.*'), 'i');

  return inputValueRegex.test(text);
};

const filterOption = (option, inputValue) => {
  // Extract the current label to check against the search query.
  const { label } = option;
  return search(label, inputValue);
};

export {
  search,
  filterOption,
};
