import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  padding-top: 5px;
`;

const Section = styled.div`
  margin-top: 24px;
  flex: 1;
`;

export {
  Container,
  Section,
};
