import React from 'react';

import CoachInfoContextProvider from './CoachInfoContextProvider';

const withCoachInfoContextProvider = (Component) => (props) => (
  <CoachInfoContextProvider>
    <Component {...props} />
  </CoachInfoContextProvider>
);

export default withCoachInfoContextProvider;
