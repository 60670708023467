import React, { useContext } from 'react';

import LoadingPage from '../../components/LoadingPage';

import GameplaySessionContext from './GameplaySessionContext';
import GameplaySessionContextProvider from './GameplaySessionContextProvider';

const withGameplaySessionContextProvider = (Component) => (props) => (
  <GameplaySessionContextProvider>
    <Component {...props} />
  </GameplaySessionContextProvider>
);

const withGameplaySessionContextReady = (Component) => (props) => {
  const { isReady } = useContext(GameplaySessionContext);
  return isReady ? <Component {...props} /> : <LoadingPage />;
};

export {
  withGameplaySessionContextProvider,
  withGameplaySessionContextReady,
};
