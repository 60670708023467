import React from 'react';

import ReonboardingModal from './ReonboardingModal';

/**
 * Adds Re-onboarding modal in the React tree
 *
 * @param {Object} Component - The component to render.
 * @returns {Object} - The component with the Re-onboarding modal.
 */
const withReonboardingModal = (Component) => (props) => (
  <>
    <Component {...props} />
    <ReonboardingModal />
  </>
);

export default withReonboardingModal;
