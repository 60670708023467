const variants = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  DARK: 'dark',
  LINK: 'link',
  SEMI_TRANSPARENT: 'semi_transparent',
};

export default variants;
