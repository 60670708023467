import React from 'react';

import FCMContextProvider from './FCMContextProvider';

const withFCMContext = (Component) => (props) => (
  <FCMContextProvider>
    <Component {...props} />
  </FCMContextProvider>
);

export {
  withFCMContext,
};
