import styled from 'styled-components';

import { headlineFontStyles } from '../../../styles/text';

const Container = styled.div`
  background: ${({ theme }) => theme.colors.system.background};
  padding-top: 4px;
`;

const Text = styled.div`
  ${headlineFontStyles}
  display: flex;
  align-items: center;
  text-align: center;
  height: 100%;
  padding: 0 50px;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: rgba(20, 34, 47, 0.4);
`;

export {
  Container,
  Text,
};
