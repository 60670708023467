import React from 'react';
import PropTypes from 'prop-types';

import { ActivityRingsTheme } from '../../../../../components/AppleActivityRings';
import { HeaderContainer } from '../styles';
import SummaryDataSection from './SummaryDataSection';
import {
  Container,
  InfoSection,
  StyledWorkoutDate,
  StyledAppleActivityRings,
} from './styles';

const TrackedActivitySummaryDayCard = ({
  date,
  activitySummary,
}) => (
  <HeaderContainer>
    <Container>
      <InfoSection>
        <StyledWorkoutDate>{date}</StyledWorkoutDate>
        <SummaryDataSection
          summaryData={activitySummary}
        />
      </InfoSection>
      <StyledAppleActivityRings
        activitySummary={activitySummary}
        theme={ActivityRingsTheme.LIGHT}
      />
    </Container>
  </HeaderContainer>
);

TrackedActivitySummaryDayCard.propTypes = {
  date: PropTypes.string.isRequired,
  activitySummary: PropTypes.object,
};

TrackedActivitySummaryDayCard.defaultProps = {
  activitySummary: null,
};

export default TrackedActivitySummaryDayCard;
