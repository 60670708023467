import React, { useCallback } from 'react';

import logEvent from '../../../utils/logger';
import useUserNavigation from '../../../hooks/useUserNavigation';
import LogActivity from './LogActivity';
import { customActivities } from './texts.json';

const LogActivityContainer = () => {
  const { navigateToUserPath } = useUserNavigation();

  const onAddCustomWorkout = useCallback(() => navigateToUserPath('customWorkout'), [navigateToUserPath]);

  const onActivitySelected = useCallback(({ title }) => {
    logEvent('activitySelectedForLogging', {
      title,
    });
    navigateToUserPath('customWorkout', {
      search: `?title=${title}`,
    });
  }, [navigateToUserPath]);

  return (
    <LogActivity
      activities={customActivities}
      onActivitySelected={onActivitySelected}
      onAddCustomWorkout={onAddCustomWorkout}
    />
  );
};

export default LogActivityContainer;
