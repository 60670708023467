const CollectionName = {
  WORKOUT: 'workout',
  WORKOUT_ASSIGNMENT: 'workoutAssignment',
  GAMEPLAY_SESSION: 'gameplaySession',
  USER_DEVICE: 'userDevice',
  USER_ACTIVITY_HISTORY: 'userActivityHistory',
  USER_EXERCISE_OVERRIDES: 'userExerciseOverrides',
  USER_NUTRITION_PROFILE: 'userNutritionProfile',
  USER_NUTRITION_MACRO_GOALS: 'userNutritionMacroGoals',
  USER_NUTRITION_LOG: 'userNutritionLog',
  COACH: 'coach',
  EXTERNAL_COACH: 'externalCoach',
  TRACKED_ACTIVITY_SUMMARY: 'trackedActivitySummary',
  WORKOUT_FEED_VIEW: 'workoutFeedView',
  EXERCISE: 'exercise',
  STREAK: 'streak',
  TRACKED_ACTIVITY: 'trackedActivity',
  REFERRAL_CODE: 'referralCode',
  PRODUCT: 'product',
  PRICE: 'price',
  APP_CUSTOMIZATION: 'appCustomization',
  APP_CUSTOM_THEME: 'appCustomTheme',
  CHANGELOG: 'changelog',
  USER: 'user',
  CONFIG: 'config',
  MESSAGE_SUGGESTION: 'messageSuggestion',
  MEASUREMENTS_LOG: 'measurementsLog',
  EXERCISE_TAG: 'exerciseTag',
  INTEGRATIONS: 'integrations',
  HABIT_LOG: 'habitLog',
  APP_CUSTOM_HABIT: 'appCustomHabits',
  MEAL_PLAN: 'mealPlan',
  MEAL_PLAN_ASSIGNMENT: 'mealPlanAssignment',
  RECIPE: 'recipe',
  POST_PAYMENT_FORM_ANSWER: 'postPaymentFormAnswer',
  USER_CONTRACT: 'userContract',
  CHECK_IN: 'checkIn',
  APP_CUSTOM_CHECK_IN: 'appCustomCheckIn',
  BROADCAST_MESSAGE: 'broadcastMessage',
  RECIPE_REPORT: 'recipeReport',
};

export default CollectionName;
