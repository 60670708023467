import ActionTypes from './actionTypes';

const actionsConfig = {
  [ActionTypes.HAND_ADVANCE_NEXT]: {
    boundingBoxConfig: {
      actionArea: {
        xMin: 0,
        xMax: 0.15,
        yMin: 0.3,
        yMax: 0.7,
      },
      /**
       * Calculates the action area to use for processing and detection considering constraint
       * like the safe areas in many devices. The safe areas are calculated based on the location
       * of an HTML element.
       *
       * @param {Object} actionArea
       * @param {Object} processingArea
       * @returns {Object}
       */
      actionAreaBoundingBox: (actionArea, processingArea) => {
        const {
          cameraViewportClientRect,
          vnbActionClientRect,
        } = processingArea || {};

        let xOffset = 0;

        if (cameraViewportClientRect
          && vnbActionClientRect
          && vnbActionClientRect.right !== cameraViewportClientRect.right
        ) {
          const vnbXMax = 1 - (
            (vnbActionClientRect.left - cameraViewportClientRect.left) / cameraViewportClientRect.width
          );
          xOffset = vnbXMax - actionArea.xMax;
        }

        const boundingBox = {
          ...actionArea,
        };

        if (xOffset > 0) {
          boundingBox.xMax += xOffset;
          boundingBox.xMin += xOffset;
        }

        return boundingBox;
      },
    },
    totalDetectionTime: 1000,
    totalProcessingTime: 1000,
  },
};

export default actionsConfig;
