import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import { ActivityTypes } from '../../../models/BaseActivity';
import { convertIndexToLetter } from '../../../../utils/text';

import Activity from '../Activity';
import Circuit from '../Circuit';

const ActivitiesList = ({
  activities,

  isEditorMode,
  onEditorModeActionPerform,
}) => {
  const renderActivities = () => (
    activities.map((activity, index) => {
      const activityLetter = convertIndexToLetter(index);

      if (activity.type === ActivityTypes.CIRCUIT) {
        const showRestWarning = isEditorMode
          && index !== activities.length - 1 // Check that this isn't the last activity of the workout
          && activity.activities.length > 0 // Check that this CIRCUIT has activities inside
          && !activity.activities[activity.activities.length - 1].restTime
          && activities[index + 1].type !== ActivityTypes.REST;

        return (
          <Circuit
            activity={activity}
            path={[index]}
            circuitTag={activityLetter}
            key={activityLetter}
            isEditorMode={isEditorMode}
            onEditorModeActionPerform={onEditorModeActionPerform}
            showRestWarning={showRestWarning}
          />
        );
      }

      return (
        <Activity
          activity={activity}
          path={[index]}
          tag={activityLetter}
          key={activityLetter}
          isEditorMode={isEditorMode}
          onEditorModeActionPerform={onEditorModeActionPerform}
        />
      );
    })
  );

  return (
    <>
      {renderActivities()}
    </>
  );
};

ActivitiesList.propTypes = {
  activities: PropTypes.array.isRequired,

  isEditorMode: PropTypes.bool,
  onEditorModeActionPerform: PropTypes.func,
};

ActivitiesList.defaultProps = {
  isEditorMode: false,
  onEditorModeActionPerform: () => {},
};

export default compose(
  observer,
)(ActivitiesList);
