import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { IonSpinner } from '@ionic/react';

import VideoInProgressImg from '../../../assets/video_in_progress.svg';
import VideoPlaylist from '../../../components/VideoPlaylist';

import texts from './texts.json';
import {
  DeleteButton,
  VideoHeaderContainer,
  HeaderText,
  VideoPlaceholder,
  WarningContainer,
  LoaderWrapper,
} from './styles';
import WorkoutVideoStatus from './workoutVideoStatus';

const WorkoutVideo = ({
  showDeleteButton,
  showCameraOffWarning,
  videos,
  date,
  isDeleteButtonDisabled,
  showSpinnerOverlay,
  status,
  onOpenDeleteVideoAlert,
}) => {
  const renderStatusIndicator = useCallback(() => {
    switch (status) {
      case WorkoutVideoStatus.PROCESSING:
        return <VideoPlaceholder src={VideoInProgressImg} />;
      case WorkoutVideoStatus.PREPARING:
        return (
          <LoaderWrapper>
            <IonSpinner name="crescent" />
          </LoaderWrapper>
        );
      default:
        return null;
    }
  }, [status]);

  return (
    <>
      {showCameraOffWarning && (
        <WarningContainer>{texts.cameraOffWarning}</WarningContainer>
      )}
      {videos.length > 0 && (
        <>
          <VideoHeaderContainer>
            <HeaderText>{date}</HeaderText>
            {showDeleteButton && (
              <DeleteButton
                onClick={onOpenDeleteVideoAlert}
                disabled={isDeleteButtonDisabled}
              >
                {texts.deleteVideoAlert.title}
              </DeleteButton>
            )}
          </VideoHeaderContainer>
          <VideoPlaylist
            showSpinnerOverlay={showSpinnerOverlay}
          />
        </>
      )}
      {renderStatusIndicator()}
    </>
  );
};

WorkoutVideo.propTypes = {
  videos: PropTypes.array.isRequired,
  date: PropTypes.string.isRequired,
  showDeleteButton: PropTypes.bool.isRequired,
  showCameraOffWarning: PropTypes.bool.isRequired,
  isDeleteButtonDisabled: PropTypes.bool.isRequired,
  showSpinnerOverlay: PropTypes.bool.isRequired,
  status: PropTypes.oneOf(Object.values(WorkoutVideoStatus)).isRequired,
  onOpenDeleteVideoAlert: PropTypes.func.isRequired,
};

export default WorkoutVideo;
